/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { createContext } from 'react';
import { Router } from 'react-router';
import { History } from 'history';
import {
  RACThemeProvider,
  makeStyles,
  StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';
// import '../css/commontheme.css';
// import '../css/Smart.css';
// import '../css/stylesheet.css';
// import '../css/twoFactorpopup.css';

// Racstrap css
// import '../css/global.css';
// import '../css/PaymentSheet.css';
// import '../css/twoFactorpopup.css';
// import '../css/GridStyle.css';

import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../../src/index';
import { PaymentContext } from '../context/paymentTriangle/chartcontext';
import { HistoryContext } from '../context/history/historyContext';
import { CustomerContext } from '../context/customer/customerdata';
import { StateContext } from '../context/stateapi/stateapicontext';

interface Props {
  history: History;
  customProps?: CustomPropInterface;
}

export const appTestId = 'appTestId';

const useStyles = makeStyles(() => ({
  App: {
    marginTop: '62px',
  },
  container: {
    width: '100%',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
  },
}));

const generateClassName = createGenerateClassName({
  //disableGlobal: true,
  //productionPrefix: 'racprodprefix',
  seed: 'RAC-PAYMENT-',
});
export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {
  const enableLogs = window.sessionStorage.getItem('enableLogs'); if (enableLogs != '1') {    // eslint-disable-next-line @typescript-eslint/no-empty-function 
    console.log = function () { };
  }
  const { isRenderedByContainer } = window;
  const classes = useStyles();

  return (
    <div className={`${classes.App} ${classes.container}`} data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
        <StateContext>
          <HistoryContext>
            <CustomerContext>
              <PaymentContext>
                <RACThemeProvider>
                  <ContainerContext.Provider value={customProps}>
                    <div className="">
                      <ErrorBoundary>
                        <Router history={history}>
                          {isRenderedByContainer ? (
                            <InContainerAuthProvider>
                              <AuthGuard />
                            </InContainerAuthProvider>
                           ) : (
                            <StandaloneAuthProvider>
                              <AuthGuard />
                            </StandaloneAuthProvider>
                          )} 
                        </Router>
                      </ErrorBoundary>
                    </div>
                  </ContainerContext.Provider>
                </RACThemeProvider>
              </PaymentContext>
            </CustomerContext>
          </HistoryContext>
        </StateContext>
      </StylesProvider>
    </div>
  );
};

/* eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';

export const rentalTwofactorStyle = makeStyles((theme) => ({
    textCenter: {
      textAlign: 'center',
    },
    logoResponsive: {},
    spacerMargin: {
      margin: theme.typography.pxToRem(8),
    },
    spacerMT1: {
      marginTop: theme.typography.pxToRem(4),
    },
    racLoginAlert: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    racRemoteLoginAlert: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
      marginTop: '15px'
    },
    racRemoteLogin: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      textAlign: 'center',
      margin: '25px 15px',
      fontSize: theme.typography.pxToRem(14),
    },
  
    formLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    floatRight: {
      float: 'right',
    },
    spacerMT5: {
      marginTop: theme.typography.pxToRem(48),
    },
    spacerMB3: {
      marginBottom: theme.typography.pxToRem(24),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    spacerME2: {
      marginRight: theme.typography.pxToRem(16),
    },
    racErrorIcon: {
      width: '50px',
    },
    racErrorFunction: {
      fontFamily: 'OpenSans-semibold',
      marginBottom: '20px',
      marginTop: '10px',
    },
    racPinContainer: {
      margin: '0 auto',
      width: '85%',
      paddingLeft: '15px',
    },
    racPinLabel: {
      fontFamily: 'OpenSans-semibold',
      color: `${RACCOLOR.RICH_BLACK}`,
      marginLeft: '0.5rem',
      marginBottom: '3px',
    },
    racPinError: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      marginTop: '5px',
      marginLeft: '0.5rem',
      fontFamily: 'OpenSans-semibold',
    },
    racLoader: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, 85%)',
    },
    spinnerModal: {
      position: 'absolute',
      inset: '40px',
      border: '1px solid rgb(204 204 204 / 0%)',
      background: 'rgb(255 255 255 / 0%)',
      overflow: 'auto',
      borderRadius: '4px',
      outline: 'none',
      padding: '20px',
    },
    paddingButtons: {
      paddingRight: '45px',
    },
    buttonMargin: {
      marginRight: '50px'
    },
    masterLoader: {
      position: "fixed",
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1050,
      textAlign: "center",
      margin: 0,
    },
    Loader: {
      display: "block",
      position: "fixed",
      zIndex: 1051,
      top: "50%",
      right: "50%",
    },
  }));
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable no-cond-assign */
/* eslint-disable no-constant-condition */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable no-debugger */
import React from 'react';
import Modal from 'react-modal';
import { useState, useEffect ,useContext} from 'react';
import { userTraceContextData } from "../../context/UserTraceContext";
//import {getPaymentinfo,getCardDetails} from '../../api/user';
import {
  RACModalCard,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACCheckBox,
  RACRadio,
  RACButton,
  Grid,
  Typography,
  Box,
  RACCOLOR,
  makeStyles,
} from '@rentacenter/racstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ParamType } from '../../constants/constants';
//import { values } from 'lodash';

const useClasses = makeStyles((theme: any) => (
  {
    title: {
      color: '#212529',
      fontFamily: 'OpenSans-bold',
      fontSize: '16px',
      marginBottom: '10px',
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },
    checkboxwidth: {
      width: theme.typography.pxToRem(10),
    },
    checkboxStyle:
    {
      width: 'unset',
      border: 'none',
    },
    w100: {
      width: '100%',
    },
    floatLeft: {
      float: 'left',
    },
    floatRight: {
      float: 'right',
    },
    /* Margin and Padding spacing */
    m0: {
      margin: 0,
    },

    m1: {
      margin: theme.typography.pxToRem(4),
    },

    m2: {
      margin: theme.typography.pxToRem(8),
    },

    m3: {
      margin: theme.typography.pxToRem(16),
    },

    m4: {
      margin: theme.typography.pxToRem(24),
    },

    m5: {
      margin: theme.typography.pxToRem(48),
    },

    mAuto: {
      margin: 'auto',
    },

    mx0: {
      marginRight: 0,
      marginLeft: 0,
    },

    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mx2: {
      marginRight: theme.typography.pxToRem(8),
      marginLeft: theme.typography.pxToRem(8),
    },

    mx3: {
      marginRight: theme.typography.pxToRem(16),
      marginLeft: theme.typography.pxToRem(16),
    },
    mx4: {
      marginRight: theme.typography.pxToRem(24),
      marginLeft: theme.typography.pxToRem(24),
    },

    mx5: {
      marginRight: theme.typography.pxToRem(48),
      marginLeft: theme.typography.pxToRem(48),
    },

    mxAuto: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    my0: {
      marginTop: 0,
      marginBottom: 0,
    },

    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },

    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },

    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },

    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },

    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },

    myAuto: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    mt0: {
      marginTop: 0,
    },

    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },

    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },

    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },

    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },

    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },

    mtAuto: {
      marginTop: 'auto',
    },

    me0: {
      marginRight: 0,
    },

    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },

    me3: {
      marginRight: theme.typography.pxToRem(16),
    },

    me4: {
      marginRight: theme.typography.pxToRem(24),
    },

    me5: {
      marginRight: theme.typography.pxToRem(48),
    },

    meAuto: {
      marginRight: 'auto',
    },

    mb0: {
      marginBottom: 0,
    },

    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },

    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },

    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },

    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },

    mbAuto: {
      marginBottom: 'auto',
    },

    ms0: {
      marginLeft: 0,
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },

    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },

    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },

    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },

    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },

    msAuto: {
      marginLeft: 'auto',
    },

    p0: {
      padding: 0,
    },

    p1: {
      padding: theme.typography.pxToRem(4),
    },

    p2: {
      padding: theme.typography.pxToRem(8),
    },

    p3: {
      padding: theme.typography.pxToRem(16),
    },

    p4: {
      padding: theme.typography.pxToRem(24),
    },

    p5: {
      padding: theme.typography.pxToRem(48),
    },

    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },

    px1: {
      paddingRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(4),
    },

    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },

    px3: {
      paddingRight: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(16),
    },

    px4: {
      paddingRight: theme.typography.pxToRem(24),
      paddingLeft: theme.typography.pxToRem(24),
    },

    px5: {
      paddingRight: theme.typography.pxToRem(48),
      paddingLeft: theme.typography.pxToRem(48),
    },

    py0: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },

    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },

    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },

    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },

    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },

    pt0: {
      paddingTop: 0,
    },

    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },

    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },

    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },

    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },

    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },

    pe0: {
      paddingRight: 0,
    },

    pe1: {
      paddingRight: theme.typography.pxToRem(4),
    },

    pe2: {
      paddingRight: theme.typography.pxToRem(8),
    },

    pe3: {
      paddingRight: theme.typography.pxToRem(16),
    },

    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },

    pe5: {
      paddingRight: theme.typography.pxToRem(48),
    },

    pb0: {
      paddingBottom: 0,
    },

    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },

    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },

    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },

    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },

    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },

    ps0: {
      paddingLeft: 0,
    },

    ps1: {
      paddingLeft: theme.typography.pxToRem(4),
    },

    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },

    ps3: {
      paddingLeft: theme.typography.pxToRem(16),
    },

    ps4: {
      paddingLeft: theme.typography.pxToRem(24),
    },

    ps5: {
      paddingLeft: theme.typography.pxToRem(48),
    },

    font11: {
      fontSize: theme.typography.pxToRem(11),
    },

    font12: {
      fontSize: theme.typography.pxToRem(12),
    },

    font13: {
      fontSize: theme.typography.pxToRem(13),
    },

    font14: {
      fontSize: theme.typography.pxToRem(14),
    },

    font15: {
      fontSize: theme.typography.pxToRem(15),
    },

    font16: {
      fontSize: theme.typography.pxToRem(16),
    },

    font17: {
      fontSize: theme.typography.pxToRem(17),
    },

    font18: {
      fontSize: theme.typography.pxToRem(18),
    },
    customerNorecords: {
      textAlign: 'center',
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },

    mandatoryfield: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
    },
    formLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    raccollg5: {
      flex: '0 0 auto',
      width: '41.6666666667%',
    },
    borderrightstyle: {
      borderRight: '1px solid #D3D3D3',
    },
    raccollg6: {
      flex: '0 0 auto',
      width: '50%',
    },
    titletxtstyle: {
      fontSize: theme.typography.pxToRem(24),
    },
    bold: {
      fontFamily: 'OpenSans-bold',
    },
    raccollg3: {
      flex: '0 0 auto',
      width: '25%',
    },
    labeltxtstyle: {
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      color: '#000000',
    },
    raccollg4: {
      flex: '0 0 auto',
      width: '33.3333333333%',
    },
    labelnotxtstyle: {
      color: '#6C86A5',
    },
    raccollg7: {
      flex: '0 0 auto',
      width: '58.3333333333%',
    },
    alertcolor: {
      color: '#69a4ff',
    },
    alertwidgartcontainer: {
      flexWrap: 'unset',
      overflow: 'auto',
      display: 'flex',
      float: 'left',
      width: '420px',
    },
    raccollg2: {
      flex: '0 0 auto',
      width: '16.6666666667%',
    },
    Norecords: {
      textAlign: 'center',
      paddingTop: theme.typography.pxToRem(30),
      paddingBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: '#000',
    },
    alertswidgartsstyle: {
      backgroundColor: '#FCE5E5',
      borderRadius: theme.typography.pxToRem(40),
      color: '#4B4B4B',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      padding: '4px 15px 6px 15px',
      marginTop: '1px',
      float: 'left',
      whiteSpace: 'nowrap',
    },
    raccollg9: {
      flex: '0 0 auto',
      width: '75%',
    },
    racGrid: {
      '& th': {
        fontFamily: 'OpenSans-semibold',
        whiteSpace: 'nowrap',
      }
    },
    formcheckinput: {
      width: theme.typography.pxToRem(8),
      height: theme.typography.pxToRem(8),
      verticalAlign: 'top',
      backgroundColor: '#fff',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      border: '1px solid rgba(0, 0, 0, 0.25)',
      '& checked': {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      }
    },
    rowColor: {
      backgroundColor: '#fff',
    },
    fontchange: {
      fontSize: theme.typography.pxToRem(13),
      whiteSpace: 'nowrap',
    },
    racpadlink: {
      color: '#2179fe',
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'none',
    },
    racpadpaymentsuccess: {
      borderLeft: '3px solid #56E0D8!important',
    },
    racpadagrlink: {
      borderLeft: '3px solid transparent',
      paddingLeft: theme.typography.pxToRem(10),
      borderRadius: theme.typography.pxToRem(2),
      padding: theme.typography.pxToRem(9),
    },
    racpadpaymentfails: {
      borderLeft: '3px solid #FD6A63',
    },
    accordiontoggle: {
      display: 'block',
    },
    accordionarrowchan: {
      backgroundImage: 'url(../assets/images/accordion-open-icon.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: theme.typography.pxToRem(20),
      cursor: 'pointer',
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      display: 'block',
    },
    accordionarrow: {
      backgroundImage: 'url(../assets/images/accordion-close-icon.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: theme.typography.pxToRem(20),
      cursor: 'pointer',
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      display: 'block',
    },
    hiddenRow: {
      backgroundColor: 'white',
    },
    backgroundcol: {
      backgroundColor: '#FAE2DF',
    },
    textcenter: {
      textAlign: 'center',
    },
    textCenter: {
      textAlign: 'center',
    },
    raccollg8: {
      flex: '0 0 auto',
      width: '66.6666666667%',
    },
    tabborder: {
      borderBottom: '1px solid #EFEFEF',
    },
    RACnav: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingLeft: '0',
      marginBottom: '0',
      listStyle: 'none',
    },
    racpadtab: {
      width: 'auto',
    },
    semibold: {
      fontFamily: 'OpenSans-semibold',
    },
    w25: {
      width: '25%',
    },
    inputgroup: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      width: '100%',
    },
    inputgrouptext: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#212529',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#e9ecef',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      borderTopLeftRadius: theme.typography.pxToRem(9),
      borderBottomLeftRadius: theme.typography.pxToRem(9),
    },
    formcontrol: {
      display: 'block',
      fontSize: theme.typography.pxToRem(14),
      width: '100%',
      padding: '0.375rem 0.75rem',
      lineHeight: '1.5',
      color: '#212529',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      borderRadius: '0.6rem',
      border: '1px solid #C4C4C4',
    },
    textend: {
      textAlign: 'right',
    },
    RAClabeltxtstyle: {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(5),
      fontFamily: 'OpenSans-semibold',
      color: '#111111',
    },
    RACvaluetxtstyle: {
      color: '#B91919',
      fontSize: theme.typography.pxToRem(16),
    },
    w75: {
      width: '75%',
    },
    raccollg12: {
      flex: '0 0 auto',
      width: '100%',
    },
    borderbottom: {
      borderBottom: '1px solid #dee2e6',
    },
    formcheck: {
      display: 'block',
      minHeight: '1.5rem',
      marginBottom: '0.125rem',
    },
    totalsublabelstyle: {
      fontSize: theme.typography.pxToRem(16),
    },
    totallabelstyle: {
      fontSize: theme.typography.pxToRem(18),
      color: '#fff',
    },
    editCardbtn: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#2279fd',
    },
    w50: {
      width: '50%',
    },
    updatebutton: {
      backgroundColor: '#E0EAFF',
      color: '#2468FF',
      border: 'none',
    },
    deletebutton: {
      backgroundColor: '#FFD1D1',
      color: '#B50000',
      border: 'none',
    },
    RACpaymenttotalcontainer: {
      backgroundColor: '#2179FE',
      borderRadius: theme.typography.pxToRem(10),
      width: '100%',
      float: 'left',
      height: '100%',
    },
    totalvaluestyle: {
      fontSize: theme.typography.pxToRem(25),
    },
    colorwhite: {
      color: '#fff',
    },
    formcheckinline: {
      display: 'inline-block',
      marginRight: '1rem',
    },
    changecredit: {
      color: '#fff',
    },
    containerborderbtm: {
      borderBottom: '1px solid #448BF7',
    },
    alertwidget: {
      borderRadius: '0',
    },
    labelbluetxtstyle: {
      color: '#2179FE',
      textDecoration: 'none',
      fontFamily: 'OpenSans-semibold',
    },
    justifycontentcenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    raccollg10: {
      flex: '0 0 auto',
      width: '83.3333333333%',
    },
    autopayValue: {
      borderRadius: '50%',
      padding: '4px 10px',
      backgroundColor: '#EFF4FF',
      color: '#276BFF',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
    },
    autoPaySubTitle: {
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(17),
    },
    autoPaySubTitleAccent: {
      color: '#7E7E7E',
      fontSize: theme.typography.pxToRem(14),
      fontFamily: 'OpenSans-semibold',
    },
    racTable: {
      backgroundColor: '#f7f7f7',
    },
    RACalertmsg: {
      color: '#ff1d1d',
    },
    raccollg11: {
      flex: '0 0 auto',
      width: '91.6666666667%',
    },
    tablerowtheme: {
      backgroundColor: 'transparent',
    },
    clearbtn: {
      color: '#2179FE',
      fontFamily: 'OpenSans-semibold',
    },
    modalfooter: {
      borderTop: '0px solid transparent',
    },
    racLoginAlert: {
      color: 'red',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    racErrorIcon: {
      width: theme.typography.pxToRem(50),
    },
    racErrorFunction: {
      fontFamily: 'OpenSans-semibold',
      marginBottom: theme.typography.pxToRem(20),
      marginTop: theme.typography.pxToRem(10),
    },
    racPinContainer: {
      margin: '0 auto',
      width: '94%',
    },
    racPinLabel: {
      fontFamily: 'OpenSans-semibold',
      color: '#000',
      marginLeft: '0.5rem',
      marginBottom: theme.typography.pxToRem(3),
    },
    racPinError: {
      color: 'red',
      marginTop: theme.typography.pxToRem(5),
      marginLeft: '0.5rem',
      fontFamily: 'OpenSans-semibold',
    },
    mandatory: {
      marginBottom: theme.typography.pxToRem(8),
      '& after': {
        content: '*',
        color: 'red',
      }
    },
    containerlftborder: {
      borderLeft: '1px solid #D6D6D6',
    },
    validationstyle: {
      color: 'red',
      fontSize: theme.typography.pxToRem(14),
    },
    customsure: {
      color: '#CB324C'
    },
    bgpopdelete: {
      backgroundColor: '#F8F8F8',
      borderBottomLeftRadius: theme.typography.pxToRem(9),
      borderBottomRightRadius: theme.typography.pxToRem(9),
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0),
    },
    graycolortext: {
      color: '#52595e',
    },
    blackcolortext: {
      color: '#000'
    },
    btnkeepdelete: {
      color: 'white',
      backgroundColor: '#CB324C',
      border: '#CB324C',
      paddingTop: theme.typography.pxToRem(11),
      paddingBottom: theme.typography.pxToRem(11),
    },
    racDeviceInfo: {
      position: 'relative',
    },
    textright: {
      textAlign: 'right',
    },
    customtextpay: {
      color: '#2179fe',
      fontFamily: 'OpenSans-semibold',
    },
    textleft: {
      textAlign: 'left',
    },
    listgroupitem: {
      backgroundColor: '#EFF4FF',
      color: '#2179FE',
      borderColor: '#EFF4FF',
      float: 'left',
      width: 'auto',
      borderRadius: theme.typography.pxToRem(3),
    },
    raccolauto: {
      width: 'auto',
    },
    hide: {
      display: 'none',
    },
    racDeviceGuide: {
      position: 'absolute',
      background: '#fff',
      width: theme.typography.pxToRem(335),
      padding: theme.typography.pxToRem(15),
      right: '0',
      zIndex: 1,
      borderRadius: theme.typography.pxToRem(10),
      boxShadow: '0 0 6px #dfdfdf',
    },
    racDeviceImage: {
      '& svg': {
        width: '100%',
        height: 'auto',
      },
      '& img': {
        width: '100%',
        height: 'auto',
      }
    },
    toolbar: {
      position: 'fixed',
      right: '0',
      bottom: '0',
      left: '0',
      zIndex: 1030,
      backgroundColor: '#fff',
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '1rem',
    },
    btngroup: {
      position: 'relative',
      display: 'inline-flex',
      verticalAlign: 'middle',
    },
    dropdownitemcustom: {
      color: '#2468FF',
      fontFamily: 'OpenSans-bold',
      backgroundColor: 'white',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    dropdowntoggle: {
      '&: after': {
        display: 'inline-block',
        marginLeft: '0.255em',
        verticalAlign: '0.255em',
        content: "",
        borderTop: '0.3em solid',
        borderRight: '0.3em solid transparent',
        borderBottom: '0',
        borderLeft: '0.3em solid transparent',
      }
    },
    footerbtnmenu: {
      border: '1px solid #2468FF',
      position: 'absolute',
      top: 'auto',
      left: '0',
      backgroundColor: 'white',
      bottom: theme.typography.pxToRem(43),
      display: 'none',
    },
    customerTablecellbgcolor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
  }));

export type Autopayprops = {
  PropsCustomerName: string,
  PropsCustomerPhoneNumber: string,
  PropsCustomerId: string | number,
  setAPpopupcompleted: any,
  setResponseObject?: any,
  GetCardResponse?: any,
  GetPaymentInfoResponse?: any,
  CheckedAgreementIds?: any,
  setIsClubSelectedForAutopay?: any,
  IsClubSelectedForAutopay: any,
  PropsIsManageCardPopup?: any,
  setAutoPayLoader?: any
}
export default function Autopay(props: Autopayprops) {
  const classes = useClasses();
  const {
    PropsCustomerName,
    PropsCustomerPhoneNumber,
    PropsCustomerId,
    setAPpopupcompleted,
    setResponseObject,
    GetCardResponse,
    GetPaymentInfoResponse,
    CheckedAgreementIds,
    setIsClubSelectedForAutopay,
    IsClubSelectedForAutopay,
    PropsIsManageCardPopup
  } = props
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;
  const AutopayCustomerInfoObj = {
    CustomerName: '',
    CustomerPhoneNumber: ''
  }
  const [AutopayOpen, setAutopayOpen] = useState(true);
  const [SubmitButtonDisabeld, setSubmitButtonDisabeld] = useState(true);
  const [AutoPayCheckbox, setAutoPayCheckbox] = useState(false);
  const [AutopayCustomerInfo, setAutopayCustomerInfo] = useState(AutopayCustomerInfoObj);
  const [ListOfCards, setListOfCards] = useState([]);
  const [ListOfCardsChkArray, setListOfCardsChkArray]: any = useState([]);
  const [ListOfCardsDisableArray, setListOfCardsDisableArray]: any = useState([])
  const [ListOfAgreements, setListOfAgreements]: any = useState([]);
  const [ListOfAgrChkArray, setListOfAgrChkArray]: any = useState([]);
  //const[ListOfAgrDisableArray,setListOfAgrTrueArray]=useState([]);
  const [ListOfAgrDisableArray, setListOfAgrDisableArray]: any = useState([]);
  const [ResponseToBeSent, setResponseToBeSent] = useState({});
  const [SelectedAgreementIds, setSelectedAgreementIds]: any = useState([]);
  const [ClearButtonClicked, setClearButtonClicked] = useState(false);
  const [AutopayBtnText, setAutopayBtnText] = useState('Send Autopay Signup Text');
  const { customerId } = useParams<ParamType>();//RAC2
  const { agreementId } = useParams<ParamType>();//RAC2
  const urlParams = new URLSearchParams(window.location.search);//RAC2
  const exchangeAgreementId = urlParams.get('exchangeAgreementId');//RAC2
  const history = useHistory();
  useEffect(() => {
    debugger;
    setAutopayCustomerInfo({ ...AutopayCustomerInfo, CustomerName: PropsCustomerName, CustomerPhoneNumber: PropsCustomerPhoneNumber })
    console.log('GetCardDetailResponse', GetCardResponse);
    const ListOfCardsArray: any = GetCardResponse.cardResp.map((ele: any) => { return { value: ele.cardLastFour } });
    console.log('ListOfCards', ListOfCardsArray)
    setListOfCards(ListOfCardsArray);
    const CardsFalseArray: any = new Array(ListOfCardsArray.length).fill(false);
    setListOfCardsChkArray(CardsFalseArray);
    setListOfCardsDisableArray(CardsFalseArray);
    if(PropsIsManageCardPopup){ setAutopayBtnText('Send Text'); }

    console.log('GetPaymentInfoResponse', GetPaymentInfoResponse);
    const ListOfAgreementsArray: any = [];
    for (let limit = 0; limit <= GetPaymentInfoResponse.paymentInfo.paymentInfo.length - 1; limit++) {
      if ((GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].storeNumber == String(window.sessionStorage.getItem('storeNumber'))) && ((!PropsIsManageCardPopup && GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].autopayEnrollmentDate === null)
        || (PropsIsManageCardPopup && GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].autopayEnrollmentDate))) {
        const AgreementIdForLoop = GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].agreementId;
        const obj: any = {
          AgreementId: GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].agreementId,
          Description: GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].agreementDesc,
          Schedule: GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].schedule,
        }
        const AmountDueArray: any = GetPaymentInfoResponse.amountDue.amountDueResponse
        const IndexOfPay = AmountDueArray.findIndex(obj => obj.agreementId === GetPaymentInfoResponse.paymentInfo.paymentInfo[limit].agreementId);
        // for (let i = 0; i <= AmountDueArray.length - 1; i++) {
        if (AgreementIdForLoop === AmountDueArray[IndexOfPay].agreementId) {
          obj.DueDate = GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].pastDueDate;
          // const TaxAmount = Number((Number((GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].ldwAmount == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].ldwAmount)) +
          //   (GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].rpayTaxAmount == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].rpayTaxAmount)) +
          //   (GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].ldwTaxAmount == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].ldwTaxAmount)) +
          //   (GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].lFeeTaxAmount == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].lFeeTaxAmount)) +
          //   (GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].policyTaxAmount == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].policyTaxAmount)) +
          //   Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].deliveryAmountTax == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].deliveryAmountTax))
          //   + Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].mattressFeeTax == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].mattressFeeTax))
          //   + Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].processingFeeTax == undefined ? 0 : Number(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].processingFeeTax)))));
          const caculatedScheduleRates = calculateSchduleRates(GetPaymentInfoResponse.amountDue.amountDueResponse[IndexOfPay].agreementRateDetails, 'AGR');
          obj.Amount = Number(caculatedScheduleRates.toFixed(2));
          //   break;
          // }
        }
        // if(GetPaymentInfoResponse.amountDue.amountDueResponse[limit].amountDue!==undefined)
        // {
        //   obj.DueDate=GetPaymentInfoResponse.amountDue.amountDueResponse[limit].nextDueDate;
        //   obj.Amount=GetPaymentInfoResponse.amountDue.amountDueResponse[limit].amountDue;
        // }
        // else{
        //   let temp=limit;
        //   obj.DueDate=GetPaymentInfoResponse.amountDue.amountDueResponse[temp+1].nextDueDate;
        //   obj.Amount=GetPaymentInfoResponse.amountDue.amountDueResponse[temp+1].amountDue;
        //   temp=0;
        // }
        if (AmountDueArray[IndexOfPay].initialPaymentFlag === 0 || (exchangeAgreementId && AmountDueArray[IndexOfPay]?.agreementId == agreementId)) {
          ListOfAgreementsArray.push(obj);
        }
      }
    }
    let isClubIncluded = false;
    let allowClubForEnrollment = false;
    const ClubObj: any = {}
    const AmountDueArray: any = GetPaymentInfoResponse.amountDue.amountDueResponse
    if(GetPaymentInfoResponse?.paymentInfo?.clubInformation && GetPaymentInfoResponse?.paymentInfo?.clubInformation?.length) {
      allowClubForEnrollment = (!PropsIsManageCardPopup && GetPaymentInfoResponse.paymentInfo.clubInformation[0].autopayEnrollmentDate == null) ||
      (PropsIsManageCardPopup && GetPaymentInfoResponse.paymentInfo.clubInformation[0].autopayEnrollmentDate) ? true : false;
    }
    for (let i = 0; i <= AmountDueArray.length - 1; i++) {
      if (AmountDueArray[i].clubAmountDue !== undefined && AmountDueArray[i].initialPaymentFlag === 0 && allowClubForEnrollment && 
        AmountDueArray[i].storeNumber == String(window.sessionStorage.getItem('storeNumber'))) {
        const objClubInfo = GetPaymentInfoResponse.paymentInfo.clubInformation[0];
        // ClubObj.AgreementId = objClubInfo.clubMembershipNumber !== undefined ? String(objClubInfo.clubMembershipNumber) : 0;
        ClubObj.AgreementId = 'Club';
        ClubObj.Description = 'Club';
        ClubObj.Schedule = GetPaymentInfoResponse.paymentInfo.clubDaysLateAndPaymentSchedule[0].paymentSchedule;
        ClubObj.DueDate = AmountDueArray[i].pastDueDate;
        const TaxAmount = Number(AmountDueArray[i].clubTax);
        const caculatedScheduleRates = calculateSchduleRates(AmountDueArray[i].agreementRateDetails, 'CLUB');
        ClubObj.Amount = Number(caculatedScheduleRates.toFixed(2));
        isClubIncluded = true;
      }
    }
    if (isClubIncluded) {
      ListOfAgreementsArray.push(ClubObj);
    }

    console.log('ListOfAgreementsArray', ListOfAgreementsArray, CheckedAgreementIds);
    setListOfAgreements(ListOfAgreementsArray);
    const FalseArray: any = new Array(ListOfAgreementsArray.length).fill(false);
    const FalseArr: any = new Array(ListOfAgreementsArray.length).fill(true);
    const IndexOfSchedule = ListOfAgreementsArray.findIndex(obj => obj.AgreementId === CheckedAgreementIds[0]);
    debugger
    if (IndexOfSchedule == 0 || IndexOfSchedule > 0) {
      const SelectedSchedule = ListOfAgreementsArray[IndexOfSchedule].Schedule;
      const TempArr: any = [];
      for (let i = 0; i < ListOfAgreementsArray.length; i++) {
        if (ListOfAgreementsArray[i].Schedule == SelectedSchedule) {
          TempArr.push(i)
        }
      }
      for (let i = 0; i < TempArr.length; i++) {
        FalseArr[TempArr[i]] = false;
      }
      setListOfAgrDisableArray(FalseArr);
    }

    console.log('Check box condtion in initial', FalseArray);
    let updatedCheckedState = [];

    debugger;

    const AgreementIDs = ListOfAgreementsArray.map((ele: any) => {

      return ele.AgreementId;

    });
    let selectAgrIndex: any = undefined;
    for (let index = 0; index <= CheckedAgreementIds.length - 1; index++) {
      const clubList = ListOfAgreementsArray.filter((obj: any) => obj.AgreementId === String(CheckedAgreementIds[index]));
      if (clubList[0]?.Description == 'Club') {
        selectAgrIndex = index;
      }
      const indexOfCheckedArr = AgreementIDs.indexOf(CheckedAgreementIds[index]);

      const ReplacedArray = FalseArray.splice(indexOfCheckedArr, 1, true);

      updatedCheckedState = FalseArray;

    }
    console.log('CheckedFalseArr', updatedCheckedState);
    if (updatedCheckedState.length > 0) {
      setListOfAgrChkArray(updatedCheckedState);
    }
    else {
      setListOfAgrChkArray(FalseArray);
    }
    const TempResponse: any = ResponseToBeSent;
    TempResponse.AgreementIds = CheckedAgreementIds;
    setResponseToBeSent(TempResponse);
    if (selectAgrIndex == undefined)
      setSelectedAgreementIds(CheckedAgreementIds)
    else
      setSelectedAgreementIds(CheckedAgreementIds.splice(selectAgrIndex, 1))
  }, [ClearButtonClicked])


  const calculateSchduleRates = (rateDetails: any, type: any) => {
    let autoPayAmountWithTax = 0;
    const scheduleRefCode = rateDetails.scheduleRefCode.includes("SEMI") ? "Semi-Monthly" :
      rateDetails.scheduleRefCode === "MON" ? "Monthly" :
        rateDetails.scheduleRefCode === "BIWK" ? "Bi-Weekly" :
          rateDetails.scheduleRefCode === "WK" ? "Weekly" :
            "";

    if (type == 'AGR') {
      switch (scheduleRefCode) {
        case 'Weekly':
          autoPayAmountWithTax = (Number(rateDetails.weeklyRent) > 0 ?
            Number(rateDetails.weeklyRent) : 0) +
            (Number(rateDetails.weeklyRentTax) > 0 ?
              Number(rateDetails.weeklyRentTax) : 0) +
            (Number(rateDetails.weeklyLdw) > 0 ?
              Number(rateDetails.weeklyLdw) : 0) +
            (Number(rateDetails.weeklyLdwTax) > 0 ?
              Number(rateDetails.weeklyLdwTax) : 0) +
            (Number(rateDetails.weeklyPolicy) > 0 ?
              Number(rateDetails.weeklyPolicy) : 0) +
            (Number(rateDetails.weeklyPolicyTax) > 0 ?
              Number(rateDetails.weeklyPolicyTax) : 0)
          break;
        case 'Bi-Weekly':
          autoPayAmountWithTax = (Number(rateDetails.biWeeklyRent) > 0 ?
            Number(rateDetails.biWeeklyRent) : 0) +
            (Number(rateDetails.biWeeklyRentTax) > 0 ?
              Number(rateDetails.biWeeklyRentTax) : 0) +
            (Number(rateDetails.biWeeklyLdw) > 0 ?
              Number(rateDetails.biWeeklyLdw) : 0) +
            (Number(rateDetails.biWeeklyLdwTax) > 0 ?
              Number(rateDetails.biWeeklyLdwTax) : 0) +
            (Number(rateDetails.biWeeklyPolicy) > 0 ?
              Number(rateDetails.biWeeklyPolicy) : 0) +
            (Number(rateDetails.biWeeklyPolicyTax) > 0 ?
              Number(rateDetails.biWeeklyPolicyTax) : 0)
          break;
        case 'Monthly':
          autoPayAmountWithTax = (Number(rateDetails.monthlyRent) > 0 ?
            Number(rateDetails.monthlyRent) : 0) +
            (Number(rateDetails.monthlyRentTax) > 0 ?
              Number(rateDetails.monthlyRentTax) : 0) +
            (Number(rateDetails.monthlyLdw) > 0 ?
              Number(rateDetails.monthlyLdw) : 0) +
            (Number(rateDetails.monthlyLdwTax) > 0 ?
              Number(rateDetails.monthlyLdwTax) : 0) +
            (Number(rateDetails.monthlyPolicy) > 0 ?
              Number(rateDetails.monthlyPolicy) : 0) +
            (Number(rateDetails.monthlyPolicyTax) > 0 ?
              Number(rateDetails.monthlyPolicyTax) : 0)
          break;
        case 'Semi-Monthly':
          autoPayAmountWithTax = (Number(rateDetails.semiMonthlyRent) > 0 ?
            Number(rateDetails.semiMonthlyRent) : 0) +
            (Number(rateDetails.semiMonthlyRentTax) > 0 ?
              Number(rateDetails.semiMonthlyRentTax) : 0) +
            (Number(rateDetails.semiMonthlyLdw) > 0 ?
              Number(rateDetails.semiMonthlyLdw) : 0) +
            (Number(rateDetails.semiMonthlyLdwTax) > 0 ?
              Number(rateDetails.semiMonthlyLdwTax) : 0) +
            (Number(rateDetails.semiMonthlyPolicy) > 0 ?
              Number(rateDetails.semiMonthlyPolicy) : 0) +
            (Number(rateDetails.semiMonthlyPolicyTax) > 0 ?
              Number(rateDetails.semiMonthlyPolicyTax) : 0)
          break;
        default:
          autoPayAmountWithTax = 0
      }
    }
    if (type == 'CLUB') {
      switch (scheduleRefCode) {
        case 'Weekly':
          autoPayAmountWithTax = (Number(rateDetails.weeklyClub) > 0 ?
            Number(rateDetails.weeklyClub) : 0) +
            (Number(rateDetails.weeklyClubTax) > 0 ?
              Number(rateDetails.weeklyClubTax) : 0)

          break;
        case 'Bi-Weekly':
          autoPayAmountWithTax = (Number(rateDetails.biWeeklyClub) > 0 ?
            Number(rateDetails.biWeeklyClub) : 0) +
            (Number(rateDetails.biWeeklyClubTax) > 0 ?
              Number(rateDetails.biWeeklyClubTax) : 0)
          break;
        case 'Monthly':
          autoPayAmountWithTax = (Number(rateDetails.monthlyClub) > 0 ?
            Number(rateDetails.monthlyClub) : 0) +
            (Number(rateDetails.monthlyClubTax) > 0 ?
              Number(rateDetails.monthlyClubTax) : 0)
          break;
        case 'Semi-Monthly':
          autoPayAmountWithTax = (Number(rateDetails.semiMonthlyClub) > 0 ?
            Number(rateDetails.semiMonthlyClub) : 0) +
            (Number(rateDetails.semiMonthlyClubTax) > 0 ?
              Number(rateDetails.semiMonthlyClubTax) : 0)
          break;
        default:
          autoPayAmountWithTax = 0
      }
    }
    return autoPayAmountWithTax;
  }


  const AutoPayCloseClick = () => {
    setAutopayOpen(false)
    setAPpopupcompleted(Math.random());
    if(exchangeAgreementId){
      props?.setAutoPayLoader(true);
    }
  }
  const AutopayChkboxAction = () => {
    if (AutoPayCheckbox === true) {
      setAutoPayCheckbox(false)
      setSubmitButtonDisabeld(true);
    }
    else {
      setAutoPayCheckbox(true)
      setSubmitButtonDisabeld(false);
    }
  }
  const AutoPayNotnowClick = () => {
    setAutopayOpen(false);
    setAPpopupcompleted(Math.random());
    if(exchangeAgreementId){
      props?.setAutoPayLoader(true);
    }
  }
  const AutoPaySubmitClick = () => {
    debugger;
    console.log('Autopay submit triggered', ListOfAgrChkArray);

    const TempResponse: any = ResponseToBeSent;
    const TempArr: any = [];
    for (let i = 0; i < ListOfAgrChkArray.length; i++) {
      if (ListOfAgrChkArray[i] === true) {
        TempArr.push(i)
      }
    }
    const ListOfAgreementIds: any = [];
    for (let i = 0; i < TempArr.length; i++) {
      ListOfAgreementIds.push(ListOfAgreements[TempArr[i]].AgreementId);
    }

    console.log('ResponseToBearr', ListOfAgreementIds);
    console.log('Selected agreement IDS', SelectedAgreementIds);
    TempResponse.AgreementIds = ListOfAgreementIds;
    console.log('ResponseToBeSent', TempResponse);
    setAutopayOpen(false);
    setResponseObject(TempResponse);
    setAPpopupcompleted(Math.floor(Math.random() * 10) + 1);
    if(exchangeAgreementId){
      props?.setAutoPayLoader(true);
    }
  }

  const ListOfAgreementsBind = (Value: any) => (
    <>
      {Value != undefined && Value.length > 0 ?
        (Value.map((option: any, index: any) => {
          return (
            <RACTableRow key={index} className={classes.racTable}>
              <RACTableCell className={classes.checkboxwidth}>
                <RACCheckBox
                  //className={`${classes.formcheckinput} ${classes.checkboxStyle}`}
                  checked={ListOfAgrChkArray[index]}
                  disabled={ListOfAgrDisableArray[index]}
                  onChange={() => AgrChkBoxOnchange(index, option.Schedule)}
                />
              </RACTableCell>
              <RACTableCell>{option.Description}</RACTableCell>
              <RACTableCell>{option.Amount}</RACTableCell>
              <RACTableCell>{option.Schedule}</RACTableCell>
              <RACTableCell>{option.DueDate}</RACTableCell>
            </RACTableRow>
          )
        })
        ) : null
      }

    </>
  )
  function ListOfCardsBind(Value: any) {
    // if (Value.length > 0) {
    return Value.map((option: any, index: number) => {
      return (
        <Grid item key={index}>
          {PropsIsManageCardPopup ? <RACRadio
            //className={classes.formcheckinput}
            name="flexRadioDefault"
            id=""
            value={ListOfCardsChkArray[index]}
            checked={ListOfCardsChkArray[index] ? true : false}
            onChange={() => cardsRadioOnchange(index)}
          /> : <RACCheckBox
            //className={classes.formcheckinput}
            name="flexRadioDefault"
            id=""
            value={ListOfCardsChkArray[index]}
            disabled={ListOfCardsDisableArray[index]}
            onChange={() => CardsChkBoxOnchange(index)}
          />}
          <Box component="span" className={classes.mb1} style={{ marginLeft: '5px' }}>
            xxxx-xxxx-xxxx-{option.value}
          </Box>
        </Grid>
      );
    });
  }
  const CardsChkBoxOnchange = (index: any) => {
    const TempResponse: any = ResponseToBeSent;
    const AlreadyChecked = ListOfCardsChkArray.includes(true);
    if (AlreadyChecked === false) {
      setAutopayBtnText('Send Text');
      console.log('in AlreadyChecked false')
      const updatedRadioState = ListOfCardsChkArray.map((item, position) =>
        index === position ? !item : item
      );
      const updatedDisableState = ListOfCardsDisableArray.map((item, position) =>
        index === position ? item : !item);
      setListOfCardsChkArray(updatedRadioState);
      setListOfCardsDisableArray(updatedDisableState);

      TempResponse.customerPaymentMethodId = GetCardResponse.cardResp[index].customerPaymentMethodId;
      console.log('ResponseToBeSent', ResponseToBeSent);
      setResponseToBeSent(TempResponse);
    }
    else {
      console.log('in AlreadyChecked false');
      setAutopayBtnText('Send Autopay Signup Text');
      const CardsFalseArray: any = new Array(ListOfCardsChkArray.length).fill(false);
      setListOfCardsChkArray(CardsFalseArray);
      setListOfCardsDisableArray(CardsFalseArray);
      TempResponse.customerPaymentMethodId = '';
      console.log('ResponseToBeSent', ResponseToBeSent);
      setResponseToBeSent(TempResponse);
    }


  }

  const cardsRadioOnchange = (index: any) => {
    debugger;
    const TempResponse: any = ResponseToBeSent;
    const updatedRadioState = ListOfCardsChkArray.map((item, position) => index === position ? !item : false);
    setListOfCardsChkArray(updatedRadioState);
    setAutopayBtnText('Send Text');

    if (updatedRadioState[index]) {
      TempResponse.customerPaymentMethodId = GetCardResponse.cardResp[index].customerPaymentMethodId;
      console.log('ResponseToBeSent', ResponseToBeSent);
      setResponseToBeSent(TempResponse);
    }
  }
  const AgrChkBoxOnchange = (index, schedule) => {
    debugger;
    //const TempResponse: any = ResponseToBeSent;
    const ArrayOfAgreementIds: any = SelectedAgreementIds;
    const updatedCheckedState = ListOfAgrChkArray.map((item, position) =>
      index === position ? !item : item
    );
    setListOfAgrChkArray(updatedCheckedState);
    const SelectedItem = ListOfAgreements[index].AgreementId;
    const Description = ListOfAgreements[index].Description;
    if (Description !== 'Club') {
      const ItemAlreadyAvailable = ArrayOfAgreementIds.includes(SelectedItem);
      console.log('CheckarrCondition', ItemAlreadyAvailable);
      if (ItemAlreadyAvailable === false) {
        ArrayOfAgreementIds.push(ListOfAgreements[index].AgreementId);
        //TempResponse.AgreementIds = ArrayOfAgreementIds;
        console.log('ChoosenAgreementIds', ArrayOfAgreementIds)
        //setResponseToBeSent(TempResponse);
      }
      else {
        const UnselectedAgreementId = ListOfAgreements[index].AgreementId;
        const IndexOfUnSelect = ArrayOfAgreementIds.indexOf(UnselectedAgreementId);
        ArrayOfAgreementIds.splice(IndexOfUnSelect);
        // TempResponse.AgreementIds = ArrayOfAgreementIds;
        // setResponseToBeSent(TempResponse);
      }
      setSelectedAgreementIds(ArrayOfAgreementIds);
    }
    else {
      if (IsClubSelectedForAutopay === false) {
        setIsClubSelectedForAutopay(true)
      }
      else {
        setIsClubSelectedForAutopay(false)
      }
    }
    const TempArr: any = [];
    for (let i = 0; i < ListOfAgreements.length; i++) {
      if (ListOfAgreements[i].Schedule == schedule) {
        TempArr.push(i)
      }
    }
    const AlreadySelectedDisable = updatedCheckedState.includes(true);
    if (AlreadySelectedDisable) {
      const updatedDisabledState = PropsIsManageCardPopup ? new Array(ListOfAgreements.length).fill(true) : new Array(ListOfAgrDisableArray.length).fill(true);
      console.log('TempArr', TempArr);
      for (let i = 0; i < TempArr.length; i++) {
        updatedDisabledState[TempArr[i]] = false;
      }
      setListOfAgrDisableArray(updatedDisabledState);
    }
    else {
      const updatedDisabledState = new Array(ListOfAgrDisableArray.length).fill(false);
      setListOfAgrDisableArray(updatedDisabledState);
    }


  }
  const AutoPayClearClick = () => {
    setResponseToBeSent({});
    if (ClearButtonClicked === false) {
      setClearButtonClicked(true)
    }
    else {
      setClearButtonClicked(false)
    }
  }

  const autoPopUpHeadFields = [
    { display: '' },
    { display: 'Description' },
    { display: 'Amount' },
    { display: 'Schedule' },
    { display: 'Due Date' }
  ];

  const renderBindGridHead = () => (
    <>
      {autoPopUpHeadFields.map((val, index) => {
        return <RACTableCell className={classes.racTable} key={index}>{val.display}</RACTableCell>
      })}
    </>
  )

  const autoPayPopUp = () => {
    return (
      <>
        <Grid item className={classes.w100}>
          <Grid item className={classes.justifycontentcenter}>
            <Grid item className={classes.raccollg7}>
              <Typography variant="h4" className={`${classes.bold} ${classes.mb3} ${classes.floatLeft} ${classes.textcenter} ${classes.mb3}`}>
                Discuss the benefits of Autopay and enroll your customer
                today
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={`${classes.mb3} ${classes.justifycontentcenter}`}>
            <Grid item className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography className={`${classes.autopayValue} ${classes.me2}`}>1</Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Convenience
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Save Customer Time
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={`${classes.mb3} ${classes.justifycontentcenter}`}>
            <Grid className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography className={`${classes.autopayValue} ${classes.me2}`}>2</Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Zero Fees
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Avoid convenience/phone fees{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Typography className={`${classes.font14} ${classes.mb3}`}>
            Please select agreement(s)/Club to enroll in Autopay. Items must be on the same schedule and same Due Date. Autopay will be effective on the next Due Date.
          </Typography>
          <Grid item className={`${classes.raccollg12} ${classes.floatLeft}`}>


            <Grid item className={`${classes.w100} ${classes.mb3} ${classes.floatLeft}`}>

              <RACTable
                renderTableHead={renderBindGridHead}
                renderTableContent={() => ListOfAgreementsBind(ListOfAgreements)}
              />
            </Grid>

            <Grid item>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Grid item className={`${classes.w100} ${classes.font14} ${classes.mb3}`}>
                  {/* eslint-disable react/no-unescaped-entities */}
                  To enroll with a different schedule, use the 'Autopay Signup Text' button on the Accept Payment screen - 'Signup with Card on File' option.
                </Grid>

              </Grid>
            </Grid>
            <Grid item className={`${classes.justifycontentcenter}`} style={{ width: '100%' }}>
              <Grid item className={classes.raccollg5}>
                <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes.textcenter} ${classes.mb2}`}>
                  <Box component="label" className={classes.mb1} >Please select one Card on File</Box>
                </Grid>
                <Grid item className={`${classes.w100} ${classes.mb3} ${classes.textcenter}`}>
                  {ListOfCardsBind(ListOfCards)}
                </Grid>
              </Grid>
            </Grid>
            <Typography className={`${classes.w100} ${classes.textCenter} ${classes.font14} ${classes.mb3} ${classes.mandatoryfield}`}>Note:Only the last text message sent will be used for Autopay signup</Typography>
          </Grid>
          <Grid item className={`${classes.formcheck} ${classes.textcenter} ${classes.ps5} ${classes.mb3}`}>
            <RACCheckBox
              //className={`${classes.formcheckinput} ${classes.checkboxwidth} ${classes.checkboxStyle}`}
              id="flexCheckDefault"
              onClick={() => AutopayChkboxAction()}
              checked={AutoPayCheckbox}
              data-testid="AutopayCheckTest"
            />
            <Box component="label"
            //className={`${classes.formcheck}`}
            >
              Customer has verbally consented to receive this text
              message
            </Box>
          </Grid>
          <Typography className={`${classes.font14} ${classes.mb3} ${classes.textcenter}`}>
            Autopay will be associated with the Primary Renter
          </Typography>
          <Grid item className={`${classes.justifycontentcenter} ${classes.mb3}`}>
            <Grid item className={classes.raccollg7}>
              <Grid item className={`${classes.w100} ${classes.w100} ${classes.mb2}`}>
                <Box component="span" className={`${classes.me3} ${classes.font14}`}>
                  {' '}
                  Customer Name
                </Box>
                <Box component="span" className={`${classes.semibold} ${classes.font14}`}>
                  {' '}
                  {AutopayCustomerInfo.CustomerName}
                </Box>
              </Grid>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Box component="span" className={`${classes.w100} ${classes.me3} ${classes.font14}`}>
                  Phone Number
                </Box>
                <Box component="span" className={`${classes.semibold} ${classes.font14}`}>
                  {' '}
                  {AutopayCustomerInfo.CustomerPhoneNumber}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={`${classes.raccollg12} ${classes.py4} ${classes.textcenter}`}>

          {/* <RACButton
            className={`${classes.mx0} ${classes.clearbtn} ${classes.px1}`}
            data-bs-dismiss="modal"
            onClick={() => AutoPayClearClick()}
            data-testid="AutoPayNotnowTest"
          >
            Clear
          </RACButton> */}

          <RACButton
            className={classes.mx1}
            color="primary"
            variant="outlined"
            data-bs-dismiss="modal"
            onClick={() => AutoPayNotnowClick()}
            data-testid="AutoPayNotnowTest"
          >
            Not now
          </RACButton>

          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#Customer-Signature"
            onClick={() => AutoPaySubmitClick()}
            disabled={SubmitButtonDisabeld}
            data-testid="SendTxtAsPaymentTest"
          >
            {AutopayBtnText}
          </RACButton>
        </Grid>

      </>
    )
  }

  return (
    <RACModalCard
      isOpen={AutopayOpen}
      maxWidth="sm"
      //title="Customer Alerts"
      onClose={() => AutoPayCloseClick()}
      closeIcon={true}
      // eslint-disable-next-line react/no-children-prop
      children={autoPayPopUp()}
    />
  )
}

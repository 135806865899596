/* eslint-disable*/

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-case-declarations  */
/* eslint-disable react/no-children-prop  */


/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */



/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */



/* eslint-disable sonarjs/prefer-single-boolean-return */


import {useEffect,useState} from 'react'
import React from 'react';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent,
    RACButton,
    RACSelect,
    RACTextbox,
} from '@rentacenter/racstrap';
import { ReactComponent as SearchImageDefault } from '../../../assets/images/search-btn.svg';

import { getSearchParams } from '../../../api/user';
import { GlobalStyles } from '../../../stylesJS/global';
import { customerStyles } from '../../../stylesJS/customerStyles';
import { searchCriteriaStyle } from '../../stylesJS/searchResult';
import { DropDownArray } from '../../interface/commonInterface';
import { iniTxtValues } from '../../interface/rentalItemInterface';

interface CriteriaSearchProps {
    searchclick: ({} : iniTxtValues) => void;
    TxtValues: iniTxtValues;
    setTxtValues: ({} : iniTxtValues) => void;
}



export default function Criteriasearch (props:CriteriaSearchProps)
{
     const classes1=searchCriteriaStyle();
     const classes = customerStyles();


   /* RIS_PC_NO_05 searchbtnFlag is declared for enabling and disabling the search button
      RIS_PC_NO_06  Creating iniTxValues object and assigning it to the state variable TxtValues
      and creating a state variable conditionLoader */

    const {TxtValues,setTxtValues}:CriteriaSearchProps=props
     const [searchbtnFlag,setsearchbtnFlag]=useState(true);
     const [dropdownArr,setdropdownArr]=useState<DropDownArray[]>([{label:'',value:''}])
    const [conditionLoader,setconditionLoader]=useState(true)

    /*RIS_PC_NO_07 Inside the the loaddata() is called  */

     useEffect(()=>{
        loaddata();
     },[])

     /**RIS_PC_NO_11 The useEffect is performed when the TxtValues is updated and it checks
      * whether the value is entered in the search criteria based on that the search button will be
      * disabled or enabled.
      *
      */

     useEffect(()=>{
        if(TxtValues.itemNumber!==""||TxtValues.modelNumber!==""||TxtValues.serialNumber!==""||
           TxtValues.conditionId!=="")
        {
            setsearchbtnFlag(false)
        }else{
            setsearchbtnFlag(true)
        }
     },[TxtValues])
     /**
      * RIS_PC_NO_07 The loaddata() function is performed and inside it getsearchParam() service call is made
      * RIS_PC_NO_08 The service call is performed and the data is fetched
      * RIS_PC_NO_09 By using the response received the dropdown array is updated.
      *
      */
     const loaddata=async()=>{

        setconditionLoader(true)
        const response=await getSearchParams();
        setconditionLoader(false)
        let condition=response.conditionList;
        let condArr=[{label:"Select",value:''}]
        condition.map((val)=>{
            if(val.referenceCode=="ATO")
            {let data={label:"Auto",value:val.id.toString()}

            condArr.push(data)

            }else if(val.referenceCode=="EXCP")
            {
                let data={label:"Exception",value:val.id.toString()}

            condArr.push(data)
            }else if(val.referenceCode=="NEW")
            {
                let data={label:"New",value:val.id.toString()}

                condArr.push(data)

            }

        })
        setdropdownArr(condArr);
     }

     /**
      * RIS_PC_NO_10 When the values entered in the text fields the searchcriteriahandlechange function is
      * performed.
      *
      */

     const searchcriteriahandlechange=(val:React.ChangeEvent<HTMLSelectElement>,name:string)=>{
        switch(name)
        {
            case "itemNumber":
                setTxtValues({...TxtValues,"itemNumber":val.target.value})
                break;
            case "modelNumber":
                setTxtValues({...TxtValues,"modelNumber":val.target.value})
                break;
            case "serialNumber":
                setTxtValues({...TxtValues,"serialNumber":val.target.value})
                break;
            case "condition":
                setTxtValues({...TxtValues,"conditionId":val.target.value})

        }

     }
     const EnterOnClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (
          e.key == 'Enter' &&
          (
            TxtValues.modelNumber.length > 0 ||
            TxtValues.itemNumber.length > 0 ||
            TxtValues.serialNumber.length > 0 ||
            TxtValues.conditionId !== ""

           )
        ) {
            props.searchclick(TxtValues)
        }
      };
     /**
      * RIS_PC_NO_12 when co-worker clicks the search button the searchbtnOnclick
      * function is performed.
      */

     const searchbtnOnclick=()=>{
    props.searchclick(TxtValues)
     }

    //RIS_PC_NO_05 Rendering the Search criteria, Item # text box ,Model text box , Serial # text box
    //  and condition dropdown
    return(

        <React.Fragment>
      <Grid container className={classes1.mt2} style={{marginTop:"0px"}}>
        <Grid item md={12}>
        <Typography
             variant="h1"
             style={{marginLeft:"2px"}}
             className={`${classes1.title1} ${classes1.mt3}`}>
                                    Search Criteria
                                </Typography>

          <Card className={classes1.card}>
            <CardContent>
              <Grid container spacing={2}>
                {/* <Grid item md={2}></Grid> */}
         {/* <RACTable
        className={classes1.tableStyle}
        // renderTableHead = {tableheader}
        renderTableContent= {tableContent}
        /> */}
         <Grid item md={2} style={{marginLeft:"1%"}}>
                  <RACTextbox
                    inputlabel="Item #"
                    inputLabelClassname={`${classes1.formLabel }`}
                    className={`${classes1.dollartextN}`}
                    type="number"
                    id="IemNo"
                    name="itemNumber"
                    isCurrency={false}
                    maxlength={50}
                    value={TxtValues.itemNumber}
                    OnChange={(e) => searchcriteriahandlechange(e,"itemNumber")}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2} style={{marginLeft:"2%"}}>
                  <RACTextbox
                    inputlabel="Model"
                    inputLabelClassname={classes1.formLabel}
                    className={`${classes1.dollartextN}`}
                    type="text"
                    id="ModelNo"
                    name="modelNumber"
                    isCurrency={false}
                    maxlength={50}
                    value={TxtValues.modelNumber}
                    OnChange={(e) => searchcriteriahandlechange(e,"modelNumber")}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2} style={{marginLeft:"2%"}}>
                  <RACTextbox
                    inputlabel="Serial #"
                    inputLabelClassname={classes1.formLabel}
                    className={`${classes1.dollartextN}`}
                    type="text"
                    id="serialNo"
                    name="serialNumber"
                    isCurrency={false}
                    maxlength={50}
                    value={TxtValues.serialNumber}
                    OnChange={(e) => searchcriteriahandlechange(e,"serialNumber")}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2}style={{marginLeft:"2%"}}>
                  <RACSelect
                    // classes={{nativeSelect:classes1.dropdown1}}
                    inputLabel="Condition"
                    inputLabelClassName={classes1.formLabelN}
                    name="condition"
                    loading={conditionLoader}
                    defaultValue={ TxtValues.conditionId }
                    onChange={(e:React.ChangeEvent<HTMLSelectElement>) => searchcriteriahandlechange(e,"condition")}
                    options={dropdownArr}
                  />
                </Grid>

                <Grid item md={2} style={{marginLeft:"-1%", marginTop:"-12px"}}>
                    <RACButton
                      disabled={searchbtnFlag}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{margin:"38px 0px 0px 40px"}}
                      className={`${classes.spacerMT2N} ${classes.spacerP1N}`}
                      onClick={() =>searchbtnOnclick()}
                    >
                      <SearchImageDefault
                        className={classes.racGlobalSearchBtn}
                      ></SearchImageDefault>
                      Search
                    </RACButton>
                  </Grid>
        </Grid>
        </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>

    )

}
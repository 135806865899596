/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable sonarjs/prefer-single-boolean-return */

import React, { useState, useEffect, useContext } from "react";
import {
  RACButton,
  Grid,
  RACModalCard,
  RACTextbox,
} from "@rentacenter/racstrap";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import SecondFactor from './TwoFactorPopup';
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import { useLocation } from "react-router-dom";
import calculationModulePayloadFormation from "./commonModulePayloadFormation";
import { calculatePaymentModule } from "../../api/user";
import { userTraceContextData } from "../../context/UserTraceContext";
const AcceptEpo = (props: any) => {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;
  const location = useLocation<any>();
  const urlParams = new URLSearchParams(window.location.search);
  const retentionType = urlParams.get("CRTYPE");
  const classes = takePaymentStyles();

  // const pastDueRent:any = "67.89";

  // interface acceptEpoCalucaltion{
  //      epoAmount: string,
  //      lateFee: string,
  //      taxValue: string,
  //      suspense: string
  // }

  // const acceptEpoValues: acceptEpoCalucaltion={
  //     lateFee: "0.00",
  //     suspense: "0.00",
  //     epoAmount: "",
  //     taxValue: ""

  // }
  // debugger;
  const [noteAgr,setNoteAgr]= useState(false)
  let [noteAgrinfo,setNoteAgrInfo]=useState<any>({
    noteAmount:String(Number(props.agreementChecked[0].agreementNoteDetails.editedNoteAmount)==0||String(Number(props.agreementChecked[0].agreementNoteDetails.editedNoteAmount))=='NaN'?props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount:props.agreementChecked[0].agreementNoteDetails.editedNoteAmount),
    agrAmount:(Number(props.info.epoamount)-Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount))<0?"0.00":
    (Number(props.info.epoamount)-Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount)).toFixed(2),
    noteAmountInitial:Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount)
               
  })
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [acceptSpinner,setacceptSpinner]=useState(false)
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState();
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [CurrentRole, setCurrentRole] = useState('');
  // debugger;
  const [epoPopupFields, setepoPopupFields] = useState({
      pastDueRent: (props.info.currentDaysLate > 0 ? props.info.amountDue : 0.00).toFixed(2),
      epoAmt: Number(props.info.epoamount)<0?"0.00":Number(props.info.epoamount).toFixed(2),
      taxAmt: Number(Number(props.info.taxAmount).toFixed(2)),
      total: String(Number((Number(Number(props.info.epoamount) + Number(props.info.currentDaysLate > 0 ? props.info.amountDue : 0.00) + Number(props.info.lateFee) + Number(props.info.taxAmount)) -
      Number(props.info.suspense))).toFixed(2)),
      editedNoteAmount:props.agreementChecked[0].agreementNoteDetails.editedNoteAmount
  });
  const [secondlevelepoeditcheck,setsecondlevelepoeditcheck]=useState<any>({
    pastDueRent: (props.info.currentDaysLate > 0 ? props.info.amountDue : 0.00).toFixed(2),
    epoAmt: props.info.epoamount=="-0.00"?"0.00":Number(props.info.epoamount).toFixed(2),
    taxAmt: Number(Number(props.info.taxAmount).toFixed(2)),
    total: String(Number((Number(Number(props.info.epoamount) + Number(props.info.currentDaysLate > 0 ? props.info.amountDue : 0.00) + Number(props.info.lateFee) + Number(props.info.taxAmount)) -
    Number(props.info.suspense))).toFixed(2)),
    editedNoteAmount:props.agreementChecked[0].agreementNoteDetails.editedNoteAmount
});
  const [amountCrossed,setamountCrossed] = useState<any>(false)
  const [amountCrossedepo, setamountCrossedepo]= useState<any>(false)
  const [EPODiscountCrosses , setEPODiscountCrossed]= useState<any>(false);
  const [acceptEPODisable, setacceptEPODisable]= useState<any>(false);
  const containerData = useContext(ContainerContext) as CustomPropInterface;

  // const total =
  //   Number((Number(Number(epoPopupFields.epoAmt) + Number(epoPopupFields.pastDueRent) + props.info.lateFee + epoPopupFields.taxAmt) -
  //   props.info.suspense).toFixed(2)); 


  useEffect(() => {
    // debugger;
    console.log("propsVal in AcceptEPO EPO", props);
    if(props.agreementChecked[0].agreementNoteDetails.isNoteAgreement=="1"){
      setNoteAgr(true)
    }
    console.log("Current", props.info.currentDaysLate);
    if (
      validateObject(props) &&
      validateObject(props.info) &&
      validateObject(props.info.epoamount) &&
      validateObject(props.info.lateFee) &&
      validateObject(props.info.suspense) &&
      validateObject(props.info.Taxamount)
    ) {
      // validateObject(props.info) && validateObject(props.info.lateFee) ? props.info.lateFee : '',
      // validateObject(props.info) && validateObject(props.info.suspense) ? props.info.suspense : '',
      // validateObject(props.info) && validateObject(props.info.Taxamount) ? props.info.Taxamount : ''
      //setEpoValue(props.info)
    }
  }, []);
  const onClickAcceptEPObtn = async() => {
    
    
    if(secondlevelepoeditcheck.pastDueRent==epoPopupFields.pastDueRent&&
      secondlevelepoeditcheck.epoAmt==epoPopupFields.epoAmt&&
      secondlevelepoeditcheck.taxAmt==epoPopupFields.taxAmt&&
      secondlevelepoeditcheck.total==epoPopupFields.total){
        if(noteAgr){
          epoPopupFields.epoAmt=noteAgrinfo.agrAmount
        }
        if(props.configFlags?.commonModuleFlag==1){
          props.checkedAgr[0].isAcceptEPOValue=props.checkedAgr[0].isAcceptEPODiscounts
          commonModuleAcceptEPOTrigger()
        }else{
          let traceObj={
            iD:Number(userTraceData?.length)+1,
            type:"AcceptEPO"
          }
          setUserTraceData([...userTraceData,traceObj])
          props.triggerTotalOnclick(epoPopupFields, false,noteAgrinfo);
        }
        
      
      }else{
        setacceptSpinner(true)
        setSecondFactorOpen(true)
      }
    
    // props.triggerTotalOnclick(epoPopupFields, false , noteAgrinfo);
  };
  const validateObject = (value: any) => {
    if (value !== undefined && value !== null && value !== "") {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    // debugger;
    if (TwoFactorCompleted === true) {
      setSecondFactorOpen(false);
      if(noteAgr){
        props.checkedAgr[0].isAcceptEPODiscounts.noteDiscount = Math.abs(Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount)-Number(noteAgrinfo.noteAmount)).toFixed(2)
        props.checkedAgr[0].isAcceptEPODiscounts.epoDiscount = Math.abs(Number(props.actualEpoAmount)-Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount)-Number(noteAgrinfo?.agrAmount)<0?(Number(props.actualEpoAmount)-Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount)):
        Number(props.actualEpoAmount)-Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount)-Number(noteAgrinfo?.agrAmount)).toFixed(2);
        props.checkedAgr[0].isAcceptEPODiscounts.pastDueDiscount = Math.abs(Number(props.actualPastDueRent)-Number(epoPopupFields.pastDueRent)).toFixed(2);
      }else{
        props.checkedAgr[0].isAcceptEPODiscounts.epoDiscount = Math.abs(Number(props.actualEpoAmount)-Number(epoPopupFields.epoAmt)).toFixed(2);
        props.checkedAgr[0].isAcceptEPODiscounts.pastDueDiscount = Math.abs(Number(props.actualPastDueRent)-Number(epoPopupFields.pastDueRent)).toFixed(2);
      }
      if(props.configFlags?.commonModuleFlag==1){
        props.checkedAgr[0].isAcceptEPOValue=props.checkedAgr[0].isAcceptEPODiscounts
        props.checkedAgr[0].isAcceptEPOValue={
          epoDiscount:Number(props.checkedAgr[0].isAcceptEPODiscounts?.epoDiscount).toFixed(2),
          pastDueDiscount:Number(props.checkedAgr[0].isAcceptEPODiscounts?.pastDueDiscount).toFixed(2),
          noteDiscount:Number(props.checkedAgr[0].isAcceptEPODiscounts?.noteDiscount).toFixed(2)
        }

        commonModuleAcceptEPOTrigger()
      }else{
        props.triggerTotalOnclick(epoPopupFields, false);
      }
     
    }
    else if (TwoFactorCancelClick === true) {
      // setload(false);
      setacceptSpinner(false)
      setSecondFactorOpen(false);
      setTwoFactorCancelClick(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TwoFactorCancelClick, TwoFactorCompleted, EmployeeDetails])

  const commonModuleAcceptEPOTrigger = async()=>{
    setacceptSpinner(true)
    let commonModulePayload = await calculationModulePayloadFormation( {
      customerId:props.customerId,
      storeNumber:props.storeNumber,
      checkedAgr:props.checkedAgr})
    let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
    props.checkedAgr[0].isAcceptEPOValue=undefined;
    commonModuleServiceRes=commonModuleServiceRes.data
    let commonModuleAgrRes =  commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
      (x) =>
        String(x.agreementId) ===
        String(props.checkedAgr[0].Agreementid)
    );
    setacceptSpinner(false)
    let epoAmount=commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory == "EPO"||x.itemCategory == "SAC"||x.itemCategory == "INEPO")
    let NoteAMount =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory == "INAGMEPO"||x.itemCategory == "INAGMSAC")
    let finalEPOAmount =Number(epoAmount[0]?.amount)>0&&Number(NoteAMount[0]?.amount)>0?(Number(epoAmount[0]?.amount)+Number(NoteAMount[0]?.amount)+Number(NoteAMount[0]?.tax)).toFixed(2):( epoAmount?.length>0?(epoAmount[0].amount):"0.00")
    let pastDueRent =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='LTRPAY')
    let pastDueRentDiscount=commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='LTRPAYDSCOUNT')
    let finalPastDueAmount = Number(pastDueRent[0]?.amount)>0&&Number(pastDueRentDiscount[0]?.amount)>0?(Number(pastDueRent[0]?.amount)-Number(pastDueRentDiscount[0]?.amount)).toFixed(2):(pastDueRent?.length>0?(pastDueRent[0].amount):"0.00")
    const epoCompRes={
      epoAmt: finalEPOAmount,
      pastDueRent:finalPastDueAmount,
      taxAmt:commonModuleAgrRes[0].totalTax,
      total: 
        (Number(commonModuleAgrRes[0].totalAmount)+Number(commonModuleAgrRes[0].totalTax)-Number(props.checkedAgr[0].SuspenseAmount)).toFixed(2)
      ,
      editedNoteAmount:epoPopupFields.editedNoteAmount
      
    }
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"AcceptEPO"
    }
    setUserTraceData([...userTraceData,traceObj])
    props.triggerTotalOnclick(epoCompRes, false ,noteAgrinfo);
  }
  useEffect(() => {
    const GettingRole = async () => {
      if(containerData != undefined){
        const GetMenuDetails: any = containerData.GetRole();
        console.log('GetMenuDetails', GetMenuDetails);
        // const GetMenuDetailsResponse = GetMenuDetails.data;
        const currentRole = GetMenuDetails;
        console.log('currentRole', currentRole);
        setCurrentRole(currentRole);
      }
        // if (GetMenuDetails.status === 200) {
          
        // }
      // const GetcurrentUser = await GetEmployeeID();
      // console.log('GetcurrentUser', GetcurrentUser);
      // if (GetcurrentUser.status === 200) {
      //   const GetcurrentUserResponse = GetcurrentUser.data;
      //   const EmployeeId = GetcurrentUserResponse.employeeId;
      //   const GetMenuPayload = {
      //     coworkerId: EmployeeId,
      //   };
        
      // }
    };
    GettingRole();
  }, [containerData]);
  const acceptEpoOnchange = (e) => {
    const epoDetails = {
      epoAmt: epoPopupFields.epoAmt,
      pastDueRent: epoPopupFields.pastDueRent,
      taxAmt: epoPopupFields.taxAmt,
      total:epoPopupFields.total,
      editedNoteAmount:epoPopupFields.editedNoteAmount
    };
    let notAgrInfoOnchange:any=noteAgrinfo
    // eslint-disable-next-line no-empty
    console.log(e.target, "on change details");
    const AmtTargetValue = String(e.target.value.replace(/,/g, ''));
    let epoError = false;
    let pastDueError = false;
    let epoDiscountError = false;
    if (e.target.name == "epoAmtBox") {
      if(parseFloat(AmtTargetValue) > parseFloat(props.actualEpoAmount)){
        setamountCrossedepo(true);
        epoError = true;
      } else if (retentionType && retentionType == 'EPO' && (Number(props.actualEpoAmount) - Number(AmtTargetValue) > Number(location?.state?.retentionData?.epoDiscount))) {
        epoDiscountError = true;
        setEPODiscountCrossed(true);
        epoError = false;
        setamountCrossedepo(false);
      }
      else {
        setamountCrossedepo(false);
        setEPODiscountCrossed(false);
        epoError = false;
        epoDiscountError = false;
      }
      epoDetails.epoAmt = AmtTargetValue;
    //  debugger
      if(noteAgr){
        let agrAmount=(Number( epoDetails.epoAmt)-Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount))<=0?"0.00":(Number( epoDetails.epoAmt)-Number(props.agreementChecked[0].agreementNoteDetails?.remainingNoteCostAmount)).toFixed(2)
        let noteAmount="";
        if(agrAmount=="0.00"){
          noteAmount = ( (Number( epoDetails.epoAmt))).toFixed(2)
        }
        notAgrInfoOnchange={
          agrAmount:String(agrAmount),
          noteAmount:noteAmount==""?props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount:noteAmount
        }
        setNoteAgrInfo({
          agrAmount:String(agrAmount),
          noteAmount:noteAmount==""?props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount:noteAmount
        })
        
      }
      // setepoPopupFields(epoPopupFields); 
    }
    if (e.target.name == "pastDueRentAmtBox") {
      if(parseFloat(AmtTargetValue) > parseFloat(props.actualPastDueRent)){
        setamountCrossed(true);
        pastDueError = true;
      } else {
        setamountCrossed(false);
        pastDueError = false;
      }
      epoDetails.pastDueRent = AmtTargetValue;
      
    }
    if(epoError || pastDueError || epoDiscountError){
      setacceptEPODisable(true);
    }
    else{
      setacceptEPODisable(false);
    }
    if(noteAgr){
      const totalWithOutTax = Number((Number(Number(notAgrInfoOnchange.agrAmount) + Number(epoDetails.pastDueRent))).toFixed(2)); 
      const lateFeetax=Number(Number(props.info.lateFee)*Number(props.agreementChecked[0].lateFeeTaxRate))
      epoDetails.taxAmt = Number(Number((totalWithOutTax * props.info.taxRate)+Number(lateFeetax)).toFixed(2));
      epoDetails.total = (Number(Number(epoDetails.epoAmt) + Number(epoDetails.pastDueRent) + Number(props.info.lateFee) + Number(epoDetails.taxAmt)) -
      Number(props.info.suspense)).toFixed(2);
      epoDetails.editedNoteAmount=Number(props.agreementChecked[0].agreementNoteDetails.remainingNoteCostAmount).toFixed(2)==Number(notAgrInfoOnchange.noteAmount).toFixed(2)?"0.00":Number(notAgrInfoOnchange.noteAmount).toFixed(2)
      setepoPopupFields(epoDetails);
    }else{
      const totalWithOutTax = Number((Number(Number(epoDetails.epoAmt) + Number(epoDetails.pastDueRent))).toFixed(2));
      const lateFeetax=Number(Number(props.info.lateFee)*Number(props.agreementChecked[0].lateFeeTaxRate)) 
    epoDetails.taxAmt =Number(Number((totalWithOutTax * props.info.taxRate)+Number(lateFeetax)).toFixed(2));
    epoDetails.total = (Number(Number(epoDetails.epoAmt) + Number(epoDetails.pastDueRent) + Number(props.info.lateFee) + Number(epoDetails.taxAmt)) -
    Number(props.info.suspense)).toFixed(2);
    setepoPopupFields(epoDetails);
    }
   
   
  };
  

  const acceptEpoOnBlur = (e) => {
    // debugger;
    const epoDetails = {
      epoAmt: Number(epoPopupFields.epoAmt).toFixed(2),
      pastDueRent: Number(epoPopupFields.pastDueRent).toFixed(2),
      taxAmt: epoPopupFields.taxAmt,
      total:epoPopupFields.total,
      editedNoteAmount:epoPopupFields.editedNoteAmount
    };
    setepoPopupFields(epoDetails);

    // let notAgrInfoOnchange:any=noteAgrinfo
    // // eslint-disable-next-line no-empty
    // console.log(e.target, "on change details");
    // const AmtTargetValue = String(e.target.value.replace(/,/g, ''));
    // let epoError = false;
    // let pastDueError = false;
    // if (e.target.name == "epoAmtBox") {
    //   if(parseFloat(AmtTargetValue) > parseFloat(props.actualEpoAmount)){
    //     setamountCrossedepo(true);
    //     epoError = true;
    //   }
    //   else {
    //     setamountCrossedepo(false);
    //     epoError = false;
    //   }
    //   epoDetails.epoAmt = AmtTargetValue;
    // //  debugger
    //   if(noteAgr){
    //     let agrAmount=(Number( epoDetails.epoAmt)-Number(props.agreementChecked[0].agreementNoteDetails.noteAmountDueIncTax))<=0?"0.00":(Number( epoDetails.epoAmt)-Number(noteAgrinfo?.noteAmount)).toFixed(2)
    //     let noteAmount="";
    //     if(agrAmount=="0.00"){
    //       noteAmount = ( (Number( epoDetails.epoAmt))).toFixed(2)
    //     }
    //     notAgrInfoOnchange={
    //       agrAmount:String(agrAmount),
    //       noteAmount:noteAmount==""?props.agreementChecked[0].agreementNoteDetails.noteAmountDueIncTax:noteAmount
    //     }
    //     setNoteAgrInfo({
    //       agrAmount:String(agrAmount),
    //       noteAmount:noteAmount==""?props.agreementChecked[0].agreementNoteDetails.noteAmountDueIncTax:noteAmount
    //     })
        
    //   }
    //   // setepoPopupFields(epoPopupFields); 
    // }
    // if (e.target.name == "pastDueRentAmtBox") {
    //   if(parseFloat(AmtTargetValue) > parseFloat(props.actualPastDueRent)){
    //     setamountCrossed(true);
    //     pastDueError = true;
    //   }
      
    //   else {
    //     setamountCrossed(false);
    //     pastDueError = false;
    //   }
    //   epoDetails.pastDueRent = AmtTargetValue;
      
    // }
    // if(epoError || pastDueError){
    //   setacceptEPODisable(true);
    // }
    // else{
    //   setacceptEPODisable(false);
    // }
    // if(noteAgr){
    //   const totalWithOutTax = Number((Number(Number(notAgrInfoOnchange.agrAmount) + Number(epoDetails.pastDueRent) + props.info.lateFee)).toFixed(2)); 
    //   epoDetails.taxAmt = Number((totalWithOutTax * props.info.taxRate).toFixed(2));
    //   epoDetails.total = (Number(Number(epoDetails.epoAmt) + Number(epoDetails.pastDueRent) + Number(props.info.lateFee) + Number(epoDetails.taxAmt)) -
    //   Number(props.info.suspense)).toFixed(2);
    //   epoDetails.editedNoteAmount=notAgrInfoOnchange.noteAmount
    //   setepoPopupFields(epoDetails);
    // }else{
    //   const totalWithOutTax = Number((Number(Number(epoDetails.epoAmt) + Number(epoDetails.pastDueRent) + props.info.lateFee)).toFixed(2)); 
    // epoDetails.taxAmt = Number((totalWithOutTax * props.info.taxRate).toFixed(2));
    // epoDetails.total = (Number(Number(epoDetails.epoAmt) + Number(epoDetails.pastDueRent) + Number(props.info.lateFee) + Number(epoDetails.taxAmt)) -
    // Number(props.info.suspense)).toFixed(2);
    // setepoPopupFields(epoDetails);
    // }
  };



  const acceptEpoPopup = () => {
    return (
      <Grid container spacing={2} className={classes.px3}>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Past Due Rent
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={
                epoPopupFields.pastDueRent
              }
              disabled={props.info.currentDaysLate > 0 ? false : true}
              // value={errorCheck}
              OnChange={(e) => acceptEpoOnchange(e)}
              Onblur={(e)=>acceptEpoOnBlur(e)}
              data-testid="amounttextbox"
              name="pastDueRentAmtBox"
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={amountCrossed ? `New Amount can't exceed ${props.actualPastDueRent}` : ''}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            EPO
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={
                epoPopupFields.epoAmt
              }
              OnChange={(e) => acceptEpoOnchange(e)}
              Onblur={(e)=>acceptEpoOnBlur(e)}
              data-testid="amounttextbox"
              type={"number"}
              name="epoAmtBox"
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={amountCrossedepo ? `New Amount can't exceed ${Number(props.actualEpoAmount).toFixed(2)}`: EPODiscountCrosses ? `New Amount can't be less than ${(Number(props.actualEpoAmount) - Number(location?.state?.retentionData?.epoDiscount)).toFixed(2)}`:''}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Late Fee
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={props.info.lateFee}
              data-testid="amounttextbox"
              disabled={true}
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Tax
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={Number(epoPopupFields.taxAmt).toFixed(2)}
              data-testid="amounttextbox"
              disabled={true}
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Suspense
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={props.info.suspense}
              data-testid="amounttextbox"
              disabled={true}
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>
        {noteAgr?
         <><Grid
            item
            className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
          >
            <label
              htmlFor="a11y_takehome"
              className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
            >
              Note Amount (Inc.Tax)
            </label>
            <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={Number(noteAgrinfo?.noteAmount).toFixed(2)}
                data-testid="amounttextbox"
                disabled={true}
                type={"number"}
                digitFormat={"currency"}
                maxlength={10} />
            </Grid>
          </Grid><Grid
            item
            className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
          >
              <label
                htmlFor="a11y_takehome"
                className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
              >
                Agreement Cost
              </label>
              <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
                <RACTextbox
                  isCurrency={true}
                  className={`${classes.custDigit} ${classes.cashtxtbox}`}
                  dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                  value={noteAgrinfo?.agrAmount}
                  data-testid="amounttextbox"
                  disabled={true}
                  type={"number"}
                  digitFormat={"currency"}
                  maxlength={10} />
              </Grid>
            </Grid></>:null}
        
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Total
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              disabled={true}
              value={Number(epoPopupFields.total)<0?"("+Number(Number(epoPopupFields.total)*-1).toFixed(2)+")":Number(epoPopupFields.total).toFixed(2)}
              data-testid="amounttextbox"
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="outlined"
            name="De_ActivateClubbtn"
            data-bs-toggle="modal"
            style={{
              backgroundColor: "white",
              color: "#2C3333",
            }}
            onClick={props.triggercloseEPOModal}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            name="De_ActivateClubbtn"
            data-bs-toggle="modal"
            loading={acceptSpinner}
            disabled={acceptEPODisable}
            onClick={onClickAcceptEPObtn}
          >
            Accept
          </RACButton>
          {SecondFactorOpen === true ? <SecondFactor setTwoFactorCancelClick={setTwoFactorCancelClick}
        setTwoFactorCompleted={setTwoFactorCompleted}
        setEmployeeDetails={setEmployeeDetails}
        // storeAccessResponse={profileAccResp}
        moduleName={"Accept Epo"}
        CurrentRole={CurrentRole} /> : null}
         {/* <RACModalCard
        isOpen={load}
        maxWidth="xs"
        onClose={() => setload(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopup()}
      /> */}
        </Grid>
      </Grid>
    );
  };

  return (
    <RACModalCard
      isOpen={true}
      maxWidth="xs"
      title="Accept EPO"
      onClose={props.triggercloseEPOModal}
      closeIcon={true}
      borderRadius="20px"
      /* eslint-disable react/no-children-prop */
      // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
      children={acceptEpoPopup()}
    />
  );
};

export default AcceptEpo;

/* eslint-disable */

export default function validateAddressFn(response: any, addressDr ?: any, onlyZipStateCountry ?: boolean, request? : any){
    const verdict= response.result.verdict;
    const address = response.result.address;
    const confirmedComponents =["postal_code","locality","administrative_area_level_1"];
    const confirmationComp = ['CONFIRMED','UNCONFIRMED_BUT_PLAUSIBLE'];
    console.log(request.Addresses.StateProvince, "request.Addresses.StateProvince");
    
    const validatAddressComponent =()=>{
        return (address.addressComponents.filter((item : any)=>  confirmedComponents.includes(item.componentType))).every((item : any)=>{
            return confirmedComponents.includes(item.componentType) && confirmationComp.includes(item.confirmationLevel)
          })
    }
    const formatgoooleAddress = () =>{
        const state = address.addressComponents.filter((item:any)=>{
            return item.componentType == 'administrative_area_level_1' ? true : false
    })
    address.postalAddress.administrativeArea = state[0].componentName.text ? state[0].componentName.text : request.Addresses.StateProvince;
    // if(request.validateAddress.addressLines.length ==4)
    //     address.postalAddress.addressLines =[request.validateAddress.addressLines[0]]
    // else if(request.validateAddress.addressLines.length ==5)
    // address.postalAddress.addressLines =[request.validateAddress.addressLines[0],request.validateAddress.addressLines[1]]
    return {
        message:'',
        status : true,
        suggestedAddress : address.formattedAddress,
        postalAddress : address.postalAddress
    }
    }
    try{
    if(onlyZipStateCountry){

        if(address.unconfirmedComponentTypes && address.unconfirmedComponentTypes.some ((item : any)=>{
            return confirmedComponents.includes(item)
        }) && !validatAddressComponent())
        {
            return {
                status : false, 
                message : 'Address could not be corrected. Please enter the valid address.'
            }
        }
        else if(!(address.unconfirmedComponentTypes && address.unconfirmedComponentTypes.some ((item : any)=>{
            return confirmedComponents.includes(item)
        }) && !validatAddressComponent() )&& (addressDr?.Addresses && (addressDr.Addresses.MatchCode == '1' || addressDr.Addresses.MatchCode == '2'))){
            const addressDoctorResponse = addressDr.Addresses
            address.formattedAddress = addressDoctorResponse.StreetAddress
            address.postalAddress.addressLines = [  ]
            if(addressDoctorResponse.Building){
                address.postalAddress.addressLines.push(addressDoctorResponse.Building)
                address.formattedAddress += `, ${addressDoctorResponse.Building}`}
            if(addressDoctorResponse.Unit){
                address.postalAddress.addressLines.push(addressDoctorResponse.Unit)
                address.formattedAddress += `, ${addressDoctorResponse.Unit}`
            }
            address.postalAddress.addressLines.push(addressDoctorResponse.StreetAddress)
                address.formattedAddress += `, ${ addressDoctorResponse.City}, ${ addressDoctorResponse.StateProvince}, ${ address.postalAddress.postalCode}`
                
            return {
                message :'',
                status :true,
                matchCode: addressDr.Addresses.MatchCode,
                suggestedAddress : address.formattedAddress,
                postalAddress : address.postalAddress
            }
        }
        else if(!addressDr && !(address.unconfirmedComponentTypes && address.unconfirmedComponentTypes.some ((item : any)=>{
            return confirmedComponents.includes(item)
        }) && !validatAddressComponent())){
            return formatgoooleAddress();
        }
        else{
            const addressDoctorResponse = addressDr.Addresses
            address.formattedAddress = addressDoctorResponse.StreetAddress
            address.postalAddress.addressLines = [  ]
            if(addressDoctorResponse.Building){
                address.postalAddress.addressLines.push(addressDoctorResponse.Building)
                address.formattedAddress += `, ${addressDoctorResponse.Building}`}

            if(addressDoctorResponse.Unit){
                address.postalAddress.addressLines.push(addressDoctorResponse.Unit)
                address.formattedAddress += `, ${addressDoctorResponse.Unit}`
            }
            address.postalAddress.addressLines.push(addressDoctorResponse.StreetAddress)
                address.formattedAddress += `, ${ addressDoctorResponse.City}, ${ addressDoctorResponse.StateProvince}, ${ address.postalAddress.postalCode}`
                return {
                status : true,
                suggestedAddress : address.formattedAddress,
                postalAddress : address.postalAddress
            }
        }
    }
    else{
    // const status =['PREMISE', 'SUB_PREMISE','PREMISE_PROXIMITY'] 
    // console.log((verdict.inputGranularity == 'PREMISE' ) )
    // if(verdict?.addressComplete)
    // {
    //         const state = address.addressComponents.filter((item:any)=>{
    //                 return item.componentType == 'administrative_area_level_1' ? true : false
    //         })
    //         address.postalAddress.administrativeArea = state[0].componentName.text
    //         return {
    //             status : true,
    //             suggestedAddress : address.formattedAddress,
    //             postalAddress : address.postalAddress
    //         }   
    // }
    // else
    // if((status.includes(verdict.inputGranularity )) && (status.includes(verdict.validationGranularity))){
        if(!(address.unconfirmedComponentTypes && address.unconfirmedComponentTypes.some ((item : any)=>{
            return confirmedComponents.includes(item)
        }) && !validatAddressComponent()) ){
            return formatgoooleAddress();
        }
        else
        return {
                status : false,
                message : 'Address could not be corrected. Please enter the valid address.'
            }
    // }
    // else
    // return {
    //             status : false,
    //             message : 'Address could not be corrected. Please enter the valid address.'
    //         }
    //     }
        }
        }
        catch(e : any){
            console.log('Error occured in the validate Address fn', e)
        return {
            status : false,
            message : 'Address validation currently Unavailable.'
        }
    }
}

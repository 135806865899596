/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */


/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */


/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
enum CONTENT_TYPE {
  pdf = 'application/pdf',
  html = 'text/html',
  jpg = 'image/jpg',
  jpeg = 'image/jpeg',
  jpe = 'image/jpe',
  gif = 'image/gif',
  png = 'image/png',
  bmp = 'image/bmp',
}
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { CustomerdataContext } from '../../context/customer/customerdata';

export const ClubHistoryPrintInfo = (props: any) => {

    const {customerdata, setcustomerdata}=useContext(CustomerdataContext);

    
    let result:any=undefined




    let customerPrimaryAddress:any="";
    let customerSecondaryAddress:any="";
    let customerCity="";
    let customerdob="";
    let customerPhoneNumber="";
    let customerEmailAdress="";
    let customerFname="";
    let customerLname="";
    let customerPlusFour="";
    let customerZip=""
    let customerState=""

    // useEffect(() => {
        // BindGrid()
        result=customerdata
        if(result!=undefined&&result!=""){
            debugger;
            result=result.data
            if(result.GetCustomer.value.addresses!=undefined){
              let customerAdressFilterArray:any=result.GetCustomer.value.addresses?.filter((x)=>
                x.addressTypeDesc=="Primary Address"
              )
              if(customerAdressFilterArray.length==0&&result.GetCustomer.value.addresses.length>0){
                customerAdressFilterArray=[result.GetCustomer.value.addresses[0]]
              }
              customerPrimaryAddress=customerAdressFilterArray.length>0?customerAdressFilterArray[0].addressLine1:""
              customerSecondaryAddress=customerAdressFilterArray.length>0?customerAdressFilterArray[0].addressLine2:""
              customerPlusFour=customerAdressFilterArray.length>0?customerAdressFilterArray[0].postalCode != null?customerAdressFilterArray[0].postalCode?.split("-")[1]:"":""
              customerZip=customerAdressFilterArray.length>0?customerAdressFilterArray[0].postalCode!=null?customerAdressFilterArray[0].postalCode?.split("-")[0]:"":""
              customerState=customerAdressFilterArray.length>0?customerAdressFilterArray[0].state:""
              customerCity=customerAdressFilterArray.length>0?customerAdressFilterArray[0].city:""
            }
            customerdob=result.GetCustomer.value.dateOfBirth
            let customerPhoneDetails:any=result.GetCustomer.value.phones?.filter((x)=>
              x.primary=="Y"
            )
            if(customerPhoneDetails.length==0&&result.GetCustomer.value.phones.length>0){
              customerPhoneDetails=[result.GetCustomer.value.phones[0]]
            }
            customerPhoneNumber=customerPhoneDetails.length>0?customerPhoneDetails[0]?.phoneNumber:""
            customerEmailAdress=result.GetCustomer.value.emailAddress
            customerFname=result.GetCustomer.value.firstName
            customerLname=result.GetCustomer.value.lastName

          }
        

    // }, [])

    // const roundOff = (num: any, places: number) => {
    //     const x = Math.pow(10, places);
    //     return Math.round(num * x) / x;
    // }

    const ClubGridbind = () => {
    
          return props.ClubGridInfoResp.map((value: any, index: any) => {
    
            return (
    
              <tr key={index}>
              <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
              {value.datePaid}
              </td>
              <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
              {value.dayPaid}{" "}
              </td>
              <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
              {value.timePaid}
              </td>
              <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
              {value.receiptId}
              </td>
              <td
              style={{
                paddingTop: 10,
                fontSize: 13,
                fontWeight: 600,
                paddingRight: 10
              }}
            >
              {value.oldDueDate}
            </td>


              <td
              style={{
                paddingTop: 10,
                fontSize: 13,
                fontWeight: 600,
                textAlign: "right",
                paddingRight: 20
              }}
            >
              ${value.totalPayment}
            </td>
            <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.nextDueDate}
            </td>

            <td
              style={{
                paddingTop: 10,
                fontSize: 13,
                fontWeight: 600,
                textAlign: "right"
              }}
            >
            {value.daysLate}
            </td>
            <td
              style={{
                paddingTop: 10,
                fontSize: 13,
                fontWeight: 600,
                textAlign: "right",
                paddingRight: 20
              }}
            >
              {value.store}
            </td>



            <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.paymentOrigin}
            </td>
            <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.tenderType}
            </td>


            <td
              style={{
                paddingTop: 10,
                fontSize: 13,
                fontWeight: 600,
                textAlign: "right"
              }}
            >
           ${value.tax}
            </td>
            </tr>

            )
          })
    }
    
    const getContentType = (fileName: string) => {
      // if (fileContentType) return fileContentType;
      debugger;
      let result;
      if (fileName && typeof fileName === 'string') {
        const splits = fileName.split('.');
        const extension = splits[splits.length - 1] as string;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        result = CONTENT_TYPE[extension];
      }
      return result || CONTENT_TYPE.pdf;
    };
    const ClubActivitybind=()=>{
        return props.clubActivityInfo.map((value: any, index: any) => {
  
            return (
    
              <tr key={index}>
              <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
              {value.activityDate}
              </td>
              <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
              {value.activityType}
              </td>
              <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
              {value.status}
              </td>
              <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
              {value.coWorker}
              </td>
            </tr>
    
    
            )
          })
    }


      

    
    
    return (


      <div style={{display:"none"}}>
      <div style={{ width: "100%", float: "left" }} id={"ClubAgreementPrintInfo"}>
        {/*top section starts here*/}
        <div style={{ width: "100%", float: "left" }}>
          <div style={{ width: "50%", float: "left" }}>
            <h4 style={{ fontSize: 17, fontWeight: "bold" }}>
              Club Payment Receipt
            </h4>
          </div>
          <div style={{ width: "50%", float: "left", marginTop: 25 }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Date :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{moment().format('MM/DD/YYYY')}</span>
          </div>
        </div>
        <div style={{ width: "100%", float: "left", marginBottom: 20 }}>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Store # :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{String(window.sessionStorage.getItem("storeNumber"))}</span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Membership # :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{props.clubinfo.membershipId || "NA"}</span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Customer :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{props.commoninformation.customerName} </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Co-Customer :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{props.commoninformation.coCustomerName || "-"} </span>
    
          </div>
        </div>
        {/*top section ends here*/}
        {/*Club Information section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: 20 }}>
          <h4
            style={{
              borderBottom: "1px solid #000",
              paddingBottom: 8,
              width: "100%",
              fontSize: 13,
              fontWeight: "bold",
              marginBottom: 20
            }}
          >
            Club Information
          </h4>
          <div style={{ width: "10%", float: "left", paddingRight: "10%" }}>
            <label
              style={{
                width: "75%",
                float: "left",
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Weekly Rate
            </label>
            <label
              style={{
                width: "75%",
                float: "left",
                paddingTop: 13,
                textAlign: "right",
                fontWeight: 600,
                fontSize: 13
              }}
            >
              ${props.clubinfo.scheduleRate}
            </label>
          </div>
          <div style={{ width: "12%", float: "left", paddingRight: "15%" }}>
            <label
              style={{
                width: "90%",
                float: "left",
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Club Paid To Date
            </label>
            <label
              style={{
                width: "90%",
                float: "left",
                paddingTop: 13,
                textAlign: "right",
                fontWeight: 600,
                fontSize: 13
              }}
            >
              ${props.clubinfo.clubPaidTillDate}
            </label>
          </div>
          <div style={{ width: "11%", float: "left", paddingRight: "14%" }}>
            <label
              style={{
                width: "58%",
                float: "left",
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Open Date
            </label>
            <label
              style={{
                width: "56%",
                float: "left",
                paddingTop: 13,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              {props.clubinfo.enrollmentDate || "NA"}
            </label>
          </div>
          <div style={{ width: "10%", float: "left", paddingRight: "18%" }}>
            <label
              style={{
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Close Date
            </label>
            <label
              style={{
                width: "56%",
                float: "left",
                paddingTop: 13,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              {props.clubinfo.terminationDate}
            </label>
          </div>
        </div>
        {/*Club Information section ends here*/}
        {/*Club activity section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: 50 }}>
          <h4
            style={{
              borderBottom: "1px solid #000",
              paddingBottom: 10,
              width: "100%",
              fontSize: 15,
              fontWeight: "bold",
              marginBottom: 10
            }}
          >
            Club Activity
          </h4>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Activity Date
                  </span>
                </th>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Activity Type
                  </span>
                </th>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Status
                  </span>
                </th>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Coworker
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
            {ClubActivitybind()}
            </tbody>
          </table>
        </div>
        {/*Club activity section ends here*/}
        {/*Payment History Summary section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: 20 }}>
          <h4 style={{ fontSize: 13, fontWeight: "bold" }}>Club Payment History</h4>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Date Paid
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Day
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Time
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Receipt #
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13,
                    paddingRight: 10
                  }}
                >
                  Old Due Date{" "}
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13,
                    paddingRight: 20
                  }}
                >
                  Total Payment
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Next Due Date
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13
                  }}
                >
                  Days Late
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13,
                    paddingRight: 20
                  }}
                >
                  Store
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Payment Origin
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Tender Type
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13
                  }}
                >
                  Tax
                </th>
              </tr>
            </thead>
            <tbody>
            {ClubGridbind()}
            </tbody>
          </table>
        </div>
        {/*Payment History Summary section ends here*/}
      </div>
      </div>
    )


}
export default ClubHistoryPrintInfo
/* eslint-disable sonarjs/no-nested-switch */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-case-declarations  */
/* eslint-disable react/no-children-prop  */


/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable sonarjs/prefer-single-boolean-return */
import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent,
    RACButton,
    RACModalCard,
    RACSelect,
    RACTextbox,
    ListItem,
    List,
    RACCheckBox,
    CircularProgress,
    RACRadio,
    RACInfoIcon,
    TextareaAutosize,
    RACTable,
    RACTableCell,
    RACTableRow,
    // LinearProgress

} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { ProductPermissions } from '../../constants/constants';
import { fontSize } from '@mui/system';
import { array } from 'yup';
import { userTraceContextData } from "../../context/UserTraceContext";
import {calculatePaymentModule} from '../../api/user'
import calculationModulePayloadFormation from "./commonModulePayloadFormation"



//UI
const useClasses = makeStyles((theme: any) => ({
    borderRed: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    mtop30:{
        marginTop:'30px',
    },
    mtop20:{
        marginTop:'20px',
    },
    w100: {
        width: '100%',
    },
    loaderStyle: {
        border: 'none',
        backgroundColor: 'transparent',
        // userFocus: 'none',
        // MozUserFocus: 'none',
        outline: 'none',
    },
    RACLoaderPage: {
        textAlign: 'center',
        marginTop: '250px',
        fontSize: '16px',
        marginLeft: '30px',
        marginRight: '30px',
        outline: 'none',
    },
    card: {
        borderRadius: '16px!important',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
        width: '100%',
    },
    title: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '28px!important',
        marginBottom: '10px!important',
    },
   

    title1: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '19px!important',
        marginBottom: '10px!important',
    },
    titleColor: {
        color: 'black',
        marginLeft: '10px',
        marginTop: '15px',
    },
    titleColor1: {
        color: 'black',
        marginLeft: '3px',
        marginTop: '14px',
    },
    infoTextStyle: {
        color: 'gray',
        fontSize: theme.typography.pxToRem(13),
        textAlign: 'center',
    },
    my4: {
        marginTop: theme.typography.pxToRem(24),
        marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
        marginBottom: theme.typography.pxToRem(48),
    },
    pt2: {
        paddingTop: theme.typography.pxToRem(8),
    },
    px2: {
        paddingRight: theme.typography.pxToRem(8),
        paddingLeft: theme.typography.pxToRem(8),
    },
    cardHeight: {
        height: ' 5.9375rem',
    },
    cardHeight1: {
        height: 'auto',
        marginTop: '26px',
    },
    headtag: {
        marginTop: '17px',
        marginLeft: '19px',
    },
    fixedFooter: {
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.typography.pxToRem(16),
        backgroundColor: '#ffffff',
        boxShadow: '0 0 3px #d9d9d9',
    },
    ml3: {
        marginLeft: theme.typography.pxToRem(16),
    },
    p2: {
        padding: theme.typography.pxToRem(8),
    },
    modalBody: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '1rem',
        width: '98%'
    },
    modalContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pointerEvents: 'auto',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        outline: '0',
    },
    me2: {
        marginRight: '0.5rem !important',
        marginLeft: '10rem',
    },
    floatStart: {
        float: 'left',
    },
    floatStart1: {
        float: 'left',
        '& input': {
            height: '24px'
        },
    },
    mb3: {
        marginBottom: theme.typography.pxToRem(16),
    },
    formLabel: {
        marginBottom: '0.2rem',
        color: '#111111',
        //eslint-disable-next-line sonarjs/no-duplicate-string
        fontFamily: 'OpenSans-semibold',
        fontSize: '14px',
        marginLeft: '10px',
    },
    textCenter: {
        textAlign: 'center',
    },
    px3: {
        paddingRight: '1rem !important',
        paddingLeft: '1rem !important',
    },
    pb4: {
        paddingBottom: '1.5rem !important',
    },
    colMd12: {
        flex: '0 0 auto',
        width: '100%',
    },
    center: {
        textAlign: 'center',
        margin: '12px',
    },
    right: {
        marginRight: '10px',
    },
    right1: {
        marginRight: '15px',
    },
    equal: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
    },
    equal1: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
        paddingBottom: '25px'
    },
    leftpadding: {
        paddingLeft: '9.5rem',
    },
    heading: {
        fontSize: '20px',
    },
    tittles: {
        marginTop: '10px',
        marginBottom: '7px',
    },
    tittle1: {
        marginTop: '10px',
        marginBottom: '7px',
        // padding: '10px !important'
        '& input': {
            padding: '10px !important'
        },
    },
    dropdown1: {
        '& div': {
            // float:"left",
            padding: '10px 9px',
            width:"150px",
            // marginLeft:"0px"
        }
    },
    tittles1: {
        marginTop: '10px',
        marginBottom: '7px'
    },
    textstyle: {
        marginRight: '10px',
        marginBottom: '25px',
    },
    textstyle1: {
        marginRight: '25px',
        marginTop: '25px',
    },
    dollartext1: {
        width: '87% !important',
        '& input': {
            height: '21px'
        },
        // width: '13% !important',
        // padding: '6px 14px !important',
        // marginBottom: '25px !important',
    },
    dollartext3:{
        width:'40% !important   ',
        
        '& input':{
            // height: '25px',
            borderBottomLeftRadius: "0px",
            borderTopLeftRadius: "0px",
            
        }
    },
    // dollartext2:{
    //     width: '80% !important',
    //     '& input': {
    //         height: '21px'
    //     },

    // },
    marginTop: {
        marginTop: '5px',
    },
    textcolor: {
        color: 'blue',
    },
    cardborder: {
        border: '0 !important',
    },
    BreadCrumbInactive: {
        '&:after': {
            content: "''",
            width: 0,
            height: 0,
            borderTop: '5px solid transparent',
            borderLeft: '10px solid #555',
            borderBottom: '5px solid transparent',
        },
    },
    marginBottom: {
        marginBottom: '10px !important',
    },
    marginBottom1: {
        marginBottom: '10px !important',
        '& input': {
            padding: '10px'
        },
    },
    marginright: {
        marginRight: '20px',
    },
    typeMargin: {
        padding: '1px',
        '& input': {
            height: '24px'
        },
    },
    screenTop: {
        marginTop: '22px'
    },
    imgStyle: {
        paddingBottom: '10px'
    },
    outlinedBtn: {
        padding: '0.7rem 2rem',
    },
    zipCodeClass: {
        '& div': {
            '& div': {
                '& div': {
                    '& input': {
                        padding: '10px'
                    }
                }
            }
        }
    },
    marginCustName: {
        marginTop: '10px',
        color: '#2179FE'
    },
    marginCustID: {
        marginTop: '10px',
        color: 'gray'
    },
    paddingCustname: {
        padding: '10px'
    },
    customerToolbar: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        paddingLeft: theme.typography.pxToRem(16),
        paddingRight: theme.typography.pxToRem(16),
        paddingTop: theme.typography.pxToRem(16),
        paddingBottom: theme.typography.pxToRem(16),
    },
    fixedBottom: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        padding: '1rem',
    },
    floatLeft: {
        float: 'left',
    },
    floatRight: {
        float: 'right',
        marginTop:'30px'
    },
    me1: {
        marginRight: theme.typography.pxToRem(4),
    },
    foc: {
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '1px 1px 6px 3px #80bdff',
        },
    },
    racFixedFooterMargin: {
        marginBottom: '120px',
    },
    spacerMT4: {
        marginTop: theme.typography.pxToRem(24),
    },
    RACPOPMsg: {
        fontFamily: 'OpenSans-bold',
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0,
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    formLabelInline: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        display: 'inline-block',
        paddingLeft: 0,
    },
    custDetailSection: {
        display: 'flex',
        marginLeft: '20px',
        width: '50%',
        justifyContent: 'space-around'
    },
    racInfoStyle: {
        marginLeft: '5px',
        position: 'absolute'
    },
    textArea:{
        margin: "10px 0px",
        width:1000 ,
        padding:"6PX", 
        borderRadius: "4px",
        resize: "none" 

    },
    description:{
        margin:"0px 0px 20px 0px"
    },
    description1:{
        margin:"0px 0px 50px 0px"
    },
     modalcard:{
        height:"60%",
        // width:"100%"

    },
    tablestyle:{
        "& th": {
            fontFamily: "OpenSans-semibold",
            // float:"left",
            fontWeight:"bold",
            whiteSpace: "nowrap",
            // marginTop:"-20px",
            // padding: "0px 50px 20px 0px",

            
          },
          "& td": {
            float:"left",
            // padding: "0px 60px 0px 0px",
            color: "black",
            
            // fontSize:"bold",
          }
    },
    tableStyle:{
        "& thead": {backgroundColor:"white",},
"& tbody": {
"& tr":{backgroundColor:"white ",
"& td":{fontWeight:"bold"
},
},
},

        
    },
    grid1:{
        marginTop:"10px",
        marginRight:"5%"
    },
    parag:{
        padding:"-10px"

    },
    btnmargin:{
        marginLeft:"5px"
    },
    textright:{
        paddingLeft:"50px"
    },
    textright1:{
        paddingLeft:"150px"
    },
    textright2:{
        paddingLeft:"110px"
    },
    textBoxcell:{
        // paddingLeft:"40px"
        // float:"right"
    },
    header2:{
        fontWeight:"bolder",
        fontSize:"10%"
    }
}));

export default function ChargeoffPopup(props?: any)
 {
    const {
        userTraceValue,
      } = useContext(userTraceContextData);
      const [userTraceData, setUserTraceData] = userTraceValue;
    
    //  debugger;
    /**PC_CO_10 the state variable chargeoffDatas is created */
    const classes= takePaymentStyles();
    const classes1 =useClasses()
    const loadpopup=props.isOpen
    const [load,setload]=useState(loadpopup)
    // const Chargeoffobj={AgreementNo:"",                               
    //     AgreementID:"", 
    //     CloseDate:"",
    //     EPOamountOwed:"",
    //     EPOamountRemaining:"",
    //     EPOamountWithtax:"",
    //     Amount:"0.00",
    //     Reason:"select"}
    const [chargeoffDatas,setchargeoffDatas]=useState<any>([]);
    // const [chargeOffDataNew,setchargeOffDataNew]= useState<any>([])
    const [reasonDropdown,setreasonDropdown]=useState<any>();
    const [reasonDropdownFlag,setreasonDropdownFlag]=useState(false)
    const [continuebtnFlag,stecontinuebtnFlag]=useState(true);
    const [continuebtnValidateFlag,setContinuebtnValidateFlag]=useState(true)
    const [acceptSpinner,setacceptSpinner] = useState(false)

    // const [errorMessage,seterrorMessage]=useState(true);
    // const [errorMessagereason,seterrorMessagereason]=useState(true)
    
     /**PC_CO_10 Inside the useEffect the value received through props is 
      * assigned to the object obj and it is updated to the state variable
      * chargeoffDatas
      */
    //  useEffect(() => {
    //     console.log('dfvgfsdv',chargeoffDatas);
    //  }, [chargeoffDatas])

    useEffect(()=>{
        let chargeDrop=[{label:'select',value:'select'}]
        props.reasonType.forEach((val:any,index:any)=>{
            let dropdown={
                          label:val.label,
                          value:val.value
                        }
                        chargeDrop.push(dropdown)
            

        })
        setreasonDropdown(chargeDrop);
        console.log("***", props.chargeoffInfo)
        for(let i=0;i<props.chargeOffValues.length;i++)
        {
            let obj={AgreementNo:props.chargeOffValues[i].AgreementNo,                               
                AgreementID:props.chargeOffValues[i].AgreementID, 
                CloseDate:props.chargeOffValues[i].CloseDate,
                EPOamountOwed:props.chargeOffValues[i].EPOamountOwed,
                EPOamountRemaining:props.chargeOffValues[i].EPOamountRemaining,
                EPOamountWithtax:props.chargeOffValues[i].EPOamountWithtax,
                // ((Number(props.chargeOffValues[i].EPOamountOwed))*Number((1+props.tax))).toFixed(2),
                Amount:props.chargeOffValues[i].Amount,
                Reason:props.chargeOffValues[i].Reason,
                ReasonRefcode:props.chargeOffValues[i].ReasonRefcode,
                taxRate:props.chargeOffValues[i].taxRate,
                remainingEpoTax:props.chargeOffValues[i].remainingEpoTax
                };
                chargeoffDatas.push(obj)

        }
       

        setchargeoffDatas(chargeoffDatas);
    },[])

    console.log("**********",chargeoffDatas)
    /**PC_CO_14 condition check is done to check whether entired field
     * is filled and to enable & disable the continue button
     */
    useEffect(()=>{
        // debugger;
       let flag=chargeoffDatas.some((x)=>Number(x.Amount)!=0.00 &&Number(x.Amount)!=0 && x.Amount!="" && x.Reason!="select" && x.Reason != "")
                                  if(flag==true)
                                  {
                                    stecontinuebtnFlag(false)
                                  }
                                  else{
                                    stecontinuebtnFlag(true);
                                  }

        let flagValidation = chargeoffDatas.every((x) => Number(x.Amount) <= Number(x.EPOamountWithtax))
        if (flagValidation == true) {
            setContinuebtnValidateFlag(false)
        }
        else {
            setContinuebtnValidateFlag(true);
        }
    },[chargeoffDatas])
   /**PC_CO_19 useEffect checks whether the data is received for edit purpose */
    useEffect(()=>{
        // debugger;

        if(props&& props.editItemInfo)
        {
            console.log("4444", props.editItemInfo)
            let editArray:any=[];

            if(props.editItemInfo.length>0)
            {
                // for(let i=0;i<props.editItemInfo.length;i++)
                // {
                //     let obj={
                //         AgreementNo:props.editItemInfo[i].AgreementNo,                               
                // AgreementID:props.editItemInfo[i].AgreementID, 
                // CloseDate:props.editItemInfo[i].CloseDate,
                // EPOamountOwed:props.editItemInfo[i].EPOamountOwed,
                // EPOamountRemaining:props.editItemInfo[i].EPOamountRemaining,
                // EPOamountWithtax:props.editItemInfo[i].EPOamountWithtax,
                // Amount:props.editItemInfo[i].Amount,
                // Reason:props.editItemInfo[i].Reason
        
                //     }
                //     // console.log("3333",obj)
                //     editArray.push(obj)

                // }
                setchargeoffDatas(props.editItemInfo)
                
            }
        //     let obj={
        //         AgreementNo:props.editItemInfo.AgreementNumber,                               
        // AgreementID:props.editItemInfo.Agreementid, 
        // CloseDate:props.editItemInfo.CloseDate,
        // EPOamountOwed:props.editItemInfo.EPOamountOwed,
        // EPOamountRemaining:props.editItemInfo.EPOamountRemaining,
        // EPOamountWithtax:props.editItemInfo.EPOamountWithtax,
        // Amount:props.editItemInfo.Amount,
        // Reason:props.editItemInfo.description

        //     }
            
            // console.log("3333",editArray)
            // setchargeoffDatas(editArray)

            
        }
        if(props.editItemID==1)
        {
            setreasonDropdownFlag(true)

        }
        
    },[])
    console.log("1111",chargeoffDatas)

    console.log("555555",props.editItemInfo)
    console.log("6666",props.editItemID)
    
    

    const cancelBtn=()=>{
        props.response(undefined)
    }

    /**PC_CO_13 handlechange function for amount field and dropdown  */

    useEffect(()=>{
        console.log("loggg",chargeoffDatas)

    },[chargeoffDatas])
    const handlechangeChargeoff=(val,name,index)=>{
        // debugger;
        let chargeoff:any=[...chargeoffDatas];
        let Reason:any=""

        switch(name){
            case "Amount":
                let removeComa=String(val.target.value).replace(/,/g,"")
                console.log("sdf",removeComa, val.target.value)
                // let value=""
                // for(let i=0;i<removeComa.length;i++){
                //     value=value+ removeComa[i]
                // }
                chargeoff[index].Amount=removeComa
                setchargeoffDatas(chargeoff)
                // setchargeoffDatas([...chargeoffDatas,chargeoffDatas[index].Amount=removeComa])
            break;

            case "Reason":

            switch (val.target.value) {
                case 'COLLAGCY':
                    Reason = "Collection agency"
                    break;
                case 'COURTORD':
                    Reason = "Court ordered"
                    break;
                case 'CHARGEOFF':
                    Reason = "Charge off"
                    break;
                case 'CUSTINIT':
                    Reason = "Customer Initiated"
                    break;
            }
            chargeoff[index].Reason=Reason;
                chargeoff[index].ReasonRefcode=val.target.value
                setchargeoffDatas(chargeoff)
                // setchargeoffDatas({...chargeoffDatas,"Reason":val.target.value})
                break;
            }
        }
        console.log("2222222222222",chargeoffDatas)
      

        const handleOnblur=(val:any,index)=>{
        //     debugger;
        let chargeoff:any=[...chargeoffDatas];
        if(val.target.value==""||val.target.value==0.0)
        {   chargeoff[index].Amount="0.00"
            setchargeoffDatas(chargeoff)

            // setchargeoffDatas({...chargeoffDatas,"Amount":"0.00"})
        }else{
            let value=String(val.target.value).replace(/,/g,"")
            chargeoff[index].Amount=Number(value).toFixed(2)
            setchargeoffDatas(chargeoff)   
                // setchargeoffDatas({...chargeoffDatas,"Amount":Number(value).toFixed(2)})
        }

        }

        const commonModuleChargeOffTrigger =async(resp:any)=>{
            // debugger;
             setacceptSpinner(true);
            let commonModulePayload = await calculationModulePayloadFormation( {
                customerId:props.customerId,
                storeNumber:props.storeNumber,
                checkedAgr:resp})
        
                let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
                console.log("commonModuleServiceRes",commonModuleServiceRes)
                setacceptSpinner(false);
                commonModuleServiceRes=commonModuleServiceRes?.data;
                let commonModuleAgrRes =  commonModuleServiceRes?.paymentDetails.filter((x)=>x.storeNumber==String( window.sessionStorage.getItem("storeNumber")))
                let calculateModuleSkipStolen =
                          commonModuleAgrRes[0]?.otherIncome?.filter(
                            (x) => x.itemCategory == "RESTITPAY"
                          );
               let totalAmount:any=0
               console.log("respresp",resp)
                for(let i=0;i<resp?.length;i++)
                {
                    let AgrRes =  calculateModuleSkipStolen.filter(
                        (x) =>
                          String(x.agreementId) ===
                          String(resp[i]?.AgreementID)
                      );
        
                      totalAmount=Number(AgrRes[0].amount) + Number(AgrRes[0]?.tax)
        
                      resp[i].Amount=Number(totalAmount).toFixed(2);
                      resp[i].newTax=Number(AgrRes[0]?.tax).toFixed(2);
                      resp[i].amount=Number(AgrRes[0]?.amount).toFixed(2)
                }
          
                props.response(resp)
        
        
           }

    /**PC_CO_15 The continueOnclick() function is triggered when the continue button is clicked 
     * PC_CO_20 After editing the continue button is clicked and continueOnclick() function
    */
    const continueOnclick=(res:any)=>{
        // debugger;
        if(res=="Submit")
        {
            let respData:any=[]
            let chargeoff:any=[...chargeoffDatas];
            chargeoff.map((x,index)=>{
                if(x.Amount=="0.00"||x.Reason=="select" ||x.Reason=="" )
                {
                    chargeoff[index].Amount="0.00";
                    chargeoff[index].Reason="select";
                }
            })
            setchargeoffDatas(chargeoff)
            respData=chargeoff.filter((x)=>x.Amount!="0.00" && x.Reason!="select" && x.Reason!="")

            
            let arrary:any=[]
            if(respData.length>0)
            {
                for(let i=0;i<respData.length;i++)
                {
                    let splitTax=Number(respData[i].taxRate) + 1;
                    let amountWithoutTax=(Number(respData[i].Amount)/splitTax).toFixed(2);
                    let newTax=(Number(respData[i].Amount)-Number(amountWithoutTax)).toFixed(2);

                    let obj = {
                        Reason: respData[i].Reason,
                        ReasonRefcode: respData[i].ReasonRefcode,
                        Amount: respData[i].Amount,
                        amount:amountWithoutTax,
                        type: "Restitution",
                        AgreementID: respData[i].AgreementID,
                        AgreementNo: respData[i].AgreementNo,
                        CloseDate: respData[i].CloseDate,
                        EPOamountOwed: respData[i].EPOamountOwed,
                        EPOamountRemaining: respData[i].EPOamountRemaining,
                        EPOamountWithtax: respData[i].EPOamountWithtax,
                        taxRate: respData[i].taxRate,
                        remainingEpoTax: respData[i].remainingEpoTax,
                        newTax: newTax,
                        editItemID: props.editItemID,
                        nineoneFee: 0,
                        removePayment: 0

                    }
                    arrary.push(obj)

                }
                if(props.configFlags?.commonModuleFlag==1){
                    // props.checkedAgr[0].isAcceptEPOValue=props.checkedAgr[0].isAcceptEPODiscounts
                    commonModuleChargeOffTrigger(arrary)
                  }else{
                    props.response(arrary)
                  }
            }

        }else if(res=="RemovePayment")
        {
            props.response({
                description:chargeoffDatas.Reason,
                Amount:chargeoffDatas.Amount,
                type:"Restitution",
                Agreementid:chargeoffDatas.AgreementID,
                AgreementNumber:chargeoffDatas.AgreementNo,
                CloseDate:chargeoffDatas.CloseDate,
                EPOamountOwed:chargeoffDatas.EPOamountOwed,
                EPOamountRemaining:chargeoffDatas.EPOamountRemaining,
                EPOamountWithtax:chargeoffDatas.EPOamountWithtax,
                editItemID:props.editItemID,
                nineoneFee:0,
                removePayment:1
               
    
    
            })


        }
        

    }

    const popup=()=>{
        return(
           
                // <Grid  item style={{margin:'0px 0px 10px 0px'}} className={`${classes.raccollg12} ${classes.floatLeft}`}>
            <Grid>
                <Grid>
                   <RACTable
                    className={classes.racGrid} 
                    renderTableHead={chargeoffgridHeader}
                    renderTableContent={chargeoffgridContent}
                    // stickyHeader
                    />
                     {/* <Typography hidden={errorMessage} style={{marginTop:"-25px",marginLeft:"70%"}} variant='h6'>Enter valid amount</Typography> */}
                     
                </Grid>          
                            <Grid style={{marginRight:"-2px"}} className={`${classes.floatRight} ${classes1.grid1}`}>
                                <RACButton
                                     variant="outlined"
                                     color="primary"
                                     className={`${classes.me1} ${classes.foc} ${classes1. btnmargin}`}
                                    onClick={() =>cancelBtn() }
                                >
                                    Cancel
                                </RACButton>
                                {/* <RACButton
                                    variant="contained"
                                    color="primary"
                                    className={`${classes.me1}  ${classes1. btnmargin}`}
                                    // disabled={props && props.editItemId && props.editItemId > 0 ? false : true}
                                    disabled={props.editItemID&&props.editItemID>0?false:true}
                                    onClick={() =>  continueOnclick("RemovePayment")}
                                >
                                    Remove Payment
                                </RACButton> */}
                                <RACButton
                                    variant="contained"
                                    color="primary"
                                    className={`${classes.me1}  ${classes1. btnmargin}`}
                                    disabled={continuebtnFlag==false && continuebtnValidateFlag==false? false :true}
                                        //submitBtnEnabled == false &&  istermsAndConditionCheck == true ? false : true||nonInventorySubmitHandle}
                                    loading={acceptSpinner}
                                    onClick={() => continueOnclick("Submit")}
                                >
                                    Continue
                                </RACButton> 
                                {/* <LinearProgress value={50}  />   */}
                            </Grid>
            </Grid>)  }
    
    /**PC_CO_11 chargeoffgridHeader function which is called in renderTableHead attribute 
     * of RACTable.
     */
    const chargeoffgridHeader=()=>{
        return(
        <>
            <RACTableCell  >Agreement #</RACTableCell>
            <RACTableCell  >Close Date</RACTableCell>
            <RACTableCell  style={{textAlign:"right"}}>EPO Amount Owed</RACTableCell>
            <RACTableCell    style={{textAlign:"right"}}>EPO Amount Remaining</RACTableCell>
            <RACTableCell style={{textAlign:"right"}}><p style={{marginTop:"30px"}}>EPO Amount Remaining</p><p style={{marginTop:"-20px",marginLeft:"-0px"}}>(Including Tax)</p></RACTableCell>
            <RACTableCell style={{textAlign:"center"}}>Amount Collected<span style={{color:"red"}}>*</span></RACTableCell>
            <RACTableCell >Reason<span style={{color:"red"}}>*</span></RACTableCell>
        </>
   
        )
        
 };
    
    /**PC_CO_12 chargeoffgridContent function which is called in renderTableContent attribute 
     * of RACTable.
     */

    const  chargeoffgridContent=()=>{
        return chargeoffDatas.map((val,index)=>{
            return(
            <>
            <RACTableRow className={`${classes.tablerowtheme} ${classes.font12}`}>     
                  <RACTableCell >
                {val.AgreementNo}
                </RACTableCell>
                <RACTableCell  >
                 {val.CloseDate==null||val.CloseDate==""?"-":val.CloseDate}
                </RACTableCell>
                <RACTableCell style={{textAlign:"right"}} >
                {"$"+val.EPOamountOwed}
                </RACTableCell>
                <RACTableCell style={{textAlign:"right"}}>
                {"$"+val.EPOamountRemaining}
                </RACTableCell>
                <RACTableCell style={{textAlign:"right"}} >
                {"$"+val.EPOamountWithtax}
                </RACTableCell>
                <RACTableCell style={{float:"right",marginRight:"-10%"}}>
                <RACTextbox
                                                    className={classes1.dollartext3}
                                                    dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                                                    type="number"
                                                    name={`Amount_${index}`}
                                                    id={index}
                                                    value={val.Amount}
                                                    // value={chargeoffDatas.Amount}
                                                    OnChange={(e:any) =>{ handlechangeChargeoff(e,"Amount",index)}} 
                                                    Onblur={(e:any)=>{handleOnblur(e,index)}}                                                 
                                                    isCurrency={true}
                                                    maxlength={10}
                                                    required={false} 
                                                    // errorMessage={errorMessage==false?"Enter amount less thanEpoAmount IncludingTax":""}                                                   
                                                     />
                                                    
                    <Typography hidden={Number(val.Amount)>Number(val.EPOamountWithtax)?false:true} style={{color:"red"}}variant='h6'><p style={{marginTop:"1px"}}>Enter amount less than</p><p  style={{marginTop:"-20px",marginBottom:"-10px"}}>EpoAmount IncludingTax</p></Typography>
                    {console.log('asdfgre', val.Amount, val.EPOamountWithtax)}
                    {/* <Typography hidden={errorMessage} style={{color:"red"}}variant='h6'>Enter amount less than EpoAmount IncludingTax</Typography> */}
                    
                   </RACTableCell> 
                
                   <RACTableCell  style={{width: '12% '}}>
                   <RACSelect
                // classes={{ nativeSelect: classes1.dropdown1 }}
                name={`Reason_${index}`}
                // id={index}
                defaultValue={val.ReasonRefcode}
                // defaultValue={chargeoffDatas.Reason}
                isDisabled={reasonDropdownFlag}
                onChange={(e: any) =>handlechangeChargeoff(e,"Reason",index)}
                options={reasonDropdown}
                 />  
                </RACTableCell>
            </RACTableRow>
            </>
            );          
    })
    }  
    return(
        props.isOpen ?(
        
        <RACModalCard
        isOpen={load}
        borderRadius="20px"
        // GridClassname={racGrid}
        maxWidth="lg"
        className={classes1.modalcard}
        children={popup()}
        title={" Skip Stolen/Payment"}      
        // TitleClassName={classes1.header2}
        // onClose={()=>{setload(false)}}
        />       
       ):null        
    )
}
 
/**
 * Import the required packages, interface and context
 */
import React from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { RACButton, Grid, Typography } from '@rentacenter/racstrap';
import { dateFormat } from '../utils/scheduleAndDateFormat';
import CONSTANTS from '../constants/constant';
import { useContext } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { useHistory, useParams } from 'react-router-dom';
import { ParamType } from '../interface/paymentInfoInterface';
import {
  CODDAProps,
  PrimaryAddrsProps,
  RetentionProps,
} from '../interface/onLoadPopupInterface';

/**
 * PS_PI_175 - PS_PI_177
 * This popup is used to display the due date adjustment validation in CO initial flow
 * @returns
 */
export function CustomerOrderDDAPopup(props: CODDAProps) {
  const classes = takePaymentStyles();
  const { modalCardEnable, setModalCardEnable } = useContext(agreementContext);
  return (
    <Grid>
      <Grid className={`${classes.textCenter}`}>
        <Typography className={`${classes.bold}`}>
          This is the Initial Payment for this Customer Order.
        </Typography>
        <Typography className={`${classes.bold}`}>
          You are required to set the next due date to take the payment.
        </Typography>
        {dateFormat(props.nextDueDate, CONSTANTS.MM_DD_YYYY) ? (
          <Typography className={`${classes.bold}`}>
            A due date set to{' '}
            {dateFormat(props.nextDueDate, CONSTANTS.MM_DD_YYYY)} will use the
            full deposit.
          </Typography>
        ) : (
          CONSTANTS.EMPTY_STRING
        )}
      </Grid>
      <Grid className={`${classes.py4} ${classes.textCenter}`}>
        <RACButton
          className={classes.mx1}
          variant="contained"
          color="primary"
          onClick={() => {
            setModalCardEnable({
              ...modalCardEnable,
              onLoadDueDateAdjustment: true,
            });
            props.setCoDueDateAdjustmentPopup(false);
          }}
        >
          OK
        </RACButton>
      </Grid>
    </Grid>
  );
}

/**
 * PS_PI_162 - PS_PI_164
 * Declaring primaryAddrsValidationPopup()
 * Displays the validation text if there is no primary address or active primary address or primaryPhone number for the customer
 * @returns
 */
export const PrimaryAddrsValidationPopup = (props: PrimaryAddrsProps) => {
  const classes = takePaymentStyles();
  const history = useHistory();
  const { customerId } = useParams<ParamType>();

  return (
    <Grid item md={12}>
      <Grid item md={12} className={classes.textCenter}>
        <AlertIcon></AlertIcon>
        <Typography
          variant={'body2'}
          className={classes.RACPOPMsg}
          data-testid="customerPopup"
        >
          {props.text}
        </Typography>
      </Grid>
      <Grid
        container
        className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
      >
        <RACButton
          data-testid="okBtn"
          variant="contained"
          color="primary"
          isRounded={false}
          onClick={() =>
            history.push({
              pathname: `/customer/update/${customerId}/customer`,
            })
          }
        >
          Ok
        </RACButton>
      </Grid>
    </Grid>
  );
};

/**
 * PS_PI_172 - PS_PI_174
 * This popup is used to display details regarding the retention offer they selected
 * @returns
 */
export const RenderRetentionAlert = (props: RetentionProps) => {
  const classes = takePaymentStyles();
  const { modalCardEnable, setModalCardEnable } = useContext(agreementContext);
  /**
   * PS_PI_168 - PS_PI_171
   * Declaring acceptApplyRetention()
   * Opens freeTime or Epo based on retentionText
   * Used in the onLoad of retention offer navigation
   */
  const acceptApplyRetention = () => {
    if (props.retentionText == CONSTANTS.FREETIME) {
      setModalCardEnable({ ...modalCardEnable, onLoadFreeTimePopup: true });
    } else {
      setModalCardEnable({ ...modalCardEnable, onLoadAcceptEPO: true });
    }
    props.setRetentionLoadPopup(false);
  };

  return (
    <Grid>
      <Grid className={classes.textcenter}>
        <Typography className={classes.racErrorFunction}>
          {props.retentionText == CONSTANTS.FREETIME
            ? `Retention extension for agreement ${props.stateData.agreementNumber} is going to set up with a retention option. Customer has upto ${props.stateData.extentionDays} days extension available with a recommend. You will need to complete the extension payment to finalize`
            : `Retention EPO reduction for agreement ${props.stateData.agreementNumber} is going to get set up with ${props.stateData.retentionEPOMessage}. You will need to complete the EPO payment to finalize`}
        </Typography>
      </Grid>
      <Grid className={classes.textcenter}>
        <RACButton
          variant="contained"
          color="primary"
          className={classes.me2}
          data-testid="okBtnRetention"
          onClick={acceptApplyRetention}
        >
          OK
        </RACButton>
      </Grid>
    </Grid>
  );
};

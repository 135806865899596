/**
 * PS_CCCB_02
 * Import the required packages, Interface and context
 */
import React, { useContext } from 'react';
import {
  RACButton,
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { agreementContext } from '../context/PaymentInformationContext';
import CONSTANTS from '../constants/constant';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { CccbHeader, CccbBodyArray } from '../interface/nsfAndCCCBInterface';
import { AgreementContextValue } from '../interface/contextInterface';

/**
 * PS_CCCB_03 - PS_CCCB_15
 * Declare a function as CCCBComponent()
 * This retrives the data from the context and displays the HTML for CCCB popup
 * @returns
 */
export function CCCBComponent() {
  /**
   * PS_CCCB_04 - PS_CCCB_06
   * Variable declarationa nd initialization
   */
  const classes = takePaymentStyles();
  const { agreementDetails, modalCardEnable, setModalCardEnable } =
    useContext<AgreementContextValue>(agreementContext);
  const cccbAgreement = agreementDetails.filter((el: AgreementDetails) =>
    el.agreementType === CONSTANTS.CREDIT_CARD_CHARGE_BACK && el.selected
      ? el
      : null
  );

  const cccbFirstBody: CccbHeader[] = [
    { display: CONSTANTS.CARD_STAR_PATTERN + cccbAgreement[0].cardLastFour },
    { display: cccbAgreement[0].returnDate },
    { display: '  ' },
  ];

  /**
   * PS_CCCB_07 - PS_CCCB_08
   * Declare a function as cccbHeader(), responsible for binding the headers
   * @param headerArray holds the values for headers
   * @returns
   */
  const cccbHeader = (headerArray: CccbHeader[]) => (
    <>
      {headerArray.map((val, index) => {
        return (
          <RACTableCell
            className={`${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.rowColor}`}
            style={{ paddingLeft: '2px' }}
            key={index}
          >
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );
  /**
   * PS_CCCB_09
   * An array that holds the table body values
   */
  const bodyArray: CccbBodyArray[] = [
    {
      bodyHeader: CONSTANTS.CHARGE_BACK_AMOUNT,
      originalAmount: cccbAgreement[0].amount,
      balanceAmount: cccbAgreement[0].balanceAmount,
    },
    {
      bodyHeader: CONSTANTS.CHARGE_BACK_TOTAL,
      originalAmount: cccbAgreement[0].amount,
      balanceAmount: cccbAgreement[0].balanceAmount,
    },
  ];
  /**
   * PS_CCCB_10 - PS_CCCB_11
   * This function binds the table body using bodyArray
   * @returns
   */
  const cccbBody = () => (
    <>
      {bodyArray.map((item: CccbBodyArray, index: number) => {
        return (
          <RACTableRow
            className={` ${classes.rowColor} ${classes.font12}`}
            key={index}
          >
            <RACTableCell
              className={` ${classes.paymentStore}  ${classes.fontFamilyOpenSansBold} `}
            >
              {item.bodyHeader}
            </RACTableCell>
            <RACTableCell
              className={` ${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.fontFamilyOpenSansSemiBold} `}
            >
              <span>$</span>
              {item.originalAmount}
            </RACTableCell>
            <RACTableCell
              className={` ${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.fontFamilyOpenSansSemiBold}`}
            >
              <span>$</span>
              {item.balanceAmount}
            </RACTableCell>
            <RACTableCell
              className={` ${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.fontFamilyOpenSansSemiBold}`}
            >
              <span>$</span>
              {item.balanceAmount}
            </RACTableCell>
          </RACTableRow>
        );
      })}
    </>
  );

  /**
   * PS_CCCB_12 - PS_CCCB_15
   * within the retuen part, call the table header and body
   * binding functions followed by cancel and continue buttons
   */
  return (
    <>
      <>
        <Grid style={{ width: '65%' }}>
          <Grid>
            <RACTable
              renderTableHead={() => cccbHeader(CONSTANTS.CCCB_FIRST_HEADER)}
              renderTableContent={() => cccbHeader(cccbFirstBody)}
            />
          </Grid>
        </Grid>
        <Grid>
          <RACTable
            renderTableHead={() => cccbHeader(CONSTANTS.CCCB_SECOND_HEADER)}
            renderTableContent={() => cccbBody()}
          />
        </Grid>

        <Grid item md={12} style={{ marginTop: '30px' }}>
          <RACButton
            data-testid="continueBtn"
            variant="contained"
            color="primary"
            className={`${classes.spacerMR2} ${classes.setDefault}`}
            style={{ float: 'right' }}
            onClick={() =>
              setModalCardEnable({ ...modalCardEnable, CCCBPopup: false })
            }
          >
            Continue
          </RACButton>

          <RACButton
            data-testid="cancelBtn"
            type="button"
            color="primary"
            variant="outlined"
            style={{ float: 'right' }}
            className={`${classes.spacerMR2} ${classes.setDefault1}`}
            onClick={() =>
              setModalCardEnable({ ...modalCardEnable, CCCBPopup: false })
            }
          >
            Cancel
          </RACButton>
        </Grid>
      </>
    </>
  );
}

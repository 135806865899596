/**
 * PS_PIC_1.1, PS_PIC_1.2
 * Importing the required packages from react
 * Import the interface from PaymentInfoInterface and COntextInterface
 */
import React, { createContext } from 'react';
import { useState } from 'react';
import {
  AgreementDetails,
  Customer,
  PaymentScheduleReferences,
} from '../interface/paymentInfoInterface';
import * as interfaceType from '../interface/contextInterface';
import CONSTANTS from '../constants/constant';
import { AmountDetails } from '../interface/calculatePaymentResponse';
import { ChargeOffDetails } from '../interface/commonInterface';
import { StoreExceptionPayload } from '../interface/acceptPaymentInterface';
/**
 * PS_PIC_1.3
 * Creating a context as agreementContext and exporting it
 */
export const agreementContext =
  createContext<interfaceType.AgreementContextValue>(
    {} as interfaceType.AgreementContextValue
  );
/**
 * PS_PIC_1.4
 * Declaring a functional component as PaymentInformationContext
 * Declare all the required state variables and provide it to the context.
 * @param props
 * @returns
 */
export const PaymentInformationContext = (props: interfaceType.Props) => {
  /**
   * PS_PIC_1.5
   * It is used to handle the disable attribute of Accept Payment button
   */
  const paymentDisableOptions: interfaceType.PaymentDisableOptions = {
    alertDisable: false,
    isValidCarryRent: false,
    tenderPayment: true,
  };
  /**
   * PS_PIC_1.6
   * It stores the feature flags that has value greater than 0
   */
  const [featureFlags, setfeatureFlags] = useState<interfaceType.FeatureFlags>(
    {}
  );

  /**
   * PS_PIC_1.7
   * It store all the required informations of agreement received from the paymentInfo api response
   */
  const [agreementDetails, setAgreementDetails] = useState<AgreementDetails[]>(
    CONSTANTS.AGREEMENT_DETAILS_INITIAL_ARRAY
  );

  /**
   * PS_PIC_1.8 - state variable for storing customerObj.
   * It stores the details of customer, commitment, card, available credits,
   * user entered amount, payment details, contact number and address details.
   */
  const [customerInfo, setCustomerInfo] = useState<Customer>(
    CONSTANTS.CUSTOMER_INITIAL_OBJECT
  );

  /**
   * PS_PIC_1.9 - State variable for handling Accept Payment button disable condition
   */
  const [paymentDisable, setPaymentDisable] =
    useState<interfaceType.PaymentDisableOptions>(paymentDisableOptions);
  /**
   * PS_PIC_1.10 - To store the schedule details from response
   */
  const [paymentSchedule, setPaymentSchedule] = useState<
    PaymentScheduleReferences[]
  >([]);
  /**
   * PS_PIC_1.11 - To store the store details from response
   */
  const [storeInfo, setStoreInfo] = useState<interfaceType.StoreInfo>({});
  /**
   * PS_PIC_1.12 - To store the copy of initial value of agreementDetails
   */
  const [agreementDetailsDeepClone, setAgreementDetailsDeepClone] = useState<
    AgreementDetails[]
  >([]);
  /**
   * PS_PIC_1.8 - state variable for storing customerObj
   */
  const [customerInfoDeepClone, setCustomerInfoDeepClone] = useState<Customer>(
    CONSTANTS.CUSTOMER_INITIAL_OBJECT
  );
  /**
   * PS_PIC_1.13 - To store charge off agreement details.
   */
  const [otheIncomeChargeOffDetails, setOtheIncomeChargeOffDetails] = useState<
    ChargeOffDetails[]
  >([]);

  const [
    otherIncomeChargeOffDetailsDeepClone,
    setOtheIncomeChargeOffDetailsDeepClone,
  ] = useState<ChargeOffDetails[]>([]);

  /**
   * PS_PIC_1.15 - To store the other income type details of the current store.
   */
  const [otherIncomeDetails, setOtherIncomeDetails] = useState<AmountDetails[]>(
    []
  );
  /**
   * PS_PIC_1.16 - To store the co-worker details
   */
  const [coWorkerDetails, setCoworkerDetails] =
    useState<interfaceType.CoWorkerDetails>(
      {} as interfaceType.CoWorkerDetails
    );
  /**
   * PS_PIC_1.17 - To store boolean values to represent if different store agreement is selected.
   */
  const [multiStoreSelected, setMultiStoreSelected] = useState<boolean>(false);
  /**
   * PS_PIC_1.18 - To track the set to default or remove amount due action performed
   */
  const [isSetToDefault, setIsSetToDefault] = useState<number>(0);
  const [removeAmountDue, setRemoveAmountDue] = useState<boolean>(false);
  const [carryRent, setCarryRent] = useState<string>(CONSTANTS.EMPTY_STRING);
  /**
   * PS_PIC_1.19 - To render the component
   */
  const [renderContext, setRenderContext] = useState<boolean>(false);

  const [paymentOrigin, setPaymentOrigin] = useState<string>(
    CONSTANTS.IN_STORE_VALUE
  );
  /**
   * PS_PIC_1.20 - To store if CO initial or CO prelim flow
   */
  const [customerOrderPayment, setCustomerOrderPayment] = useState<
    string | undefined
  >();
  /**
   * PS_PIC_1.21 - To store remaining amount and amount used in freeTime
   */
  const [cappedFreeTimeDetails, setCappedFreeTimeDetails] =
    useState<interfaceType.CappedFreeTimeDetails>({
      storeRemainingAmount: CONSTANTS.ZERO_NUMBER,
      originalStoreRemainingBalance: CONSTANTS.ZERO_NUMBER,
      amountUsed: CONSTANTS.ZERO_NUMBER,
    });
  /**
   * PS_PIC_1.22 - To store the state list
   */
  const [stateData, setStateData] = useState<interfaceType.StateData[]>([]);
  const [bestTimeList, setBestTimeList] = useState<interfaceType.StateData[]>(
    []
  );
  /**
   * PS_PIC_1.23 - To handle the popups in grid
   */
  const [modalCardEnable, setModalCardEnable] =
    useState<interfaceType.ModalCardEnable>({
      freeTimePopup: false,
      onLoadFreeTimePopup: false,
      adjustDueDatePopup: false,
      onLoadDueDateAdjustment: false,
      CCCBPopup: false,
      NSFPopup: false,
      addSuspensePopup: false,
      useSuspensePopup: false,
      transferSuspensePopup: false,
      finalTransferSuspensePopup: false,
      retentionPopup: false,
      racExchangeComponent: false,
      viewEpoScheduleComponent: false,
      acceptEpoPopup: false,
      onLoadAcceptEPO: false,
    });
  /**
   * PS_PIC_1.24 - To store non inventory sale details
   */
  const [nonInventorySaleInfo, setNonInventorySaleInfo] =
    useState<interfaceType.NonInventorySaleInfo>({
      typeDropDownResp: [],
      editIndex: CONSTANTS.EMPTY_STRING,
    });
  /**
   * PS_PIC_1.25 - To store non inventory sale options
   */
  const [nonInventorySaleOptions, setNonInventorySaleOptions] =
    useState<interfaceType.NonInventorySaleOptions>({
      typeDropDownArray: [],
      xBoxProviderValues: [
        { label: CONSTANTS.SELECT, value: CONSTANTS.SELECT },
      ],
      xBoxRatePlanValues: [
        { label: CONSTANTS.SELECT, value: CONSTANTS.SELECT },
      ],
    });
  /**
   * PS_PIC_1.26 - Used for manuplations on back rent items
   */
  const [backRentEditInfo, setbackRentEditInfo] =
    useState<interfaceType.BackRent>({
      backRentOwed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      salesTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      taxRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      total: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      defaultTotal: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    });
  const [backRentInfo, setBackRentInfo] = useState<interfaceType.BackRent>(
    {} as interfaceType.BackRent
  );
  const [StoreExceptionPayload, setStoreExceptionPayload] = useState<
    StoreExceptionPayload[]
  >([]);
  /**
   * For payment history response
   */
  const [paymentHistoryResponse, setPaymentHistoryResponse] =
    useState<interfaceType.PaymentHistoryResponse>({
      payHistoryAgr:
        {} as interfaceType.PaymentHistoryResponse['payHistoryAgr'],
      payHistoryClub:
        {} as interfaceType.PaymentHistoryResponse['payHistoryClub'],
      payHistoryRecp:
        {} as interfaceType.PaymentHistoryResponse['payHistoryRecp'],
    });

  /**
   * For tax payload
   */
  const [taxPayload, setTaxPayload] = useState<interfaceType.TaxPayload>(
    {} as interfaceType.TaxPayload
  );

  /**
   * For OtherAction api call loader
   */
  const [otherActionLoader, setOtherActionLoader] = useState<boolean>(false);

  /**
   * For commitment state
   */
  const [commitmentLoad, setCommitmentLoad] = useState<boolean>(false);

  /**
   * For payment Origin popup
   */
  const [originPopup, setOriginPopup] = useState<boolean>(true);

  const [carryRentCalci, setCarryRentCalci] = useState<boolean>(false);

  const [typeOfCard, setTypeOfCard] = useState<string>('');

  const [totalDue, setTotalDue] = useState<number>(
    CONSTANTS.ZERO_TWO_DECIMAL_NUMBER
  );

  /**
   * PS_PIC_1.27, PS_PIC_1.28
   */
  return (
    <>
      <agreementContext.Provider
        value={{
          agreementDetails,
          setAgreementDetails,
          customerInfo,
          setCustomerInfo,
          paymentDisable,
          setPaymentDisable,
          paymentSchedule,
          setPaymentSchedule,
          featureFlags,
          setfeatureFlags,
          storeInfo,
          setStoreInfo,
          agreementDetailsDeepClone,
          setAgreementDetailsDeepClone,
          otheIncomeChargeOffDetails,
          setOtheIncomeChargeOffDetails,
          otherIncomeChargeOffDetailsDeepClone,
          setOtheIncomeChargeOffDetailsDeepClone,
          nonInventorySaleOptions,
          setNonInventorySaleOptions,
          nonInventorySaleInfo,
          setNonInventorySaleInfo,
          backRentEditInfo,
          setbackRentEditInfo,
          StoreExceptionPayload,
          setStoreExceptionPayload,
          otherIncomeDetails,
          setOtherIncomeDetails,
          coWorkerDetails,
          setCoworkerDetails,
          multiStoreSelected,
          setMultiStoreSelected,
          isSetToDefault,
          setIsSetToDefault,
          renderContext,
          setRenderContext,
          customerOrderPayment,
          setCustomerOrderPayment,
          customerInfoDeepClone,
          setCustomerInfoDeepClone,
          backRentInfo,
          setBackRentInfo,
          cappedFreeTimeDetails,
          setCappedFreeTimeDetails,
          stateData,
          setStateData,
          bestTimeList,
          setBestTimeList,
          modalCardEnable,
          setModalCardEnable,
          removeAmountDue,
          setRemoveAmountDue,
          paymentHistoryResponse,
          setPaymentHistoryResponse,
          paymentOrigin,
          setPaymentOrigin,
          taxPayload,
          setTaxPayload,
          otherActionLoader,
          setOtherActionLoader,
          carryRent,
          setCarryRent,
          commitmentLoad,
          setCommitmentLoad,
          originPopup,
          setOriginPopup,
          carryRentCalci,
          setCarryRentCalci,
          typeOfCard,
          setTypeOfCard,
          totalDue,
          setTotalDue,
        }}
      >
        {props.children}
      </agreementContext.Provider>
    </>
  );
};

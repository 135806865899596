import { createBrowserHistory, History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app/App';
import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      keepPreviousData: false,
    },
  },
});

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}
export interface CustomPropInterface {
  GetRole: () => string;
  GetEmployeeId: () => string;
  GetMenuConfig: () => any;
}

window.renderPayment1 = (
  containerId: string,
  history: History,
  customProps?: CustomPropInterface
) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.render(
      <QueryClientProvider client={queryClient}>
        <App history={history} customProps={customProps} />
      </QueryClientProvider>,
      elem
    );
  }
};

window.unmountPayment1 = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};

if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    });

    window.renderPayment1('root', defaultHistory);
  });
}

/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable use-isnan */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable prettier/prettier */
/* eslint-disable no-constant-condition */
/* eslint-disable no-compare-neg-zero */
/* eslint-disable sonarjs/no-identical-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-one-iteration-loop */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-collection-size-mischeck */
// eslint-disable-next-line prettier/prettier
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-empty */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-console */
/* eslint-disable no-debugger */

import React, { useEffect, useState ,useContext } from "react";
import {
  RACButton,
  RACModalCard,
  TextareaAutosize,
  Grid,
  Typography,
  RACSelect,
} from "@rentacenter/racstrap";
import CurrencyInput from "react-currency-input-field";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { userTraceContextData } from "../../context/UserTraceContext";
export default function RefundDeposit(props: any) {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;

  const classes = takePaymentStyles();
  const RSDObject = {
    securityDepositAgreementNumber: "Select",
    securityDepositAgreement: "Select",
    depositAmount: "0.00",
    amountCollected: "0.00",
    totalToRefund: "0.00",
    reasonDescription: "",
  };
  const securityDepositAgreements:any = [
    // {
    //   label: "Select",
    //   value: "Select",
    // },
    // {
    //   label: "AGR: 0257486",
    //   value: "0257486",
    // },
    // {
    //   label: "AGR: 9621445",
    //   value: "9621445",
    // },
    // {
    //   label: "AGR: 7412658",
    //   value: "7412658",
    // },
    // {
    //   label: "AGR: 321585",
    //   value: "321585",
    // },
  ];
  const [openRDS, setOpenRDS] = useState(false);
  const [disableProceed, setDisableProceed] = useState(true);
  const [RDSData, setRDSData] = useState(RSDObject);
  const [dropDownOption,setDropDownOption]= useState()
  useEffect(() => {
    setOpenRDS(props.throwOpenRDS);
  }, [props.throwOpenRDS]);
  useEffect(() => {
    if (RDSData.securityDepositAgreement == "Select") {
      setDisableProceed(true);
    } else {
      setDisableProceed(false);
    }
  }, [RDSData]);
  
  useEffect(()=>{
    console.log("throwMasterRSDDatathrowMasterRSDData",props.throwMasterRSDData)
    if(props.throwMasterRSDData.length>0)
    {
      console.log("props.throwMasterRSDData",props.throwMasterRSDData)
      for(let i=0;i<props.throwMasterRSDData.length;i++)
      {
        let obj:any={
          label:props.throwMasterRSDData[i]?.securityDepositAgreementNumber=="Select"?"Select":"AGR:"+ String(props.throwMasterRSDData[i]?.securityDepositAgreementNumber),
          value:props.throwMasterRSDData[i]?.securityDepositAgreement
        }
        securityDepositAgreements.push(obj)

      }
      setDropDownOption(securityDepositAgreements)
    }

   
  },[])

 

  const proceedRefund = () => {
    setOpenRDS(false);
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"Refund Security Deposit"
    }
    setUserTraceData([...userTraceData,traceObj])
    props.acceptRefund(RDSData);
  };

  const renderRDSModal = () => {
    return (
      <>
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <Typography>Security Deposit</Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACSelect
              defaultValue={RDSData.securityDepositAgreement}
              onChange={(event: any) => {
                const filteredMasterData = props.throwMasterRSDData.filter(
                  (el) => el.securityDepositAgreement === event.target.value
                );
                setRDSData(filteredMasterData[0]);
              }}
              options={dropDownOption}
            />
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <Typography>Deposit Amount</Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <div className={`${classes.inputgroup}`}>
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                type="text"
                className={`${classes.formcontrol3} ${classes.amountTextAlignment} ${classes.amountGreyOut}`}
                value={RDSData.depositAmount}
                disabled={true}
                decimalsLimit={2}
              />
            </div>
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <Typography>
              Amount Collected <span className={classes.emphasized}>*</span>
            </Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <div className={`${classes.inputgroup}`}>
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                type="text"
                className={`${classes.formcontrol3} ${classes.amountTextAlignment}`}
                value={RDSData.amountCollected}
                onChange={(e) => {
                  console.log("loggingRSDAmt to collect", e.target.value);
                  console.log('loguoad', RDSData.depositAmount);
                  setRDSData({
                    ...RDSData,
                    amountCollected:
                      e.target.value == null
                        ? "0.00"
                        : e.target.value.replace(/,/g, ""),
                    totalToRefund:
                      e.target.value !== null &&
                      Number(RDSData.depositAmount) -
                        Number(e.target.value.replace(/,/g, "")) >
                        0
                        ? (
                            Number(RDSData.depositAmount) -
                            Number(e.target.value.replace(/,/g, ""))
                          ).toFixed(2)
                        : e.target.value !== null &&
                          Number(RDSData.depositAmount) -
                            Number(e.target.value.replace(/,/g, "")) <=
                            0
                        ? "0.00"
                        : Number(RDSData.depositAmount).toFixed(2),
                  });
                }}
                onBlur={() => {
                  setRDSData({
                    ...RDSData,
                    amountCollected: Number(RDSData.amountCollected).toFixed(2),
                  });
                }}
              />
            </div>
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <Typography>Total to Refund</Typography>
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <div className={`${classes.inputgroup}`}>
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                type="text"
                className={`${classes.formcontrol3} ${classes.amountTextAlignment} ${classes.amountGreyOut}`}
                value={RDSData.totalToRefund}
                disabled={true}
                decimalsLimit={2}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.px3}>
          <Grid item>
            <Typography>Describe reason for Amount Collected</Typography>
          </Grid>
          <TextareaAutosize
            minRows={5}
            className={classes.textArea}
            value={RDSData.reasonDescription}
            onChange={(e: any) =>
              setRDSData({
                ...RDSData,
                reasonDescription: e.target.value,
              })
            }
          />
        </Grid>
        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth} ${classes.mt3}`}
        >
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "#2C3333",
            }}
            onClick={() => {
              setOpenRDS(false);
              props.closeRDS();
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            disabled={disableProceed}
            onClick={() => proceedRefund()}
          >
            Proceed
          </RACButton>
        </Grid>
      </>
    );
  };
  return (
    <RACModalCard
      isOpen={openRDS}
      maxWidth="xs"
      closeIcon={true}
      title="Refund Security Deposit"
      onClose={() => {
        setOpenRDS(false);
        props.closeRDS();
      }}
      borderRadius={"25px !important"}
      // eslint-disable-next-line react/no-children-prop
      children={renderRDSModal()}
    />
  );
}

/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-case-declarations  */
/* eslint-disable react/no-children-prop  */


/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable sonarjs/prefer-single-boolean-return */
import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent,
    RACButton,
    RACModalCard,
    RACSelect,
    RACTextbox,
    ListItem,
    List,
    RACCheckBox,
    CircularProgress,
    RACRadio,
    RACInfoIcon,
    TextareaAutosize,
    RACTable,
    RACTableCell,
    RACTableRow,
    RACCOLOR,
    // LinearProgress

} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { ProductPermissions } from '../../constants/constants';
import { fontSize } from '@mui/system';


// import { useLocation } from 'react-router-dom';
// import { useParams } from 'react-router';

// import SearchFilter from './CriteriaSearch';
// import { getInventorySearch, PrintFaxSheet, getSalesInfo } from '../../api/user';
// import DynamicGridComponent from './grid';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';

// import { InventoryContext } from '../../context/InventoryInfoContext';
// import { InventoryGlobalStyles } from './Styles/InventoryGlobalStyles';
// import { searchResultStyles } from './Styles/searchResultStyles';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../stylesJS/global'
import { useLocation } from 'react-router';
import clsx from 'clsx';
import Criteriasearch from './Riscriteriasearch';
import { getInventorySearch ,GetRole,GetEmployeeID,getPriceQuote} from '../../api/user'
import DynamicGridComponent from './Risgrid'
import RentalSecondFactor from './RentalTwoFactor';
import { ReactComponent as DeleteIcon } from "../../assets/images/layer28.svg";
import { ReactComponent as Deleteicon } from "../../assets/images/delete.svg";
import { create } from 'lodash';
import { createContext } from 'react';
import { RentaldataContext } from '../../context/Riscontext';
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";

//import moment from 'moment';


// export interface columns 
//   columnId: string;
//   sortOrder: string;
//   columnTitle: string;
//   IsSorted: boolean;
//   IsSortable: boolean;
//   columnType?: 'link';
//   handleColumnClick?: () => void;
//   subColumns?: columns[];
//   columnColor?: string;
// }
// const sortDirection = {
//   ASC: 'asc',
//   DESC: 'desc',
// };


const useClasses = makeStyles((theme: any) => ({
    borderRed: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    mtop30: {
        marginTop: '30px',
    },
    mtop20: {
        marginTop: '20px',
    },
    w100: {
        width: '100%',
    },
    loaderStyle: {
        border: 'none',
        backgroundColor: 'transparent',
        // userFocus: 'none',
        // MozUserFocus: 'none',
        outline: 'none',
    },
    RACLoaderPage: {
        textAlign: 'center',
        marginTop: '250px',
        fontSize: '16px',
        marginLeft: '30px',
        marginRight: '30px',
        outline: 'none',
    },
    card: {
        borderRadius: '16px!important',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
        width: '100%',
    },
    title: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '28px!important',
        marginBottom: '10px!important',
    },
    masterLoader: {
        position: 'fixed',
        backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: 0.6,
        zIndex: 1050,
        textAlign: 'center',
        margin: 0,
      },

      Loader: {
        display: 'block',
        position: 'fixed',
        zIndex: 1051,
        top: '50%',
        right: '50%',
      },

    title1: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '22px!important',
        marginBottom: '10px!important',
    },
    title2: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '20px!important',
        marginBottom: '10px!important'
    },
    title3: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '20px!important',
        marginBottom: '5px!important'
    },

    titleColor: {
        color: 'black',
        marginLeft: '10px',
        marginTop: '15px',
    },
    titleColor1: {
        color: 'black',
        marginLeft: '3px',
        marginTop: '14px',
    },
    infoTextStyle: {
        color: 'gray',
        fontSize: theme.typography.pxToRem(13),
        textAlign: 'center',
    },
    my4: {
        marginTop: theme.typography.pxToRem(24),
        marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
        marginBottom: theme.typography.pxToRem(48),
    },
    pt2: {
        paddingTop: theme.typography.pxToRem(8),
    },
    px2: {
        paddingRight: theme.typography.pxToRem(8),
        paddingLeft: theme.typography.pxToRem(8),
    },
    cardHeight: {
        height: ' 5.5275rem',
    },
    cardHeight1: {
        height: 'auto',
        marginTop: '26px',
    },
    headtag: {
        marginTop: '17px',
        marginLeft: '19px',
    },
    fixedFooter: {
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.typography.pxToRem(16),
        backgroundColor: '#ffffff',
        boxShadow: '0 0 3px #d9d9d9',
    },
    ml3: {
        marginLeft: theme.typography.pxToRem(16),
    },
    p2: {
        padding: theme.typography.pxToRem(8),
    },
    modalBody: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '1rem',
        width: '98%'
    },
    modalContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pointerEvents: 'auto',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        outline: '0',
    },
    me2: {
        marginRight: '0.5rem !important',
        marginLeft: '10rem',
    },
    me2N: {
        marginRight: '0.5rem !important',
        marginLeft: '0.5rem',
    },
    floatStart: {
        float: 'left',
    },
    floatStart1: {
        float: 'left',
        '& input': {
            height: '24px'
        },
    },
    mb3: {
        marginBottom: theme.typography.pxToRem(16),
    },
    formLabel: {
        marginBottom: '0.2rem',
        color: '#111111',
        //eslint-disable-next-line sonarjs/no-duplicate-string
        fontFamily: 'OpenSans-semibold',
        fontSize: '14px',
        marginLeft: '10px',
    },
    textCenter: {
        textAlign: 'center',
    },
    textLeft:{
        textAlign:"left"
    },
    px3: {
        paddingRight: '1rem !important',
        paddingLeft: '1rem !important',
    },
    pb4: {
        paddingBottom: '1.5rem !important',
    },
    colMd12: {
        flex: '0 0 auto',
        width: '100%',
    },
    center: {
        textAlign: 'center',
        margin: '12px',
    },
    right: {
        marginRight: '10px',
    },
    right1: {
        marginRight: '15px',
    },
    equal: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
    },
    equal1: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
        paddingBottom: '25px'
    },
    leftpadding: {
        paddingLeft: '9.5rem',
    },
    heading: {
        fontSize: '20px',
    },
    tittles: {
        marginTop: '0px',
        marginBottom: '0px',
    },
    tittle1: {
        marginTop: '10px',
        marginBottom: '7px',
        // padding: '10px !important'
        '& input': {
            padding: '10px !important'
        },
    },
    dropdown1: {
        '& div': {
            padding: '10px 5px'
        }
    },
    tittles1: {
        marginTop: '10px',
        marginBottom: '7px'
    },
    textstyle: {
        marginRight: '10px',
        marginBottom: '25px',
    },
    textstyle1: {
        marginRight: '25px',
        marginTop: '25px',
    },
    dollartext1: {
        width: '87% !important',
        '& input': {
            height: '21px'
        },
        // width: '13% !important',
        // padding: '6px 14px !important',
        // marginBottom: '25px !important',
    },
    dollartext3: {
        width: '45% !important',



        '& input': {
            // float:"right",
            height: '23px',
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            // textAllign:"right",

        }
    },
    custDigitN: {
        width: "45%",
         float:"right",
        "& input": {
            textAlign: "right",
            height: '21px',
            borderBottomLeftRadius: "0px",
            borderTopLeftRadius: "0px",
        },

    },
    custDigitN1: {
        
        width: "188%",
        marginLeft:"-99%",
        
        "& input": {
            textAlign: "right",
            height: '23px',
            borderBottomLeftRadius: "0px",
            borderTopLeftRadius: "0px",
        },

    },
    // dollartext2:{
    //     width: '80% !important',
    //     '& input': {
    //         height: '21px'
    //     },

    // },
    marginTop: {
        marginTop: '0px',
    },
    textcolor: {
        color: 'blue',
    },
    cardborder: {
        borderRadius: '0px !important',
    },
    BreadCrumbInactive: {
        '&:after': {
            content: "''",
            width: 0,
            height: 0,
            borderTop: '5px solid transparent',
            borderLeft: '10px solid #555',
            borderBottom: '5px solid transparent',
        },
    },
    marginBottom: {
        marginBottom: '10px !important',
    },
    marginBottom1: {
        marginBottom: '10px !important',
        '& input': {
            padding: '10px'
        },
    },
    marginright: {
        marginRight: '20px',
    },
    typeMargin: {
        padding: '1px',
        '& input': {
            height: '24px'
        },
    },
    screenTop: {
        marginTop: '22px'
    },
    imgStyle: {
        paddingBottom: '10px'
    },
    outlinedBtn: {
        padding: '0.7rem 2rem',
    },
    zipCodeClass: {
        '& div': {
            '& div': {
                '& div': {
                    '& input': {
                        padding: '10px'
                    }
                }
            }
        }
    },
    marginCustName: {
        marginTop: '5px',
        color: '#2179FE'
    },
    marginCustID: {
        marginTop: '5px',
        color: 'gray'
    },
    paddingCustname: {
        padding: '10px'
    },
    customerToolbar: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        paddingLeft: theme.typography.pxToRem(16),
        paddingRight: theme.typography.pxToRem(16),
        paddingTop: theme.typography.pxToRem(16),
        paddingBottom: theme.typography.pxToRem(16),
    },
    fixedBottom: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        padding: '1rem',
    },
    floatLeft: {
        float: 'left',
    },
    floatRight: {
        float: 'right',
    },
    me1: {
        marginRight: theme.typography.pxToRem(4),
    },
    foc: {
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '1px 1px 6px 3px #80bdff',
        },
    },
    racFixedFooterMargin: {
        marginBottom: '120px',
    },
    spacerMT4: {
        marginTop: theme.typography.pxToRem(24),
    },
    RACPOPMsg: {
        fontFamily: 'OpenSans-bold',
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0,
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    formLabelInline: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        display: 'inline-block',
        paddingLeft: 0,
    },
    custDetailSection: {
        display: 'flex',
        marginLeft: '20px',
        width: '50%',
        justifyContent: 'space-around',
        marginTop: "-6px"
    },
    racInfoStyle: {
        marginLeft: '5px',
        position: 'absolute'
    },
    textArea: {
        margin: "10px 0px",
        width: 1000,
        padding: "6PX",
        borderRadius: "4px",
        resize: "none"

    },
    textAreaN: {
        margin: "10px 0px",
        width: 500,
        padding: "6PX",
        borderRadius: "4px",
        resize: "none"

    },
    description: {
        margin: "10px 0px"
    },
    GridLoader: {
        textAlign: 'center',
        marginBottom: theme.typography.pxToRem(20),
        fontSize: theme.typography.pxToRem(16),
        color: "white",
        margin: '30px 0px',
    },
    srmargin: {
        marginLeft: "2px"

    },
    totalsublabelstyle: {
        fontSize: theme.typography.pxToRem(12),
        color: "white",
        fontWeight: "lighter"

    },
    totalsublabelstyle1: {
        fontSize: theme.typography.pxToRem(14),
    },
    totalsublabelstyle2: {
        fontSize: theme.typography.pxToRem(14),
        color: "#2179FE"
    },
    totalsublabelstyle3: {
        fontSize: theme.typography.pxToRem(17),
        color: "#2179FE"
    },
    RACpaymenttotalcontainer1: {
        backgroundColor: "#2279FD",
        borderRadius: theme.typography.pxToRem(25),
        width: "100%",
        float: "left",
        // paddingBottom: "30px",
    },
    RACpaymenttotalcontainer2: {
        backgroundColor: "#ebf3ff",
        color: "primary",
        borderRadius: theme.typography.pxToRem(15),
        width: "100%",
        float: "left",
        paddingBottom: "30px",
    },
    selecteditem: {
        width: "25%",
        marginTop:"1px",
        padding:"8px"
        // display: "block",
        // marginTop: "-45.2%"
    },
    selecteditemNon: {
        display: "none"
    },
    beforeselect: {
        width: "99%",
        margin: "0.5%"
    },
    afterselect: {
        width: "75%",
        padding:"8px"
        // margin: "0.5"
    },
    scrollbar: {
        overflow: "auto",
        height: "400px",
        width: "100%"
    },

    p2N: {
        padding: theme.typography.pxToRem(2),
    },
    textGrey: {
        color: "gray",
        fontSize: "13px"
    },
    textBlue: {
        color: "#2179FE",
        fontSize: "13px"
    },
    fixTableHead: {
        overflowY: 'auto',
        maxHeight: '500px',
        '& th': {
            top: 0,
            backgroundColor: '#ffffff',
            fontFamily: 'OpenSans-semibold',
            zIndex: 1,
            padding: '0.9375rem 0.2rem',
            whiteSpace: 'nowrap',
        },
        '& tbody': {
            '& tr': {
                top: 10,
                backgroundColor: '#ffffff',
            },
            '& td': {
                fontFamily: 'OpenSans-semibold',
                padding: '0.9375rem 0.2rem',
                color: '#4A5174',
            },
        },
    },

}));

export const risResponse = createContext<any>("");


export default function Rentalitemsale(props:any) {
    // ;
    const classes2 = takePaymentStyles();
    const history = useHistory();
    const location = useLocation();
    const classes = useClasses();
    const classes1: any = GlobalStyles();
    const containerData: any = useContext(
        ContainerContext
      ) as CustomPropInterface;
    // const classes1: any = GlobalStyles();
 
       /* RIS_PC_NO_03  creating the instance variable tax, customerID,customerName where it is 
          received from Takepayment.tsx 
          RIS_PC_NO_13  Declare state variable internalServerController which renders "something Went Wrong "
          in search result.
          Declare state variable loaderController which renders the loading page.
          Decalare state variable Gridloader which renders the loading page
       */
    const {updatedInventory} = useContext(RentaldataContext)
    const [controlRecord, setcontrolRecord] = React.useState<any>(false);
    const [internalServerController, setinternalServerController]: any = React.useState(false);
    const [tabledata, settabledata] = useState<any>();
    const [loaderController, setloaderController]: any = React.useState(false);
    const [Gridloader, setGridloader]: any = React.useState(true);
    const [request, setrequest] = useState<any>();
    const { tax, customerID, customerName,editNo ,editresponse}: any = (location && location.state) || "";
    // editresponse,
    const [arrradio, setarrradio] = useState<any>([])
    const [itemselectdata, setitemselectdata] = useState<any>({
        "inventoryId": "",
        "inventoryNumber": "",
        "inventoryDescription": "",
        "storeNumber": "",
        "departmentsEn": "",
        "departmentsEs": null,
        "subDepartmentsEn": "",
        "subDepartmentsEs": null,
        "brandName": "",
        "bracketEn": "",
        "bracketEs": null,
        "statusEn": "",
        "statusEs": null,
        "conditionEn": "",
        "conditionEs": "",
        "remainingValue": "",
        "daysIdle": 0,
        "serialNumber": "",
        "modelNumber": "",
        "conditionRefCode": "",
        "rmsItemMasterId": "",
        "inventoryPriceId": "",
        "weeklyRate": "",
        "weeklyTerm": 0
    })
    const [amountdata, setamountdata] = useState<any>({
            itemFee: "0.00",
            inventoryDescription:'',
            inventoryNumber:'',
            inventoryId:'',
            salesprice: '',
            type: '',
            // description: '',
            // salesprice: '1020.00',
            Deliveryfee: 'select',
            salestax: '0.00',
            totalsales: '0.00',
            reason:'',
            DeliverfeeTax:"0.00"
    })
    const [customerInfo,setCustomerInfo]=useState({customerID:customerID,customerName:customerName,editNo:editNo})
    const [itemselectFlag, setitemselectFlag] = useState(false)
    const [loadPriceCard,setLoadPriceCard] = useState(false)
    let [deliveryFeeApplicable,setDeliveryFeeApplicable]=useState<any>()
    const [alertpopupFlag, setalertpopupFlag] = useState(false)
    const [sptxboxFlag, setsptxboxFlag] = useState(false)
    const [alertinfopopupFlag, setalertinfopopupFlag] = useState(false)
    const [overridepopupflag,setoverridepopupflag]=useState(false)
    const [continuebtnPopupFlag,setcontinuebtnPopupFlag]=useState(true)
    const [riscontinuebtnFlag,setriscontinuebtnFlag]=useState(true)
    const [maximumpopupFlag,setmaximumpopupFlag]=useState(false)
    const [minimumpopupFlag,setminimumpopupFlag]=useState(false)
    // const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
    const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
    const [RoleFromSLAModule,setRoleFromSLAModule]=useState<any>()
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
//   const [EmployeeDetails, setEmployeeDetails] = useState();
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [CurrentRole, setCurrentRole] = useState("");
  const [dropdownArr,setdropdownArr]=useState<any>([{label:'select',value:'select'}])
//   const [RoleFromSLAModule,setRoleFromSLAModule]=useState()
  let [price,setprice]=useState("")
  const [firstlogin,setFirstlogin]=useState<any>(false)
  const [controlLoader,setcontrolLoader]=useState(false)
  const [remoteFlag,setRemoteFlag]=useState<any>(false)
  let totalPrice=(Number(itemselectdata.cashPrice)).toFixed(2)
  const role:any="CAR";
  const iniTxtValues={"storeNumbers": "",
  "itemNumber":"",
  "serialNumber": "",
  "bracket":"",
  "brand":"",
  "modelNumber":"",
  "poNumber": null,
  "statusId": 1,
  "subStatusId": [2],
  "conditionId":'',
  "department": "",
  "subDepartment": "",
  "setNumber": 0,
  "isActivePriceTag": true,
  "edit":""

   }

   const[cancelFlag,setcancelFlag] =useState(false)
   const [TxtValues,setTxtValues]=useState(iniTxtValues);
   const{slaRole,setSlaRole}=useContext(RentaldataContext);
   const [deleiveryfeeFlag,setDeliveryfeeFlag]=useState(false)
   const [pin,setPin]=useState<any>()
   const [editRisItem, setEditRisItem] = useState<any>();
//    const {Risresponse,setRisresponse}=useContext(RentaldataContext)
//    const {editresponse,setEditresponse}=useContext(RentaldataContext)

   /**RIS_PC_NO_22 The useEffect checks the condition of itemselected whether it is 
    * equal to NEW if condition is true it disables the sales price txt box and
    * renders the alert popup.
    * 
    * RIS_PC_NO_23 If the condition is not equal to NEW then the sales price text box 
    * enabled and itemselected state variable is updated as true.
    */

    useEffect(() => {

        if(itemselectdata.inventoryId!=undefined && itemselectdata.inventoryId!=""&&
        itemselectdata.inventoryNumber!=undefined && itemselectdata.inventoryNumber!=""
     )
     {
         Pageload()
     }
       
    }, [itemselectdata])
    console.log("NNN", amountdata)

    const Pageload=async()=>{
        
        
        if (itemselectdata.conditionRefCode == "NEW") {
            setalertpopupFlag(true)
            setsptxboxFlag(true)
            setitemselectFlag(true)
        } else if (itemselectdata.conditionRefCode == "EXCP" || itemselectdata.conditionRefCode == "ATO") {
            setalertpopupFlag(false)
            setsptxboxFlag(false)
            setitemselectFlag(true)
        }
        setLoadPriceCard(true)
        await loaddata()
        setLoadPriceCard(false)
        // if(editresponse==undefined)
        // {
          
        // setamountdata({...amountdata,salestax:Number(saletax).toFixed(2),totalsales:totalSaleSP })
        // setamountdata(dataN)
       
    // }
    // if(editresponse!=undefined)
    // {
    //     // history.replace({ pathname: '/payment1/rentalitemsale', 
    //     // state: {editresponse:undefined,
    //     //         customerID:customerInfo.customerID,
    //     //         customerName:customerInfo.customerName,
    //     //         editNo:"0"} })

    // }
    


    }



 const loaddata = async()=>{
    

  let  inventoryInfo= [
        {
            "inventoryId": Number(itemselectdata.inventoryId),
            "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
        }
    ]
    const GetPriceQuoteReq: any = {
        storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
        customerId: Number(customerID),
        agreementType:'RIS',
        fees:amountdata?.editNo=="1"&&Number(amountdata.Deliveryfee)>0?[
            {
             feeType:"DELI",
             feeAmount:String(amountdata.Deliveryfee)
            }
        ]:[],
        // agreementRate: Number(itemselectdata.weeklyRate),
        // agreementTerm: Number(itemselectdata.weeklyTerm),
        // isLDWapplied: true,
        // isClubApplied: true,
        // tireplusPolicy: false,
        sacTotal:amountdata?.editNo=="1"?Number(amountdata.salesprice): Number(Number(itemselectdata.cashPrice)),
        // extensionDays: 0,
        inventoryInfo: inventoryInfo,
        // totalCost: String(Number(itemselectdata.weeklyRate) * Number(itemselectdata.weeklyTerm)),
      //   setcontrolLoader(true)
      };
      
    
      setLoadPriceCard(true)
    const response = await getPriceQuote( GetPriceQuoteReq)
    // setcontrolLoader(false)
    setLoadPriceCard(false)
    console.log("response",response)
    let del:any=response?.data?.deliveryFees?.feeAmounts
    console.log("data",response.data?.deliveryFees?.feeAmounts)

     deliveryFeeApplicable=response?.data?.deliveryFees?.deliveryFeesApplicable
        setDeliveryFeeApplicable(deliveryFeeApplicable)
    if(response?.data?.deliveryFees?.deliveryFeesApplicable==1)
    {
        let delTax=response?.data?.fees?.filter((x)=>x.feeType=="DELI")
        setamountdata({...amountdata,DeliverfeeTax:delTax.length!=0?delTax[0]?.feeAmountTax:""})

       
        let delArr=[{label:"select",value:'select'}]
    for(let i =0;i<response.data?.deliveryFees?.feeAmounts.length;i++)
    {
        let data={label:response.data?.deliveryFees?.feeAmounts[i],value:response.data?.deliveryFees?.feeAmounts[i]}
        delArr.push(data)
        
    }
    setdropdownArr(delArr);

    }
    
    totalPrice=(Number(itemselectdata.cashPrice)).toFixed(2)
    let data = {
        itemFee: "0.00",
        inventoryDescription: itemselectdata.inventoryDescription,
        inventoryNumber: itemselectdata.inventoryNumber,
        inventoryId:itemselectdata.inventoryId,
        // salesprice: itemselectdata.totalPrice,
        type: '',
        // description: '',
        salesprice:amountdata.editNo!="1"||amountdata.salesprice=="0.00"?(Number(itemselectdata.cashPrice)).toFixed(2):amountdata.salesprice,
      
        Deliveryfee: amountdata.Deliveryfee,
        salestax: '0.00',
        totalsales: '0.00',
        reason:'',
        editNo:amountdata.editNo!=undefined?amountdata.editNo:editNo
    }
    // // let saletax = ((Number(data.salesprice) ) * 0.0825).toFixed(2);
    // let totalSaleSP = ((Number(data.salesprice) + Number(data.Deliveryfee=='select'?0.00:data.Deliveryfee)) + Number(amountdata?.salestax)).toFixed(2)
    // let dataN = { ...data, salestax: Number(amountdata.salestax).toFixed(2), totalsales: totalSaleSP }
    let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(data.salesprice) + Number(data.Deliveryfee=='select'?0:data.Deliveryfee) + Number(salesTax)
               setamountdata({...data,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2),Deliveryfee:data.Deliveryfee})

 }

    /**RIS_PC_NO_41 This useEffect is triggered when the rentalitemsale page is loaded for the edit purpose */
    useEffect(()=>{
        
        if(editresponse!=undefined && Object.keys(editresponse).length != 0)
        {
            console.log("editresponse",editresponse)

            let object=[{
                inventoryId:editresponse.inventoryId,
                inventoryNumber:editresponse.inventoryNumber,
                inventoryDescription:editresponse.inventoryDescription,
                modelNumber:editresponse.modelNumber,
                serialNumber:editresponse.serialNumber,
                conditionEn:editresponse.conditionEn,
                conditionRefCode:editresponse.conditionRefCode,
                remainingValue:editresponse.remainingValue,
                conditionId:editresponse.conditionId,
                itemFee:editresponse.itemFee,
                inventoryPriceId: editresponse.inventoryPriceId,
                cashPrice:editresponse.cashPrice,
                purchaseDate:editresponse.purchaseDate,
                purchaseCost:editresponse.purchaseCost,

                // ristype:"Rental Item Sale",
                // risNo:1,
                Deliveryfee:Number(editresponse.Deliveryfee)==0?'select':editresponse.Deliveryfee,
                salesprice:editresponse.salesprice,
                totalsales: editresponse.totalsales,
                salestax:editresponse.salestax,
                weeklyRate: editresponse.weeklyRate,
                weeklyTerm: editresponse.weeklyTerm,
                editNo:editNo
            }]
            // let object=[{
            //     inventoryId:editresponse[0].inventoryId,
            //     inventoryNumber:editresponse[0].inventoryNumber,
            //     inventoryDescription:editresponse[0].inventoryDescription,
            //     modelNumber:editresponse[0].modelNumber,
            //     serialNumber:editresponse[0].serialNumber,
            //     conditionEn:editresponse[0].conditionEn,
            //     conditionRefCode:editresponse[0].conditionRefCode,
            //     remainingValue:editresponse[0].remainingValue,
            //     conditionId:editresponse[0].conditionId,
            //     itemFee:editresponse[0].itemFee,
            //     // ristype:"Rental Item Sale",
            //     // risNo:1,
            //     Deliveryfee:editresponse[0].Deliveryfee,
            //     salesprice:editresponse[0].salesprice,
            //     totalsales: editresponse[0].totalsales,
            //     salestax:editresponse[0].salestax,
            //     weeklyRate: editresponse[0].weeklyRate,
            //     weeklyTerm: editresponse[0].weeklyTerm,
            //     editNo:editNo
            // }]
            let conditionId=""

            if (object[0].conditionEn == 'Based on DOR-system push') {
                conditionId = '1';
              } else if (object[0].conditionEn == 'Term pushed up/down by Coworker') {
                conditionId = '5';
              } else if (object[0].conditionEn == 'New - Full Term') {
                conditionId = '6';
              } else {
                conditionId ="";
              }
            setTxtValues({...TxtValues,conditionId:object[0].conditionId!=""?object[0].conditionId:conditionId,
                itemNumber:object[0].inventoryNumber,
                modelNumber:object[0].modelNumber,
                serialNumber:object[0].serialNumber,
    
                })
            setEditRisItem(object)
            settabledata(object)

            setitemselectdata({...itemselectdata,inventoryId:object[0].inventoryId, inventoryNumber:object[0].inventoryNumber,
                inventoryDescription:object[0].inventoryDescription,modelNumber:object[0].modelNumber,serialNumber:object[0].serialNumber,
                conditionEn:object[0].conditionEn,conditionRefCode:object[0].conditionRefCode,remainingValue:object[0].remainingValue,weeklyRate:object[0].weeklyRate,
                weeklyTerm:object[0].weeklyTerm,inventoryPriceId:object[0].inventoryPriceId,cashPrice:object[0].cashPrice,purchaseDate:object[0].purchaseDate,purchaseCost:object[0].purchaseCost})
            setamountdata({...amountdata,itemFee:object[0].itemFee,
                             Deliveryfee:object[0].Deliveryfee,
                             salesprice:object[0].salesprice,
                             totalsales:object[0].totalsales,
                             salestax:object[0].salestax,
                             inventoryDescription:object[0].inventoryDescription,
                             editNo:object[0].editNo
                            })
                            setriscontinuebtnFlag(false)
                            // setEditresponse(undefined)

                            history.replace({ pathname: '/payment1/rentalitemsale', 
                            state: {
                                editresponse:{},
                                    customerID:customerInfo.customerID,
                                    customerName:customerInfo.customerName,
                                    editNo:"0"} })

                                    setcancelFlag(true)

                           
           
                // searchresultgrid()
          
                // setitemselectFlag(true);
        }
    },[])




   /** RIS_PC_NO_24 The useEffect checks whether the amountdata.reason is not equal to
    * null ,if condition true the continue button in override popup is enabled.
    * 
   */

    useEffect(() => {
        ;
        if(amountdata.reason!=''&& amountdata.reason!=null)
        {
            setcontinuebtnPopupFlag(false)
        }else{
            setcontinuebtnPopupFlag(true)
        }
        // if(amountdata.salesprice!=''&&amountdata.salesprice!="0.00" && Number(amountdata.salesprice) > Number(totalPrice))
        // {
        //     setmaximumpopupFlag(true)

        // }



    }, [amountdata])

    /**RIS_PC_NO_30 Once the response is received from the second factor authentication
     * this useEffect is triggered and condition check is done.
     */

    useEffect(()=>{
        
        if(TwoFactorCompleted && !TwoFactorCancelClick&&remoteFlag==true)
        {   setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            setRemoteFlag(false)
            // riscontinuebtnOnclick()
            towFactorCompleted()
            // handleOnclick()
        }

        if(TwoFactorCompleted && !TwoFactorCancelClick && firstlogin)
        {
            setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            setFirstlogin(false)
            let response={
                inventoryId:itemselectdata.inventoryId,
                inventoryNumber:itemselectdata.inventoryNumber,
                inventoryDescription:itemselectdata.inventoryDescription,
                modelNumber:itemselectdata.modelNumber,
                serialNumber:itemselectdata.serialNumber,
                conditionEn:itemselectdata.conditionEn,
                conditionRefCode:itemselectdata.conditionRefCode,
                remainingValue:itemselectdata.remainingValue,
                weeklyRate: itemselectdata.weeklyRate,
                weeklyTerm: itemselectdata.weeklyTerm,
                inventoryPriceId: itemselectdata.inventoryPriceId,
                cashPrice:itemselectdata.cashPrice,
                purchaseDate:itemselectdata.purchaseDate,
                purchaseCost:itemselectdata.purchaseCost,
                conditionId:TxtValues.conditionId,
                itemFee:amountdata.itemFee,
                // ristype:"Rental Item Sale",
                // risNo:1,
                Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
                salesprice:amountdata.salesprice,
                totalsales: amountdata.totalsales,
                salestax:amountdata.salestax,
                DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                reason:amountdata.reason,
                editNo:amountdata.editNo




            }
            // setRisresponse(response)
            history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                          state:{
                            Risresponse:response,
                                 ristype:"Rental Item Sale",
                                 risNo:1}})


            
        }
        else if(TwoFactorCompleted && !TwoFactorCancelClick && firstlogin==false && !remoteFlag)
        {   setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            handleOnclick()
        }
        console.log("flagg",remoteFlag)

        if(!TwoFactorCompleted && TwoFactorCancelClick)
        {
            setTwoFactorCancelClick(false)
            setTwoFactorCompleted(false)
            setSecondFactorOpen(false)
            setRemoteFlag(false);
            setFirstlogin(false);

        }
        console.log("RoleFromSLAModule",RoleFromSLAModule)
    },[TwoFactorCompleted,TwoFactorCancelClick,RoleFromSLAModule])

    console.log("...Employee",TwoFactorCompleted)
    console.log("...Employee1",RoleFromSLAModule)
useEffect(()=>{
    const GettingRole = async () => {
        
        const GetcurrentUser = await GetEmployeeID();
        console.log("GetcurrentUser", GetcurrentUser);
        if (GetcurrentUser.status === 200) {
          const GetcurrentUserResponse = GetcurrentUser.data;
          const EmployeeId = GetcurrentUserResponse.employeeId;
          const GetMenuPayload = {
            coworkerId:EmployeeId,
          };
          const GetMenuDetails: any = await GetRole(GetMenuPayload);
          console.log("GetMenuDetails", GetMenuDetails);
          if (GetMenuDetails.status === 200) {
            const GetMenuDetailsResponse = GetMenuDetails.data;
            const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
            console.log("currentRole", EmployeeId);
            console.log("currentRolerole", EmployeeId);
            setCurrentRole(currentRole);
          }
        }
      };
      if (containerData !== undefined) {
        console.log("containerData", containerData);
        console.log("containerDataRole", containerData.GetRole());
        setCurrentRole(containerData.GetRole());
      } else {
        GettingRole();
      }

},[containerData])



   

   /**
    * RIS_PC_NO_13 The searchclick() function is performed inside it req object is created
    * and getInventorysearch() service call is made
    * RIS_PC_NO_14 By making the service call the datas are fetched .
    * RIS_PC_NO_15 Based on the response.status received the condition check and functionality is 
    * performed.
    */
    const searchclick = async (val) => {
        ;
        try {
            setloaderController(true);
            setGridloader(true);

        let req = {
            storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
            itemNumber: val.itemNumber == '' ? null : [val.itemNumber],
            serialNumber: val.serialNumber.trim(),
            bracket: val.bracket.trim(),
            brand: val.brand.trim(),
            modelNumber: val.modelNumber.trim(),
            poNumber: null,
            statusId: 1,
            subStatusId: [2],
            conditionId:val.conditionId==""?null:Number(val.conditionId),
            department: "",
            subDepartment: "",
            setNumber: 0,
            isActivePriceTag: true

        }
        console.log("IDD",req)
        let req1 = {
            "storeNumbers": [
                "04741"
            ],
            "itemNumber": null,
            "serialNumber": "",
            "bracket": "",
            "brand": "",
            "modelNumber": "",
            "poNumber": null,
            "statusId": 1,
            "subStatusId": null,
            "conditionId": null,
            "department": "",
            "subDepartment": "",
            "setNumber": 0,
            "isActivePriceTag": false
        }
        // val.conditionId == 0 ? null : parseInt(val.conditionId)
        setitemselectFlag(false)
        setCustomerInfo({...customerInfo,editNo:"0"})
        setamountdata({...amountdata,Deliveryfee:"0.00",salesprice:"0.00",totalsales:"0.00"})
       
        setriscontinuebtnFlag(true)
        setrequest(req)
        const response: any = await getInventorySearch(req)
        // setloaderController(true);
        //   setGridloader(true);
        //   setTimeout(loaddata,6000)
        // settabledata(data)

            if (response.status == 400 || response.status == 500) {
                setinternalServerController(true);
                setloaderController(false);
            } else if (response.status == 200 && response.data.response.length > 0) {
                //setsearchResultContext(response.data.response);
                setloaderController(false);
                // eslint-disable-next-line no-console
                console.log('Response from api', response);

                const ApiResult: any = response.data.response;
                // eslint-disable-next-line no-console
                console.log('response', ApiResult);
                // eslint-disable-next-line no-console
                console.log('response-array', ApiResult[0]);
                if(updatedInventory.length!=0)
                {
                    let tabledata1= ApiResult.filter((x)=>!updatedInventory.includes(x.inventoryNumber))
                    settabledata(tabledata1);
                    if(tabledata1.length<=0)
                    {
                        setcontrolRecord(true);
                        setloaderController(false);
                    }
                    console.log("tabledata",updatedInventory,tabledata1)
                }
                else{
                    settabledata(ApiResult);
                }

                // settabledata(ApiResult);
            } else if (response.data.response.length <= 0) {
                setcontrolRecord(true);
                setloaderController(false);
            }

            if (updatedInventory.length != 0) {
                let tabledata1 = response.data.response.filter((x) => !updatedInventory.includes(x.inventoryNumber))
                settabledata(tabledata1);
                console.log("tabledataNew", updatedInventory, tabledata1)
            }
            else {
                settabledata(response.data.response);
            }
            console.log("111res", tabledata)
        }
        catch {
            setinternalServerController(true);
            setloaderController(false);
        }
    }
    // const loaddata=()=>{
    //     settabledata(data)
    //     setloaderController(false);
    //       setGridloader(false);

    // }

    //CanclelBtn() is triggered when co-worker clicks the cancel button in Rental Item Sale page
    const cancelBtn = () => {
        ;
        // history.push({ pathname: `/payment1/paymentinformation/${customerID}/0`})
         if(!cancelFlag)
         {
            // setRisresponse(undefined)
            history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                      state:{
                        Risresponse:undefined,
                             ristype:"Rental Item Sale",
                             risNo:0}})

         }
         else 
        //  if(cancelFlag && !itemselectFlag)
         {
            let response={
                inventoryId:editRisItem[0].inventoryId,
                inventoryNumber:editRisItem[0].inventoryNumber,
                inventoryDescription:editRisItem[0].inventoryDescription,
                modelNumber:editRisItem[0].modelNumber,
                serialNumber:editRisItem[0].serialNumber,
                conditionEn:editRisItem[0].conditionEn,
                remainingValue:editRisItem[0].remainingValue,
                conditionRefCode:editRisItem[0].conditionRefCode,
                weeklyRate: editRisItem[0].weeklyRate,
                weeklyTerm: editRisItem[0].weeklyTerm,
                inventoryPriceId: editRisItem[0].inventoryPriceId,
                cashPrice:editRisItem[0].cashPrice,
                purchaseDate:editRisItem[0].purchaseDate,
                purchaseCost:editRisItem[0].purchaseCost,
                conditionId:editRisItem[0].conditionId,
                itemFee:editRisItem[0].itemFee,
                // ristype:"Rental Item Sale",
                // risNo:1,
                Deliveryfee:editRisItem[0].Deliveryfee=="select"?0:editRisItem[0].Deliveryfee,
                salesprice:editRisItem[0].salesprice,
                totalsales: editRisItem[0].totalsales,
                salestax:editRisItem[0].salestax,
                reason:editRisItem[0].reason,
                DeliverfeeTax:editRisItem[0].DeliverfeeTax==""?0:editRisItem[0].DeliverfeeTax,
                editNo:editRisItem[0].editNo
    
    
    
    
            }
            // setRisresponse(response)
            history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                          state:{
                            Risresponse:response,
                                 ristype:"Rental Item Sale",
                                 risNo:1}})

            
         }
        //  else if(cancelFlag && itemselectFlag){
        //     let response={
        //         inventoryId:itemselectdata.inventoryId,
        //         inventoryNumber:itemselectdata.inventoryNumber,
        //         inventoryDescription:itemselectdata.inventoryDescription,
        //         modelNumber:itemselectdata.modelNumber,
        //         serialNumber:itemselectdata.serialNumber,
        //         conditionEn:itemselectdata.conditionEn,
        //         remainingValue:itemselectdata.remainingValue,
        //         conditionRefCode:itemselectdata.conditionRefCode,
        //         weeklyRate: itemselectdata.weeklyRate,
        //         weeklyTerm: itemselectdata.weeklyTerm,
        //         inventoryPriceId: itemselectdata.inventoryPriceId,
        //         cashPrice:itemselectdata.cashPrice,
        //         purchaseDate:itemselectdata.purchaseDate,
        //         purchaseCost:itemselectdata.purchaseCost,
        //         conditionId:TxtValues.conditionId,
        //         itemFee:amountdata.itemFee,
        //         // ristype:"Rental Item Sale",
        //         // risNo:1,
        //         Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
        //         salesprice:amountdata.salesprice,
        //         totalsales: amountdata.totalsales,
        //         salestax:amountdata.salestax,
        //         reason:amountdata.reason,
        //         DeliverfeeTax:amountdata.DeliverfeeTax==""?0:amountdata.DeliverfeeTax,
        //         editNo:amountdata.editNo
        
        //     }
        //     // setRisresponse(response)
        //     history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
        //                   state:{
        //                     Risresponse:response,
        //                          ristype:"Rental Item Sale",
        //                          risNo:1}})

            
        //  }
       
    }
    /**RIS_PC_NO_22 The risRadiobtnonclick() function is triggered when radio
     * button is checked.
     */

    const risRadiobtnonclick = (value) => {
        ;
        // if (itemselectFlag == false) {
            setDeliveryfeeFlag(true)
            const radiobt: any = arrradio.map((val: any, indexNew: any) => {
                return value == indexNew ? !val : false
            })
            setarrradio([...radiobt]);
            tabledata.map((val: any, indexNew: any) => {
                if (value == indexNew && radiobt[value] == true) {
                    setamountdata({...amountdata,Deliveryfee:"select",salesprice:"0.00",totalsales:"0.00",salestax:"0.00"})
                    setitemselectdata(val)

                }
                else if (value == indexNew && radiobt[value] == false) {
                    radiobt[value] = true
                    setarrradio([...radiobt])
                
                }
            })
            
            setriscontinuebtnFlag(false)



        // }
    }

    /**RIS_PC_NO_25 While clicking the delete icon in the selected item the deletClick() function
     * is triggered
     */
    const deleClick = () => {
        let radiobtn: any = arrradio.map((val, index) => {
            return val == true ? !val : false
        })
        setarrradio([...radiobtn])
        setitemselectdata({
            "inventoryId": "",
            "inventoryNumber": "",
            "inventoryDescription": "",
            "storeNumber": "",
            "departmentsEn": "",
            "departmentsEs": null,
            "subDepartmentsEn": "",
            "subDepartmentsEs": null,
            "brandName": "",
            "bracketEn": "",
            "bracketEs": null,
            "statusEn": "",
            "statusEs": null,
            "conditionEn": "",
            "conditionEs": "",
            "remainingValue": "",
            "daysIdle": 0,
            "serialNumber": "",
            "modelNumber": "",
            "conditionRefCode": "",
            "rmsItemMasterId": "",
            "inventoryPriceId": "",
            "weeklyRate": "",
            "weeklyTerm": 0
        })
        setitemselectFlag(false)
        setriscontinuebtnFlag(true)

    }
    console.log("selected", itemselectdata)
/**
 * RIS_PC_NO_16 The searchresultgrid() function is defined here where the 
 * DynamicGridComponent is rendered the tabledata ,request are sended as props 
 */
    const searchresultgrid = () => {
        ;
        console.log('Came inside Grid Method');
        if (tabledata !== undefined && tabledata.length > 0) {
            console.log('Inside table bind', tabledata);
            return (
                <div>
                    <DynamicGridComponent
                        // gridtype="Normal"
                        // id="packagePriceItems"
                        tabledata={tabledata}
                        Request={request}
                        settabledata={settabledata}
                        
                        itemselectFlag={itemselectFlag}
                        setitemselectFlag={setitemselectFlag}
                        customerInfo={customerInfo}
                        editresponse={editresponse}
                        itemselectdata={itemselectdata}
                        arrradio={arrradio}
                        setarrradio={setarrradio}
                        risRadiobtnonclick={(e) => risRadiobtnonclick(e)}
                    // order={sortDirection.ASC}
                    // throwStyle={classes}
                    ></DynamicGridComponent>

                </div>
            );
        } else if (internalServerController) {
            return (
                <div>
                    <Typography className={`${classes.title2} ${classes1.mt3}  ${classes.srmargin}`}>
                        Search Results
                    </Typography>
                    <div className={`${classes.textCenter} ${classes1.mt5}`}>
                        <AlertIcon />
                        <Typography className={`${classes1.subTitle} ${classes.mb3}`}>
                            Something Went Wrong
                        </Typography>
                    </div>
                </div>
            );
        } else if (controlRecord) {
            return (
                <div>
                    <Typography className={`${classes.title2} ${classes1.mt3}  ${classes.srmargin}`}>
                        Search Results
                    </Typography>
                    <div className={`${classes.textCenter} ${classes1.mt5}`}>
                        <NoRecord />
                        <Typography className={`${classes1.subTitle} ${classes.mb3}`}>
                            Search criteria did not return any results
                        </Typography>
                    </div>
                </div>
            );
        }
    };

  /**RIS_PC_NO_22 This popup is rendered while selecting the New condition inventory item */

    const alertpopup = () => {
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    <AlertIcon ></AlertIcon>
                    <Typography className={classes2.racErrorFunction}>
                        No price changes are allowed for New Items.
                    </Typography>
                </Grid>
                <Grid className={classes2.textcenter}>
                    <RACButton
                        autoFocus
                        variant="contained"
                        color="primary"
                        className={classes.me2N}
                        onClick={() => setalertpopupFlag(false)}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );
    }
  /**RIS_PC_NO_33 once the handleOnclick() function is triggered this popup is rendered */
    const alertinfopopup = () => {
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    {/* <AlertIcon ></AlertIcon> */}
                    <Typography className={classes2.racErrorFunction}>
                        Sale Price is outside business parameters and will create an exception. Do you wish to continue?
                    </Typography>
                </Grid>
                <Grid container  style={{marginLeft:"10px"}}>
                    <Grid item md={4} className={classes.floatLeft}>
                        <RACButton
                         style={{marginLeft:"100px"}}
                            variant="outlined"
                            color="primary"
                            className={`${classes.me1} ${classes.foc}`}
                            onClick={() => yesBtn()}
                        >
                            Yes
                        </RACButton>
                    </Grid>
                    <Grid  item md={4}className={classes.floatRight}>

                       
                        <RACButton
                           style={{marginLeft:"90px", padding:"9% 20%"}}
                            variant="contained"
                            color="primary"
                            className={`${classes.me1} ${classes.foc}`}
                            onClick={()=>{noBtn("NO")}}
                        >
                            No
                        </RACButton>
                    </Grid>

                </Grid>

            </div>
        )

    }
    //RIS_PC_NO_34  In alertInfo popup when click the yes button the yesbtn() is triggered
    const  yesBtn=()=>{
        setalertinfopopupFlag(false)
        
        let response={
            inventoryId:itemselectdata.inventoryId,
            inventoryNumber:itemselectdata.inventoryNumber,
            inventoryDescription:itemselectdata.inventoryDescription,
            modelNumber:itemselectdata.modelNumber,
            serialNumber:itemselectdata.serialNumber,
            conditionEn:itemselectdata.conditionEn,
            remainingValue:itemselectdata.remainingValue,
            conditionRefCode:itemselectdata.conditionRefCode,
            weeklyRate: itemselectdata.weeklyRate,
            weeklyTerm: itemselectdata.weeklyTerm,
            inventoryPriceId: itemselectdata.inventoryPriceId,
            cashPrice:itemselectdata.cashPrice,
            purchaseDate:itemselectdata.purchaseDate,
            purchaseCost:itemselectdata.purchaseCost,
            conditionId:TxtValues.conditionId,
            itemFee:amountdata.itemFee,
            // ristype:"Rental Item Sale",
            // risNo:1,
            Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
            salesprice:amountdata.salesprice,
            totalsales: amountdata.totalsales,
            salestax:amountdata.salestax,
            DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
            reason:amountdata.reason




        }
        // setRisresponse(response)
        history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                      state:{
                        Risresponse:response,
                             ristype:"Rental Item Sale",
                             risNo:1}})

        // setoverridepopupflag(true)   
     }


 /**RIS_PC_NO_26 when co-worker click the ok button noBtn() is triggered with "maximum" as 
  * argument.
   */
    const noBtn=async(val)=>{
        
        if(val=="default")
        {
            let data={
                itemFee: "0.00",
                inventoryDescription: itemselectdata.inventoryDescription,
                inventoryNumber: itemselectdata.inventoryNumber,
                inventoryId:itemselectdata.inventoryId,
                type: '',
                description: '',
                salestax: '0.00',
                totalsales: '0.00',
                reason:'',
                salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
                Deliveryfee:"select"
            }
            let  inventoryInfo= [
                {
                    "inventoryId": Number(itemselectdata.inventoryId),
                    "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                }
            ]
            const GetPriceQuoteReq: any = {
                // schedule: null,
                // dueDate: null,
                storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                customerId: Number(customerID),
                agreementType:'RIS',
                fees:[],
                // agreementRate: Number(itemselectdata.weeklyRate),
                // agreementTerm: Number(itemselectdata.weeklyTerm),
                // isLDWapplied: true,
                // isClubApplied: true,
                // tireplusPolicy: false,
                sacTotal: Number(data.salesprice),
                // extensionDays: 0,
                inventoryInfo: inventoryInfo,
                // totalCost: String(Number(itemselectdata.weeklyRate) * Number(itemselectdata.weeklyTerm)),
              };
              setLoadPriceCard(true)
            const response = await getPriceQuote( GetPriceQuoteReq)
            setLoadPriceCard(false)
            console.log("response",response);
            if(response!=undefined)
            {
                let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(amountdata.salesprice) + Number(data.Deliveryfee=="select"?0:data.Deliveryfee) + Number(salesTax)
               setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2),Deliveryfee:data.Deliveryfee})
            }
            // let saletax = ((Number(data.salesprice)) * 0.0825).toFixed(2);
            // let totalSaleSP = ((Number(data.salesprice) + Number(data.Deliveryfee)) + Number(saletax)).toFixed(2)
            // let dataN = { ...data,salesprice:data.salesprice, salestax: Number(saletax).toFixed(2), totalsales: totalSaleSP }
            // setamountdata(dataN)
    

        }
        else if(val=="minimum")
        {

            let data={
                itemFee: "0.00",
                inventoryDescription: itemselectdata.inventoryDescription,
                inventoryNumber: itemselectdata.inventoryNumber,
                inventoryId:itemselectdata.inventoryId,
                // salesprice: itemselectdata.totalPrice,
                type: '',
                description: '',
                reason:amountdata.reason,
                salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
                Deliveryfee:amountdata.Deliveryfee
            }
            let  inventoryInfo= [
                {
                    "inventoryId": Number(itemselectdata.inventoryId),
                    "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                }
            ]
            const GetPriceQuoteReq: any = {
                storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                customerId: Number(customerID),
                agreementType:'RIS',
                fees:deliveryFeeApplicable==1 && amountdata.Deliveryfee !="select"?[
                    {
                     feeType:"DELI",
                     feeAmount:String(amountdata.Deliveryfee)
                    }
                ]:[],
                // agreementRate: Number(itemselectdata.weeklyRate),
                // agreementTerm: Number(itemselectdata.weeklyTerm),
                // isLDWapplied: true,
                // isClubApplied: true,
                // tireplusPolicy: false,
                sacTotal: Number(data.salesprice),
                // extensionDays: 0,
                inventoryInfo: inventoryInfo,
                // totalCost: String(Number(itemselectdata.weeklyRate) * Number(itemselectdata.weeklyTerm)),
              };
              setminimumpopupFlag(false)
              setLoadPriceCard(true)
            const response = await getPriceQuote( GetPriceQuoteReq)
            setLoadPriceCard(false)
            console.log("response",response);
            if(response!=undefined)
            {
                let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(data.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
               setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
            }
            // let saletax = ((Number(data.salesprice) +Number(data.itemFee)) * 0.0825).toFixed(2);
            // let totalSaleSP = ((Number(data.salesprice) + Number(data.Deliveryfee)) + Number(saletax)).toFixed(2)
            // let dataN = { ...data,salesprice:data.salesprice, salestax:saletax, totalsales: totalSaleSP }
    
        //  setamountdata(dataN)
        
        // }

        }
        else if(val=="maximum")
        {
            let data={
                itemFee: "0.00",
                inventoryDescription: itemselectdata.inventoryDescription,
                inventoryNumber: itemselectdata.inventoryNumber,
                inventoryId:itemselectdata.inventoryId,
                type: '',
                reason:amountdata.reason,
                salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
                Deliveryfee:amountdata.Deliveryfee
            }
            let  inventoryInfo= [
                {
                    "inventoryId": Number(itemselectdata.inventoryId),
                    "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                }
            ]
            const GetPriceQuoteReq: any = {
                storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                customerId: Number(customerID),
                agreementType:'RIS',
                fees:deliveryFeeApplicable==1 && amountdata.Deliveryfee !="select"?[
                    {
                     feeType:"DELI",
                     feeAmount:String(amountdata.Deliveryfee)
                    }
                ]:[],
                // agreementRate: Number(itemselectdata.weeklyRate),
                // agreementTerm: Number(itemselectdata.weeklyTerm),
                // isLDWapplied: true,
                // isClubApplied: true,
                // tireplusPolicy: false,
                sacTotal: Number(data.salesprice),
                // extensionDays: 0,
                inventoryInfo: inventoryInfo,
                // totalCost: String(Number(itemselectdata.weeklyRate) * Number(itemselectdata.weeklyTerm)),
              };
              setmaximumpopupFlag(false)
              setLoadPriceCard(true)
            const response = await getPriceQuote( GetPriceQuoteReq)
            setLoadPriceCard(false)
            console.log("response",response);
            if(response!=undefined)
            {
                let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
               let totalSale=Number(data.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
               setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
            }
            // let saletax = ((Number(data.salesprice) +Number(data.itemFee)+ Number(data.Deliveryfee)) * 0.0825).toFixed(2);
            // let totalSaleSP = ((Number(data.salesprice) + Number(data.Deliveryfee)) + Number(saletax)).toFixed(2)
            // let dataN = { ...data,salesprice:data.salesprice, salestax:saletax, totalsales: totalSaleSP }
    
        //  setamountdata(dataN)
         

        }
        else if(val=="NO")
        {
        let data={
            itemFee: "0.00",
            inventoryDescription: itemselectdata.inventoryDescription,
            inventoryNumber: itemselectdata.inventoryNumber,
            inventoryId:itemselectdata.inventoryId,
            // salesprice: itemselectdata.totalPrice,
            type: '',
            salestax: '0.00',
            totalsales: '0.00',
            reason:'',
            salesprice:Number(Number(itemselectdata.cashPrice)).toFixed(2),
            Deliveryfee:amountdata.Deliveryfee
        }
        let  inventoryInfo= [
            {
                "inventoryId": Number(itemselectdata.inventoryId),
                "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
            }
        ]
        const GetPriceQuoteReq: any = {
            storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
            customerId: Number(customerID),
            agreementType:'RIS',
            fees:[],
            // agreementRate: Number(itemselectdata.weeklyRate),
            // agreementTerm: Number(itemselectdata.weeklyTerm),
            // isLDWapplied: true,
            // isClubApplied: true,
            // tireplusPolicy: false,
            sacTotal: Number(data.salesprice),
            // extensionDays: 0,
            inventoryInfo: inventoryInfo,
            // totalCost: String(Number(itemselectdata.weeklyRate) * Number(itemselectdata.weeklyTerm)),
          };
          setalertinfopopupFlag(false)
          setLoadPriceCard(true)
        const response = await getPriceQuote( GetPriceQuoteReq)
        setLoadPriceCard(false)
        console.log("response",response);
        if(response!=undefined)
        {
            let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
           let totalSale=Number(amountdata.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
           setamountdata({...amountdata,salesprice:data.salesprice,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
        }
    //     let saletax = ((Number(data.salesprice) + Number(data.Deliveryfee)) * 0.0825).toFixed(2);
    //     let totalSaleSP = ((Number(data.salesprice) + Number(data.Deliveryfee)) + Number(saletax)).toFixed(2)
    //     let dataN = { ...data,salesprice:data.salesprice, salestax: Number(saletax).toFixed(2), totalsales: totalSaleSP }

    //  setamountdata(dataN)
     
    }

    }
    console.log("***",amountdata)

    /**RIS_PC_NO_34 This popup is rendered co-worker clicks yes button in alertInfo popup.
     * triggered
     */

    const overridepopup=()=>{
        return (
            <div>
                <Grid className={classes.textCenter}>
                    {/* <AlertIcon ></AlertIcon> */}
                    <Typography  variant='h5'
                    style={{marginLeft:"-29%",marginBottom:"1%"}}
                    className={`${classes2.racErrorFunction}${classes.description}`}>
                    Enter reason in Sale price override reason.
                    </Typography>
                    <TextareaAutosize                                    
                                      name='reason'
                                      minRows={5}
                                      className={`${classes.textAreaN} `}
                                       value={amountdata.reason}
                                      onChange={(e:any) => selecteditemHandlechange(e,'reason')}
                                      />
                </Grid>
                <Grid container  style={{marginLeft:"10px"}}>
                    

                    <Grid  className={classes.floatRight}>                 
                        <RACButton
                           style={{marginLeft:"330px", padding:"6px 5%"}}
                            variant="contained"
                            color="primary"
                            disabled={continuebtnPopupFlag}
                            className={`${classes.me1} ${classes.foc}`}
                            onClick={()=>{overridepopupContinueBtn()}}
                        >
                         Continue
                        </RACButton>
                    </Grid>
                    <Grid item className={classes.floatRight}>
                    <RACButton
                        style={{marginLeft:"50px", padding:"6% 12%"}}
                        variant="outlined"
                        color="primary"
                        className={`${classes.me1} ${classes.foc}`}
                        onClick={() => setoverridepopupflag(false)}
                    >
                        Cancel
                    </RACButton>
                </Grid>

                </Grid>

            </div>
        )


    }

    /**RIS_PC_NO_34 If the sales price is less than the remaining value then the minimumpopup() 
     * is rendered.
     */

    const minimumpopup=()=>{
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    {/* <AlertIcon ></AlertIcon> */}
                    <Typography className={classes2.racErrorFunction}>
                    Invalid Sales Price detected! The minimum sales price for your access level is ${price}
                    </Typography>
                </Grid>
                <Grid className={classes2.textcenter}>
                    <RACButton
                        autoFocus
                        variant="contained"
                        color="primary"
                        className={classes.me2N}
                        onClick={() =>{noBtn("minimum")}}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );

    }
   /**RIS_PC_NO_26 This popup is rendered when sales price entered greater than total price */
    const maximumpopup=()=>{
        return (
            <div>
                <Grid className={classes2.textcenter}>
                    {/* <AlertIcon ></AlertIcon> */}
                    <Typography className={classes2.racErrorFunction}>
                    Sale price is over the maximum value. The sale price must be less than or equal to ${totalPrice}.
                     The sale price will be updated with this maximum value
                    </Typography>
                </Grid>
                <Grid className={classes2.textcenter}>
                    <RACButton
                        autoFocus
                        variant="contained"
                        color="primary"
                        className={classes.me2N}
                        onClick={() =>noBtn("maximum")}
                    >
                        OK
                    </RACButton>
                </Grid>
            </div>
        );

    }

   /**
    * RIS_PC_NO_33 The handleOnclick function is triggedred  once the twofactor authentication completed
    * RIS_PC_NO_35 This function is triggered inside the twoFactorCompleted() based on condition
   */

    const handleOnclick = () => {
        
        
        if (itemselectdata.conditionRefCode == "EXCP" || itemselectdata.conditionRefCode == "ATO") {
            if(Number(amountdata.salesprice)!=Number(totalPrice))
            {
                let response={
                    inventoryId:itemselectdata.inventoryId,
                    inventoryNumber:itemselectdata.inventoryNumber,
                    inventoryDescription:itemselectdata.inventoryDescription,
                    modelNumber:itemselectdata.modelNumber,
                    serialNumber:itemselectdata.serialNumber,
                    conditionEn:itemselectdata.conditionEn,
                    remainingValue:itemselectdata.remainingValue,
                    conditionRefCode:itemselectdata.conditionRefCode,
                    weeklyRate: itemselectdata.weeklyRate,
                    weeklyTerm: itemselectdata.weeklyTerm,
                    inventoryPriceId: itemselectdata.inventoryPriceId,
                    cashPrice:itemselectdata.cashPrice,
                    purchaseDate:itemselectdata.purchaseDate,
                    purchaseCost:itemselectdata.purchaseCost,
                    conditionId:TxtValues.conditionId,
                    itemFee:amountdata.itemFee,
                    // ristype:"Rental Item Sale",
                    // risNo:1,
                    Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
                    salesprice:amountdata.salesprice,
                    totalsales: amountdata.totalsales,
                    salestax:amountdata.salestax,
                    DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                    reason:amountdata.reason
        
                }
                // setRisresponse(response)
                history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                              state:{
                                Risresponse:response,
                                     ristype:"Rental Item Sale",
                                     risNo:1}})
            }
            // setalertinfopopupFlag(true);
        }
        else{
            setalertinfopopupFlag(false);
        }
    }

    /**
     * 
     * RIS_PC_NO_25 when co-worker clicks the continue button this function is triggered
     * 
     * RIS_PC_NO_26 Inside the condition check is done 
     * 
     * RIS_PC_NO_27 Condition check is done whether salesprice not equal to total price, and seconFactoropen state variable updated as true.
     * 
     */

    const riscontinuebtnOnclick=()=>{
        
        const rvAmount=Number(itemselectdata.remainingValue)/2 + Number(itemselectdata.remainingValue)

        if(Number(amountdata.salesprice) !==Number(totalPrice) && Number(amountdata.salesprice) > Number(totalPrice) )
        {
            setmaximumpopupFlag(true);
        }
        else 
        if(Number(amountdata.salesprice) !==Number(totalPrice)&& Number(amountdata.salesprice) < Number(totalPrice))
        {

            // setSecondFactorOpen(true)

            // if(CurrentRole=="DMT" || CurrentRole=="DM" || CurrentRole=="RD")
            // {

            // }
            // setPin(1);
            // setTwoFactorCompleted(true)
            if(CurrentRole=="DMT"||CurrentRole=="DM"||CurrentRole=="RD")
            {
                if(Number(amountdata.salesprice)== 0.00 )
                {
                    price="0.01"
                    setprice(price)
                    setminimumpopupFlag(true)

                }else
                 if(Number(amountdata.salesprice) > rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice)-0.01)
                {   setPin(0);
                    setFirstlogin(true)
                    setSecondFactorOpen(true)
                  
                               
                }else if(Number(amountdata.salesprice) >= 0.01 && Number(amountdata.salesprice)<=rvAmount)
                {
                    // handleOnclick()
                    setPin(0);
                    setFirstlogin(false)
                    setSecondFactorOpen(true)

                }
            }else if(CurrentRole=="CAR"||CurrentRole=="AM"||CurrentRole=="LAM"){
                
                if(Number(amountdata.salesprice)>=Number(itemselectdata.remainingValue) &&
                   Number(amountdata.salesprice)<=Number(totalPrice))
                   {
                    setPin(0);
                    setFirstlogin(false)
                    setSecondFactorOpen(true)
                    // handleOnclick()
                   }
                   else if(Number(amountdata.salesprice)<Number(itemselectdata.remainingValue)){
                    setPin(1);
                    setRemoteFlag(true)
                    setSecondFactorOpen(true)
                    // setRemoteFlag
                    // price=itemselectdata.remainingValue
                    // setprice(price)
                    // setminimumpopupFlag(true)
                    // setSecondFactorOpen(true)
                   }
            }else if(CurrentRole=="SM"||CurrentRole=="SSM"){
                if(Number(amountdata.salesprice)>=(Number(itemselectdata.remainingValue)/2 )&&
                Number(amountdata.salesprice)<= rvAmount)
                {
                    setPin(0);
                    setFirstlogin(false)
                    setSecondFactorOpen(true)
                //  handleOnclick()
                }else if(Number(amountdata.salesprice)> rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice))
                {
                    setPin(0);
                    setFirstlogin(true)
                    setSecondFactorOpen(true)

                }
                else  if(Number(amountdata.salesprice)<(Number(itemselectdata.remainingValue)/2 )){
                    // price=String(itemselectdata.remainingValue/2)
                    // setprice(price)
                    // setminimumpopupFlag(true)
                    setPin(1);
                    // setFirstlogin(false)
                    setRemoteFlag(true)
                    setSecondFactorOpen(true)
                }


            }

        }
        else{

            let response={
                inventoryId:itemselectdata.inventoryId,
                inventoryNumber:itemselectdata.inventoryNumber,
                inventoryDescription:itemselectdata.inventoryDescription,
                modelNumber:itemselectdata.modelNumber,
                serialNumber:itemselectdata.serialNumber,
                conditionEn:itemselectdata.conditionEn,
                conditionRefCode:itemselectdata.conditionRefCode,
                remainingValue:itemselectdata.remainingValue,
                weeklyRate: itemselectdata.weeklyRate,
                weeklyTerm: itemselectdata.weeklyTerm,
                inventoryPriceId: itemselectdata.inventoryPriceId,
                cashPrice:itemselectdata.cashPrice,
                purchaseDate:itemselectdata.purchaseDate,
                purchaseCost:itemselectdata.purchaseCost,
                conditionId:TxtValues.conditionId,
                itemFee:amountdata.itemFee,
                // ristype:"Rental Item Sale",
                // risNo:1,
                Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
                salesprice:amountdata.salesprice,
                totalsales: amountdata.totalsales,
                salestax:amountdata.salestax,
                DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                reason:amountdata.reason,
                editNo:amountdata.editNo




            }
            // setRisresponse(response)
            history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                          state:{
                            Risresponse:response,
                                 ristype:"Rental Item Sale",
                                 risNo:1}})

        }
        setcancelFlag(false)
    
       }

       /**RIS_PC_NO_31 This function is triggered when two factor authentication over.
        * RIS_PC_NO_32 The condition check is done whether salesprice is equal to 0.00 and else if condition is performed
        * RIS_PC_NO_33
        * RIS_PC_NO_35 The condition check is done based on role CAR,LAM,AM
        * RIS_PC_NO_36 The condition check is done based on role SM,SSM
        * RIS_PC_NO_37 else part is performed and the response is send to the payment page.
        */

       const towFactorCompleted=()=>{
        ;
        const rvAmount=Number(itemselectdata.remainingValue)/2 + Number(itemselectdata.remainingValue)

        if(Number(amountdata.salesprice) !==Number(totalPrice) && Number(amountdata.salesprice) > Number(totalPrice) )
        {
            setmaximumpopupFlag(true);
        }
        else if(Number(amountdata.salesprice) !==Number(totalPrice)&& Number(amountdata.salesprice) < Number(totalPrice))
        {
            if(slaRole=="DMT"||slaRole=="DM"||slaRole=="RD")
            {
                if(Number(amountdata.salesprice)== 0.00 )
                {
                    price="0.01"
                    setprice(price)
                    setminimumpopupFlag(true)

                }else
                 if(Number(amountdata.salesprice) > rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice)-0.01)
                {
                    // setSecondFactorOpen(true)
                    let response={
                        inventoryId:itemselectdata.inventoryId,
                        inventoryNumber:itemselectdata.inventoryNumber,
                        inventoryDescription:itemselectdata.inventoryDescription,
                        modelNumber:itemselectdata.modelNumber,
                        serialNumber:itemselectdata.serialNumber,
                        conditionEn:itemselectdata.conditionEn,
                        conditionRefCode:itemselectdata.conditionRefCode,
                        remainingValue:itemselectdata.remainingValue,
                        weeklyRate: itemselectdata.weeklyRate,
                        weeklyTerm: itemselectdata.weeklyTerm,
                        inventoryPriceId: itemselectdata.inventoryPriceId,
                        cashPrice:itemselectdata.cashPrice,
                        purchaseDate:itemselectdata.purchaseDate,
                        purchaseCost:itemselectdata.purchaseCost,
                        conditionId:TxtValues.conditionId,
                        itemFee:amountdata.itemFee,
                        // ristype:"Rental Item Sale",
                        // risNo:1,
                        Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
                        salesprice:amountdata.salesprice,
                        totalsales: amountdata.totalsales,
                        salestax:amountdata.salestax,
                        DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                        reason:amountdata.reason,
                        editNo:amountdata.editNo    
                    }
                    // setRisresponse(response)
                    history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                                  state:{
                                    Risresponse:response,
                                         ristype:"Rental Item Sale",
                                         risNo:1}})
                    
                  
                               
                }else if(Number(amountdata.salesprice) >= 0.01 && Number(amountdata.salesprice)<=rvAmount)
                {
                    handleOnclick()
                }
            }else if(slaRole=="CAR"||slaRole=="AM"||slaRole=="LAM"){
                
                if(Number(amountdata.salesprice)>=Number(itemselectdata.remainingValue) &&
                   Number(amountdata.salesprice)<=Number(totalPrice))
                   {
                    handleOnclick()
                   }
                   else if(Number(amountdata.salesprice)<Number(itemselectdata.remainingValue)){
                    price=itemselectdata.remainingValue
                    setprice(price)
                    setminimumpopupFlag(true)
                    // setSecondFactorOpen(true)
                   }
            }else if(slaRole=="SM"||slaRole=="SSM"){
                if(Number(amountdata.salesprice)>=(Number(itemselectdata.remainingValue)/2 )&&
                Number(amountdata.salesprice)<= rvAmount)
                {
                 handleOnclick()
                }else if(Number(amountdata.salesprice)> rvAmount&& Number(amountdata.salesprice)<=Number(totalPrice))
                {
                    let response={
                        inventoryId:itemselectdata.inventoryId,
                        inventoryNumber:itemselectdata.inventoryNumber,
                        inventoryDescription:itemselectdata.inventoryDescription,
                        modelNumber:itemselectdata.modelNumber,
                        serialNumber:itemselectdata.serialNumber,
                        conditionEn:itemselectdata.conditionEn,
                        conditionRefCode:itemselectdata.conditionRefCode,
                        remainingValue:itemselectdata.remainingValue,
                        weeklyRate: itemselectdata.weeklyRate,
                        weeklyTerm: itemselectdata.weeklyTerm,
                        inventoryPriceId: itemselectdata.inventoryPriceId,
                        cashPrice:itemselectdata.cashPrice,
                        purchaseDate:itemselectdata.purchaseDate,
                        purchaseCost:itemselectdata.purchaseCost,
                        conditionId:TxtValues.conditionId,
                        itemFee:amountdata.itemFee,
                        // ristype:"Rental Item Sale",
                        // risNo:1,
                        Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
                        salesprice:amountdata.salesprice,
                        totalsales: amountdata.totalsales,
                        salestax:amountdata.salestax,
                        DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                        reason:amountdata.reason,
                        editNo:amountdata.editNo
                    }
                    // setRisresponse(response)
                    history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                                  state:{
                                    Risresponse:response,
                                         ristype:"Rental Item Sale",
                                         risNo:1}})
                }
                else  if(Number(amountdata.salesprice)<(Number(itemselectdata.remainingValue)/2 )){
                    price=String(itemselectdata.remainingValue/2)
                    setprice(price)
                    setminimumpopupFlag(true)
                    // setSecondFactorOpen(true)
                }


            }

        }else{

            let response={
                inventoryId:itemselectdata.inventoryId,
                inventoryNumber:itemselectdata.inventoryNumber,
                inventoryDescription:itemselectdata.inventoryDescription,
                modelNumber:itemselectdata.modelNumber,
                serialNumber:itemselectdata.serialNumber,
                conditionEn:itemselectdata.conditionEn,
                conditionRefCode:itemselectdata.conditionRefCode,
                remainingValue:itemselectdata.remainingValue,
                weeklyRate: itemselectdata.weeklyRate,
                weeklyTerm: itemselectdata.weeklyTerm,
                inventoryPriceId: itemselectdata.inventoryPriceId,
                cashPrice:itemselectdata.cashPrice,
                purchaseDate:itemselectdata.purchaseDate,
                purchaseCost:itemselectdata.purchaseCost,
                conditionId:TxtValues.conditionId,
                itemFee:amountdata.itemFee,
                // ristype:"Rental Item Sale",
                // risNo:1,
                Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
                salesprice:amountdata.salesprice,
                totalsales: amountdata.totalsales,
                salestax:amountdata.salestax,
                DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
                reason:amountdata.reason,
                editNo:amountdata.editNo




            }
            // setRisresponse(response)
            history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                          state:{
                            Risresponse:response,
                                 ristype:"Rental Item Sale",
                                 risNo:1}})

        }
        setcancelFlag(false)
    

      


       }
    
   /**RIS_PC_NO_24  selecteditemHandlechange() function is triggered when the value is entered in the 
    * sales price text box
   */
    const selecteditemHandlechange = async(val, name) => {
        ;
        switch (name) {
            case "salesprice":
                let value = String(val.target.value).replace(/,/g, "")
                // let valueDelivery = String(amountdata.Deliveryfee).replace(/,/g, "")
                // // }+ Number(valueDelivery)
                // let saletax = ((Number(value) ) * 0.0825).toFixed(2);
                // let totalSaleSP = ((Number(value) + Number(valueDelivery)) + Number(saletax)).toFixed(2)
                setamountdata({ ...amountdata, [val.target.name]: value
                    // , salestax: Number(saletax).toFixed(2), totalsales: totalSaleSP 
                })
                break;
            case "Deliveryfee":
                let valueDel = String(val.target.value).replace(/,/g, "")
                // let valueDel1 = String(amountdata.salesprice).replace(/,/g, "")
                // let saletaxDel = ((Number(valueDel) + Number(valueDel1)) * 0.0825).toFixed(2);
                // let totalSaleDF = ((Number(valueDel) + Number(valueDel1)) + Number(saletaxDel)).toFixed(2)
                // setamountdata({ ...amountdata, [name]: valueDel,totalsales:Number(Number(amountdata.totalsales)-Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee)+Number(val.target.value=='select'?0:val.target.value)).toFixed(2)
                    //  salestax: Number(saletaxDel).toFixed(2), totalsales: totalSaleDF
                    //  })
                    let amount=Number(amountdata.salesprice)

                    let  inventoryInfo= [
                        {
                            "inventoryId": Number(itemselectdata.inventoryId),
                            "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
                        }
                    ]
                    const GetPriceQuoteReq: any = {
                         storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                            customerId: Number(customerID),
                            agreementType:'RIS',
                            fees:deliveryFeeApplicable==1?[
                                {
                                 feeType:"DELI",
                                 feeAmount:valueDel
                                }
                            ]:[],
                            sacTotal:amount,
                            // extensionDays: 0,
                            inventoryInfo: inventoryInfo,
                            // totalCost: String(Number(itemselectdata.weeklyRate) * Number(itemselectdata.weeklyTerm)),
                      };
                    setLoadPriceCard(true)
                    const response = await getPriceQuote( GetPriceQuoteReq)
                    setLoadPriceCard(false)
                    console.log("response",response);
                    // let delTax=response?.data?.fees?.filter((x)=>x.feeType=="DELI")
                    // setamountdata({...amountdata,DeliverfeeTax:delTax.length!=0?delTax[0]?.feeAmountTax:""})
                    if(response!=undefined)
                    {
                        let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
                       let totalSale=Number(amountdata.salesprice) + Number(valueDel) + Number(salesTax)
                       let delTax=response?.data?.fees?.filter((x)=>x.feeType=="DELI")
                       setamountdata({...amountdata,salestax:salesTax,DeliverfeeTax:delTax?.length!=0?delTax[0]?.feeAmountTax:"0.00",[name]: valueDel,totalsales:Number(totalSale).toFixed(2)
                    //    Number(totalSale)-Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee)+Number(val.target.value=='select'?0:val.target.value)
                    })
                    }
                break;
            // let delivTax=val.target.value * props.tax;
            case "reason":
                setamountdata({...amountdata,"reason":val.target.value})


        }

    }

    const handleOnBlur=async(val)=>{
        ;
        let amount=Number(val.target.value)

        let  inventoryInfo= [
            {
                "inventoryId": Number(itemselectdata.inventoryId),
                "inventoryPriceId": Number(itemselectdata.inventoryPriceId)
            }
        ]
        const GetPriceQuoteReq: any = {
             storeNumber:String(window.sessionStorage.getItem("storeNumber")) ,
                customerId: Number(customerID),
                agreementType:'RIS',
                fees:deliveryFeeApplicable==1 && amountdata.Deliveryfee !="select"?[
                    {
                     feeType:"DELI",
                     feeAmount:String(amountdata.Deliveryfee)
                    }
                ]:[],
                // agreementRate: Number(itemselectdata.weeklyRate),
                // agreementTerm: Number(itemselectdata.weeklyTerm),
                // isLDWapplied: true,
                // isClubApplied: true,
                // tireplusPolicy: false,
                sacTotal:amount,
                // extensionDays: 0,
                inventoryInfo: inventoryInfo,
                // totalCost: String(Number(itemselectdata.weeklyRate) * Number(itemselectdata.weeklyTerm)),
          };
        setLoadPriceCard(true)
        const response = await getPriceQuote( GetPriceQuoteReq)
        setLoadPriceCard(false)
        console.log("response",response);
        if(response!=undefined)
        {
            let salesTax=response?.data?.agreementInitialPayment?.totalTax==undefined?0:response?.data?.agreementInitialPayment?.totalTax;
           let totalSale=Number(amountdata.salesprice) + Number(amountdata.Deliveryfee=='select'?0:amountdata.Deliveryfee) + Number(salesTax)
           setamountdata({...amountdata,salestax:salesTax,totalsales:Number(totalSale).toFixed(2)})
        }



    }

    /**RIS_PC_NO_34 Once the reason is entered and click the continue button overridepopupContinueBtn()
     * function is triggered.
     */

    const overridepopupContinueBtn=()=>{
        let response={
            inventoryId:itemselectdata.inventoryId,
            inventoryNumber:itemselectdata.inventoryNumber,
            inventoryDescription:itemselectdata.inventoryDescription,
            modelNumber:itemselectdata.modelNumber,
            serialNumber:itemselectdata.serialNumber,
            conditionEn:itemselectdata.conditionEn,
            remainingValue:itemselectdata.remainingValue,
            conditionRefCode:itemselectdata.conditionRefCode,
            weeklyRate: itemselectdata.weeklyRate,
            weeklyTerm: itemselectdata.weeklyTerm,
            inventoryPriceId: itemselectdata.inventoryPriceId,
            cashPrice:itemselectdata.cashPrice,
            purchaseDate:itemselectdata.purchaseDate,
            purchaseCost:itemselectdata.purchaseCost,
            conditionId:TxtValues.conditionId,
            itemFee:amountdata.itemFee,
            // ristype:"Rental Item Sale",
            // risNo:1,
            Deliveryfee:amountdata.Deliveryfee=="select"?0:amountdata.Deliveryfee,
            salesprice:amountdata.salesprice,
            totalsales: amountdata.totalsales,
            salestax:amountdata.salestax,
            DeliverfeeTax:amountdata.DeliverfeeTax==""||amountdata.DeliverfeeTax==undefined?0:amountdata.DeliverfeeTax,
            reason:amountdata.reason




        }
        // setRisresponse(response)
        history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
                      state:{
                        Risresponse:response,
                             ristype:"Rental Item Sale",
                             risNo:1}})

       
        // return(
        //     <div>
        // <risResponse.Provider
        //  value={{ response:{
        //     inventoryId:itemselectdata.inventoryId,
        //     inventoryNumber:itemselectdata.inventoryNumber,
        //     inventoryDescription:itemselectdata.inventoryDescription,
        //     modelNumber:itemselectdata.modelNumber,
        //     serialNumber:itemselectdata.serialNumber,
        //     conditionEn:itemselectdata.conditionEn,
        //     remainingValue:itemselectdata.remainingValue,
        //     conditionId:TxtValues.conditionId,
        //     itemFee:amountdata.itemFee,
        //     // ristype:"Rental Item Sale",
        //     // risNo:1,
        //     Deliveryfee:amountdata.Deliveryfee,
        //     salesprice:amountdata.salesprice,
        //     totalsales: amountdata.totalsales,
        //     salestax:amountdata.salestax,
        //     reason:amountdata.reason

        // }}}>
        //     {props.children}

        // </risResponse.Provider>

        //  history.push({pathname:`/payment1/paymentinformation/${customerID}/0`,
        //                 state:{ ristype:"Rental Item Sale"}}
        //               )
        // </div>
        // );

       



    }


   /**RIS_PC_NO_22 alertpopupFlag updated as true and alert popup is rendered
    * 
    */


    return (

        <Grid>
            <RACModalCard
                isOpen={alertpopupFlag}
                maxWidth="xs"
                // onClose={() => setalertpopupFlag(false)}
                // closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={alertpopup()}
            />
            <RACModalCard
                isOpen={alertinfopopupFlag}
                maxWidth="xs"
                onClose={() => setalertinfopopupFlag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={alertinfopopup()}
            />
             <RACModalCard
                isOpen={overridepopupflag}
                maxWidth="sm"
                onClose={() => setoverridepopupflag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={overridepopup()}
            />
            <RACModalCard
                isOpen={maximumpopupFlag}
                maxWidth="xs"
                onClose={() => setmaximumpopupFlag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={maximumpopup()}
            />
            <RACModalCard
                isOpen={minimumpopupFlag}
                maxWidth="xs"
                onClose={() => setmaximumpopupFlag(false)}
                closeIcon={false}
                borderRadius={"20px !important"}
                // eslint-disable-next-line react/no-children-prop
                children={minimumpopup()}
            />
            <Grid container className={classes.tittles}>

                <Grid className={classes1.m2N} >
                    <List className={`${classes1.dflex} ${classes1.p0} `}>
                        <ListItem className={`${classes1.widthAuto} ${classes.p2N} ${classes1.fontSemiBold} ${classes.floatStart} ${classes.BreadCrumbInactive}`}>
                            <Typography className={`${classes1.pr2} ${classes.textGrey} `} >
                            <a
                                    style={{ cursor: "pointer", textDecoration: "none" }}
                                    onClick={() => history.push(`/dashboard`)}
                                >
                                    Dashboard
                                </a>

                            </Typography>
                        </ListItem>
                        <ListItem className={`${classes1.widthAuto} ${classes.p2N} ${classes.floatStart} ${classes1.fontSemiBold} ${classes.BreadCrumbInactive}`}>
                            <Typography className={`${classes1.pr2} ${classes.textGrey} `} >
                            <a
                                style={{ cursor:"pointer",textDecoration:"none"}}
                                onClick={()=> history.push(`/payment1/paymentinformation/${customerInfo.customerID}/0`)}>Payment</a>

                            </Typography>
                        </ListItem>
                        <ListItem className={`${classes1.widthAuto} ${classes.p2N} ${classes.floatStart} ${classes1.fontSemiBold}`}>
                            <Typography className={`${classes1.pr2}   ${classes.textBlue} `} >
                                Rental Item Sale
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Card
                className={`${classes.w100}  ${classes.card}  ${classes.cardHeight} ${classes.marginTop} ${classes.cardborder}`}
            >   
                <CardContent>
                    <Grid container>
                        <Grid item md={3} style={{ marginLeft: "-18px", marginTop: "1px" }}>
                            <Typography
                                variant="h1"
                                className={`${classes.title1} ${classes.titleColor}`}>
                                Rental Item Sale
                            </Typography>
                        </Grid>
                        <Grid style={{ marginLeft: "-2%" }} className={classes.custDetailSection}>
                            <Grid style={{ marginLeft: "-75%" }} item md={4} className={classes.paddingCustname}>
                                <Typography>
                                    Customer Name
                                </Typography>
                                <Typography className={classes.marginCustName} style={{ cursor: 'pointer' }} onClick={() => history.push({
                                    pathname: `/customer/update/${customerName}/customer`
                                })}>
                                    {customerInfo.customerName}
                                </Typography>
                            </Grid><Grid item md={4} style={{ marginLeft: "-125%" }} className={classes.paddingCustname}>
                                <Typography >
                                    Customer ID
                                </Typography>
                                <Typography className={classes.marginCustID}>
                                    {customerInfo.customerID}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

            <Grid style={{ width: "100%",display:"flex" }}>

              {/*  RIS_PC_NO_04  Rendering the criteria search component */}
              {/* /**RIS_PC_NO_16 The searchresultgrid() function is trigered for rendering the search result grid */ }

                <Grid container className={itemselectFlag == true ? `${classes.afterselect}` : `${classes.beforeselect}`} >
                    {/* <Grid > */}
                    <Criteriasearch
                        searchclick={searchclick}
                        TxtValues={TxtValues}
                        setTxtValues={setTxtValues}
                    />
                    <Grid item xs={12} sm={12} md={12} lg={12} className={classes1.mbFooterSpacing}>
                        {loaderController === true && Gridloader === true ? (
                            <>
                                <Typography className={`${classes.title2} ${classes1.mt3} ${classes.srmargin}`}>
                                    Search Results
                                </Typography>
                                <Grid className={`${classes1.GridLoader} ${classes.textCenter} ${classes1.mt5}`}>
                                    <CircularProgress />
                                </Grid>
                            </>
                        ) : (
                            <Grid >
                                {searchresultgrid()}

                            </Grid>

                        )}

                    </Grid>


                    {/* </Grid> */}
                </Grid>
                {/**RIS_PC_NO_23 The selected item is rendered when itemselectFlag is updated 
                 * as true.
                 */}

                {/* {itemselectFlag == true ? */}
                <>
                    <Grid className={itemselectFlag == true ? `${classes.floatRight} ${classes.selecteditem}` : `${classes.selecteditemNon}`} >
                        <Typography className={`${classes.title3} ${classes1.mt3} ${classes.srmargin}`}>
                            Selected Items
                        </Typography>
                        {/* {controlLoader ? (
        
        <div className={classes2.racLoader} style={{ margin: '20% 20% 20% 48%' }}>
          <CircularProgress size={10} />
        </div>
      ) : ( */}
                        <Card className={`${classes1.card}  ${classes1.mb2}`} style={{ borderRadius: "20px" }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                {loadPriceCard ? (
                                    <Grid className={classes.masterLoader}>
                                    <Grid className={classes.Loader}>
                                        <CircularProgress />
                                    </Grid>
                                    </Grid>
                                ) : null}
                                    <Grid
                                        item
                                        className={`${classes.w100} ${classes.floatLeft}  ${classes2.mb2}`}
                                    >
                                        <Grid item
                                            className={`${classes.RACpaymenttotalcontainer1} ${classes2.p3}`}>
                                            <Grid
                                                item
                                                className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}
                                            >
                                                <Typography
                                                    className={`${classes2.floatLeft} ${classes2.totalsublabelstyle} ${classes2.bold} ${classes2.mt2} ${classes2.colorwhite}`}
                                                >
                                                    {amountdata.inventoryDescription}
                                                </Typography>

                                            </Grid>
                                            {/* <Grid item className={`${classes2.floatRight}`}>
                                              <Deleteicon></Deleteicon>
                                               </Grid> */}
                                            <Grid item className={`${classes.w100} ${classes.floatLeft} `}>
                                                <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes2.colorwhite}`}>
                                                    Item #
                                                </Typography>
                                                {/* <Grid item className={`${classes.floatLeft} ${classes2.totalsublabelstyle} ${classes2.bold} ${classes2.totallabelstyle} ${classes2.colorwhite}`} */}
                                                {/* style={{marginLeft:"2px"}}> */}
                                                <Typography className={`  ${classes2.floatLeft} ${classes.totalsublabelstyle}`} style={{ marginLeft: "2px" }} >
                                                    : {amountdata.inventoryNumber}
                                                </Typography>
                                                <Typography className={`  ${classes2.floatLeft} `} style={{ float: "right", marginRight: "10px",cursor:"pointer" }} >
                                                    <Deleteicon onClick={() => { deleClick() }}></Deleteicon>
                                                </Typography>
                                            </Grid>
                                            {/* </Grid> */}

                                        </Grid>

                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography style={{marginTop:"7px"}} className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Sale Price
                                        </Typography>
                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes2.totalsublabelstyle} ${classes2.bold} `}
                                            style={{ marginRight: "-96px" }}
                                        >
                                            <RACTextbox
                                                className={classes.custDigitN}
                                                type="number"
                                                name="salesprice"
                                                value={amountdata.salesprice}
                                                OnChange={(e: any) => { selecteditemHandlechange(e, "salesprice") }}  
                                                Onblur={(val:any) => {handleOnBlur(val)}}
                                                isCurrency={true}
                                                maxlength={10}
                                                required={false}
                                                disabled={sptxboxFlag}
                                            // errorMessage={errorMessage==false?"Enter amount less thanEpoAmount IncludingTax":""}                                                   
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Item Fees
                                        </Typography>
                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes2.totalsublabelstyle} `}
                                        >
                                            <Typography style={{ marginRight: "4px"  }} className={`${classes.floatLeft} ${classes.totalsublabelstyle1} `}>
                                                {amountdata.itemFee}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography
                                        style={{marginTop:"7px"}} 
                                         className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Delivery Charge($)
                                        </Typography>
                                        <Grid
                                            item
                                            className={` ${classes2.totalsublabelstyle} ${classes2.bold} `}
                                            style={{float:"right", marginRight:"-2%" }}
                                        >
                                            {/* <RACTextbox
                                                className={classes.custDigitN}
                                                type="number"
                                                name="Deliveryfee"
                                                value={amountdata.Deliveryfee}
                                                OnChange={(e: any) => { selecteditemHandlechange(e, "Deliveryfee") }}
                                                // Onblur={(e:any)=>{handleOnblur(e)}}                                                 
                                                isCurrency={true}
                                                maxlength={10}
                                                required={false}
                                            // errorMessage={errorMessage==false?"Enter amount less thanEpoAmount IncludingTax":""}                                                   
                                            /> */}
                                            <RACSelect
                                                // className={classes.custDigitN}
                                                classes={{nativeSelect:classes.custDigitN1}}
                                                // type="number"
                                                name="Deliveryfee"
                                                defaultValue={amountdata.Deliveryfee}
                                                onChange={(e: any) => { selecteditemHandlechange(e, "Deliveryfee") }}
                                                // defaultValue={ TxtValues.conditionId } 
                                                options={dropdownArr}
                                                isDisabled={deliveryFeeApplicable==0?true:false}
                                                loading={loadPriceCard}
                                                // Onblur={(e:any)=>{handleOnblur(e)}}                                                 
                                                // isCurrency={true}
                                                // maxlength={10}
                                                // required={false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes2.mb2}`}>
                                        <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle1} ${classes2.bold}`}>
                                            Sales Tax
                                        </Typography>
                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes2.totalsublabelstyle} `}
                                        >
                                            <Typography style={{ marginRight: "4px" }} className={`${classes.floatLeft} ${classes.totalsublabelstyle1} `}>
                                                {amountdata.salestax}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        className={`${classes.w100} ${classes.floatLeft} ${classes2.mb4}`}
                                        style={{marginRight:"4px"}}
                                    >

                                        <Grid
                                            item
                                            className={`${classes2.floatRight} ${classes.totalsublabelstyle3}  `}
                                        >
                                            <Typography className={`${classes.floatLeft}${classes.totalsublabelstyle3} ${classes2.bold}  `} style={{cursor:'pointer'}}>
                                                <a style={{ textDecoration: "underline" }} onClick={()=>{noBtn("default")}}>Set to Default</a>
                                            </Typography>

                                        </Grid>


                                    </Grid>

                                    <Grid item
                                        className={`${classes.RACpaymenttotalcontainer2} ${classes2.p1}`}
                                        style={{marginRight:"4px"}}>
                                        <Grid
                                            item
                                            className={`${classes.w100} ${classes.floatLeft} ${classes2.mb1}`}
                                        >
                                            <Typography
                                                className={`${classes2.floatLeft} ${classes.totalsublabelstyle2} ${classes2.bold} ${classes2.mt2}`}
                                            >
                                                Total Sales
                                            </Typography>
                                            <Grid
                                                item
                                                className={`${classes2.floatRight} ${classes.totalsublabelstyle3}  `}
                                                style={{marginTop:"2%"}}
                                            >
                                                <Typography variant='h4' className={`${classes.floatLeft}${classes.totalsublabelstyle3} ${classes2.bold}  `}>
                                                    {amountdata.totalsales}
                                                </Typography>

                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {/* )} */}
                    </Grid> </>
                {/* : null} */}
            </Grid>
            {/* </Card> */}

            {/**RIS_PC_NO_25 when continue button is clicked riscontinuebtnOnclick() function triggered */}
            <Grid
                item
                classes={{
                    root: clsx(
                        classes.customerToolbar,
                        classes.fixedBottom
                    ),
                }}
                style={{ zIndex: 0 }}
            >
                <Grid item className={classes.floatLeft}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.me1} ${classes.foc}`}
                        onClick={() => cancelBtn()}
                    >
                        Cancel
                    </RACButton>
                </Grid>
                <Grid className={classes.floatRight}>

                    {/* <RACButton
                                    variant="contained"
                                    color="primary"
                                    className={classes.me1}
                                    // disabled={props && props.editItemId && props.editItemId > 0 ? false : true}
                                    // onClick={() => submitBtnClicked("RemovePayment")}
                                >
                                    Remove Payment
                                </RACButton>  */}
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.me1}
                    disabled={riscontinuebtnFlag}
                    //submitBtnEnabled == false &&  istermsAndConditionCheck == true ? false : true||nonInventorySubmitHandle}
                    onClick={() =>riscontinuebtnOnclick()}
                    >
                        Continue
                    </RACButton>
                </Grid>
            </Grid>
            {SecondFactorOpen === true ? <RentalSecondFactor 
             setTwoFactorCancelClick={setTwoFactorCancelClick}
             setTwoFactorCompleted={setTwoFactorCompleted}
            //  moduleName="ApplyFreeTime"
             pin={pin}
             setPin={setPin}
            //  ()=>{setSecondFactorOpen(false); handleOnclick()}
            //  setEmployeeDetails={setEmployeeDetails}
            //  setRoleFromSLAModule={setRoleFromSLAModule}
             // storeAccessResponse={profileAccResp}
             CurrentRole={CurrentRole} 
             /> : null}
            </Grid>
    )

}
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable use-isnan */
// eslint-disable-next-line prettier/prettier
// eslint-disable-next-line prettier/prettier

import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";
export const PayHistory = () => {
    const useClasses = makeStyles((theme) => ({
 
/***************************************************************/
  /**** Setting up the default PADDING values - starts here *****/
/***************************************************************/

  payHistp2: {
    padding: theme.typography.pxToRem(8),
  },
  payHistp3: {
    padding: theme.typography.pxToRem(16),
  },




 
/***************************************************************/
  /**** Setting up the default PADDING values - ends here *****/
/***************************************************************/

/***************************************************************/
  /**** Setting up the BACKGROUND values - starts here *****/
/***************************************************************/
payHistbgTransparent: {
    backgroundColor:'transparent',
  },
payHistbgLightBlue: {
    backgroundColor:'#EFF4FF',
  },

  /***************************************************************/
    /**** Setting up the BACKGROUND values - ends here *****/
  /***************************************************************/
  
  payHistfs5: {
    fontSize: theme.typography.pxToRem(20),
  },

  payHistfs7: {
    fontSize: theme.typography.pxToRem(14),
  },

  payHistfontSemiBold: {
    fontFamily:'OpenSans-semibold',
  },
  payHistfontBold: {
    fontFamily:'OpenSans-bold',
  },

  payHisttextGrey: {
    color:'#4A5174',
  },
 
 
  payHisttextBlue :{
    color:'#2179FE',
  },

  payHistjustifySpaceBetween: {
    justifyContent:'space-between',
  },

  payHistcomponentRight: {
    justifyContent:'flex-end',
    alignItems:'center',
    display:'flex',
  },

  payHistm2: {
    margin: theme.typography.pxToRem(8),
  },

  payHistmt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  payHistmt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  payHistmb3: {
    marginBottom: theme.typography.pxToRem(16),
  },
  payHistmb4: {
    marginBottom: theme.typography.pxToRem(24),
  },
  payHistmb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  payHistmr2: {
    marginRight: theme.typography.pxToRem(8),
  },
  payHistborderRadius5:{
      borderRadius:theme.typography.pxToRem(5),
  },
payhistoryw100:{
  width:'100%',
},
GridLoader: {
  textAlign: 'center',
  marginBottom: theme.typography.pxToRem(20),
  fontSize: theme.typography.pxToRem(16),
  color: `${RACCOLOR.RICH_BLACK}`,
  margin: '30px 0px',
},





    }));

    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const classes = useClasses();
    return classes;
};

  /***************************************************************/
    /**** Setting up div structure for suspense - starts here *****/
  /***************************************************************/
  

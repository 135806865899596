/* eslint-disable prettier/prettier */
import React from 'react';
import { GridLoader } from '../../constants/constants';
import {
  Card,
  Grid,
  CardContent,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { reversePaymentStyle } from '../../stylesJS/reversePaymentStyle';

export default function LoaderGrid() {
  const classes = reversePaymentStyle();
  const renderHeaderLoader = () => {
    return (
      <>
        <RACTableCell>
          {' '}
          <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
        </RACTableCell>
        <RACTableCell>
          {' '}
          <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
        </RACTableCell>
        <RACTableCell>
          {' '}
          <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
        </RACTableCell>
        <RACTableCell>
          {' '}
          <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
        </RACTableCell>
      </>
    );
  }; // SQ-27 This method will be rendering the loader for the header of reverse payment grid.
  const renderBodyLoader = (): any => {
    return GridLoader.map((loaderData, index) => {
      return (
        <RACTableRow key={index + loaderData}>
          <RACTableCell>
            <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
          </RACTableCell>
          <RACTableCell>
            <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
          </RACTableCell>
          <RACTableCell>
            <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
          </RACTableCell>
          <RACTableCell>
            <Skeleton count={1} baseColor="#FFFFFF" highlightColor="#e7e7e7" />
          </RACTableCell>
        </RACTableRow>
      );
    });
  }; // SQ-28 This method will be rendering the loader for the content of reverse payment grid.
  return (
    <Grid item md={12} className={classes.mb100}>
      <Card className={`${classes.card} ${classes.w100}`}>
        <CardContent>
          <Grid item md={12}>
            <RACTable
              renderTableHead={renderHeaderLoader}
              renderTableContent={renderBodyLoader}
              className={classes.agrementGridMC}
            />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

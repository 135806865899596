/* eslint-disable */

/**PSC_BR_NO_17
 * The required racstrap, hooks and constants are imported
 */
import React, { useState, useEffect, useContext } from 'react';
import { takePaymentStyles } from '../../../stylesJS/makeStyles';
import { Grid, RACButton, RACModalCard, RACTextbox, Typography } from '@rentacenter/racstrap';
import { calculatePaymentModule } from '../../../api/user';
import calculationModulePayloadFormationUpdate from '../CommonModulePayloadFormationUpdate';
import { agreementContext } from '../../context/PaymentInformationContext';
import { otherIncomeResponseFormation } from '../../../utils/utils';

interface proceedBtnFlag {
  disable: boolean;
  validation: boolean;
}
import { AgreementDetails } from '../../interface/paymentInfoInterface';
import CONSTANTS from '../../constants/constant';
import { AmountDetails, PaymentDetails } from '../../interface/calculatePaymentResponse';
import { BackRent } from '../../interface/contextInterface';
import { BackRentComponentProps, ProceedBtnFlag } from '../../interface/backRentInterface';
export default function BackRentComponent(props: BackRentComponentProps) {
  const classes = takePaymentStyles();
  /**PSC_BR_NO_18 - PSC_BR_NO_19
   * The state variable declaration
   */
  const {
    agreementDetails,
    setAgreementDetails,
    backRentInfo,
    renderContext,
    setRenderContext
  } = useContext(agreementContext);
  const [acceptSpinner, setAcceptSpinner] = useState<boolean>(false)
  const [backRentDetails, setBackRentDetails] = useState<BackRent>({
    backRentOwed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    salesTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    taxRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    total: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    defaultTotal: CONSTANTS.ZREO_TWO_DECIMAL_STRING
  })
  const [disableProceedBtn, setDisableProceedBtn] = useState<ProceedBtnFlag>({
    disable: false,
    validation: false
  })
  /**PSC_BR_NO_20
   * The useEffect used to render the amount prefilled to the textFields
   */
  useEffect(() => {

    if (props?.backRentEditInfo) {
      setBackRentDetails(props?.backRentEditInfo);
    } else if (backRentInfo) {
      setBackRentDetails(backRentInfo);
    }

  }, [backRentInfo, props?.backRentEditInfo])

  /**PSC_BR_NO_22
   * While editing the amount in the text field the useEffect is triggered, and it handles the enable and disable the
   * proceed button
   */
  useEffect(() => {
    if (Number(backRentDetails.total) <= Number(backRentDetails.defaultTotal) && Number(backRentDetails.total) != 0) {
      setDisableProceedBtn({ ...disableProceedBtn, disable: false, validation: false })
    } else if (Number(backRentDetails.total) == 0) {
      setDisableProceedBtn({ ...disableProceedBtn, disable: true })
    } else {
      setDisableProceedBtn({ ...disableProceedBtn, disable: true, validation: true })
    }

  }, [backRentDetails])

  /**PSC_BR_NO_22
   * The handleTotalField() method is invoked when the Enter the amount in the text fields.
   */
  const handleTotalField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const removeComma = String(e.target.value).replace(CONSTANTS.REPLACE_COMMA, CONSTANTS.EMPTY_STRING)
    setBackRentDetails({ ...backRentDetails, total: removeComma })
  }

  /**PSC_BR_NO_23
   * The handleOnblur() method is invoked when the user clicks out the text fields
   */
  const handleOnblur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value == CONSTANTS.EMPTY_STRING || e.target.value == String(CONSTANTS.ZERO_TWO_DECIMAL_NUMBER)) {
      setBackRentDetails({ ...backRentDetails, total: CONSTANTS.ZREO_TWO_DECIMAL_STRING })
    }
    else {
      const removeComma = String(e.target.value).replace(CONSTANTS.REPLACE_COMMA, CONSTANTS.EMPTY_STRING)
      setBackRentDetails({ ...backRentDetails, total: Number(removeComma).toFixed(2) })

    }
  }
  /**PSC_BR_NO_24 - PSC_BR_NO_33
   * The onClickproceedbtn() method is invoked when the co-worker clicks the proceed button
   */
  const onClickproceedbtn = async () => {
    let salesTax: number = CONSTANTS.ZERO_NUMBER;
    let backRentAmount: number = CONSTANTS.ZERO_NUMBER;
    let totalAmount: string = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    setAcceptSpinner(true);
    const splitTax: number = Number(backRentInfo.taxRate) + CONSTANTS.ONE_NUMBER;
    const amountWithoutTax: string = (
      Number(backRentDetails.total) / splitTax
    ).toFixed(2);
    const newTax: string = (
      Number(backRentDetails.total) - Number(amountWithoutTax)
    ).toFixed(2);
    const array = [
      {
        backRentOwed: backRentInfo.backRentOwed,
        amountWithoutTax: amountWithoutTax,
        salesTax: Number(newTax).toFixed(2),
        total: Number(backRentDetails.total).toFixed(2),
        taxRate: backRentDetails.taxRate,
        type: CONSTANTS.BACK_RENT,
      },
    ];

    console.log("array", array)
    const commonModulePayload = await calculationModulePayloadFormationUpdate(
      {
        customerId: props.customerId,
        storeNumber: String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
        checkedAgr: array,
      }
    );

    let commonModuleServiceRes = await calculatePaymentModule(
      commonModulePayload
    );
    console.log("commonModuleServiceRes", commonModuleServiceRes)

    commonModuleServiceRes = commonModuleServiceRes?.data;

    const commonModuleAgrRes: PaymentDetails[] =
      commonModuleServiceRes?.paymentDetails.filter(
        (x: PaymentDetails) =>
          x.storeNumber ==
          String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER))
      );

    const backRentDetail: AmountDetails[] = commonModuleAgrRes[0]?.otherIncome?.filter(
      (x) => x.itemCategory == CONSTANTS.BKRPAY
    ) ?? [];

    backRentDetail.forEach((x) => {
      backRentAmount = Number(backRentAmount) + Number(x.amount);
      salesTax = Number(salesTax) + Number(x?.tax);
    });
    totalAmount = (Number(backRentAmount) + Number(salesTax)).toFixed(2);
    setAcceptSpinner(false);

    const otherIncomeAgr: AgreementDetails[] = await otherIncomeResponseFormation([
      {
        agreementNumber: CONSTANTS.ADJUST_BACK_RENT,
        backRentOwed: array[0].backRentOwed,
        amount: Number(backRentAmount).toFixed(2),
        salesTax: String(salesTax),
        Amount: totalAmount,
        taxRate: array[0].taxRate,
        type: CONSTANTS.BACK_RENT,
        description: CONSTANTS.BACK_RENT_COLLECTED,
      },
    ]);
    console.log("otherIncomeAgr", otherIncomeAgr)
    const index: number = agreementDetails.findIndex(
      (x) => x.agreementType == CONSTANTS.BACK_RENT
    );
    if (index != CONSTANTS.ONE_NEGATIVE_NUMBER) {
      setAgreementDetails((prevDetails) =>
        prevDetails.map((detail: AgreementDetails, i) =>
          i === index
            ? {
              ...detail,
              totalDueAmount: otherIncomeAgr[0].totalDueAmount,
              totalTax: otherIncomeAgr[0].totalTax,
            }
            : detail
        )
      );
    } else {
      if (agreementDetails.length == CONSTANTS.ONE_NUMBER && agreementDetails[0].agreementId == CONSTANTS.EMPTY_STRING)
        setAgreementDetails(otherIncomeAgr);
      else
        setAgreementDetails([...agreementDetails, ...otherIncomeAgr]);

      console.log('agreementDetails', [...agreementDetails, ...otherIncomeAgr]);
    }
    setRenderContext(!renderContext);
    props.onClose(false);
  };

  /**PSC_BR_NO_14
   * The BackRentPopUP() method is used to render the entire backRent popup
   */
  const BackRentPopUP = () => {
    return (
      <>
        <Grid>
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid item md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Back Rent Owed</label></span></Grid>
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              <RACTextbox
                type='number'
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={backRentDetails.backRentOwed}
                isCurrency={true}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Amount</label></span></Grid>
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              <RACTextbox
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}

                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={backRentDetails.amount}
                isCurrency={true}
                disabled={true}
                type='number'
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Sales Tax</label></span></Grid>
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              <RACTextbox
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={backRentDetails.salesTax}
                isCurrency={true}
                disabled={true}
                type='number'
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Total</label><span
              className={classes.mandatoryfield}
            >*</span></span></Grid>
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              <RACTextbox
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                id='total'
                value={backRentDetails.total}
                OnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleTotalField(e)}
                Onblur={(e: React.ChangeEvent<HTMLInputElement>) => handleOnblur(e)}
                isCurrency={true}
                type='number'
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
          {disableProceedBtn.validation ?
            <Typography
              component={'label'}
              className={classes.mandatoryfield}
            >
              Amount should not be entered more than the Back Rent Amount

            </Typography> : null}
          <Grid style={{ float: 'right' }}>

            <RACButton
              className={classes.ms2}
              data-testid='Cancel_id'
              color='primary'
              variant='outlined'
              onClick={() => {
                props.onClose(false)
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.ms2}
              data-testid='proceed_id'
              style={{ marginRight: '50px' }}
              color='primary'
              variant='contained'
              disabled={disableProceedBtn.disable}
              loading={acceptSpinner}
              onClick={() => onClickproceedbtn()}
            >
              Proceed
            </RACButton>
          </Grid>
        </Grid>
      </>
    )


  }

  return (
    <Grid>
      <RACModalCard
        isOpen={props.isOpen}
        maxWidth={'xs'}
        title='Back Rent'
        onClose={() => props.onClose(false)}
        closeIcon={true}
        borderRadius='25px !important'

      // children={BackRentPopUP()}
      >
        {BackRentPopUP()}
      </RACModalCard>
    </Grid>
  );

}
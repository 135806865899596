/*eslint-disable */
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';

export const customerPaymentInfoStyle = () =>{
    const useStyles = makeStyles((theme) => ({
        container: {
          width: '100%',
          margin: '1.25rem',
        },
        halfContainer: {
          width: '100%',
        },
        noLeftPadding: {
          paddingLeft: '0 !important',
        },
        leftRightPadding12: {
          padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(12)}`,
        },
        valueText: {
          color: `${RACCOLOR.GRAY}`,
        },
        item: {
          display: 'flex',
          justifyContent: 'space-between',
        },
        outlinedBox: {
          width: 'fit-content',
          background: RACCOLOR.LAVENDER_MIST,
          padding: theme.typography.pxToRem(15),
          borderRadius: theme.typography.pxToRem(6),
        },
        outlinedBoxValue: {
          fontFamily: 'OpenSans-bold',
        },
        noTopAndBottomPadding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        boxesContainer: {
          padding: `
          ${theme.typography.pxToRem(24)} 
          ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(
            24
          )} ${theme.typography.pxToRem(10)}`,
        },
        alignChildrenBottom: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        row: { marginBottom: theme.typography.pxToRem(14) },
        selectOptionsPaper: {
          maxHeight: theme.typography.pxToRem(200),
        },
    }));
    const classes = useStyles();
    return classes;
}
/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import { RACAlert } from "@rentacenter/racstrap";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { GeneralStateContext } from "../../context/GeneralProvider";

export default function ReverseException() {
  const classes = reversePaymentStyle();
  const { isSIMSReceipt } = useContext(GeneralStateContext);
  return (
    <RACAlert
      AlertMessage={
        "This payment is taken in SIMS. Please reverse your payment in SIMS."
      }
      variant="outlined"
      severity="error"
      className={
        isSIMSReceipt ? `${classes.alertBackground}` : `${classes.hideAlert}` 
      }
    />
  );
}// This component will be rendering the exception card when the receipt is from SIMS.

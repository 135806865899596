/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable*/
import React, { useState, useEffect, useContext } from 'react';
import {
  RACButton,
  Grid,
  RACModalCard,
  RACTextbox,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  AcceptEpoProps,
  acceptEPO,
  acceptEPOerror,
  noteInfo,
} from '../interface/acceptEPOInterface';
import { useParams, useLocation } from 'react-router-dom';
import { calculatePaymentModule } from '../../api/user';
import { agreementContext } from '../context/PaymentInformationContext';
import { calculateTax, calculateTotal } from '../../utils/utils';
import SecondFactor from '../../racstrapComponents/microfrontends/TwoFactorPopup';
import calculationModulePayloadFormationUpdate from './CommonModulePayloadFormationUpdate';
import { LocationParams, ParamType } from '../interface/commonInterface';
import CONSTANTS from '../constants/constant';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { AmountDetails } from '../interface/calculatePaymentResponse';

const AcceptEpo = (props: AcceptEpoProps) => {
  /**
   * PS_EPO_3 - PS_EPO_6
   * The required state variable decalaration and the destructuring is performed here.
   */
  const classes = takePaymentStyles();
  const { customerId } = useParams<ParamType>();
  const location = useLocation<LocationParams>();
  const acceptEPOObj: acceptEPO = {
    taxRate: CONSTANTS.ZERO_NUMBER,
    lateFeeTaxRate: CONSTANTS.ZERO_NUMBER,
    pastDueRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    epo: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    lateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    tax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    lateFeeTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    suspense: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    total: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    noteAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    agreementCost: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    isNoteAgreement: CONSTANTS.EMPTY_STRING,
  };
  const acceptEpoError: acceptEPOerror = {
    pastDuePent: CONSTANTS.ZERO_NUMBER,
    epo: CONSTANTS.ZERO_NUMBER,
  };
  const noteInfo: noteInfo = {
    noteAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    agrAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
  };
  const { agreementDetails, setAgreementDetails, coWorkerDetails, renderContext, setRenderContext } =
    useContext(agreementContext);
  const [acceptEpoData, setAcceptEpoData] = useState<acceptEPO>(acceptEPOObj);
  const [noteAgrInfo, setNoteAgrInfo] = useState<noteInfo>(noteInfo);
  const [isNoteAgr, setIsNoteAgr] = useState<boolean>(false);
  const [acceptSpinner, setAcceptSpinner] = useState<boolean>(false);
  const [acceptEpoErrorFlag, setAcceptEpoErrorFlag] = useState({ validateEpo: false, validatePastDue: false, validateEpoDiscountFlag: false });
  let index: number;
  const [openSecondFactor, setOpenSecondFactor] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const retentionType = urlParams.get("CRTYPE");

  /**
   * PS_EPO_7 - PS_EPO_8
   * This useEffect is intitially triggered on the pageload inside the useEffec the
   * calculateEPO() function is triggered.
   */
  useEffect(() => {
    if (props.isOpen)
      calculateEPO();
  }, [props.isOpen]);

  /**
   * PS_EPO_12 - PS_EPO_22
   * The calculateEPO() is invoked on the page load used to calculate the EPOAmount and NoteAmount to
   * display in the text fields.
   */
  const calculateEPO = () => {

    let epoAmount: string = CONSTANTS.ZREO_TWO_DECIMAL_STRING;

    if (props.selectedAgreement.length == CONSTANTS.ONE_NUMBER) {
      epoAmount = Number(
        Number(props.selectedAgreement[0].epoAmount) -
        Number(props.selectedAgreement[0].epoTax) -
        Number(props.selectedAgreement[0].lateFee) -
        Number(props.selectedAgreement[0].pastDueRent)
      ).toFixed(2);
      const total =
        Number(epoAmount) +
        Number(props.selectedAgreement[0].pastDueRent) +
        Number(props.selectedAgreement[0].lateFee) +
        Number(props.selectedAgreement[0].epoTax) -
        Number(props.selectedAgreement[0].suspenseAmount);

      setAcceptEpoData({
        ...acceptEpoData,
        taxRate: Number(props.selectedAgreement[0]?.taxRate),
        lateFeeTaxRate: Number(props.selectedAgreement[0]?.lateFeeTaxRate),
        epo: epoAmount,
        lateFeeTax: Number(
          Number(props.selectedAgreement[0].lateFee) *
          Number(props.selectedAgreement[0]?.lateFeeTaxRate)
        ).toFixed(2),
        pastDueRent: Number(props.selectedAgreement[0].pastDueRent).toFixed(2),
        lateFee: Number(props.selectedAgreement[0].lateFee).toFixed(2),
        tax: Number(props.selectedAgreement[0].epoTax).toFixed(2),
        total: Number(total).toFixed(2),
        suspense: Number(props.selectedAgreement[0].suspenseAmount).toFixed(2),
      });
    }
    if (props.selectedAgreement[0]?.isNoteAgreement == CONSTANTS.STATUS_YES) {
      const remainingNoteCostAmount =
        props.selectedAgreement[0]?.updatedRemainingNoteAmount;
      const agrAmount = Number(
        Number(epoAmount) - Number(remainingNoteCostAmount)
      ).toFixed(2);
      setNoteAgrInfo({
        ...noteAgrInfo,
        noteAmount:
          remainingNoteCostAmount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        agrAmount:
          agrAmount < CONSTANTS.ZREO_TWO_DECIMAL_STRING
            ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
            : agrAmount,
      });
      setIsNoteAgr(true);
    }
  };
  /** This function calculates  the note discount*/
  const noteAmountCalculation = (val: string, pastDueRent: string) => {
    const remainingNoteCost = Number(
      props.selectedAgreement[0].agreementRateDetails?.remainingNoteCost
    );
    const epoAmt = Number(val);
    const agrAmount = Math.max(epoAmt - remainingNoteCost, 0).toFixed(2);
    const noteAmount =
      agrAmount === CONSTANTS.ZREO_TWO_DECIMAL_STRING
        ? epoAmt.toFixed(2)
        : remainingNoteCost.toFixed(2);
    const updatedNoteAgrInfo = {
      agrAmount,
      noteAmount,
    };

    setNoteAgrInfo(updatedNoteAgrInfo);
    const AmountWithoutTax: number =
      Number(agrAmount) +
      Number(pastDueRent)
    // +
    // Number(acceptEpoData.lateFee);

    const LateFeeAmountTax: number = calculateTax(
      Number(acceptEpoData.lateFee),
      Number(acceptEpoData.lateFeeTaxRate)
    );

    const LateFeeAmountWithTax: number = Number(acceptEpoData.lateFee) + LateFeeAmountTax;

    const amountTax: number = calculateTax(
      Number(AmountWithoutTax),
      Number(acceptEpoData.taxRate)
    );
    const total: number = calculateTotal(
      Number(pastDueRent) + Number(epoAmt) + LateFeeAmountWithTax,
      Number(amountTax),
      Number(acceptEpoData.suspense)
    );

    setAcceptEpoData({
      ...acceptEpoData,
      epo: val,
      pastDueRent: pastDueRent,
      total: Number(total).toFixed(2),
      tax: Number(amountTax + LateFeeAmountTax).toFixed(2),
    });
  };

  /**
   * PS_EPO_37 - PS_EPO_37
   * The onEpoChange() method is invoked when the user enters the amount in the text fields.
   * @param val
   */
  const onEpoChange = (val: React.ChangeEvent<HTMLInputElement>) => {
    let AmountWithoutTax: number;
    let amountTax: number;
    let total: number;
    const value = val.target.value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    const LateFeeAmountTax: number = calculateTax(
      Number(acceptEpoData.lateFee),
      Number(acceptEpoData.lateFeeTaxRate)
    );

    const LateFeeAmountWithTax: number = Number(acceptEpoData.lateFee) + LateFeeAmountTax;

    switch (val.target.name) {
      case CONSTANTS.EPO_TXT_BOX_AMOUNT:
        if (isNoteAgr) {
          noteAmountCalculation(value, acceptEpoData.pastDueRent);
        } else {
          AmountWithoutTax = Number(
            Number(value) +
            Number(acceptEpoData.pastDueRent)
          );

          amountTax = calculateTax(
            Number(AmountWithoutTax),
            Number(acceptEpoData.taxRate)
          );
          total = calculateTotal(
            Number(AmountWithoutTax) + LateFeeAmountWithTax,
            Number(amountTax),
            Number(acceptEpoData.suspense)
          );
          setAcceptEpoData({
            ...acceptEpoData,
            epo: value,
            total: Number(total).toFixed(2),
            tax: Number(amountTax + LateFeeAmountTax).toFixed(2),
          });
        }
        validateFields(value, CONSTANTS.EPO)
        break;
      case CONSTANTS.PAST_DUE_RENT_TXT_BOX_AMOUNT:
        if (isNoteAgr) {
          noteAmountCalculation(acceptEpoData.epo, value);
        } else {
          AmountWithoutTax = Number(
            Number(acceptEpoData.epo) +
            Number(value) +
            Number(acceptEpoData.lateFee)
          );
          amountTax = calculateTax(
            Number(AmountWithoutTax),
            Number(acceptEpoData.taxRate)
          );

          total = calculateTotal(
            Number(AmountWithoutTax) + LateFeeAmountWithTax,
            Number(amountTax),
            Number(acceptEpoData.suspense)
          );
          setAcceptEpoData({
            ...acceptEpoData,
            pastDueRent: value,
            total: Number(total).toFixed(2),
            tax: Number(amountTax + LateFeeAmountTax).toFixed(2),
          });
        }
        validateFields(value, CONSTANTS.PASTDUERENT)
        // setAcceptEpoData({...acceptEpoData,pastDueRent:Number(value).toFixed(2)});
        break;
    }

  };
  /**
   * PS_EPO_37
   * The onEpoBlur() method is invoked when the user clicks outside the textFields.
   * @param val
   */
  const onEpoBlur = (val: React.ChangeEvent<HTMLInputElement>) => {
    const epoDetails = acceptEpoData;
    switch (val.target.name) {
      case "epoAmtBox":
        setAcceptEpoData({
          ...acceptEpoData,
          epo: Number(epoDetails.epo).toFixed(2),
        });
        break;
      case "pastDueRentAmtBox":
        setAcceptEpoData({
          ...acceptEpoData,
          pastDueRent: Number(epoDetails.pastDueRent).toFixed(2),
        });
        break;
    }
  };

  /**
   * PS_EPO_48 - PS_EPO_49
   * The commonModuleAcceptEPOTrigger() function will make common module APi call and
   * updated the context.
   */

  const discountApplied = (selectedAgreements: AgreementDetails) => {
    if (selectedAgreements) {
      if (selectedAgreements?.isNoteAgreement == CONSTANTS.STATUS_YES) {
        selectedAgreements.acceptEpoFlag = CONSTANTS.ONE_NUMBER;
        selectedAgreements.isAcceptEPODiscounts.noteDiscount = Math.abs(
          Number(selectedAgreements?.agreementRateDetails?.remainingNoteCost) -
          Number(noteAgrInfo.noteAmount)
        ).toFixed(2);
        let exclusiveNoteAgreement =(Number(selectedAgreements?.actualEpo) - Number(selectedAgreements?.agreementRateDetails?.remainingNoteCost)).toFixed(2);
        selectedAgreements.isAcceptEPODiscounts.epoDiscount = Math.abs(
          Number(exclusiveNoteAgreement) - Number(noteAgrInfo?.agrAmount) <
            CONSTANTS.ZERO_NUMBER
            ? Number(selectedAgreements?.actualEpo) -
            Number(selectedAgreements?.agreementRateDetails?.remainingNoteCost)
            : Number(selectedAgreements?.actualEpo) -
            Number(selectedAgreements?.agreementRateDetails?.remainingNoteCost) -
            Number(noteAgrInfo?.agrAmount)
        ).toFixed(2);
        selectedAgreements.isAcceptEPODiscounts.pastDueDiscount = Math.abs(
          Number(selectedAgreements?.actualPastDueRent) -
          Number(acceptEpoData.pastDueRent)
        ).toFixed(2);
      } else {
        selectedAgreements.isAcceptEPODiscounts.epoDiscount = Math.abs(
          Number(selectedAgreements?.actualEpo) - Number(acceptEpoData.epo)
        ).toFixed(2);
        selectedAgreements.isAcceptEPODiscounts.pastDueDiscount = Math.abs(
          Number(selectedAgreements?.actualPastDueRent) -
          Number(acceptEpoData.pastDueRent)
        ).toFixed(2);
        selectedAgreements.acceptEpoFlag = CONSTANTS.ONE_NUMBER;
      }
    }
    return selectedAgreements;
  };

  /**
   * PS_EPO_48 - PS_EPO_52
   * The commonModuleAcceptEPOTrigger() function will make common module APi call and
   * updated the context.
   */
  const commonModuleAcceptEPOTrigger = async () => {
    setAcceptSpinner(true);
    index = agreementDetails.findIndex(
      (x) => x.selected && x.agreementId == props.selectedAgreement[0].agreementId
    );
    const response = discountApplied(props.selectedAgreement[0]);
    const commonPayload = {
      customerId: customerId,
      storeNumber: String(
        window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
      ),
      checkedAgr: [response],
    };

    const commonModulePayload = await calculationModulePayloadFormationUpdate(
      commonPayload
    );
    try {
      const commonModuleServiceRes = await calculatePaymentModule(
        commonModulePayload
      );

      setAcceptSpinner(false);
      debugger
      const commonModuleResponse =
        commonModuleServiceRes.data.paymentDetails[0].agreementPaymentDetails.filter(
          (agr: AgreementDetails) =>
            agr.agreementId == props.selectedAgreement[0]?.agreementId
        );
      const pastDueRent = commonModuleResponse[0].amountDetails.reduce(
        (acc: number, el: AmountDetails) =>
          acc +
          (CONSTANTS.LTRPAY_ARRAY.includes(el.itemCategory)
            ? Number(el.amount)
            : CONSTANTS.ZERO_NUMBER)
        , CONSTANTS.ZERO_NUMBER

      );
      const pastDueDiscount = commonModuleResponse[0].amountDetails.reduce(
        (acc: number, el: AmountDetails) =>
          acc +
          (CONSTANTS.LTRPAYDSCOUNT.includes(el.itemCategory)
            ? Number(el.amount)
            : CONSTANTS.ZERO_NUMBER),
        CONSTANTS.ZERO_NUMBER
      );

      setAgreementDetails((prevDetails) =>
        prevDetails.map((detail, i) =>
          i === index
            ? {
              ...detail,
              pastDueRent: pastDueDiscount
                ? Number(Number(pastDueRent) - Number(pastDueDiscount)).toFixed(
                  2
                )
                : Number(pastDueRent).toFixed(2),
              totalTax: commonModuleResponse[0].totalTax,
              totalDueAmount: Number(Number(commonModuleResponse[0].totalAmount) + Number(commonModuleResponse[0].totalTax)).toFixed(2),
              totalAmount: Number(commonModuleResponse[0].totalAmount).toFixed(2),
              agreementType: CONSTANTS.NOTE_AGREEMENT_TYPE.includes(
                agreementDetails[i].agreementType
              )
                ? CONSTANTS.EPO_NOTE
                : CONSTANTS.EPO_RENT,
              suspenseDetails: {
                ...agreementDetails[i].suspenseDetails,
                suspUse: agreementDetails[i].suspenseAmount,
                suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
              },
              epoAmount: Number(Number(commonModuleResponse[0].totalAmount) + Number(commonModuleResponse[0].totalTax)
                // + Number(agreementDetails[i].suspenseAmount)
              ).toFixed(2),
              updatedRemainingNoteAmount: Number(noteAgrInfo.noteAmount).toFixed(2),
              epoTax: commonModuleResponse[0].totalTax,
              isAcceptEPODiscounts: response.isAcceptEPODiscounts,
              nextDueDate:
                new Date(agreementDetails[i]?.sacDate) >= new Date()
                  ? CONSTANTS.SAC
                  : CONSTANTS.EPO,
              acceptEpoFlag: CONSTANTS.ONE_NUMBER,
            }
            : detail
        )
      );
      setRenderContext(!renderContext);
      props.onClose(false);

    } catch (error: unknown) {
      console.log("error_in_EPo", error)
      setAcceptSpinner(true);
    }

  };

  const validateFields = (value: string, type: string) => {

    switch (type) {
      case CONSTANTS.EPO:
        if (Number(value) > Number(props.selectedAgreement[0]?.actualEpo)) {
          setAcceptEpoErrorFlag({ ...acceptEpoErrorFlag, validateEpo: true })
        }
        else if (retentionType && retentionType == 'EPO' && location?.state && (Number(props.selectedAgreement[0]?.actualEpo) - Number(value) > Number(location?.state[CONSTANTS.RETENTION_DATA]?.epoDiscount))) {
          setAcceptEpoErrorFlag({ ...acceptEpoErrorFlag, validateEpoDiscountFlag: true, validateEpo: false });
        }
        else {
          setAcceptEpoErrorFlag({ ...acceptEpoErrorFlag, validateEpoDiscountFlag: false, validateEpo: false });
        }
        break;
      case CONSTANTS.PASTDUERENT:
        if (Number(value) > Number(props.selectedAgreement[0]?.actualPastDueRent)) {
          setAcceptEpoErrorFlag({ ...acceptEpoErrorFlag, validatePastDue: true });
        }
        else {
          setAcceptEpoErrorFlag({ ...acceptEpoErrorFlag, validatePastDue: false });
        }
        break;
    }


  }

  /**
   * PS_EPO_45 - PS_EPO_47
   * The acceptEpoClick() method is invoked when the Accept button is clicked.
   */
  const acceptEpoClick = () => {
    if (
      acceptEpoData.epo != Number(props.selectedAgreement[0]?.actualEpo).toFixed(2) ||
      acceptEpoData.pastDueRent != Number(props.selectedAgreement[0]?.actualPastDueRent).toFixed(2)
    ) {
      setAcceptSpinner(true);
      setOpenSecondFactor(true);
    } else {
      commonModuleAcceptEPOTrigger();
    }
  };

  /**
   * PS_EPO_24 - PS_EPO_36
   * The acceptEpoPopup() method is used to render the entire UI of the Accept EPO popup
   * @returns
   */
  const acceptEpoPopup = () => {
    return (
      <Grid container spacing={2} className={classes.px3}>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Past Due Rent
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={acceptEpoData.pastDueRent}
              id='pastDueRentAmtBox'
              OnChange={(e) => onEpoChange(e)}
              Onblur={(e) => onEpoBlur(e)}
              data-testid="amounttextbox"
              name="pastDueRentAmtBox"
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={acceptEpoErrorFlag.validatePastDue ? `New Amount can't exceed ${props.selectedAgreement[0]?.actualPastDueRent}` : CONSTANTS.EMPTY_STRING}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            EPO
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={acceptEpoData.epo}
              OnChange={(e) => onEpoChange(e)}
              Onblur={(e) => onEpoBlur(e)}
              data-testid="amounttextbox"
              type={"number"}
              name="epoAmtBox"
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={acceptEpoErrorFlag.validateEpo ? `New Amount can't exceed ${Number(props.selectedAgreement[0]?.actualEpo).toFixed(2)}` : acceptEpoErrorFlag.validateEpoDiscountFlag ? `New Amount can't be less than ${(Number(props.selectedAgreement[0]?.actualEpo) - Number(location?.state[CONSTANTS.RETENTION_DATA]?.epoDiscount)).toFixed(2)}` : CONSTANTS.EMPTY_STRING}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Late Fee
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={acceptEpoData.lateFee}
              data-testid="amounttextbox"
              disabled={true}
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Tax
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={acceptEpoData.tax}
              data-testid="amounttextbox"
              disabled={true}
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>
        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Suspense
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={acceptEpoData.suspense}
              data-testid="amounttextbox"
              disabled={true}
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>
        {isNoteAgr ? (
          <>
            <Grid
              item
              className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
            >
              <label
                htmlFor="a11y_takehome"
                className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
              >
                Note Amount (Inc.Tax)
              </label>
              <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
                <RACTextbox
                  isCurrency={true}
                  className={`${classes.custDigit} ${classes.cashtxtbox}`}
                  dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                  value={Number(noteAgrInfo?.noteAmount).toFixed(2)}
                  data-testid="amounttextbox"
                  disabled={true}
                  type={"number"}
                  digitFormat={"currency"}
                  maxlength={10}
                />
              </Grid>
            </Grid>
            <Grid
              item
              className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
            >
              <label
                htmlFor="a11y_takehome"
                className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
              >
                Agreement Cost
              </label>
              <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
                <RACTextbox
                  isCurrency={true}
                  className={`${classes.custDigit} ${classes.cashtxtbox}`}
                  dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                  value={Number(noteAgrInfo?.agrAmount).toFixed(2)}
                  data-testid="amounttextbox"
                  disabled={true}
                  type={"number"}
                  digitFormat={"currency"}
                  maxlength={10}
                />
              </Grid>
            </Grid>
          </>
        ) : null}

        <Grid
          item
          className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
        >
          <label
            htmlFor="a11y_takehome"
            className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
          >
            Total
          </label>
          <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              disabled={true}
              value={Number(acceptEpoData.total) < 0 ? "(" + Number(Number(acceptEpoData.total) * -1).toFixed(2) + ")" : Number(acceptEpoData.total).toFixed(2)
              }
              data-testid="amounttextbox"
              type={"number"}
              digitFormat={"currency"}
              maxlength={10}
            />
          </Grid>
        </Grid>

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="outlined"
            name="De_ActivateClubbtn"
            data-bs-toggle="modal"
            style={{
              backgroundColor: "white",
              color: "#2C3333",
            }}
            data-testid='acceptEPO-cancel-btn'
            onClick={() => {
              props.onClose(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            name="De_ActivateClubbtn"
            data-bs-toggle="modal"
            data-testid='acceptEPO-accept-btn'
            loading={acceptSpinner}
            disabled={Object.values(acceptEpoErrorFlag).includes(true)}
            onClick={() => acceptEpoClick()}
          >
            Accept
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  /**
   * PS_EPO_23
   * The return part is declared helps to invoke the rendereing function of
   * the accept EPO.
   */
  return (
    <>
      <RACModalCard
        isOpen={props.isOpen}
        maxWidth="xs"
        title="Accept EPO"
        onClose={() => {
          props.onClose(true);
        }}
        closeIcon={true}
        borderRadius="20px"
      >
        {acceptEpoPopup()}
      </RACModalCard>
      {openSecondFactor === true ? (
        <SecondFactor
          setTwoFactorCancelClick={() => { setOpenSecondFactor(false); setAcceptSpinner(false); }}
          setTwoFactorCompleted={() => {
            setOpenSecondFactor(false)
            commonModuleAcceptEPOTrigger()
          }}
          moduleName={CONSTANTS.SLA_MODULE_NAME_ACCEPTEPO}
          CurrentRole={coWorkerDetails.role}
        />
      ) : null}
    </>
  );
};

export default AcceptEpo;

/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
import { useEffect,useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  makeStyles,
} from '@rentacenter/racstrap';
import CurrencyInput from "react-currency-input-field";
const paddingStyle = '0.375rem 0.75rem';
const useClasses = makeStyles((theme: any) => (
  {
    title: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontSize: '18px',
      marginBottom: '10px',
    },
    formcontrol3: {
      display: 'block',
      fontSize: theme.typography.pxToRem(14),
      width: '81%',
      padding: '0.375rem 0.75rem',
      lineHeight: '1.5',
      color: '#212529',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      borderTopRightRadius: '0.6rem',
      borderBottomRightRadius: '0.6rem',
      borderTopLeftRadius: '0rem',
      borderBottomLeftRadius: '0rem',
      border: '1px solid #C4C4C4',
  },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },

    w100: {
      width: '100%',
    },
    floatLeft: {
      float: 'left',
    },
    floatRight: {
      float: 'right',
    },
    /* Margin and Padding spacing */
    m0: {
      margin: 0,
    },

    m1: {
      margin: theme.typography.pxToRem(4),
    },

    m2: {
      margin: theme.typography.pxToRem(8),
    },

    m3: {
      margin: theme.typography.pxToRem(16),
    },

    m4: {
      margin: theme.typography.pxToRem(24),
    },

    m5: {
      margin: theme.typography.pxToRem(48),
    },

    mAuto: {
      margin: 'auto',
    },

    mx0: {
      marginRight: 0,
      marginLeft: 0,
    },

    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mx2: {
      marginRight: theme.typography.pxToRem(8),
      marginLeft: theme.typography.pxToRem(8),
    },

    mx3: {
      marginRight: theme.typography.pxToRem(16),
      marginLeft: theme.typography.pxToRem(16),
    },
    mx4: {
      marginRight: theme.typography.pxToRem(24),
      marginLeft: theme.typography.pxToRem(24),
    },

    mx5: {
      marginRight: theme.typography.pxToRem(48),
      marginLeft: theme.typography.pxToRem(48),
    },

    mxAuto: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    my0: {
      marginTop: 0,
      marginBottom: 0,
    },

    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },

    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },

    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },

    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },

    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },

    myAuto: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    mt0: {
      marginTop: 0,
    },

    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },

    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },

    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },

    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },

    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },

    mtAuto: {
      marginTop: 'auto',
    },

    me0: {
      marginRight: 0,
    },

    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },

    me3: {
      marginRight: theme.typography.pxToRem(16),
    },

    me4: {
      marginRight: theme.typography.pxToRem(24),
    },

    me5: {
      marginRight: theme.typography.pxToRem(48),
    },

    meAuto: {
      marginRight: 'auto',
    },

    mb0: {
      marginBottom: 0,
    },

    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },

    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },

    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },

    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },

    mbAuto: {
      marginBottom: 'auto',
    },

    ms0: {
      marginLeft: 0,
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },

    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },

    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },

    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },

    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },

    msAuto: {
      marginLeft: 'auto',
    },

    p0: {
      padding: 0,
    },

    p1: {
      padding: theme.typography.pxToRem(4),
    },

    p2: {
      padding: theme.typography.pxToRem(8),
    },

    p3: {
      padding: theme.typography.pxToRem(16),
    },

    p4: {
      padding: theme.typography.pxToRem(24),
    },

    p5: {
      padding: theme.typography.pxToRem(48),
    },

    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },

    px1: {
      paddingRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(4),
    },

    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },

    px3: {
      paddingRight: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(16),
    },

    px4: {
      paddingRight: theme.typography.pxToRem(24),
      paddingLeft: theme.typography.pxToRem(24),
    },

    px5: {
      paddingRight: theme.typography.pxToRem(48),
      paddingLeft: theme.typography.pxToRem(48),
    },

    py0: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },

    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },

    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },

    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },

    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },

    pt0: {
      paddingTop: 0,
    },

    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },

    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },

    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },

    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },

    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },

    pe0: {
      paddingRight: 0,
    },

    pe1: {
      paddingRight: theme.typography.pxToRem(4),
    },

    pe2: {
      paddingRight: theme.typography.pxToRem(8),
    },

    pe3: {
      paddingRight: theme.typography.pxToRem(16),
    },

    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },

    pe5: {
      paddingRight: theme.typography.pxToRem(48),
    },

    pb0: {
      paddingBottom: 0,
    },

    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },

    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },

    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },

    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },

    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },

    ps0: {
      paddingLeft: 0,
    },

    ps1: {
      paddingLeft: theme.typography.pxToRem(4),
    },

    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },

    ps3: {
      paddingLeft: theme.typography.pxToRem(16),
    },

    ps4: {
      paddingLeft: theme.typography.pxToRem(24),
    },

    ps5: {
      paddingLeft: theme.typography.pxToRem(48),
    },

    font11: {
      fontSize: theme.typography.pxToRem(11),
    },

    font12: {
      fontSize: theme.typography.pxToRem(12),
    },

    font13: {
      fontSize: theme.typography.pxToRem(13),
    },

    font14: {
      fontSize: theme.typography.pxToRem(14),
    },

    font15: {
      fontSize: theme.typography.pxToRem(15),
    },

    font16: {
      fontSize: theme.typography.pxToRem(16),
    },

    font17: {
      fontSize: theme.typography.pxToRem(17),
    },

    font18: {
      fontSize: theme.typography.pxToRem(18),
    },
    formLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    w70: {
      width: '70%',
    },
    labeltxtstyle: {
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      color: '#000000',
    },
    inputgroup: {
      position: 'relative',
      display: 'flex',
      alignItems: 'stretch',
      width: '100%',
    },
    w50: {
      width: '50%',
    },
    inputgrouptext: {
      display: 'flex',
      alignItems: 'center',
      padding: paddingStyle,
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#212529',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#e9ecef',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      borderTopLeftRadius: theme.typography.pxToRem(9),
      borderBottomLeftRadius: theme.typography.pxToRem(9),
    },
    widthauto: {
      width: 'auto',
    },
    formcontrol: {
      display: 'block',
      fontSize: theme.typography.pxToRem(14),
      width: '100%',
      padding: paddingStyle,
      lineHeight: '1.5',
      color: '#212529',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      borderRadius: '0.6rem',
      border: '1px solid #C4C4C4',
    },
    textend: {
      textAlign: 'right',
    },
    bold: {
      fontFamily: 'OpenSans-bold',
    },
    displayInline:
    {
      display: 'inline',
    },
    coaInputBox: {
      width: '60%',
    },
    raccollg4: {
      flex: '0 0 auto',
      width: '33.3333333333%',
    },
    raccollg8: {
      flex: '0 0 auto',
      width: '66.6666666667%',
    },
    w25: {
      width: '25%',
    },
    w35:
    {
      width: '35%',
    },
    currenyPadding: {
      padding: '6px 14px',
    },
    formcontrol1: {
      display: 'block',
      fontSize: theme.typography.pxToRem(14),
      width: '75%',
      padding: paddingStyle,
      lineHeight: '1.5',
      color: '#212529',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      borderRadius: '0.6rem',
      border: '1px solid #C4C4C4',
    },
    accountBalanceCss : {
      marginTop: '19px',
    }
  }))
export function AvailableCredits(props: any) {
  const classes = useClasses();
  const [accountBalance,setAccountBalance]=useState<any>([])
  const [balanceAmount,setBalanceAmount]= useState<any>([])

  useEffect(() => {
    let credits:any
    let balanceType:any
    if(props.availableCredits!=undefined)
    {
      credits=props.availableCredits.filter((x)=>x.accountBalance >0.00 && (x.bucket === 'COA' || x.bucket === 'IP Suspense'))
      setAccountBalance(credits);
    }
    if(props.balance !=undefined)
    {
      balanceType=props.balance.filter((x)=>x.balanceType === 'WEBLEAD' && x.balanceAmount>0.00 )
      setBalanceAmount(balanceType);
    }
    console.log('dcedge33r443',credits, balanceType,props.throwDiffStore);
  }, [props.balance,props.availableCredits,props.throwDiffStore])

  return (
    //    available credits starts here
    <Grid container data-testid="availcreditid">
      <Grid item className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}>
        <Typography variant="h6" className={`${classes.floatLeft} ${classes.mt2} ${classes.raccollg8} ${classes.title}`}>
          Available Credit
        </Typography>
      </Grid>


      <Grid item className={`${classes.w100} ${classes.floatLeft}`}>

        <Card className={`${classes.card} ${classes.w100} ${classes.floatLeft} ${classes.p3}`} style={{ marginBottom: '20px' }}>
          <CardContent className={classes.p0}>

            {accountBalance !== undefined && accountBalance.length!=0 && props.throwDiffStore === false ?
              (accountBalance.map((value, index: any) => {

                return (
                  <>
                  {/* { parseFloat(value.accountBalance)>0.00 ? */}
                  <Grid item key={index} className={`${classes.w100} ${classes.floatLeft}`}>
                    {value.bucket === 'COA' || value.bucket === 'IP Suspense' ?
                    <>
                    <Typography component={'label'} className={`${classes.formLabel} ${classes.w35} ${classes.mt3} ${classes.floatLeft}`}>{value.bucket}</Typography>
                    <Box component="span" className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatLeft} ${classes.accountBalanceCss}`}>$<Box className={classes.displayInline}>{value.accountBalance}</Box></Box>
                    </>
                    : null}
                    <Grid item className={`${classes.w50} ${classes.floatRight} ${classes.mt2}`}>

                      {value.bucket === 'COA' || value.bucket === 'IP Suspense' ?

                        <Grid item className={`${classes.inputgroup} ${classes.mb3}`}>
                          <span className={classes.inputgrouptext}>$</span>
                          <CurrencyInput
                            className={`${classes.formcontrol3} ${classes.textend}`}
                            type="text"
                            disabled={props.disableCred || value.bucket === 'COA' || value.bucket === 'IP Suspense' ? true : false}
                            id="WL-Deposit"
                            defaultValue={value.accountBalance}
                          // onChange={(e)=>{updateAvailablecreditsarray(e,index,value)}}
                          />
                        </Grid>

                        : null}

                    </Grid>
                  </Grid>
                  {/* : null } */}
                  </>
                )
              })
              ) : null}
              {balanceAmount !== undefined && balanceAmount.length>0?
              (balanceAmount.map((value, index: any) => {

                return (
                  <>
                  {/* { value.balanceAmount>0.00 ? */}
                  <Grid item key={index} className={`${classes.w100} ${classes.floatLeft}`}>
                    {/* {value.balanceType === 'WEBLEAD' ? */}
                    {/* <> */}
                    <Typography component={'label'} className={`${classes.formLabel} ${classes.w35} ${classes.mt3} ${classes.floatLeft}`}>WL Deposit</Typography>
                    <Box component="span" className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatLeft} ${classes.accountBalanceCss}`}>$<Box className={classes.displayInline}>{value.balanceAmount}</Box></Box>
                    {/* </> */}
                    {/* : null} */}
                    <Grid item className={`${classes.w50} ${classes.floatRight} ${classes.mt2}`}>

                      {/* {value.balanceType === 'WEBLEAD' ? */}

                        <Grid item className={`${classes.inputgroup} ${classes.mb3}`}>
                          <span className={classes.inputgrouptext}>$</span>
                          <CurrencyInput
                            className={`${classes.formcontrol3} ${classes.textend}`}
                            type="text"
                            disabled={props.disableCred || value.balanceType === 'WEBLEAD' ? true : false}
                            id="WL-Deposit"
                            defaultValue={value.balanceAmount}
                          // onChange={(e)=>{updateAvailablecreditsarray(e,index,value)}}
                          />
                        </Grid>

                        {/* : null} */}

                    </Grid>
                  </Grid>
                  {/* : null } */}
                  </>
                )
              })
              ) : null}

          </CardContent>
        </Card>

      </Grid>
    </Grid>
    //    available credits ends here
  );
}

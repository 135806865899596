// * eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */


/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */


/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import moment from 'moment'
import {
  RACButton,
  Grid,
  RACModalCard,
  RACTextbox,
  RACDatePicker,
  Modal,
  CircularProgress,
  Typography
} from "@rentacenter/racstrap";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { AdjustDueDate } from '../../api/user';
import AdjustDueDatecalci from "../../racstrapComponents/microfrontends/AdjustDueDateCalci";

const PromoFreeDays = (props: any) => {
    debugger;
  const classes = takePaymentStyles();
  const MyDate = new Date(props.adjustDueDateReq[0].CurrentDueDate)
  const currentduedate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-'
    + ('0' + (MyDate.getDate())).slice(-2)
    ;
    const noOfDayAllowed = Number(props.adjustDueDateReq[0].PromoFreeDays)
  const [loadSpinner, setloadSpinner] = useState<boolean>(false)//used to load the spinner and dibale the both amount text box and next due date text box
  const res = Number(Number((props.adjustDueDateReq).map(bill => bill.Amount).reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))).toFixed(2));//get the addition of the amount of all agrrement in the adjust all due date case
  const [totalAmount, settotalAmount] = useState<number>(res)//needs to set the totalAmount according to the response of AdjustDueDate service
  const [nextDueDate, setnextDueDate] = useState<any>("")//needs to set according to the response from AjustDueDate Service
  const [errorAmount, seterrorAmount] = useState<boolean>(false)// used for the error msg if the date is invalid
  const [errorValue,seterrorValue] = useState<any>(0)
  const [resAmount, setresAmount] = useState<any>(res)// used to not to trigger the fe lambda if the values are same than the previous execution
  const [calciRes,setcalciRes]  = useState<any>(res)
  const [finalAdjustedArray,setfinalAdjustedArray]  = useState<any>([])
  useEffect(() => {
    console.log('props in adjustDueDate', props)
  }, [])

  //on change of next due date && totalAmountDue adjustDueDate 
  const dateFormate = (input: any) => { return moment(input).format('YYYY-MM-DD') }
  let adjustDueRes:any =[]


  const adjustDueDateChange = async (e: any, ref: string) => {
    debugger;
    
      // setloadSpinner(true)
      // if(props)
   
    const adjustDueDatereq: any = []
    for (let i = 0; i < (props.adjustDueDateReq).length; i++) {
         let coverageEndDate=""
         let policyStatus=""
      if(props.ldwChangeArr.length>0){
        // props.ldwChangeArr
        const coverageEndDateInfo = props.ldwChangeArr.filter(
          (x) => String(x.agreementId) === String(props.adjustDueDateReq[i].Agreementid)
        );
        if(coverageEndDateInfo.length>0){
          if(Number(coverageEndDateInfo[0].action)==2){
            const currentdate: Date = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
            const current_date = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate());
            coverageEndDate =  moment(current_date).format('MM/DD/YYYY');
          }else if(Number(coverageEndDateInfo[0].action)==1){
            coverageEndDate = "12/31/2099"
          }
        }
      }
      if(coverageEndDate==""||coverageEndDate==null||coverageEndDate==undefined){
        coverageEndDate=String(props.adjustDueDateReq[i].coverageEndDate)
      }
      //policy
      if(props.policyChangeArr.length>0){
        // props.ldwChangeArr
        const policyChangeArrInfo = props.policyChangeArr.filter(
          (x) => String(x.agreementId) === String(props.adjustDueDateReq[i].Agreementid)
        );
        if(policyChangeArrInfo.length>0){
          if(Number(policyChangeArrInfo[0].action)==2){
            policyStatus = "I"
          }else if(Number(policyChangeArrInfo[0].action)==1){
            policyStatus = "A"
          }
        }
      }
      if(policyStatus==""||policyStatus==null){
        policyStatus=String(props.adjustDueDateReq[i].policyStatus)
      }
      let scheduleRef=""
      if(props.adjustDueDateReq[i].editSchedule!=""&&props.adjustDueDateReq[i].editSchedule!=null){
        scheduleRef=props.adjustDueDateReq[i].editSchedule
      }else{
        scheduleRef=props.adjustDueDateReq[i].agreementRateDetails.scheduleRefCode
      }
      const res = {
        "agreementId": String(props.adjustDueDateReq[i].Agreementid),
        "agreementRateDetails":props.adjustDueDateReq[i].agreementRateDetails,
        "currentDueDate": String(props.adjustDueDateReq[i].CurrentDueDate),
        "dueDate": String(props.adjustDueDateReq[i].Nextduedate),
        "coverageEndDate": coverageEndDate,
        "taxRate": String(props.adjustDueDateReq[i].taxRate),
        "lateFee": String(props.adjustDueDateReq[i].lateFee),
        "lateFeeTax": String(props.adjustDueDateReq[i].lFeeTaxAmount),
        "policyStatus": String(policyStatus),
        "epoAmount": String(props.adjustDueDateReq[i].EPOAmount),
        "epoTax": String(props.adjustDueDateReq[i].EPOTax),
        "sacDate": String(props.adjustDueDateReq[i].SAC_Date),
        "isLdwAllowed": String(props.adjustDueDateReq[i].isLdwAllowed),
        "agreementNumber": String(props.adjustDueDateReq[i].AgreementNumber),
        "carryRent": String(props.adjustDueDateReq[i].carryRentAmountUsed),
        "carryRentTax": String(props.adjustDueDateReq[i].carryRentTaxUsed),
        "scheduleRefCode": scheduleRef

      }
      adjustDueDatereq.push(res)

    }
    if (ref == "Date") {
      seterrorAmount(false)
      if (((new Date(e) < new Date(props.adjustDueDateReq[0].CurrentDueDate)))) {
        // seterrorDate(true)
      } else if ((dateFormate(e) == dateFormate(nextDueDate))) {
        // seterrorDate(false)
      } else {
        // seterrorDate(false)


        const calcireq = {
          rateResponse: adjustDueDatereq,
          dueDate: e
        }
        //   adjustDueDatereq ={
        //   "agreementId":["1","1"],
        //   "currentDueDate":props.adjustDueDateReq[0].CurrentDueDate,
        //   "nextDueDate":"",
        //   "totalAmount":String(totalAmount)

        // }
        debugger;
        adjustDueRes = await AdjustDueDatecalci(calcireq);
        debugger;
        let totalAmount = 0;
        let totalTaxAmount = 0;
        for (let index = 0; index < adjustDueRes.length; index++) {
          totalAmount += Number(adjustDueRes[index].totalAmount);
          totalTaxAmount += Number(adjustDueRes[index].totalTax);
        }
        setcalciRes(adjustDueRes)
        settotalAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
        setresAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
      }
      setnextDueDate(e)

    } else {
      if(errorAmount==false){
      if (Number(e.target.value) != Number(resAmount)) {
        //   const adjustDueDatereq ={
        //     "agreementId":["1","1"],
        //     "currentDueDate":props.adjustDueDateReq[0].CurrentDueDate,
        //     "nextDueDate":"",
        //     "totalAmount":String(totalAmount)

        // }
        const calcireq = {
          rateResponse: adjustDueDatereq,
          amountAdjusted: String(e.target.value)
        }
        debugger;
         adjustDueRes = await AdjustDueDatecalci(calcireq);
        debugger;
        
       const finadjust:any=[]
        let totalAmount = 0;
        let totalTaxAmount = 0;
        for (let index = 0; index < adjustDueRes.length; index++) {
          totalAmount += Number(adjustDueRes[index].totalAmount);
          totalTaxAmount += Number(adjustDueRes[index].totalTax);
          if(adjustDueRes[index].nextDueDate!="SAC"&&adjustDueRes[index].nextDueDate!="PIF"&&adjustDueRes[0].nextDueDate!="EPO"){
            const MyDate = new Date(adjustDueRes[0].nextDueDate)
          const nextDueDateFormate = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-'
            + ('0' + (MyDate.getDate())).slice(-2)
            ;
  
          setnextDueDate(nextDueDateFormate)
             
          }else{
            finadjust.push(`Adjusted AgreementNumber # ${adjustDueRes[index].agreementNumber} - will be ${adjustDueRes[index].nextDueDate} `)
          }
          
          
        }
        setfinalAdjustedArray(finadjust)
        setcalciRes(adjustDueRes)
        settotalAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
        setresAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
        // seterrorDate(false)
      }
    }

    }
    setloadSpinner(false)

    
    
  }

  /**
   * saveAdjustDueDate to send the response to take payment
   */
  const saveAdjustDueDate = () => {
    // setcalciRes(adjustDueRes)
    
    for (let i = 0; i < props.adjustDueDateReq.length; i++) {
      debugger;
      // const agreeId = calciRes.filter(
      //   (x) => String(x.agreementId) === String(props.adjustDueDateReq[i].Agreementid)
      // );
      // const agreeType = props.adjustDueDateReq.filter(
      //   (x) => String(x.Agreementid) === String(agreeId[0].agreementId)
      // );
      // const nextduedate = moment(agreeId[0].nextDueDate).format('MM/DD/YYYY');
      let nextduedate
      let diffDates
        // if(agreeId[0].nextDueDate!="SAC"&&agreeId[0].nextDueDate!="PIF"&&agreeId[0].nextDueDate!="EPO"){
          // const currentdate: Date = new Date(new Date(nextDueDate).toLocaleString('en-US', { timeZone: 'America/Chicago' }));
           nextduedate = moment(nextDueDate).format('MM/DD/YYYY');
            diffDates = moment(nextduedate, 'MM/DD/YYYY');
        // }else{
        //   nextduedate=agreeId[0].nextDueDate
        // }
        debugger;
        const currentduedate =  moment(props.adjustDueDateReq[i].CurrentDueDate, 'MM/DD/YYYY');
        const diffBetweenDates = diffDates.diff(currentduedate , 'days');
        props.adjustDueDateReq[i].Nextduedate =  nextduedate,
        // lFeeTaxAmount: 0,
        // lateFee:
        props.adjustDueDateReq[i].Amount =0.00+Number(props.adjustDueDateReq[i].lateFee)+Number(props.adjustDueDateReq[i].carryRentAmountUsed)
        // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
        props.adjustDueDateReq[i].Taxamount = 0.00+Number(props.adjustDueDateReq[i].lFeeTaxAmount)+Number(props.adjustDueDateReq[i].carryRentTaxUsed)
        props.adjustDueDateReq[i].policyAmount= Number(0.00)
        // props.adjustDueDateReq[i].editSchedule=selectedSchedule
        props.adjustDueDateReq[i].ldwTaxAmount=Number(0.00),
        props.adjustDueDateReq[i].agrLdwAmount=Number(0.00)
        props.adjustDueDateReq[i].policyTaxAmount=Number(0.00)
        props.adjustDueDateReq[i].PromoFreeDaysApplied=Number(diffBetweenDates)
        props.adjustDueDateReq[i].agreementRate=Number(0.00)
        props.adjustDueDateReq[i].rpayTaxAmount=Number(0.00)
        props.adjustDueDateReq[i].FreeTime={
          freeTimeType: 'PROMO',
          freeTimeDays: Number(diffBetweenDates),
          isOptServiceWaived: true,
        }
        // props.adjustDueDateReq[i].Agreementtype=agreeType[0].Agreementtype=="Club"?"Club":agreeId[0].agreementType
    }
    props.response({ resArray: props.adjustDueDateReq})
  }
  /**
   * adjustDueDatePopUP returns the UI
   * @returns 
   */

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = current => {
    return current.isAfter(yesterday);
  };
  const adjustDueDatePopUP = () => {
    return (
      <Grid container spacing={2} className={classes.px3}>

        <Grid className={`${classes.w100} ${classes.ms2}`} style={{marginBottom:'20px'}}>
                    <Grid className={classes.w70} style={{ backgroundColor:'#EFF4FF',borderRadius:'10px',height:'40px',padding:'7px 10px'}}>
                        <Typography style={{ color:'#2179FE',fontSize:'17px',fontFamily: 'OpenSans-semibold'}}>Agreement #:{props?.adjustDueDateReq[0]?.AgreementNumber}</Typography>
                    </Grid>
                </Grid>
        <Grid
          item
          className={`${classes.w100} ${classes.mt2} ${classes.floatLeft}`}
        >

          <Grid className={`${classes.w100} ${classes.inputgroup} ${classes.mb3}`}>
            <Grid className={`${classes.ms2} ${classes.w50}`}>
              <RACDatePicker
                value={currentduedate}
                label="Current Due Date"
                name="currentDueDate"

                disabled
              />
            </Grid>
            <Grid className={`${classes.ms2} ${classes.w50}`}>
              <RACDatePicker
                value={nextDueDate}
                label="Next Due Date"
                name="nextDueDate"
                disabled={loadSpinner}
                onChange={(e: any) => setnextDueDate(e)}
                inputProps={{
                  min: moment(currentduedate)
                    .add(1, 'd')
                    .format('YYYY-MM-DD'), 
                  max: moment(currentduedate)
                    .add(Number(noOfDayAllowed),'d')
                    .format('YYYY-MM-DD'), 
                }}
               
              />

            </Grid>
       
          </Grid>
       
        </Grid>
      

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="outlined"
            name="clearCancel"
            data-bs-toggle="modal"
            style={{
              backgroundColor: "white",
              color: "#2C3333",
            }}

            onClick={() => { props.isClosed(false) }}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            name="De_ActivateClubbtn"
            data-bs-toggle="modal"
            onClick={() => saveAdjustDueDate()}
            disabled={errorAmount}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <RACModalCard
      isOpen={props.isOpen}
      maxWidth="xs"
      title="Promo Free Time"
      onClose={() => props.isClosed(false)}
      closeIcon={true}
      borderRadius="20px"
      /* eslint-disable react/no-children-prop */
      // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
      children={adjustDueDatePopUP()}
    />
  );
};

export default PromoFreeDays;

/* eslint-disable */
import React, { useContext, useState } from "react";
import {
  RACButton,
  Grid,
  Typography,
  RACModalCard,
  RACTable,
  RACTableRow,
  RACTableCell,
  RACRadio,
} from "@rentacenter/racstrap";
import { ReactComponent as ErrorIcon } from './../../assets/images/no-records-found.svg';
import { customerStyles } from "../../stylesJS/customerStyles";
import { useHistory, useParams } from "react-router-dom";
import { CustomerdataContext } from "../../context/customer/customerdata";
import { ParamType } from "../../constants/constants";
export default function ExchangeAgreementsNavigate(props: any){
    const classes = customerStyles();
    const history = useHistory();
    const [openListOfAgreementsPopup, setOpenListOfAgreementsPopup] = useState<any>(false);
    const {exchangeNavigationPopup, setExchangeNavigationPopup} = useContext(CustomerdataContext);
    const [returnAgreementId, setReturnAgreementId] = useState(props?.value?.exchangeAgreementIds[0]?.agreementId);
    const { customerId } = useParams<ParamType>();
    let agreementNumbers = props?.value?.exchangeAgreementIds;
    agreementNumbers = agreementNumbers.map((e: any)=> e.agreementNumber)
    console.log('Agreement Number and Ids', props);

    const Header = [
        ' ',
        'Agreement #',
        'Description'
    ]

    function showExchangePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                {
                    agreementNumbers?.length > 0 ?
                        <>
                            <ErrorIcon/>
                            <Typography className={`${classes.spacerMT3} ${classes.customerRacpopup123}`} title={agreementNumbers.join(', ')}>
                                Exchange Agreement #{agreementNumbers && agreementNumbers?.length ? (agreementNumbers.join(', '))?.length > 20 ? agreementNumbers.join(', ').slice(0, 19) + `...` : agreementNumbers.join(',') : 0} return
                            </Typography>
                            <Typography className={`${classes.customerRacpopup123}`}>
                                not complete, Do you want to process?
                            </Typography>
                        </>
                        :
                        null
                }
                <Grid className={`${classes.buttonsPadding} ${classes.centerButton}`}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.customerFloatleft} ${classes.mr15px}`}
                        onClick={() => {
                            props?.value?.setExchangedAgreementsNumberPopup(false);
                        }}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.customerFloatleft}`}
                        onClick={() => {
                            if(agreementNumbers?.length > 1){
                                setOpenListOfAgreementsPopup(true);
                            }
                            else{
                                history.push(`/agreement/info/details/${customerId}/${props?.value?.exchangeAgreementIds[0].agreementId}?return=payment`);
                            }
                        }}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        )
    }

    function showListOfAgreementsPopup() {
        return (
            <Grid className={`${classes.textRight}`}>
                {
                    agreementNumbers?.length > 0 ?
                        <RACTable
                            className={classes.racGrid}
                            renderTableHead={bindHead}
                            renderTableContent={bindBody}
                        />
                        :
                        <>
                            <ErrorIcon/>
                            <Typography className={`${classes.customerRacpopup123} ${classes.spacerMT3}`}>
                                No Records Found
                            </Typography>
                        </>
                }
                <Grid className={`${classes.buttonsPadding} ${classes.floatRight} ${classes.pr0}`}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.customerFloatleft} ${classes.mr15px}`}
                        onClick={() => {
                            props?.value?.setExchangedAgreementsNumberPopup(false);
                            setOpenListOfAgreementsPopup(false);
                        }}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.customerFloatleft} ${classes.mr15px}`}
                        onClick={() => {
                            history.push(`/agreement/info/details/${customerId}/${returnAgreementId}?return=payment`)
                        }}
                    >
                        Next
                    </RACButton>
                </Grid>
            </Grid>
        )
    }

    function bindHead(){
        return(
            <>
                {
                    Header.map((element: any) => (
                        <RACTableCell className={element == 'Agreement #' ? `${classes.width40}` : element == 'Description' ? `${classes.width50}` : element == ' ' ? `${classes.width10}`: ''}>
                            {element}
                        </RACTableCell>
                    ))
                }
            </>
        )
    }

    function bindBody(){
        debugger;
        return (
            <>
                {
                    props?.value?.exchangeAgreementIds?.map((element: any) => (
                        <RACTableRow className={`${classes.bgWhilte} ${classes.w100}`}>
                            <RACTableCell className={`${classes.width10}`}>
                                <RACRadio
                                onChange={()=>{
                                    setReturnAgreementId(element?.agreementId)
                                }}
                                value={element?.agreementId}
                                name='agreementId'
                                checked={element?.agreementId == returnAgreementId}
                                />
                            
                            </RACTableCell>
                            <RACTableCell className={`${classes.width40}`}>
                                {element?.agreementNumber}
                            </RACTableCell>
                            <RACTableCell className={`${classes.width50}`}>
                                {element?.agreementDesc && element?.agreementDesc.length > 25 ? element?.agreementDesc?.slice(0, 25) + '...' : element?.agreementDesc}
                            </RACTableCell>
                        </RACTableRow>
                    ))
                }
            </>
        )
    }
    return(
        <>
            <RACModalCard
                isOpen={props?.value?.exchangedAgreementsNumberPopup}
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                onClose={() => {
                    props?.value?.setExchangedAgreementsNumberPopup(false);
                }}
                className={`${classes.customModal4}`}
            >
                {showExchangePopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={openListOfAgreementsPopup}
                title="Return Pending Agreement"
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                onClose={() => {
                    props?.value?.setExchangedAgreementsNumberPopup(false);
                    setOpenListOfAgreementsPopup(false);
                }}
                className={`${classes.customModal4}`}
            >
                {showListOfAgreementsPopup()}
            </RACModalCard>
        </>
    );
}
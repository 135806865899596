/* eslint-disable*/
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useState, useEffect, useContext } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import { CircularProgress } from "@rentacenter/racstrap";
import { CoworkerRequired } from "../api/user";
import { AppRoute } from "../config/route-config";
import { UserProvider } from "../context/user/UserProvider";
//import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from "../components/UserFetcher/UserFetcher";
import { Paymentgrid } from "../../src/components/microfrontends/Takepaymentbutton";
//import { PaymentInformation } from '../../src/components/microfrontends/Takepayment';
import { DeliveryContextData } from "../context/DeliveryContext";
import { UserTraceContext } from "../context/UserTraceContext";
//import { CustomerContextProvider } from '../context/customerContext/customerContext';
//import SearchResult from '../components/customerComponent/SearchCustomerResult';
import { CustomerContext } from "../context/customer/customerdata";
//racstrap
import SearchResults from "../racstrapComponents/customerComponent/SearchCustomerResult";
import { PaymentInformations } from "../../src/racstrapComponents/microfrontends/Takepayment";
import RevenueRecog from "../racstrapComponents/microfrontends/RevenueRecog";
import ParentComponent from "../racstrapComponents/Reverse Payment/ParentComponent";
import { OriginContextProvider } from "../context/OriginContext";
import { COPaymentInformations } from "../racstrapComponents/microfrontends/COPrelim";
import { COInitialPaymentInformations } from "../racstrapComponents/microfrontends/COInitial";
import { RisContext } from "../context/Riscontext";

import { FeatureFlags } from "../refactoredComponents/interface/contextInterface";
import {
  agreementContext,
  PaymentInformationContext,
} from "../refactoredComponents/context/PaymentInformationContext";
import CONSTANTS from "../refactoredComponents/constants/constant";
import PaymentInformationParent from "../refactoredComponents/microfrontends/PaymentInformationParent";
import { NonInventorySale } from "../refactoredComponents/microfrontends/otherIncome/NonInventorySale";
import { ConfigResponse } from "../refactoredComponents/interface/commonInterface";
import Rentalitemsale from "../racstrapComponents/microfrontends/Rentalitemsale";
import RentalItemSale from "../refactoredComponents/microfrontends/rentalItemSale/RentalItemSale";
import { useQueryClient } from "@tanstack/react-query";
import { initial } from "lodash";
//import PaymentInformation from '../components/microfrontends/Takepayment';
export const routesTestId = "routesTestId";

const PaymentCustomRoute = ({ configResponse }) => {
  interface ParamType {
    agreementId: string;
  }
  const [configResults, setConfigResults] = useState();
  const [routeUpdated, setRouteUpdated] = useState(false);
  const [needNewComponent, setNeedNewComponent] = useState(false);
  const location = useLocation<any>();
  const { Risresponse, ristype }: any = (location && location.state) || "";
  const getUrl = window.location.href;
  const urlParams = new URLSearchParams(window.location.search);
  const reloadTime = urlParams.get("reloadTime");
  const returnType = urlParams.get("type");
  const retentionType = urlParams.get("CRTYPE");
  const exchangeAgreementId = urlParams.get("exchangeAgreementId");
  const commitmentAM = urlParams.get("origin");
  const { agreementId } = useParams<ParamType>();

  useEffect(() => {
    let typeRIS = ristype;
    let risResponse = Risresponse;
    if (
      location?.state?.redirectSource == "am" &&
      (location?.state?.ristyp || location?.state?.Risresponse)
    ) {
      location.state.ristype = "";
      location.state.Risresponse = undefined;
      typeRIS = "";
      risResponse = undefined;
    }

    if (
      !reloadTime &&
      !risResponse &&
      !returnType &&
      agreementId == "0" &&
      !retentionType &&
      !exchangeAgreementId &&
      !commitmentAM &&
      !typeRIS &&
      !getUrl.includes("/customerorder/initial/") &&
      !getUrl.includes("/customerorder/preliminary/")
    ) {
      if (location?.state?.redirectSource == "am") {
        window.name = location?.state?.redirectSource;
      } else {
        window.name = "";
      }

      // window.location.href =
      // window.location.origin +
      // window.location.pathname +
      // window.location.search +
      // (window.location.search ? "&" : "?") +
      // "reloadTime=" +
      // Date.now().toString() +
      // window.location.hash;

      const newUrl =
        window.location.origin +
        window.location.pathname +
        window.location.search +
        (window.location.search ? "&" : "?") +
        "reloadTime=" +
        Date.now().toString() +
        window.location.hash;
      window.history.replaceState({ path: newUrl }, "", newUrl);
      window.location.href = newUrl;
    }
  }, []);

  useEffect(() => {
    (() => {
      const configPermissions = {
        paymentOptimized: 0,
        revenueRecognition: 0,
        backRent: 0,
        chargeOff: 0,
        refundSecurityDeposit: 0,
        rentalItemSale: 0,
        manualReceipt: 0,
        voidCardPayment: 0,
        nsfPayment: 0,
        cccbPayment: 0,
      };

      // const storeNumber = String(
      //   window.sessionStorage.getItem("storeNumber")
      // );
      // const bodyobject = {
      //   storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
      //   paramKeyNames: [
      //     "PaymentHistory",
      //     "AcceptEPO",
      //     "CarryRent",
      //     "PaymentFunctions",
      //     "EditSchedule",
      //     "AdjustDue",
      //     "CashSale",
      //     "PaymentFee",
      //     "PaymentTriangle",
      //     "AddApplyTransferSuspense",
      //     "ActivateClub",
      //     "RegularFreeTime",
      //     "ServiceFreeTime",
      //     "RetentionExtensionFreeTime",
      //     "PromoFreeTime",
      //     "RaftApiFlag",
      //     "CarryWaveLateFee",
      //     "OptimizedPayment",
      //     "RevenueRecognition",
      //     "BackRent",
      //     "ChargeOffSkipStolen",
      //     "RefundSecurityDeposit",
      //     "RentalItemSale",
      //     "ManualReceipt",
      //     "VoidCardPayments",
      //     "NSFPayments",
      //     "CCCBPayments",
      //     "Cellphone911Fee_Amount",
      //     "Cellphone911Fee_Percent",
      //     "RemoveAmountDue",
      //     "CommonModulePaymentFlag",
      //     "CloudSwipeCustomerCard",
      //     "CloudStoreSwipeDevicelist",
      //     "AcimaRecovery",
      //     "OtherSale",
      //     "XboxGiftCard",
      //     "XboxMembership",
      //     "PaymentDonation",
      //     "RacDbWebLead",
      //     "FinalPaymentTransferSuspense",
      //     "PaymentSearchCustomerRetention",
      //     "CustomerPaymentPageCustomerRetention",
      //     "AgreementExchange",
      //     "ManageCards",
      //     "CappedFreeTimePercentage",
      //     "CappedFreeTimeRegular",
      //     "CappedFreeTimeService",
      //     "CappedFreeTimeRetention",
      //     "CappedFreeTimeInitPayment",
      //     "CappedFreeTimePromo",
      //     "CappedFreeTimeEnabled",
      //     "EnableGoogleAddressValidation",
      //     "RACExchange",
      //     "AgreementExchangePeriod",
      //     "AgreementExchangeReturnPeriod",
      //     "RewardsEligibilityAmount",
      //     "Txt2PayCardOnFile",
      //     "CustomerRewards",
      //     "PaymentRefactorFlag",
      //   ],
      // };
      // let configResponse;

      // if (
      //   storeNumber &&
      //   storeNumber != null &&
      //   storeNumber != undefined &&
      //   storeNumber != "" &&
      //   storeNumber != "null" &&
      //   storeNumber != "undefined"
      // ) {
      //   configResponse = await CoworkerRequired(bodyobject);
      // }
      setConfigResults(configResponse);
      if (
        configResponse?.storeProfileResponse?.configDetails.length &&
        configResponse?.storeProfileResponse?.configDetails[0].configDetails
          ?.length
      ) {
        configResponse.storeProfileResponse.configDetails[0].configDetails.map(
          (el) => {
            if (el.paramKeyName == "RevenueRecognition") {
              configPermissions.revenueRecognition = Number(el.paramValue);
            }
            if (el.paramKeyName == "OptimizedPayment") {
              configPermissions.paymentOptimized = Number(el.paramValue);
              if (Number(el.paramValue) == 1) {
                setNeedNewComponent(true);
              }
            }
            if (el.paramKeyName == "BackRent") {
              configPermissions.backRent = Number(el.paramValue);
            }
            if (el.paramKeyName == "ChargeOffSkipStolen") {
              configPermissions.chargeOff = Number(el.paramValue);
            }
            if (el.paramKeyName == "RefundSecurityDeposit") {
              configPermissions.refundSecurityDeposit = Number(el.paramValue);
            }
            if (el.paramKeyName == "RentalItemSale") {
              configPermissions.rentalItemSale = Number(el.paramValue);
            }
            if (el.paramKeyName == "ManualReceipt") {
              configPermissions.manualReceipt = Number(el.paramValue);
            }
            if (el.paramKeyName == "VoidCardPayments") {
              configPermissions.voidCardPayment = Number(el.paramValue);
            }
            if (el.paramKeyName == "NSFPayments") {
              configPermissions.nsfPayment = Number(el.paramValue);
            }
            if (el.paramKeyName == "CCCBPayments") {
              configPermissions.cccbPayment = Number(el.paramValue);
            }
            // if (el.paramKeyName == "PaymentRefactorFlag") {
            //   // setRefactorFlag(el.paramValue);
            //   setRefactorFlag("1");
            // }
          }
        );
      }
      setRouteUpdated(true);
    })();
  }, []);

  return (
    <>
      {routeUpdated && needNewComponent ? (
        <Route exact path={AppRoute.PaymentInformation}>
          <RevenueRecog throwConfigValues={configResults} />
        </Route>
      ) : routeUpdated && !needNewComponent ? (
        <Route exact path={AppRoute.PaymentInformation}>
          <PaymentInformations throwConfigValues={configResults} />
        </Route>
      ) : (
        <>
          <div style={{ margin: "20% 20% 20% 48%" }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};

const PaymentRefactorRoute = ({ configResponse, initialKey }) => {
  const {
    setfeatureFlags,
    setAgreementDetails,
    setAgreementDetailsDeepClone,
    setCustomerInfo,
    setCustomerInfoDeepClone,
    setOriginPopup,
  } = useContext(agreementContext);
  const configResult: FeatureFlags = {};
  const [routeUpdated, setRouteUpdated] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    console.log("initialKey, initialKey", initialKey);
    if (initialKey) {
      queryClient.clear();
      setAgreementDetails(CONSTANTS.AGREEMENT_DETAILS_INITIAL_ARRAY);
      setAgreementDetailsDeepClone([]);
      setCustomerInfo(CONSTANTS.CUSTOMER_INITIAL_OBJECT);
      setCustomerInfoDeepClone(CONSTANTS.CUSTOMER_INITIAL_OBJECT);
      setOriginPopup(true);
    }
    setFeatureContext();
  }, []);

  const setFeatureContext = () => {
    configResponse?.storeProfileResponse?.configDetails[0]?.configDetails.map(
      (item: ConfigResponse) =>
        item.paramValue != CONSTANTS.ZERO_STRING
          ? (configResult[item.paramKeyName] = item.paramValue)
          : null
    );
    console.log(configResult, "configResult");
    setfeatureFlags(configResult);
    setRouteUpdated(true);
  };

  return (
    <>
      {routeUpdated ? (
        <PaymentInformationParent />
      ) : (
        <>
          <div style={{ margin: "20% 20% 20% 48%" }}>
            <CircularProgress size={30} />
          </div>
        </>
      )}
    </>
  );
};

export const Routes = () => {
  const [configResponse, setConfigResponse] = useState();
  const [isRefactored, setIsRefactored] = useState<string>("");
  console.log(isRefactored, "isRefactored");
  const [isInitial, setIsInitial] = useState<boolean>(true);
  console.log("initialKey", isInitial);

  useEffect(() => {
    getCoWorkerDetails();
  }, []);

  const getCoWorkerDetails = async () => {
    const storeNumber = String(window.sessionStorage.getItem("storeNumber"));
    const bodyobject = {
      storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
      paramKeyNames: [
        "PaymentHistory",
        "AcceptEPO",
        "CarryRent",
        "PaymentFunctions",
        "EditSchedule",
        "AdjustDue",
        "CashSale",
        "PaymentFee",
        "PaymentTriangle",
        "AddApplyTransferSuspense",
        "ActivateClub",
        "RegularFreeTime",
        "ServiceFreeTime",
        "RetentionExtensionFreeTime",
        "PromoFreeTime",
        "RaftApiFlag",
        "CarryWaveLateFee",
        "OptimizedPayment",
        "RevenueRecognition",
        "BackRent",
        "ChargeOffSkipStolen",
        "RefundSecurityDeposit",
        "RentalItemSale",
        "ManualReceipt",
        "VoidCardPayments",
        "NSFPayments",
        "CCCBPayments",
        "Cellphone911Fee_Amount",
        "Cellphone911Fee_Percent",
        "RemoveAmountDue",
        "CommonModulePaymentFlag",
        "CloudSwipeCustomerCard",
        "CloudStoreSwipeDevicelist",
        "AcimaRecovery",
        "OtherSale",
        "XboxGiftCard",
        "XboxMembership",
        "PaymentDonation",
        "RacDbWebLead",
        "FinalPaymentTransferSuspense",
        "PaymentSearchCustomerRetention",
        "CustomerPaymentPageCustomerRetention",
        "AgreementExchange",
        "ManageCards",
        "CappedFreeTimePercentage",
        "CappedFreeTimeRegular",
        "CappedFreeTimeService",
        "CappedFreeTimeRetention",
        "CappedFreeTimeInitPayment",
        "CappedFreeTimePromo",
        "CappedFreeTimeEnabled",
        "EnableGoogleAddressValidation",
        "RACExchange",
        "AgreementExchangePeriod",
        "AgreementExchangeReturnPeriod",
        "RewardsEligibilityAmount",
        "Txt2PayCardOnFile",
        "CustomerRewards",
        "PaymentRefactorFlag",
      ],
    };

    let configRes;

    if (
      storeNumber &&
      storeNumber != null &&
      storeNumber != undefined &&
      storeNumber != "" &&
      storeNumber != "null" &&
      storeNumber != "undefined"
    ) {
      configRes = await CoworkerRequired(bodyobject);
      

      const paymentRefactorConfig =
        configRes?.storeProfileResponse?.configDetails[0]?.configDetails.find(
          (item: ConfigResponse) => item.paramKeyName === "PaymentRefactorFlag"
        );

      if (paymentRefactorConfig) {
        setIsRefactored(paymentRefactorConfig.paramValue);
      }
      setConfigResponse(configRes);
      setIsInitial(false);
    }
  };

  const renderRoutes = () => {
    if (!configResponse) {
      return (
        <>
          <div style={{ margin: "20% 20% 20% 48%" }}>
            <CircularProgress size={30} />
          </div>
        </>
      );
    } else {
      if (isRefactored == CONSTANTS.ONE_STRING) {
        return (
          <>
            <UserProvider>
              {/* <PermissionsProvider> */}
              <OriginContextProvider>
                <UserFetcher />
                <div data-testid={routesTestId}>
                  <CustomerContext>
                    <RisContext>
                      <UserTraceContext>
                        <DeliveryContextData>
                          <PaymentInformationContext>
                            <Switch>
                              <Route path={[AppRoute.PaymentInformation]}>
                                <PaymentRefactorRoute
                                  configResponse={configResponse}
                                  initialKey={isInitial}
                                />
                              </Route>
                              <Route exact path={AppRoute.Index}>
                                <Redirect to={AppRoute.SearchResult} />
                              </Route>
                              <Route exact path={AppRoute.SearchResult}>
                                <SearchResults />
                              </Route>
                              <Route exact path={AppRoute.Paymentgrid}>
                                <Paymentgrid />
                              </Route>
                              <Route exact path={AppRoute.COPrelim}>
                                <PaymentRefactorRoute
                                  configResponse={configResponse}
                                  initialKey={isInitial}
                                />
                              </Route>
                              <Route exact path={AppRoute.COInitial}>
                                <PaymentRefactorRoute
                                  configResponse={configResponse}
                                  initialKey={isInitial}
                                />
                              </Route>
                              {/* Rental Item Sale */}
                              <Route
                                exact
                                path={AppRoute.RentalItemSaleRefactor}
                              >
                                <RentalItemSale />
                              </Route>

                              {/**Non-Inventory sale */}
                              <Route exact path={AppRoute.NonInventorySale}>
                                <NonInventorySale />
                              </Route>

                              {/* Racstrap */}
                              <Route
                                exact
                                path={AppRoute.PaymentInformationRacstrap}
                              >
                                <PaymentInformations />
                              </Route>
                              <Route exact path={AppRoute.ReversePayment}>
                                <ParentComponent />
                              </Route>
                            </Switch>
                          </PaymentInformationContext>
                        </DeliveryContextData>
                      </UserTraceContext>
                    </RisContext>
                  </CustomerContext>
                  {/* <CustomerContextProvider>
            <Switch>
              <Route exact path={AppRoute.SearchResult}>
                <SearchResult />
              </Route>
            </Switch>
          </CustomerContextProvider> */}
                </div>
                {/* </PermissionsProvider> */}
              </OriginContextProvider>
            </UserProvider>
          </>
        );
      } else {
        return (
          <>
            <UserProvider>
              {/* <PermissionsProvider> */}
              <OriginContextProvider>
                <UserFetcher />
                <div data-testid={routesTestId}>
                  <CustomerContext>
                    <RisContext>
                      <UserTraceContext>
                        <DeliveryContextData>
                          <Switch>
                            <Route path={[AppRoute.PaymentInformation]}>
                              <PaymentCustomRoute
                                configResponse={configResponse}
                              />
                            </Route>
                            <Route exact path={AppRoute.Index}>
                              <Redirect to={AppRoute.SearchResult} />
                            </Route>
                            <Route exact path={AppRoute.SearchResult}>
                              <SearchResults />
                            </Route>
                            <Route exact path={AppRoute.Paymentgrid}>
                              <Paymentgrid />
                            </Route>
                            {/* <Route exact path={AppRoute.PaymentInformation}>
            <PaymentInformations />
          </Route> */}
                            <Route exact path={AppRoute.COPrelim}>
                              <COPaymentInformations />
                            </Route>
                            <Route exact path={AppRoute.COInitial}>
                              <COInitialPaymentInformations />
                            </Route>
                            {/* Rental Item Sale */}
                            <Route exact path={AppRoute.RentalItemSale}>
                              <Rentalitemsale />
                            </Route>

                            {/* Racstrap */}
                            <Route
                              exact
                              path={AppRoute.PaymentInformationRacstrap}
                            >
                              <PaymentInformations />
                            </Route>
                            <Route exact path={AppRoute.ReversePayment}>
                              <ParentComponent />
                            </Route>
                          </Switch>
                        </DeliveryContextData>
                      </UserTraceContext>
                    </RisContext>
                  </CustomerContext>
                  {/* <CustomerContextProvider>
    <Switch>
      <Route exact path={AppRoute.SearchResult}>
        <SearchResult />
      </Route>
    </Switch>
  </CustomerContextProvider> */}
                </div>
                {/* </PermissionsProvider> */}
              </OriginContextProvider>
            </UserProvider>
          </>
        );
      }
    }
  };

  return <>{renderRoutes()}</>;
  // return<>
  //   <UserProvider>
  //   {/* <PermissionsProvider> */}
  //   <OriginContextProvider>
  //     <UserFetcher />
  //     <div data-testid={routesTestId}>
  //       <CustomerContext>
  //         <RisContext>
  //          <UserTraceContext>
  //           <DeliveryContextData>
  //           <PaymentInformationContext>
  //             <Switch>
  //               <Route path={[AppRoute.PaymentInformation]}>
  //                 <PaymentCustomRoute />
  //               </Route>
  //               <Route exact path={AppRoute.Index}>
  //                 <Redirect to={AppRoute.SearchResult} />
  //               </Route>
  //               <Route exact path={AppRoute.SearchResult}>
  //                 <SearchResults />
  //               </Route>
  //               <Route exact path={AppRoute.Paymentgrid}>
  //                 <Paymentgrid />
  //               </Route>
  //               {/* <Route exact path={AppRoute.PaymentInformation}>
  //               <PaymentInformations />
  //             </Route> */}
  //               <Route exact path={AppRoute.COPrelim}>
  //                 <COPaymentInformations />
  //               </Route>
  //               <Route exact path={AppRoute.COInitial}>
  //                 <COInitialPaymentInformations />
  //               </Route>
  //               {/* Rental Item Sale */}
  //               <Route exact path={AppRoute.RentalItemSale}>
  //                 <Rentalitemsale />
  //               </Route>

  //               {/**Non-Inventory sale */}
  //                 <Route exact path={AppRoute.NonInventorySale}>
  //                   <NonInventorySale />
  //                 </Route>

  //               {/* Racstrap */}
  //               <Route exact path={AppRoute.PaymentInformationRacstrap}>
  //                 <PaymentInformations />
  //               </Route>
  //               <Route exact path={AppRoute.ReversePayment}>
  //                 <ParentComponent />
  //               </Route>
  //             </Switch>
  //             </PaymentInformationContext>
  //           </DeliveryContextData>
  //           </UserTraceContext>
  //         </RisContext>
  //       </CustomerContext>
  //       {/* <CustomerContextProvider>
  //       <Switch>
  //         <Route exact path={AppRoute.SearchResult}>
  //           <SearchResult />
  //         </Route>
  //       </Switch>
  //     </CustomerContextProvider> */}
  //     </div>
  //     {/* </PermissionsProvider> */}
  //   </OriginContextProvider>
  // </UserProvider>
  // </>
};

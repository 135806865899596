/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useContext } from 'react';

// import RACLogo from '../../assets/images/RAC-Logo.svg';
import { ReactComponent as RACLogo } from '../../assets/images/RACUpdatedLogoNew.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
// import Erroricon from '../../assets/images/erroricon.svg';

// import OtpInput from 'react-otp-input';
//import Typography from '@mui/material/Typography';
//import Grid from '@mui/material/Grid';
/* eslint-enable no-console */
import {
  CircularProgress,
  Typography,
  Grid,
  RACButton,
  RACTextbox,
  RACModalCard,
  makeStyles,
  RACCOLOR,
} from '@rentacenter/racstrap';
// import PinField from 'react-pin-field';
import PinField from 'react-pin-input';
import clsx from 'clsx';
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import {
  getPinDetails,
//   getprofileaccess,
  getsecondfactor,
  GetRemoteLoginStatus,
  GetRole
} from '../../api/user';
import { RentaldataContext } from '../../context/Riscontext';

export type PropsInterface = {
  setTwoFactorCancelClick?: any;
  setTwoFactorCompleted?: any;
  setEmployeeDetails?: any;
  moduleName?: string;
  storeAccessResponse?: any;
  CurrentRole?: string;
  pin:any;
  setPin:any;
  firstlogin?:any;
  setFirstlogin?:any;
  remoteFlag?:any;
  setRemoteFlag?:any;
};

const useClasses = makeStyles((theme: any) => ({
  textCenter: {
    textAlign: 'center',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  racRemoteLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    marginTop: '15px'
  },
  racRemoteLogin: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    textAlign: 'center',
    margin: '25px 15px',
    fontSize: theme.typography.pxToRem(14),
  },

  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  racLoader: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
  },
  spinnerModal: {
    position: 'absolute',
    inset: '40px',
    border: '1px solid rgb(204 204 204 / 0%)',
    background: 'rgb(255 255 255 / 0%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  buttonMargin: {
    marginRight: '50px'
  },
  masterLoader: {
    position: "fixed",
    backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 1050,
    textAlign: "center",
    margin: 0,
  },
  Loader: {
    display: "block",
    position: "fixed",
    zIndex: 1051,
    top: "50%",
    right: "50%",
  },
}));

export default function RentalSecondFactor(props: PropsInterface) {
  const classes = useClasses();
  const { setTwoFactorCancelClick, setTwoFactorCompleted } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role, setRole] = useState<any>('');
  const [openPinModal, setOpenPinModal] = useState(false);
  const [openRemotePopup, setopenRemotePopup] = useState(false);
  // eslint-disable-next-line prefer-const
  let [otp, setotp] = useState('');
  const [loader, setloader] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup'
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [somethingtwo, setsomethingtwo] = React.useState('modal fade');

  const [InvalidPin, setInvalidPin] = React.useState<any>();
  //   const [InvalidPin1, setInvalidPin1] = React.useState(false);
  const [Continue, setContinue] = React.useState(true);
  const [remoteId, setremoteId] = useState('');
  const [securityCode, setsecurityCode] = useState('');
  const [errorMessage, seterrorMessage] = useState('');
  const {setSlaRole}=useContext(RentaldataContext)


  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };
  const handleRemoteID = (event: any) => {
    setremoteId(event.target.value);
  };

  const handleSecurityCode = (event: any) => {
    setsecurityCode(event.target.value);
  };


  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setloader(true);
   
    let proRol:any
   
    setErrorLine(false);
    const GetMenuPayload = {
      coworkerId:username,
      };
    const GetMenuDetails: any = await GetRole(GetMenuPayload);
    if (GetMenuDetails.status === 200) {
        const  GetMenuDetailsResponse = GetMenuDetails?.data;
         const currentRole = GetMenuDetailsResponse?.coworkerProfile?.role;
         proRol=GetMenuDetailsResponse?.coworkerProfile?.role
          setSlaRole(currentRole)
         }
         const payload = {
            userName: username,
            role:  proRol,
            storeNumber: window.sessionStorage.getItem('storeNumber'),
            password: Password,
          };
    const result = await getsecondfactor(payload);
    setloader(false);
    UserValidate(result);
  };
  const remoteContinue = async (remoteId: any, securityCode: any) => {
    setopenRemotePopup(false);
    setloader(true);
    const payload = {
      userName: remoteId,
      passCode: securityCode
    };
    seterrorMessage('');
    const result = await GetRemoteLoginStatus(payload);
    if (result?.status == 200 && result?.data?.response?.result == 'allow') {
      const GetMenuPayload = {
        coworkerId:remoteId,
        };
         const GetMenuDetails: any = await GetRole(GetMenuPayload);
         if (GetMenuDetails?.status === 200) {
          const  GetMenuDetailsResponse = GetMenuDetails.data;
           const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          //  proRol=GetMenuDetailsResponse.coworkerProfile.role
            setSlaRole(currentRole)
           }
          }
    setloader(false);
    RemoteValidate(result);
  };
  const RemoteValidate = (result: any) => {
    if (result?.status == 200) {
      if (result?.data?.response?.result == 'allow') {
        setopenRemotePopup(false)
        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;
      }
      else {
        seterrorMessage(result?.data?.response?.status_msg)
        setopenRemotePopup(true)
      }
    } else {
      // console.log('Hi from remote validate', result)
      seterrorMessage(result?.data?.errors[0]?.error?.split('"')[7])
      setopenRemotePopup(true)
    }
  };



  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };
  const remoteModelCancelClick = () => {
    seterrorMessage('');
    setopenRemotePopup(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };

  useEffect(() => {
    setsomethingone('modal fade show expandPopup');
    setsomethingtwo('modal fade');
    // const payload = {
    //   module: props.moduleName || 'Accept Payment',
    //   storeNumber: window.sessionStorage.getItem('storeNumber'),
    //   currentRole: props.CurrentRole,
    // };
    const PageLoad = async () => {
    //   const result =
    //     props.storeAccessResponse == undefined
    //       ? await getprofileaccess(payload)
    //       : props.storeAccessResponse;
      setloader(false);
      setRole(props.CurrentRole);
      popupSelect(props.pin);
    };
    PageLoad();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popupSelect = (result: any) => {
    if (result === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
    else if(result === 0)
       setOpenPinModal(true);
      setpopupFlag(false);
  };

  const openModelCancelClick = () => {
    setErrorLine(false);
    setOpenModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };

  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

  const remoteEnter = (event: any, remoteId: any, securityCode: any) => {
    if (event.charCode === 13 && remoteId !== '' && securityCode !== '') {
      event.preventDefault();
      remoteContinue(remoteId, securityCode);
    }
  };
  const remoteClick = () => {
    setOpenModal(false)
    setopenRemotePopup(true)
  }


  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = containerData.GetEmployeeId();
    const id = userResponse;
    // setEmployeeDetails !== undefined
    //   ? setEmployeeDetails(userResponse.data)
    //   : null;
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    const result = await getPinDetails(payload);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
    }
  };

  const noClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  const pinValue = (event: any) => {
    otp = event;
  };

  //eslint-disable-next-line sonarjs/no-identical-functions
  const onCloseClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
  };

  const onCloseSecondfacClick = () => {
    setOpenModal(false);
  };
  //eslint-disable-next-line sonarjs/no-identical-functions
  const onPinCloseClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };

  const openModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorline === true ? (
          <Grid className={classes.spacerMargin}>
            <Typography className={classes.racLoginAlert}>
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => secondfactorEnter(event, userID, password)}
              isCurrency={false}
              type="password"
              value={password}
              OnChange={(event) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.buttonMargin
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={() => remoteClick()}
          >
            Remote
          </RACButton>

          {userID !== '' && password !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openRemoteModal = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
          <RACLogo className={classes.logoResponsive}></RACLogo>
        </Grid>
        {errorMessage !== '' ? <Grid className={classes.spacerMargin}>
          <Typography className={classes.racRemoteLoginAlert}>
            {errorMessage}
          </Typography>
        </Grid> : null}
        <Grid className={classes.spacerMargin}>
          <Typography className={classes.racRemoteLogin}>
            Please enter the DM in Training or above level security code to continue
          </Typography>
        </Grid>
        <Grid container spacing={2} className={classes.spacerMT1}>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Remote ID
            </Typography>
            <RACTextbox
              maxlength={30}
              isCurrency={false}
              type="text"
              value={remoteId}
              OnChange={(event) => handleRemoteID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="body2"
              component="label"
              className={classes.formLabel}
            >
              Security Code
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => remoteEnter(event, remoteId, securityCode)}
              isCurrency={false}
              type="password"
              value={securityCode}
              OnChange={(event) => handleSecurityCode(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={remoteModelCancelClick}
          >
            Cancel
          </RACButton>
          {remoteId !== '' && securityCode !== '' ? (
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => remoteContinue(remoteId, securityCode)}
            >
              Continue
            </RACButton>
          ) : (
            <RACButton
              variant="contained"
              color="primary"
              className={classes.disabled}
            >
              Continue
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };


  const openAlertModalContent = () => {
    return (
      <div>
        <Grid className={classes.textCenter}>
        <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={classes.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.spacerME2}
            onClick={okClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid item md={12} onKeyPress={pinEnter}>
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: '0.3rem' }}
              inputStyle={{
                border: '1px solid #adb5bd',
                borderRadius: '0.6rem',
                marginRight: '20px',
              }}
              onComplete={(event) => pinValue(event)}
              onChange={(event) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          classes={{
            root: clsx(
              classes.floatRight,
              classes.spacerMT5,
              classes.spacerMB3,
              classes.paddingButtons
            ),
          }}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.spacerME2}
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            disabled={Continue}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      {loader === true ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {/* <Modal
        isOpen={loader}
        className={classes.spinnerModal}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div>
          <div className={classes.racLoader}>
            <CircularProgress />
          </div>
        </div>
      </Modal> */}
      <RACModalCard
        isOpen={openModal}
        borderRadius="20px"
        onClose={onCloseSecondfacClick}
        maxWidth={'xs'}
        className="racSecondFacPopup"
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openModalContent()}
      ></RACModalCard>
       <RACModalCard
        isOpen={openRemotePopup}
        borderRadius="20px"
        maxWidth={'xs'}
        className="racSecondFacPopup"
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openRemoteModal()}
      ></RACModalCard>

      <RACModalCard
        isOpen={openSecondModal}
        borderRadius="20px"
        closeIcon={true}
        onClose={onCloseClick}
        maxWidth={'sm'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openAlertModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openPinModal}
        borderRadius="20px"
        className="racPinPopup"
        closeIcon={true}
        onClose={onPinCloseClick}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>
    </div>
  );
}

/*eslint-disable */
/**
 * PS_NO_NS_35 - PS_NO_NS_36
 * Import required hooks and the racstrap elements
 */
import React, { useContext } from 'react';
import {
  Grid,
  RACTextbox,
  TextareaAutosize,
  Typography,
} from '@rentacenter/racstrap';
import { nonInventorySaleStyles } from '../../../stylesJS/nonInventorySaleStyles';
import { getTax } from '../../../api/user';
import CONSTANTS from '../../constants/constant';
import {
  AcimaOtherProps,
  formatedTaxPayload,
} from '../../interface/nonInventorySaleInterface';
import { agreementContext } from '../../context/PaymentInformationContext';
export function AcimaOther(props: AcimaOtherProps) {
  /**
   * PS_NO_NS_37
   * The destructuring of props is done here
   */
  const {
    nonInventorySaleType,
    acimaOtherData,
    setacimaOtherData,
    setblurLoaderActive,
  } = props;
  const classes = nonInventorySaleStyles();
  const taxInputCategory = {
    salesPrice: 'Sales Tax',
    deliveryFee: 'Delivery Tax',
  };
  const { taxPayload } = useContext(agreementContext);

  /**
   * PS_NO_NS_38
   * The handleAcimaChange() method is invoked while editing the amount in the text fields
   * @param event
   * @param name
   */
  const handleAcimaChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    name: string
  ) => {
    const val = event.target.value.replace(
      CONSTANTS.REPLACE_COMMA,
      CONSTANTS.EMPTY_STRING
    );
    switch (name) {
      case 'description':
        setacimaOtherData({
          ...acimaOtherData,
          description: event.target.value,
        });
        break;
      case 'salesPrice':
        setacimaOtherData({
          ...acimaOtherData,
          salesPrice: (val),
          totalSales: (
            Number(val) +
            Number(acimaOtherData.deliveryFee)
          ).toFixed(2),
          salesTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING
        });
        break;
      case 'deliveryFee':
        setacimaOtherData({
          ...acimaOtherData,
          deliveryFee: (val),
          totalSales: (
            Number(val) +
            Number(acimaOtherData.salesPrice)
          ).toFixed(2),
        });
        break;
    }

  }

  /**
   * PS_NO_NS_39
   * The handleAcimaBlur() method is invoked while cliking outside the text box after editing.
   * @param e
   * @param source
   */
  const handleAcimaBlur = async (
    e: React.FocusEvent<HTMLInputElement>,
    source: string
  ) => {
    console.log('AcimaBlur',e);
    const finalSalesPrice = e.target.value;
    let obtainedTax = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    setblurLoaderActive(true);
    const formatedTaxPayload: formatedTaxPayload = taxPayload;
    formatedTaxPayload.storeInputs = [
      {
        taxInputs: [
          {
            amount: Number(
              finalSalesPrice.replace(
                CONSTANTS.REPLACE_COMMA,
                CONSTANTS.EMPTY_STRING
              )
            ).toFixed(2),
            taxInputId: taxInputCategory[source],
            itemCategory:
              source != 'deliveryFee'
                ? CONSTANTS.CATEGORYMAPPER[nonInventorySaleType]
                : 'DELIVFEE',
          },
        ],
        storeNumber: String(
          window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
        ),
      },
    ];
    const taxResponse = await getTax(formatedTaxPayload);
    console.log('taxResponse', taxResponse);  // PS_NO_NS_40

    setblurLoaderActive(false);
    if (taxResponse?.status == 200) {
      obtainedTax = taxResponse?.data?.taxResponse[0]?.taxOutputs[0]?.taxAmount;
    }

    if (
      source == 'salesPrice' &&
      Number(finalSalesPrice) > CONSTANTS.ZERO_NUMBER
    ) {
      //   const finalSalesPrice = e.target.value;
      setacimaOtherData(prevData=>({
        ...prevData,
        salesPrice: finalSalesPrice
          ? Number(
              finalSalesPrice.replace(
                CONSTANTS.REPLACE_COMMA,
                CONSTANTS.EMPTY_STRING
              )
            ).toFixed(2)
          : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        salesTax: (
          Number(obtainedTax) + Number(acimaOtherData.deliveryFeeTax)
        ).toFixed(2),
        totalSales: (
          Number(obtainedTax) +
          Number(acimaOtherData.deliveryFee) +
          Number(acimaOtherData.salesPrice) +
          Number(acimaOtherData.deliveryFeeTax)
        ).toFixed(2),
      }));
    } else if (
      source == 'deliveryFee' &&
      Number(finalSalesPrice) > CONSTANTS.ZERO_NUMBER
    ) {
      setacimaOtherData(prevData=>({
        ...prevData,
        deliveryFee: finalSalesPrice
          ? Number(
              finalSalesPrice.replace(
                CONSTANTS.REPLACE_COMMA,
                CONSTANTS.EMPTY_STRING
              )
            ).toFixed(2)
          : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        deliveryFeeTax: obtainedTax,
        salesTax: (
          Number(obtainedTax) +
          Number(acimaOtherData.salesTax) -
          Number(acimaOtherData.deliveryFeeTax)
        ).toFixed(2),
        totalSales: (
          Number(obtainedTax) +
          Number(acimaOtherData.deliveryFee) +
          Number(acimaOtherData.salesPrice) +
          Number(acimaOtherData.salesTax) -
          Number(acimaOtherData.deliveryFeeTax)
        ).toFixed(2),
      }));
    } else if (
      source == 'deliveryFee' &&
      Number(finalSalesPrice) == CONSTANTS.ZERO_NUMBER
    ) {
      setacimaOtherData(prevData=>({
        ...prevData,
        deliveryFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        deliveryFeeTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        salesTax: (
          Number(acimaOtherData.salesTax) -
          Number(acimaOtherData.deliveryFeeTax)
        ).toFixed(2),
        totalSales: (
          Number(acimaOtherData.salesPrice) +
          (Number(acimaOtherData.salesTax) -
            Number(acimaOtherData.deliveryFeeTax))
        ).toFixed(2),
      }));
    } else if (
      source == 'salesPrice' &&
      Number(finalSalesPrice) == CONSTANTS.ZERO_NUMBER
    ) {
      setacimaOtherData(prevData=>({
        ...prevData,
        salesPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        salesTax: Number(acimaOtherData.deliveryFeeTax).toFixed(2),
        totalSales: (
          Number(acimaOtherData.deliveryFee) +
          Number(acimaOtherData.deliveryFeeTax)
        ).toFixed(2),
      }));
    }
  };

  return (
    <>
      {/**PS_NO_NS_37
       * This return part renders the entire UI of the Non-Inventory sale.
       */}
      {nonInventorySaleType == 'AcimaRecovery' ||
      nonInventorySaleType == 'Other' ? (
        <Grid>
          <Grid item sm={12} md={12} lg={8}>
            <Typography variant="h6" className={classes.description}>
              Description<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextareaAutosize
              name="description"
              minRows={5}
              className={classes.textArea}
              value={acimaOtherData.description}
              //   value={"hii"}
              data-testid="description"
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleAcimaChange(e, 'description')
              }
            />
          </Grid>
          <Grid className={classes.w100}>
            <Grid
              item
              md={2}
              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
            >
              <RACTextbox
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                inputLabelClassname={classes.marginBottom}
                type="number"
                name="salesPrice"
                data-testid='sale-price'
                value={acimaOtherData.salesPrice}
                // value={"20.00"}
                disabled={false}
                isCurrency={true}
                maxlength={10}
                inputlabel="Sales Price"
                required={true}
                OnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAcimaChange(e, 'salesPrice')}
                Onblur={(e: React.FocusEvent<HTMLInputElement>) => handleAcimaBlur(e, 'salesPrice')}
              />
            </Grid>

            <Grid
              item
              md={2}
              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
            >
              <RACTextbox
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                inputLabelClassname={classes.marginBottom}
                type="number"
                name="deliveryFee"
                value={acimaOtherData.deliveryFee}
                // value={"30"}
                disabled={false}
                isCurrency={true}
                maxlength={10}
                inputlabel="Delivery Fee"
                // required={true}
                OnChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAcimaChange(e, 'deliveryFee')}
                Onblur={(e: React.FocusEvent<HTMLInputElement>) => handleAcimaBlur(e, 'deliveryFee')}
              />
            </Grid>

            <Grid
              item
              md={2}
              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
            >
              <RACTextbox
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                inputLabelClassname={classes.marginBottom}
                type="number"
                name="salesTax"
                value={acimaOtherData.salesTax}
                // value={"0.55"}
                disabled={true}
                isCurrency={true}
                maxlength={10}
                inputlabel="Sales Tax"
              />
            </Grid>
            <Grid
              item
              md={2}
              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
            >
              <RACTextbox
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                inputLabelClassname={classes.marginBottom}
                type="number"
                name="totalSales"
                value={acimaOtherData.totalSales}
                // value={"55.00"}
                disabled={true}
                isCurrency={true}
                maxlength={10}
                inputlabel="Total Sales"
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  )
}
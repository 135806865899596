/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
import React, { useContext, useEffect } from "react";
import {
  Grid,
  RACButton,
  RACModalCard,
  RACSelect,
  TextField,
  Typography,
} from "@rentacenter/racstrap";
import { postReversePayment, getDocument, storeException } from "../../api/user";
import {
  ReverseReasonObject,
  ModalRadius,
  ReversePayConfirmationMessage,
} from "../../constants/constants";
import { b64toBlob, getContentType } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import SecondFactor from "../microfrontends/TwoFactorPopup";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
import StoreException from "../microfrontends/StoreException";
import { CustomerdataContext } from "../../context/customer/customerdata";
export default function Footer() {
  const history = useHistory();
  const classes = reversePaymentStyle();
  const { StoreExceptionPayload, setStoreExceptionPayload } = React.useContext(CustomerdataContext)

  let stopFlag = false;
  const containerData: any = useContext(
    ContainerContext
  ) as CustomPropInterface;
  const {
    reverseReason,
    openSLA,
    reverseConfirmation,
    confirmationMessage,
    messageTracker,
    reverseReasonValue,
    controlReverseButton,
    cardConfirmationMessage,
    reversePayOptions,
    exceptionModal,
    postReversePayPayload,
    loadingModal,
    tenderTypesUsed,
    disableSaveBtn,
    restrictWFG,
    CCAmount,
    CurrentRole,
    isVoidFlow,
    openVoidConfirmation,
  } = useContext(GeneralStateContext);
  const {
    setReverseReason,
    setOpenSLA,
    setReverseConfirmation,
    setConfirmationMessage,
    setMessageTracker,
    setReverseReasonValue,
    setLoadingModal,
    setExceptionModal,
    setOpenCheckMOPopup,
    setCardReversal,
    setReverseReceiptURL,
    setOpenViewReceipt,
    setDisableSaveBtn,
    setCurrentRole,
    setEmployeeId,
    setIsPostReversalFailed,
    setOpenVoidConfirmation,
    setFailedReceiptFetching,
    setReversedReceiptID
  } = useContext(GeneralStateDispatchContext);
  const [StoreExceptionEnable, setStoreExceptionEnable] = React.useState(false)


  useEffect(() => {
    if (containerData) {
      setCurrentRole(containerData.GetRole());
      setEmployeeId(containerData.GetEmployeeId());
    }
  }, [containerData]); // SQ-13 This method will be obtaining the logged in employee id & their role

  const bindReverseReason = () => {
    return reversePayOptions.map((obj: any) => {
      if (
        obj.description == "WORRY FREE GUARANTEE (FIRST 7 DAYS AFTER SALE ONLY)"
      ) {
        return { label: "Worry Free Guarantee", value: obj.referenceCode };
      } else {
        return { label: obj.description, value: obj.referenceCode };
      }
    });
  }; // SQ-16 This method will be rendering the reason drop down values dynamically

  const reverseReasonRendered = () => {
    return (
      <Grid item md={12}>
        <Grid className={`${classes.justifyContentCenter}`}>
          <RACSelect
            inputLabel="Reason"
            required={true}
            options={bindReverseReason()}
            defaultValue={
              reverseReasonValue.reverseReason !== ""
                ? reverseReasonValue.reverseReason
                : "SELECT"
            }
            onChange={(e: any) => {
              if (restrictWFG && e.target.value == "WFG") {
                setDisableSaveBtn(1);
              } else {
                setDisableSaveBtn(0);
              }
              setReverseReasonValue({
                ...reverseReasonValue,
                reverseReason: e.target.value == "SELECT" ? "" : e.target.value,
              });
            }}
            {...(restrictWFG &&
              reverseReasonValue.reverseReason == "WFG" && {
              errorMessage:
                "Payment reversal for Worry Free Guarantee can be done only for Initial Payments.",
            })}
          />
        </Grid>
        <Grid container className={`${classes.mt4}`}>
          <Typography className={classes.notesLabel}>
            Reason Code Information{" "}
            <span className={`${classes.reqField}`}>*</span>
          </Typography>
          <TextField
            className={classes.fieldVisitTextContent}
            multiline
            variant="outlined"
            maxRows={3}
            minRows={3}
            value={reverseReasonValue.reasonInformation}
            onChange={(e) => {
              setReverseReasonValue({
                ...reverseReasonValue,
                reasonInformation: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentRight} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={closeReasonModal}
          >
            Cancel
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setReverseReason(false);
              setOpenSLA(true);
            }}
            disabled={
              reverseReasonValue.reasonInformation !== "" &&
                reverseReasonValue.reverseReason !== "" &&
                disableSaveBtn === 0
                ? false
                : true
            }
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // SQ-15 This method will be rendering the reverse reason popup.

  const SLACompleted = () => {
    setOpenSLA(false);
    if (isVoidFlow) {
      setOpenVoidConfirmation(true);
    } else {
      setReverseConfirmation(true);
      setConfirmationMessage(
        ReversePayConfirmationMessage.RefundConfirmationMessage
      );
      setMessageTracker(messageTracker + 1);
    }
  }; // SQ-17 This method is invoked when SLA is success.

  const SLACanceled = () => {
    setOpenSLA(false);
    setReverseReasonValue(ReverseReasonObject);
  }; // SQ-18 This method is invoked when SLA popup is closed or SLA got failed.

  const closeConfirmation = () => {
    setReverseConfirmation(false);
    setReverseReasonValue(ReverseReasonObject);
    setMessageTracker(0);
  }; // SQ-19 This method is invoked when close or cancel button is clicked in the confirmation popup

  const closeReasonModal = () => {
    setReverseReason(false);
    setReverseReasonValue(ReverseReasonObject);
    setDisableSaveBtn(0);
  }; // This method is invoked when close button is clicked in the reverse confirmation popup.

  const apiCall = async (postReversalData) => {
    const receiptResponse = await getDocument(
      postReversalData.data.reversedReceiptId,
      "RCP"
    );
    if (
      receiptResponse.status == 200 &&
      Object.keys(receiptResponse.data).length > 0 &&
      receiptResponse.data.fileList[0].fileObject !== null
    ) {
      const Receiptrecord = receiptResponse.data.fileList.filter(
        (x) => x.docType === "PRCT"
      );
      if (Receiptrecord.length) {
        const type = getContentType(Receiptrecord[0]?.fileName);
        setReverseReceiptURL(
          URL.createObjectURL(b64toBlob(Receiptrecord[0]?.fileObject, type))
        );
      }
      console.log("5tyt", Receiptrecord);
      stopFlag = true;
      setOpenViewReceipt(true);
    }
  };

  const invokeGenerateReceipt = async (postReversalData) => {
    setReversedReceiptID(postReversalData.data.reversedReceiptId);
    let receiptTimeOut = true;
    setTimeout(async function () {
      receiptTimeOut = false;
    }, 25000);
    // const paymentReceiptRequest = {
    //   receiptId: Number(postReversalData.data.reversedReceiptId),
    //   resultText: "",
    //   cellPhoneActivation: [],
    // };
    setLoadingModal({ ...loadingModal, document: true });
    while (!stopFlag && receiptTimeOut) {
      await apiCall(postReversalData);
    }
    // const receiptResponse = await ReceiptGeneration(paymentReceiptRequest);
    setLoadingModal({ ...loadingModal, document: false });
    // if (receiptResponse.status === 200) {
    //   setReverseReceiptURL(
    //     URL.createObjectURL(
    //       b64toBlob(receiptResponse.data.receiptHtmlUrl, "text/html")
    //     )
    //   );
    //   setOpenViewReceipt(true);
    // } else if (receiptResponse.status === 500) {
    //   setExceptionModal({ ...exceptionModal, serverError: true });
    // } else if (receiptResponse.status === 400) {
    //   setExceptionModal({ ...exceptionModal, badRequest: true });
    // }
    if (!stopFlag) {
      setFailedReceiptFetching(true);
      // setExceptionModal({ ...exceptionModal, badRequest: true });
    }
  }; // SQ-22 This method is invoked when reverse payment is success. This method will be proving the latest reversal receipt.

  const acceptConfirmation = async () => {
    debugger
    if (messageTracker == 1) {
      setConfirmationMessage(
        ReversePayConfirmationMessage.CustomerAvailableConfirmationMessage
      );
      setReverseConfirmation(true);
      setMessageTracker(messageTracker + 1);
    } else if (messageTracker == 2) {
      if (
        tenderTypesUsed.card &&
        Number(CCAmount.totalCashPayEligible) === 0 &&
        Number(CCAmount.totalCardPayEligible) > 0
      ) {
        setReverseConfirmation(false);
        setMessageTracker(0);
        setCardReversal(true);
      } else {
        setConfirmationMessage(cardConfirmationMessage);
        setReverseConfirmation(true);
        setMessageTracker(messageTracker + 1);
      }
    } else if (messageTracker == 3) {
      setReverseConfirmation(false);
      setMessageTracker(0);
      if (tenderTypesUsed.check) {
        setOpenCheckMOPopup(true);
      } else if (tenderTypesUsed.card) {
        setCardReversal(true);
      } else {
        setStoreExceptionEnable(true)
      }

    }
  }; // SQ-19 This method is invoked when confirmation popup is accepted.

  const reverseConfirmationRenderer = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <Typography className={`${classes.bold} ${classes.titletxtstyle}`}>
            {confirmationMessage}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={closeConfirmation}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={acceptConfirmation}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // This method will be rendering all the reversal confirmation messages.

  const voidConfirmationRenderer = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <Typography className={`${classes.bold} ${classes.titletxtstyle}`}>
            Are you sure, you want to initiate a refund of the preliminary
            payment ?
          </Typography>
          <Typography className={`${classes.bold} ${classes.titletxtstyle}`}>
            You must void the agreement once the refund is completed.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => {
              setOpenVoidConfirmation(false);
              setReverseReasonValue(ReverseReasonObject);
              setDisableSaveBtn(0);
            }}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenVoidConfirmation(false);
              setReverseConfirmation(true);
              setConfirmationMessage(
                ReversePayConfirmationMessage.RefundConfirmationMessage
              );
              setMessageTracker(messageTracker + 1);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const storeExceptionCompleted = async (updatedPayload?) => {
    debugger
    setStoreExceptionEnable(false)
    const StoreExceptionPayload = updatedPayload
    setLoadingModal({ ...loadingModal, reversePay: true });
    const postReversal = await postReversePayment(postReversePayPayload);
    setLoadingModal({ ...loadingModal, reversePay: false });
    debugger
    if (postReversal.status === 200) {
      if (postReversal?.data && StoreExceptionPayload) {
        const payload = StoreExceptionPayload.map((obj) => {
          return obj.identifierId = Number(postReversal?.data?.reversedReceiptId)
        })
        const Request = {
          storeException: StoreExceptionPayload
        }
        storeException(Request);
        setStoreExceptionPayload([])
      }
      invokeGenerateReceipt(postReversal);
    } else if (postReversal.status === 400) {
      // setExceptionModal({ ...exceptionModal, badRequest: true });
      setIsPostReversalFailed(true);
    } else if (postReversal.status === 500) {
      setExceptionModal({ ...exceptionModal, serverError: true });
    }


  }
  const storeExceptionCancelled = async () => {
    debugger
    setStoreExceptionEnable(false);
    setReverseReasonValue(ReverseReasonObject);
    setMessageTracker(0);
  }

  return (
    <Grid className={classes.fixedBottom}>

      {StoreExceptionEnable ? (<StoreException
        refCode={'RFN'}
        identifierType={'RECEIPT'}
        storeExceptionCancelled={storeExceptionCancelled}
        storeExceptionCompleted={storeExceptionCompleted}
      />) : null}
      <Grid className={classes.floatLeft}>
        <RACButton
          color="primary"
          variant="outlined"
          className={classes.ms2}
          onClick={() =>
            history.push({
              pathname: `/payment1/paymentsearch`,
            })
          }
        >
          Cancel
        </RACButton>
      </Grid>

      <Grid className={classes.floatRight}>
        <RACButton
          color="primary"
          variant="contained"
          onClick={() => setReverseReason(true)}
          // onClick={() => setCardReversal(true)}
          disabled={controlReverseButton}
        >
          Reverse
        </RACButton>
      </Grid>
      <RACModalCard
        isOpen={reverseReason}
        maxWidth="xs"
        borderRadius={ModalRadius}
        title="Reason for Reversing Payment"
        closeIcon={true}
        onClose={closeReasonModal}
        contentClassName={`${classes.p0}`}
        //eslint-disable-next-line react/no-children-prop
        children={reverseReasonRendered()}
      />
      <RACModalCard
        isOpen={reverseConfirmation}
        maxWidth="sm"
        borderRadius={ModalRadius}
        closeIcon={true}
        onClose={closeConfirmation}
        //eslint-disable-next-line react/no-children-prop
        children={reverseConfirmationRenderer()}
      />

      <RACModalCard
        isOpen={openVoidConfirmation}
        maxWidth="sm"
        borderRadius={ModalRadius}
        //eslint-disable-next-line react/no-children-prop
        children={voidConfirmationRenderer()}
      />

      {openSLA === true ? (
        <SecondFactor
          setTwoFactorCancelClick={SLACanceled}
          setTwoFactorCompleted={SLACompleted}
          CurrentRole={CurrentRole}
          moduleName="Reverse Payment"
        />
      ) : null}
    </Grid>
  );
}

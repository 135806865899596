/**
 * PS_NSF_02
 * Import the required packages, interface and context
 */
import React, { useContext, useState } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  RACTextbox,
  RACButton,
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
} from '@rentacenter/racstrap';
import { agreementContext } from '../context/PaymentInformationContext';
import CONSTANTS from '../constants/constant';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import {
  CccbHeader,
  NsfBodyArray,
  NSFData,
} from '../interface/nsfAndCCCBInterface';
import { AgreementContextValue } from '../interface/contextInterface';

/**
 * PS_NSF_03
 * Invoke a functional based component as NSFComponent
 * @returns
 */
export function NSFComponent() {
  /**
   * PS_NSF_06
   * Declaring variables and Destructing from context
   */
  const classes = takePaymentStyles();
  const {
    agreementDetails,
    setAgreementDetails,
    customerInfo,
    setCustomerInfo,
    renderContext,
    setRenderContext,
    modalCardEnable,
    setModalCardEnable,
  } = useContext<AgreementContextValue>(agreementContext);
  const nsfAgreement = agreementDetails.filter((el: AgreementDetails) =>
    el.agreementType === CONSTANTS.NSF_CHECK && el.selected ? el : null
  );
  const agreementDetailsClone = JSON.parse(JSON.stringify(agreementDetails));

  const totalAdjustedAmount =
    Number(nsfAgreement[0].balanceAmount) +
    Number(nsfAgreement[0].nsfFee) +
    Number(nsfAgreement[0].nsfFeeTax);

  /**
   * PS_NSF_04 - PS_NSF_05
   * Declaring an object with nsf datas from context
   *  and initializing it to a state variable
   */
  const data = {
    nsfFeeTax: Number(nsfAgreement[0].nsfFeeTax),
    nsfFeeTaxRate: Number(nsfAgreement[0].nsfFeeTaxRate),
    nsfFee: nsfAgreement[0].nsfFee || CONSTANTS.ZERO_STRING,
    originalNSFFee: Number(nsfAgreement[0].originalNsfFee),
    balanceFee: Number(nsfAgreement[0].balanceFee),
    adjustedTotal: totalAdjustedAmount,
    validationMsg: CONSTANTS.EMPTY_STRING,
  };
  const [nsfData, setnsfData] = useState<NSFData>(data);

  const checkArray: { display: string }[] = [
    { display: nsfAgreement[0].checkNumber ?? CONSTANTS.EMPTY_STRING },
    { display: nsfAgreement[0].checkWrittenBy ?? CONSTANTS.EMPTY_STRING },
    { display: nsfAgreement[0].returnDate ?? CONSTANTS.EMPTY_STRING },
  ];

  const gridArray: NsfBodyArray[] = [
    {
      bodyHeader: CONSTANTS.CHECK_AMOUNT,
      originalAmount: Number(nsfAgreement[0].amount),
      balanceDue: Number(nsfAgreement[0].balanceAmount),
      adjusted: Number(nsfAgreement[0].balanceAmount),
    },
    {
      bodyHeader: CONSTANTS.NSF_FEE,
      originalAmount: Number(nsfAgreement[0].originalNsfFee),
      balanceDue: Number(nsfAgreement[0].balanceFee),
      adjusted: Number(nsfData.nsfFee) || Number(nsfAgreement[0].balanceFee),
    },
    {
      bodyHeader: CONSTANTS.NSF_FEE_TAX,
      originalAmount:
        Number(nsfAgreement[0].originalNsfFee) *
        Number(nsfAgreement[0].nsfFeeTaxRate),
      balanceDue: Number(nsfAgreement[0].originalNsfFeeTax),
      adjusted: nsfData.nsfFeeTax || Number(nsfAgreement[0].nsfFeeTax),
    },
    {
      bodyHeader: CONSTANTS.NSF_TOTAL,
      originalAmount:
        Number(nsfAgreement[0].originalNsfFee) +
        Number(nsfAgreement[0].amount) +
        Number(nsfAgreement[0].originalNsfFeeTax),
      balanceDue:
        Number(nsfAgreement[0].balanceAmount) +
        Number(nsfAgreement[0].balanceFee) +
        Number(nsfAgreement[0].originalNsfFeeTax),
      adjusted: nsfData.adjustedTotal || totalAdjustedAmount,
    },
  ];

  const nsfHeader = (headerArray: CccbHeader[], style?: string) => (
    <>
      {headerArray.map((val, index) => {
        return (
          <RACTableCell
            className={
              style === CONSTANTS.SECOND_HEADER_NSF_STYLE_TEXT
                ? `${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.rowColor}`
                : style == CONSTANTS.NSF_FIRST_BODY
                ? `${classes.paymentStore} ${classes.fontFamilyOpenSansSemiBold}`
                : `${classes.racTable} ${classes.title1}`
            }
            style={{ backgroundColor: 'White' }}
            key={index}
          >
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );

  /**
   * PS_NSF_10 - PS_NSF_15
   * handleAmountChange function invokes when user changes value in input field
   * checks for the amount validation with original nsf fee and sets the data
   * @param e
   */

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAmount = e.target.value.replace(/,/g, CONSTANTS.EMPTY_STRING);
    const tax = Number(inputAmount) * Number(nsfData.nsfFeeTaxRate);
    let total: number =
      Number(nsfAgreement[0].balanceAmount) + Number(inputAmount) + Number(tax);
    total = Number(total.toFixed(2));
    if (Number(inputAmount) > Number(nsfData.balanceFee)) {
      setnsfData({
        ...nsfData,
        nsfFee: inputAmount,
        nsfFeeTax: tax,
        adjustedTotal: total,
        validationMsg: ` Amount entered should not be greater than $ ${nsfData.balanceFee}.`,
      });
    } else
      setnsfData({
        ...nsfData,
        nsfFee: inputAmount,
        nsfFeeTax: tax,
        adjustedTotal: total,
        validationMsg: CONSTANTS.EMPTY_STRING,
      });
  };

  /**
   * PS_NSF_31 - PS_NSF_35
   * This function binds the NSF table in UI
   * Holds an inout field and in onchange it triggers handleAmountChange()
   * @returns
   */
  const nsfBody = () => {
    return (
      <>
        {gridArray.map((item: NsfBodyArray, index: number) => (
          <>
            <RACTableRow
              className={` ${classes.rowColor} ${classes.font12}`}
              key={index}
            >
              <RACTableCell
                className={` ${classes.paymentStore} ${classes.fontFamilyOpenSansSemiBold}  `}
              >
                {item.bodyHeader}
              </RACTableCell>
              <RACTableCell
                className={` ${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.fontFamilyOpenSansSemiBold}`}
              >
                <span>$</span>
                {Number(item.originalAmount).toFixed(2)}
              </RACTableCell>
              <RACTableCell
                className={` ${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.fontFamilyOpenSansSemiBold}`}
              >
                <span>$</span>
                {Number(item.balanceDue).toFixed(2)}
              </RACTableCell>
              {item.bodyHeader == CONSTANTS.NSF_FEE ? (
                <RACTableCell style={{ width: '23%' }}>
                  <RACTextbox
                    inputlabel=""
                    className={`${classes.nsfCurrency} `}
                    type={'number'}
                    digitFormat={'currency'}
                    id="nsfFee-textbox"
                    name="nsfFee-textbox"
                    value={nsfData.nsfFee}
                    OnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleAmountChange(e)
                    }
                    data-testid="nsfFee-textbox"
                    maxlength={6}
                    dollarTextClassName={classes.currency}
                    isCurrency={true}
                    errorMessage={nsfData.validationMsg}
                    inputTextBoxClassname={'nsfFee-textbox'}
                  />
                </RACTableCell>
              ) : (
                <RACTableCell
                  className={`${classes.textend} ${classes.font12} ${classes.paymentStore} `}
                  style={{ fontFamily: 'OpenSans-semibold' }}
                >
                  <span>$</span>
                  {Number(item.adjusted).toFixed(2)}
                </RACTableCell>
              )}
            </RACTableRow>
          </>
        ))}
      </>
    );
  };
  /**
   * PS_NSF_16 - PS_NSF_23
   * This function triggers in continue click
   * Sets the updated data to the context
   */
  const handleContinue = () => {
    const response: AgreementDetails[] = [];
    agreementDetailsClone.forEach((eachAgreement: AgreementDetails) => {
      if (
        eachAgreement.agreementType == CONSTANTS.NSF_CHECK &&
        eachAgreement.selected
      )
        response.push({
          ...eachAgreement,
          totalDueAmount: nsfData.adjustedTotal,
          nsfFee: nsfData.nsfFee,
          nsfFeeTax: String(nsfData.nsfFeeTax),
          totalTax: String(nsfData.nsfFeeTax),
          isNSFEdit: true,
        });
      else response.push({ ...eachAgreement });
    });
    setAgreementDetails(response);
    const customerDetail = JSON.parse(JSON.stringify(customerInfo));
    customerDetail.carryRentEnable = false;
    setCustomerInfo(customerDetail);
    setModalCardEnable({ ...modalCardEnable, NSFPopup: false });
    setRenderContext(!renderContext);
  };
  /**
   * PS_NSF_38
   * This function triggers in set to default button click
   * Set the initila value back to the context
   */
  const setToDefault = () => {
    setnsfData({
      nsfFeeTax: Number(nsfAgreement[0].originalNsfFeeTax),
      nsfFeeTaxRate: Number(nsfAgreement[0].nsfFeeTaxRate),
      nsfFee: nsfAgreement[0].balanceFee || CONSTANTS.ZERO_STRING,
      originalNSFFee: Number(nsfAgreement[0].originalNsfFee),
      balanceFee: Number(nsfAgreement[0].balanceFee),
      adjustedTotal:
        Number(nsfAgreement[0].balanceAmount) +
        Number(nsfAgreement[0].balanceFee) +
        Number(nsfAgreement[0].originalNsfFeeTax),
      validationMsg: CONSTANTS.EMPTY_STRING,
    });
    const response: AgreementDetails[] = [];
    agreementDetailsClone.forEach((eachAgreement: AgreementDetails) => {
      if (
        eachAgreement.agreementType == CONSTANTS.NSF_CHECK &&
        eachAgreement.selected
      ) {
        response.push({
          ...eachAgreement,
          nsfFee: eachAgreement.balanceFee,
          nsfFeeTax: String(eachAgreement.originalNsfFeeTax),
          totalDueAmount:
            Number(eachAgreement.balanceAmount) +
            Number(eachAgreement.balanceFee) +
            Number(eachAgreement.originalNsfFeeTax),
          totalTax: String(eachAgreement.originalNsfFeeTax),
          isNSFEdit: false,
        });
      } else response.push({ ...eachAgreement });
    });

    setAgreementDetails(response);
    const customerDetail = JSON.parse(JSON.stringify(customerInfo));
    customerDetail.carryRentEnable = false;
    setCustomerInfo(customerDetail);
  };

  /**
   * PS_NSF_36 - PS_NSF_39
   * Within return,  it binds table header and body
   * with continue, set to default and cancel buttons
   */
  return (
    <>
      <Grid>
        <RACTable
          renderTableHead={() => nsfHeader(CONSTANTS.NSF_FIRST_HEADER)}
          renderTableContent={() =>
            nsfHeader(checkArray, CONSTANTS.NSF_FIRST_BODY)
          }
        />
      </Grid>
      <Grid>
        <RACTable
          renderTableHead={() =>
            nsfHeader(
              CONSTANTS.NSF_SECOND_HEADER,
              CONSTANTS.SECOND_HEADER_NSF_STYLE_TEXT
            )
          }
          renderTableContent={() => nsfBody()}
        />
      </Grid>
      <Grid item md={12} style={{ marginTop: '30px' }}>
        <RACButton
          variant="contained"
          color="primary"
          className={`${classes.spacerMR2} ${classes.setDefault}`}
          style={{
            float: 'right',
            // padding: '8px',
            paddingLeft: '2%',
            paddingRight: '2%',
            marginTop: '0.5%',
          }}
          disabled={
            Number(nsfData.nsfFee) == CONSTANTS.ZERO_NUMBER ||
            Number(nsfData.nsfFee) > Number(nsfData.balanceFee)
              ? true
              : false
          }
          onClick={() => handleContinue()}
          data-testid="continue-btn-nsf"
        >
          Continue
        </RACButton>

        <RACButton
          className={`${classes.spacerMR2} ${classes.setDefault}`}
          color="primary"
          variant="contained"
          style={{
            float: 'right',
            marginLeft: '1%',
            marginRight: '1%',
            marginTop: '0.5%',
          }}
          onClick={() => setToDefault()}
          data-testid="setToDefault-btn-nsf"
        >
          Set To Default
        </RACButton>
        <RACButton
          data-testid="cancel-Btn"
          type="button"
          color="primary"
          variant="outlined"
          style={{ float: 'right' }}
          className={`${classes.spacerMR2} ${classes.setDefault1}`}
          onClick={() => {
            setnsfData(data);
            setModalCardEnable({ ...modalCardEnable, NSFPopup: false });
          }}
        >
          Cancel
        </RACButton>
      </Grid>
    </>
  );
}

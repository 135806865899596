/* eslint-disable prettier/prettier */
/* eslint-disable no-dupe-else-if */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-duplicate-props */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-constant-condition */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable sonarjs/no-identical-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-irregular-whitespace */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

import React, { useEffect, useState, useContext } from "react";
import { ClubReq, ldwReq, policyReq } from "./paymentgrid";
import Modal from "react-modal";
import { ReactComponent as DeleteIcon } from "../../assets/images/layer28.svg";
import { ReactComponent as Visacard } from "../../../src/assets/images/Visa-Card.svg";
import { ReactComponent as Mastercard } from "../../../src/assets/images/Mastercard.svg";
import CurrencyInput from "react-currency-input-field";
import { ReactComponent as SuccessIcon } from "../../../src/assets/images/success-icon.svg";
import { ReactComponent as Txt2payimg } from "../../../src/assets/images/Txt-Pay-Icon.svg";
import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
import { ReactComponent as AmericanExpress } from "../../assets/images/American Express - img.svg";
import { ReactComponent as DinersClub } from "../../assets/images/Diners Club-img.svg";
import { ReactComponent as JCB } from "../../assets/images/JCB - img.svg";
import { ReactComponent as Paypal } from "../../assets/images/paypal-img.svg";
import { ReactComponent as Discover } from "../../assets/images/Discover - img.svg";
import { ReactComponent as Info } from "../../assets/images/info.svg";
import {
  UserStateContext,
  UserThunksContext,
} from "../../context/user/user-contexts";
import moment from "moment";
import { OriginContext } from "../../context/OriginContext";
import { AMOrigin } from "../../constants/constants";
import axios from "axios";
import AdjustDueDatecalci from "./AdjustDueDateCalci";
// import { CustomerdataContext } from "../../context/customer/customerdata";
//syed change 8 (icons)
import { ReactComponent as Infosus } from "../../assets/images/infosus.svg";
import { ReactComponent as Deleteicon } from "../../assets/images/delete.svg";

import { ReactComponent as Step1img } from "../../assets/images/step1img.svg";
import { ReactComponent as Step2img } from "../../assets/images/step2img.svg";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
//import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input/input';
import { Payment } from "./AcceptPayment";
import { AvailableCredits } from "./AvailableCredits";
import { CardTokendto } from "../../domain/deletecardpayload";
import { GetAllPaymentInfoRequest } from "../../../src/domain/getpaymentinforequest";
import OutsideClickHandler from "react-outside-click-handler";
import {
  GetPaymentInformation,
  AllPaymentInfo,
  AmountDueResponse,
} from "../../../src/domain/getpaymentinforesponse";
import { PaymentCustomerIdFiltersDTO } from "../../domain/getCustomerCardDTO";
import { getRegistrationId, loadVantivIFrame } from "./vantiv-iframe.service";
import { CreditCard } from "./CreditCard";
import { ProcessPaymentLogRequest } from "./ProcessPaymentLogRequest";
import Autopay from "./AutopayPopup";
import { AutopaySendMessageRequest } from "../../domain/Autopaypayload";
import {
  AgreementVals,
  ReceiptCategoriesRequestDTO,
  AcceptClubPaymentParam,
  AcceptPaymentParam,
  CheqAndMO,
  AcceptPaymentInfo,
  CardChk,
} from "../../../src/components/microfrontends/paymentgrid";
import { PaymentInfo } from "../../domain/swipecardpayload";
import {
  SaveCardRequestDTO,
  CustomerInfo,
  BillAddressInfo,
  SaveBillinginfo,
} from "../../domain/Savecardpayload";
import { Text2payDTO } from "../../domain/sendtxt2pay";
import {
  Deletecustomercard,
  Savecustomercard,
  Swipecustomercard,
  getpaymentinfo,
  GetCardInfo,
  LDwServiceCall,
  PolicyServiceCall,
  Sendtxt2pay,
  ActiveClub,
  // UnEnrollAutoPay,
  EnrollAutopay,
  UpdateAlert,
  sendMessage,
  GetCustomerAlert,
  getprofileaccess,
  GetAlert,
  GetLegalSatatus,
  getmenuandstore,
  getsecondfactor,
  getCurrentInfo,
  getPinDetails,
  CustomerCoverage,
  StoreswipeDevice,
  GetState,
  GetEmployeeID,
  GetRole,
  getpaymentHistory,
  CoworkerRequired,
  GetCustomerByIdInfo,
  GetTaxRateConv,
  getTax,
  GetCustomerCoverage,
} from "../../api/user";
import { ConsoleLogger } from "@aws-amplify/core";
//import { isInteger } from "lodash";
import { ReactComponent as ExpandAlert } from "../../assets/images/expand-alert-icon.svg";
import { ReactComponent as ExpandCancel } from "../../assets/images/alert-cancel-icon.svg";
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACCheckBox,
  RadioColor,
  RACRadio,
  RACModalCard,
  RACToggle,
  RACChip,
  RACTable,
  RACTableRow,
  RACTableCell,
  RACBadge,
  Grid,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
  Box,
  CircularProgress,
  RACCOLOR,
  makeStyles,
  RACAlert,
  RACInfoIcon,
} from "@rentacenter/racstrap";
import { DeliveryContext } from "../../context/DeliveryContext";
import { ReactComponent as AccordianOpenIcon } from "../../assets/images/accordion-open-icon.svg";
import { ReactComponent as AccordianCloseIcon } from "../../assets/images/accordion-close-icon.svg";
import PopUpCarryWaive from "../../racstrapComponents/microfrontends/Carrywaive";
import { ReactComponent as InfoiconGrey } from "../../assets/images/Info-icon-grey.svg";
import { ReactComponent as RacLogo } from "../../assets/images/RAC-Logo.svg";
import NoRecordsFound from "../../assets/images/alertIconpng.png";
import PinField from "react-pin-input";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { parse } from "path";
import { useHistory } from "react-router";
import { TableCell, TableRow } from "@mui/material";
import { group } from "console";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { number } from "yup";
import SecondFactor from "../../racstrapComponents/microfrontends/TwoFactorPopup";
import { text } from "@fortawesome/fontawesome-svg-core";
import { MakePaymentDTO } from "../../domain/makepaymentpayload";
import {
  ClubActivityInfo,
  ClubHistoryGridInfo,
  ClubInfo,
  CustomerInfoHis,
  Rootobject,
} from "../../domain/getPaymentHistory";
/**
 * Changes:Carry Rent
 * Developer:Muzzammil Shaik
 * Desc:Import the carry rent omponent
 */
import { CarryRent } from "./CarryRent";
import ClubPayment from "./clubPaymentHistory";
import DeliverySlots from "./paymentHistory";
import AdjustDueDatePopup from "../../racstrapComponents/microfrontends/AdjustDueDate";
import PromoFreeDays from "../../racstrapComponents/microfrontends/PromoFreeDays";
import EditSchedule from "../../racstrapComponents/microfrontends/EditSchedule";
import NonInventorySales from "../../racstrapComponents/microfrontends/CashSale";
import { Redirect, useLocation } from "react-router-dom";
import { AppRoute } from "../../config/route-config";
import { PayHistory } from "../../stylesJS/PayHistory";
import AcceptEpo from "./AcceptEpo";
import EpoSchedule from "./EpoSchedule";
import { CustomerInformation } from "./CustomerInformation";
import { ChartContext } from "../../context/paymentTriangle/chartcontext";
import { HistorydataContext } from "../../context/history/historyContext";
import { orderBy } from "lodash";
import { StateDataContext } from "../../context/stateapi/stateapicontext";
import { CustomerdataContext } from "../../context/customer/customerdata";
import FreeTime from "./FreeTime"; // Added By Me
import Wizard from "./Wizard";
import ProcessPayment from "./ProcessPayment";
let coWorkerRole = "";
let addrowarray: any;

let unenrollautopayagreementids: any = [];
let totalduereceiptidsupport: any = [];
let tendertypeglobalarray: tenderType[] = [];
let FloatInputTxt: any;
let AgreementSelect: AgreementVals[] = [];
let agreeementarray: any = [];
let swipeCardDetails: any[] = [];
let acceptPaymentParam: AcceptPaymentParam[] = [];
let actLDWArr: ldwReq[] = [];
let actPolicyArr: ldwReq[] = [];
let suspenseError: any[] = [];
let diffStoreError: any[] = [];
let epoError: any[] = [];
// let diffStoreError: any[] = [];
let ReceiptCategoriesRequestParam: ReceiptCategoriesRequestDTO[] = [];
let swipeCardAmt: any[] = [];
let AcceptclubPaymentParam: AcceptClubPaymentParam | undefined = undefined;
let showAutoPayPopup = false;
let localGetPayData;
let customerverificationStatus;
/*The paygrid values is the array of objects which consist all the data that needs to binded*/
interface tenderType {
  tendertype: string;
  Amount: string;
  paymentMethodID?: string;
}

interface custCoverage {
  ldwAmount: any;
  ldwTax?: number;
  policyAmount: any;
  isLDW: boolean;
  isPolicy: boolean;
  policyTax?: number;
  ldwInfo?: any;
}
interface paygrid {
  carriedLate?: any;
  carriedLateTax?: any;
  constLateFee: any;
  waivedLateFee: any;
  isReturnAgrFlag?: number;
  lateFeeTaxRate: any;
  accepoEPoAgrnoteInfo: any;
  agreementNoteDetails: any;
  transferOldSuspense: any;
  suspenseAdd: any;
  editedEpoAmount: any;
  acceptEpoFlag: any;
  actualPastDueRent: any;
  actualEpoAmount: any;
  suspenseSub: any;
  AgreementNumber: string;
  Agreementid: number;
  Agreementtype: string;
  Agreementdesc: string;
  Schedule: string;
  CurrentDueDate: string;
  Nextduedate: string;
  CurrentDaysLate: number;
  Amount: number;
  SAC_Date: string;
  SuspenseAmount: number;
  EPOAmount: number;
  Latelast10: number;
  PromoFreeDays: number;
  Autopay: string;
  store_number: number;
  number_of_paymentsmade: number;
  sign_status: string;
  confirmeddeliversystatus: boolean;
  Taxamount: number;
  Autopay_enrollmentdate: string;
  clubactive: boolean;
  Phonenumber: string;
  EmailAddress: string;
  partyId: string;
  clubName: string;
  clubMembershipNumber: string;
  clubCoverageTermination: string;
  clubFlag: boolean;
  clublastLate10: number;
  cardLastFour: number;
  agrLdwAmount: number;
  COA: any;
  ipSuspense: any;
  carriedLateFee: any;
  wlDeposit: any;
  htSuspense: any;
  suspense: any;
  racTirePay: any;
  storeCityName: string;
  stateProvinceName: string;
  ldwTaxAmount?: number;
  rpayTaxAmount?: number;
  lFeeTaxAmount?: number;
  lateFee?: number;
  policyTaxAmount?: number;
  ldwPercentage?: number;
  actualAmountDue?: number;
  convTax?: number;
  policyAmount?: number;
  deliveryAmount?: number;
  deliveryAmountTax?: number;
  mattressFee?: number;
  mattressFeeTax?: number;
  processingFee?: number;
  processingFeeTax?: number;
  extensionAmount?: number;
  noOfDaysFree?: number;
  taxGeoCode?: string;
  zipPlus4UsedForTax?: string;
  EPOTax: number;
  dueDateModified?: boolean;
  agreementRateDetails?: any;
  taxRate?: string;
  coverageEndDate?: string | null;
  epoTax?: string | null;
  policyStatus?: string | null;
  editSchedule: null | string;
  agreementRate: any;
  miscellaneousItemsNum: number | null;
  carryRentAmountUsed: any;
  carryRentTaxUsed: any;
  carryRentAmountAdd: any;
  isLdwAllowed: string | any;
  carryLdwAmount: number;
  carryLdwAmountTax: number;
  nineOneOneFee?: number;
  cellPhoneActivation?: number;
  cellPhoneActivationTax?: number;
  miscellaneousItemInfo: any;
  pastDueRent?: number;
  PromoFreeDaysApplied?: any;
  FreeTime?: any; // Added By Me
  isCOATransfered: any;
  isSwitchoutDeliveryPending: 0; // Added By Me
  originAgreementId?: any;
  clubDocumentSignStatus?: any;
  customerClubId: any;
  isClubDeactivateTriggered: any;
  promotionRate?: any;
  promotionType?: any;
  promotionDueDate?: any;
  maxWeeksAllowed?: any;
  freeTimeWeeksAllowed?: any;
  numberOfFreeDays?: any;
  referralPromoFreeDays?: any;
  promotionDetails?: any;
  agreementStatus?: any;
}
interface AutoPayType {
  schedule: string;
  autoPay: boolean;
  itemIndex: number;
  agreementID: number;
}

interface AgrAmtTax {
  agreementId: number;
  totalAmount: number;
  taxAmount: number;
}
let agrAmtTaxArray: AgrAmtTax[] = [];
let autoPayEnrollArray: AutoPayType[] = [];
let CardGrid: CardChk;
let chqAndMo: CheqAndMO[] = [];
//let paymentagreements: paygrid[] = [];
let Clubagreementinsertion: paygrid[] = [];
let customerName = "";
let selectedGrid;
let clubInitialCheckFlag = true;
let previousAmt = 0.0;
let previousState = false;
let chargeCardValue = 0.0;

const useClasses = makeStyles((theme: any) => ({
  navLink: {
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",
    position: "relative",
    fontFamily: "OpenSans-semibold",
    color: "#000",
  },
  navLinkactive: {
    color: "#2179FE",
    borderBottom: "5px solid #2468FF !important",
    fontFamily: "OpenSans-semibold",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "5px",
      width: "10px",
      background: "#2468ff",
      left: "-5px",
      bottom: "-5px",
      borderTopLeftRadius: "5px",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      height: "5px",
      width: "10px",
      background: "#2468ff",
      right: "-5px",
      bottom: "-5px",
      borderTopRightRadius: "5px",
    },
  },
  dropdowntoggle: {
    display: "inline-block",
    marginLeft: "0.255em",
    verticalAlign: "0.255em",
    content: "",
    borderTop: "0.3em solid",
    borderRight: "0.3em solid transparent",
    borderBottom: "0",
    borderLeft: "0.3em solid transparent",
  },
  dropdownitemcustom: {
    color: "#2468FF",
    fontFamily: "OpenSans-bold",
    backgroundColor: "white",
    textDecoration: "none",
    cursor: "pointer",
  },
  popUp: {
    position: "absolute",
    bottom: "100%",
    backgroundColor: "white",
    width: "200px",
    padding: "15px",
    "& li": {
      listStyle: "none",
    },
  },
  popUpHide: {
    display: "none",
  },
  customMenuContainer: {
    border: "1px solid #2468FF",
  },
  paymentBtnHover: {
    "&:hover": {
      color: "white",
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
    },
  },
}));

export const COPaymentInformations = () => {
  const {
    clubsStatus,
    customerOrder,
    // searchResult
  } = useContext(DeliveryContext);
  const [needCarryWaveAuth, setNeedCarryWaveAuth] = useState(false);
  const { redirectOrigin } = useContext(OriginContext);
  let adjustCommitmentData: any;
  const [clubBtnStatus, setclubBtnStatus] = clubsStatus;
  const [custOrder, setCustOrder] = customerOrder;
  const [phoneNumberRequired, setphoneNumberRequired] = useState<any>(false);
  const { chartdata, setchartdata } = useContext(ChartContext);
  const { historydata, sethistorydata } = useContext(HistorydataContext);
  const { statedata, setstatedata } = useContext(StateDataContext);
  const {
    paymentFunctionFlag,
    setpaymentFunctionFlag,
    setCommitmentPayTringle,
  } = useContext(CustomerdataContext);
  const { customerdata, setcustomerdata } = useContext(CustomerdataContext);
  const userdata: any = useContext(UserStateContext);
  const userthunkdata: any = useContext(UserThunksContext);
  let [currentServiceFlags, setcurrentServiceFlags] = useState<any>({
    isCustomerRedirection: 1,
  });
  const [coWorkerRoleCarryWaive, setCoWorkerRoleCarryWaive] = useState("");
  // const [isCommitmentInSIMS, setisCommitmentInSIMS] = useState(false);
  const [isPendingAgrTransfer, setIsPendingAgrTransfer] = useState(false);
  console.log("user context userdata", userdata);
  console.log("user thunk context userdata", userthunkdata);
  const [clubActivate, setClubActivate] = useState<any>();
  const [opentransferSuspense, setOpenTransferSuspense] = useState(false);
  const [newCOATotal, setNewCOATotal] = useState("0.00");
  const [disableEPOByEPP, setDisableEPOByEPP] = useState(false);
  const [isFromAccountMgmt, setIsFromAccountMgmt] = useState(false);
  const [ConvFeeWithOutTax, setConvFeeWithOutTax] = useState<any>(0);
  const [suspenseButton, setsuspenseButton] = useState<any>(false);
  const [checkdisable, setcheckdisable] = useState<any>(false);
  const [instoreDisable, setinstoreDisable] = useState<any>(false);
  const [configFeeOnlyTax, setconfigFeeOnlyTax] = useState<any>(0);
  const [lateFeeTaxRateCus, setLateFeeTaxRateCus] = useState<any>([]);
  const [susbtndisabled, setsusbtndisabled] = useState<any>(false);
  const [saveCardPopUp, setsaveCardPopUp] = useState<any>(false);
  const [saveCardSuccess, setsaveCardSuccess] = useState<any>(false);
  const [deleteCardPopUp, setdeleteCardPopUp] = useState<any>(false);
  const [deleteCardSuccess, setdeleteCardSuccess] = useState<any>(false);
  const [failedToDeleteCardError, setFailedToDeleteCardError] = useState<any>("");
  const [deleteCardFail, setdeleteCardFail] = useState<any>(false);
  const [saveCardFail, setsaveCardFail] = useState<any>(false);
  const [popUpCarrywaive, setPopUpCarryWaive] = useState<boolean>();
  const [storeSwipeDeviceListArray,setStoreSwipeDeviceListArray]=useState<any>();
  const [transferDifferenceZero, settransferDifferenceZero] =
    useState<any>(false);
  const [postalCodeZip5ErrorFlag,setPostalCodeZip5ErrorFlag] = useState<any>(0) // this flag is used to render the popup and Verbiage of incorrect Zip code and phone number
  const [storeMerchantId, setstoreMerchantId] = useState<any>("");
  const [storezip, setstorezip] = useState<any>("");
  const [customerzipcodenull, setcustomerzipcodenull] = useState<any>(false);
  const [suspenseAllDelete, setsuspenseAllDelete] = useState<any>(false);
  const [FinalSuspenseDelete, setFinalSuspenseDelete] = useState<any>(false);
  const [suspenseDeleteExpection, setSuspenseDeleteExpection] =
    useState<any>(false);
  const [returnAgrSuspense, setReturnAgrSuspense] = useState<any>(false);
  console.log("went into the payment comp");
  const navlinkClass = useClasses();
  const classes = takePaymentStyles();
  const history = useHistory();
  // const [ConvFeeWithOutTax,setConvFeeWithOutTax] = useState<any>(0)
  // const [configFeeOnlyTax,setconfigFeeOnlyTax]=useState<any>(0)
  const temp = {
    checktype: "0",
    Type: "",
    checkno: "",
    Written_To: "",
    Written_by: "",
    Amount: "0.00",
  };
  const cardrequirements = {
    CardCss: `${navlinkClass.navLink} ${classes.p3}`,
    Checked: false,
    checkbox: false,
    swipcard: false,
    newCard: false,
    editcrd: false,
    delt: false,
    Proceedbtn: true,
    tran: false,
    CardNumberValidation: false,
    chkcvv: "",
    chkamt: "0.00",
    txt2pay: false,
    txt2paybtn: true,
    PaymentOrgin: "Store",
    drpdown: false,
    APDeletePopup: false,
    ConvAmt: ConvFeeWithOutTax,
    Otheraction: true,
    Newtotal: ConvFeeWithOutTax,
    Ldwhide: true,
    Policyhide: true,
  };

  const newcardinformation = {
    card: "",
    card_holder_name: "",
    card1: "",
    card2: "",
    card_last_four: "",
    cvv: "",
    name: "",
    address_line_1: "",
    address_line2: "",
    city: "",
    state: "",
    zip_code: "",
  };

  // swipe popup state variable
  let swipeObj = {
    load: false,
    tran: false,
    infopopup: false,
  };
  const [swipevar, setswipevar] = useState(swipeObj);

  const Validation = {
    SwipeAmtValidationtxtbox: false,
    State_validation: false,
    Zip_code_validation: false,
    Address_line1_validation: false,
    Name_validation: false,
    Cvv_validation: false,
    expiration_validation: false,
    Card_holder_validation: false,
    CardValidation: false,
    Phone_no_validation: false,
    chargeCardValidation: false,
  };

  const CardObj = {
    Cardname: "",
    address_1: "",
    address_2: "",
    zip: "",
    state: "0",
    city: "",
  };

  const APUP = {
    IsAP: false,
    IsUP: false,
  };

  const tempcustCoverage: custCoverage = {
    ldwAmount: "",
    ldwTax: 0,
    policyAmount: "",
    isLDW: false,
    isPolicy: false,
  };

  //Accept EPO Changes
  const SLAObj = {
    TwoFactorCancelClick: false,
    TwoFactorCompleted: false,
  };

  // State declared for payment History by syed azimudeen
  const [PaymentHistory, setPaymentHistory] = useState(false);
  const [ClubPaymentHistory, setClubPaymentHistory] = useState(false);

  let [uniqueAgreementID, setuniqueAgreementID] = useState<any>([]); //to get the unique agreement data from the response to set it in the drop down
  let [paymentHisGrid, setpaymentHisGrid] = useState<any>([]); //to get the payment history data from the response
  const [clubActivityInfo, setclubActivityInfo] = useState<any>([]); //to get the club activity data from the response
  const [ClubGridInfoResp, setClubGridInfoResp] = useState<any>([]); //to get the club payment grid data from the response
  let [commoninformation, setcommoninformation] = useState<any>(); //to get the common customer information data from the response
  let [clubinformation, setclubinformation] = useState<any>(); //to get the club information data from the response

  let [payhisflag, setpayhisflag] = useState<boolean>(true); //to get the club information data from the response
  let [payclubflag, setpayclubflag] = useState<boolean>(true); //to get the club information data from the response
  const [customerGCID, setCustomerGCID] = useState<any>("");
  const [mobtn, setmobtn] = useState(false);
  const [cashbtn, setcashbtn] = useState(false);
  const [defaultVal, setdefaultVal]: any = useState();
  const [DD, setDD]: any = useState();
  const [load, setload] = useState(false);
  const [Autopaybutton, setAutopaybutton] = useState(true);
  const [customerCoverageObject, setcustomerCoverageObject] =
    useState(tempcustCoverage);
  const [updatedarray, setupdatedarray]: any = useState([]);
  const [agreementarraystate, setagreementarraystate]: any = useState([]);
  const [IsClubSelectedForAutopay, setIsClubSelectedForAutopay] =
    useState(false);

  const [propsautopayopen, setpropsautopayopen] = useState(-1);
  const [APpopupcompleted, setAPpopupcompleted] = useState(-1);
  const [ResponseObject, setResponseObject]: any = useState();
  const [actualEpoAmount, setactualEpoAmount] = useState<any>(0);
  const [editedEPOAmount, seteditedEPOAmount] = useState<any>(0);
  // //const{customerid,agreementFlag}=useParams<Params>()

  const [CreditRadio, setCreditRadio] = useState(false);
  const [changeRadio, setchangeRadio] = useState(true);
  const [APUPStates, setAPUPStates] = useState(APUP);
  const [chkMoney, setchkMoney]: any = useState();
  // Validation state variables starts here
  const [EditCard, setEditCard]: any = useState(CardObj);
  const [Name_validation, setName_validation] = useState(false);
  const [Address_line1_validation, setAddress_line1_validation] =
    useState(false);
  const [State_validation, setState_validation] = useState(false);
  const [Zip_code_validation, setZip_code_validation] = useState(false);
  const [amount_feild, setamount_feild]: any = useState();

  // Validation state variables ends here
  const [paymentSchedule, setpaymentSchedule]: any = useState(2);
  //State variable of payment origin option ends here
  const [chkboxselect, setchkboxselect] = useState(false);
  //footer components state variables
  const [PopupClass, setPopupClass] = useState<string>(
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  const [ActiveLDW, setActiveLDW] = useState(false);
  const [TerminateLDW, setTerminateLDW] = useState(false);
  const [Activepolicy, setActivepolicy] = useState(false);
  const [DeActivepolicy, setDeActivepolicy] = useState(false);
  const [paymentMade, setpaymentMade] = useState(false);
  const [paymentMadeOptimized, setpaymentMadeOptimized] = useState(false); //used as dependency for the useEffect which helps to empty the Global variable.
  //footer components state variables ends here

  //configFlag
  let [configFlags, setconfigFlags] = useState<any>({
    commonModuleFlag: 0,
    isAdjustDueDateFeatureFlag: 0,
    isEditScheduleFeatureFlag: 0,
    isCarryRentFeatureFlag: 0,
    isAcceptEpoFeatureFlag: 0,
    isPaymentHistoryFeatureFlag: 0,
    isCashSaleFeatureFlag: 0,
    isPaymentTraingleFeatureFlag: 0,
    isAddApplyTransferSuspenseFeatureFlag: 0,
    isPaymentFunctionsFeatureFlag: 0,
    isActivateClub: 0,
    isRegularFreeTimeAllowed: 0, // Added By Me
    isServiceFreeTimeAllowed: 0, // Added By Me
    isRegExtFreeTimeAllowed: 0, // Added By Me
    isPromoFreeTimeallowed: 0, // Added By Me
    raftApiFlag: 0,
    carryWaveLateFee: 0,
    optimizedPayment: 0,
    isCloudSwipeCustomerCard: 0,
    isCloudStoreSwipeDevicelist: 0,
    isRacDbWebLead: 0,
  });

  const [Popupfields, setPopupfields] = useState(newcardinformation);
  const [Validator, setValidator] = useState(Validation);
  const [gettotamt, setgettotamt]: any = useState("0.00");
  const [swipeamt, setswipeamt] = useState("0.00");
  const [Cardrequire, setCardrequire] = useState(cardrequirements);
  const [calcModuleResponse, setCalcModuleResponse] = useState<any>("");
  const [cashtabCss, setcashtabCss] = useState<any>(
    `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
  );
  const [cardtabCss, setcardtabCss] = useState<any>(
    `${navlinkClass.navLink} ${classes.p3}`
  );
  const [checkMoneyOrdertabCss, setcheckMoneyOrdertabCss] = useState<any>(
    `${navlinkClass.navLink} ${classes.p3}`
  );
  const [cashselected, setcashselected] = useState(0); //This state is used to enable and disable cash div so that check div will be visible.
  const [gridhide, setgridhide] = useState(""); //This state is used for the accordion logic.
  const [addcheck, setaddcheck] = useState<boolean>(false); //This state is used for the addcheck popup enable and disable.
  const [suspensePopupClass, setsuspensePopupClass] = useState<string>(
    // `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  const [addsuspense, setaddsuspense] = useState<boolean>(false); //This state is used for the add suspense  popup enable and disable.
  const [usesuspense, setusesuspense] = useState<boolean>(false); //This state is used for the use suspense  popup enable and disable.
  const [transuspense, settransuspense] = useState<boolean>(false); //This state is used for the use suspense  popup enable and disable.
  const [refundsuspense, setrefundsuspense] = useState<boolean>(false); //This state is used for the use suspense  popup enable and disable.
  let [addsuslist, setaddsuslist] = useState<boolean>(true); //This state is used for the add suspense  popup enable and disable.
  const [refundsuslist, setrefundsuslist] = useState<boolean>(true); //This state is used for the use suspense  popup enable and disable.
  const [transfersuslist, settransfersuslist] = useState<boolean>(true); //This state is used for the use suspense  popup enable and disable.
  const [AddsusText, setAddsusText] = useState(false);
  const [UsesusText, setUsesusText] = useState(false);
  const [deactivateClubAmount, setdeactivateClubAmount] = useState<any>("0.00");
  const [susamount, setsusamount] = useState();
  const [addsus, setaddsus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  const [usesus, setusesus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  const [transsus, settranssus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  const [refundsus, setrefundsus]: any = useState("0.00"); //This is the state is used to cash text box in the suspens add amount.
  /*  state variable for suspense */
  let [totolsuspenseamount, settotolsuspenseamount] = useState<any>("0.00");
  let [totalnewsuspenseamount, settotalnewsuspenseamount] =
    useState<any>("0.00"); //to set the new total amount
  let [differenceamount, setdifferenceamount] = useState<any>("0.00"); //to set the new total amount
  let [totalsuspensemaster, settotalsuspensemaster]: any = useState([]); //This state is used to setstate the reciept item that needs to be displayed in the suspense due div.
  let [mastersuspensearray, setmastersuspensearray] = useState<any>([]);
  let [masteraddsuspensearray, setmasteraddsuspensearray] = useState<any>([]);
  let [masterusesuspensearray, setmasterusesuspensearray] = useState<any>([]);
  let [mastereditsuspensearray, setmastereditsuspensearray] = useState<any>([]);
  let [mastertranssuspensearray, setmastertranssuspensearray] = useState<any>(
    []
  );
  let [masterrefundsuspensearray, setmasterrefundsuspensearray] = useState<any>(
    []
  );
  const [addmoneyorder, setaddmoneyorder] = useState<boolean>(false); //This state is used for the addmoneyorder popup enable and disable.
  const [adddata, setadddata] = useState<any>(temp); //This state is used for the getting data from the addcheck popup or add moneyorder popup and also used to bind it.
  const [ConrolUpdate, setConrolUpdate] = useState("0"); //This state is used to specify the difference between update and cancel button if cancel then setadddata state will be get nulled.
  const [button, setbutton] = useState(false); //This state is used to hide and show update and save button based on the triggeration.
  const [adddataanother, setaddataanother] = useState(chqAndMo); //This is the state in which the added object in the setadddata will be pushed inside and map function will be used in this to bind the data in dynamic grid.
  const [uploadindex, setuploadindex] = useState(0); //This is the state which is used to denote the index at which the data gets uploaded onclick of update button.
  const [addcash, setaddcash]: any = useState("0.00"); //This is the state is used to cash text box in the cash tendertype.
  const [totaldue, settotaldue]: any = useState(0.0); //This is the state which is used to calculate the total due.
  const [checking, setchecking]: any = useState("0"); //This is used to delete the data in the dynamic grid .It is used to bind the grid instantly after the data gets deleted in the adddataanother array.
  const [RemaniningAmtStatic, setRemaniningAmtStatic]: any = useState(0); //This state is used to set the remaining amount as static for backup and several operation purposes.
  const [remainingamountdue, setremainingamountdue]: any = useState(0); //This state is used to set the remaining amount due.
  const [personalcheckalert, setpersonalcheckalert] = useState<any>(false); //This state is used to enable the personalcheck validation alert box.
  const [travellercheckalert, settravellercheckalert] = useState<any>(false); //This state is used to enable the travellercheck alert box.
  const [totalamountcheck, settotalamountcheck] = useState<any>(0); //This is the state which is used to calculate the totalamount check.
  const [cashamountcheckbox, setcashamountcheckbox] = useState<any>(); //This state is used to take the all the digit the cash amount text box based on it the remaininng amount and change gets updated.
  const [unenrollautopayopen, setunenrollautopayopen] = useState(false);
  //validation state variable's are here
  const [checknovalid, setchecknovalid] = useState(false); //This state is used to validate the check no.
  const [Writtento, setwrittento] = useState(false); //This state is used to validate the written to field.
  const [writtenby, setwritenby] = useState(false); //This state is used to validate the written by field.
  const [Amount, setAmount] = useState(false); //This state is used to validate the Amount field.
  const [Type, setType] = useState(false); //This state is used to validate the check type.
  const [CityValidation, setCityValidation] = useState(false);
  const [change, setchange] = useState<any>(0); //This state is used to show the change.
  const [uploadtotal, setuploadtotal] = useState<any>(0); //This is used to get the backup of the amount at that particular grid in order if the update button is triggered.
  const [totalcontrol, settotalcontrol] = useState<any>(0); //This is used to differntiate the upload and save button inorder to calculate total amount check.
  const [totaltax, settotaltax]: any = useState(0.0); //This state variable is used to set the total tax in the total due div.
  const [Acceptpaymentbuttonenable, setAcceptpaymentbuttonenable] =
    useState(true); //This state is used for enable and disable of the acceptpayment button.
  const [tooltipdisplay, settooltipdisplay] = useState(""); //This state is used to display the tooltip for the dynamically binded grid.
  const [tooltipdesdisplay, settooltipdesdisplay] = useState(""); //This state is for the tooltipdisplay of the description grid.
  const [tooltipdispalygrid, settooltipdisplaygrid] = useState(""); //This state is for the display of the tooltip on dynamically generated grid.
  const [cancelindication, setcancelindication] = useState(0); //This state is used for the indication of cancel button triggeration.
  const [totalduereceiptid, settotalduereceiptid]: any = useState([]); //This state is used to setstate the reciept item that needs to be displayed in the total due div.
  const [totalduetendertype, settotalduetendertype]: any = useState([]);
  const [editcardindex, seteditcardindex]: any = useState(0);
  const [editcardnumberbind, seteditcardnumberbind]: any = useState(0);
  const [chkbox, setchkbox] = useState<any>([-1]);
  const [OverAll_Chk, setOverAll_Chk] = useState(false);
  let acceptPay: AcceptPaymentParam[] = [];
  const [AcceptPayments, SetAcceptPayments]: any = useState(acceptPay);
  let rcpCat: ReceiptCategoriesRequestDTO[] = [];
  const [ReceiptCategory, setReceiptCategory] = useState(rcpCat);
  const [AcceptClub, setAcceptClub] = useState<AcceptClubPaymentParam>();
  const [policyService_state, setpolicyService_state]: any = useState(1);
  const [getCardService_state, setgetCardService_state]: any = useState([]);
  const [profileAccResp, setprofileAccResp]: any = useState(undefined);
  const [ActionState, setActionState]: any = useState();
  const [showLDWPolicy, setshowLDWPolicy]: any = useState(false);
  const [clubState, setclubState]: any = useState();
  let [paygridvalues, setpaygridvalues] = useState<any>([]);
  const [radioBtn, setradioBtn] = useState("0");
  const [autopaypopup, setautopaypopup]: any = useState(false);
  const [cardGridData, setCardGridData] = useState(CardGrid);
  const [CardErrorLbl, setCardErrorLbl]: any = useState(false);
  const [suspErrLbl, setsuspErrLbl]: any = useState(false);
  const [epoErrLbl, setepoErrLbl]: any = useState(false);
  const [acceptPayment, setAcceptPayment] = useState<AcceptPaymentInfo>();
  const [getpayment, setgetpayment] = useState<any>();
  let avaArr: AllPaymentInfo[] = [];
  const [aval_det, setaval_det] = useState(avaArr);
  const [paymentOrigin, setpaymentOrigin] = useState("Store");
  const [empId, setempId] = useState("");
  const [PaymentOrigin1, setPaymentOrigin1]: any = useState("In Store");
  const [checkdrpdown, setcheckdrpdown]: any = useState("");
  const [loadAlerts, setLoadAlerts] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [CustomAlerttxt, setCustomAlerttxt] = useState("");
  const [Istextboxdisable, setIstextboxdisable] = useState(false);
  const [disableAlert, setdisableAlert] = useState<boolean>(false);
  const [Enabletxt, setEnabletxt] = useState(true);
  const [AlertWizard, setAlertWizard] = useState<any>([]);
  const [AlertIsOpen, setIsOpen] = useState(false);
  const [objAlertdata, setobjAlertdata] = useState<any>([]);
  const [AlertUpdateObj, setAlertUpdateObj] = useState<any | []>([]);
  const [AlertAuthenticationNeeded, setAlertAuthenticationNeeded] =
    useState<any>();
  const [authenType, setauthenType] = useState<"ALERT" | "">("");
  const [openPinModal, setOpenPinModal] = useState(false);
  const [otp, setotp] = useState("");
  const [somethingone, setsomethingone] = React.useState(
    "modal fade show expandPopup modal-top"
  );
  const [somethingtwo, setsomethingtwo] = React.useState(
    "modal fade modal-top"
  );
  const [loader, setloader] = useState<boolean>(false);
  const [InvalidPin, setInvalidPin] = React.useState<any>();
  const [Continue, setContinue] = React.useState(true);
  const [getStore, setGetStore] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState("");
  const [password, setPassword] = useState("");
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState("");
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [isSecondAuthenValid, setIsSecondAuthenValid] =
    useState<boolean>(false);
  const [LegalHold, setLegalHold] = useState<any>([]);
  const [isLegalHold, setIsLegalHold] = useState<boolean>(false);
  const [legalHoldApplied, setLegalHoldApplied] = useState<0 | 1 | 2>(0);
  const [OtherActionHidden, setOtherActionHidden] = useState(true);
  const [PaymentAmt, setPaymentAmt] = useState(0);
  const [sendtext, setsendtext] = useState(true);
  const [bindGridCheck, setbindGridCheck] = useState(false);
  const [initagreementnumber, setinitagreementnumber] = useState<any>(0);
  const [DeveiceResp, setDeveiceResp]: any = useState();
  const [otherActState, setotherActState]: any = useState();
  const [TerminalId, setTerminalId]: any = useState("0");
  const [swipepopupshow, setswipepopupshow] = useState(false);
  const [swipeFlag, setswipeFlag] = useState(false);
  const [nextBtn, setnextBtn] = useState(false);
  const [Sessionstorage, setSessionstorage]: any = useState();
  const [coaamt, setcoaamt]: any = useState(0);
  const [wldepositamount, setwldepositamount]: any = useState(0);
  const [ipsuspense, setipsuspense]: any = useState(0);
  const [suspensehide, setsuspensehide]: any = useState(false);
  const [suspenseAmount, setSuspenseAmount]: any = useState(0.0);
  const [checkmonvar, setcheckmonvar]: any = useState("0");
  const [CardRadiArr, setCardRadiArr]: any = useState([]);
  const [availableCreds, setAvailableCreds] = useState(0.0);
  const [verbalecheckbox, setvarbalecheckbox]: any = useState(false);
  const [vantivError, setVantivError] = useState("");
  const [vantivIframe, setVantivIframe] = useState();
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState<any>(null);
  const [cardAuthNo, setcardAuthNo] = useState(undefined);
  const [cardLastFour, setcardLastFour] = useState("");
  const [showAddCard, setshowAddCard] = useState(false);
  const [cvv, setCvv] = useState("");
  const [cvvTextValue, setcvvTextValue] = useState("");
  const [selectedCheckBoxCount, setSelectedCheckBoxCount] = useState(0);
  const [mainGridloader, setmainGridloader] = useState(true);
  const [convtax, setconvtax] = useState(0);
  const [finalagr, setfinalagr] = useState<any>(null);
  const [swipeDisable, setswipeDisable] = useState(false);
  const [diffStoreErrLbl, setdiffStoreErrLbl]: any = useState(false);
  const [cardtxtDisable, setcardtxtDisable] = useState(false);
  const [Latelast10value, setLatelast10value]: any = useState(0.0);
  const [overpayMoneyord, setoverpayMoneyord]: any = useState(false);
  const [addNewCard, setaddNewCard] = useState(false);
  const [cardDetails, setcardDetails]: any = useState([]);
  const [cardAmt, setCardAmt]: any = useState([{ amount: 0.0 }]);
  const [swipeDeviceValue, setswipeDeviceValue] = useState("0.00");
  const [nameVal, setnameVal] = useState(false);
  const [addressval1, setaddressval1] = useState(false);
  const [ZipVal, setZipVal] = useState(false);
  const [cityVal, setcityVal] = useState(false);
  const [stateVal, setstateVal] = useState(false);
  const [TranFail, setTranFail] = useState(false);
  const [txtChange, settxtChange] = useState(false);
  const [pageLoadedInitial, setpageLoadedInitial] = useState(true);
  const [clubCheckDisabled, setclubCheckDisabled] = useState(false);
  const [showChargeCard, setshowChargeCard] = useState(false);
  const [chargeCardAmt, setchargeCardAmt] = useState("0.00");
  const [convAmtWTax, setconvAmtWTax] = useState("0.00");
  const [chargeCardDisable, setchargeCardDisable] = useState(true);
  const [chargeCardContinue, setchargeCardContinue] = useState(true);
  const [otherActionSpinner, setotherActionSpinner] = useState(false);
  const [chargeBillInfo, setChargeBillInfo]: any = useState(undefined);
  const [paymentOriginDisable, setpaymentOriginDisable] = useState(false);
  const [creditDisable, setcreditDisable] = useState(false);
  const [AlertLoadCount, setAlertLoadCount] = useState<number>(0);
  const [AlertLoad, setAlertLoad] = useState<any | []>([]);
  const [activeAlert, setActiveAlert] = useState<[] | any>([]);
  const [availableCredsremaining, setavailableCredsremaining] = useState<any>(
    []
  );
  const { paymentSearchValue } = useContext(DeliveryContext);
  const [searchVal, setsearchVal] = paymentSearchValue;
  /**
   * changes : AdjustDueDate , Edit Schedule
   * Develepor: Muzzammil
   * desc : state variables used for both adjust due date and edit schedule for take payment
   */
  const [unverifiedCustomer, setunverifiedCustomer] = useState<boolean>(false);
  const [deliveryUnconfiremd, setdeliveryUnconfiremd] =
    useState<boolean>(false);
  const [agreementDeliveryUncomfirmed, setagreementDeliveryUncomfirmed] =
    useState<any>([]);
  const [carryMessage, setcarryMessage] = useState<string>("");
  const [paymentEditScheduleDropDown, setpaymentEditScheduleDropDown] =
    useState<any>();
  const [agrId, setAgrId] = useState<any>();
  const [checkedAgr, setcheckedAgr] = useState<any>([]); //gets the oncheck agreement details
  let [adjustDueDateSelectedAgre, setadjustDueDateSelectedAgre] = useState<any>(
    []
  ); //push all selected agreementIds
  const [adjustButton, setadjustButton] = useState<boolean>(); //responsible for displaying and not displaying the adjustduedate button
  const [promoFreeTimeButton, setpromoFreeTimeButton] = useState<any>();
  const [selectedOnlyCashSale, setselectedOnlyCashSale] = useState<any>(false);
  const [editButton, seteditButton] = useState<boolean>(); //responsible for displaying and not displaying the adjustduedate button
  const [disableAdjustDueDateButton, setdisableAdjustDueDateButton] =
    useState<boolean>(false); //disabling the adjust due date button if the agreement on checked is from the different store compared to login store
  const [openAdjustDueDate, setopenAdjustDueDate] = useState<boolean>(false); //on true --> accesing the adjust due date comeponent
  const [openPromoFreeDays, setopenPromoFreeDays] = useState<boolean>(false);
  const [editSchedulePopUp, seteditSchedulePopUp] = useState<boolean>(); //on true -->accesing the edit schedule component
  const [adjustAllButton, setadjustAllButton] = useState<boolean>(false);
  //set to default
  const [adjustTerminateLdwAmount, setadjustTerminateLdwAmount] =
    useState<any>();
  //cash sale
  const [nonInventoryPopUp, setnonInventoryPopUp] = useState<boolean>(false);
  const [misNumberValue, setmisNumberValue] = useState<any>(false);
  const [OtherIncomeHidden, setOtherIncomeHidden] = useState(false);
  const [EditNoninventoryData, setEditNoninventoryData] = useState<any>();
  const [otherIncomePopupClass, setotherIncomePopupClass] = useState<string>(
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  //carry rent
  const [isCommitmentInOtherStore, setIsCommitmentInOtherStore] =
    useState(false);
  const [carryRentWidget, setcarryRentWidget] = useState<boolean>(false);
  const [carryRentAmount, setcarryRentAmount] = useState<any>("0.00");
  const [totalCalciTextValue, settotalCalciTextValue] = useState<any>("0.00");
  const [carryRentInitialAndFinal, setcarryRentInitialAndFinal] =
    useState<boolean>(false);
  const [carryRentCheck, setcarryRentCheck] = useState<any>(false);
  const [selectallChecked, setselectallChecked] = useState<any>(false);
  const [carryMax, setcarryMax] = useState<any>(false);
  const [agreecarryUsed, setagreecarryUsed] = useState<any>(0);
  const [defaultPaygridvalues, setdefaultPaygridvalues] = useState<any>(); //setting the default getpayment info
  const [toDefaultActive, settoDefaultActive] = useState<boolean>(false);
  const [defaultTotaldue, setdefaultTotaldue] = useState<any>();
  //multiple card Muzzammil
  let [multipleCard, setmultipleCard] = useState<any>([]);
  let [chargeCardArray, setchargeCardArray] = useState<any>([]);
  const [cardToken, setcardToken] = useState<any>([]);
  //Accept EPO Changes
  const [showAcceptEpoPopup, setShowAcceptEpoPopup] = useState(false);
  const [epoPopup, setepoPopup] = useState<any>();
  const [epoShedulePopup, setEpoShedulePopup] = useState(false);
  const [showEPOGridBtn, setshowEPOGridBtn] = useState(false);
  const [newcustomer, setnewcustomer] = useState(true);
  const [customerInformation, setCustomerInformation] = useState<any>();
  const [epoPopupClass, setepoPopupClass] = useState<string>(
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );
  //activateClub
  const [clubStatus, setclubStatus] = useState("Club");
  const [
    deactivateClubCustomerRedirection,
    setdeactivateClubCustomerRedirection,
  ] = useState(false);
  const [clubStatusPopup, setclubStatusPopup] = useState({
    activateClub: false,
    deactivateClub: false,
    pendingClub: false,
  });
  const [clubAmount, setclubAmount] = useState("");
  const [errorPopup, seterrorPopup] = useState(false);
  const [masterloader, setmasterloader] = useState(false);
  const [isclubOnSameStore, setisclubOnSameStore] = useState(true);
  const [redirectToAccountManagement, setRedirectToAccountManagement] =
    useState(false);
    const placeOrderAddressObject = {
      country: "",
      city: "",
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      phoneNumber: "",
      verified: "",
      state: ""
    };
  const [placeOrderAddressData, setPlaceOrderAddressData]: any = useState(
    placeOrderAddressObject
  );
  const [placeOrderItemData, setPlaceOrderItemData]: any = useState([]);
  const [EAFlag, setEAFlag]: any = useState(null);
  const [AgrNumberPO, setAgrNumberPO]: any = useState("");
  const [taxExemption, setTaxExemption] = useState<any>("");
  let [enableCashPayment, setenableCashPayment] = useState(false);

  //ChargeCard and Add New Card Details pre-fill, variable used
  const prefillObj = {
    customerPrimaryAddress: "",
    customerSecondaryAddress: "",
    customerZip: "",
    customerCity: "",
    customerState: "",
    name: "",
  };
  const [customerInfopreFill, setCustomerInfopreFill] = useState(prefillObj);
  const [chargeCardFlag, setChargeCardFlag] = useState(false);
  const location = useLocation<any>();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [registerNumber, setRegisterNumber] = useState("");

  let SacAmt = 0;
  let epoAmt = 0;
  let currentUserResponse: any;
  let COAamount = 0.0;
  let selectedValue: any;
  let swipeAmt = 0.0;
  let selectedLabel: any;
  let swiperes: any;
  let cardSaveDetail: any;
  let stateDD: any;
  let truearrayforcheckbox: any[] = [];
  useEffect(() => {
    if (redirectOrigin === AMOrigin) {
      setIsFromAccountMgmt(true);
    }
  }, []);

  //This useEffect is trigger when chargeCard or NewCard button is Clicked
  useEffect(() => {
    if (chargeCardFlag == true) {
      let obj = {
        address_line_1: customerInfopreFill.customerPrimaryAddress,
        address_line2: customerInfopreFill.customerSecondaryAddress,
        city: customerInfopreFill.customerCity,
        state: customerInfopreFill.customerState,
        zip_code: customerInfopreFill.customerZip,
        name: customerInfopreFill.name,
      };
      window.sessionStorage.setItem(
        "billName",
        String(customerInfopreFill.name)
      );
      window.sessionStorage.setItem(
        "billAdd1",
        String(customerInfopreFill.customerPrimaryAddress)
      );
      window.sessionStorage.setItem(
        "billAdd2",
        String(customerInfopreFill.customerSecondaryAddress)
      );
      window.sessionStorage.setItem(
        "billState",
        String(customerInfopreFill.customerState)
      );
      window.sessionStorage.setItem(
        "billCity",
        String(customerInfopreFill.customerCity)
      );
      window.sessionStorage.setItem(
        "billZip",
        String(customerInfopreFill.customerZip)
      );
      setPopupfields({
        ...Popupfields,
        address_line_1: obj.address_line_1,
        address_line2: obj.address_line2,
        city: obj.city,
        zip_code: obj.zip_code,
        state: obj.state,
        name: obj.name,
      });
      setdefaultVal(obj.state);
      setChargeCardFlag(false);
      cardSaveDetail = Popupfields;
    }
  }, [chargeCardFlag]);

  useEffect(() => {
    /* eslint-disable no-console */
    if (
      paymentFunctionFlag.payFunc == 0 &&
      paymentFunctionFlag.isCashSale == 1
    ) {
      setnonInventoryPopUp(true);
    }
    console.log("I am get payinfo useEffect");
    let JsonsessionStoreDetails: any =
      window.sessionStorage.getItem("storeDetails");
    console.log(JsonsessionStoreDetails, "sessionStoreDetails1");
    // console.log(atob(JsonsessionStoreDetails),"sessionStoreDetails2")
    let sessionStoreDetails: any;
    try {
      sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
    } catch (e: any) {
      console.log(e, "not json formate");
    }

    if (sessionStoreDetails) {
      setPlaceOrderAddressData({
        ...placeOrderAddressData,
        country: "USA",
        city: sessionStoreDetails.city,
        addressLine1: sessionStoreDetails.addressLine1,
        addressLine2: sessionStoreDetails.addressLine2,
        postalCode: sessionStoreDetails.zip,
        phoneNumber: sessionStoreDetails.workPhoneNumber,
        verified: "true",
        state: sessionStoreDetails.state,
      });
    }

    console.log(sessionStoreDetails, "sessionStoreDetails2");
    if (sessionStoreDetails?.ccPaymentMerchantId != null || undefined || "") {
      setstoreMerchantId(sessionStoreDetails?.ccPaymentMerchantId);
      setstorezip(sessionStoreDetails?.zip);
    }
    getpaid();
  }, []);
  useEffect(() => {
    if (userdata.user?.permissions?.NAV_MENU?.includes("RPDCUST")) {
      currentServiceFlags.isCustomerRedirection = 1;
    } else {
      currentServiceFlags.isCustomerRedirection = 0;
    }
  }, [userdata]);
  let falsearrayforcheckbox: any[] = [];
  let storeNumber: any = "";
  let newStore: boolean;
  //static array of payment origin
  let paymentOriginarr = [
    { label: "In Store", value: "Store" },
    { label: "Over the Phone", value: "Pay By Phone" },
  ];
  //Static array of DevicName dropdown
  const DeviceNameArrfunc = () => {
    let storeSwipeDeviceList:any=[]
    if (
      configFlags?.isCloudStoreSwipeDevicelist == 0 &&
      DeveiceResp !== undefined &&
      DeveiceResp?.status == 200 &&
      DeveiceResp?.data !== undefined &&
      DeveiceResp?.data?.StorePaymentDeviceResponse !== undefined &&
      DeveiceResp?.data?.StorePaymentDeviceResponse?.devices !== undefined &&
      DeveiceResp?.data?.StorePaymentDeviceResponse?.devices !== null &&
      DeveiceResp?.data?.StorePaymentDeviceResponse?.devices.length > 0
    ) {
      console.log("DeveiceResp", DeveiceResp);
      console.log(
        "DeveiceResp.devices",
        DeveiceResp?.data?.StorePaymentDeviceResponse?.devices
      );
      return DeveiceResp?.data?.StorePaymentDeviceResponse?.devices.map(
        (values: any) => {
          const storeNumber = String(Number(values.storeNumber));
          const terminalId = String(values.termNumber)?.slice(-3);
          console.log("store and device id", storeNumber, terminalId);
          return { label: values.name, value: terminalId };
        }
      );
    } else if (
      configFlags?.isCloudStoreSwipeDevicelist == 1 &&
      DeveiceResp !== undefined &&
      DeveiceResp?.status == 200 &&
      DeveiceResp?.data !== undefined &&
      DeveiceResp?.data?.devices !== undefined &&
      DeveiceResp?.data?.devices !== null &&
      DeveiceResp?.data?.devices?.length > 0
    ) {
      console.log("DeveiceResp", DeveiceResp);
      console.log("DeveiceResp.devices", DeveiceResp?.data?.devices);
      return DeveiceResp?.data?.devices.map((values: any) => {
        const storeNumber = String(Number(values.storeNumber));
        const terminalId = String(values.termNumber)?.slice(-3);
        console.log("store and device id", storeNumber, terminalId);
        return { label: values.name, value: terminalId };
      });
    } else {
      // return [];
      setStoreSwipeDeviceListArray([])
    }
  };
  //static array of check info popup dropdown
  let CheckDropdownArr = [
    { value: "0", label: "Select" },
    { value: "1", label: "Personal check" },
    { value: "2", label: "Travellers check" },
    { value: "3", label: "Payroll Check" },
    { value: "4", label: "Government Check" },
    { value: "5", label: "Cashiers Check" },
    { value: "6", label: "Club Claim Check" },
  ];

  //this useeffect is to set the onchange variable of the devicename in the session storage
  useEffect(() => {
    setSessionstorage(String(window.sessionStorage.getItem("deviceName")));
    setTerminalId(String(window.sessionStorage.getItem("TerminalId")));
    setRegisterNumber(String(window.sessionStorage.getItem("registerNumber")));
    console.log("Session storage", Sessionstorage);
    console.log("Terminal storage", TerminalId);

    if (TerminalId === undefined) {
      setTerminalId("0");
      setSessionstorage(
        String(window.sessionStorage.setItem("TerminalId", "0"))
      );
    }
  }, []);
  useEffect(() => {
    console.log("sdcfred585455556sddpolicyService_state", policyService_state);
  }, [policyService_state]);
  useEffect(() => {
    console.log(paymentMade, "paymentMade Flag");
    if (paymentMade) {
      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      clubInitialCheckFlag = true;
    }
  }, [paymentMade]);
   //UseEffect helps to empty the global variable when the page is navigated once after taking the payment.
   useEffect(()=>{
    if (paymentMadeOptimized) {
      
      tendertypeglobalarray=[]
    }
  },[paymentMadeOptimized]);
  useEffect(() => {
    if (APpopupcompleted > 1) {
      if (ResponseObject.customerPaymentMethodId !== undefined) {
        let apResponseObject = ResponseObject.AgreementIds;
        let amountvalue = 0;
        let Schedule = "Weekly";
        if (IsClubSelectedForAutopay == false) {
          console.log("went into conditon schedule is not undefined");
          Schedule = paygridvalues.filter(
            (x) => x.Agreementid === apResponseObject[0]
          )[0].Schedule;
        } else {
          console.log("went into conditon schedule is undefined but club");
          Schedule = paygridvalues.filter((x) => x.Agreementtype === "Club")[0]
            .Schedule;
        }

        for (let i = 0; i < apResponseObject.length; i++) {
          let amtDueResp = agrAmtTaxArray.filter(
            (x) => x.agreementId == apResponseObject[i]
          );
          if (amtDueResp[0] != undefined) {
            amountvalue =
              amountvalue + amtDueResp[0].totalAmount + amtDueResp[0].taxAmount;
          } else {
            let objClubInfo =
              getpayment?.paymentInfo.clubInformation[0].clubMembershipNumber;
            let amtDueResp = agrAmtTaxArray.filter(
              (x) => x.agreementId == Number(objClubInfo)
            );
            amountvalue =
              amountvalue + amtDueResp[0].totalAmount + amtDueResp[0].taxAmount;
          }
        }
        let number = String(
          getpayment?.paymentInfo.customerInfo[0].phoneNumber
        );
        number = number.replace(/[^\d]/g, "");
        let Payload2: AutopaySendMessageRequest = {
          sendTextFlag: 1,
          callTxtReq: {
            language: "E",
            phoneNumber: String(number),
            textMessage: "TXT2AC",
            messageType: "TXT2AC",
            messageTemplateKey: "TXT2AC-MESSAGE",
            messageSubstitutes: {
              schedule: Schedule,
              amount: String(Number(amountvalue).toFixed(2)),
              lastFour: getCardService_state.cardResp.filter(
                (x) =>
                  x.customerPaymentMethodId ===
                  ResponseObject.customerPaymentMethodId
              )[0].cardLastFour,
            },
            callBackParams: {
              lastName: String(
                getpayment?.paymentInfo.customerInfo[0].lastName
              ),
              firstName: String(
                getpayment?.paymentInfo.customerInfo[0].firstName
              ),
              customerId: String(customerId),
              customerPaymentMethodId: ResponseObject.customerPaymentMethodId, //issue
              agreementIds: agreeementarray,
              clubId:
                IsClubSelectedForAutopay === true
                  ? String(
                      getpayment?.paymentInfo.clubInformation[0].customerClubId
                    )
                  : "", //issue
            },
            storeNumber: String(window.sessionStorage.getItem("storeNumber")),
            transactionId: customerId,
          },
        };
        sendMessage(Payload2);
      } else {
        let number = String(
          getpayment?.paymentInfo.customerInfo[0].phoneNumber
        );
        number = number.replace(/[^\d]/g, "");
        let Payload: AutopaySendMessageRequest = {
          sendTextFlag: 0,
          signupReq: {
            phoneNumber: String(number),
            storeNumber: String(window.sessionStorage.getItem("storeNumber")),
            partyId: customerId,
            emailAddress: String(
              getpayment?.paymentInfo.customerInfo[0].emailAddress
            ),
          },
        };
        sendMessage(Payload);
      }
    }
    showAutoPayPopup = false;
    setpropsautopayopen(-1);
  }, [APpopupcompleted]);

  useEffect(() => {
    if (showAddCard) {
      callVantivIframe(1);
    }
    if (showChargeCard) {
      callVantivIframe(2);
    }
  }, [showAddCard, showChargeCard]);

  // UseEffect for Txt2Pay enable disable - saraswathi;
  useEffect(() => {
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      console.log("entered into the for loop of loadother action");
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    if (
      isChecked == 1 &&
      totaldue > 0 &&
      (remainingamountdue === totaldue || tendertypeglobalarray.length === 0)
    ) {
      console.log("txt2pay is enable");
      setCardrequire({ ...Cardrequire, txt2paybtn: false });
    } else {
      console.log("txt2pay is disable");
      setCardrequire({ ...Cardrequire, txt2paybtn: true });
    }
    setPaymentAmt(totaldue);
  }, [remainingamountdue]);
  // Assign Alert - code ends here - Lokesh N
  interface agrarr {
    agreementId: number;
  }

  interface ParamType {
    customerId: string;
    agreementId: string;
  }
  const { customerId } = useParams<ParamType>();
  const { agreementId } = useParams<ParamType>();
  const [customerparamid]: any = useState(customerId);
  const [agreementparamid]: any = useState(agreementId);
  let unrollautopayagreementids: agrarr[] = [];
  let Enrollautopaagreementids: any = [];
  let bindgridmanipulation: any;

  bindgridmanipulation = [];
  let chkboxArr: any = [];

  const pageload = async () => {
    let Role = "";
    if (containerData != undefined) {
      currentUserResponse = containerData.GetEmployeeId();
      Role = containerData.GetRole();
      setempId(currentUserResponse);
      const menuConfig = containerData.GetMenuConfig();
      if(menuConfig && menuConfig.some((item: any)=> ( item.appComponentFunctionName=='Cash Payment'))){
        enableCashPayment = validateCashPayment(menuConfig);
        setenableCashPayment(enableCashPayment);
      }
    } else {
      const GetcurrentUser = await GetEmployeeID();
      console.log("GetcurrentUser", GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        setempId(EmployeeId);
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log("GetMenuDetails", GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          Role = GetMenuDetailsResponse.coworkerProfile.role;
          if(GetMenuDetailsResponse.menuConfig && GetMenuDetailsResponse.menuConfig.some((item: any)=> ( item.appComponentFunctionName=='Cash Payment'))){  
            enableCashPayment = validateCashPayment(GetMenuDetailsResponse.menuConfig);
            setenableCashPayment(enableCashPayment);
          }
        }
      }
    }
    let pageload = await GetCardInfo(customerId);
    let cardinfo: any = [];
    let getCardRes: any = [];
    if (pageload?.paymentMethods?.length > 0) {
      for (let i = 0; i < pageload?.paymentMethods?.length; i++) {
        cardinfo.push({
          accountNumber: pageload?.paymentMethods[i]?.tokenInfo?.accountNumber,
          apFlag: 0,
          billingAddressDetails: {
            addressLine1:
              pageload?.paymentMethods[i]?.billingAddress?.addressLine1,
            addressLine2:
              pageload?.paymentMethods[i]?.billingAddress?.addressLine2,
            city: pageload?.paymentMethods[i]?.billingAddress?.city,
            state: pageload?.paymentMethods[i]?.billingAddress?.state,
            zip: pageload?.paymentMethods[i]?.billingAddress?.postalCode,
          },
          billingAddressId:
            pageload?.paymentMethods[i]?.billingAddress?.billingAddressId,
          cardExpiration:
            pageload?.paymentMethods[i]?.tokenInfo?.expirationDate,
          cardHolderName: pageload?.paymentMethods[i]?.cardHolderName,
          cardLastFour: pageload?.paymentMethods[i]?.lastFour,
          cardRef: pageload?.paymentMethods[i]?.cardTypeRef,
          cardToken: pageload?.paymentMethods[i]?.tokenInfo?.paymentToken,
          cardType: pageload?.paymentMethods[i]?.cardType,
          customerId: Number(pageload?.paymentMethods[i]?.customerId),
          customerPaymentMethodId: pageload?.paymentMethods[i]?.paymentMethodId,
          routingNumber: pageload?.paymentMethods[i]?.tokenInfo?.routingNumber,
        });
      }
      pageload = { cardResp: cardinfo };
    } else {
      pageload = { cardResp: [] };
    }
    setgetCardService_state(pageload);
    if (pageload.cardResp !== undefined && pageload?.cardResp?.length > 0) {
      const Card_FalseArray: any = new Array(pageload.cardResp.length).fill(
        false
      );
      setCardRadiArr(Card_FalseArray);
      setEditCard({
        ...EditCard,
        address_1:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine1,
        address_2:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine2,
        Cardname: pageload.cardResp[editcardindex].cardHolderName,
        city: pageload.cardResp[editcardindex].billingAddressDetails.city,
        zip: pageload.cardResp[editcardindex].billingAddressDetails.zip,
        state: pageload.cardResp[editcardindex].billingAddressDetails.state,
        cardExpDate: pageload.cardResp[editcardindex].cardExpiration,
      });
      let mulCardArr: any = [];
      let cardToken: any = [];
      for (let i = 0; i < pageload.cardResp.length; i++) {
        mulCardArr.push({
          lastFour: String(pageload.cardResp[i].cardLastFour),
          Amount: "0.00",
          billingAddress: pageload.cardResp[i].billingAddressDetails,
          expirationDate: pageload.cardResp[i].cardExpiration,
          paymentId: pageload.cardResp[i].customerPaymentMethodId,
          cardType: pageload.cardResp[i].cardType,
          cvv: "",
          cvvEncrypt: "",
          chargeCard: false,
          cardToken: pageload.cardResp[i].cardToken,
          cardRef: pageload.cardResp[i].cardRef,
        });
        cardToken.push({
          lastFour: String(pageload.cardResp[i].cardLastFour),
          cardToken: pageload.cardResp[i].cardToken,
        });
      }
      setcardToken(cardToken);
      setmultipleCard(mulCardArr);
    }
    const payload = {
      module: "Accept Payment",
      storeNumber: window.sessionStorage.getItem("storeNumber"),
      currentRole: Role,
    };

    const profileResp = await getprofileaccess(payload);
    setprofileAccResp(profileResp);
    /* eslint-disable no-console */
    console.log(currentUserResponse, "get current user");
  };

  const validateCashPayment = (menuConfig: any) => {
    return !(menuConfig.some((item: any)=> ( item.appComponentFunctionName=='Cash Payment' && item.edit == 1)))
  }

  const getCardAfterUpdate = async () => {
    let pageload = await GetCardInfo(customerId);
    let cardinfo: any = [];
    let getCardRes: any = [];
    if (pageload?.paymentMethods?.length > 0) {
      for (let i = 0; i < pageload?.paymentMethods?.length; i++) {
        cardinfo.push({
          accountNumber: pageload?.paymentMethods[i]?.tokenInfo?.accountNumber,
          apFlag: 0,
          billingAddressDetails: {
            addressLine1:
              pageload?.paymentMethods[i]?.billingAddress?.addressLine1,
            addressLine2:
              pageload?.paymentMethods[i]?.billingAddress?.addressLine2,
            city: pageload?.paymentMethods[i]?.billingAddress?.city,
            state: pageload?.paymentMethods[i]?.billingAddress?.state,
            zip: pageload?.paymentMethods[i]?.billingAddress?.postalCode,
          },
          billingAddressId:
            pageload?.paymentMethods[i]?.billingAddress?.billingAddressId,
          cardExpiration:
            pageload?.paymentMethods[i]?.tokenInfo?.expirationDate,
          cardHolderName: pageload?.paymentMethods[i]?.cardHolderName,
          cardLastFour: pageload?.paymentMethods[i]?.lastFour,
          cardRef: pageload?.paymentMethods[i]?.cardTypeRef,
          cardToken: pageload?.paymentMethods[i]?.tokenInfo?.paymentToken,
          cardType: pageload?.paymentMethods[i]?.cardType,
          customerId: Number(pageload?.paymentMethods[i]?.customerId),
          customerPaymentMethodId: pageload?.paymentMethods[i]?.paymentMethodId,
          routingNumber: pageload?.paymentMethods[i]?.tokenInfo?.routingNumber,
        });
      }
      pageload = { cardResp: cardinfo };
    } else {
      pageload = { cardResp: [] };
    }
    setgetCardService_state(pageload);
    if (pageload.cardResp !== undefined) {
      const Card_FalseArray: any = new Array(pageload.cardResp.length).fill(
        false
      );
      setCardRadiArr(Card_FalseArray);
      setEditCard({
        ...EditCard,
        address_1:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine1,
        address_2:
          pageload.cardResp[editcardindex].billingAddressDetails.addressLine2,
        Cardname: pageload.cardResp[editcardindex].cardHolderName,
        city: pageload.cardResp[editcardindex].billingAddressDetails.city,
        zip: pageload.cardResp[editcardindex].billingAddressDetails.zip,
        state: pageload.cardResp[editcardindex].billingAddressDetails.state,
        cardExpDate: pageload.cardResp[editcardindex].cardExpiration,
      });
    }
    let mulCardArr: any = [];
    let cardToken: any = [];
    let mulCardUI: any = [];
    for (let i = 0; i < pageload.cardResp.length; i++) {
      mulCardArr.push({
        lastFour: String(pageload.cardResp[i].cardLastFour),
        Amount: "0.00",
        billingAddress: pageload.cardResp[i].billingAddressDetails,
        expirationDate: pageload.cardResp[i].cardExpiration,
        paymentId: pageload.cardResp[i].customerPaymentMethodId,
        cardType: pageload.cardResp[i].cardType,
        cvv: "",
        cvvEncrypt: "",
        chargeCard: false,
        cardToken: pageload.cardResp[i].cardToken,
        cardRef: pageload.cardResp[i].cardRef,
      });
      cardToken.push({
        lastFour: String(pageload.cardResp[i].cardLastFour),
        cardToken: pageload.cardResp[i].cardToken,
      });
    }
    setcardToken(cardToken);
    setmultipleCard(mulCardArr);
    tendertypeglobalarray = tendertypeglobalarray.filter(
      (x) => !x.tendertype.split(" - ")[0].includes("Card ending")
    );
    settotalduetendertype(tendertypeglobalarray);
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    //
    const tenderAmount = Number(
      tendertypeglobalarray.length == 0
        ? 0.0
        : tendertypeglobalarray
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    if (
      Number(totaldue) -
        Number(availableCreds) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) >
      Number(tenderAmount)
    ) {
      setremainingamountdue(
        Number(
          Number(totaldue) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) -
            Number(tenderAmount)
        ).toFixed(2)
      );
    } else {
      setremainingamountdue("0.00");
      setchange(
        Number(
          Number(tenderAmount) -
            Number(totaldue) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
        ).toFixed(2)
      );
    }
  };
  const unenrollAutoPayCloseClick = () => {
    setvarbalecheckbox(false);
    setsendtext(true);
    setunenrollautopayopen(false);
  };
  const AutoPayCloseClick = () => {
    setautopaypopup(false);
    setAutopaybutton(true);
  };
  const AutoPayNotnowClick = () => {
    setautopaypopup(false);
    setAutopaybutton(true);
    setvarbalecheckbox(false);
    setsendtext(true);
  };
  const AutoPayClearClick = () => {
    setvarbalecheckbox(false);
    setupdatedarray(unerollemptyarray);
  };
  let checkuncheckarr: any = new Array(updatedarray.length);
  const unCheckandChecking = (index: any) => {
    updatedarray.map((value: any, index: any) => {
      checkuncheckarr.push(updatedarray[index]);
    });
    if (updatedarray[index] == true) {
      checkuncheckarr.splice(index, 1, false);
    } else {
      checkuncheckarr.splice(index, 1, true);
    }
    setupdatedarray(checkuncheckarr);
  };
  const phoneNumberRequiredPopUp = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {phoneNumberRequired == true ? (
            <Typography className={classes.racErrorFunction}>
              This action requires a primary phone number please update the
              primary phone number
            </Typography>
          ) : null}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setphoneNumberRequired(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const zipCodeRequiredPopUp = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            There is no customer zip code available amount due will be
            calculated based on store zip code. use payment traingle if customer
            zip code needs to update in payment page.
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setcustomerzipcodenull(false)}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const suspenseDeleteFunc = () => {
    const checkSomeFinalSuspenseUse = totalsuspensemaster?.some(
      (el) => el.sustype == "FinalTransferUsed" || el.sustype == "FinalUsed"
    );
    const returnAGRId = paygridvalues?.some((el) => el.isReturnAGR == 1);
    let checkReturnAGRSuspenseUse = [];
    if (returnAGRId?.length > 0) {
      checkReturnAGRSuspenseUse = totalsuspensemaster?.some(
        (el) => String(el.AgreementNumber) == String(returnAGRId[0].Agreementid)
      );
    }

    if (
      checkSomeFinalSuspenseUse?.length > 0 ||
      checkReturnAGRSuspenseUse?.length > 0
    ) {
      setSuspenseDeleteExpection(true);
      if (checkSomeFinalSuspenseUse?.length > 0) {
        setFinalSuspenseDelete(true);
      }
      if (checkReturnAGRSuspenseUse?.length > 0) {
        setReturnAgrSuspense(true);
      }
    } else {
      setsuspenseAllDelete(true);
    }
  };

  const finalsuspenseOverallDelete = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {suspenseDeleteExpection == true && returnAgrSuspense == false ? (
            <Typography className={classes.racErrorFunction}>
              Final payment Suspense cannot be deleted. Please use set to
              default
            </Typography>
          ) : suspenseDeleteExpection == false && returnAgrSuspense == true ? (
            <Typography className={classes.racErrorFunction}>
              Return agreement Suspense cannot be deleted. Please use set to
              default
            </Typography>
          ) : (
            <Typography className={classes.racErrorFunction}>
              Final payment & Return agreement Suspense cannot be deleted.
              Please use set to default
            </Typography>
          )}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setSuspenseDeleteExpection(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </div>
    );
  };

  const suspenseOverAllDelete = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            Are you sure to delete all the suspenses?
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => deleteclick()}
          >
            Yes
          </RACButton>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => setsuspenseAllDelete(false)}
          >
            No
          </RACButton>
        </Grid>
      </div>
    );
  };
  const EnrollAutoPayment = () => {
    if (paygridvalues[0].Phonenumber == "") {
      setphoneNumberRequired(true);
    } else {
      showAutoPayPopup = true;
      setpropsautopayopen(1);
      console.log("--------------- Props AP 0", showAutoPayPopup);
      agreeementarray = [];
      if (
        getCardService_state.length == 0 ||
        getCardService_state.cardResp === undefined
      ) {
        setautopaypopup(true);
      } else {
        console.log("--------------- Props AP 1", showAutoPayPopup);
        for (let i = 0; i < paygridvalues.length; i++) {
          if (
            (document.getElementsByName("grid")[i] as HTMLInputElement)
              .checked === true
          ) {
            if (paygridvalues[i].Agreementtype === "Club") {
              setIsClubSelectedForAutopay(true);
              agreeementarray.push("Club");
            } else {
              agreeementarray.push(paygridvalues[i].Agreementid);
            }
          }
        }
        setagreementarraystate(agreeementarray);
      }
    }
  };

  const AutoPaySubmitClick = () => {
    let isSignUp = false;
    console.log(
      "paygridvalues length inside autopay submit click-------->",
      paygridvalues
    );
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      let isChecked = (
        document.getElementsByName("grid")[i] as HTMLInputElement
      ).checked;
      if (isChecked == true) {
        Enrollautopaagreementids.push(paygridvalues[i].Agreementid);
      }
      if (
        getCardService_state.length === 0 &&
        getCardService_state.cardResp === undefined
      ) {
        let paymentsMade = paygridvalues[i].number_of_paymentsmade;

        if (isChecked == true) {
          if (paymentsMade === 0) {
            let payload1 = {
              agreementIds: Enrollautopaagreementids,
            };
            EnrollAutopay(payload1);
          } else {
            isSignUp = true;
            break;
          }
        }
      }
    }
    if (isSignUp) {
      let number = String(getpayment?.paymentInfo.customerInfo[0].phoneNumber);
      number = number.replace(/[^\d]/g, "");
      let Payload: AutopaySendMessageRequest = {
        sendTextFlag: 0,
        signupReq: {
          phoneNumber: String(number),
          storeNumber: String(window.sessionStorage.getItem("storeNumber")),
          partyId: customerId,
          emailAddress: String(
            getpayment?.paymentInfo.customerInfo[0].emailAddress
          ),
        },
      };
      sendMessage(Payload);
    }
    setvarbalecheckbox(false);
    setautopaypopup(false);
    setAutopaybutton(true);
  };

  // const LDW_apiCall = async (Num) => {
  //   if (carryRentWidget) {
  //     carryCheckChange();
  //   }
  //   if (actLDWArr.length > 0) {
  //     let objLdwAction = actLDWArr.filter(
  //       (x) => x.agreementId == Number(paygridvalues[selectedGrid].Agreementid)
  //     );
  //     if (objLdwAction[0] != undefined) {
  //       objLdwAction[0].action = Number(Num);
  //     } else {
  //       let ldwParam: ldwReq = {
  //         agreementId: Number(paygridvalues[selectedGrid].Agreementid),
  //         action: Number(Num),
  //         cusCoverageData: otherActState.data,
  //       };
  //       actLDWArr.push(ldwParam);
  //     }
  //   } else {
  //     let ldwParam: ldwReq = {
  //       agreementId: Number(paygridvalues[selectedGrid].Agreementid),
  //       action: Number(Num),
  //       cusCoverageData: otherActState.data,
  //     };
  //     actLDWArr.push(ldwParam);
  //   }

  //   /* eslint-disable no-console */

  //   let objLdWAmount = totalduereceiptid.filter(
  //     (x) => x.agreement_id == paygridvalues[selectedGrid].AgreementNumber
  //   );
  //   console.log(objLdWAmount, "ldw amount log");
  //   if (Num === 2) {
  //     if (paygridvalues[0].Agreementtype == "Club") {
  //       console.log("went inside condition", paygridvalues[0]);
  //       document
  //         .getElementById("maincheckbox")
  //         ?.setAttribute("disabled", "true");
  //       setclubCheckDisabled(true);
  //       AcceptclubPaymentParam = undefined;
  //     }
  //     let totalAdjustedLdwAmount = "0";
  //     if (adjustTerminateLdwAmount != "0") {
  //       totalAdjustedLdwAmount = Number(adjustTerminateLdwAmount).toFixed(2);
  //     } else {
  //       totalAdjustedLdwAmount = customerCoverageObject.ldwAmount;
  //     }
  //     let TerminateLdw_Amt =
  //       parseFloat(totaldue) - parseFloat(totalAdjustedLdwAmount);
  //     console.log("TerminateLdw_Amt", TerminateLdw_Amt);
  //     paygridvalues[selectedGrid].Amount =
  //       paygridvalues[selectedGrid].Amount - parseFloat(totalAdjustedLdwAmount);
  //     paygridvalues[selectedGrid].Taxamount =
  //       Number(paygridvalues[selectedGrid].Taxamount) -
  //       parseFloat(String(paygridvalues[selectedGrid].ldwTaxAmount));
  //     const totaltaxamount =
  //       totaltax - Number(paygridvalues[selectedGrid].ldwTaxAmount);
  //     settotaltax(totaltaxamount);
  //     paygridvalues[selectedGrid].agrLdwAmount = 0;
  //     paygridvalues[selectedGrid].ldwTaxAmount = 0;
  //     console.log("Amount Due", paygridvalues[selectedGrid].Amount);
  //     if (objLdWAmount[0] != undefined) {
  //       objLdWAmount[0].agreement_totalamount = TerminateLdw_Amt;
  //     }
  //     console.log("Reciept Due", objLdWAmount);

  //     setRemaniningAmtStatic(
  //       Number(RemaniningAmtStatic) - parseFloat(totalAdjustedLdwAmount)
  //     );
  //     settotaldue(TerminateLdw_Amt);
  //     setremainingamountdue(
  //       Number(RemaniningAmtStatic) - parseFloat(totalAdjustedLdwAmount)
  //     ); //change here .tofixed
  //     setAcceptpaymentbuttonenable(true);
  //     setActionState(1);
  //     resetPayment(undefined);

  //     console.log(
  //       "ldwTax",
  //       Number(paygridvalues[selectedGrid].Taxamount) -
  //         parseFloat(otherActState.data.ldwInfo.ldwAmountWithTax)
  //     );
  //   } else {
  //     if (paygridvalues[0].Agreementtype == "Club") {
  //       console.log("went inside condition", paygridvalues[0]);
  //       document.getElementById("maincheckbox")?.removeAttribute("disabled");
  //       setclubCheckDisabled(false);
  //     }

  //     let ActiveLdw_Amt =
  //       parseFloat(totaldue) +
  //       parseFloat(customerCoverageObject.ldwAmount) +
  //       Number(paygridvalues[selectedGrid].carryLdwAmount) +
  //       Number(paygridvalues[selectedGrid].carryLdwAmountTax);
  //     console.log("ActiveLdw_Amt", ActiveLdw_Amt);
  //     paygridvalues[selectedGrid].Amount =
  //       paygridvalues[selectedGrid].Amount +
  //       Number(paygridvalues[selectedGrid].carryLdwAmount) +
  //       Number(paygridvalues[selectedGrid].carryLdwAmountTax) +
  //       parseFloat(customerCoverageObject.ldwAmount);
  //     paygridvalues[selectedGrid].agrLdwAmount = parseFloat(
  //       String(
  //         Number(customerCoverageObject.ldwAmount) -
  //           Number(customerCoverageObject.ldwTax) +
  //           Number(paygridvalues[selectedGrid].carryLdwAmount)
  //       )
  //     );
  //     paygridvalues[selectedGrid].ldwTaxAmount = parseFloat(
  //       String(customerCoverageObject.ldwTax) +
  //         String(paygridvalues[selectedGrid].carryLdwAmountTax)
  //     );

  //     const totaltaxamount =
  //       totaltax + Number(paygridvalues[selectedGrid].ldwTaxAmount);
  //     settotaltax(totaltaxamount);
  //     console.log("Amount Due", paygridvalues[selectedGrid].Amount);
  //     if (objLdWAmount[0] != undefined) {
  //       objLdWAmount[0].agreement_totalamount = ActiveLdw_Amt;
  //     }
  //     console.log("Reciept Due", objLdWAmount[0].agreement_totalamount);
  //     paygridvalues[selectedGrid].Taxamount = Number(
  //       Number(paygridvalues[selectedGrid].Taxamount) +
  //         parseFloat(String(Number(paygridvalues[selectedGrid].ldwTaxAmount)))
  //     );
  //     console.log(
  //       "ldwTax",
  //       Number(paygridvalues[selectedGrid].Taxamount) +
  //         parseFloat(String(paygridvalues[selectedGrid].ldwTaxAmount))
  //     );
  //     setRemaniningAmtStatic(
  //       Number(RemaniningAmtStatic) +
  //         Number(paygridvalues[selectedGrid].carryLdwAmount) +
  //         Number(paygridvalues[selectedGrid].carryLdwAmountTax) +
  //         parseFloat(customerCoverageObject.ldwAmount)
  //     );
  //     settotaldue(ActiveLdw_Amt);
  //     setremainingamountdue(
  //       Number(RemaniningAmtStatic) +
  //         Number(paygridvalues[selectedGrid].carryLdwAmount) +
  //         Number(paygridvalues[selectedGrid].carryLdwAmountTax) +
  //         parseFloat(customerCoverageObject.ldwAmount)
  //     ); //change here .tofixed
  //     setAcceptpaymentbuttonenable(true);
  //     setActionState(2);
  //     resetPayment(undefined);
  //   }
  //   let receiptObj = ReceiptCategoriesRequestParam.filter(
  //     (e) => e.agreementId == paygridvalues[selectedGrid].Agreementid
  //   );
  //   let acceptObj = acceptPaymentParam.filter(
  //     (e) => e.agreementId == paygridvalues[selectedGrid].Agreementid
  //   );
  //   if (receiptObj[0] != undefined) {
  //     receiptObj[0].ldwFeeAmount = Number(
  //       paygridvalues[selectedGrid].agrLdwAmount.toFixed(2)
  //     );
  //     receiptObj[0].ldwFeeAmountTax = Number(
  //       Number(paygridvalues[selectedGrid].ldwTaxAmount).toFixed(2)
  //     );
  //     receiptObj[0].amountDue = Number(
  //       (
  //         paygridvalues[selectedGrid].Amount -
  //         Number(paygridvalues[selectedGrid].lateFee)
  //       ).toFixed(2)
  //     );
  //     receiptObj[0].totalTax = Number(
  //       paygridvalues[selectedGrid].Taxamount.toFixed(2)
  //     );
  //   }
  //   console.log(receiptObj[0], "receipt obj ldw");
  //   if (acceptObj[0] != undefined) {
  //     acceptObj[0].ldwFeeAmount = Number(
  //       paygridvalues[selectedGrid].agrLdwAmount.toFixed(2)
  //     );
  //     acceptObj[0].agreementAmountDue = Number(
  //       (
  //         Number(paygridvalues[selectedGrid].Amount) +
  //         Number(paygridvalues[selectedGrid].suspenseSub)
  //       ).toFixed(2)
  //     );
  //     acceptObj[0].totalTax = Number(
  //       paygridvalues[selectedGrid].Taxamount.toFixed(2)
  //     );
  //   }
  // };

  const LDW_apiCall = async (Num, otherActData?, manualIndex?) => {
    // alert("Reached LDW_apiCall");
    console.log("value of the otherActData is df", otherActData);
    // alert("called ldw");
    // debugger;
    if (carryRentWidget) {
      carryCheckChange();
    }
    console.log("8eijdnfyr4u3wed", actLDWArr);
    console.log("sxcfe456tygfselectedGrid", selectedGrid);
    // alert("came inside ldw api call");

    if (actLDWArr.length > 0) {
      let objLdwAction = actLDWArr.filter(
        (x) =>
          x.agreementId ==
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          )
      );
      if (objLdwAction[0] != undefined) {
        objLdwAction[0].action = Number(Num);
      } else {
        let ldwParam: ldwReq = {
          agreementId: Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          ),
          action: Number(Num),
          cusCoverageData:
            otherActData?.custCoverageresp !== undefined
              ? otherActData?.custCoverageresp.data
              : otherActState.data,
        };
        actLDWArr.push(ldwParam);
      }
    } else {
      let ldwParam: ldwReq = {
        agreementId: Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Agreementid
        ),
        action: Number(Num),
        cusCoverageData:
          otherActData?.custCoverageresp !== undefined
            ? otherActData?.custCoverageresp.data
            : otherActState.data,
      };
      actLDWArr.push(ldwParam);
    }

    /* eslint-disable no-console */

    let objLdWAmount = totalduereceiptid.filter(
      (x) =>
        x.agreement_id ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .AgreementNumber
    );
    console.log(objLdWAmount, "ldw amount log");
    if (Num === 2) {
      if (paygridvalues[0].Agreementtype == "Club") {
        console.log("went inside condition", paygridvalues[0]);
        document
          .getElementById("maincheckbox")
          ?.setAttribute("disabled", "true");
        setclubCheckDisabled(true);
        AcceptclubPaymentParam = undefined;
      }
      console.log(
        "vakue on the adjustTerminateLdwAmount",
        adjustTerminateLdwAmount
      );
      console.log(
        "customerCoverageObject 8firj4nhr4rt",
        customerCoverageObject
      );
      console.log("otherActData?.resp", otherActData?.resp);

      let totalAdjustedLdwAmount = "0";
      // if (adjustTerminateLdwAmount != "0") {
      //   console.log('reached the if of the ldw');

      //   totalAdjustedLdwAmount = Number(adjustTerminateLdwAmount).toFixed(2);
      // } else {
      totalAdjustedLdwAmount =
        otherActData?.resp !== undefined
          ? otherActData?.resp.ldwAmount
          : customerCoverageObject.ldwAmount;
      // }
      console.log(
        "value of thred totalAdjustedLdwAmount is",
        totalAdjustedLdwAmount
      );
      console.log(
        " vndjskw dmfjei348rg",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount
      );
      console.log("sdfee4rtf totaldue", totaldue);

      let TerminateLdw_Amt =
        parseFloat(totaldue) - parseFloat(totalAdjustedLdwAmount);
      console.log("TerminateLdw_Amt", TerminateLdw_Amt);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount - parseFloat(totalAdjustedLdwAmount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount =
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) -
        parseFloat(
          String(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].ldwTaxAmount
          )
        );
      const totaltaxamount =
        totaltax -
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .ldwTaxAmount
        );
      settotaltax(totaltaxamount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].agrLdwAmount = 0;
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].ldwTaxAmount = 0;
      console.log(
        "Amount Due",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount
      );
      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = TerminateLdw_Amt;
      }
      console.log("Reciept Due", objLdWAmount);

      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) - parseFloat(totalAdjustedLdwAmount)
      );
      settotaldue(TerminateLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) - parseFloat(totalAdjustedLdwAmount)
      ); //change here .tofixed
      setAcceptpaymentbuttonenable(true);
      setActionState(1);
      resetPayment(undefined);

      // console.log(
      //   "ldwTax",
      //   Number(paygridvalues[selectedGrid].Taxamount) -
      //     parseFloat(otherActState.data.ldwInfo.ldwAmountWithTax)
      // );
    } else {
      console.log(
        "sdcfev48dlmvbbb",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
      );

      if (paygridvalues[0].Agreementtype == "Club") {
        console.log("went inside condition", paygridvalues[0]);
        document.getElementById("maincheckbox")?.removeAttribute("disabled");
        setclubCheckDisabled(false);
      }
      console.log("customerCoverageObject value", customerCoverageObject);
      console.log("totaldue check log", totaldue);
      console.log(
        "carryldwamt",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .carryLdwAmount
      );
      console.log(
        "carryldwtacamt",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .carryLdwAmountTax
      );
      console.log(
        "amtlogger",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount
      );

      let ActiveLdw_Amt =
        parseFloat(totaldue) +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.ldwAmount
            : customerCoverageObject.ldwAmount
        ) +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmount
        ) +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmountTax
        );
      console.log("ActiveLdw_Amt", ActiveLdw_Amt);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmount
        ) +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .carryLdwAmountTax
        ) +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.ldwAmount
            : customerCoverageObject.ldwAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].agrLdwAmount = parseFloat(
        String(
          Number(
            otherActData?.resp !== undefined
              ? otherActData?.resp.ldwAmount
              : customerCoverageObject.ldwAmount
          ) -
            Number(
              otherActData?.resp !== undefined
                ? otherActData?.resp.ldwAmount
                : customerCoverageObject.ldwAmount
            ) +
            Number(
              paygridvalues[
                manualIndex !== undefined ? manualIndex : selectedGrid
              ].carryLdwAmount
            )
        )
      );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].ldwTaxAmount = parseFloat(
        String(
          otherActData?.resp !== undefined
            ? otherActData?.resp.ldwAmount
            : customerCoverageObject.ldwAmount
        ) +
          String(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmountTax
          )
      );

      const totaltaxamount =
        totaltax +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .ldwTaxAmount
        );
      settotaltax(totaltaxamount);
      console.log(
        "Amount Due",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount
      );
      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = ActiveLdw_Amt;
      }
      //console.log("Reciept Due", objLdWAmount[0].agreement_totalamount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) +
          parseFloat(
            String(
              Number(
                paygridvalues[
                  manualIndex !== undefined ? manualIndex : selectedGrid
                ].ldwTaxAmount
              )
            )
          )
      );
      console.log(
        "ldwTax",
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) +
          parseFloat(
            String(
              paygridvalues[
                manualIndex !== undefined ? manualIndex : selectedGrid
              ].ldwTaxAmount
            )
          )
      );
      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmount
          ) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmountTax
          ) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.ldwAmount
              : customerCoverageObject.ldwAmount
          )
      );
      console.log("chnsj74mkd936serActiveLdw_Amt", ActiveLdw_Amt);

      settotaldue(ActiveLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmount
          ) +
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].carryLdwAmountTax
          ) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.ldwAmount
              : customerCoverageObject.ldwAmount
          )
      ); //change here .tofixed
      setAcceptpaymentbuttonenable(true);
      setActionState(2);
      resetPayment(undefined);
    }
    let receiptObj = ReceiptCategoriesRequestParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    let acceptObj = acceptPaymentParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    if (receiptObj[0] != undefined) {
      receiptObj[0].ldwFeeAmount = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].agrLdwAmount.toFixed(2)
      );
      receiptObj[0].ldwFeeAmountTax = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .ldwTaxAmount
        ).toFixed(2)
      );
      receiptObj[0].amountDue = Number(
        (
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Amount -
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].lateFee
          )
        ).toFixed(2)
      );
      receiptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
    console.log(receiptObj[0], "receipt obj ldw");
    if (acceptObj[0] != undefined) {
      acceptObj[0].ldwFeeAmount = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].agrLdwAmount.toFixed(2)
      );
      acceptObj[0].agreementAmountDue = Number(
        (
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Amount
          ) +
          (Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].acceptEpoFlag
          ) == 1
            ? Number(
                paygridvalues[
                  manualIndex !== undefined ? manualIndex : selectedGrid
                ].suspenseSub
              )
            : 0)
        ).toFixed(2)
      );
      acceptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
  };
  // const Policy_apiCall = async (Num) => {
  //   if (actPolicyArr.length > 0) {
  //     let objLdwAction = actPolicyArr.filter(
  //       (x) => x.agreementId == Number(paygridvalues[selectedGrid].Agreementid)
  //     );
  //     if (objLdwAction[0] != undefined) {
  //       objLdwAction[0].action = Number(Num);
  //     } else {
  //       let ldwParam: ldwReq = {
  //         agreementId: Number(paygridvalues[selectedGrid].Agreementid),
  //         action: Number(Num),
  //         cusCoverageData: otherActState.data,
  //       };
  //       actPolicyArr.push(ldwParam);
  //     }
  //   } else {
  //     let ldwParam: ldwReq = {
  //       agreementId: Number(paygridvalues[selectedGrid].Agreementid),
  //       action: Number(Num),
  //       cusCoverageData: otherActState.data,
  //     };
  //     actPolicyArr.push(ldwParam);
  //   }
  //   /* eslint-disable no-console */
  //   let objLdWAmount = totalduereceiptid.filter(
  //     (x) => x.agreement_id == paygridvalues[selectedGrid].AgreementNumber
  //   );
  //   console.log(objLdWAmount, "ldw amount log");
  //   if (Num === 2) {
  //     setpolicyService_state(1);
  //     let TerminateLdw_Amt =
  //       parseFloat(totaldue) - parseFloat(customerCoverageObject.policyAmount);
  //     console.log("TerminateLdw_Amt", TerminateLdw_Amt);
  //     paygridvalues[selectedGrid].Amount =
  //       paygridvalues[selectedGrid].Amount -
  //       parseFloat(customerCoverageObject.policyAmount);
  //     paygridvalues[selectedGrid].Taxamount =
  //       Number(paygridvalues[selectedGrid].Taxamount) -
  //       parseFloat(String(paygridvalues[selectedGrid].policyTaxAmount));
  //     const totaltaxamount =
  //       totaltax - Number(paygridvalues[selectedGrid].policyTaxAmount);
  //     settotaltax(totaltaxamount);
  //     paygridvalues[selectedGrid].policyAmount = 0;
  //     paygridvalues[selectedGrid].policyTaxAmount = 0;
  //     console.log("Amount Due", paygridvalues[selectedGrid].Amount);
  //     if (objLdWAmount[0] != undefined) {
  //       objLdWAmount[0].agreement_totalamount = TerminateLdw_Amt;
  //     }
  //     console.log("Reciept Due", objLdWAmount);

  //     setRemaniningAmtStatic(
  //       Number(RemaniningAmtStatic) -
  //         parseFloat(customerCoverageObject.policyAmount)
  //     );
  //     settotaldue(TerminateLdw_Amt);
  //     setremainingamountdue(
  //       Number(RemaniningAmtStatic) -
  //         parseFloat(customerCoverageObject.policyAmount)
  //     ); //change here .tofixed
  //     setAcceptpaymentbuttonenable(true);
  //     resetPayment(undefined);
  //   } else {
  //     setpolicyService_state(2);
  //     let ActiveLdw_Amt =
  //       parseFloat(totaldue) + parseFloat(customerCoverageObject.policyAmount);
  //     console.log("ActiveLdw_Amt", ActiveLdw_Amt);
  //     paygridvalues[selectedGrid].Amount =
  //       paygridvalues[selectedGrid].Amount +
  //       parseFloat(customerCoverageObject.policyAmount);
  //     paygridvalues[selectedGrid].policyAmount = parseFloat(
  //       String(
  //         Number(customerCoverageObject.policyAmount) -
  //           Number(customerCoverageObject.policyTax)
  //       )
  //     );
  //     paygridvalues[selectedGrid].policyTaxAmount = parseFloat(
  //       String(customerCoverageObject.policyTax)
  //     );
  //     console.log(
  //       paygridvalues[selectedGrid],
  //       customerCoverageObject,
  //       "active policy"
  //     );
  //     const totaltaxamount =
  //       totaltax + Number(paygridvalues[selectedGrid].policyTaxAmount);
  //     settotaltax(totaltaxamount);
  //     console.log("Amount Due", paygridvalues[selectedGrid].Amount);
  //     if (objLdWAmount[0] != undefined) {
  //       objLdWAmount[0].agreement_totalamount = ActiveLdw_Amt;
  //     }
  //     console.log("Reciept Due", objLdWAmount[0].agreement_totalamount);
  //     paygridvalues[selectedGrid].Taxamount = Number(
  //       Number(paygridvalues[selectedGrid].Taxamount) +
  //         parseFloat(String(paygridvalues[selectedGrid].policyTaxAmount))
  //     );
  //     setRemaniningAmtStatic(
  //       Number(RemaniningAmtStatic) +
  //         parseFloat(customerCoverageObject.policyAmount)
  //     );
  //     settotaldue(ActiveLdw_Amt);
  //     setremainingamountdue(
  //       Number(RemaniningAmtStatic) +
  //         parseFloat(customerCoverageObject.policyAmount)
  //     ); //change here .tofixed
  //     setAcceptpaymentbuttonenable(true);
  //     resetPayment(undefined);
  //   }
  //   let receiptObj = ReceiptCategoriesRequestParam.filter(
  //     (e) => e.agreementId == paygridvalues[selectedGrid].Agreementid
  //   );
  //   let acceptObj = acceptPaymentParam.filter(
  //     (e) => e.agreementId == paygridvalues[selectedGrid].Agreementid
  //   );
  //   if (receiptObj[0] != undefined) {
  //     receiptObj[0].racTirePay = Number(
  //       paygridvalues[selectedGrid].policyAmount.toFixed(2)
  //     );
  //     receiptObj[0].racTirePayTax = Number(
  //       Number(paygridvalues[selectedGrid].policyTaxAmount).toFixed(2)
  //     );
  //     receiptObj[0].amountDue = Number(
  //       (
  //         paygridvalues[selectedGrid].Amount -
  //         Number(paygridvalues[selectedGrid].lateFee)
  //       ).toFixed(2)
  //     );
  //     receiptObj[0].totalTax = Number(
  //       paygridvalues[selectedGrid].Taxamount.toFixed(2)
  //     );
  //   }
  //   console.log(receiptObj[0], "receipt obj ldw");
  //   if (acceptObj[0] != undefined) {
  //     acceptObj[0].ldwFeeAmount = Number(
  //       paygridvalues[selectedGrid].policyAmount.toFixed(2)
  //     );
  //     acceptObj[0].agreementAmountDue = Number(
  //       (
  //         Number(paygridvalues[selectedGrid].Amount) +
  //         Number(paygridvalues[selectedGrid].suspenseSub)
  //       ).toFixed(2)
  //     );
  //     acceptObj[0].totalTax = Number(
  //       paygridvalues[selectedGrid].Taxamount.toFixed(2)
  //     );
  //   }
  // };

  const Policy_apiCall = async (Num, otherActData?, manualIndex?) => {
    console.log("selectedGrid check logger log", selectedGrid);
    console.log(
      "pay val log chekker",
      paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
    );

    if (actPolicyArr.length > 0) {
      let objLdwAction = actPolicyArr.filter(
        (x) =>
          x.agreementId ==
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          )
      );
      if (objLdwAction[0] != undefined) {
        objLdwAction[0].action = Number(Num);
      } else {
        let ldwParam: ldwReq = {
          agreementId: Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Agreementid
          ),
          action: Number(Num),
          cusCoverageData:
            otherActData?.custCoverageresp !== undefined
              ? otherActData?.custCoverageresp.data
              : otherActState.data,
        };
        actPolicyArr.push(ldwParam);
      }
    } else {
      let ldwParam: ldwReq = {
        agreementId: Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Agreementid
        ),
        action: Number(Num),
        cusCoverageData:
          otherActData?.custCoverageresp !== undefined
            ? otherActData?.custCoverageresp.data
            : otherActState.data,
      };
      actPolicyArr.push(ldwParam);
    }
    /* eslint-disable no-console */
    let objLdWAmount = totalduereceiptid.filter(
      (x) =>
        x.agreement_id ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .AgreementNumber
    );
    console.log(objLdWAmount, "ldw amount log");
    if (Num === 2) {
      setpolicyService_state(1);
      let TerminateLdw_Amt =
        parseFloat(totaldue) -
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      console.log("TerminateLdw_Amt", TerminateLdw_Amt);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount -
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount =
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) -
        parseFloat(
          String(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].policyTaxAmount
          )
        );
      const totaltaxamount =
        totaltax -
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .policyTaxAmount
        );
      settotaltax(totaltaxamount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyAmount = 0;
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyTaxAmount = 0;
      console.log(
        "Amount Due",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount
      );
      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = TerminateLdw_Amt;
      }
      console.log("Reciept Due", objLdWAmount);

      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) -
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      );
      settotaldue(TerminateLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) -
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      ); //change here .tofixed
      setAcceptpaymentbuttonenable(true);
      resetPayment(undefined);
    } else {
      console.log("dcfredfterf9ikmsnvhfeuiwi3otherActData", otherActData);
      console.log(
        "selected grid faatd",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
      );

      setpolicyService_state(2);
      let ActiveLdw_Amt =
        parseFloat(totaldue) +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      console.log("ActiveLdw_Amt", ActiveLdw_Amt);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Amount =
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount +
        parseFloat(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyAmount = parseFloat(
        String(
          Number(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          ) -
            Number(
              otherActData?.resp !== undefined
                ? otherActData?.resp.policyAmount
                : customerCoverageObject.policyAmount
            )
        )
      );
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].policyTaxAmount = parseFloat(
        String(
          otherActData?.resp !== undefined
            ? otherActData?.resp.policyAmount
            : customerCoverageObject.policyAmount
        )
      );
      console.log(
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid],
        otherActData?.resp !== undefined
          ? otherActData?.resp.policyAmount
          : customerCoverageObject.policyAmount,
        "active policy"
      );
      const totaltaxamount =
        totaltax +
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .policyTaxAmount
        );
      settotaltax(totaltaxamount);
      console.log(
        "Amount Due",
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Amount
      );
      if (objLdWAmount[0] != undefined) {
        objLdWAmount[0].agreement_totalamount = ActiveLdw_Amt;
      }
      // console.log("Reciept Due", objLdWAmount[0].agreement_totalamount);
      paygridvalues[
        manualIndex !== undefined ? manualIndex : selectedGrid
      ].Taxamount = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Taxamount
        ) +
          parseFloat(
            String(
              paygridvalues[
                manualIndex !== undefined ? manualIndex : selectedGrid
              ].policyTaxAmount
            )
          )
      );
      setRemaniningAmtStatic(
        Number(RemaniningAmtStatic) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      );
      console.log(
        "7y8rufhbvuyhuiewvhbe2wefyrdfvnfdkjdActiveLdw_Amt",
        ActiveLdw_Amt
      );

      settotaldue(ActiveLdw_Amt);
      setremainingamountdue(
        Number(RemaniningAmtStatic) +
          parseFloat(
            otherActData?.resp !== undefined
              ? otherActData?.resp.policyAmount
              : customerCoverageObject.policyAmount
          )
      ); //change here .tofixed
      setAcceptpaymentbuttonenable(true);
      resetPayment(undefined);
    }
    let receiptObj = ReceiptCategoriesRequestParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    let acceptObj = acceptPaymentParam.filter(
      (e) =>
        e.agreementId ==
        paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
          .Agreementid
    );
    if (receiptObj[0] != undefined) {
      receiptObj[0].racTirePay = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].policyAmount.toFixed(2)
      );
      receiptObj[0].racTirePayTax = Number(
        Number(
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .policyTaxAmount
        ).toFixed(2)
      );
      receiptObj[0].amountDue = Number(
        (
          paygridvalues[manualIndex !== undefined ? manualIndex : selectedGrid]
            .Amount -
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].lateFee
          )
        ).toFixed(2)
      );
      receiptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
    console.log(receiptObj[0], "receipt obj ldw");
    if (acceptObj[0] != undefined) {
      acceptObj[0].ldwFeeAmount = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].policyAmount.toFixed(2)
      );
      acceptObj[0].agreementAmountDue = Number(
        (
          Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].Amount
          ) +
          (Number(
            paygridvalues[
              manualIndex !== undefined ? manualIndex : selectedGrid
            ].acceptEpoFlag
          ) == 1
            ? Number(
                paygridvalues[
                  manualIndex !== undefined ? manualIndex : selectedGrid
                ].suspenseSub
              )
            : 0)
        ).toFixed(2)
      );
      acceptObj[0].totalTax = Number(
        paygridvalues[
          manualIndex !== undefined ? manualIndex : selectedGrid
        ].Taxamount.toFixed(2)
      );
    }
  };
  const ClubApiCall = async () => {
    let paymentScheduleId;
    if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Bi-Weekly"
    ) {
      paymentScheduleId = 1;
    } else if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Weekly"
    ) {
      paymentScheduleId = 2;
    } else if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Monthly"
    ) {
      paymentScheduleId = 3;
    } else if (
      getpayment?.paymentInfo.clubDaysLateAndPaymentSchedule[0]
        .paymentSchedule === "Semi-Monthly"
    ) {
      paymentScheduleId = 4;
    }
    setpaymentSchedule(paymentScheduleId);
    const clubParam: ClubReq = {
      paymentScheduleId: paymentSchedule,
      customerId: customerId,
      isCreate: clubState,
      storeNumber: window.sessionStorage.getItem("storeNumber"),
    };
    let ClubFunc = await ActiveClub(clubParam);
    /* eslint-disable no-console */
    console.log("Club response----->", ClubFunc);
    /* eslint-disable no-console */
    console.log("clubParam", clubParam);
  };
  let unenrollautopayfilterdata = paygridvalues.filter(
    (x) =>
      x.Autopay_enrollmentdate !== "" &&
      x.Autopay_enrollmentdate !== null &&
      x.Autopay_enrollmentdate !== undefined
  );
  let unenrollfalsearray: any = new Array(unenrollautopayfilterdata.length);
  let unerollemptyarray: any = new Array(unenrollautopayfilterdata.length);
  unerollemptyarray.fill(false);
  unenrollfalsearray.fill(false);

  const unenrollautopay = () => {
    if (paygridvalues[0].Phonenumber == "") {
      setphoneNumberRequired(true);
    } else {
      setunenrollautopayopen(true);
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement)
            .checked === true
        ) {
          unenrollautopayagreementids.push(paygridvalues[i].Agreementid);
        }
      }

      for (let i = 0; i < unenrollautopayfilterdata.length; i++) {
        if (
          unenrollautopayagreementids[i] ===
          unenrollautopayfilterdata[i].Agreementid
        ) {
          unenrollfalsearray[i] = true;
        }
      }
      setupdatedarray(unenrollfalsearray);
    }
  };

  useEffect(() => {
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (
      parseFloat(change) > 0.0 &&
      parseFloat(
        String(
          Number(totaldue) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
        )
      ) > 0.0
    ) {
      if (
        parseFloat(change) >=
        parseFloat(
          String(
            Number(totaldue) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
          )
        )
      ) {
        setsuspensehide(true);
      } else {
        setsuspensehide(false);
      }
    } else {
      setsuspensehide(false);
    }
    if (changeRadio === true) {
      setchangeRadio(true);
      setCreditRadio(false);
    } else {
      setchangeRadio(false);
      setCreditRadio(true);
    }

    if (CreditRadio === true) {
      setchangeRadio(false);
      setCreditRadio(true);
    } else {
      setchangeRadio(true);
      setCreditRadio(false);
    }
  }, [change]);

  let getpaymentpayload: GetAllPaymentInfoRequest = {
    customer_id: localStorage.getItem("customerId"),
    AgreementIdCustomerId: [
      {
        agreement_id: 773286343,
        customer_id: localStorage.getItem("customerId"),
      },
      {
        agreement_id: 7656757656,
        customer_id: localStorage.getItem("customerId"),
      },
    ],
    storeNumber: 3451,
    customerZipCode5: 34827,
    customerZip4: 7632673,
    customerState: "czechoslovakia",
    customerDOB: "07/05/2000",
  };

  /*
   ** changes made for -payment history service call
   ** changes made By - Muzzammil
   */

  useEffect(() => {
    /* eslint-disable no-console */
    console.log(" useEffect for get payment history");
    if (
      configFlags.isPaymentHistoryFeatureFlag == 1 &&
      configFlags.isPaymentFunctionsFeatureFlag == 1
    ) {
      getpayHis();
    }
    if (
      configFlags.isCloudStoreSwipeDevicelist == 1 &&
      configFlags.optimizedPayment == 1
    ) {
      swipeDevice_api();
    } else if (
      configFlags.isCloudStoreSwipeDevicelist == 0 &&
      configFlags.optimizedPayment == 1
    ) {
      swipeDevice_api();
    }
  }, [configFlags]);

  const getpayHis = async () => {
    let customerId = customerparamid;
    let getpayhisstate1: Rootobject = await getpaymentHistory(
      customerId,
      "agr", 0
    );
    let selectAllReceipts: any = await getpaymentHistory(customerId, "recp", 0);
    let chechArray: any[];
    chechArray = selectAllReceipts.value.customerPaymentHistory.filter((o2) =>
      o2.tenderType.includes("CHK")
    );
    console.log(chechArray, "check count");
    sethistorydata(chechArray);
    console.log(getpayhisstate1, "entered getpayhis");
    const uniqueAgreementID1 = [
      ...new Set(
        getpayhisstate1.value.paymentHistoryGridInfo.map(
          (data) => data.agreementNumber
        )
      ),
    ];
    const uniqueAgrID = [
      ...new Set(
        getpayhisstate1.value.paymentHistoryGridInfo.map(
          (data) => data.agreementId
        )
      ),
    ];
    console.log(uniqueAgreementID1, "unique id");
    const clubActivityInfo: ClubActivityInfo[] =
      getpayhisstate1.value.clubActivityInfo;
    console.log(clubActivityInfo, "club inventory");
    const ClubGridInfoResp: ClubHistoryGridInfo[] =
      getpayhisstate1.value.clubHistoryGridInfo;
    console.log(ClubGridInfoResp, "club grid");
    let customerInfoobj1: CustomerInfoHis = getpayhisstate1.value.customerInfo;
    console.log(customerInfoobj1, " customent info obj");
    let clubInfoobj: ClubInfo = getpayhisstate1.value.clubInfo;
    console.log(clubInfoobj, " club info obj");
    if (uniqueAgreementID1.length == 0) {
      uniqueAgreementID.push({
        label: "No record",
        value: "123456",
      });
    } else {
      uniqueAgreementID.push({
        label: "All",
        value: "0",
      });
      let indexcount = 1;
      uniqueAgreementID1.map((o1, index) => {
        uniqueAgreementID.push({
          label: o1.toString(),
          value: indexcount.toString(),
          ID: uniqueAgrID[index],
        });
        indexcount++;
      });
    }
    console.log(uniqueAgreementID, "unique id log");
    setClubGridInfoResp([...ClubGridInfoResp]);
    setclubActivityInfo([...clubActivityInfo]);
    setuniqueAgreementID(uniqueAgreementID);
    setcommoninformation(customerInfoobj1);
    setclubinformation(clubInfoobj);
    let paymentHisGrid: any = [[]];
    uniqueAgreementID1.map((o1, index) => {
      paymentHisGrid[index] =
        getpayhisstate1.value.paymentHistoryGridInfo.filter(
          (o2) => o2.agreementNumber === o1
        );
      console.log(paymentHisGrid);
    });
    paymentHisGrid.unshift(selectAllReceipts.value.customerPaymentHistory);
    setpaymentHisGrid(paymentHisGrid);
    console.log(
      paymentHisGrid[0].length,
      ClubGridInfoResp.length,
      "length of his and club"
    );
    if (paymentHisGrid[0].length > 0) {
      setpayhisflag(false);
    }
    if (ClubGridInfoResp.length > 0) {
      setpayclubflag(false);
    }

    if (paymentHisGrid[0].length == 0 && ClubGridInfoResp.length > 0) {
      setpayhisflag(false);
      setpayclubflag(false);
    }
    console.log(payclubflag, "flag after set");
    console.log(uniqueAgreementID, "unique id data");
    console.log(paymentHisGrid, "history data");
  };

  let cashamountarray: any;
  cashamountarray = [];

  const getpaid = async (origin?) => {
    swipeCardDetails = [];
    tendertypeglobalarray = [];
    let calculationModuleRes = calcModuleResponse;
    let lateFeeTaxRate: any = lateFeeTaxRateCus;
    totalduereceiptidsupport = [];
    addrowarray = [];
    agrAmtTaxArray = [];
    showAutoPayPopup = false;
    Clubagreementinsertion = [];
    setpaygridvalues([]);
    paygridvalues = [];
    let getpaymentstate;
    let configConvFeeWithOutTax = 0;
    let configConvFeeTax = 0;
    let configPermissions = {
      commonModuleFlag: 0,
      isAdjustDueDateFeatureFlag: 0,
      isEditScheduleFeatureFlag: 0,
      isCarryRentFeatureFlag: 0,
      isAcceptEpoFeatureFlag: 0,
      isPaymentHistoryFeatureFlag: 0,
      isCashSaleFeatureFlag: 0,
      isPaymentTraingleFeatureFlag: 0,
      isAddApplyTransferSuspenseFeatureFlag: 0,
      isPaymentFunctionsFeatureFlag: 0,
      isActivateClub: 0,
      isRegularFreeTimeAllowed: 0, // Added by me
      isServiceFreeTimeAllowed: 0, // Added by me
      isRegExtFreeTimeAllowed: 0, // Added by me
      isPromoFreeTimeallowed: 0, // Added by me
      raftApiFlag: 0,
      carryWaveLateFee: 0,
      optimizedPayment: 0,
      isCloudSwipeCustomerCard: 0,
      isCloudStoreSwipeDevicelist: 0,
      isRacDbWebLead : 0,
    };
    if (defaultPaygridvalues == undefined) {
      let paymentFunctionFlagValue = 0;
      if (Number(paymentFunctionFlag.payFunc) == 1) {

        const storeNumber =  window.sessionStorage.getItem("storeNumber") 
        const bodyobject = {
          storeNumbers: [String(window.sessionStorage.getItem("storeNumber"))],
          paramKeyNames: [
            "PaymentHistory",
            "AcceptEPO",
            "CarryRent",
            "PaymentFunctions",
            "EditSchedule",
            "AdjustDue",
            "CashSale",
            "PaymentFee",
            "PaymentTriangle",
            "AddApplyTransferSuspense",
            "ActivateClub",
            "RegularFreeTime",
            "ServiceFreeTime",
            "RetentionExtensionFreeTime",
            "PromoFreeTime",
            "RaftApiFlag",
            "CarryWaveLateFee",
            "OptimizedPayment",
            "RevenueRecognition",
            "BackRent",
            "ChargeOffSkipStolen",
            "RefundSecurityDeposit",
            "RentalItemSale",
            "ManualReceipt",
            "VoidCardPayments",
            "NSFPayments",
            "CCCBPayments",
            "Cellphone911Fee_Amount",
            "Cellphone911Fee_Percent",
            "RemoveAmountDue",
            "CommonModulePaymentFlag",
            "CloudSwipeCustomerCard",
            "CloudStoreSwipeDevicelist",
            "AcimaRecovery",
            "OtherSale",
            "XboxGiftCard",
            "XboxMembership",
            "PaymentDonation",
            "RacDbWebLead",
            "PaymentSearchCustomerRetention",
            "CustomerPaymentPageCustomerRetention"
          ],
        };
        let CoworkerNum
        if (storeNumber && storeNumber != null && storeNumber != undefined && storeNumber != ''&& storeNumber != "undefined" && storeNumber != "null" ) {
         CoworkerNum = await CoworkerRequired(bodyobject);
        }
        // if (redirectOrigin === AMOrigin) {
        //   (async () => {
        //     await axios
        //       .get(
        //         `https://6361075067d3b7a0a6bbba6c.mockapi.io/getReversePay/getCommitmentDetails`
        //       )
        //       .then(async (res: any) => {
        //         //setAdjustCommitmentDataStored(res.data);
        //         const filteredClubData = res.data.commitmentDetail.filter(
        //           (obj) => obj.contractTypeId === 2
        //         );
        //         const filteredAgreementData = res.data.commitmentDetail.filter(
        //           (obj) =>
        //             obj.contractTypeId === 1 &&
        //             obj.commitmentTransRefCode !== "WOS" &&
        //             obj.commitmentTransRefCode !== "AEPDA" &&
        //             obj.commitmentTransRefCode !== "AEA" &&
        //             obj.commitmentTransRefCode !== "TAS"
        //         );
        //         const additionalData = res.data.commitmentDetail.filter(
        //           (obj) =>
        //             obj.commitmentTransRefCode === "WOS" ||
        //             obj.commitmentTransRefCode === "AEPDA" ||
        //             obj.commitmentTransRefCode === "AEA" ||
        //             obj.commitmentTransRefCode === "TAS"
        //         );
        //         console.log(
        //           "filteredAgreementData check log",
        //           filteredAgreementData
        //         );
        //         let commitmentStruture: any = [];
        //         let CommitmentClubAgreementStructure: any = [];
        //         if (filteredClubData.length > 0) {
        //           filteredClubData.map((clubObj, clubIndex) => {
        //             if (clubIndex === 0) {
        //               CommitmentClubAgreementStructure.push({
        //                 agreementID: clubObj.commitmentId,
        //                 adjustedInfo: [
        //                   {
        //                     type: clubObj.commitmentTransRefCode,
        //                     value: clubObj.commitmentTransValue,
        //                     priority:
        //                       clubObj.commitmentTransRefCode == "ES"
        //                         ? 1
        //                         : clubObj.commitmentTransRefCode == "DDA"
        //                         ? 2
        //                         : null,
        //                   },
        //                 ],
        //               });
        //             } else {
        //               const isAgreementThere =
        //                 CommitmentClubAgreementStructure.findIndex(
        //                   (el) => el.agreementID == clubObj.commitmentId
        //                 );
        //               CommitmentClubAgreementStructure[
        //                 isAgreementThere
        //               ].adjustedInfo.push({
        //                 type: clubObj.commitmentTransRefCode,
        //                 value: clubObj.commitmentTransValue,
        //                 priority:
        //                   clubObj.commitmentTransRefCode == "ES"
        //                     ? 1
        //                     : clubObj.commitmentTransRefCode == "DDA"
        //                     ? 2
        //                     : null,
        //               });
        //             }
        //           });
        //         }

        //         filteredAgreementData.map((obj, index) => {
        //           if (index === 0) {
        //             if (obj.commitmentTransRefCode === "FTT") {
        //               const waiveFilter = additionalData.filter(
        //                 (obj) => obj.commitmentTransRefCode === "WOS"
        //               );
        //               commitmentStruture.push({
        //                 agreementID: obj.commitmentId,
        //                 adjustedInfo: [
        //                   {
        //                     type: obj.commitmentTransRefCode,
        //                     value: {
        //                       nextDueDate: obj.nextDueDate,
        //                       freeTimeType: obj.commitmentTransValue,
        //                       waiveOptService:
        //                         waiveFilter[0].commitmentTransValue,
        //                     },
        //                     priority: 4,
        //                   },
        //                 ],
        //               });
        //             } else if (obj.commitmentTransRefCode === "AEF") {
        //               const pastDueAmountFilter = additionalData.filter(
        //                 (obj) => obj.commitmentTransRefCode === "AEPDA"
        //               );
        //               const acceptEpoAmountFilter = additionalData.filter(
        //                 (obj) => obj.commitmentTransRefCode === "AEA"
        //               );
        //               commitmentStruture.push({
        //                 agreementID: obj.commitmentId,
        //                 adjustedInfo: [
        //                   {
        //                     type: obj.commitmentTransRefCode,
        //                     value: {
        //                       pastDueRent:
        //                         pastDueAmountFilter[0].commitmentTransValue,
        //                       epoAmount:
        //                         acceptEpoAmountFilter[0].commitmentTransValue,
        //                     },
        //                     priority: 3,
        //                   },
        //                 ],
        //               });
        //             } else if (obj.commitmentTransRefCode === "TUS") {
        //               const transferAddFilter = additionalData.filter(
        //                 (obj) => obj.commitmentTransRefCode === "TAS"
        //               );
        //               let transferData: any = [];
        //               transferAddFilter.map((transObj) => {
        //                 transferData.push({
        //                   agreementID: transObj.commitmentId,
        //                   transferType: transObj.commitmentTransRefCode,
        //                   value: transObj.commitmentTransValue,
        //                 });
        //               });
        //               commitmentStruture.push({
        //                 agreementID: obj.commitmentId,
        //                 adjustedInfo: [
        //                   {
        //                     type: obj.commitmentTransRefCode,
        //                     value: [
        //                       ...transferData,
        //                       {
        //                         agreementID: obj.commitmentId,
        //                         transferType: obj.commitmentTransRefCode,
        //                         value: obj.commitmentTransValue,
        //                       },
        //                     ],
        //                   },
        //                 ],
        //               });
        //             } else {
        //               commitmentStruture.push({
        //                 agreementID: obj.commitmentId,
        //                 adjustedInfo: [
        //                   {
        //                     type: obj.commitmentTransRefCode,
        //                     value: obj.commitmentTransValue,
        //                     priority:
        //                       obj.commitmentTransRefCode == "TOS" ||
        //                       obj.commitmentTransRefCode == "AOS"
        //                         ? 1
        //                         : obj.commitmentTransRefCode == "ES"
        //                         ? 2
        //                         : obj.commitmentTransRefCode == "AEF"
        //                         ? 3
        //                         : obj.commitmentTransRefCode == "FTT" ||
        //                           obj.commitmentTransRefCode == "DDA"
        //                         ? 4
        //                         : obj.commitmentTransRefCode == "AS" ||
        //                           obj.commitmentTransRefCode == "US" ||
        //                           obj.commitmentTransRefCode == "TAS" ||
        //                           obj.commitmentTransRefCode == "TUS"
        //                         ? 5
        //                         : null,
        //                   },
        //                 ],
        //               });
        //             }
        //           } else {
        //             const isAgreementThere = commitmentStruture.findIndex(
        //               (el) => el.agreementID == obj.commitmentId
        //             );
        //             if (isAgreementThere >= 0) {
        //               if (obj.commitmentTransRefCode === "FTT") {
        //                 const waiveFilter = additionalData.filter(
        //                   (obj) => obj.commitmentTransRefCode === "WOS"
        //                 );
        //                 commitmentStruture[isAgreementThere].adjustedInfo.push({
        //                   type: obj.commitmentTransRefCode,
        //                   value: {
        //                     nextDueDate: obj.nextDueDate,
        //                     freeTimeType: obj.commitmentTransValue,
        //                     waiveOptService:
        //                       waiveFilter[0].commitmentTransValue,
        //                   },
        //                   priority: 4,
        //                 });
        //               } else if (obj.commitmentTransRefCode === "AEF") {
        //                 const pastDueAmountFilter = additionalData.filter(
        //                   (obj) => obj.commitmentTransRefCode === "AEPDA"
        //                 );
        //                 const acceptEpoAmountFilter = additionalData.filter(
        //                   (obj) => obj.commitmentTransRefCode === "AEA"
        //                 );
        //                 commitmentStruture[isAgreementThere].adjustedInfo.push({
        //                   type: obj.commitmentTransRefCode,
        //                   value: {
        //                     pastDueRent:
        //                       pastDueAmountFilter[0].commitmentTransValue,
        //                     epoAmount:
        //                       acceptEpoAmountFilter[0].commitmentTransValue,
        //                   },
        //                   priority: 3,
        //                 });
        //               } else {
        //                 commitmentStruture[isAgreementThere].adjustedInfo.push({
        //                   type: obj.commitmentTransRefCode,
        //                   value: obj.commitmentTransValue,
        //                   priority:
        //                     obj.commitmentTransRefCode == "TOS" ||
        //                     obj.commitmentTransRefCode == "AOS"
        //                       ? 1
        //                       : obj.commitmentTransRefCode == "ES"
        //                       ? 2
        //                       : obj.commitmentTransRefCode == "AEF"
        //                       ? 3
        //                       : obj.commitmentTransRefCode == "FTT" ||
        //                         obj.commitmentTransRefCode == "DDA"
        //                       ? 4
        //                       : obj.commitmentTransRefCode == "AS" ||
        //                         obj.commitmentTransRefCode == "US" ||
        //                         obj.commitmentTransRefCode == "TAS" ||
        //                         obj.commitmentTransRefCode == "TUS"
        //                       ? 5
        //                       : null,
        //                 });
        //               }
        //             } else {
        //               if (obj.commitmentTransRefCode === "FTT") {
        //                 const waiveFilter = additionalData.filter(
        //                   (obj) => obj.commitmentTransRefCode === "WOS"
        //                 );
        //                 //   console.log('waiveFilter value', waiveFilter);
        //                 commitmentStruture.push({
        //                   agreementID: obj.commitmentId,
        //                   adjustedInfo: [
        //                     {
        //                       type: obj.commitmentTransRefCode,
        //                       value: {
        //                         nextDueDate: obj.nextDueDate,
        //                         freeTimeType: obj.commitmentTransValue,
        //                         waiveOptService:
        //                           waiveFilter[0].commitmentTransValue,
        //                       },
        //                       priority: 4,
        //                     },
        //                   ],
        //                 });
        //               } else if (obj.commitmentTransRefCode === "AEF") {
        //                 const pastDueAmountFilter = additionalData.filter(
        //                   (obj) => obj.commitmentTransRefCode === "AEPDA"
        //                 );
        //                 const acceptEpoAmountFilter = additionalData.filter(
        //                   (obj) => obj.commitmentTransRefCode === "AEA"
        //                 );
        //                 commitmentStruture.push({
        //                   agreementID: obj.commitmentId,
        //                   adjustedInfo: [
        //                     {
        //                       type: obj.commitmentTransRefCode,
        //                       value: {
        //                         pastDueRent:
        //                           pastDueAmountFilter[0].commitmentTransValue,
        //                         epoAmount:
        //                           acceptEpoAmountFilter[0].commitmentTransValue,
        //                       },
        //                       priority: 3,
        //                     },
        //                   ],
        //                 });
        //               } else if (obj.commitmentTransRefCode === "TUS") {
        //                 const transferAddFilter = additionalData.filter(
        //                   (obj) => obj.commitmentTransRefCode === "TAS"
        //                 );
        //                 let transferData: any = [];
        //                 transferAddFilter.map((transObj) => {
        //                   transferData.push({
        //                     agreementID: transObj.commitmentId,
        //                     transferType: transObj.commitmentTransRefCode,
        //                     value: transObj.commitmentTransValue,
        //                   });
        //                 });
        //                 commitmentStruture.push({
        //                   agreementID: obj.commitmentId,
        //                   adjustedInfo: [
        //                     {
        //                       type: obj.commitmentTransRefCode,
        //                       value: [
        //                         ...transferData,
        //                         {
        //                           agreementID: obj.commitmentId,
        //                           transferType: obj.commitmentTransRefCode,
        //                           value: obj.commitmentTransValue,
        //                         },
        //                       ],
        //                     },
        //                   ],
        //                 });
        //               } else {
        //                 commitmentStruture.push({
        //                   agreementID: obj.commitmentId,
        //                   adjustedInfo: [
        //                     {
        //                       type: obj.commitmentTransRefCode,
        //                       value: obj.commitmentTransValue,
        //                       priority:
        //                         obj.commitmentTransRefCode == "TOS" ||
        //                         obj.commitmentTransRefCode == "AOS"
        //                           ? 1
        //                           : obj.commitmentTransRefCode == "ES"
        //                           ? 2
        //                           : obj.commitmentTransRefCode == "AEF"
        //                           ? 3
        //                           : obj.commitmentTransRefCode == "FTT" ||
        //                             obj.commitmentTransRefCode == "DDA"
        //                           ? 4
        //                           : obj.commitmentTransRefCode == "AS" ||
        //                             obj.commitmentTransRefCode == "US" ||
        //                             obj.commitmentTransRefCode == "TAS" ||
        //                             obj.commitmentTransRefCode == "TUS"
        //                           ? 5
        //                           : null,
        //                     },
        //                   ],
        //                 });
        //               }
        //             }
        //           }
        //         });
        //         adjustCommitmentData = {
        //           commitmentDetail: commitmentStruture,
        //           CommitmentClubAgreement:
        //             CommitmentClubAgreementStructure.length > 0
        //               ? CommitmentClubAgreementStructure
        //               : null,
        //         };
        //         // setCommitmentPayTringle(res.data);
        //         console.log("response from the API hit", res.data);
        //       });
        //   })();
        // }
        if (
          CoworkerNum !== undefined ||
          (null &&
            CoworkerNum.storeProfileResponse !== undefined &&
            CoworkerNum.storeProfileResponse &&
            CoworkerNum.storeProfileResponse.configDetails !== undefined &&
            CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
              undefined &&
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails
              .length > 0)
        ) {
          for (
            let i = 0;
            i <
            CoworkerNum.storeProfileResponse.configDetails[0].configDetails
              .length;
            i++
          ) {
            const configArray =
              CoworkerNum.storeProfileResponse.configDetails[0].configDetails;
            if (configArray[i].paramKeyName == "CashSale") {
              configPermissions.isCashSaleFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PaymentHistory") {
              configPermissions.isPaymentHistoryFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "AcceptEPO") {
              configPermissions.isAcceptEpoFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CarryRent") {
              configPermissions.isCarryRentFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "EditSchedule") {
              configPermissions.isEditScheduleFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "AdjustDue") {
              configPermissions.isAdjustDueDateFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PaymentTriangle") {
              configPermissions.isPaymentTraingleFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "ActivateClub") {
              configPermissions.isActivateClub = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "AddApplyTransferSuspense") {
              configPermissions.isAddApplyTransferSuspenseFeatureFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "PaymentFunctions") {
              configPermissions.isPaymentFunctionsFeatureFlag = Number(
                configArray[i].paramValue
              );
              paymentFunctionFlagValue = Number(configArray[i].paramValue);
            }
            if (configArray[i].paramKeyName == "RegularFreeTime") {
              configPermissions.isRegularFreeTimeAllowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "ServiceFreeTime") {
              configPermissions.isServiceFreeTimeAllowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "RetentionExtensionFreeTime") {
              configPermissions.isRegExtFreeTimeAllowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "PromoFreeTime") {
              configPermissions.isPromoFreeTimeallowed = Number(
                configArray[i].paramValue
              );
            } // Added by me
            if (configArray[i].paramKeyName == "PaymentFee") {
              configConvFeeWithOutTax = Number(configArray[i].paramValue);
              setConvFeeWithOutTax(configArray[i].paramValue);
            }
            if (configArray[i].paramKeyName == "RaftApiFlag") {
              configPermissions.raftApiFlag = Number(configArray[i].paramValue);
            }
            if (configArray[i].paramKeyName == "CarryWaveLateFee") {
              configPermissions.carryWaveLateFee = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "OptimizedPayment") {
              configPermissions.optimizedPayment = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CommonModulePaymentFlag") {
              configPermissions.commonModuleFlag = Number(
                configArray[i].paramValue
              );
            }
            if (configArray[i].paramKeyName == "CloudSwipeCustomerCard") {
              configPermissions.isCloudSwipeCustomerCard = Number(
                configArray[i].paramValue
              );
              // setIsCloudSwipeCustomerCard(Number(configArray[i].paramValue));
            }
            if (configArray[i].paramKeyName == "CloudStoreSwipeDevicelist") {
              configPermissions.isCloudStoreSwipeDevicelist = Number(
                configArray[i].paramValue
              );
              // setIsCloudStoreSwipeDevicelist(Number(configArray[i].paramValue));
            }
            if (configArray[i].paramKeyName == "RacDbWebLead") {
              configPermissions.isRacDbWebLead = Number(
                configArray[i].paramValue
              );
            }
          }
        }
      } else {
        paymentFunctionFlagValue = 0;
        configPermissions.isCashSaleFeatureFlag = Number(
          paymentFunctionFlag.isCashSale
        );
        configConvFeeWithOutTax = Number(paymentFunctionFlag.convFeeWithOutTax);
        setConvFeeWithOutTax(paymentFunctionFlag.convFeeWithOutTax);
      }

      setconfigFlags(configPermissions);
      let result: any = undefined;
      let customerPrimaryAddress: any = "";
      let customerSecondaryAddress: any = "";
      let customerCity = "";
      let customerdob = "";
      let customerPhoneNumber = "";
      let customerEmailAdress = "";
      let customerFname = "";
      let customerLname = "";
      let customerPlusFour = "";
      let customerZip = "";
      let customerState = "";

      const customer_id: any = customerId;
      const Storenumber: any = String(
        window.sessionStorage.getItem("storeNumber")
      );
      result = await GetCustomerByIdInfo(customer_id, Storenumber);
      setcustomerdata(result);
      if (result != undefined && result != "") {
        // debugger;
        result = result.data;
        customerverificationStatus = result?.GetApproval?.value?.customerApprovalDetails?.verificationStatus

        if (result.GetCustomer.value.addresses != undefined) {
          setCustomerInformation(result.GetCustomer.value);
          let customerAdressFilterArray: any =
            result.GetCustomer.value.addresses?.filter(
              (x) => x.addressType == "PRIM"
            );
          if (
            customerAdressFilterArray?.length == 0 &&
            result.GetCustomer.value.addresses?.length > 0
          ) {
            customerAdressFilterArray = [result.GetCustomer.value.addresses[0]];
          }
          customerPrimaryAddress =
            customerAdressFilterArray?.length > 0
              ? customerAdressFilterArray[0].addressLine1
              : "";
          customerSecondaryAddress =
            customerAdressFilterArray?.length > 0
              ? customerAdressFilterArray[0].addressLine2
              : "";
          customerPlusFour =
            customerAdressFilterArray?.length > 0
              ? customerAdressFilterArray[0].postalCode?.split("-")[1]
              : "";
          customerZip =
            customerAdressFilterArray?.length > 0
              ? customerAdressFilterArray[0].postalCode?.split("-")[0]
              : "";
          customerState =
            customerAdressFilterArray?.length > 0
              ? customerAdressFilterArray[0].state
              : "";
          customerCity =
            customerAdressFilterArray?.length > 0
              ? customerAdressFilterArray[0].city
              : "";
        }
        customerdob = result.GetCustomer.value.dateOfBirth;
        let customerPhoneDetails: any = result.GetCustomer.value.phones?.filter(
          (x) => {
            x.primary == "Y";
          }
        );
        if (
          customerPhoneDetails?.length == 0 &&
          result.GetCustomer.value?.phones?.length > 0
        ) {
          customerPhoneDetails = [result.GetCustomer.value.phones[0]];
        } else {
          customerPhoneDetails = [];
        }
        customerPhoneNumber =
          customerPhoneDetails?.length > 0
            ? customerPhoneDetails[0].phoneNumber
            : "";
        customerEmailAdress = result.GetCustomer.value.emailAddress;
        customerFname = result.GetCustomer.value.firstName;
        customerLname = result.GetCustomer.value.lastName;
        //chargeCard and NewCard Details pre-fill
        setCustomerInfopreFill({
          ...customerInfopreFill,
          customerPrimaryAddress: customerPrimaryAddress,
          customerSecondaryAddress: customerSecondaryAddress,
          customerZip: customerZip,
          customerCity: customerCity,
          customerState: customerState,
          name: customerFname + " " + customerLname,
        });
      }

      pageload();
      console.log(
        "UserStateContext, UserThunksContext ",
        UserStateContext,
        UserThunksContext
      );
      if (paymentFunctionFlagValue == 1) {
        getpaymentstate = await getpaymentinfo({
          customerID: customerId,
          agreementID: [agreementId],
          commonModuleFlag: configPermissions.commonModuleFlag,
        });
        if (configPermissions.commonModuleFlag == 1) {
          calculationModuleRes = getpaymentstate.commonModuleRes;
          // calculationModuleRes = calculationModuleRes?.data
          console.log(
            "calculationModuleRes",
            calculationModuleRes,
            getpaymentstate
          );
          setCalcModuleResponse(calculationModuleRes);
          if(calculationModuleRes){
            let convFee = calculationModuleRes?.customerFees?.filter(
              (x) => x.itemCategory == "CONVENFEE"
            );
            if(convFee?.length>0){
              configConvFeeWithOutTax = Number(convFee[0].amount);
              setConvFeeWithOutTax(convFee[0].amount);
            }

          }
        }
      } else {
        const cusDob = moment(customerdob).format("MM/DD/YYYY");
        getpaymentstate = {
          paymentInfo: {
            paymentInfo: [],
            paymentOrigin: [],
            clubInformation: [],
            deliveryInformation: [],
            numberOfPaymentsMadeInfo: [],
            remainingCredits: [],
            documentSignStatus: [],
            paymentHistoryInfo: [],
            customerInfo: [
              {
                addressLine1: customerPrimaryAddress,
                addressLine2: customerSecondaryAddress,
                city: customerCity,
                dob: cusDob,
                emailAddress: customerEmailAdress,
                entPartyId: null,
                firstName: customerFname,
                lastName: customerLname,
                phoneNumber: customerPhoneNumber,
                plus4: customerPlusFour,
                salesLead: null,
                state: customerState,
                totalPaymentsMade: 0,
                verified: "Y",
                verifiedDate: null,
                zip: customerZip,
              },
            ],
            promoFreeDays: [],
            clubDaysLateAndPaymentSchedule: [],
            clubPromoInfo: [],
            clubInfoResponse: [],
            lastLate10GraphInfo: [],
            cashSaleInfo: null,
          },
          amountDue: { amountDueResponse: [] },
          taxTotal: [],
          paymentScheule: [],
          balance: [],
        };
      }

      if (configPermissions.isRacDbWebLead == 1) {
        getpaymentstate.balance =
          getpaymentstate.paymentInfo.remainingCredits.filter(
            (x) =>
              String(x.storeNumber) ==
                String(window.sessionStorage.getItem("storeNumber")) &&
              x.bucket == "WL Deposit"
          );

        for (let i = 0; i < getpaymentstate.balance?.length; i++) {
          getpaymentstate.balance[i].balanceAmount =
            getpaymentstate.balance[i].accountBalance;
          getpaymentstate.balance[i].balanceType = "WEBLEAD";
        }

        if (getpaymentstate.balance?.length > 1) {
          let balanceSumAmount = 0;
          for (let i = 0; i < getpaymentstate.balance?.length; i++) {
            balanceSumAmount =
              balanceSumAmount +
              Number(getpaymentstate.balance[i].balanceAmount);
          }
          getpaymentstate.balance = [
            {
              agreementId: getpaymentstate.balance[0].agreementId,
              balanceAmount: Number(Number(balanceSumAmount).toFixed(2)),
              balanceType: getpaymentstate.balance[0].balanceType,
              storeNumber: getpaymentstate.balance[0].storeNumber,
            },
          ];
        }
      } else {
      getpaymentstate.balance = getpaymentstate.balance.filter(
        (x) =>
          String(x.storeNumber) ==
            String(window.sessionStorage.getItem("storeNumber")) &&
          x.balanceType == "WEBLEAD"
      );
      if (getpaymentstate.balance?.length > 1) {
        let balanceSumAmount = 0;
        for (let i = 0; i < getpaymentstate.balance?.length; i++) {
          balanceSumAmount =
            balanceSumAmount + Number(getpaymentstate.balance[i].balanceAmount);
        }
        getpaymentstate.balance = [
          {
            agreementId: getpaymentstate.balance[0].agreementId,
            balanceAmount: Number(Number(balanceSumAmount).toFixed(2)),
            balanceType: getpaymentstate.balance[0].balanceType,
            balanceTypeDesc: getpaymentstate.balance[0].balanceTypeDesc,
            entPartyAccountId: getpaymentstate.balance[0].entPartyAccountId,
            partyId: getpaymentstate.balance[0].partyId,
            promoFreeTime: getpaymentstate.balance[0].promoFreeTime,
            racdbCustomerId: getpaymentstate.balance[0].racdbCustomerId,
            racdbCustometAccountId:
              getpaymentstate.balance[0].racdbCustometAccountId,
            storeNumber: getpaymentstate.balance[0].storeNumber,
          },
        ];
      }
    }
      //getpaymentstate.paymentInfo.remainingCredits=getpaymentstate.paymentInfo.remainingCredits.filter((x)=>String(x.storeNumber)== String(window.sessionStorage.getItem("storeNumber")))
      if (
        getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId !=
          null &&
        getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId != "" &&
        getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId !=
          undefined
      ) {
        setCustomerGCID(
          getpaymentstate?.paymentInfo?.customerInfo[0]?.globalCustomerId
        );
      }
      let objChartData = getpaymentstate.paymentInfo.lastLate10GraphInfo;
      console.log(objChartData, "chart data");
      setchartdata(objChartData);
      //phone conv Fee
      if (Number(configConvFeeWithOutTax) != 0) {
        let zip5 = "";
        let zip4 = "";
        let taxExemptAsOfToday = "";
        let postalZip =
          getpaymentstate?.paymentInfo?.customerInfo[0]?.zip == null
            ? null
            : getpaymentstate?.paymentInfo?.customerInfo[0]?.zip?.replace(
                /-/g,
                ""
              );

        let JsonsessionStoreDetails: any =
          window.sessionStorage.getItem("storeDetails");
        console.log(JsonsessionStoreDetails, "sessionStoreDetails1");
        // console.log(atob(JsonsessionStoreDetails),"sessionStoreDetails2")
        let sessionStoreDetails: any;
        try {
          sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
        } catch (e: any) {
          console.log(e, "not json formate");
        }

      
        console.log(sessionStoreDetails, "sessionStoreDetails2");
        if (
          postalZip?.length != 5 ||
          postalZip == undefined ||
          postalZip == null
        ) {
          zip5 = sessionStoreDetails?.zip?.split("-")[0];
          zip4 = sessionStoreDetails?.zip?.split("-")[1];
          if (zip4?.length != 4) {
            zip4 = "0000";
          }
        } else {
          zip5 = getpaymentstate.paymentInfo?.customerInfo[0]?.zip;
          if (
            getpaymentstate.paymentInfo?.customerInfo[0]?.plus4?.length == 4
          ) {
            zip4 = getpaymentstate.paymentInfo?.customerInfo[0]?.plus4;
          } else {
            zip4 = "0000";
          }
        }
        if (
          getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted !=
            undefined &&
          getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted != null &&
          getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted != ""
        ) {
          taxExemptAsOfToday =
            getpaymentstate.paymentInfo.customerInfo[0].isTaxExempted;
          setTaxExemption(taxExemptAsOfToday);
        }
        const convFeeTaxReq = {
          taxInputRequest: [
            {
              receiptItemCategory: "CONVENFEE",
              amount: String(configConvFeeWithOutTax),
              taxInputId: 32,
            },
            {
              receiptItemCategory: "LTRPAYFEE",
              amount: String(10.0),
              taxInputId: 33,
            },
          ],
          storeNumber: String(window.sessionStorage.getItem("storeNumber")),
          customerZipCode5:
            zip5 == "" ? getpaymentstate.paymentInfo.customerInfo[0].zip : zip5,
          customerZip4:
            zip4 == ""
              ? getpaymentstate.paymentInfo.customerInfo[0].plus4
              : zip4,
          customerState: getpaymentstate.paymentInfo.customerInfo[0].state,
          taxExemptAsOfToday:
            taxExemptAsOfToday == ""
              ? false
              : taxExemptAsOfToday == "N"
              ? false
              : true,
          customerDOB: getpaymentstate.paymentInfo.customerInfo[0].dob,
        };

        const formedTaxEnginePayload: any = {
          customerState: getpaymentstate.paymentInfo.customerInfo[0].state,
          postalCode: zip5 + "-" + zip4,
          customerId: customerId,
          apiCall: "",
          dateOfBirth: moment(
            getpaymentstate.paymentInfo.customerInfo[0].dob
          ).format("YYYY-MM-DD"),
          taxRateOnly: "N",
          taxExemptAsOfToday:
            taxExemptAsOfToday == "" ? "N" : taxExemptAsOfToday,
        };
        let taxInputsData: any = [];
        taxInputsData.push(
          {
            amount: String(configConvFeeWithOutTax),
            taxInputId: "32",
            itemCategory: "CONVENFEE",
          },
          {
            amount: String(10.0),
            taxInputId: "33",
            itemCategory: "LTRPAYFEE",
          }
        );
        formedTaxEnginePayload.storeInputs = [
          {
            taxInputs: taxInputsData,
            storeNumber: String(window.sessionStorage.getItem("storeNumber")),
          },
        ];
        if (configPermissions.optimizedPayment == 0) {
          const taxServiceRes = await GetTaxRateConv(convFeeTaxReq);
          const taxRateRes =
            taxServiceRes?.data?.TaxCalculatorRespone?.taxOutPutResponse?.filter(
              (obj) => obj.taxInputId == 32
            );
          lateFeeTaxRate =
            taxServiceRes?.data?.TaxCalculatorRespone?.taxOutPutResponse?.filter(
              (obj) => obj.taxInputId == 33
            );
          setLateFeeTaxRateCus(lateFeeTaxRate);

          if (taxRateRes?.length > 0) {
            configConvFeeTax = Number(taxRateRes[0]?.taxAmount);
            setconfigFeeOnlyTax(configConvFeeTax);
          }
        } else if (configPermissions.optimizedPayment == 1) {
          const taxServiceRes = await getTax(formedTaxEnginePayload);
          console.log("taxServiceRes", taxServiceRes);
          const taxRateRes =
            taxServiceRes?.data?.taxResponse[0]?.taxOutputs?.filter(
              (obj) => obj.taxOutputId == "32"
            );
          lateFeeTaxRate =
            taxServiceRes?.data?.taxResponse[0]?.taxOutputs?.filter(
              (obj) => obj.taxOutputId == "33"
            );
          setLateFeeTaxRateCus(lateFeeTaxRate);
          console.log("lateFeeTaxRate", lateFeeTaxRate);

          if (taxRateRes?.length > 0) {
            configConvFeeTax = Number(taxRateRes[0]?.taxAmount);
            setconfigFeeOnlyTax(configConvFeeTax);
          }
          console.log("configConvFeeTax", configConvFeeTax);
        }
      } else {
        setconfigFeeOnlyTax(configConvFeeTax);
      }
      if (getpaymentstate != undefined && getpaymentstate.length != 0) {
        let paymentScheduleResp =
          getpaymentstate.paymentScheule?.references.filter(
            (x: any) => x.referenceKey == "PAYMENT_SCHEDULE"
          );
        setpaymentEditScheduleDropDown(paymentScheduleResp);
      }
      setdefaultPaygridvalues(getpaymentstate);
    } else {
      getpaymentstate = defaultPaygridvalues;
      configConvFeeWithOutTax = ConvFeeWithOutTax;
      configConvFeeTax = configFeeOnlyTax;
      calculationModuleRes = calcModuleResponse;
      configPermissions = configFlags;
    }
    //Common Module
    if (configPermissions.commonModuleFlag == 1) {
      let amountDueResponseCalculateModule: any = [];
      if (calculationModuleRes) {
        for (
          let i = 0;
          i < getpaymentstate?.paymentInfo?.paymentInfo?.length;
          i++
        ) {
          let calculatepaymentStoreRes =
            calculationModuleRes?.paymentDetails?.filter(
              (x) =>
                x.storeNumber ==
                getpaymentstate?.paymentInfo?.paymentInfo[i]?.storeNumber
            );

          if (calculatepaymentStoreRes?.length > 0) {
            // Agr array push

            let calculatePaymentResAgr =
              calculatepaymentStoreRes[0]?.agreementPaymentDetails?.filter(
                (x) =>
                  x.agreementId ==
                  getpaymentstate?.paymentInfo?.paymentInfo[i].agreementId
              );

            if (calculatePaymentResAgr?.length > 0) {
              let getPaymentinfoRes =
                getpaymentstate?.paymentInfo?.paymentInfo[i];

              let lateFee = calculatePaymentResAgr[0].amountDetails?.filter(
                (x) => x.itemCategory == "LTRPAYFEE"
              );
              let pastDueRent = calculatePaymentResAgr[0].amountDetails?.filter(
                (x) => x.itemCategory == "LTRPAY"
              );

              let policyAmount =
                calculatePaymentResAgr[0].amountDetails?.filter(
                  (x) => x.itemCategory == "RACTIREPAY"
                );

              let initialPaymentInfo =
                calculatePaymentResAgr[0].amountDetails?.filter(
                  (x) => x.itemCategory == "INITPAY"
                );

              let ldw = calculatePaymentResAgr[0].amountDetails?.filter(
                (x) => x.itemCategory == "LDW" || x.itemCategory == "LTLDW"
              );

              let convFee = calculationModuleRes?.customerFees?.filter(
                (x) => x.itemCategory == "CONVENFEE"
              );

              let rpayTaxAmount =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) =>
                    x.itemCategory == "RPAY" ||
                    x.itemCategory == "LTRPAY" ||
                    x.itemCategory == "INITPAY"
                );

              let extensionAmount =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "EXTAMT"
                );

              let deliveryFee =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "DELIVFEE"
                );

              let processingFee =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "PROCESSINGFEE"
                );

              let mattressfee =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "MATTRESSFEE"
                );

              // let getAmountDueAgrRes =
              //   getpaymentstate?.amountDue?.amountDueResponse?.filter(
              //     (x) => x.agreementId == calculatePaymentResAgr[0].agreementId,
              //   );

              let getAMountDueObj = {
                amountDue:
                  calculatePaymentResAgr[0]?.scheduleRate == undefined
                    ? "0.00"
                    : calculatePaymentResAgr[0]?.scheduleRate,

                amountDueLateRent:
                  pastDueRent?.length > 0 ? pastDueRent[0]?.amount : "0.00",

                ldwAmount: ldw?.length > 0 ? ldw[0].amount : "0.00",

                ldwLateRent: ldw?.length > 0 ? ldw[0].amount : "0.00",

                lateFee:
                  lateFee[0]?.amount == undefined ? "0.00" : lateFee[0]?.amount,

                lFeeTaxAmount:
                  lateFee[0]?.amount == undefined ? "0.00" : lateFee[0]?.tax,

                pastDueRent:
                  calculatePaymentResAgr[0]?.totalPastDueAmount == undefined
                    ? "0.00"
                    : calculatePaymentResAgr[0]?.totalPastDueAmount,

                policyAmount:
                  policyAmount[0]?.amount == undefined
                    ? "0.00"
                    : policyAmount[0]?.amount,

                policyLateRent:
                  policyAmount[0]?.amount == undefined
                    ? "0.00"
                    : policyAmount[0]?.amount,

                policyTaxAmount:
                  policyAmount[0]?.tax == undefined
                    ? "0.00"
                    : policyAmount[0]?.tax,

                isSwitchoutDeliveryPending: "0", //--getPaymentInfo

                agreementId: calculatePaymentResAgr[0]?.agreementId,

                agreementStatus: calculatePaymentResAgr[0]?.agreementStatus,

                coPaymentAmount: 0,

                coWebleadUsed: 0,

                nextDueDate:
                  calculatePaymentResAgr[0]?.isFinalPayment != undefined &&
                  calculatePaymentResAgr[0]?.isFinalPayment != ""
                    ? calculatePaymentResAgr[0]?.isFinalPayment
                    : calculatePaymentResAgr[0]?.nextDueDate != "" ||
                      calculatePaymentResAgr[0]?.nextDueDate != "SAC" ||
                      calculatePaymentResAgr[0]?.nextDueDate != "PIF" ||
                      calculatePaymentResAgr[0]?.nextDueDate != "EPO" ||
                      calculatePaymentResAgr[0]?.nextDueDate != null
                    ? moment(calculatePaymentResAgr[0]?.nextDueDate).format(
                        "MM/DD/YYYY"
                      )
                    : calculatePaymentResAgr[0]?.nextDueDate,

                pastDueDate:
                  calculatePaymentResAgr[0]?.currentDueDate != "" ||
                  calculatePaymentResAgr[0]?.currentDueDate != "SAC" ||
                  calculatePaymentResAgr[0]?.currentDueDate != "PIF" ||
                  calculatePaymentResAgr[0]?.currentDueDate != "EPO" ||
                  calculatePaymentResAgr[0]?.currentDueDate != null
                    ? moment(calculatePaymentResAgr[0]?.currentDueDate).format(
                        "MM/DD/YYYY"
                      )
                    : calculatePaymentResAgr[0]?.currentDueDate,

                epoAmount: (
                  Number(
                    calculatePaymentResAgr[0]?.agreementRateDetails
                      .estimatedEpoAmountIncTax
                  ) - Number(calculatePaymentResAgr[0]?.suspenseBalance)
                ).toFixed(2), //--Need to Add

                agreementType: calculatePaymentResAgr[0]?.agreementType,

                initialPaymentFlag: initialPaymentInfo?.length > 0 ? 1 : 0,

                extensionAmont:
                  extensionAmount?.length == 0
                    ? "0.00"
                    : extensionAmount[0]?.amount, //

                extensionAmontTax:
                  extensionAmount?.length == 0
                    ? "0.00"
                    : extensionAmount[0]?.tax,

                deliveryAmount:
                  deliveryFee?.length == 0 ? "0.00" : deliveryFee[0]?.amount,

                deliveryAmountTax:
                  deliveryFee?.length == 0 ? "0.00" : deliveryFee[0]?.tax,

                processingFee:
                  processingFee?.length == 0
                    ? "0.00"
                    : processingFee[0]?.amount,

                processingFeeTax:
                  processingFee?.length == 0 ? "0.00" : processingFee[0]?.tax,

                mattressFee:
                  mattressfee?.length == 0 ? "0.00" : mattressfee[0]?.amount,

                mattressFeeTax:
                  mattressfee?.length == 0 ? "0.00" : mattressfee[0]?.tax,

                totalLateAmount: calculatePaymentResAgr[0]?.totalAmount,

                totalLateTax: calculatePaymentResAgr[0]?.totalTax,

                initialPaymentTotal: (
                  Number(calculatePaymentResAgr[0]?.totalAmount) +
                  Number(calculatePaymentResAgr[0]?.totalTax)
                ).toFixed(2),

                rpayTaxAmount: rpayTaxAmount?.length,

                ldwTaxAmount: ldw?.length > 0 ? ldw[0].tax : 0,

                taxGeoCode:
                  calculatePaymentResAgr[0]?.amountDetails[0]?.taxGeoCode, //--Use any one category

                totalAmount: calculatePaymentResAgr[0]?.totalAmount,

                totalTax: calculatePaymentResAgr[0]?.totalTax,

                epoTax:
                  calculatePaymentResAgr[0]?.agreementRateDetails
                    .estimatedEpoTax, //Need to Add

                taxRate: rpayTaxAmount[0]?.taxRate, //--Use any one category

                coverageEndDate:
                  calculatePaymentResAgr[0]?.agreementRateDetails
                    .coverageEndDate, //Need to add

                policyStatus: "", //Need to add

                ldwPercentage:
                  calculatePaymentResAgr[0]?.agreementRateDetails.ldwPercentage, //Need to add

                agreementRateDetails:
                  calculatePaymentResAgr[0]?.agreementRateDetails, //Need to add

                isLdwAllowed:
                  calculatePaymentResAgr[0]?.agreementRateDetails.isLdwAllowed, //Need to add

                storeNumber: calculatepaymentStoreRes[0]?.storeNumber,

                convFee: Number(convFee[0]?.amount),

                convFeeTax: convFee[0]?.tax,

                zipPlus4UsedForTax:
                  calculatePaymentResAgr[0]?.amountDetails[0]
                    ?.zipPlus4UsedForTax,
              };
              //Agreement Rate Details Mapping
              let carryRent = calculatePaymentResAgr[0]?.amountDetails?.filter(
                (x) => x.itemCategory == "CRPAY"
              );
              let carryLateRent =
                calculatePaymentResAgr[0]?.amountDetails?.filter(
                  (x) => x.itemCategory == "CLFEEPAY"
                );
              if (getAMountDueObj.agreementRateDetails) {
                getAMountDueObj.agreementRateDetails.carriedLate =
                  carryLateRent?.length > 0 ? carryLateRent[0].amount : "0.00";
                getAMountDueObj.agreementRateDetails.carriedLateTax =
                  carryLateRent?.length > 0 ? carryLateRent[0].tax : "0.00";
                getAMountDueObj.agreementRateDetails.carryRent =
                  carryRent?.length > 0 ? carryLateRent[0].amount : "0.00";
                getAMountDueObj.agreementRateDetails.carryRentTax =
                  carryRent?.length > 0 ? carryLateRent[0].tax : "0.00";
                getAMountDueObj.agreementRateDetails.daysLate =
                  calculatePaymentResAgr?.daysLate == undefined
                    ? "0.00"
                    : calculatePaymentResAgr[0]?.daysLate;
                getAMountDueObj.agreementRateDetails.isNoteAgreement =
                  calculatePaymentResAgr?.isNoteAgreement == undefined
                    ? ""
                    : calculatePaymentResAgr[0]?.isNoteAgreement == "Y"
                    ? "1"
                    : 0;
              }

              amountDueResponseCalculateModule.push(getAMountDueObj);
              getpaymentstate.taxTotal?.push({agreementId: calculatePaymentResAgr[0]?.agreementId,amount:Number(calculatePaymentResAgr[0]?.totaltax)})
            } else {
              getpaymentstate?.paymentInfo?.paymentInfo?.splice(0, i);
            }
          }
        }
        console.log("responseresponse", amountDueResponseCalculateModule);

        for (
          let i = 0;
          i < getpaymentstate?.paymentInfo?.clubInformation?.length;
          i++
        ) {
          let calculatepaymentStoreRes =
            calculationModuleRes?.paymentDetails?.filter(
              (x) =>
                x.storeNumber ==
                getpaymentstate?.paymentInfo?.clubInformation[0]?.storeNumber
            );

          let calculatePaymentResClub =
            calculatepaymentStoreRes[0]?.clubPaymentDetails?.filter(
              (x) =>
                x.clubMembershipNumber ==
                getpaymentstate?.paymentInfo?.clubInformation[0]
                  ?.clubMembershipNumber
            );

          if (calculatePaymentResClub?.length) {
            // let getAmountDueClubRes =
            //   getpaymentstate?.amountDue?.amountDueResponse?.filter(
            //     (x) =>
            //       x.customerClubId ==
            //       Number(calculatePaymentResClub[0].customerClubId),
            //   );

            let convFee = calculationModuleRes?.customerFees?.filter(
              (x) => x.itemCategory == "CONVENFEE"
            );

            let getAmountDueClub = {
              clubAmountDue: calculatePaymentResClub[0]?.scheduleRate,

              clubAmountLateRent: calculatePaymentResClub[0]?.totalAmount,

              customerClubId: Number(calculatePaymentResClub[0].customerClubId),

              originAgreementIdStatus:
                calculatePaymentResClub[0]?.originAgreementStatus,

              clubStatus: calculatePaymentResClub[0]?.clubStatus,

              lateFee: "0.00",

              nextDueDate:
                calculatePaymentResClub[0]?.nextDueDate != "" ||
                calculatePaymentResClub[0]?.nextDueDate != "SAC" ||
                calculatePaymentResClub[0]?.nextDueDate != "PIF" ||
                calculatePaymentResClub[0]?.nextDueDate != "EPO" ||
                calculatePaymentResClub[0]?.nextDueDate != null
                  ? moment(calculatePaymentResClub[0]?.nextDueDate).format(
                      "MM/DD/YYYY"
                    )
                  : calculatePaymentResClub[0]?.nextDueDate,

              pastDueDate:
                calculatePaymentResClub[0]?.currentDueDate != "" ||
                calculatePaymentResClub[0]?.currentDueDate != "SAC" ||
                calculatePaymentResClub[0]?.currentDueDate != "PIF" ||
                calculatePaymentResClub[0]?.currentDueDate != "EPO" ||
                calculatePaymentResClub[0]?.currentDueDate != null
                  ? moment(calculatePaymentResClub[0]?.currentDueDate).format(
                      "MM/DD/YYYY"
                    )
                  : calculatePaymentResClub[0]?.currentDueDate,

              epoAmount: "0.00",

              agreementType: "",

              initialPaymentFlag:
                calculatePaymentResClub[0]?.clubStatus == "P" ? 1 : 0,

              clubTax: calculatePaymentResClub[0]?.totalTax,

              taxGeoCode: calculatePaymentResClub[0]?.taxGeoCode,

              totalAmount: calculatePaymentResClub[0]?.totalAmount,

              totalTax: calculatePaymentResClub[0]?.totalTax,

              epoTax: "0.00",

              taxRate: calculatePaymentResClub[0]?.taxRate,

              coverageEndDate: "9999-01-01", //Need to check

              policyStatus: null,

              agreementRateDetails: calculatePaymentResClub[0]?.clubRateDetails,

              isLdwAllowed: null,

              storeNumber: calculatepaymentStoreRes[0].storeNumber,

              clubCreatedCustomerId: customerId,

              priority: "0",

              convFee: Number(convFee[0]?.amount),

              convFeeTax: convFee[0]?.tax,

              zipPlus4UsedForTax:
                calculatePaymentResClub[0]?.zipPlus4UsedForTax,

              totalLateAmount: calculatePaymentResClub[0]?.totalAmount,

              totalLateTax: calculatePaymentResClub[0]?.totaltax,
            };

            amountDueResponseCalculateModule.push(getAmountDueClub);
            getpaymentstate.taxTotal?.push({agreementId: 'Club',amount:Number(calculatePaymentResClub[0]?.totaltax)})
          } else {
            getpaymentstate?.paymentInfo?.clubInformation?.splice(0, i);
          }
        }

        if (getpaymentstate?.amountDue?.amountDueResponse) {
          getpaymentstate.amountDue.amountDueResponse =
            amountDueResponseCalculateModule;
        }
      }
    }

    //

    if (
      getpaymentstate?.paymentInfo?.storeInfo?.transferAgreementStatus === 1
    ) {
      setIsPendingAgrTransfer(true);
    }

    if (
      getpaymentstate.status !== undefined &&
      getpaymentstate.status !== 200
    ) {
      console.log("went inside condition");
      setbindGridCheck(true);
      customerName = "-";
      setmainGridloader(false);
      setgetpayment(undefined);
    } else {
      console.log("went outside condition");
      let getpaid: AllPaymentInfo = getpaymentstate;
      console.log(getpaymentstate, "payment info response");
      getpaymentstate.paymentInfo.remainingCredits = [];
      setgetpayment(getpaymentstate);
      if (
        getpaymentstate?.commitmentDetails !== "" &&
        getpaymentstate?.commitmentDetails !== undefined
      ) {
        const isCommitmentInSameStoreBoolean =
          getpaymentstate.commitmentDetails.commitments.every(
            (el) =>
              el.storeNumber ==
              String(window.sessionStorage.getItem("storeNumber"))
          );
        setIsCommitmentInOtherStore(!isCommitmentInSameStoreBoolean);

        const basicFilter =
          getpaymentstate.commitmentDetails.commitments.filter(
            (obj) =>
              obj.createdSource == "RACDB" &&
              obj.storeNumber ==
                String(window.sessionStorage.getItem("storeNumber")) &&
              obj.commitmentStatusCode == "OP"
          );
        setCommitmentPayTringle(basicFilter[0]);
        let filteredClubData: any = [];
        let filteredAgreementData: any = [];
        let additionalData: any = [];
        if (basicFilter.length && basicFilter[0].commitmentDetail !== null) {
          filteredClubData = basicFilter[0].commitmentDetail.filter(
            (obj) => Number(obj.contractTypeId) == 2
          );
          filteredAgreementData = basicFilter[0].commitmentDetail.filter(
            (obj) =>
              (Number(obj.contractTypeId) == 1 ||
                Number(obj.contractTypeId) == 3) &&
              obj.commitmentTransRefCode !== "WOS" &&
              obj.commitmentTransRefCode !== "AEPDA" &&
              obj.commitmentTransRefCode !== "AEA" &&
              obj.commitmentTransRefCode !== "TAS"
          );
          additionalData = basicFilter[0].commitmentDetail.filter(
            (obj) =>
              obj.commitmentTransRefCode === "WOS" ||
              obj.commitmentTransRefCode === "AEPDA" ||
              obj.commitmentTransRefCode === "AEA" ||
              obj.commitmentTransRefCode === "TAS"
          );
        }
        let commitmentStruture: any = [];
        let CommitmentClubAgreementStructure: any = [];
        if (filteredClubData.length > 0) {
          filteredClubData.map((clubObj, clubIndex) => {
            if (clubIndex === 0) {
              CommitmentClubAgreementStructure.push({
                agreementID: clubObj.contractId,
                adjustedInfo: [
                  {
                    type: clubObj.commitmentTransRefCode,
                    value: clubObj.commitmentTransValue,
                    priority:
                      clubObj.commitmentTransRefCode == "ES"
                        ? 1
                        : clubObj.commitmentTransRefCode == "DDA"
                        ? 2
                        : clubObj.commitmentTransRefCode == "DC"
                        ? 3
                        : null,
                  },
                ],
              });
            } else {
              const isAgreementThere =
                CommitmentClubAgreementStructure.findIndex(
                  (el) => el.agreementID == clubObj.contractId
                );
              CommitmentClubAgreementStructure[
                isAgreementThere
              ].adjustedInfo.push({
                type: clubObj.commitmentTransRefCode,
                value: clubObj.commitmentTransValue,
                priority:
                  clubObj.commitmentTransRefCode == "ES"
                    ? 1
                    : clubObj.commitmentTransRefCode == "DDA"
                    ? 2
                    : clubObj.commitmentTransRefCode == "DC"
                    ? 3
                    : null,
              });
            }
          });
        }
        if (filteredAgreementData.length) {
          filteredAgreementData.map((obj, index) => {
            if (index === 0) {
              if (obj.commitmentTransRefCode === "FTT") {
                const waiveFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "WOS"
                );
                const dueDateFilter = filteredAgreementData.filter(
                  (objDueDate) =>
                    objDueDate.commitmentTransRefCode === "DDA" &&
                    Number(objDueDate.contractId) == Number(obj.contractId)
                );
                console.log("dueDateFilter", dueDateFilter);

                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: {
                        nextDueDate: dueDateFilter[0].commitmentTransValue,
                        freeTimeType: obj.commitmentTransValue,
                        waiveOptService:
                          waiveFilter[0].commitmentTransValue == "True" ||
                          waiveFilter[0].commitmentTransValue == "true"
                            ? true
                            : false,
                      },
                      priority: 4,
                    },
                  ],
                });
              } else if (obj.commitmentTransRefCode === "AEF") {
                const pastDueAmountFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "AEPDA"
                );
                const acceptEpoAmountFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "AEA"
                );
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: {
                        pastDueRent:
                          pastDueAmountFilter[0].commitmentTransValue,
                        epoAmount:
                          acceptEpoAmountFilter[0].commitmentTransValue,
                      },
                      priority: 3,
                    },
                  ],
                });
              } else if (obj.commitmentTransRefCode === "TUS") {
                const transferAddFilter = additionalData.filter(
                  (obj) => obj.commitmentTransRefCode === "TAS"
                );
                let transferData: any = [];
                transferAddFilter.map((transObj) => {
                  transferData.push({
                    agreementID: transObj.contractId,
                    transferType: transObj.commitmentTransRefCode,
                    value: transObj.commitmentTransValue,
                  });
                });
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: [
                        ...transferData,
                        {
                          agreementID: obj.contractId,
                          transferType: obj.commitmentTransRefCode,
                          value: obj.commitmentTransValue,
                        },
                      ],
                      priority: 5,
                    },
                  ],
                });
              } else if (obj.commitmentTransRefCode === "DDA") {
                const isFTTAvailable = filteredAgreementData.some(
                  (objFTT) =>
                    Number(objFTT.contractId) === Number(obj.contractId) &&
                    objFTT.commitmentTransRefCode == "FTT"
                );
                if (!isFTTAvailable) {
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: obj.commitmentTransValue,
                        priority: 4,
                      },
                    ],
                  });
                }
              } else {
                commitmentStruture.push({
                  agreementID: obj.contractId,
                  adjustedInfo: [
                    {
                      type: obj.commitmentTransRefCode,
                      value: obj.commitmentTransValue,
                      priority:
                        obj.commitmentTransRefCode == "TOS" ||
                        obj.commitmentTransRefCode == "AOS"
                          ? 1
                          : obj.commitmentTransRefCode == "ES"
                          ? 2
                          : obj.commitmentTransRefCode == "AEF"
                          ? 3
                          : obj.commitmentTransRefCode == "FTT" ||
                            obj.commitmentTransRefCode == "DDA"
                          ? 4
                          : obj.commitmentTransRefCode == "AS" ||
                            obj.commitmentTransRefCode == "US" ||
                            obj.commitmentTransRefCode == "TAS" ||
                            obj.commitmentTransRefCode == "TUS"
                          ? 5
                          : null,
                    },
                  ],
                });
              }
            } else {
              const isAgreementThere = commitmentStruture.findIndex(
                (el) => el.agreementID == obj.contractId
              );
              if (isAgreementThere >= 0) {
                if (obj.commitmentTransRefCode === "FTT") {
                  const waiveFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "WOS"
                  );
                  const dueDateFilter = filteredAgreementData.filter(
                    (objDueDate) =>
                      objDueDate.commitmentTransRefCode === "DDA" &&
                      Number(objDueDate.contractId) == Number(obj.contractId)
                  );
                  commitmentStruture[isAgreementThere].adjustedInfo.push({
                    type: obj.commitmentTransRefCode,
                    value: {
                      nextDueDate: dueDateFilter[0].commitmentTransValue,
                      freeTimeType: obj.commitmentTransValue,
                      waiveOptService: waiveFilter[0].commitmentTransValue,
                    },
                    priority: 4,
                  });
                } else if (obj.commitmentTransRefCode === "AEF") {
                  const pastDueAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEPDA"
                  );
                  const acceptEpoAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEA"
                  );
                  commitmentStruture[isAgreementThere].adjustedInfo.push({
                    type: obj.commitmentTransRefCode,
                    value: {
                      pastDueRent: pastDueAmountFilter[0].commitmentTransValue,
                      epoAmount: acceptEpoAmountFilter[0].commitmentTransValue,
                    },
                    priority: 3,
                  });
                } else if (obj.commitmentTransRefCode === "DDA") {
                  const isFTTAvailable = filteredAgreementData.some(
                    (objFTT) =>
                      Number(objFTT.contractId) === Number(obj.contractId) &&
                      objFTT.commitmentTransRefCode == "FTT"
                  );
                  if (!isFTTAvailable) {
                    commitmentStruture[isAgreementThere].adjustedInfo.push({
                      type: obj.commitmentTransRefCode,
                      value: obj.commitmentTransValue,
                      priority: 4,
                    });
                  }
                } else {
                  commitmentStruture[isAgreementThere].adjustedInfo.push({
                    type: obj.commitmentTransRefCode,
                    value: obj.commitmentTransValue,
                    priority:
                      obj.commitmentTransRefCode == "TOS" ||
                      obj.commitmentTransRefCode == "AOS"
                        ? 1
                        : obj.commitmentTransRefCode == "ES"
                        ? 2
                        : obj.commitmentTransRefCode == "AEF"
                        ? 3
                        : obj.commitmentTransRefCode == "FTT" ||
                          obj.commitmentTransRefCode == "DDA"
                        ? 4
                        : obj.commitmentTransRefCode == "AS" ||
                          obj.commitmentTransRefCode == "US" ||
                          obj.commitmentTransRefCode == "TAS" ||
                          obj.commitmentTransRefCode == "TUS"
                        ? 5
                        : null,
                  });
                }
              } else {
                if (obj.commitmentTransRefCode === "FTT") {
                  const waiveFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "WOS"
                  );
                  const dueDateFilter = filteredAgreementData.filter(
                    (objDueDate) =>
                      objDueDate.commitmentTransRefCode === "DDA" &&
                      Number(objDueDate.contractId) == Number(obj.contractId)
                  );
                  //   console.log('waiveFilter value', waiveFilter);
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: {
                          nextDueDate: dueDateFilter[0].commitmentTransValue,
                          freeTimeType: obj.commitmentTransValue,
                          waiveOptService: waiveFilter[0].commitmentTransValue,
                        },
                        priority: 4,
                      },
                    ],
                  });
                } else if (obj.commitmentTransRefCode === "AEF") {
                  const pastDueAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEPDA"
                  );
                  const acceptEpoAmountFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "AEA"
                  );
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: {
                          pastDueRent:
                            pastDueAmountFilter[0].commitmentTransValue,
                          epoAmount:
                            acceptEpoAmountFilter[0].commitmentTransValue,
                        },
                        priority: 3,
                      },
                    ],
                  });
                } else if (obj.commitmentTransRefCode === "TUS") {
                  const transferAddFilter = additionalData.filter(
                    (obj) => obj.commitmentTransRefCode === "TAS"
                  );
                  let transferData: any = [];
                  transferAddFilter.map((transObj) => {
                    transferData.push({
                      agreementID: transObj.contractId,
                      transferType: transObj.commitmentTransRefCode,
                      value: transObj.commitmentTransValue,
                    });
                  });
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: [
                          ...transferData,
                          {
                            agreementID: obj.contractId,
                            transferType: obj.commitmentTransRefCode,
                            value: obj.commitmentTransValue,
                          },
                        ],
                      },
                    ],
                  });
                } else if (obj.commitmentTransRefCode === "DDA") {
                  const isFTTAvailable = filteredAgreementData.some(
                    (objFTT) =>
                      Number(objFTT.contractId) === Number(obj.contractId) &&
                      objFTT.commitmentTransRefCode == "FTT"
                  );
                  if (!isFTTAvailable) {
                    commitmentStruture.push({
                      agreementID: obj.contractId,
                      adjustedInfo: [
                        {
                          type: obj.commitmentTransRefCode,
                          value: obj.commitmentTransValue,
                          priority: 4,
                        },
                      ],
                    });
                  }
                } else {
                  commitmentStruture.push({
                    agreementID: obj.contractId,
                    adjustedInfo: [
                      {
                        type: obj.commitmentTransRefCode,
                        value: obj.commitmentTransValue,
                        priority:
                          obj.commitmentTransRefCode == "TOS" ||
                          obj.commitmentTransRefCode == "AOS"
                            ? 1
                            : obj.commitmentTransRefCode == "ES"
                            ? 2
                            : obj.commitmentTransRefCode == "AEF"
                            ? 3
                            : obj.commitmentTransRefCode == "FTT" ||
                              obj.commitmentTransRefCode == "DDA"
                            ? 4
                            : obj.commitmentTransRefCode == "AS" ||
                              obj.commitmentTransRefCode == "US" ||
                              obj.commitmentTransRefCode == "TAS" ||
                              obj.commitmentTransRefCode == "TUS"
                            ? 5
                            : null,
                      },
                    ],
                  });
                }
              }
            }
          });
        }

        adjustCommitmentData = {
          commitmentDetail: commitmentStruture,
          CommitmentClubAgreement:
            CommitmentClubAgreementStructure.length > 0
              ? CommitmentClubAgreementStructure
              : null,
        };
      }
      localGetPayData = getpaymentstate;
      setaval_det(getpaymentstate);
      if (getpaid !== undefined) {
        let recordsFound = 0;
        let collectedPOI: any = [];
        let obtainedEAFlag: any = null;
        let obtainedAgrNumber: any = "";
        if (getpaid.amountDue.amountDueResponse.length > 0) {
          // if (getpaid?.paymentInfo?.customerInfo[0]?.zip == null) {
          //   setcustomerzipcodenull(true);
          // }

          /** 
             *  If the customer has incorrect or zip code of length not equal to 5 then navigated to the
                  customer page by displaying the popup
          */
          let postalCodeZip5 = getpaymentstate?.paymentInfo?.customerInfo[0]?.zip == null
            ? null
            : getpaymentstate?.paymentInfo?.customerInfo[0]?.zip?.split("-")[0]
          let phoneNumberPrimary = getpaymentstate?.paymentInfo?.customerInfo[0]?.phoneNumber == null || "" ? ""
            : getpaymentstate?.paymentInfo?.customerInfo[0]?.phoneNumber.toString()

          if (
            postalCodeZip5?.length != 5 ||
            postalCodeZip5 == undefined ||
            postalCodeZip5 == null
          ) {
            setPostalCodeZip5ErrorFlag(1)
          }
          else if (phoneNumberPrimary?.length != 10) {
            setPostalCodeZip5ErrorFlag(2)
          }


          for (let i = 0; i < getpaid.paymentInfo.paymentInfo.length; i++) {
            let payinfoarray: paygrid = {
              Agreementid: 0,
              AgreementNumber: "",
              Agreementtype: "",
              Agreementdesc: "",
              Schedule: "",
              CurrentDueDate: "05/07/2000",
              Nextduedate: "05/07/2000",
              CurrentDaysLate: 0,
              Amount: 0,
              SAC_Date: "05/07/2000",
              SuspenseAmount: 0,
              EPOAmount: 0,
              Latelast10: 0,
              PromoFreeDays: 0,
              Autopay: "-",
              store_number: 0,
              number_of_paymentsmade: 0,
              sign_status: "",
              confirmeddeliversystatus: true,
              Taxamount: 0,
              Autopay_enrollmentdate: "05/07/2000",
              clubactive: true,
              Phonenumber: "+1465656776767665656",
              EmailAddress: "something@gmail.com",
              partyId: "54362464176",
              clubName: "ndskjfn",
              clubMembershipNumber: "sdfhuds",
              clubCoverageTermination: "",
              clubFlag: true,
              clublastLate10: 5432,
              cardLastFour: 5674,
              agrLdwAmount: 0,
              COA: 0,
              ipSuspense: 0,
              carriedLateFee: 0,
              wlDeposit: 0,
              htSuspense: 0,
              suspense: 0,
              racTirePay: 0,
              storeCityName: "",
              stateProvinceName: "",
              ldwTaxAmount: 0,
              rpayTaxAmount: 0,
              lFeeTaxAmount: 0,
              lateFee: 0,
              policyTaxAmount: 0,
              ldwPercentage: undefined,
              actualAmountDue: 0,
              convTax: configConvFeeTax,
              policyAmount: 0,
              deliveryAmount: 0,
              deliveryAmountTax: 0,
              noOfDaysFree: 0,
              extensionAmount: 0,
              mattressFee: 0,
              mattressFeeTax: 0,
              processingFee: 0,
              processingFeeTax: 0,
              taxGeoCode: "",
              zipPlus4UsedForTax: "",
              EPOTax: 0,
              dueDateModified: false,
              agreementRateDetails: null,
              taxRate: "",
              coverageEndDate: "",
              epoTax: "",
              policyStatus: "",
              editSchedule: "",
              agreementRate: "",
              carryRentAmountUsed: 0,
              carryRentTaxUsed: 0,
              carryRentAmountAdd: 0,
              miscellaneousItemsNum: 0,
              isLdwAllowed: "0",
              carryLdwAmountTax: 0,
              carryLdwAmount: 0,
              nineOneOneFee: 0,
              cellPhoneActivation: 0,
              cellPhoneActivationTax: 0,
              miscellaneousItemInfo: undefined,
              pastDueRent: 0,
              suspenseAdd: 0,
              PromoFreeDaysApplied: 0,
              FreeTime: null, // Added By Me
              isCOATransfered: false,
              isSwitchoutDeliveryPending: 0, // Added By Me
              suspenseSub: 0,
              actualEpoAmount: 0,
              editedEpoAmount: 0,
              actualPastDueRent: 0,
              acceptEpoFlag: 0,
              originAgreementId: null,
              clubDocumentSignStatus: null,
              transferOldSuspense: 0,
              agreementNoteDetails: {
                noteAmountDueIncTax: "0.00",
                remainingNoteCost: "0.00",
                remainingAgreementCost: "0.00",
                isNoteAgreement: "0",
                NotAmountFinal: "0",
                editedNoteAmount: "",
                remainingNoteCostAmount: "0.00",
              },
              accepoEPoAgrnoteInfo: undefined,
              customerClubId: "",
              isClubDeactivateTriggered: false,
              lateFeeTaxRate: lateFeeTaxRate[0]?.taxRate,
              isReturnAgrFlag: 0,
              constLateFee: 0,
              waivedLateFee: 0,
              agreementStatus: undefined,
              promotionDetails: undefined,
              referralPromoFreeDays: undefined,
              numberOfFreeDays: undefined,
              freeTimeWeeksAllowed: undefined,
              maxWeeksAllowed: undefined,
              promotionDueDate: undefined,
              promotionType: undefined,
              promotionRate: undefined,
              carriedLate: 0,
              carriedLateTax: 0,
            };
            recordsFound = recordsFound + 1;
            let objAgreementId = getpaid.paymentInfo.paymentInfo[i].agreementId;
            let objPromoFreeDays = getpaid.paymentInfo.paymentInfo.filter(
              (x) => x.agreementId === objAgreementId
            );
            let objcurrentdayslate =
              getpaid.paymentInfo.paymentHistoryInfo.filter(
                (x) => String(x.agreementId) === String(objAgreementId)
              );
            console.log(
              getpaid.amountDue.amountDueResponse,
              objAgreementId,
              "agreement and amt due response"
            );
            let objepoamount = getpaid.amountDue.amountDueResponse.filter(
              (x) => x.agreementId === objAgreementId
            );
            console.log(
              objepoamount,
              "agreement and amt due response filtered"
            );
            let objsignstatus =
              getpaid.paymentInfo.documentSignStatus.length > 0
                ? getpaid.paymentInfo.documentSignStatus.filter(
                    (x) => x.agreementId === objAgreementId
                  )
                : [];
            let objNumberOfPaymentsMade =
              getpaid.paymentInfo.numberOfPaymentsMadeInfo.filter(
                (x) => x.agreementId == objAgreementId
              );

            console.log("objNumberOfPaymentsMade", objNumberOfPaymentsMade);

            payinfoarray.number_of_paymentsmade =
              objNumberOfPaymentsMade !== undefined
                ? objNumberOfPaymentsMade[0].noOfPaymentsMade
                : 0;

            let objDeliveryFlag =
              getpaid.paymentInfo.deliveryInformation?.filter(
                (x) => String(x.agreementId) === String(objAgreementId)
              );
            let objCustInfo: any = getpaid.paymentInfo.customerInfo[0];
            payinfoarray.Phonenumber =
              objCustInfo?.phoneNumber !== undefined &&
              objCustInfo?.phoneNumber !== null
                ? objCustInfo?.phoneNumber.toString()
                : "";
            payinfoarray.Agreementid =
              getpaid.paymentInfo.paymentInfo[i].agreementId;
            console.log(getpaid.paymentInfo.paymentInfo[i].agreementId, i);
            payinfoarray.Agreementtype = objepoamount[0].agreementType;
            payinfoarray.agreementStatus = objepoamount[0].agreementStatus;
            payinfoarray.promotionDetails =
              getpaid.paymentInfo.paymentInfo[i].promoInformation?.promoDetails;
            payinfoarray.referralPromoFreeDays =
              getpaid.paymentInfo.paymentInfo[
                i
              ].promoInformation?.referralPromoFreeDays;
            payinfoarray.numberOfFreeDays =
              getpaid.paymentInfo.paymentInfo[i].promoInformation?.noOfDaysFree;
            payinfoarray.freeTimeWeeksAllowed =
              getpaid.paymentInfo.paymentInfo[
                i
              ].promoInformation?.freeTimeWeeksAllowed;
            payinfoarray.maxWeeksAllowed =
              getpaid.paymentInfo.paymentInfo[
                i
              ].promoInformation?.maxWeeksAllowed;
            payinfoarray.promotionDueDate =
              getpaid.paymentInfo.paymentInfo[i].promoInformation?.dueDate;
            payinfoarray.promotionType =
              getpaid.paymentInfo.paymentInfo[i].promoInformation?.promoType;
            payinfoarray.promotionRate =
              getpaid.paymentInfo.paymentInfo[
                i
              ].promoInformation?.promotionRate;
            payinfoarray.AgreementNumber = String(
              getpaid.paymentInfo.paymentInfo[i].agreementNumber
            );
            payinfoarray.Agreementdesc =
              getpaid.paymentInfo.paymentInfo[i].agreementDesc == null
                ? ""
                : getpaid.paymentInfo.paymentInfo[i].agreementDesc;
            payinfoarray.Schedule = getpaid.paymentInfo.paymentInfo[i].schedule;
            if (objcurrentdayslate[0] != undefined)
              payinfoarray.CurrentDaysLate =
                objcurrentdayslate.length > 0 &&
                objcurrentdayslate[0].noOfDaysLate !== null
                  ? objcurrentdayslate[0].noOfDaysLate
                  : 0;
            else payinfoarray.CurrentDaysLate = 0;
            let objamountdue = getpaid.amountDue.amountDueResponse.filter(
              (x) => x.agreementId === objAgreementId
            );

            //V2 new changes Muzzammil agreementRateDetails
            let agreementRateDetailsObj =
              getpaid.amountDue.amountDueResponse.filter(
                (x) => x.agreementId === objAgreementId
              );
            payinfoarray.taxRate = agreementRateDetailsObj[0].taxRate;
            payinfoarray.agreementRateDetails =
              agreementRateDetailsObj[0].agreementRateDetails;
            payinfoarray.coverageEndDate =
              agreementRateDetailsObj[0].coverageEndDate;
            payinfoarray.epoTax = agreementRateDetailsObj[0].epoTax;
            payinfoarray.policyStatus = agreementRateDetailsObj[0].policyStatus;
            payinfoarray.agreementRate = agreementRateDetailsObj[0].amountDue;
            payinfoarray.carryRentAmountUsed =
              agreementRateDetailsObj[0].agreementRateDetails.carryRent ==
              undefined
                ? 0.0
                : agreementRateDetailsObj[0].agreementRateDetails.carryRent;
            payinfoarray.carryRentTaxUsed =
              agreementRateDetailsObj[0].agreementRateDetails.carryRentTax ==
              undefined
                ? 0.0
                : agreementRateDetailsObj[0].agreementRateDetails.carryRentTax;
            payinfoarray.isLdwAllowed =
              agreementRateDetailsObj[0].isLdwAllowed == undefined
                ? "0"
                : agreementRateDetailsObj[0].isLdwAllowed;
            //V2 new changes Muzzammil agreementRateDetails End
            let taxamount1 = Number(
              getpaid.taxTotal.filter(
                (x) => x.agreementId === objAgreementId.toString()
              )[0].amount
            ).toFixed(2);
            payinfoarray.Taxamount =
              Number(taxamount1) +
              Number(payinfoarray.carryRentTaxUsed) +
              Number(payinfoarray.carryLdwAmountTax);
            let ldwamountcalculation = "0.00";
            if (
              objamountdue[0].ldwLateRent !== undefined &&
              objamountdue[0].ldwLateRent !== null &&
              objamountdue[0].ldwLateRent !== "" &&
              objamountdue[0].ldwLateRent !== "0"
            ) {
              ldwamountcalculation = Number(
                objamountdue[0].ldwLateRent
              ).toFixed(2);
            } else if (
              objamountdue[0].ldwAmount !== undefined &&
              objamountdue[0].ldwAmount !== null &&
              objamountdue[0].ldwAmount !== ""
            ) {
              ldwamountcalculation = Number(objamountdue[0].ldwAmount).toFixed(
                2
              );
            }
            if (objamountdue[0].extensionAmont != undefined) {
              payinfoarray.extensionAmount = Number(
                objamountdue[0].extensionAmont
              );
              payinfoarray.noOfDaysFree = Number(objamountdue[0].freeDays);
            } else {
              payinfoarray.extensionAmount = 0;
              payinfoarray.noOfDaysFree = 0;
            }
            console.log("------->Ldw", ldwamountcalculation);
            payinfoarray.ldwPercentage = Number(objamountdue[0].ldwPercentage);

            payinfoarray.taxGeoCode = String(objamountdue[0].taxGeoCode);
            payinfoarray.zipPlus4UsedForTax = String(
              objamountdue[0].zipPlus4UsedForTax
            );
            if (
              objamountdue[0].policyLateRent !== null &&
              objamountdue[0].policyLateRent !== undefined &&
              objamountdue[0].policyLateRent !== "" &&
              objamountdue[0].policyLateRent !== "0"
            ) {
              payinfoarray.policyTaxAmount = objamountdue[0].policyTaxAmount;
              payinfoarray.policyAmount = Number(
                Number(objamountdue[0].policyLateRent).toFixed(2)
              );
            } else if (
              objamountdue[0].policyAmount !== null &&
              objamountdue[0].policyAmount !== undefined &&
              objamountdue[0].policyAmount !== ""
            ) {
              payinfoarray.policyTaxAmount = objamountdue[0].policyTaxAmount;
              payinfoarray.policyAmount = Number(
                Number(objamountdue[0].policyAmount).toFixed(2)
              );
            }
            console.log("----->Policy", payinfoarray.policyAmount);

            if (
              objamountdue[0].lateFee != 0 &&
              objcurrentdayslate[0] != undefined
            ) {
              payinfoarray.lateFee =
                objcurrentdayslate[0].noOfDaysLate > 0
                  ? Number(Number(objamountdue[0].lateFee).toFixed(2))
                  : 0.0;
              payinfoarray.constLateFee =
                objcurrentdayslate[0].noOfDaysLate > 0
                  ? Number(Number(objamountdue[0].lateFee).toFixed(2))
                  : 0.0;

              payinfoarray.lFeeTaxAmount = objamountdue[0].lFeeTaxAmount;
            }
            if (
              objamountdue[0].amountDueLateRent !== undefined &&
              objamountdue[0].amountDueLateRent !== null &&
              objamountdue[0].amountDueLateRent !== "" &&
              objamountdue[0].amountDueLateRent !== "0"
            ) {
              payinfoarray.Amount = Number(objamountdue[0].amountDueLateRent);
              payinfoarray.rpayTaxAmount = objamountdue[0].rpayTaxAmount;
              payinfoarray.pastDueRent = Number(objamountdue[0].pastDueRent);
              payinfoarray.actualPastDueRent = Number(
                objamountdue[0].pastDueRent
              );
            } else {
              payinfoarray.Amount = Number(objamountdue[0].amountDue);
              payinfoarray.rpayTaxAmount = objamountdue[0].rpayTaxAmount;
            }
            console.log("before payinfo array----->", payinfoarray.Amount);

            if (objamountdue[0].deliveryAmount != undefined) {
              payinfoarray.deliveryAmount = Number(
                objamountdue[0].deliveryAmount
              );
              payinfoarray.deliveryAmountTax = Number(
                objamountdue[0].deliveryAmountTax
              );
            }

            if (objamountdue[0].mattressFee != undefined) {
              payinfoarray.mattressFee = Number(objamountdue[0].mattressFee);
              payinfoarray.mattressFeeTax = Number(
                objamountdue[0].mattressFeeTax
              );
            }
            if (objamountdue[0].processingFee != undefined) {
              payinfoarray.processingFee = Number(
                objamountdue[0].processingFee
              );
              payinfoarray.processingFeeTax = Number(
                objamountdue[0].processingFeeTax
              );
            }

            payinfoarray.actualAmountDue = Number(
              payinfoarray.Amount + Number(payinfoarray.rpayTaxAmount)
            );

            if (objamountdue[0].initialPaymentFlag != 1) {
              payinfoarray.Amount =
                Number(payinfoarray.Amount.toFixed(2)) +
                Number(payinfoarray.lateFee) +
                Number(taxamount1) +
                Number(ldwamountcalculation) +
                Number(payinfoarray.deliveryAmount) +
                Number(payinfoarray.policyAmount) +
                Number(payinfoarray.carryRentAmountUsed) +
                Number(payinfoarray.carryRentTaxUsed);
              payinfoarray.Amount = Number(
                AddTrailingZerosToAmount(payinfoarray.Amount)
              );
            } else {
              payinfoarray.Amount = Number(
                (
                  Number(
                    Number(objamountdue[0].initialPaymentTotal).toFixed(2)
                  ) +
                  Number(payinfoarray.carryRentAmountUsed) +
                  Number(payinfoarray.carryRentTaxUsed)
                ).toFixed(2)
              );
              payinfoarray.Amount = Number(
                AddTrailingZerosToAmount(payinfoarray.Amount)
              );
            }

            if (objamountdue[0] != undefined) {
              setCardrequire({
                ...Cardrequire,
                ConvAmt: String(
                  Number(configConvFeeTax) + Number(configConvFeeWithOutTax)
                ),
              });
              payinfoarray.convTax = Number(configConvFeeTax);
            }
            console.log("after payinfo array----->", payinfoarray.Amount);
            payinfoarray.Nextduedate = objamountdue[0].nextDueDate;
            payinfoarray.CurrentDueDate = objamountdue[0].pastDueDate;
            const clubFlag =
              getpaid.paymentInfo.clubInformation[0] == undefined
                ? false
                : getpaid.paymentInfo.clubInformation[0].clubFlag;
            if (clubFlag == true) {
              setclubState(0);
              payinfoarray.clubName =
                getpaid.paymentInfo.clubInformation[0].clubName;
              payinfoarray.clubMembershipNumber =
                getpaid.paymentInfo.clubInformation[0].clubMembershipNumber;
              payinfoarray.clubCoverageTermination =
                getpaid.paymentInfo.clubInformation[0].clubCoverageTermination;
              payinfoarray.clublastLate10 =
                getpaid.paymentInfo.clubInformation[0].clublastLate10;
              payinfoarray.cardLastFour = Number(
                getpaid.paymentInfo.clubInformation[0].cardLastFour
              );
            }
            payinfoarray.originAgreementId =
              getpaid.paymentInfo.clubInformation[0]?.originAgreementId;
            payinfoarray.clubDocumentSignStatus =
              getpaid.paymentInfo.clubInformation[0]?.clubDocumentSignStatus;
            setclubState(1);
            payinfoarray.SAC_Date = getpaid.paymentInfo.paymentInfo[i].sacDate;
            payinfoarray.SuspenseAmount = Number(
              getpaid.paymentInfo.paymentInfo[i].suspenseAmount
            );
            payinfoarray.transferOldSuspense = Number(
              getpaid.paymentInfo.paymentInfo[i].suspenseAmount
            );
            let getNoteDetails = getpaid.amountDue.amountDueResponse.filter(
              (x) => x.agreementId === objAgreementId
            );
            if (getNoteDetails?.length > 0 && getNoteDetails != undefined) {
              payinfoarray.agreementNoteDetails.isNoteAgreement =
                getNoteDetails[0].agreementRateDetails.isNoteAgreement;
              payinfoarray.agreementNoteDetails.remainingAgreementCost =
                getNoteDetails[0].agreementRateDetails.remainingAgreementCost;
              payinfoarray.agreementNoteDetails.remainingNoteCost = Number(
                getNoteDetails[0].agreementRateDetails.noteCost
              );
              payinfoarray.agreementNoteDetails.noteAmountDueIncTax =
                Number(getNoteDetails[0].agreementRateDetails.noteCost) +
                Number(getNoteDetails[0].agreementRateDetails.noteTax);
              payinfoarray.agreementNoteDetails.NotAmountFinal = Number(
                getNoteDetails[0].agreementRateDetails.noteAmountIncTax
              );
              payinfoarray.agreementNoteDetails.remainingNoteCostAmount =
                Number(
                  getNoteDetails[0].agreementRateDetails.remainingNoteCost
                );

              // payinfoarray.EPOAmount=  payinfoarray.EPOAmount+Number(getNoteDetails[0].agreementRateDetails.noteCost)+Number(getNoteDetails[0].agreementRateDetails.noteTax)
            }
            payinfoarray.EPOAmount =
              objepoamount.length == 0
                ? 0.0
                : Number(objepoamount[0].epoAmount) +
                  Number(
                    objepoamount[0].lFeeTaxAmount == undefined
                      ? 0
                      : objepoamount[0].lFeeTaxAmount
                  ) +
                  Number(getpaid.paymentInfo.paymentInfo[i].suspenseAmount);
            payinfoarray.EPOTax =
              objepoamount.length == 0 ? 0.0 : Number(objepoamount[0].epoTax);
            payinfoarray.Latelast10 =
              getpaid.paymentInfo.paymentInfo[i].lastLate10;
            payinfoarray.PromoFreeDays =
              objPromoFreeDays.length > 0 &&
              objPromoFreeDays[0].promoFreeDays !== null
                ? parseInt(objPromoFreeDays[0].promoFreeDays.toString())
                : 0;
            payinfoarray.Autopay = String(
              getpaid.paymentInfo.paymentInfo[i].cardLastFour
            );
            payinfoarray.store_number =
              getpaid.paymentInfo.paymentInfo[i].storeNumber;
            payinfoarray.sign_status =
              objsignstatus.length == 0
                ? "Not Signed"
                : objsignstatus[0].documentSignStatus;
            payinfoarray.Autopay_enrollmentdate =
              getpaid.paymentInfo.paymentInfo[i].autopayEnrollmentDate;
            payinfoarray.number_of_paymentsmade =
              objNumberOfPaymentsMade.length > 0
                ? objNumberOfPaymentsMade[0].noOfPaymentsMade
                : 0;
            payinfoarray.confirmeddeliversystatus =
              objDeliveryFlag.length > 0
                ? objDeliveryFlag[0].deliveryFlag
                : false;
            payinfoarray.agrLdwAmount = Number(ldwamountcalculation);
            payinfoarray.ldwTaxAmount = objamountdue[0].ldwTaxAmount;
            //Added rcData.accountbalance>0  condition to handle negative values in the available credits-Mano Ranjith
            let wlDepositDetail =
              getpaid.balance == undefined ? [] : getpaid.balance;
            let objBalanceDetails = wlDepositDetail.filter(
              (x) => x.balanceType == "WEBLEAD"
            );
            console.log(
              wlDepositDetail,
              objBalanceDetails,
              getpaid.balance,
              "wl deposit details"
            );
            if (
              objBalanceDetails != undefined &&
              objBalanceDetails.length > 0
            ) {
              if (Number(objBalanceDetails[0].balanceAmount) > 0) {
                payinfoarray.wlDeposit = objBalanceDetails[0].balanceAmount;
              } else {
                payinfoarray.wlDeposit = 0.0;
              }
            }
            // if (
            //   getpaid.paymentInfo.remainingCredits !== undefined &&
            //   getpaid.paymentInfo.remainingCredits.length > 0
            // ) {
            //   let rcInfo = getpaid.paymentInfo.remainingCredits;
            //   let rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "coa"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     COAamount = rcData[0].accountBalance;
            //     payinfoarray.COA = COAamount;
            //   }
            //   rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "suspense"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     payinfoarray.suspense = rcData[0].accountBalance;
            //   }

            //   rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "ht suspense"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     payinfoarray.htSuspense = rcData[0].accountBalance;
            //   }
            //   rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "ip suspense"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     payinfoarray.ipSuspense = rcData[0].accountBalance;
            //   }
            // }
            collectedPOI.push({
              storeNumber: sessionStorage.getItem("storeNumber"),
              quantity: "1",
              fullfillmentType: "shipping",
              deliveryStartTime: "",
              deliveryDate: "",
              modelNumber:
                getpaid.paymentInfo.paymentInfo[i].coItemDetails[0].modelNumber,
              supplierNumber:
                getpaid.paymentInfo.paymentInfo[i].coItemDetails[0]
                  .supplierNumber,
            });
            obtainedEAFlag =
              getpaid.paymentInfo.paymentInfo[i].coItemDetails[0].eaFlag;
            obtainedAgrNumber =
              getpaid.paymentInfo.paymentInfo[i].agreementNumber;
            payinfoarray.stateProvinceName =
              getpaid.paymentInfo.paymentInfo[i].stateProvinceName;
            payinfoarray.storeCityName =
              getpaid.paymentInfo.paymentInfo[i].storeCityName;

            if (configPermissions.commonModuleFlag == 1) {
              let calculatePaymentRes =
                getpaymentstate?.amountDue?.amountDueResponse.filter(
                  (x) => x.agreementId == payinfoarray.Agreementid
                );
              (payinfoarray.Amount = Number(
                (
                  Number(calculatePaymentRes[0].totalAmount) +
                  Number(calculatePaymentRes[0].totalTax)
                ).toFixed(2)
              )),
                (payinfoarray.Taxamount = Number(
                  calculatePaymentRes[0].totalTax
                ));
            }
            //return Agr Code
            const urlParams = new URLSearchParams(window.location.search);
            const returnAGRParamValue = urlParams.get("returnAGR");
            const CPSAGRParamValue = urlParams.get("cps");
            if (
              returnAGRParamValue != "" &&
              returnAGRParamValue != null &&
              returnAGRParamValue != undefined &&
              Number(returnAGRParamValue) > 0
            ) {
              if (
                String(payinfoarray.Agreementid) == String(returnAGRParamValue)
              ) {
                payinfoarray.Amount = 0.0;
                payinfoarray.Taxamount = 0.0;
                payinfoarray.rpayTaxAmount = 0.0;
                payinfoarray.agrLdwAmount = 0.0;
                payinfoarray.lateFee = 0.0;
                payinfoarray.lFeeTaxAmount = 0.0;
                payinfoarray.ldwTaxAmount = 0.0;
                payinfoarray.Nextduedate = payinfoarray.CurrentDueDate;
                payinfoarray.suspenseSub = payinfoarray.SuspenseAmount;
                payinfoarray.isReturnAgrFlag = 1;
                if (String(CPSAGRParamValue) == "0") {
                  setradioBtn("1");
                  setCreditRadio(true);
                  setchangeRadio(false);
                } else {
                  setradioBtn("0");
                  setCreditRadio(false);
                  setchangeRadio(true);
                }
              }
            }
            const filteredAmtDueResponse: any =
              getpaid.amountDue.amountDueResponse.filter((obj) => {
                console.log("scfdsw444434r", obj.agreementId);

                return (
                  Number(obj.agreementId) ===
                  Number(getpaid.paymentInfo.paymentInfo[i].agreementId)
                );
              }); // Newly Added
            if (filteredAmtDueResponse.length > 0) {
              payinfoarray.isSwitchoutDeliveryPending =
                filteredAmtDueResponse[0].isSwitchoutDeliveryPending;
            } // Newly Added
            paygridvalues.push(payinfoarray);
            customerName =
              getpaid.paymentInfo.customerInfo[0].firstName +
              " " +
              getpaid.paymentInfo.customerInfo[0].lastName;

            let objAgrArray: AgrAmtTax = {
              agreementId: objAgreementId,
              totalAmount: Number(objamountdue[0].totalAmount),
              taxAmount: Number(objamountdue[0].totalTax),
            };
            agrAmtTaxArray.push(objAgrArray);
          }
          if (
            getpaid.paymentInfo.clubInformation.length > 0 &&
            getpaid.paymentInfo.clubInformation[0].clubFlag === true
          ) {
            let payinfoarray1: paygrid = {
              AgreementNumber: "8748973573985",
              Agreementid: 0,
              Agreementtype: "",
              Agreementdesc: "",
              Schedule: "",
              CurrentDueDate: "05/07/2000",
              Nextduedate: "05/07/2000",
              CurrentDaysLate: 0,
              Amount: 0,
              SAC_Date: "05/07/2000",
              SuspenseAmount: 0,
              EPOAmount: 0,
              Latelast10: 0,
              PromoFreeDays: 0,
              Autopay: "-",
              store_number: 0,
              number_of_paymentsmade: 0,
              sign_status: "",
              confirmeddeliversystatus: true,
              Taxamount: 0,
              Autopay_enrollmentdate: "05/07/2000",
              clubactive: true,
              Phonenumber: "+1465656776767665656",
              EmailAddress: "something@gmail.com",
              partyId: "54362464176",
              clubName: "ndskjfn",
              clubMembershipNumber: "sdfhuds",
              clubCoverageTermination: "",
              clubFlag: true,
              clublastLate10: 5432,
              cardLastFour: 5674,
              agrLdwAmount: 0,
              COA: 0,
              ipSuspense: 0,
              carriedLateFee: 0,
              wlDeposit: 0,
              htSuspense: 0,
              suspense: 0,
              lateFee: 0,
              convTax: configConvFeeTax,
              racTirePay: 0,
              storeCityName: "",
              stateProvinceName: "",
              ldwPercentage: undefined,
              taxGeoCode: "",
              zipPlus4UsedForTax: "",
              EPOTax: 0,
              dueDateModified: false,
              agreementRateDetails: null,
              taxRate: "",
              coverageEndDate: "",
              epoTax: "",
              policyStatus: "",
              editSchedule: "",
              agreementRate: "",
              carryRentAmountUsed: 0,
              carryRentTaxUsed: 0,
              carryRentAmountAdd: 0,
              miscellaneousItemsNum: 0,
              isLdwAllowed: "0",
              carryLdwAmount: 0,
              carryLdwAmountTax: 0,
              nineOneOneFee: 0,
              cellPhoneActivation: 0,
              cellPhoneActivationTax: 0,
              miscellaneousItemInfo: undefined,
              PromoFreeDaysApplied: 0,
              FreeTime: null, // Added By Me
              isCOATransfered: false,
              isSwitchoutDeliveryPending: 0, // Added By Me
              suspenseSub: 0,
              actualEpoAmount: 0,
              actualPastDueRent: 0,
              acceptEpoFlag: 0,
              editedEpoAmount: 0,
              originAgreementId: 0,
              suspenseAdd: 0,
              clubDocumentSignStatus: 0,
              transferOldSuspense: 0,
              agreementNoteDetails: {
                noteAmountDueIncTax: "0.00",
                remainingNoteCost: "0.00",
                remainingAgreementCost: "0.00",
                NotAmountFinal: "0",
                isNoteAgreement: "0",
              },
              accepoEPoAgrnoteInfo: undefined,
              customerClubId: "",
              isClubDeactivateTriggered: false,
              lateFeeTaxRate: lateFeeTaxRate[0]?.taxRate,
              isReturnAgrFlag: 0,
              constLateFee: 0,
              waivedLateFee: 0,
            };
            recordsFound = recordsFound + 1;
            window.sessionStorage.setItem("LDWaction", String(""));
            let objClubInfo = getpaid.paymentInfo.clubInformation[0];
            let objCustInfo = getpaid.paymentInfo.customerInfo[0];
            let objClubDaysLPAndPS =
              getpaid.paymentInfo.clubDaysLateAndPaymentSchedule[0];
            let objclubAmountDue = getpaid.amountDue.amountDueResponse.filter(
              (x) => x.clubAmountDue !== undefined
            );
            payinfoarray1.customerClubId =
              getpaid.paymentInfo?.clubInformation[0]?.customerClubId;
            payinfoarray1.AgreementNumber =
              objClubInfo.clubMembershipNumber !== undefined
                ? String(objClubInfo.clubMembershipNumber)
                : "0";
            payinfoarray1.Agreementid =
              objClubInfo.clubMembershipNumber !== undefined
                ? Number(objClubInfo.clubMembershipNumber)
                : 0;
            payinfoarray1.Agreementtype = "Club";
            payinfoarray1.Agreementdesc =
              objClubInfo.primaryCustomer == null
                ? objCustInfo.firstName + " " + objCustInfo.lastName
                : String(objClubInfo.primaryCustomer);
            payinfoarray1.Schedule =
              objClubDaysLPAndPS.paymentSchedule !== undefined
                ? objClubDaysLPAndPS.paymentSchedule
                : "";
            payinfoarray1.CurrentDueDate = objclubAmountDue[0].pastDueDate;
            payinfoarray1.taxRate = objclubAmountDue[0].taxRate;
            payinfoarray1.agreementRateDetails =
              objclubAmountDue[0].agreementRateDetails;
            payinfoarray1.coverageEndDate = objclubAmountDue[0].coverageEndDate;
            payinfoarray1.epoTax = objclubAmountDue[0].epoTax;
            payinfoarray1.policyStatus = objclubAmountDue[0].policyStatus;
            payinfoarray1.agreementRate = objclubAmountDue[0].clubAmountDue;
            payinfoarray1.isLdwAllowed =
              objclubAmountDue[0].isLdwAllowed == undefined
                ? "0"
                : objclubAmountDue[0].isLdwAllowed;
            //adjust due date Muzzammil  agreementRateDetails End
            payinfoarray1.Nextduedate = String(objclubAmountDue[0].nextDueDate);
            payinfoarray1.CurrentDaysLate =
              objClubDaysLPAndPS.noOfDaysLate !== null
                ? objClubDaysLPAndPS.noOfDaysLate
                : 0;
            let taxamount2 = Number(
              getpaid.taxTotal.filter((x) => x.agreementId === "Club")[0].amount
            );
            payinfoarray1.Taxamount = taxamount2;
            payinfoarray1.taxGeoCode = String(objclubAmountDue[0].taxGeoCode);
            payinfoarray1.zipPlus4UsedForTax = String(
              objclubAmountDue[0].zipPlus4UsedForTax
            );
            payinfoarray1.Amount =
              objclubAmountDue[0].clubAmountLateRent === null ||
              objclubAmountDue[0].clubAmountLateRent === undefined ||
              objclubAmountDue[0].clubAmountLateRent === "" ||
              objclubAmountDue[0].clubAmountLateRent === "0"
                ? Number(objclubAmountDue[0].clubAmountDue) + taxamount2
                : Number(objclubAmountDue[0].clubAmountLateRent) + taxamount2;
            payinfoarray1.Amount = Number(
              AddTrailingZerosToAmount(payinfoarray1.Amount)
            );
            payinfoarray1.SAC_Date = "-";
            payinfoarray1.SuspenseAmount = 0;
            payinfoarray1.EPOAmount = 0;
            payinfoarray1.Latelast10 = objClubInfo.clublastLate10;
            payinfoarray1.PromoFreeDays = 0;
            payinfoarray1.Autopay = String(
              objClubInfo.cardLastFour !== null ||
                objClubInfo.cardLastFour !== undefined ||
                objClubInfo.cardLastFour !== ""
                ? objClubInfo.cardLastFour
                : "0"
            );
            payinfoarray1.Autopay_enrollmentdate = String(
              getpaid.paymentInfo.clubInformation.length > 0 &&
                getpaid.paymentInfo.clubInformation[0].autopayEnrollmentDate !==
                  undefined &&
                getpaid.paymentInfo.clubInformation[0].autopayEnrollmentDate !==
                  "" &&
                getpaid.paymentInfo.clubInformation[0].autopayEnrollmentDate !==
                  null
                ? getpaid?.paymentInfo.clubInformation[0].autopayEnrollmentDate
                : ""
            );
            payinfoarray1.originAgreementId =
              getpaid.paymentInfo.clubInformation[0]?.originAgreementId;
            payinfoarray1.clubDocumentSignStatus =
              getpaid.paymentInfo.clubInformation[0]?.clubDocumentSignStatus;

            payinfoarray1.store_number =
              getpaid.paymentInfo.clubInformation[0].storeNumber == undefined
                ? 0
                : getpaid.paymentInfo.clubInformation[0].storeNumber;
            payinfoarray1.clubactive = true;
            payinfoarray1.Phonenumber =
              objCustInfo.phoneNumber !== undefined &&
              objCustInfo.phoneNumber !== null
                ? objCustInfo.phoneNumber.toString()
                : "";
            payinfoarray1.EmailAddress =
              objCustInfo.phoneNumber !== undefined
                ? objCustInfo.emailAddress
                : "";
            payinfoarray1.partyId =
              objCustInfo.entPartyId !== null
                ? objCustInfo.entPartyId.toString()
                : "";
            payinfoarray1.clubName =
              getpaid !== undefined ? objClubInfo.clubName : "";
            payinfoarray1.clubMembershipNumber =
              getpaid !== undefined ? objClubInfo.clubMembershipNumber : "";
            payinfoarray1.agrLdwAmount = 0;
            payinfoarray1.lateFee = objclubAmountDue[0].lateFee;
            payinfoarray1.constLateFee = objclubAmountDue[0].lateFee;
            if (configPermissions.commonModuleFlag == 1) {
              let calculatePaymentRes =
                getpaymentstate.amountDue.amountDueResponse.filter(
                  (x) => x.customerClubId == payinfoarray1.customerClubId
                );
              (payinfoarray1.Amount = Number(
                (
                  Number(calculatePaymentRes[0].totalAmount) +
                  Number(calculatePaymentRes[0].totalTax)
                ).toFixed(2)
              )),
                (payinfoarray1.Taxamount = Number(
                  calculatePaymentRes[0].totalTax
                ));
            }
            else {
              payinfoarray1.Amount = 0.0;
              payinfoarray1.Taxamount = 0.0;
            }
            
            let wlDepositDetail =
              getpaid.balance == undefined ? [] : getpaid.balance;
            let objBalanceDetails = wlDepositDetail.filter(
              (x) => x.balanceType == "WEBLEAD"
            );
            console.log(
              wlDepositDetail,
              objBalanceDetails,
              getpaid.balance,
              "wl deposit details"
            );
            if (
              objBalanceDetails != undefined &&
              objBalanceDetails.length > 0
            ) {
              if (Number(objBalanceDetails[0].balanceAmount) > 0) {
                payinfoarray1.wlDeposit = objBalanceDetails[0].balanceAmount;
              } else {
                payinfoarray1.wlDeposit = 0.0;
              }
            }
            // if (
            //   getpaid.paymentInfo.remainingCredits !== undefined &&
            //   getpaid.paymentInfo.remainingCredits.length > 0
            // ) {
            //   let rcInfo = getpaid.paymentInfo.remainingCredits;
            //   let rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "coa"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     COAamount = rcData[0].accountBalance;
            //     payinfoarray1.COA = COAamount;
            //   }
            //   rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "suspense"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     payinfoarray1.suspense = rcData[0].accountBalance;
            //   }
            //   rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "ht suspense"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     payinfoarray1.htSuspense = rcData[0].accountBalance;
            //   }
            //   rcData = rcInfo.filter(
            //     (x) => x.bucket.toLowerCase() === "ip suspense"
            //   );
            //   if (
            //     rcData != undefined &&
            //     rcData.length > 0 &&
            //     rcData[0].accountBalance > 0
            //   ) {
            //     payinfoarray1.ipSuspense = rcData[0].accountBalance;
            //   }
            // }

            if (objclubAmountDue[0].initialPaymentFlag === 0) {
              payinfoarray1.number_of_paymentsmade = 1;
            }
            if (
              payinfoarray1.number_of_paymentsmade === 0 &&
              String(
                getpaid.paymentInfo.clubInformation[0].originAgreementId
              ) == String(getpaid.paymentInfo.paymentInfo[0].agreementId)
            ) {
              console.log("allow clubdata");
              paygridvalues.push(payinfoarray1);
            }

            let objAgrArray: AgrAmtTax = {
              agreementId:
                objClubInfo.clubMembershipNumber !== undefined
                  ? Number(objClubInfo.clubMembershipNumber)
                  : 0,
              totalAmount: Number(objclubAmountDue[0].totalAmount),
              taxAmount: Number(objclubAmountDue[0].totalTax),
            };
            agrAmtTaxArray.push(objAgrArray);
          }
        }
        setPlaceOrderItemData(collectedPOI);
        setEAFlag(obtainedEAFlag);
        setAgrNumberPO(obtainedAgrNumber);
        console.log(
          "----------------------paymentagreements Final: " +
            paygridvalues.length
        );

        console.log(paygridvalues.length);
        truearrayforcheckbox = new Array(
          getpaid.paymentInfo.paymentInfo.length
        ).fill(true);
        falsearrayforcheckbox = new Array(
          getpaid.paymentInfo.paymentInfo.length
        ).fill(false);
        /*
         *This code is used for showing agreement of the store which logged in first.
         *Here We are getting the store number from the session storage.
         *The data we gonna bind in the grid are sorted based on the sort() method.
         *The club agreements gets filtered from the sorted array and it has been in a separate array Clubagreementinsertion.
         *Then the data or agreements of the currently logged in store has been filtered and also its also concatenated with the clubagreementinsertion array.
         *Then the other storedata also gets filtered and it also gets concatenated.
         *The entire concatenated array has been setstated in the paygridvalues.
         */
        let currentstorenumber = window.sessionStorage.getItem("storeNumber");
        console.log("------->current store number", currentstorenumber);

        let payAgrs = paygridvalues.sort((a: any, b: any) =>
          a["store_number"] < b["store_number"] ? -1 : 1
        );
        console.log("------>payargs", payAgrs);
        let Clubagreementinsertion = payAgrs.filter(
          (x) => x.Agreementtype === "Club"
        );
        let curStoreData = payAgrs.filter(
          (x) =>
            x.store_number.toString() === currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...curStoreData);
        let otherStoreData = payAgrs.filter(
          (x) =>
            x.store_number.toString() !== currentstorenumber &&
            x.Agreementtype !== "Club"
        );
        Clubagreementinsertion.push(...otherStoreData);
        setpaygridvalues(Clubagreementinsertion);
        console.log("paygridvalues", paygridvalues);
        // swipeDevice_api();
        setmainGridloader(false);
        stateApi();
        if (agreementId !== "0") {
          setCardrequire({ ...Cardrequire, txt2paybtn: false });
        } else {
          setAcceptpaymentbuttonenable(true);
        }
        acceptPaymentParam = [];
        swipeAmt = 0.0;
        previousAmt = 0.0;
        swipeCardAmt = [];
        actLDWArr = [];
        actPolicyArr = [];
        epoError = [];
        suspenseError = [];
        diffStoreError = [];
        setpageLoadedInitial(false);
        ReceiptCategoriesRequestParam = [];
        acceptPaymentParam = [];
        AcceptclubPaymentParam = undefined;
        clubInitialCheckFlag = true;
        if (agreementId == "0" && recordsFound > 0) {
          selectallcheckbox(true, undefined);
        }
        console.log(
          "amt due at end of getpaid",
          getpaid.amountDue,
          getpaid.amountDue.amountDueResponse.length
        );
        if (
          getpaid.amountDue !== undefined &&
          getpaid.amountDue.amountDueResponse.length > 0
        )
          setconvAmtWTax(
            (
              Number(configConvFeeWithOutTax) + Number(configConvFeeTax)
            ).toFixed(2)
          );
        /**
         * Changes: 3 Payment Verification
         * Developer: Muzzammil
         * Psuedo_Code:PC_1.1
         * Description: set the state variable as true if the customer is not verified and make more than 3 payments
         *
         */
        if (
          Number(getpaid.paymentInfo.customerInfo[0].totalPaymentsMade) >= 2 &&
          customerverificationStatus == 'PENDING'
        ) {
          setunverifiedCustomer(true);
        }
        if (
          paygridvalues.length == 1 &&
          paygridvalues[0].Agreementtype != "Club"
          // &&
          // paygridvalues[0].FreeTime == null // Added By Me
        ) {
          loadOtherActions(0);
        }
        if (agreementId != "0") {
          adjustDueDateSelectedAgre.push(agreementId);
          let clubvalue = paygridvalues.filter(
            (x) => x.Agreementtype == "Club" && x.number_of_paymentsmade == 0
          );
          setSelectedCheckBoxCount(1);
          if (clubvalue.length > 0) {
            adjustDueDateSelectedAgre.push(clubvalue[0].Agreementid);
            setSelectedCheckBoxCount(2);
          }
          adjustDueDateSelectedAgre = Array.from(
            new Set(adjustDueDateSelectedAgre)
          );
          setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
          multipleAdjust();
        }
        let isSIMSCreatedSource = false;
        if (getpaymentstate.commitmentDetails !== "") {
          const SIMSCommitment =
            getpaymentstate.commitmentDetails.commitments.filter(
              (obj) => obj.createdSource == "ENTERPRISE"
            );

          if (SIMSCommitment.length) {
            isSIMSCreatedSource = true;
          }
        }
        // if (
        //   adjustCommitmentData !== undefined &&
        //   (adjustCommitmentData?.commitmentDetail.length > 0 ||
        //     adjustCommitmentData?.CommitmentClubAgreement !== null) &&
        //   origin == undefined
        // ) {
        //   adjustCommitmentDataBind(paygridvalues);
        // } else if (isSIMSCreatedSource) {
        //   setisCommitmentInSIMS(true);
        // }
      }
    }
  };

  // const adjustCommitmentDataBind = async (payGridData: any) => {
  //   console.log("value of adjustCommitmentDataBind", payGridData);
  //   console.log(
  //     "check the value of adjustCommitmentData",
  //     adjustCommitmentData
  //   );

  //   let collectedAgreementID: any = [];
  //   payGridData.map((payGridObj, index) => {
  //     collectedAgreementID.push(payGridObj.Agreementid);
  //   });
  //   setadjustDueDateSelectedAgre(collectedAgreementID);
  //   console.log("check the collectedAgreementID value", collectedAgreementID);
  //   if (adjustCommitmentData.commitmentDetail.length > 0) {
  //     adjustCommitmentData?.commitmentDetail.map((agrObj) => {
  //       agrObj.adjustedInfo.sort(
  //         (a, b) => parseFloat(a.priority) - parseFloat(b.priority)
  //       );
  //     });
  //   }
  //   if (adjustCommitmentData?.CommitmentClubAgreement !== null) {
  //     adjustCommitmentData?.CommitmentClubAgreement[0].adjustedInfo.sort(
  //       (a, b) => parseFloat(a.priority) - parseFloat(b.priority)
  //     );
  //   }
  //   console.log(
  //     "sorted value of adjustCommitmentData.CommitmentInfoAgreements",
  //     adjustCommitmentData
  //   );
  //   if (adjustCommitmentData.CommitmentClubAgreement !== null) {
  //     for (let index = 0; index < payGridData.length; index++) {
  //       for (
  //         let j = 0;
  //         j < adjustCommitmentData.CommitmentClubAgreement.length;
  //         j++
  //       ) {
  //         let payGridObj = payGridData[index];
  //         let obj = adjustCommitmentData?.CommitmentClubAgreement[j];
  //         if (Number(payGridObj.customerClubId) == Number(obj.agreementID)) {
  //           for (let k = 0; k < obj.adjustedInfo.length; k++) {
  //             let clubObject = obj.adjustedInfo[k];
  //             if (clubObject.type === "DDA") {
  //               let coverageEndDateInfo: any = [];
  //               const adjustDueDatereq: any = [];
  //               let coverageEndDate = "";
  //               let scheduleRef = "";
  //               let policyStatus = "";
  //               if (actLDWArr.length > 0) {
  //                 coverageEndDateInfo = actLDWArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 if (coverageEndDateInfo.length > 0) {
  //                   if (Number(coverageEndDateInfo[0].action) == 2) {
  //                     const currentdate: Date = new Date(
  //                       new Date().toLocaleString("en-US", {
  //                         timeZone: "America/Chicago",
  //                       })
  //                     );
  //                     const current_date = new Date(
  //                       currentdate.getFullYear(),
  //                       currentdate.getMonth(),
  //                       currentdate.getDate()
  //                     );
  //                     coverageEndDate =
  //                       moment(current_date).format("MM/DD/YYYY");
  //                   } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //                     coverageEndDate = "12/31/2099";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayLdw
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayLdw = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.perDayLdwTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.biweeklyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.monthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.semiMonthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (coverageEndDate == "") {
  //                 coverageEndDate = String(payGridData[index].coverageEndDate);
  //               }
  //               if (
  //                 payGridData[index].editSchedule != "" &&
  //                 payGridData[index].editSchedule != null
  //               ) {
  //                 scheduleRef = payGridData[index].editSchedule;
  //               } else {
  //                 scheduleRef =
  //                   payGridData[index].agreementRateDetails.scheduleRefCode;
  //               }
  //               if (actPolicyArr.length > 0) {
  //                 const policyChangeArrInfo = actPolicyArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 if (policyChangeArrInfo.length > 0) {
  //                   if (Number(policyChangeArrInfo[0].action) == 2) {
  //                     policyStatus = "I";
  //                   } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //                     policyStatus = "A";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayPolicy
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayPolicy = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.perDayPolicyTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.weeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.biWeeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.monthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicy = Number(
  //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                           .scheduleRates.semimonthlyRate
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (policyStatus == "" || policyStatus == null) {
  //                 policyStatus = String(payGridData[index].policyStatus);
  //               }
  //               const res = {
  //                 agreementId: String(payGridData[index].Agreementid),
  //                 agreementRateDetails: payGridData[index].agreementRateDetails,
  //                 currentDueDate: String(payGridData[index].CurrentDueDate),
  //                 dueDate: String(payGridData[index].Nextduedate),
  //                 coverageEndDate: coverageEndDate,
  //                 taxRate: String(payGridData[index].taxRate),
  //                 lateFee: String(payGridData[index].lateFee),
  //                 lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //                 policyStatus: String(policyStatus),
  //                 epoAmount: String(payGridData[index].EPOAmount),
  //                 epoTax: String(payGridData[index].EPOTax),
  //                 sacDate: String(payGridData[index].SAC_Date),
  //                 isLdwAllowed: String(payGridData[index].isLdwAllowed),
  //                 agreementNumber: String(payGridData[index].AgreementNumber),
  //                 carryRent: String(payGridData[index].carryRentAmountUsed),
  //                 carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //                 scheduleRefCode: scheduleRef,
  //               };
  //               adjustDueDatereq.push(res);
  //               const calcireq = {
  //                 rateResponse: adjustDueDatereq,
  //                 dueDate: moment(clubObject.value).format("MM/DD/YYYY"),
  //               };
  //               console.log(
  //                 "before val value of hr e payGridData",
  //                 payGridData
  //               );

  //               let resFromAdjCalci = await AdjustDueDatecalci(calcireq);
  //               console.log("resFromAdjCalci from calc", resFromAdjCalci);
  //               console.log("value of ther payGridData check", payGridData);
  //               let nextduedate;
  //               if (
  //                 resFromAdjCalci[0].nextDueDate != "SAC" &&
  //                 resFromAdjCalci[0].nextDueDate != "PIF" &&
  //                 resFromAdjCalci[0].nextDueDate != "EPO"
  //               ) {
  //                 nextduedate = moment(resFromAdjCalci[0].nextDueDate).format(
  //                   "MM/DD/YYYY"
  //                 );
  //               } else {
  //                 nextduedate = resFromAdjCalci[0].nextDueDate;
  //               }
  //               (payGridData[index].Nextduedate = nextduedate),
  //                 (payGridData[index].Amount = Number(
  //                   (
  //                     Number(resFromAdjCalci[0].totalAmount) +
  //                     Number(resFromAdjCalci[0].totalTax)
  //                   ).toFixed(2)
  //                 )),
  //                 // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
  //                 (payGridData[index].Taxamount = Number(
  //                   resFromAdjCalci[0].totalTax
  //                 ));
  //               payGridData[index].policyAmount = Number(
  //                 resFromAdjCalci[0].policyAmount
  //               );
  //               // props.adjustDueDateReq[i].editSchedule=selectedSchedule
  //               (payGridData[index].ldwTaxAmount = Number(
  //                 resFromAdjCalci[0].ldwTaxAmount
  //               )),
  //                 (payGridData[index].agrLdwAmount = Number(
  //                   resFromAdjCalci[0].ldwAmount
  //                 ));
  //               payGridData[index].policyTaxAmount = Number(
  //                 resFromAdjCalci[0].policyTaxAmount
  //               );
  //               payGridData[index].agreementRate = Number(
  //                 resFromAdjCalci[0].agreementRate
  //               );
  //               payGridData[index].rpayTaxAmount = Number(
  //                 resFromAdjCalci[0].rpayTaxAmount
  //               );
  //               // payGridData[index].Agreementtype =
  //               //   resFromAdjCalci[0].agreementType;
  //               payGridData[index].Agreementtype = "Club";
  //               //   ? "Club"
  //               //   : resFromAdjCalci[0].agreementType;
  //               // }
  //               console.log("after value of hr e payGridData", payGridData);
  //               // adjustDueDateSelectedAgre.push(payGridData[index].Agreementid);
  //               // setadjustDueDateSelectedAgre([
  //               //   payGridData[index].Agreementid,
  //               // ]);
  //               // setadjustDueDateSelectedAgre([payGridData[index].Agreementid]);
  //               // resArray: [payGridData[index]],
  //               // selectedAgr: [payGridData[index].Agreementid],
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 adjustEditflag: 1,
  //                 selectedAgr: collectedAgreementID,
  //                 adjustedAgreement: payGridData[index].Agreementid,
  //               });
  //               console.log(
  //                 "value in ihte obtaine adjustDueRes",
  //                 resFromAdjCalci
  //               );
  //             } else if (clubObject.type === "ES") {
  //               const adjustDueDatereq: any = [];
  //               let coverageEndDateInfo: any = [];
  //               let policyStatus = "";
  //               let coverageEndDate = "";
  //               console.log("4rt5ty6ty5rfgytds", actLDWArr);

  //               if (actLDWArr.length > 0) {
  //                 coverageEndDateInfo = actLDWArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 console.log(
  //                   "coverageEndDateInfo 3e4r5tfd",
  //                   coverageEndDateInfo
  //                 );

  //                 if (coverageEndDateInfo.length > 0) {
  //                   if (Number(coverageEndDateInfo[0].action) == 2) {
  //                     const currentdate: Date = new Date(
  //                       new Date().toLocaleString("en-US", {
  //                         timeZone: "America/Chicago",
  //                       })
  //                     );
  //                     const current_date = new Date(
  //                       currentdate.getFullYear(),
  //                       currentdate.getMonth(),
  //                       currentdate.getDate()
  //                     );
  //                     coverageEndDate =
  //                       moment(current_date).format("MM/DD/YYYY");
  //                   } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //                     coverageEndDate = "12/31/2099";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayLdw
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayLdw = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.perDayLdwTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.biweeklyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.monthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.semiMonthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (coverageEndDate == "") {
  //                 coverageEndDate = String(payGridData[index].coverageEndDate);
  //               }
  //               if (actPolicyArr.length > 0) {
  //                 const policyChangeArrInfo = actPolicyArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 if (policyChangeArrInfo.length > 0) {
  //                   if (Number(policyChangeArrInfo[0].action) == 2) {
  //                     policyStatus = "I";
  //                   } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //                     policyStatus = "A";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayPolicy
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayPolicy = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.perDayPolicyTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.weeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.biWeeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.monthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicy = Number(
  //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                           .scheduleRates.semimonthlyRate
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (policyStatus == "") {
  //                 policyStatus = String(payGridData[index].policyStatus);
  //               }
  //               const res = {
  //                 agreementId: String(payGridData[index].Agreementid),
  //                 agreementRateDetails: payGridData[index].agreementRateDetails,
  //                 currentDueDate: String(payGridData[index].CurrentDueDate),
  //                 dueDate: String(payGridData[index].Nextduedate),
  //                 coverageEndDate: String(coverageEndDate),
  //                 taxRate: String(payGridData[index].taxRate),
  //                 lateFee: String(payGridData[index].lateFee),
  //                 lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //                 policyStatus: String(policyStatus),
  //                 epoAmount: String(payGridData[index].EPOAmount),
  //                 epoTax: String(payGridData[index].EPOTax),
  //                 sacDate: String(payGridData[index].SAC_Date),
  //                 carryRent: String(payGridData[index].carryRentAmountUsed),
  //                 carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //                 agreementNumber: String(payGridData[index].AgreementNumber),
  //                 scheduleRefCode: "",
  //               };
  //               adjustDueDatereq.push(res);
  //               const editScheudleRequest = {
  //                 rateResponse: adjustDueDatereq,
  //                 schedule: clubObject.value,
  //               };
  //               console.log("3edfr5g6yh7yu7", editScheudleRequest);

  //               // (async () => {
  //               const adjustDueRes = await AdjustDueDatecalci(
  //                 editScheudleRequest
  //               );
  //               console.log("value of res from adjustDueRes", adjustDueRes);
  //               console.log(
  //                 "initianl valued ofer payGridData",
  //                 payGridData[index]
  //               );

  //               let nextduedate;
  //               if (
  //                 adjustDueRes[0].nextDueDate != "SAC" &&
  //                 adjustDueRes[0].nextDueDate != "PIF" &&
  //                 adjustDueRes[0].nextDueDate != "EPO"
  //               ) {
  //                 nextduedate = moment(adjustDueRes[0].nextDueDate).format(
  //                   "MM/DD/YYYY"
  //                 );
  //               } else {
  //                 nextduedate = adjustDueRes[0].nextDueDate;
  //               }
  //               // if(adjustedObj.value === 'MON'){

  //               // }
  //               let updatedSchedule = "";
  //               if (clubObject.value.includes("SEMI")) {
  //                 updatedSchedule = "Semi-Monthly";
  //               } else if (clubObject.value === "MON") {
  //                 updatedSchedule = "Monthly";
  //               } else if (clubObject.value === "BIWK") {
  //                 updatedSchedule = "Bi-Weekly";
  //               } else if (clubObject.value === "WK") {
  //                 updatedSchedule = "Weekly";
  //               }

  //               (payGridData[index].Amount =
  //                 Number(adjustDueRes[0].totalAmount) +
  //                 Number(adjustDueRes[0].totalTax)),
  //                 (payGridData[index].Schedule = updatedSchedule),
  //                 (payGridData[index].Taxamount = Number(
  //                   adjustDueRes[0].totalTax
  //                 )),
  //                 (payGridData[index].Nextduedate = nextduedate);
  //               payGridData[index].policyAmount = Number(
  //                 adjustDueRes[0].policyAmount
  //               );
  //               payGridData[index].editSchedule = clubObject.value;
  //               (payGridData[index].ldwTaxAmount = Number(
  //                 adjustDueRes[0].ldwTaxAmount
  //               )),
  //                 (payGridData[index].agrLdwAmount = Number(
  //                   adjustDueRes[0].ldwAmount
  //                 ));
  //               payGridData[index].policyTaxAmount = Number(
  //                 adjustDueRes[0].policyTaxAmount
  //               );
  //               payGridData[index].agreementRate = Number(
  //                 adjustDueRes[0].agreementRate
  //               );
  //               payGridData[index].Agreementtype = "Club";
  //                 // adjustDueRes[0].agreementType;
  //               // adjustDueRes[index].Agreementtype = "Club";
  //               //   ? "Club"
  //               //   : adjustDueRes[0].agreementType;
  //               payGridData[index].rpayTaxAmount = Number(
  //                 adjustDueRes[0].rpayTaxAmount
  //               );
  //               // resArray: [payGridData[index]],
  //               // selectedAgr: [payGridData[index].Agreementid],
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 adjustEditflag: 0,
  //                 selectedAgr: collectedAgreementID,
  //               });
  //               // })();
  //             } else if (clubObject.type === "DC") {
  //               let agrIdWithoutClub: any = [];
  //               payGridData.map((payGridObj, index) => {
  //                 if (payGridObj.Agreementtype !== "Club") {
  //                   agrIdWithoutClub.push(payGridObj.Agreementid);
  //                 }
  //               });
  //               await deActivatePopupYesHandleChange();
  //               await editCommitmentRes({
  //                 resArray: payGridData,
  //                 selectedAgr: agrIdWithoutClub,
  //               });
  //               setclubStatus("A");
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   console.log("sd8ewdnf validate adjustCommitmentData", adjustCommitmentData);
  //   if (adjustCommitmentData.commitmentDetail.length > 0) {
  //     for (let index = 0; index < payGridData.length; index++) {
  //       for (
  //         let j = 0;
  //         j < adjustCommitmentData?.commitmentDetail.length;
  //         j++
  //       ) {
  //         let payGridObj = payGridData[index];
  //         let commitmentObj = adjustCommitmentData?.commitmentDetail[j];
  //         if (
  //           Number(payGridObj.Agreementid) == Number(commitmentObj.agreementID)
  //         ) {
  //           for (let k = 0; k < commitmentObj.adjustedInfo.length; k++) {
  //             let adjustedObj = commitmentObj.adjustedInfo[k];
  //             if (adjustedObj.type === "DDA") {
  //               // alert("DDA Reached");
  //               // let adjustDueRes: any = [];
  //               let coverageEndDateInfo: any = [];
  //               const adjustDueDatereq: any = [];
  //               let coverageEndDate = "";
  //               let scheduleRef = "";
  //               let policyStatus = "";
  //               if (actLDWArr.length > 0) {
  //                 coverageEndDateInfo = actLDWArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 if (coverageEndDateInfo.length > 0) {
  //                   if (Number(coverageEndDateInfo[0].action) == 2) {
  //                     const currentdate: Date = new Date(
  //                       new Date().toLocaleString("en-US", {
  //                         timeZone: "America/Chicago",
  //                       })
  //                     );
  //                     const current_date = new Date(
  //                       currentdate.getFullYear(),
  //                       currentdate.getMonth(),
  //                       currentdate.getDate()
  //                     );
  //                     coverageEndDate =
  //                       moment(current_date).format("MM/DD/YYYY");
  //                   } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //                     coverageEndDate = "12/31/2099";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayLdw
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayLdw = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.perDayLdwTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.biweeklyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.monthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.semiMonthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (coverageEndDate == "") {
  //                 coverageEndDate = String(payGridData[index].coverageEndDate);
  //               }
  //               if (
  //                 payGridData[index].editSchedule != "" &&
  //                 payGridData[index].editSchedule != null
  //               ) {
  //                 scheduleRef = payGridData[index].editSchedule;
  //               } else {
  //                 scheduleRef =
  //                   payGridData[index].agreementRateDetails.scheduleRefCode;
  //               }
  //               if (actPolicyArr.length > 0) {
  //                 const policyChangeArrInfo = actPolicyArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 if (policyChangeArrInfo.length > 0) {
  //                   if (Number(policyChangeArrInfo[0].action) == 2) {
  //                     policyStatus = "I";
  //                   } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //                     policyStatus = "A";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayPolicy
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayPolicy = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.perDayPolicyTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.weeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.biWeeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.monthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicy = Number(
  //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                           .scheduleRates.semimonthlyRate
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (policyStatus == "" || policyStatus == null) {
  //                 policyStatus = String(payGridData[index].policyStatus);
  //               }
  //               const res = {
  //                 agreementId: String(payGridData[index].Agreementid),
  //                 agreementRateDetails: payGridData[index].agreementRateDetails,
  //                 currentDueDate: String(payGridData[index].CurrentDueDate),
  //                 dueDate: String(payGridData[index].Nextduedate),
  //                 coverageEndDate: coverageEndDate,
  //                 taxRate: String(payGridData[index].taxRate),
  //                 lateFee: String(payGridData[index].lateFee),
  //                 lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //                 policyStatus: String(policyStatus),
  //                 epoAmount: String(payGridData[index].EPOAmount),
  //                 epoTax: String(payGridData[index].EPOTax),
  //                 sacDate: String(payGridData[index].SAC_Date),
  //                 isLdwAllowed: String(payGridData[index].isLdwAllowed),
  //                 agreementNumber: String(payGridData[index].AgreementNumber),
  //                 carryRent: String(payGridData[index].carryRentAmountUsed),
  //                 carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //                 scheduleRefCode: scheduleRef,
  //               };
  //               adjustDueDatereq.push(res);
  //               const calcireq = {
  //                 rateResponse: adjustDueDatereq,
  //                 dueDate: moment(adjustedObj.value).format("MM/DD/YYYY"),
  //               };
  //               console.log("calcireq logegr for dda", calcireq);

  //               console.log(
  //                 "before val value of hr e payGridData",
  //                 payGridData
  //               );

  //               // (async () => {
  //               let resFromAdjCalci = await AdjustDueDatecalci(calcireq);
  //               console.log("resFromAdjCalci from calc", resFromAdjCalci);
  //               console.log("value of ther payGridData check", payGridData);
  //               let nextduedate;
  //               if (
  //                 resFromAdjCalci[0].nextDueDate != "SAC" &&
  //                 resFromAdjCalci[0].nextDueDate != "PIF" &&
  //                 resFromAdjCalci[0].nextDueDate != "EPO"
  //               ) {
  //                 nextduedate = moment(resFromAdjCalci[0].nextDueDate).format(
  //                   "MM/DD/YYYY"
  //                 );
  //               } else {
  //                 nextduedate = resFromAdjCalci[0].nextDueDate;
  //               }
  //               (payGridData[index].Nextduedate = nextduedate),
  //                 (payGridData[index].Amount = Number(
  //                   (
  //                     Number(resFromAdjCalci[0].totalAmount) +
  //                     Number(resFromAdjCalci[0].totalTax)
  //                   ).toFixed(2)
  //                 )),
  //                 // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
  //                 (payGridData[index].Taxamount = Number(
  //                   resFromAdjCalci[0].totalTax
  //                 ));
  //               payGridData[index].policyAmount = Number(
  //                 resFromAdjCalci[0].policyAmount
  //               );
  //               // props.adjustDueDateReq[i].editSchedule=selectedSchedule
  //               (payGridData[index].ldwTaxAmount = Number(
  //                 resFromAdjCalci[0].ldwTaxAmount
  //               )),
  //                 (payGridData[index].agrLdwAmount = Number(
  //                   resFromAdjCalci[0].ldwAmount
  //                 ));
  //               payGridData[index].policyTaxAmount = Number(
  //                 resFromAdjCalci[0].policyTaxAmount
  //               );
  //               payGridData[index].agreementRate = Number(
  //                 resFromAdjCalci[0].agreementRate
  //               );
  //               payGridData[index].rpayTaxAmount = Number(
  //                 resFromAdjCalci[0].rpayTaxAmount
  //               );
  //               payGridData[index].Agreementtype =
  //                 resFromAdjCalci[0].agreementType;
  //               // payGridData[index].Agreementtype == "Club"
  //               //   ? "Club"
  //               //   : resFromAdjCalci[0].agreementType;
  //               // }
  //               console.log("after value of hr e payGridData", payGridData);
  //               // adjustDueDateSelectedAgre.push(payGridData[index].Agreementid);
  //               // setadjustDueDateSelectedAgre([payGridData[index].Agreementid]);
  //               // setadjustDueDateSelectedAgre([payGridData[index].Agreementid]);
  //               // resArray: [payGridData[index]],
  //               // selectedAgr: [payGridData[index].Agreementid],
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 adjustEditflag: 1,
  //                 selectedAgr: collectedAgreementID,
  //                 adjustedAgreement: payGridData[index].Agreementid,
  //               });
  //               console.log(
  //                 "value in ihte obtaine adjustDueRes",
  //                 resFromAdjCalci
  //               );
  //               // })();
  //             } else if (adjustedObj.type === "FTT") {
  //               // alert("Apply Free Time");
  //               const nextduedate = moment(
  //                 adjustedObj.value.nextDueDate
  //               ).format("MM/DD/YYYY");
  //               const currentduedate = moment(
  //                 payGridData[index].CurrentDueDate
  //               ).format("MM/DD/YYYY");
  //               const date1 = new Date(currentduedate);
  //               const date2 = new Date(nextduedate);
  //               const time_difference = date2.getTime() - date1.getTime();
  //               const days_difference = time_difference / (1000 * 3600 * 24);
  //               if (adjustedObj.value.waiveOptService == true) {
  //                 // alert("reached the if condition of not null");
  //                 payGridData[index].Amount = 0.0;
  //                 payGridData[index].Taxamount = 0.0;
  //                 payGridData[index].policyAmount = 0.0;
  //                 payGridData[index].ldwTaxAmount = 0.0;
  //                 payGridData[index].agrLdwAmount = 0.0;
  //                 payGridData[index].policyTaxAmount = 0.0;
  //                 payGridData[index].agreementRate = 0.0;
  //                 payGridData[index].rpayTaxAmount = 0.0;
  //                 payGridData[index].Latelast10 = 0.0;
  //                 payGridData[index].lateFee = 0.0;
  //                 payGridData[index].lFeeTaxAmount = 0.0;
  //                 payGridData[index].Nextduedate = nextduedate;
  //                 payGridData[index].PromoFreeDaysApplied =
  //                   adjustedObj.value.freeTimeType == "PROMO"
  //                     ? Number(days_difference)
  //                     : payGridData[index].PromoFreeDaysApplied;
  //                 payGridData[index].FreeTime = {
  //                   freeTimeType: adjustedObj.value.freeTimeType,
  //                   freeTimeDays: Number(days_difference),
  //                   isOptServiceWaived: adjustedObj.value.waiveOptService,
  //                 };
  //                 // resArray: [payGridData[index]],
  //                 // selectedAgr: [payGridData[index].Agreementid],

  //                 editCommitmentRes({
  //                   resArray: payGridData,
  //                   selectedAgr: collectedAgreementID,
  //                 });
  //               } else {
  //                 // alert("Free Time Reached");
  //                 let coverageEndDateInfo: any = [];
  //                 const adjustDueDatereq: any = [];
  //                 let coverageEndDate = "";
  //                 let policyStatus = "";
  //                 if (actLDWArr.length > 0) {
  //                   coverageEndDateInfo = actLDWArr.filter(
  //                     (x) =>
  //                       String(x.agreementId) ===
  //                       String(payGridData[index].Agreementid)
  //                   );
  //                   if (coverageEndDateInfo.length > 0) {
  //                     if (Number(coverageEndDateInfo[0].action) == 2) {
  //                       coverageEndDate = moment().format("MM/DD/YYYY");
  //                     } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //                       coverageEndDate = "12/31/2099";
  //                       if (
  //                         Number(
  //                           payGridData[index].agreementRateDetails.perDayLdw
  //                         ) == 0
  //                       ) {
  //                         payGridData[index].agreementRateDetails.perDayLdw = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.weeklyRate
  //                           ) / 7
  //                         ).toFixed(2);
  //                         payGridData[index].agreementRateDetails.perDayLdwTax =
  //                           (
  //                             (Number(
  //                               coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                                 .scheduleRates.weeklyRate
  //                             ) /
  //                               7) *
  //                             Number(
  //                               coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                                 .ldwTaxRate
  //                             )
  //                           ).toFixed(2);
  //                         payGridData[index].agreementRateDetails.weeklyLdw =
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.weeklyRate
  //                           ).toFixed(2);
  //                         payGridData[index].agreementRateDetails.weeklyLdwTax =
  //                           (
  //                             Number(
  //                               coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                                 .scheduleRates.weeklyRate
  //                             ) *
  //                             Number(
  //                               coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                                 .ldwTaxRate
  //                             )
  //                           ).toFixed(2);
  //                         payGridData[index].agreementRateDetails.biWeeklyLdw =
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.biweeklyRate
  //                           ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.biWeeklyLdwTax = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.biweeklyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                         payGridData[index].agreementRateDetails.monthlyLdw =
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.monthlyRate
  //                           ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.monthlyLdwTax = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.monthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.semiMonthlyLdw = Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.semiMonthlyLdwTax = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.semimonthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       }
  //                     }
  //                   }
  //                 }
  //                 if (coverageEndDate == "") {
  //                   coverageEndDate = String(
  //                     payGridData[index].coverageEndDate
  //                   );
  //                 }
  //                 if (actPolicyArr.length > 0) {
  //                   // props.ldwChangeArr
  //                   const policyChangeArrInfo = actPolicyArr.filter(
  //                     (x) =>
  //                       String(x.agreementId) ===
  //                       String(payGridData[index].Agreementid)
  //                   );
  //                   if (policyChangeArrInfo.length > 0) {
  //                     if (Number(policyChangeArrInfo[0].action) == 2) {
  //                       policyStatus = "I";
  //                     } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //                       policyStatus = "A";
  //                       if (
  //                         Number(
  //                           payGridData[index].agreementRateDetails.perDayPolicy
  //                         ) == 0
  //                       ) {
  //                         payGridData[index].agreementRateDetails.perDayPolicy =
  //                           (
  //                             Number(
  //                               coverageEndDateInfo[0].cusCoverageData
  //                                 .policyInfo.scheduleRates.weeklyRate
  //                             ) / 7
  //                           ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.perDayPolicyTax = (
  //                           (Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .scheduleRates.weeklyRate
  //                           ) /
  //                             7) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                         payGridData[index].agreementRateDetails.weeklyPolicy =
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .scheduleRates.weeklyRate
  //                           ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.weeklyPolicyTax = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .scheduleRates.weeklyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.biWeeklyPolicy = Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.biWeeklyPolicyTax = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .scheduleRates.biweeklyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.monthlyPolicy = Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.monthlyPolicyTax = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .scheduleRates.monthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.semiMonthlyPolicy = Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ).toFixed(2);
  //                         payGridData[
  //                           index
  //                         ].agreementRateDetails.semiMonthlyPolicyTax = (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .scheduleRates.semimonthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       }
  //                     }
  //                   }
  //                 }
  //                 if (policyStatus == "") {
  //                   policyStatus = String(payGridData[index].policyStatus);
  //                 }
  //                 let scheduleRef = "";
  //                 if (
  //                   payGridData[index].editSchedule != "" &&
  //                   payGridData[index].editSchedule != null
  //                 ) {
  //                   scheduleRef = payGridData[index].editSchedule;
  //                 } else {
  //                   scheduleRef =
  //                     payGridData[index].agreementRateDetails.scheduleRefCode;
  //                 }
  //                 const res = {
  //                   agreementId: String(payGridData[index].Agreementid),
  //                   agreementRateDetails:
  //                     payGridData[index].agreementRateDetails,
  //                   currentDueDate: String(payGridData[index].CurrentDueDate),
  //                   dueDate: String(payGridData[index].Nextduedate),
  //                   coverageEndDate: coverageEndDate,
  //                   taxRate: String(payGridData[index].taxRate),
  //                   lateFee: String(payGridData[index].lateFee),
  //                   lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //                   policyStatus: String(policyStatus),
  //                   epoAmount: String(payGridData[index].EPOAmount),
  //                   epoTax: String(payGridData[index].EPOTax),
  //                   sacDate: String(payGridData[index].SAC_Date),
  //                   isLdwAllowed: String(payGridData[index].isLdwAllowed),
  //                   agreementNumber: String(payGridData[index].AgreementNumber),
  //                   carryRent: String(payGridData[index].carryRentAmountUsed),
  //                   carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //                   scheduleRefCode: scheduleRef,
  //                 };
  //                 adjustDueDatereq.push(res);
  //                 console.log(
  //                   "adjustDueDatereq value in push hsv",
  //                   adjustDueDatereq
  //                 );

  //                 const calcireq = {
  //                   rateResponse: adjustDueDatereq,
  //                   dueDate: nextduedate,
  //                 };
  //                 // (async () => {
  //                 let adjustDueRes = await AdjustDueDatecalci(calcireq);
  //                 console.log(
  //                   "value from the jcdu vasd adjustDueRes",
  //                   adjustDueRes
  //                 );
  //                 let nextduedateUpdated;
  //                 if (
  //                   adjustDueRes[0].nextDueDate != "SAC" &&
  //                   adjustDueRes[0].nextDueDate != "PIF" &&
  //                   adjustDueRes[0].nextDueDate != "EPO"
  //                 ) {
  //                   nextduedateUpdated = moment(
  //                     adjustDueRes[0].nextDueDate
  //                   ).format("MM/DD/YYYY");
  //                 } else {
  //                   nextduedateUpdated = adjustDueRes[0].nextDueDate;
  //                 }
  //                 payGridData[index].Nextduedate = nextduedateUpdated;
  //                 payGridData[index].Amount =
  //                   Number(adjustDueRes[0].policyAmount) +
  //                   Number(adjustDueRes[0].ldwAmount) +
  //                   Number(adjustDueRes[0].ldwTaxAmount) +
  //                   Number(adjustDueRes[0].policyTaxAmount);
  //                 payGridData[index].Taxamount =
  //                   Number(adjustDueRes[0].ldwTaxAmount) +
  //                   Number(adjustDueRes[0].policyTaxAmount);
  //                 payGridData[index].policyAmount = Number(
  //                   adjustDueRes[0].policyAmount
  //                 );
  //                 payGridData[index].ldwTaxAmount = Number(
  //                   adjustDueRes[0].ldwTaxAmount
  //                 );
  //                 payGridData[index].agrLdwAmount = Number(
  //                   adjustDueRes[0].ldwAmount
  //                 );
  //                 payGridData[index].policyTaxAmount = Number(
  //                   adjustDueRes[0].policyTaxAmount
  //                 );
  //                 payGridData[index].agreementRate = 0.0;
  //                 payGridData[index].rpayTaxAmount = 0.0;
  //                 payGridData[index].PromoFreeDaysApplied =
  //                   adjustedObj.value.freeTimeType == "PROMO"
  //                     ? Number(days_difference)
  //                     : payGridData[index].PromoFreeDaysApplied;
  //                 payGridData[index].Latelast10 = 0.0;
  //                 payGridData[index].lateFee = 0.0;
  //                 payGridData[index].lFeeTaxAmount = 0.0;
  //                 payGridData[index].FreeTime = {
  //                   freeTimeType: adjustedObj.value.freeTimeType,
  //                   freeTimeDays: Number(days_difference),
  //                   isOptServiceWaived: adjustedObj.value.waiveOptService,
  //                 };
  //                 // resArray: [payGridData[index]],
  //                 // selectedAgr: [payGridData[index].Agreementid],
  //                 editCommitmentRes({
  //                   resArray: payGridData,
  //                   selectedAgr: collectedAgreementID,
  //                 });
  //                 // })();
  //               }
  //             } else if (adjustedObj.type === "TOS") {
  //               console.log("reached Opt.Service else", index);

  //               if (adjustedObj.value === "LDW") {
  //                 selectedGrid = index;
  //                 // (async () => {
  //                 const callLoadOtherAction: any = await commitmentLoadAction(
  //                   index
  //                 );
  //                 console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //                 if (callLoadOtherAction !== undefined) {
  //                   const terminateLdw_Resp: any = await LDW_apiCall(
  //                     2,
  //                     callLoadOtherAction,
  //                     index
  //                   );
  //                   editCommitmentRes({
  //                     resArray: payGridData,
  //                     selectedAgr: collectedAgreementID,
  //                   });
  //                   console.log(terminateLdw_Resp);
  //                 }
  //                 // })();
  //               } else if (adjustedObj.value === "Policy") {
  //                 selectedGrid = index;
  //                 // (async () => {
  //                 const callLoadOtherAction: any = await commitmentLoadAction(
  //                   index
  //                 );
  //                 console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //                 if (callLoadOtherAction !== undefined) {
  //                   const terminateLdw_Resp: any = await Policy_apiCall(
  //                     2,
  //                     callLoadOtherAction,
  //                     index
  //                   );
  //                   editCommitmentRes({
  //                     resArray: payGridData,
  //                     selectedAgr: collectedAgreementID,
  //                   });
  //                   console.log(terminateLdw_Resp);
  //                 }
  //                 // })();
  //               }
  //             } else if (adjustedObj.type === "AOS") {
  //               console.log("reached Opt.Service else", index);
  //               if (adjustedObj.value === "LDW") {
  //                 selectedGrid = index;
  //                 // (async () => {
  //                 const callLoadOtherAction: any = await commitmentLoadAction(
  //                   index
  //                 );
  //                 console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //                 console.log("updated index value check in LDW", index);

  //                 if (callLoadOtherAction !== undefined) {
  //                   const terminateLdw_Resp: any = await LDW_apiCall(
  //                     1,
  //                     callLoadOtherAction,
  //                     index
  //                   );
  //                   editCommitmentRes({
  //                     resArray: payGridData,
  //                     selectedAgr: collectedAgreementID,
  //                   });
  //                   console.log(terminateLdw_Resp);
  //                 }
  //                 // })();
  //               } else if (adjustedObj.value === "Policy") {
  //                 selectedGrid = index;
  //                 // (async () => {
  //                 const callLoadOtherAction: any = await commitmentLoadAction(
  //                   index
  //                 );
  //                 console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //                 console.log("updated index value check in Policy", index);
  //                 if (callLoadOtherAction !== undefined) {
  //                   const terminateLdw_Resp: any = await Policy_apiCall(
  //                     1,
  //                     callLoadOtherAction,
  //                     index
  //                   );
  //                   console.log("Policy-Activate done", terminateLdw_Resp);
  //                   editCommitmentRes({
  //                     resArray: payGridData,
  //                     selectedAgr: collectedAgreementID,
  //                   });
  //                 }
  //                 // })();
  //               }
  //             } else if (adjustedObj.type === "AS") {
  //               let insertArray: any[] = [];
  //               insertArray.push({
  //                 sustype:
  //                   Number(payGridData[index].suspenseAdd) > 0 ? "Add" : "type",
  //                 agreementnumber: payGridData[index].Agreementid,
  //                 agreementid: payGridData[index].AgreementNumber,
  //                 schedure: payGridData[index].Schedule,
  //                 paymentamount: payGridData[index].Amount,
  //                 epoamount: payGridData[index].EPOAmount,
  //                 suspenseamount: Number(
  //                   payGridData[index].SuspenseAmount
  //                 ).toFixed(2),
  //                 newsuspense: Number(payGridData[index].suspenseAdd).toFixed(
  //                   2
  //                 ),
  //                 agreementType: payGridData[index].Agreementtype,
  //               });
  //               setaddsus("0.00");
  //               setusesus("0.00");
  //               settranssus("0.00");
  //               setaddsus(adjustedObj.value);
  //               insertArray[0].newsuspense = adjustedObj.value;
  //               insertArray[0].sustype = "Add"; // Check This
  //               setmasteraddsuspensearray(insertArray);

  //               const addfilterzero = insertArray.filter(function (e) {
  //                 return e.sustype != "type" && Number(e.newsuspense) > 0;
  //               });
  //               const usefilterzero = masterusesuspensearray.filter(function (
  //                 e
  //               ) {
  //                 return e.sustype != "type" && Number(e.newsuspense) > 0;
  //               });
  //               // if (totalsuspensemaster)
  //               console.log("sdfffr5555addfilterzero", addfilterzero);
  //               console.log("sdfffr55698addfilterzero", usefilterzero);

  //               settotalsuspensemaster([...addfilterzero, ...usefilterzero]);
  //               for (let i = 0; i < addfilterzero.length; i++) {
  //                 let index = paygridvalues.findIndex(
  //                   (x) =>
  //                     String(x.Agreementid) ===
  //                     String(addfilterzero[i].agreementnumber)
  //                 );
  //                 paygridvalues[index].suspenseAdd = Number(
  //                   addfilterzero[i].newsuspense
  //                 );
  //                 // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(addfilterzero[i].newsuspense)
  //                 let receiptObj = ReceiptCategoriesRequestParam.filter(
  //                   (e) =>
  //                     e.agreementId == Number(addfilterzero[i].agreementnumber)
  //                 );
  //                 let receiptObjaccept = acceptPaymentParam.filter(
  //                   (e) =>
  //                     e.agreementId == Number(addfilterzero[i].agreementnumber)
  //                 );
  //                 if (receiptObj[0] != undefined) {
  //                   receiptObj[0].suspenseAdd = Number(
  //                     addfilterzero[i].newsuspense
  //                   );
  //                 }
  //                 if (
  //                   String(addfilterzero[i].agreementnumber) ==
  //                   String(receiptObjaccept[0].agreementId)
  //                 ) {
  //                   acceptPaymentParam[i].addTransferSusFlag = 1;
  //                 }
  //               }
  //               setReceiptCategory(ReceiptCategoriesRequestParam);
  //               let totalsusamount: number;
  //               totalsusamount = 0;
  //               for (let i = 0; i < addfilterzero.length; i++) {
  //                 totalsusamount =
  //                   totalsusamount + Number(addfilterzero[i].newsuspense);
  //               }
  //               // setsuspensePopupClass(
  //               //   `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  //               // );
  //               setaddsuspense(false);
  //               let tenderedAmount = 0;
  //               for (let i = 0; i < tendertypeglobalarray.length; i++) {
  //                 tenderedAmount =
  //                   tenderedAmount + Number(tendertypeglobalarray[i].Amount);
  //               }
  //               let resAmount = 0.0;
  //               for (let i = 0; i < checkedAgr.length; i++) {
  //                 resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
  //               }
  //               const addSuspenseAmount = addfilterzero.filter(function (e) {
  //                 return e.sustype == "Add" && Number(e.newsuspense) > 0;
  //               });
  //               const useSuspenseAmount = usefilterzero.filter(function (e) {
  //                 return e.sustype == "Used" && Number(e.newsuspense) > 0;
  //               });

  //               let addSusTotalAmount =
  //                 addSuspenseAmount.length == 0
  //                   ? 0.0
  //                   : addSuspenseAmount
  //                       .map((bill) => bill.newsuspense)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );
  //               let usedSusTotalAmount =
  //                 useSuspenseAmount.length == 0
  //                   ? 0.0
  //                   : useSuspenseAmount
  //                       .map((bill) => bill.newsuspense)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );

  //               let resTotalAmount = 0.0;
  //               if (Cardrequire.PaymentOrgin == "Pay By Phone") {
  //                 resAmount = Number(resAmount) + Number(convAmtWTax);
  //               }
  //               resAmount = Number(resAmount) - Number(carryRentAmount);
  //               resTotalAmount =
  //                 totalduetendertype.length == 0
  //                   ? 0.0
  //                   : totalduetendertype
  //                       .map((bill) => bill.Amount)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );
  //               if (Number(resTotalAmount) > Number(resAmount)) {
  //                 resetPayment(undefined);
  //                 setremainingamountdue(
  //                   Number(
  //                     (
  //                       Number(resAmount) -
  //                       Number(availableCreds) +
  //                       Number(addSusTotalAmount) -
  //                       Number(usedSusTotalAmount)
  //                     ).toFixed(2)
  //                   )
  //                 );
  //                 setchange("0.00");
  //                 setAcceptpaymentbuttonenable(true);
  //               } else {
  //                 if (
  //                   Number(resTotalAmount) >=
  //                   Number(
  //                     (
  //                       Number(resAmount) -
  //                       Number(availableCreds) +
  //                       Number(addSusTotalAmount) -
  //                       Number(usedSusTotalAmount)
  //                     ).toFixed(2)
  //                   )
  //                 ) {
  //                   setchange(
  //                     Number(resTotalAmount) -
  //                       Number(
  //                         (
  //                           Number(resAmount) -
  //                           Number(availableCreds) +
  //                           Number(addSusTotalAmount) -
  //                           Number(usedSusTotalAmount)
  //                         ).toFixed(2)
  //                       )
  //                   );
  //                   setremainingamountdue("0.00");
  //                   setRemaniningAmtStatic("0.00");
  //                   setAcceptpaymentbuttonenable(false);
  //                 } else {
  //                   setremainingamountdue(
  //                     Number(
  //                       (
  //                         Number(resAmount) -
  //                         Number(availableCreds) +
  //                         Number(addSusTotalAmount) -
  //                         Number(usedSusTotalAmount)
  //                       ).toFixed(2)
  //                     ) - Number(resTotalAmount)
  //                   );
  //                   setRemaniningAmtStatic(
  //                     Number(
  //                       (
  //                         Number(resAmount) -
  //                         Number(availableCreds) +
  //                         Number(addSusTotalAmount) -
  //                         Number(usedSusTotalAmount)
  //                       ).toFixed(2)
  //                     ) - Number(resTotalAmount)
  //                   );
  //                   setchange("0.00");
  //                   setAcceptpaymentbuttonenable(true);
  //                 }
  //               }

  //               settotaldue(resAmount);
  //               multipleAdjust();
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 selectedAgr: collectedAgreementID,
  //               });
  //             } else if (adjustedObj.type === "US") {
  //               let insertArray: any[] = [];
  //               insertArray.push({
  //                 sustype:
  //                   Number(payGridData[index].suspenseSub) > 0
  //                     ? "Used"
  //                     : "type",
  //                 agreementnumber: payGridData[index].Agreementid,
  //                 agreementid: payGridData[index].AgreementNumber,
  //                 schedure: payGridData[index].Schedule,
  //                 paymentamount: payGridData[index].Amount,
  //                 epoamount: payGridData[index].EPOAmount,
  //                 suspenseamount: Number(
  //                   payGridData[index].SuspenseAmount
  //                 ).toFixed(2),
  //                 newsuspense: Number(payGridData[index].suspenseSub).toFixed(
  //                   2
  //                 ),
  //                 agreementType: payGridData[index].Agreementtype,
  //               });
  //               console.log("che the insertArray pushed value", insertArray);

  //               setaddsus("0.00");
  //               setusesus("0.00");
  //               settranssus("0.00");
  //               setusesus(adjustedObj.value);
  //               insertArray[0].newsuspense = adjustedObj.value;
  //               insertArray[0].sustype = "Used";
  //               console.log(
  //                 "changed the insertArray pushed value",
  //                 insertArray
  //               );
  //               setmasterusesuspensearray(insertArray);
  //               const addfilterzero = masteraddsuspensearray.filter(function (
  //                 e
  //               ) {
  //                 return e.sustype != "type" && Number(e.newsuspense) > 0;
  //               });
  //               console.log("filaddfilterzero", addfilterzero);

  //               const usefilterzero = insertArray.filter(function (e) {
  //                 return e.sustype != "type" && Number(e.newsuspense) > 0;
  //               });
  //               console.log("filusefilterzero", usefilterzero);

  //               settotalsuspensemaster([...addfilterzero, ...usefilterzero]);

  //               for (let i = 0; i < usefilterzero.length; i++) {
  //                 let index = paygridvalues.findIndex(
  //                   (x) =>
  //                     String(x.Agreementid) ===
  //                     String(usefilterzero[i].agreementnumber)
  //                 );
  //                 paygridvalues[index].suspenseSub = Number(
  //                   usefilterzero[i].newsuspense
  //                 );
  //                 // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
  //                 console.log("for count");
  //                 if (
  //                   usefilterzero[i].agreementnumber ==
  //                   ReceiptCategoriesRequestParam[i].agreementId
  //                 ) {
  //                   ReceiptCategoriesRequestParam[i].suspenseSub = Number(
  //                     usefilterzero[i].newsuspense
  //                   );
  //                 }
  //               }
  //               setReceiptCategory(ReceiptCategoriesRequestParam);
  //               let totalsusamountuse: number;
  //               totalsusamountuse = 0;
  //               for (let i = 0; i < usefilterzero.length; i++) {
  //                 totalsusamountuse =
  //                   totalsusamountuse + Number(usefilterzero[i].newsuspense);
  //               }
  //               let resAmount = 0.0;
  //               for (let i = 0; i < checkedAgr.length; i++) {
  //                 resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
  //               }
  //               const addSuspenseAmount = addfilterzero.filter(function (e) {
  //                 return e.sustype == "Add" && Number(e.newsuspense) > 0;
  //               });
  //               const useSuspenseAmount = usefilterzero.filter(function (e) {
  //                 return e.sustype == "Used" && Number(e.newsuspense) > 0;
  //               });
  //               let addSusTotalAmount =
  //                 addSuspenseAmount.length == 0
  //                   ? 0.0
  //                   : addSuspenseAmount
  //                       .map((bill) => bill.newsuspense)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );
  //               let usedSusTotalAmount =
  //                 useSuspenseAmount.length == 0
  //                   ? 0.0
  //                   : useSuspenseAmount
  //                       .map((bill) => bill.newsuspense)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );

  //               let resTotalAmount = 0.0;
  //               if (Cardrequire.PaymentOrgin == "Pay By Phone") {
  //                 resAmount = Number(resAmount) + Number(convAmtWTax);
  //               }
  //               resAmount = Number(resAmount) - Number(carryRentAmount);
  //               resTotalAmount =
  //                 totalduetendertype.length == 0
  //                   ? 0.0
  //                   : totalduetendertype
  //                       .map((bill) => bill.Amount)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );

  //               if (Number(resTotalAmount) > Number(resAmount)) {
  //                 resetPayment(undefined);
  //                 setremainingamountdue(
  //                   Number(
  //                     (
  //                       Number(resAmount) -
  //                       Number(availableCreds) +
  //                       Number(addSusTotalAmount) -
  //                       Number(usedSusTotalAmount)
  //                     ).toFixed(2)
  //                   )
  //                 );
  //                 setchange("0.00");
  //                 setAcceptpaymentbuttonenable(true);
  //               } else {
  //                 if (
  //                   Number(resTotalAmount) >=
  //                   Number(
  //                     (
  //                       Number(resAmount) -
  //                       Number(availableCreds) +
  //                       Number(addSusTotalAmount) -
  //                       Number(usedSusTotalAmount)
  //                     ).toFixed(2)
  //                   )
  //                 ) {
  //                   setchange(
  //                     Number(resTotalAmount) -
  //                       Number(
  //                         (
  //                           Number(resAmount) -
  //                           Number(availableCreds) +
  //                           Number(addSusTotalAmount) -
  //                           Number(usedSusTotalAmount)
  //                         ).toFixed(2)
  //                       )
  //                   );
  //                   setremainingamountdue("0.00");
  //                   setRemaniningAmtStatic("0.00");
  //                   setAcceptpaymentbuttonenable(false);
  //                 } else {
  //                   setremainingamountdue(
  //                     Number(
  //                       (
  //                         Number(resAmount) -
  //                         Number(availableCreds) +
  //                         Number(addSusTotalAmount) -
  //                         Number(usedSusTotalAmount)
  //                       ).toFixed(2)
  //                     ) - Number(resTotalAmount)
  //                   );
  //                   setRemaniningAmtStatic(
  //                     Number(
  //                       (
  //                         Number(resAmount) -
  //                         Number(availableCreds) +
  //                         Number(addSusTotalAmount) -
  //                         Number(usedSusTotalAmount)
  //                       ).toFixed(2)
  //                     ) - Number(resTotalAmount)
  //                   );
  //                   setchange("0.00");
  //                   setAcceptpaymentbuttonenable(true);
  //                 }
  //               }

  //               settotaldue(resAmount);
  //               multipleAdjust();
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 selectedAgr: collectedAgreementID,
  //               });
  //             } else if (adjustedObj.type === "AEF") {
  //               console.log("chec the iss4rd", adjustedObj.value);
  //               const totalWithOutTax = Number(
  //                 Number(
  //                   Number(adjustedObj.value.epoAmount) +
  //                     Number(adjustedObj.value.pastDueRent) +
  //                     payGridData[index].lateFee
  //                 ).toFixed(2)
  //               );
  //               const epoFieldValuesObject = {
  //                 pastDueRent: Number(adjustedObj.value.pastDueRent).toFixed(2),
  //                 epoAmt: Number(adjustedObj.value.epoAmount),
  //                 taxAmt: Number(
  //                   (
  //                     totalWithOutTax * Number(payGridData[index].taxRate)
  //                   ).toFixed(2)
  //                 ),
  //                 total: (
  //                   Number(
  //                     Number(adjustedObj.value.epoAmount) +
  //                       Number(adjustedObj.value.pastDueRent) +
  //                       Number(payGridData[index].lateFee) +
  //                       Number(
  //                         (
  //                           totalWithOutTax * payGridData[index].taxRate
  //                         ).toFixed(2)
  //                       )
  //                   ) - Number(payGridData[index].SuspenseAmount)
  //                 ).toFixed(2),
  //               };
  //               console.log(
  //                 "epoPopup sxcnjbhfeywhusjnhey4ge3w",
  //                 epoFieldValuesObject
  //               );
  //               selectedGrid = index;
  //               await acceptEpoButton(
  //                 epoFieldValuesObject,
  //                 false,
  //                 "CommitmentEPO"
  //               );
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 selectedAgr: collectedAgreementID,
  //               });
  //             } else if (adjustedObj.type === "ES") {
  //               const adjustDueDatereq: any = [];
  //               let coverageEndDateInfo: any = [];
  //               let policyStatus = "";
  //               let coverageEndDate = "";
  //               console.log("4rt5ty6ty5rfgytds", actLDWArr);

  //               if (actLDWArr.length > 0) {
  //                 coverageEndDateInfo = actLDWArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 console.log(
  //                   "coverageEndDateInfo 3e4r5tfd",
  //                   coverageEndDateInfo
  //                 );

  //                 if (coverageEndDateInfo.length > 0) {
  //                   if (Number(coverageEndDateInfo[0].action) == 2) {
  //                     const currentdate: Date = new Date(
  //                       new Date().toLocaleString("en-US", {
  //                         timeZone: "America/Chicago",
  //                       })
  //                     );
  //                     const current_date = new Date(
  //                       currentdate.getFullYear(),
  //                       currentdate.getMonth(),
  //                       currentdate.getDate()
  //                     );
  //                     coverageEndDate =
  //                       moment(current_date).format("MM/DD/YYYY");
  //                   } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //                     coverageEndDate = "12/31/2099";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayLdw
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayLdw = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.perDayLdwTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.biweeklyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyLdwTax =
  //                         (
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .scheduleRates.monthlyRate
  //                           ) *
  //                           Number(
  //                             coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                               .ldwTaxRate
  //                           )
  //                         ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.semiMonthlyLdw =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyLdwTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (coverageEndDate == "") {
  //                 coverageEndDate = String(payGridData[index].coverageEndDate);
  //               }
  //               if (actPolicyArr.length > 0) {
  //                 const policyChangeArrInfo = actPolicyArr.filter(
  //                   (x) =>
  //                     String(x.agreementId) ===
  //                     String(payGridData[index].Agreementid)
  //                 );
  //                 if (policyChangeArrInfo.length > 0) {
  //                   if (Number(policyChangeArrInfo[0].action) == 2) {
  //                     policyStatus = "I";
  //                   } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //                     policyStatus = "A";
  //                     if (
  //                       Number(
  //                         payGridData[index].agreementRateDetails.perDayPolicy
  //                       ) == 0
  //                     ) {
  //                       payGridData[index].agreementRateDetails.perDayPolicy = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) / 7
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.perDayPolicyTax = (
  //                         (Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) /
  //                           7) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.weeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.weeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.weeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.biWeeklyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.biWeeklyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.biweeklyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[index].agreementRateDetails.monthlyPolicy =
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.monthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.monthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicy = Number(
  //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                           .scheduleRates.semimonthlyRate
  //                       ).toFixed(2);
  //                       payGridData[
  //                         index
  //                       ].agreementRateDetails.semiMonthlyPolicyTax = (
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .scheduleRates.semimonthlyRate
  //                         ) *
  //                         Number(
  //                           coverageEndDateInfo[0].cusCoverageData.policyInfo
  //                             .ldwTaxRate
  //                         )
  //                       ).toFixed(2);
  //                     }
  //                   }
  //                 }
  //               }
  //               if (policyStatus == "") {
  //                 policyStatus = String(payGridData[index].policyStatus);
  //               }
  //               const res = {
  //                 agreementId: String(payGridData[index].Agreementid),
  //                 agreementRateDetails: payGridData[index].agreementRateDetails,
  //                 currentDueDate: String(payGridData[index].CurrentDueDate),
  //                 dueDate: String(payGridData[index].Nextduedate),
  //                 coverageEndDate: String(coverageEndDate),
  //                 taxRate: String(payGridData[index].taxRate),
  //                 lateFee: String(payGridData[index].lateFee),
  //                 lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //                 policyStatus: String(policyStatus),
  //                 epoAmount: String(payGridData[index].EPOAmount),
  //                 epoTax: String(payGridData[index].EPOTax),
  //                 sacDate: String(payGridData[index].SAC_Date),
  //                 carryRent: String(payGridData[index].carryRentAmountUsed),
  //                 carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //                 agreementNumber: String(payGridData[index].AgreementNumber),
  //                 scheduleRefCode: "",
  //               };
  //               adjustDueDatereq.push(res);
  //               const editScheudleRequest = {
  //                 rateResponse: adjustDueDatereq,
  //                 schedule: adjustedObj.value,
  //               };
  //               console.log("3edfr5g6yh7yu7", editScheudleRequest);

  //               // (async () => {
  //               const adjustDueRes = await AdjustDueDatecalci(
  //                 editScheudleRequest
  //               );
  //               console.log("value of res from adjustDueRes", adjustDueRes);
  //               console.log(
  //                 "initianl valued ofer payGridData",
  //                 payGridData[index]
  //               );

  //               let nextduedate;
  //               if (
  //                 adjustDueRes[0].nextDueDate != "SAC" &&
  //                 adjustDueRes[0].nextDueDate != "PIF" &&
  //                 adjustDueRes[0].nextDueDate != "EPO"
  //               ) {
  //                 nextduedate = moment(adjustDueRes[0].nextDueDate).format(
  //                   "MM/DD/YYYY"
  //                 );
  //               } else {
  //                 nextduedate = adjustDueRes[0].nextDueDate;
  //               }
  //               // if(adjustedObj.value === 'MON'){

  //               // }
  //               let updatedSchedule = "";
  //               if (adjustedObj.value.includes("SEMI")) {
  //                 updatedSchedule = "Semi-Monthly";
  //               } else if (adjustedObj.value === "MON") {
  //                 updatedSchedule = "Monthly";
  //               } else if (adjustedObj.value === "BIWK") {
  //                 updatedSchedule = "Bi-Weekly";
  //               } else if (adjustedObj.value === "WK") {
  //                 updatedSchedule = "Weekly";
  //               }

  //               (payGridData[index].Amount =
  //                 Number(adjustDueRes[0].totalAmount) +
  //                 Number(adjustDueRes[0].totalTax)),
  //                 (payGridData[index].Schedule = updatedSchedule),
  //                 (payGridData[index].Taxamount = Number(
  //                   adjustDueRes[0].totalTax
  //                 )),
  //                 (payGridData[index].Nextduedate = nextduedate);
  //               payGridData[index].policyAmount = Number(
  //                 adjustDueRes[0].policyAmount
  //               );
  //               payGridData[index].editSchedule = adjustedObj.value;
  //               (payGridData[index].ldwTaxAmount = Number(
  //                 adjustDueRes[0].ldwTaxAmount
  //               )),
  //                 (payGridData[index].agrLdwAmount = Number(
  //                   adjustDueRes[0].ldwAmount
  //                 ));
  //               payGridData[index].policyTaxAmount = Number(
  //                 adjustDueRes[0].policyTaxAmount
  //               );
  //               payGridData[index].agreementRate = Number(
  //                 adjustDueRes[0].agreementRate
  //               );
  //               payGridData[index].Agreementtype =
  //                 adjustDueRes[0].agreementType;
  //               // adjustDueRes[index].Agreementtype == "Club"
  //               //   ? "Club"
  //               //   : adjustDueRes[0].agreementType;
  //               payGridData[index].rpayTaxAmount = Number(
  //                 adjustDueRes[0].rpayTaxAmount
  //               );
  //               // resArray: [payGridData[index]],
  //               // selectedAgr: [payGridData[index].Agreementid],
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 adjustEditflag: 0,
  //                 selectedAgr: collectedAgreementID,
  //               });
  //               // })();
  //             } else if (adjustedObj.type === "TUS") {
  //               let insertArray: any[] = [];
  //               let totalsusamount = 0;
  //               let transferedSupenseAmount = 0;
  //               for (let i = 0; i < paygridvalues?.length; i++) {
  //                 if (
  //                   paygridvalues[i].Agreementtype != "Club" &&
  //                   paygridvalues[i].Nextduedate != "PIF" &&
  //                   paygridvalues[i].Nextduedate != "SAC" &&
  //                   paygridvalues[i].Nextduedate != "EPO" &&
  //                   paygridvalues[i].number_of_paymentsmade > 0 &&
  //                   String(paygridvalues[i].store_number) ==
  //                     String(window.sessionStorage.getItem("storeNumber"))
  //                 ) {
  //                   totalsusamount =
  //                     Number(totalsusamount) +
  //                     Number(paygridvalues[i].SuspenseAmount);
  //                   transferedSupenseAmount =
  //                     Number(transferedSupenseAmount) +
  //                     Number(paygridvalues[i].transferOldSuspense);
  //                   insertArray.push({
  //                     sustype: "type",
  //                     agreementnumber: paygridvalues[i].AgreementNumber,
  //                     agreementid: paygridvalues[i].Agreementid,
  //                     schedure: paygridvalues[i].Schedule,
  //                     paymentamount: paygridvalues[i].Amount,
  //                     epoamount: paygridvalues[i].EPOAmount,
  //                     suspenseamount: Number(
  //                       paygridvalues[i].SuspenseAmount
  //                     ).toFixed(2),
  //                     newsuspense: Number(
  //                       paygridvalues[i].SuspenseAmount
  //                     ).toFixed(2),
  //                     agreementType: paygridvalues[i].Agreementtype,
  //                   });
  //                   settotolsuspenseamount(Number(totalsusamount).toFixed(2));
  //                   setdifferenceamount(
  //                     Number(
  //                       (
  //                         Number(transferedSupenseAmount) -
  //                         Number(totalsusamount)
  //                       ).toFixed(2)
  //                     )
  //                   );
  //                 }
  //               }

  //               setmastertranssuspensearray([...insertArray]);
  //               setaddsus("0.00");
  //               setusesus("0.00");
  //               settranssus("0.00");
  //               console.log("before asign amt insertArray", insertArray);
  //               console.log("trancomdetadjustedObjvalue", adjustedObj.value);

  //               insertArray.map((obj) => {
  //                 adjustedObj.value.map((adjObj, index) => {
  //                   if (
  //                     Number(adjObj.agreementID) === Number(obj.agreementid)
  //                   ) {
  //                     insertArray[index].newsuspense = adjObj.value;
  //                     insertArray[index].sustype = "Trans";
  //                   }
  //                 });
  //               });

  //               let newtransferedSuspense = 0;
  //               let ActualSuspenseAmount = 0;
  //               for (let i = 0; i < insertArray.length; i++) {
  //                 newtransferedSuspense =
  //                   newtransferedSuspense + Number(insertArray[i].newsuspense);
  //                 ActualSuspenseAmount =
  //                   ActualSuspenseAmount +
  //                   Number(insertArray[i].suspenseamount);
  //               }
  //               settotalnewsuspenseamount(newtransferedSuspense);
  //               let totaltransnewsusamount: number;
  //               totaltransnewsusamount = 0;
  //               for (let i = 0; i < insertArray.length; i++) {
  //                 totaltransnewsusamount =
  //                   totaltransnewsusamount + insertArray[i].newsuspense;
  //               }
  //               settotalnewsuspenseamount(totaltransnewsusamount);
  //               for (let i = 0; i < insertArray.length; i++) {
  //                 if (
  //                   insertArray[i].suspenseamount < insertArray[i].newsuspense
  //                 ) {
  //                   insertArray[i].sustype = "TransferAdd";
  //                 } else {
  //                   insertArray[i].sustype = "TransferUsed";
  //                 }
  //               }
  //               console.log("chansdfewr asign amt insertArray", insertArray);
  //               let newInsertArray: any = [];
  //               for (let i = 0; i < insertArray.length; i++) {
  //                 let indexValue: any = paygridvalues.findIndex(
  //                   (x) =>
  //                     String(x.Agreementid) ===
  //                     String(insertArray[i].agreementid)
  //                 );
  //                 console.log("sxcfdescfresindexValue", indexValue);

  //                 if (
  //                   insertArray[i].sustype == "TransferAdd" ||
  //                   "TransferUsed"
  //                 ) {
  //                   console.log("dfre45rt54ered34", insertArray[i]);
  //                   console.log(
  //                     "sdcfedcfr4erfpaygridvalues",
  //                     paygridvalues[indexValue]
  //                   );

  //                   if (
  //                     Number(insertArray[i].newsuspense) -
  //                       Number(paygridvalues[indexValue].transferOldSuspense) >
  //                     0
  //                   ) {
  //                     newInsertArray.push({
  //                       sustype: "TransferAdd",
  //                       agreementnumber: insertArray[i].agreementnumber,
  //                       agreementid: insertArray[i].agreementid,
  //                       schedure: insertArray[i].schedure,
  //                       paymentamount: insertArray[i].paymentamount,
  //                       epoamount: Number(insertArray[i].epoamount),
  //                       suspenseamount: insertArray[i].suspenseamount,
  //                       newsuspense:
  //                         Number(insertArray[i].newsuspense) -
  //                         Number(paygridvalues[indexValue].transferOldSuspense),
  //                       agreementType: insertArray[i].agreementType,
  //                     });
  //                     // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
  //                     // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
  //                     paygridvalues[indexValue].SuspenseAmount = Number(
  //                       insertArray[i].newsuspense
  //                     );
  //                   } else if (
  //                     Number(insertArray[i].newsuspense) -
  //                       Number(paygridvalues[indexValue].transferOldSuspense) <
  //                     0
  //                   ) {
  //                     newInsertArray.push({
  //                       sustype: "TransferUsed",
  //                       agreementnumber: insertArray[i].agreementnumber,
  //                       agreementid: insertArray[i].agreementid,
  //                       schedure: insertArray[i].schedure,
  //                       paymentamount: insertArray[i].paymentamount,
  //                       epoamount: Number(insertArray[i].epoamount),
  //                       suspenseamount: insertArray[i].suspenseamount,
  //                       newsuspense:
  //                         Number(
  //                           paygridvalues[indexValue].transferOldSuspense
  //                         ) - Number(insertArray[i].newsuspense),
  //                       agreementType: insertArray[i].agreementType,
  //                     });
  //                     // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
  //                     // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
  //                     paygridvalues[indexValue].SuspenseAmount = Number(
  //                       insertArray[i].newsuspense
  //                     );
  //                     const useFilterTransferBack =
  //                       masterusesuspensearray.filter(function (e) {
  //                         return (
  //                           e.sustype != "type" &&
  //                           Number(e.newsuspense) > 0 &&
  //                           String(e.agreementid) ==
  //                             String(insertArray[i].agreementid)
  //                         );
  //                       });
  //                     if (useFilterTransferBack?.length > 0) {
  //                       if (
  //                         Number(insertArray[i].newsuspense) <
  //                         Number(useFilterTransferBack[0].newsuspense)
  //                       ) {
  //                         masterusesuspensearray =
  //                           masterusesuspensearray.filter(function (e) {
  //                             return (
  //                               String(e.agreementid) !=
  //                               String(useFilterTransferBack[0].agreementid)
  //                             );
  //                           });
  //                         for (
  //                           let i = 0;
  //                           i < useFilterTransferBack.length;
  //                           i++
  //                         ) {
  //                           let index = paygridvalues.findIndex(
  //                             (x) =>
  //                               String(x.Agreementid) ===
  //                               String(useFilterTransferBack[0].agreementnumber)
  //                           );
  //                           paygridvalues[index].suspenseSub = 0;
  //                           // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
  //                           let receiptObj =
  //                             ReceiptCategoriesRequestParam.filter(
  //                               (e) =>
  //                                 e.agreementId ==
  //                                 Number(
  //                                   useFilterTransferBack[0].agreementnumber
  //                                 )
  //                             );
  //                           if (
  //                             useFilterTransferBack[0].agreementnumber ==
  //                             receiptObj[0].agreementId
  //                           ) {
  //                             receiptObj[0].suspenseSub = 0;
  //                           }
  //                         }
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //               const usefilterzero = masterusesuspensearray.filter(function (
  //                 e
  //               ) {
  //                 return e.sustype != "type" && Number(e.newsuspense) > 0;
  //               });
  //               const addfilterzero = masteraddsuspensearray.filter(function (
  //                 e
  //               ) {
  //                 return e.sustype != "type" && Number(e.newsuspense) > 0;
  //               });
  //               console.log("sdcvgfdrt4insertArray", insertArray);

  //               setmastertranssuspensearray(newInsertArray);
  //               settotalsuspensemaster([
  //                 ...usefilterzero,
  //                 ...addfilterzero,
  //                 ...newInsertArray,
  //               ]);

  //               // let addfilter = totalsuspensemaster.filter(function (e) {
  //               //   return e.sustype == "Add";
  //               // });
  //               // let usefilter = totalsuspensemaster.filter(function (e) {
  //               //   return e.sustype == "Used";
  //               // });

  //               let resAmount = 0.0;
  //               for (let i = 0; i < paygridvalues.length; i++) {
  //                 resAmount =
  //                   Number(paygridvalues[i].Amount) + Number(resAmount);
  //               }

  //               const addSuspenseAmount = addfilterzero.filter(function (e) {
  //                 return e.sustype == "Add" && Number(e.newsuspense) > 0;
  //               });
  //               const useSuspenseAmount = usefilterzero.filter(function (e) {
  //                 return e.sustype == "Used" && Number(e.newsuspense) > 0;
  //               });
  //               let addSusTotalAmount =
  //                 addSuspenseAmount.length == 0
  //                   ? 0.0
  //                   : addSuspenseAmount
  //                       .map((bill) => bill.newsuspense)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );

  //               let usedSusTotalAmount =
  //                 useSuspenseAmount.length == 0
  //                   ? 0.0
  //                   : useSuspenseAmount
  //                       .map((bill) => bill.newsuspense)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );
  //               let resTotalAmount = 0.0;
  //               if (Cardrequire.PaymentOrgin == "Pay By Phone") {
  //                 resAmount = Number(resAmount) + Number(convAmtWTax);
  //               }
  //               resTotalAmount =
  //                 totalduetendertype.length == 0
  //                   ? 0.0
  //                   : totalduetendertype
  //                       .map((bill) => bill.Amount)
  //                       .reduce((acc, bill) =>
  //                         (Number(bill) + Number(acc)).toFixed(2)
  //                       );

  //               if (Number(resTotalAmount) > Number(resAmount)) {
  //                 resetPayment(undefined);
  //                 setremainingamountdue(
  //                   Number(
  //                     (
  //                       Number(resAmount) -
  //                       Number(availableCreds) +
  //                       Number(addSusTotalAmount) -
  //                       Number(usedSusTotalAmount)
  //                     ).toFixed(2)
  //                   )
  //                 );
  //                 setchange("0.00");
  //                 setAcceptpaymentbuttonenable(true);
  //               } else {
  //                 if (
  //                   Number(resTotalAmount) >=
  //                   Number(
  //                     (
  //                       Number(resAmount) -
  //                       Number(availableCreds) +
  //                       Number(addSusTotalAmount) -
  //                       Number(usedSusTotalAmount)
  //                     ).toFixed(2)
  //                   )
  //                 ) {
  //                   setchange(
  //                     Number(resTotalAmount) -
  //                       Number(
  //                         (
  //                           Number(resAmount) -
  //                           Number(availableCreds) +
  //                           Number(addSusTotalAmount) -
  //                           Number(usedSusTotalAmount)
  //                         ).toFixed(2)
  //                       )
  //                   );
  //                   setremainingamountdue("0.00");
  //                   setRemaniningAmtStatic("0.00");
  //                   setAcceptpaymentbuttonenable(false);
  //                 } else {
  //                   setremainingamountdue(
  //                     Number(
  //                       (
  //                         Number(resAmount) -
  //                         Number(availableCreds) +
  //                         Number(addSusTotalAmount) -
  //                         Number(usedSusTotalAmount)
  //                       ).toFixed(2)
  //                     ) - Number(resTotalAmount)
  //                   );
  //                   setRemaniningAmtStatic(
  //                     Number(
  //                       (
  //                         Number(resAmount) -
  //                         Number(availableCreds) +
  //                         Number(addSusTotalAmount) -
  //                         Number(usedSusTotalAmount)
  //                       ).toFixed(2)
  //                     ) - Number(resTotalAmount)
  //                   );
  //                   setchange("0.00");
  //                   setAcceptpaymentbuttonenable(true);
  //                 }
  //               }
  //               console.log("transloggerchecker", insertArray);

  //               multipleAdjust();
  //               editCommitmentRes({
  //                 resArray: payGridData,
  //                 selectedAgr: collectedAgreementID,
  //               });
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   // payGridData.map((payGridObj, index) => {
  //   //   adjustCommitmentData?.commitmentDetail.map((commitmentObj: any) => {
  //   //     if (
  //   //       Number(payGridObj.Agreementid) == Number(commitmentObj.agreementID)
  //   //     ) {
  //   //       commitmentObj.adjustedInfo.map((adjustedObj) => {
  //   //         if (adjustedObj.type === "DDA") {
  //   //           // alert("DDA Reached");
  //   //           // let adjustDueRes: any = [];
  //   //           let coverageEndDateInfo: any = [];
  //   //           const adjustDueDatereq: any = [];
  //   //           let coverageEndDate = "";
  //   //           let scheduleRef = "";
  //   //           let policyStatus = "";
  //   //           if (actLDWArr.length > 0) {
  //   //             coverageEndDateInfo = actLDWArr.filter(
  //   //               (x) =>
  //   //                 String(x.agreementId) ===
  //   //                 String(payGridData[index].Agreementid)
  //   //             );
  //   //             if (coverageEndDateInfo.length > 0) {
  //   //               if (Number(coverageEndDateInfo[0].action) == 2) {
  //   //                 const currentdate: Date = new Date(
  //   //                   new Date().toLocaleString("en-US", {
  //   //                     timeZone: "America/Chicago",
  //   //                   })
  //   //                 );
  //   //                 const current_date = new Date(
  //   //                   currentdate.getFullYear(),
  //   //                   currentdate.getMonth(),
  //   //                   currentdate.getDate()
  //   //                 );
  //   //                 coverageEndDate = moment(current_date).format("MM/DD/YYYY");
  //   //               } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //   //                 coverageEndDate = "12/31/2099";
  //   //                 if (
  //   //                   Number(
  //   //                     payGridData[index].agreementRateDetails.perDayLdw
  //   //                   ) == 0
  //   //                 ) {
  //   //                   payGridData[index].agreementRateDetails.perDayLdw = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) / 7
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.perDayLdwTax = (
  //   //                     (Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) /
  //   //                       7) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.biWeeklyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.biWeeklyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.monthlyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.monthlyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.semiMonthlyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.semimonthlyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.semiMonthlyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.semimonthlyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                 }
  //   //               }
  //   //             }
  //   //           }
  //   //           if (coverageEndDate == "") {
  //   //             coverageEndDate = String(payGridData[index].coverageEndDate);
  //   //           }
  //   //           if (
  //   //             payGridData[index].editSchedule != "" &&
  //   //             payGridData[index].editSchedule != null
  //   //           ) {
  //   //             scheduleRef = payGridData[index].editSchedule;
  //   //           } else {
  //   //             scheduleRef =
  //   //               payGridData[index].agreementRateDetails.scheduleRefCode;
  //   //           }
  //   //           if (actPolicyArr.length > 0) {
  //   //             const policyChangeArrInfo = actPolicyArr.filter(
  //   //               (x) =>
  //   //                 String(x.agreementId) ===
  //   //                 String(payGridData[index].Agreementid)
  //   //             );
  //   //             if (policyChangeArrInfo.length > 0) {
  //   //               if (Number(policyChangeArrInfo[0].action) == 2) {
  //   //                 policyStatus = "I";
  //   //               } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //   //                 policyStatus = "A";
  //   //                 if (
  //   //                   Number(
  //   //                     payGridData[index].agreementRateDetails.perDayPolicy
  //   //                   ) == 0
  //   //                 ) {
  //   //                   payGridData[index].agreementRateDetails.perDayPolicy = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) / 7
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.perDayPolicyTax =
  //   //                     (
  //   //                       (Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) /
  //   //                         7) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyPolicy =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyPolicyTax =
  //   //                     (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.biWeeklyPolicy =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.biWeeklyPolicyTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyPolicy =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.monthlyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyPolicyTax =
  //   //                     (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.monthlyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.semiMonthlyPolicy = Number(
  //   //                     coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                       .scheduleRates.semimonthlyRate
  //   //                   ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.semiMonthlyPolicyTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.semimonthlyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                 }
  //   //               }
  //   //             }
  //   //           }
  //   //           if (policyStatus == "" || policyStatus == null) {
  //   //             policyStatus = String(payGridData[index].policyStatus);
  //   //           }
  //   //           const res = {
  //   //             agreementId: String(payGridData[index].Agreementid),
  //   //             agreementRateDetails: payGridData[index].agreementRateDetails,
  //   //             currentDueDate: String(payGridData[index].CurrentDueDate),
  //   //             dueDate: String(payGridData[index].Nextduedate),
  //   //             coverageEndDate: coverageEndDate,
  //   //             taxRate: String(payGridData[index].taxRate),
  //   //             lateFee: String(payGridData[index].lateFee),
  //   //             lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //   //             policyStatus: String(policyStatus),
  //   //             epoAmount: String(payGridData[index].EPOAmount),
  //   //             epoTax: String(payGridData[index].EPOTax),
  //   //             sacDate: String(payGridData[index].SAC_Date),
  //   //             isLdwAllowed: String(payGridData[index].isLdwAllowed),
  //   //             agreementNumber: String(payGridData[index].AgreementNumber),
  //   //             carryRent: String(payGridData[index].carryRentAmountUsed),
  //   //             carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //   //             scheduleRefCode: scheduleRef,
  //   //           };
  //   //           adjustDueDatereq.push(res);
  //   //           const calcireq = {
  //   //             rateResponse: adjustDueDatereq,
  //   //             dueDate: adjustedObj.value,
  //   //           };
  //   //           console.log("before val value of hr e payGridData", payGridData);

  //   //           (async () => {
  //   //             let resFromAdjCalci = await AdjustDueDatecalci(calcireq);
  //   //             console.log("resFromAdjCalci from calc", resFromAdjCalci);
  //   //             console.log("value of ther payGridData check", payGridData);
  //   //             let nextduedate;
  //   //             if (
  //   //               resFromAdjCalci[0].nextDueDate != "SAC" &&
  //   //               resFromAdjCalci[0].nextDueDate != "PIF" &&
  //   //               resFromAdjCalci[0].nextDueDate != "EPO"
  //   //             ) {
  //   //               nextduedate = moment(resFromAdjCalci[0].nextDueDate).format(
  //   //                 "MM/DD/YYYY"
  //   //               );
  //   //             } else {
  //   //               nextduedate = resFromAdjCalci[0].nextDueDate;
  //   //             }
  //   //             (payGridData[index].Nextduedate = nextduedate),
  //   //               (payGridData[index].Amount = Number(
  //   //                 (
  //   //                   Number(resFromAdjCalci[0].totalAmount) +
  //   //                   Number(resFromAdjCalci[0].totalTax)
  //   //                 ).toFixed(2)
  //   //               )),
  //   //               // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
  //   //               (payGridData[index].Taxamount = Number(
  //   //                 resFromAdjCalci[0].totalTax
  //   //               ));
  //   //             payGridData[index].policyAmount = Number(
  //   //               resFromAdjCalci[0].policyAmount
  //   //             );
  //   //             // props.adjustDueDateReq[i].editSchedule=selectedSchedule
  //   //             (payGridData[index].ldwTaxAmount = Number(
  //   //               resFromAdjCalci[0].ldwTaxAmount
  //   //             )),
  //   //               (payGridData[index].agrLdwAmount = Number(
  //   //                 resFromAdjCalci[0].ldwAmount
  //   //               ));
  //   //             payGridData[index].policyTaxAmount = Number(
  //   //               resFromAdjCalci[0].policyTaxAmount
  //   //             );
  //   //             payGridData[index].agreementRate = Number(
  //   //               resFromAdjCalci[0].agreementRate
  //   //             );
  //   //             payGridData[index].rpayTaxAmount = Number(
  //   //               resFromAdjCalci[0].rpayTaxAmount
  //   //             );
  //   //             payGridData[index].Agreementtype =
  //   //               resFromAdjCalci[0].agreementType;
  //   //             // payGridData[index].Agreementtype == "Club"
  //   //             //   ? "Club"
  //   //             //   : resFromAdjCalci[0].agreementType;
  //   //             // }
  //   //             console.log("after value of hr e payGridData", payGridData);
  //   //             // adjustDueDateSelectedAgre.push(payGridData[index].Agreementid);
  //   //             // setadjustDueDateSelectedAgre([payGridData[index].Agreementid]);
  //   //             // setadjustDueDateSelectedAgre([payGridData[index].Agreementid]);
  //   //             // resArray: [payGridData[index]],
  //   //             // selectedAgr: [payGridData[index].Agreementid],
  //   //             editCommitmentRes({
  //   //               resArray: payGridData,
  //   //               adjustEditflag: 1,
  //   //               selectedAgr: collectedAgreementID,
  //   //               adjustedAgreement: payGridData[index].Agreementid,
  //   //             });
  //   //             console.log(
  //   //               "value in ihte obtaine adjustDueRes",
  //   //               resFromAdjCalci
  //   //             );
  //   //           })();
  //   //         } else if (adjustedObj.type === "FTT") {
  //   //           // alert("Apply Free Time");
  //   //           const nextduedate = moment(adjustedObj.value.nextDueDate).format(
  //   //             "MM/DD/YYYY"
  //   //           );
  //   //           const currentduedate = moment(
  //   //             payGridData[index].CurrentDueDate
  //   //           ).format("MM/DD/YYYY");
  //   //           const date1 = new Date(currentduedate);
  //   //           const date2 = new Date(nextduedate);
  //   //           const time_difference = date2.getTime() - date1.getTime();
  //   //           const days_difference = time_difference / (1000 * 3600 * 24);
  //   //           if (adjustedObj.value.waiveOptService !== null) {
  //   //             // alert("reached the if condition of not null");
  //   //             payGridData[index].Amount = 0.0;
  //   //             payGridData[index].Taxamount = 0.0;
  //   //             payGridData[index].policyAmount = 0.0;
  //   //             payGridData[index].ldwTaxAmount = 0.0;
  //   //             payGridData[index].agrLdwAmount = 0.0;
  //   //             payGridData[index].policyTaxAmount = 0.0;
  //   //             payGridData[index].agreementRate = 0.0;
  //   //             payGridData[index].rpayTaxAmount = 0.0;
  //   //             payGridData[index].Latelast10 = 0.0;
  //   //             payGridData[index].lateFee = 0.0;
  //   //             payGridData[index].lFeeTaxAmount = 0.0;
  //   //             payGridData[index].Nextduedate = nextduedate;
  //   //             payGridData[index].PromoFreeDaysApplied =
  //   //               adjustedObj.value.freeTimeType == "PROMO"
  //   //                 ? Number(days_difference)
  //   //                 : payGridData[index].PromoFreeDaysApplied;
  //   //             payGridData[index].FreeTime = {
  //   //               freeTimeType: adjustedObj.value.freeTimeType,
  //   //               freeTimeDays: Number(days_difference),
  //   //               isOptServiceWaived:
  //   //                 adjustedObj.value.waiveOptService !== null ? true : false,
  //   //             };
  //   //             // resArray: [payGridData[index]],
  //   //             // selectedAgr: [payGridData[index].Agreementid],

  //   //             editCommitmentRes({
  //   //               resArray: payGridData,
  //   //               selectedAgr: collectedAgreementID,
  //   //             });
  //   //           } else {
  //   //             // alert("Free Time Reached");
  //   //             let coverageEndDateInfo: any = [];
  //   //             const adjustDueDatereq: any = [];
  //   //             let coverageEndDate = "";
  //   //             let policyStatus = "";
  //   //             if (actLDWArr.length > 0) {
  //   //               coverageEndDateInfo = actLDWArr.filter(
  //   //                 (x) =>
  //   //                   String(x.agreementId) ===
  //   //                   String(payGridData[index].Agreementid)
  //   //               );
  //   //               if (coverageEndDateInfo.length > 0) {
  //   //                 if (Number(coverageEndDateInfo[0].action) == 2) {
  //   //                   coverageEndDate = moment().format("MM/DD/YYYY");
  //   //                 } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //   //                   coverageEndDate = "12/31/2099";
  //   //                   if (
  //   //                     Number(
  //   //                       payGridData[index].agreementRateDetails.perDayLdw
  //   //                     ) == 0
  //   //                   ) {
  //   //                     payGridData[index].agreementRateDetails.perDayLdw = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) / 7
  //   //                     ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.perDayLdwTax = (
  //   //                       (Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) /
  //   //                         7) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.weeklyLdw =
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.weeklyLdwTax = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.biWeeklyLdw =
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.biweeklyRate
  //   //                       ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.biWeeklyLdwTax =
  //   //                       (
  //   //                         Number(
  //   //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                             .scheduleRates.biweeklyRate
  //   //                         ) *
  //   //                         Number(
  //   //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                             .ldwTaxRate
  //   //                         )
  //   //                       ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.monthlyLdw =
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.monthlyRate
  //   //                       ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.monthlyLdwTax =
  //   //                       (
  //   //                         Number(
  //   //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                             .scheduleRates.monthlyRate
  //   //                         ) *
  //   //                         Number(
  //   //                           coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                             .ldwTaxRate
  //   //                         )
  //   //                       ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.semiMonthlyLdw =
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.semimonthlyRate
  //   //                       ).toFixed(2);
  //   //                     payGridData[
  //   //                       index
  //   //                     ].agreementRateDetails.semiMonthlyLdwTax = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .scheduleRates.semimonthlyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   }
  //   //                 }
  //   //               }
  //   //             }
  //   //             if (coverageEndDate == "") {
  //   //               coverageEndDate = String(payGridData[index].coverageEndDate);
  //   //             }
  //   //             if (actPolicyArr.length > 0) {
  //   //               // props.ldwChangeArr
  //   //               const policyChangeArrInfo = actPolicyArr.filter(
  //   //                 (x) =>
  //   //                   String(x.agreementId) ===
  //   //                   String(payGridData[index].Agreementid)
  //   //               );
  //   //               if (policyChangeArrInfo.length > 0) {
  //   //                 if (Number(policyChangeArrInfo[0].action) == 2) {
  //   //                   policyStatus = "I";
  //   //                 } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //   //                   policyStatus = "A";
  //   //                   if (
  //   //                     Number(
  //   //                       payGridData[index].agreementRateDetails.perDayPolicy
  //   //                     ) == 0
  //   //                   ) {
  //   //                     payGridData[index].agreementRateDetails.perDayPolicy = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) / 7
  //   //                     ).toFixed(2);
  //   //                     payGridData[
  //   //                       index
  //   //                     ].agreementRateDetails.perDayPolicyTax = (
  //   //                       (Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) /
  //   //                         7) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.weeklyPolicy =
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ).toFixed(2);
  //   //                     payGridData[
  //   //                       index
  //   //                     ].agreementRateDetails.weeklyPolicyTax = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.biWeeklyPolicy =
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.biweeklyRate
  //   //                       ).toFixed(2);
  //   //                     payGridData[
  //   //                       index
  //   //                     ].agreementRateDetails.biWeeklyPolicyTax = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.biweeklyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                     payGridData[index].agreementRateDetails.monthlyPolicy =
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.monthlyRate
  //   //                       ).toFixed(2);
  //   //                     payGridData[
  //   //                       index
  //   //                     ].agreementRateDetails.monthlyPolicyTax = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.monthlyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                     payGridData[
  //   //                       index
  //   //                     ].agreementRateDetails.semiMonthlyPolicy = Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.semimonthlyRate
  //   //                     ).toFixed(2);
  //   //                     payGridData[
  //   //                       index
  //   //                     ].agreementRateDetails.semiMonthlyPolicyTax = (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.semimonthlyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   }
  //   //                 }
  //   //               }
  //   //             }
  //   //             if (policyStatus == "") {
  //   //               policyStatus = String(payGridData[index].policyStatus);
  //   //             }
  //   //             let scheduleRef = "";
  //   //             if (
  //   //               payGridData[index].editSchedule != "" &&
  //   //               payGridData[index].editSchedule != null
  //   //             ) {
  //   //               scheduleRef = payGridData[index].editSchedule;
  //   //             } else {
  //   //               scheduleRef =
  //   //                 payGridData[index].agreementRateDetails.scheduleRefCode;
  //   //             }
  //   //             const res = {
  //   //               agreementId: String(payGridData[index].Agreementid),
  //   //               agreementRateDetails: payGridData[index].agreementRateDetails,
  //   //               currentDueDate: String(payGridData[index].CurrentDueDate),
  //   //               dueDate: String(payGridData[index].Nextduedate),
  //   //               coverageEndDate: coverageEndDate,
  //   //               taxRate: String(payGridData[index].taxRate),
  //   //               lateFee: String(payGridData[index].lateFee),
  //   //               lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //   //               policyStatus: String(policyStatus),
  //   //               epoAmount: String(payGridData[index].EPOAmount),
  //   //               epoTax: String(payGridData[index].EPOTax),
  //   //               sacDate: String(payGridData[index].SAC_Date),
  //   //               isLdwAllowed: String(payGridData[index].isLdwAllowed),
  //   //               agreementNumber: String(payGridData[index].AgreementNumber),
  //   //               carryRent: String(payGridData[index].carryRentAmountUsed),
  //   //               carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //   //               scheduleRefCode: scheduleRef,
  //   //             };
  //   //             adjustDueDatereq.push(res);
  //   //             console.log(
  //   //               "adjustDueDatereq value in push hsv",
  //   //               adjustDueDatereq
  //   //             );

  //   //             const calcireq = {
  //   //               rateResponse: adjustDueDatereq,
  //   //               dueDate: nextduedate,
  //   //             };
  //   //             (async () => {
  //   //               let adjustDueRes = await AdjustDueDatecalci(calcireq);
  //   //               console.log(
  //   //                 "value from the jcdu vasd adjustDueRes",
  //   //                 adjustDueRes
  //   //               );
  //   //               let nextduedate;
  //   //               if (
  //   //                 adjustDueRes[0].nextDueDate != "SAC" &&
  //   //                 adjustDueRes[0].nextDueDate != "PIF" &&
  //   //                 adjustDueRes[0].nextDueDate != "EPO"
  //   //               ) {
  //   //                 nextduedate = moment(adjustDueRes[0].nextDueDate).format(
  //   //                   "MM/DD/YYYY"
  //   //                 );
  //   //               } else {
  //   //                 nextduedate = adjustDueRes[0].nextDueDate;
  //   //               }
  //   //               payGridData[index].Nextduedate = nextduedate;
  //   //               payGridData[index].Amount =
  //   //                 Number(adjustDueRes[0].policyAmount) +
  //   //                 Number(adjustDueRes[0].ldwAmount) +
  //   //                 Number(adjustDueRes[0].ldwTaxAmount) +
  //   //                 Number(adjustDueRes[0].policyTaxAmount);
  //   //               payGridData[index].Taxamount =
  //   //                 Number(adjustDueRes[0].ldwTaxAmount) +
  //   //                 Number(adjustDueRes[0].policyTaxAmount);
  //   //               payGridData[index].policyAmount = Number(
  //   //                 adjustDueRes[0].policyAmount
  //   //               );
  //   //               payGridData[index].ldwTaxAmount = Number(
  //   //                 adjustDueRes[0].ldwTaxAmount
  //   //               );
  //   //               payGridData[index].agrLdwAmount = Number(
  //   //                 adjustDueRes[0].ldwAmount
  //   //               );
  //   //               payGridData[index].policyTaxAmount = Number(
  //   //                 adjustDueRes[0].policyTaxAmount
  //   //               );
  //   //               payGridData[index].agreementRate = 0.0;
  //   //               payGridData[index].rpayTaxAmount = 0.0;
  //   //               payGridData[index].PromoFreeDaysApplied =
  //   //                 adjustedObj.value.freeTimeType == "PROMO"
  //   //                   ? Number(days_difference)
  //   //                   : payGridData[index].PromoFreeDaysApplied;
  //   //               payGridData[index].Latelast10 = 0.0;
  //   //               payGridData[index].lateFee = 0.0;
  //   //               payGridData[index].lFeeTaxAmount = 0.0;
  //   //               payGridData[index].FreeTime = {
  //   //                 freeTimeType: adjustedObj.value.freeTimeType,
  //   //                 freeTimeDays: Number(days_difference),
  //   //                 isOptServiceWaived:
  //   //                   adjustedObj.value.waiveOptService !== null ? true : false,
  //   //               };
  //   //               // resArray: [payGridData[index]],
  //   //               // selectedAgr: [payGridData[index].Agreementid],
  //   //               editCommitmentRes({
  //   //                 resArray: payGridData,
  //   //                 selectedAgr: collectedAgreementID,
  //   //               });
  //   //             })();
  //   //           }
  //   //         } else if (adjustedObj.type === "TOS") {
  //   //           console.log("reached Opt.Service else", index);

  //   //           if (adjustedObj.value === "LDW") {
  //   //             selectedGrid = index;
  //   //             (async () => {
  //   //               const callLoadOtherAction: any = await commitmentLoadAction(
  //   //                 index
  //   //               );
  //   //               console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //   //               if (callLoadOtherAction !== undefined) {
  //   //                 const terminateLdw_Resp: any = await LDW_apiCall(
  //   //                   2,
  //   //                   callLoadOtherAction,
  //   //                   index
  //   //                 );
  //   //                 editCommitmentRes({
  //   //                   resArray: payGridData,
  //   //                   selectedAgr: collectedAgreementID,
  //   //                 });
  //   //                 console.log(terminateLdw_Resp);
  //   //               }
  //   //             })();
  //   //           } else if (adjustedObj.value === "Policy") {
  //   //             selectedGrid = index;
  //   //             (async () => {
  //   //               const callLoadOtherAction: any = await commitmentLoadAction(
  //   //                 index
  //   //               );
  //   //               console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //   //               if (callLoadOtherAction !== undefined) {
  //   //                 const terminateLdw_Resp: any = await Policy_apiCall(
  //   //                   2,
  //   //                   callLoadOtherAction,
  //   //                   index
  //   //                 );
  //   //                 editCommitmentRes({
  //   //                   resArray: payGridData,
  //   //                   selectedAgr: collectedAgreementID,
  //   //                 });
  //   //                 console.log(terminateLdw_Resp);
  //   //               }
  //   //             })();
  //   //           }
  //   //         } else if (adjustedObj.type === "AOS") {
  //   //           console.log("reached Opt.Service else", index);
  //   //           if (adjustedObj.value === "LDW") {
  //   //             selectedGrid = index;
  //   //             (async () => {
  //   //               const callLoadOtherAction: any = await commitmentLoadAction(
  //   //                 index
  //   //               );
  //   //               console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //   //               console.log("updated index value check in LDW", index);

  //   //               if (callLoadOtherAction !== undefined) {
  //   //                 const terminateLdw_Resp: any = await LDW_apiCall(
  //   //                   1,
  //   //                   callLoadOtherAction,
  //   //                   index
  //   //                 );
  //   //                 editCommitmentRes({
  //   //                   resArray: payGridData,
  //   //                   selectedAgr: collectedAgreementID,
  //   //                 });
  //   //                 console.log(terminateLdw_Resp);
  //   //               }
  //   //             })();
  //   //           } else if (adjustedObj.value === "Policy") {
  //   //             selectedGrid = index;
  //   //             (async () => {
  //   //               const callLoadOtherAction: any = await commitmentLoadAction(
  //   //                 index
  //   //               );
  //   //               console.log("sxmnchdjsdf8eurjfndhe34ur", callLoadOtherAction);
  //   //               console.log("updated index value check in Policy", index);
  //   //               if (callLoadOtherAction !== undefined) {
  //   //                 const terminateLdw_Resp: any = await Policy_apiCall(
  //   //                   1,
  //   //                   callLoadOtherAction,
  //   //                   index
  //   //                 );
  //   //                 console.log("Policy-Activate done", terminateLdw_Resp);
  //   //                 editCommitmentRes({
  //   //                   resArray: payGridData,
  //   //                   selectedAgr: collectedAgreementID,
  //   //                 });
  //   //               }
  //   //             })();
  //   //           }
  //   //         } else if (adjustedObj.type === "AS") {
  //   //           let insertArray: any[] = [];
  //   //           insertArray.push({
  //   //             sustype:
  //   //               Number(payGridData[index].suspenseAdd) > 0 ? "Add" : "type",
  //   //             agreementnumber: payGridData[index].Agreementid,
  //   //             agreementid: payGridData[index].AgreementNumber,
  //   //             schedure: payGridData[index].Schedule,
  //   //             paymentamount: payGridData[index].Amount,
  //   //             epoamount: payGridData[index].EPOAmount,
  //   //             suspenseamount: Number(
  //   //               payGridData[index].SuspenseAmount
  //   //             ).toFixed(2),
  //   //             newsuspense: Number(payGridData[index].suspenseAdd).toFixed(2),
  //   //             agreementType: payGridData[index].Agreementtype,
  //   //           });
  //   //           setaddsus("0.00");
  //   //           setusesus("0.00");
  //   //           settranssus("0.00");
  //   //           setaddsus(adjustedObj.value);
  //   //           insertArray[0].newsuspense = adjustedObj.value;
  //   //           insertArray[0].sustype = "Add"; // Check This
  //   //           setmasteraddsuspensearray(insertArray);

  //   //           const addfilterzero = insertArray.filter(function (e) {
  //   //             return e.sustype != "type" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           const usefilterzero = masterusesuspensearray.filter(function (e) {
  //   //             return e.sustype != "type" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           // if (totalsuspensemaster)
  //   //           console.log("sdfffr5555addfilterzero", addfilterzero);
  //   //           console.log("sdfffr55698addfilterzero", usefilterzero);

  //   //           settotalsuspensemaster([...addfilterzero, ...usefilterzero]);
  //   //           for (let i = 0; i < addfilterzero.length; i++) {
  //   //             let index = paygridvalues.findIndex(
  //   //               (x) =>
  //   //                 String(x.Agreementid) ===
  //   //                 String(addfilterzero[i].agreementnumber)
  //   //             );
  //   //             paygridvalues[index].suspenseAdd = Number(
  //   //               addfilterzero[i].newsuspense
  //   //             );
  //   //             // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(addfilterzero[i].newsuspense)
  //   //             let receiptObj = ReceiptCategoriesRequestParam.filter(
  //   //               (e) =>
  //   //                 e.agreementId == Number(addfilterzero[i].agreementnumber)
  //   //             );
  //   //             let receiptObjaccept = acceptPaymentParam.filter(
  //   //               (e) =>
  //   //                 e.agreementId == Number(addfilterzero[i].agreementnumber)
  //   //             );
  //   //             if (receiptObj[0] != undefined) {
  //   //               receiptObj[0].suspenseAdd = Number(
  //   //                 addfilterzero[i].newsuspense
  //   //               );
  //   //             }
  //   //             if (
  //   //               String(addfilterzero[i].agreementnumber) ==
  //   //               String(receiptObjaccept[0].agreementId)
  //   //             ) {
  //   //               acceptPaymentParam[i].addTransferSusFlag = 1;
  //   //             }
  //   //           }
  //   //           setReceiptCategory(ReceiptCategoriesRequestParam);
  //   //           let totalsusamount: number;
  //   //           totalsusamount = 0;
  //   //           for (let i = 0; i < addfilterzero.length; i++) {
  //   //             totalsusamount =
  //   //               totalsusamount + Number(addfilterzero[i].newsuspense);
  //   //           }
  //   //           // setsuspensePopupClass(
  //   //           //   `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  //   //           // );
  //   //           setaddsuspense(false);
  //   //           let tenderedAmount = 0;
  //   //           for (let i = 0; i < tendertypeglobalarray.length; i++) {
  //   //             tenderedAmount =
  //   //               tenderedAmount + Number(tendertypeglobalarray[i].Amount);
  //   //           }
  //   //           let resAmount = 0.0;
  //   //           for (let i = 0; i < checkedAgr.length; i++) {
  //   //             resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
  //   //           }
  //   //           const addSuspenseAmount = addfilterzero.filter(function (e) {
  //   //             return e.sustype == "Add" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           const useSuspenseAmount = usefilterzero.filter(function (e) {
  //   //             return e.sustype == "Used" && Number(e.newsuspense) > 0;
  //   //           });

  //   //           let addSusTotalAmount =
  //   //             addSuspenseAmount.length == 0
  //   //               ? 0.0
  //   //               : addSuspenseAmount
  //   //                   .map((bill) => bill.newsuspense)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );
  //   //           let usedSusTotalAmount =
  //   //             useSuspenseAmount.length == 0
  //   //               ? 0.0
  //   //               : useSuspenseAmount
  //   //                   .map((bill) => bill.newsuspense)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );

  //   //           let resTotalAmount = 0.0;
  //   //           if (Cardrequire.PaymentOrgin == "Pay By Phone") {
  //   //             resAmount = Number(resAmount) + Number(convAmtWTax);
  //   //           }
  //   //           resAmount = Number(resAmount) - Number(carryRentAmount);
  //   //           resTotalAmount =
  //   //             totalduetendertype.length == 0
  //   //               ? 0.0
  //   //               : totalduetendertype
  //   //                   .map((bill) => bill.Amount)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );
  //   //           if (Number(resTotalAmount) > Number(resAmount)) {
  //   //             resetPayment(undefined);
  //   //             setremainingamountdue(
  //   //               Number(
  //   //                 (
  //   //                   Number(resAmount) -
  //   //                   Number(availableCreds) +
  //   //                   Number(addSusTotalAmount) -
  //   //                   Number(usedSusTotalAmount)
  //   //                 ).toFixed(2)
  //   //               )
  //   //             );
  //   //             setchange("0.00");
  //   //             setAcceptpaymentbuttonenable(true);
  //   //           } else {
  //   //             if (
  //   //               Number(resTotalAmount) >=
  //   //               Number(
  //   //                 (
  //   //                   Number(resAmount) -
  //   //                   Number(availableCreds) +
  //   //                   Number(addSusTotalAmount) -
  //   //                   Number(usedSusTotalAmount)
  //   //                 ).toFixed(2)
  //   //               )
  //   //             ) {
  //   //               setchange(
  //   //                 Number(resTotalAmount) -
  //   //                   Number(
  //   //                     (
  //   //                       Number(resAmount) -
  //   //                       Number(availableCreds) +
  //   //                       Number(addSusTotalAmount) -
  //   //                       Number(usedSusTotalAmount)
  //   //                     ).toFixed(2)
  //   //                   )
  //   //               );
  //   //               setremainingamountdue("0.00");
  //   //               setRemaniningAmtStatic("0.00");
  //   //               setAcceptpaymentbuttonenable(false);
  //   //             } else {
  //   //               setremainingamountdue(
  //   //                 Number(
  //   //                   (
  //   //                     Number(resAmount) -
  //   //                     Number(availableCreds) +
  //   //                     Number(addSusTotalAmount) -
  //   //                     Number(usedSusTotalAmount)
  //   //                   ).toFixed(2)
  //   //                 ) - Number(resTotalAmount)
  //   //               );
  //   //               setRemaniningAmtStatic(
  //   //                 Number(
  //   //                   (
  //   //                     Number(resAmount) -
  //   //                     Number(availableCreds) +
  //   //                     Number(addSusTotalAmount) -
  //   //                     Number(usedSusTotalAmount)
  //   //                   ).toFixed(2)
  //   //                 ) - Number(resTotalAmount)
  //   //               );
  //   //               setchange("0.00");
  //   //               setAcceptpaymentbuttonenable(true);
  //   //             }
  //   //           }

  //   //           settotaldue(resAmount);
  //   //           multipleAdjust();
  //   //           editCommitmentRes({
  //   //             resArray: payGridData,
  //   //             selectedAgr: collectedAgreementID,
  //   //           });
  //   //         } else if (adjustedObj.type === "US") {
  //   //           let insertArray: any[] = [];
  //   //           insertArray.push({
  //   //             sustype:
  //   //               Number(payGridData[index].suspenseSub) > 0 ? "Used" : "type",
  //   //             agreementnumber: payGridData[index].Agreementid,
  //   //             agreementid: payGridData[index].AgreementNumber,
  //   //             schedure: payGridData[index].Schedule,
  //   //             paymentamount: payGridData[index].Amount,
  //   //             epoamount: payGridData[index].EPOAmount,
  //   //             suspenseamount: Number(
  //   //               payGridData[index].SuspenseAmount
  //   //             ).toFixed(2),
  //   //             newsuspense: Number(payGridData[index].suspenseSub).toFixed(2),
  //   //             agreementType: payGridData[index].Agreementtype,
  //   //           });
  //   //           console.log("che the insertArray pushed value", insertArray);

  //   //           setaddsus("0.00");
  //   //           setusesus("0.00");
  //   //           settranssus("0.00");
  //   //           setusesus(adjustedObj.value);
  //   //           insertArray[0].newsuspense = adjustedObj.value;
  //   //           insertArray[0].sustype = "Used";
  //   //           console.log("changed the insertArray pushed value", insertArray);
  //   //           setmasterusesuspensearray(insertArray);
  //   //           const addfilterzero = masteraddsuspensearray.filter(function (e) {
  //   //             return e.sustype != "type" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           console.log("filaddfilterzero", addfilterzero);

  //   //           const usefilterzero = insertArray.filter(function (e) {
  //   //             return e.sustype != "type" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           console.log("filusefilterzero", usefilterzero);

  //   //           settotalsuspensemaster([...addfilterzero, ...usefilterzero]);

  //   //           for (let i = 0; i < usefilterzero.length; i++) {
  //   //             let index = paygridvalues.findIndex(
  //   //               (x) =>
  //   //                 String(x.Agreementid) ===
  //   //                 String(usefilterzero[i].agreementnumber)
  //   //             );
  //   //             paygridvalues[index].suspenseSub = Number(
  //   //               usefilterzero[i].newsuspense
  //   //             );
  //   //             // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
  //   //             console.log("for count");
  //   //             if (
  //   //               usefilterzero[i].agreementnumber ==
  //   //               ReceiptCategoriesRequestParam[i].agreementId
  //   //             ) {
  //   //               ReceiptCategoriesRequestParam[i].suspenseSub = Number(
  //   //                 usefilterzero[i].newsuspense
  //   //               );
  //   //             }
  //   //           }
  //   //           setReceiptCategory(ReceiptCategoriesRequestParam);
  //   //           let totalsusamountuse: number;
  //   //           totalsusamountuse = 0;
  //   //           for (let i = 0; i < usefilterzero.length; i++) {
  //   //             totalsusamountuse =
  //   //               totalsusamountuse + Number(usefilterzero[i].newsuspense);
  //   //           }
  //   //           let resAmount = 0.0;
  //   //           for (let i = 0; i < checkedAgr.length; i++) {
  //   //             resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
  //   //           }
  //   //           const addSuspenseAmount = addfilterzero.filter(function (e) {
  //   //             return e.sustype == "Add" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           const useSuspenseAmount = usefilterzero.filter(function (e) {
  //   //             return e.sustype == "Used" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           let addSusTotalAmount =
  //   //             addSuspenseAmount.length == 0
  //   //               ? 0.0
  //   //               : addSuspenseAmount
  //   //                   .map((bill) => bill.newsuspense)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );
  //   //           let usedSusTotalAmount =
  //   //             useSuspenseAmount.length == 0
  //   //               ? 0.0
  //   //               : useSuspenseAmount
  //   //                   .map((bill) => bill.newsuspense)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );

  //   //           let resTotalAmount = 0.0;
  //   //           if (Cardrequire.PaymentOrgin == "Pay By Phone") {
  //   //             resAmount = Number(resAmount) + Number(convAmtWTax);
  //   //           }
  //   //           resAmount = Number(resAmount) - Number(carryRentAmount);
  //   //           resTotalAmount =
  //   //             totalduetendertype.length == 0
  //   //               ? 0.0
  //   //               : totalduetendertype
  //   //                   .map((bill) => bill.Amount)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );

  //   //           if (Number(resTotalAmount) > Number(resAmount)) {
  //   //             resetPayment(undefined);
  //   //             setremainingamountdue(
  //   //               Number(
  //   //                 (
  //   //                   Number(resAmount) -
  //   //                   Number(availableCreds) +
  //   //                   Number(addSusTotalAmount) -
  //   //                   Number(usedSusTotalAmount)
  //   //                 ).toFixed(2)
  //   //               )
  //   //             );
  //   //             setchange("0.00");
  //   //             setAcceptpaymentbuttonenable(true);
  //   //           } else {
  //   //             if (
  //   //               Number(resTotalAmount) >=
  //   //               Number(
  //   //                 (
  //   //                   Number(resAmount) -
  //   //                   Number(availableCreds) +
  //   //                   Number(addSusTotalAmount) -
  //   //                   Number(usedSusTotalAmount)
  //   //                 ).toFixed(2)
  //   //               )
  //   //             ) {
  //   //               setchange(
  //   //                 Number(resTotalAmount) -
  //   //                   Number(
  //   //                     (
  //   //                       Number(resAmount) -
  //   //                       Number(availableCreds) +
  //   //                       Number(addSusTotalAmount) -
  //   //                       Number(usedSusTotalAmount)
  //   //                     ).toFixed(2)
  //   //                   )
  //   //               );
  //   //               setremainingamountdue("0.00");
  //   //               setRemaniningAmtStatic("0.00");
  //   //               setAcceptpaymentbuttonenable(false);
  //   //             } else {
  //   //               setremainingamountdue(
  //   //                 Number(
  //   //                   (
  //   //                     Number(resAmount) -
  //   //                     Number(availableCreds) +
  //   //                     Number(addSusTotalAmount) -
  //   //                     Number(usedSusTotalAmount)
  //   //                   ).toFixed(2)
  //   //                 ) - Number(resTotalAmount)
  //   //               );
  //   //               setRemaniningAmtStatic(
  //   //                 Number(
  //   //                   (
  //   //                     Number(resAmount) -
  //   //                     Number(availableCreds) +
  //   //                     Number(addSusTotalAmount) -
  //   //                     Number(usedSusTotalAmount)
  //   //                   ).toFixed(2)
  //   //                 ) - Number(resTotalAmount)
  //   //               );
  //   //               setchange("0.00");
  //   //               setAcceptpaymentbuttonenable(true);
  //   //             }
  //   //           }

  //   //           settotaldue(resAmount);
  //   //           multipleAdjust();
  //   //           editCommitmentRes({
  //   //             resArray: payGridData,
  //   //             selectedAgr: collectedAgreementID,
  //   //           });
  //   //         } else if (adjustedObj.type === "AEF") {
  //   //           console.log("chec the iss4rd", adjustedObj.value);
  //   //           const totalWithOutTax = Number(
  //   //             Number(
  //   //               Number(adjustedObj.value.epoAmount) +
  //   //                 Number(adjustedObj.value.pastDueRent) +
  //   //                 payGridData[index].lateFee
  //   //             ).toFixed(2)
  //   //           );
  //   //           const epoFieldValuesObject = {
  //   //             pastDueRent: Number(adjustedObj.value.pastDueRent).toFixed(2),
  //   //             epoAmt: Number(adjustedObj.value.epoAmount),
  //   //             taxAmt: Number(
  //   //               (
  //   //                 totalWithOutTax * Number(payGridData[index].taxRate)
  //   //               ).toFixed(2)
  //   //             ),
  //   //             total: (
  //   //               Number(
  //   //                 Number(adjustedObj.value.epoAmount) +
  //   //                   Number(adjustedObj.value.pastDueRent) +
  //   //                   Number(payGridData[index].lateFee) +
  //   //                   Number(
  //   //                     (totalWithOutTax * payGridData[index].taxRate).toFixed(
  //   //                       2
  //   //                     )
  //   //                   )
  //   //               ) - Number(payGridData[index].SuspenseAmount)
  //   //             ).toFixed(2),
  //   //           };
  //   //           console.log(
  //   //             "epoPopup sxcnjbhfeywhusjnhey4ge3w",
  //   //             epoFieldValuesObject
  //   //           );
  //   //           selectedGrid = index;
  //   //           acceptEpoButton(epoFieldValuesObject, false, 'CommitmentEPO');
  //   //         } else if (adjustedObj.type === "ES") {
  //   //           const adjustDueDatereq: any = [];
  //   //           let coverageEndDateInfo: any = [];
  //   //           let policyStatus = "";
  //   //           let coverageEndDate = "";
  //   //           console.log("4rt5ty6ty5rfgytds", actLDWArr);

  //   //           if (actLDWArr.length > 0) {
  //   //             coverageEndDateInfo = actLDWArr.filter(
  //   //               (x) =>
  //   //                 String(x.agreementId) ===
  //   //                 String(payGridData[index].Agreementid)
  //   //             );
  //   //             console.log(
  //   //               "coverageEndDateInfo 3e4r5tfd",
  //   //               coverageEndDateInfo
  //   //             );

  //   //             if (coverageEndDateInfo.length > 0) {
  //   //               if (Number(coverageEndDateInfo[0].action) == 2) {
  //   //                 const currentdate: Date = new Date(
  //   //                   new Date().toLocaleString("en-US", {
  //   //                     timeZone: "America/Chicago",
  //   //                   })
  //   //                 );
  //   //                 const current_date = new Date(
  //   //                   currentdate.getFullYear(),
  //   //                   currentdate.getMonth(),
  //   //                   currentdate.getDate()
  //   //                 );
  //   //                 coverageEndDate = moment(current_date).format("MM/DD/YYYY");
  //   //               } else if (Number(coverageEndDateInfo[0].action) == 1) {
  //   //                 coverageEndDate = "12/31/2099";
  //   //                 if (
  //   //                   Number(
  //   //                     payGridData[index].agreementRateDetails.perDayLdw
  //   //                   ) == 0
  //   //                 ) {
  //   //                   payGridData[index].agreementRateDetails.perDayLdw = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) / 7
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.perDayLdwTax = (
  //   //                     (Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) /
  //   //                       7) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.biWeeklyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.biWeeklyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.monthlyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.monthlyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.semiMonthlyLdw =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.semimonthlyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.semiMonthlyLdwTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .scheduleRates.semimonthlyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.ldwInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                 }
  //   //               }
  //   //             }
  //   //           }
  //   //           if (coverageEndDate == "") {
  //   //             coverageEndDate = String(payGridData[index].coverageEndDate);
  //   //           }
  //   //           if (actPolicyArr.length > 0) {
  //   //             const policyChangeArrInfo = actPolicyArr.filter(
  //   //               (x) =>
  //   //                 String(x.agreementId) ===
  //   //                 String(payGridData[index].Agreementid)
  //   //             );
  //   //             if (policyChangeArrInfo.length > 0) {
  //   //               if (Number(policyChangeArrInfo[0].action) == 2) {
  //   //                 policyStatus = "I";
  //   //               } else if (Number(policyChangeArrInfo[0].action) == 1) {
  //   //                 policyStatus = "A";
  //   //                 if (
  //   //                   Number(
  //   //                     payGridData[index].agreementRateDetails.perDayPolicy
  //   //                   ) == 0
  //   //                 ) {
  //   //                   payGridData[index].agreementRateDetails.perDayPolicy = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ) / 7
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.perDayPolicyTax =
  //   //                     (
  //   //                       (Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) /
  //   //                         7) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyPolicy =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.weeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.weeklyPolicyTax =
  //   //                     (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.weeklyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.biWeeklyPolicy =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.biWeeklyPolicyTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.biweeklyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyPolicy =
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.monthlyRate
  //   //                     ).toFixed(2);
  //   //                   payGridData[index].agreementRateDetails.monthlyPolicyTax =
  //   //                     (
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .scheduleRates.monthlyRate
  //   //                       ) *
  //   //                       Number(
  //   //                         coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                           .ldwTaxRate
  //   //                       )
  //   //                     ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.semiMonthlyPolicy = Number(
  //   //                     coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                       .scheduleRates.semimonthlyRate
  //   //                   ).toFixed(2);
  //   //                   payGridData[
  //   //                     index
  //   //                   ].agreementRateDetails.semiMonthlyPolicyTax = (
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .scheduleRates.semimonthlyRate
  //   //                     ) *
  //   //                     Number(
  //   //                       coverageEndDateInfo[0].cusCoverageData.policyInfo
  //   //                         .ldwTaxRate
  //   //                     )
  //   //                   ).toFixed(2);
  //   //                 }
  //   //               }
  //   //             }
  //   //           }
  //   //           if (policyStatus == "") {
  //   //             policyStatus = String(payGridData[index].policyStatus);
  //   //           }
  //   //           const res = {
  //   //             agreementId: String(payGridData[index].Agreementid),
  //   //             agreementRateDetails: payGridData[index].agreementRateDetails,
  //   //             currentDueDate: String(payGridData[index].CurrentDueDate),
  //   //             dueDate: String(payGridData[index].Nextduedate),
  //   //             coverageEndDate: String(coverageEndDate),
  //   //             taxRate: String(payGridData[index].taxRate),
  //   //             lateFee: String(payGridData[index].lateFee),
  //   //             lateFeeTax: String(payGridData[index].lFeeTaxAmount),
  //   //             policyStatus: String(policyStatus),
  //   //             epoAmount: String(payGridData[index].EPOAmount),
  //   //             epoTax: String(payGridData[index].EPOTax),
  //   //             sacDate: String(payGridData[index].SAC_Date),
  //   //             carryRent: String(payGridData[index].carryRentAmountUsed),
  //   //             carryRentTax: String(payGridData[index].carryRentTaxUsed),
  //   //             agreementNumber: String(payGridData[index].AgreementNumber),
  //   //             scheduleRefCode: "",
  //   //           };
  //   //           adjustDueDatereq.push(res);
  //   //           const editScheudleRequest = {
  //   //             rateResponse: adjustDueDatereq,
  //   //             schedule: adjustedObj.value,
  //   //           };
  //   //           console.log("3edfr5g6yh7yu7", editScheudleRequest);

  //   //           (async () => {
  //   //             const adjustDueRes = await AdjustDueDatecalci(
  //   //               editScheudleRequest
  //   //             );
  //   //             console.log("value of res from adjustDueRes", adjustDueRes);
  //   //             console.log(
  //   //               "initianl valued ofer payGridData",
  //   //               payGridData[index]
  //   //             );

  //   //             let nextduedate;
  //   //             if (
  //   //               adjustDueRes[0].nextDueDate != "SAC" &&
  //   //               adjustDueRes[0].nextDueDate != "PIF" &&
  //   //               adjustDueRes[0].nextDueDate != "EPO"
  //   //             ) {
  //   //               nextduedate = moment(adjustDueRes[0].nextDueDate).format(
  //   //                 "MM/DD/YYYY"
  //   //               );
  //   //             } else {
  //   //               nextduedate = adjustDueRes[0].nextDueDate;
  //   //             }
  //   //             // if(adjustedObj.value === 'MON'){

  //   //             // }
  //   //             let updatedSchedule = "";
  //   //             if (adjustedObj.value.includes("SEMI")) {
  //   //               updatedSchedule = "Semi-Monthly";
  //   //             } else if (adjustedObj.value === "MON") {
  //   //               updatedSchedule = "Monthly";
  //   //             } else if (adjustedObj.value === "BIWK") {
  //   //               updatedSchedule = "Bi-Weekly";
  //   //             } else if (adjustedObj.value === "WK") {
  //   //               updatedSchedule = "Weekly";
  //   //             }

  //   //             (payGridData[index].Amount =
  //   //               Number(adjustDueRes[0].totalAmount) +
  //   //               Number(adjustDueRes[0].totalTax)),
  //   //               (payGridData[index].Schedule = updatedSchedule),
  //   //               (payGridData[index].Taxamount = Number(
  //   //                 adjustDueRes[0].totalTax
  //   //               )),
  //   //               (payGridData[index].Nextduedate = nextduedate);
  //   //             payGridData[index].policyAmount = Number(
  //   //               adjustDueRes[0].policyAmount
  //   //             );
  //   //             payGridData[index].editSchedule = adjustedObj.value;
  //   //             (payGridData[index].ldwTaxAmount = Number(
  //   //               adjustDueRes[0].ldwTaxAmount
  //   //             )),
  //   //               (payGridData[index].agrLdwAmount = Number(
  //   //                 adjustDueRes[0].ldwAmount
  //   //               ));
  //   //             payGridData[index].policyTaxAmount = Number(
  //   //               adjustDueRes[0].policyTaxAmount
  //   //             );
  //   //             payGridData[index].agreementRate = Number(
  //   //               adjustDueRes[0].agreementRate
  //   //             );
  //   //             payGridData[index].Agreementtype =
  //   //               adjustDueRes[0].agreementType;
  //   //             // adjustDueRes[index].Agreementtype == "Club"
  //   //             //   ? "Club"
  //   //             //   : adjustDueRes[0].agreementType;
  //   //             payGridData[index].rpayTaxAmount = Number(
  //   //               adjustDueRes[0].rpayTaxAmount
  //   //             );
  //   //             // resArray: [payGridData[index]],
  //   //             // selectedAgr: [payGridData[index].Agreementid],
  //   //             editCommitmentRes({
  //   //               resArray: payGridData,
  //   //               adjustEditflag: 0,
  //   //               selectedAgr: collectedAgreementID,
  //   //             });
  //   //           })();
  //   //         } else if (adjustedObj.type === "TUS") {
  //   //           let insertArray: any[] = [];
  //   //           let totalsusamount = 0;
  //   //           let transferedSupenseAmount = 0;
  //   //           for (let i = 0; i < paygridvalues?.length; i++) {
  //   //             if (
  //   //               paygridvalues[i].Agreementtype != "Club" &&
  //   //               paygridvalues[i].Nextduedate != "PIF" &&
  //   //               paygridvalues[i].Nextduedate != "SAC" &&
  //   //               paygridvalues[i].Nextduedate != "EPO" &&
  //   //               paygridvalues[i].number_of_paymentsmade > 0 &&
  //   //               String(paygridvalues[i].store_number) ==
  //   //                 String(window.sessionStorage.getItem("storeNumber"))
  //   //             ) {
  //   //               totalsusamount =
  //   //                 Number(totalsusamount) +
  //   //                 Number(paygridvalues[i].SuspenseAmount);
  //   //               transferedSupenseAmount =
  //   //                 Number(transferedSupenseAmount) +
  //   //                 Number(paygridvalues[i].transferOldSuspense);
  //   //               insertArray.push({
  //   //                 sustype: "type",
  //   //                 agreementnumber: paygridvalues[i].AgreementNumber,
  //   //                 agreementid: paygridvalues[i].Agreementid,
  //   //                 schedure: paygridvalues[i].Schedule,
  //   //                 paymentamount: paygridvalues[i].Amount,
  //   //                 epoamount: paygridvalues[i].EPOAmount,
  //   //                 suspenseamount: Number(
  //   //                   paygridvalues[i].SuspenseAmount
  //   //                 ).toFixed(2),
  //   //                 newsuspense: Number(
  //   //                   paygridvalues[i].SuspenseAmount
  //   //                 ).toFixed(2),
  //   //                 agreementType: paygridvalues[i].Agreementtype,
  //   //               });
  //   //               settotolsuspenseamount(Number(totalsusamount).toFixed(2));
  //   //               setdifferenceamount(
  //   //                 Number(
  //   //                   (
  //   //                     Number(transferedSupenseAmount) - Number(totalsusamount)
  //   //                   ).toFixed(2)
  //   //                 )
  //   //               );
  //   //             }
  //   //           }

  //   //           setmastertranssuspensearray([...insertArray]);
  //   //           setaddsus("0.00");
  //   //           setusesus("0.00");
  //   //           settranssus("0.00");
  //   //           console.log("before asign amt insertArray", insertArray);
  //   //           console.log("trancomdetadjustedObjvalue", adjustedObj.value);

  //   //           insertArray.map((obj) => {
  //   //             adjustedObj.value.map((adjObj, index) => {
  //   //               if (Number(adjObj.agreementID) === Number(obj.agreementid)) {
  //   //                 insertArray[index].newsuspense = adjObj.value;
  //   //                 insertArray[index].sustype = "Trans";
  //   //               }
  //   //             });
  //   //           });

  //   //           let newtransferedSuspense = 0;
  //   //           let ActualSuspenseAmount = 0;
  //   //           for (let i = 0; i < insertArray.length; i++) {
  //   //             newtransferedSuspense =
  //   //               newtransferedSuspense + Number(insertArray[i].newsuspense);
  //   //             ActualSuspenseAmount =
  //   //               ActualSuspenseAmount + Number(insertArray[i].suspenseamount);
  //   //           }
  //   //           settotalnewsuspenseamount(newtransferedSuspense);
  //   //           let totaltransnewsusamount: number;
  //   //           totaltransnewsusamount = 0;
  //   //           for (let i = 0; i < insertArray.length; i++) {
  //   //             totaltransnewsusamount =
  //   //               totaltransnewsusamount + insertArray[i].newsuspense;
  //   //           }
  //   //           settotalnewsuspenseamount(totaltransnewsusamount);
  //   //           for (let i = 0; i < insertArray.length; i++) {
  //   //             if (
  //   //               insertArray[i].suspenseamount < insertArray[i].newsuspense
  //   //             ) {
  //   //               insertArray[i].sustype = "TransferAdd";
  //   //             } else {
  //   //               insertArray[i].sustype = "TransferUsed";
  //   //             }
  //   //           }
  //   //           console.log("chansdfewr asign amt insertArray", insertArray);
  //   //           let newInsertArray: any = [];
  //   //           for (let i = 0; i < insertArray.length; i++) {
  //   //             let indexValue: any = paygridvalues.findIndex(
  //   //               (x) =>
  //   //                 String(x.Agreementid) === String(insertArray[i].agreementid)
  //   //             );
  //   //             console.log("sxcfdescfresindexValue", indexValue);

  //   //             if (insertArray[i].sustype == "TransferAdd" || "TransferUsed") {
  //   //               console.log("dfre45rt54ered34", insertArray[i]);
  //   //               console.log(
  //   //                 "sdcfedcfr4erfpaygridvalues",
  //   //                 paygridvalues[indexValue]
  //   //               );

  //   //               if (
  //   //                 Number(insertArray[i].newsuspense) -
  //   //                   Number(paygridvalues[indexValue].transferOldSuspense) >
  //   //                 0
  //   //               ) {
  //   //                 newInsertArray.push({
  //   //                   sustype: "TransferAdd",
  //   //                   agreementnumber: insertArray[i].agreementnumber,
  //   //                   agreementid: insertArray[i].agreementid,
  //   //                   schedure: insertArray[i].schedure,
  //   //                   paymentamount: insertArray[i].paymentamount,
  //   //                   epoamount: Number(insertArray[i].epoamount),
  //   //                   suspenseamount: insertArray[i].suspenseamount,
  //   //                   newsuspense:
  //   //                     Number(insertArray[i].newsuspense) -
  //   //                     Number(paygridvalues[indexValue].transferOldSuspense),
  //   //                   agreementType: insertArray[i].agreementType,
  //   //                 });
  //   //                 // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
  //   //                 // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
  //   //                 paygridvalues[indexValue].SuspenseAmount = Number(
  //   //                   insertArray[i].newsuspense
  //   //                 );
  //   //               } else if (
  //   //                 Number(insertArray[i].newsuspense) -
  //   //                   Number(paygridvalues[indexValue].transferOldSuspense) <
  //   //                 0
  //   //               ) {
  //   //                 newInsertArray.push({
  //   //                   sustype: "TransferUsed",
  //   //                   agreementnumber: insertArray[i].agreementnumber,
  //   //                   agreementid: insertArray[i].agreementid,
  //   //                   schedure: insertArray[i].schedure,
  //   //                   paymentamount: insertArray[i].paymentamount,
  //   //                   epoamount: Number(insertArray[i].epoamount),
  //   //                   suspenseamount: insertArray[i].suspenseamount,
  //   //                   newsuspense:
  //   //                     Number(paygridvalues[indexValue].transferOldSuspense) -
  //   //                     Number(insertArray[i].newsuspense),
  //   //                   agreementType: insertArray[i].agreementType,
  //   //                 });
  //   //                 // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
  //   //                 // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
  //   //                 paygridvalues[indexValue].SuspenseAmount = Number(
  //   //                   insertArray[i].newsuspense
  //   //                 );
  //   //                 const useFilterTransferBack = masterusesuspensearray.filter(
  //   //                   function (e) {
  //   //                     return (
  //   //                       e.sustype != "type" &&
  //   //                       Number(e.newsuspense) > 0 &&
  //   //                       String(e.agreementid) ==
  //   //                         String(insertArray[i].agreementid)
  //   //                     );
  //   //                   }
  //   //                 );
  //   //                 if (useFilterTransferBack?.length > 0) {
  //   //                   if (
  //   //                     Number(insertArray[i].newsuspense) <
  //   //                     Number(useFilterTransferBack[0].newsuspense)
  //   //                   ) {
  //   //                     masterusesuspensearray = masterusesuspensearray.filter(
  //   //                       function (e) {
  //   //                         return (
  //   //                           String(e.agreementid) !=
  //   //                           String(useFilterTransferBack[0].agreementid)
  //   //                         );
  //   //                       }
  //   //                     );
  //   //                     for (let i = 0; i < useFilterTransferBack.length; i++) {
  //   //                       let index = paygridvalues.findIndex(
  //   //                         (x) =>
  //   //                           String(x.Agreementid) ===
  //   //                           String(useFilterTransferBack[0].agreementnumber)
  //   //                       );
  //   //                       paygridvalues[index].suspenseSub = 0;
  //   //                       // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
  //   //                       let receiptObj = ReceiptCategoriesRequestParam.filter(
  //   //                         (e) =>
  //   //                           e.agreementId ==
  //   //                           Number(useFilterTransferBack[0].agreementnumber)
  //   //                       );
  //   //                       if (
  //   //                         useFilterTransferBack[0].agreementnumber ==
  //   //                         receiptObj[0].agreementId
  //   //                       ) {
  //   //                         receiptObj[0].suspenseSub = 0;
  //   //                       }
  //   //                     }
  //   //                   }
  //   //                 }
  //   //               }
  //   //             }
  //   //           }
  //   //           const usefilterzero = masterusesuspensearray.filter(function (e) {
  //   //             return e.sustype != "type" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           const addfilterzero = masteraddsuspensearray.filter(function (e) {
  //   //             return e.sustype != "type" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           console.log("sdcvgfdrt4insertArray", insertArray);

  //   //           setmastertranssuspensearray(newInsertArray);
  //   //           settotalsuspensemaster([
  //   //             ...usefilterzero,
  //   //             ...addfilterzero,
  //   //             ...newInsertArray,
  //   //           ]);

  //   //           // let addfilter = totalsuspensemaster.filter(function (e) {
  //   //           //   return e.sustype == "Add";
  //   //           // });
  //   //           // let usefilter = totalsuspensemaster.filter(function (e) {
  //   //           //   return e.sustype == "Used";
  //   //           // });

  //   //           let resAmount = 0.0;
  //   //           for (let i = 0; i < paygridvalues.length; i++) {
  //   //             resAmount = Number(paygridvalues[i].Amount) + Number(resAmount);
  //   //           }

  //   //           const addSuspenseAmount = addfilterzero.filter(function (e) {
  //   //             return e.sustype == "Add" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           const useSuspenseAmount = usefilterzero.filter(function (e) {
  //   //             return e.sustype == "Used" && Number(e.newsuspense) > 0;
  //   //           });
  //   //           let addSusTotalAmount =
  //   //             addSuspenseAmount.length == 0
  //   //               ? 0.0
  //   //               : addSuspenseAmount
  //   //                   .map((bill) => bill.newsuspense)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );

  //   //           let usedSusTotalAmount =
  //   //             useSuspenseAmount.length == 0
  //   //               ? 0.0
  //   //               : useSuspenseAmount
  //   //                   .map((bill) => bill.newsuspense)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );
  //   //           let resTotalAmount = 0.0;
  //   //           if (Cardrequire.PaymentOrgin == "Pay By Phone") {
  //   //             resAmount = Number(resAmount) + Number(convAmtWTax);
  //   //           }
  //   //           resTotalAmount =
  //   //             totalduetendertype.length == 0
  //   //               ? 0.0
  //   //               : totalduetendertype
  //   //                   .map((bill) => bill.Amount)
  //   //                   .reduce((acc, bill) =>
  //   //                     (Number(bill) + Number(acc)).toFixed(2)
  //   //                   );

  //   //           if (Number(resTotalAmount) > Number(resAmount)) {
  //   //             resetPayment(undefined);
  //   //             setremainingamountdue(
  //   //               Number(
  //   //                 (
  //   //                   Number(resAmount) -
  //   //                   Number(availableCreds) +
  //   //                   Number(addSusTotalAmount) -
  //   //                   Number(usedSusTotalAmount)
  //   //                 ).toFixed(2)
  //   //               )
  //   //             );
  //   //             setchange("0.00");
  //   //             setAcceptpaymentbuttonenable(true);
  //   //           } else {
  //   //             if (
  //   //               Number(resTotalAmount) >=
  //   //               Number(
  //   //                 (
  //   //                   Number(resAmount) -
  //   //                   Number(availableCreds) +
  //   //                   Number(addSusTotalAmount) -
  //   //                   Number(usedSusTotalAmount)
  //   //                 ).toFixed(2)
  //   //               )
  //   //             ) {
  //   //               setchange(
  //   //                 Number(resTotalAmount) -
  //   //                   Number(
  //   //                     (
  //   //                       Number(resAmount) -
  //   //                       Number(availableCreds) +
  //   //                       Number(addSusTotalAmount) -
  //   //                       Number(usedSusTotalAmount)
  //   //                     ).toFixed(2)
  //   //                   )
  //   //               );
  //   //               setremainingamountdue("0.00");
  //   //               setRemaniningAmtStatic("0.00");
  //   //               setAcceptpaymentbuttonenable(false);
  //   //             } else {
  //   //               setremainingamountdue(
  //   //                 Number(
  //   //                   (
  //   //                     Number(resAmount) -
  //   //                     Number(availableCreds) +
  //   //                     Number(addSusTotalAmount) -
  //   //                     Number(usedSusTotalAmount)
  //   //                   ).toFixed(2)
  //   //                 ) - Number(resTotalAmount)
  //   //               );
  //   //               setRemaniningAmtStatic(
  //   //                 Number(
  //   //                   (
  //   //                     Number(resAmount) -
  //   //                     Number(availableCreds) +
  //   //                     Number(addSusTotalAmount) -
  //   //                     Number(usedSusTotalAmount)
  //   //                   ).toFixed(2)
  //   //                 ) - Number(resTotalAmount)
  //   //               );
  //   //               setchange("0.00");
  //   //               setAcceptpaymentbuttonenable(true);
  //   //             }
  //   //           }
  //   //           console.log("transloggerchecker", insertArray);

  //   //           multipleAdjust();
  //   //           editCommitmentRes({
  //   //             resArray: payGridData,
  //   //             selectedAgr: collectedAgreementID,
  //   //           });
  //   //         }
  //   //       });
  //   //     }
  //   //   });
  //   // });
  // };

  useEffect(() => {
    Bindremainingamount();
  }, [paygridvalues]);

  useEffect(() => {
    Gridbindingunenrollautopay();
  }, [updatedarray]);
  const fixedChange = (value: string) => {
    if (value == undefined || value == null || parseFloat(value) < 0.0) {
      return "0.00";
    } else {
      return String(parseFloat(value).toFixed(2));
    }
  };

  const swipeDevice_api = async () => {
    let swipeDevicePayload = {
      cloudStoreSwipeDevicelist:
        configFlags?.isCloudStoreSwipeDevicelist == 1 ? "Y" : "N",
      storeNumber: window.sessionStorage.getItem("storeNumber"),
    };

    let swipe_storeResp = await StoreswipeDevice(swipeDevicePayload);
    if(swipe_storeResp.status == 200)setDeveiceResp(swipe_storeResp);
    console.log("swipe_storeResp", swipe_storeResp);
  };

  const stateApi = async () => {
    const res1 = await GetState();
    const statesList = res1.data.map((state: any) => {
      return { label: state.stateAbbreviation, value: state.stateAbbreviation };
    });
    console.log(statesList);
    const sortedStatesList: any = orderBy(statesList, ["label"], ["asc"]);
    console.log(sortedStatesList);
    setstatedata(sortedStatesList);
    console.log("rs1", res1);
    if (res1.data.length > 0) {
      const buildStateDropDown: any[] = [];
      res1.data.map((value: any, index: any) => {
        if (index == 0) {
          buildStateDropDown.push({ label: "Select", value: "0" });
        }
        buildStateDropDown.push({
          label: value.stateAbbreviation,
          value: value.stateAbbreviation,
        });
      });
      setdefaultVal(buildStateDropDown[0].value);
      setDD(buildStateDropDown);
      console.log("DDStructure -->", buildStateDropDown);
    }
  };

  useEffect(() => {
    receiptitemsbind();
  }, [totalduereceiptid]);

  useEffect(() => {
    if (
      APUPStates.IsAP === APUPStates.IsUP &&
      APUPStates.IsAP !== false &&
      APUPStates.IsUP !== false
    ) {
      setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      console.log("--->Important");
    }
  }, [APUPStates]);

  const receiptitemsbind = () => {
    if (paygridvalues !== undefined) {
      if (totalduereceiptid.length > 0) {
        return totalduereceiptid.map((value: any, index: any) => {
          return (
            <Grid
              item
              key={index}
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                variant="caption"
                className={`${classes.floatLeft}  ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite} `}
              >
                {value.agreement_id}
              </Typography>
              <Grid
                item
                className={` ${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
              >
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  $
                  {value.agreement_totalamount !== undefined &&
                  value.agreement_totalamount !== null
                    ? parseFloat(value.agreement_totalamount).toFixed(2)
                    : 0.0}
                </Typography>
              </Grid>
            </Grid>
          );
        });
      }
    } else {
      return (
        <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
          <Typography
            className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            12345
          </Typography>
          <Typography
            className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            <Typography>${"0.00"}</Typography>
          </Typography>
        </Grid>
      );
    }
  };

  const suspenseitemsbind = () => {
    if (paygridvalues !== undefined) {
      if (totalsuspensemaster.length > 0) {
        return totalsuspensemaster.map((value: any, index: any) => {
          return (
            <Grid
              item
              key={index}
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                variant="caption"
                className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
              >
                {value.agreementid} {"-"}{" "}
                {value.sustype == "TransferAdd" || value.sustype == "Add"
                  ? "Add"
                  : "Use"}
                <span> </span>
                {value.sustype == "TransferAdd" ||
                value.sustype == "TransferUsed" ? (
                  <Infosus
                    title={"Transfer"}
                    onMouseOver={() => {
                      "Transfer";
                    }}
                  ></Infosus>
                ) : null}
                <span> </span>
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
              >
                <Typography className={classes.displayInline}>
                  $
                  {value.newsuspense !== undefined && value.newsuspense !== null
                    ? parseFloat(value.newsuspense).toFixed(2)
                    : 0.0}
                </Typography>
              </Typography>
            </Grid>
          );
        });
      }

      // } else {
      //   return (
      //     <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
      //       <Typography className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}>
      //         {agreementId}
      //       </Typography>
      //       <Typography className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}>
      //         <Typography>${parseFloat(fixedChange(totaldue))}</Typography>
      //       </Typography>
      //     </Grid>
      //   );
      // }
    } else {
      return (
        <Grid className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}>
          <Typography
            className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            12345
          </Typography>
          <Typography
            className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            <Typography>${"0.00"}</Typography>
          </Typography>
        </Grid>
      );
    }
  };

  //mapping function for getCard service

  const Bindcardsymbol = (arg: any) => {
    if (arg === "Master") {
      return <Mastercard className={classes.cardSize}></Mastercard>;
    }
    if (arg === "American Express") {
      return <AmericanExpress className={classes.cardSize}></AmericanExpress>;
    }
    if (arg === "JCB") {
      return <JCB className={classes.cardSize}></JCB>;
    }
    if (arg === "Diners Club") {
      return <DinersClub className={classes.cardSize}></DinersClub>;
    }
    if (arg === "PayPal") {
      return <Paypal className={classes.cardSize}></Paypal>;
    }
    if (arg === "Discover") {
      return <Discover className={classes.cardSize}></Discover>;
    } else {
      return <Visacard className={classes.cardSize}></Visacard>;
    }
  };

  const CVVChange = (e: any, index: any) => {
    let count = 0;
    setCvv(e.target.value);
    let checked = 0;
    let enablepayment = true;
    let fieldtype = new RegExp("^[0-9|*]*$");
    if (fieldtype.test(e.target.value)) {
      if (String(e.target.value).length == 1) {
        enablepayment = false;
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
        setAcceptpaymentbuttonenable(true);
      } else if (String(e.target.value).length == 2) {
        enablepayment = false;
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
        setAcceptpaymentbuttonenable(true);
      } else if (String(e.target.value).length == 3) {
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
      } else if (String(e.target.value).length == 4) {
        multipleCard[index].cvv = String(multipleCard[index].cvv) + String(e.target.value).replace(/\*/g, "");
        multipleCard[index].cvvEncrypt = e.target.value.replace(/\d/g, "*");
        setcvvTextValue(multipleCard[index].cvvEncrypt);
      } else {
        multipleCard[index].cvvEncrypt = "";
        multipleCard[index].cvv = "";
        setcvvTextValue("");
        setAcceptpaymentbuttonenable(true);
        enablepayment = false;
      }
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          checked = 1;
        }
      }

      let cvvCount = 0;
      for (let i = 0; i < multipleCard.length; i++) {
        if (
          Number(multipleCard[i].Amount > 0) &&
          multipleCard[i].cvvEncrypt.length < 3
        ) {
          enablepayment = false;
          // setcvvTextValue("")
          cvvCount++;
        }
      }
      if (cvvCount > 0) {
        setcvvTextValue("");
      } else {
        setcvvTextValue("***");
      }
      if (
        Number(remainingamountdue) <= 0 &&
        selectedCheckBoxCount != 0 &&
        enablepayment &&
        !CardErrorLbl &&
        Number(chargeCardAmt) == 0
      ) {
        setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
    } else {
      multipleCard[index].cvvEncrypt = "";
      // setAcceptpaymentbuttonenable(true);
      // enablepayment = false;
      for (let i = 0; i < multipleCard.length; i++) {
        if (
          Number(multipleCard[i].Amount > 0) &&
          multipleCard[i].cvvEncrypt.length < 3
        ) {
          enablepayment = false;
          // setcvvTextValue("")
          // cvvCount++;
        }
      }
      if (
        Number(remainingamountdue) <= 0 &&
        selectedCheckBoxCount != 0 &&
        enablepayment &&
        !CardErrorLbl &&
        Number(chargeCardAmt) == 0
      ) {
        setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
    }
  };

  function ChkDivbinding(
    index: any,
    cardToken: any,
    lastFour: any,
    billingDetails: any,
    expireDate: any,
    cardType: any,
    customerPaymentMethodId: string
  ) {
    /* eslint-disable no-console */
    console.log("hi i am not chkbox div", index);
    return chkbox.map((chkvalue: any) => {
      return (
        <Grid
          item
          className={`${classes.raccollg7} ${classes.floatLeft}`}
          key={index}
          id={"div-" + index}
          data-testid="2ndChkboxDivId"
          style={
            CardRadiArr[index] == false
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <Grid
            item
            className={`${classes.raccollg5} ${classes.mt3} ${classes.px2} ${classes.floatLeft}`}
          >
            <div
              className={`${classes.inputgroup} ${classes.m0} ${classes.mt2}`}
            >
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                data-testid="2ndtextinput"
                required={true}
                value={
                  multipleCard == undefined
                    ? "0.00"
                    : multipleCard[index] == undefined
                    ? "0.00"
                    : multipleCard[index].Amount
                }
                name="chkAmt"
                disabled={cardtxtDisable}
                type="text"
                onBlurCapture={(e) => cardTextonblur(e, index)}
                onBlur={(e) =>
                  Amtonclickbtn2ndbox(
                    e,
                    lastFour,
                    billingDetails,
                    index,
                    expireDate,
                    cardToken,
                    cardType,
                    customerPaymentMethodId
                  )
                }
                decimalsLimit={2}
                onValueChange={(value, name) =>
                  chkfunc(
                    index,
                    value,
                    name,
                    lastFour,
                    billingDetails,
                    expireDate,
                    cardToken,
                    customerPaymentMethodId
                  )
                }
                className={`${classes.formcontrol3} ${classes.textend}`}
                id={"txtCurr" + index}
              />
            </div>
          </Grid>
          <Grid
            item
            className={`${classes.raccollg4} ${classes.mt4} ${classes.floatLeft} ${classes.ps3}`}
          >
            <Grid
              item
              className={`${classes.floatLeft} ${classes.w25} ${classes.pt2}`}
            >
              <Typography
                htmlFor="exampleFormControlInput1"
                className={classes.formLabel}
                data-testid="CvvId"
                component={"label"}
              >
                CVV
              </Typography>
            </Grid>
            <Grid
              item
              className={`${classes.w50} ${classes.floatLeft} ${classes.ps2}`}
            >
              <RACTextbox
                isCurrency={false}
                name="chkcvv"
                id={"CVV" + index}
                pattern="\d*"
                maxlength={4}
                value={
                  multipleCard == undefined
                    ? ""
                    : multipleCard[index] == undefined
                    ? ""
                    : multipleCard[index].cvvEncrypt
                }
                OnChange={(e) => CVVChange(e, index)} //cvv on change
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.raccollg3} ${classes.mt3} ${classes.floatLeft} ${classes.px2}`}
          >
            <p
              className={`${classes.textend} ${classes.editCardbtn}`}
              data-bs-toggle="modal"
              data-bs-target="#editcard"
              onClick={() => edit(index)}
              data-testid="EditId"
            >
              Edit Card
            </p>
          </Grid>
        </Grid>
      );
    });
  }

  const GetcardMpping = () => {
    /* eslint-disable no-console */
    console.log("Hii I am GetcardMapping", getCardService_state);
    if (
      getCardService_state.cardResp !== undefined ||
      getCardService_state.length > 0
    ) {
      return getCardService_state.cardResp.map((value: any, index: any) => {
        return (
          <Grid
            item
            key={index}
            className={`${classes.w100} ${classes.floatLeft} ${classes.cardSplitBorder}`}
          >
            <Grid
              item
              className={`${classes.raccollg5} ${classes.pt2} ${classes.mt3} ${classes.floatLeft} ${classes.pe0}`}
            >
              <Grid
                item
                className={`${classes.formcheck} ${classes.mar6}  ${classes.ps0} ${classes.floatLeft} ${classes.alignContentCenter}`}
              >
                <RACCheckBox
                  style={{ float: "left" }}
                  defaultValue=""
                  id={"card-2" + index}
                  value={index}
                  name="report"
                  checked={CardRadiArr[index]}
                  disabled={cardtxtDisable}
                  onChange={(e) =>
                    SelectCardOptionChange(
                      index,
                      value.cardExpiration,
                      value.cardToken,
                      value.cardLastFour,
                      String(value.customerPaymentMethodId)
                    )
                  }
                  data-testid="2ndCardChkboxId"
                />
                <Typography
                  className={`${classes.font14} ${classes.alignContentCenter}`}
                  htmlFor={"card-2" + index}
                  id={"ce" + index}
                  component={"label"}
                >
                  {" "}
                  {Bindcardsymbol(value.cardType)} Card ending with{" "}
                  {value.cardLastFour}
                </Typography>
              </Grid>
            </Grid>
            {ChkDivbinding(
              index,
              value.cardToken,
              value.cardLastFour,
              value.billingAdressDetails,
              value.cardExpiration,
              value.cardType,
              String(value.customerPaymentMethodId)
            )}
          </Grid>
        );
      });
    }
  };

  const tendertypebind = () => {
    return totalduetendertype.map((value: any, index: any) => {
      return (
        <Grid
          item
          key={index}
          className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
        >
          <Typography
            variant="caption"
            className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            {value.tendertype}
          </Typography>
          <Typography
            variant="caption"
            className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
          >
            <Typography className={classes.displayInline}>
              ${value.Amount}
            </Typography>
          </Typography>
        </Grid>
      );
    });
  };
  /*The settotaldueamount is used to calculate the total due based on the checkbox checking and also checking
   the status of the agreement > 3 number of payments and it is also used to check the check box for total selection if all the check box are selected
  Here the remaining amount due also calculated and also based on that the change value gets increased if remaining amount due is 0 and also total tax amount also gets calculated*/
  const descriptiontooltip = (e: any) => {
    settooltipdesdisplay(e);
  };

  const calculateTotalDueAmount = (
    id: any,
    index: any,
    paramAmount: any,
    paramAgrId: number,
    paramCheckedCount: number
  ) => {
    let sortedPayGridValue: any = [];
    let filteredClubData: any = [];
    let filteredNormalData: any = [];
    paygridvalues.map((payGridObj, index) => {
      if (payGridObj.Agreementtype == "Club") {
        filteredClubData.push(payGridObj);
      } else {
        filteredNormalData.push(payGridObj);
      }
    });
    sortedPayGridValue = [...filteredClubData, ...filteredNormalData];
    epoAmt = Number(sortedPayGridValue[index].EPOAmount);
    let agrAmount = Number(sortedPayGridValue[index].Amount.toFixed(2));
    let curDate = new Date();
    let sacDate = new Date(sortedPayGridValue[index].SAC_Date);
    let disableAcceptPaymentButton = true;
    console.log("epoAmt----->", epoAmt, agrAmount, curDate, sacDate);

    if (agrAmount >= epoAmt && curDate <= sacDate) {
      SacAmt = agrAmount - epoAmt;
    }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let objId = id;
    let AmountDue = paramAmount;
    let addtotaldue = 0;
    const resultSelecteAgre = sortedPayGridValue.filter(function (e) {
      return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
    });
    let amount = 0;
    for (let i = 0; i < resultSelecteAgre.length; i++) {
      amount = amount + Number(resultSelecteAgre[i].Amount);
    }
    let objAvailCreditsAmt = 0;
    if (Number(amount) <= Number(sortedPayGridValue[index].wlDeposit)) {
      objAvailCreditsAmt = amount;
      // Number(paygridvalues[index].COA) +
      // Number(paygridvalues[index].wlDeposit)
      // Number(paygridvalues[index].ipSuspense)
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "WEBLEAD",
          accountBalance: Number(amount).toFixed(2),
        },
      ]);
    } else if (Number(amount) > Number(sortedPayGridValue[index].wlDeposit)) {
      objAvailCreditsAmt =
        Number(sortedPayGridValue[index].COA) +
        Number(sortedPayGridValue[index].wlDeposit) +
        Number(sortedPayGridValue[index].ipSuspense);
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "WEBLEAD",
          accountBalance: Number(sortedPayGridValue[index].wlDeposit).toFixed(
            2
          ),
        },
        {
          balanceType: "COA",
          accountBalance: Number(sortedPayGridValue[index].COA).toFixed(2),
        },
        {
          balanceType: "IP Suspence",
          accountBalance: Number(sortedPayGridValue[index].ipSuspense).toFixed(
            2
          ),
        },
      ]);
    } else if (Number(amount) == 0) {
      objAvailCreditsAmt =
        Number(sortedPayGridValue[index].COA) +
        Number(sortedPayGridValue[index].ipSuspense);
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "COA",
          accountBalance: Number(sortedPayGridValue[index].COA).toFixed(2),
        },
        {
          balanceType: "IP Suspence",
          accountBalance: Number(sortedPayGridValue[index].ipSuspense).toFixed(
            2
          ),
        },
      ]);
    }
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    const CardAmount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    console.log("CardAmount", CardAmount);

    let objTenderAmount =
      Number(addcash) +
      Number(totalamountcheck) +
      Number(CardAmount) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount);
    console.log("Tender Amount", objTenderAmount);

    let objCheBox = document.getElementById(objId) as HTMLInputElement;
    let selAgrID = Number(objCheBox.value.split("|")[0]);
    if (objCheBox.checked === true) {
      console.log("**selectedCheckBoxCount**", selectedCheckBoxCount);
      loadOtherActions();

      /*Acceptpayment button tooltip display starts here*/
      if (sortedPayGridValue[index].number_of_paymentsmade >= 3) {
        if (sortedPayGridValue[index].sign_status === "") {
          let toolTipMsg =
            sortedPayGridValue[index].confirmeddeliversystatus !== false
              ? "Please sign the agreement"
              : "Please sign the agreement and update the confirmed delivery status";
          settooltipdisplay(toolTipMsg);
        } else if (
          sortedPayGridValue[index].sign_status !== "" &&
          sortedPayGridValue[index].confirmeddeliversystatus === false
        ) {
          settooltipdisplay("Please update the confirmed delivery status");
        }
      }
      /*Acceptpayment button tooltip display ends here*/
      /*Total due receiptitem binding starts here*/
      let agreementDetails = {
        agreement_id:
          sortedPayGridValue[index].AgreementNumber == "Adjust"
            ? "Miscellaneous Item " +
              sortedPayGridValue[index].miscellaneousItemsNum
            : sortedPayGridValue[index].AgreementNumber,
        agreement_totalamount: parseFloat(fixedChange(AmountDue)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      /*Total due receiptitem binding ends here*/

      let totaltaxamount = 0;
      totaltaxamount = totaltax + sortedPayGridValue[index].Taxamount;
      settotaltax(totaltaxamount);

      addtotaldue = parseFloat(totaldue) + parseFloat(AmountDue);
      console.log("addtotaldue-------->", addtotaldue);
      console.log("objAvailCreditsAmt-------->", objAvailCreditsAmt);
      let objPmtAmtNoTender = addtotaldue;
      objPmtAmtNoTender =
        objPmtAmtNoTender -
        objAvailCreditsAmt +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount); //32.14-17.86
      let objPmtAmtWithTender = Number(objPmtAmtNoTender - objTenderAmount); //14.28 - 0
      console.log("objPmtAmtNoTender", objPmtAmtNoTender);
      settotaldue(addtotaldue);
      if (objPmtAmtWithTender === 0.0) {
        setRemaniningAmtStatic(objPmtAmtNoTender);
        setremainingamountdue(0.0);
        setchange(0.0);
      } else if (objPmtAmtWithTender > 0.0) {
        setRemaniningAmtStatic(objPmtAmtNoTender);
        setremainingamountdue(objPmtAmtWithTender);
        setchange(0.0);
      } else if (objPmtAmtWithTender < 0.0) {
        setRemaniningAmtStatic(objPmtAmtNoTender);
        setremainingamountdue(0.0);
        setchange(Math.abs(objPmtAmtWithTender));
      }
      let checkedCount = 0;
      let objChkBoxList = document.getElementsByName("grid");
      for (let i = 0; i < objChkBoxList.length; i++) {
        let objCheckBox = objChkBoxList[i] as HTMLInputElement;
        if (objCheckBox.checked === true) {
          checkedCount++;
        }
      }
      if (objPmtAmtWithTender <= 0.0 && checkedCount > 0) {
        disableAcceptPaymentButton = false;
      } else {
        disableAcceptPaymentButton = true;
      }

      let ldwamountcalculation = "0.00";
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      let epoAmt = 0;
      let epoTax = 0;
      if (getpayment !== undefined && getpayment !== null) {
        let objAmtDue;
        if (sortedPayGridValue[index].Agreementtype === "Club") {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === sortedPayGridValue[index].Agreementid
          );
        }
        if (objAmtDue.length > 0) {
          if (
            objAmtDue[0].ldwLateRent !== undefined &&
            objAmtDue[0].ldwLateRent !== null &&
            objAmtDue[0].ldwLateRent !== "" &&
            objAmtDue[0].ldwLateRent !== "0"
          ) {
            ldwamountcalculation = objAmtDue[0].ldwLateRent;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          } else if (
            objAmtDue[0].ldwAmount !== undefined &&
            objAmtDue[0].ldwAmount !== null &&
            objAmtDue[0].ldwAmount !== ""
          ) {
            ldwamountcalculation = objAmtDue[0].ldwAmount;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          }
          console.log("------->Ldw", ldwamountcalculation);

          if (
            objAmtDue[0].amountDueLateRent !== undefined &&
            objAmtDue[0].amountDueLateRent !== null &&
            objAmtDue[0].amountDueLateRent !== "" &&
            objAmtDue[0].amountDueLateRent !== "0"
          ) {
            objAmount = objAmtDue[0].amountDueLateRent;
          } else if (objAmtDue[0].amountDue !== undefined) {
            objAmount = String(objAmtDue[0].amountDue);
          } else if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            clubFlag = 1;
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            clubFlag = 1;
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
        }

        let objPayHistory = getpayment.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === sortedPayGridValue[index].Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            sortedPayGridValue[index].Amount -
            Number(sortedPayGridValue[index].lateFee) +
            Number(sortedPayGridValue[index].suspenseSub)
          ).toFixed(2)
        );

        if (sortedPayGridValue[index].Agreementtype == "EPO Rent") {
          console.log("Inside If agrAmount----->", agreementAmount);
          if (sortedPayGridValue[index].Nextduedate == "EPO") {
            epoAmt =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - sortedPayGridValue[index].Taxamount
                    ).toFixed(2)
                  )
                : Number(sortedPayGridValue[index].EPOAmount) +
                  Number(sortedPayGridValue[index].suspenseSub) -
                  Number(sortedPayGridValue[index].lateFee) -
                  Number(sortedPayGridValue[index].EPOTax);
            epoTax =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(sortedPayGridValue[index].rpayTaxAmount) -
                  Number(sortedPayGridValue[index].lFeeTaxAmount)
                : Number(sortedPayGridValue[index].EPOTax) -
                  Number(sortedPayGridValue[index].lFeeTaxAmount);
          } else {
            sacAmt =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - sortedPayGridValue[index].Taxamount
                    ).toFixed(2)
                  )
                : Number(sortedPayGridValue[index].EPOAmount) +
                  Number(sortedPayGridValue[index].suspenseSub) -
                  Number(sortedPayGridValue[index].lateFee) -
                  Number(sortedPayGridValue[index].EPOTax);
            sacTax =
              sortedPayGridValue[index].acceptEpoFlag == 0
                ? Number(sortedPayGridValue[index].rpayTaxAmount) -
                  Number(sortedPayGridValue[index].lFeeTaxAmount)
                : Number(sortedPayGridValue[index].EPOTax) -
                  Number(sortedPayGridValue[index].lFeeTaxAmount);
          }
          setfinalagr(Number(sortedPayGridValue[index].Agreementid));
          pifFlag = 1;
        } else if (sortedPayGridValue[index].Agreementtype == "PIF Rent") {
          pifAmt =
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? Number(
                  (
                    agreementAmount - sortedPayGridValue[index].Taxamount
                  ).toFixed(2)
                )
              : Number(sortedPayGridValue[index].EPOAmount) +
                Number(sortedPayGridValue[index].suspenseSub) -
                Number(sortedPayGridValue[index].lateFee) -
                Number(sortedPayGridValue[index].EPOTax);
          pifTax =
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? Number(sortedPayGridValue[index].rpayTaxAmount) -
                Number(sortedPayGridValue[index].lFeeTaxAmount)
              : Number(sortedPayGridValue[index].EPOTax) -
                Number(sortedPayGridValue[index].lFeeTaxAmount);
          setfinalagr(Number(sortedPayGridValue[index].Agreementid));
          pifFlag = 1;
        }
        if (
          ((pifFlag == 1 &&
            Number(sortedPayGridValue[index].SuspenseAmount) > 0) ||
            (Number(
              sortedPayGridValue[index].SuspenseAmount >=
                sortedPayGridValue[index].EPOAmount
            ) &&
              sortedPayGridValue[index].Agreementtype !== "Club")) &&
          sortedPayGridValue[index].miscellaneousItemsNum == 0 &&
          Number(sortedPayGridValue[index].suspenseSub) == 0
        ) {
          setsuspErrLbl(true);
          suspenseError.push(sortedPayGridValue[index].Agreementid);
        }
        if (
          Number(
            sortedPayGridValue[index].EPOAmount <
              sortedPayGridValue[index].Amount
          ) &&
          pifFlag != 1 &&
          sortedPayGridValue[index].Agreementtype !== "Club" &&
          sortedPayGridValue[index].miscellaneousItemsNum == 0
        ) {
          setepoErrLbl(true);
          epoError.push(sortedPayGridValue[index].Agreementid);
        }
        // if (
        //   String(paygridvalues[index].store_number) !==
        //   window.sessionStorage.getItem("storeNumber")
        // ) {
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(paygridvalues[index].Agreementid);
        // }
      }

      let ChangeAmt = change;
      console.log("ChangeAmt", ChangeAmt);

      if (sortedPayGridValue[index].miscellaneousItemsNum == 0) {
        let ReceiptArray = {
          customerId: Number(customerId),
          schedule:
            sortedPayGridValue[index].Schedule +
            "|" +
            sortedPayGridValue[index].AgreementNumber,
          amountDue: Number(
            clubFlag !== 1 && pifFlag == 0
              ? (
                  sortedPayGridValue[index].Amount -
                  Number(sortedPayGridValue[index].lateFee)
                ).toFixed(2)
              : 0
          ),
          ldwFeeAmount: Number(sortedPayGridValue[index].agrLdwAmount),
          clubPayment: Number(
            clubFlag === 1
              ? Number(sortedPayGridValue[index].Amount.toFixed(2)) -
                  Number(sortedPayGridValue[index].Taxamount)
              : 0
          ),
          clubPaymentTax: Number(
            clubFlag === 1 ? sortedPayGridValue[index].Taxamount : 0
          ),
          creditOnAccountAdd: 0,
          creditOnAccountSub: Number(sortedPayGridValue[index].COA),
          integratedPaymentSuspense:
            -1 * Number(sortedPayGridValue[index].ipSuspense),
          initialPayment:
            clubFlag === 1
              ? 0
              : sortedPayGridValue[index].number_of_paymentsmade === 0
              ? 1
              : 0,
          netLateFee: 0,
          carriedLateFee: 0,
          wlDeposit: -1 * Number(sortedPayGridValue[index].wlDeposit),
          htSuspense: 0,
          suspenseAdd: Number(sortedPayGridValue[index].suspenseAdd),
          suspenseSub: Number(sortedPayGridValue[index].suspenseSub), //Number(paygridvalues[index].suspense),
          racTirePay: Number(sortedPayGridValue[index].policyAmount),
          agreementId:
            clubFlag !== 1
              ? Number(sortedPayGridValue[index].Agreementid)
              : undefined,
          change: Number(fixedChange(ChangeAmt)),
          totalTax: sortedPayGridValue[index].Taxamount,
          sac: Number(sacAmt),
          sacTax: Number(sacTax),
          epo: Number(epoAmt),
          epoTax: Number(epoTax),
          epoDiscount:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? 0.0
              : Number(sortedPayGridValue[index].actualEpoAmount) -
                Number(sortedPayGridValue[index].editedEPOAmount),
          pastDueDiscount:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? 0.0
              : Number(sortedPayGridValue[index].actualPastDueRent) -
                Number(sortedPayGridValue[index].pastDueRent),
          pastDueAmount:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? undefined
              : Number(sortedPayGridValue[index].actualPastDueRent),
          nextDueDate:
            sortedPayGridValue[index].acceptEpoFlag == 0
              ? undefined
              : sortedPayGridValue[index].Nextduedate,
          ldwFeeAmountTax: Number(sortedPayGridValue[index].ldwTaxAmount),
          rentalPaymentTax: Number(
            pifFlag == 0 && clubFlag == 0
              ? sortedPayGridValue[index].rpayTaxAmount
              : 0
          ),
          lateRentalPaymentfeeTax: Number(
            sortedPayGridValue[index].lFeeTaxAmount
          ),
          lateRentalPaymentfee: Number(sortedPayGridValue[index].lateFee),
          racTirePayTax: Number(sortedPayGridValue[index].policyTaxAmount),
          deliveryFee: Number(sortedPayGridValue[index].deliveryAmount),
          deliveryFeeTax: Number(sortedPayGridValue[index].deliveryAmountTax),
          processingFee: Number(sortedPayGridValue[index].processingFee),
          processingFeeTax: Number(sortedPayGridValue[index].processingFeeTax),
          carryRentAmountUsed:
            sortedPayGridValue[index].FreeTime == null
              ? Number(sortedPayGridValue[index].carryRentAmountUsed)
              : 0,
          carryRentTaxUsed:
            sortedPayGridValue[index].FreeTime == null
              ? Number(sortedPayGridValue[index].carryRentTaxUsed)
              : 0,
          pif: Number(pifAmt),
          pifTax: Number(pifTax),
          extensionAmount: Number(sortedPayGridValue[index].extensionAmount),
          convenienceFee: 0,
          convenienceFeeTax: 0,
          mattressFee: Number(sortedPayGridValue[index].mattressFee),
        };

        ReceiptCategoriesRequestParam.push(ReceiptArray);
      }

      if (
        sortedPayGridValue[index].Agreementtype !== "Club" &&
        sortedPayGridValue[index].miscellaneousItemsNum == 0
      ) {
        let acceptPaymentArray = {
          extensionAmount: Number(sortedPayGridValue[index].extensionAmount),
          noOfDaysFree: Number(sortedPayGridValue[index].noOfDaysFree),
          initialPaymentFlag: 1,
          // sortedPayGridValue[index].number_of_paymentsmade == 0 ? 1 : 0,
          addTransferSusFlag: 0,
          schedule: sortedPayGridValue[index].Schedule,
          agreementId: Number(sortedPayGridValue[index].Agreementid),
          ldwFeeAmount: Number(
            sortedPayGridValue[index].agrLdwAmount.toFixed(2)
          ), // Added By Me
          // Number(paygridvalues[index].PromoFreeDaysApplied) == 0
          //   ? Number(paygridvalues[index].agrLdwAmount.toFixed(2))
          //   : 0.0,
          agreementAmountDue: Number(
            fixedChange(
              String(
                Number(sortedPayGridValue[index].Amount) +
                  (Number(sortedPayGridValue[index].acceptEpoFlag) == 1
                    ? Number(sortedPayGridValue[index].suspenseSub)
                    : 0)
              )
            )
          ),
          remainingEpoAmount: Number(
            (
              sortedPayGridValue[index].EPOAmount -
              sortedPayGridValue[index].EPOTax
            ).toFixed(2)
          ),
          promoFreeDays: Number(sortedPayGridValue[index].PromoFreeDaysApplied),
          netLateFee: Number(sortedPayGridValue[index].lateFee),
          carriedLateFee: Number(
            sortedPayGridValue[index].carriedLateFee.toFixed(2)
          ),
          numberOfDaysLate: sortedPayGridValue[index].CurrentDaysLate,
          originalDueDate: sortedPayGridValue[index].CurrentDueDate,
          nextDueDate: sortedPayGridValue[index].Nextduedate,
          totalTax: Number(sortedPayGridValue[index].Taxamount.toFixed(2)),
          policyAmount: Number(Number(sortedPayGridValue[index].policyAmount)),
          deliveryFeeAmount: Number(sortedPayGridValue[index].deliveryAmount),
          processingFeeAmount: Number(sortedPayGridValue[index].processingFee),
          otherFeeAmount: Number(sortedPayGridValue[index].mattressFee),
          editSchedule: sortedPayGridValue[index].editSchedule,
          freeTime: sortedPayGridValue[index].FreeTime, // Added By Me
          ldwActivate: undefined,
        };
        acceptPaymentParam.push(acceptPaymentArray);
        setAcceptClub(undefined);
      } else if (sortedPayGridValue[index].miscellaneousItemsNum == 0) {
        console.log("went into condition");
        AcceptclubPaymentParam = {
          paymentSchedule: sortedPayGridValue[index].Schedule,
          originalDueDate: sortedPayGridValue[index].CurrentDueDate,
          nextDueDate: sortedPayGridValue[index].Nextduedate,
          numberOfDaysLate: sortedPayGridValue[index].CurrentDaysLate,
          clubTax: String(sortedPayGridValue[index].Taxamount.toFixed(2)),
          clubAmountDue: Number(
            Number(sortedPayGridValue[index].Amount).toFixed(2)
          ),
          editSchedule: sortedPayGridValue[index].editSchedule,
        };
        setAcceptClub(AcceptclubPaymentParam);
      }
      // code added for the late fee calculation added by Mano Ranjith
      let Individuallateadditionfee =
        Number(sortedPayGridValue[index].lateFee) + Latelast10value;
      setLatelast10value(Individuallateadditionfee);
    } else {
      console.log("**selectedCheckBoxCount**", selectedCheckBoxCount);
      if (totalduereceiptidsupport.length - 1 == 1) {
        loadOtherActions();
      } else {
        setOtherActionHidden(true);
        setshowLDWPolicy(false);
      }

      /*Accept payment empty tooltip if check box is not selected */
      settooltipdisplay("");

      /*Total due receiptitem binding starts here*/
      settotaldue(RemaniningAmtStatic);
      for (let i = 0; i < totalduereceiptidsupport.length; i++) {
        if (
          totalduereceiptidsupport[i].agreement_id ===
            parseInt(sortedPayGridValue[index].AgreementNumber.toString()) ||
          totalduereceiptidsupport[i].agreement_id ===
            sortedPayGridValue[index].AgreementNumber
        ) {
          totalduereceiptidsupport.splice(i, 1);
          break;
        } else if (
          Number(
            totalduereceiptidsupport[i].agreement_id.split(
              "Miscellaneous Item "
            )[1]
          ) == Number(sortedPayGridValue[index].miscellaneousItemsNum)
        ) {
          totalduereceiptidsupport.splice(i, 1);
        }
      }
      /*Total due receiptitem binding ends here*/

      let totaltaxamount = 0;
      totaltaxamount = totaltax - sortedPayGridValue[index].Taxamount;
      settotaltax(totaltaxamount);

      let reducetotaldue = Number(totaldue) - Number(AmountDue);
      reducetotaldue = Number(reducetotaldue.toFixed(2));
      settotaldue(reducetotaldue);
      console.log("objAvailCreditsAmt", objAvailCreditsAmt);

      let objtendertypereducechange =
        objTenderAmount +
        (paramCheckedCount > 0
          ? objAvailCreditsAmt + Number(usedSusTotalAmount)
          : 0);
      objtendertypereducechange = Number(objtendertypereducechange.toFixed(2));
      console.log("objtendertypereducechange", objtendertypereducechange);

      let objRedPmtWithTenderAndAvailCredits =
        reducetotaldue - objtendertypereducechange + Number(addSusTotalAmount);
      objRedPmtWithTenderAndAvailCredits = Number(
        objRedPmtWithTenderAndAvailCredits.toFixed(2)
      );
      console.log(
        "objRedPmtWithTenderAndAvailCredits",
        objRedPmtWithTenderAndAvailCredits
      );
      objRedPmtWithTenderAndAvailCredits < 0.0
        ? setremainingamountdue(0.0)
        : setremainingamountdue(objRedPmtWithTenderAndAvailCredits); // change here .tofixed

      let objRedPmtWithNoTender =
        reducetotaldue -
        (paramCheckedCount > 0
          ? objAvailCreditsAmt +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          : 0);
      objRedPmtWithNoTender = Number(objRedPmtWithNoTender.toFixed(2));
      console.log("objRedPmtWithNoTender", objRedPmtWithNoTender);

      objRedPmtWithNoTender < 0.0
        ? setRemaniningAmtStatic(0.0)
        : setRemaniningAmtStatic(objRedPmtWithNoTender);
      console.log("objRedPmtWithNoTender", objRedPmtWithNoTender);
      console.log(
        "objRedPmtWithTenderAndAvailCredits",
        objRedPmtWithTenderAndAvailCredits
      );

      //amount is greater and customer still has remaing amount due to pay
      if (objRedPmtWithTenderAndAvailCredits > 0.0) {
        setremainingamountdue(objRedPmtWithTenderAndAvailCredits);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer has removed all agreements
      else if (paramCheckedCount == 0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(0.0);
      }
      //customer has paid exact amount either through avab credits or with tender type as well
      else if (objRedPmtWithTenderAndAvailCredits == 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer made overpayment after unchecking
      else if (objRedPmtWithTenderAndAvailCredits < 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(Math.abs(objRedPmtWithTenderAndAvailCredits));
        setRemaniningAmtStatic(
          objRedPmtWithNoTender >= 0.0 ? objRedPmtWithNoTender : 0.0
        );
      }

      let rcDataExists = ReceiptCategoriesRequestParam.filter(
        (x) => x.agreementId === selAgrID
      );
      let susErrorAgr = suspenseError.filter(
        (x: any) => x === String(selAgrID)
      );
      let diffStoreErrAgr = diffStoreError.filter(
        (x: any) => String(x) === String(selAgrID)
      );
      console.log("Different Store Agreement Filter", diffStoreError, selAgrID);
      let epoErrorAgr = epoError.filter((x: any) => x === String(selAgrID));
      if (rcDataExists != undefined && rcDataExists.length > 0) {
        ReceiptCategoriesRequestParam.splice(
          ReceiptCategoriesRequestParam.indexOf(rcDataExists[0]),
          1
        );
      }

      let apDataExists = acceptPaymentParam.filter(
        (x) => x.agreementId === selAgrID
      );
      if (apDataExists != undefined && apDataExists.length > 0) {
        acceptPaymentParam.splice(
          acceptPaymentParam.indexOf(apDataExists[0]),
          1
        );
      }

      let clubRCDataExists = ReceiptCategoriesRequestParam.filter(
        (x) => x.clubPayment > 0
      );
      if (sortedPayGridValue[index].Agreementtype === "Club") {
        AcceptclubPaymentParam = undefined;
        if (clubRCDataExists != undefined && clubRCDataExists.length > 0) {
          ReceiptCategoriesRequestParam.splice(
            ReceiptCategoriesRequestParam.indexOf(clubRCDataExists[0]),
            1
          );
        }
      }

      console.log("is suspenseErrorDeselected", susErrorAgr[0], suspenseError);
      if (susErrorAgr != undefined && susErrorAgr.length > 0) {
        suspenseError.splice(suspenseError.indexOf(susErrorAgr[0]), 1);
        console.log("went into suspense error length condtion", suspenseError);
        if (suspenseError.length == 0) setsuspErrLbl(false);
      }
      if (epoErrorAgr != undefined && epoErrorAgr.length > 0) {
        epoError.splice(epoError.indexOf(epoErrorAgr[0]), 1);
        console.log("went into epo error length condtion", suspenseError);
        if (epoError.length == 0) setepoErrLbl(false);
      }
      if (diffStoreErrAgr != undefined && diffStoreErrAgr.length > 0) {
        diffStoreError.splice(diffStoreError.indexOf(diffStoreErrAgr[0]), 1);
        console.log(
          "went into diff error length condtion",
          diffStoreErrAgr,
          diffStoreError
        );
        // if (diffStoreError.length == 0) {
        //   setdiffStoreErrLbl(false);
        // }
      }
      let collectSelectedBoxIndex: any = [];
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          collectSelectedBoxIndex.push(i);
        }
      }
      console.log("collectSelectedBoxIndex", collectSelectedBoxIndex);
      let addedlatevalue = 0;
      for (let i = 0; i < sortedPayGridValue.length; i++) {
        for (let j = 0; j < collectSelectedBoxIndex.length; j++) {
          if (collectSelectedBoxIndex[j] == i) {
            addedlatevalue =
              Number(sortedPayGridValue[i].lateFee) + addedlatevalue;
          }
        }
      }
      // code added for the late fee subtraction added by Mano Ranjith
      // let Individuallatesubtractionfee =
      //   Latelast10value - Number(sortedPayGridValue[index].lateFee);
      setLatelast10value(addedlatevalue);
    }

    /*Total due receiptitem added to state varaible*/
    settotalduereceiptid(totalduereceiptidsupport);
    setcoaamt(sortedPayGridValue[index].COA);
    setwldepositamount(sortedPayGridValue[index].wlDeposit);
    setipsuspense(sortedPayGridValue[index].ipSuspense);
    setReceiptCategory(ReceiptCategoriesRequestParam);
    console.log(ReceiptCategoriesRequestParam, "receipt array");
    SetAcceptPayments(acceptPaymentParam);

    let checkedCount = 0;
    let objChkBoxList = document.getElementsByName("grid");
    autoPayEnrollArray = [];
    for (let i = 0; i < objChkBoxList.length; i++) {
      let objCheckBox = objChkBoxList[i] as HTMLInputElement;
      if (objCheckBox.checked === true) {
        let objAgrID = objCheckBox.value.split("|")[0];
        checkedCount++;

        let objPgVal = sortedPayGridValue.filter(
          (x) => x.Agreementid == Number(objAgrID)
        );

        let auPay: AutoPayType = {
          schedule: objPgVal[0].Schedule.toString(),
          autoPay:
            objPgVal[0].Autopay_enrollmentdate !== "" &&
            objPgVal[0].Autopay_enrollmentdate !== undefined &&
            objPgVal[0].Autopay_enrollmentdate !== null,
          itemIndex: index,
          agreementID: Number(objAgrID),
        };
        autoPayEnrollArray.push(auPay);
      }
      checkedCount == objChkBoxList.length
        ? setOverAll_Chk(true)
        : setOverAll_Chk(false);
    }
    if (checkedCount === 0) {
      disableAcceptPaymentButton = true;
      resetPayment(0);
    }

    if (
      !objCheBox.checked &&
      objTenderAmount >
        Number(totaldue) -
          Number(availableCreds) -
          Number(usedSusTotalAmount) -
          Number(AmountDue) &&
      objTenderAmount > 0 &&
      checkedCount > 0
    ) {
      resetPayment(AmountDue);
      let reducetotaldue =
        Number(totaldue) - Number(AmountDue) + Number(addSusTotalAmount);
      reducetotaldue = Number(reducetotaldue.toFixed(2));
      let objRedPmtWithNoTender =
        reducetotaldue -
        (paramCheckedCount > 0
          ? objAvailCreditsAmt - Number(usedSusTotalAmount)
          : 0);
      objRedPmtWithNoTender = Number(objRedPmtWithNoTender.toFixed(2));
      console.log("objRedPmtWithNoTender", objRedPmtWithNoTender);
      //amount is greater and customer still has remaing amount due to pay

      if (objRedPmtWithNoTender > 0.0) {
        setremainingamountdue(objRedPmtWithNoTender);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer has removed all agreements
      else if (paramCheckedCount == 0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = true;
        setchange(0.0);
        setRemaniningAmtStatic(0.0);
      }
      //customer has paid exact amount either through avab credits or with tender type as well
      else if (objRedPmtWithNoTender == 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(0.0);
        setRemaniningAmtStatic(objRedPmtWithNoTender);
      }
      //customer made overpayment after unchecking
      else if (objRedPmtWithNoTender < 0.0) {
        setremainingamountdue(0.0);
        disableAcceptPaymentButton = false;
        setchange(Math.abs(objRedPmtWithNoTender));
        setRemaniningAmtStatic(
          objRedPmtWithNoTender >= 0.0 ? objRedPmtWithNoTender : 0.0
        );
      }
      //disableAcceptPaymentButton = true;
    }

    let agreementgrid: AgreementVals = {
      ReceiptCategoriesRequestDTOParam: ReceiptCategory,
      acceptPayment: acceptPaymentParam,
      acceptClubPayment: AcceptClub,
    };

    let no_payments_made = sortedPayGridValue[index].number_of_paymentsmade;
    console.log("no_payments_made-1", no_payments_made);
    const resultSelecteAgres = sortedPayGridValue.filter(function (e) {
      return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
    });
    let noOfPaymentsAllowedToggle = 0;
    for (let i = 0; i < resultSelecteAgres.length; i++) {
      if (
        resultSelecteAgres[i].number_of_paymentsmade == 0 ||
        String(resultSelecteAgres[i].store_number) !=
          String(window.sessionStorage.getItem("storeNumber"))
      ) {
        noOfPaymentsAllowedToggle = 1;
      }
    }
    if (noOfPaymentsAllowedToggle == 0) {
      /* Autopay enrollment logic starts here */
      const distintSchedule = Array.from(
        new Set(autoPayEnrollArray.map((item) => item.schedule))
      );
      const apEnroll = Array.from(
        new Set(autoPayEnrollArray.map((item) => item.autoPay))
      );

      if (distintSchedule.length === 1 && apEnroll.length === 1) {
        if (apEnroll[0]) {
          setAPUPStates({ ...APUPStates, IsAP: false, IsUP: true });
        } else {
          setAPUPStates({ ...APUPStates, IsAP: true, IsUP: false });
        }
      } else {
        setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      }
      /* Autopay enrollment logic ends here */
    } else {
      setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
    }
    setAcceptpaymentbuttonenable(disableAcceptPaymentButton);
    FormAcceptPaymentData(0, objAvailCreditsAmt);
  };

  /**
   * This method will reset all added tenders, reset the arrays, and
   * will reset the remaining amount, total due and remaining amount static.
   *
   * @param paramAmount - Amount will be passed as 0 / actual agreement amount to be reduced.
   */
  const resetPayment = (paramAmount?: number) => {
    setAcceptpaymentbuttonenable(true);
    setaddcash("0.00");
    settotalamountcheck(0.0);
    setadddata(temp);
    setgettotamt("0.00");
    addrowarray = [];
    let objChkMo: CheqAndMO[] = [];
    setaddataanother(objChkMo);
    tendertypeglobalarray = [];
    settotalduetendertype(tendertypeglobalarray);
    const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
    setCardRadiArr(TempCard_Array);
    for (let i = 0; i < multipleCard.length; i++) {
      multipleCard[i].Amount = "0.00";
    }
    setmultipleCard(multipleCard);
    setCardErrorLbl(false);
    setCardGridData(CardGrid);
    setcvvTextValue("");
    setswipeFlag(false);
    setcardDetails([]);
    swipeCardDetails = [];
    swipeCardAmt = [];
    setcardtxtDisable(false);
    setchargeCardArray([]);
    setCardAmt([{ amount: 0.0 }]);
    setswipeamt("0.00");
    if (paramAmount === 0) {
      setRemaniningAmtStatic("0.00");
      setremainingamountdue("0.00");
      setchange(0.0);
    } else if (paramAmount != undefined) {
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let objRAmt =
        Number(totaldue) -
        Number(availableCreds) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) -
        Number(paramAmount);
      setRemaniningAmtStatic(objRAmt.toFixed(2));
      setremainingamountdue(objRAmt.toFixed(2));
      setchange(0.0);
    }
  };
  /**
   * changes : Adjust Due Date v2
   * Developer : Muzzammil
   * Desc : Adjust Due Date button needs to enable only when it mets certails condition
   *
   * @param id
   * @param index
   * @param amount
   * @param agreementselectedId
   * @param e
   */
  const settotaldueamount = (
    id: any,
    index: number,
    amount: number,
    agreementselectedId: number,
    e: any
  ) => {
    let sortedPayGridValue: any = [];
    let filteredClubData: any = [];
    let filteredNormalData: any = [];
    paygridvalues.map((payGridObj, index) => {
      if (payGridObj.Agreementtype == "Club") {
        filteredClubData.push(payGridObj);
      } else {
        filteredNormalData.push(payGridObj);
      }
    });
    sortedPayGridValue = [...filteredClubData, ...filteredNormalData];
    let checked; // to check if the check box is checked or unchecked
    let objCheAdjust = document.getElementById(id) as HTMLInputElement;

    if (objCheAdjust.checked === true) {
      checked = true;
    } else {
      checked = false;
    }
    if (
      agreementselectedId !== undefined &&
      agreementselectedId !== null &&
      checked == true
    ) {
      setAgrId(agreementselectedId);
      adjustDueDateSelectedAgre.push(agreementselectedId);
      adjustDueDateSelectedAgre = Array.from(
        new Set(adjustDueDateSelectedAgre)
      );
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
    } else {
      adjustDueDateSelectedAgre = Array.from(
        new Set(adjustDueDateSelectedAgre)
      );
      adjustDueDateSelectedAgre = adjustDueDateSelectedAgre.filter(function (
        value,
        index,
        arr
      ) {
        return value != agreementselectedId;
      }); //Used for to remove the unchecked agrrement id from the array that which it already pushed into the array while checked
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      if (adjustDueDateSelectedAgre.length == 0) {
        setdeliveryUnconfiremd(false);
      }
      setAgrId(undefined);
    }
    const agr: any = sortedPayGridValue.filter(
      (el: any) => el.Agreementid == agreementselectedId
    );
    console.log("agr val", agr);
    multipleAdjust();
    let objCheBox = document.getElementById(id) as HTMLInputElement;
    let collectSelectedBoxCount = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        collectSelectedBoxCount = collectSelectedBoxCount + 1;
      }
    }
    console.log("collectSelectedBoxCount", collectSelectedBoxCount);
    let checkedCount = collectSelectedBoxCount;
    const agrsus: any = sortedPayGridValue.filter(
      (el: any) => el.Agreementid == String(agreementselectedId)
    );
    if (objCheBox.checked === true) {
      //checkedCount = checkedCount + 1;

      if (
        agrsus[0].Agreementtype != "Club" &&
        agrsus[0].Nextduedate != "SAC" &&
        agrsus[0].Nextduedate != "PIF" &&
        agrsus[0].Nextduedate != "EPO" &&
        agrsus[0].number_of_paymentsmade != 0
      ) {
        setaddsuslist(false);
      }

      if (checkedCount == 1) {
        setrefundsuslist(false);
      } else if (checkedCount > 1) {
        setrefundsuslist(true);
        setmastersuspensearray([]);
      }
      console.log("count", checkedCount);
    } else {
      //checkedCount = checkedCount - 1;
      console.log(agr);
      //removesuspense(agr);
      if (checkedCount <= 0) {
        setaddsuslist(true);
        setrefundsuslist(true);
        setmastersuspensearray([]);
        console.log(mastersuspensearray);
      }
      if (checkedCount == 1) {
        setrefundsuslist(false);
      }
      console.log("entered else");
      console.log("count", checkedCount);
      console.log(addsuslist);
    }
    setSelectedCheckBoxCount(checkedCount);
    calculateTotalDueAmount(
      id,
      index,
      amount,
      agreementselectedId,
      checkedCount
    );
    let totalsusamount: number;
    totalsusamount = 0;
    for (let i = 0; i < checkedAgr.length; i++) {
      console.log(checkedAgr[i].suspenseamount, "line 3680");
      totalsusamount = totalsusamount + checkedAgr[i].suspenseamount;
    }
    console.log(totalsusamount);
    settotolsuspenseamount(totalsusamount);
    console.log(totolsuspenseamount);
    remainingamountdue;
    if (selectedCheckBoxCount > 0 && carryRentWidget == true) {
      setcarryRentCheck(true);
    }
    if (checkedCount == 0) {
      setcarryMessage("No agreements are selected");
      setcarryRentWidget(false);
      setcarryRentAmount("0.00");
      if (paymentOrigin == "Pay By Phone") {
        settotaldue(convAmtWTax);
      } else {
        settotaldue("0.00");
      }
    }
  };

  const removesuspense = (agrsus: any) => {
    console.log(agrsus, "agreement suspense");
    let removearray = JSON.parse(JSON.stringify([...totalsuspensemaster]));

    for (let i = 0; i < removearray.length; i++) {
      if (removearray[i].agreementid == agrsus[0].AgreementNumber) {
        removearray.splice(i, 1);
        break;
      }
    }
    settotalsuspensemaster(removearray);
    console.log(totalsuspensemaster);
  };

  /**changes : adjust due date v2
   * Develeoper : Muzzammil
   * Desc : if the current due date and schedule and amount due is same then we can adjust
   *        the next due date
   */

  const multipleAdjust = () => {
    acceptPaymentParam;
    AcceptclubPaymentParam;
    adjustDueDateSelectedAgre = Array.from(new Set(adjustDueDateSelectedAgre)); //removing duplicate agreementids
    let storeNumber = String(window.sessionStorage.getItem("storeNumber")); //to get the storeNumber
    const clubStatus: any = paygridvalues.filter(
      (el: any) => el?.Agreementtype == "Club"
    );
    if (clubStatus.length > 0) {
      setdeactivateClubAmount(clubStatus[0].Amount);
    }
    if (clubCheckDisabled == false) {
      if (
        clubStatus.length > 0 &&
        !clubStatus[0].originAgreementId &&
        !clubStatus[0].clubDocumentSignStatus
      ) {
        setclubBtnStatus("A");
        setCustOrder("A");
      }
      if (clubStatus.length > 0) {
        if (clubStatus[0].number_of_paymentsmade > 0) {
          setclubStatus("D");
        } else if (clubStatus[0].number_of_paymentsmade == 0) {
          setclubStatus("P");
        }
        if (
          clubStatus[0]?.store_number == sessionStorage.getItem("storeNumber")
        ) {
          setisclubOnSameStore(false);
        }
      } else {
        setclubStatus("A");
        setisclubOnSameStore(false);
      }
    }

    const resultSelecteAgre = paygridvalues.filter(function (e) {
      return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
    }); //to filter the only the required agrrements which is in adjustDueDateSelectedAgre array
    setcheckedAgr(resultSelecteAgre);
    const onlyEPPSelected = resultSelecteAgre.every(
      (el) => el.agreementRateDetails.employeePurchasePlan === "Y"
    );
    setDisableEPOByEPP(onlyEPPSelected);

    if (resultSelecteAgre.length == 0) {
      setadjustButton(false);
      seteditButton(false);
    } //if the all the checkboxes are unchecked the array will be empty then the adjust due date button should not be shown

    let count;
    let editCount;
    let carryRentCount = 0;
    let adjustall = 0;
    let editall = 0;
    let carrycount = 0;
    let carryallowedCount = 0;
    let promoCount = 0;
    let onlyCashsaleCheckedCount = 0;
    let diffStoreCount = 0;
    let lateAmount = 0;
    if (resultSelecteAgre?.length >= 1) {
      let deliveryUnconfirmed: any = [];
      let carryUsed = 0;
      let carryMessage = "";
      let suspenseCount = 0;
      for (let index = 0; index < resultSelecteAgre.length; index++) {
        /**
         * changes:3 Payment verification
         * Developer: Muzzammil
         * PsuedoCode: PC_1.2
         * Description: need to check if an agreement has delivery status 0 and and made more than three payments
         *
         */
        lateAmount =
          Number(lateAmount) + Number(resultSelecteAgre[index].lateFee);
        if (
          String(resultSelecteAgre[index].store_number) != String(storeNumber)
        ) {
          diffStoreCount = diffStoreCount + 1;
        }
        if (Number(resultSelecteAgre[index].miscellaneousItemsNum) > 0) {
          onlyCashsaleCheckedCount++;
        }
        if (
          resultSelecteAgre[index].Agreementtype != "Club" &&
          resultSelecteAgre.length == 1
        ) {
          let agr = resultSelecteAgre;
          if (Number(agr[0].actualEpoAmount) == 0) {
            let actepoamount =
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].EPOAmount !== undefined &&
              agr[0].EPOAmount !== null &&
              agr[0].EPOAmount !== ""
                ? Number(agr[0].EPOAmount) -
                  Number(agr[0].EPOTax) +
                  (Number(agr[0].acceptEpoFlag) == 1
                    ? Number(agr[0].suspenseSub)
                    : 0.0) -
                  Number(
                    agr[0].pastDueRent !== undefined &&
                      agr[0].pastDueRent !== null &&
                      agr[0].pastDueRent !== ""
                      ? agr[0].pastDueRent
                      : 0.0
                  ) -
                  Number(
                    agr[0].lateFee !== undefined &&
                      agr[0].lateFee !== null &&
                      agr[0].lateFee !== ""
                      ? agr[0].lateFee
                      : 0.0
                  )
                : "";
            agr[0].actualEpoAmount = actepoamount;
          }
          setepoPopup({
            taxRate: Number(agr[0].taxRate),
            epoamount:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].EPOAmount !== undefined &&
              agr[0].EPOAmount !== null &&
              agr[0].EPOAmount !== ""
                ? Number(agr[0].EPOAmount) -
                  Number(agr[0].EPOTax) +
                  Number(agr[0].agreementNoteDetails.editedNoteAmount) +
                  (Number(agr[0].acceptEpoFlag) == 1
                    ? Number(agr[0].suspenseSub)
                    : 0.0) -
                  Number(
                    agr[0].pastDueRent !== undefined &&
                      agr[0].pastDueRent !== null &&
                      agr[0].pastDueRent !== ""
                      ? agr[0].pastDueRent
                      : 0.0
                  ) -
                  Number(
                    agr[0].lateFee !== undefined &&
                      agr[0].lateFee !== null &&
                      agr[0].lateFee !== ""
                      ? agr[0].lateFee
                      : 0.0
                  )
                : "",
            lateFee:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].lateFee !== undefined &&
              agr[0].lateFee !== null &&
              agr[0].lateFee !== ""
                ? agr[0].lateFee
                : "",
            suspense:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].SuspenseAmount !== undefined &&
              agr[0].SuspenseAmount !== null &&
              agr[0].SuspenseAmount !== ""
                ? agr[0].SuspenseAmount
                : "",
            taxAmount:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].EPOTax !== undefined &&
              agr[0].EPOTax !== null &&
              agr[0].EPOTax !== ""
                ? agr[0].EPOTax
                : "",
            amountDue:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].pastDueRent !== undefined &&
              agr[0].pastDueRent !== null &&
              agr[0].pastDueRent !== ""
                ? agr[0].pastDueRent
                : "",
            currentDaysLate:
              agr[0] !== undefined &&
              agr[0] !== null &&
              agr[0] !== "" &&
              agr[0].CurrentDaysLate !== undefined &&
              agr[0].CurrentDaysLate !== null &&
              agr[0].CurrentDaysLate !== ""
                ? agr[0].CurrentDaysLate
                : "",
          });
        } else {
          setepoPopup(undefined);
        }
        if (
          Number(resultSelecteAgre[index].number_of_paymentsmade) >= 3 &&
          resultSelecteAgre[index].confirmeddeliversystatus == false
        ) {
          deliveryUnconfirmed.push(resultSelecteAgre[index].AgreementNumber);
          setdeliveryUnconfiremd(true);
        }
        if (Number(resultSelecteAgre[index].isSwitchoutDeliveryPending) === 1) {
          deliveryUnconfirmed.push(
            resultSelecteAgre[index].AgreementNumber + "(Switchout)"
          );
          setdeliveryUnconfiremd(true);
        } // Newly Added
        setagreementDeliveryUncomfirmed(deliveryUnconfirmed);
        //This condition used for enable and disable adjust due date button at store level and if it is club then any store it can be adjustable
        if (
          String(storeNumber) ==
            String(resultSelecteAgre[index].store_number) ||
          (selectedCheckBoxCount <= 2 &&
            resultSelecteAgre[index].Agreementtype == "Club")
        ) {
          setdisableAdjustDueDateButton(false);
        } else {
          setdisableAdjustDueDateButton(true);
        }
        if (
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].Agreementtype == "Club" ||
          resultSelecteAgre[index].number_of_paymentsmade == 0 ||
          resultSelecteAgre[index].FreeTime !== null // Added By Me
        ) {
          carryallowedCount++;
          if (resultSelecteAgre[index].Agreementtype == "Club") {
            setcarryMessage("Club amount cannot be carried");
            carryMessage = "Club amount cannot be carried";
          } else {
            if (carryMessage == "") {
              setcarryMessage(
                "Selected agreement is in intital or final payment"
              );
            }
          }
          carrycount = 1;
        } else {
          carryRentCount++;
        }
        if (Number(resultSelecteAgre[index].carryRentAmountUsed) > 0) {
          carryUsed = Number(
            (
              Number(carryUsed) +
              Number(resultSelecteAgre[index].carryRentAmountUsed)
            ).toFixed(2)
          );
          setagreecarryUsed(carryUsed);
        }
        //carry rent Check Ends
        //For multiple selected agrrements needs to check following conditions for adjust due date
        if (
          resultSelecteAgre[index].Schedule != resultSelecteAgre[0].Schedule ||
          resultSelecteAgre[index].CurrentDueDate !=
            resultSelecteAgre[0].CurrentDueDate ||
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].isReturnAgrFlag == 1 ||
          resultSelecteAgre[index].Agreementtype == "Note Payment" ||
          (resultSelecteAgre[index].number_of_paymentsmade == 0 &&
            resultSelecteAgre[index].Agreementtype != "Club") ||
          String(resultSelecteAgre[index].store_number) !=
            String(storeNumber) ||
          resultSelecteAgre[index].FreeTime !== null // Added By Me
        ) {
          count = 1;
          setadjustButton(false);
        } else {
          adjustall++;
        }
        if (
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].Agreementtype == "Note Payment" ||
          resultSelecteAgre[index].isReturnAgrFlag == 1 ||
          resultSelecteAgre[index].PromoFreeDays == 0 ||
          (resultSelecteAgre[index].number_of_paymentsmade == 0 &&
            resultSelecteAgre[index].Agreementtype != "Club") ||
          String(resultSelecteAgre[index].store_number) != String(storeNumber)
        ) {
          promoCount = 1;
          setpromoFreeTimeButton(true);
        }
        if (
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].number_of_paymentsmade == 0 ||
          resultSelecteAgre[index].Agreementtype == "Club" ||
          String(resultSelecteAgre[index].store_number) != String(storeNumber)
        ) {
          suspenseCount = 1;
          setsuspenseButton(true);
        }
        if (
          resultSelecteAgre[index].Schedule != resultSelecteAgre[0].Schedule ||
          resultSelecteAgre[index].Nextduedate == "SAC" ||
          resultSelecteAgre[index].Nextduedate == "PIF" ||
          resultSelecteAgre[index].Nextduedate == "EPO" ||
          resultSelecteAgre[index].Agreementtype == "Note Payment" ||
          resultSelecteAgre[index].isReturnAgrFlag == 1 ||
          resultSelecteAgre[index].number_of_paymentsmade == 0 ||
          String(resultSelecteAgre[index].store_number) !=
            String(storeNumber) ||
          resultSelecteAgre[index].FreeTime !== null // Added By Me
        ) {
          editCount = 1;
          seteditButton(false);
        } else {
          editall++;
        }
      }
      if (resultSelecteAgre.length == 0 || carryUsed == 0) {
        setagreecarryUsed(0.0);
      } else {
        setagreecarryUsed(carryUsed);
      }
      if (carryallowedCount == resultSelecteAgre.length) {
        setcarryRentCheck(true);
      }
      if (deliveryUnconfirmed.length == 0) {
        setdeliveryUnconfiremd(false);
      }
      if (carrycount == 0) {
        setcarryMessage("");
      }
      if (suspenseCount == 0) {
        setsuspenseButton(false);
      }
      if (promoCount == 0) {
        setpromoFreeTimeButton(true);
      }

      const suspenseEpoChanges = paygridvalues.filter(function (e) {
        return e.Agreementtype != "Club";
      });
      if (suspenseEpoChanges?.length > 0) {
        if (
          onlyCashsaleCheckedCount == resultSelecteAgre.length ||
          (suspenseEpoChanges?.length == 1 &&
            (suspenseEpoChanges[0]?.Nextduedate == "SAC" ||
              suspenseEpoChanges[0]?.Nextduedate == "EPO" ||
              suspenseEpoChanges[0]?.Nextduedate == "PIF"))
        ) {
          setselectedOnlyCashSale(true);
        } else {
          setselectedOnlyCashSale(false);
        }
      }

      if (count != 1) {
        if (adjustall > 1) {
          setadjustAllButton(true);
        } else {
          setadjustAllButton(false);
        }
        setadjustButton(true);
      }
      if (editCount != 1) {
        seteditButton(true);
      }

      //
    }
    let totalsusamount = 0;
    let SuspenseAmountFilter = resultSelecteAgre.filter(
      (x) =>
        x.Agreementtype != "Club" &&
        x.Nextduedate != "PIF" &&
        x.Nextduedate != "SAC" &&
        x.Nextduedate != "EPO" &&
        x.number_of_paymentsmade > 0 &&
        String(x.store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))
    );
    for (let i = 0; i < SuspenseAmountFilter.length; i++) {
      totalsusamount =
        Number(totalsusamount) + Number(SuspenseAmountFilter[i].SuspenseAmount);
    }

    let insertArray: any = [];
    for (let i = 0; i < resultSelecteAgre.length; i++) {
      if (Number(resultSelecteAgre[i].suspenseSub) > 0) {
        if (
          resultSelecteAgre[i].Agreementtype != "Club" &&
          resultSelecteAgre[i].Nextduedate != "PIF" &&
          resultSelecteAgre[i].Nextduedate != "SAC" &&
          resultSelecteAgre[i].Nextduedate != "EPO" &&
          resultSelecteAgre[i].number_of_paymentsmade > 0 &&
          String(resultSelecteAgre[i].store_number) ==
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          insertArray.push({
            sustype:
              Number(resultSelecteAgre[i].suspenseSub) > 0 ? "Used" : "type",
            agreementnumber: resultSelecteAgre[i].Agreementid,
            agreementid: resultSelecteAgre[i].AgreementNumber,
            schedure: resultSelecteAgre[i].Schedule,
            paymentamount: resultSelecteAgre[i].Amount,
            epoamount: resultSelecteAgre[i].EPOAmount,
            suspenseamount: resultSelecteAgre[i].SuspenseAmount,
            newsuspense: Number(resultSelecteAgre[i].suspenseSub),
            agreementType: resultSelecteAgre[i].Agreementtype,
            transferedCOA: false,
          });
        } else if (
          (resultSelecteAgre[i].Nextduedate == "PIF" ||
            resultSelecteAgre[i].Nextduedate == "SAC" ||
            resultSelecteAgre[i].Nextduedate == "EPO") &&
          resultSelecteAgre[i].number_of_paymentsmade > 0 &&
          String(resultSelecteAgre[i].store_number) ==
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          insertArray.push({
            sustype:
              Number(resultSelecteAgre[i].suspenseSub) > 0
                ? "FinalUsed"
                : "type",
            agreementnumber: resultSelecteAgre[i].Agreementid,
            agreementid: resultSelecteAgre[i].AgreementNumber,
            schedure: resultSelecteAgre[i].Schedule,
            paymentamount: resultSelecteAgre[i].Amount,
            epoamount: resultSelecteAgre[i].EPOAmount,
            suspenseamount: resultSelecteAgre[i].SuspenseAmount,
            newsuspense: Number(resultSelecteAgre[i].suspenseSub),
            agreementType: resultSelecteAgre[i].Agreementtype,
            transferedCOA: false,
          });
        }
      }
      if (Number(resultSelecteAgre[i].suspenseAdd) > 0) {
        if (
          resultSelecteAgre[i].Agreementtype != "Club" &&
          resultSelecteAgre[i].Nextduedate != "PIF" &&
          resultSelecteAgre[i].Nextduedate != "SAC" &&
          resultSelecteAgre[i].Nextduedate != "EPO" &&
          resultSelecteAgre[i].number_of_paymentsmade > 0 &&
          String(resultSelecteAgre[i].store_number) ==
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          insertArray.push({
            sustype:
              Number(resultSelecteAgre[i].suspenseAdd) > 0 ? "Add" : "type",
            agreementnumber: resultSelecteAgre[i].Agreementid,
            agreementid: resultSelecteAgre[i].AgreementNumber,
            schedure: resultSelecteAgre[i].Schedule,
            paymentamount: resultSelecteAgre[i].Amount,
            epoamount: resultSelecteAgre[i].EPOAmount,
            suspenseamount: resultSelecteAgre[i].SuspenseAmount,
            newsuspense: Number(resultSelecteAgre[i].suspenseAdd),
            agreementType: resultSelecteAgre[i].Agreementtype,
            transferedCOA: false,
          });
        }
      }
    }
    //debugger;
    if (resultSelecteAgre.length > 0) {
      for (let i = 0; i < paygridvalues.length; i++) {
        if (
          Number(paygridvalues[i].transferOldSuspense) -
            Number(paygridvalues[i].SuspenseAmount) <
          0
        ) {
          if (
            paygridvalues[i].Agreementtype != "Club" &&
            paygridvalues[i].Nextduedate != "PIF" &&
            paygridvalues[i].Nextduedate != "SAC" &&
            paygridvalues[i].Nextduedate != "EPO" &&
            paygridvalues[i].number_of_paymentsmade > 0 &&
            String(paygridvalues[i].store_number) ==
              String(window.sessionStorage.getItem("storeNumber"))
          ) {
            insertArray.push({
              sustype: "TransferAdd",
              agreementnumber: paygridvalues[i].Agreementid,
              agreementid: paygridvalues[i].AgreementNumber,
              schedure: paygridvalues[i].Schedule,
              paymentamount: paygridvalues[i].Amount,
              epoamount: paygridvalues[i].EPOAmount,
              suspenseamount: paygridvalues[i].SuspenseAmount,
              newsuspense:
                Number(paygridvalues[i].SuspenseAmount) -
                Number(paygridvalues[i].transferOldSuspense),
              agreementType: paygridvalues[i].Agreementtype,
              transferedCOA: false,
            });
          }
        }
        if (
          Number(paygridvalues[i].transferOldSuspense) -
            Number(paygridvalues[i].SuspenseAmount) >
          0
        ) {
          if (
            paygridvalues[i].Agreementtype != "Club" &&
            paygridvalues[i].Nextduedate != "PIF" &&
            paygridvalues[i].Nextduedate != "SAC" &&
            paygridvalues[i].Nextduedate != "EPO" &&
            paygridvalues[i].number_of_paymentsmade > 0 &&
            String(paygridvalues[i].store_number) ==
              String(window.sessionStorage.getItem("storeNumber"))
          ) {
            insertArray.push({
              sustype: "TransferUsed",
              agreementnumber: paygridvalues[i].Agreementid,
              agreementid: paygridvalues[i].AgreementNumber,
              schedure: paygridvalues[i].Schedule,
              paymentamount: paygridvalues[i].Amount,
              epoamount: paygridvalues[i].EPOAmount,
              suspenseamount: Number(paygridvalues[i].SuspenseAmount),
              newsuspense:
                Number(paygridvalues[i].transferOldSuspense) -
                Number(paygridvalues[i].SuspenseAmount),
              agreementType: paygridvalues[i].Agreementtype,
              transferedCOA: paygridvalues[i].isCOATransfered,
            });
          } else if (
            (paygridvalues[i].Nextduedate == "PIF" ||
              paygridvalues[i].Nextduedate == "SAC" ||
              paygridvalues[i].Nextduedate == "EPO") &&
            paygridvalues[i].number_of_paymentsmade > 0 &&
            String(paygridvalues[i].store_number) ==
              String(window.sessionStorage.getItem("storeNumber"))
          ) {
            insertArray.push({
              sustype: "FinalTransferUsed",
              agreementnumber: paygridvalues[i].Agreementid,
              agreementid: paygridvalues[i].AgreementNumber,
              schedure: paygridvalues[i].Schedule,
              paymentamount: paygridvalues[i].Amount,
              epoamount: paygridvalues[i].EPOAmount,
              suspenseamount: Number(paygridvalues[i].SuspenseAmount),
              newsuspense:
                Number(paygridvalues[i].transferOldSuspense) -
                Number(paygridvalues[i].SuspenseAmount),
              agreementType: paygridvalues[i].Agreementtype,
              transferedCOA: paygridvalues[i].isCOATransfered,
            });
          }
        }
      }
    }

    setLatelast10value(lateAmount);
    totalsuspensemaster = insertArray;
    settotalsuspensemaster(insertArray);
    let susAddCount = 0;
    let susUseCount = 0;
    for (let i = 0; i < insertArray.length; i++) {
      if (insertArray[i].sustype == "Add") {
        susAddCount == 1;
        setcheckdisable(true);
        setinstoreDisable(true);
        if (paymentOrigin != "Store") {
          phone({ target: { value: "Store" } });
        }
      }
      // if(insertArray[i].sustype=="Used"){
      //   susUseCount++
      // }
      // if(susAddCount>1||susUseCount>1){
      //   if(paymentOrigin!="Store"){
      //     phone({target:{value:"Store"}})
      //   }
      //   setinstoreDisable(true)
      // }
    }
    if (insertArray.length == 0) {
      setcheckdisable(false);
      setinstoreDisable(false);
    }
    settotalsuspensemaster(insertArray);

    if (resultSelecteAgre.length == 1) {
      if (
        resultSelecteAgre[0].Agreementtype != "Club" &&
        resultSelecteAgre[0].number_of_paymentsmade != 0 &&
        resultSelecteAgre[0].FreeTime == null &&
        resultSelecteAgre[0].isReturnAgrFlag != 1
      ) {
        setshowEPOGridBtn(true);
      } else {
        setshowEPOGridBtn(false);
      }
    } else {
      setshowEPOGridBtn(false);
    }
    if (diffStoreCount == 0) {
      setdiffStoreErrLbl(false);
    } else {
      setdiffStoreErrLbl(true);
    }
    if(enableCashPayment){ 
      setcashselected(1);
      setCardrequire({
        ...Cardrequire,
        drpdown: false,
        CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
      });
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`);
    }
  };

  /**changes : Edit Schedule v2
   * Develeoper : Muzzammil Shaik
   * Desc : onclick of EditSchedulee line item set the editSchedulePopUp (true)
   */
  const editSchedule = () => {
    if (
      editButton == true &&
      selectedCheckBoxCount >= 1 &&
      disableAdjustDueDateButton == false
    ) {
      seteditSchedulePopUp(true);
      setPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };

  const AddTrailingZerosToAmount = (inputValue: any) => {
    inputValue = Number(inputValue).toFixed(2);
    let a = Number(inputValue).toFixed(2).split(".");

    let retValue = inputValue;
    if (a.length < 2) {
      retValue = inputValue + ".00";
    } else if (a.length === 2 && Number(a[1]) * 10 < 100) {
      if (a[1] !== "") {
        const b = a[1].split("");
        if (b[0] !== "0") {
          retValue = inputValue + "0";
        }
        if (b[0] === "0" && b[1] === undefined) {
          retValue = inputValue + "0";
        }
      } else {
        retValue = inputValue + ".00";
      }
    }
    console.log("--retValue--", retValue);
    return retValue == "NaN.00" ? "0.00" : retValue;
  };

  const setcheckamountblur = (e: any) => {
    console.log("** e.target.value **", e.target.value);
    if (
      e.target.value !== "" &&
      e.target.value !== undefined &&
      Number(e.target.value) > 0
    ) {
      const a = adddata.Amount.split(".");

      if (a.length < 2) {
        setadddata({ ...adddata, Amount: adddata.Amount + ".00" });
      } else if (a.length === 2 && a[1] * 10 < 100) {
        if (a[1] !== "") {
          const b = a[1].split("");
          if (b[0] !== "0") {
            const amount = adddata.Amount + "0";
            setadddata({ ...adddata, Amount: amount });
          }
          if (b[0] === "0" && b[1] === undefined) {
            setadddata({ ...adddata, Amount: adddata.Amount + "0" });
          }
        } else {
          setadddata({ ...adddata, Amount: adddata.Amount + "00" });
        }
      }
    }
  };

  const masterSusOnBlur = (
    e: any,
    type: string,
    index: any,
    disabled: boolean
  ) => {
    // debugger
    debugger;
    // setsusbtndisabled(disabled)
    console.log(mastersuspensearray);
    console.log(mastertranssuspensearray);
    let inputText: any =
      e.target.value == "" ||
      e.target.value == undefined ||
      Number.isNaN(e.target.value)
        ? 0.0
        : e.target.value;

    if (type == "Trans") {
      let totaltransnewsusamount: number;
      totaltransnewsusamount = 0;

      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        console.log(mastertranssuspensearray[i].newsuspense, "line 3680");
        totaltransnewsusamount =
          totaltransnewsusamount + mastertranssuspensearray[i].newsuspense;
      }

      settotalnewsuspenseamount(totaltransnewsusamount);

      console.log(mastertranssuspensearray);

      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        if (
          mastertranssuspensearray[i].suspenseamount <
          mastertranssuspensearray[i].newsuspense
        ) {
          mastertranssuspensearray[i].sustype = "TransferAdd";
        } else {
          mastertranssuspensearray[i].sustype = "TransferUsed";
        }
      }

      console.log(mastertranssuspensearray);
      console.log(mastertranssuspensearray, "updated");
    }
    setaddsus(AddTrailingZerosToAmount(inputText));
    console.log(AddTrailingZerosToAmount(inputText));

    console.log(inputText, "onblur suspense");
    let totalnewsusamount: number;
    totalnewsusamount = 0;

    for (let i = 0; i < masteraddsuspensearray.length; i++) {
      console.log(masteraddsuspensearray[i].newsuspense, "line 3680");
      totalnewsusamount =
        totalnewsusamount + Number(masteraddsuspensearray[i].newsuspense);
    }
    console.log(totalnewsusamount, "new added suspense");

    //  settotalsuspenseamount(totalnewsusamount)

    let newtransferedSuspense = 0;
    let ActualSuspenseAmount = 0;
    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      newtransferedSuspense =
        newtransferedSuspense + Number(mastertranssuspensearray[i].newsuspense);
      ActualSuspenseAmount =
        ActualSuspenseAmount +
        Number(mastertranssuspensearray[i].suspenseamount);
    }
    if (
      Number(Number(ActualSuspenseAmount).toFixed(2)) -
        Number(Number(newtransferedSuspense).toFixed(2)) ==
      0
    ) {
      settransferDifferenceZero(false);
    } else {
      settransferDifferenceZero(true);
    }
    let count = 0;
    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      if (
        Number(mastertranssuspensearray[i].newsuspense) >
        Number(mastertranssuspensearray[i].epoamount)
      ) {
        count = 1;
      }
    }
    if (count == 0) {
      setsusbtndisabled(false);
    } else {
      setsusbtndisabled(true);
    }
    settotalnewsuspenseamount(newtransferedSuspense);
    // console.log(difference, "the difference")

    console.log(totalnewsuspenseamount, "suspense total");
  };

  const masterSusOnchange = (
    e: any,
    type: string,
    index = 0,
    disabled: boolean
  ) => {
    debugger;
    setaddsus("0.00");
    setusesus("0.00");
    settranssus("0.00");
    // setsusbtndisabled(disabled)
    console.log("entered susonchange");
    console.log(type);
    let inputText: any = e.target.value;

    if (type == "Add") {
      setaddsus(e.target.value);
      masteraddsuspensearray[index].newsuspense = inputText;
      masteraddsuspensearray[index].sustype = type;
      // }
      let count = 0;
      for (let i = 0; i < masteraddsuspensearray.length; i++) {
        if (
          Number(masteraddsuspensearray[i].newsuspense) >
            Number(
              (
                Number(masteraddsuspensearray[i].epoamount) -
                Number(masteraddsuspensearray[i].suspenseamount)
              ).toFixed(2)
            ) ||
          Number(Number(masteraddsuspensearray[i].newsuspense).toFixed(2)) ==
            Number(
              (
                Number(masteraddsuspensearray[i].epoamount) -
                Number(masteraddsuspensearray[i].suspenseamount)
              ).toFixed(2)
            )
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }
    }

    if (type == "Used") {
      setusesus(e.target.value);
      masterusesuspensearray[index].newsuspense = inputText;
      masterusesuspensearray[index].sustype = type;
      // setmasterusesuspensearray(masterusesuspensearray)
      console.log("** inputText **", masterusesuspensearray[index]);
      let count = 0;
      for (let i = 0; i < masterusesuspensearray.length; i++) {
        if (
          Number(masterusesuspensearray[i].newsuspense) >
          Number(masterusesuspensearray[i].suspenseamount)
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }

      // }
    }
    debugger;
    if (type == "Trans") {
      settranssus(e.target.value);
      mastertranssuspensearray[index].newsuspense = inputText;
      // mastersuspensearray[index].newsuspense =AddTrailingZerosToAmount(inputText);
      mastertranssuspensearray[index].sustype = type;
      // setmasteraddsuspensearray(mastertranssuspensearray)
      let newtransferedSuspense = 0;
      let ActualSuspenseAmount = 0;
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        newtransferedSuspense =
          newtransferedSuspense +
          Number(mastertranssuspensearray[i].newsuspense);
        ActualSuspenseAmount =
          ActualSuspenseAmount +
          Number(mastertranssuspensearray[i].suspenseamount);
      }
      if (
        Number(Number(ActualSuspenseAmount).toFixed(2)) -
          Number(Number(newtransferedSuspense).toFixed(2)) ==
        0
      ) {
        settransferDifferenceZero(false);
      } else {
        settransferDifferenceZero(true);
      }
      let count = 0;
      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        if (
          Number(mastertranssuspensearray[i].newsuspense) >
          Number(mastertranssuspensearray[i].epoamount)
        ) {
          count = 1;
        }
      }
      if (count == 0) {
        setsusbtndisabled(false);
      } else {
        setsusbtndisabled(true);
      }
      setdifferenceamount(
        Number(ActualSuspenseAmount) - Number(newtransferedSuspense)
      );
      settotalnewsuspenseamount(newtransferedSuspense);
      console.log("** inputText **", mastertranssuspensearray[index]);
    }

    setmasteraddsuspensearray(masteraddsuspensearray);
    setmasterusesuspensearray(masterusesuspensearray);
    setmastertranssuspensearray(mastertranssuspensearray);
  };

  const billingAddressValidation =(resp:any) =>{
    // debugger;
    let obj:any
    let newaddressLine1=""
    let newstate=""
    let zip5=""
    let arr:any=[]
    if(resp!=undefined)
    {
     arr.push(resp)
    let errorFlag=arr.some((x)=>x?.addressLine1 !=null&& x?.addressLine1 !="" && x?.state != null && x?.state != "" && x?.zip?.length ==5 && x?.zip != null && x?.zip != "")
    if(errorFlag==false)
    {
      let postalZip = defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip == null ? null :
      defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip?.replace(/-/g, "")
      let addressLine1=defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.addressLine1==null?null:
      defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.addressLine1
      let state=defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.state==null?null:
      defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.state
      if (
        (postalZip == undefined ||
        postalZip == null ||
        postalZip == "" ||
        postalZip?.length != 5) && (addressLine1 == undefined ||
        addressLine1 == null ||
        addressLine1 == "" )&&
        (state == undefined ||
        state == null ||
        state == "")
      ) {
        let JsonsessionStoreDetails: any =
          window.sessionStorage.getItem("storeDetails");
        console.log(JsonsessionStoreDetails, "sessionStoreDetails1");
        // console.log(atob(JsonsessionStoreDetails),"sessionStoreDetails2")
        let sessionStoreDetails: any;
        try {
          sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
        } catch (e: any) {
          console.log(e, "not json formate");
        }

        console.log(sessionStoreDetails, "sessionStoreDetails2");
        if (postalZip?.length != 5 || postalZip == undefined || postalZip == null) {
          zip5 = sessionStoreDetails?.zip?.split("-")[0];
          // zip4 = sessionStoreDetails?.zip?.split("-")[1];
        }
        newaddressLine1=sessionStoreDetails?.addressLine1;
        newstate=sessionStoreDetails?.state
      }
    obj={
      addressLine1:newaddressLine1==""?addressLine1:newaddressLine1,
      addressLine2: resp.addressLine2==null?"":resp.addressLine2,
      city: resp.city,
      state: newstate==""?state:newstate,
      zip:zip5==""?postalZip:zip5
    }
    }else{
      obj=
      {
        addressLine1:resp?.addressLine1,
        addressLine2: resp?.addressLine2==null?"":resp?.addressLine2,
        city:resp?.city,
        state:resp?.state,
        zip:resp?.zip
      }
    }
  }
    return obj
  }
  const redirectionTopaymentsearch = () => {
    const request: any = searchVal;
    request.cancelClick = false;
    setsearchVal(request);
    history.push({
      pathname: `/payment1/paymentsearch`,
    });
  };
  const redirectionToCustomer = () => {
    history.push({
      pathname: `/customer/update/${customerId}/customer`,
    });
  };

  const redirectToCustScrollToPage = (alertTypeId: any) => {
    history.push({
      pathname: `/customer/update/${customerId}/customer?alertTypeId=${alertTypeId}`,
    });
  };

  const redirectionToAgreement = (agreementId: string) => {
    history.push({
      pathname: `/agreement/info/details/${customerId}/${agreementId}`,
    });
  };

  /*This function is used to set the amount to the state on blur of the amount box in the cash tab.
  Based on this amount and condiions of change and remaining amount the amount gets updated.*/
  const cashamounttextboxblur = (e: any) => {
    let InputTxt = e.target.value === "" ? "0.00" : e.target.value;
    FloatInputTxt = InputTxt;
    console.log("** InputTxt Locale String**", InputTxt);
    console.log("cash amt", cashamountcheckbox);
    console.log("RemCashamt", remainingamountdue);

    /** Code for adding tender array for CASH. The logic is
     * we splice the current tender array if it has "Cash" tender
     * and will re-add the same if the textbox value is greater than 0
     * If the textbox value is 0 we will remove the cash tender from
     * tender array.
     */

    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype === "Cash") {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }

    if (InputTxt !== "" && parseFloat(InputTxt) !== 0.0) {
      let InputTxtArr = InputTxt.split("");
      /**
       * Below code is to set 1000 separator for the amount entered.
       */
      if (InputTxtArr.includes(",") === true) {
        InputTxtArr = InputTxtArr.filter((element: any) => element !== ",");
        let TempString = "";
        for (let i = 0; i <= InputTxtArr.length - 1; i++) {
          TempString = TempString + InputTxtArr[i];
        }
        FloatInputTxt = parseFloat(TempString);
      } else {
        FloatInputTxt = parseFloat(InputTxt);
      }
      const FloatInputTxttofixed = fixedChange(FloatInputTxt);
      const tendertypedetails = {
        tendertype: "Cash",
        Amount: FloatInputTxttofixed,
      };

      tendertypeglobalarray.push(tendertypedetails);
    }

    settotalduetendertype(tendertypeglobalarray);

    /**
     * Code for adding trailing 0's after the decimal
     * based on the amount entered in the cash amount
     * textbox.
     */
    setaddcash(AddTrailingZerosToAmount(FloatInputTxt));

    /**
     * If input text is greater than the remaining amount due then
     *  set remaining amount due = 0
     *  set change as ( input text )  - remaining amount due
     *  enable the "Accept Payment" button
     *
     * If input text is less than or equal to remianing amount due then
     *  set remaining amount due = remaining amount due - input text
     *  set change = 0
     *  disable the "Accept Payment" button
     */
    if (InputTxt !== "" && InputTxt !== undefined) {
      InputTxt = String(InputTxt).replace(",", "");
      let objRemAmount = 0;
      let objChangeAmount = 0;
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      const cardamount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      let sumOfOtherTenderAmount =
        Number(addcash) +
        Number(totalamountcheck) +
        Number(cardamount) +
        Number(availableCreds) +
        Number(swipeCardAmount) +
        Number(chargeCardAmount) -
        Number(addSusTotalAmount) +
        Number(usedSusTotalAmount);
      let remAmtDueWithOutTenders =
        Number(Number(totaldue).toFixed(2)) -
        Number(sumOfOtherTenderAmount.toFixed(2));
      if (remAmtDueWithOutTenders < 0) {
        objRemAmount = 0.0;
        objChangeAmount = -1 * remAmtDueWithOutTenders;
      } else {
        objRemAmount = remAmtDueWithOutTenders;
        objChangeAmount = 0;
      }

      setremainingamountdue(objRemAmount.toFixed(2));
      console.log(objRemAmount.toFixed(2), "after updation amt due");
      setchange(objChangeAmount.toFixed(2));

      /***
       * If the difference between total due amount and sum of all tenders
       * and available credits is less than or equal to 0 then
       * enable the "Accept Payment" button else disable the
       * "Accept Payment" button.
       */
      let isChecked = 0;
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        console.log("entered into the for loop of loadother action");
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          isChecked = 1;
        }
      }
      if (isChecked == 1 && remAmtDueWithOutTenders <= 0) {
        if (cardamount > 0 && cvvTextValue.length >= 3)
          setAcceptpaymentbuttonenable(false);
        else if (cardamount > 0 && swipeFlag == false)
          setAcceptpaymentbuttonenable(true);
        else setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
    }

    FormAcceptPaymentData(0, 0);
  };

  /*This method is used to calculate the total amount if select all check box is checked */
  const calculatetotalamount = () => {
    if (paygridvalues.length > 0) {
      let TotalAmountDue = 0;
      let Totaltax = 0;
      for (let i = 0; i <= paygridvalues.length - 1; i++) {
        TotalAmountDue = TotalAmountDue + paygridvalues[i].Amount;
        Totaltax = Totaltax + paygridvalues[i].Taxamount;
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      const resultSelecteAgre = paygridvalues.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let amount = 0;
      for (let i = 0; i < resultSelecteAgre.length; i++) {
        amount = amount + Number(resultSelecteAgre[i].Amount);
      }
      let objAvailCreditsAmt = 0;
      if (Number(amount) <= Number(paygridvalues[0].wlDeposit)) {
        objAvailCreditsAmt = amount;
        // Number(paygridvalues[index].COA) +
        // Number(paygridvalues[index].wlDeposit)
        // Number(paygridvalues[index].ipSuspense)
        console.log(objAvailCreditsAmt, "avab credit total amount");
        setAvailableCreds(objAvailCreditsAmt);
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(amount).toFixed(2),
          },
        ]);
      } else if (Number(amount) > Number(paygridvalues[0].wlDeposit)) {
        objAvailCreditsAmt =
          Number(paygridvalues[0].COA) +
          Number(paygridvalues[0].wlDeposit) +
          Number(paygridvalues[0].ipSuspense);
        console.log(objAvailCreditsAmt, "avab credit total amount");
        setAvailableCreds(objAvailCreditsAmt);
        setavailableCredsremaining([
          {
            balanceType: "WEBLEAD",
            accountBalance: Number(paygridvalues[0].wlDeposit).toFixed(2),
          },
          {
            balanceType: "COA",
            accountBalance: Number(paygridvalues[0].COA).toFixed(2),
          },
          {
            balanceType: "IP Suspence",
            accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
          },
        ]);
      } else if (Number(amount) == 0) {
        objAvailCreditsAmt =
          Number(paygridvalues[0].COA) + Number(paygridvalues[0].ipSuspense);
        console.log(objAvailCreditsAmt, "avab credit total amount");
        setAvailableCreds(objAvailCreditsAmt);
        setavailableCredsremaining([
          {
            balanceType: "COA",
            accountBalance: Number(paygridvalues[0].COA).toFixed(2),
          },
          {
            balanceType: "IP Suspence",
            accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
          },
        ]);
      }
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      const CardAmount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      console.log("CardAmount", CardAmount);

      let objTenderAmount =
        Number(addcash) +
        Number(totalamountcheck) +
        Number(CardAmount) +
        Number(chargeCardAmount) +
        Number(swipeCardAmount);
      console.log("Tender Amount", objTenderAmount);

      let objRemainingAmt =
        Number(TotalAmountDue.toFixed(2)) -
        Number(objAvailCreditsAmt.toFixed(2)) -
        Number(objTenderAmount.toFixed(2)) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount);
      console.log("** objRemainingAmt **", objRemainingAmt);
      if (Cardrequire.PaymentOrgin === "Pay By Phone") {
        objRemainingAmt = objRemainingAmt + Number(convAmtWTax);
        TotalAmountDue = TotalAmountDue + Number(convAmtWTax);
      }

      settotaltax(Totaltax);
      if (defaultTotaldue == undefined || Number(defaultTotaldue) == 0) {
        setdefaultTotaldue(TotalAmountDue);
      }
      settotaldue(TotalAmountDue);
      if (objRemainingAmt <= 0.0) {
        setremainingamountdue(0.0);
        setRemaniningAmtStatic(0.0);
        setAcceptpaymentbuttonenable(false);
        objRemainingAmt === 0
          ? setchange(0.0)
          : setchange(Math.abs(objRemainingAmt));
      } else {
        setremainingamountdue(objRemainingAmt);
        setRemaniningAmtStatic(objRemainingAmt);
        setAcceptpaymentbuttonenable(true);
        setchange(0.0);
      }
      setcoaamt(paygridvalues[0].COA);
      setwldepositamount(paygridvalues[0].wlDeposit);
      setipsuspense(paygridvalues[0].ipSuspense);
    }
  };

  const Dynamicstategen = (e: any) => {};
  /*This method is used to calculate the total amount on unchecking all the check boxes   */
  const subtractamount = () => {
    if (paygridvalues.length > 0) {
      let TotalAmountDue = totaldue;
      let Totaltax = totaltax;
      let remAmt = 0;
      setaddcash("0.00");
      settotalamountcheck(0.0);
      for (let i = 0; i <= paygridvalues.length - 1; i++) {
        TotalAmountDue = Number(TotalAmountDue) - paygridvalues[i].Amount;
        Totaltax = Totaltax - paygridvalues[i].Taxamount;
      }
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      remAmt =
        Number(TotalAmountDue) -
        Number(availableCreds) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount);
      remAmt = Number(remAmt.toFixed(2));
      settotaltax(Totaltax);
      settotaldue(TotalAmountDue);
      console.log("-------->remaining amount", remAmt);
      if (remAmt <= 0.0) {
        setremainingamountdue(0.0);
        remAmt === 0 ? setchange(0.0) : setchange(Math.abs(remAmt));
      } else {
        setremainingamountdue(remAmt);
        setchange(0.0);
      }
      setRemaniningAmtStatic(remAmt);
      console.log(
        "-------->remaining amount after remainingamountstatic",
        remAmt
      );
    }
  };
  /*This function is used to set the check type whether it is check or money order*/
  const setchecktype = (e: any) => {
    if (e.target.value !== "0") {
      setadddata({ ...adddata, checktype: e.target.value });
      setType(false);
    } else {
      setadddata({ ...adddata, checktype: e.target.value });
      setType(true);
    }
  };

  /**
   * This method is used to set or increase the cash amount
   * based on the cash amount button click.
   * @param e - Pass the amount given in the button.
   */
  const setcashamount = (e: number) => {
    let objAmt = Number(addcash) + e;
    console.log("** objAmt **", objAmt.toFixed(2));
    setaddcash(objAmt.toFixed(2));
    setcashamountcheckbox(objAmt);
    let objTenderArr = tendertypeglobalarray.filter(
      (x) => x.tendertype === "Cash"
    );
    if (objTenderArr.length > 0) {
      objTenderArr[0].Amount = objAmt.toFixed(2);
    } else {
      const tendertypedetails = {
        tendertype: "Cash",
        Amount: objAmt.toFixed(2),
      };
      tendertypeglobalarray.push(tendertypedetails);
    }
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);

    const CardAmount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    console.log("CardAmount", CardAmount);

    let objTenderAmount =
      objAmt +
      Number(totalamountcheck) +
      Number(CardAmount) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount);
    console.log("Tender Amount", objTenderAmount);
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let objChgAmt =
      Number(totaldue) -
      objTenderAmount -
      Number(availableCreds) +
      Number(addSusTotalAmount) -
      Number(usedSusTotalAmount);
    if (objChgAmt > 0) {
      setremainingamountdue(objChgAmt);
    } else {
      setchange(Math.abs(objChgAmt).toFixed(2));
      setremainingamountdue(0.0);
      if (CardAmount > 0 && cvvTextValue.length >= 3)
        setAcceptpaymentbuttonenable(false);
      else if (CardAmount > 0 && swipeFlag == false)
        setAcceptpaymentbuttonenable(true);
      else setAcceptpaymentbuttonenable(false);
    }
    FormAcceptPaymentData(Number(objAmt), 0);
  };

  useEffect(() => {
    if (checking === 1) {
      BindGridcheck();
      /*Bindagreementamount();*/
    }
    setchecking("0");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking]);

  //Onclick of change radio button starts here
  const RadiobtnChange = (e: any) => {
    if (e.target.value === "credit") {
      setradioBtn("1");
      setchangeRadio(false);
      setCreditRadio(true);
    } else if (e.target.value === "change") {
      setradioBtn("0");
      setCreditRadio(false);
      setchangeRadio(true);
    }
  };
  const verbalcheckbox = (e: any) => {
    if (e.target.checked === true) {
      setvarbalecheckbox(true);
      setsendtext(false);
    } else {
      setvarbalecheckbox(false);
      setsendtext(true);
    }
  };
  const Gridbindingunenrollautopay = () => (
    <>
      {unenrollautopayfilterdata != undefined &&
      unenrollautopayfilterdata.length > 0
        ? unenrollautopayfilterdata.map((value, index: any) => {
            return (
              <RACTableRow key={index} className={classes.tablerowtheme}>
                <RACTableCell>
                  {/* <input className="form-check-input" type="checkbox" name="popupgrid" onChange={(e) => { unCheckandChecking(index) }} checked={updatedarray[index]} value={index} id={value.Agreementid + ',' + 'id'} /> */}
                  <RACCheckBox
                    //className={`${classes.formcheckinput} ${classes.checkboxStyle}`}
                    name="popupgrid"
                    onChange={() => unCheckandChecking(index)}
                    checked={updatedarray[index]}
                    value={index}
                    id={value.Agreementid + "," + "id"}
                  />
                </RACTableCell>
                <RACTableCell>{value.Agreementdesc}</RACTableCell>
                <RACTableCell>${value.Amount.toFixed(2)}</RACTableCell>
                <RACTableCell>{value.Schedule}</RACTableCell>
                <RACTableCell>{value.Nextduedate}</RACTableCell>
              </RACTableRow>
            );
          })
        : null}
    </>
  );

  /*These functions are used to set the value from the textbox to the state. Each function is called based on the onchange event in the add check popup or add moneyorder popup*/
  const setcheckno = (e: any) => {
    setadddata({
      ...adddata,
      checkno: e.target.value.replace(/\D/, ""),
      Type: e.target.name,
    });
    setchecknovalid(false);
  };
  const setcheckwrittento = (e: any) => {
    setadddata({
      ...adddata,
      Written_To: e.target.value.replace(/[^A-Za-z']/gi, ""),
    });
    setwrittento(false);
  };
  const setcheckwrittentby = (e: any) => {
    setadddata({
      ...adddata,
      Written_by: e.target.value.replace(/[^A-Za-z']/gi, ""),
    });
    setwritenby(false);
  };
  const setcheckamount = (e: any) => {
    if (parseFloat(e) < 100000.0 || e === undefined) {
      setadddata({ ...adddata, Amount: e });
      setAmount(false);
    }
  };
  const cashamounttextbox = (inputText: any) => {
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    const cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    inputText = String(inputText).replace(",", "");
    if (validateCashInput(inputText)) {
      setaddcash(inputText);
      console.log("** inputText **", inputText);
      if (
        inputText != "" &&
        inputText != undefined &&
        parseFloat(inputText) < 100000.0
      ) {
        setcashamountcheckbox(inputText);
        const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Add" && Number(e.newsuspense) > 0;
        });
        const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Used" && Number(e.newsuspense) > 0;
        });
        let addSusTotalAmount =
          addSuspenseAmount.length == 0
            ? 0.0
            : addSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let usedSusTotalAmount =
          useSuspenseAmount.length == 0
            ? 0.0
            : useSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let remAmtDue =
          Number(totaldue) -
          Number(availableCreds) -
          Number(totalamountcheck) -
          cardamount -
          Number(chargeCardAmount) -
          Number(swipeCardAmount) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount);
        setremainingamountdue(AddTrailingZerosToAmount(remAmtDue));
      }
    }
  };

  /*This accrodionclick function is used to hide and visible the data that needs to rendered.*/
  const Accordionclick = (index: any) => {
    if (index === gridhide) {
      setgridhide("");
    } else {
      setgridhide(index);
    }
  };

  /*This onsave function is used to bind the data generated inside the grid of check or moneyorder.Here if the e.target.value==1 then it represents the check else the moneyorder*/
  const save = (e: any) => {
    /* eslint-disable no-debugger */
    setCardrequire({ ...Cardrequire, txt2paybtn: true });
    settotalcontrol(0);
    setcancelindication(0);
    const arr = {
      check_type: adddata.checktype,
      Type_info: adddata.Type,
      check_no: adddata.checkno,
      Written_too: adddata.Written_To,
      Written_by: adddata.Written_by,
      Amount_rac: adddata.Amount,
    };
    if (checkmonvar === "1") {
      let flag1 = 0;
      if (adddata.checktype === "0") {
        flag1 = 1;
        setType(true);
      }
      if (adddata.checkno === "") {
        flag1 = 1;
        setchecknovalid(true);
      }
      if (adddata.Written_To === "") {
        flag1 = 1;
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        flag1 = 1;
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        flag1 = 1;
        setAmount(true);
      }
      if (
        adddata.checktype !== "0" &&
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        flag1 === 0
      ) {
        if (
          adddata.checktype === "1" &&
          Number(adddata.Amount) > Number(remainingamountdue)
        ) {
          setpersonalcheckalert(true);
        } else if (
          adddata.checktype === "2" &&
          Number(adddata.Amount) > Number(remainingamountdue)
        ) {
          settravellercheckalert(true);
        } else {
          setaddcheck(false);
          setaddmoneyorder(false);
          addrowarray.push(arr);
          setaddataanother(addrowarray);
          const reduceremaingamount =
            Number(Number(remainingamountdue).toFixed(2)) -
            Number(Number(adddata.Amount).toFixed(2));
          console.log(
            remainingamountdue,
            adddata.Amount,
            reduceremaingamount,
            "remaining amount"
          );
          let chargeCardAmount = 0;
          let swipeCardAmount = 0;
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Ending with")
            ) {
              chargeCardAmount =
                chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Swipe ending")
            ) {
              swipeCardAmount =
                swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
          let cardamount = Number(
            multipleCard.length == 0
              ? 0.0
              : multipleCard
                  .map((bill) => bill.Amount)
                  .reduce((acc, bill) =>
                    (Number(bill) + Number(acc)).toFixed(2)
                  )
          );
          cardamount =
            Number(cardamount) +
            Number(chargeCardAmount) +
            Number(swipeCardAmount);
          if (reduceremaingamount >= 0) {
            setremainingamountdue(reduceremaingamount);
            if (reduceremaingamount === 0) {
              console.log(cvvTextValue, cardamount, "cvv text length");
              if (cardamount > 0 && cvvTextValue.length >= 3) {
                console.log("accept payment button enabled when saving check1");
                setAcceptpaymentbuttonenable(false);
              } else if (cardamount > 0 && swipeFlag == false)
                setAcceptpaymentbuttonenable(true);
              else {
                console.log("accept payment button enabled when saving check1");
                setAcceptpaymentbuttonenable(false);
              }
            }
          } else {
            const addchange =
              parseFloat(change) +
              parseFloat(reduceremaingamount.toString()) * -1;
            setchange(addchange);
            setremainingamountdue(0);
            let chargeCardAmount = 0;
            let swipeCardAmount = 0;
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Ending with")
              ) {
                chargeCardAmount =
                  chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Swipe ending")
              ) {
                swipeCardAmount =
                  swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
            let cardamount = Number(
              multipleCard.length == 0
                ? 0.0
                : multipleCard
                    .map((bill) => bill.Amount)
                    .reduce((acc, bill) =>
                      (Number(bill) + Number(acc)).toFixed(2)
                    )
            );
            cardamount =
              Number(cardamount) +
              Number(chargeCardAmount) +
              Number(swipeCardAmount);
            if (
              cardamount > 0 &&
              cvvTextValue.length < 3 &&
              swipeFlag == false
            ) {
              console.log("accept payment button enabled when saving check1");
              setAcceptpaymentbuttonenable(true);
            } else {
              setAcceptpaymentbuttonenable(false);
            }
          }
        }
      }
    }
    if (checkmonvar === "2") {
      let flag = 0;
      if (adddata.checkno === "") {
        setchecknovalid(true);
        flag = 1;
      }

      if (adddata.Written_To === "") {
        flag = 1;
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        flag = 1;
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        flag = 1;
        setAmount(true);
      }
      if (
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        flag === 0
      ) {
        if (Number(adddata.Amount) > Number(remainingamountdue)) {
          setoverpayMoneyord(true);
        } else {
          setaddmoneyorder(false);
          setaddcheck(false);
          addrowarray.push(arr);
          setaddataanother(addrowarray);
          const reduceremaingamount =
            Number(Number(remainingamountdue).toFixed(2)) -
            Number(Number(adddata.Amount).toFixed(2));
          console.log(
            remainingamountdue,
            adddata.Amount,
            reduceremaingamount,
            "remaining amount"
          );
          if (reduceremaingamount >= 0) {
            setremainingamountdue(reduceremaingamount);

            if (reduceremaingamount === 0) {
              let chargeCardAmount = 0;
              let swipeCardAmount = 0;
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Ending with")
                ) {
                  chargeCardAmount =
                    chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Swipe ending")
                ) {
                  swipeCardAmount =
                    swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
              let cardamount = Number(
                multipleCard.length == 0
                  ? 0.0
                  : multipleCard
                      .map((bill) => bill.Amount)
                      .reduce((acc, bill) =>
                        (Number(bill) + Number(acc)).toFixed(2)
                      )
              );
              cardamount =
                Number(cardamount) +
                Number(chargeCardAmount) +
                Number(swipeCardAmount);
              if (
                cardamount > 0 &&
                cvvTextValue.length < 3 &&
                swipeFlag == false
              ) {
                console.log("accept payment button enabled when saving check1");
                setAcceptpaymentbuttonenable(true);
              } else {
                setAcceptpaymentbuttonenable(false);
              }
            }
          } else {
            const addchange = change + reduceremaingamount * -1;
            setchange(addchange);
            setremainingamountdue(0);
            let chargeCardAmount = 0;
            let swipeCardAmount = 0;
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Ending with")
              ) {
                chargeCardAmount =
                  chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].tendertype
                  .split(" - ")[0]
                  .includes("Card Swipe ending")
              ) {
                swipeCardAmount =
                  swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
              }
            }
            chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
            let cardamount = Number(
              multipleCard.length == 0
                ? 0.0
                : multipleCard
                    .map((bill) => bill.Amount)
                    .reduce((acc, bill) =>
                      (Number(bill) + Number(acc)).toFixed(2)
                    )
            );
            cardamount =
              Number(cardamount) +
              Number(chargeCardAmount) +
              Number(swipeCardAmount);
            if (
              cardamount > 0 &&
              cvvTextValue.length < 3 &&
              swipeFlag == false
            ) {
              console.log("accept payment button enabled when saving check1");
              setAcceptpaymentbuttonenable(true);
            } else {
              setAcceptpaymentbuttonenable(false);
            }
          }
        }
      }
    }
  };

  const useuspensecancelbtn = () => {
    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setusesuspense(false);
  };
  const addsuspensecancelbtn = () => {
    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setaddsuspense(false);
  };
  let addfilterzero: any[];
  const addSuspenseBtn = (e: any) => {
    debugger;

    console.log(totalsuspensemaster, "total suspense");
    console.log(mastersuspensearray, "addclick suspense");
    console.log(masteraddsuspensearray, "addclick suspense");
    console.log(masteraddsuspensearray, "addclick suspense");
    console.log(mastersuspensearray.length, "addclick suspense");

    // for (let i = 0; i < mastersuspensearray.length; i++) {
    //     if(mastersuspensearray[i].sustype=="type"){
    //       mastersuspensearray.splice(i, 1);
    //     }
    // }

    // for (let i = 0; i < masteraddsuspensearray.length; i++) {
    //   for (let i = 0; i < masteraddsuspensearray.length; i++) {

    //     if (
    //       masteraddsuspensearray[i].sustype == "type" ||
    //       masteraddsuspensearray[i].newsuspense == null
    //     ) {
    //       masteraddsuspensearray.splice(i, 1);
    //       break;
    //     }
    //   }
    // }

    const addfilterzero = masteraddsuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    const usefilterzero = masterusesuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });

    // let totalusefilter = totalsuspensemaster.filter(function (e) {
    //   return e.sustype == "Used";
    // });

    console.log(usefilterzero, addfilterzero, "add details");

    // settotalsuspensemaster([ ...addfilterzero,...totalusefilter])
    if (totalsuspensemaster)
      settotalsuspensemaster([...addfilterzero, ...usefilterzero]);

    // const addfilterzero = masteraddsuspensearray.filter(function (e) {
    //   return e.sustype != "type";
    // });

    // change

    // let totalusefilter = totalsuspensemaster.filter(function (e) {
    //   return e.sustype == "Used";
    // });

    console.log(usefilterzero, addfilterzero, "add details");

    // settotalsuspensemaster([ ...addfilterzero,...totalusefilter])

    // settotalsuspensemaster([...totalsuspensemaster,...addfilterzero])

    console.log(ReceiptCategoriesRequestParam, "old receipt");

    console.log(addfilterzero, "addition value");
    console.log(acceptPaymentParam, "accept params");

    for (let i = 0; i < addfilterzero.length; i++) {
      let index = paygridvalues.findIndex(
        (x) =>
          String(x.Agreementid) === String(addfilterzero[i].agreementnumber)
      );
      paygridvalues[index].suspenseAdd = Number(addfilterzero[i].newsuspense);
      // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(addfilterzero[i].newsuspense)
      let receiptObj = ReceiptCategoriesRequestParam.filter(
        (e) => e.agreementId == Number(addfilterzero[i].agreementnumber)
      );
      let receiptObjaccept = acceptPaymentParam.filter(
        (e) => e.agreementId == Number(addfilterzero[i].agreementnumber)
      );
      if (receiptObj[0] != undefined) {
        receiptObj[0].suspenseAdd = Number(addfilterzero[i].newsuspense);
      }
      if (
        String(addfilterzero[i].agreementnumber) ==
        String(receiptObjaccept[0].agreementId)
      ) {
        acceptPaymentParam[i].addTransferSusFlag = 1;
      }
    }

    // debugger;
    console.log(ReceiptCategoriesRequestParam, "new receipt");

    setReceiptCategory(ReceiptCategoriesRequestParam);
    console.log(ReceiptCategory, " receipt final set");

    // console.log(remAmtDue,"remaining amount after add")
    console.log(ReceiptCategory, "receipt catagory");
    console.log(remainingamountdue, "remaining amount after add");
    console.log(totalsuspensemaster, "total suspense ADDING");

    let totalsusamount: number;
    totalsusamount = 0;

    for (let i = 0; i < addfilterzero.length; i++) {
      totalsusamount = totalsusamount + Number(addfilterzero[i].newsuspense);
    }

    console.log(totalnewsuspenseamount);

    console.log(totalsusamount, "addition total");

    // tender suspense update

    // debugger
    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setaddsuspense(false);

    let tenderedAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      tenderedAmount = tenderedAmount + Number(tendertypeglobalarray[i].Amount);
    }
    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    debugger;
    const addSuspenseAmount = addfilterzero.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = usefilterzero.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (Cardrequire.PaymentOrgin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resAmount = Number(resAmount) - Number(carryRentAmount);
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    // settotaltax(Number(resTaxamount))

    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    multipleAdjust();
  };
  let usefilterzero: any[];

  const useSuspenseBtn = () => {
    debugger;
    console.log(totalsuspensemaster, "total suspense");
    console.log(mastersuspensearray, "addclick suspense");
    console.log(masteraddsuspensearray, "addclick suspense");
    console.log(masteraddsuspensearray, "addclick suspense");
    console.log(masterusesuspensearray, "addclick suspense");
    console.log(mastersuspensearray.length, "addclick suspense");

    // for (let i = 0; i < masterusesuspensearray.length; i++) {
    //   for (let i = 0; i < masterusesuspensearray.length; i++) {
    //     if (
    //       masterusesuspensearray[i].sustype == "type" ||
    //       masterusesuspensearray[i].newsuspense == null
    //     ) {
    //       masterusesuspensearray.splice(i, 1);
    //       break;
    //     }
    //   }
    // }

    console.log(totalsuspensemaster, "total suspense");
    console.log(mastersuspensearray, "addclick suspense");
    console.log(masteraddsuspensearray, "addclick suspense");
    console.log(masteraddsuspensearray, "addclick suspense");
    console.log(masterusesuspensearray, "addclick suspense");
    console.log(mastersuspensearray.length, "addclick suspense");

    const usefilterzero = masterusesuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    const addfilterzero = masteraddsuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });

    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setusesuspense(false);

    // const usefilterzero = masterusesuspensearray.filter(function (e) {
    //   return e.newsuspense != "0.00" || e.sustype != "type" || e.newsuspense != null;
    // });

    // let totaladdfilter = totalsuspensemaster.filter(function (e) {
    //   return e.sustype == "Add";
    // });

    console.log(
      usefilterzero,
      addfilterzero,
      mastereditsuspensearray,
      "submit details"
    );

    // console.log(mastereditsuspensearray)

    // setmastereditsuspensearray([...usefilterzero])
    // console.log(mastereditsuspensearray)

    // settotalsuspensemaster([...totaladdfilter, ...usefilterzero])
    settotalsuspensemaster([...addfilterzero, ...usefilterzero]);

    for (let i = 0; i < usefilterzero.length; i++) {
      let index = paygridvalues.findIndex(
        (x) =>
          String(x.Agreementid) === String(usefilterzero[i].agreementnumber)
      );
      paygridvalues[index].suspenseSub = Number(usefilterzero[i].newsuspense);
      // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
      console.log("for count");
      if (
        usefilterzero[i].agreementnumber ==
        ReceiptCategoriesRequestParam[i].agreementId
      ) {
        ReceiptCategoriesRequestParam[i].suspenseSub = Number(
          usefilterzero[i].newsuspense
        );
      }
    }

    console.log(ReceiptCategoriesRequestParam, "new receipt");
    console.log(acceptPaymentParam, "accept payment array receipt");

    setReceiptCategory(ReceiptCategoriesRequestParam);

    console.log(ReceiptCategory);
    // for (let i = 0; i < totalsuspensemaster.length; i++) {
    //   console.log("for count")
    //   if (totalsuspensemaster[i].agreementnumber == ReceiptCategoriesRequestParam[i].agreementId) {
    //     ReceiptCategoriesRequestParam[i].suspenseSub = usefilterzero[i].newsuspense
    //   }

    // }

    console.log(acceptPaymentParam, "accept payment param value");

    console.log(usefilterzero, "addition value");

    let totalsusamountuse: number;
    totalsusamountuse = 0;

    for (let i = 0; i < usefilterzero.length; i++) {
      totalsusamountuse =
        totalsusamountuse + Number(usefilterzero[i].newsuspense);
    }

    console.log(totalnewsuspenseamount);

    console.log(totalsusamountuse, "addition total");

    // tender suspense update

    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    debugger;
    const addSuspenseAmount = addfilterzero.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = usefilterzero.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (Cardrequire.PaymentOrgin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resAmount = Number(resAmount) - Number(carryRentAmount);
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    // settotaltax(Number(resTaxamount))

    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    multipleAdjust();
  };

  const transSuspenseBtn = () => {
    let returnSusAmount = 0;
    if (
      mastertranssuspensearray.length === 1 &&
      Number(mastertranssuspensearray[0].suspenseamount) > 0
    ) {
      setOpenTransferSuspense(true);
      settransuspense(false);
    } else {
      let insertArray: any = [];

      for (let i = 0; i < mastertranssuspensearray.length; i++) {
        let indexValue: any = paygridvalues.findIndex(
          (x) =>
            String(x.Agreementid) ===
            String(mastertranssuspensearray[i].agreementnumber)
        );
        if (
          mastertranssuspensearray[i].sustype == "TransferAdd" ||
          "TransferUsed"
        ) {
          if (
            Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense) >
            0
          ) {
            insertArray.push({
              sustype: "TransferAdd",
              agreementnumber: mastertranssuspensearray[i].agreementnumber,
              agreementid: mastertranssuspensearray[i].agreementid,
              schedure: mastertranssuspensearray[i].schedure,
              paymentamount: mastertranssuspensearray[i].paymentamount,
              epoamount: Number(mastertranssuspensearray[i].epoamount),
              suspenseamount: mastertranssuspensearray[i].suspenseamount,
              newsuspense:
                Number(mastertranssuspensearray[i].newsuspense) -
                Number(paygridvalues[indexValue].transferOldSuspense),
              agreementType: mastertranssuspensearray[i].agreementType,
            });
            // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
            // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
            paygridvalues[indexValue].SuspenseAmount = Number(
              mastertranssuspensearray[i].newsuspense
            );
          } else if (
            Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense) <
            0
          ) {
            insertArray.push({
              sustype: "TransferUsed",
              agreementnumber: mastertranssuspensearray[i].agreementnumber,
              agreementid: mastertranssuspensearray[i].agreementid,
              schedure: mastertranssuspensearray[i].schedure,
              paymentamount: mastertranssuspensearray[i].paymentamount,
              epoamount: Number(mastertranssuspensearray[i].epoamount),
              suspenseamount: mastertranssuspensearray[i].suspenseamount,
              newsuspense:
                Number(paygridvalues[indexValue].transferOldSuspense) -
                Number(mastertranssuspensearray[i].newsuspense),
              agreementType: mastertranssuspensearray[i].agreementType,
            });
            // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
            // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
            debugger;
            paygridvalues[indexValue].SuspenseAmount = Number(
              mastertranssuspensearray[i].newsuspense
            );
            const useFilterTransferBack = masterusesuspensearray.filter(
              function (e) {
                return (
                  e.sustype != "type" &&
                  Number(e.newsuspense) > 0 &&
                  String(e.agreementid) ==
                    String(mastertranssuspensearray[i].agreementid)
                );
              }
            );
            if (useFilterTransferBack?.length > 0) {
              if (
                Number(mastertranssuspensearray[i].newsuspense) <
                Number(useFilterTransferBack[0].newsuspense)
              ) {
                masterusesuspensearray = masterusesuspensearray.filter(
                  function (e) {
                    return (
                      String(e.agreementid) !=
                      String(useFilterTransferBack[0].agreementid)
                    );
                  }
                );
                for (let i = 0; i < useFilterTransferBack.length; i++) {
                  let index = paygridvalues.findIndex(
                    (x) =>
                      String(x.Agreementid) ===
                      String(useFilterTransferBack[0].agreementnumber)
                  );
                  paygridvalues[index].suspenseSub = 0;
                  // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
                  let receiptObj = ReceiptCategoriesRequestParam.filter(
                    (e) =>
                      String(e.agreementId) ==
                      String(useFilterTransferBack[0].agreementnumber)
                  );
                  if (
                    String(useFilterTransferBack[0].agreementnumber) ==
                    String(receiptObj[0].agreementId)
                  ) {
                    receiptObj[0].suspenseSub = 0;
                  }
                }
              }
            }
          }
        }
        if (paygridvalues[indexValue].isReturnAgrFlag == 1) {
          paygridvalues[indexValue].suspenseSub =
            paygridvalues[indexValue].SuspenseAmount;
          returnSusAmount = Number(paygridvalues[indexValue].SuspenseAmount);
        }
      }
      const usefilterzero = masterusesuspensearray.filter(function (e) {
        return e.sustype != "type" && Number(e.newsuspense) > 0;
      });
      const addfilterzero = masteraddsuspensearray.filter(function (e) {
        return e.sustype != "type" && Number(e.newsuspense) > 0;
      });
      setmastertranssuspensearray(insertArray);
      settotalsuspensemaster([
        ...usefilterzero,
        ...addfilterzero,
        ...insertArray,
      ]);
      settransuspense(false);
      setUsesusText(false);
      let addfilter = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add";
      });

      let usefilter = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used";
      });

      console.log(addfilter, usefilter);

      let resAmount = 0.0;
      //  let resTaxamount=0.00
      for (let i = 0; i < checkedAgr.length; i++) {
        resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
        // resTaxamount=Number(checkedAgr[i].Taxamount)
      }
      //SuspenseTender-1
      debugger;
      const addSuspenseAmount = addfilterzero.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = usefilterzero.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      usedSusTotalAmount = usedSusTotalAmount + returnSusAmount;

      let resTotalAmount = 0.0;
      if (Cardrequire.PaymentOrgin == "Pay By Phone") {
        resAmount = Number(resAmount) + Number(convAmtWTax);
        // resTaxamount=Number(resTaxamount)+Number(convtax)
      }
      resTotalAmount =
        totalduetendertype.length == 0
          ? 0.0
          : totalduetendertype
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      if (Number(resTotalAmount) > Number(resAmount)) {
        resetPayment(undefined);
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          )
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      } else {
        if (
          Number(resTotalAmount) >=
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          )
        ) {
          setchange(
            Number(resTotalAmount) -
              Number(
                (
                  Number(resAmount) -
                  Number(availableCreds) +
                  Number(addSusTotalAmount) -
                  Number(usedSusTotalAmount)
                ).toFixed(2)
              )
          );
          setremainingamountdue("0.00");
          setRemaniningAmtStatic("0.00");
          setAcceptpaymentbuttonenable(false);
        } else {
          setremainingamountdue(
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          setRemaniningAmtStatic(
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          setchange("0.00");
          setAcceptpaymentbuttonenable(true);
        }
      }
      multipleAdjust();
    }
  };

  const acceptSuspenseTransfer = () => {
    setOpenTransferSuspense(false);
    setNewCOATotal("0.00");
    let insertArray: any = [];

    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      let indexValue: any = paygridvalues.findIndex(
        (x) =>
          String(x.Agreementid) ===
          String(mastertranssuspensearray[i].agreementnumber)
      );
      if (
        mastertranssuspensearray[i].sustype == "TransferAdd" ||
        "TransferUsed"
      ) {
        if (
          Number(mastertranssuspensearray[i].newsuspense) -
            Number(paygridvalues[indexValue].transferOldSuspense) >
          0
        ) {
          insertArray.push({
            sustype: "TransferAdd",
            agreementnumber: mastertranssuspensearray[i].agreementnumber,
            agreementid: mastertranssuspensearray[i].agreementid,
            schedure: mastertranssuspensearray[i].schedure,
            paymentamount: mastertranssuspensearray[i].paymentamount,
            epoamount: Number(mastertranssuspensearray[i].epoamount),
            suspenseamount: mastertranssuspensearray[i].suspenseamount,
            newsuspense:
              Number(mastertranssuspensearray[i].newsuspense) -
              Number(paygridvalues[indexValue].transferOldSuspense),
            agreementType: mastertranssuspensearray[i].agreementType,
          });
          // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
          // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
          paygridvalues[indexValue].SuspenseAmount = Number(
            mastertranssuspensearray[i].newsuspense
          );
        } else if (
          Number(mastertranssuspensearray[i].newsuspense) -
            Number(paygridvalues[indexValue].transferOldSuspense) <
          0
        ) {
          insertArray.push({
            sustype: "TransferUsed",
            agreementnumber: mastertranssuspensearray[i].agreementnumber,
            agreementid: mastertranssuspensearray[i].agreementid,
            schedure: mastertranssuspensearray[i].schedure,
            paymentamount: mastertranssuspensearray[i].paymentamount,
            epoamount: Number(mastertranssuspensearray[i].epoamount),
            suspenseamount: mastertranssuspensearray[i].suspenseamount,
            newsuspense:
              Number(paygridvalues[indexValue].transferOldSuspense) -
              Number(mastertranssuspensearray[i].newsuspense),
            agreementType: mastertranssuspensearray[i].agreementType,
          });
          // let indexValue:any= checkedAgr.findIndex( x => String(x.Agreementid)  ===  String(mastertranssuspensearray[i].agreementnumber))
          // checkedAgr[indexValue].transferNewSuspense = Number(mastertranssuspensearray[i].newsuspense)
          paygridvalues[indexValue].SuspenseAmount = Number(
            mastertranssuspensearray[i].newsuspense
          );
          paygridvalues[indexValue].isCOATransfered = true;
          const useFilterTransferBack = masterusesuspensearray.filter(function (
            e
          ) {
            return (
              e.sustype != "type" &&
              Number(e.newsuspense) > 0 &&
              String(e.agreementid) ==
                String(mastertranssuspensearray[i].agreementid)
            );
          });
          if (useFilterTransferBack?.length > 0) {
            if (
              Number(mastertranssuspensearray[i].newsuspense) <
              Number(useFilterTransferBack[0].newsuspense)
            ) {
              masterusesuspensearray = masterusesuspensearray.filter(function (
                e
              ) {
                return (
                  String(e.agreementid) !=
                  String(useFilterTransferBack[0].agreementid)
                );
              });
              for (let i = 0; i < useFilterTransferBack.length; i++) {
                let index = paygridvalues.findIndex(
                  (x) =>
                    String(x.Agreementid) ===
                    String(useFilterTransferBack[0].agreementnumber)
                );
                paygridvalues[index].suspenseSub = 0;
                // paygridvalues[index].Amount=Number(paygridvalues[index].actualAmount)+Number(paygridvalues[index].suspenseAdd)-Number(usefilterzero[i].newsuspense)
                let receiptObj = ReceiptCategoriesRequestParam.filter(
                  (e) =>
                    e.agreementId ==
                    Number(useFilterTransferBack[0].agreementnumber)
                );
                if (
                  useFilterTransferBack[0].agreementnumber ==
                  receiptObj[0].agreementId
                ) {
                  receiptObj[0].suspenseSub = 0;
                }
              }
            }
          }
        }
      }
    }
    const usefilterzero = masterusesuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    const addfilterzero = masteraddsuspensearray.filter(function (e) {
      return e.sustype != "type" && Number(e.newsuspense) > 0;
    });
    setmastertranssuspensearray(insertArray);
    settotalsuspensemaster([
      ...usefilterzero,
      ...addfilterzero,
      ...insertArray,
    ]);
    settransuspense(false);
    setUsesusText(false);
    let addfilter = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add";
    });

    let usefilter = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used";
    });

    console.log(addfilter, usefilter);

    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    const addSuspenseAmount = addfilterzero.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = usefilterzero.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (Cardrequire.PaymentOrgin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }
    multipleAdjust();
  };

  const refundSuspenseBtn = () => {
    console.log(masterrefundsuspensearray);

    setrefundsuspense(false);
    setSecondLevelAuthenOn("Modify Customer Route");
  };

  /*This function is used to render the tender type based on the TenderTypeoptinchange*/
  const TenderTypeOptinchange = (e: any) => {
    const Selectedoption = e.target.id;
    let isCardClicked = true;
    if (Selectedoption === "Cash-tab" && paymentOrigin !== "Pay By Phone" && !enableCashPayment) {
      setcashselected(0);
      setcashtabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
    } else if (Selectedoption === "Card-tab") {
      setcashselected(1);
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
      setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
      isCardClicked = true;
    } else if (
      Selectedoption === "CheckMoney-Order-tab" &&
      paymentOrigin !== "Pay By Phone"
    ) {
      setcashselected(2);
      setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcardtabCss(`${navlinkClass.navLink} ${classes.p3}`);
      setcheckMoneyOrdertabCss(
        `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
      );
    }

    if (isCardClicked && multipleCard === undefined) {
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
    }
  };

  const addmoneyord = () => {
    setcheckmonvar("2");
    setadddata(temp);
    setaddmoneyorder(true);
    setbutton(false);
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);
  };
  const addcheckormoneyorder = () => {
    setcheckmonvar("1");
    setadddata(temp);
    setaddcheck(true);
    setbutton(false);
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);
  };
  useEffect(() => {
    if (!addmoneyorder && addcheck && ConrolUpdate === "0") {
      setadddata(temp);
    }
    if (!addcheck && addmoneyorder && ConrolUpdate === "0") {
      setadddata(temp);
    }
    LoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addcheck, addmoneyorder]);
  const LoadData = () => {
    BindGridcheck();
    let objTotalAmount = 0;
    if (adddataanother != undefined && adddataanother.length > 0) {
      objTotalAmount = adddataanother.reduce(
        (sumValue, a) => sumValue + parseFloat(a.Amount_rac),
        0
      );
    }

    let isCMOExists = false;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype === "Check & Money Order") {
        isCMOExists = true;
        tendertypeglobalarray[i].Amount = fixedChange(String(objTotalAmount));
        break;
      }
    }

    if (!isCMOExists && adddataanother.length > 0) {
      let tendertypedetailsch = {
        tendertype: "Check & Money Order",
        Amount: fixedChange(String(objTotalAmount)),
      };
      tendertypeglobalarray.push(tendertypedetailsch);
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        isCMOExists === true &&
        tendertypeglobalarray[i].tendertype === "Check & Money Order" &&
        tendertypeglobalarray[i].Amount === "0.00"
      ) {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }
    settotalduetendertype(tendertypeglobalarray);
    settotalamountcheck(objTotalAmount);
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    const cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    let objPayAmount =
      Number(RemaniningAmtStatic) -
      objTotalAmount -
      Number(addcash) -
      cardamount -
      Number(chargeCardAmount) -
      Number(swipeCardAmount);
    objPayAmount = Number(objPayAmount.toFixed(2));
    console.log("** LoadData **", objPayAmount, RemaniningAmtStatic);
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      console.log("entered into the for loop of loadother action");
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    if (objPayAmount <= 0 && isChecked == 1) {
      setchange(Math.abs(objPayAmount).toFixed(2));
      setremainingamountdue(0.0);
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }

      chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      let cardamount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      cardamount =
        Number(cardamount) + Number(chargeCardAmount) + Number(swipeCardAmount);
      if (cardamount > 0 && cvvTextValue.length < 3 && swipeFlag == false) {
        console.log("accept payment button enabled when saving check1");
        setAcceptpaymentbuttonenable(true);
      } else {
        setAcceptpaymentbuttonenable(false);
      }
    } else {
      setremainingamountdue(objPayAmount.toFixed(2));
      setchange(0.0);
      setAcceptpaymentbuttonenable(true);
    }

    FormAcceptPaymentData(0, 0);
  };

  const checkormoneyordercancel = () => {
    setConrolUpdate("0");
    setaddcheck(false);
    setaddmoneyorder(false);
    setcancelindication(1);
  };

  const AutoLoadAct_btn = async () => {
    let pagGridFilters = paygridvalues.filter(
      (x) => x.Agreementid == Number(agreementId)
    );
    console.log(
      "Agreement Type - initial payment",
      pagGridFilters[0].Agreementtype
    );
    let otherAct_Prop;
    let custCoverageresp: any;
    custCoverageresp = await CustomerCoverage(
      pagGridFilters[0].Agreementid,
      customerId,
      paygridvalues[selectedGrid].dueDateModified == true ||
        paygridvalues[selectedGrid].editSchedule != ""
        ? paygridvalues[selectedGrid].Nextduedate
        : ""
    );
    console.log("custCoverage", custCoverageresp);
    const resp: custCoverage = {
      ldwAmount:
        pagGridFilters[0].agrLdwAmount !== undefined &&
        pagGridFilters[0].agrLdwAmount > 0
          ? Math.trunc(
              Number(pagGridFilters[0].agrLdwAmount) +
                Number(pagGridFilters[0].ldwTaxAmount) * 100
            ) / 100
          : custCoverageresp.data.ldwInfo === undefined ||
            custCoverageresp.data.ldwInfo === null
          ? ""
          : custCoverageresp.data.ldwInfo.ldwAmountWithTax,
      ldwTax: Number(
        pagGridFilters[0].agrLdwAmount !== undefined &&
          pagGridFilters[0].agrLdwAmount > 0
          ? Math.trunc(Number(paygridvalues[selectedGrid].ldwTaxAmount) * 100) /
              100
          : custCoverageresp.data.ldwInfo === undefined ||
            custCoverageresp.data.ldwInfo === null
          ? 0
          : custCoverageresp.data.ldwInfo.ldwAmountWithTax -
            custCoverageresp.data.ldwInfo.ldwAmount
      ),
      policyAmount:
        custCoverageresp.data.policyInfo === undefined ||
        custCoverageresp.data.policyInfo === null
          ? ""
          : custCoverageresp.data.policyInfo.policyAmountWithTax,
      policyTax:
        custCoverageresp.data.policyInfo === undefined ||
        custCoverageresp.data.policyInfo === null
          ? 0
          : custCoverageresp.data.policyInfo.policyAmountWithTax -
            custCoverageresp.data.policyInfo.policyAmount,
      isLDW: custCoverageresp.data.storeCoverageStatus.isLDWAllowed,
      isPolicy: custCoverageresp.data.storeCoverageStatus.isPolicyAllowed,
    };

    setcustomerCoverageObject(resp);
    otherAct_Prop = resp;
    console.log("custCoverageObject", resp);
    if (
      custCoverageresp.status === 200 &&
      paygridvalues[0].Agreementtype !== "Club"
    ) {
      if (
        (otherAct_Prop.isPolicy === true || otherAct_Prop.isLDW == true) &&
        (custCoverageresp.data.ldwInfo !== null ||
          custCoverageresp.data.policyInfo !== null)
      ) {
        setOtherActionHidden(false);
        //setshowLDWPolicy(false);
      }
      if (custCoverageresp !== undefined) {
        if (actLDWArr.length == 0) {
          console.log(actLDWArr, "ldw length went into 0 condition");
          if (
            custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
            paygridvalues[selectedGrid].number_of_paymentsmade > 0
          ) {
            console.log("Deactive ldw btn should show");
            setActionState(2);
          } else {
            console.log("Active ldw btn should show");
            setActionState(1);
          }
        } else {
          console.log(actLDWArr, "ldw length went into 1 condition");
          let objLdwAction = actLDWArr.filter(
            (x) =>
              x.agreementId == Number(paygridvalues[selectedGrid].Agreementid)
          );
          if (objLdwAction[0] != undefined) {
            if (objLdwAction[0].action === 1) {
              console.log("Deactive ldw btn should show");
              setActionState(1);
            } else {
              console.log("Active ldw btn should show");
              setActionState(2);
            }
          } else {
            if (
              custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
              paygridvalues[selectedGrid].number_of_paymentsmade > 0
            ) {
              console.log("Deactive ldw btn should show");
              setActionState(2);
            } else {
              console.log("Active ldw btn should show");
              setActionState(1);
            }
          }
        }
        if (custCoverageresp.data.policyInfo.policyStatus === "A") {
          setpolicyService_state(2);
        } else {
          setpolicyService_state(1);
        }
      }
    } else if (paygridvalues[0].Nextduedate === "PIF Rent") {
      if (
        custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
        paygridvalues[0].number_of_paymentsmade > 0
      ) {
        LDW_apiCall(2);
      }
      if (custCoverageresp.data.policyInfo.policyStatus === "A") {
        Policy_apiCall(2);
      }
    }
  };

  const loadOtherActions = async (agreementIndex?: number) => {
    console.log("Agreement Type -", paygridvalues[0].Agreementtype);

    let count = 0;
    let otherAct_Prop;
    let custCoverageresp: any;
    let checkBoxArray = document.getElementsByName("grid");

    //let objAmt = 0;

    if (agreementIndex != undefined) {
      count = 1;
      selectedGrid = agreementIndex;
    } else {
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        console.log("entered into the for loop of loadother action");

        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          selectedGrid = i;
          count++;
          console.log("before the custcall");
        }
      }
    }
    console.log("selectedGrid --->", selectedGrid);
    console.log("count --->", count);

    if (
      count === 1 &&
      paygridvalues[selectedGrid].number_of_paymentsmade > 0 &&
      (paygridvalues[selectedGrid].Agreementtype != "PIF Rent" ||
        paygridvalues[selectedGrid].Agreementtype != "EPO Rent")
    ) {
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = true;
      });
      setotherActionSpinner(true);

      custCoverageresp = await CustomerCoverage(
        paygridvalues[selectedGrid].Agreementid,
        customerId,
        paygridvalues[selectedGrid].dueDateModified == true ||
          paygridvalues[selectedGrid].FreeTime != null ||
          paygridvalues[selectedGrid].editSchedule != ""
          ? paygridvalues[selectedGrid].Nextduedate
          : ""
      );
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = false;
      });
      setotherActionSpinner(false);
      console.log("custCoverage", custCoverageresp);
      setotherActState(custCoverageresp);
      if (custCoverageresp.status === 200) {
        console.log("showLDWPolicy true");
        const resp: custCoverage = {
          ldwAmount:
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
            (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
              custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
              ActionState === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].agrLdwAmount) +
                    Number(paygridvalues[selectedGrid].ldwTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? ""
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax,

          ldwTax: Number(
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
              (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
                custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
                ActionState === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].ldwTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? 0
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax -
                custCoverageresp.data.ldwInfo.ldwAmount
          ),
          policyAmount:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].policyAmount) +
                    Number(paygridvalues[selectedGrid].policyTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? ""
              : custCoverageresp.data.policyInfo.policyAmountWithTax,
          policyTax:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].policyTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? 0
              : custCoverageresp.data.policyInfo.policyAmountWithTax -
                custCoverageresp.data.policyInfo.policyAmount,
          isLDW: custCoverageresp.data.storeCoverageStatus.isLDWAllowed,
          isPolicy: custCoverageresp.data.storeCoverageStatus.isPolicyAllowed,
          ldwInfo: custCoverageresp.data.ldwInfo,
        };
        setshowLDWPolicy(true);
        setcustomerCoverageObject(resp);
        let adjustedTerminateLdwAmount = 0;
        let adjustedTerminateLdwTaxAmount = 0;
        let totalAdjustedLdwAmount = "0";
        // if(paygridvalues[selectedGrid].dueDateModified==true||paygridvalues[selectedGrid].editSchedule!=""){
        //   adjustedTerminateLdwAmount=Number(paygridvalues[selectedGrid].agrLdwAmount)
        //   adjustedTerminateLdwTaxAmount=Number(paygridvalues[selectedGrid].ldwTaxAmount)
        //   totalAdjustedLdwAmount=(adjustedTerminateLdwAmount+adjustedTerminateLdwTaxAmount).toFixed(2)
        // }
        setadjustTerminateLdwAmount(totalAdjustedLdwAmount);
        otherAct_Prop = resp;
        console.log("custCoverageObject", resp);

        console.log(
          "nuber of payment made -->",
          paygridvalues[selectedGrid].number_of_paymentsmade
        );
        if (
          paygridvalues[selectedGrid].Agreementtype !== "Club" &&
          paygridvalues[selectedGrid].Nextduedate !== "PIF Rent"
        ) {
          if (custCoverageresp !== undefined) {
            if (custCoverageresp.data.ldwInfo !== null) {
              if (actLDWArr.length == 0) {
                console.log(actLDWArr, "ldw length went into 0 condition");
                if (
                  custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                  paygridvalues[selectedGrid].number_of_paymentsmade > 0
                ) {
                  console.log("Deactive ldw btn should show");
                  setActionState(2);
                } else {
                  console.log("Active ldw btn should show");
                  setActionState(1);
                }
              } else {
                console.log(actLDWArr, "ldw length went into 1 condition");
                let objLdwAction = actLDWArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objLdwAction[0] != undefined) {
                  if (objLdwAction[0].action === 1) {
                    console.log("Deactive ldw btn should show");
                    setActionState(2);
                  } else {
                    console.log("Active ldw btn should show");
                    setActionState(1);
                  }
                } else {
                  console.log(actLDWArr, "ldw length went into 0 condition");
                  if (
                    custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                    paygridvalues[selectedGrid].number_of_paymentsmade > 0
                  ) {
                    console.log("Deactive ldw btn should show");
                    setActionState(2);
                  } else {
                    console.log("Active ldw btn should show");
                    setActionState(1);
                  }
                }
              }
            }
            if (custCoverageresp.data.policyInfo !== null) {
              if (actPolicyArr.length == 0) {
                console.log(
                  actPolicyArr,
                  "policy length went into 0 condition"
                );
                if (custCoverageresp.data.policyInfo.policyStatus === "A") {
                  console.log("Deactive policy btn should show");
                  setpolicyService_state(2);
                } else {
                  console.log("Active policy btn should show");
                  setpolicyService_state(1);
                }
              } else {
                console.log(
                  actPolicyArr,
                  "policy length went into 1 condition"
                );
                let objPolicyAction = actPolicyArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objPolicyAction[0] != undefined) {
                  if (objPolicyAction[0].action === 1) {
                    console.log("Deactive policy btn should show");
                    setpolicyService_state(2);
                  } else {
                    console.log("Active policy btn should show");
                    setpolicyService_state(1);
                  }
                } else {
                  console.log(
                    actPolicyArr,
                    "policy length went into 0 condition"
                  );
                  if (custCoverageresp.data.policyInfo.policyStatus === "A") {
                    setpolicyService_state(2);
                  } else {
                    setpolicyService_state(1);
                  }
                }
              }
            }
          }
        } else if (paygridvalues[selectedGrid].Nextduedate === "PIF Rent") {
          if (
            custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
            paygridvalues[selectedGrid].number_of_paymentsmade > 0
          ) {
            LDW_apiCall(2);
          }
          if (custCoverageresp.data.policyInfo.policyStatus === "A") {
            Policy_apiCall(2);
          }
        }
      } else {
        console.log("showLDWPolicy false 1");
        setshowLDWPolicy(false);
      }
    } else {
      console.log("showLDWPolicy false 2");
      setOtherActionHidden(true);
      setshowLDWPolicy(false);
    }
  };
  const commitmentLoadAction = async (agreementIndex?: number) => {
    console.log("agreementIndex cehks csudet", agreementIndex);

    console.log("Agreement Type -", paygridvalues[0].Agreementtype);
    // debugger;
    // alert("Hey I am Called");
    let count = 0;
    let otherAct_Prop;
    let custCoverageresp: any;
    let checkBoxArray = document.getElementsByName("grid");

    //let objAmt = 0;

    if (agreementIndex != undefined) {
      count = 1;
      selectedGrid = agreementIndex;
    } else {
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        console.log("entered into the for loop of loadother action");

        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          selectedGrid = i;
          count++;
          console.log("before the custcall");
        }
      }
    }
    console.log("selectedGrid --->", selectedGrid);
    console.log("count --->", count);

    if (
      count === 1 &&
      paygridvalues[selectedGrid].number_of_paymentsmade > 0 &&
      (paygridvalues[selectedGrid].Agreementtype != "PIF Rent" ||
        paygridvalues[selectedGrid].Agreementtype != "EPO Rent")
    ) {
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = true;
      });
      setotherActionSpinner(true);
      console.log("check sdfe7 valuef", paygridvalues[selectedGrid]);

      custCoverageresp = await CustomerCoverage(
        paygridvalues[selectedGrid].Agreementid,
        customerId,
        paygridvalues[selectedGrid].dueDateModified == true ||
          paygridvalues[selectedGrid].FreeTime != null ||
          paygridvalues[selectedGrid].editSchedule != ""
          ? paygridvalues[selectedGrid].Nextduedate
          : ""
      );
      // alert('got customer coverage data');
      setotherActState(custCoverageresp);
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        obj.disabled = false;
      });
      setotherActionSpinner(false);
      console.log("custCoverage", custCoverageresp);
      if (custCoverageresp.status === 200) {
        console.log("showLDWPolicy true");
        console.log("check the val one", custCoverageresp.data);
        console.log("check the val two", paygridvalues[selectedGrid]);

        const resp: custCoverage = {
          ldwAmount:
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
            (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
              custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
              ActionState === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].agrLdwAmount) +
                    Number(paygridvalues[selectedGrid].ldwTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? ""
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax,

          ldwTax: Number(
            (paygridvalues[selectedGrid].agrLdwAmount !== undefined &&
              paygridvalues[selectedGrid].agrLdwAmount > 0) ||
              (paygridvalues[selectedGrid].agrLdwAmount == 0 &&
                custCoverageresp.data.ldwInfo?.ldwStatus === "A" &&
                ActionState === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].ldwTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.ldwInfo === undefined ||
                custCoverageresp.data.ldwInfo === null
              ? 0
              : custCoverageresp.data.ldwInfo.ldwAmountWithTax -
                custCoverageresp.data.ldwInfo.ldwAmount
          ),
          policyAmount:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  (Number(paygridvalues[selectedGrid].policyAmount) +
                    Number(paygridvalues[selectedGrid].policyTaxAmount)) *
                    100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? ""
              : custCoverageresp.data.policyInfo.policyAmountWithTax,
          policyTax:
            (paygridvalues[selectedGrid].policyAmount !== undefined &&
              paygridvalues[selectedGrid].policyAmount > 0) ||
            (paygridvalues[selectedGrid].policyAmount == 0 &&
              custCoverageresp.data.policyInfo?.policyStatus === "A" &&
              policyService_state === 2)
              ? Math.trunc(
                  Number(paygridvalues[selectedGrid].policyTaxAmount) * 100
                ) / 100
              : custCoverageresp.data.policyInfo === undefined ||
                custCoverageresp.data.policyInfo === null
              ? 0
              : custCoverageresp.data.policyInfo.policyAmountWithTax -
                custCoverageresp.data.policyInfo.policyAmount,
          isLDW: custCoverageresp.data.storeCoverageStatus.isLDWAllowed,
          isPolicy: custCoverageresp.data.storeCoverageStatus.isPolicyAllowed,
          ldwInfo: custCoverageresp.data.ldwInfo,
        };
        setshowLDWPolicy(true);
        setcustomerCoverageObject(resp);
        let adjustedTerminateLdwAmount = 0;
        let adjustedTerminateLdwTaxAmount = 0;
        let totalAdjustedLdwAmount = "0";
        // alert("Reached totalAdjustedLdwAmount");
        // if(paygridvalues[selectedGrid].dueDateModified==true||paygridvalues[selectedGrid].editSchedule!=""){
        //   adjustedTerminateLdwAmount=Number(paygridvalues[selectedGrid].agrLdwAmount)
        //   adjustedTerminateLdwTaxAmount=Number(paygridvalues[selectedGrid].ldwTaxAmount)
        //   totalAdjustedLdwAmount=(adjustedTerminateLdwAmount+adjustedTerminateLdwTaxAmount).toFixed(2)
        // }
        setadjustTerminateLdwAmount(totalAdjustedLdwAmount);
        otherAct_Prop = resp;
        console.log("custCoverageObject", resp);

        console.log(
          "nuber of payment made -->",
          paygridvalues[selectedGrid].number_of_paymentsmade
        );
        if (
          paygridvalues[selectedGrid].Agreementtype !== "Club" &&
          paygridvalues[selectedGrid].Nextduedate !== "PIF Rent"
        ) {
          if (custCoverageresp !== undefined) {
            if (custCoverageresp.data.ldwInfo !== null) {
              if (actLDWArr.length == 0) {
                console.log(actLDWArr, "ldw length went into 0 condition");
                if (
                  custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                  paygridvalues[selectedGrid].number_of_paymentsmade > 0
                ) {
                  console.log("Deactive ldw btn should show");
                  setActionState(2);
                } else {
                  console.log("Active ldw btn should show");
                  setActionState(1);
                }
              } else {
                console.log(actLDWArr, "ldw length went into 1 condition");
                let objLdwAction = actLDWArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objLdwAction[0] != undefined) {
                  if (objLdwAction[0].action === 1) {
                    console.log("Deactive ldw btn should show");
                    setActionState(2);
                  } else {
                    console.log("Active ldw btn should show");
                    setActionState(1);
                  }
                } else {
                  console.log(actLDWArr, "ldw length went into 0 condition");
                  if (
                    custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
                    paygridvalues[selectedGrid].number_of_paymentsmade > 0
                  ) {
                    console.log("Deactive ldw btn should show");
                    setActionState(2);
                  } else {
                    console.log("Active ldw btn should show");
                    setActionState(1);
                  }
                }
              }
            }
            if (custCoverageresp.data.policyInfo !== null) {
              if (actPolicyArr.length == 0) {
                console.log(
                  actPolicyArr,
                  "policy length went into 0 condition"
                );
                if (custCoverageresp.data.policyInfo.policyStatus === "A") {
                  console.log("Deactive policy btn should show");
                  setpolicyService_state(2);
                } else {
                  console.log("Active policy btn should show");
                  setpolicyService_state(1);
                }
              } else {
                console.log(
                  actPolicyArr,
                  "policy length went into 1 condition"
                );
                let objPolicyAction = actPolicyArr.filter(
                  (x) =>
                    x.agreementId ==
                    Number(paygridvalues[selectedGrid].Agreementid)
                );
                if (objPolicyAction[0] != undefined) {
                  if (objPolicyAction[0].action === 1) {
                    console.log("Deactive policy btn should show");
                    setpolicyService_state(2);
                  } else {
                    console.log("Active policy btn should show");
                    setpolicyService_state(1);
                  }
                } else {
                  console.log(
                    actPolicyArr,
                    "policy length went into 0 condition"
                  );
                  if (custCoverageresp.data.policyInfo.policyStatus === "A") {
                    setpolicyService_state(2);
                  } else {
                    setpolicyService_state(1);
                  }
                }
              }
            }
          }
        } else if (paygridvalues[selectedGrid].Nextduedate === "PIF Rent") {
          if (
            custCoverageresp.data.ldwInfo.ldwStatus === "A" &&
            paygridvalues[selectedGrid].number_of_paymentsmade > 0
          ) {
            LDW_apiCall(2);
          }
          if (custCoverageresp.data.policyInfo.policyStatus === "A") {
            Policy_apiCall(2);
          }
        }
        // return custCoverageresp;
        const returnData = {
          custCoverageresp: custCoverageresp,
          resp: resp,
        };
        return returnData;
      } else {
        console.log("showLDWPolicy false 1");
        setshowLDWPolicy(false);
      }
    } else {
      console.log("showLDWPolicy false 2");
      setOtherActionHidden(true);
      setshowLDWPolicy(false);
    }
  };
  //code added by the Mano Ranjith for calclating the late fee
  const Addtotallatefee = () => {
    let addedlatevalue = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      addedlatevalue = Number(paygridvalues[i].lateFee) + addedlatevalue;
    }
    return addedlatevalue;
  };
  const Subtracttotallatefee = () => {
    let subractedlatevalue = Latelast10value;
    for (let i = 0; i < paygridvalues.length; i++) {
      subractedlatevalue =
        subractedlatevalue - Number(paygridvalues[i].lateFee);
    }
    return subractedlatevalue;
  };
  const selectallcheckbox = (check?: boolean, e?) => {
    //let isChecked = e.target.checked;
    /**
     * Changes:3 Payments Verification
     * Developer:Muzzammil Shaik
     * Desc:Multiple check handling
     */
    /**
     * changes : Adjust Due Date
     * Developer : Muzzammil Shaik
     * Desc : Select all check box need to call multiple adjust due date with
     *        all the agreements
     */

    //  let objCheAdjust = document.getElementById("maincheckbox") as HTMLInputElement;
    //  let checked;
    //  if (objCheAdjust.checked === true) {
    //    checked=true
    //  } else {
    //    checked=false
    //  }
    //  if(checked==true){
    // for(let i =0; i < paygridvalues.length; i++){
    //  adjustDueDateSelectedAgre.push(paygridvalues[i].Agreementid)
    //   adjustDueDateSelectedAgre = Array.from(new Set(adjustDueDateSelectedAgre))
    //   setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre)
    // }
    // console.log(adjustDueDateSelectedAgre,"adjustDueDateSelectedAgre")
    // multipleAdjust()
    //End for bothe Adjust due date and edit schedule.
    // }else{setadjustDueDateSelectedAgre([])
    //   setdeliveryUnconfiremd(false)
    // }
    //let isChecked = e.target.checked;
    let isChecked;
    if (e != undefined) {
      isChecked = e.target.checked;
    } else {
      isChecked = check;
    }
    if (isChecked == true) {
      for (let i = 0; i < paygridvalues.length; i++) {
        adjustDueDateSelectedAgre.push(paygridvalues[i].Agreementid);
        adjustDueDateSelectedAgre = Array.from(
          new Set(adjustDueDateSelectedAgre)
        );
        setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      }
      console.log(adjustDueDateSelectedAgre, "adjustDueDateSelectedAgre");
      multipleAdjust();
    } else {
      setadjustDueDateSelectedAgre([]), setdeliveryUnconfiremd(false);
    }
    totalduereceiptidsupport = [];
    let noOfZeroPaidAgreement = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      if (paygridvalues[i].number_of_paymentsmade >= 3) {
        if (paygridvalues[i].sign_status === "") {
          settooltipdisplay(
            paygridvalues[i].Agreementid + "Please sign the agreement"
          );
        }
        if (paygridvalues[i].confirmeddeliversystatus === false) {
          settooltipdisplay(
            paygridvalues[i].Agreementid +
              "Please update the confirmed delivery status"
          );
        }
        if (
          paygridvalues[i].sign_status === "" &&
          paygridvalues[i].confirmeddeliversystatus === false
        ) {
          settooltipdisplay(
            paygridvalues[i].Agreementid +
              "Please sign the agreement and update the confirmed delivery status"
          );
        }
      }
      if (paygridvalues[i].number_of_paymentsmade === 0) {
        noOfZeroPaidAgreement = noOfZeroPaidAgreement + 1;
      }
    }
    if (isChecked === true) {
      /**
       * syed azim
       * suspense select all change
       */

      setaddsuslist(false);
      settransfersuslist(false);

      /*
          If the data does not have value against any of entollautopaydate then "Enroll AutoPay"
          button should be displayed when "Select All" check box is clicked

          1. First get the total records count
          2. Get the count of records that has auto pay enrollment date
          3. Get the distinct schedules from the array

          If the total records matches the total auto pay enrollment date records and if the
          distinct schedule length is 1 then show the respective enrollment button.
        */
      setOverAll_Chk(true);
      setSelectedCheckBoxCount(paygridvalues.length);
      let checkedCount = 0;
      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      for (let i = 0; i < paygridvalues.length; i++) {
        adjustDueDateSelectedAgre.push(paygridvalues[i].Agreementid);
        adjustDueDateSelectedAgre = Array.from(
          new Set(adjustDueDateSelectedAgre)
        );
        checkedCount = i;
        const agreementDetails = {
          agreement_id:
            paygridvalues[i].AgreementNumber == "Adjust"
              ? "Miscellaneous Item " + paygridvalues[i].miscellaneousItemsNum
              : paygridvalues[i].AgreementNumber,
          agreement_totalamount: fixedChange(String(paygridvalues[i].Amount)),
        };
        totalduereceiptidsupport.push(agreementDetails);
        settotalduereceiptid(totalduereceiptidsupport);
        chkboxArr.push(agreementDetails);
        let pifAmt = 0;
        let pifTax = 0;
        let sacTax = 0;
        let epoAmt = 0;
        let epoTax = 0;
        let pifFlag = 0;
        let sacAmt = 0;
        let PaygridObj = paygridvalues[i];
        let agreementAmount = Number(
          (
            PaygridObj.Amount -
            Number(PaygridObj.lateFee) +
            Number(PaygridObj.suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number(PaygridObj.EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date(PaygridObj.SAC_Date);
        if (PaygridObj.Agreementtype == "EPO Rent") {
          console.log("Inside If agrAmount----->", agreementAmount);
          if (PaygridObj.Nextduedate == "EPO") {
            epoAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            epoTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount) -
                  Number(PaygridObj.lFeeTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          } else {
            sacAmt =
              PaygridObj.acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
                : Number(PaygridObj.EPOAmount) +
                  Number(PaygridObj.suspenseSub) -
                  Number(PaygridObj.EPOTax) -
                  Number(PaygridObj.lateFee) -
                  Number(PaygridObj.EPOTax);
            sacTax =
              PaygridObj.acceptEpoFlag == 0
                ? Number(PaygridObj.rpayTaxAmount) -
                  Number(PaygridObj.lFeeTaxAmount)
                : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          }
          setfinalagr(Number(PaygridObj.Agreementid));
          pifFlag = 1;
        } else if (PaygridObj.Agreementtype == "PIF Rent") {
          pifAmt =
            PaygridObj.acceptEpoFlag == 0
              ? Number((agreementAmount - PaygridObj.Taxamount).toFixed(2))
              : Number(PaygridObj.EPOAmount) +
                Number(PaygridObj.suspenseSub) -
                Number(PaygridObj.EPOTax) -
                Number(PaygridObj.lateFee) -
                Number(PaygridObj.EPOTax);
          pifTax =
            PaygridObj.acceptEpoFlag == 0
              ? Number(PaygridObj.rpayTaxAmount) -
                Number(PaygridObj.lFeeTaxAmount)
              : Number(PaygridObj.EPOTax) - Number(PaygridObj.lFeeTaxAmount);
          setfinalagr(Number(PaygridObj.Agreementid));
          pifFlag = 1;
        }
        if (
          ((pifFlag == 1 && Number(PaygridObj.SuspenseAmount) > 0) ||
            (Number(PaygridObj.SuspenseAmount >= PaygridObj.EPOAmount) &&
              Number(PaygridObj.Agreementtype !== "Club"))) &&
          PaygridObj.miscellaneousItemsNum == 0 &&
          Number(PaygridObj.suspenseSub) == 0
        ) {
          setsuspErrLbl(true);
          suspenseError.push(PaygridObj.Agreementid);
        }
        // if(PaygridObj.store_number != window.sessionStorage.getItem("storeNumber") && PaygridObj.Agreementtype !== "Club"){
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(PaygridObj.Agreementid);
        // }

        if (
          Number(PaygridObj.EPOAmount < PaygridObj.Amount) &&
          pifFlag != 1 &&
          PaygridObj.Agreementtype !== "Club" &&
          PaygridObj.miscellaneousItemsNum == 0
        ) {
          setepoErrLbl(true);
          epoError.push(PaygridObj.Agreementid);
        }
        // if (
        //   PaygridObj.store_number !=
        //   window.sessionStorage.getItem("storeNumber")
        // ) {
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(PaygridObj.Agreementid);
        // }
        if (PaygridObj.miscellaneousItemsNum == 0) {
          let ReceiptArray = {
            customerId: Number(customerId),
            schedule: PaygridObj.Schedule + "|" + PaygridObj.AgreementNumber,
            amountDue: Number(
              PaygridObj.Agreementtype !== "Club" && pifFlag == 0
                ? (PaygridObj.Amount - Number(PaygridObj.lateFee)).toFixed(2)
                : 0
            ),
            ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
            // Number(PaygridObj.PromoFreeDaysApplied) == 0
            //   ? PaygridObj.agrLdwAmount
            //   : 0.0,
            clubPayment: Number(
              PaygridObj.Agreementtype === "Club"
                ? Number(PaygridObj.Amount) - Number(PaygridObj.Taxamount)
                : 0
            ),
            clubPaymentTax: Number(
              PaygridObj.Agreementtype === "Club" ? PaygridObj.Taxamount : 0
            ),
            creditOnAccountAdd: 0,
            creditOnAccountSub: -1 * Number(PaygridObj.COA),
            integratedPaymentSuspense: -1 * Number(PaygridObj.ipSuspense),
            initialPayment: Number(
              PaygridObj.Agreementtype !== "Club"
                ? PaygridObj.number_of_paymentsmade === 0
                  ? 1
                  : 0
                : 0
            ),
            netLateFee: 0,
            carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
            wlDeposit: -1 * Number(PaygridObj.wlDeposit),
            htSuspense: 0, //Number(PaygridObj[0].htSuspense),
            suspenseAdd: Number(PaygridObj.suspenseAdd), //Number(PaygridObj[0].suspense),
            suspenseSub: Number(PaygridObj.suspenseSub), //Number(PaygridObj[0].suspense),
            racTirePay: Number(PaygridObj.policyAmount), //Number(PaygridObj[0].racTirePay),
            agreementId:
              PaygridObj.Agreementtype !== "Club"
                ? Number(PaygridObj.Agreementid)
                : undefined,
            change: 0,
            totalTax: PaygridObj.Taxamount,
            sac: Number(sacAmt),
            sacTax: Number(sacTax),
            epo: Number(epoAmt),
            epoTax: Number(epoTax),
            epoDiscount:
              PaygridObj.acceptEpoFlag == 0
                ? 0.0
                : Number(PaygridObj.actualEpoAmount) -
                  Number(PaygridObj.editedEPOAmount),
            pastDueDiscount:
              PaygridObj.acceptEpoFlag == 0
                ? 0.0
                : Number(PaygridObj.actualPastDueRent) -
                  Number(PaygridObj.pastDueRent),
            pastDueAmount:
              PaygridObj.acceptEpoFlag == 0
                ? undefined
                : Number(PaygridObj.actualPastDueRent),
            nextDueDate:
              PaygridObj.acceptEpoFlag == 0
                ? undefined
                : PaygridObj.Nextduedate,
            ldwFeeAmountTax: Number(PaygridObj.ldwTaxAmount),
            rentalPaymentTax: Number(
              pifFlag == 0 && PaygridObj.Agreementtype !== "Club"
                ? PaygridObj.rpayTaxAmount
                : 0
            ),
            lateRentalPaymentfeeTax: Number(PaygridObj.lFeeTaxAmount),
            lateRentalPaymentfee: Number(PaygridObj.lateFee),
            racTirePayTax: Number(PaygridObj.policyTaxAmount),
            deliveryFee: Number(PaygridObj.deliveryAmount),
            deliveryFeeTax: Number(PaygridObj.deliveryAmountTax),
            processingFee: Number(PaygridObj.processingFee),
            processingFeeTax: Number(PaygridObj.processingFeeTax),
            carryRentAmountUsed:
              PaygridObj.FreeTime == null
                ? Number(PaygridObj.carryRentAmountUsed)
                : 0,
            carryRentTaxUsed:
              PaygridObj.FreeTime == null
                ? Number(PaygridObj.carryRentTaxUsed)
                : 0,
            //otherFee: Number(PaygridObj.mattressFee),
            //otherFeeTax: Number(PaygridObj.mattressFeeTax),
            pif: Number(pifAmt),
            pifTax: Number(pifTax),
            extensionAmount: Number(PaygridObj.extensionAmount),
            convenienceFee: 0,
            convenienceFeeTax: 0,
            mattressFee: Number(PaygridObj.mattressFee),
          };
          ReceiptCategoriesRequestParam.push(ReceiptArray);
        }

        if (
          PaygridObj.Agreementtype !== "Club" &&
          PaygridObj.miscellaneousItemsNum == 0
        ) {
          let acceptPaymentArray = {
            extensionAmount: Number(PaygridObj.extensionAmount),
            noOfDaysFree: Number(PaygridObj.noOfDaysFree),
            // initialPaymentFlag: PaygridObj.number_of_paymentsmade == 0 ? 1 : 0,
            initialPaymentFlag: 1,
            addTransferSusFlag: 0,
            schedule: PaygridObj.Schedule,
            agreementId: Number(PaygridObj.Agreementid),
            ldwFeeAmount: Number(PaygridObj.agrLdwAmount), // Added By Me
            // Number(PaygridObj.PromoFreeDaysApplied) == 0
            //   ? PaygridObj.agrLdwAmount
            //   : 0.0,
            agreementAmountDue: Number(
              fixedChange(
                String(
                  Number(PaygridObj.Amount) +
                    (Number(PaygridObj.acceptEpoFlag) == 1
                      ? Number(PaygridObj.suspenseSub)
                      : 0)
                )
              )
            ),
            remainingEpoAmount: Number(
              PaygridObj.EPOAmount == null
                ? 0.0
                : (PaygridObj.EPOAmount - PaygridObj.EPOTax).toFixed(2)
            ),
            promoFreeDays: Number(PaygridObj.PromoFreeDaysApplied),
            netLateFee: Number(PaygridObj.lateFee),
            carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
            numberOfDaysLate: PaygridObj.CurrentDaysLate,
            originalDueDate: PaygridObj.CurrentDueDate,
            nextDueDate: PaygridObj.Nextduedate,
            totalTax: Number(PaygridObj.Taxamount.toFixed(2)),
            policyAmount: Number(Number(PaygridObj.policyAmount)),
            deliveryFeeAmount: Number(PaygridObj.deliveryAmount),
            processingFeeAmount: Number(PaygridObj.processingFee),
            otherFeeAmount: Number(PaygridObj.mattressFee),
            editSchedule: PaygridObj.editSchedule,
            freeTime: PaygridObj.FreeTime, // Added By Me
            ldwActivate: undefined,
          };
          acceptPaymentParam.push(acceptPaymentArray);
        }

        if (
          PaygridObj.Agreementtype === "Club" &&
          PaygridObj.miscellaneousItemsNum == 0
        ) {
          AcceptclubPaymentParam = {
            paymentSchedule: PaygridObj.Schedule,
            originalDueDate: PaygridObj.CurrentDueDate,
            nextDueDate: PaygridObj.Nextduedate,
            numberOfDaysLate: PaygridObj.CurrentDaysLate,
            clubTax: String(PaygridObj.Taxamount.toFixed(2)),
            clubAmountDue: Number(Number(PaygridObj.Amount).toFixed(2)),
            editSchedule: PaygridObj.editSchedule,
          };
        }
        let objAvailCreditsAmt =
          Number(PaygridObj.COA) +
          Number(PaygridObj.wlDeposit) +
          Number(PaygridObj.ipSuspense);
        FormAcceptPaymentData(0, objAvailCreditsAmt);

        let auPay: AutoPayType = {
          schedule: PaygridObj.Schedule.toString(),
          autoPay:
            PaygridObj.Autopay_enrollmentdate !== "" &&
            PaygridObj.Autopay_enrollmentdate !== undefined &&
            PaygridObj.Autopay_enrollmentdate !== null,
          itemIndex: i,
          agreementID: PaygridObj.Agreementid,
        };
        autoPayEnrollArray.push(auPay);
      }
      setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
      console.log(adjustDueDateSelectedAgre, "adjustDueDateSelectedAgre");
      multipleAdjust();
      const resultSelecteAgres = paygridvalues.filter(function (e) {
        return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
      });
      let noOfPaymentsAllowedToggle = 0;
      for (let i = 0; i < resultSelecteAgres.length; i++) {
        if (
          resultSelecteAgres[i].number_of_paymentsmade == 0 ||
          String(resultSelecteAgres[i].store_number) !=
            String(window.sessionStorage.getItem("storeNumber"))
        ) {
          noOfPaymentsAllowedToggle = 1;
        }
      }
      if (noOfPaymentsAllowedToggle == 0) {
        /* Autopay enrollment logic starts here */
        const distintSchedule = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.schedule))
        );
        const apEnroll = Array.from(
          new Set(autoPayEnrollArray.map((item) => item.autoPay))
        );

        if (distintSchedule.length === 1 && apEnroll.length === 1) {
          if (apEnroll[0]) {
            setAPUPStates({ ...APUPStates, IsAP: false, IsUP: true });
          } else {
            setAPUPStates({ ...APUPStates, IsAP: true, IsUP: false });
          }
        } else {
          setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
        }
        /* Autopay enrollment logic ends here */
      } else {
        setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      }
      /* Autopay enrollment logic ends here */
      calculatetotalamount();
      //code for adding the late fee added by Mano Ranjith
      let lateamount = Addtotallatefee();
      setLatelast10value(lateamount);
      //code for carry rent
      setselectallChecked(true);
      setcarryRentCheck(false);
      if (carryRentWidget) {
        carryOnchange();
      }

      //Code for other options
      if (checkedCount == 0) loadOtherActions();
      else {
        setOtherActionHidden(true);
        setshowLDWPolicy(false);
      }
      // multipleAdjust()
    } else {
      // setmastersuspensearray([]);
      // setmasteraddsuspensearray([]);
      // setmasterusesuspensearray([]);
      // setmastertranssuspensearray([]);
      // setmasterrefundsuspensearray([]);

      setaddsuslist(true);
      setrefundsuslist(true);
      settransfersuslist(true);

      setadjustDueDateSelectedAgre([]);
      setdeliveryUnconfiremd(false);
      setAPUPStates({ ...APUPStates, IsAP: false, IsUP: false });
      totalduereceiptidsupport = [];
      settotalduereceiptid([]);
      subtractamount();
      //code for subtracting the late fee added by Mano Ranjith
      let lateamount = Subtracttotallatefee();
      setLatelast10value(lateamount);

      ReceiptCategoriesRequestParam = [];
      acceptPaymentParam = [];
      AcceptclubPaymentParam = undefined;
      addrowarray = [];
      let objChkMo: CheqAndMO[] = [];
      setaddataanother(objChkMo);
      tendertypeglobalarray = [];
      settotalduetendertype(tendertypeglobalarray);
      setAcceptClub(undefined);
      setAcceptpaymentbuttonenable(true);
      setSelectedCheckBoxCount(0);
      setOverAll_Chk(false);
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
      for (let i = 0; i < multipleCard.length; i++) {
        multipleCard[i].Amount = "0.00";
      }
      setmultipleCard(multipleCard);
      setCardErrorLbl(false);
      setCardGridData(CardGrid);
      setcvvTextValue("");
      setCardAmt([{ amount: 0.0 }]);
      swipeCardAmt = [];
      setcardDetails([]);
      setcardtxtDisable(false);
      setswipeamt("0.00");
      swipeCardDetails = [];
      setswipeFlag(false);
      setcvvTextValue("");
      setchange(0.0);
      suspenseError = [];
      epoError = [];
      diffStoreError = [];
      setepoErrLbl(false);
      setsuspErrLbl(false);
      setdiffStoreErrLbl(false);
      /**
       * changes:Carry Rent
       * Developer:Muzzammil Shaik
       * Desc:Handle select all check box
       */
      setselectallChecked(false);
      // setcarryRentWidget(false)
      setcarryMessage("No agreements are Selected");
      if (carryRentWidget == true) {
        carryOnchange();
      }
      if (paymentOrigin == "Pay By Phone") {
        settotaldue(convAmtWTax);
        setremainingamountdue(convAmtWTax);
      } else {
        settotaldue("0.00");
        setremainingamountdue("0.00");
      }
      setagreecarryUsed(0.0);
    }

    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      (document.getElementsByName("grid")[i] as HTMLInputElement).checked =
        isChecked;
      //setOverAll_Chk(e.target.checked);
      //setchkboxselect(e.target.checked);
    }

    setcoaamt(paygridvalues[0].COA);
    setwldepositamount(paygridvalues[0].wlDeposit);
    setipsuspense(paygridvalues[0].ipSuspense);
    // multipleAdjust()
  };

  const addchecktypetooltip = (index: any) => {
    if (index === "1") {
      settooltipdisplaygrid("Personal Check");
    } else if (index === "2") {
      settooltipdisplaygrid("Travellers Check");
    } else if (index === "3") {
      settooltipdisplaygrid("Payroll Check");
    } else if (index === "4") {
      settooltipdisplaygrid("Government Check");
    } else if (index === "5") {
      settooltipdisplaygrid("Cashiers Check");
    }
  };

  const BindGridcheck = () => (
    <>
      {adddataanother != undefined && adddataanother.length > 0
        ? adddataanother.map((value: any, index: any) => {
            return (
              // eslint-disable-next-line prettier/prettier
              <RACTableRow
                key={"dynamicgridrow" + index}
                data-testid={"dynamicgridrow" + index}
                className={classes.hiddenRow}
              >
                {value.Type_info === "Check" ? (
                  <RACTableCell
                    className={classes.textleft}
                    title={tooltipdispalygrid}
                    data-testid={"tooltip" + index}
                    onMouseOver={() => {
                      addchecktypetooltip(value.check_type);
                    }}
                    //className={classes.textcenter}
                  >
                    {value.Type_info}
                    <InfoiconGrey className={classes.infoIcon} />
                  </RACTableCell>
                ) : (
                  <RACTableCell className={classes.textleft}>
                    {value.Type_info}
                  </RACTableCell>
                )}
                <RACTableCell className={classes.textleft}>
                  {value.check_no}
                </RACTableCell>
                <RACTableCell className={classes.textleft}>
                  {value.Written_by}
                </RACTableCell>
                {value.Amount_rac.split(".").length < 2 ? (
                  <RACTableCell className={classes.textright}>
                    <span>$</span>
                    {value.Amount_rac}
                    <span>.00</span>
                  </RACTableCell>
                ) : (
                  <RACTableCell className={classes.textright}>
                    <span>$</span>
                    {value.Amount_rac}
                  </RACTableCell>
                )}
                <RACTableCell className={classes.textcenter}>
                  <RACButton
                    //type="button"
                    className={`${classes.paymentSmallBtn} ${classes.mx2} ${classes.updatebutton}`}
                    //value={index}
                    onClick={() => update(index)}
                    data-testid={index + "update"}
                  >
                    Update
                  </RACButton>
                  <RACButton
                    //type="button"
                    className={`${classes.paymentSmallBtn} ${classes.deletebutton}`}
                    //value={index}
                    onClick={() => Delete(index)}
                    data-testid={"deletebuttongrid" + index}
                  >
                    Delete
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            );
          })
        : null}
    </>
  );
  const CheckBoxCheckedUncheckedFun = () => {
    let checkBoxArray = document.getElementsByName("grid");
    checkBoxArray.forEach(function (item, index) {
      let obj = item as HTMLInputElement;
      let checkBox = obj;
      if (paygridvalues[index].Agreementtype == "Club") {
        if (checkBox.checked) {
          checkBox.checked = false;
          checkBox.disabled = true;
          document
            .getElementById("maincheckbox")
            ?.setAttribute("disabled", "true");
          // document.getElementById("maincheckbox")?.removeAttribute("disabled");
          // setOverAll_Chk(true);
          settotaldueamount(
            "chk" + index,
            index,
            paygridvalues[index].Amount,
            paygridvalues[index].Agreementid,
            undefined
          );
        } else {
          if (clubStatus == "A") {
            document
              .getElementById("maincheckbox")
              ?.removeAttribute("disabled");
            checkBox.checked = true;
            checkBox.disabled = false;
            let allCheckBox = Array.from(checkBoxArray).filter(
              (el: any) => !el.checked
            );
            if (allCheckBox.length == 0) {
              document
                .getElementById("maincheckbox")
                ?.setAttribute("checked", "true");
            }
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          }
        }
      }
    });
  };
  const clubStatusHandleChange = async (status: any) => {
    console.log(clubStatus);

    if (status == "P") {
      const clubStatus: any = paygridvalues.filter(
        (el: any) => el?.Agreementtype == "Club"
      );

      if (clubStatus.length > 0) {
        if (
          paygridvalues.length == 1 &&
          paygridvalues[0].Agreementtype == "Club"
        ) {
          setdeactivateClubCustomerRedirection(true);
        } else {
          paygridvalues.map((obj) => {
            if (obj.Agreementtype == "Club") {
              obj.isClubDeactivateTriggered = false;
            }
          });
          // setpaygridvalues(paygridvalues);
          if (clubStatus[0].number_of_paymentsmade > 0) {
            CheckBoxCheckedUncheckedFun();
            setclubStatus("D");
            setclubCheckDisabled(false);
          } else if (clubStatus[0].number_of_paymentsmade == 0) {
            CheckBoxCheckedUncheckedFun();
            setclubStatus("P");
            setclubCheckDisabled(false);
          }
        }
      } else {
        setmasterloader(true);
        const response: any = await GetCustomerCoverage(customerId);
        if (response?.data?.clubInfo?.clubAmountwithTax) {
          let totalAmountAdded = Number(
            response?.data?.clubInfo?.clubAmountwithTax
          );
          for (
            let i = 0;
            i < response?.data?.agreementsWithoutLDW?.length;
            i++
          ) {
            let finalPaymentFlag = 0;
            const clubInformation = paygridvalues.filter(
              (x: any) =>
                x.Nextduedate != "SAC" &&
                x.Nextduedate != "EPO" &&
                x.Nextduedate != "PIF" &&
                x.Agreementid ==
                  String(response?.data?.agreementsWithoutLDW[i]?.agreementId)
            );
            if (clubInformation.length > 0) {
              totalAmountAdded =
                Number(totalAmountAdded) +
                Number(
                  response?.data?.agreementsWithoutLDW[i]?.ldwAmountWithTax
                );
            }
          }
          paygridvalues.map((obj) => {
            if (obj.Agreementtype == "Club") {
              obj.isClubDeactivateTriggered = false;
            }
          });
          // setpaygridvalues(paygridvalues);
          setclubAmount(Number(totalAmountAdded).toFixed(2));
          setclubStatusPopup({ ...clubStatusPopup, activateClub: true });
          console.log("coverage response", response);
        } else {
          seterrorPopup(true);
        }
        setmasterloader(false);
      }
    } else {
      if (
        paygridvalues.length == 1 &&
        paygridvalues[0].Agreementtype == "Club"
      ) {
        setdeactivateClubCustomerRedirection(true);
      } else {
        setclubStatusPopup({ ...clubStatusPopup, deactivateClub: true });
      }
      // activateDeactivateClubApiCall('C', 0, 'deactivateClub')
    }
  };
  const deactivateClubCustomerRedirectionChange = () => {
    redirectionToCustomer();
  };
  const deActivatePopupYesHandleChange = () => {
    console.log(clubStatus);
    // eslint-disable-next-line no-debugger
    // debugger
    let checkBoxArray = document.getElementsByName("grid");
    let sortedPayGridValue: any = [];
    let filteredClubData: any = [];
    let filteredNormalData: any = [];
    paygridvalues.map((payGridObj, index) => {
      if (payGridObj.Agreementtype == "Club") {
        filteredClubData.push(payGridObj);
      } else {
        filteredNormalData.push(payGridObj);
      }
    });
    sortedPayGridValue = [...filteredClubData, ...filteredNormalData];

    checkBoxArray.forEach(function (item, index) {
      let obj = item as HTMLInputElement;
      let checkBox = obj;
      if (sortedPayGridValue[index].Agreementtype == "Club") {
        setClubActivate(0);
        if (checkBox.checked) {
          checkBox.checked = false;
          checkBox.disabled = true;
          settotaldueamount(
            "chk" + index,
            index,
            sortedPayGridValue[index].Amount,
            sortedPayGridValue[index].Agreementid,
            undefined
          );
          document
            .getElementById("maincheckbox")
            ?.setAttribute("disabled", "true");
          setclubCheckDisabled(true);
          AcceptclubPaymentParam = undefined;
        } else {
          checkBox.checked = false;
          checkBox.disabled = true;
          document
            .getElementById("maincheckbox")
            ?.setAttribute("disabled", "true");
        }
      }
    });
    paygridvalues.map((obj) => {
      if (obj.Agreementtype == "Club") {
        obj.isClubDeactivateTriggered = true;
      }
    });
    setclubStatusPopup({ ...clubStatusPopup, deactivateClub: false });
    setclubStatus("A");
    setclubBtnStatus("P");
    setclubCheckDisabled(true);
  };
  const activateDeactivateClubApiCall = async (
    isCreate,
    clubStatusRefCode,
    popup
  ) => {
    try {
      setclubStatusPopup({ ...clubStatusPopup, [popup]: false });
      setmasterloader(true);
      let payload: any = {
        paymentSchedule: null,
        customerId: Number(customerId),
        isCreate: isCreate,
        agreementId: null,
        clubStatusRefCode: clubStatusRefCode,
      };
      const response: any = await ActiveClub(payload);
      if (response?.customerClubId) {
        setclubStatus("Club is pending");
      }
      console.log("activateclub response", response);
      setmasterloader(false);
      window.location.reload();
    } catch (e: any) {
      setmasterloader(false);
      seterrorPopup(true);
    }
  };
  const ErrorMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            {`Something went wrong.`}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => seterrorPopup(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

    const postalCodeZip5ErrorMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography variant={"body2"} className={classes.RACPOPMsg} style={{marginTop:"5px"}} >
            {postalCodeZip5ErrorFlag==1?
             `Invalid Customer Zip Code. Please Update`
            :postalCodeZip5ErrorFlag==2?
             `Invalid Customer Phone Number. Please update `:""}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
          style={{marginLeft:"3px"}}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            className={classes.me2}
            onClick={() => redirectionToCustomer()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const update = (index: any) => {
    setConrolUpdate("1");
    setbutton(true);
    setuploadindex(index);
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);

    if (adddataanother[parseInt(index)]["Type_info"] === "Check") {
      setcheckmonvar("1");
      setaddcheck(true);
      setadddata({
        ...adddata,
        checktype: adddataanother[parseInt(index)]["check_type"],
        Type: adddataanother[parseInt(index)]["Type_info"],
        Written_To: adddataanother[parseInt(index)]["Written_too"],
        checkno: adddataanother[parseInt(index)]["check_no"],
        Written_by: adddataanother[parseInt(index)]["Written_by"],
        Amount: adddataanother[parseInt(index)]["Amount_rac"],
      });
    } else {
      setcheckmonvar("2");
      setaddmoneyorder(true);
      setadddata({
        ...adddata,
        Type: adddataanother[parseInt(index)]["Type_info"],
        Written_To: adddataanother[parseInt(index)]["Written_too"],
        checkno: adddataanother[parseInt(index)]["check_no"],
        Written_by: adddataanother[parseInt(index)]["Written_by"],
        Amount: adddataanother[parseInt(index)]["Amount_rac"],
      });
    }
    let temp = adddataanother[parseInt(index)]["Amount_rac"];
    setuploadtotal(temp);

    // if (parseFloat(remainingamountdue) > 0) {
    //   let objRemAmt = parseFloat(remainingamountdue) + parseFloat(temp);
    //   setremainingamountdue(objRemAmt);
    // }
  };

  const upload = (e: any) => {
    setcancelindication(0);
    settotalcontrol(1);
    let arr = {
      check_type: adddata.checktype,
      Type_info: adddata.Type,
      check_no: adddata.checkno,
      Written_too: adddata.Written_To,
      Written_by: adddata.Written_by,
      Amount_rac: adddata.Amount,
    };
    setchecknovalid(false);
    setType(false);
    setwrittento(false);
    setwritenby(false);
    setAmount(false);
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }

    chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    let cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    cardamount =
      Number(cardamount) + Number(chargeCardAmount) + Number(swipeCardAmount);
    // let remainamount =
    //   Number(RemaniningAmtStatic.toFixed(2)) -
    //   Number(Number(adddata.Amount).toFixed(2)) -
    //   Number(Number(cardamount).toFixed(2)) -
    //   Number(Number(cashamountcheckbox).toFixed(2));
    console.log(
      "check update enter details",
      totalamountcheck,
      uploadtotal,
      adddata.Amount
    );
    let totalcheck =
      Number(Number(totalamountcheck).toFixed(2)) -
      Number(Number(uploadtotal).toFixed(2)) +
      Number(Number(adddata.Amount).toFixed(2));

    let FloatInputTxttofixed = Number(totalcheck).toFixed(2);
    let tendertypedetailsch = {
      tendertype: "Check & Money Order",
      Amount: FloatInputTxttofixed,
    };
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype === "Check & Money Order") {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }
    if (totalcheck !== 0) {
      tendertypeglobalarray.push(tendertypedetailsch);
      settotalduetendertype(tendertypeglobalarray);
    }
    // if (remainamount > 0 && totalamountcheck <= RemaniningAmtStatic) {
    //   setremainingamountdue(remainamount);
    // } else {
    //   let changeamount =
    //     change - parseFloat(uploadtotal) + parseFloat(adddata.Amount);
    //   setchange(changeamount);
    //   setremainingamountdue(0);
    // }

    let objCheqAmt = Number(adddata.Amount).toFixed(2);
    let objRAmtStatic = Number(RemaniningAmtStatic).toFixed(2);
    //let objrmAmt = Number(remainingamountdue).toFixed(2);
    let sumOfOtherTenderAmount = Number(
      Number(addcash) + +Number(cardamount)
    ).toFixed(2);

    console.log("**Cheque Amount**", objCheqAmt);
    console.log("**Remaining Amount Static**", objRAmtStatic);
    //console.log("**Remaining Amount Due**", objrmAmt);

    if (checkmonvar === "1") {
      if (adddata.checkno === "") {
        setchecknovalid(true);
      }
      if (adddata.checktype === "0") {
        setType(true);
      }
      if (adddata.Written_To === "") {
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        setAmount(true);
      }
      if (
        adddata.checktype !== "0" &&
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        Number(adddata.Amount) > 0 &&
        !Number.isNaN(Number(adddata.Amount))
      ) {
        console.log(
          "check amt details",
          adddata.checktype,
          sumOfOtherTenderAmount,
          objRAmtStatic,
          objCheqAmt,
          FloatInputTxttofixed
        );
        if (
          adddata.checktype === "1" &&
          Number(FloatInputTxttofixed) >
            Number(
              (Number(objRAmtStatic) - Number(sumOfOtherTenderAmount)).toFixed(
                2
              )
            )
        ) {
          setpersonalcheckalert(true);
        } else if (
          adddata.checktype === "2" &&
          Number(FloatInputTxttofixed) >
            Number(
              (Number(objRAmtStatic) - Number(sumOfOtherTenderAmount)).toFixed(
                2
              )
            )
        ) {
          settravellercheckalert(true);
        } else {
          setaddcheck(false);
          setaddmoneyorder(false);
          const reduceremaingamount =
            Number(Number(objRAmtStatic).toFixed(2)) -
            Number(Number(FloatInputTxttofixed).toFixed(2)) -
            Number(Number(sumOfOtherTenderAmount).toFixed(2));
          let chargeCardAmount = 0;
          let swipeCardAmount = 0;
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Ending with")
            ) {
              chargeCardAmount =
                chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          for (let i = 0; i < tendertypeglobalarray.length; i++) {
            if (
              tendertypeglobalarray[i].tendertype
                .split(" - ")[0]
                .includes("Card Swipe ending")
            ) {
              swipeCardAmount =
                swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
            }
          }
          chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
          let cardamount = Number(
            multipleCard.length == 0
              ? 0.0
              : multipleCard
                  .map((bill) => bill.Amount)
                  .reduce((acc, bill) =>
                    (Number(bill) + Number(acc)).toFixed(2)
                  )
          );
          cardamount =
            Number(cardamount) +
            Number(chargeCardAmount) +
            Number(swipeCardAmount);
          if (reduceremaingamount >= 0) {
            setremainingamountdue(reduceremaingamount);
            let isChecked = 0;
            for (
              let i = 0;
              i < document.getElementsByName("grid").length;
              i++
            ) {
              console.log("entered into the for loop of loadother action");
              if (
                (document.getElementsByName("grid")[i] as HTMLInputElement)
                  .checked == true
              ) {
                isChecked = 1;
              }
            }
            if (reduceremaingamount === 0 && isChecked == 1) {
              if (cardamount > 0 && cvvTextValue.length >= 3)
                setAcceptpaymentbuttonenable(false);
              else if (cardamount > 0 && swipeFlag == false)
                setAcceptpaymentbuttonenable(true);
              else setAcceptpaymentbuttonenable(false);
            } else {
              setAcceptpaymentbuttonenable(true);
            }
          }
          let newARR = adddataanother;
          newARR.splice(uploadindex, 1, arr);
          setaddataanother(newARR);

          settotalamountcheck(totalcheck);
        }
      }
    }
    if (checkmonvar === "2") {
      if (
        adddata.Written_To !== "" &&
        adddata.Written_by !== "" &&
        adddata.Amount !== "" &&
        adddata.checkno !== "" &&
        Number(adddata.Amount) > 0 &&
        !Number.isNaN(Number(adddata.Amount))
      ) {
        if (
          Number(FloatInputTxttofixed) >
          Number(
            (Number(objRAmtStatic) - Number(sumOfOtherTenderAmount)).toFixed(2)
          )
        ) {
          setoverpayMoneyord(true);
        } else {
          setaddmoneyorder(false);
          setaddcheck(false);
          let isChecked = 0;
          for (let i = 0; i < document.getElementsByName("grid").length; i++) {
            console.log("entered into the for loop of loadother action");
            if (
              (document.getElementsByName("grid")[i] as HTMLInputElement)
                .checked == true
            ) {
              isChecked = 1;
            }
          }
          const reduceremaingamount =
            Number(Number(objRAmtStatic).toFixed(2)) -
            Number(Number(FloatInputTxttofixed).toFixed(2)) -
            Number(Number(sumOfOtherTenderAmount).toFixed(2));
          if (reduceremaingamount >= 0 && isChecked == 1) {
            setremainingamountdue(reduceremaingamount);
            if (reduceremaingamount === 0) {
              let chargeCardAmount = 0;
              let swipeCardAmount = 0;
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Ending with")
                ) {
                  chargeCardAmount =
                    chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              for (let i = 0; i < tendertypeglobalarray.length; i++) {
                if (
                  tendertypeglobalarray[i].tendertype
                    .split(" - ")[0]
                    .includes("Card Swipe ending")
                ) {
                  swipeCardAmount =
                    swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
                }
              }
              chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
              let cardamount = Number(
                multipleCard.length == 0
                  ? 0.0
                  : multipleCard
                      .map((bill) => bill.Amount)
                      .reduce((acc, bill) =>
                        (Number(bill) + Number(acc)).toFixed(2)
                      )
              );
              cardamount =
                Number(cardamount) +
                Number(chargeCardAmount) +
                Number(swipeCardAmount);
              if (
                cardamount > 0 &&
                cvvTextValue.length < 3 &&
                swipeFlag == false
              ) {
                console.log("accept payment button enabled when saving check1");
                setAcceptpaymentbuttonenable(true);
              } else {
                setAcceptpaymentbuttonenable(false);
              }
            } else {
              setAcceptpaymentbuttonenable(true);
            }
          }
          let newARR = adddataanother;
          newARR.splice(uploadindex, 1, arr);
          setaddataanother(newARR);

          settotalamountcheck(totalcheck);
        }
      }
      if (adddata.checkno === "") {
        setchecknovalid(true);
      }
      if (adddata.Written_To === "") {
        setwrittento(true);
      }
      if (adddata.Written_by === "") {
        setwritenby(true);
      }
      if (
        adddata.Amount === "" ||
        Number(adddata.Amount) <= 0.0 ||
        Number(adddata.Amount) <= 0 ||
        Number.isNaN(Number(adddata.Amount))
      ) {
        //flag1 = 1;
        setAmount(true);
      }
    }
  };

  /*Here the delete functionality is used to delete the data in the binded grid.*/
  const Delete = (param: any) => {
    setchecking("1");
    adddataanother.splice(param, 1);
    LoadData();
  };
  const resetCardAmountEntered = () => {
    const cardamount = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    let objRemainAmount = 0;
    let totalOtherTenderAmt =
      Number(addcash) + Number(totalamountcheck) + Number(cardamount);
    objRemainAmount =
      Number(Number(RemaniningAmtStatic).toFixed(2)) -
      Number(Number(totalOtherTenderAmt).toFixed(2));
    console.log(
      "amounts when changing a card",
      totalOtherTenderAmt,
      objRemainAmount,
      remainingamountdue,
      change
    );
    setCardrequire({ ...Cardrequire, chkcvv: "0.00" });
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype.includes("Card ending")) {
        tendertypeglobalarray.splice(i, 1);
        break;
      }
    }
    settotalduetendertype(tendertypeglobalarray);
    setCardGridData(CardGrid);
    setcardDetails([]);
    if (objRemainAmount > RemaniningAmtStatic) {
      setremainingamountdue(RemaniningAmtStatic);
      setAcceptpaymentbuttonenable(true);
    } else if (objRemainAmount < 0) {
      let newRemainAmt =
        Number(Number(RemaniningAmtStatic).toFixed(2)) -
        (Number(addcash) + Number(totalamountcheck));
      if (newRemainAmt > 0) {
        setchange(0.0);
        setremainingamountdue(newRemainAmt);
        setAcceptpaymentbuttonenable(true);
      } else if (newRemainAmt == 0) {
        setchange(0.0);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      } else if (newRemainAmt < 0) {
        setchange(-1 * newRemainAmt);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      }
    } else if (objRemainAmount == 0) {
      // setchange(0.00);
      // setremainingamountdue(0.00);
      let isChecked = 0;
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        console.log("entered into the for loop of loadother action");
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          isChecked = 1;
        }
      }
      if (isChecked == 1) {
        let newRemainAmt =
          Number(Number(RemaniningAmtStatic).toFixed(2)) -
          (Number(addcash) + Number(totalamountcheck));
        if (newRemainAmt > 0) {
          setchange(0.0);
          setremainingamountdue(newRemainAmt);
          setAcceptpaymentbuttonenable(true);
        } else if (newRemainAmt == 0) {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        } else if (newRemainAmt < 0) {
          setchange(-1 * newRemainAmt);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        }
      } else {
        setchange(0.0);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(true);
      }
    } else {
      let newRemainAmt =
        Number(Number(RemaniningAmtStatic).toFixed(2)) -
        (Number(addcash) + Number(totalamountcheck));
      if (newRemainAmt > 0) {
        setchange(0.0);
        setremainingamountdue(newRemainAmt);
        setAcceptpaymentbuttonenable(true);
      } else if (newRemainAmt == 0) {
        setchange(0.0);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      } else if (newRemainAmt < 0) {
        setchange(-1 * newRemainAmt);
        setremainingamountdue(0.0);
        setAcceptpaymentbuttonenable(false);
      }
    }
    setcvvTextValue("");
    setCvv("");
  };
  // Card tab function starts here
  // Card tab's 1st checkbox function defenition
  let TempCard_Array: any = [];
  const SelectCardOptionChange = (
    index: any,
    expiredate: any,
    cardToken: any,
    cardlastFour: any,
    customerPaymentMethodId: string
  ) => {
    // debugger;
    console.log("check box clicked");
    CardRadiArr.map((value: any, index: any) => {
      TempCard_Array.push(CardRadiArr[index]);
    });
    if (CardRadiArr[index] == true) {
      TempCard_Array.splice(index, 1, false);
    } else {
      TempCard_Array.splice(index, 1, true);
    }
    setCardRadiArr(TempCard_Array);
    /* eslint-disable no-console */
    console.log("printing index", index);
    /* eslint-disable no-console */
    console.log("chkbox of cardtab", chkbox);
    let objRemainAmount = 0;
    if (
      cardGridData != undefined &&
      swipeFlag != true &&
      cardGridData.BillingAddress?.cardToken !== cardToken
    ) {
      console.log("Entered into swipeplag not true");
      objRemainAmount =
        parseFloat(remainingamountdue) + parseFloat(cardGridData.Amount);
      setCardrequire({ ...Cardrequire, chkcvv: "0.00" });
      let CardCheckObj: CardChk = {
        CardLastFour: String(cardGridData.CardLastFour),
        Amount: "0.00",
        BillingAddress: cardGridData.BillingAddress,
        cardExpiration: expiredate,
        customerPaymentMethodId: customerPaymentMethodId,
      };
      if (CardCheckObj.BillingAddress != undefined) {
        CardCheckObj.BillingAddress.cardToken = cardToken;
      }
      setCardGridData(CardCheckObj);
      if (objRemainAmount > RemaniningAmtStatic) {
        objRemainAmount = RemaniningAmtStatic;
      }
      const FloatInputTxttofixed = cardGridData.Amount;
      const tendertypedetails = {
        tendertype: cardlastFour,
        Amount: FloatInputTxttofixed,
        paymentMethodID: String(customerPaymentMethodId),
      };

      // setremainingamountdue(objRemainAmount);
      // setcvvTextValue("");
      // setCvv("");
    } else if (
      cardGridData != undefined &&
      cardGridData.BillingAddress?.cardToken === cardToken
    ) {
      console.log("Entered into condition which has no swipe");
      let CardCheckObj: CardChk = {
        CardLastFour: String(cardGridData.CardLastFour),
        Amount: cardGridData.Amount,
        BillingAddress: cardGridData.BillingAddress,
        cardExpiration: expiredate,
        customerPaymentMethodId: customerPaymentMethodId,
      };
      CardCheckObj.BillingAddress != undefined
        ? (CardCheckObj.BillingAddress.cardToken = cardToken)
        : cardToken;
      setCardGridData(CardCheckObj);
    } else if (swipeFlag == true) {
      console.log("Entered into else condition of radio btn");
      let CardCheckObj: CardChk = {
        CardLastFour: undefined,
        Amount: cardGridData.Amount,
        BillingAddress: undefined,
        cardExpiration: undefined,
      };
      setCardGridData(CardCheckObj);
    }

    // const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
    // TempCard_Array[index] = true;
    // setCardRadiArr(TempCard_Array);

    // for (let i = 0; i < document.getElementsByName("report").length; i++) {
    //   let objRdo = document.getElementsByName("report")[i] as HTMLInputElement;
    //   let divData = document.getElementById("div-" + i);
    //   setCardErrorLbl(false);
    //   if (divData != undefined) {
    //     if (index === i && objRdo.checked) {
    //       divData.style.display = "block";
    //     } else {
    //       divData.style.display = "none";
    //     }
    //   }
    // }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let objRdo = document.getElementsByName("report")[
      index
    ] as HTMLInputElement;
    let divData = document.getElementById("div-" + index);

    let cardAmount = 0.0;
    let nonCardAmount = 0.0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].paymentMethodID ===
        String(customerPaymentMethodId)
      ) {
        tendertypeglobalarray.splice(i, 1);
        // cardArray.splice(i,1)
        break;
      } else if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card ending")
      ) {
        cardAmount = cardAmount + Number(tendertypeglobalarray[i].Amount);
      } else {
        nonCardAmount = nonCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    nonCardAmount = nonCardAmount + Number(chargeCardAmt);
    multipleCard[index].Amount = "0.00";
    multipleCard[index].cvv = "";
    multipleCard[index].cvvEncrypt = "";
    settotalduetendertype(tendertypeglobalarray);
    // setcardDetails(cardArray)
    // debugger;
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    resTotalAmount = Number(
      (Number(resTotalAmount) + Number(chargeCardAmt)).toFixed(2)
    );
    if (divData != undefined) {
      if (objRdo.checked) {
        divData.style.display = "block";
        if (
          Number(Number(cardAmount).toFixed(2)) >
          Number(totaldue) +
            Number(addSusTotalAmount) -
            Number(availableCreds) -
            Number(usedSusTotalAmount)
        ) {
          setremainingamountdue(
            Number(
              (
                Number(totaldue) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            ) - Number(nonCardAmount)
          );
          if (
            Number(totaldue) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(nonCardAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(nonCardAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
          setCardErrorLbl(true);
        } else {
          setremainingamountdue(
            Number(
              (
                Number(totaldue) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          if (
            Number(totaldue) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(resTotalAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(resTotalAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
        }
      } else {
        divData.style.display = "none";
        //
        if (
          Number(
            (
              Number(totaldue) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) -
            Number(resTotalAmount) >
          0
        ) {
          debugger;
          setremainingamountdue(
            Number(
              (
                Number(totaldue) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            ) - Number(resTotalAmount)
          );
          setchange("0.00");
        } else if (
          Number(Number(cardAmount).toFixed(2)) <=
          Number(totaldue) +
            Number(addSusTotalAmount) -
            Number(availableCreds) -
            Number(usedSusTotalAmount)
        ) {
          setremainingamountdue("0.00");
          if (
            Number(totaldue) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(resTotalAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(resTotalAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
        } else if (
          Number(Number(nonCardAmount).toFixed(2)) >=
          Number(totaldue) +
            Number(addSusTotalAmount) -
            Number(availableCreds) -
            Number(usedSusTotalAmount)
        ) {
          //  if(nonCardAmount>){
          setremainingamountdue("0.00");
          if (
            Number(totaldue) +
              Number(addSusTotalAmount) -
              Number(availableCreds) -
              Number(usedSusTotalAmount) -
              Number(nonCardAmount) <
            0
          ) {
            let change =
              -1 *
              (Number(totaldue) +
                Number(addSusTotalAmount) -
                Number(availableCreds) -
                Number(usedSusTotalAmount) -
                Number(nonCardAmount));
            setchange(Number(change).toFixed(2));
          } else {
            setchange("0.00");
          }
          //  }
        }
        // setremainingamountdue(Number(Number(remainingamountdue)+Number(multipleCard[index].Amount)).toFixed(2))
      }
    }

    // if(Number(remainingamountdue)!=0.00){
    //   setAcceptpaymentbuttonenable(true)
    // }
    let enableAcc = true;
    let amountChk = 0;
    for (let i = 0; i < multipleCard.length; i++) {
      amountChk = amountChk + Number(multipleCard[i].Amount);
    }
    if (
      Number(Number(amountChk).toFixed(2)) >
      Number(totaldue) -
        Number(availableCreds) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount)
    ) {
      setCardErrorLbl(true);
    } else {
      enableAcc = false;
      setCardErrorLbl(false);
    }
    let enablepayment = true;
    for (let i = 0; i < multipleCard.length; i++) {
      if (
        Number(multipleCard[i].Amount > 0) &&
        multipleCard[i].cvvEncrypt.length < 3
      ) {
        enablepayment = false;
      }
    }
    if (
      Number(totaldue) -
        Number(availableCreds) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) -
        Number(resTotalAmount) <=
        0 &&
      selectedCheckBoxCount != 0 &&
      enableAcc == false &&
      enablepayment &&
      Number(chargeCardAmt) == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
    // debugger;
    // if(Number(remainingamountdue)!=0.00){
    //   setAcceptpaymentbuttonenable(true)
    // }
  };
  // Swipecard Model function defines here
  const Swipecard = () => {
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      console.log("entered into the for loop of loadother action");
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    if (isChecked == 1) {
      setCardrequire({ ...Cardrequire, swipcard: true, Proceedbtn: true });
      setswipeDeviceValue("0.00");
      setValidator({ ...Validator, SwipeAmtValidationtxtbox: false });
      setgettotamt("0.00");
    }
  };

  //vantiv func
  const callVantivIframe = (iframeType: number) => {
    console.log(process.env, "env variables");
    if (iframeType == 1) {
      setVantivIframe(
        loadVantivIFrame("01190090", "ccframe", handleVantivCallback)
      );
    } else if (iframeType == 2) {
      //const temp=loadVantivIFrame('01190090', 'ccframe', handleVantivCallback);
      setVantivIframe(
        loadVantivIFrame("01190090", "chargeframe", handleVantivChargeCallback)
      );
    }
    return 1;
  };
  const handleVantivCallback = (response: any) => {
    if (response.response === "870") {
      //setIsSubmitting(true);
      const card = new CreditCard(response);
      //const formValues: FormValues = getValues();
      const request: ProcessPaymentLogRequest = {
        //paymentLogId: paymentLog.paymentLogId.toString(),
        paymentToken: response.paypageRegistrationId,
        cardType: card.type,
        expirationDate: card.cardExpiration,
        lastFour: card.lastDigits,
        savePaymentMethod: "Y",
      };
      vantivSubmit(request);
      close();
    } else {
      handleVantivErrorResponse(response.response);
    }
  };
  const handleVantivChargeCallback = (response: any) => {
    console.log(response, "callback");
    if (response.response === "870") {
      //setIsSubmitting(true);
      const card = new CreditCard(response);
      //const formValues: FormValues = getValues();
      const request: ProcessPaymentLogRequest = {
        //paymentLogId: paymentLog.paymentLogId.toString(),
        paymentToken: response.paypageRegistrationId,
        cardType: card.type,
        expirationDate: card.cardExpiration,
        lastFour: card.lastDigits,
        savePaymentMethod: "Y",
      };
      vantivChargeSubmit(request);
      close();
    } else {
      handleVantivErrorResponse(response.response);
    }
  };

  const cardSuccessfullySaved = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {saveCardSuccess ? (
          <Grid item md={12} className={classes.textcenter}>
            <SuccessIcon></SuccessIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Card Saved Successfully
            </Typography>
          </Grid>
        ) : saveCardFail ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Something went wrong. Failed to save the card
            </Typography>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => savedCardOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const cardSuccessfullyDeleted = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {deleteCardSuccess ? (
          <Grid item md={12} className={classes.textcenter}>
            <SuccessIcon></SuccessIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Card Deleted Successfully
            </Typography>
          </Grid>
        ) : deleteCardFail ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              {failedToDeleteCardError == ""
                ? "Something went wrong - Unable to delete card. Please retry"
                : failedToDeleteCardError}
            </Typography>
          </Grid>
        ) : (
          ""
        )}
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => deleteCardOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const savedCardOk = () => {
    setsaveCardFail(false);
    setsaveCardSuccess(false);
    setsaveCardPopUp(false);
  };
  const deleteCardOk = () =>{
    setdeleteCardFail(false);
    setdeleteCardSuccess(false);
    setdeleteCardPopUp(false);
    setFailedToDeleteCardError("");
  }
  const spinnerpopupSaveCard = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={saveCardPopUp} size={40} />
          <Typography className="row justify-content-center popup-text">
            Saving card
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const spinnerpopupDeleteCard = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={deleteCardPopUp} size={40} />
          <Typography className="row justify-content-center popup-text">
            Deleting card
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const EditCardfunc = async () => {
    /* eslint-disable no-console */
    console.log("Hii I am in save Edit card details", EditCard);
    if (
      EditCard.Cardname !== "" &&
      EditCard.address_1 !== "" &&
      EditCard.city !== "" &&
      EditCard.zip !== "" &&
      EditCard.state !== "0"
    ) {
      setCardrequire({ ...Cardrequire, editcrd: false });
      const customerInfoParam: CustomerInfo = {
        FirstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
        LastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
      };
      const BillInfo: SaveBillinginfo = {
        Address1: EditCard.address_1,
        Address2: EditCard.address_2,
        City: EditCard.city,
        StateProvince: EditCard.state,
        PostalCode: EditCard.zip,
        Country: "USA",
        BillShipSameAddressFlag: "0",
      };
      const NewCardReq: SaveCardRequestDTO = {
        raftApiFlag: Number(configFlags.raftApiFlag),
        storeMerchantId: storeMerchantId || null,
        paymentFlag: 0,
        clubFlag: 0,
        updateFlag: 1,
        cardType: getCardService_state.cardResp[editcardindex].cardType,
        cardToken: String(
          getCardService_state.cardResp[editcardindex].cardToken
        ),
        cardHolderName: String(EditCard.Cardname),
        cardExpiration: String(EditCard.cardExpDate),
        storeId: String(window.sessionStorage.getItem("storeNumber")),
        transactionAmount: "0.00",
        cardNumberLastFour:
          getCardService_state.cardResp[editcardindex].cardLastFour,
        customerId: Number(customerId),
        PaymentID: "",
        Amount: 0,
        SalesTax: 0,
        CustomerInfo: customerInfoParam,
        BillToAddress: BillInfo,
        agreementIds: [],
      };
      setsaveCardPopUp(true);
      let savecardres = await Savecustomercard(NewCardReq);
      /* eslint-disable no-console */
      console.log("savecard response------->", savecardres);
      if (
        savecardres?.status === 200 &&
        savecardres?.data !== "" &&
        savecardres?.data?.customerId != undefined
      ) {
        getCardAfterUpdate();
        setsaveCardPopUp(false);
        setsaveCardSuccess(true);
      } else {
        setsaveCardPopUp(false);
        setsaveCardFail(true);
      }
    } else {
      if (EditCard.Cardname === "") {
        setnameVal(true);
      }
      if (EditCard.address_1 === "") {
        setaddressval1(true);
      }
      if (EditCard.city === "") {
        setcityVal(true);
      }
      if (EditCard.state === "0") {
        setstateVal(true);
      }
      if (EditCard.zip === "") {
        setZipVal(true);
      }
    }
  };

  const vantivSubFunc = () => {
    console.log("Reached in vantivesubfunc", Popupfields);
    console.log("DefaultVal", defaultVal);
    if (
      Popupfields.name !== "" &&
      Popupfields.address_line_1 !== "" &&
      Popupfields.zip_code !== "" &&
      Popupfields.city !== "" &&
      defaultVal !== "0"
    ) {
      console.log("am in if condition of savecard");
      getRegistrationId(vantivIframe, null);
    } else {
      console.log("am in else condition of savecard");
      if (Popupfields.address_line_1 === "") {
        setAddress_line1_validation(true);
      }
      if (Popupfields.name === "") {
        setName_validation(true);
      }
      if (defaultVal === "0") {
        setState_validation(true);
      }
      if (Popupfields.zip_code === "") {
        setZip_code_validation(true);
      }
      if (Popupfields.city === "") {
        setCityValidation(true);
      }
    }
    setChargeCardFlag(false);
  };

  const vantivSubmit = async (request: ProcessPaymentLogRequest) => {
    let isCardClicked = true;
    console.log(request, "Am in vantivsubmit func");
    setshowAddCard(false);
    const customerInfoParam: CustomerInfo = {
      FirstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
      LastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
    };
    const BillInfo: SaveBillinginfo = {
      Address1: String(window.sessionStorage.getItem("billAdd1")),
      Address2: String(window.sessionStorage.getItem("billAdd2")),
      City: String(window.sessionStorage.getItem("billCity")),
      StateProvince: String(window.sessionStorage.getItem("billState")),
      PostalCode: String(window.sessionStorage.getItem("billZip")),
      Country: "USA",
      BillShipSameAddressFlag: "0",
    };
    const NewCardReq: SaveCardRequestDTO = {
      raftApiFlag: Number(configFlags.raftApiFlag),
      storeMerchantId: storeMerchantId || null,
      paymentFlag: 0,
      clubFlag: 0,
      updateFlag: 0,
      cardType: String(request.cardType),
      cardToken: request.paymentToken,
      cardHolderName: String(window.sessionStorage.getItem("billName")),
      cardExpiration: String(request.expirationDate),
      storeId: String(window.sessionStorage.getItem("storeNumber")),
      transactionAmount: "0.00",
      cardNumberLastFour: String(request.lastFour),
      customerId: Number(customerId),
      PaymentID: String(request.paymentToken),
      Amount: 0,
      SalesTax: 0,
      CustomerInfo: customerInfoParam,
      BillToAddress: BillInfo,
      agreementIds: [],
    };
    /* eslint-disable no-console */
    console.log("Hii I am in save new card details");
    setsaveCardPopUp(true);
    let savecardres = await Savecustomercard(NewCardReq);
    /* eslint-disable no-console */
    console.log("savecard response------->", savecardres);
    if (
      savecardres.status === 200 &&
      savecardres.data !== "" &&
      savecardres?.data?.customerId != undefined
    ) {
      await getCardAfterUpdate();
      setsaveCardPopUp(false);
      setsaveCardSuccess(true);
      if (isCardClicked && multipleCard === undefined) {
        const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
        setCardRadiArr(TempCard_Array);
      }
    } else {
      setsaveCardPopUp(false);
      setsaveCardFail(true);
    }
    setPopupfields(newcardinformation);
    setdefaultVal("0");
  };

  const vantivChargeSubmit = async (request: ProcessPaymentLogRequest) => {
    console.log(request, "Am in vantivchargesubmit func");
    setshowChargeCard(false);
    // setcardtxtDisable(true);
    // setchargeCardDisable(true);
    //setshowChargeCard(true);
    setshowAddCard(false);
    setaddNewCard(true);

    setpaymentOriginDisable(false);
    let cardType = "Unknown";
    let cardRef = "U";
    switch (request.cardType) {
      case "1":
        cardType = "Master";
        cardRef = "M";
        break;
      case "2":
        cardType = "Visa";
        cardRef = "V";
        break;
      case "3":
        cardType = "American Express";
        cardRef = "A";
        break;
      case "4":
        cardType = "Discover";
        cardRef = "D";
        break;
      case "5":
        cardType = "Diners Club";
        cardRef = "DC";
        break;
      case "6":
        cardType = "JCB";
        cardRef = "J";
        break;
      case "7":
        cardType = "PayPal";
        cardRef = "PP";
        break;
      case "10":
        cardType = "Card";
        cardRef = "C";
        break;
      case "11":
        cardType = "Unknown";
        cardRef = "U";
        break;
    }
    let cardDetail = {
      cardAmount: Number(chargeCardValue),
      cardAuthNumber: "",
      cardLastFour: request.lastFour,
      cardType: cardType,
      cardToken: request.paymentToken,
      cardTransactionType: "MNL",
      cardRef: cardRef,
    };
    // swipeCardDetails.push(cardDetail);
    // setcardDetails(swipeCardDetails);

    let b = "/";
    let position = 2;
    let cardExpirationParse = [
      String(request.expirationDate).slice(0, position),
      b,
      String(request.expirationDate).slice(position),
    ].join("");
    const BillInfo: SaveBillinginfo = {
      Address1: String(window.sessionStorage.getItem("billAdd1")),
      Address2: "",
      City: String(window.sessionStorage.getItem("billCity")),
      StateProvince: String(window.sessionStorage.getItem("billState")),
      PostalCode: String(window.sessionStorage.getItem("billZip")),
      Country: "USA",
      BillShipSameAddressFlag: "0",
    };
    setChargeBillInfo(BillInfo);
    let CardCheckObj: CardChk = {
      CardLastFour: String(request.lastFour),
      Amount: Number(String(chargeCardValue)).toFixed(2),
      BillingAddress: undefined,
      cardExpiration: String(cardExpirationParse),
      customerPaymentMethodId: String(request.paymentToken),
      cardType: String(cardType),
    };
    console.log(CardCheckObj, "card check object");
    console.log(request, "vantiv resp");
    setCardGridData(CardCheckObj);
    FormAcceptPaymentData(0, 0, CardCheckObj);
    const CardDetail = "Card Ending with - " + request.lastFour;
    const tendertypedetails = {
      tendertype: CardDetail,
      Amount: String(chargeCardValue),
    };

    let chargeBillInfo = {
      addressLine1: String(window.sessionStorage.getItem("billAdd1")),
      addressLine2: "",
      city: String(window.sessionStorage.getItem("billCity")),
      state: String(window.sessionStorage.getItem("billState")),
      zip: String(window.sessionStorage.getItem("billZip")),
    };

    //  debugger;

    let multipleCardPush = {
      lastFour: String(request.lastFour),
      Amount: Number(String(chargeCardValue)).toFixed(2),
      billingAddress: chargeBillInfo,
      expirationDate: String(cardExpirationParse),
      paymentId: String(request.paymentToken),
      cardType: String(cardType),
      cvv: "",
      cvvEncrypt: "***",
      chargeCard: true,
      cardToken: String(request.paymentToken),
      cardRef: cardRef,
    };
    tendertypeglobalarray.push(tendertypedetails);
    settotalduetendertype(tendertypeglobalarray);
    // multipleCard=multipleCard.push(multipleCardPush)
    // setmultipleCard(multipleCard)
    // setchargeCardAmt("0.00")
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    setchargeCardAmt("0.00");
    chargeCardArray = [...chargeCardArray, multipleCardPush];
    setchargeCardArray(chargeCardArray);
    setcardToken([
      ...cardToken,
      {
        lastFour: String(request.lastFour),
        cardToken: String(request.paymentToken),
      },
    ]);
    let payButton = 0;
    for (let i = 0; i < multipleCard.length; i++) {
      if (
        Number(multipleCard[i].Amount) > 0 &&
        multipleCard[i].cvvEncrypt.length < 3
      ) {
        payButton++;
      }
    }
    const totalamount =
      tendertypeglobalarray.length == 0
        ? 0.0
        : tendertypeglobalarray
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (
      Number(
        (
          Number(totaldue) -
          Number(availableCreds) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) -
          Number(totalamount)
        ).toFixed(2)
      ) <= 0 &&
      payButton == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }

    // const customerInfoParam : CustomerInfo = {
    //   FirstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
    //   LastName: String(getpayment?.paymentInfo.customerInfo[0].lastName)
    // }
    // const BillInfo = {
    //   Address1:String(window.sessionStorage.getItem('billAdd1')),
    //   Address2:'',
    //   City:String(window.sessionStorage.getItem('billCity')),
    //   StateProvince:String(window.sessionStorage.getItem('billState')),
    //   PostalCode: String(window.sessionStorage.getItem('billZip')),
    //   Country: "USA",
    //   BillShipSameAddressFlag: "0",
    //   cardToken: request.paymentToken
    // }
    // let CardCheckObj: CardChk = {
    //   CardLastFour: String(request.lastFour),
    //   Amount: cardGridData.Amount,
    //   BillingAddress: undefined,
    //   cardExpiration: String(request.expirationDate),
    //   chargeCard: true,
    //   customerPaymentMethodId: request.paymentToken
    // };
    // setCardGridData(CardCheckObj);

    // const NewCardReq: SaveCardRequestDTO = {
    //   paymentFlag: 0,
    //   clubFlag: 0,
    //   updateFlag: 0,
    //   cardType: String(request.cardType),
    //   cardToken: request.paymentToken,
    //   cardHolderName: String(window.sessionStorage.getItem('billName')),
    //   cardExpiration:String( request.expirationDate),
    //   storeId: String(window.sessionStorage.getItem('storeNumber')),
    //   transactionAmount: "0.00",
    //   cardNumberLastFour: String(request.lastFour),
    //   customerId: Number(customerId),
    //   PaymentID: String(request.paymentToken),
    //   Amount: 0,
    //   SalesTax: 0,
    //   CustomerInfo:customerInfoParam,
    //   BillToAddress: BillInfo,
    //   agreementIds: [],
    // };
    // /* eslint-disable no-console */
    // console.log("Hii I am in save new card details");
    // let savecardres = await Savecustomercard(NewCardReq);
    /* eslint-disable no-console */
    // console.log("savecard response------->", savecardres);
    // if(savecardres.status === 200 && savecardres.data !== ''){
    //   pageload();
    // }
    setPopupfields(newcardinformation);
    setdefaultVal("0");
  };

  const handleVantivErrorResponse = (responseCode: string | number) => {
    const isCreditCardNumberError = ["871", "872", "873", "874", "876"].some(
      (errorCode) => errorCode === responseCode
    );
    const isCVVError = ["881", "882", 883].some(
      (errorCode) => errorCode === responseCode
    );
    if (isCreditCardNumberError) {
      setVantivError(
        "Please check and re-enter your credit card number and try again."
      );
    } else if (isCVVError) {
      setVantivError(
        "Please check and re-enter your card validation number and try again."
      );
    } else if (responseCode === "884") {
      setVantivError("884");
    } else {
      setVantivError(
        "We are experiencing technical difficulties. Please try again"
      );
    }
  };
  //vantiv func end
  const editcardOnchange = (e: any) => {
    // debugger;
    let TxtValue = e.value;
    let TxtName = e.name;
    let fieldtype;

    if (TxtName === "city") {
      fieldtype = /^[\sa-zA-Z]*$/;
    }
    if (TxtName === "name") {
      //eslint-disable-next-line
      fieldtype = /^[a-zA-Z ]*$/;
    }
    if (TxtName === "zip_code") {
      fieldtype = new RegExp("^[0-9 ]*$");
    }
    console.log("EditTxtvalue", TxtValue);
    console.log("name", e.name);

    if (TxtName === "address_line_1") {
      setEditCard({ ...EditCard, address_1: TxtValue });
      if (EditCard.address_1 !== "") {
        setaddressval1(false);
      }
    } else if (TxtName === "address_line2") {
      setEditCard({ ...EditCard, address_2: TxtValue });
    } else if (TxtName === "State") {
      setEditCard({ ...EditCard, state: TxtValue });
      if (EditCard.state !== "0") {
        setstateVal(false);
      }
    }
    const temp = fieldtype.test(TxtValue);
    if (temp) {
      if (TxtName === "zip_code") {
        setEditCard({ ...EditCard, zip: TxtValue });
        if (EditCard.zip !== "") {
          setZipVal(false);
        }
      } else if (TxtName === "name") {
        let str = TxtValue;
        str = str.replace(/^\s+/, "");
        setEditCard({ ...EditCard, Cardname: str });
        if (EditCard.Cardname !== "") {
          setnameVal(false);
        }
      } else if (TxtName === "city") {
        setEditCard({ ...EditCard, city: TxtValue });
        if (EditCard.city !== "") {
          setcityVal(false);
        }
      }
    }
  };

  const stateOnchange = (e: any) => {
    let stateVal = e.value;
    console.log("selectedState", stateVal);
    setEditCard({ ...EditCard, state: stateVal });
    setstateVal(false);
  };

  const newcard = () => {
    // setCardrequire({ ...Cardrequire, newCard: true });
    setshowChargeCard(false);
    setshowAddCard(true);
    setPopupfields(newcardinformation);
    setdefaultVal("0");
    setChargeCardFlag(true); //AddNew Card pre-fill
  };

  const chargeCard = () => {
    // setCardrequire({ ...Cardrequire, newCard: true });
    // setcardtxtDisable(true);
    setshowChargeCard(true);
    setshowAddCard(false);
    setaddNewCard(true);
    setpaymentOriginDisable(true);
    setPopupfields(newcardinformation);
    setdefaultVal("0");
    setChargeCardFlag(true); //AddNew Card pre-fill
  };

  const edit = (index: any) => {
    setCardrequire({ ...Cardrequire, editcrd: true });
    seteditcardindex(index);
    setEditCard({
      ...EditCard,
      address_1:
        getCardService_state.cardResp[index].billingAddressDetails.addressLine1,
      address_2:
        getCardService_state.cardResp[index].billingAddressDetails.addressLine2,
      Cardname: getCardService_state.cardResp[index].cardHolderName,
      city: getCardService_state.cardResp[index].billingAddressDetails.city,
      zip: getCardService_state.cardResp[index].billingAddressDetails.zip,
      state: getCardService_state.cardResp[index].billingAddressDetails.state,
      cardExpDate: getCardService_state.cardResp[index].cardExpiration,
    });
  };

  //syed azim code for payment history and club payment

  const paymentHistoryClose = (res: any) => {
    setPaymentHistory(false);
    setClubPaymentHistory(true);
  };

  const clubpaymentHistoryClose = (res: any) => {
    setClubPaymentHistory(false);
    setPaymentHistory(true);
  };

  //Close functions of the all card module popup defines here
  const close = () => {
    setCardrequire({
      ...Cardrequire,
      swipcard: false,
      newCard: false,
      editcrd: false,
      delt: false,
      tran: false,
      txt2pay: false,
      drpdown: false,
      APDeletePopup: false,
    });

    // setActivateClub(false);
    // setDeActivateClub(false);
    setTerminateLDW(false);
    setActiveLDW(false);
    setActivepolicy(false);
    setDeActivepolicy(false);
    setshowAddCard(false);
    setnameVal(false);
    setaddressval1(false);
    setcityVal(false);
    setstateVal(false);
    setZipVal(false);
  };

  const AddCardClose = () => {
    //ChargeCard details pre-fill flag
    setChargeCardFlag(false);
    setshowAddCard(false);
    setdefaultVal("0");
    setPopupfields(newcardinformation);
    setAddress_line1_validation(false);
    setName_validation(false);
    setVantivIframe(undefined);
    setState_validation(false);
    setZip_code_validation(false);
    setCityValidation(false);
  };
  const ChargeCardClose = () => {
    // setcardtxtDisable(false);
    //ChargeCard details pre-fill flag
    setChargeCardFlag(false);
    setshowChargeCard(false);
    setaddNewCard(false);
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    setremainingamountdue(
      (Number(RemaniningAmtStatic) - Number(resTotalAmount)).toFixed(2)
    );
    setchargeCardAmt("0.00");
    setVantivIframe(undefined);
    setCardGridData(CardGrid);
    setpaymentOriginDisable(false);
    setcvvTextValue("");
    setswipeFlag(false);
  };
  const del = () => {
    setCardrequire({ ...Cardrequire, delt: true, editcrd: false });
    seteditcardnumberbind(
      getCardService_state.cardResp[editcardindex].cardLastFour
    );
  };

  //this useeffect id for autocheck
  useEffect(() => {
    if (acceptPaymentParam.length > 0) {
      SetAcceptPayments(acceptPaymentParam);
    }
    console.log(
      AcceptclubPaymentParam,
      ReceiptCategoriesRequestParam,
      "club auto checked details"
    );
    if (
      AcceptclubPaymentParam !== undefined &&
      AcceptclubPaymentParam !== null
    ) {
      setAcceptClub(AcceptclubPaymentParam);
    } else {
      setAcceptClub(undefined);
    }

    if (ReceiptCategoriesRequestParam.length > 0) {
      setReceiptCategory(ReceiptCategoriesRequestParam);
    }
  });

  const YesforDelete = async () => {
    let cardReset = false;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (tendertypeglobalarray[i].tendertype.includes("Card ending")) {
        cardReset = true;
        break;
      }
    }
    if (cardReset) {
      let objRemainAmount = 0;
      let chargeCardAmount = 0;
      let swipeCardAmount = 0;
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Ending with")
        ) {
          chargeCardAmount =
            chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].tendertype
            .split(" - ")[0]
            .includes("Card Swipe ending")
        ) {
          swipeCardAmount =
            swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
        }
      }
      chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
      let cardamount = Number(
        multipleCard.length == 0
          ? 0.0
          : multipleCard
              .map((bill) => bill.Amount)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
      );
      cardamount =
        Number(cardamount) + Number(chargeCardAmount) + Number(swipeCardAmount);
      let totalOtherTenderAmt =
        Number(addcash) + Number(totalamountcheck) + Number(cardamount);
      objRemainAmount =
        Number(Number(RemaniningAmtStatic).toFixed(2)) -
        Number(Number(totalOtherTenderAmt).toFixed(2));
      console.log(
        "amounts when changing a card",
        totalOtherTenderAmt,
        objRemainAmount,
        remainingamountdue,
        change
      );
      setCardrequire({ ...Cardrequire, chkcvv: "0.00" });
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (tendertypeglobalarray[i].tendertype.includes("Card ending")) {
          tendertypeglobalarray.splice(i, 1);
          break;
        }
      }
      settotalduetendertype(tendertypeglobalarray);
      let CardCheckObj: CardChk = {
        CardLastFour: String(cardGridData.CardLastFour),
        Amount: "0.00",
        BillingAddress: cardGridData.BillingAddress,
        cardExpiration: undefined,
        customerPaymentMethodId: undefined,
      };
      setCardGridData(CardCheckObj);
      if (objRemainAmount > RemaniningAmtStatic) {
        setremainingamountdue(RemaniningAmtStatic);
        setAcceptpaymentbuttonenable(true);
      } else if (objRemainAmount < 0) {
        let newRemainAmt =
          Number(Number(RemaniningAmtStatic).toFixed(2)) -
          (Number(addcash) + Number(totalamountcheck));
        if (newRemainAmt > 0) {
          setchange(0.0);
          setremainingamountdue(newRemainAmt);
          setAcceptpaymentbuttonenable(true);
        } else if (newRemainAmt == 0) {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        } else if (newRemainAmt < 0) {
          setchange(-1 * newRemainAmt);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        }
      } else if (objRemainAmount == 0) {
        // setchange(0.00);
        // setremainingamountdue(0.00);
        let isChecked = 0;
        for (let i = 0; i < document.getElementsByName("grid").length; i++) {
          console.log("entered into the for loop of loadother action");
          if (
            (document.getElementsByName("grid")[i] as HTMLInputElement)
              .checked == true
          ) {
            isChecked = 1;
          }
        }
        if (isChecked == 1) {
          let newRemainAmt =
            Number(Number(RemaniningAmtStatic).toFixed(2)) -
            (Number(addcash) + Number(totalamountcheck));
          if (newRemainAmt > 0) {
            setchange(0.0);
            setremainingamountdue(newRemainAmt);
            setAcceptpaymentbuttonenable(true);
          } else if (newRemainAmt == 0) {
            setchange(0.0);
            setremainingamountdue(0.0);
            setAcceptpaymentbuttonenable(false);
          } else if (newRemainAmt < 0) {
            setchange(-1 * newRemainAmt);
            setremainingamountdue(0.0);
            setAcceptpaymentbuttonenable(false);
          }
        } else {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(true);
        }
      } else {
        let newRemainAmt =
          Number(Number(RemaniningAmtStatic).toFixed(2)) -
          (Number(addcash) + Number(totalamountcheck));
        if (newRemainAmt > 0) {
          setchange(0.0);
          setremainingamountdue(newRemainAmt);
        } else if (newRemainAmt == 0) {
          setchange(0.0);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        } else if (newRemainAmt < 0) {
          setchange(-1 * newRemainAmt);
          setremainingamountdue(0.0);
          setAcceptpaymentbuttonenable(false);
        }
      }
      //const FloatInputTxttofixed = cardGridData.Amount;
      // const tendertypedetails = {
      //   tendertype: cardlastFour,
      //   Amount: FloatInputTxttofixed,
      // };

      //setremainingamountdue(objRemainAmount);
      setcvvTextValue("");
      setCvv("");
    }
    if (getCardService_state.cardResp[editcardindex].apFlag === 1) {
      setCardrequire({
        ...Cardrequire,
        delt: false,
        editcrd: false,
        APDeletePopup: true,
      });
    } else {
      setCardrequire({ ...Cardrequire, delt: false, editcrd: false });
      setdeleteCardPopUp(true)
      const DeleteResp = await Deletecustomercard(
        getCardService_state.cardResp[editcardindex].customerPaymentMethodId,
        customerId
      );
      if (DeleteResp?.status === 200 && DeleteResp!= undefined && DeleteResp?.data?.customerId === String(customerId)) {
        getCardAfterUpdate();
        setdeleteCardPopUp(false)
        setdeleteCardSuccess(true)
      } else{
        setdeleteCardPopUp(false)
        setdeleteCardFail(true)
      }
    }
  };


  const Enroll_AP_Card = async () => {
    setCardrequire({ ...Cardrequire, APDeletePopup: false });
    /* eslint-disable no-console */
    console.log("getCardService_state----->", getCardService_state);
    /* eslint-disable no-console */
    console.log("editcardindex", editcardindex);
    /* eslint-disable no-console */
    console.log(
      "getCardService_state.cardResp[editcardindex]",
      getCardService_state.cardResp[editcardindex]
    );
    /* eslint-disable no-console */
    console.log(
      "DeleteCard request----->",
      getCardService_state.cardResp[editcardindex].customerPaymentMethodId
    );
    const DeleteResp = await Deletecustomercard(
      getCardService_state.cardResp[editcardindex].customerPaymentMethodId,
      customerId
    );
    console.log("DeleteResp", DeleteResp);
    if (DeleteResp.customerId === String(customerId)) {
      getCardAfterUpdate();
    }
  };

  const Amtonblurclick = (e: any) => {
    let regexValidate: string = String(e.target.value).replace(",", "");
    let onlyinputtext: string = String(regexValidate).replace(".", "");
    let regexExp = new RegExp("[0-9]");
    if (!onlyinputtext.match(regexExp) && onlyinputtext != "") {
      setswipeDeviceValue(swipeDeviceValue);
    } else {
      let InputTxt = e.target.value;
      let txtArr = InputTxt.split("");
      if (txtArr.includes(",") === true) {
        txtArr = txtArr.filter((element: any) => element !== ",");
        let Tempvar = "";
        for (let i = 0; i <= txtArr.length - 1; i++) {
          Tempvar = Tempvar + txtArr[i];
        }
        const FloatVar = parseFloat(Tempvar);
        const DecimalVal = fixedChange(String(FloatVar));
        setswipeDeviceValue(DecimalVal);
      } else if (InputTxt === "" || InputTxt === ".") {
        setswipeDeviceValue("0.00");
      } else {
        InputTxt = String(InputTxt).replace(",", "");
        const DecimalInputTxt = parseFloat(InputTxt);
        const FloatTxt = fixedChange(String(DecimalInputTxt));
        setswipeDeviceValue(FloatTxt);
      }
    }
  };

  const totalamount = (value: any) => {
    // let regexValidate: string = String(value).replace(",", "");
    // let onlyinputtext: string = String(regexValidate).replace(".", "");
    // let regexExp = new RegExp("[0-9]");
    // if (!onlyinputtext.match(regexExp) && onlyinputtext != "") {
    //   setswipeDeviceValue(swipeDeviceValue);
    // }
    // else {
    value = value.replace(",", "");
    if (validateCashInput(value)) {
      setswipeDeviceValue(String(value));

      setValidator({ ...Validator, SwipeAmtValidationtxtbox: false });
      if (
        value === "0" ||
        value === undefined ||
        value === null ||
        value === ""
      ) {
        setCardrequire({ ...Cardrequire, Proceedbtn: true });
      } else {
        setCardrequire({ ...Cardrequire, Proceedbtn: false });
      }
    }
    //}
  };

  const totalChargeAmount = (value: any) => {
    // let regexValidate: string = String(value).replace(",", "");
    // let onlyinputtext: string = String(regexValidate).replace(".", "");
    // let regexExp = new RegExp("[0-9]");
    // if (!onlyinputtext.match(regexExp) && onlyinputtext != "") {
    //   setswipeDeviceValue(swipeDeviceValue);
    // }
    // else {
    const cardAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    value = value.replace(",", "");
    if (validateCashInput(value)) {
      // let CardCheckObj: CardChk = {
      //   CardLastFour: undefined,
      //   Amount: chargeCardAmt,
      //   BillingAddress: undefined,
      //   cardExpiration: undefined,
      //   chargeCard: true,
      //   customerPaymentMethodId: undefined
      // };
      // setCardGridData(CardCheckObj);
      const objRemainAmt =
        RemaniningAmtStatic - parseFloat(String(value)) - Number(cardAmount);
      let isChecked = 0;
      for (let i = 0; i < document.getElementsByName("grid").length; i++) {
        console.log("entered into the for loop of loadother action");
        setValidator({ ...Validator, chargeCardValidation: false });
        if (
          (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
          true
        ) {
          isChecked = 1;
        }
      }
      if (Number(Number(objRemainAmt).toFixed(2)) == 0 && isChecked == 1) {
        console.log("charge amt", objRemainAmt);
        setremainingamountdue(Number(objRemainAmt.toFixed(2)));
        setchargeCardContinue(false); //change this
      } else if (Number(Number(objRemainAmt).toFixed(2)) > 0) {
        setValidator({ ...Validator, chargeCardValidation: false });
        console.log("(swipeamt) = RemaniningAmtStatic", objRemainAmt);
        if (Validator.chargeCardValidation) {
          setchargeCardContinue(true);
        } else {
          setchargeCardContinue(false);
        }

        setremainingamountdue(Number(objRemainAmt.toFixed(2))); //change this
      }
      if (Number(Number(objRemainAmt).toFixed(2)) < 0) {
        setValidator({ ...Validator, chargeCardValidation: true });
        setchargeCardContinue(true); //change this
      }
      setchargeCardAmt(Number(value).toFixed(2));
      chargeCardValue = Number(Number(value).toFixed(2));
      setchange("0.00");
    }
    //}
  };

  const validateCashInput = (inputText: string) => {
    let cashvalue = inputText.split(".");
    let validData = false;
    if (
      cashvalue.length === 2 &&
      cashvalue[0].length < 6 &&
      cashvalue[1].length <= 2
    ) {
      validData = true;
    } else if (cashvalue.length === 1 && cashvalue[0].length < 6) {
      validData = true;
    }

    return validData;
  };

  const Spinner = async (e: any) => {
    //let dummy;
    if (
      Number(Number(swipeDeviceValue).toFixed(2)) >
      Number(Number(remainingamountdue).toFixed(2))
    ) {
      setValidator({ ...Validator, SwipeAmtValidationtxtbox: true });
    } else {
      console.log("TerminalId in swipe payload -->", TerminalId);
      const currentDate = new Date()
        .toJSON()
        .replace("T", "-")
        .replace("Z", "");
      const PaymentInfo: PaymentInfo = {
        TerminalID: String(TerminalId),
        Amount: String(swipeDeviceValue),
        PaymentDate:
          currentDate.split(".")[0] + ":000" + currentDate.split(".")[1],
        PostalCode: String(getpayment?.paymentInfo.customerInfo[0].zip),
        PaymentType: "2",
        TransactionType: "2",
        RegisterNumber: registerNumber
      };
      const SwipeCardRequestDTO = {
        PaymentInfo: PaymentInfo,
        storeNumber: window.sessionStorage.getItem("storeNumber"),
        userId:String(empId),
        clientId:"RACPAD",
        customerId: Number(customerId),
        name: customerName,
        CloudSwipeCustomerCard:
          configFlags?.isCloudSwipeCustomerCard == 1 ? "Y" : "N",
      };
      setCardrequire({ ...Cardrequire, swipcard: false });
      setload(true);
      swiperes = await Swipecustomercard(SwipeCardRequestDTO);
      setload(false);
      console.log("swiperes", swiperes);

     
        if (
          swiperes === undefined ||
          (swiperes.status !== 200 && swiperes !== 403)
        ) {
          settxtChange(false);
          setTranFail(true);
          setswipeamt("0.00");
        } else if (swiperes.status === 200) {
          let swipeStoredAmt = 0.0;
          for (let i = 0; i <= cardAmt.length - 1; i++) {
            swipeStoredAmt = swipeStoredAmt + cardAmt[i].amount;
          }
          swipeAmt = Number(swipeStoredAmt) + Number(swipeDeviceValue);
          let swipeArray = {
            amount: Number(swipeDeviceValue),
          };
          swipeCardAmt.push(swipeArray);
          setCardAmt(swipeCardAmt);
          setswipeamt(String(Number(swipeAmt)));
          let lastfourNum =
            "Card Swipe ending - " + String(swiperes.data.cardLastFour);
          console.log("swipe amount after succeding", swipeAmt);
          setcardtxtDisable(true);

          setcardAuthNo(swiperes.data.cardAuthNumber);
          window.sessionStorage.setItem(
            "cardAuthNumber",
            swiperes.data.cardAuthNumber
          );
          setcardLastFour(swiperes.data.cardLastFour);
          setCardrequire({ ...Cardrequire, swipcard: false, tran: true }); //transaction completed
          console.log("swipe amount if out", swipeDeviceValue);

          //multi-swipe card changes
          let cardDetail = {
            cardAmount: Number(swipeDeviceValue),
            cardAuthNumber: swiperes.data.cardAuthNumber,
            cardLastFour: swiperes.data.cardLastFour,
            cardToken: swiperes.data.cardToken,
            cardSequence: swiperes.data.cardRefNumber,
            cardTransactionType: "SWP",
          };
          swipeCardDetails.push(cardDetail);
          setcardDetails(swipeCardDetails);
          if (
            String(swipeDeviceValue) !== "" &&
            parseFloat(String(swipeDeviceValue)) !== 0.0
          ) {
            console.log("swipe amount if in", swipeamt);
            const FloatInputTxttofixed = fixedChange(swipeDeviceValue);
            const tendertypedetails = {
              tendertype: lastfourNum,
              Amount: FloatInputTxttofixed,
              paymentMethodID: String(swiperes.data.cardToken),
            };

            tendertypeglobalarray.push(tendertypedetails);
            settotalduetendertype(tendertypeglobalarray);
          } else {
            for (let i = 0; i < tendertypeglobalarray.length; i++) {
              if (
                tendertypeglobalarray[i].paymentMethodID ===
                String(swiperes.data.cardToken)
              ) {
                tendertypeglobalarray.splice(i, 1);
                break;
              }
            }
            settotalduetendertype(tendertypeglobalarray);
          }
          const resTotalAmount =
            tendertypeglobalarray.length == 0
              ? 0.0
              : tendertypeglobalarray
                  .map((bill) => bill.Amount)
                  .reduce((acc, bill) =>
                    (Number(bill) + Number(acc)).toFixed(2)
                  );
          if (
            Number(Number(swipeDeviceValue).toFixed(2)) ==
            Number(Number(remainingamountdue).toFixed(2))
          ) {
            const objRemainAmt =
              Number(totaldue) -
              Number(resTotalAmount) -
              Number(availableCreds);
            // RemaniningAmtStatic -
            // (parseFloat(String(swipeAmt)) +
            //   parseFloat(addcash) +
            //   totalamountcheck);
            console.log("(swipeamt) = RemaniningAmtStatic", objRemainAmt);
            setremainingamountdue(Number(objRemainAmt.toFixed(2)));
            // setRemaniningAmtStatic(Number(objRemainAmt.toFixed(2)));
            setAcceptpaymentbuttonenable(false);
          } else if (
            Number(Number(swipeDeviceValue).toFixed(2)) <
            Number(Number(remainingamountdue).toFixed(2))
          ) {
            const objRemainAmt =
              Number(totaldue) -
              Number(resTotalAmount) -
              Number(availableCreds);
            // RemaniningAmtStatic -
            // (parseFloat(String(swipeAmt)) +
            //   parseFloat(addcash) +
            //   totalamountcheck);
            console.log("(swipeamt) = RemaniningAmtStatic", objRemainAmt);
            setremainingamountdue(Number(objRemainAmt.toFixed(2)));
            // setRemaniningAmtStatic(Number(objRemainAmt.toFixed(2)));
          }

          let CardCheckObj: CardChk = {
            CardLastFour: undefined,
            Amount: Number(String(swipeAmt)).toFixed(2),
            BillingAddress: undefined,
            cardExpiration: undefined,
          };
          console.log(CardCheckObj, "cardGrid");
          setswipeFlag(true);

          // CardCheckObj.BillingAddress != undefined ? CardCheckObj.BillingAddress.cardToken = cardToken : cardToken;
          setCardGridData(CardCheckObj);
          console.log(CardCheckObj, "cardGrid set swipe");
          FormAcceptPaymentData(0, 0);
        } else if (swiperes.status === 403) {
          setTranFail(true);
          settxtChange(true);
          setswipeamt("0.00");
        }
    }
  };

  const okClickUnavailable = () => {
    setTranFail(false);
    setCardrequire({ ...Cardrequire, swipcard: false });
  };

  const unAvailable = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {txtChange === false ? (
            <Typography className={classes.racErrorFunction}>
              Swipe Transaction has Failed
            </Typography>
          ) : (
            <Typography className={classes.racErrorFunction}>
              Swipe Transaction has Timeout
            </Typography>
          )}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={okClickUnavailable}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const spinnerpopup = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={load} size={40} />
          <Typography variant="h4" className={classes.mt3}>
            Transaction In Progress
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const initialnewcardstate = (e: any) => {
    let fieldtype;
    console.log(e, "Card Agr");
    if (e.name === "City") {
      //eslint-disable-next-line
      fieldtype = /^[\sa-zA-Z]*$/;
    }
    if (e.name === "name") {
      //eslint-disable-next-line
      fieldtype = /^[a-zA-Z ]*$/;
    } else if (e.name === "zip_code") {
      fieldtype = new RegExp("^[0-9 ]*$");
    }

    //if (fieldtype.test(e.value)) {
    let inputtxt = e.value;
    let FieldName = e.name;
    let temp = false;
    if (FieldName === "name" || FieldName === "City")
      temp = fieldtype.test(inputtxt);
    if (temp && FieldName === "name") {
      if (inputtxt !== "") {
        console.log("name conditon");
        setName_validation(false);
      }
      let str = inputtxt;
      str = str.replace(/^\s+/, "");
      setPopupfields({ ...Popupfields, name: str });
      cardSaveDetail = Popupfields;
      window.sessionStorage.setItem("billName", String(inputtxt));
      console.log(Popupfields, "name field");
    } else if (FieldName === "state") {
      if (inputtxt === "0") {
        console.log("state conditon");
        setState_validation(false);
      }
      setPopupfields({ ...Popupfields, state: inputtxt });
      cardSaveDetail = Popupfields;
      window.sessionStorage.setItem("billState", String(inputtxt));
      console.log(Popupfields, "state field");
    } else if (FieldName === "zip_code") {
      if (inputtxt !== "") {
        console.log("zip conditon");
        setZip_code_validation(false);
      }
      setPopupfields({ ...Popupfields, zip_code: inputtxt });
      window.sessionStorage.setItem("billZip", String(inputtxt));
      cardSaveDetail = Popupfields;
      console.log(Popupfields, "zip field");
    } else if (temp && FieldName === "City") {
      if (inputtxt !== "") {
        console.log("city conditon");
        setCityValidation(false);
      }
      setPopupfields({ ...Popupfields, city: inputtxt });
      window.sessionStorage.setItem("billCity", String(inputtxt));
      cardSaveDetail = Popupfields;

      console.log(Popupfields, "city field");
    }

    if (e.value == "" && e.name == "name") {
      setPopupfields({ ...Popupfields, name: "" });
      window.sessionStorage.setItem("billName", "");
    } else if (e.value == "" && e.name == "City") {
      setPopupfields({ ...Popupfields, city: "" });
      window.sessionStorage.setItem("billCity", "");
    }

    if (FieldName === "address_line_1") {
      if (inputtxt !== "") {
        setAddress_line1_validation(false);
      }
      setPopupfields({ ...Popupfields, address_line_1: inputtxt });
      cardSaveDetail = Popupfields;
      window.sessionStorage.setItem("billAdd1", String(inputtxt));
      console.log(Popupfields, "adress1 field");
    } else if (FieldName === "address_line2") {
      window.sessionStorage.setItem("billAdd2", String(inputtxt));
      setPopupfields({ ...Popupfields, address_line2: inputtxt });
      cardSaveDetail = Popupfields;
      console.log(Popupfields, "adress2 field");
    }
  };

  const chkfunc = (
    index: any,
    value: any,
    name: any,
    lastFour: any,
    billingDetails: any,
    expireDate: any,
    cardToken: any,
    customerPaymentMethodId: any
  ) => {
    if (name === "chkAmt") {
      //console.log()
      // debugger;
      let CardCheckObj: CardChk = {
        CardLastFour: String(lastFour),
        Amount: value,
        BillingAddress: billingDetails,
        cardExpiration: expireDate,
        customerPaymentMethodId: customerPaymentMethodId,
      };

      CardCheckObj.BillingAddress != undefined
        ? (CardCheckObj.BillingAddress.cardToken = cardToken)
        : cardToken;

      multipleCard[index].Amount = value == undefined ? "0.00" : value;
      setCardGridData(CardCheckObj);
      let enablepayment = true;
      for (let i = 0; i < multipleCard.length; i++) {
        if (
          Number(multipleCard[i].Amount > 0) &&
          multipleCard[i].cvvEncrypt.length < 3
        ) {
          enablepayment = false;
        }
      }
      if (
        Number(remainingamountdue) <= 0 &&
        selectedCheckBoxCount != 0 &&
        CardErrorLbl == false &&
        enablepayment
      ) {
        setAcceptpaymentbuttonenable(false);
      }
    } else {
      if (name === "chkcvv") {
        setCardrequire({ ...Cardrequire, chkcvv: value });
      }
    }
  };

  const cardTextonblur = (e: any, index: any) => {
    // debugger;
    multipleCard[index].Amount = Number(multipleCard[index].Amount).toFixed(2);
  };
  let cardArray: any = [];
  const Amtonclickbtn2ndbox = (
    e: any,
    lastFour: any,
    billingDetails: any,
    index: any,
    expireDate: any,
    cardToken: any,
    cardType: any,
    customerPaymentMethodId: string
  ) => {
    // debugger;
    // if(isNaN(e.target.value)==false && (e.target.value).split(".")[1].split(/(?!$)/u).length==2){
    //   // if(){
    //     multipleCard[index].Amount= e.target.value.trim()==""?"0.00":e.target.value
    //   // }

    // }

    let lastfourNum = "Card ending - " + String(lastFour);
    let InputTxt = e.target.value;
    let txtArr = InputTxt.split("");
    let CashAmt =
      cashamountcheckbox !== undefined && cashamountcheckbox !== null
        ? cashamountcheckbox
        : 0;
    let objChqMOAmt = 0;
    if (adddataanother != undefined && adddataanother.length > 0) {
      objChqMOAmt = adddataanother.reduce(
        (sumVal, a) => sumVal + parseFloat(a.Amount_rac),
        0
      );
    }

    console.log("InputTxt", parseFloat(InputTxt));
    console.log("Chq&MO", objChqMOAmt);
    console.log("CashAmtchk", parseFloat(CashAmt));
    console.log("RemaniningAmtStatic", RemaniningAmtStatic);
    let enableAcc = true;
    let FloatVar: any;
    let DecimalVal: any;
    if (txtArr.includes(",") === true) {
      txtArr = txtArr.filter((element: any) => element !== ",");
      let Tempvar = "";
      for (let i = 0; i <= txtArr.length - 1; i++) {
        Tempvar = Tempvar + txtArr[i];
      }
      FloatVar = parseFloat(Tempvar);
      DecimalVal = fixedChange(FloatVar);
      setgettotamt(DecimalVal);
    } else if (
      InputTxt === "" ||
      InputTxt === "." ||
      InputTxt === "0" ||
      InputTxt === undefined ||
      InputTxt === null
    ) {
      setgettotamt("0.00");
    } else {
      FloatVar = parseFloat(InputTxt);
      DecimalVal = fixedChange(FloatVar);
      setgettotamt(DecimalVal);
    }

    const FloatInputTxttofixed = DecimalVal;
    const tendertypedetails = {
      tendertype: lastfourNum,
      Amount: FloatInputTxttofixed,
      paymentMethodID: String(customerPaymentMethodId),
    };
    //debugger;
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);

    console.log(
      InputTxt,
      Number(Number(RemaniningAmtStatic).toFixed(2)),
      "card amt check"
    );
    let cardamount = multipleCard
      .map((bill) => bill.Amount)
      .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let sumOfOtherTenderAmount =
      Number(addcash) +
      Number(totalamountcheck) +
      Number(cardamount) +
      Number(availableCreds) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount) -
      Number(addSusTotalAmount) +
      Number(usedSusTotalAmount);
    let totalOtherTenderAmt =
      Number(addcash) +
      Number(totalamountcheck) +
      Number(chargeCardAmount) +
      Number(swipeCardAmount);
    let remAmtDueWithOutTenders =
      Number(Number(totaldue).toFixed(2)) -
      Number(sumOfOtherTenderAmount.toFixed(2));
    console.log(
      remAmtDueWithOutTenders,
      sumOfOtherTenderAmount,
      Number(Number(totaldue).toFixed(2)),
      "remaing amount with all tender types"
    );
    //let objRemainAmt = Number(Number(RemaniningAmtStatic).toFixed(2)) - (parseFloat(InputTxt) + parseFloat(CashAmt) + objChqMOAmt);
    let isChecked = 0;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      console.log("entered into the for loop of loadother action");
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ==
        true
      ) {
        isChecked = 1;
      }
    }
    console.log("previous amt", previousAmt);
    if (
      parseFloat(InputTxt) === 0 ||
      InputTxt === undefined ||
      InputTxt === null ||
      InputTxt === "" ||
      InputTxt === "NaN"
    ) {
      console.log("Entered into 0th scenario");
      if (paymentOrigin !== "Pay By Phone") {
        setswipeDisable(false);
      }
      setremainingamountdue(Number(Number(remAmtDueWithOutTenders).toFixed(2)));
      // setchange(Number(resTotalAmount)-Number(totaldue)-Number(availableCreds))
      // );
      let amountDueWhenNoCard = Number(
        (Number(RemaniningAmtStatic) - Number(totalOtherTenderAmt)).toFixed(2)
      );
      if (
        amountDueWhenNoCard == 0 &&
        isChecked == 1 &&
        totalOtherTenderAmt > 0
      ) {
        setAcceptpaymentbuttonenable(false);
      } else {
        setAcceptpaymentbuttonenable(true);
      }
      enableAcc = false;
      setCardErrorLbl(false);
    } else if (remAmtDueWithOutTenders > 0) {
      enableAcc = false;
      setCardErrorLbl(false);
      setremainingamountdue(Number(Number(remAmtDueWithOutTenders).toFixed(2)));
      // setchange(Number(resTotalAmount)-Number(totaldue)-Number(availableCreds))
      if (Number(Number(remAmtDueWithOutTenders).toFixed(2)) > 0) {
        setAcceptpaymentbuttonenable(true);
      }
      // if (paymentOrigin !== "Pay By Phone") {
      //   setswipeDisable(true);
      // }
    } else if (remAmtDueWithOutTenders < 0) {
      console.log(remAmtDueWithOutTenders, "went into conditon");
      enableAcc = true;
      setCardErrorLbl(true);
      setAcceptpaymentbuttonenable(true);
      // if (paymentOrigin !== "Pay By Phone") {
      //   setswipeDisable(true);
      // }
    } else if (remAmtDueWithOutTenders == 0 && isChecked == 1) {
      setremainingamountdue(Number(Number(remAmtDueWithOutTenders).toFixed(2)));

      if (cvvTextValue !== "" && cvvTextValue.length >= 3) {
        setAcceptpaymentbuttonenable(false);
      }
      enableAcc = false;
      setCardErrorLbl(false);
      // if (paymentOrigin !== "Pay By Phone") {
      //   setswipeDisable(true);
      // }
    }
    let cardDetail = {
      cardAmount: Number(DecimalVal),
      cardAuthNumber: "",
      cardLastFour: lastFour,
      cardType: cardType,
      cardToken: cardToken,
      cardTransactionType: "PAYTOK",
    };

    // debugger;

    if (
      e.target.value !== "" &&
      parseFloat(e.target.value) !== 0.0 &&
      enableAcc != true
    ) {
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].paymentMethodID ===
          String(customerPaymentMethodId)
        ) {
          tendertypeglobalarray.splice(i, 1);
          cardArray.splice(i, 1);
          break;
        }
      }
      // cardArray.push(cardDetail);
      tendertypeglobalarray.push(tendertypedetails);
      settotalduetendertype(tendertypeglobalarray);
      // setcardDetails(cardArray);
    } else {
      for (let i = 0; i < tendertypeglobalarray.length; i++) {
        if (
          tendertypeglobalarray[i].paymentMethodID ===
          String(customerPaymentMethodId)
        ) {
          tendertypeglobalarray.splice(i, 1);
          cardArray.splice(i, 1);
          break;
        }
      }
      settotalduetendertype(tendertypeglobalarray);
      // setcardDetails(cardArray);
    }
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    // if(Number(resTotalAmount)-Number(totaldue)-Number(availableCreds)<0){
    //   setchange(0.00)
    // }else{
    //   setchange(Number(resTotalAmount)-Number(totaldue)-Number(availableCreds))
    // }
    resTotalAmount = resTotalAmount + Number(chargeCardAmt);
    let CardCheckObj: CardChk = {
      CardLastFour: String(lastFour),
      Amount: DecimalVal,
      BillingAddress: billingDetails,
      cardExpiration: expireDate,
      customerPaymentMethodId: customerPaymentMethodId,
    };
    CardCheckObj.BillingAddress != undefined
      ? (CardCheckObj.BillingAddress.cardToken = cardToken)
      : cardToken;
    setCardGridData(CardCheckObj);

    setgettotamt("0.00");
    previousAmt = Number(DecimalVal);
    FormAcceptPaymentData(0, 0);
    let enablepayment = true;
    let cvvCount = 0;
    for (let i = 0; i < multipleCard.length; i++) {
      if (
        Number(multipleCard[i].Amount > 0) &&
        multipleCard[i].cvvEncrypt.length < 3
      ) {
        enablepayment = false;
        // setcvvTextValue("")
        cvvCount++;
      }
    }
    if (cvvCount > 0) {
      setcvvTextValue("");
    } else {
      setcvvTextValue("***");
    }
    if (
      Number(remAmtDueWithOutTenders) <= 0 &&
      selectedCheckBoxCount != 0 &&
      enableAcc == false &&
      enablepayment &&
      Number(chargeCardAmt) == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
    cardDetail;
    cardTextonblur(e, index);
  };

  //text2pay function defines here
  const txt2paybtn = () => {
    if (paygridvalues[0].Phonenumber == "") {
      setphoneNumberRequired(true);
    } else {
      setCardrequire({ ...Cardrequire, txt2pay: true });
    }
  };
  let transactionDate = new Date();

  let amount =
    acceptPayment?.CardInfo == undefined || acceptPayment?.CardInfo == null
      ? 0
      : acceptPayment?.CardInfo.Amount;
  let cardFlag = "0";
  if (amount > 0) {
    cardFlag = "1";
  }

  const yesforTxt2pay = async () => {
    setCardrequire({ ...Cardrequire, txt2pay: false });
    console.log("Payment Amt --->", PaymentAmt);
    console.log("EpoAmt --->", epoAmt);
    if (PaymentAmt >= epoAmt) {
      SacAmt = PaymentAmt;
    }
    let count;
    for (let i = 0; i < document.getElementsByName("grid").length; i++) {
      if (
        (document.getElementsByName("grid")[i] as HTMLInputElement).checked ===
        true
      ) {
        selectedGrid = i;
        count++;
      }
    }
    let transferSuspense: any = [];
    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        Number(paygridvalues[i].SuspenseAmount) !=
        Number(paygridvalues[i].transferOldSuspense)
      ) {
        transferSuspense.push({
          currentBalance: Number(paygridvalues[i].transferOldSuspense).toFixed(
            2
          ),
          newSuspenseBalance: Number(paygridvalues[i].SuspenseAmount).toFixed(
            2
          ),
          agreementId: String(paygridvalues[i].Agreementid),
          isTransferToCoa: paygridvalues[i].isCOATransfered,
        });
      }
    }
    const txt2payReqparam: MakePaymentDTO = {
      transferSuspense: transferSuspense,
      storeMerchantId: storeMerchantId,
      miscellaneousArray: [],
      recieptCategoryReqDetails: ReceiptCategory,
      storeNumber: String(window.sessionStorage.getItem("storeNumber")),
      customerId: String(customerId),
      storeCoWorkerId: String(empId),
      carryAmount: Number(carryRentAmount),
      taxRate: Number(paygridvalues[0].taxRate),
      paymentOriginName: "Store",
      totalAmountCollected: Number(
        (Number(totaldue) + Number(carryRentAmount)).toFixed(2)
      ),
      totalTaxCollected: Number(Number(totaltax).toFixed(2)),
      paymentId: "",
      cardAmount: Number(
        (Number(totaldue) + Number(carryRentAmount)).toFixed(2)
      ),
      cashAmount: 0,
      moneyOrderAmount: 0,
      checkAmount: 0,
      phoneNumber: String(getpayment?.paymentInfo.customerInfo[0].phoneNumber),
      transactionDate: String(transactionDate),
      customerZip:
        getpayment?.paymentInfo.customerInfo[0].zip +
        "-" +
        getpayment?.paymentInfo.customerInfo[0].plus4,
      customerState: String(getpayment?.paymentInfo.customerInfo[0].state),
      customerDOB: String(getpayment?.paymentInfo.customerInfo[0].dob),
      //paymentAmount: Number(Number(totalDueamt).toFixed(2)),
      cardDetails: [],
      documentDetails: [],
      paymentAmount: Number(Number(RemaniningAmtStatic).toFixed(2)),
      cardFlag: cardFlag,
      emailAddress: String(
        getpayment?.paymentInfo.customerInfo[0].emailAddress
      ),
      originFee: "0.00",
      totalAmountDue: Number(
        (Number(totaldue) + Number(carryRentAmount)).toFixed(2)
      ),
      wlDeposit: Number(paygridvalues[0].wlDeposit),
      itSuspence: Number(paygridvalues[0].ipSuspense),
      suspense: 0,
      customerInfo: {
        FirstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
        LastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
      },
      creditOnAccount: Number(paygridvalues[0].COA),
      firstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
      lastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
      address1: String(getpayment?.paymentInfo.customerInfo[0].addressLine1),
      // change: Number( getcardResponse.CashAmount+
      //   getcardResponse.MOAmount+
      //   getcardResponse.ChequeAmount +
      //   0.00) - Number(Number(totalDueamt).toFixed(2)),
      change: 0.0,
      acceptPayment: acceptPaymentParam,
      stateProvince: String(getpayment?.paymentInfo.customerInfo[0].state),
      postalCode: String(getpayment?.paymentInfo.customerInfo[0].zip),
      city: String(getpayment?.paymentInfo.customerInfo[0].city),
      acceptClubPayment: AcceptClub,
    };
    console.log("Text2pay request param -->", txt2payReqparam);
    const Txt2Payresp = await Sendtxt2pay(txt2payReqparam);
    console.log("Txt2Pay Response --->", Txt2Payresp);
    console.log("count of the selected grid in txt2Pay", count);
    // window.location.reload()
  };

  /**
   * This function gets triggered when Payment Origin drop down gets changed.
   * When the drop down value is changed from store to Phone the following should happen
   *  1. A convenience fee of $ 1.99 gets added to the todal due.
   *  2. Selected tenders should be removed.
   *  3. A popup should be shown about the changed total due amount.
   *  4. On clicking "yes" in popup the total due and remaining amount due will be added with the
   *     convenience fee.
   *  5. On clicking "no" the popup should be closed.
   * When the drop down value is change from Phone to Store the following should happen
   *  1. Convenience fee of $ 1.99 should be subtracted from the total due
   *  2. Remaining amount due should be subtracted with $1.99
   * @param e - The drop down control is passed as parameter to this function
   */
  const phone = (e: any) => {
    const SelectedOption = e.target.value;
    const value = parseFloat(convAmtWTax);
    if (SelectedOption === "Pay By Phone") {
      let NewTotalDue = Number(totaldue) + value;
      console.log("TotalAmt", Number(totaldue));
      console.log("Value", value);
      console.log("phoneAmt", NewTotalDue);
      // setcreditDisable(true);
      // setchangeRadio(false);
      // setCreditRadio(true);
      // let payGridObj = paygridvalues.filter((e) => e.Agreementtype != "Club");
      // console.log(payGridObj[0], "not club paygrid value");
      setconvtax(
        Number(
          paygridvalues[0].convTax == undefined ? 0 : paygridvalues[0].convTax
        )
      );
      let objChkMo: CheqAndMO[] = [];
      setaddataanother(objChkMo);
      setcashamountcheckbox(0.0);
      setaddcash(0.0);
      //setAcceptpaymentbuttonenable(false);
      setCardrequire({
        ...Cardrequire,
        drpdown: true,
        PaymentOrgin: SelectedOption,
        Newtotal: NewTotalDue.toFixed(2),
      });

      if (tendertypeglobalarray.length > 0) {
        let cashTenderIndex = tendertypeglobalarray.findIndex(
          (x) => x.tendertype === "Cash"
        );
        if (cashTenderIndex > -1) {
          tendertypeglobalarray.splice(cashTenderIndex, 1);
        }

        let chqTenderIndex = tendertypeglobalarray.findIndex(
          (x) => x.tendertype === "Check & Money Order"
        );
        if (chqTenderIndex > -1) {
          tendertypeglobalarray.splice(chqTenderIndex, 1);
        }
      }
      setchargeCardDisable(false);
      settotalduetendertype(tendertypeglobalarray);
    } else {
      //setaddNewCard(false);
      const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Add" && Number(e.newsuspense) > 0;
      });
      const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
        return e.sustype == "Used" && Number(e.newsuspense) > 0;
      });
      let addSusTotalAmount =
        addSuspenseAmount.length == 0
          ? 0.0
          : addSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
      let usedSusTotalAmount =
        useSuspenseAmount.length == 0
          ? 0.0
          : useSuspenseAmount
              .map((bill) => bill.newsuspense)
              .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

      setswipeDisable(previousState);
      setCardrequire({ ...Cardrequire, PaymentOrgin: SelectedOption });
      setmobtn(false);
      setcashbtn(false);
      setconvtax(0);
      let TotalRemAmt = Number(totaldue) - value;
      let remAmtStat =
        TotalRemAmt -
        availableCreds +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount);
      let RemAmt = 0;
      if (parseFloat(remainingamountdue) > 0.0) {
        RemAmt = parseFloat(remainingamountdue) - value;
        if (RemAmt < 0) {
          RemAmt = RemAmt * -1;
        }
      }
      console.log("----->Remamtvalue", RemAmt);
      setcreditDisable(false);
      setchangeRadio(true);
      setradioBtn("0");
      setCreditRadio(false);
      //setremainingamountdue(RemAmt);
      //setRemaniningAmtStatic(Number(remAmtStat.toFixed(2)));
      // setdefaultTotaldue(TotalRemAmt)
      settotaldue(TotalRemAmt);
      ConvenienceFee(SelectedOption);
      setpaymentOrigin(SelectedOption);
      tendertypeglobalarray = [];
      settotalduetendertype(tendertypeglobalarray);
      const TempCard_Array: any = new Array(CardRadiArr.length).fill(false);
      setCardRadiArr(TempCard_Array);
      for (let i = 0; i < multipleCard.length; i++) {
        multipleCard[i].Amount = "0.00";
      }
      setmultipleCard(multipleCard);
      setCardErrorLbl(false);
      setCardGridData(CardGrid);
      setcvvTextValue("");
      swipeCardAmt = [];
      setcardDetails([]);
      setcardtxtDisable(false);
      setCardAmt([{ amount: 0.0 }]);
      setswipeamt("0.00");
      swipeCardDetails = [];
      setswipeFlag(false);
      setcvvTextValue("");
      setRemaniningAmtStatic(String(remAmtStat));
      setremainingamountdue(String(remAmtStat));
      setAcceptpaymentbuttonenable(true);
      setchargeCardDisable(true);
      setchange(0.0);
      setchargeCardArray([]);
    }
  };

  /***
   * This function gets called when "yes" button is clicked from the
   * convenience fee popup. Add the convenience fee of $1.99 to the total due and
   * remaining amount due.
   */
  const NewtotalAmt = () => {
    //setaddNewCard(true);
    previousState = swipeDisable;
    setswipeDisable(true);
    const value = parseFloat(convAmtWTax);
    let chargeCardAmount = 0;
    let swipeCardAmount = 0;
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Ending with")
      ) {
        chargeCardAmount =
          chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    for (let i = 0; i < tendertypeglobalarray.length; i++) {
      if (
        tendertypeglobalarray[i].tendertype
          .split(" - ")[0]
          .includes("Card Swipe ending")
      ) {
        swipeCardAmount =
          swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
      }
    }
    chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
    let cardAmt = Number(
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    );
    cardAmt =
      Number(cardAmt) + Number(chargeCardAmount) + Number(swipeCardAmount);
    //let objCashAndCheqAmt = Number(addcash) + Number(totalamountcheck);
    let objRemainAmt = 0.0;
    let objChange = 0.0;
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    objRemainAmt =
      Number(totaldue) +
      value -
      cardAmt -
      Number(availableCreds) +
      Number(addSusTotalAmount) -
      Number(usedSusTotalAmount);
    if (objRemainAmt > 0) {
      setAcceptpaymentbuttonenable(true);
    }
    if (objRemainAmt <= 0) {
      objChange = Math.abs(Number(objRemainAmt.toFixed(2)));
      objRemainAmt = 0.0;
      setAcceptpaymentbuttonenable(false);
    }
    setcashselected(1);
    setmobtn(true);
    setcashbtn(true);
    setCardrequire({
      ...Cardrequire,
      drpdown: false,
      CardCss: `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`,
    });
    setcashtabCss(`${navlinkClass.navLink} ${classes.p3}`);
    setcheckMoneyOrdertabCss(`${navlinkClass.navLink} ${classes.p3}`);
    setremainingamountdue(Number(objRemainAmt.toFixed(2)));
    setRemaniningAmtStatic(
      (Number(RemaniningAmtStatic) + Number(value)).toFixed(2)
    );
    settotaldue((Number(totaldue) + Number(value)).toFixed(2));
    setchange(objChange);
    setpaymentOrigin("Pay By Phone");
    setaddcash(0);
    setaddataanother(chqAndMo);
    let PaygridObj = paygridvalues.filter((x) => x.Agreementtype != "Club");
    setcreditDisable(true);
    setchangeRadio(false);
    setCreditRadio(true);
    setradioBtn("1");
    settotalamountcheck(0);
    console.log(
      "remainingamountdue",
      (Number(RemaniningAmtStatic) + Number(value) - cardAmt).toFixed(2)
    );
  };

  /**
   * This function is called when "No" button is clicked in the
   * convenience fee popup.
   */
  const closephonepopup = () => {
    setCardrequire({
      ...Cardrequire,
      drpdown: false,
      PaymentOrgin: "Store",
    });
    ConvenienceFee(Cardrequire.PaymentOrgin);
    setcreditDisable(false);
  };

  const AvailCredits_Binding = () => {
    if (
      availableCredsremaining !== undefined &&
      availableCredsremaining !== undefined &&
      availableCredsremaining.length > 0
    ) {
      return availableCredsremaining.map((item: any, index: any) => {
        if (
          (item.balanceType === "COA" || item.balanceType === "IP Suspense") &&
          parseFloat(item.accountBalance) > 0.0
        ) {
          return (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  variant="caption"
                  className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  {item.balanceType}
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  ($<span>{item.accountBalance})</span>
                </Typography>
              </Grid>
            </Grid>
          );
        }
      });
    }
  };

  const WLDeposit_Bind = () => {
    if (
      availableCredsremaining !== undefined &&
      availableCredsremaining.length > 0
    ) {
      return availableCredsremaining.map((item: any, index: any) => {
        if (
          item.balanceType === "WEBLEAD" &&
          parseFloat(item.accountBalance) > 0.0
        ) {
          return (
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
            >
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  variant="caption"
                  className={`${classes.floatLeft} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  WL Deposit
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.totallabelstyle}`}
                >
                  ($<span>{item.accountBalance})</span>
                </Typography>
              </Grid>
            </Grid>
          );
        }
      });
    }
  };

  //footer functionalities starts here
  const popupButtonclick = () => {
    setCardrequire({
      ...Cardrequire,
      Ldwhide: customerCoverageObject.isLDW === true ? false : true,
      Policyhide:
        customerCoverageObject.isPolicy === true &&
        customerCoverageObject.policyAmount !== ""
          ? false
          : true,
    });
    if (
      PopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      PopupClass === `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };

  //accept epo changes
  const epopopupButtonclick = () => {
    //console.log(mastersuspensearray,"master array")

    console.log("entered epoonlcick");
    console.log(epoPopupClass);

    if (
      epoPopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setepoPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      // const [suspensePopupClass, setsuspensePopupClass] = useState<string>(
      epoPopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setepoPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };

  const epoOnblur = () => {
    //setaddsuspense(true)
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const epoSheduleOpen = () => {
    // alert("Hello");
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setEpoShedulePopup(true);
    // const agr:any = paygridvalues[selectedGrid].Agreementid
    // history.push(`/payment1/eposhedule/${agr}`)
  };
  // const SLACancel = () => {
  //   setSLAController({ ...SLAController, TwoFactorCancelClick: false });
  //   setopenSecondFactor(false);
  // };

  // const SLACompleted = (value: any) => {
  //   setSLAController({ ...SLAController, TwoFactorCompleted: value });
  //   setShowAcceptEpoPopup(true);
  //   setopenSecondFactor(false);
  // };

  //accept epo code
  // const acceptEpoButton = (epoFieldValues: any, closeState: any) => {
  //   console.log("accept btn", closeState);

  //   settotaldue(val);
  //   setShowAcceptEpoPopup(closeState);
  //   console.log("Remaninggg", remainingamountdue);
  //   let totalTenderAmount = 0.00;
  //   for(let i =0 ; i<totalduetendertype.length;i++){
  //     totalTenderAmount = totalTenderAmount + totalduetendertype[i].Amount;
  //   }
  //   totalTenderAmount = totalTenderAmount + availableCreds;
  //   if(totalTenderAmount < val){
  //     setchange(0.00);
  //     setremainingamountdue(val - totalTenderAmount);
  //     setAcceptpaymentbuttonenable(true);
  //   }
  //   else{
  //     setchange(Math.abs(val - totalTenderAmount));
  //     setremainingamountdue(0.00);
  //     setAcceptpaymentbuttonenable(false);
  //   }
  //   console.log("paygridvalues", paygridvalues);
  //   paygridvalues.forEach((el: any, i: any) => {
  //     if (el.Agreementid == agrId) {
  //       paygridvalues[i].Amount = val;
  //     }
  //   });
  // };
  const acceptEpoButton = (
    epoFieldValues: any,
    closeState: any,
    invokeSource?: any
  ) => {
    //debugger;
    console.log(paygridvalues, "agreement type added 1");
    if (paygridvalues[selectedGrid] != undefined) {
      setShowAcceptEpoPopup(false);
      setepoPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
      let payGridObj = paygridvalues[selectedGrid];
      let prevAmt;
      let prevAgrType = paygridvalues[selectedGrid].Agreementtype;
      //let resAmount=0
      //0
      totalduereceiptidsupport = [];
      acceptPaymentParam = [];
      ReceiptCategoriesRequestParam = [];
      //let res=response.resArray;
      let resAmount = Number(epoFieldValues.total);
      let resTaxamount = Number(epoFieldValues.taxAmt);
      //for(let i =0 ; i<adjustDueDateSelectedAgre.length;i++){

      //payGridObj = paygridvalues.filter((x) => x.Agreementid === res[i].Agreementid );
      // debugger;
      prevAmt = paygridvalues[selectedGrid].Amount;
      paygridvalues[selectedGrid].Amount = Number(epoFieldValues.total);
      paygridvalues[selectedGrid].Taxamount = Number(epoFieldValues.taxAmt);
      paygridvalues[selectedGrid].Agreementtype =
        prevAgrType == "Note Payment" ||
        prevAgrType == "Epo Note" ||
        prevAgrType == "PIF Note"
          ? "Epo Note"
          : "EPO Rent";
      paygridvalues[selectedGrid].Nextduedate =
        new Date(paygridvalues[selectedGrid].SAC_Date) >= new Date()
          ? "SAC"
          : "EPO";
      paygridvalues[selectedGrid].rpayTaxAmount = Number(epoFieldValues.taxAmt);
      paygridvalues[selectedGrid].policyAmount = 0.0;
      paygridvalues[selectedGrid].policyTaxAmount = 0.0;
      paygridvalues[selectedGrid].suspenseSub =
        paygridvalues[selectedGrid].SuspenseAmount;
      paygridvalues[selectedGrid].pastDueRent = Number(
        epoFieldValues.pastDueRent
      );
      paygridvalues[selectedGrid].suspenseAdd = 0;
      paygridvalues[selectedGrid].acceptEpoFlag = 1;
      paygridvalues[selectedGrid].agreementNoteDetails.editedNoteAmount =
        Number(epoFieldValues.editedNoteAmount).toFixed(2);
      // let editedEpoAmunt =agr[0] !== undefined &&
      // agr[0] !== null &&
      // agr[0] !== "" &&
      // agr[0].EPOAmount !== undefined &&
      // agr[0].EPOAmount !== null &&
      // agr[0].EPOAmount !== ""
      //   ? Number(agr[0].EPOAmount) - Number(agr[0].EPOTax) +Number(agr[0].suspenseSub) - Number(agr[0].pastDueRent !== undefined &&
      //     agr[0].pastDueRent !== null &&
      //     agr[0].pastDueRent !== "" ? agr[0].pastDueRent : 0.00) - Number(agr[0].lateFee !== undefined &&
      //       agr[0].lateFee !== null &&
      //       agr[0].lateFee !== ""
      //         ? agr[0].lateFee : 0.00)
      //   : ""
      //   let editedamount = Number(editedEpoAmunt)<0?0.00:Number(editedEpoAmunt)
      // seteditedEPOAmount(editedamount)
      // paygridvalues[selectedGrid].SuspenseAmount = 0.00;
      paygridvalues[selectedGrid].EPOAmount = Number(epoFieldValues.total);
      paygridvalues[selectedGrid].EPOTax = Number(epoFieldValues.taxAmt);
      paygridvalues[selectedGrid].editedEPOAmount = Number(
        epoFieldValues.epoAmt
      );

      //to set the receipt item values
      const agreementDetails = {
        agreement_id: paygridvalues[selectedGrid].AgreementNumber,
        agreement_totalamount: fixedChange(String(epoFieldValues.total)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      settotalduereceiptid(totalduereceiptidsupport);
      //accept payment array push starts here
      let ldwamountcalculation = 0.0;
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax = 0.0;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      let epoAmt = 0;
      let epoTax = 0;
      let getPayData =
        localGetPayData !== undefined
          ? localGetPayData
          : getpayment !== undefined
          ? getpayment
          : undefined;
      if (getPayData !== undefined && getPayData !== null) {
        //let objLdwInfo = getpayment.paymentInfo.ldwInformation.filter(x => x.agreementId ===  payGridObj[0].Agreementid);
        let objAmtDue;
        if (payGridObj.Agreementtype === "Club") {
          objAmtDue = getPayData.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getPayData.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === payGridObj.Agreementid
          );
        }

        if (objAmtDue[0].isLdwAllowed != "1") {
          paygridvalues[selectedGrid].agrLdwAmount = 0;
          paygridvalues[selectedGrid].ldwTaxAmount = 0;
        }

        //if (objLdwInfo.length > 0 && objLdwInfo[0].ldwFlag == true &&  payGridObj[0].Agreementtype !== "Club") {
        if (
          objAmtDue[0].ldwLateRent !== undefined &&
          objAmtDue[0].ldwLateRent !== null &&
          objAmtDue[0].ldwLateRent !== "" &&
          objAmtDue[0].ldwLateRent !== "0" &&
          objAmtDue[0].isLdwAllowed == "1"
        ) {
          ldwamountcalculation = objAmtDue[0].ldwLateRent;
          ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          resTaxamount = resTaxamount + Number(objAmtDue[0].ldwTaxAmount);
          paygridvalues[selectedGrid].Taxamount =
            paygridvalues[selectedGrid].Taxamount +
            Number(objAmtDue[0].ldwTaxAmount);
        } else if (
          objAmtDue[0].ldwAmount !== undefined &&
          objAmtDue[0].ldwAmount !== null &&
          objAmtDue[0].ldwAmount !== "" &&
          objAmtDue[0].isLdwAllowed == "1"
        ) {
          ldwamountcalculation = objAmtDue[0].ldwAmount;
          ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          resTaxamount = resTaxamount + Number(objAmtDue[0].ldwTaxAmount);
          paygridvalues[selectedGrid].Taxamount =
            paygridvalues[selectedGrid].Taxamount +
            Number(objAmtDue[0].ldwTaxAmount);
        }
        console.log(
          objAmtDue[0],
          ldwamountcalculation,
          ldwFeeTax,
          "amount due in accept epo"
        );
        //}
        if (
          objAmtDue[0].amountDueLateRent !== undefined &&
          objAmtDue[0].amountDueLateRent !== null &&
          objAmtDue[0].amountDueLateRent !== "" &&
          objAmtDue[0].amountDueLateRent !== "0"
        ) {
          objAmount = objAmtDue[0].amountDueLateRent;
        } else if (objAmtDue[0].amountDue !== undefined) {
          objAmount = String(objAmtDue[0].amountDue);
        } else if (
          objAmtDue[0].clubAmountLateRent !== undefined &&
          objAmtDue[0].clubAmountLateRent !== null &&
          objAmtDue[0].clubAmountLateRent !== ""
        ) {
          clubFlag = 1;
          objAmount = objAmtDue[0].clubAmountLateRent;
        } else {
          clubFlag = 1;
          objAmount = String(objAmtDue[0].clubAmountDue);
        }
        let objPayHistory = getPayData.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === payGridObj.Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            payGridObj.Amount -
            Number(payGridObj.lateFee) +
            Number(payGridObj.suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number( payGridObj[0].EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date( payGridObj[0].SAC_Date);
        if (paygridvalues[selectedGrid].Agreementtype == "EPO Rent") {
          console.log("Inside If agrAmount----->", agreementAmount);
          if (paygridvalues[selectedGrid].Nextduedate == "EPO") {
            epoAmt =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - paygridvalues[selectedGrid].Taxamount
                    ).toFixed(2)
                  )
                : Number(paygridvalues[selectedGrid].EPOAmount) +
                  Number(paygridvalues[selectedGrid].suspenseSub) -
                  Number(paygridvalues[selectedGrid].lateFee) -
                  Number(paygridvalues[selectedGrid].EPOTax);
            epoTax =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(paygridvalues[selectedGrid].rpayTaxAmount) -
                  Number(paygridvalues[selectedGrid].lFeeTaxAmount)
                : Number(paygridvalues[selectedGrid].EPOTax) -
                  Number(paygridvalues[selectedGrid].lFeeTaxAmount);
          } else {
            sacAmt =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(
                    (
                      agreementAmount - paygridvalues[selectedGrid].Taxamount
                    ).toFixed(2)
                  )
                : Number(paygridvalues[selectedGrid].EPOAmount) +
                  Number(paygridvalues[selectedGrid].suspenseSub) -
                  Number(paygridvalues[selectedGrid].lateFee) -
                  Number(paygridvalues[selectedGrid].EPOTax);
            sacTax =
              paygridvalues[selectedGrid].acceptEpoFlag == 0
                ? Number(paygridvalues[selectedGrid].rpayTaxAmount) -
                  Number(paygridvalues[selectedGrid].lFeeTaxAmount)
                : Number(paygridvalues[selectedGrid].EPOTax) -
                  Number(paygridvalues[selectedGrid].lFeeTaxAmount);
          }
          setfinalagr(Number(paygridvalues[selectedGrid].Agreementid));
          pifFlag = 1;
        } else if (paygridvalues[selectedGrid].Agreementtype == "PIF Rent") {
          pifAmt =
            paygridvalues[selectedGrid].acceptEpoFlag == 0
              ? Number(
                  (
                    agreementAmount - paygridvalues[selectedGrid].Taxamount
                  ).toFixed(2)
                )
              : Number(paygridvalues[selectedGrid].EPOAmount) +
                Number(paygridvalues[selectedGrid].suspenseSub) -
                Number(paygridvalues[selectedGrid].lateFee) -
                Number(paygridvalues[selectedGrid].EPOTax);
          pifTax =
            paygridvalues[selectedGrid].acceptEpoFlag == 0
              ? Number(paygridvalues[selectedGrid].rpayTaxAmount) -
                Number(paygridvalues[selectedGrid].lFeeTaxAmount)
              : Number(paygridvalues[selectedGrid].EPOTax) -
                Number(paygridvalues[selectedGrid].lFeeTaxAmount);
          setfinalagr(Number(paygridvalues[selectedGrid].Agreementid));
          pifFlag = 1;
        }
        let ChangeAmt = change;
        console.log("ChangeAmt", ChangeAmt);
        let ReceiptArray = {
          customerId: Number(customerId),
          schedule: payGridObj.Schedule + "|" + payGridObj.AgreeentNumber,
          amountDue: Number(
            clubFlag !== 1 && pifFlag == 0
              ? (payGridObj.Amount - Number(payGridObj.lateFee)).toFixed(2)
              : 0
          ),
          ldwFeeAmount: Number(payGridObj.agrLdwAmount), // Added By Me
          // Number(payGridObj.PromoFreeDaysApplied) == 0
          //   ? payGridObj.agrLdwAmount
          //   : 0.0,
          clubPayment: Number(
            clubFlag === 1
              ? Number(payGridObj.Amount.toFixed(2)) -
                  Number(payGridObj.Taxamount)
              : 0
          ),
          clubPaymentTax: Number(clubFlag === 1 ? payGridObj.Taxamount : 0),
          creditOnAccountAdd: 0,
          creditOnAccountSub: Number(payGridObj.COA),
          integratedPaymentSuspense: -1 * Number(payGridObj.ipSuspense),
          initialPayment:
            clubFlag === 1
              ? 0
              : payGridObj.number_of_paymentsmade === 0
              ? 1
              : 0,
          netLateFee: 0,
          carriedLateFee: 0,
          wlDeposit: -1 * Number(payGridObj.wlDeposit),
          htSuspense: 0,
          suspenseAdd: Number(0),
          suspenseSub: Number(payGridObj.suspenseSub), //Number( payGridObj[0].suspense),
          racTirePay: Number(payGridObj.policyAmount),
          agreementId:
            clubFlag !== 1 ? Number(payGridObj.Agreementid) : undefined,
          change: Number(fixedChange(ChangeAmt)),
          totalTax: payGridObj.Taxamount,
          sac: Number(sacAmt),
          sacTax: Number(sacTax),
          epo: Number(epoAmt),
          epoTax: Number(epoTax),
          epoDiscount:
            payGridObj.acceptEpoFlag == 0
              ? 0.0
              : Number(payGridObj.actualEpoAmount) -
                Number(payGridObj.editedEPOAmount),
          pastDueDiscount:
            payGridObj.acceptEpoFlag == 0
              ? 0.0
              : Number(payGridObj.actualPastDueRent) -
                Number(payGridObj.pastDueRent),
          pastDueAmount:
            payGridObj.acceptEpoFlag == 0
              ? undefined
              : Number(payGridObj.actualPastDueRent),
          nextDueDate:
            payGridObj.acceptEpoFlag == 0 ? undefined : payGridObj.Nextduedate,
          // ldwFeeAmountTax: Number(payGridObj.ldwTaxAmount),
          ldwFeeAmountTax: Number(payGridObj.ldwTaxAmount),
          rentalPaymentTax: pifFlag == 0 ? Number(payGridObj.rpayTaxAmount) : 0,
          lateRentalPaymentfeeTax: Number(payGridObj.lFeeTaxAmount),
          lateRentalPaymentfee: Number(payGridObj.lateFee),
          racTirePayTax: Number(payGridObj.policyTaxAmount),
          deliveryFee: Number(payGridObj.deliveryAmount),
          deliveryFeeTax: Number(payGridObj.deliveryAmountTax),
          processingFee: Number(payGridObj.processingFee),
          processingFeeTax: Number(payGridObj.processingFeeTax),
          //otherFee: Number( payGridObj[0].mattressFee),
          //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
          pif: Number(pifAmt),
          pifTax: Number(pifTax),
          extensionAmount: Number(payGridObj.extensionAmount),
          convenienceFee: 0,
          convenienceFeeTax: 0,
          mattressFee: Number(payGridObj.mattressFee),
        };
        ReceiptCategoriesRequestParam.push(ReceiptArray);
        if (payGridObj.Agreementtype !== "Club") {
          let acceptPaymentArray = {
            extensionAmount: Number(payGridObj.extensionAmount),
            noOfDaysFree: Number(payGridObj.noOfDaysFree),
            initialPaymentFlag: 1,
            // initialPaymentFlag: payGridObj.number_of_paymentsmade == 0 ? 1 : 0,
            addTransferSusFlag: 0,
            schedule: payGridObj.Schedule,
            agreementId: Number(payGridObj.Agreementid),
            ldwFeeAmount: Number(payGridObj.agrLdwAmount.toFixed(2)), // Added By Me
            // Number(
            //   objAmtDue[0].isLdwAllowed == "1" ||
            //     Number(payGridObj.PromoFreeDaysApplied) == 0
            //     ? payGridObj.agrLdwAmount.toFixed(2)
            //     : 0.0
            // ),
            agreementAmountDue: Number(
              fixedChange(
                String(
                  Number(payGridObj.Amount) +
                    (Number(payGridObj.acceptEpoFlag) == 1
                      ? Number(payGridObj.suspenseSub)
                      : 0)
                )
              )
            ),
            remainingEpoAmount: Number(payGridObj.EPOAmount.toFixed(2)),
            promoFreeDays: Number(payGridObj.PromoFreeDaysApplied),
            netLateFee: Number(payGridObj.lateFee),
            carriedLateFee: Number(payGridObj.carriedLateFee.toFixed(2)),
            numberOfDaysLate: payGridObj.CurrentDaysLate,
            originalDueDate: payGridObj.CurrentDueDate,
            nextDueDate: payGridObj.Nextduedate,
            totalTax: Number(payGridObj.Taxamount.toFixed(2)),
            policyAmount: Number(0),
            editSchedule: payGridObj.editSchedule,
            freeTime: payGridObj.FreeTime, // Added By Me
            ldwActivate: undefined,
          };
          acceptPaymentParam.push(acceptPaymentArray);
          setAcceptClub(undefined);
        }
        //}
        //debugger;
        let objAvailCreditsAmt = 0;
        if (Number(resAmount) <= Number(payGridObj.wlDeposit)) {
          objAvailCreditsAmt = resAmount;
          // Number(paygridvalues[index].COA) +
          // Number(paygridvalues[index].wlDeposit)
          // Number(paygridvalues[index].ipSuspense)
          console.log(objAvailCreditsAmt, "avab credit total amount");
          setAvailableCreds(objAvailCreditsAmt);
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(resAmount).toFixed(2),
            },
          ]);
        } else if (Number(resAmount) > Number(payGridObj.wlDeposit)) {
          objAvailCreditsAmt =
            Number(payGridObj.COA) +
            Number(payGridObj.wlDeposit) +
            Number(payGridObj.ipSuspense);
          console.log(objAvailCreditsAmt, "avab credit total amount");
          setAvailableCreds(objAvailCreditsAmt);
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(payGridObj.wlDeposit).toFixed(2),
            },
            {
              balanceType: "COA",
              accountBalance: Number(payGridObj.COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number(payGridObj.ipSuspense).toFixed(2),
            },
          ]);
        } else if (Number(resAmount) == 0) {
          objAvailCreditsAmt =
            Number(payGridObj.COA) + Number(payGridObj.ipSuspense);
          console.log(objAvailCreditsAmt, "avab credit total amount");
          setAvailableCreds(objAvailCreditsAmt);
          setavailableCredsremaining([
            {
              balanceType: "COA",
              accountBalance: Number(payGridObj.COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number(payGridObj.ipSuspense).toFixed(2),
            },
          ]);
        }
        // const addSuspenseAmount = []
        // const useSuspenseAmount = []
        let addSusTotalAmount = 0.0;
        let usedSusTotalAmount = 0.0;
        let resTotalAmount = 0.0;
        if (Cardrequire.PaymentOrgin == "Pay By Phone") {
          resAmount = Number(resAmount) + Number(convAmtWTax);
          resTaxamount = Number(resTaxamount) + Number(convtax);
        }
        resTotalAmount =
          totalduetendertype.length == 0
            ? 0.0
            : totalduetendertype
                .map((bill) => bill.Amount)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        if (Number(resTotalAmount) > Number(resAmount)) {
          resetPayment(undefined);
          setremainingamountdue(
            Number(
              (
                Number(resAmount) -
                Number(objAvailCreditsAmt) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
          );
          setchange("0.00");
          setAcceptpaymentbuttonenable(true);
        } else {
          if (
            Number(resTotalAmount) >=
            Number(
              (
                Number(resAmount) -
                Number(objAvailCreditsAmt) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
          ) {
            setchange(
              Number(resTotalAmount) -
                Number(
                  (
                    Number(resAmount) -
                    Number(objAvailCreditsAmt) +
                    Number(addSusTotalAmount) -
                    Number(usedSusTotalAmount)
                  ).toFixed(2)
                )
            );
            setremainingamountdue("0.00");
            setRemaniningAmtStatic("0.00");
            setAcceptpaymentbuttonenable(false);
          } else {
            setremainingamountdue(
              Number(
                (
                  Number(resAmount) -
                  Number(objAvailCreditsAmt) +
                  Number(addSusTotalAmount) -
                  Number(usedSusTotalAmount)
                ).toFixed(2)
              ) - Number(resTotalAmount)
            );
            setRemaniningAmtStatic(
              Number(
                (
                  Number(resAmount) -
                  Number(objAvailCreditsAmt) +
                  Number(addSusTotalAmount) -
                  Number(usedSusTotalAmount)
                ).toFixed(2)
              ) - Number(resTotalAmount)
            );
            setchange("0.00");
            setAcceptpaymentbuttonenable(true);
          }
        }
        settotaldue(resAmount);
        settotaltax(Number(resTaxamount));
        setopenAdjustDueDate(false);
        setopenPromoFreeDays(false);
        seteditSchedulePopUp(false);
      }
      multipleAdjust();
    }
  };

  const acceptEpoCloseButton = () => {
    console.log("dfgshjk", epoShedulePopup);
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setShowAcceptEpoPopup(false);
    setEpoShedulePopup(false);
  };

  const acceptEpoPopupOpen = () => {
    setShowAcceptEpoPopup(true);
  };

  //syed change 3

  const suspensepopupButtonclick = () => {
    console.log(mastersuspensearray, "master array");

    console.log("entered suspenseonlcick");
    console.log(suspensePopupClass);

    if (
      suspensePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setsuspensePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      // const [suspensePopupClass, setsuspensePopupClass] = useState<string>(
      suspensePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setsuspensePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };

  const suspenseOnblur = () => {
    //setaddsuspense(true)
    setsuspensePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const otherActOnblur = () => {
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };
  const epoActOnblur = () => {
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const TerminateLDWbtnPopup = () => {
    setTerminateLDW(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  //onclick change for payment history popup

  const HistorybtnPopup = () => {
    console.log("entered history");
    setPaymentHistory(true);
  };

  //syed change 6
  const addSuspensePopup = () => {
    debugger;

    console.log(ReceiptCategoriesRequestParam, "Receipt array");
    console.log(mastersuspensearray, "first");
    console.log(totalsuspensemaster, "first");
    console.log(masterusesuspensearray, "first");
    console.log(masteraddsuspensearray, "first");

    console.log(totalsuspensemaster, "total master");

    let insertArray: any[] = [];

    for (let i = 0; i < checkedAgr.length; i++) {
      if (
        checkedAgr[i].Agreementtype != "Club" &&
        checkedAgr[i].Nextduedate != "PIF" &&
        checkedAgr[i].Nextduedate != "SAC" &&
        checkedAgr[i].Nextduedate != "EPO" &&
        checkedAgr[i].number_of_paymentsmade > 0 &&
        String(checkedAgr[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))
      ) {
        insertArray.push({
          sustype: Number(checkedAgr[i].suspenseAdd) > 0 ? "Add" : "type",
          agreementnumber: checkedAgr[i].Agreementid,
          agreementid: checkedAgr[i].AgreementNumber,
          schedure: checkedAgr[i].Schedule,
          paymentamount: checkedAgr[i].Amount,
          epoamount: checkedAgr[i].EPOAmount,
          suspenseamount: Number(checkedAgr[i].SuspenseAmount).toFixed(2),
          newsuspense: Number(checkedAgr[i].suspenseAdd).toFixed(2),
          agreementType: checkedAgr[i].Agreementtype,
        });
      }
    }

    console.log(insertArray, "inserted array");
    setmasteraddsuspensearray([...insertArray]);

    console.log("mastersuspenseADD");
    console.log(masteraddsuspensearray);
    // setmastersuspensearray([])
    console.log(mastersuspensearray);

    console.log("entered suspense popup");
    console.log(addsuspense);

    setAddsusText(true);
    setaddsuspense(true);
  };

  const useSuspensePopup = () => {
    // debugger;

    // let usefiltered = totalsuspensemaster.filter(function (e) {
    //   return e.sustype =="Used"; });

    console.log(mastersuspensearray, "first");
    console.log(totalsuspensemaster, "first");
    console.log(masterusesuspensearray, "first");
    console.log(masteraddsuspensearray, "first");

    console.log(totalsuspensemaster, "total master");

    let insertArray: any[] = [];

    console.log(checkedAgr, "checked agreement");

    for (let i = 0; i < checkedAgr.length; i++) {
      if (
        checkedAgr[i].Agreementtype != "Club" &&
        checkedAgr[i].Nextduedate != "PIF" &&
        checkedAgr[i].Nextduedate != "SAC" &&
        checkedAgr[i].Nextduedate != "EPO" &&
        checkedAgr[i].number_of_paymentsmade > 0 &&
        String(checkedAgr[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))
      ) {
        insertArray.push({
          sustype: Number(checkedAgr[i].suspenseSub) > 0 ? "Used" : "type",
          agreementnumber: checkedAgr[i].Agreementid,
          agreementid: checkedAgr[i].AgreementNumber,
          schedure: checkedAgr[i].Schedule,
          paymentamount: checkedAgr[i].Amount,
          epoamount: checkedAgr[i].EPOAmount,
          suspenseamount: Number(checkedAgr[i].SuspenseAmount).toFixed(2),
          newsuspense: Number(checkedAgr[i].suspenseSub).toFixed(2),
          agreementType: checkedAgr[i].Agreementtype,
        });
      }
    }
    setmasterusesuspensearray([...insertArray]);

    setUsesusText(true);
    setusesuspense(true);
  };

  const transferSuspensePopup = () => {
    // debugger

    // let usefiltered = totalsuspensemaster.filter(function (e) {
    //   return e.sustype =="Used"; });
    // settotalnewsuspenseamount('0.00')
    // setdifferenceamount('0.00')
    console.log(mastersuspensearray, "first");
    console.log(totalsuspensemaster, "first");
    console.log(masterusesuspensearray, "first");
    console.log(masteraddsuspensearray, "first");
    console.log(mastertranssuspensearray, "first");

    console.log(totalsuspensemaster, "total master");

    console.log(checkedAgr, "checked agreement");
    let insertArray: any[] = [];
    let totalsusamount = 0;
    let transferedSupenseAmount = 0;

    for (let i = 0; i < paygridvalues?.length; i++) {
      if (
        paygridvalues[i].Agreementtype != "Club" &&
        paygridvalues[i].Nextduedate != "PIF" &&
        paygridvalues[i].Nextduedate != "SAC" &&
        paygridvalues[i].Nextduedate != "EPO" &&
        paygridvalues[i].number_of_paymentsmade > 0 &&
        String(paygridvalues[i].store_number) ==
          String(window.sessionStorage.getItem("storeNumber"))
      ) {
        totalsusamount =
          Number(totalsusamount) + Number(paygridvalues[i].SuspenseAmount);
        transferedSupenseAmount =
          Number(transferedSupenseAmount) +
          Number(paygridvalues[i].transferOldSuspense);
        insertArray.push({
          sustype: "type",
          agreementnumber: paygridvalues[i].Agreementid,
          agreementid: paygridvalues[i].AgreementNumber,
          schedure: paygridvalues[i].Schedule,
          paymentamount: paygridvalues[i].Amount,
          epoamount: paygridvalues[i].EPOAmount,
          suspenseamount: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
          newsuspense: Number(paygridvalues[i].SuspenseAmount).toFixed(2),
          agreementType: paygridvalues[i].Agreementtype,
        });
        settotolsuspenseamount(Number(totalsusamount).toFixed(2));
        setdifferenceamount(
          Number(
            (Number(transferedSupenseAmount) - Number(totalsusamount)).toFixed(
              2
            )
          )
        );
        if (Number(totalsusamount) - Number(transferedSupenseAmount) == 0) {
          settransferDifferenceZero(false);
        } else {
          settransferDifferenceZero(true);
        }
      }
    }

    setmastertranssuspensearray([...insertArray]);

    console.log("mastersuspenseUSE");
    console.log(masterusesuspensearray);
    // setmastersuspensearray([])
    console.log(mastersuspensearray);

    console.log("entered suspense popup");
    console.log(addsuspense);

    setUsesusText(true);
    settransuspense(true);
  };

  const refundSuspensePopup = () => {
    // debugger

    // let refundvalue = [...mastersuspensearray]
    // console.log(mastersuspensearray)

    let insertArray: any[] = [];

    for (let i = 0; i < checkedAgr.length; i++) {
      if (
        checkedAgr[i].Agreementtype != "Club" &&
        checkedAgr[i].Nextduedate != "PIF" &&
        checkedAgr[i].Nextduedate != "SAC" &&
        checkedAgr[i].Nextduedate != "EPO" &&
        checkedAgr[i].number_of_paymentsmade > 0
      ) {
        insertArray.push({
          sustype: "type",
          agreementnumber: checkedAgr[i].Agreementid,
          agreementid: checkedAgr[i].AgreementNumber,
          schedure: checkedAgr[i].Schedule,
          paymentamount: checkedAgr[i].Amount,
          epoamount: checkedAgr[i].EPOAmount,
          suspenseamount: checkedAgr[i].SuspenseAmount,
          newsuspense: 0.0,
          agreementType: checkedAgr[i].Agreementtype,
        });
      }
    }

    setmasterrefundsuspensearray([...insertArray]);

    setrefundsuspense(true);
  };

  const ActivateLDWbtnPopup = () => {
    setActiveLDW(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const Activepolicypopup = () => {
    setActivepolicy(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const DeActivepolicypopup = () => {
    setDeActivepolicy(true);
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const ContinueTerminateLDW = async () => {
    setTerminateLDW(false);
    const terminateLdw_Resp: any = await LDW_apiCall(2);
  };

  const continueActivateLDW = async () => {
    setActiveLDW(false);
    const ActiveLdw_Resp: any = await LDW_apiCall(1);
  };

  const ContinueActivatePolicy = async () => {
    setActivepolicy(false);
    const activatePolicy_ResPonse: any = await Policy_apiCall(1);
  };

  const ContinueDeActivatePolicy = async () => {
    setDeActivepolicy(false);
    const TerminatePolicy_Res: any = await Policy_apiCall(2);
  };
  const changeDateformat = (datearg: string) => {
    if (datearg !== "" || datearg !== null || datearg !== undefined) {
      let day = new Date(datearg).getDay();
      if (day == 0) {
        return datearg + "," + "Sun";
      } else if (day == 1) {
        return datearg + "," + "Mon";
      } else if (day == 2) {
        return datearg + "," + "Tue";
      } else if (day == 3) {
        return datearg + "," + "Wed";
      } else if (day == 4) {
        return datearg + "," + "Thu";
      } else if (day == 5) {
        return datearg + "," + "Fri";
      } else if (day == 6) {
        return datearg + "," + "Sat";
      }
    }
  };

  const FormAcceptPaymentData = (
    paramamount: any,
    paramAvailCredits: number,
    paramCardObject?: CardChk
  ) => {
    let objCheckArray = adddataanother.filter((x) => x.Type_info === "Check");
    let objCheckAmt = objCheckArray.reduce(
      (sumVal, a) => sumVal + parseFloat(a.Amount_rac),
      0
    );

    let objMoArray = adddataanother.filter(
      (x) => x.Type_info === "Money Order"
    );
    let objMoAmt = objMoArray.reduce(
      (sumVal, a) => sumVal + parseFloat(a.Amount_rac),
      0
    );

    let objAP: AcceptPaymentInfo = {
      CashAmount: paramamount === 0 ? parseFloat(addcash) : paramamount,
      ChequeAmount: Number(fixedChange(String(objCheckAmt))),
      MOAmount: Number(fixedChange(String(objMoAmt))),
      CardInfo: multipleCard,
      CheqAndMO: adddataanother.length > 0 ? adddataanother : null,
      AvailableCredits: paramAvailCredits,
    };
    setAcceptPayment(objAP);
  };

  const Bindgridcheckbox = () => {
    //   ReceiptCategoriesRequestParam = [];
    //   acceptPaymentParam = [];
    //   AcceptclubPaymentParam = undefined;
    if (paygridvalues !== undefined) {
      let checkBoxArray = document.getElementsByName("grid");
      //let objAmt = 0;
      checkBoxArray.forEach(function (item, index) {
        let obj = item as HTMLInputElement;
        let dataVal = obj.value.split("|");

        if (ReceiptCategoriesRequestParam.length === 0 && agreementId !== "0") {
          for (let i = 0; i < paygridvalues.length; i++) {
            if (paygridvalues[index].Agreementid == agreementId) {
              selectedGrid = i;
            }
          }
          let PaygridObj = paygridvalues.filter(
            (x) => x.Agreementid == Number(agreementId)
          );
          let epoAmt = 0;
          let epoTax = 0;
          console.log("PaygridObj", PaygridObj);
          //epoAmt = Number(PaygridObj[0].EPOAmount);
          let agrAmount = Number(PaygridObj[0].Amount.toFixed(2));
          let curDate = new Date();
          let sacDate = new Date(PaygridObj[index].SAC_Date);
          let sacTax = 0;
          console.log("epoAmt----->", epoAmt, agrAmount, curDate, sacDate);

          let pifAmt = 0;
          let pifTax = 0;
          let pifFlag = 0;

          let agreementAmount = Number(
            (
              PaygridObj[0].Amount -
              Number(PaygridObj[0].lateFee) +
              Number(PaygridObj[0].suspenseSub)
            ).toFixed(2)
          );
          //let epoAmount = Number(PaygridObj[0].EPOAmount.toFixed(2));

          let sacAmt = 0;
          console.log("agreement type added 2", PaygridObj[0]);
          if (PaygridObj[0].Agreementtype == "EPO Rent") {
            console.log("Inside If agrAmount----->", agreementAmount);
            if (PaygridObj[0].Nextduedate == "EPO") {
              epoAmt =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(
                      (agreementAmount - PaygridObj[0].Taxamount).toFixed(2)
                    )
                  : Number(PaygridObj[0].EPOAmount) +
                    Number(PaygridObj[0].suspenseSub) -
                    Number(PaygridObj[0].lateFee) -
                    Number(PaygridObj[0].EPOTax);
              epoTax =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(PaygridObj[0].rpayTaxAmount) -
                    Number(PaygridObj[0].lFeeTaxAmount)
                  : Number(PaygridObj[0].EPOTax) -
                    Number(PaygridObj[0].lFeeTaxAmount);
            } else {
              sacAmt =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(
                      (agreementAmount - PaygridObj[0].Taxamount).toFixed(2)
                    )
                  : Number(PaygridObj[0].EPOAmount) +
                    Number(PaygridObj[0].suspenseSub) -
                    Number(PaygridObj[0].lateFee) -
                    Number(PaygridObj[0].EPOTax);
              sacTax =
                PaygridObj[0].acceptEpoFlag == 0
                  ? Number(PaygridObj[0].rpayTaxAmount) -
                    Number(PaygridObj[0].lFeeTaxAmount)
                  : Number(PaygridObj[0].EPOTax) -
                    Number(PaygridObj[0].lFeeTaxAmount);
            }
            pifFlag = 1;
          } else if (PaygridObj[0].Agreementtype == "PIF Rent") {
            pifAmt =
              PaygridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - PaygridObj[0].Taxamount).toFixed(2))
                : Number(PaygridObj[0].EPOAmount) +
                  Number(PaygridObj[0].suspenseSub) -
                  Number(PaygridObj[0].lateFee) -
                  Number(PaygridObj[0].EPOTax);
            pifTax =
              PaygridObj[0].acceptEpoFlag == 0
                ? Number(PaygridObj[0].rpayTaxAmount) -
                  Number(PaygridObj[0].lFeeTaxAmount)
                : Number(PaygridObj[0].EPOTax) -
                  Number(PaygridObj[0].lFeeTaxAmount);
            pifFlag = 1;
          }
          if (PaygridObj[0].miscellaneousItemsNum == 0) {
            let ReceiptArray = {
              customerId: Number(customerId),
              schedule:
                PaygridObj[0].Schedule + "|" + PaygridObj[0].AgreementNumber,
              amountDue: Number(
                PaygridObj[0].Agreementtype !== "Club" && pifFlag == 0
                  ? (
                      PaygridObj[0].Amount - Number(PaygridObj[0].lateFee)
                    ).toFixed(2)
                  : 0
              ),
              ldwFeeAmount: PaygridObj[0].agrLdwAmount,
              clubPayment: Number(
                PaygridObj[0].Agreementtype === "Club"
                  ? Number(PaygridObj[0].Amount) -
                      Number(PaygridObj[0].Taxamount)
                  : 0
              ),
              clubPaymentTax: Number(
                PaygridObj[0].Agreementtype === "Club"
                  ? PaygridObj[0].Taxamount
                  : 0
              ),
              creditOnAccountAdd: 0,
              creditOnAccountSub: Number(PaygridObj[0].COA),
              integratedPaymentSuspense: -1 * Number(PaygridObj[0].ipSuspense),
              initialPayment: Number(
                PaygridObj[0].Agreementtype !== "Club"
                  ? PaygridObj[0].number_of_paymentsmade === 0
                    ? 1
                    : 0
                  : 0
              ),
              netLateFee: 0,
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              wlDeposit: -1 * Number(PaygridObj[0].wlDeposit),
              htSuspense: 0, //Number(PaygridObj[0].htSuspense),
              suspenseAdd: Number(PaygridObj[0].suspenseAdd),
              suspenseSub: Number(PaygridObj[0].suspenseSub), //Number(PaygridObj[0].suspense),
              racTirePay: Number(PaygridObj[0].policyAmount), //Number(PaygridObj[0].racTirePay),
              agreementId:
                PaygridObj[0].Agreementtype !== "Club"
                  ? Number(PaygridObj[0].Agreementid)
                  : undefined,
              change: 0,
              totalTax: PaygridObj[0].Taxamount,
              sac: Number(sacAmt),
              sacTax: Number(sacTax),
              epo: Number(epoAmt),
              epoTax: Number(epoTax),
              epoDiscount:
                PaygridObj[0].acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj[0].actualEpoAmount) -
                    Number(PaygridObj[0].editedEPOAmount),
              pastDueDiscount:
                PaygridObj[0].acceptEpoFlag == 0
                  ? 0.0
                  : Number(PaygridObj[0].actualPastDueRent) -
                    Number(PaygridObj[0].pastDueRent),
              pastDueAmount:
                PaygridObj[0].acceptEpoFlag == 0
                  ? undefined
                  : Number(PaygridObj[0].actualPastDueRent),
              nextDueDate:
                PaygridObj[0].acceptEpoFlag == 0
                  ? undefined
                  : PaygridObj[0].Nextduedate,
              ldwFeeAmountTax: Number(PaygridObj[0].ldwTaxAmount),
              rentalPaymentTax: Number(
                pifFlag == 0 && PaygridObj[0].Agreementtype !== "Club"
                  ? PaygridObj[0].rpayTaxAmount
                  : 0
              ),
              lateRentalPaymentfeeTax: Number(PaygridObj[0].lFeeTaxAmount),
              lateRentalPaymentfee: Number(PaygridObj[0].lateFee),
              racTirePayTax: Number(PaygridObj[0].policyTaxAmount),
              deliveryFee: Number(PaygridObj[0].deliveryAmount),
              deliveryFeeTax: Number(PaygridObj[0].deliveryAmountTax),
              processingFee: Number(PaygridObj[0].processingFee),
              processingFeeTax: Number(PaygridObj[0].processingFeeTax),
              carryRentAmountUsed:
                PaygridObj[0].FreeTime == null
                  ? Number(PaygridObj[0].carryRentAmountUsed)
                  : 0,
              carryRentTaxUsed:
                PaygridObj[0].FreeTime == null
                  ? Number(PaygridObj[0].carryRentTaxUsed)
                  : 0,
              //otherFee: Number(PaygridObj[0].mattressFee),
              //otherFeeTax: Number(PaygridObj[0].mattressFeeTax),
              extensionAmount: Number(PaygridObj[0].extensionAmount),
              pif: Number(pifAmt),
              pifTax: Number(pifTax),
              convenienceFee: 0,
              convenienceFeeTax: 0,
              mattressFee: Number(PaygridObj[0].mattressFee),
            };
            ReceiptCategoriesRequestParam.push(ReceiptArray);
          }
        }
        let clubObj = paygridvalues.filter((x) => x.Agreementtype == "Club");
        let objAmtDue;
        let objAmount = "0.00";
        if (clubObj[0] != undefined && clubObj[0].Agreementtype === "Club") {
          objAmtDue = getpayment?.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        }
        if (
          AcceptclubPaymentParam == undefined &&
          clubObj[0] != undefined &&
          agreementId !== "0" &&
          objAmtDue[0].initialPaymentFlag == 1 &&
          clubInitialCheckFlag
        ) {
          clubInitialCheckFlag = false;
          console.log("clubObj", clubObj);
          // epoAmt = Number(clubObj[0].EPOAmount);
          // let agrAmount = Number(clubObj[0].Amount.toFixed(2));
          // let curDate = new Date();
          // let sacDate = new Date(clubObj[index].SAC_Date);
          // let sacTax = 0;
          // console.log("epoAmt----->", epoAmt, agrAmount, curDate, sacDate);

          // let pifAmt = 0;
          // let pifTax = 0;
          // let pifFlag = 0;
          // let agreementAmount = Number((clubObj[0].Amount - Number(clubObj[0].lateFee)).toFixed(2))
          // let epoAmount = Number(clubObj[0].EPOAmount.toFixed(2));

          // let sacAmt = 0;
          // if (clubObj[0].Agreementtype == "EPO Rent") {
          //   console.log("Inside If agrAmount----->", agreementAmount);
          //   sacAmt = agreementAmount - epoAmount;
          //   sacTax = Number(clubObj[0].rpayTaxAmount);
          //   pifFlag = 1;
          // }
          // else if (clubObj[0].Agreementtype == "PIF Rent") {
          //   pifAmt = Number((agreementAmount - clubObj[0].Taxamount).toFixed(2));
          //   pifTax = Number(clubObj[0].rpayTaxAmount);
          //   pifFlag = 1;

          // }
          if (clubObj[0].miscellaneousItemsNum == 0) {
            let ReceiptArray = {
              customerId: Number(customerId),
              schedule: clubObj[0].Schedule + "|" + clubObj[0].AgreementNumber,
              amountDue: Number(
                clubObj[0].Agreementtype !== "Club"
                  ? (clubObj[0].Amount - Number(clubObj[0].lateFee)).toFixed(2)
                  : 0
              ),
              ldwFeeAmount: clubObj[0].agrLdwAmount,
              clubPayment: Number(
                clubObj[0].Agreementtype === "Club"
                  ? Number(clubObj[0].Amount) - Number(clubObj[0].Taxamount)
                  : 0
              ),
              clubPaymentTax: Number(
                clubObj[0].Agreementtype === "Club" ? clubObj[0].Taxamount : 0
              ),
              creditOnAccountAdd: 0,
              creditOnAccountSub: Number(clubObj[0].COA),
              integratedPaymentSuspense: -1 * Number(clubObj[0].ipSuspense),
              initialPayment: Number(
                clubObj[0].Agreementtype !== "Club"
                  ? clubObj[0].number_of_paymentsmade === 0
                    ? 1
                    : 0
                  : 0
              ),
              netLateFee: 0,
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              wlDeposit: -1 * Number(clubObj[0].wlDeposit),
              htSuspense: 0, //Number(PaygridObj[0].htSuspense),
              suspenseAdd: Number(clubObj[0].suspenseAdd),
              suspenseSub: Number(clubObj[0].suspenseSub), //Number(PaygridObj[0].suspense),
              racTirePay: Number(clubObj[0].policyAmount), //Number(PaygridObj[0].racTirePay),
              agreementId:
                clubObj[0].Agreementtype !== "Club"
                  ? Number(clubObj[0].Agreementid)
                  : undefined,
              change: 0,
              totalTax: clubObj[0].Taxamount,
              sac: Number(0),
              sacTax: Number(0),
              ldwFeeAmountTax: Number(clubObj[0].ldwTaxAmount),
              rentalPaymentTax: Number(
                clubObj[0].Agreementtype !== "Club"
                  ? clubObj[0].rpayTaxAmount
                  : 0
              ),
              lateRentalPaymentfeeTax: Number(clubObj[0].lFeeTaxAmount),
              lateRentalPaymentfee: Number(clubObj[0].lateFee),
              racTirePayTax: Number(clubObj[0].policyTaxAmount),
              deliveryFee: Number(clubObj[0].deliveryAmount),
              deliveryFeeTax: Number(clubObj[0].deliveryAmountTax),
              processingFee: Number(clubObj[0].processingFee),
              processingFeeTax: Number(clubObj[0].processingFeeTax),
              carryRentAmountUsed:
                clubObj[0].FreeTime == null
                  ? Number(clubObj[0].carryRentAmountUsed)
                  : 0,
              carryRentTaxUsed:
                clubObj[0].FreeTime == null
                  ? Number(clubObj[0].carryRentTaxUsed)
                  : 0,
              //otherFee: Number(PaygridObj[0].mattressFee),
              //otherFeeTax: Number(PaygridObj[0].mattressFeeTax),
              extensionAmount: Number(clubObj[0].extensionAmount),
              pif: Number(0),
              pifTax: Number(0),
              convenienceFee: 0,
              convenienceFeeTax: 0,
              mattressFee: Number(clubObj[0].mattressFee),
            };
            console.log(ReceiptArray, "club auto-check receipt array");
            ReceiptCategoriesRequestParam.push(ReceiptArray);
          }

          if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
          AcceptclubPaymentParam = {
            paymentSchedule: clubObj[0].Schedule,
            originalDueDate: clubObj[0].CurrentDueDate,
            nextDueDate: clubObj[0].Nextduedate,
            numberOfDaysLate: clubObj[0].CurrentDaysLate,
            clubTax: String(clubObj[0].Taxamount.toFixed(2)),
            clubAmountDue: Number(Number(clubObj[0].Amount).toFixed(2)),
            editSchedule: clubObj[0].editSchedule,
          };
          console.log(AcceptclubPaymentParam, "club auto-check receipt array");
          if (
            dataVal.length > 0 &&
            dataVal[0] === String(clubObj[0].Agreementid)
          ) {
            let checkBox = obj;
            checkBox.checked = true;
          }
        }

        if (dataVal.length > 0 && dataVal[0] === agreementId) {
          let checkBox = obj;
          console.log(
            "went into bind grid conditon",
            dataVal[0],
            obj,
            agreementId
          );
          checkBox.checked = true;
          let PaygridObj = paygridvalues.filter(
            (x) => x.Agreementid == Number(agreementId)
          );
          if (
            PaygridObj[0].Agreementtype !== "Club" &&
            acceptPaymentParam.length === 0 &&
            PaygridObj[0].miscellaneousItemsNum == 0
          ) {
            console.log("PaygridObj", PaygridObj);
            let acceptPaymentArray = {
              extensionAmount: Number(PaygridObj[0].extensionAmount),
              noOfDaysFree: Number(PaygridObj[0].noOfDaysFree),
              // initialPaymentFlag: Number(
              //   PaygridObj[0].number_of_paymentsmade === 0 ? 1 : 0
              // ),
              initialPaymentFlag: 1,
              addTransferSusFlag: 0,
              schedule: PaygridObj[0].Schedule,
              agreementId: Number(PaygridObj[0].Agreementid),
              ldwFeeAmount: Number(PaygridObj[0].agrLdwAmount), // Added By Me
              agreementAmountDue: Number(
                fixedChange(
                  String(
                    Number(PaygridObj[0].Amount) +
                      (Number(PaygridObj[0].acceptEpoFlag) == 1
                        ? Number(PaygridObj[0].suspenseSub)
                        : 0)
                  )
                )
              ),
              remainingEpoAmount: Number(
                PaygridObj[0].EPOAmount == null
                  ? 0.0
                  : (PaygridObj[0].EPOAmount - PaygridObj[0].EPOTax).toFixed(2)
              ),
              promoFreeDays: Number(PaygridObj[0].PromoFreeDaysApplied),
              netLateFee: Number(PaygridObj[0].lFeeTaxAmount),
              carriedLateFee: 0, //Number(PaygridObj[0].carriedLateFee),
              numberOfDaysLate: PaygridObj[0].CurrentDaysLate,
              originalDueDate: PaygridObj[0].CurrentDueDate,
              nextDueDate: PaygridObj[0].Nextduedate,
              totalTax: Number(PaygridObj[0].Taxamount.toFixed(2)),
              policyAmount: Number(Number(PaygridObj[0].policyAmount)),
              deliveryFeeAmount: Number(PaygridObj[0].deliveryAmount),
              processingFeeAmount: Number(PaygridObj[0].processingFee),
              otherFeeAmount: Number(PaygridObj[0].mattressFee),
              editSchedule: PaygridObj[0].editSchedule,
              freeTime: PaygridObj[0].FreeTime, // Added By Me
              ldwActivate: undefined,
            };
            acceptPaymentParam.push(acceptPaymentArray);
            /* eslint-disable no-console */
            console.log(AcceptPayments, "accept payment array");
            console.log(acceptPaymentParam, "Temp created object");
          }
          checkBox.disabled = true;

          let objAPEnrollDate = PaygridObj[0].Autopay_enrollmentdate;
          let APUP = {
            IsAP: false,
            IsUP: false,
          };
          if (
            objAPEnrollDate !== "" &&
            objAPEnrollDate !== undefined &&
            objAPEnrollDate !== null
          ) {
            APUP.IsAP = false;
            APUP.IsUP = true;
          } else {
            APUP.IsAP = true;
            APUP.IsUP = false;
          }
          // else {
          //   obj.disabled = false;
          // }
        }

        //  else {
        //   obj.disabled = false;
        // }
        if (paygridvalues != undefined && paygridvalues.length > 0) {
          // if(paygridvalues.l)
          if (
            clubCheckDisabled &&
            dataVal[0] === paygridvalues[0].AgreementNumber
          ) {
            let checkBox = obj;
            checkBox.disabled = true;
          } else if (
            clubCheckDisabled != true &&
            dataVal[0] === paygridvalues[0].AgreementNumber
          ) {
            let checkBox = obj;
            checkBox.disabled = false;
          }
        }
      });
    }
  };

  const Bindremainingamount = () => {
    if (paygridvalues !== undefined && paygridvalues.length > 0) {
      if (agreementId !== "0") {
        console.log(
          "consoled paygridvalues on bindremaining amount---->",
          paygridvalues
        );

        let pagGridFilters = paygridvalues.filter(
          (x) => x.Agreementid == Number(agreementId)
        );

        console.log("Filtered Array ----> ", pagGridFilters);
        const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Add" && Number(e.newsuspense) > 0;
        });
        const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Used" && Number(e.newsuspense) > 0;
        });
        let addSusTotalAmount =
          addSuspenseAmount.length == 0
            ? 0.0
            : addSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let usedSusTotalAmount =
          useSuspenseAmount.length == 0
            ? 0.0
            : useSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

        let initialAgree = [];
        let initialClub = [];
        let resultSelecteAgre: any = [];
        if (Number(agreementId) != 0) {
          initialAgree = paygridvalues.filter(function (e) {
            return String(e.Agreementid) == String(agreementId);
          });
          initialClub = paygridvalues.filter(function (e) {
            return (
              String(e.Agreementtype) == "Club" && e.number_of_paymentsmade == 0
            );
          });
          resultSelecteAgre = [...initialAgree, ...initialClub];
        } else {
          resultSelecteAgre = paygridvalues.filter(function (e) {
            return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
          });
        }

        let amount = 0;
        for (let i = 0; i < resultSelecteAgre.length; i++) {
          amount = amount + Number(resultSelecteAgre[i].Amount);
        }
        let objAvailCreds = 0;
        if (Number(amount) <= Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds = amount;
          console.log(objAvailCreds, "avab credit total amount");
          setAvailableCreds(objAvailCreds);
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(amount).toFixed(2),
            },
          ]);
        } else if (Number(amount) >= Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds =
            Number(paygridvalues[0].COA) +
            Number(paygridvalues[0].wlDeposit) +
            Number(paygridvalues[0].ipSuspense);
          console.log(objAvailCreds, "avab credit total amount");
          setAvailableCreds(objAvailCreds);
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(paygridvalues[0].wlDeposit).toFixed(2),
            },
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
            },
          ]);
        } else if (Number(amount) == 0) {
          objAvailCreds =
            Number(paygridvalues[0].COA) + Number(paygridvalues[0].ipSuspense);
          console.log(objAvailCreds, "avab credit total amount");
          setAvailableCreds(objAvailCreds);
          setavailableCredsremaining([
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
            },
          ]);
        }
        setRemaniningAmtStatic(
          Number((pagGridFilters[0].Amount - objAvailCreds).toFixed(2))
        );
        setcoaamt(pagGridFilters[0].COA);
        setwldepositamount(pagGridFilters[0].wlDeposit);
        setipsuspense(pagGridFilters[0].ipSuspense);
        let chargeCardAmount = 0;
        let swipeCardAmount = 0;
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Ending with")
          ) {
            chargeCardAmount =
              chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Swipe ending")
          ) {
            swipeCardAmount =
              swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
        let CardAmount = Number(
          multipleCard.length == 0
            ? 0.0
            : multipleCard
                .map((bill) => bill.Amount)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
        );
        CardAmount =
          Number(CardAmount) +
          Number(chargeCardAmount) +
          Number(swipeCardAmount);
        let objTenderAmount =
          CardAmount + Number(addcash) + Number(totalamountcheck);
        let objpgGridAmount =
          Number(pagGridFilters[0].Amount) -
          objAvailCreds +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount);

        console.log("**Before If**", pagGridFilters.length);
        if (pagGridFilters !== undefined && pagGridFilters.length > 0) {
          console.log(
            "**Inside If**",
            Number(remainingamountdue),
            objTenderAmount,
            Number(objpgGridAmount.toFixed(2))
          );
          // if (
          //   pagGridFilters[0].store_number !=
          //   window.sessionStorage.getItem("storeNumber")
          // ) {
          //   setdiffStoreErrLbl(true);
          //   diffStoreError.push(pagGridFilters.Agreementid);
          // }
          if (Number(remainingamountdue) === 0 && objTenderAmount === 0) {
            console.log("**Inside If** - 1");
            setremainingamountdue(objpgGridAmount.toFixed(2));
            if (Number(objpgGridAmount.toFixed(2)) <= 0) {
              console.log("went into grid amount conditon");
              setAcceptpaymentbuttonenable(false);
              if (Number(objpgGridAmount.toFixed(2)) < 0) {
                setchange(-1 * Number(objpgGridAmount.toFixed(2)));
              } else {
                setchange("0.00");
              }
            } else {
              setchange("0.00");
              setAcceptpaymentbuttonenable(true);
            }
          } else if (objTenderAmount >= objpgGridAmount) {
            console.log("**Inside If** - 2");
            setremainingamountdue("0.00");
          } else if (objTenderAmount < objpgGridAmount) {
            console.log("**Inside If** - 3");
            setremainingamountdue(
              (objpgGridAmount - objTenderAmount).toFixed(2)
            );
          }
        } else {
          setremainingamountdue("0.00");
        }
        settotaldue(parseFloat(String(pagGridFilters[0].Amount)));
        settotaltax(parseFloat(String(pagGridFilters[0].Taxamount)));
        setinitagreementnumber(pagGridFilters[0].AgreementNumber);
        //AutoLoadAct_btn();
        if (totalduereceiptidsupport.length == 0) {
          let agreementDetails = {
            agreement_id:
              pagGridFilters[0].AgreementNumber == "Adjust"
                ? "Miscellaneous Item "
                : pagGridFilters[0].AgreementNumber,
            agreement_totalamount: parseFloat(
              fixedChange(String(objpgGridAmount))
            ),
          };
          totalduereceiptidsupport.push(agreementDetails);
          settotalduereceiptid(totalduereceiptidsupport);
        }
      }
      let pagGridFilters = paygridvalues.filter(
        (x) => x.Agreementtype == "Club"
      );
      let objAmtDue;
      if (
        pagGridFilters[0] != undefined &&
        pagGridFilters[0].Agreementtype === "Club"
      ) {
        objAmtDue = getpayment?.amountDue.amountDueResponse.filter(
          (x) => x.clubAmountDue !== undefined
        );
      }
      if (
        pagGridFilters[0] != undefined &&
        objAmtDue[0].initialPaymentFlag == 1 &&
        agreementId != "0"
      ) {
        console.log(
          "consoled paygridvalues on bindremaining amount---->",
          paygridvalues
        );
        console.log("Filtered Array ----> ", pagGridFilters);
        let agreementFilterAmt = paygridvalues.filter(
          (x) => x.Agreementid == Number(agreementId)
        );
        // if (
        //   pagGridFilters[0].store_number !=
        //   window.sessionStorage.getItem("storeNumber")
        // ) {
        //   setdiffStoreErrLbl(true);
        //   diffStoreError.push(pagGridFilters[0].Agreementid);
        // }
        const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Add" && Number(e.newsuspense) > 0;
        });
        const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Used" && Number(e.newsuspense) > 0;
        });
        let addSusTotalAmount =
          addSuspenseAmount.length == 0
            ? 0.0
            : addSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let usedSusTotalAmount =
          useSuspenseAmount.length == 0
            ? 0.0
            : useSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

        let initialAgree = [];
        let initialClub = [];
        let resultSelecteAgre: any = [];
        if (Number(agreementId) != 0) {
          initialAgree = paygridvalues.filter(function (e) {
            return String(e.Agreementid) == String(agreementId);
          });
          initialClub = paygridvalues.filter(function (e) {
            return (
              String(e.Agreementtype) == "Club" && e.number_of_paymentsmade == 0
            );
          });
          resultSelecteAgre = [...initialAgree, ...initialClub];
        } else {
          resultSelecteAgre = paygridvalues.filter(function (e) {
            return adjustDueDateSelectedAgre.indexOf(e.Agreementid) != -1;
          });
        }
        let amount = 0;
        for (let i = 0; i < resultSelecteAgre.length; i++) {
          amount = amount + Number(resultSelecteAgre[i].Amount);
        }
        let objAvailCreds = 0;
        if (Number(amount) <= Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds = amount;
          // Number(paygridvalues[index].COA) +
          // Number(paygridvalues[index].wlDeposit)
          // Number(paygridvalues[index].ipSuspense)
          console.log(objAvailCreds, "avab credit total amount");
          setAvailableCreds(objAvailCreds);
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(amount).toFixed(2),
            },
          ]);
        } else if (Number(amount) > Number(paygridvalues[0].wlDeposit)) {
          objAvailCreds =
            Number(paygridvalues[0].COA) +
            Number(paygridvalues[0].wlDeposit) +
            Number(paygridvalues[0].ipSuspense);
          console.log(objAvailCreds, "avab credit total amount");
          setAvailableCreds(objAvailCreds);
          setavailableCredsremaining([
            {
              balanceType: "WEBLEAD",
              accountBalance: Number(paygridvalues[0].wlDeposit).toFixed(2),
            },
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
            },
          ]);
        } else if (Number(amount) == 0) {
          objAvailCreds =
            Number(paygridvalues[0].COA) + Number(paygridvalues[0].ipSuspense);
          console.log(objAvailCreds, "avab credit total amount");
          setAvailableCreds(objAvailCreds);
          setavailableCredsremaining([
            {
              balanceType: "COA",
              accountBalance: Number(paygridvalues[0].COA).toFixed(2),
            },
            {
              balanceType: "IP Suspence",
              accountBalance: Number(paygridvalues[0].ipSuspense).toFixed(2),
            },
          ]);
        }
        setRemaniningAmtStatic(
          Number(
            (
              pagGridFilters[0].Amount +
              agreementFilterAmt[0].Amount -
              objAvailCreds +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          )
        );
        setcoaamt(agreementFilterAmt[0].COA);
        setwldepositamount(agreementFilterAmt[0].wlDeposit);
        setipsuspense(agreementFilterAmt[0].ipSuspense);
        let chargeCardAmount = 0;
        let swipeCardAmount = 0;
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Ending with")
          ) {
            chargeCardAmount =
              chargeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        for (let i = 0; i < tendertypeglobalarray.length; i++) {
          if (
            tendertypeglobalarray[i].tendertype
              .split(" - ")[0]
              .includes("Card Swipe ending")
          ) {
            swipeCardAmount =
              swipeCardAmount + Number(tendertypeglobalarray[i].Amount);
          }
        }
        chargeCardAmount = chargeCardAmount + Number(chargeCardAmt);
        let CardAmount = Number(
          multipleCard.length == 0
            ? 0.0
            : multipleCard
                .map((bill) => bill.Amount)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
        );
        CardAmount =
          Number(CardAmount) +
          Number(chargeCardAmount) +
          Number(swipeCardAmount);
        let objTenderAmount =
          CardAmount + Number(addcash) + Number(totalamountcheck);
        let objpgGridAmount =
          Number(pagGridFilters[0].Amount) +
          Number(agreementFilterAmt[0].Amount) -
          objAvailCreds +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount);
        let overallAmtForBind =
          Number(pagGridFilters[0].Amount) +
          Number(agreementFilterAmt[0].Amount);
        let overallTaxAmtForBind =
          Number(pagGridFilters[0].Taxamount) +
          Number(agreementFilterAmt[0].Taxamount);

        console.log("**Before If**", pagGridFilters.length);
        if (pagGridFilters !== undefined && pagGridFilters.length > 0) {
          console.log(
            "**Inside If**",
            Number(remainingamountdue),
            objTenderAmount,
            Number(objpgGridAmount.toFixed(2))
          );
          if (Number(remainingamountdue) === 0 && objTenderAmount === 0) {
            console.log("**Inside If** - 1");
            setremainingamountdue(objpgGridAmount.toFixed(2));
            if (Number(objpgGridAmount.toFixed(2)) <= 0) {
              console.log("went into grid amount conditon");
              setAcceptpaymentbuttonenable(false);
              if (Number(objpgGridAmount.toFixed(2)) < 0) {
                setchange(-1 * Number(objpgGridAmount.toFixed(2)));
              } else {
                setchange("0.00");
              }
            } else {
              setchange("0.00");
              setAcceptpaymentbuttonenable(true);
            }
          } else if (objTenderAmount >= objpgGridAmount) {
            console.log("**Inside If** - 2");
            setremainingamountdue("0.00");
          } else if (objTenderAmount < objpgGridAmount) {
            console.log("**Inside If** - 3");
            setremainingamountdue(
              (objpgGridAmount - objTenderAmount).toFixed(2)
            );
          }
        } else {
          setremainingamountdue("0.00");
        }
        settotaldue(parseFloat(String(overallAmtForBind)));
        settotaltax(parseFloat(String(overallTaxAmtForBind)));
        //AutoLoadAct_btn();
        let agreementDetails = {
          agreement_id:
            pagGridFilters[0].AgreementNumber == "Adjust"
              ? "Miscellaneous Item " + pagGridFilters[0].miscellaneousItemsNum
              : pagGridFilters[0].AgreementNumber,
          agreement_totalamount: parseFloat(
            fixedChange(String(pagGridFilters[0].Amount))
          ),
        };
        totalduereceiptidsupport.push(agreementDetails);
        settotalduereceiptid(totalduereceiptidsupport);
        if (paygridvalues.length == 2) {
          setOverAll_Chk(true);
        }
      }
    } else {
      setremainingamountdue("0.00");
    }
  };
  const handleOnClick = () => {};
  const Agreementtypebinding = (agrtype: any) => {
    if (agrtype === "Rental Agreement (RTO)") {
      return "Rent";
    } else {
      return agrtype;
    }
  };
  /*
  {
    BindGrid() method is used to bind the Agreements grid.
    Here the map function is used for the paygrid values array which consist of
    array objects of objects is used. gridhide state value is used to hide or unhide the
    data according to the accordion click.
  }
  */

  const BindGrid = () => (
    <>
      {console.log("paygridvaluesdfdsd525554", paygridvalues)}
      {paygridvalues != undefined && paygridvalues.length > 0 ? (
        paygridvalues.map((value, index: any) => {
          if (
            storeNumber !== value.store_number &&
            value.Agreementtype !== "Club" &&
            String(value.store_number) != "0"
          ) {
            storeNumber = value.store_number;
            newStore = true;
          } else {
            newStore = false;
          }
          const isAPflagPresent: any = paygridvalues.findIndex(
            (el: any, i: any) => {
              if (
                el.Autopay_enrollmentdate !== "" &&
                el.Autopay_enrollmentdate !== undefined &&
                el.Autopay_enrollmentdate !== "null" &&
                el.Autopay_enrollmentdate !== null
              ) {
                return i;
              }
            }
          );
          console.log("isAPflagPresent", isAPflagPresent);
          return (
            <React.Fragment key={index}>
              {
                /*value.Agreementtype!=='Club' This condition is added because the club agreement should not come under any store-Mano Ranjith */
                newStore === true &&
                Number(configFlags.isPaymentFunctionsFeatureFlag) == 1 ? (
                  <RACTableRow
                    className={` ${classes.rowColor} ${classes.font12}`}
                  >
                    <RACTableCell className={` ${classes.paymentStore}`}>
                      {value.store_number}
                    </RACTableCell>
                    <RACTableCell
                      colSpan={9}
                      className={` ${classes.paymentStore}`}
                    >
                      {"- " +
                        value.storeCityName +
                        ", " +
                        value.stateProvinceName}
                    </RACTableCell>
                  </RACTableRow>
                ) : null
              }
              <RACTableRow className={` ${classes.rowColor} ${classes.font12}`}>
                <RACTableCell
                  className={` ${classes.checkboxwidth} ${classes.font12}`}
                >
                  <input
                    style={{ marginTop: "6px", marginLeft: "19px" }}
                    type="checkbox"
                    name="grid"
                    id={"chk" + index}
                    onChange={(e) => {
                      settotaldueamount(
                        "chk" + index,
                        index,
                        value.Amount,
                        value.Agreementid,
                        e
                      );
                    }}
                    value={value.Agreementid + "|" + value.Amount}
                    data-testid={"subcheckbox" + index}
                  />
                  {/* {clubCheckDisabled == true && index==0 ?
                      <input
                        style={{ marginTop: '6px', marginLeft: '19px' }}
                        type="checkbox"
                        name="grid"
                        disabled
                        id={"chk" + index}
                        onChange={(e) => {
                          settotaldueamount(
                            "chk" + index,
                            index,
                            value.Amount,
                            value.Agreementid,
                            e
                          );
                        }}
                        value={value.Agreementid + "|" + value.Amount}
                        data-testid={"subcheckbox" + index}
                      />
                    : } */}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {value.Agreementtype !== "Club" ? (
                    <span
                      onClick={() => {
                        value.miscellaneousItemsNum == 0
                          ? redirectionToAgreement(String(value.Agreementid))
                          : redirectionToCashSale(value.miscellaneousItemsNum);
                      }}
                      className={
                        value.CurrentDaysLate === 0
                          ? `${classes.fontchange} ${classes.racpadlink} ${classes.racpadlink} ${classes.racpadpaymentsuccess} ${classes.racpadagrlink} ${classes.Aghover}`
                          : `${classes.fontchange} ${classes.racpadlink} ${classes.racpadagrlink} ${classes.racpadpaymentfails} ${classes.Aghover}`
                      }
                    >
                      {value.AgreementNumber}
                      {value.Autopay_enrollmentdate !== "" &&
                      value.Autopay_enrollmentdate !== undefined &&
                      value.Autopay_enrollmentdate !== "null" &&
                      value.Autopay_enrollmentdate !== null ? (
                        <span className={classes.badgeContainer}>AP</span>
                      ) : null}
                    </span>
                  ) : (
                    <span
                      className={
                        value.CurrentDaysLate === 0
                          ? `${classes.fontchange} ${classes.racpadlink} ${classes.racpadlink} ${classes.racpadpaymentsuccess} ${classes.racpadagrlink} ${classes.nonLinkText}`
                          : `${classes.fontchange} ${classes.racpadlink} ${classes.racpadagrlink} ${classes.racpadpaymentfails} ${classes.nonLinkText}`
                      }
                    >
                      {value.AgreementNumber}
                      {value.Autopay_enrollmentdate !== "" &&
                      value.Autopay_enrollmentdate !== undefined &&
                      value.Autopay_enrollmentdate !== "null" &&
                      value.Autopay_enrollmentdate !== null ? (
                        <span className={classes.badgeContainer}>AP</span>
                      ) : null}
                    </span>
                  )}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {Agreementtypebinding(value.Agreementtype)}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.font12}`}
                  title={tooltipdesdisplay}
                  onMouseOver={() => {
                    descriptiontooltip(value.Agreementdesc);
                  }}
                  data-testid={"maingridtooltipdisplay" + index}
                >
                  {isAPflagPresent !== -1
                    ? value.Agreementdesc.substring(0, 10) + "..."
                    : value.Agreementtype !== "Club"
                    ? value.Agreementdesc.substring(0, 25) + "..."
                    : value.Agreementdesc}
                  {/* {value.Autopay_enrollmentdate !== "" &&
                      value.Autopay_enrollmentdate !== undefined &&
                      value.Autopay_enrollmentdate !== "null" &&
                      value.Autopay_enrollmentdate !== null && value.Agreementtype !== "Club"  ? value.Agreementdesc.substring(0, 10)+"...":value.Agreementtype !== "Club"
                      ? value.Agreementdesc.substring(0, 25) + "..."
                      : value.Agreementdesc} */}
                  {/* {value.Agreementtype !== "Club"
                    ? value.Agreementdesc.substring(0, 25) + "..."
                    : value.Agreementdesc} */}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {value.Schedule}
                </RACTableCell>
                <RACTableCell className={`${classes.font12}`}>
                  {changeDateformat(value.CurrentDueDate)}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.dueDateModified == true
                      ? `${classes.modifiedGrid} ${classes.font12}`
                      : `${classes.font12}`
                  }
                >
                  {value.Nextduedate !== "PIF" &&
                  value.Nextduedate !== "SAC" &&
                  value.Nextduedate !== "EPO"
                    ? changeDateformat(value.Nextduedate)
                    : value.Nextduedate}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.textend} ${classes.font12}`}
                >
                  {value.CurrentDaysLate}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.textend} ${classes.font12} ${classes.amounttxtstyle}`}
                >
                  <span>$</span>
                  {Number.isNaN(Number(value.Amount))
                    ? 0.0
                    : Number(value.Amount) +
                        Number(value.suspenseAdd) -
                        (Number(value.acceptEpoFlag) == 1
                          ? 0.0
                          : Number(value.suspenseSub)) <
                      0
                    ? "(" +
                      (
                        -1 *
                        (Number(value.Amount) +
                          Number(value.suspenseAdd) -
                          (Number(value.acceptEpoFlag) == 1
                            ? 0.0
                            : Number(value.suspenseSub)))
                      ).toFixed(2) +
                      ")"
                    : (
                        Number(value.Amount) +
                        Number(value.suspenseAdd) -
                        (Number(value.acceptEpoFlag) == 1
                          ? 0.0
                          : Number(value.suspenseSub))
                      ).toFixed(2)}
                </RACTableCell>
                <RACTableCell
                  data-bs-toggle="collapse"
                  data-bs-target="#row-1"
                  //className={`${classes.accordiontoggle} ${classes.font12}`}
                  aria-expanded={gridhide == index ? true : false}
                >
                  {gridhide === index ? (
                    <AccordianOpenIcon
                      className={`${classes.accordionIconGrid} ${classes.me2}`}
                      id={index + "t1"}
                      onClick={() => Accordionclick(index)}
                    />
                  ) : (
                    <AccordianCloseIcon
                      className={`${classes.accordionIconGrid} ${classes.me2} ${classes.accordionGridClose}`}
                      id={index + "t1"}
                      onClick={() => Accordionclick(index)}
                    />
                  )}
                </RACTableCell>
              </RACTableRow>
              {BindHiddenRow(value, index)}
            </React.Fragment>
          );
        })
      ) : (
        <RACTableRow className={classes.hiddenRow} key="0">
          <RACTableCell colSpan={9} className={classes.textcenter}>
            No Records Found
          </RACTableCell>
        </RACTableRow>
      )}
    </>
  );

  const BindHiddenRow = (value, index: any) => {
    if (gridhide === index) {
      return (
        <RACTableRow data-testid="gridhidediv">
          <RACTableCell
            colSpan={10}
            className={`${classes.hiddenRow} ${classes.p0}`}
          >
            <Grid
              item
              className={`${classes.backgroundcol} ${classes.floatLeft} ${classes.pb0} ${classes.px5} ${classes.py2} ${classes.w100}`}
              id="row-2"
            >
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  SAC Date
                </Box>
                <Box
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {value.SAC_Date == "-"
                    ? "-"
                    : changeDateformat(value.SAC_Date)}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Suspense Amount
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  ${value.SuspenseAmount}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  EPO Amount
                </Box>
                {value.agreementRateDetails.employeePurchasePlan === "Y" ? (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    -
                  </Box>
                ) : (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    ${Number(fixedChange(String(Number(value.EPOAmount))))}
                  </Box>
                )}
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  #Late last 10
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {value.Latelast10}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Promo Free Days
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {value.PromoFreeDays}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.raccollg2} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Autopay
                </Box>
                {value.Autopay_enrollmentdate !== "" &&
                value.Autopay_enrollmentdate !== undefined &&
                value.Autopay_enrollmentdate !== "null" &&
                value.Autopay_enrollmentdate !== null &&
                value.Autopay !== undefined &&
                value.Autopay !== 0 &&
                value.Autopay !== null ? (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    {value.Autopay == "null" || value.Autopay == null
                      ? "-"
                      : "Card ending with " + value.Autopay}
                  </Box>
                ) : (
                  <Box
                    component="span"
                    className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                  >
                    -
                  </Box>
                )}
              </Grid>
            </Grid>
          </RACTableCell>
        </RACTableRow>
      );
    } else {
      return null;
    }
  };
  const continueAMClicked = () => {
    let filteredNonClubData = checkedAgr.filter(
      (obj) => obj.Agreementtype !== "Club"
    );
    let filteredClubData = paygridvalues.filter(
      (obj) => obj.Agreementtype == "Club"
    );

    let agreementPayload: any = [];
    filteredNonClubData.map((obj, index) => {
      for (const [key, value] of Object.entries(obj)) {
        const isAgreementThere = agreementPayload.findIndex(
          (el) => Number(el.agreementId) === Number(obj.Agreementid)
        );
        if (key === "dueDateModified" && value === true) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "DDA",
              value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              transactions: [
                {
                  type: "DDA",
                  value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
                },
              ],
            });
          }
        } else if (key === "editSchedule" && value !== "") {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "ES",
              value: obj.editSchedule,
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              transactions: [
                {
                  type: "ES",
                  value: obj.editSchedule,
                },
              ],
            });
          }
        } else if (key === "FreeTime" && value !== null) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push(
              {
                type: "FTT",
                value: obj.FreeTime.freeTimeType,
              },
              {
                type: "WOS",
                value:
                  obj.FreeTime.isOptServiceWaived == true ? "True" : "False",
              },
              {
                type: "DDA",
                value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
              }
            );
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              transactions: [
                {
                  type: "FTT",
                  value: obj.FreeTime.freeTimeType,
                },
                {
                  type: "WOS",
                  value:
                    obj.FreeTime.isOptServiceWaived == true ? "True" : "False",
                },
                {
                  type: "DDA",
                  value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
                },
              ],
            });
          }
        } else if (key === "acceptEpoFlag" && value === 1) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push(
              {
                type: "AEF",
                value: "True",
              },
              {
                type: "AEPDA",
                value: String(obj.pastDueRent.toFixed(2)),
              },
              {
                type: "AEA",
                value: String(obj.editedEPOAmount.toFixed(2)),
              }
            );
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              transactions: [
                {
                  type: "AEF",
                  value: "True",
                },
                {
                  type: "AEPDA",
                  value: String(obj.pastDueRent.toFixed(2)),
                },
                {
                  type: "AEA",
                  value: String(obj.editedEPOAmount.toFixed(2)),
                },
              ],
            });
          }
        } else if (key === "suspenseAdd" && Number(value) > 0) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "AS",
              value: String(obj.suspenseAdd),
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              transactions: [
                {
                  type: "AS",
                  value: String(obj.suspenseAdd),
                },
              ],
            });
          }
        } else if (key === "suspenseSub" && Number(value) > 0) {
          if (isAgreementThere >= 0) {
            agreementPayload[isAgreementThere].transactions.push({
              type: "US",
              value: String(obj.suspenseSub),
            });
          } else {
            agreementPayload.push({
              agreementId: obj.Agreementid,
              transactions: [
                {
                  type: "US",
                  value: String(obj.suspenseSub),
                },
              ],
            });
          }
        }
      }
    });
    if (actLDWArr.length) {
      actLDWArr.map((ldwObj, ldwIndex) => {
        const isAgreementThere = agreementPayload.findIndex(
          (el) => Number(el.agreementId) === Number(ldwObj.agreementId)
        );
        if (isAgreementThere >= 0) {
          agreementPayload[isAgreementThere].transactions.push({
            type: ldwObj.action === 1 ? "AOS" : "TOS",
            value: "LDW",
          });
        } else {
          agreementPayload.push({
            agreementId: String(ldwObj.agreementId),
            transactions: [
              {
                type: ldwObj.action === 1 ? "AOS" : "TOS",
                value: "LDW",
              },
            ],
          });
        }
      });
    }
    if (actPolicyArr.length) {
      actPolicyArr.map((policyObj) => {
        const isAgreementThere = agreementPayload.findIndex(
          (el) => Number(el.agreementId) === Number(policyObj.agreementId)
        );
        if (isAgreementThere >= 0) {
          agreementPayload[isAgreementThere].transactions.push({
            type: policyObj.action === 1 ? "AOS" : "TOS",
            value: "Policy",
          });
        } else {
          agreementPayload.push({
            agreementId: String(policyObj.agreementId),
            transactions: [
              {
                type: policyObj.action === 1 ? "AOS" : "TOS",
                value: "Policy",
              },
            ],
          });
        }
      });
    }
    if (totalsuspensemaster.length) {
      let filteredTransferData = totalsuspensemaster.filter(
        (obj) => obj.sustype == "TransferAdd" || obj.sustype == "TransferUsed"
      );
      if (filteredTransferData.length) {
        filteredTransferData.map((transferObject) => {
          const isAgreementThere = agreementPayload.findIndex(
            (el) =>
              Number(el.agreementId) === Number(transferObject.agreementnumber)
          );
          if (isAgreementThere >= 0) {
            if (transferObject.sustype === "TransferUsed") {
              agreementPayload[isAgreementThere].transactions.push({
                type: "TUS",
                value: String(transferObject.newsuspense),
              });
            } else if (transferObject.sustype === "TransferAdd") {
              agreementPayload[isAgreementThere].transactions.push({
                type: "TAS",
                value: String(transferObject.newsuspense),
              });
            }
          } else {
            let transactionsData: any = [];
            if (transferObject.sustype === "TransferUsed") {
              transactionsData.push({
                type: "TUS",
                value: String(transferObject.newsuspense),
              });
            } else if (transferObject.sustype === "TransferAdd") {
              transactionsData.push({
                type: "TAS",
                value: String(transferObject.newsuspense),
              });
            }
            agreementPayload.push({
              agreementId: String(transferObject.agreementnumber),
              transactions: transactionsData,
            });
          }
        });
      }
    }
    let clubPayLoad: any = [];
    filteredClubData.map((obj, index) => {
      for (const [key, value] of Object.entries(obj)) {
        const isClubAgreementThere = clubPayLoad.findIndex(
          (el) => Number(el.clubId) === Number(obj.customerClubId)
        );
        if (key === "dueDateModified" && value === true) {
          if (isClubAgreementThere >= 0) {
            clubPayLoad[isClubAgreementThere].transactions.push({
              type: "DDA",
              value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
            });
          } else {
            clubPayLoad.push({
              clubId: String(obj.customerClubId),
              transactions: [
                {
                  type: "DDA",
                  value: moment(obj.Nextduedate).format("YYYY-MM-DD"),
                },
              ],
            });
          }
        } else if (key === "editSchedule" && value !== "") {
          if (isClubAgreementThere >= 0) {
            clubPayLoad[isClubAgreementThere].transactions.push({
              type: "ES",
              value: obj.editSchedule,
            });
          } else {
            clubPayLoad.push({
              clubId: String(obj.customerClubId),
              transactions: [
                {
                  type: "ES",
                  value: obj.editSchedule,
                },
              ],
            });
          }
        } else if (key === "isClubDeactivateTriggered" && value == true) {
          if (isClubAgreementThere >= 0) {
            clubPayLoad[isClubAgreementThere].transactions.push({
              type: "DC",
              value: "True",
            });
          } else {
            clubPayLoad.push({
              clubId: String(obj.customerClubId),
              transactions: [
                {
                  type: "DC",
                  value: "True",
                },
              ],
            });
          }
        }
      }
    });

    console.log("agreementPayload to Programiz!", agreementPayload);
    console.log("clubPayLoad to Programiz!", clubPayLoad);

    history.push({
      pathname: `/am/customer/${customerId}`,
      state: {
        commitmentDetails: {
          commitmentType: location?.state?.commitmentData?.commitmentType,
          commitmentDate: location?.state?.commitmentData?.commitmentDate,
          commitmentTime: location?.state?.commitmentData?.commitmentTime,
          notes: location?.state?.commitmentData?.commitmentNotes,
          amount: String(
            (Number(totaldue) + Number(carryRentAmount)).toFixed(2)
          ),
          agreements:
            agreementPayload.length > 0 ? agreementPayload : undefined,
          club: clubPayLoad.length > 0 ? clubPayLoad[0] : undefined,
        },
      },
    });
  };

  useEffect(() => {
    if (configFlags.isPaymentFunctionsFeatureFlag == 1) {
      getAlerts();
    }
  }, [configFlags]);

  const getAlerts = async () => {
    const getAlerts = await GetAlert(customerId);
    /* eslint-disable no-console */
    console.log("result inside getAlerts", getAlerts);
    buildActiveAlerts();
    //Alert list
    setobjAlertdata(getAlerts.alert !== undefined ? getAlerts.alert : []);
  };

  const buildActiveAlerts = async () => {
    let getAlerts: any = [];
    //if (updatedAlert == undefined || updatedAlert == null) {
    getAlerts = await GetCustomerAlert(customerId);
    getAlerts =
      getAlerts?.customerAlertByIdDTO?.value?.customeralert !== undefined
        ? getAlerts?.customerAlertByIdDTO?.value?.customeralert
        : undefined;

    const activeAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter((val: any) => val.alertCleared == 0)
        : [];
    const popupAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter(
            (val: any) =>
              val.alertCleared == 0 &&
              val.alertTypeId !== "3" &&
              val.alertTypeId !== 3
          )
        : [];

    const alertLoad: any = [];
    let activeAlertcount = 0;
    if (getAlerts !== undefined && getAlerts.length > 0) {
      for (let i = 0; i < getAlerts.length; i++) {
        alertLoad.push({
          alertTypeId: getAlerts[i].alertTypeId,
          alertCleared: getAlerts[i].alertCleared,
        });
        if (
          getAlerts[i].alertCleared == 0 ||
          getAlerts[i].alertCleared == "0"
        ) {
          activeAlertcount = activeAlertcount + 1;
        }
      }
    }
    setAlertLoadCount(activeAlertcount);
    setAlertLoad(alertLoad);
    setAlertWizard(getAlerts !== undefined ? getAlerts : []);
    //active alert
    setActiveAlert(activeAlerts);
    //Alerts without custom alerts
    setAlertUpdateObj([...popupAlerts]);
  };

  const CustomAlertval = (e: any) => {
    // //debugger;
    const alertValue = formatTextChange(e.target.value);
    setCustomAlerttxt(alertValue);
    document.getElementById("CustomAlert")?.focus();
    if (alertValue == undefined || alertValue == null || alertValue == "") {
      setdisableAlert(true);
    } else {
      setdisableAlert(false);
    }
  };
  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const Assignalertclear = (
    action: "add" | "remove",
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    // eslint-disable-next-line no-debugger
    ////debugger;
    // if (
    //   (alertTypeId == "2" || alertTypeId == "4" || alertTypeId == "5") &&
    //   action == "add"
    // ) {
    //   setAlertAuthenticationNeeded(
    //     action ? newAlert : { removeAlert: true, alertTypeId: alertTypeId }
    //   );
    //   setauthenType("ALERT");
    //   racCoWorkerFunc("Assign Alert");
    // }

    let activeAlertObj: any = AlertUpdateObj;
    if (
      alertTypeId !== undefined &&
      alertTypeId !== null &&
      alertTypeId == "3"
    ) {
      if (action == "remove") {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    activeAlertObj = alterAlertValue(action, newAlert, alertTypeId);
    /* eslint-disable no-console */
    // console.log('val', val);

    setAlertUpdateObj([...activeAlertObj]);
  };
  const alterAlertValue = (
    action: "add" | "remove",
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    let activeAlertObj: any = AlertUpdateObj;

    if (action == "remove") {
      if (alertTypeId != "3") {
        const editAlert = activeAlertObj.findIndex(
          (val: any) => val.alertTypeId == alertTypeId
        );
        if (editAlert !== -1) {
          activeAlertObj[editAlert].alertCleared = 1;
        }
      } else {
        activeAlertObj = activeAlertObj.filter(
          (val: any) => val.alertTypeId != "3"
        );
      }
    } else {
      // if (alertTypeId !== "2" && alertTypeId !== "4" && alertTypeId !== "5") {
      const editAlert = activeAlertObj.findIndex(
        (val: any) => val.alertTypeId == alertTypeId
      );
      if (editAlert !== -1) {
        activeAlertObj[editAlert].alertCleared = 0;
      } else {
        newAlert.alertCleared = 0;
        activeAlertObj.push(newAlert);
      }
      // }
    }
    return activeAlertObj;
  };
  const alterAlert = (
    action: "add" | "remove",
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    let activeAlertObj: any = AlertUpdateObj;
    if (
      alertTypeId !== undefined &&
      alertTypeId !== null &&
      alertTypeId == "3"
    ) {
      if (action == "remove") {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    if (action == "remove") {
      if (alertTypeId != "3") {
        const editAlert = activeAlertObj.findIndex(
          (val: any) => val.alertTypeId == alertTypeId
        );
        if (editAlert !== -1) {
          activeAlertObj[editAlert].alertCleared = 1;
        }
      } else {
        activeAlertObj = activeAlertObj.filter(
          (val: any) => val.alertTypeId != "3"
        );
      }
    } else {
      if (alertTypeId !== "2" && alertTypeId !== "4" && alertTypeId !== "5") {
        newAlert.alertCleared = 0;
        activeAlertObj.push(newAlert);
      }
    }
    return activeAlertObj;
  };

  const RemoveAlert = async (removeAlert: any, index: any) => {
    try {
      // const ObjAlertWizard: any = AlertWizard;
      // ObjAlertWizard.splice(index, 1);
      const payload: any = {
        alertTypes: removeAlert,
        customerId: parseInt(customerId),
        userId: "191197",
      };

      /* eslint-disable no-console */
      console.log("payload", payload);
      /* eslint-disable no-console */
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      /* eslint-disable no-console */
      console.log("response", response);
      /* eslint-disable no-console */
      if (response !== undefined && response !== null) {
        buildActiveAlerts();
        //getAlerts();
      }
      setIsAlertLoading(false);
    } catch (e: any) {
      console.log("error while removing alert");
      setIsAlertLoading(false);
    }
  };

  function openAlert() {
    setIsOpen(true);
  }

  function closeAlert() {
    setIsOpen(false);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignalert = () => {
    if (
      objAlertdata !== undefined &&
      objAlertdata !== null &&
      objAlertdata.length > 0
    ) {
      const objAlertdataL = objAlertdata.sort((a: any, b: any) =>
        a["alertTypeDescEn"] < b["alertTypeDescEn"] ? -1 : 1
      );
      return objAlertdataL.map((val: any, index: any) => {
        //const AlertWizardList: any = [];
        let inActiveAlert: any = [];
        const isAlertActive: boolean =
          AlertUpdateObj.filter(
            (val1: any) =>
              val1.alertTypeId == val.alertTypeId && val1.alertCleared == 0
          ).length > 0
            ? true
            : false;
        if (isAlertActive === false) {
          // inActiveAlert = inActiveAlerts.filter(
          //   (val1: any) => val1.alertTypeId == val.alertTypeId
          // );
          inActiveAlert = {
            alertText: "",
            alertTypeDescEn: val.alertTypeDescEn,
            alertTypeDescEs: val.alertTypeDescEs,
            alertCleared: 0,
            alertTypeId: val.alertTypeId,
            customerAlertId: "",
          };
          // inActiveAlert !== undefined &&
          // inActiveAlert !== null &&
          // inActiveAlert.length > 0
          //   ? {
          //       alertText: '',
          //       alertTypeDescEn: inActiveAlert[0].alertTypeDescEn,
          //       alertTypeDescEs: inActiveAlert[0].alertTypeDescEs,
          //       alertCleared: 0,
          //       alertTypeId: inActiveAlert[0].alertTypeId,
          //       customerAlertId: '',
          //     }
          //   : {
          //       alertText: '',
          //       alertTypeDescEn: val.alertTypeDescEn,
          //       alertTypeDescEs: val.alertTypeDescEs,
          //       alertCleared: 0,
          //       alertTypeId: val.alertTypeId,
          //       customerAlertId: '',
          //     };
        }
        //AlertWizard.filter((val1:any)=>val1.alertTypeId == val.alertTypeId && val.alertTypeId != "3")

        //let OtherAlert: any = [];
        let isOtherAlert = false;
        //let OtherAlertObj: any = [];
        if (index == objAlertdataL.length - 1) {
          // OtherAlert = objAlertdataL.filter(
          //   (val: any) => val.alertTypeId == '3'
          // );
          isOtherAlert =
            AlertUpdateObj.filter((val1: any) => val1.alertTypeId == "3")
              .length > 0
              ? true
              : false;
        }
        // const disableAlert =
        //   val.alertTypeId == '2' ||
        //   val.alertTypeId == '4' ||
        //   val.alertTypeId == '5'
        //     ? !isSecondAuthenValid
        //     : false;
        return index == objAlertdataL.length - 1 ? (
          <React.Fragment>
            {buildAlert(isAlertActive, index, inActiveAlert, val)}
            {buildAlert(
              isOtherAlert,
              index + 1,
              {
                alertCleared: 0,
                alertText: "",
                alertTypeDescEn: "Other",
                alertTypeDescEs: null,
                alertTypeId: "3",
                customerAlertId: "",
              },
              {
                alertTypeDescEn: "Other",
                alertTypeDescEs: null,
                alertTypeId: "3",
              }
            )}
          </React.Fragment>
        ) : val.alertTypeId !== "3" ? (
          buildAlert(isAlertActive, index, inActiveAlert, val)
        ) : null;
      });
    } else if (objAlertdata.length == 0) {
      return (
        <Grid item className={`${classes.racCol2} ${classes.spacerMR2}`}>
          <Grid
            item
            style={{ marginLeft: "160px" }}
            className={`${classes.customerNorecordsAlert} ${classes.ms2}`}
          >
            <CircularProgress className={classes.alertLoader} />
          </Grid>
        </Grid>
      );
    }
  };

  const buildAlert = (
    isAlertExist: boolean,
    index: any,
    addAlertObj: any,
    val: any
  ) => {
    return (
      <Grid className={`${classes.p0} ${classes.mb3}`} key={index}>
        <RACCheckBox
          name="assignAlertChecked"
          checked={isAlertExist}
          onChange={() =>
            Assignalertclear(
              isAlertExist === false ? "add" : "remove",
              addAlertObj,
              val.alertTypeId
            )
          }
        />
        {val.alertTypeDescEn}
      </Grid>
    );
  };

  const formatPhone = (input: any) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, "($1) $2-$3");
  };

  const AlertMessages = () => {
    if (activeAlert !== undefined && activeAlert !== null) {
      return activeAlert.map((val: any, index: any) => {
        const alertActive = AlertLoad.findIndex(
          (alert: any) =>
            alert.alertTypeId == val.alertTypeId && alert.alertCleared == 0
        );
        if (alertActive !== -1) {
          return (
            <span
              className={`${classes.alertWidgetStyle} ${classes.me2}`}
              key={index}
              id={index}
            >
              {
                /*customAlertSpan ? null : (*/
                val.alertTypeId !== "3" && val.alertTypeId !== 3 ? (
                  <a
                    // onClick={(e: any) => ScrollToPage(val.alertTypeId, e)}
                    onClick={() => redirectToCustScrollToPage(val.alertTypeId)}
                    className={classes.me2}
                  >
                    <ExpandAlert></ExpandAlert>
                  </a>
                ) : null
                /*)*/
              }
              {val.alertTypeDescEn == "Other"
                ? val.alertText
                : val.alertTypeDescEn}
              <a className={classes.ms2}>
                <ExpandCancel
                  onClick={() =>
                    RemoveAlert(
                      [
                        {
                          alertTypeId: parseInt(val.alertTypeId),
                          customerAlertId: parseInt(val.customerAlertId),
                          alertText: "",
                          alertClear: 1,
                        },
                      ],
                      index
                    )
                  }
                ></ExpandCancel>
              </a>
            </span>
          );
        }
      });
    }
  };

  const ScrollToPage = (Id: any, e: any) => {
    e.preventDefault();
    const elementId: string =
      Id == "6" ? "addressDiv" : Id == "9" ? "ParentContactInformation" : "";

    const Tab: any = Id == "7" ? 1 : Id == "11" ? 2 : "";
    if (elementId !== "") {
      const titleElement = document.getElementById(elementId);
      if (titleElement !== null) {
        titleElement.scrollIntoView({ behavior: "smooth" });
      }

      //Need to add redirection to Paymentschedule and capture agreement signture
    }
    // if (Tab !== '') {
    //   setactivetab(Tab);
    // }
  };
  /**
   * changes : set to default v2
   * Developer : Muzzammil Shaik
   * Desc : to set the state variables from the UI changed state variable to back to original with all
   * grid and Tender type changes
   */
  // let setToDefault:any=0
  const carryRes = (response: any) => {
    // debugger;
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    const carryrentamount = Number(
      Number(
        response.carryRentAmount == undefined ? 0.0 : response.carryRentAmount
      ) - Number(carryRentAmount)
    );

    settotalCalciTextValue(response.maxCarry);

    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (
      Number(resTotalAmount) <
      Number(totaldue) -
        Number(availableCreds) +
        Number(addSusTotalAmount) -
        Number(usedSusTotalAmount) +
        Number(carryRentAmount)
    ) {
      settotaldue(Number(totaldue) - Number(carryrentamount));
      setremainingamountdue(
        Number(
          (
            Number(totaldue) -
            Number(carryrentamount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        ) - Number(resTotalAmount)
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(totaldue) -
            Number(carryrentamount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      // - Number(resTotalAmount) Removed this from setRemaniningAmtStatic for check & MO issue
      setcarryRentAmount(Number(response.carryRentAmount));
      setchange(0.0);
      //  let epoCheckedAgree = checkedAgr.filter(x=>Number(x.EPOAmount)>0)
      // let

      //  setAcceptpaymentbuttonenable(false)
    } else {
      settotaldue(Number(totaldue) + Number(response.carryRentAmount));
      setremainingamountdue("0.00");
      setcarryRentAmount(Number("0.00"));
      setchange(
        Number(
          (
            Number(resTotalAmount) -
            Number(totaldue) -
            Number(carryrentamount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      // setAcceptpaymentbuttonenable(true)
    }

    //   let carryRentAmountres = Number(response.carryRentAmount)
    // checkedAgr.sort( function(a, b) {return Number(b.EPOAmount)-Number(a.EPOAmount)} );
    //  for(let i=0;i<checkedAgr.length;i++){
    //   let index = paygridvalues.findIndex( x => x.Agreementid  ===  checkedAgr[i].Agreementid);
    //   let receiptindex = ReceiptCategoriesRequestParam.findIndex( x => String(x.agreementId) ===  String(checkedAgr[i].Agreementid));
    //   if(Number(carryRentAmountres)!=0){
    //     carryRentAmountres=carryRentAmountres-Number( paygridvalues[index].agreementRateDetails.perDayRent)+Number(paygridvalues[index].agreementRateDetails.perDayRentTax)
    //   }
    //   if(Number(carryRentAmountres)>0){
    //     //Number((Number(paygridvalues[index].agreementRateDetails.perDayRent)<Number(carryRentAmountres)?Number(paygridvalues[index].agreementRateDetails.perDayRent):Number(Number(Math.abs(carryRentAmountres))/Number(paygridvalues[index].taxRate)).toFixed(2))
    //     paygridvalues[index].carryRentAmountAdd=Number((Number(paygridvalues[index].agreementRateDetails.perDayRent)<Number(carryRentAmountres)?Number(paygridvalues[index].agreementRateDetails.perDayRent):Number(Math.abs(carryRentAmountres))/Number(paygridvalues[index].taxRate)).toFixed(2))
    //     ReceiptCategoriesRequestParam[receiptindex].carryRentAmountAdd=Number((Number(paygridvalues[index].agreementRateDetails.perDayRent)<Number(carryRentAmountres)?Number(paygridvalues[index].agreementRateDetails.perDayRent):Number(Math.abs(carryRentAmountres))/Number(paygridvalues[index].taxRate)).toFixed(2))
    //   }else if(Number(carryRentAmountres)<0){
    //     paygridvalues[index].carryRentAmountAdd=Number(((Number( paygridvalues[index].agreementRateDetails.perDayRent)+Number(paygridvalues[index].agreementRateDetails.perDayRentTax)+Number(carryRentAmountres))/Number(paygridvalues[index].taxRate)).toFixed(2))
    //     ReceiptCategoriesRequestParam[receiptindex].carryRentAmountAdd=Number(((Number( paygridvalues[index].agreementRateDetails.perDayRent)+Number(paygridvalues[index].agreementRateDetails.perDayRentTax)+Number(carryRentAmountres))/Number(paygridvalues[index].taxRate)).toFixed(2))
    //   }else if(carryRentAmountres==0){
    //     paygridvalues[index].carryRentAmountAdd=Number(Number(carryRentAmountres).toFixed(2))
    //     ReceiptCategoriesRequestParam[receiptindex].carryRentAmountAdd=Number(Number(carryRentAmountres).toFixed(2))
    //   }
    // }
    // ReceiptCategoriesRequestParam

    // setpaygridvalues(paygridvalues)

    const cardAmount =
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let cvvToggle = 0;
    if (
      (Number(cardAmount) > 0 && cvvTextValue.length < 3) ||
      CardErrorLbl == true
    ) {
      cvvToggle = 1;
    }
    if (
      Number(
        Number(resTotalAmount) +
          Number(response.carryRentAmount) +
          Number(availableCreds)
      ) >=
        Number(Number(totaldue).toFixed(2)) -
          Number(carryrentamount) +
          Number(response.carryRentAmount) &&
      cvvToggle == 0
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
  };

  const setTodefaultVal = async () => {
    // if(toDefaultActive!=true){
    //   settoDefaultActive(true)
    // }
    // debugger;

    // setOverAll_Chk(false);
    actLDWArr = [];
    actPolicyArr = [];
    if (
      paygridvalues.length == 1 &&
      paygridvalues[0].Agreementtype != "Club"
      // &&
      // paygridvalues[0].FreeTime == null // Added By Me
    ) {
      loadOtherActions(0);
    } else {
      setshowLDWPolicy(false);
    }
    let onlyMiscellineaousFlag = false;
    let onlyMiscellineaousCount = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      if (Number(paygridvalues[i].miscellaneousItemsNum) > 0) {
        onlyMiscellineaousCount++;
      }
    }
    if (onlyMiscellineaousCount == Number(paygridvalues.length)) {
      onlyMiscellineaousFlag = true;
    }

    if (agreementId != "0" || onlyMiscellineaousFlag == true) {
      selectallcheckbox(false);
    } else {
      document.getElementById("maincheckbox")?.removeAttribute("disabled");
    }
    if (carryRentWidget) {
      // await carryOnchange()
      setcarryRentWidget(false);
      setcarryRentAmount("0.00");
    }
    setclubCheckDisabled(false);
    resetPayment(undefined);
    await getpaid("STD");

    if (paymentOrigin == "Pay By Phone" || showChargeCard == true) {
      if (showChargeCard) {
        ChargeCardClose();
      }
      phone({ target: { value: "Store" } });
    }
    //  debugger;
    let defaultAmount = 0;
    if (agreementId != "0") {
      let defaultinitialValue = paygridvalues.filter(
        (x) => Number(x.Agreementid) === Number(agreementId)
      );
      let clubvalue = paygridvalues.filter(
        (x) => x.Agreementtype == "Club" && x.number_of_paymentsmade == 0
      );
      for (let i = 0; i < defaultinitialValue.length; i++) {
        defaultAmount = defaultAmount + Number(defaultinitialValue[i].Amount);
      }
      for (let j = 0; j < clubvalue.length; j++) {
        defaultAmount = defaultAmount + Number(clubvalue[j].Amount);
      }
      //  setdefaultTotaldue(defaultAmount)
    } else {
      defaultAmount = paygridvalues.length == 0 ? 0.0 : defaultTotaldue;
    }
    setmasteraddsuspensearray([]);
    setmasterusesuspensearray([]);
    setmastertranssuspensearray([]);
    setchargeCardAmt("0.00");
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (
      Number(
        (
          Number(defaultAmount) +
          Number(addSuspenseAmount) -
          Number(usedSusTotalAmount) -
          Number(availableCreds)
        ).toFixed(2)
      ) <= 0
    ) {
      setremainingamountdue("0.00");
      setRemaniningAmtStatic("0.00");
      settotaldue("0.00");
      setchange(
        Number(availableCreds) -
          Number(addSuspenseAmount) +
          Number(usedSusTotalAmount) -
          Number(defaultAmount)
      );
      setAcceptpaymentbuttonenable(false);
    } else {
      setremainingamountdue(
        Number(defaultAmount) -
          Number(availableCreds) +
          Number(addSuspenseAmount) -
          Number(usedSusTotalAmount)
      );
      setRemaniningAmtStatic(
        Number(defaultAmount) -
          Number(availableCreds) +
          Number(addSuspenseAmount) -
          Number(usedSusTotalAmount)
      );
      setchange(0.0);
      settotaldue(defaultAmount);
      setAcceptpaymentbuttonenable(true);
    }
    //Activate club changes
    const clubStatus: any = paygridvalues.filter(
      (el: any) => el?.Agreementtype == "Club"
    );
    if (
      clubStatus.length > 0 &&
      !clubStatus[0].originAgreementId &&
      !clubStatus[0].clubDocumentSignStatus
    ) {
      setclubBtnStatus("A");
      setCustOrder("A");
    }
    if (clubStatus.length > 0) {
      if (clubStatus[0].number_of_paymentsmade > 0) {
        setclubStatus("D");
      } else if (clubStatus[0].number_of_paymentsmade == 0) {
        setclubStatus("P");
      }
      if (
        clubStatus[0]?.store_number == sessionStorage.getItem("storeNumber")
      ) {
        setisclubOnSameStore(false);
      }
    } else {
      setclubStatus("A");
      setisclubOnSameStore(false);
    }
    //

    //epo pop up hide
    setepoPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setchargeCardArray([]);
    setClubActivate(undefined);
    TenderTypeOptinchange({ target: { id: "Cash-tab" } });
    //  multipleAdjust()
  };
  //  useEffect(() => {
  //   // let resTotalAmount=0.00;
  //   // resTotalAmount =totalduetendertype.length==0?0.00:((totalduetendertype).map(bill => bill.Amount).reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2)));
  //   if(toDefaultActive==true){
  //     setTodefaultVal()
  //   }

  // }, [toDefaultActive==true]);
  const redirectionToCashSale = (Num: any) => {
    setnonInventoryPopUp(true);
    const editedItemValues = paygridvalues.filter(
      (e) => Number(e.miscellaneousItemsNum) == Number(Num)
    );
    setEditNoninventoryData(editedItemValues);
  };
  const nonInventoryOnClick = async (res: any) => {
    // debugger
    let selectallFlag = true;
    setReceiptCategory([]);
    ReceiptCategoriesRequestParam = [];
    acceptPaymentParam = [];
    AcceptclubPaymentParam = undefined;
    setAcceptClub(undefined);
    // setAcceptPayment([])
    setOtherIncomeHidden(true);
    setnonInventoryPopUp(false);
    otherIncomeOnblur();
    setEditNoninventoryData(undefined);
    let misNumber = 0;
    for (let i = 0; i < paygridvalues.length; i++) {
      let addNum = paygridvalues[i].miscellaneousItemsNum > 0 ? 1 : 0;
      misNumber = misNumber + addNum;
    }
    if (res != undefined) {
      let coa = 0.0;
      let suspense = 0.0;
      let htSuspense = 0.0;
      let ipSuspense = 0.0;
      let wlDeposit = 0.0;

      // if (
      //   getpayment.paymentInfo.remainingCredits !== undefined &&
      //   getpayment.paymentInfo.remainingCredits.length > 0
      // ) {
      //   let rcInfo = getpayment.paymentInfo.remainingCredits;
      //   let rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "coa");
      //   if (
      //     rcData != undefined &&
      //     rcData.length > 0 &&
      //     rcData[0].accountBalance > 0
      //   ) {
      //     COAamount = rcData[0].accountBalance;
      //     coa = Number(COAamount);
      //   }
      //   rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "suspense");
      //   if (
      //     rcData != undefined &&
      //     rcData.length > 0 &&
      //     rcData[0].accountBalance > 0
      //   ) {
      //     suspense = Number(rcData[0].accountBalance);
      //   }

      //   rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ht suspense");
      //   if (
      //     rcData != undefined &&
      //     rcData.length > 0 &&
      //     rcData[0].accountBalance > 0
      //   ) {
      //     htSuspense = Number(rcData[0].accountBalance);
      //   }
      //   rcData = rcInfo.filter((x) => x.bucket.toLowerCase() === "ip suspense");
      //   if (
      //     rcData != undefined &&
      //     rcData.length > 0 &&
      //     rcData[0].accountBalance > 0
      //   ) {
      //     ipSuspense = Number(rcData[0].accountBalance);
      //   }
      // }
      let wlDepositDetail: any =
        getpayment.balance == undefined ? [] : getpayment.balance;
      let objBalanceDetails: any = wlDepositDetail.filter(
        (x: any) => x.balanceType == "WEBLEAD"
      );
      console.log(
        wlDepositDetail,
        objBalanceDetails,
        getpayment.balance,
        "wl deposit details"
      );
      if (objBalanceDetails != undefined && objBalanceDetails.length > 0) {
        if (Number(objBalanceDetails[0].balanceAmount) > 0) {
          wlDeposit = Number(objBalanceDetails[0].balanceAmount);
        } else {
          wlDeposit = 0.0;
        }
      }

      const Description = res.description == undefined ? "" : res.description;
      const Amount =
        Number(res.salesPrice) +
        Number(res.salesTax) +
        Number(res.nineOneOneFee);
      //(res.data.filter((e) => e.key=="Sales Price"))[0].value
      const salesTax = Number(res.salesTax);
      //(res.data.filter((e) => e.key=="Sales Tax"))[0].value
      const nineoneoneFee = Number(res.nineOneOneFee);
      const type = res.type;
      //(res.data.filter((e) => e.key=="911 Fee"))[0].value
      // const
      if (Number(res.editItemId) > 0 && Number(res.removePayment) == 0) {
        const editedItemInfo = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) == Number(res.editItemId)
        );
        editedItemInfo[0].Agreementdesc = Description;
        editedItemInfo[0].Amount = Number(Amount);
        editedItemInfo[0].Taxamount = Number(salesTax);
        editedItemInfo[0].actualAmountDue = Number(Amount);
        editedItemInfo[0].nineOneOneFee = nineoneoneFee;
        editedItemInfo[0].cellPhoneActivation = Number(res.salesPrice);
        editedItemInfo[0].cellPhoneActivationTax = Number(salesTax);
        editedItemInfo[0].agreementRate = res.Amount;
        editedItemInfo[0].miscellaneousItemInfo = res;
        // nineOneOneFee: nineoneoneFee,
        // cellPhoneActivation: Number(Amount),
        // cellPhoneActivationTax: Number(salesTax),
      } else if (res.editItemId > 0 && Number(res.removePayment) == 1) {
        if (paygridvalues.length == 1) {
          selectallFlag = false;
        }
        paygridvalues = paygridvalues.filter(
          (e) => Number(e.miscellaneousItemsNum) != Number(res.editItemId)
        );
      } else {
        const itempaygridvalue = {
          AgreementNumber: "Adjust",
          Agreementdesc: Description,
          Agreementid: "",
          Agreementtype: type,
          Amount: Number(Amount),
          Autopay: "null",
          Autopay_enrollmentdate: null,
          COA: coa,
          CurrentDaysLate: 0,
          CurrentDueDate: "-",
          EPOAmount: 0,
          EPOTax: 0,
          EmailAddress: "",
          Latelast10: 0,
          Nextduedate: "-",
          Phonenumber: "",
          PromoFreeDays: 0,
          SAC_Date: "",
          Schedule: "",
          SuspenseAmount: 0,
          Taxamount: Number(salesTax),
          actualAmountDue: Number(Amount),
          agrLdwAmount: 0,
          agreementRate: res.Amount,
          agreementRateDetails: null,
          cardLastFour: 0,
          carriedLateFee: 0,
          carryRentAmountAdd: 0,
          carryRentAmountUsed: 0,
          carryRentTaxUsed: 0,
          clubCoverageTermination: null,
          clubFlag: true,
          clubMembershipNumber: "",
          clubName: "",
          clubactive: false,
          clublastLate10: 0,
          confirmeddeliversystatus: false,
          convTax: configFeeOnlyTax,
          coverageEndDate: "",
          deliveryAmount: 0,
          deliveryAmountTax: 0,
          dueDateModified: false,
          editSchedule: "",
          extensionAmount: 0,
          htSuspense: 0,
          ipSuspense: 0,
          lFeeTaxAmount: 0,
          suspenseAdd: 0,
          suspenseSub: 0,
          lateFee: 0,
          ldwPercentage: NaN,
          ldwTaxAmount: 0,
          mattressFee: 0,
          mattressFeeTax: 0,
          noOfDaysFree: NaN,
          number_of_paymentsmade: 0,
          partyId: "",
          policyAmount: 0,
          policyTaxAmount: 0,
          processingFee: 0,
          processingFeeTax: 0,
          racTirePay: 0,
          rpayTaxAmount: 0,
          sign_status: "",
          stateProvinceName:
            configFlags.isPaymentFunctionsFeatureFlag == 1
              ? getpayment?.paymentInfo?.cashSaleInfo?.stateProvinceName
              : "",
          storeCityName:
            configFlags.isPaymentFunctionsFeatureFlag == 1
              ? getpayment?.paymentInfo?.cashSaleInfo?.city
              : "",
          store_number: window.sessionStorage.getItem("storeNumber"),
          suspense: 0,
          taxGeoCode: "",
          taxRate: "",
          wlDeposit: wlDeposit,
          zipPlus4UsedForTax: "",
          nineOneOneFee: nineoneoneFee,
          transferOldSuspense: 0,
          cellPhoneActivation: Number(res.salesPrice),
          cellPhoneActivationTax: Number(salesTax),
          miscellaneousItemsNum: misNumber + 1,
          miscellaneousItemInfo: res,
          agreementNoteDetails: {
            noteAmountDueIncTax: "0.00",
            remainingNoteCost: "0.00",
            remainingAgreementCost: "0.00",
            isNoteAgreement: "0",
            noteCost: "0",
            editedNoteAmount: "",
          },
          accepoEPoAgrnoteInfo: undefined,
        };

        paygridvalues = [...paygridvalues, itempaygridvalue];
      }
    }
    setpaygridvalues(paygridvalues);
    //  resetPayment(0)
    // resetPayment(0)

    setCardrequire({
      ...Cardrequire,
      ConvAmt: String(Number(configFeeOnlyTax) + Number(ConvFeeWithOutTax)),
    });
    setconvAmtWTax(
      (Number(ConvFeeWithOutTax) + Number(configFeeOnlyTax)).toFixed(2)
    );
    //}
    // await selectallcheckbox(false)
    // selectallfunc()
    if (agreementId == "0" && selectallFlag && paygridvalues?.length != 0) {
      selectallcheckbox(true);
    }
    if (paygridvalues?.length == 0) {
      selectallcheckbox(false);
      resetPayment(undefined);
    }

    // selectallcheckbox(false)
    // selectallcheckbox(true)
    //  await selectallcheckbox(true)
    //  setmisNumberValue(true)
    //  BindGridCheckFunc()
  };
  useEffect(() => {
    if (paygridvalues != undefined && paygridvalues.length > 0) {
      if (agreementId != "0") {
        // let remainingAmount =0.00
        selectallcheckbox(false);
        let totalTax = 0.0;
        let totalAmountDue = 0.0;
        let count = 0;
        let clubChecked = false;
        // let receiptItemArray=[]
        adjustDueDateSelectedAgre.push(agreementId);
        let clubvalue = paygridvalues.filter(
          (x) => x.Agreementtype == "Club" && x.number_of_paymentsmade == 0
        );
        totalAmountDue =
          totalAmountDue +
          Number(
            paygridvalues.filter(
              (x) => String(x.Agreementid) == String(agreementId)
            )[0].Amount
          );
        totalTax =
          totalTax +
          Number(
            paygridvalues.filter(
              (x) => String(x.Agreementid) == String(agreementId)
            )[0].Taxamount
          );
        // setSelectedCheckBoxCount(1)
        count = count + 1;
        if (clubvalue.length > 0) {
          adjustDueDateSelectedAgre.push(clubvalue[0].Agreementid);
          // setSelectedCheckBoxCount(2)

          clubChecked = true;
          count = count + 1;
          totalAmountDue = totalAmountDue + Number(clubvalue[0].Amount);
          totalTax = totalTax + Number(clubvalue[0].Taxamount);
        }
        for (let i = 0; i < paygridvalues.length; i++) {
          if (paygridvalues[i].miscellaneousItemsNum > 0) {
            adjustDueDateSelectedAgre.push(paygridvalues[i].Agreementid);
            count = count + 1;
            totalAmountDue = totalAmountDue + Number(paygridvalues[i].Amount);
            totalTax = totalTax + Number(paygridvalues[i].Taxamount);
          }
        }
        const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Add" && Number(e.newsuspense) > 0;
        });
        const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
          return e.sustype == "Used" && Number(e.newsuspense) > 0;
        });
        let addSusTotalAmount =
          addSuspenseAmount.length == 0
            ? 0.0
            : addSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let usedSusTotalAmount =
          useSuspenseAmount.length == 0
            ? 0.0
            : useSuspenseAmount
                .map((bill) => bill.newsuspense)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

        let resTotalAmount = 0.0;
        resTotalAmount =
          totalduetendertype.length == 0
            ? 0.0
            : totalduetendertype
                .map((bill) => bill.Amount)
                .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
        let remainingAmt =
          totalAmountDue -
          Number(availableCreds) +
          Number(addSusTotalAmount) -
          Number(usedSusTotalAmount) -
          Number(resTotalAmount);
        settotaldue(totalAmountDue);
        settotaltax(totalTax);
        if (remainingAmt > 0) {
          setremainingamountdue(remainingAmt);
          setRemaniningAmtStatic(remainingAmt);
          setAcceptpaymentbuttonenable(true);
        } else {
          setremainingamountdue(0.0);
          setRemaniningAmtStatic(0.0);
          setchange(Number(remainingAmt));
          setAcceptpaymentbuttonenable(false);
        }

        let checkBoxArray = document.getElementsByName("grid");
        //let objAmt = 0;
        setSelectedCheckBoxCount(0);
        checkBoxArray.forEach(function (item, index) {
          let obj = item as HTMLInputElement;
          let checkBox = obj;

          if (
            paygridvalues[index].Agreementtype == "Club" &&
            clubChecked == true
          ) {
            // let agreementDetails = {
            //   agreement_id: paygridvalues[index].AgreementNumber=="Adjust"?"Miscellaneous Item "+paygridvalues[index].miscellaneousItemsNum:paygridvalues[index].AgreementNumber,
            //   agreement_totalamount: parseFloat(fixedChange(paygridvalues[index].Amount)),
            // };
            // totalduereceiptidsupport.push(agreementDetails)
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          } else if (paygridvalues[index].Agreementid == String(agreementId)) {
            // let agreementDetails = {
            //   agreement_id: paygridvalues[index].AgreementNumber=="Adjust"?"Miscellaneous Item "+paygridvalues[index].miscellaneousItemsNum:paygridvalues[index].AgreementNumber,
            //   agreement_totalamount: parseFloat(fixedChange(paygridvalues[index].Amount)),
            // };
            // totalduereceiptidsupport.push(agreementDetails)
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          } else if (paygridvalues[index].miscellaneousItemsNum > 0) {
            // let agreementDetails = {
            //   agreement_id: paygridvalues[index].AgreementNumber=="Adjust"?"Miscellaneous Item "+paygridvalues[index].miscellaneousItemsNum:paygridvalues[index].AgreementNumber,
            //   agreement_totalamount: parseFloat(fixedChange(paygridvalues[index].Amount)),
            // };
            // totalduereceiptidsupport.push(agreementDetails)
            checkBox.checked = true;
            settotaldueamount(
              "chk" + index,
              index,
              paygridvalues[index].Amount,
              paygridvalues[index].Agreementid,
              undefined
            );
          }
          // settotalduereceiptid(totalduereceiptidsupport);
          setSelectedCheckBoxCount(count);
          adjustDueDateSelectedAgre = Array.from(
            new Set(adjustDueDateSelectedAgre)
          );
          setadjustDueDateSelectedAgre(adjustDueDateSelectedAgre);
          multipleAdjust();
        });

        // nonInventoryPaymentRequest()
      } else {
        let checkBoxArray = document.getElementsByName("grid");
        //let objAmt = 0;
        checkBoxArray.forEach(function (item, index) {
          let obj = item as HTMLInputElement;
          let checkBox = obj;
          checkBox.checked = true;
        });
      }
    } else if (paygridvalues != undefined && paygridvalues.length == 0) {
      settotaldue(0.0);
      settotaltax(0.0);
    }
  }, [nonInventoryPopUp]);

  // const nonInventoryPaymentRequest =()=>{
  //   let clubvalue = paygridvalues.filter((x) => x.Agreementtype=="Club"&&x.number_of_paymentsmade==0 );
  //   totalAmountDue=totalAmountDue+Number( paygridvalues.filter((x) => String(x.Agreementid)==String(agreementId))[0].Amount)
  //   if(clubvalue.length>0){

  //   }

  // }

  const otherIncomepopupButtonclick = () => {
    // setCardrequire({
    //   ...Cardrequire,
    //   Ldwhide: customerCoverageObject.isLDW === true ? false : true,
    //   Policyhide:
    //     customerCoverageObject.isPolicy === true &&
    //     customerCoverageObject.policyAmount !== ""
    //       ? false
    //       : true,
    // });
    if (
      otherIncomePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setotherIncomePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      otherIncomePopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setotherIncomePopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };
  const otherIncomeOnblur = () => {
    setotherIncomePopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  /**
   * changes : Adjust Due Date v2
   * Developer : Muzzammil
   * Desc : to set the state variables from the response of adjust due date component
   *        Amount Due and remianing amount amount Due , total amount Due , total tax , receipt items
   */
  const editAdjustRes = (response: any) => {
    //debugger;
    let payGridObj;
    let prevAmt;
    let resAmount = 0;
    let resTaxamount = 0;
    totalduereceiptidsupport = [];
    acceptPaymentParam = [];
    ReceiptCategoriesRequestParam = [];
    let res = response.resArray;

    for (let i = 0; i < adjustDueDateSelectedAgre.length; i++) {
      resAmount = Number(resAmount) + Number(res[i].Amount);
      resTaxamount = Number(resTaxamount) + Number(res[i].Taxamount);
      payGridObj = paygridvalues.filter(
        (x) => x.Agreementid === res[i].Agreementid
      );
      //  debugger;
      if (res?.length == 1) {
        setLatelast10value(payGridObj[0].lateFee);
      }
      prevAmt = payGridObj[0].Amount;
      payGridObj[0].Amount = res[i].Amount;
      payGridObj[0].Nextduedate =
        res[i].Nextduedate == undefined
          ? payGridObj[0].Nextduedate
          : res[i].Nextduedate;
      payGridObj[0].Taxamount = res[i].Taxamount;
      payGridObj[0].Schedule =
        res[i].Schedule == undefined ? payGridObj[0].Schedule : res[i].Schedule;
      payGridObj[0].editSchedule =
        res[i].editSchedule == undefined
          ? payGridObj[0].editSchedule
          : res[i].editSchedule;
      // payGridObj[0].agreementRateDetails.scheduleRefCode = res[i].editSchedule==undefined?payGridObj[0].agreementRateDetails.scheduleRefCode:res[i].editSchedule
      payGridObj[0].ldwTaxAmount =
        res[i].ldwTaxAmount == undefined
          ? payGridObj[0].ldwTaxAmount
          : res[i].ldwTaxAmount;
      payGridObj[0].agrLdwAmount =
        res[i].agrLdwAmount == undefined
          ? payGridObj[0].agrLdwAmount
          : res[i].agrLdwAmount;
      payGridObj[0].policyTaxAmount =
        res[i].policyTaxAmount == undefined
          ? payGridObj[0].policyTaxAmount
          : res[i].policyTaxAmount;
      payGridObj[0].policyAmount =
        res[i].policyAmount == undefined
          ? payGridObj[0].policyAmount
          : res[i].policyAmount;
      payGridObj[0].agreementRate =
        res[i].agreementRate == undefined
          ? payGridObj[0].agreementRate
          : res[i].agreementRate;
      payGridObj[0].PromoFreeDays =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDays
          : Number(payGridObj[0].PromoFreeDays) -
            Number(res[i].PromoFreeDaysApplied);
      payGridObj[0].PromoFreeDaysApplied =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDaysApplied
          : res[i].PromoFreeDaysApplied;

      // payGridObj[0].agreementRateTax=res[i].agreementRateTax==undefined?payGridObj[0].agreementRateTax:res[i].agreementRateTax
      payGridObj[0].rpayTaxAmount =
        res[i].rpayTaxAmount == undefined
          ? payGridObj[0].rpayTaxAmount
          : res[i].rpayTaxAmount;
      payGridObj[0].Agreementtype =
        res[i].Agreementtype == undefined
          ? payGridObj[0].Agreementtype
          : res[i].Agreementtype;
      if (response.adjustEditflag == 1) {
        payGridObj[0].dueDateModified = true;
      }

      //to set the receipt item values
      const agreementDetails = {
        agreement_id: payGridObj[0].AgreementNumber,
        agreement_totalamount: fixedChange(String(res[i].Amount)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      settotalduereceiptid(totalduereceiptidsupport);
      //accept payment array push starts here
      let ldwamountcalculation = "0.00";
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let epoAmt = 0;
      let epoTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      if (getpayment !== undefined && getpayment !== null) {
        //let objLdwInfo = getpayment.paymentInfo.ldwInformation.filter(x => x.agreementId ===  payGridObj[0].Agreementid);
        let objAmtDue;

        if (payGridObj[0].Agreementtype === "Club") {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === payGridObj[0].Agreementid
          );
        }

        if (objAmtDue.length > 0) {
          if (
            objAmtDue[0].ldwLateRent !== undefined &&
            objAmtDue[0].ldwLateRent !== null &&
            objAmtDue[0].ldwLateRent !== "" &&
            objAmtDue[0].ldwLateRent !== "0"
          ) {
            ldwamountcalculation = objAmtDue[0].ldwLateRent;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          } else if (
            objAmtDue[0].ldwAmount !== undefined &&
            objAmtDue[0].ldwAmount !== null &&
            objAmtDue[0].ldwAmount !== ""
          ) {
            ldwamountcalculation = objAmtDue[0].ldwAmount;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          }
          console.log("------->Ldw", ldwamountcalculation);
          //}

          if (
            objAmtDue[0].amountDueLateRent !== undefined &&
            objAmtDue[0].amountDueLateRent !== null &&
            objAmtDue[0].amountDueLateRent !== "" &&
            objAmtDue[0].amountDueLateRent !== "0"
          ) {
            objAmount = objAmtDue[0].amountDueLateRent;
          } else if (objAmtDue[0].amountDue !== undefined) {
            objAmount = String(objAmtDue[0].amountDue);
          } else if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            clubFlag = 1;
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            clubFlag = 1;
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
        }

        let objPayHistory = getpayment.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === payGridObj[0].Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            payGridObj[0].Amount -
            Number(payGridObj[0].lateFee) +
            Number(payGridObj[0].suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number( payGridObj[0].EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date( payGridObj[0].SAC_Date);
        if (payGridObj[0].Agreementtype == "EPO Rent") {
          console.log("Inside If agrAmount----->", agreementAmount);
          if (payGridObj[0].Nextduedate == "EPO") {
            epoAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            epoTax =
              Number(payGridObj[0].rpayTaxAmount) -
              Number(payGridObj[0].lFeeTaxAmount);
          } else {
            sacAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            sacTax =
              Number(payGridObj[0].rpayTaxAmount) -
              Number(payGridObj[0].lFeeTaxAmount);
          }
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        } else if (payGridObj[0].Agreementtype == "PIF Rent") {
          pifAmt =
            payGridObj[0].acceptEpoFlag == 0
              ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
              : Number(payGridObj[0].EPOAmount) +
                Number(payGridObj[0].suspenseSub) -
                Number(payGridObj[0].lateFee) -
                Number(payGridObj[0].EPOTax);
          pifTax =
            Number(payGridObj[0].rpayTaxAmount) -
            Number(payGridObj[0].lFeeTaxAmount);
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        }
      }

      let ChangeAmt = change;
      console.log("ChangeAmt", ChangeAmt);

      let ReceiptArray = {
        customerId: Number(customerId),
        schedule: payGridObj[0].Schedule + "|" + payGridObj[0].AgreementNumber,
        amountDue: Number(
          clubFlag !== 1 && pifFlag == 0
            ? (payGridObj[0].Amount - Number(payGridObj[0].lateFee)).toFixed(2)
            : 0
        ),
        ldwFeeAmount: Number(payGridObj[0].agrLdwAmount),
        clubPayment: Number(
          clubFlag === 1
            ? Number(payGridObj[0].Amount.toFixed(2)) -
                Number(payGridObj[0].Taxamount)
            : 0
        ),
        clubPaymentTax: Number(clubFlag === 1 ? payGridObj[0].Taxamount : 0),
        creditOnAccountAdd: 0,
        creditOnAccountSub: Number(payGridObj[0].COA),
        integratedPaymentSuspense: -1 * Number(payGridObj[0].ipSuspense),
        initialPayment:
          clubFlag === 1
            ? 0
            : payGridObj[0].number_of_paymentsmade === 0
            ? 1
            : 0,
        netLateFee: 0,
        carriedLateFee: 0,
        wlDeposit: -1 * Number(payGridObj[0].wlDeposit),
        htSuspense: 0,
        suspenseAdd: Number(payGridObj[0].suspenseAdd),
        suspenseSub: Number(payGridObj[0].suspenseSub), //Number( payGridObj[0].suspense),
        racTirePay: Number(payGridObj[0].policyAmount),
        agreementId:
          clubFlag !== 1 ? Number(payGridObj[0].Agreementid) : undefined,
        change: Number(fixedChange(ChangeAmt)),
        totalTax: payGridObj[0].Taxamount,
        sac: Number(sacAmt),
        sacTax: Number(sacTax),
        epoDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualEpoAmount) -
              Number(payGridObj[0].editedEPOAmount),
        pastDueDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualPastDueRent) -
              Number(payGridObj[0].pastDueRent),
        ldwFeeAmountTax: Number(payGridObj[0].ldwTaxAmount),
        pastDueAmount:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : Number(payGridObj[0].actualPastDueRent),
        nextDueDate:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : payGridObj[0].Nextduedate,
        rentalPaymentTax:
          pifFlag == 0 && payGridObj[0].Agreementtype !== "Club"
            ? Number(payGridObj[0].rpayTaxAmount)
            : 0,
        lateRentalPaymentfeeTax: Number(payGridObj[0].lFeeTaxAmount),
        lateRentalPaymentfee: Number(payGridObj[0].lateFee),
        racTirePayTax: Number(payGridObj[0].policyTaxAmount),
        deliveryFee: Number(payGridObj[0].deliveryAmount),
        deliveryFeeTax: Number(payGridObj[0].deliveryAmountTax),
        processingFee: Number(payGridObj[0].processingFee),
        processingFeeTax: Number(payGridObj[0].processingFeeTax),
        carryRentAmountUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentAmountUsed)
            : 0,
        carryRentTaxUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentTaxUsed)
            : 0,
        //otherFee: Number( payGridObj[0].mattressFee),
        //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
        pif: Number(pifAmt),
        pifTax: Number(pifTax),
        extensionAmount: Number(payGridObj[0].extensionAmount),
        convenienceFee: 0,
        convenienceFeeTax: 0,
        mattressFee: Number(payGridObj[0].mattressFee),
      };

      ReceiptCategoriesRequestParam.push(ReceiptArray);

      if (
        payGridObj[0].Agreementtype !== "Club" &&
        payGridObj[0].miscellaneousItemsNum == 0
      ) {
        let acceptPaymentArray = {
          extensionAmount: Number(payGridObj[0].extensionAmount),
          noOfDaysFree: Number(payGridObj[0].noOfDaysFree),
          initialPaymentFlag: 1,
          // initialPaymentFlag: payGridObj[0].number_of_paymentsmade == 0 ? 1 : 0,
          addTransferSusFlag: 0,
          schedule: payGridObj[0].Schedule,
          agreementId: Number(payGridObj[0].Agreementid),
          ldwFeeAmount: Number(payGridObj[0].agrLdwAmount.toFixed(2)),
          agreementAmountDue: Number(
            fixedChange(
              String(
                Number(payGridObj[0].Amount) +
                  (Number(payGridObj[0].acceptEpoFlag) == 1
                    ? Number(payGridObj[0].suspenseSub)
                    : 0)
              )
            )
          ),
          remainingEpoAmount: Number(
            payGridObj[0].EPOAmount == null
              ? 0.0
              : (payGridObj[0].EPOAmount - payGridObj[0].EPOTax).toFixed(2)
          ),
          promoFreeDays: Number(payGridObj[0].PromoFreeDaysApplied),
          netLateFee: Number(payGridObj[0].lateFee),
          carriedLateFee: Number(payGridObj[0].carriedLateFee.toFixed(2)),
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          totalTax: Number(payGridObj[0].Taxamount.toFixed(2)),
          policyAmount: Number(Number(payGridObj[0].policyAmount)), // Change Made
          editSchedule: payGridObj[0].editSchedule,
          freeTime: payGridObj[0].FreeTime, // Added By Me
          ldwActivate: undefined,
        };
        acceptPaymentParam.push(acceptPaymentArray);
        setAcceptClub(undefined);
      } else if (payGridObj[0].miscellaneousItemsNum == 0) {
        console.log("went into condition");
        AcceptclubPaymentParam = {
          paymentSchedule: payGridObj[0].Schedule,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          clubTax: String(payGridObj[0].Taxamount.toFixed(2)),
          clubAmountDue: Number(fixedChange(String(payGridObj[0].Amount))),
          editSchedule: payGridObj[0].editSchedule,
        };
        setAcceptClub(AcceptclubPaymentParam);
      }
      if (
        adjustDueDateSelectedAgre.length == 1 &&
        payGridObj[0].Agreementtype != "Club"
        // &&
        // payGridObj[0].FreeTime == null
      ) {
        let index = paygridvalues.findIndex(
          (x) => String(x.Agreementid) === String(adjustDueDateSelectedAgre[0])
        );
        if (index != null && index != undefined) {
          loadOtherActions(index);
        }
      }
    }

    //debugger;
    setpaygridvalues(paygridvalues);
    let resTotalAmount = 0.0;
    if (Cardrequire.PaymentOrgin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      resTaxamount = Number(resTaxamount) + Number(convtax);
    }
    let objAvailCreditsAmt = 0;
    if (Number(resAmount) <= Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt = resAmount;
      // Number(paygridvalues[index].COA) +
      // Number(paygridvalues[index].wlDeposit)
      // Number(paygridvalues[index].ipSuspense)
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "WEBLEAD",
          accountBalance: Number(resAmount).toFixed(2),
        },
      ]);
    } else if (Number(resAmount) > Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) +
        Number(payGridObj[0].wlDeposit) +
        Number(payGridObj[0].ipSuspense);
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "WEBLEAD",
          accountBalance: Number(payGridObj[0].wlDeposit).toFixed(2),
        },
        {
          balanceType: "COA",
          accountBalance: Number(payGridObj[0].COA).toFixed(2),
        },
        {
          balanceType: "IP Suspence",
          accountBalance: Number(payGridObj[0].ipSuspense).toFixed(2),
        },
      ]);
    } else if (Number(resAmount) == 0) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) + Number(payGridObj[0].ipSuspense);
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "COA",
          accountBalance: Number(payGridObj[0].COA).toFixed(2),
        },
        {
          balanceType: "IP Suspence",
          accountBalance: Number(payGridObj[0].ipSuspense).toFixed(2),
        },
      ]);
    }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(objAvailCreditsAmt) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }
    let addedlatevalue = 0;
    for (let i = 0; i < checkedAgr?.length; i++) {
      addedlatevalue = Number(checkedAgr[i].lateFee) + addedlatevalue;
    }
    settotaldue(resAmount);
    settotaltax(Number(resTaxamount));
    setPopUpCarryWaive(false);
    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    multipleAdjust();
  };
  const editCommitmentRes = (response: any) => {
    //;
    console.log("valeudsvcdbv response", response);
    console.log(
      "adjustDueDateSelectedAgre sdr5tfe3",
      adjustDueDateSelectedAgre
    );

    let payGridObj;
    let prevAmt;
    let resAmount = 0;
    let resTaxamount = 0;
    totalduereceiptidsupport = [];
    acceptPaymentParam = [];
    ReceiptCategoriesRequestParam = [];
    let res = response.resArray;
    // debugger;
    console.log("cdimmws837res", res);

    for (let i = 0; i < response.selectedAgr.length; i++) {
      console.log("83uejdnfhrued34r", res[i].Amount);

      resAmount = Number(resAmount) + Number(res[i].Amount);
      resTaxamount = Number(resTaxamount) + Number(res[i].Taxamount);
      payGridObj = paygridvalues.filter(
        (x) => x.Agreementid === res[i].Agreementid
      );
      console.log("chedkmj8545269eee", payGridObj);

      prevAmt = payGridObj[0].Amount;
      payGridObj[0].Amount = res[i].Amount;
      payGridObj[0].Nextduedate =
        res[i].Nextduedate == undefined
          ? payGridObj[0].Nextduedate
          : res[i].Nextduedate;
      payGridObj[0].Taxamount = res[i].Taxamount;
      payGridObj[0].Schedule =
        res[i].Schedule == undefined ? payGridObj[0].Schedule : res[i].Schedule;
      payGridObj[0].editSchedule =
        res[i].editSchedule == undefined
          ? payGridObj[0].editSchedule
          : res[i].editSchedule;
      // payGridObj[0].agreementRateDetails.scheduleRefCode = res[i].editSchedule==undefined?payGridObj[0].agreementRateDetails.scheduleRefCode:res[i].editSchedule
      payGridObj[0].ldwTaxAmount =
        res[i].ldwTaxAmount == undefined
          ? payGridObj[0].ldwTaxAmount
          : res[i].ldwTaxAmount;
      payGridObj[0].agrLdwAmount =
        res[i].agrLdwAmount == undefined
          ? payGridObj[0].agrLdwAmount
          : res[i].agrLdwAmount;
      payGridObj[0].policyTaxAmount =
        res[i].policyTaxAmount == undefined
          ? payGridObj[0].policyTaxAmount
          : res[i].policyTaxAmount;
      payGridObj[0].policyAmount =
        res[i].policyAmount == undefined
          ? payGridObj[0].policyAmount
          : res[i].policyAmount;
      payGridObj[0].agreementRate =
        res[i].agreementRate == undefined
          ? payGridObj[0].agreementRate
          : res[i].agreementRate;
      payGridObj[0].PromoFreeDays =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDays
          : Number(payGridObj[0].PromoFreeDays) -
            Number(res[i].PromoFreeDaysApplied);
      payGridObj[0].PromoFreeDaysApplied =
        res[i].PromoFreeDaysApplied == undefined
          ? payGridObj[0].PromoFreeDaysApplied
          : res[i].PromoFreeDaysApplied;

      // payGridObj[0].agreementRateTax=res[i].agreementRateTax==undefined?payGridObj[0].agreementRateTax:res[i].agreementRateTax
      payGridObj[0].rpayTaxAmount =
        res[i].rpayTaxAmount == undefined
          ? payGridObj[0].rpayTaxAmount
          : res[i].rpayTaxAmount;
      payGridObj[0].Agreementtype =
        res[i].Agreementtype == undefined
          ? payGridObj[0].Agreementtype
          : res[i].Agreementtype;
      if (
        response.adjustEditflag !== undefined &&
        response.adjustEditflag === 1 &&
        response.adjustedAgreement !== undefined &&
        Number(response.adjustedAgreement) == Number(payGridObj[0].Agreementid)
      ) {
        payGridObj[0].dueDateModified = true;
      }
      // else {
      //   payGridObj[i].dueDateModified = false;
      // }

      //to set the receipt item values
      const agreementDetails = {
        agreement_id: payGridObj[0].AgreementNumber,
        agreement_totalamount: fixedChange(String(res[i].Amount)),
      };
      totalduereceiptidsupport.push(agreementDetails);
      settotalduereceiptid(totalduereceiptidsupport);
      //accept payment array push starts here
      let ldwamountcalculation = "0.00";
      let objCarriedLateFee = 0;
      let objAmount = "0.00";
      let clubFlag = 0;
      let ldwFeeTax;
      let pifAmt = 0;
      let pifTax = 0;
      let sacTax = 0;
      let epoAmt = 0;
      let epoTax = 0;
      let pifFlag = 0;
      let sacAmt = 0;
      if (getpayment !== undefined && getpayment !== null) {
        //let objLdwInfo = getpayment.paymentInfo.ldwInformation.filter(x => x.agreementId ===  payGridObj[0].Agreementid);
        let objAmtDue;

        if (payGridObj[0].Agreementtype === "Club") {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.clubAmountDue !== undefined
          );
        } else {
          objAmtDue = getpayment.amountDue.amountDueResponse.filter(
            (x) => x.agreementId === payGridObj[0].Agreementid
          );
        }

        if (objAmtDue.length > 0) {
          if (
            objAmtDue[0].ldwLateRent !== undefined &&
            objAmtDue[0].ldwLateRent !== null &&
            objAmtDue[0].ldwLateRent !== "" &&
            objAmtDue[0].ldwLateRent !== "0"
          ) {
            ldwamountcalculation = objAmtDue[0].ldwLateRent;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          } else if (
            objAmtDue[0].ldwAmount !== undefined &&
            objAmtDue[0].ldwAmount !== null &&
            objAmtDue[0].ldwAmount !== ""
          ) {
            ldwamountcalculation = objAmtDue[0].ldwAmount;
            ldwFeeTax = Number(objAmtDue[0].ldwTaxAmount);
          }
          console.log("------->Ldw", ldwamountcalculation);
          //}

          if (
            objAmtDue[0].amountDueLateRent !== undefined &&
            objAmtDue[0].amountDueLateRent !== null &&
            objAmtDue[0].amountDueLateRent !== "" &&
            objAmtDue[0].amountDueLateRent !== "0"
          ) {
            objAmount = objAmtDue[0].amountDueLateRent;
          } else if (objAmtDue[0].amountDue !== undefined) {
            objAmount = String(objAmtDue[0].amountDue);
          } else if (
            objAmtDue[0].clubAmountLateRent !== undefined &&
            objAmtDue[0].clubAmountLateRent !== null &&
            objAmtDue[0].clubAmountLateRent !== ""
          ) {
            clubFlag = 1;
            objAmount = objAmtDue[0].clubAmountLateRent;
          } else {
            clubFlag = 1;
            objAmount = String(objAmtDue[0].clubAmountDue);
          }
        }

        let objPayHistory = getpayment.paymentInfo.paymentHistoryInfo.filter(
          (x) => x.agreementId === payGridObj[0].Agreementid
        );
        if (objPayHistory !== undefined && objPayHistory.length > 0) {
          objCarriedLateFee = objPayHistory[0].carriedLateFee;
        }
        let agreementAmount = Number(
          (
            payGridObj[0].Amount -
            Number(payGridObj[0].lateFee) +
            Number(payGridObj[0].suspenseSub)
          ).toFixed(2)
        );
        //let epoAmount = Number( payGridObj[0].EPOAmount.toFixed(2));
        // let currentDate = new Date();
        // let SACDate = new Date( payGridObj[0].SAC_Date);
        if (payGridObj[0].Agreementtype == "EPO Rent") {
          console.log("Inside If agrAmount----->", agreementAmount);
          if (payGridObj[0].Nextduedate == "EPO") {
            epoAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            epoTax =
              Number(payGridObj[0].rpayTaxAmount) -
              Number(payGridObj[0].lFeeTaxAmount);
          } else {
            sacAmt =
              payGridObj[0].acceptEpoFlag == 0
                ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
                : Number(payGridObj[0].EPOAmount) +
                  Number(payGridObj[0].suspenseSub) -
                  Number(payGridObj[0].lateFee) -
                  Number(payGridObj[0].EPOTax);
            sacTax =
              Number(payGridObj[0].rpayTaxAmount) -
              Number(payGridObj[0].lFeeTaxAmount);
          }
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        } else if (payGridObj[0].Agreementtype == "PIF Rent") {
          pifAmt =
            payGridObj[0].acceptEpoFlag == 0
              ? Number((agreementAmount - payGridObj[0].Taxamount).toFixed(2))
              : Number(payGridObj[0].EPOAmount) +
                Number(payGridObj[0].suspenseSub) -
                Number(payGridObj[0].lateFee) -
                Number(payGridObj[0].EPOTax);
          pifTax =
            Number(payGridObj[0].rpayTaxAmount) -
            Number(payGridObj[0].lFeeTaxAmount);
          setfinalagr(Number(payGridObj[0].Agreementid));
          pifFlag = 1;
        }
      }

      let ChangeAmt = change;
      console.log("ChangeAmt", ChangeAmt);

      let ReceiptArray = {
        customerId: Number(customerId),
        schedule: payGridObj[0].Schedule + "|" + payGridObj[0].AgreementNumber,
        amountDue: Number(
          clubFlag !== 1 && pifFlag == 0
            ? (payGridObj[0].Amount - Number(payGridObj[0].lateFee)).toFixed(2)
            : 0
        ),
        ldwFeeAmount: Number(payGridObj[0].agrLdwAmount),
        clubPayment: Number(
          clubFlag === 1
            ? Number(payGridObj[0].Amount.toFixed(2)) -
                Number(payGridObj[0].Taxamount)
            : 0
        ),
        clubPaymentTax: Number(clubFlag === 1 ? payGridObj[0].Taxamount : 0),
        creditOnAccountAdd: 0,
        creditOnAccountSub: Number(payGridObj[0].COA),
        integratedPaymentSuspense: -1 * Number(payGridObj[0].ipSuspense),
        initialPayment:
          clubFlag === 1
            ? 0
            : payGridObj[0].number_of_paymentsmade === 0
            ? 1
            : 0,
        netLateFee: 0,
        carriedLateFee: 0,
        wlDeposit: -1 * Number(payGridObj[0].wlDeposit),
        htSuspense: 0,
        suspenseAdd: Number(payGridObj[0].suspenseAdd),
        suspenseSub: Number(payGridObj[0].suspenseSub), //Number( payGridObj[0].suspense),
        racTirePay: Number(payGridObj[0].policyAmount),
        agreementId:
          clubFlag !== 1 ? Number(payGridObj[0].Agreementid) : undefined,
        change: Number(fixedChange(ChangeAmt)),
        totalTax: payGridObj[0].Taxamount,
        sac: Number(sacAmt),
        sacTax: Number(sacTax),
        epoDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualEpoAmount) -
              Number(payGridObj[0].editedEPOAmount),
        pastDueDiscount:
          payGridObj[0].acceptEpoFlag == 0
            ? 0.0
            : Number(payGridObj[0].actualPastDueRent) -
              Number(payGridObj[0].pastDueRent),
        ldwFeeAmountTax: Number(payGridObj[0].ldwTaxAmount),
        pastDueAmount:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : Number(payGridObj[0].actualPastDueRent),
        nextDueDate:
          payGridObj[0].acceptEpoFlag == 0
            ? undefined
            : payGridObj[0].Nextduedate,
        rentalPaymentTax:
          pifFlag == 0 && payGridObj[0].Agreementtype !== "Club"
            ? Number(payGridObj[0].rpayTaxAmount)
            : 0,
        lateRentalPaymentfeeTax: Number(payGridObj[0].lFeeTaxAmount),
        lateRentalPaymentfee: Number(payGridObj[0].lateFee),
        racTirePayTax: Number(payGridObj[0].policyTaxAmount),
        deliveryFee: Number(payGridObj[0].deliveryAmount),
        deliveryFeeTax: Number(payGridObj[0].deliveryAmountTax),
        processingFee: Number(payGridObj[0].processingFee),
        processingFeeTax: Number(payGridObj[0].processingFeeTax),
        carryRentAmountUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentAmountUsed)
            : 0,
        carryRentTaxUsed:
          payGridObj[0].FreeTime == null
            ? Number(payGridObj[0].carryRentTaxUsed)
            : 0,
        //otherFee: Number( payGridObj[0].mattressFee),
        //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
        pif: Number(pifAmt),
        pifTax: Number(pifTax),
        extensionAmount: Number(payGridObj[0].extensionAmount),
        convenienceFee: 0,
        convenienceFeeTax: 0,
        mattressFee: Number(payGridObj[0].mattressFee),
      };

      ReceiptCategoriesRequestParam.push(ReceiptArray);

      if (
        payGridObj[0].Agreementtype !== "Club" &&
        payGridObj[0].miscellaneousItemsNum == 0
      ) {
        let acceptPaymentArray = {
          extensionAmount: Number(payGridObj[0].extensionAmount),
          noOfDaysFree: Number(payGridObj[0].noOfDaysFree),
          initialPaymentFlag: 1,
          // initialPaymentFlag: payGridObj[0].number_of_paymentsmade == 0 ? 1 : 0,
          addTransferSusFlag: 0,
          schedule: payGridObj[0].Schedule,
          agreementId: Number(payGridObj[0].Agreementid),
          ldwFeeAmount: Number(payGridObj[0].agrLdwAmount.toFixed(2)),
          agreementAmountDue: Number(
            fixedChange(
              String(
                Number(payGridObj[0].Amount) +
                  (Number(payGridObj[0].acceptEpoFlag) == 1
                    ? Number(payGridObj[0].suspenseSub)
                    : 0)
              )
            )
          ),
          remainingEpoAmount: Number(
            payGridObj[0].EPOAmount == null
              ? 0.0
              : (payGridObj[0].EPOAmount - payGridObj[0].EPOTax).toFixed(2)
          ),
          promoFreeDays: Number(payGridObj[0].PromoFreeDaysApplied),
          netLateFee: Number(payGridObj[0].lateFee),
          carriedLateFee: Number(payGridObj[0].carriedLateFee.toFixed(2)),
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          totalTax: Number(payGridObj[0].Taxamount.toFixed(2)),
          policyAmount: Number(Number(payGridObj[0].policyAmount)),
          editSchedule: payGridObj[0].editSchedule,
          freeTime: payGridObj[0].FreeTime, // Added By Me
          ldwActivate: undefined,
        };
        acceptPaymentParam.push(acceptPaymentArray);
        setAcceptClub(undefined);
      } else if (payGridObj[0].miscellaneousItemsNum == 0) {
        console.log("went into condition");
        AcceptclubPaymentParam = {
          paymentSchedule: payGridObj[0].Schedule,
          originalDueDate: payGridObj[0].CurrentDueDate,
          nextDueDate: payGridObj[0].Nextduedate,
          numberOfDaysLate: payGridObj[0].CurrentDaysLate,
          clubTax: String(payGridObj[0].Taxamount.toFixed(2)),
          clubAmountDue: Number(fixedChange(String(payGridObj[0].Amount))),
          editSchedule: payGridObj[0].editSchedule,
        };
        setAcceptClub(AcceptclubPaymentParam);
      }
      if (
        adjustDueDateSelectedAgre.length == 1 &&
        payGridObj[0].Agreementtype != "Club"
        // &&
        // payGridObj[0].FreeTime == null
      ) {
        let index = paygridvalues.findIndex(
          (x) => String(x.Agreementid) === String(adjustDueDateSelectedAgre[0])
        );
        if (index != null && index != undefined) {
          loadOtherActions(index);
        }
      }
    }

    //;
    let gridSortedValue: any = [];
    let collectNonClubAgr: any = [];
    let collectClubAgr: any = [];
    paygridvalues.map((payGridObj, index) => {
      if (payGridObj.Agreementtype == "Club") {
        collectClubAgr.push(payGridObj);
      } else {
        collectNonClubAgr.push(payGridObj);
      }
    });
    gridSortedValue = [...collectClubAgr, ...collectNonClubAgr];
    console.log("gridSortedValue gridSortedValue111", gridSortedValue);
    console.log("collectNonClubAgr gridSortedValue111", gridSortedValue);
    console.log("collectClubAgr gridSortedValue111", gridSortedValue);

    setpaygridvalues(gridSortedValue);
    // setpaygridvalues(paygridvalues);
    let resTotalAmount = 0.0;
    if (Cardrequire.PaymentOrgin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      resTaxamount = Number(resTaxamount) + Number(convtax);
    }
    let objAvailCreditsAmt = 0;
    if (Number(resAmount) <= Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt = resAmount;
      // Number(paygridvalues[index].COA) +
      // Number(paygridvalues[index].wlDeposit) +
      // Number(paygridvalues[index].ipSuspense);
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "WEBLEAD",
          accountBalance: Number(resAmount).toFixed(2),
        },
      ]);
    } else if (Number(resAmount) > Number(payGridObj[0].wlDeposit)) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) +
        Number(payGridObj[0].wlDeposit) +
        Number(payGridObj[0].ipSuspense);
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "WEBLEAD",
          accountBalance: Number(payGridObj[0].wlDeposit).toFixed(2),
        },
        {
          balanceType: "COA",
          accountBalance: Number(payGridObj[0].COA).toFixed(2),
        },
        {
          balanceType: "IP Suspence",
          accountBalance: Number(payGridObj[0].ipSuspense).toFixed(2),
        },
      ]);
    } else if (Number(resAmount) == 0) {
      objAvailCreditsAmt =
        Number(payGridObj[0].COA) + Number(payGridObj[0].ipSuspense);
      console.log(objAvailCreditsAmt, "avab credit total amount");
      setAvailableCreds(objAvailCreditsAmt);
      setavailableCredsremaining([
        {
          balanceType: "COA",
          accountBalance: Number(payGridObj[0].COA).toFixed(2),
        },
        {
          balanceType: "IP Suspence",
          accountBalance: Number(payGridObj[0].ipSuspense).toFixed(2),
        },
      ]);
    }
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(objAvailCreditsAmt) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(objAvailCreditsAmt) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(objAvailCreditsAmt) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    settotaltax(Number(resTaxamount));

    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    multipleAdjust();
  };
  const carryOnchange = () => {
    let resTotalAmount = 0.0;
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    // debugger;
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    if (
      carryRentWidget == false &&
      Number(selectedCheckBoxCount) > 0 &&
      carryRentInitialAndFinal == false
    ) {
      setcarryRentWidget(true);
    } else if (carryRentWidget == true) {
      setcarryRentWidget(false);
      settotaldue(Number(totaldue) + Number(carryRentAmount));
      if (Number(selectedCheckBoxCount) == 0) {
        setremainingamountdue("0.00");
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      } else {
        setremainingamountdue(
          Number(totaldue) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount) +
            Number(carryRentAmount) -
            Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
        if (
          Number(
            Number(totaldue) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount) +
              Number(carryRentAmount) -
              Number(resTotalAmount)
          ) < 0
        ) {
          setremainingamountdue("0.00");
          setAcceptpaymentbuttonenable(false);
          setchange(
            String(
              Number(availableCreds) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount) +
                Number(resTotalAmount) -
                Number(carryRentAmount) -
                Number(totaldue)
            )
          );
        }
      }
      setcarryRentAmount("0.00");
      if (paygridvalues != undefined) {
        for (let i = 0; i < paygridvalues.length; i++) {
          paygridvalues[i].carryRentAmountAdd = 0.0;
        }
      }
    }
  };
  const carryCheckChange = () => {
    // debugger;
    // if(Number(carrytotalCalci)>Number(carryRentAmount)){
    //   setremainingamountdue(Number((Number(remainingamountdue)-Number(carryRentAmount)).toFixed(2)))
    //   settotaldue(Number(Number(totaldue)-Number(carryRentAmount)).toFixed(2))
    //   // setcarryRentAmount("0.00")
    //   setcarryRentCheck(false)
    // }else{
    // if((carryRentCheck)==true){

    let ldwtax = 0;
    let ldwAmount = 0;
    let ldwtaxwithAmount = 0;

    let totalCalciText = 0;
    let reqAgreeArray = checkedAgr.filter(
      (x) =>
        x.Agreementtype != "Club" &&
        x.Nextduedate != "SAC" &&
        x.Nextduedate != "PIF" &&
        x.Nextduedate != "EPO" &&
        x.number_of_paymentsmade != 0 &&
        x.FreeTime == null // Added By Me
    );

    // if(ag)
    for (let i = 0; i < reqAgreeArray.length; i++) {
      let ldwTerminateToggle = 0;
      let coverageEndDateInfo: any = [];

      //  if(props.mxcarry[i].agreementRateDetails=="Weekly"){

      if (reqAgreeArray[i].agreementRateDetails != undefined) {
        let startDate = moment(reqAgreeArray[i].CurrentDueDate);
        let endDate = moment(reqAgreeArray[i].Nextduedate);
        let result = endDate.diff(startDate, "days");
        if (Number(result) != 1) {
          totalCalciText =
            totalCalciText +
            (Number(reqAgreeArray[i].agreementRateDetails.perDayRent) +
              Number(reqAgreeArray[i].agreementRateDetails.perDayRentTax));
          for (let j = 0; j < actLDWArr.length; j++) {
            // let coverageEndDate=""
            // let policyStatus=""
            if (actLDWArr.length > 0) {
              // props.ldwChangeArr
              coverageEndDateInfo = actLDWArr.filter(
                (x) =>
                  String(x.agreementId) === String(reqAgreeArray[i].Agreementid)
              );
              if (coverageEndDateInfo.length > 0) {
                if (Number(coverageEndDateInfo[0].action) == 2) {
                  // coverageEndDate =  moment().format('MM/DD/YYYY');
                  ldwTerminateToggle = 1;
                } else if (Number(coverageEndDateInfo[0].action) == 1) {
                  // coverageEndDate = "12/31/2099"
                  ldwTerminateToggle = 2;
                  if (
                    Number(reqAgreeArray[i].agreementRateDetails.perDayLdw) == 0
                  ) {
                    reqAgreeArray[i].agreementRateDetails.perDayLdw = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.weeklyRate
                      ) / 7
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.perDayLdwTax = (
                      (Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.weeklyRate
                      ) /
                        7) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.weeklyLdw = Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.weeklyRate
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.weeklyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.weeklyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.biWeeklyLdw = Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.biweeklyRate
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.biWeeklyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.biweeklyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.monthlyLdw = Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.monthlyRate
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.monthlyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.monthlyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.semiMonthlyLdw =
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.semimonthlyRate
                      ).toFixed(2);
                    reqAgreeArray[i].agreementRateDetails.semiMonthlyLdwTax = (
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .scheduleRates.semimonthlyRate
                      ) *
                      Number(
                        coverageEndDateInfo[0].cusCoverageData.ldwInfo
                          .ldwTaxRate
                      )
                    ).toFixed(2);
                  }
                }
              }
            }
          }
          if (
            Number(reqAgreeArray[i].agreementRateDetails.perDayLdw) == 0 &&
            ldwTerminateToggle == 2
          ) {
            if (
              Number(
                coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                  .weeklyRate
              ) > 0
            ) {
              ldwtaxwithAmount =
                ldwtaxwithAmount +
                Number(
                  (
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.weeklyRate
                    ) / 7
                  ).toFixed(2)
                );
            }
          } else {
            ldwtax =
              ldwTerminateToggle == 1 ||
              Number(reqAgreeArray[i].agrLdwAmount) == 0
                ? 0
                : Number(reqAgreeArray[i].agreementRateDetails.perDayLdwTax);
            ldwAmount =
              ldwTerminateToggle == 1 ||
              Number(reqAgreeArray[i].agrLdwAmount) == 0
                ? 0
                : Number(reqAgreeArray[i].agreementRateDetails.perDayLdw);
            ldwtaxwithAmount =
              ldwtaxwithAmount + Number(ldwtax) + Number(ldwAmount);
          }
        }
      }
    }

    totalCalciText = Number(
      (Number(totalCalciText) + Number(ldwtaxwithAmount)).toFixed(2)
    );
    let resTotalAmount = 0.0;
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (selectallChecked && carryRentInitialAndFinal == false) {
      setremainingamountdue(
        Number(
          (
            Number(remainingamountdue) +
            Number(addSusTotalAmount) -
            Number(useSuspenseAmount) -
            Number(carryRentAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(remainingamountdue) +
            Number(addSusTotalAmount) -
            Number(useSuspenseAmount) -
            Number(carryRentAmount)
          ).toFixed(2)
        )
      );
      settotaldue(
        Number(Number(totaldue) - Number(carryRentAmount)).toFixed(2)
      );
      // setcarryRentAmount("0.00")
      setselectallChecked(false);
    } else if (
      Number(Number(totalCalciText).toFixed(2)) < Number(carryRentAmount) ||
      (carryRentInitialAndFinal && selectallChecked == false)
    ) {
      setremainingamountdue(
        Number(
          (
            Number(remainingamountdue) +
            Number(addSusTotalAmount) -
            Number(useSuspenseAmount) +
            Number(carryRentAmount)
          ).toFixed(2)
        )
      );
      setRemaniningAmtStatic(
        Number(
          (
            Number(remainingamountdue) +
            Number(addSusTotalAmount) -
            Number(useSuspenseAmount) +
            Number(carryRentAmount)
          ).toFixed(2)
        )
      );
      settotaldue(
        Number(Number(totaldue) + Number(carryRentAmount)).toFixed(2)
      );
      setcarryRentAmount("0.00");
      if (paygridvalues != undefined) {
        for (let i = 0; i < paygridvalues.length; i++) {
          paygridvalues[i].carryRentAmountAdd = 0.0;
        }
      }
      if (carryRentInitialAndFinal) {
        setcarryRentWidget(false);
      }
    }
    if (carryRentInitialAndFinal && selectallChecked == true) {
      setcarryRentAmount("0.00");
      if (paygridvalues != undefined) {
        for (let i = 0; i < paygridvalues.length; i++) {
          paygridvalues[i].carryRentAmountAdd = 0.0;
        }
      }
    }
    const cardAmount =
      multipleCard.length == 0
        ? 0.0
        : multipleCard
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let cvvToggle = 0;
    if (
      (Number(cardAmount) > 0 && cvvTextValue.length < 3) ||
      CardErrorLbl == true
    ) {
      cvvToggle = 1;
    }
    if (
      (Number(
        Number(resTotalAmount) +
          Number(carryRentAmount) +
          Number(addSusTotalAmount) -
          Number(useSuspenseAmount)
      ) >=
        Number(totaldue) +
          Number(carryRentAmount) +
          Number(addSusTotalAmount) -
          Number(useSuspenseAmount) &&
        selectedCheckBoxCount > 0) ||
      (Number(remainingamountdue) <= 0 &&
        cvvToggle == 0 &&
        selectedCheckBoxCount > 0)
    ) {
      setAcceptpaymentbuttonenable(false);
    } else {
      setAcceptpaymentbuttonenable(true);
    }
    setcarryRentCheck(false);
    if (carryRentWidget) {
      carryOnchange();
    }
    // }

    // }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignsave_click = async () => {
    try {
      // eslint-disable-next-line no-debugger
      // //debugger;
      const removeAlert: any = [];
      const AlertWizardObj: any = AlertWizard;
      // eslint-disable-next-line no-console
      console.log("AlertUpdateObj", AlertUpdateObj);
      for (let i = 0; i < AlertUpdateObj.length; i++) {
        const alertExist = AlertWizardObj.filter(
          (val: any) => AlertUpdateObj[i].alertTypeId == val.alertTypeId
        );
        if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared !== 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
              AlertUpdateObj[i].customerAlertId !== null &&
              AlertUpdateObj[i].customerAlertId !== ""
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != "3"
                ? parseInt(alertExist[0].customerAlertId)
                : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == "3" ? CustomAlerttxt : "",
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        } else if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared == 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
              AlertUpdateObj[i].customerAlertId !== null &&
              AlertUpdateObj[i].customerAlertId !== ""
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != "3"
                ? parseInt(alertExist[0].customerAlertId)
                : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == "3" ? CustomAlerttxt : "",
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        }
      }
      // debugger;
      let removeAlertObj = removeAlert;
      removeAlertObj = removeAlertObj.filter(
        (val: any) =>
          (val.customerAlertId == undefined || val.customerAlertId == null) &&
          val.alertClear == 1
      );
      const AlertObj: any = [];
      for (let i = 0; i < removeAlert.length; i++) {
        const inValidAlert = removeAlertObj.filter(
          (val: any) => val.alertTypeId == removeAlert[i].alertTypeId
        );
        if (inValidAlert.length == 0) {
          AlertObj.push(removeAlert[i]);
        }
      }
      const payload: any = {
        alertTypes: AlertObj,
        customerId: parseInt(customerId),
        userId: "191197",
      };
      /* eslint-disable no-console */
      console.log("payload", payload);
      /* eslint-disable no-console */
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      // eslint-disable-next-line no-console
      setCustomAlerttxt("");
      setEnabletxt(true);
      console.log("response", response);
      if (response !== undefined && response !== null) {
        await buildActiveAlerts();
      }
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    } catch (e: any) {
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    }
  };

  const Cancelclick = () => {
    // debugger;
    const activeAlertObj = activeAlert;
    //added
    const actAlert: any = [];
    if (activeAlertObj.length > 0) {
      for (let i = 0; i < activeAlertObj.length; i++) {
        const alertActive = AlertLoad.findIndex(
          (alert: any) => alert.alertTypeId == activeAlertObj[i].alertTypeId
        );
        if (alertActive !== -1) {
          //added
          const al: any = activeAlertObj[i];
          // if (al.alertTypeId == '3') {
          //   al.alertCleared = 1;
          // } else {
          al.alertCleared = AlertLoad[alertActive].alertCleared;
          // }
          actAlert.push(al);
          //ends
          activeAlertObj[i].alertCleared = AlertLoad[alertActive].alertCleared;
        }
      }
    }
    const filterOtherCheckBox: any = actAlert.filter(
      (el: any) => el.alertTypeId !== 3 && el.alertTypeId !== "3"
    );
    setAlertUpdateObj([...filterOtherCheckBox]);
    setEnabletxt(true);
    setCustomAlerttxt("");
    setdisableAlert(false);
    closeAlert();
  };
  const racCoWorkerFunc = async (moduleName: string) => {
    //const d: any = document.getElementsByName('RAC_Co_worker');
    try {
      setSecondLevelAuthenOn(moduleName);
    } catch (e: any) {
      //setloader(false);
      // eslint-disable-next-line no-console
      console.log("getprofileaccess payload", e);
    }
  };

  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const getlegalHold = async () => {
    let isLeaglHold = false;
    try {
      const getLegalHold = await GetLegalSatatus(customerId);
      /* eslint-disable no-console */
      console.log("getLegalHold", getLegalHold);

      setLegalHold(
        getLegalHold !== undefined &&
          getLegalHold !== null &&
          getLegalHold?.legalHoldStatusRes !== undefined &&
          getLegalHold?.legalHoldStatusRes !== null &&
          getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0]
          : null
      );
      isLeaglHold =
        getLegalHold !== undefined &&
        getLegalHold !== null &&
        getLegalHold?.legalHoldStatusRes !== undefined &&
        getLegalHold?.legalHoldStatusRes !== null &&
        getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0].legalHoldStatus == "true" ||
            getLegalHold.legalHoldStatusRes[0].legalHoldStatus === true
            ? true
            : false
          : false;
      setIsLegalHold(isLeaglHold);
    } catch (e: any) {
      setLegalHold(null);
      /* eslint-disable no-console */
      console.log("getLegalHold", e);
    }
    return isLeaglHold;
  };

  // const getCoWorkerRole = async (isLegalHold: boolean) => {
  //   try {
  //     // eslint-disable-next-line

  //     //if (isLegalHold) {
  //     // if(containerData != undefined){
  //     //   currentUserResponse =  containerData.GetEmployeeId();
  //     //   setempId(currentUserResponse);
  //     // }
  //     // const coWorkerRoleObj: any = ;
  //     // if (
  //     //   coWorkerRoleObj?.coworkerProfile !== undefined &&
  //     //   coWorkerRoleObj?.coworkerProfile !== null &&
  //     //   coWorkerRoleObj?.coworkerProfile.role !== undefined &&
  //     //   coWorkerRoleObj?.coworkerProfile.role !== null
  //     // ) {
  //       //currentUserResponse =  containerData.getRole();
  //       const roleObj = containerData.getRole();
  //       coWorkerRole = roleObj;
  //       setLegalHoldApplied(
  //         (roleObj == 'Store Manager' ||
  //           roleObj == 'Customer Account Rep' ||
  //           roleObj == 'Assistant Manager' ||
  //           roleObj == 'Lead Assistant Manager' ||
  //           roleObj == 'District Manager') &&
  //           isLegalHold
  //           ? 1
  //           : isLegalHold &&
  //             (roleObj == 'DM in Training' || roleObj == 'Regional Director')
  //             ? 2
  //             : 0
  //       );
  //       setIsSecondAuthenValid(
  //         roleObj == 'DM in Training' ||
  //           roleObj == 'Regional Director' ||
  //           roleObj == 'District Manager'
  //           ? true
  //           : false
  //       );
  //     //}
  //     /* eslint-disable no-console */
  //     //console.log('coWorkerRoleObj', coWorkerRoleObj);
  //     // } else {
  //     //   setLegalHoldApplied(0);
  //     // }
  //   } catch (e: any) {
  //     setLegalHoldApplied(0);
  //     /* eslint-disable no-console */
  //     console.log('Error in co worker role');
  //   }
  // };

  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== "" && password !== "") {
      event.preventDefault();
      loginClick(username, Password);
    }
  };

  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem("storeNumber"),
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    setloader(false);
    UserValidate(result);
  };

  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === "200" &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        // if (authenType == 'RACWORKER') {
        //   setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
        // }
        if (authenType == "ALERT") {
          const alertUpdateObj: any = AlertUpdateObj;
          alertUpdateObj.push(AlertAuthenticationNeeded);
          setAlertUpdateObj([...alertUpdateObj]);
          setAlertAuthenticationNeeded("");
        }
        // if (authenType == 'ROUTES') {
        //   setcustomerInformation({
        //     ...customerInformation,
        //     route: preferredRoute,
        //   });
        //   setPreferrefRoute('');
        //}
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
    setotp("");
    setUserID("");
    setPassword("");
    setauthenType("");
  };

  const openModelCancelClick = () => {
    setOpenModal(false);
    setErrorLine(false);
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };

  const setChargeOffclose = () => {
    setOpenPinModal(false);
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };

  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);

  // useEffect(()=>{
  //   if(configFlags.isPaymentTraingleFeatureFlag==0&&customerdata==''){
  //     getCustomerData()

  //   }

  // },[configFlags])
  // const getCustomerData =async ()=>{
  //   const customer_id: any = customerId
  //     const Storenumber: any = String(window.sessionStorage.getItem("storeNumber"))

  //     const result: any =  await GetCustomerByIdInfo(customer_id, Storenumber);
  //     setcustomerdata(result)

  // }

  // const pinValue = (event: any) => {
  //   otp = event;
  // };

  // const unenrollautopaysendtextservice = () => {
  //   let Schedule = '';
  //   let Amounttounenroll = 0;
  //   let agreementarray: any = [];
  //   for (let i = 0; i < updatedarray.length; i++) {
  //     if (updatedarray[i] === true) {
  //       Schedule = unenrollautopayfilterdata[i].Schedule;
  //       Amounttounenroll = Amounttounenroll + unenrollautopayfilterdata[i].Amount;
  //       agreementarray.push(unenrollautopayfilterdata[i].Agreementid)
  //     }
  //   }
  //   let payload1 = {
  //     language: 'E',
  //     phoneNumber: String(getpayment?.paymentInfo.customerInfo[0].phoneNumber),
  //     textMessage: 'TXT2ACU',
  //     messageType: 'TXT2ACU',
  //     messageTemplateKey: 'TXT2ACU-MESSAGE',
  //     messageSubstitutes: {
  //       schedule: Schedule,
  //       amount: Amounttounenroll
  //     },
  //     callBackParams: {
  //       lastName: getpayment?.paymentInfo.customerInfo[0].lastName,
  //       firstName: getpayment?.paymentInfo.customerInfo[0].firstName,
  //       partyId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId,
  //       agreementIds: agreementarray,
  //       partyClubCoverageIds: [customerId]
  //     },
  //     storeNumber: String(window.sessionStorage.getItem('storeNumber')),
  //     transactionId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId
  //   }
  //   sendMessage(payload1);
  //   setsendtext(true);
  // }
  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    console.log("getCurrentUser Response", userResponse);
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    // eslint-disable-next-line no-console
    console.log("getPin Payload", payload);
    const result = await getPinDetails(payload);
    // eslint-disable-next-line no-console
    console.log("Total Result", result);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      //setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
    }
  };

  const noClick = () => {
    setOpenPinModal(false);
  };

  const ConvenienceFee = (selectedOpt: any) => {
    /* eslint-disable no-console */
    console.log("selected opt", selectedOpt);
    if (selectedOpt === "Pay By Phone") {
      return (
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
        >
          <Grid item>
            <Typography
              className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
            >
              Convenience Fee
            </Typography>
            <Typography
              className={` ${classes.floatRight} ${classes.totallabelstyle} ${classes.totalvaluestyle}`}
            >
              <Typography>${convAmtWTax}</Typography>
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      ("");
    }
  };

  const seconduthenResponses = (e: any) => {
    if (e === true && authenType == "ALERT") {
      let alertUpdateObj: any = AlertUpdateObj;
      if (AlertAuthenticationNeeded.removeAlert == undefined) {
        alertUpdateObj.push(AlertAuthenticationNeeded);
      } else {
        alertUpdateObj = alterAlert(
          "remove",
          null,
          AlertAuthenticationNeeded.alertTypeId
        );
      }
      setAlertUpdateObj([...alertUpdateObj]);
      setAlertAuthenticationNeeded("");
    }
    setSecondLevelAuthenOn(null);
    setauthenType("");
  };

  const alertPopUp = () => {
    return (
      <Grid container>
        <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
          {Assignalert()}
          {Enabletxt !== true ? (
            <RACTextbox
              isCurrency={false}
              className={classes.mb4}
              id="CustomAlert"
              value={CustomAlerttxt}
              OnChange={(e) => CustomAlertval(e)}
            />
          ) : null}
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.textright} ${classes.modalfooter}`}
        >
          <RACButton
            size="small"
            data-bs-dismiss="modal"
            aria-label="Close"
            //style={{ textTransform: 'none', fontWeight: 'bolder', paddingLeft: '14px', paddingRight: '14px' }}
            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
            variant="outlined"
            color="primary"
            onClick={() => Cancelclick()}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            disabled={
              (activeAlert.length == 0 && AlertUpdateObj.length == 0) ||
              disableAlert
                ? true
                : false
            }
            className={`${classes.paymentSmallBtn1}`}
            onClick={() => {
              Assignsave_click();
              closeAlert();
            }}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const openModalFunc = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <RacLogo></RacLogo>
        </Grid>
        {errorline === true ? (
          <Grid
            className={`${classes.floatLeft} ${classes.my2} ${classes.mx1}`}
          >
            <Typography
              className={`${classes.racLoginAlert} ${classes.mandatoryfield}`}
            >
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2}>
          <Grid item className={classes.w100}>
            <Typography
              variant="body2"
              component="label"
              className={classes.semibold}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              className={classes.w100}
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item className={classes.w100}>
            <Typography
              variant="body2"
              component="label"
              className={classes.semibold}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event) => secondfactorEnter(event, userID, password)}
              isCurrency={false}
              className={classes.w100}
              type="password"
              value={password}
              OnChange={(event) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid className={`${classes.floatRight} ${classes.mt5} ${classes.mb3}`}>
          <RACButton
            variant="outlined"
            color="primary"
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          {userID !== "" && password !== "" ? (
            <RACButton
              className={classes.me2}
              color="primary"
              variant="contained"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton
              className={classes.ms2}
              color="primary"
              variant="contained"
            >
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };

  const openSecondModalFunc = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <img
            className={classes.racErrorIcon}
            src={NoRecordsFound}
            alt="Logo"
          />
          <Typography className={classes.racErrorFunction}>
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            className={classes.me2}
            onClick={okClick}
            color="primary"
            variant="contained"
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };

  const openPinModalFunc = () => {
    return (
      <div>
        <Grid container className={classes.racPinContainer}>
          <Grid item md={12}>
            <Typography className={classes.racPinLabel}>PIN</Typography>
          </Grid>
          <Grid onKeyPress={pinEnter}>
            {/* <PinField
                id="pininput"
                className="racPinField"
                length={4}
                validate="0123456789"
                type="password"
                autoFocus={true}
                onComplete={(event) => pinValue(event)}
                onChange={(event) => otpchange(event)}
              /> */}
            <PinField
              length={4}
              type="numeric"
              focus={true}
              secret={true}
              autoSelect={false}
              style={{ marginLeft: "0.3rem" }}
              inputStyle={{
                border: "1px solid #adb5bd",
                borderRadius: "0.6rem",
                marginRight: "0.9rem",
              }}
              //onComplete={(event) => pinValue(event)}
              onChange={(event) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className={classes.racPinError}>
                Invalid PIN{" "}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid className={`${classes.floatRight} ${classes.mt5} ${classes.mb3}`}>
          <RACButton
            className={classes.me2}
            variant="outlined"
            color="primary"
            onClick={noClick}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.me2}
            disabled={Continue}
            color="primary"
            variant="contained"
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  const onCloseSecondfacClick = () => {
    setOpenModal(false);
  };

  const AutopayChkboxAction = (e: any) => {
    if (e.target.checked === true) {
      setAutopaybutton(false);
    } else {
      setAutopaybutton(true);
    }
  };
  const unenrollautopaysendtextservice = () => {
    let Schedule = "";
    let Amounttounenroll = 0;
    let clubselectedvar = false;
    let agreementarray: any = [];
    for (let i = 0; i < updatedarray.length; i++) {
      if (updatedarray[i] === true) {
        Schedule = unenrollautopayfilterdata[i].Schedule;
        Amounttounenroll =
          Amounttounenroll + unenrollautopayfilterdata[i].Amount;
        if (unenrollautopayfilterdata[i].Agreementtype === "Club") {
          clubselectedvar = true;
        } else {
          agreementarray.push(unenrollautopayfilterdata[i].Agreementid);
        }
      }
    }
    let number = String(getpayment?.paymentInfo.customerInfo[0].phoneNumber);
    number = number.replace(/[^\d]/g, "");
    let Payload2: AutopaySendMessageRequest = {
      sendTextFlag: 1,
      callTxtReq: {
        language: "E",
        phoneNumber: String(number),
        textMessage: "TXT2ACU",
        messageType: "TXT2ACU",
        messageTemplateKey: "TXT2ACU-MESSAGE",
        messageSubstitutes: {
          schedule: Schedule,
          amount: String(Number(Amounttounenroll).toFixed(2)),
        },
        callBackParams: {
          lastName: String(getpayment?.paymentInfo.customerInfo[0].lastName),
          firstName: String(getpayment?.paymentInfo.customerInfo[0].firstName),
          customerId: customerId,
          customerPaymentMethodId: "",
          agreementIds: agreementarray,
          clubId:
            clubselectedvar === true
              ? String(
                  getpayment?.paymentInfo.clubInformation[0].customerClubId
                )
              : "",
        },
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        transactionId: customerId,
      },
    };
    // let payload1 = {
    //   language: 'E',
    //   phoneNumber: String(getpayment?.paymentInfo.customerInfo[0].phoneNumber),
    //   textMessage: 'TXT2ACU',
    //   messageType: 'TXT2ACU',
    //   messageTemplateKey: 'TXT2ACU-MESSAGE',
    //   messageSubstitutes: {
    //     schedule: Schedule,
    //     amount: Amounttounenroll
    //   },
    //   callBackParams: {
    //     lastName: getpayment?.paymentInfo.customerInfo[0].lastName,
    //     firstName: getpayment?.paymentInfo.customerInfo[0].firstName,
    //     partyId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId,
    //     agreementIds: agreementarray,
    //     partyClubCoverageIds: clubselectedvar === true ? [customerId] : [],
    //   },
    //   storeNumber: String(window.sessionStorage.getItem('storeNumber')),
    //   transactionId: getpayment?.paymentInfo.customerInfo[0].entPartyId !== null ? String(getpayment?.paymentInfo.customerInfo[0].entPartyId) : customerId
    // }
    sendMessage(Payload2);
    setsendtext(true);
    setunenrollautopayopen(false);
  };

  const unEnrollAutoPay = () => {
    return (
      <>
        <Grid item className={classes.w100}>
          <Grid item className={classes.justifycontentcenter}>
            <Grid item className={classes.raccollg7}>
              <Typography
                variant="h4"
                className={`${classes.bold} ${classes.mb3} ${classes.floatLeft} ${classes.textcenter} ${classes.mb3}`}
              >
                Discuss the benefits of remaining on Autopay!
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid item className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  1
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Convenience
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Save Customer time
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid className={classes.raccollg8}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  2
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Zero Fees
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Avoid convenience/phone fees{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Typography className={`${classes.font14} ${classes.mb3} `}>
            Please select agreement(s)/Club to un-enroll from Autopay.Autopay
            un-enrollment will be effective immediately.
          </Typography>
          <Grid item className={`${classes.raccollg12} ${classes.floatLeft}`}>
            <Grid
              item
              className={`${classes.w100} ${classes.mb3} ${classes.floatLeft}`}
            >
              <RACTable
                renderTableHead={renderBindGridHeader}
                renderTableContent={() => Gridbindingunenrollautopay()}
              />
            </Grid>

            {/* <Grid item className='autopay-card-height'>
                      <Grid item className="w-100">
                        <Grid item className="w-100 font-14 mb-3">
                        To enroll with a different schedule, use the 'Autopay Signup Text' button on the Accept Payment screen - 'Signup with Card on File' option.
                        </Grid>
                        <Grid item className="justify-content-center d-flex" style={{ width: '100%' }}>
                          <Grid item className='centerLayout rac-col-md-5 mb-3' >
                            <Box component="label" className="mb-2 floatLeft" >Please select one Card on File</Box>
                            {ListOfCardsBind(ListOfCards)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> */}
            <Typography
              className={`${classes.font14} ${classes.RACalertmsg} ${classes.mb3}`}
            >
              Note:Only the last text message sent will be used for Autopay
              un-enrollment
            </Typography>
          </Grid>
          <Grid
            item
            className={`${classes.mb3} ${classes.floatLeft} ${classes.w100}`}
          >
            <Grid
              item
              className={`${classes.formcheck} ${classes.mb3} ${classes.floatLeft}`}
            >
              <RACCheckBox
                className={`${classes.formcheckinput} ${classes.floatLeft} ${classes.checkboxStyle}`}
                onClick={(e) => {
                  verbalcheckbox(e);
                }}
                checked={verbalecheckbox}
                data-testid="AutopayCheckTest"
              />
              <Box component="label" className={classes.ms2}>
                Customer has verbally consented to receive this text message
              </Box>
            </Grid>
          </Grid>
          <Typography
            className={`${classes.font14} ${classes.mb3} ${classes.textcenter}`}
          >
            Autopay will be associated with the Primary Renter
          </Typography>
          <Grid
            item
            className={`${classes.justifycontentcenter} ${classes.mb3}`}
          >
            <Grid item className={classes.raccollg7}>
              <Grid
                item
                className={`${classes.w100} ${classes.w100} ${classes.mb2}`}
              >
                <Box
                  component="span"
                  className={`${classes.me3} ${classes.font14}`}
                >
                  {" "}
                  Customer Name :
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {" "}
                  {getpayment !== undefined
                    ? getpayment.paymentInfo.customerInfo[0].firstName +
                      " " +
                      getpayment.paymentInfo.customerInfo[0].lastName
                    : null}
                </Box>
              </Grid>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Box
                  component="span"
                  className={`${classes.w100} ${classes.me3} ${classes.font14}`}
                >
                  Phone Number :
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {" "}
                  {getpayment?.paymentInfo.customerInfo[0].phoneNumber}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          className={`${classes.raccollg12} ${classes.py4} ${classes.textcenter}`}
        >
          {/* <RACButton
            // type="button"
            className={`${classes.mx0} ${classes.clearbtn} ${classes.px1}`}
            data-bs-dismiss="modal"
            onClick={() => AutoPayClearClick()}
            data-testid="AutoPayNotnowTest"
          >
            Clear
          </RACButton> */}

          <RACButton
            // type="button"
            className={classes.mx1}
            color="primary"
            variant="outlined"
            data-bs-dismiss="modal"
            onClick={unenrollAutoPayCloseClick}
            data-testid="AutoPayNotnowTest"
          >
            Not now
          </RACButton>

          <RACButton
            // type="button"
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#Customer-Signature"
            onClick={() => unenrollautopaysendtextservice()}
            disabled={sendtext}
            data-testid="SendTxtAsPaymentTest"
          >
            Send Text
          </RACButton>
        </Grid>
      </>
    );
  };

  const autoPayPopUp = () => {
    return (
      <>
        <Grid item className={classes.w100}>
          <Grid item className={classes.justifycontentcenter}>
            <Grid item className={classes.raccollg9}>
              <Typography
                variant="h4"
                className={`${classes.bold} ${classes.mb3} ${classes.floatLeft} ${classes.textcenter} ${classes.mb3}`}
              >
                Discuss the benefits of Autopay and enroll your customer today
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginLeft: "12px" }}
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid item className={classes.raccollg10}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  1
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Convenience
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Save Customer Time
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            style={{ marginLeft: "12px" }}
            className={`${classes.mb3} ${classes.justifycontentcenter}`}
          >
            <Grid className={classes.raccollg10}>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  className={`${classes.autopayValue} ${classes.me2}`}
                >
                  2
                </Typography>
              </Grid>
              <Grid xs="auto" className={classes.floatLeft}>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitle} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Zero Fees
                </Typography>
                <Typography
                  variant="caption"
                  className={`${classes.autoPaySubTitleAccent} ${classes.w100} ${classes.floatLeft}`}
                >
                  Avoid convenience/phone fees{" "}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            className={`${classes.mb3} ${classes.floatLeft} ${classes.w100}`}
          >
            <Grid
              item
              className={`${classes.formcheck} ${classes.mb3} ${classes.floatLeft}`}
            >
              <RACCheckBox
                // className={`${classes.formcheckinput} ${classes.floatLeft} ${classes.checkboxStyle}`}
                id="flexCheckDefault"
                onClick={(e) => {
                  verbalcheckbox(e);
                }}
                checked={verbalecheckbox}
                data-testid="AutopayCheckTest"
              />
              <Box component="label" className={classes.ms2}>
                Customer has verbally consented to receive this text message
              </Box>
            </Grid>
          </Grid>
          <Typography
            className={`${classes.font14} ${classes.mb3} ${classes.textcenter}`}
          >
            Autopay will be associated with the Primary Renter
          </Typography>
          <Grid
            item
            className={`${classes.justifycontentcenter} ${classes.mb3}`}
          >
            <Grid item className={classes.raccollg8}>
              <Grid
                item
                className={`${classes.w100} ${classes.w100} ${classes.mb2}`}
              >
                <Box
                  component="span"
                  className={`${classes.me3} ${classes.font14}`}
                >
                  {" "}
                  Customer Name
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {customerName}
                </Box>
              </Grid>
              <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
                <Box
                  component="span"
                  className={`${classes.w100} ${classes.me3} ${classes.font14}`}
                >
                  Phone Number
                </Box>
                <Box
                  component="span"
                  className={`${classes.semibold} ${classes.font14}`}
                >
                  {" "}
                  {formatPhone(
                    getpayment?.paymentInfo.customerInfo == undefined
                      ? ""
                      : getpayment?.paymentInfo.customerInfo[0].phoneNumber
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.raccollg12} ${classes.py4} ${classes.textcenter}`}
          >
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="outlined"
              data-bs-dismiss="modal"
              onClick={() => AutoPayNotnowClick()}
              data-testid="AutoPayNotnowTest"
            >
              Not now
            </RACButton>

            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target="#Customer-Signature"
              onClick={() => AutoPaySubmitClick()}
              disabled={sendtext}
              data-testid="SendTxtAsPaymentTest"
            >
              Send Autopay Signup Text
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  //syed change 4

  const addsuspensefields = [
    { display: "Agreement #" },
    { display: "Schedule" },
    { display: "Payment" },
    { display: "EPO /SAC(Without Suspense)" },
    { display: "Current Suspense" },
    { display: "New Suspense" },
  ];

  const suspenseBindGridHead = () => (
    <>
      {addsuspensefields.map((val, index) => {
        return val.display === null ? (
          <RACTableCell key={index} className={`${classes.font12}`}>
            <span style={{ float: "right" }}>{val.display}</span>
          </RACTableCell>
        ) : (
          <RACTableCell key={index} className={`${classes.font12}`}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );

  const gridheadfields = [
    { display: null },
    { display: "Agreement #" },
    { display: "Type" },
    { display: "Description" },
    { display: "Schedule" },
    { display: "Current Due Date" },
    { display: "Next Due Date" },
    { display: "Current Days Late" },
    { display: "Amount Due" },
    { display: "" },
  ];

  const renderBindGridHead = () => (
    <>
      {gridheadfields.map((val, index) => {
        return val.display === null ? (
          <RACTableCell
            className={` ${classes.checkboxwidth} ${classes.font12}`}
          >
            <input
              //className="form-check-input"
              style={{ marginTop: "7px", marginLeft: "19px" }}
              type="checkbox"
              id="maincheckbox"
              data-testid="maincheckboxId"
              disabled={
                agreementId !== "0" || paygridvalues.length == 0 ? true : false
              }
              checked={OverAll_Chk}
              onClick={(e) => selectallcheckbox(undefined, e)}
            />
          </RACTableCell>
        ) : val.display === "Current Days Late" ||
          val.display === "Amount Due" ? (
          <RACTableCell key={index} className={`${classes.font12}`}>
            <span style={{ float: "right" }}>{val.display}</span>
          </RACTableCell>
        ) : (
          <RACTableCell key={index} className={`${classes.font12}`}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );
  //activateClubModal
  const activateClubPopup = () => {
    return (
      <Grid
        item
        id="terminateldw"
        data-testid="ActivateClubPopupId"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="terminateldw"
        aria-hidden="true"
      >
        <Typography className={classes.ps2}>
          By activating Club, your regular payment with LDW will increase by
          <Typography
            variant="caption"
            className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
          >
            ${clubAmount}
          </Typography>
          . Do you want to activate Club?
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
        >
          <RACButton
            // type="button"
            className={classes.mx1}
            variant="outlined"
            color="primary"
            data-testid="ActivateLDWCancelId"
            onClick={() =>
              setclubStatusPopup({ ...clubStatusPopup, activateClub: false })
            }
            data-bs-dismiss="modal"
          >
            No
          </RACButton>
          <RACButton
            // type="button"
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-testid="continueActivateLDWId"
            onClick={() =>
              activateDeactivateClubApiCall(1, "P", "activateClub")
            }
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  //deactivateClubModal
  const deactivateClubPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textcenter}>
          <Typography className={classes.RACPOPMsg}>
            {"By deactivating club, your regular payment will decrease by $" +
              deactivateClubAmount +
              ".   Press continue to confirm"}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifycontentcenter}`}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={`${classes.me2} ${classes.foc}`}
            onClick={() =>
              setclubStatusPopup({ ...clubStatusPopup, deactivateClub: false })
            }
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => deActivatePopupYesHandleChange()}
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const deactivateClubCustomerRedirectionPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textcenter}>
          <Typography className={classes.RACPOPMsg}>
            {
              "Customer has no active agreement Please deactivate Club in Customer Editor Screen Using Club button"
            }
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifycontentcenter}`}
        >
          <RACButton
            color="primary"
            variant="outlined"
            className={`${classes.me2} ${classes.foc}`}
            onClick={() => setdeactivateClubCustomerRedirection(false)}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => deactivateClubCustomerRedirectionChange()}
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const unEnrollautoPopUpHeadFields = [
    { display: "Document Type" },
    { display: "Document #" },
    { display: "Document From" },
    { display: "Amount" },
    { display: "Action" },
  ];

  const renderUnEnrollBindGridHead = () => (
    <>
      {unEnrollautoPopUpHeadFields.map((val, index) => {
        return val.display === "Document Type" ||
          val.display === "Document #" ||
          val.display === "Document From" ? (
          <RACTableCell className={classes.textleft} key={index}>
            {val.display}
          </RACTableCell>
        ) : val.display === "Amount" ? (
          <RACTableCell className={classes.textright} key={index}>
            {val.display}
          </RACTableCell>
        ) : val.display === "Action" ? (
          <RACTableCell className={classes.textcenter} key={index}>
            {val.display}
          </RACTableCell>
        ) : null;
      })}
    </>
  );

  const addcheckpopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <RACSelect
              data-testid="dropmainId"
              options={CheckDropdownArr}
              defaultValue={adddata.checktype}
              inputLabel="Type of Check"
              required={true}
              onChange={(e) => setchecktype(e)}
            ></RACSelect>
            {Type === true ? (
              <label className={classes.mandatoryfield}>
                Please select the Check Type.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              maxlength={20}
              id="Check#"
              name="Check"
              required={true}
              inputlabel=" Check #"
              value={adddata.checkno}
              OnChange={(e) => setcheckno(e)}
              data-testid="checkno"
              isCurrency={false}
            />
            {checknovalid === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Check Number.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              maxlength={30}
              required={true}
              inputlabel="Written To"
              id="WrittenTo"
              value={adddata.Written_To}
              OnChange={(e) => setcheckwrittento(e)}
              data-testid="writtento"
              isCurrency={false}
            />
            {Writtento === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Written To.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              isCurrency={false}
              maxlength={30}
              id="Writtenby"
              inputlabel="Written By"
              required={true}
              value={adddata.Written_by}
              OnChange={(e) => setcheckwrittentby(e)}
              data-testid="writtenby"
            />
            {writtenby === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Written By.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <label className={classes.labeltxtstyle}>Amount</label>
            <span className={classes.mandatoryfield}></span>
            <div className={classes.inputgroup}>
              <span className={classes.inputgrouptext}>$</span>
              <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={adddata.Amount}
                onValueChange={(e: any) => {
                  /* eslint-disable no-console */
                  console.log(e);
                  setcheckamount(e);
                }}
                onBlur={(e: any) => {
                  /* eslint-disable no-console */
                  console.log(e);
                  setcheckamountblur(e);
                }}
                decimalsLimit={2}
                data-testid="amountbox"
              />
            </div>
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </Grid>
        </Grid>

        <Grid className={`${classes.textright} ${classes.modalfooter}`}>
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => {
              checkormoneyordercancel();
            }}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>

          {button === true ? (
            <RACButton
              className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
              color="primary"
              variant="contained"
              onClick={(e) => upload(e)}
              data-testId="checkupdate"
            >
              Update
            </RACButton>
          ) : (
            <RACButton
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              onClick={(e) => save(e)}
              data-testid="addchecksave"
            >
              Save
            </RACButton>
          )}
        </Grid>
      </Grid>
    );
  };

  const addsuspensepopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <RACTable
            className={classes.racGrid}
            renderTableHead={suspenseBindGridHead}
            //renderTableContent={BindGrid}
            // renderTableContent={BindGridCheckFunc}
            renderTableContent={suspensebindgrid}
          />
        </Grid>

        <Grid
          className={`${classes.floatRight} ${classes.modalfooter}`}
          style={{ marginTop: "20px" }}
        >
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => addsuspensecancelbtn()}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>

          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            onClick={(e) => addSuspenseBtn(e)}
            disabled={susbtndisabled}
            data-testid="addchecksave"
          >
            Add
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const usesuspensepopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <RACTable
            className={classes.racGrid}
            renderTableHead={suspenseBindGridHead}
            renderTableContent={suspenseusebindgrid}
          />
        </Grid>

        {/* <Grid item xs={12} sm={12} md={6} lg={5}>
          <Grid className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}>
            <Grid container className={Payclasses.payHistjustifySpaceBetween}>
              <Grid item xs={12} sm={12} md={6} lg={7} className={Payclasses.payHistmb2}>
                <Grid container>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}> Current Suspense Total: </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}>{totolsuspenseamount}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={5} className={Payclasses.payHistmb2}>
                <Grid container>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}> New suspense total : </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}>{totalnewsuspenseamount}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={6} lg={5} className={Payclasses.payHistmb2}>
                <Grid container>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}> Difference : </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}>${differenceamount}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid
          className={`${classes.floatRight} ${classes.modalfooter}`}
          style={{ marginTop: "20px" }}
        >
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => useuspensecancelbtn()}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>
          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            // eslint-disable-next-line react-hooks/rules-of-hooks
            onClick={() => useSuspenseBtn()}
            data-testid="addchecksave"
            disabled={susbtndisabled}
          >
            Use
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const transsuspensepopup = () => {
    return (
      <Grid
        item
        data-testid="addcheckbtnmode"
        id="check-info"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <RACTable
            className={classes.racGrid}
            renderTableHead={suspenseBindGridHead}
            renderTableContent={suspensetransbindgrid}
          />
        </Grid>
        <Grid>
          <RACCheckBox
            disabled={
              mastertranssuspensearray.length === 1 &&
              Number(mastertranssuspensearray[0].suspenseamount) > 0
                ? false
                : true &&
                  Number(
                    new URLSearchParams(window.location.search).get("returnAGR")
                  ) > 0
            }
            //value={mastertranssuspensearray[0]?.suspenseamount}
            onChange={(e) => {
              if (e.target.checked) {
                checkBoxController(
                  mastertranssuspensearray[0]?.suspenseamount,
                  "TransferUsed"
                );
                setNewCOATotal(mastertranssuspensearray[0]?.suspenseamount);
                setsusbtndisabled(false);
              } else {
                checkBoxController(0.0, "TransferUsed");
                setNewCOATotal("0.00");
                setsusbtndisabled(true);
              }
            }}
            label={"Transfer Return Agreement Suspense to COA"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Grid
            className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}
          >
            <Grid container className={Payclasses.payHistjustifySpaceBetween}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={7}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      Current Suspense Total:{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      {"  " + "$" + totolsuspenseamount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      New Suspense Total :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      {"$" + Number(totalnewsuspenseamount).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {mastertranssuspensearray.length === 1 &&
              Number(mastertranssuspensearray[0].suspenseamount) > 0 ? (
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        New COA Total :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        {"$" + newCOATotal}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid
                xs={12}
                sm={12}
                md={6}
                lg={5}
                className={Payclasses.payHistmb2}
              >
                <Grid container>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                    >
                      {" "}
                      Difference :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography
                      className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                    >
                      ${Number(differenceamount).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className={`${classes.floatRight} ${classes.modalfooter}`}>
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            style={{
              backgroundColor: "white",
              textTransform: "none",
              fontWeight: "bolder",
              paddingLeft: "14px",
              paddingRight: "14px",
              color: "#2179fe",
            }}
            onClick={() => settransuspense(false)}
            data-bs-toggle="modal"
          >
            Cancel
          </RACButton>
          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            disabled={susbtndisabled || transferDifferenceZero}
            // eslint-disable-next-line react-hooks/rules-of-hooks
            onClick={() => transSuspenseBtn()}
            data-testid="addchecksave"
          >
            Transfer
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const checkBoxController = (valueFromCheck, type) => {
    settranssus("0.00");
    mastertranssuspensearray[0].newsuspense = "0.00";
    mastertranssuspensearray[0].sustype = type;
    console.log(
      "** inputText value from the check trans **",
      mastertranssuspensearray[0]
    );
    setmastertranssuspensearray(mastertranssuspensearray);
  }; // SQ-2 This method will be assigning the suspense type and new suspense amount.

  const refundpopup = () => {
    if (masterrefundsuspensearray.length > 0) {
      return (
        <Grid
          item
          data-testid="addcheckbtnmode"
          id="check-info"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
        >
          <Grid container spacing={2} className={classes.px3}>
            <RACTable
              className={classes.racGrid}
              renderTableHead={suspenseBindGridHead}
              renderTableContent={suspenserefundbindgrid}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={5}>
            <Grid
              className={` ${Payclasses.payHistbgLightBlue} ${Payclasses.payHistp3} ${Payclasses.payHistmt2} ${Payclasses.payHistborderRadius5}`}
            >
              <Grid container className={Payclasses.payHistjustifySpaceBetween}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={7}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        Current Suspense Total:{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        {totolsuspenseamount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        New suspense total :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        {totalnewsuspenseamount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={12}
                  md={6}
                  lg={5}
                  className={Payclasses.payHistmb2}
                >
                  <Grid container>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7}`}
                      >
                        {" "}
                        Difference :{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        className={`${Payclasses.payHisttextBlue} ${Payclasses.payHistfs7} ${Payclasses.payHistfontBold}`}
                      >
                        ${differenceamount}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={`${classes.floatRight} ${classes.modalfooter}`}>
            <RACButton
              // type="button"
              className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
              variant="outlined"
              color="primary"
              style={{
                backgroundColor: "white",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#2179fe",
              }}
              onClick={() => {}}
              data-bs-toggle="modal"
            >
              Cancel
            </RACButton>

            <RACButton
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              onClick={() => refundSuspenseBtn()}
              data-testid="addchecksave"
            >
              Refund
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const personalcheckalertpopup = () => {
    return (
      <Grid item data-testid="personal">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            Personal check is not applicable for over payment
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              color="primary"
              variant="contained"
              onClick={() => {
                setpersonalcheckalert(false);
              }}
              data-bs-target="#delivery-receipt"
              data-testid="PersonalOkId"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const Moneyorderalertpopup = () => {
    return (
      <Grid item data-testid="travellercheck">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            Money Order is not applicable for over payment
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-testid="okbuttontravel"
              onClick={() => {
                setoverpayMoneyord(false);
              }}
              data-bs-target="#delivery-receipt"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const travellercheckalertPopup = () => {
    return (
      <Grid item data-testid="travellercheck">
        <Grid
          item
          id="transaction"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Typography className={`${classes.textcenter} ${classes.mt3}`}>
            Traveller check is not applicable for over payment
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
          >
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-testid="okbuttontravel"
              onClick={() => {
                settravellercheckalert(false);
              }}
              data-bs-target="#delivery-receipt"
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  //DeviceDropdown onchange function defines here
  const deviceDropdown = (e: any) => {
    // debugger;
    selectedValue = e.target.value;
    console.log("selectedValue", selectedValue);
    if (configFlags?.isCloudStoreSwipeDevicelist == 1) {
      selectedLabel = DeveiceResp.data.devices.filter((el: any, index: any) => {
        const storeNumber = String(Number(el.storeNumber));
        const terminalId = String(el.termNumber).slice(-3);
        console.log("store and device id", storeNumber, terminalId);
        return terminalId === selectedValue;
      });
    } else {
      selectedLabel =
        DeveiceResp.data.StorePaymentDeviceResponse.devices.filter(
          (el: any, index: any) => {
            const storeNumber = String(Number(el.storeNumber));
            const terminalId = String(el.termNumber).slice(-3);
            console.log("store and device id", storeNumber, terminalId);
            return terminalId === selectedValue;
          }
        );
    }
    let label =
      selectedLabel !== undefined && selectedLabel.length > 0
        ? selectedLabel[0].name
        : undefined;

        let registerNumber =
      selectedLabel !== undefined && selectedLabel.length > 0
        ? selectedLabel[0].registerNumber
        : undefined;
    setRegisterNumber(registerNumber);
    console.log("Label val", label);
    console.log("selectedLabel", selectedLabel);
    setSessionstorage(selectedLabel);
    setTerminalId(selectedValue);
    window.sessionStorage.setItem("TerminalId", String(selectedValue));
    window.sessionStorage.setItem("registerNumber", String(registerNumber));
    window.sessionStorage.setItem("deviceName", String(label));
  };

  const dynamicState = (e: any) => {
    selectedValue = e.value;
    console.log("State_SelecteVal", selectedValue);
    setdefaultVal(selectedValue);
    if (selectedValue !== "0") {
      setState_validation(false);
    }
    window.sessionStorage.setItem("billState", String(selectedValue));
  };

  const infoonclick = () => {
    swipepopupshow === false
      ? setswipepopupshow(true)
      : setswipepopupshow(false);
  };

  const deleteclick = () => {
    for (let i = 0; i < masteraddsuspensearray.length; i++) {
      const index = paygridvalues?.findIndex(
        (x: any) =>
          String(x.Agreementid) ==
          String(masteraddsuspensearray[i].agreementnumber)
      );
      paygridvalues[index].suspenseAdd = 0;
      paygridvalues[index].isCOATransfered = false;
      masteraddsuspensearray[i].newsuspense = "0.00";
    }

    for (let i = 0; i < masterusesuspensearray.length; i++) {
      const index = paygridvalues?.findIndex(
        (x: any) =>
          String(x.Agreementid) ==
          String(masterusesuspensearray[i].agreementnumber)
      );
      paygridvalues[index].suspenseSub = 0;
      paygridvalues[index].isCOATransfered = false;
      masterusesuspensearray[i].newsuspense = "0.00";
    }

    for (let i = 0; i < mastertranssuspensearray.length; i++) {
      const index = paygridvalues?.findIndex(
        (x: any) =>
          String(x.Agreementid) ==
          String(mastertranssuspensearray[i].agreementnumber)
      );
      console.log("check the filter trans", paygridvalues[index]);
      paygridvalues[index].isCOATransfered = false;
      paygridvalues[index].suspenseAdd = 0;
      paygridvalues[index].SuspenseAmount =
        paygridvalues[index].transferOldSuspense;
      mastertranssuspensearray[i].newsuspense = "0.00";
    }
    setpaygridvalues(paygridvalues);
    mastertranssuspensearray = [];
    totalsuspensemaster = [];
    masteraddsuspensearray = [];
    masteraddsuspensearray = [];
    mastertranssuspensearray = [];
    settotalsuspensemaster([]);
    setmasteraddsuspensearray([]);
    setmasterusesuspensearray([]);
    setmastertranssuspensearray([]);

    // for (let i = 0; i < totalsuspensemaster.length; i++) {
    //   if (masteraddsuspensearray[i].agreementid == deleteid) {
    //     // mastersuspensearray.splice(i, 1);
    //     masteraddsuspensearray[i].newsuspense="0.00"
    //     break;
    //   }
    // }
    //   if (masterusesuspensearray[i].agreementid == deleteid) {
    //     // mastersuspensearray.splice(i, 1);
    //     masterusesuspensearray[i].newsuspense="0.00"
    //     break;
    //   }
    // }

    console.log(totalsuspensemaster);
    console.log("deleted suspense");

    console.log("deleted suspense");
    suspenseitemsbind();
    BindGrid();

    let resAmount = 0.0;
    //  let resTaxamount=0.00
    for (let i = 0; i < checkedAgr.length; i++) {
      resAmount = Number(checkedAgr[i].Amount) + Number(resAmount);
      // resTaxamount=Number(checkedAgr[i].Taxamount)
    }
    //SuspenseTender-1
    debugger;
    const addSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount = totalsuspensemaster.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount.length == 0
        ? 0.0
        : addSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount.length == 0
        ? 0.0
        : useSuspenseAmount
            .map((bill) => bill.newsuspense)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));

    let resTotalAmount = 0.0;
    if (Cardrequire.PaymentOrgin == "Pay By Phone") {
      resAmount = Number(resAmount) + Number(convAmtWTax);
      // resTaxamount=Number(resTaxamount)+Number(convtax)
    }
    resTotalAmount =
      totalduetendertype.length == 0
        ? 0.0
        : totalduetendertype
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    if (Number(resTotalAmount) > Number(resAmount)) {
      resetPayment(undefined);
      setremainingamountdue(
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      );
      setchange("0.00");
      setAcceptpaymentbuttonenable(true);
    } else {
      if (
        Number(resTotalAmount) >=
        Number(
          (
            Number(resAmount) -
            Number(availableCreds) +
            Number(addSusTotalAmount) -
            Number(usedSusTotalAmount)
          ).toFixed(2)
        )
      ) {
        setchange(
          Number(resTotalAmount) -
            Number(
              (
                Number(resAmount) -
                Number(availableCreds) +
                Number(addSusTotalAmount) -
                Number(usedSusTotalAmount)
              ).toFixed(2)
            )
        );
        setremainingamountdue("0.00");
        setRemaniningAmtStatic("0.00");
        setAcceptpaymentbuttonenable(false);
      } else {
        setremainingamountdue(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setRemaniningAmtStatic(
          Number(
            (
              Number(resAmount) -
              Number(availableCreds) +
              Number(addSusTotalAmount) -
              Number(usedSusTotalAmount)
            ).toFixed(2)
          ) - Number(resTotalAmount)
        );
        setchange("0.00");
        setAcceptpaymentbuttonenable(true);
      }
    }

    settotaldue(resAmount);
    // settotaltax(Number(resTaxamount))

    setopenAdjustDueDate(false);
    setopenPromoFreeDays(false);
    seteditSchedulePopUp(false);
    setsuspenseAllDelete(false);
    setSuspenseDeleteExpection(false);
    multipleAdjust();
  };

  const step1Next = () => {
    setnextBtn(true);
    setswipepopupshow(false);
  };

  const Previous = () => {
    setnextBtn(false);
    setswipepopupshow(true);
  };

  const skip = () => {
    setswipepopupshow(false);
    setnextBtn(false);
  };

  const MoneyOrderPopup = () => {
    return (
      <Grid
        item
        id="MoneyOrderInformation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="carrywaive"
        aria-hidden="true"
      >
        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              id="TotalFees"
              inputlabel="Money Order#"
              name="Money Order"
              required={true}
              maxlength={20}
              value={adddata.checkno}
              OnChange={(e) => {
                setcheckno(e);
              }}
              data-testid="addmoneyorderno"
            />

            {checknovalid === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Money Order Number.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              id="Amount-collect"
              inputlabel="Issuer"
              required={true}
              maxlength={30}
              value={adddata.Written_by}
              OnChange={(e) => {
                setcheckwrittentby(e);
              }}
              data-testid="addmoneyorderwritten"
            />

            {writtenby === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Money Order issuer.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              type="text"
              isCurrency={false}
              inputlabel=" Purchaser"
              required={true}
              id="carrylate"
              value={adddata.Written_To}
              maxlength={30}
              OnChange={(e) => {
                setcheckwrittento(e);
              }}
              data-testid="addmoneyorderwrittento"
            />

            {Writtento === true ? (
              <label className={classes.mandatoryfield}>
                Please enter Money Order Purchaser.
              </label>
            ) : null}
          </Grid>

          <Grid item md={6} className={classes.mb2}>
            <label className={classes.labeltxtstyle}>
              Amount<span className={classes.mandatoryfield}></span>
            </label>
            <div className={`${classes.inputgroup} ${classes.mb3}`}>
              <span className={classes.inputgrouptext}>$</span>

              <CurrencyInput
                type="text"
                name="moneyorderAmount"
                className={classes.formcontrol3}
                value={adddata.Amount}
                onValueChange={(e) => {
                  setcheckamount(e);
                }}
                onBlur={(e) => {
                  setcheckamountblur(e);
                }}
                decimalsLimit={2}
                data-testid="addmoneyorderamount"
              />

              {Amount === true ? (
                <label className={classes.mandatoryfield}>
                  Please enter a positive Money Order Amount.
                </label>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.pb2}`}
        >
          <RACButton
            className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
            variant="outlined"
            color="primary"
            data-bs-dismiss="modal"
            onClick={() => {
              checkormoneyordercancel();
            }}
            data-testid="moneyordcanceltest"
          >
            Cancel
          </RACButton>

          {button === true ? (
            <RACButton
              className={`${classes.paymentSmallBtn1} ${classes.mx2}`}
              color="primary"
              variant="contained"
              onClick={(e) => upload(e)}
              value="2"
            >
              Update
            </RACButton>
          ) : (
            <RACButton
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              value="2"
              onClick={(e) => save(e)}
              data-testid="addmoneysave"
            >
              Save
            </RACButton>
          )}
        </Grid>
      </Grid>
    );
  };

  const swipeCardpopup = () => {
    if (DeveiceResp !== undefined && DeveiceResp.data !== "") {
      return (
        <Grid
          item
          data-testid="swipecardmodal"
          id="Swipecard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="transaction"
          aria-hidden="true"
        >
          <Grid container justifyContent={"center"}>
            <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
              <Typography
                variant="caption"
                className={`${classes.RAClabeltxtstyle} ${classes.semibold}`}
              >
                Remaining Amount Due:{" "}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.RACvaluetxtstyle} ${classes.bold}`}
              >
                $
                {fixedChange(
                  String(Number(remainingamountdue) - Number(swipeDeviceValue))
                )}
              </Typography>
            </Grid>
            <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
              <Grid
                item
                md={12}
                className={`${classes.formLabel} ${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
              >
                Computer Name
              </Grid>
              <Grid
                item
                md={12}
                className={`${classes.mb3} ${classes.w100} ${classes.floatLeft}`}
              >
                <RACSelect
                  options={DeviceNameArrfunc()}
                  defaultValue={TerminalId}
                  onChange={(e) => deviceDropdown(e)}
                />
                <Grid
                  className={`${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
                >
                  <Typography
                    className={`${classes.floatLeft}`}
                    variant="caption"
                  >
                    Please read the instruction to know the device name.
                  </Typography>
                  <Grid
                    className={`${classes.floatLeft} ${classes.racDeviceInfo}`}
                  >
                    <Info onClick={() => infoonclick()}></Info>
                    {swipepopupshow === true ? (
                      <Grid
                        id="swipepopup"
                        item
                        className={classes.racDeviceGuide}
                      >
                        <Card
                          className={`${classes.w100} ${classes.mb2} ${classes.card} ${classes.shadownone}`}
                        >
                          <CardContent>
                            <Typography
                              className={`${classes.semibold} ${classes.font14}`}
                            >
                              Step 1
                            </Typography>
                            <Typography className={classes.font14}>
                              Please go to &quot;My Details&quot; app in your
                              Desktop to know the system inforamtion.Refer the
                              screen shot given below.
                            </Typography>
                            <Grid className={classes.racDeviceImage}>
                              <Step1img></Step1img>
                            </Grid>
                            <Grid
                              className={`${classes.floatRight} ${classes.my3}`}
                            >
                              <RACButton
                                variant="outlined"
                                color="secondary"
                                className={classes.mx2}
                                onClick={() => skip()}
                              >
                                Skip
                              </RACButton>
                              <RACButton
                                variant="contained"
                                color="primary"
                                onClick={() => step1Next()}
                              >
                                Next
                              </RACButton>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null}
                    {nextBtn === true ? (
                      <Grid
                        id="swipepopup"
                        item
                        className={classes.racDeviceGuide}
                      >
                        <Card
                          className={`${classes.w100} ${classes.mb2} ${classes.card} ${classes.shadownone} `}
                        >
                          <CardContent>
                            <Typography
                              className={`${classes.semibold} ${classes.font14}`}
                            >
                              Step 2
                            </Typography>
                            <Typography className={classes.font14}>
                              You can find the computer name as highlighted in
                              red color.Refer the screenshot given below.
                            </Typography>
                            <Grid className={classes.racDeviceImage}>
                              <Step2img></Step2img>
                            </Grid>
                            <Grid
                              className={`${classes.floatRight} ${classes.my3}`}
                            >
                              <RACButton
                                variant="outlined"
                                color="secondary"
                                className={classes.mx2}
                                onClick={() => Previous()}
                              >
                                Previous
                              </RACButton>
                              <RACButton
                                variant="contained"
                                color="primary"
                                onClick={() => skip()}
                              >
                                Ok
                              </RACButton>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
              <Grid
                item
                md={12}
                className={`${classes.formLabel} ${classes.mt2}`}
              >
                Amount
              </Grid>
              <Grid item md={12} className={`${classes.mb3}`}>
                <RACTextbox
                  isCurrency={true}
                  className={`${classes.custDigit}`}
                  value={swipeDeviceValue}
                  name="total_amt"
                  id="dollor"
                  data-testid="amtTxtBoxId"
                  Onblur={(e: any) => Amtonblurclick(e)}
                  OnChange={(e: any) => totalamount(e.target.value)}
                  OnKeydown={(e: any) => {
                    if (e.key.toLowerCase() === "delete") {
                      totalamount("0.00");
                    }
                  }}
                  type={"number"}
                  digitFormat={"currency"}
                  maxlength={10}
                  dollarTextClassName={classes.currencyDollarField}
                />
                {Validator.SwipeAmtValidationtxtbox === true ? (
                  <label
                    className={classes.validationstyle}
                    style={{
                      visibility:
                        Validator.SwipeAmtValidationtxtbox === true
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    Amount should not be entered more than the remaining amount
                  </label>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} className={`${classes.p3} ${classes.textright}`}>
            <RACButton
              // type="button"
              className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
              variant="outlined"
              color="primary"
              style={{
                backgroundColor: "#fafcff",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#5a5a5a",
                borderColor: "#cee0ff  !important",
              }}
              onClick={() => close()}
              data-bs-toggle="modal"
              data-testid="SwipeCardClose"
            >
              Cancel
            </RACButton>

            <RACButton
              // type="button"
              className={`${classes.paymentSmallBtn1}`}
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#2179fe",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "white",
              }}
              onClick={(e) => Spinner(e)}
              data-bs-toggle="modal"
              disabled={
                Number(swipeDeviceValue) > 0 && String(TerminalId) != "null"
                  ? Cardrequire.Proceedbtn
                  : true
              }
              data-testid="SwipeCardClose"
            >
              Next
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const AddNewCardPopup = () => {
    if (showAddCard && !showChargeCard) {
      return (
        <Grid
          style={{ float: "left", width: "100%" }}
          id="newcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="NewCardTestId"
        >
          <Grid style={{ float: "left", width: "100%" }}>
            <Grid style={{ float: "left", width: "40%" }}>
              <h5 style={{ marginBottom: 20, marginTop: "20px" }}>
                Add Card Information
              </h5>
              <Grid style={{ float: "left", width: "100%" }}>
                <Grid id="ccframe">{callVantivIframe(1)}</Grid>
              </Grid>
            </Grid>

            <Grid style={{ float: "left", width: "60%" }}>
              <h5 style={{ marginBottom: 20 }}>Billing Address</h5>

              <Grid style={{ float: "left", width: "100%" }}>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    name="name"
                    id="Name"
                    inputlabel="Name"
                    required={true}
                    data-testid="NameId"
                    value={Popupfields.name}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Name_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Name
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line_1"
                    id="AddressLine1"
                    data-testid="AddressLine1Id"
                    value={Popupfields.address_line_1}
                    inputlabel="Address Line 1"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {Address_line1_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Address line1
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line2"
                    data-testid="AddressLine2Id"
                    id="AddressLine2"
                    inputlabel="Address Line 2"
                    value={Popupfields.address_line2}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type={"number"}
                    digitFormat={"normal"}
                    name="zip_code"
                    id="ZIP"
                    pattern="\d*"
                    maxlength={5}
                    value={Popupfields.zip_code}
                    data-testid="ZipCodeId"
                    inputlabel="ZIP Code"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Zip_code_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter ZIP code
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="City"
                    id="City"
                    required={true}
                    inputlabel="City"
                    value={Popupfields.city}
                    data-testid="StateId"
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {CityValidation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter City
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACSelect
                    // className={classes.formcontrol} Need to update node module
                    data-testid="dropmainId"
                    options={DD}
                    defaultValue={defaultVal}
                    inputLabel="State"
                    required={true}
                    name="state"
                    onChange={(e) => dynamicState(e.target)}
                  ></RACSelect>
                  {State_validation === true ? (
                    <Typography className={classes.mandatoryfield}>
                      Please select state
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ float: "left", width: "100%" }}>
            <div style={{ float: "right" }}>
              <RACButton
                className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                variant="outlined"
                color="primary"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: "white",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "#2179fe",
                }}
                onClick={() => AddCardClose()}
                data-bs-toggle="modal"
              >
                Cancel
              </RACButton>

              <RACButton
                className={`${classes.paymentSmallBtn1}`}
                color="primary"
                variant="contained"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: "#2179fe",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "white",
                }}
                onClick={() => vantivSubFunc()}
                data-bs-toggle="modal"
              >
                Save
              </RACButton>
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const chargeCardPopup = () => {
    if (showChargeCard && !showAddCard) {
      return (
        <Grid
          style={{ float: "left", width: "100%" }}
          id="newcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="NewCardTestId"
        >
          <Grid style={{ float: "left", width: "100%" }}>
            <Grid style={{ float: "left", width: "40%" }}>
              <h5 style={{ marginBottom: 20, marginTop: "20px" }}>
                Charge Card Information
              </h5>
              <Grid style={{ float: "left", width: "100%" }}>
                <Grid id="chargeframe">{callVantivIframe(2)}</Grid>
              </Grid>
            </Grid>

            <Grid style={{ float: "left", width: "60%" }}>
              <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
                <Grid
                  item
                  md={12}
                  className={`${classes.formLabel} ${classes.mt2}`}
                >
                  Amount
                </Grid>
                <Grid item md={12} className={`${classes.mb3}`}>
                  <RACTextbox
                    isCurrency={true}
                    className={`${classes.custDigit}`}
                    value={chargeCardAmt}
                    name="total_amt"
                    id="chargetxtbox"
                    data-testid="amtTxtBoxId"
                    Onblur={(e: any) => totalChargeAmount(e.target.value)}
                    OnChange={(e: any) => setchargeCardAmt(e.target.value)}
                    OnKeydown={(e: any) => {
                      if (e.key.toLowerCase() === "delete") {
                        setchargeCardAmt("0.00");
                      }
                    }}
                    type={"number"}
                    digitFormat={"currency"}
                    maxlength={10}
                    dollarTextClassName={classes.currencyDollarField}
                  />
                  {Validator.chargeCardValidation === true ? (
                    <label
                      className={classes.validationstyle}
                      style={{
                        visibility:
                          Validator.chargeCardValidation === true
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      Amount should not be entered more than the remaining
                      amount
                    </label>
                  ) : null}
                </Grid>
              </Grid>
              <h5 style={{ marginBottom: 20 }}>Billing Address</h5>
              <Grid style={{ float: "left", width: "100%" }}>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    name="name"
                    id="Name"
                    inputlabel="Name"
                    required={true}
                    data-testid="NameId"
                    value={Popupfields.name}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Name_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Name
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line_1"
                    id="AddressLine1"
                    data-testid="AddressLine1Id"
                    value={Popupfields.address_line_1}
                    inputlabel="Address Line 1"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {Address_line1_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter Address line1
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="address_line2"
                    data-testid="AddressLine2Id"
                    id="AddressLine2"
                    inputlabel="Address Line 2"
                    value={Popupfields.address_line2}
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    isCurrency={false}
                    type={"number"}
                    digitFormat={"normal"}
                    name="zip_code"
                    id="ZIP"
                    pattern="\d*"
                    maxlength={5}
                    value={Popupfields.zip_code}
                    data-testid="ZipCodeId"
                    inputlabel="ZIP Code"
                    required={true}
                    OnChange={(e) => initialnewcardstate(e.target)}
                  />
                  {Zip_code_validation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter ZIP code
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACTextbox
                    type="text"
                    name="City"
                    id="City"
                    required={true}
                    inputlabel="City"
                    value={Popupfields.city}
                    data-testid="StateId"
                    OnChange={(e) => initialnewcardstate(e.target)}
                    isCurrency={false}
                  />
                  {CityValidation === true ? (
                    <Typography
                      className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                    >
                      Please enter City
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  style={{
                    float: "left",
                    width: "48%",
                    marginBottom: 20,
                    padding: "1%",
                  }}
                >
                  <RACSelect
                    // className={classes.formcontrol} Need to update node module
                    data-testid="dropmainId"
                    options={DD}
                    defaultValue={defaultVal}
                    inputLabel="State"
                    required={true}
                    name="state"
                    onChange={(e) => dynamicState(e.target)}
                  ></RACSelect>
                  {State_validation === true ? (
                    <Typography className={classes.mandatoryfield}>
                      Please select state
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ float: "left", width: "100%" }}>
            <div style={{ float: "right" }}>
              <RACButton
                className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                variant="outlined"
                color="primary"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: "white",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "#2179fe",
                }}
                onClick={() => ChargeCardClose()}
                data-bs-toggle="modal"
              >
                Cancel
              </RACButton>

              <RACButton
                className={`${classes.paymentSmallBtn1}`}
                color="primary"
                variant="contained"
                name="De_ActivateClubbtn"
                disabled={chargeCardContinue}
                style={{
                  backgroundColor: "#2179fe",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "white",
                }}
                onClick={() => vantivSubFunc()}
                data-bs-toggle="modal"
              >
                Continue
              </RACButton>
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  };

  const editCardPopup = () => {
    if (getCardService_state.length !== 0 && editcardindex !== undefined) {
      return (
        <Grid
          item
          id="editcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="EditCardPopupId"
        >
          <Grid container spacing={2}>
            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                id="Name0"
                name="name"
                required={true}
                value={EditCard.Cardname}
                isCurrency={false}
                inputlabel="Name"
                OnChange={(e: any) => editcardOnchange(e.target)}
              />
              {nameVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter Name
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                id="AddressLine10"
                name="address_line_1"
                OnChange={(e) => editcardOnchange(e.target)}
                value={EditCard.address_1}
                isCurrency={false}
                required={true}
                inputlabel="Address Line 1"
              />
              {addressval1 === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter Address line1
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                isCurrency={false}
                OnChange={(e) => editcardOnchange(e.target)}
                inputlabel="Address Line 2"
                name="address_line2"
                id="AddressLine20"
                value={EditCard.address_2}
              />
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                pattern="\d*"
                maxlength={5}
                OnChange={(e) => editcardOnchange(e.target)}
                name="zip_code"
                id="ZIP77"
                required={true}
                value={EditCard.zip}
                inputlabel="ZIP Code"
                isCurrency={false}
              />
              {ZipVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter ZIP code
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACTextbox
                type="text"
                name="city"
                id="city"
                required={true}
                isCurrency={false}
                OnChange={(e) => editcardOnchange(e.target)}
                inputlabel="City"
                value={EditCard.city}
              />
              {cityVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please enter City
                </Typography>
              ) : null}
            </Grid>

            <Grid item md={6} className={`${classes.mb3} ${classes.px2}`}>
              <RACSelect
                options={DD}
                defaultValue={EditCard.state}
                inputLabel="State"
                name="State"
                required={true}
                onChange={(e) => stateOnchange(e.target)}
              ></RACSelect>
              {stateVal === true ? (
                <Typography
                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                >
                  Please select State
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Grid item md={12} className={`${classes.w100} ${classes.mt4}`}>
            <RACButton
              data-bs-dismiss="modal"
              onClick={() => del()}
              style={{
                backgroundColor: "#FFD1D1",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#B50000",
              }}
              data-testid="DeleteBtnId"
            >
              Delete Card
            </RACButton>

            <Grid item className={classes.floatRight}>
              <RACButton
                style={{
                  backgroundColor: "#fafcff",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: " #5a5a5a",
                }}
                data-bs-dismiss="modal"
                onClick={() => close()}
                data-testid="EditsCrdCancelbtnIn"
              >
                Cancel
              </RACButton>

              <RACButton
                style={{
                  backgroundColor: "#2179fe",
                  textTransform: "none",
                  fontWeight: "bolder",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  color: "#FFFFFF",
                }}
                onClick={() => EditCardfunc()}
                data-testid="EditsCrdSavebtnIn"
              >
                {" "}
                Save
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  const DeletePopupForEnrolledCard = () => {
    if (getCardService_state != undefined) {
      return (
        <Grid
          item
          id="editcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="EditCardPopupId"
        >
          <Typography
            className={`${classes.textcenter} ${classes.mt3} ${classes.mb1}`}
          >
            The card you are about to delete is currently set up for Autopay by
            the customer.{" "}
          </Typography>
          <Typography className={`${classes.textcenter} ${classes.mb1}`}>
            You will need to set up another card to keep the customer on
            Autopay.
          </Typography>
          <Typography className={`${classes.textcenter} ${classes.mb1}`}>
            {" "}
            Are you sure want to continue ?
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => close()}
              data-testid="EditsCrdCancelbtnIn"
            >
              No
            </RACButton>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              onClick={() => Enroll_AP_Card()}
              data-testid="EditsCrdSavebtnIn"
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const DeletePopup = () => {
    return (
      <Grid
        item
        id="delete-auto"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-testid="DeleteCardPopupId"
        tabIndex={-1}
        aria-labelledby="delete-auto"
        aria-modal="true"
        role="dialog"
      >
        <Grid container className={classes.textcenter}>
          <Grid item md={12} className={classes.mb3}>
            <DeleteIcon></DeleteIcon>
          </Grid>
          <Grid item md={12}>
            <Typography
              variant="h6"
              className={`${classes.customsure} ${classes.semibold}`}
            >
              Delete Card
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.bgpopdelete} ${classes.mt4} ${classes.pb3}`}
          >
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.textcenter} ${classes.mt3} ${classes.mb1}`}
            >
              The card you selected ending with
              <Typography
                variant="caption"
                className={`${classes.blackcolortext} ${classes.ps2} ${classes.font14} ${classes.semibold}`}
              >
                {editcardnumberbind}
              </Typography>{" "}
              will be{" "}
            </Typography>
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.textcenter} ${classes.mb1}`}
            >
              removed from the customer&apos;s card on the file list.
            </Typography>
            <Typography
              className={`${classes.font14} ${classes.semibold} ${classes.graycolortext}`}
            >
              {" "}
              Are you sure you want to continue?
            </Typography>
            <Grid
              item
              md={12}
              className={`${classes.w100} ${classes.mt3} ${classes.textcenter}`}
            >
              <RACButton
                className={classes.mx1}
                variant="outlined"
                color="primary"
                onClick={() => close()}
                data-bs-dismiss="modal"
                data-testid="NoKeepItBtnId"
                data-bs-toggle="modal"
                data-bs-target="#delivery-receipt"
              >
                {" "}
                No,Keep It
              </RACButton>
              <RACButton
                onClick={() => YesforDelete()}
                className={`${classes.btnkeepdelete} ${classes.mx2} ${classes.px3}`}
                data-testid="yesDeleteCardId"
              >
                Yes,Delete Card
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const TransactionCompletePopup = () => {
    return (
      <Grid
        item
        id="transaction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        data-testid="TranId"
      >
        <Grid container className={classes.textcenter}>
          <Grid item md={12}>
            <SuccessIcon></SuccessIcon>
          </Grid>
          <Typography
            className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Payment processed successfully, click "Accept Payment" to complete transaction.
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => close()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const Txt2paypopup = () => {
    if (getpayment !== undefined || getpayment !== Error) {
      return (
        <Grid
          item
          id="ph-text-autopay"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="ph-text-autopay"
          aria-modal="true"
          data-testid="Txt2payPopup"
        >
          <Grid container className={classes.textcenter}>
            <Grid item md={12} className={classes.mb3}>
              <Txt2payimg></Txt2payimg>
            </Grid>
            <Grid item md={12} className={classes.mb4}>
              <Typography variant="h5" className={classes.customtextpay}>
                Phone to Text for Payment
              </Typography>
            </Grid>

            <Grid
              md={12}
              className={`${classes.bgpopdelete} ${classes.floatLeft}`}
            >
              <Grid container md={12} className={classes.justifycontentcenter}>
                <Grid item md={6} className={classes.floatLeft}>
                  <Typography
                    variant="caption"
                    className={`${classes.RAClabeltxtstyle} ${classes.w100} ${classes.pt4} ${classes.floatLeft}`}
                  >
                    Phone Number
                  </Typography>
                  <Typography
                    className={`${classes.w100} ${classes.pb4} ${classes.semibold}`}
                  >
                    {formatPhone(
                      getpayment?.paymentInfo.customerInfo == undefined
                        ? ""
                        : getpayment?.paymentInfo.customerInfo[0].phoneNumber
                    )}
                  </Typography>
                </Grid>
                <Grid item md={6} className={classes.floatLeft}>
                  <Typography
                    variant="caption"
                    className={`${classes.RAClabeltxtstyle} ${classes.semibold} ${classes.w100} ${classes.pt4} ${classes.floatLeft}`}
                  >
                    Customer Name
                  </Typography>
                  <Typography
                    className={`${classes.w100} ${classes.pb4} ${classes.semibold}`}
                  >
                    {customerName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                md={12}
                className={`${classes.pb2} ${classes.modalfooter}`}
              >
                <RACButton
                  className={classes.mx1}
                  variant="outlined"
                  color="primary"
                  data-bs-dismiss="modal"
                  onClick={() => close()}
                  data-testid="txt2payCancel"
                >
                  Cancel
                </RACButton>
                <RACButton
                  className={classes.mx1}
                  color="primary"
                  variant="contained"
                  data-bs-dismiss="modal"
                  onClick={() => yesforTxt2pay()}
                  data-testid="Yestxt2payId"
                >
                  Yes
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  const InphonePaymentOrigin = () => {
    return (
      <Grid
        item
        id="phonepay"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
        data-testid="InphonePopupid"
      >
        <Box
          component="span"
          className={`${classes.textleft} ${classes.mb1} ${classes.px3} ${classes.floatLeft}`}
        >
          A convenience fee of{" "}
          <Typography
            variant="caption"
            className={`${classes.RAClabeltxtstyle} ${classes.bold}`}
          >
            ${Number(ConvFeeWithOutTax).toFixed(2)} plus Tax{" "}
          </Typography>
          when applicable
          <Box
            component="span"
            className={`${classes.ps1} ${classes.textleft}`}
          >
            will be added.
          </Box>
        </Box>
        <List className={`${classes.mt3} ${classes.mx3} ${classes.floatLeft}`}>
          <ListItem className={classes.listgroupitem}>
            <Grid className={classes.raccolauto}>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                Current Total:{" "}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                ${fixedChange(totaldue)}
              </Typography>
            </Grid>
          </ListItem>
          <ListItem className={classes.listgroupitem}>
            <Grid className={classes.raccolauto}>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                New Total:{" "}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.bold}`}
              >
                ${fixedChange(Cardrequire.Newtotal)}
              </Typography>
            </Grid>
          </ListItem>
        </List>
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.mt3} ${classes.floatRight}`}
        >
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
            variant="outlined"
            color="primary"
            data-bs-dismiss="modal"
            data-testid="NobtnId"
            onClick={() => closephonepopup()}
          >
            No
          </RACButton>
          <RACButton
            // type="button"
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            onClick={() => NewtotalAmt()}
            data-bs-toggle="modal"
            data-testid="YesBtn"
            data-bs-target="#autopayconsent"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const TerminateLDWpopup = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          id="terminateldw"
          data-bs-backdrop="static"
          data-testid="TerminateLDWTestId"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            By deactivating LDW, your regular payment will decrease by
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.bold} ${classes.ms1} ${classes.font16}`}
            >
              $
              {adjustTerminateLdwAmount != "0"
                ? adjustTerminateLdwAmount
                : customerCoverageObject.ldwAmount}
            </Typography>
            . If you have club it will be cancelled and decreased by that
            payment amount also.
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.modalfooter} ${classes.mt3} ${classes.textright}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="TerminateCancelbtn"
              data-bs-dismiss="modal"
              onClick={() => close()}
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="ActiveLDWContinueId"
              onClick={() => ContinueTerminateLDW()}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const ActiveLDWpopup = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          id="terminateldw"
          data-testid="ActivateLdwPopupId"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            By activating LDW, your regular payment with LDW will increase by
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${customerCoverageObject.ldwAmount}
            </Typography>
            . Do you want to activate LDW?
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
          >
            <RACButton
              // type="button"
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="ActivateLDWCancelId"
              onClick={() => close()}
              data-bs-dismiss="modal"
            >
              Cancel
            </RACButton>
            <RACButton
              // type="button"
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="continueActivateLDWId"
              onClick={() => continueActivateLDW()}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const ActivepolicypopupModal = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          id="terminateldw"
          data-testid="ActivatePolicyPopId"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            Your regular payment with policy will increase by
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${customerCoverageObject.policyAmount}
            </Typography>
            . Do you want to active Policy?
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.modalfooter} ${classes.mt4} ${classes.textright}`}
          >
            <RACButton
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="ActivatePolicyCancelId"
              data-bs-dismiss="modal"
              style={{
                backgroundColor: "white",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "#2179fe",
              }}
              onClick={() => close()}
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="ContinueActivatePolicyId"
              style={{
                backgroundColor: "#2179fe",
                textTransform: "none",
                fontWeight: "bolder",
                paddingLeft: "14px",
                paddingRight: "14px",
                color: "white",
              }}
              onClick={() => ContinueActivatePolicy()}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const DeActivepolicypopupModal = () => {
    if (customerCoverageObject !== undefined) {
      return (
        <Grid
          item
          data-testid="TerminatePolicyPopupId"
          id="terminateldw"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.px3}>
            Your regular payment with policy will decrease by
            <Typography
              variant="caption"
              className={`${classes.labeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${customerCoverageObject.policyAmount}
            </Typography>
            . Do you want to DeActive Policy?
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
          >
            <RACButton
              // type="button"
              className={classes.mx1}
              variant="contained"
              color="primary"
              data-testid="ContinueDeActivatePolicyID"
              onClick={() => ContinueDeActivatePolicy()}
            >
              Continue
            </RACButton>
            <RACButton
              // type="button"
              className={classes.mx1}
              color="primary"
              variant="outlined"
              data-testid="DeActivateCancelId"
              data-bs-dismiss="modal"
              onClick={() => close()}
            >
              {" "}
              Cancel
            </RACButton>
          </Grid>
        </Grid>
      );
    }
  };

  const autoPopUpHeadFields = [
    { display: "" },
    { display: "Description" },
    { display: "Amount" },
    { display: "Schedule" },
    { display: "Due Date" },
    { display: "" },
  ];

  const renderBindGridHeader = () => (
    <>
      {autoPopUpHeadFields.map((val, index) => {
        return (
          <RACTableCell className={classes.racTable} key={index}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );
  const Addedloaderfunandgrid = () => {
    if (mainGridloader === false) {
      if (bindGridCheck === false) {
        return BindGrid();
      } else {
        return (
          <RACTableRow className={classes.hiddenRow} key="0">
            <RACTableCell colSpan={9} className={classes.textcenter}>
              No Records Found
            </RACTableCell>
          </RACTableRow>
        );
      }
    } else {
      return (
        <RACTableCell colSpan={9} className={classes.textcenter}></RACTableCell>
      );
    }
  };

  const Payclasses = PayHistory();
  const suspensebindgrid = () => {
    console.log(masteraddsuspensearray, "grid map");
    return masteraddsuspensearray.map((value, index: any) => {
      console.log(value);

      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.suspenseamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? ( */}
            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense}
              data-testid="suspensetextbox"
              name={"suspenseTxtbox" + index}
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                Number(value.newsuspense) >
                Number(value.epoamount) - Number(value.suspenseamount)
                  ? "Amount entered can not be greater than EPO"
                  : "" ||
                    Number(Number(value.newsuspense).toFixed(2)) ==
                      Number(
                        (
                          Number(value.epoamount) - Number(value.suspenseamount)
                        ).toFixed(2)
                      )
                  ? "Amount entered can not be equal to EPO. Please use EPO functionality to process the payment"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Add",
                  index,
                  Number(value.newsuspense) >
                    Number(
                      (
                        Number(value.epoamount) - Number(value.suspenseamount)
                      ).toFixed(2)
                    )
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Add",
                  index,
                  Number(value.newsuspense) >
                    Number(
                      (
                        Number(value.epoamount) - Number(value.suspenseamount)
                      ).toFixed(2)
                    )
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}

            {/* {UsesusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const suspenseusebindgrid = () => {
    console.log(masterusesuspensearray, "grid use map");
    return masterusesuspensearray.map((value, index: any) => {
      console.log(value);

      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.suspenseamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null }
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null} */}

            {/* {UsesusText ? ( */}

            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense}
              data-testid="suspensetextbox"
              name={"suspenseTxtbox" + index}
              digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                Number(value.newsuspense) > Number(value.suspenseamount)
                  ? "Amount greater than available suspense"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Used",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Used",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const suspensetransbindgrid = () => {
    console.log(mastertranssuspensearray, "grid use map");
    return mastertranssuspensearray.map((value, index: any) => {
      console.log(value);

      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.suspenseamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null }
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null} */}

            {/* {UsesusText ? ( */}

            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense}
              disabled={
                mastertranssuspensearray.length === 1 &&
                Number(mastertranssuspensearray[0].suspenseamount) > 0
                  ? true
                  : false
              }
              data-testid="suspensetextbox"
              // default={"0.00"}
              // type={"number"}
              name={"suspenseTxtbox" + index}
              // digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                Number(value.newsuspense) > Number(value.epoamount)
                  ? "Amount greater than epo"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Trans",
                  index,
                  Number(value.newsuspense) > Number(value.epoamount)
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Trans",
                  index,
                  Number(value.newsuspense) >= Number(value.epoamount)
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const confirmTransferSuspenseBind = () => {
    return (
      <Grid item id="transatraction" aria-hidden="true">
        <Grid item md={12} className={classes.textcenter}>
          <Typography
            className={`${classes.bold} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            You are attempting to move ${newCOATotal} from suspense to
            customer's credit on account. Would you like to continue ?
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="secondary"
            variant="outlined"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => {
              setOpenTransferSuspense(false);
              setNewCOATotal("0.00");
              checkBoxController(0.0, "TransferUsed");
            }}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => acceptSuspenseTransfer()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // SQ-4 This method will be rendering the confirmation popup before moving the suspense to COA

  const suspenserefundbindgrid = () => {
    console.log(masterrefundsuspensearray, "grid use map");
    return masterrefundsuspensearray.map((value, index: any) => {
      console.log(value);

      return (
        <RACTableRow key={index} className={Payclasses.payHistbgTransparent}>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.agreementid}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption"> {value.schedure} </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">{value.paymentamount}</Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption">
              {" "}
              {value.epoamount.toFixed(2)}{" "}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            <Typography variant="caption" color="primary">
              {value.suspenseamount}
            </Typography>
          </RACTableCell>
          <RACTableCell>
            {/* <CurrencyInput
                type="text"
                name="checkAmount"
                className={classes.formcontrol3}
                value={addsus}
                decimalsLimit={2}
                data-testid="amountbox"
              /> */}

            {/* {AddsusText ? (
              <RACTextbox
                isCurrency={true}
                className={`${classes.custDigit} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={value.newsuspense || "0.00"}
                data-testid="suspensetextbox"
                type={"number"}
                name={'suspenseTxtbox'+index}
                digitFormat={"currency"}
                maxlength={10}
                OnKeydown={(e: any) => {
                  if (e.key.toLowerCase() === "delete") {
                    cashamounttextbox("0.00");
                  }
                }}

                OnChange={(e: any) => {
                  masterSusOnchange(
                    e,
                    "Use",
                    index
                  );
                }}

                Onblur={(e: any) => {
                  masterSusOnBlur(
                    e,
                    "Use",
                    index
                  );
                }}
              />
              ) :null }
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null} */}

            {/* {UsesusText ? ( */}

            <RACTextbox
              isCurrency={true}
              className={`${classes.custDigit} ${classes.cashtxtbox}`}
              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
              value={value.newsuspense || "0.00"}
              data-testid="suspensetextbox"
              // default={"0.00"}
              // type={"number"}
              name={"suspenseTxtbox" + index}
              // digitFormat={"currency"}
              maxlength={10}
              errorMessage={
                value.newsuspense > value.suspenseamount
                  ? "Amount greater than available suspense"
                  : ""
              }
              OnChange={(e: any) => {
                masterSusOnchange(
                  e,
                  "Refund",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
              Onblur={(e: any) => {
                masterSusOnBlur(
                  e,
                  "Refund",
                  index,
                  value.newsuspense > value.suspenseamount
                );
              }}
            />
            {/* ) :null } */}
            {Amount === true ? (
              <label className={classes.mandatoryfield}>
                Amount must be greater than 0.
              </label>
            ) : null}
          </RACTableCell>
        </RACTableRow>
      );
    });
  };

  const BindGridCheckFunc = () => (
    <>
      {Addedloaderfunandgrid()}
      {Bindgridcheckbox()}
    </>
  );

  if (redirectToAccountManagement) {
    return (
      <Redirect
        to={{
          pathname: AppRoute.AccountManagement.replace(
            ":customerId",
            location?.state?.accountManagementCustomerId || ""
          ),
          state: {
            ...(location?.state || {}),
            redirectSource: "payment",
          },
        }}
      />
    );
  }
  return nonInventoryPopUp == false ? (
    <Grid container data-testid="paymentfullscreen">
      <Wizard customerData={getpayment} />
      {mainGridloader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {/* unenroll autopay popup starts here */}
      <RACModalCard
        isOpen={unenrollautopayopen}
        maxWidth="sm"
        onClose={unenrollAutoPayCloseClick}
        closeIcon={true}
        /* eslint-disable react/no-children-prop */
        children={unEnrollAutoPay()}
      />
      <RACModalCard
        isOpen={opentransferSuspense}
        maxWidth="xs"
        onClose={() => setOpenTransferSuspense(false)}
        closeIcon={false}
        borderRadius={"25px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={confirmTransferSuspenseBind()}
      />
      {/* unenroll autopay popup starts here */}
      <RACModalCard
        isOpen={phoneNumberRequired}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={phoneNumberRequiredPopUp()}
      />
      <RACModalCard
        isOpen={customerzipcodenull}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={zipCodeRequiredPopUp()}
      />
      <RACModalCard
        isOpen={suspenseAllDelete}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={suspenseOverAllDelete()}
      />

      <RACModalCard
        isOpen={suspenseDeleteExpection}
        maxWidth="sm"
        /* eslint-disable react/no-children-prop */
        children={finalsuspenseOverallDelete()}
      />

      {/* uneroll autopay popup ends here */}
      <RACModalCard
        isOpen={autopaypopup}
        maxWidth="xs"
        onClose={AutoPayCloseClick}
        closeIcon={true}
        /* eslint-disable react/no-children-prop */
        children={autoPayPopUp()}
      />
      <RACModalCard
        isOpen={saveCardPopUp}
        maxWidth="xs"
        onClose={() => setsaveCardPopUp(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopupSaveCard()}
      />
      <RACModalCard
        isOpen={saveCardSuccess || saveCardFail}
        maxWidth="xs"
        onClose={() => savedCardOk()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={cardSuccessfullySaved()}
      />
       <RACModalCard
        isOpen={deleteCardPopUp}
        maxWidth="xs"
        onClose={() => setdeleteCardPopUp(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopupDeleteCard()}
      />
      <RACModalCard
        isOpen={deleteCardSuccess || deleteCardFail}
        maxWidth="xs"
        onClose={() => deleteCardOk()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={cardSuccessfullyDeleted()}
      />


      {/* <Grid item md={12}>
        <List className={`${classes.breadcrumb} ${classes.py1}`}>
          <ListItem className={classes.breadcrumbItem}>
            <a
              className={classes.breadcrumbInActive}
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`/dashboard`)}
            >
              Dashboard
            </a>
          </ListItem>
          <ListItem
            className={`${classes.breadcrumbItem1} ${classes.breadcrumbArrow} ${classes.breadcrumbInActive}`}
          >
            Payment
          </ListItem>
          <ListItem
            className={`${classes.breadcrumbItemActive} ${classes.breadcrumbArrow}`}
          >
            {currentServiceFlags.isCustomerRedirection == 1 ? (
              <a
                style={{ cursor: "pointer" }}
                onClick={() => redirectionToCustomer()}
              >
                {getpayment !== undefined &&
                getpayment.paymentInfo.customerInfo.length > 0
                  ? getpayment?.paymentInfo.customerInfo[0].firstName +
                    " " +
                    getpayment?.paymentInfo.customerInfo[0].lastName
                  : ""}
              </a>
            ) : getpayment !== undefined &&
              getpayment.paymentInfo.customerInfo.length > 0 ? (
              getpayment?.paymentInfo.customerInfo[0].firstName +
              " " +
              getpayment?.paymentInfo.customerInfo[0].lastName
            ) : (
              ""
            )}
          </ListItem>
        </List>
      </Grid> */}
      {/* <Card
        className={`${classes.card} ${classes.w100} ${classes.floatLeft}  ${classes.alertwidget}`}
        id="accordionExample"
      >
        <Grid item className={classes.px0}>
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft}`}
            id="headingOne"
          >
            <Grid container className={classes.px2}>
              <Grid
                className={`${classes.raccollg5} ${classes.paymentAlignCenter} ${classes.floatLeft} ${classes.borderrightstyle}`}
              >
                <Grid
                  item
                  className={`${classes.raccollg6}  ${classes.floatLeft}`}
                >
                  <Typography
                    component="span"
                    className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
                  >
                    Customer Payment
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={`${classes.raccollg3} ${classes.floatLeft}`}
                >
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
                  >
                    Customer Name
                  </Box>
                  {currentServiceFlags.isCustomerRedirection == 1 ? (
                    <a
                      className={`${classes.labelbluetxtstyle} ${classes.w100} ${classes.floatLeft}`}
                      onClick={() => redirectionToCustomer()}
                    >
                      {getpayment !== undefined &&
                      getpayment.paymentInfo.customerInfo.length > 0
                        ? getpayment?.paymentInfo.customerInfo[0].firstName +
                          " " +
                          getpayment?.paymentInfo.customerInfo[0].lastName
                        : ""}
                    </a>
                  ) : getpayment !== undefined &&
                    getpayment.paymentInfo.customerInfo.length > 0 ? (
                    getpayment?.paymentInfo.customerInfo[0].firstName +
                    " " +
                    getpayment?.paymentInfo.customerInfo[0].lastName
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid
                  className={`${classes.floatLeft} ${classes.raccollg3} ${classes.px1}`}
                >
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
                  >
                    Customer ID
                  </Box>
                  <Box
                    component="span"
                    className={`${classes.labelnotxtstyle} ${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
                  >
                    {customerId}
                  </Box>
                </Grid>
              </Grid>
              {configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                <Grid
                  item
                  className={isPendingAgrTransfer ? `${classes.raccollg7} ${classes.paymentAlignCenter} ${classes.floatLeft} ${classes.disablePage}` : `${classes.raccollg7} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
                >
                  <Grid
                    item
                    className={`${classes.raccollg7} ${classes.floatLeft} ${classes.ps2}`}
                  >
                    <Grid item className={`${classes.w100} ${classes.mt2}`}>
                      {activeAlert !== undefined &&
                      activeAlert !== null &&
                      activeAlert.length > 0 ? (
                        <React.Fragment>
                          <Typography
                            variant="h4"
                            className={`${classes.font14} ${classes.semibold} ${classes.floatLeft} ${classes.me1}`}
                          >
                            Customer Alerts
                          </Typography>
                          <Typography
                            variant="h4"
                            className={`${classes.font14} ${classes.semibold} ${classes.floatLeft}  ${classes.me4} ${classes.alertcolor}`}
                          >
                            ({activeAlert.length})
                          </Typography>
                        </React.Fragment>
                      ) : (
                        <Typography
                          variant="h4"
                          className={`${classes.semibold} ${classes.font16} ${classes.floatLeft} ${classes.me4}`}
                        >
                          Customer Alerts
                        </Typography>
                      )}
                      <Grid item className={classes.alertwidgartcontainer}>
                        {AlertMessages()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    className={`${classes.raccollg5} ${classes.floatLeft} ${classes.ps2}`}
                  >
                    <Grid container>
                      <Grid item className={classes.raccollg4}>
                        {isAlertLoading ? (
                          <Grid
                            item
                            className={`${classes.textright} ${classes.me2} ${classes.mt2}`}
                          >
                            <CircularProgress className={classes.loaderIcon} />
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid item className={classes.raccollg6}>
                        <RACButton
                          //type="button"
                          variant="contained"
                          color="primary"
                          isRounded={false}
                          data-testid="assignAlert"
                          onClick={openAlert}
                        >
                          Assign Alert
                        </RACButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <RACModalCard
                    isOpen={AlertIsOpen}
                    maxWidth="xs"
                    title="Customer Alerts"
                    onClose={() => {
                      Cancelclick();
                      closeAlert();
                    }}
                    closeIcon={true}
                    // eslint-disable-next-line react/no-children-prop
                    children={alertPopUp()}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Card> */}

      {/*triagle Agreement starts here*/}
      {/* {configFlags.isPaymentTraingleFeatureFlag == 1 &&
      configFlags.isPaymentFunctionsFeatureFlag == 1 && !isPendingAgrTransfer? (
        <CustomerInformation
        />
      ) : null} */}

      {/*triagle Agreement starts here*/}

      <Card
        className={`${classes.card} ${classes.mt3} ${classes.w100} ${classes.floatLeft} ${classes.mb3} ${classes.alertwidget}`}
        id="accordionExample"
      >
        {suspErrLbl &&
        diffStoreErrLbl == false &&
        deliveryUnconfiremd == false ? (
          <RACAlert
            AlertMessage={
              " Selected agreement has suspense amount in excess of EPO, please use SIMS for PIF/EPO payment"
            }
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : // />
        null}
        {epoErrLbl &&
        deliveryUnconfiremd == false &&
        suspErrLbl == false &&
        diffStoreErrLbl == false ? (
          <RACAlert
            AlertMessage={
              "Selected agreement epo amount less than the amount due, please use SIMS for PIF/EPO payment"
            }
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : null}
        {diffStoreErrLbl ? (
          <RACAlert
            AlertMessage={
              " Selected agreement for a different store. Please take a payment on the agreement in your store only"
            }
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : // </Typography>
        null}
        {deliveryUnconfiremd == false &&
        unverifiedCustomer &&
        suspErrLbl == false &&
        epoErrLbl == false &&
        diffStoreErrLbl == false ? (
          <RACAlert
            AlertMessage={" Please verify customer information"}
            variant="outlined"
            severity="warning"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : null}

        {deliveryUnconfiremd && diffStoreErrLbl == false ? (
          <RACAlert
            AlertMessage={`Selected Agreement# ${String(
              agreementDeliveryUncomfirmed
            )} delivery is not confirmed. Please confirm delivery to continue with payment`}
            variant="outlined"
            severity="error"
            // className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
          />
        ) : null}
        {/* {isCommitmentInSIMS ? (
          <RACAlert
            AlertMessage={
              "Commitment is taken in SIMS. Please continue the commitment payment in SIMS"
            }
            variant="outlined"
            severity="error"
          />
        ) : null} */}
      </Card>

      <Grid
        item
        className={`${classes.raccollg9} ${classes.floatLeft} ${classes.px2}`}
      >
        {/*Customer Agreement starts here*/}

        <Card className={`${classes.hidden}`}>
          <CardContent className={classes.p0}>
            <Grid
              container
              className={isPendingAgrTransfer ? `${classes.disablePage}` : ""}
            >
              <RACTable
                className={classes.racGrid}
                renderTableHead={renderBindGridHead}
                //renderTableContent={BindGrid}
                renderTableContent={BindGridCheckFunc}
              />
            </Grid>
            <Grid item md={12} className={classes.textcenter}>
              <Grid className={`${classes.w100}`}>
                {/* <Grid item>
                  <Grid item className={`${classes.btngroup} `} role="group">
                    <OutsideClickHandler onOutsideClick={suspenseOnblur}>
                      <List
                        style={{
                          left: "250px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                        className={suspensePopupClass}
                        data-testid="UlButtonsId"
                        aria-labelledby="btnGroupDrop1"
                        onBlur={() => suspenseOnblur()}
                      >
                        <ListItem disabled={addsuslist}>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="addsusId"
                            onClick={() => {
                              addsuslist ? null : addSuspensePopup();
                            }}
                            data-bs-target="#addsus"
                          >
                            Add
                          </a>
                        </ListItem>
                        <ListItem disabled={addsuslist}>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="addsusId"
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            onClick={() => {
                              addsuslist ? null : useSuspensePopup();
                            }}
                            data-bs-target="#addsus"
                          >
                            Use
                          </a>
                        </ListItem>
                        <ListItem disabled={addsuslist}>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="addsusId"
                            data-bs-target="#addsus"
                            onClick={() => {
                              addsuslist ? null : transferSuspensePopup();
                            }}
                          >
                            Transfer
                          </a>
                        </ListItem>
                      </List>
                    </OutsideClickHandler>
                    <RACModalCard
                      isOpen={addsuspense}
                      // isOpen={true}
                      maxWidth="md"
                      title="Add Suspense"
                      // onClose={() => addsuspensecancel()}
                      onClose={() => setaddsuspense(false)}
                      closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={addsuspensepopup()}
                    />

                    <RACModalCard
                      isOpen={usesuspense}
                      // isOpen={true}

                      maxWidth="md"
                      title="Use Suspense"
                      onClose={() => setusesuspense(false)}
                      closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={usesuspensepopup()}
                    />

                    <RACModalCard
                      isOpen={transuspense}
                      // isOpen={true}
                      maxWidth="md"
                      title="Transfer Suspense"
                      // onClose={() => addsuspensecancel()}
                      onClose={() => settransuspense(false)}
                      closeIcon={true}
                      // className={classes.chargeOffModalWidth2}
                      // eslint-disable-next-line react/no-children-prop
                      children={transsuspensepopup()}
                    />

                    <RACModalCard
                      isOpen={refundsuspense}
                      maxWidth="md"
                      title="Refund suspense"
                      onClose={() => setrefundsuspense(false)}
                      closeIcon={true}
                      children={refundpopup()}
                    />

                    <RACModalCard
                      isOpen={false}
                      maxWidth="md"
                      title="Alert popup"
                      onClose={() => setrefundsuspense(false)}
                      closeIcon={true}
                      // children={Adderrorpopup()}
                    />
                  </Grid>
                </Grid> */}

                {/* {paygridvalues.length > 0 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 &&
                (configFlags.isAdjustDueDateFeatureFlag == 1 ||
                  configFlags.isAcceptEpoFeatureFlag == 1 ||
                  configFlags.isEditScheduleFeatureFlag == 1) ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    disabled={isPendingAgrTransfer}
                    onClick={() => setTodefaultVal()}
                    // {text('ContainedButton Label', 'hover over me')}
                  >
                    Set To Default
                  </RACButton>
                ) : null} */}
                {/* {paygridvalues.length > 0 &&
                configFlags.isAddApplyTransferSuspenseFeatureFlag == 1 &&
                selectedCheckBoxCount != 0 &&
                adjustDueDateSelectedAgre.includes("") == false &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    onClick={() => suspensepopupButtonclick()}
                    disabled={suspenseButton || isPendingAgrTransfer}
                  >
                    Suspense
                    <span className={navlinkClass.dropdowntoggle}></span>
                  </RACButton>
                ) : null} */}
                {/* {Number(configFlags.isPaymentFunctionsFeatureFlag) == 1 &&
                selectedCheckBoxCount != 0 &&
                (configFlags.isRegularFreeTimeAllowed == 1 ||
                  configFlags.isServiceFreeTimeAllowed == 1 ||
                  configFlags.isRegExtFreeTimeAllowed == 1 ||
                  configFlags.isPromoFreeTimeallowed == 1) ? (
                  <FreeTime
                    receivedCheckedAgr={checkedAgr}
                    disableFreeTimeForTransfer={isPendingAgrTransfer}
                    configData={configFlags}
                    ldwChangeArr={actLDWArr}
                    policyChangeArr={actPolicyArr}
                    response={(res: any) => {
                      editAdjustRes(res);
                    }}
                  />
                ) : null} */}

                {/* {selectedCheckBoxCount != 0 &&
                configFlags.isAdjustDueDateFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    disabled={
                      isPendingAgrTransfer || disableAdjustDueDateButton ||
                      !(adjustButton == true && selectedCheckBoxCount >= 1)
                    }
                    onClick={() => setopenAdjustDueDate(true)}
                    // {text('ContainedButton Label', 'hover over me')}
                  >
                    {selectedCheckBoxCount == 1
                      ? "Adjust Due Date"
                      : "Adjust All Due Date"}
                  </RACButton>
                ) : null} */}
                {/* {selectedCheckBoxCount != 0 &&
                configFlags.isAcceptEpoFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <RACButton
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    color="primary"
                    variant="contained"
                    onClick={() => epopopupButtonclick()}
                    disabled={!showEPOGridBtn || isPendingAgrTransfer || disableEPOByEPP}
                  >
                    EPO
                  </RACButton>
                ) : null} */}
                {/* {showEPOGridBtn && selectedCheckBoxCount != 0 ? (
                  <Grid
                    item
                    className={`${classes.btngroup} ${classes.floatLeft} ${classes.listPosition}`}
                    role="group"
                  >
                    <OutsideClickHandler onOutsideClick={epoActOnblur}>
                      <List
                        style={{
                          right: "0px",
                          marginBottom: "55px",
                          borderRadius: "5px",
                        }}
                        className={epoPopupClass}
                        // `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
                        // className={`${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`}
                        data-testid="UlButtonsId"
                        aria-labelledby="btnGroupDrop1"
                        onBlur={() => epoActOnblur()}
                      >
                        <ListItem>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            data-bs-toggle="modal"
                            data-testid="TerminateLDWId"
                            onClick={() => acceptEpoPopupOpen()}
                            aria-disabled={true}
                            data-bs-target="#terminateldw"
                          >
                            Accept EPO
                          </a>
                        </ListItem>

                        <ListItem>
                          <a
                            className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                            style={{ background: "white", color: "" }}
                            data-testid="ActiveLDWId"
                            onClick={() => epoSheduleOpen()}
                            data-bs-toggle="modal"
                            data-bs-target="#terminateldw"
                          >
                            Schedule
                          </a>
                        </ListItem>
                      </List>
                    </OutsideClickHandler>
                  </Grid>
                ) : null} */}
                {/* {APUPStates.IsUP === true &&
                adjustDueDateSelectedAgre.includes("") == false &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  //change here
                  <RACButton
                    //type="button"
                    className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                    data-bs-toggle="modal"
                    data-testid="UnEnrollAPId"
                    color="primary"
                    variant="contained"
                    data-bs-target="#autopayconsent"
                    onClick={() => {
                      unenrollautopay();
                    }}
                  >
                    Un-Enroll Autopay
                  </RACButton>
                ) : null} */}
                {/* {configFlags.isPaymentFunctionsFeatureFlag == 1 &&
                selectedCheckBoxCount > 0 &&
                APUPStates.IsUP === false ? (
                  <div
                    style={{ display: "inline" }}
                    title={
                      !(
                        APUPStates.IsAP === true &&
                        adjustDueDateSelectedAgre.includes("") == false
                      )
                        ? "Please select valid agreements to Enroll-AutoPay/Un-Enroll Autopay"
                        : ""
                    }
                  >
                    <RACButton
                      className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                      color="primary"
                      variant="contained"
                      data-bs-toggle="modal"
                      data-bs-target="#autopayconsent"
                      disabled={
                        !(
                          APUPStates.IsAP === true &&
                          adjustDueDateSelectedAgre.includes("") == false
                        )
                      }
                      onClick={(e: any) => {
                        EnrollAutoPayment();
                      }}
                    >
                      Enroll Autopay
                    </RACButton>
                  </div>
                ) : null} */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/*Customer Agreement ends here*/}
        {/*Tender type starts here*/}
        <Grid
          item
          className={`${classes.raccollg8}  ${classes.raccolmd12} ${classes.floatLeft} ${classes.mb3} ${classes.pe1}`}
        >
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}
          >
            <Typography
              variant="h6"
              className={`${classes.floatLeft} ${classes.mt2} ${classes.raccollg8} ${classes.title}`}
            >
              Tender Type
            </Typography>
            <Grid item className={`${classes.floatRight} ${classes.raccollg4}`}>
              <label
                htmlFor="ally-payday"
                className={`${classes.formLabel} ${classes.floatLeft} ${classes.raccollg6} ${classes.mt2}`}
              >
                Payment Origin
              </label>
              <div className={`${classes.raccollg6} ${classes.floatLeft}`}>
                <RACSelect
                  data-testid="PaymentOriginId"
                  options={paymentOriginarr}
                  isDisabled={true}
                  defaultValue={paymentOrigin}
                  onChange={(e) => phone(e)}
                ></RACSelect>
              </div>
            </Grid>
          </Grid>
          <Card
            className={`${classes.card} ${classes.p3} ${classes.pt0} ${classes.marginbottom}`}
          >
            <Grid item className={classes.tabborder}>
              <List
                className={`${classes.RACnav} ${classes.racpadtab} ${classes.py0}`}
                id="RACTab"
                role="tablist"
              >
                <ListItem
                  className={cashtabCss}
                  id="Cash-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Cash"
                  role="tab"
                  aria-controls="Cash"
                  aria-selected="true"
                  // name="Cash"
                  onClick={(e) => {
                    TenderTypeOptinchange(e);
                  }}
                  data-testid="Cashtender"
                  disabled={cashbtn || enableCashPayment ? true : false}
                >
                  Cash
                </ListItem>
                <ListItem
                  className={cardtabCss}
                  id="Card-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Card"
                  role="tab"
                  aria-controls="Card"
                  aria-selected="false"
                  data-testid="Cardtender"
                  // name="Card"
                  onClick={(e) => TenderTypeOptinchange(e)}
                >
                  Card
                </ListItem>

                <ListItem
                  className={checkMoneyOrdertabCss}
                  id="CheckMoney-Order-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#CheckMoney-Order"
                  role="tab"
                  // name="money"
                  aria-controls="CheckMoney-Order"
                  aria-selected="false"
                  onClick={(e) => {
                    TenderTypeOptinchange(e);
                  }}
                  data-testid="Checktender"
                  disabled={mobtn}
                >
                  Check/Money Order
                </ListItem>
              </List>
              <Grid className={`${classes.floatRight} ${classes.mt2}`}>
                <Typography
                  variant="body2"
                  className={`${classes.RAClabeltxtstyle} ${classes.floatLeft} ${classes.semibold}`}
                >
                  Remaining Amount Due :
                </Typography>
                <Typography
                  variant="body2"
                  className={`${classes.RACvaluetxtstyle} ${classes.bold} ${classes.floatLeft}`}
                >
                  ${fixedChange(remainingamountdue)}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft}`}
              id="RACTabContent"
            >
              {/*Cash tab content starts here*/}
              {cashselected === 0 ? (
                <Grid
                  item
                  id="Cash"
                  role="tabpanel"
                  data-testid="cashtabtestid"
                  aria-labelledby="Cash-tab"
                >
                  <Grid
                    item
                    className={`${classes.w100} ${classes.floatLeft} ${classes.my3} ${classes.mt4}`}
                  >
                    <Grid
                      item
                      className={`${classes.w25} ${classes.mt2} ${classes.floatLeft}`}
                    >
                      <label
                        htmlFor="a11y_takehome"
                        className={`${classes.formLabel} ${classes.mb2} ${classes.floatLeft}`}
                      >
                        Amount
                      </label>
                      <Grid className={`${classes.inputgroup} ${classes.mb3}`}>
                        <RACTextbox
                          isCurrency={true}
                          disabled={isPendingAgrTransfer}
                          className={`${classes.custDigit} ${classes.cashtxtbox}`}
                          dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                          value={addcash}
                          data-testid="amounttextbox"
                          Onblur={(e: any) => {
                            cashamounttextboxblur(e);
                          }}
                          OnChange={(e: any) => {
                            cashamounttextbox(e.target.value);
                          }}
                          OnKeydown={(e: any) => {
                            if (e.key.toLowerCase() === "delete") {
                              cashamounttextbox("0.00");
                            }
                          }}
                          type={"number"}
                          digitFormat={"currency"}
                          maxlength={10}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      className={`${classes.floatLeft} ${classes.w75} ${classes.ps4} ${classes.mt4} `}
                    >
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold}${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(1);
                        }}
                      >
                        {" "}
                        $ 1
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold}  ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(5);
                        }}
                      >
                        {" "}
                        $ 5
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(10);
                        }}
                      >
                        {" "}
                        $ 10
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(20);
                        }}
                      >
                        {" "}
                        $ 20
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(50);
                        }}
                      >
                        {" "}
                        $ 50
                      </RACButton>
                      <RACButton
                        className={`${classes.ps3} ${classes.pe3} ${classes.py2} ${classes.bold} ${classes.ms2} ${navlinkClass.paymentBtnHover}${classes.cashbuttonpadding}`}
                        data-testid="amountval1"
                        variant="outlined"
                        disabled={isPendingAgrTransfer}
                        color="primary"
                        onClick={() => {
                          setcashamount(100);
                        }}
                      >
                        {" "}
                        $ 100
                      </RACButton>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {cashselected === 2 ? (
                <Grid
                  item
                  id="CheckMoney-Order"
                  role="tabpanel"
                  aria-labelledby="CheckMoney-Order-tab"
                  data-testid="checkdivshow"
                >
                  <Grid
                    item
                    className={`${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
                  >
                    <Grid
                      item
                      className={`${classes.raccollg12} ${classes.mt4} ${classes.mb3} ${classes.floatLeft}`}
                    >
                      <Grid item className={classes.floatRight}>
                        <RACButton
                          variant="outlined"
                          color="primary"
                          data-testid="amountval1"
                          className={`${classes.paymentSmallBtn} ${classes.mx2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          //style={{ textTransform: 'none', fontWeight: 'bolder', paddingLeft: '14px', paddingRight: '14px' }}
                          onClick={() => {
                            addcheckormoneyorder();
                          }}
                          // disable={adjustDueDateSelectedAgre.includes("")}
                          disabled={
                            isPendingAgrTransfer ||
                            adjustDueDateSelectedAgre.includes("") ||
                            checkdisable
                          }
                        >
                          Add Check
                        </RACButton>
                        <RACButton
                          variant="outlined"
                          color="primary"
                          data-testid="amountval1"
                          disabled={isPendingAgrTransfer}
                          className={`${classes.paymentSmallBtn} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          //style={{ textTransform: 'none', fontWeight: 'bolder', paddingLeft: '14px', paddingRight: '14px' }}
                          onClick={() => {
                            addmoneyord();
                          }}
                        >
                          Add Money Order
                        </RACButton>
                        {/* <button
                            className="btn outlined-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#check-info"
                            onClick={() => {
                              addcheckormoneyorder();
                            }}
                            data-testid="addcheckbtn"
                          >
                            Add Check
                          </button> */}
                        {/* <button
                          className="btn outlined-btn ms-2"
                          data-bs-toggle="modal"
                          data-bs-target="#MoneyOrderInformation"
                          onClick={() => {
                            addmoneyord();
                          }}
                          data-testid="addmoneyorderbtn"
                        >
                          Add Money Order
                        </button> */}
                      </Grid>
                    </Grid>
                    <Grid
                      className={`${classes.raccollg12} ${classes.floatLeft}`}
                    >
                      {/* <table className="table table-borderless table-hover racpad-table button-wrap">
                                  <thead>
                                    <tr>
                                      <th>Document Type</th>
                                      <th>Document #</th>
                                      <th>Document From</th>
                                      <th>Amount</th>
                                      <th className="text-center">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    className="dynamicgridbodyclass"
                                    data-testid="dynamicgrid"
                                  >
                                    {BindGridcheck()}
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td colSpan={2} className="text-end">
                                        Total Amount{" "}
                                        <span className="ms-3">
                                          ${totalamountcheck.toFixed(2)}
                                        </span>
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table> */}
                      <RACTable
                        className={classes.racGrid}
                        renderTableHead={renderUnEnrollBindGridHead}
                        renderTableContent={() => BindGridcheck()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {/*Check Money Order tab content ends here*/}

              {/* <!--Card Tab starts here> */}
              {cashselected === 1 ? (
                <>
                  <Grid
                    item
                    data-testid="carddivshow"
                    id="Card"
                    role="tabpanel"
                    aria-labelledby="Card-tab"
                  >
                    <Grid
                      item
                      className={`${classes.w100} ${classes.floatLeft} ${classes.my3} ${classes.mt4}`}
                    >
                      <Grid
                        item
                        className={`${classes.font16} ${classes.bold} ${classes.floatLeft}`}
                      >
                        Select Card
                      </Grid>
                      <Grid item className={classes.floatRight}>
                        <RACButton
                          color="primary"
                          variant="outlined"
                          className={`${classes.paymentSmallBtn} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          onClick={() => Swipecard()}
                          data-bs-toggle="modal"
                          disabled={
                            isPendingAgrTransfer ||
                            swipeDisable ||
                            suspErrLbl ||
                            diffStoreErrLbl ||
                            epoErrLbl ||
                            deliveryUnconfiremd
                          }
                          data-testid="swipeId"
                        >
                          Swipe Card
                        </RACButton>
                        <RACButton
                          color="primary"
                          className={`${classes.paymentSmallBtn} ${classes.mx2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          variant="outlined"
                          disabled={
                            isPendingAgrTransfer ||
                            suspErrLbl ||
                            diffStoreErrLbl ||
                            epoErrLbl ||
                            deliveryUnconfiremd
                          }
                          onClick={() => chargeCard()}
                          data-bs-toggle="modal"
                          data-testid="swipeId"
                        >
                          Charge Card
                        </RACButton>
                        <RACButton
                          color="primary"
                          className={`${classes.paymentSmallBtn}  ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                          variant="outlined"
                          disabled={addNewCard}
                          onClick={() => newcard()}
                          data-bs-toggle="modal"
                          data-testid="swipeId"
                        >
                          Add New Card
                        </RACButton>
                      </Grid>
                    </Grid>

                    {/* Card total checkbox starts here */}
                    <Grid
                      item
                      className={`${classes.w100} ${classes.floatLeft}`}
                    >
                      {CardErrorLbl === true ? (
                        <Typography
                          component={"label"}
                          // id={"lbl" + index}
                          className={classes.mandatoryfield}
                        >
                          Amount should not be entered more than the remaining
                          amount
                        </Typography>
                      ) : null}
                      {GetcardMpping()}
                      {/* <Grid item className="col-md-4 mt-3 floatLeft mb-4 pe-0"></Grid> */}
                      {/*Card total checkbox display ends here*/}
                    </Grid>
                  </Grid>
                  {showAddCard && !showChargeCard ? (
                    <Grid
                      style={{ float: "left", width: "100%" }}
                      id="newcard"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex={-1}
                      aria-labelledby="carrywaive"
                      aria-hidden="true"
                      data-testid="NewCardTestId"
                    >
                      <Grid style={{ float: "left", width: "100%" }}>
                        <Grid style={{ float: "left", width: "40%" }}>
                          <h5
                            style={{
                              marginBottom: 20,
                              marginTop: "20px",
                              fontSize: "14px",
                            }}
                          >
                            Add Card Information
                          </h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid id="ccframe"></Grid>
                          </Grid>
                        </Grid>

                        <Grid style={{ float: "left", width: "60%" }}>
                          <h5 style={{ marginBottom: "20", fontSize: "14px" }}>
                            Billing Address
                          </h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type="text"
                                name="name"
                                id="Name"
                                inputlabel="Name"
                                required={true}
                                data-testid="NameId"
                                value={Popupfields.name}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Name_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Name
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line_1"
                                id="AddressLine1"
                                data-testid="AddressLine1Id"
                                value={Popupfields.address_line_1}
                                inputlabel="Address Line 1"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {Address_line1_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Address line1
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line2"
                                data-testid="AddressLine2Id"
                                id="AddressLine2"
                                inputlabel="Address Line 2"
                                value={Popupfields.address_line2}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type={"number"}
                                digitFormat={"normal"}
                                name="zip_code"
                                id="ZIP"
                                pattern="\d*"
                                maxlength={5}
                                value={Popupfields.zip_code}
                                data-testid="ZipCodeId"
                                inputlabel="ZIP Code"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Zip_code_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter ZIP code
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="City"
                                id="City"
                                required={true}
                                inputlabel="City"
                                value={Popupfields.city}
                                data-testid="StateId"
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {CityValidation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter City
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACSelect
                                // className={classes.formcontrol} Need to update node module
                                data-testid="dropmainId"
                                options={DD}
                                defaultValue={defaultVal}
                                inputLabel="State"
                                required={true}
                                name="state"
                                onChange={(e) => dynamicState(e.target)}
                              ></RACSelect>
                              {State_validation === true ? (
                                <Typography className={classes.mandatoryfield}>
                                  Please select state
                                </Typography>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid style={{ float: "left", width: "100%" }}>
                        <div style={{ float: "right" }}>
                          <RACButton
                            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                            variant="outlined"
                            color="primary"
                            name="De_ActivateClubbtn"
                            style={{
                              backgroundColor: "white",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "#2179fe",
                            }}
                            onClick={() => AddCardClose()}
                            data-bs-toggle="modal"
                          >
                            Cancel
                          </RACButton>

                          <RACButton
                            className={`${classes.paymentSmallBtn1}`}
                            color="primary"
                            variant="contained"
                            name="De_ActivateClubbtn"
                            style={{
                              backgroundColor: "#2179fe",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "white",
                            }}
                            onClick={() => vantivSubFunc()}
                            data-bs-toggle="modal"
                          >
                            Save
                          </RACButton>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  {showChargeCard && !showAddCard ? (
                    <Grid
                      style={{ float: "left", width: "100%" }}
                      id="newcard"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex={-1}
                      aria-labelledby="carrywaive"
                      aria-hidden="true"
                      data-testid="NewCardTestId"
                    >
                      <Grid style={{ float: "left", width: "100%" }}>
                        <Grid style={{ float: "left", width: "40%" }}>
                          <h5 style={{ marginBottom: 20, marginTop: "20px" }}>
                            Charge Card Information
                          </h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid id="chargeframe"></Grid>
                          </Grid>
                        </Grid>

                        <Grid style={{ float: "left", width: "60%" }}>
                          <Grid
                            item
                            md={12}
                            className={`${classes.mb3} ${classes.ps2}`}
                          >
                            <Grid
                              item
                              md={12}
                              className={`${classes.formLabel} ${classes.mt2}`}
                            >
                              Amount
                            </Grid>
                            <Grid item md={12} className={`${classes.mb3}`}>
                              <RACTextbox
                                isCurrency={true}
                                className={`${classes.custDigit}`}
                                value={chargeCardAmt}
                                name="total_amt"
                                id="chargetxtbox"
                                data-testid="amtTxtBoxId"
                                Onblur={(e: any) =>
                                  totalChargeAmount(e.target.value)
                                }
                                OnChange={(e: any) =>
                                  setchargeCardAmt(e.target.value)
                                }
                                OnKeydown={(e: any) => {
                                  if (e.key.toLowerCase() === "delete") {
                                    setchargeCardAmt("0.00");
                                  }
                                }}
                                type={"number"}
                                digitFormat={"currency"}
                                maxlength={10}
                                dollarTextClassName={
                                  classes.currencyDollarField
                                }
                              />
                              {Validator.chargeCardValidation === true ? (
                                <label
                                  className={classes.validationstyle}
                                  style={{
                                    visibility:
                                      Validator.chargeCardValidation === true
                                        ? "visible"
                                        : "hidden",
                                  }}
                                >
                                  Amount should not be entered more than the
                                  remaining amount
                                </label>
                              ) : null}
                            </Grid>
                          </Grid>
                          <h5 style={{ marginBottom: 20 }}>Billing Address</h5>
                          <Grid style={{ float: "left", width: "100%" }}>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type="text"
                                name="name"
                                id="Name"
                                inputlabel="Name"
                                required={true}
                                data-testid="NameId"
                                value={Popupfields.name}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Name_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Name
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line_1"
                                id="AddressLine1"
                                data-testid="AddressLine1Id"
                                value={Popupfields.address_line_1}
                                inputlabel="Address Line 1"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {Address_line1_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter Address line1
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="address_line2"
                                data-testid="AddressLine2Id"
                                id="AddressLine2"
                                inputlabel="Address Line 2"
                                value={Popupfields.address_line2}
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                isCurrency={false}
                                type={"number"}
                                digitFormat={"normal"}
                                name="zip_code"
                                id="ZIP"
                                pattern="\d*"
                                maxlength={5}
                                value={Popupfields.zip_code}
                                data-testid="ZipCodeId"
                                inputlabel="ZIP Code"
                                required={true}
                                OnChange={(e) => initialnewcardstate(e.target)}
                              />
                              {Zip_code_validation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter ZIP code
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACTextbox
                                type="text"
                                name="City"
                                id="City"
                                required={true}
                                inputlabel="City"
                                value={Popupfields.city}
                                data-testid="StateId"
                                OnChange={(e) => initialnewcardstate(e.target)}
                                isCurrency={false}
                              />
                              {CityValidation === true ? (
                                <Typography
                                  className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                                >
                                  Please enter City
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              style={{
                                float: "left",
                                width: "48%",
                                marginBottom: 20,
                                padding: "1%",
                              }}
                            >
                              <RACSelect
                                // className={classes.formcontrol} Need to update node module
                                data-testid="dropmainId"
                                options={DD}
                                defaultValue={defaultVal}
                                inputLabel="State"
                                required={true}
                                name="state"
                                onChange={(e) => dynamicState(e.target)}
                              ></RACSelect>
                              {State_validation === true ? (
                                <Typography className={classes.mandatoryfield}>
                                  Please select state
                                </Typography>
                              ) : null}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid style={{ float: "left", width: "100%" }}>
                        <div style={{ float: "right" }}>
                          <RACButton
                            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                            variant="outlined"
                            color="primary"
                            name="De_ActivateClubbtn"
                            style={{
                              backgroundColor: "white",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "#2179fe",
                            }}
                            onClick={() => ChargeCardClose()}
                            data-bs-toggle="modal"
                          >
                            Cancel
                          </RACButton>

                          <RACButton
                            className={`${classes.paymentSmallBtn1}`}
                            color="primary"
                            variant="contained"
                            name="De_ActivateClubbtn"
                            disabled={
                              chargeCardContinue || Number(chargeCardAmt) == 0
                            }
                            style={{
                              backgroundColor: "#2179fe",
                              textTransform: "none",
                              fontWeight: "bolder",
                              paddingLeft: "14px",
                              paddingRight: "14px",
                              color: "white",
                            }}
                            onClick={() => vantivSubFunc()}
                            data-bs-toggle="modal"
                          >
                            Continue
                          </RACButton>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              ) : null}
              {/* <!--Card Tab Ends here> */}

              {/*Check Information starts*/}
              {/* <div className="w-100 float-start "></div> */}
              <RACModalCard
                isOpen={addcheck}
                maxWidth="xs"
                title="Check Information"
                onClose={() => checkormoneyordercancel()}
                closeIcon={true}
                // eslint-disable-next-line react/no-children-prop
                children={addcheckpopup()}
              />
              {/*Check Information ends*/}
            </Grid>
          </Card>
        </Grid>

        {/*Tender type ends here*/}
        {/*Available Credit starts here*/}
        <Grid
          item
          className={`${classes.raccollg4} ${classes.floatLeft} ${classes.mb3} ${classes.px1}  ${classes.responsivelayout}`}
        >
          <AvailableCredits
            disableCred={isPendingAgrTransfer}
            availableCredits={getpayment?.paymentInfo.remainingCredits}
            balance={getpayment?.balance}
            BucketValue={amount_feild}
          ></AvailableCredits>
          {/* {
        Changes:Carry Rent
        Developer:Muzzammil Shaik
        Desc:UI for Toggle and Widget
      } */}
          {/* <RACInfoIcon
        infoTitle={carryMessage}
        placement={'left'}
        className= {carryMessage==""?classes.carryInfocolorGreen:classes.carryInfocolorRed}
        /> */}
          {/* {configFlags.isCarryRentFeatureFlag == 1 &&
          configFlags.isPaymentFunctionsFeatureFlag == 1 &&
          isFromAccountMgmt === false ? (
            <RACToggle
              name="MailingAddres"
              GridClassName={`${classes.gridClassToggleReverse} ${classes.carryRentBottom}`}
              labelGridClassName={classes.gridClassLabel}
              checked={
                carryRentWidget &&
                selectedCheckBoxCount > 0 &&
                carryRentInitialAndFinal == false
              }
              toggleColor="#71CA2F"
              leftText={true}
              disabled={isPendingAgrTransfer}
              labelClassName={classes.switchLabel}
              leftLabel="Carry Rent"
              OnClick={(e) => {
                carryOnchange();
              }}
            />
          ) : null} */}

          {carryRentWidget &&
          selectedCheckBoxCount > 0 &&
          carryRentInitialAndFinal == false ? (
            <CarryRent
              totalDue={Number(totaldue) + Number(carryRentAmount)}
              totalduetendertype={totalduetendertype}
              remainingDue={remainingamountdue}
              mxcarry={checkedAgr}
              response={(res: any) => {
                carryRes(res);
              }}
              carryAmount={carryRentAmount}
              carryRentCheck={carryRentCheck}
              availableCreds={availableCreds}
              carryMax={carryMax}
              ldwChangeArr={actLDWArr}
              totalCalciTextValue={totalCalciTextValue}
              // BucketValue={amount_feild}
            ></CarryRent>
          ) : null}
          {carryRentCheck ? carryCheckChange() : null}
        </Grid>
        {/*Available Credit ends here*/}
      </Grid>
      {/*Available Credit ends here*/}
      {/*Total due starts here*/}
      <Grid
        item
        className={`${classes.raccollg3} ${classes.floatLeft} ${classes.px1} ${classes.mb3}`}
      >
        <Grid
          item
          className={`${classes.RACpaymenttotalcontainer} ${classes.p3}`}
        >
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.totallabelstyle} ${classes.bold} ${classes.mt2}`}
              >
                TOTAL DUE
              </Typography>
              <Grid item className={classes.floatRight}>
                <Typography
                  className={`${classes.floatLeft} ${classes.totalvaluestyle} ${classes.bold} ${classes.totallabelstyle}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.floatLeft} ${classes.totalvaluestyle} ${classes.bold} ${classes.totallabelstyle}`}
                >
                  {" "}
                  {AddTrailingZerosToAmount(
                    Math.abs(Number(totaldue) + Number(carryRentAmount))
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
              >
                Total Tax
              </Typography>
              <Grid
                item
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
              >
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  {totaltax.toFixed(2) === -0.0 ||
                  totaltax.toFixed(2) === "-0.00" ||
                  Number(totaltax) === 0 ||
                  totaltax === "0"
                    ? (0.0 + convtax).toFixed(2)
                    : (Number(totaltax) + convtax).toFixed(2)}
                </Typography>
                {/* totaltax.toFixed(2) */}
              </Grid>
            </Grid>
            {/**
             * Changes : Carry Rent
             * Developer:Muzzammil Shaik
             * Desc: Line item in blue panel
             */}
            {carryRentWidget &&
            selectedCheckBoxCount > 0 &&
            carryRentInitialAndFinal == false ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carry Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    ($
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {Number(carryRentAmount).toFixed(2) + ")"}
                  </Typography>
                  {/* totaltax.toFixed(2) */}
                </Grid>
              </Grid>
            ) : null}
            {Number(agreecarryUsed) > 0 && selectedCheckBoxCount != 0 ? (
              <Grid
                item
                className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
              >
                <Typography
                  className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
                >
                  Carried Rent
                </Typography>

                <Grid
                  item
                  className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
                >
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    $
                  </Typography>
                  <Typography
                    className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                  >
                    {Number(agreecarryUsed).toFixed(2)}
                  </Typography>
                  {/* totaltax.toFixed(2) */}
                </Grid>
              </Grid>
            ) : null}
            {/* <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.semibold} ${classes.totalsublabelstyle} ${classes.colorwhite}`}
              >
                Total Late Fee
              </Typography>
              <Grid
                item
                className={`${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite}`}
              >
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  $
                </Typography>
                <Typography
                  className={`${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.floatLeft} ${classes.colorwhite}`}
                >
                  {AddTrailingZerosToAmount(Latelast10value)}
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
          {AvailCredits_Binding()}
          {WLDeposit_Bind()}
          {ConvenienceFee(paymentOrigin)}

          {/* <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
              >
                Suspense
                {totalsuspensemaster?.length > 0 ? (
                  <Deleteicon
                    onClick={() => suspenseDeleteFunc() }
                  ></Deleteicon>
                ) : null}
              </Typography>
              {suspenseitemsbind()}
            </Grid>
          </Grid> */}

          {/* <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2} `}
                style={{ marginBottom: "20px" }}
              >
                Receipt Items
              </Typography>
              {receiptitemsbind()}
            </Grid>
          </Grid> */}
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft} ${classes.containerborderbtm} ${classes.mb2}`}
          >
            <Grid
              item
              className={`${classes.w100} ${classes.floatLeft} ${classes.mb3}`}
            >
              <Typography
                className={`${classes.floatLeft} ${classes.bold} ${classes.totallabelstyle} ${classes.colorwhite} ${classes.mt2}`}
              >
                Tender Type
              </Typography>
            </Grid>
            {tendertypebind()}
          </Grid>
          <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
            <Grid
              item
              className={`${classes.formcheckinline} ${classes.floatLeft}`}
            >
              <Grid
                item
                className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft} ${classes.me2}`}
              >
                <RadioColor
                  className={classes.floatLeft}
                  name="changeorcredit"
                  onClick={(e) => RadiobtnChange(e)}
                  disabled={creditDisable || isPendingAgrTransfer}
                  defaultValue="change"
                  checked={changeRadio}
                  /*disabled={changeenable}*/ value="change"
                  IscolorVariant={true}
                  variantColor={"#2FB452"}
                  backgroundColor={"white"}
                />
                <Box
                  component="span"
                  className={`${classes.changecredit} ${classes.ps0}`}
                >
                  Change
                </Box>
              </Grid>
              {/* <Grid
                item
                className={`${classes.formcheck} ${classes.ps0} ${classes.floatLeft}`}
              >
                <RadioColor
                  className={classes.floatLeft}
                  name="changeorcredit"
                  onClick={(e) => RadiobtnChange(e)}
                  defaultValue="change"
                  checked={CreditRadio}
                  disabled={selectedOnlyCashSale || isPendingAgrTransfer}
                  value="credit"
                  IscolorVariant={true}
                  variantColor={"#2FB452"}
                  backgroundColor={"white"}
                />
                {suspensehide === true ? (
                  <Box
                    component="span"
                    className={`${classes.changecredit} ${classes.ps0}`}
                  >
                    Suspense
                  </Box>
                ) : (
                  <Box
                    component="span"
                    className={`${classes.changecredit} ${classes.ps0}`}
                  >
                    Credit
                  </Box>
                )}
              </Grid> */}
            </Grid>
            <Box
              component="span"
              className={`${classes.changecredit} ${classes.floatRight} ${classes.totalsublabelstyle} ${classes.bold} ${classes.totallabelstyle} ${classes.mtcustom}`}
            >
              $
              <Box component="span" className={classes.changecredit}>
                {fixedChange(change)}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/*Total due ends here*/}
      {/*Footer starts here*/}
      <Grid
        item
        className={`${classes.toolbar} ${classes.mx0} ${classes.raccollg12}`}
      >
        <Grid item className={classes.floatLeft}>
          <RACButton
            onClick={() => {
              if (location?.state?.redirectSource === "am") {
                setRedirectToAccountManagement(true);
                return;
              }
              return redirectionTopaymentsearch();
            }}
            type="button"
            color="primary"
            variant="outlined"
          >
            Cancel
          </RACButton>
        </Grid>
        <Grid item className={classes.floatRight}>
          <Grid
            item
            className={`${classes.btngroup} ${classes.floatLeft}`}
            role="group"
          >
            <OutsideClickHandler onOutsideClick={otherActOnblur}>
              <List
                style={{ marginBottom: "5px", borderRadius: "5px" }}
                className={PopupClass}
                data-testid="UlButtonsId"
                aria-labelledby="btnGroupDrop1"
                onBlur={() => otherActOnblur()}
              >
                {ActionState === 2 &&
                showLDWPolicy == true &&
                Cardrequire.Ldwhide === false &&
                selectedCheckBoxCount == 1 ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="TerminateLDWId"
                      onClick={() => TerminateLDWbtnPopup()}
                      data-bs-target="#terminateldw"
                    >
                      Terminate LDW
                    </a>
                  </ListItem>
                ) : null}
                {configFlags.isPaymentHistoryFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <ListItem disabled={payhisflag}>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="paymenthistory"
                      onClick={() => {
                        payhisflag ? null : HistorybtnPopup();
                      }}
                      data-bs-target="#terminateldw"
                    >
                      Payment History
                    </a>
                  </ListItem>
                ) : null}

                {ActionState === 1 &&
                showLDWPolicy == true &&
                Cardrequire.Ldwhide === false &&
                selectedCheckBoxCount == 1 ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      style={{ background: "white", color: "" }}
                      data-testid="ActiveLDWId"
                      data-bs-toggle="modal"
                      onClick={() => ActivateLDWbtnPopup()}
                      data-bs-target="#terminateldw"
                    >
                      Activate LDW
                    </a>
                  </ListItem>
                ) : null}
                {/**
                 *  changes: Edit Schedule V2
                 *  Developer : Muzzammil Shaik
                 *  Desc : UI for edit schedule
                 */}
                {/* {? ( */}
                {configFlags.isEditScheduleFeatureFlag == 1 &&
                configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
                  <ListItem
                    disabled={
                      !(
                        editButton == true &&
                        selectedCheckBoxCount >= 1 &&
                        disableAdjustDueDateButton == false
                      )
                    }
                  >
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="TerminateLDWId"
                      onClick={() => editSchedule()}
                      data-bs-target="#terminateldw"
                      aria-disabled={true}
                    >
                      Edit Schedule
                    </a>
                  </ListItem>
                ) : null}

                {/* ) : null} */}
                {policyService_state === 1 &&
                showLDWPolicy == true &&
                selectedCheckBoxCount == 1 &&
                Cardrequire.Policyhide === false ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="ActivatePolicyId"
                      onClick={() => Activepolicypopup()}
                      data-bs-target="#terminateldw"
                    >
                      Activate Policy
                    </a>
                  </ListItem>
                ) : null}

                {policyService_state !== 1 &&
                showLDWPolicy == true &&
                selectedCheckBoxCount == 1 &&
                Cardrequire.Policyhide === false ? (
                  <ListItem>
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-testid="DeactivatePolicybtnId"
                      style={{ background: "white" }}
                      data-bs-toggle="modal"
                      onClick={() => DeActivepolicypopup()}
                      data-bs-target="#terminateldw"
                    >
                      Deactivate Policy
                    </a>
                  </ListItem>
                ) : null}
                {Number(configFlags?.carryWaveLateFee) == 1 ? (
                  <ListItem
                    disabled={
                      checkedAgr.length == 1 &&
                      checkedAgr[0]?.constLateFee > 0 &&
                      checkedAgr[0]?.Nextduedate != "SAC" &&
                      checkedAgr[0]?.Nextduedate != "EPO" &&
                      checkedAgr[0]?.Nextduedate != "PIF"
                        ? false
                        : true
                    }
                  >
                    <a
                      className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="TerminateLDWId"
                      onClick={() =>
                        Number(checkedAgr[0].constLateFee > 0) &&
                        checkedAgr.length == 1 &&
                        checkedAgr[0]?.Nextduedate != "SAC" &&
                        checkedAgr[0]?.Nextduedate != "EPO" &&
                        checkedAgr[0]?.Nextduedate != "PIF"
                          ? setNeedCarryWaveAuth(true)
                          : null
                      }
                      data-bs-target="#terminateldw"
                      //aria-disabled={true}
                    >
                      Waive Late Fee
                    </a>
                  </ListItem>
                ) : null}
              </List>
            </OutsideClickHandler>

            {/* {otherActionSpinner === true ? (
              <CircularProgress
                className={classes.alertLoader}
              ></CircularProgress>
            ) : null} */}

            {/* {OtherActionHidden === false ? */}
            {/* {Number(configFlags.isPaymentFunctionsFeatureFlag) == 1 ? (
              <RACButton
                //className={`${classes.ms2} ${classes.mt1}`}
                color="primary"
                variant="contained"
                disabled={isPendingAgrTransfer}
                onClick={() => popupButtonclick()}
                // onBlur={()=>otherActOnblur()}
                loading={otherActionSpinner}
                hidden={OtherActionHidden}
              >
                Other Actions
                <span className={navlinkClass.dropdowntoggle}></span>
              </RACButton>
            ) : null} */}
            {/* : null} */}
          </Grid>
          {/**
           * Changes: Cash Sale
           * Developer : Muzzammil Shaik
           * Desc: UI for other Income
           */}
          <Grid
            item
            className={`${classes.btngroup} ${classes.floatLeft}`}
            role="group"
          >
            <OutsideClickHandler onOutsideClick={() => otherIncomeOnblur()}>
              <List
                style={{ marginBottom: "5px", borderRadius: "5px" }}
                className={otherIncomePopupClass}
                data-testid="UlButtonsId"
                aria-labelledby="btnGroupDrop1"
                onBlur={() => otherIncomeOnblur()}
              >
                <ListItem>
                  <a
                    className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                    data-testid="DeactivatePolicybtnId"
                    style={{ background: "white" }}
                    data-bs-toggle="modal"
                    // onClick={() => nonInventoryOnClick({
                    //   type:"Cell Phone Activarion",
                    //   Description:"Net10 $35 Plan",
                    //   Amount:"38.60"
                    // })}
                    onClick={() => setnonInventoryPopUp(true)}
                    data-bs-target="#terminateldw"
                  >
                    Non-Inventory Sale
                  </a>
                </ListItem>
              </List>
            </OutsideClickHandler>
            {/* {otherActionSpinner === true ? (
          <CircularProgress
            className={classes.alertLoader}
          ></CircularProgress>
        ) : null} */}
            {/* {OtherActionHidden === false ?  */}
            {/* {configFlags.isCashSaleFeatureFlag == 1 &&
            isFromAccountMgmt === false ? (
              <RACButton
                className={classes.ms2}
                color="primary"
                variant="contained"
                disabled={isPendingAgrTransfer}
                onClick={() => otherIncomepopupButtonclick()}
                // onBlur={()=>otherIncomeOnblur()}
                hidden={OtherIncomeHidden}
              >
                Other Income
                <span className={navlinkClass.dropdowntoggle}></span>
              </RACButton>
            ) : null} */}
            {/* {configFlags.isActivateClub == 1 &&
            configFlags.isPaymentFunctionsFeatureFlag == 1 ? (
              <RACButton
                className={classes.ms2}
                color="primary"
                variant="contained"
                onClick={() =>
                  clubStatusHandleChange(clubStatus == "A" ? "P" : "C")
                }
                disabled={isclubOnSameStore || isPendingAgrTransfer}
              >
                {clubStatus == "P"
                  ? "Club is pending"
                  : clubStatus == "D"
                  ? "Deactivate club"
                  : "Activate club"}
              </RACButton>
            ) : null} */}
            {/* } */}
          </Grid>
          {/* {isFromAccountMgmt === false ? (
            <RACButton
              className={classes.ms2}
              color="primary"
              variant="contained"
              name="De_ActivateClubbtn"
              onClick={() => txt2paybtn()}
              data-bs-toggle="modal"
              disabled={
                isPendingAgrTransfer || (paygridvalues.length > 0 &&
                epoErrLbl == false &&
                diffStoreErrLbl == false &&
                suspErrLbl == false &&
                diffStoreErrLbl == false &&
                tendertypeglobalarray.length == 0
                  ? false
                  : true) ||
                deliveryUnconfiremd ||
                isCommitmentInSIMS || selectedCheckBoxCount==0 ||
                adjustDueDateSelectedAgre.includes("")
              }
            >
              Txt2Pay
            </RACButton>
          ) : null} */}

          {/*
           *Changes made for - Payment History popup
           *Changes made by  - syed azimudeen
           */}

          {PaymentHistory ? (
            <DeliverySlots
              name="test"
              ClubGridInfoResp={ClubGridInfoResp}
              clubActivityInfo={clubActivityInfo}
              clubInfo={clubinformation}
              disable={payclubflag}
              customerId={customerId}
              emailId={getpayment.paymentInfo.customerInfo[0].emailAddress}
              paymenthistorygrid={paymentHisGrid}
              uniqueAgreementID={uniqueAgreementID}
              commoninformation={commoninformation}
              openModal={PaymentHistory}
              closePaymentHistoryPopup={(onClose: any) => {
                setPaymentHistory(onClose);
              }}
              response={(res: any) => {
                paymentHistoryClose(res);
              }}
              redirect={(response: any) => {
                history.push(response);
              }}
            />
          ) : null}

          {/*
           *Changes made for - Payment Club payment history popup
           *Changes made by  - syed azimudeen
           */}

          {ClubPaymentHistory ? (
            <ClubPayment
              customerData={customerdata}
              activateClubFeatureFlag={configFlags.isActivateClub}
              openModal={ClubPaymentHistory}
              paygridvalues={paygridvalues}
              ClubGridInfoResp={ClubGridInfoResp}
              clubActivityInfo={clubActivityInfo}
              clubinfo={clubinformation}
              paymenthistorygrid={paymentHisGrid}
              uniqueAgreementID={uniqueAgreementID}
              commoninformation={commoninformation}
              closeClubPaymentHistoryPopup={(onClose: any) => {
                setClubPaymentHistory(onClose);
              }}
              response={(res: any) => {
                clubpaymentHistoryClose(res);
              }}
              redirect={(response: any) => {
                history.push(response);
              }}
            />
          ) : null}
          {isFromAccountMgmt === false &&
          configFlags.optimizedPayment == "0" ? (
            <Payment
              totalsuspensemaster={totalsuspensemaster}
              configFlags={configFlags}
              changeRadio={changeRadio}
              CreditRadio={CreditRadio}
              suspensehide={suspensehide}
              storeMerchantId={storeMerchantId}
              availableCredsremaining={availableCredsremaining}
              activateClubFeatureFlag={configFlags.isActivateClub}
              clubStatus={clubStatus}
              customerData={customerdata}
              ConvFeeWithOutTax={ConvFeeWithOutTax}
              tenderTypeArray={totalduereceiptid}
              carryRent={carryRentAmount}
              clubCheckDisabled={clubCheckDisabled}
              chargeCard={chargeCardArray}
              cardToken={cardToken}
              taxGeoCode={
                paygridvalues[0] != undefined ? paygridvalues[0].taxGeoCode : ""
              }
              taxZip={
                paygridvalues[0] != undefined
                  ? paygridvalues[0].zipPlus4UsedForTax
                  : ""
              }
              convTax={paygridvalues[0] == undefined ? 0 : convtax}
              clubActivate={clubActivate}
              origin={Cardrequire.PaymentOrgin}
              profileAccResp={profileAccResp}
              checkDetail={adddataanother}
              cardDetail={cardDetails}
              lastFour={cardLastFour}
              ClubPayFlag={checkedAgr}
              cardAuthNumber={cardAuthNo}
              billingAddressCharge={chargeBillInfo}
              coworkerId={empId}
              actLDWArr={actLDWArr}
              actPolicyArr={actPolicyArr}
              swipeFlag={swipeFlag}
              finalagr={finalagr}
              paygridvalues={paygridvalues}
              disabled={
                isPendingAgrTransfer ||
                (suspErrLbl
                  ? true
                  : diffStoreErrLbl
                  ? true
                  : epoErrLbl
                  ? true
                  : Acceptpaymentbuttonenable) ||
                deliveryUnconfiremd ||
                CardErrorLbl ||
                selectedCheckBoxCount == 0
              }
              setAcceptpaymentbuttonenable={setAcceptpaymentbuttonenable}
              cashamountcheckbox={cashamountcheckbox}
              getResponse={acceptPayment}
              TotalchkMoney={chkMoney}
              WidgetRadioBtn={radioBtn}
              Taxfield={getpayment}
              Totaldue={Number(totaldue) + Number(carryRentAmount)}
              GetPaymentVal={AgreementSelect}
              AcceptPay={AcceptPayments}
              ClubPay={AcceptclubPaymentParam}
              Reciept={ReceiptCategory}
              AgreementIdProp={agreementId}
              setpaymentMade={setpaymentMade}
              setpaymentMadeOptimized={(response:any)=>setpaymentMadeOptimized(response)}
              CustomerIdProp={customerId}
              Paymentchange={change}
              PayOrigin={paymentOrigin}
              avaliableCredits={availableCreds}
              getCardstate={getCardService_state}
              customerId={customerId}
              COAamountnum={coaamt}
              weblead={wldepositamount}
              Integratedsus={ipsuspense}
              Suspense={suspenseAmount}
              swipeCardAmt={swipeamt}
              isCoPrelim={true}
              provideEAFlag={EAFlag}
              providePlaceOrderItemData={placeOrderItemData}
              provideAgrNumberPO={AgrNumberPO}
              providePOAddressData={placeOrderAddressData}
            />
          ) : isFromAccountMgmt === false &&
            configFlags.optimizedPayment == "1" ? (
            <ProcessPayment
            tendertypeglobalarray={tendertypeglobalarray}
              customerGCID={customerGCID}
              totalsuspensemaster={totalsuspensemaster}
              configFlags={configFlags}
              changeRadio={changeRadio}
              CreditRadio={CreditRadio}
              suspensehide={suspensehide}
              storeMerchantId={storeMerchantId}
              availableCredsremaining={availableCredsremaining}
              activateClubFeatureFlag={configFlags.isActivateClub}
              clubStatus={clubStatus}
              customerData={customerdata}
              ConvFeeWithOutTax={ConvFeeWithOutTax}
              tenderTypeArray={totalduereceiptid}
              carryRent={carryRentAmount}
              clubCheckDisabled={clubCheckDisabled}
              chargeCard={chargeCardArray}
              cardToken={cardToken}
              taxGeoCode={
                paygridvalues[0] != undefined ? paygridvalues[0].taxGeoCode : ""
              }
              taxZip={
                paygridvalues[0] != undefined
                  ? paygridvalues[0].zipPlus4UsedForTax
                  : ""
              }
              convTax={paygridvalues[0] == undefined ? 0 : convtax}
              clubActivate={clubActivate}
              origin={Cardrequire.PaymentOrgin}
              profileAccResp={profileAccResp}
              checkDetail={adddataanother}
              cardDetail={cardDetails}
              lastFour={cardLastFour}
              ClubPayFlag={checkedAgr}
              cardAuthNumber={cardAuthNo}
              billingAddressCharge={chargeBillInfo}
              coworkerId={empId}
              actLDWArr={actLDWArr}
              actPolicyArr={actPolicyArr}
              swipeFlag={swipeFlag}
              finalagr={finalagr}
              paygridvalues={paygridvalues}
              disabled={
                isPendingAgrTransfer ||
                (suspErrLbl
                  ? true
                  : diffStoreErrLbl
                  ? true
                  : epoErrLbl
                  ? true
                  : Acceptpaymentbuttonenable) ||
                deliveryUnconfiremd ||
                CardErrorLbl ||
                selectedCheckBoxCount == 0
              }
              setAcceptpaymentbuttonenable={setAcceptpaymentbuttonenable}
              cashamountcheckbox={cashamountcheckbox}
              getResponse={acceptPayment}
              TotalchkMoney={chkMoney}
              WidgetRadioBtn={radioBtn}
              Taxfield={getpayment}
              Totaldue={Number(totaldue) + Number(carryRentAmount)}
              GetPaymentVal={AgreementSelect}
              AcceptPay={AcceptPayments}
              ClubPay={AcceptclubPaymentParam}
              Reciept={ReceiptCategory}
              AgreementIdProp={agreementId}
              setpaymentMade={setpaymentMade}
              setpaymentMadeOptimized={(response:any)=>setpaymentMadeOptimized(response)}
              CustomerIdProp={customerId}
              Paymentchange={change}
              PayOrigin={paymentOrigin}
              avaliableCredits={availableCreds}
              getCardstate={getCardService_state}
              customerId={customerId}
              COAamountnum={coaamt}
              weblead={wldepositamount}
              Integratedsus={ipsuspense}
              Suspense={suspenseAmount}
              swipeCardAmt={swipeamt}
              isCoPrelim={true}
              provideEAFlag={EAFlag}
              providePlaceOrderItemData={placeOrderItemData}
              provideAgrNumberPO={AgrNumberPO}
              providePOAddressData={placeOrderAddressData}
              selectedAgreements={checkedAgr}
              billingAddressValidation={billingAddressValidation}
            />
          ) : null}
          {isFromAccountMgmt === true ? (
            <RACButton
              color="primary"
              variant="contained"
              className={classes.ms2}
              disabled={isCommitmentInOtherStore}
              onClick={() => continueAMClicked()}
            >
              {" "}
              Continue
            </RACButton>
          ) : null}
        </Grid>
      </Grid>
      {/*Footer ends here*/}

      {/*personal check alert*/}
      <RACModalCard
        isOpen={personalcheckalert}
        maxWidth="xs"
        onClose={() => setpersonalcheckalert(false)}
        // eslint-disable-next-line react/no-children-prop
        children={personalcheckalertpopup()}
      />

      {/*personal check alert End here*/}
      {/*Activateclub popup starts here*/}
      <RACModalCard
        isOpen={clubStatusPopup.activateClub}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() =>
          setclubStatusPopup({ ...clubStatusPopup, activateClub: false })
        }
        // eslint-disable-next-line react/no-children-prop
        children={activateClubPopup()}
      />
      {/*DeActivateclub popup starts here */}
      <RACModalCard
        isOpen={clubStatusPopup.deactivateClub}
        closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() =>
          setclubStatusPopup({ ...clubStatusPopup, deactivateClub: false })
        }
        // eslint-disable-next-line react/no-children-prop
        children={deactivateClubPopup()}
      />
      <RACModalCard
        isOpen={deactivateClubCustomerRedirection}
        closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() => setdeactivateClubCustomerRedirection(false)}
        // eslint-disable-next-line react/no-children-prop
        children={deactivateClubCustomerRedirectionPopup()}
      />
      {/*DeActivate popup ends here*/}
      {/*masterloader popup starts here */}
      {masterloader ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      {/*masterloader popup ends here */}
      {/*Error popup starts here */}
      <RACModalCard
        isOpen={errorPopup}
        closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        onClose={() => seterrorPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ErrorMsgPopup()}
      />
      {/*Error popup ends here */}

      <RACModalCard
        isOpen={postalCodeZip5ErrorFlag != 0 ? true : false}
        // closeIcon={true}
        borderRadius="20px"
        maxWidth="xs"
        // onClose={() => setPrimaryAddressErrorFlag(true)}
        // eslint-disable-next-line react/no-children-prop
        children={postalCodeZip5ErrorMsgPopup()}
      />

      {/*Traveller check alert*/}
      <RACModalCard
        isOpen={travellercheckalert}
        maxWidth="xs"
        onClose={() => settravellercheckalert(false)}
        // eslint-disable-next-line react/no-children-prop
        children={travellercheckalertPopup()}
      />

      {/*Traveller check alert ends here*/}
      {/*Money order alert starts here*/}
      <RACModalCard
        isOpen={overpayMoneyord}
        maxWidth="xs"
        onClose={() => setoverpayMoneyord(false)}
        // eslint-disable-next-line react/no-children-prop
        children={Moneyorderalertpopup()}
      />
      {/*Money order alert ends here*/}
      {/*Money Order Information starts*/}
      <RACModalCard
        isOpen={addmoneyorder}
        maxWidth="xs"
        closeIcon={true}
        title="Money Order Information"
        onClose={() => setaddmoneyorder(false)}
        // eslint-disable-next-line react/no-children-prop
        children={MoneyOrderPopup()}
      />
      {/*Money Order Information ends*/}

      {/* Card tab whole popup starrts here */}

      {/* Swipe card starts here   */}
      <RACModalCard
        isOpen={Cardrequire.swipcard}
        maxWidth="xs"
        closeIcon={true}
        title="Swipe Card"
        onClose={() => setCardrequire({ ...Cardrequire, swipcard: false })}
        // eslint-disable-next-line react/no-children-prop
        children={swipeCardpopup()}
      />
      {/* Swipe card ends here */}

      {/*Edit Card information starts here*/}
      <RACModalCard
        isOpen={Cardrequire.editcrd}
        maxWidth="xs"
        title="Billing Address"
        onClose={() => close()}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={editCardPopup()}
      />
      {/*Edit Card information ends here*/}

      {/* Delete card popup of enrolled card starts here */}
      <RACModalCard
        isOpen={Cardrequire.APDeletePopup}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, APDeletePopup: false })}
        // eslint-disable-next-line react/no-children-prop
        children={DeletePopupForEnrolledCard()}
      />
      {/* Delete card popup of enrolled card ends here */}

      {/* delete popup starts here */}

      <RACModalCard
        isOpen={Cardrequire.delt}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, delt: false })}
        // eslint-disable-next-line react/no-children-prop
        children={DeletePopup()}
      />
      {/* delete popup end here */}

      {/* Transaction completed Modal Starts here */}
      <RACModalCard
        isOpen={Cardrequire.tran}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, tran: false })}
        // eslint-disable-next-line react/no-children-prop
        children={TransactionCompletePopup()}
      />

      <RACModalCard
        isOpen={Cardrequire.txt2pay}
        maxWidth="xs"
        onClose={() => setCardrequire({ ...Cardrequire, txt2pay: false })}
        // eslint-disable-next-line react/no-children-prop
        children={Txt2paypopup()}
      />

      {/*phone pay starts*/}

      <RACModalCard
        isOpen={Cardrequire.drpdown}
        maxWidth="xs"
        title="Phone Payment"
        closeIcon={true}
        onClose={() => closephonepopup()}
        // eslint-disable-next-line react/no-children-prop
        children={InphonePaymentOrigin()}
      />
      {/*phone-pay ends*/}

      {/* Footer components modal starts here */}

      {/* This popup is to Activate Club  */}
      {/* <Modal isOpen={ActivateClub} className="modal-dialog ">
        <div
          className="Terminatecls"
          id="terminateldw"
          data-bs-backdrop="static"
          data-testid="TerminateLDWTestId"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <div className="delt">
            <div className="modal-content">
              <div className="modal-header border-0">
                <label className="modal-title title popup-text">
                  Activate Club
                </label>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  data-testid="TerminateLDWclosebntId"
                  onClick={() => close()}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p className="popup-text mt-3">
                  Your regular payment with club will increase by
                  <span className="label-txt-style font-16-custom">
                    {ClubArr1}
                  </span>
                  .Do you want to activate club?
                </p>
              </div>
              <div className="col-md-12 p-3 text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-1 float-end"
                  data-testid="ActiveLDWContinueId"
                  onClick={() => YesActivatingClub()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-1 float-end"
                  data-testid="TerminateCancelbtn"
                  data-bs-dismiss="modal"
                  onClick={() => close()}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* This popup is to DeActivate Club  */}
      {/* <Modal isOpen={DeActivateClub} className="modal-dialog delt">
        <div
          className="Terminatecls"
          id="terminateldw"
          data-bs-backdrop="static"
          data-testid="TerminateLDWTestId"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <div className="delt">
            <div className="modal-content">
              <div className="modal-header border-0">
                <label className="modal-title title popup-text">
                  De-Activate Club
                </label>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  data-testid="TerminateLDWclosebntId"
                  onClick={() => close()}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <p className="popup-text mt-3">
                  By deactivating club, your regular payment will decrease by
                  <span className="label-txt-style font-16-custom">
                    {" "}
                    {ClubArr2}
                  </span>
                  . Do you want to Deactivate club?
                </p>
              </div>
              <div className="col-md-12 p-3 text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-1 float-end"
                  data-testid="ActiveLDWContinueId"
                  onClick={() => YesDeActivatingClub()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mx-1 float-end"
                  data-testid="TerminateCancelbtn"
                  data-bs-dismiss="modal"
                  onClick={() => close()}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* This popup is to Terminate LDw  */}

      <RACModalCard
        isOpen={TerminateLDW}
        maxWidth="xs"
        title="Terminate LDW"
        onClose={() => setTerminateLDW(false)}
        // eslint-disable-next-line react/no-children-prop
        children={TerminateLDWpopup()}
      />

      {/* This popup is to Activate LDW  */}
      <RACModalCard
        isOpen={ActiveLDW}
        maxWidth="xs"
        title="Activate LDW"
        onClose={() => setActiveLDW(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ActiveLDWpopup()}
      />

      <RACModalCard
        isOpen={Activepolicy}
        maxWidth="xs"
        title="Activate Policy"
        onClose={() => setActivepolicy(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ActivepolicypopupModal()}
      />

      {/* This popup is to DeActivate LDW  */}
      <RACModalCard
        isOpen={DeActivepolicy}
        maxWidth="xs"
        title="DeActivate Policy"
        onClose={() => setDeActivepolicy(false)}
        // eslint-disable-next-line react/no-children-prop
        children={DeActivepolicypopupModal()}
      />

      {/* footer components modal ends here */}
      {console.log(
        "---------->showAutoPayPopup before referencing the component",
        showAutoPayPopup
      )}
      {showAutoPayPopup === true &&
      (getCardService_state.cardResp !== undefined ||
        getCardService_state.length > 0) &&
      getpayment !== undefined ? (
        <Autopay
          PropsCustomerName={customerName} //customer name should be sent
          PropsCustomerPhoneNumber={getpayment.paymentInfo.customerInfo[0].phoneNumber.toString()} //phone number should be sent
          PropsCustomerId={customerId} //customer id should be sent
          setAPpopupcompleted={setAPpopupcompleted} //send text autopay completed is used for submit button triggeration
          GetCardResponse={getCardService_state} //get card response should be sent
          GetPaymentInfoResponse={getpayment} //getpaymentinfo response should be sent
          setResponseObject={setResponseObject}
          CheckedAgreementIds={agreementarraystate} // checked  agreement ids should be sent
          setIsClubSelectedForAutopay={setIsClubSelectedForAutopay} // This returns is the club is used to send whether the club is selected for the autopay
          IsClubSelectedForAutopay={IsClubSelectedForAutopay} ///this is the state which is used to trigger whether it is selected for a
        />
      ) : null}

      {secondLevelAuthenOn !== null ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setSecondLevelAuthenOn(null)}
          setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
          moduleName={secondLevelAuthenOn}
          CurrentRole={coWorkerRole}
        ></SecondFactor>
      ) : null}

      {/**
       * changes : Promo Free Days v2
       * Developer : Muzzammil shaik
       * Desc : to open pop calling Promo Free Days Component component
       */}
      {openPromoFreeDays ? (
        <PromoFreeDays
          // totalAmount={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Amount !== undefined ? checkedAgr[0].Amount : ''}
          // totalTax={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Taxamount !== undefined ? checkedAgr[0].Taxamount : ''}
          // currentDueDate={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].CurrentDueDate !== undefined ? checkedAgr[0].CurrentDueDate : ''}
          // nextDueDate ={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Nextduedate !== undefined ? checkedAgr[0].Nextduedate : ''}
          // agreementid= {adjustDueDateSelectedAgre!==undefined?adjustDueDateSelectedAgre:[]}
          adjustDueDateReq={checkedAgr !== undefined ? checkedAgr : []}
          ldwChangeArr={actLDWArr}
          policyChangeArr={actPolicyArr}
          // customerId={customerId !== undefined ? customerId : ''}
          isOpen={openPromoFreeDays}
          isClosed={(action: any) => setopenPromoFreeDays(action)}
          response={(res: any) => {
            editAdjustRes(res);
          }}
        />
      ) : null}
      {/**
       * changes : Adjust Due Date v2
       * Developer : Muzzammil shaik
       * Desc : to open pop calling adjust due date component
       */}
      {openAdjustDueDate ? (
        <AdjustDueDatePopup
          // totalAmount={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Amount !== undefined ? checkedAgr[0].Amount : ''}
          // totalTax={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Taxamount !== undefined ? checkedAgr[0].Taxamount : ''}
          // currentDueDate={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].CurrentDueDate !== undefined ? checkedAgr[0].CurrentDueDate : ''}
          // nextDueDate ={checkedAgr !== undefined && checkedAgr.length > 0 && checkedAgr[0].Nextduedate !== undefined ? checkedAgr[0].Nextduedate : ''}
          // agreementid= {adjustDueDateSelectedAgre!==undefined?adjustDueDateSelectedAgre:[]}
          adjustDueDateReq={checkedAgr !== undefined ? checkedAgr : []}
          ldwChangeArr={actLDWArr}
          defaultPaygridvalues={defaultPaygridvalues}
          taxExemption={taxExemption}
          customerInformation={customerInformation}
          policyChangeArr={actPolicyArr}
          // customerId={customerId !== undefined ? customerId : ''}
          isOpen={openAdjustDueDate}
          isClosed={(action: any) => setopenAdjustDueDate(action)}
          response={(res: any) => {
            editAdjustRes(res);
          }}
        />
      ) : null}
      {/**
       * changes : Edit Schedule v2
       * Developer : Muzzammil shaik
       * Desc : to open pop calling Edit Schedule Component
       */}
      {editSchedulePopUp ? (
        <EditSchedule
          // agreementid= {adjustDueDateSelectedAgre!==undefined?adjustDueDateSelectedAgre:[]}
          ldwChangeArr={actLDWArr}
          policyChangeArr={actPolicyArr}
          editScheduleReq={checkedAgr !== undefined ? checkedAgr : []}
          paymentSchedule={
            paymentEditScheduleDropDown != undefined
              ? paymentEditScheduleDropDown
              : []
          }
          // customerId={customerId !== undefined ? customerId : ''}
          isOpen={editSchedulePopUp}
          isClosed={(action: any) => seteditSchedulePopUp(action)}
          response={(response: any) => {
            editAdjustRes(response);
          }}
        />
      ) : null}
      <PopUpCarryWaive
        checkedAgr={checkedAgr}
        isOpen={popUpCarrywaive}
        response={(res: any) => {
          editAdjustRes(res);
        }}
        isClosed={(action: any) => setPopUpCarryWaive(action)}
      />

      {/**
       * changes : Accept EPO v2
       * Developer : Sid V
       * Desc : to open pop calling Accept and EPO Schedule Component
       */}
      {showAcceptEpoPopup ? (
        <AcceptEpo
          info={epoPopup}
          agreementChecked={checkedAgr}
          actualEpoAmount={
            checkedAgr[0] != undefined ? checkedAgr[0].actualEpoAmount : 0
          }
          actualPastDueRent={
            checkedAgr[0] != undefined ? checkedAgr[0].actualPastDueRent : 0
          }
          triggercloseEPOModal={acceptEpoCloseButton}
          triggerTotalOnclick={acceptEpoButton}
        />
      ) : null}
      {epoShedulePopup ? (
        <EpoSchedule
          agreementId={
            checkedAgr[0] != undefined ? checkedAgr[0].Agreementid : 0
          }
          coCustomerProps={customerId}
          newCoCustomer={newcustomer}
          epoScheduleCompletedFunction={() => acceptEpoCloseButton()}
          triggercloseEPOModal={acceptEpoCloseButton}
        />
      ) : null}

      {/*Enroll autopay popup starts here*/}

      {needCarryWaveAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setNeedCarryWaveAuth(false);
          }}
          setTwoFactorCompleted={() => {
            setNeedCarryWaveAuth(false);
            setPopUpCarryWaive(true);
          }}
          moduleName="Adjust Late Fee"
          CurrentRole={coWorkerRoleCarryWaive}
        ></SecondFactor>
      ) : null}

      <RACModalCard
        isOpen={openModal}
        closeIcon={true}
        onClose={onCloseSecondfacClick}
        maxWidth={"xs"}
        className={classes.racSecondFacPopup}
        title={""}
        //eslint-disable-next-line react/no-children-prop
        children={openModalFunc()}
      />

      {/*spinner part*/}

      <RACModalCard
        isOpen={load}
        maxWidth="xs"
        onClose={() => setload(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopup()}
      />
      {/* spinner part end here */}

      <RACModalCard
        isOpen={openSecondModal}
        closeIcon={true}
        onClose={okClick}
        maxWidth={"sm"}
        title={""}
        //eslint-disable-next-line react/no-children-prop
        children={openSecondModalFunc()}
      />

      <RACModalCard
        isOpen={openPinModal}
        className={classes.racSecondFacPopup}
        closeIcon={true}
        onClose={setChargeOffclose}
        maxWidth={"xs"}
        title={"Enter PIN to continue"}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalFunc()}
      />

      <RACModalCard
        isOpen={TranFail}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={unAvailable()}
      />
    </Grid>
  ) : (
    <NonInventorySales
      customerInfo={customerdata}
      isOpen={nonInventoryPopUp}
      currentPermissions={userdata}
      editItemId={
        EditNoninventoryData != undefined
          ? EditNoninventoryData[0] != undefined
            ? EditNoninventoryData[0].miscellaneousItemsNum
            : 0
          : 0
      }
      editItemInfo={
        EditNoninventoryData != undefined
          ? EditNoninventoryData[0] != undefined
            ? EditNoninventoryData[0].miscellaneousItemInfo
            : undefined
          : undefined
      }
      isClosed={(action: any) => setnonInventoryPopUp(action)}
      response={(response: any) => {
        nonInventoryOnClick(response);
      }}
    />
  );
};
export default COPaymentInformations;

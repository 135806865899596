/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable*/

/**
 * PS_TT_1 import statements
 */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Card,
  makeStyles,
  RACSelect,
  Box,
  RACButton,
  RACModalCard,
} from '@rentacenter/racstrap';
import TenderCard from './TenderCard';
import TenderCash from './TenderCash';
import { TenderCheckMoneyGrid as TenderCheckMo } from './TenderCheckMoneyGrid';
import {
  calculateRemainingAmountDue,
  calculateChangeAmount,
} from '../utils/amountCalculations';
import {
  AgreementDetails,
  Customer,
  CardDetailsInterface,
  TenderCardDetailsArray,
} from '../interface/paymentInfoInterface';
import {
  paymentOrigin,
  TaxResponseInterface,
  tenderDetialsInterface,
} from '../interface/tenderTypeInterface';
import { agreementContext } from '../context/PaymentInformationContext';

import CONSTANTS from '../constants/constant';
import { AgreementContextValue } from '../interface/contextInterface';
import { StoreAddressDetails } from '../interface/tenderCardInterface';
import {
  CoPrelimDetails,
  ShippingAddress,
  Items,
} from '../interface/paymentInfoInterface';

export default function TenderType() {
  const useClasses = makeStyles(() => ({
    navLink: {
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
      position: 'relative',
      fontFamily: 'OpenSans-semibold',
      color: '#000',
    },
    navLinkactive: {
      color: '#2179FE',
      borderBottom: '5px solid #2468FF !important',
      fontFamily: 'OpenSans-semibold',
      '&:before': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        left: '-5px',
        bottom: '-5px',
        borderTopLeftRadius: '5px',
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        right: '-5px',
        bottom: '-5px',
        borderTopRightRadius: '5px',
      },
    },
    dropdowntoggle: {
      display: 'inline-block',
      marginLeft: '0.255em',
      verticalAlign: '0.255em',
      content: '',
      borderTop: '0.3em solid',
      borderRight: '0.3em solid transparent',
      borderBottom: '0',
      borderLeft: '0.3em solid transparent',
    },
    dropdownitemcustom: {
      color: '#2468FF',
      fontFamily: 'OpenSans-bold',
      backgroundColor: 'white',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    popUp: {
      position: 'absolute',
      bottom: '100%',
      backgroundColor: 'white',
      width: '200px',
      padding: '15px',
      '& li': {
        listStyle: 'none',
      },
    },
    popUpHide: {
      display: 'none',
    },
    customMenuContainer: {
      border: '1px solid #2468FF',
    },
    paymentBtnHover: {
      '&:hover': {
        color: 'white',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
  }));
  const classes = takePaymentStyles();
  const navlinkClass = useClasses();
  const paymentOriginData = [
    { label: CONSTANTS.IN_STORE, value: CONSTANTS.IN_STORE_VALUE },
    { label: CONSTANTS.IN_PHONE, value: CONSTANTS.IN_PHONE_VALUE },
  ];

  /**
   * PS_TT_3 - Storing the Context
   */
  const agreementInfo = useContext(agreementContext);

  /**
   * PS_TT_4 - State variable creations
   */
  const [remainingamountdue, setRemainingamountdue] = useState<string>(
    agreementInfo.customerInfo.amountUsed.remainingAmountDue
  );
  const [disableTenderType, setDisableTenderType] = useState<paymentOrigin>({
    cash: false,
    card: false,
    checkMo: false,
  });

  const [currentTab, setCurrentTab] = useState<string>(
    CONSTANTS.ORIGIN_CASH_TAB
  );
  const [phoneConveniencePopup, setPhoneConveniencePopup] =
    useState<boolean>(false);
  const [paymentOriginDisable, setPaymentOriginDisable] =
    useState<boolean>(false);

  const [rerenderReaminingAmount, setRerenderReaminingAmount] =
    useState<boolean>(true);

  const customerId = agreementInfo.customerInfo.customerId;
  const firstName = agreementInfo.customerInfo.customerDetails[0].firstName;
  const lastName = agreementInfo.customerInfo.customerDetails[0].lastName;

  /**
   * PS_TCD_38
   * This gets the store details from the session storage
   * PS_TCD_41
   */
  const JsonsessionStoreDetails: string | null = window.sessionStorage.getItem(
    CONSTANTS.STORE_DETAILS
  );
  let sessionStoreDetails: StoreAddressDetails;

  try {
    if (JsonsessionStoreDetails) {
      sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
    }
  } catch (e) {
    console.error('Error parsing store details:', e);
  }

  useEffect(() => {
    const eaFlag =
      agreementInfo?.agreementDetails?.[0]?.coItemDetails?.[0]?.eaFlag ??
      CONSTANTS.EMPTY_STRING;
    const shippingAddress: ShippingAddress = {
      country: 'USA',
      city: sessionStoreDetails?.city,
      addressLine1: sessionStoreDetails?.addressLine1,
      addressLine2: sessionStoreDetails?.addressLine2,
      postalCode: sessionStoreDetails?.zip,
      phoneNumber: sessionStoreDetails?.workPhoneNumber,
      verified: 'true',
      state: sessionStoreDetails?.state,
    };
    const items: Items = {
      storeNumber: sessionStorage.getItem('storeNumber'),
      quantity: '1',
      fullfillmentType: 'shipping',
      deliveryStartTime: '',
      deliveryDate: '',
      modelNumber:
        agreementInfo?.agreementDetails?.[0]?.coItemDetails?.[0]?.modelNumber ??
        CONSTANTS.EMPTY_STRING,
      supplierNumber:
        agreementInfo?.agreementDetails?.[0]?.coItemDetails?.[0]
          ?.supplierNumber ?? CONSTANTS.EMPTY_STRING,
    };
    const customerContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    const coItemDetails: CoPrelimDetails = {
      provideEAFlag: eaFlag,
      providePOAddressData: shippingAddress,
      providePlaceOrderItemData: items,
      provideAgrNumberPO: agreementInfo.agreementDetails[0].agreementNumber,
    };
    customerContextClone.coPrelimDetails = coItemDetails;

    agreementInfo.setCustomerInfo(customerContextClone);
  }, [agreementInfo.customerOrderPayment, agreementInfo.agreementDetails]);

  /**
   * PS_TT_5
   * This useEffect sets the remaining amount due, change,
   * coaUsed, webLeadUsed, carryRent, ipSuspense
   * PS_TT_15
   */
  useEffect(() => {
    const remainingAmount = calculateRemainingAmountDue(agreementInfo);

    const changeAmount = calculateChangeAmount(agreementInfo);
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    if (
      agreementInfo.multiStoreSelected ||
      agreementInfo.paymentOrigin == CONSTANTS.IN_PHONE
    )
      agreementContextClone.change.type = CONSTANTS.CAPITAL_CREDIT;
    else if (
      !(
        agreementInfo.multiStoreSelected ||
        agreementInfo.paymentOrigin == CONSTANTS.IN_PHONE
      ) &&
      agreementInfo.customerInfo.change.type !== CONSTANTS.CAPITAL_SUSPENSE &&
      agreementInfo.customerInfo.change.type !== CONSTANTS.CAPITAL_CREDIT
    )
      agreementContextClone.change.type = CONSTANTS.CHANGE;
    agreementContextClone.amountUsed.remainingAmountDue = Number(
      remainingAmount.remainingAmount.toFixed(2)
    );
    agreementContextClone.change.amount = changeAmount.change;
    agreementContextClone.amountUsed.coa = remainingAmount.coaUsed; // used Coa
    agreementContextClone.amountUsed.coaUsed = Number(remainingAmount.coa); // full Coa
    agreementContextClone.amountUsed.webLeadUsed = remainingAmount.webLeadUsed;
    agreementContextClone.amountUsed.carryRent = remainingAmount.carryRent;
    agreementContextClone.amountUsed.ipSuspenseUsed =
      remainingAmount.ipSuspense;
    if(agreementInfo.customerOrderPayment === CONSTANTS.IS_CO_INITIAL){
      agreementInfo.agreementDetails[0].suspenseDetails.suspAdd = (remainingAmount.suspenseAdd).toFixed(2)
    }
    agreementInfo.setCustomerInfo(agreementContextClone);
  }, [
    agreementInfo.agreementDetails,
    rerenderReaminingAmount,
    agreementInfo.isSetToDefault,
    agreementInfo.renderContext,
  ]);

  useEffect(() => {
    paymentOriginChange(CONSTANTS.IN_STORE_VALUE);
  }, [agreementInfo.isSetToDefault]);

  useEffect(() => {
    agreementInfo.customerInfo.amountUsed.carryRent = agreementInfo.carryRent;
  }, [agreementInfo]);

  /**
   * PS_TT_16
   * This useEffect clear the amount that is entered in the tender type
   * PS_TT_27
   */
  useEffect(() => {
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    if (disableTenderType.cash) {
      agreementContextClone.amountUsed.tenderDetails.cash.amount =
        CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    }
    if (disableTenderType.card) {
      agreementContextClone.amountUsed.tenderDetails.card = [];
    }
    if (disableTenderType.checkMo) {
      agreementContextClone.amountUsed.tenderDetails.checkDetails = [];
      agreementContextClone.amountUsed.tenderDetails.moneyOrderDetails = [];
    }
    agreementInfo.setCustomerInfo(agreementContextClone);
    setRerenderReaminingAmount(!rerenderReaminingAmount);
  }, [
    disableTenderType.cash,
    disableTenderType.card,
    disableTenderType.checkMo,
  ]);

  /**
   * PS_TT_28
   * This useEffect handle the accept payment button disable/enable changes
   * PS_TT_41
   */
  useEffect(() => {
    const tenderDetails = (agreementInfo: AgreementContextValue) => {
      const tenderDetails: tenderDetialsInterface[] = [];
      const cardDetails: CardDetailsInterface[] = [];

      agreementInfo?.customerInfo?.amountUsed?.cardDetails.map(
        (val: CardDetailsInterface) => {
          cardDetails.push({
            cardLastFour: val.cardLastFour,
            amount: val.amount,
          });
        }
      );

      agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.card.map(
        (val: TenderCardDetailsArray) => {
          tenderDetails.push({
            cardLastFour: val.cardLastFour,
            amount: val.amount,
          });
        }
      );

      function sortByTenderType(
        tenderDetails: tenderDetialsInterface | CardDetailsInterface,
        cardDetails: tenderDetialsInterface | CardDetailsInterface
      ) {
        return tenderDetails?.cardLastFour?.localeCompare(
          cardDetails.cardLastFour
        );
      }

      // Sort arrays
      tenderDetails.sort(sortByTenderType);
      cardDetails.sort(sortByTenderType);
      return JSON.stringify(tenderDetails) === JSON.stringify(cardDetails);
    };

    const totalTenderAmount = tenderDetails(agreementInfo);
    const reminingAmount =
      agreementInfo.customerInfo.amountUsed.remainingAmountDue;

    if (
      reminingAmount <= CONSTANTS.ZREO_TWO_DECIMAL_STRING &&
      totalTenderAmount
    ) {
      agreementInfo.setPaymentDisable((prev) => ({
        ...prev,
        tenderPayment: false,
      }));
    } else {
      agreementInfo.setPaymentDisable((prev) => ({
        ...prev,
        tenderPayment: true,
      }));
    }
  }, [
    agreementInfo.customerInfo.amountUsed,
    rerenderReaminingAmount,
    agreementInfo.renderContext,
  ]);

  /**
   * PS_TT_42
   * This useEffect handels the paymentOrigin and
   * PS_TT_51
   */
  useEffect(() => {
    if (
      agreementInfo.customerOrderPayment == CONSTANTS.IS_CO_PRELIM ||
      agreementInfo.typeOfCard == CONSTANTS.CHARGE_CARD
    ) {
      setPaymentOriginDisable(true);
    }

    const isAddSuspense = agreementInfo.agreementDetails.filter(
      (agreement: AgreementDetails) => 
        agreement.selected &&
          agreement.suspenseDetails.suspAdd !==
            CONSTANTS.ZREO_TWO_DECIMAL_STRING
    );
    const isAgreementCCCB = agreementInfo.agreementDetails.filter(
      (agreementDetails: AgreementDetails) =>
        agreementDetails.agreementType == CONSTANTS.CREDIT_CARD_CHARGE_BACK &&
        agreementDetails.selected
    );

    if (isAddSuspense.length !== CONSTANTS.ZERO_NUMBER) {
      paymentOriginChange(CONSTANTS.IN_STORE_VALUE);
      setPaymentOriginDisable(true);
    }

    if (agreementInfo.multiStoreSelected) {
      setCurrentTab(CONSTANTS.ORIGIN_CARD_TAB);
      setDisableTenderType({
        cash: true,
        card: false,
        checkMo: true,
      });
    } else {
      setDisableTenderType({
        cash: false,
        card: false,
        checkMo: false,
      });
    }

    if (isAgreementCCCB.length > CONSTANTS.ZERO_NUMBER) {
      setPaymentOriginDisable(true);
      if (currentTab == CONSTANTS.ORIGIN_CARD_TAB) {
        setCurrentTab(CONSTANTS.ORIGIN_CASH_TAB);
      }
      if (agreementInfo.multiStoreSelected) {
        setCurrentTab(CONSTANTS.EMPTY_STRING);
        setDisableTenderType({
          cash: true,
          card: true,
          checkMo: true,
        });
      } else {
        setDisableTenderType({
          cash: false,
          card: true,
          checkMo: false,
        });
      }
    }

    if (
      isAgreementCCCB.length == CONSTANTS.ZERO_NUMBER &&
      isAddSuspense.length == CONSTANTS.ZERO_NUMBER &&
      agreementInfo.customerOrderPayment !== CONSTANTS.IS_CO_PRELIM &&
      agreementInfo.typeOfCard !== CONSTANTS.CHARGE_CARD
    ) {
      setPaymentOriginDisable(false);
    }

    if (agreementInfo.coWorkerDetails.cwsUser) {
      setDisableTenderType({
        cash: true,
        card: false,
        checkMo: false,
      });
      if(currentTab == CONSTANTS.ORIGIN_CASH_TAB){
        setCurrentTab(CONSTANTS.ORIGIN_CARD_TAB);
      }
    }

    if (agreementInfo.paymentOrigin == CONSTANTS.IN_PHONE_VALUE) {
      setCurrentTab(CONSTANTS.ORIGIN_CARD_TAB);
      setDisableTenderType({
        cash: true,
        card: false,
        checkMo: true,
      });
    }

    if (
      isAgreementCCCB.length > CONSTANTS.ZERO_NUMBER &&
      currentTab == CONSTANTS.ORIGIN_CARD_TAB &&
      agreementInfo.paymentOrigin == CONSTANTS.IN_PHONE_VALUE
    ) {
      paymentOriginChange(CONSTANTS.IN_STORE_VALUE);
    }
  }, [
    agreementInfo.multiStoreSelected,
    agreementInfo.paymentOrigin,
    agreementInfo.agreementDetails,
    agreementInfo.typeOfCard,
  ]);

  /**
   * PS_TT_52
   * This useEffect update the remaining amount in local
   * PS_TT_53
   */
  useEffect(() => {
    setRemainingamountdue(
      agreementInfo.customerInfo.amountUsed.remainingAmountDue
    );
  }, [agreementInfo.customerInfo.amountUsed.remainingAmountDue]);

  /**
   * PS_TT_66
   * This function handles the payment origin dropdown change
   * PS_TT_71
   */
  const paymentOriginChange = (value: string) => {
    const eventValue = value;
    if (eventValue == CONSTANTS.IN_PHONE_VALUE) {
      setPhoneConveniencePopup(true);
    }
    if (eventValue == CONSTANTS.IN_STORE_VALUE) {
      const agreementContextClone = JSON.parse(
        JSON.stringify(agreementInfo.customerInfo)
      );
      agreementContextClone.amountUsed.convenienceFee =
        CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      agreementInfo.setPaymentOrigin(CONSTANTS.IN_STORE_VALUE);
      setDisableTenderType({
        cash: false,
        card: false,
        checkMo: false,
      });
      agreementInfo.setCustomerInfo(agreementContextClone);
      setRerenderReaminingAmount(!rerenderReaminingAmount);
    }
  };

  /**
   * PS_TT-54
   * This function changes the 0 to 0.00
   * PS_TT-56
   */
  const fixedChange = useCallback((value: string) => {
    if (
      value == undefined ||
      value == null ||
      parseFloat(value) < CONSTANTS.ZERO_DECIMAL_NUMBER
    ) {
      return CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    } else {
      return String(parseFloat(value).toFixed(2));
    }
  }, []);

  /**
   * PS_TT_59
   * this function will change the tender type based on the event
   * PS_TT_64
   */
  const TenderTypeOptinchange = (e: React.MouseEvent<HTMLLIElement>) => {
    const target = e.target as HTMLInputElement;
    const selectedOption = target.id;

    if (
      (selectedOption === CONSTANTS.ORIGIN_CASH_TAB &&
        agreementInfo.paymentOrigin == CONSTANTS.IN_STORE_VALUE) ||
      selectedOption === CONSTANTS.ORIGIN_CARD_TAB ||
      (selectedOption === CONSTANTS.ORIGIN_CHECK_MONEYORDER &&
        agreementInfo.paymentOrigin == CONSTANTS.IN_STORE_VALUE)
    )
      setCurrentTab(selectedOption);
  };

  /**
   * PS_TT_74
   * This calculates and renders the convience fee popup
   * PS_TT_76
   * @returns html part for convience fee popup
   */

  function convienencePopup() {
    const convinenceFee: TaxResponseInterface[] =
      agreementInfo?.customerInfo?.convinenceFee;

    const convinenceFeeWithTax = convinenceFee?.[0]?.tax;

    const totalDueAmount = agreementInfo.totalDue;

    const calculatedAmount = (
      totalDueAmount +
      (parseFloat(convinenceFee?.[0]?.amount) ??
        CONSTANTS.ZERO_TWO_DECIMAL_NUMBER) +
      parseFloat(convinenceFeeWithTax)
    ).toFixed(2);

    const displayAmount = isNaN(Number(calculatedAmount))
      ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
      : calculatedAmount;

    return (
      <Grid
        item
        id="phonepay"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
        data-testid="InphonePopupid"
      >
        <Box
          component="span"
          className={`${classes.textleft} ${classes.mb1} ${classes.px3} ${classes.floatLeft}`}
        >
          A convenience fee of{' '}
          <Typography
            variant="caption"
            className={`${classes.RAClabeltxtstyle} ${classes.bold}`}
          >
            $
            {isNaN(Number(convinenceFee?.[0]?.amount))
              ? CONSTANTS.ZREO_TWO_DECIMAL_STRING
              : Number(convinenceFee?.[0]?.amount).toFixed(2)}{' '}
            plus tax {' '}
          </Typography>
          when applicable
          <Box
            component="span"
            className={`${classes.ps1} ${classes.textleft}`}
          >
            will be added.
          </Box>
        </Box>
        <List className={`${classes.mt3} ${classes.mx3} ${classes.floatLeft}`}>
          <ListItem className={classes.listgroupitem}>
            <Grid className={classes.raccolauto}>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                Current Total:{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                ${fixedChange(totalDueAmount.toString())}
              </Typography>
            </Grid>
          </ListItem>
          <ListItem className={classes.listgroupitem}>
            <Grid className={classes.raccolauto}>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.semibold}`}
              >
                New Total:{' '}
              </Typography>
              <Typography
                variant="caption"
                className={`${classes.font14} ${classes.bold}`}
              >
                $
                {displayAmount}
              </Typography>
            </Grid>
          </ListItem>
        </List>
        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.mt3} ${classes.floatRight}`}
        >
          <RACButton
            className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
            variant="outlined"
            color="primary"
            data-bs-dismiss="modal"
            data-testid="NobtnId"
            onClick={() => setPhoneConveniencePopup(false)}
          >
            No
          </RACButton>
          <RACButton
            className={`${classes.paymentSmallBtn1}`}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            onClick={() => addConvniencefee()}
            data-bs-toggle="modal"
            data-testid="YesBtn"
            data-bs-target="#autopayconsent"
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  /**
   * PS_TT_78
   * This function handles the convenience fee
   * PS_TT_84
   */
  const addConvniencefee = () => {
    agreementInfo.setPaymentOrigin(CONSTANTS.IN_PHONE_VALUE);
    setCurrentTab(CONSTANTS.ORIGIN_CARD_TAB);
    setDisableTenderType({
      cash: true,
      card: false,
      checkMo: true,
    });
    setPhoneConveniencePopup(false);
    const agreementContextClone: Customer = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );

    const convinenceFee = agreementInfo.customerInfo.convinenceFee;
    const convinenceFeeWithTax: string = convinenceFee[0].tax;
    agreementContextClone.amountUsed.tenderDetails.cash.amount =
      CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    agreementContextClone.amountUsed.convenienceFee =
      parseFloat(convinenceFee[0].amount) + parseFloat(convinenceFeeWithTax);
    agreementInfo.setCustomerInfo(agreementContextClone);
    agreementInfo.setTypeOfCard(CONSTANTS.EMPTY_STRING);
    setRerenderReaminingAmount(!rerenderReaminingAmount);
  };

  /**
   * HTML for tenderType
   */
  return (
    <>
      <Grid
        item
        className={`${classes.raccollg8}  ${classes.raccolmd12} ${classes.floatLeft} ${classes.mb3} ${classes.pe1}`}
      >
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}
        >
          <Typography
            variant="h6"
            className={`${classes.floatLeft} ${classes.mt2} ${classes.raccollg8} ${classes.title}`}
          >
            Tender Type
          </Typography>
          <Grid item className={`${classes.floatRight} ${classes.raccollg4}`}>
            <label
              htmlFor="ally-payday"
              className={`${classes.formLabel} ${classes.floatLeft} ${classes.raccollg6} ${classes.mt2}`}
            >
              Payment Origin
            </label>
            <div className={`${classes.raccollg6} ${classes.floatLeft}`}>
              <RACSelect
                data-testid="PaymentOriginId"
                isDisabled={paymentOriginDisable}
                options={paymentOriginData}
                defaultValue={agreementInfo.paymentOrigin}
                onChange={(event) => {
                  paymentOriginChange(event.target.value);
                }}
              ></RACSelect>
            </div>
          </Grid>
        </Grid>
        <Card
          className={`${classes.card} ${classes.p3} ${classes.pt0} ${classes.marginbottom}`}
        >
          <Grid item className={classes.tabborder}>
            <List
              className={`${classes.RACnav} ${classes.racpadtab} ${classes.py0}`}
              id="RACTab"
              role="tablist"
            >
              <ListItem
                key={Math.random()}
                className={
                  currentTab == CONSTANTS.ORIGIN_CASH_TAB
                    ? `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
                    : `${navlinkClass.navLink} ${classes.p3}`
                }
                id="Cash-tab"
                data-bs-toggle="tab"
                data-bs-target="#Cash"
                role="tab"
                aria-controls="Cash"
                aria-selected="true"
                onClick={(e) => {
                  disableTenderType.cash ? null : TenderTypeOptinchange(e);
                }}
                data-testid="Cashtender"
                disabled={disableTenderType.cash}
              >
                Cash
              </ListItem>
              <ListItem
                className={
                  currentTab == CONSTANTS.ORIGIN_CARD_TAB
                    ? `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
                    : `${navlinkClass.navLink} ${classes.p3}`
                }
                id="Card-tab"
                data-bs-toggle="tab"
                data-bs-target="#Card"
                role="tab"
                aria-controls="Card"
                aria-selected="false"
                data-testid="Cardtender"
                disabled={disableTenderType.card}
                onClick={(e) => {
                  disableTenderType.card ? null : TenderTypeOptinchange(e);
                }}
              >
                Card
              </ListItem>

              <ListItem
                className={
                  currentTab == CONSTANTS.ORIGIN_CHECK_MONEYORDER
                    ? `${navlinkClass.navLink} ${classes.p3} ${navlinkClass.navLinkactive}`
                    : `${navlinkClass.navLink} ${classes.p3}`
                }
                id="CheckMoney-Order-tab"
                data-bs-toggle="tab"
                data-bs-target="#CheckMoney-Order"
                role="tab"
                aria-controls="CheckMoney-Order"
                aria-selected="false"
                onClick={(e) => {
                  disableTenderType.checkMo ? null : TenderTypeOptinchange(e);
                }}
                data-testid="Checktender"
                disabled={disableTenderType.checkMo}
              >
                Check/Money Order
              </ListItem>
            </List>
            <Grid className={`${classes.floatRight} ${classes.mt2}`}>
              <Typography
                variant="body2"
                className={`${classes.RAClabeltxtstyle} ${classes.floatLeft} ${classes.semibold}`}
              >
                Remaining Amount Due :
              </Typography>
              <Typography
                variant="body2"
                className={`${classes.RACvaluetxtstyle} ${classes.bold} ${classes.floatLeft}`}
              >
                ${fixedChange(remainingamountdue)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            className={`${classes.w100} ${classes.floatLeft}`}
            id="RACTabContent"
          >
            {currentTab == CONSTANTS.ORIGIN_CASH_TAB && (
              <TenderCash
                setRerenderReaminingAmount={() =>
                  setRerenderReaminingAmount(!rerenderReaminingAmount)
                }
                rerenderReaminingAmount={rerenderReaminingAmount}
              />
            )}
            {currentTab == CONSTANTS.ORIGIN_CARD_TAB && (
              <TenderCard
                setCurrentTab = {()=>setCurrentTab(CONSTANTS.ORIGIN_CASH_TAB)}
                setRerenderReaminingAmount={() =>
                  setRerenderReaminingAmount(!rerenderReaminingAmount)
                }
                rerenderReaminingAmount={rerenderReaminingAmount}
              />
            )}
            {currentTab == CONSTANTS.ORIGIN_CHECK_MONEYORDER && (
              <TenderCheckMo
                setCurrentTab = {()=>setCurrentTab(CONSTANTS.ORIGIN_CASH_TAB)}
                setRerenderReaminingAmount={() =>
                  setRerenderReaminingAmount(!rerenderReaminingAmount)
                }
                rerenderReaminingAmount={rerenderReaminingAmount}
              />
            )}
          </Grid>
        </Card>
      </Grid>
      <RACModalCard
        isOpen={phoneConveniencePopup}
        maxWidth="xs"
        title="Phone Payment"
        closeIcon={true}
        onClose={() => setPhoneConveniencePopup(false)}
      >
        {convienencePopup()}
      </RACModalCard>
    </>
  );
}

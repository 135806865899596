/* eslint-disable */
import { getpaymentHistory } from '../../api/user';
import { CustomerPayHistoryRecp } from '../interface/apiInterface';
import { PaymentHistoryResponse } from '../interface/contextInterface';
import { ClubActivityInfo, ClubInfo, ClubPaymentHistoryGridInfo, CustomerInfoPaymentHis, PaymentHistoryInterface, Rootobject, constructAgreementPayloadDeciderInterface, uniqueAgreementIDDropDown } from '../interface/paymentHistoryInterface';

export const getpayHis = async (paymentHistoryResponse:PaymentHistoryResponse) => {
    const uniqueAgreementID:uniqueAgreementIDDropDown[]= [];

    const uniqueAgreementID1 = [
      ...new Set(
        paymentHistoryResponse?.payHistoryAgr?.value?.paymentHistoryGridInfo.map(
          (data) => data.agreementNumber
        )
      ),
    ];
    const uniqueAgrID = [
      ...new Set(
        paymentHistoryResponse?.payHistoryAgr?.value?.paymentHistoryGridInfo.map(
          (data) => data.agreementId
        )
      ),
    ]; // To assign ID only for Agr
    console.log("paymenthistory",paymentHistoryResponse);
    const clubActivityInfo: ClubActivityInfo[] =
    paymentHistoryResponse.payHistoryClub?.value?.clubActivityInfo; // Only for club
    const ClubGridInfoResp: ClubPaymentHistoryGridInfo[] =
    paymentHistoryResponse.payHistoryClub?.value?.clubHistoryGridInfo; // Only for club
    let customerInfoobj1:CustomerInfoPaymentHis= paymentHistoryResponse?.payHistoryAgr?.value?.customerInfo; // Customer information
    // : CustomerInfoHis | undefined
    let clubInfoobj: ClubInfo = paymentHistoryResponse.payHistoryClub?.value?.clubInfo; // Only for club
    if (uniqueAgreementID1.length == 0) {
      uniqueAgreementID.push({
        label: "No record",
        value: "123456",
      });
    } else {
      uniqueAgreementID.push({
        label: "All",
        value: "0",
      });
      let indexcount = 1;
      uniqueAgreementID1.map((o1: string, index) => {
        uniqueAgreementID.push({
          label: o1.toString(),
          value: indexcount.toString(),
          ID: uniqueAgrID[index],
        });
        indexcount++;
      });
    } // Need only once

    let paymentHisGrid:  PaymentHistoryInterface[][] = [[]];
    uniqueAgreementID1.map((o1, index) => {
      paymentHisGrid[index] =
      paymentHistoryResponse?.payHistoryAgr?.value?.paymentHistoryGridInfo.filter(
          (o2) => o2.agreementNumber === o1
        );
    }); // Forms the array of arrays which gets seperated by agreements
    
    paymentHisGrid.unshift(paymentHistoryResponse?.payHistoryRecp?.value?.customerPaymentHistory); // Appends all the receipts in the 0th position
    console.log('paymentHisGrid plops', paymentHistoryResponse);

    const constructAgreementPayloadDecider: constructAgreementPayloadDeciderInterface[] = [];

    uniqueAgreementID1.forEach((el:string) => {
      constructAgreementPayloadDecider.push({
        agreementNumber: el,
        offset: 0,
        isFurtherDataThere: true
      })
    });
    // setAgreementPayloadDecider(constructAgreementPayloadDecider)

    return {
        ClubGridInfoResp:ClubGridInfoResp,
        clubActivityInfo:clubActivityInfo,
        uniqueAgreementID:uniqueAgreementID,
        commoninformation:customerInfoobj1,
        clubinformation:clubInfoobj,
        agreementRentPaid:paymentHistoryResponse?.payHistoryAgr?.value?.paymentHistoryGridInfo,
        paymentHisGrid:paymentHisGrid,
        agreementPayloadDecider:constructAgreementPayloadDecider

    }


  };

  export const agrPaymentHistory = async (agreementOffSetParam: number,customerparamid:string) => {
    let customerId = customerparamid;
    let getpayhisstate1: Rootobject;
    getpayhisstate1 = await getpaymentHistory(customerId, "agr", agreementOffSetParam);
    return getpayhisstate1;
  };
  export const recpaymentHistory = async (receiptOffSetParam: number,customerparamid:string) => {
    let customerId = customerparamid;
    let selectAllReceipts: Rootobject;
    selectAllReceipts = await getpaymentHistory(customerId, "recp", receiptOffSetParam);
    return selectAllReceipts;
  };
  export const clubPaymentHistory = async (clubOffSetParam: number,customerparamid:string) => {
    let customerId = customerparamid;
    let getClubReceipts: Rootobject;
    getClubReceipts = await getpaymentHistory(customerId, "club", clubOffSetParam);
    return getClubReceipts;
  };


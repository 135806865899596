/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import {
  Grid,
  RACModalCard,
  Typography,
  CircularProgress,
} from "@rentacenter/racstrap";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { GeneralStateContext } from "../../context/GeneralProvider";
export default function ReversePayLoader() {
  const classes = reversePaymentStyle();
  const { loadingModal } = useContext(GeneralStateContext);
  const documentLoadingRenderer = () => {
    return (
      <>
        <Grid className={`${classes.textCenter}`}>
          <CircularProgress />
          <Typography
            className={`${classes.textCenter} ${classes.bold} ${classes.mt2} `}
          >
            Reversing Your Payments
          </Typography>
          <Typography className={`${classes.gridLabel} ${classes.mt2}`}>
            Please Wait...
          </Typography>
        </Grid>
      </>
    );
  }; // This method will be rendering the loader till the reversal is done.
  return (
    <RACModalCard
      isOpen={loadingModal.reversePay}
      maxWidth="xs"
      borderRadius={"25px !important"}
      //eslint-disable-next-line react/no-children-prop
      children={documentLoadingRenderer()}
    />
  );
}

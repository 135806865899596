/* eslint-disable prettier/prettier */
import React from "react";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { Grid } from "@rentacenter/racstrap";
import BreadCrumbs from "./BreadCrumbs";
import HeaderComponent from "./HeaderComponent";
import GridComponent from "./Grid";
import Footer from "./Footer";
import ServerError from "./ServerError";
import BadRequest from "./BadRequest";
import DocumentLoading from "./DocumentLoading";
import ReversePayLoader from "./ReversePayLoader";
import CheckReversalComponent from "./CheckReversalComponent";
import CardReversalComponent from "./CardReversalComponent";
import GeneralProvider from "../../context/GeneralProvider";
import ViewReverseReceipt from "./ViewReverseReceipt";
import ReverseReceipt from "./ReverseReceipt";
import ReverseException from "./ReverseException";
import PostReversalException from "./PostReversalException";
import TransferException from "./TransferException";

export default function ParentComponent() {
  const classes = reversePaymentStyle();
  return (
    <Grid className={`${classes.containerSpacing}`}>
      <GeneralProvider>
        <BreadCrumbs />
        <HeaderComponent />
        <ReverseException />
        <GridComponent />
        <CheckReversalComponent />
        <CardReversalComponent />
        <Footer />
        <ServerError />
        <BadRequest />
        <TransferException />
        <PostReversalException />
        <ReversePayLoader />
        <DocumentLoading />
        <ReverseReceipt />
        <ViewReverseReceipt />
      </GeneralProvider>
    </Grid>
  );
} // PC-05 This component will be the parent component which has all the child components in it.

/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useContext, useEffect } from "react";
import { Card, Grid, Typography, Box } from "@rentacenter/racstrap";
import { useHistory } from "react-router-dom";
import { ParamType } from "../../constants/constants";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { useParams } from "react-router-dom";
import { GetCustomerByIdInfo } from "../../api/user";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";

export default function CustomerDetails() {
  const history = useHistory();
  const { customerId } = useParams<ParamType>();
  const { customerInformation } = useContext(GeneralStateContext);
  const classes = reversePaymentStyle();
  const storeNumber: any = String(window.sessionStorage.getItem("storeNumber"));
  const { setCustomerInformation} = useContext(GeneralStateDispatchContext);

  useEffect(() => {
    (async () => {
      const customerAPIResult = await GetCustomerByIdInfo(
        customerId,
        storeNumber
      );
      console.log("customerAPIResult", customerAPIResult);
      if (customerAPIResult.status === 200) {
        setCustomerInformation(customerAPIResult.data.GetCustomer.value);
      } else if (
        customerAPIResult.status === 500 ||
        customerAPIResult.status === 400
      ) {
        setCustomerInformation({
          firstName: "-",
          lastName: "",
          customerId: "-",
          customerRoutes: undefined,
        });
      }

     
    })();
  }, []); // PC-07 SQ-1 This method will be obtaining the customer's information.

  return (
    <Card
      className={`${classes.card} ${classes.w100} ${classes.floatLeft}  ${classes.alertwidget} ${classes.mb1p}`}
    >
      <Grid
        item
        className={`${classes.w100} ${classes.px0} ${classes.floatLeft} `}
      >
        <Grid container className={`${classes.px2} ${classes.h65}`}>
          <Grid
            className={`${classes.raccollg5} ${classes.paymentAlignCenter} ${classes.floatLeft}`}
          >
            <Grid item className={`${classes.raccollg6}  ${classes.floatLeft}`}>
              <Typography
                component="span"
                className={`${classes.titletxtstyle} ${classes.bold} ${classes.pe4} ${classes.floatLeft}`}
              >
                Reverse Payment
              </Typography>
            </Grid>
            <Grid
              item
              className={`${classes.raccollg3CustmerName} ${classes.floatLeft}`}
            >
              <Box
                component="span"
                className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
              >
                Customer Name
              </Box>
              {customerInformation === undefined ? (
                <Skeleton
                  count={1}
                  baseColor="#FFFFFF"
                  highlightColor="#e7e7e7"
                />
              ) : (
                <a
                  style={{ cursor: "pointer" }}
                  className={`${classes.labelbluetxtstyle} ${classes.w100} ${classes.floatLeft}`}
                  onClick={() =>
                    history.push({
                      pathname: `/customer/update/${customerId}/customer`,
                    })
                  }
                >
                  {customerInformation.firstName} {customerInformation.lastName}
                </a>
              )}
            </Grid>
            <Grid
              className={`${classes.floatLeft} ${classes.raccollg3} ${classes.px1}`}
            >
              <Box
                component="span"
                className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
              >
                Customer ID
              </Box>
              <Box
                component="span"
                className={`${classes.gridLabel} ${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
              >
                {customerInformation === undefined ? (
                  <Skeleton
                    count={1}
                    baseColor="#FFFFFF"
                    highlightColor="#e7e7e7"
                  />
                ) : (
                  customerInformation.customerId
                )}
              </Box>
            </Grid>
            <Grid
              className={`${classes.floatLeft} ${classes.raccollg3} ${classes.px1}`}
            >
              <Box
                component="span"
                className={`${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
              >
                Route
              </Box>
              <Box
                component="span"
                className={`${classes.gridLabel} ${classes.labeltxtstyle} ${classes.w100} ${classes.floatLeft}`}
              >
                {customerInformation === undefined ? (
                  <Skeleton
                    count={1}
                    baseColor="#FFFFFF"
                    highlightColor="#e7e7e7"
                  />
                ) : customerInformation?.customerRoutes == undefined ? (
                  "-"
                ) : (
                  customerInformation.customerRoutes[0].routeCodeDesc
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

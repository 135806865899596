/* eslint-disable prettier/prettier */
export enum AppRoute {
  Index = '/payment1',
  Paymentgrid = '/payment1/paymentgrid',
  PaymentInformation = '/payment1/paymentinformation/:customerId/:agreementId',
  SearchResult = '/payment1/paymentsearch',
  ReceiptPage = '/payment1/receiptInfo/:receiptId',
  ReversePayment = '/payment1/reversepayment/:customerId',
  COPrelim = '/payment1/customerorder/preliminary/:customerId/:agreementId',
  COInitial = '/payment1/customerorder/initial/:customerId/:agreementId',
  //Racstrap
  SearchResultRacstrap = '/payment1/racstrap/paymentsearch',
  PaymentInformationRacstrap = '/payment1/racstrap/paymentinformation/:customerId/:agreementId',
  RentalItemSale='/payment1/rentalitemsale',
 


  AccountManagement = '/am/customer/:customerId',

  //RefactoredRoutes
  RentalItemSaleRefactor='/payment1/rentalitemsale/:customerId/:agreementId?',
  NonInventorySale='/payment1/cashSale/:customerId/:agreementId?',
}

/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";
import { useHistory } from "react-router";
import { useParams, useLocation } from "react-router-dom";
import { ParamType } from "../../constants/constants";

export default function ViewReverseReceipt() {
  const classes = reversePaymentStyle();
  const history = useHistory();
  const search = useLocation().search;
  const agreementID = new URLSearchParams(search).get('agreementId');
  const { customerId } = useParams<ParamType>();
  const { openViewReceipt, openVoidRemainder, isVoidFlow, reverseReceiptID } =
    useContext(GeneralStateContext);
  const { setOpenViewReceipt, setOpenReverseReceipt, setOpenVoidRemainder } =
    useContext(GeneralStateDispatchContext);
    
  const closeReceipt = () => {
    setOpenViewReceipt(false);
    if (isVoidFlow) {
      setOpenVoidRemainder(true);
    } else {
      if(agreementID) {
        history.push({
          pathname: `/agreement/info/details/${customerId}/${agreementID}`,
        });
      } else {
        history.push({
          pathname: `/payment1/paymentinformation/${customerId}/0`,
        });
      }
    }
  }; // This method will be closing the receipt list and redirect to payment information page.
  const ViewReceiptRenderer = () => {
    return (
      <Grid item id="delivery-receipt">
        <Grid item md={12} className={`${classes.py4} ${classes.textcenter}`}>
          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Reverse Receipt - {reverseReceiptID}:
            <a
              onClick={() => setOpenReverseReceipt(true)}
              style={{
                textDecoration: "underline",
                color: "#2179FE",
                cursor: "pointer",
              }}
              className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
          <Grid className={`${classes.w100} ${classes.textcenter}`}>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              onClick={closeReceipt}
              data-testid="ReceiptCancel"
              style={{ marginTop: "20px" }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }; // This method will be rendering the list of receipts that can be viewed and printed.

  const voidRemainderRenderer = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <Typography className={`${classes.bold} ${classes.titletxtstyle}`}>
            The initial payment has been successfully refunded to the customer.
            Please make sure to VOID the agreement.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenVoidRemainder(false);
              history.push({
                pathname: `/dashboard/homepage`,
              });
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <RACModalCard
        isOpen={openViewReceipt}
        maxWidth="xs"
        title={"Print"}
        onClose={closeReceipt}
        borderRadius={"25px !important"}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={ViewReceiptRenderer()}
      />
      <RACModalCard
        isOpen={openVoidRemainder}
        maxWidth="sm"
        borderRadius={"25px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={voidRemainderRenderer()}
      />
    </>
  );
}

/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */







import React from 'react';
import { RACAccordion, makeStyles } from '@rentacenter/racstrap';
import { CustomerInformationDetails } from './CustomerInformationDetails';

const useStyles = makeStyles(() => ({
  customerInformationRoot: {
    width: 'calc(100% + 1.5rem)',
    // left: '-0.75rem',
    margin: '0 !important',
  },
  accordionSummaryRoot: {
    width: '10px',
    position: 'absolute',
    right: 10,
    top: '-45px',
  },
  accordionSummaryContent: {
    margin: '0 !important',
  },
}));

export const CustomerInformation = (props:any) => {
  const classes = useStyles();
  console.log(props.configDetails, "theprops in the cusomter information page");
  return (
    <>
      <RACAccordion
        details={<CustomerInformationDetails
          agreementAddressDetails={props.agreementAddressDetails}
          configDetails ={props.configDetails.configDetails}
        />}
        accordionProps={{
          classes: {
            root: classes.customerInformationRoot,
          },
        }}
        summaryProps={{
          classes: {
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
          },
        }}
      />
    </>
  );
};

/* eslint-disable */
import React, { useEffect } from "react";
import { Grid, Typography, RACModalCard, RACButton } from "@rentacenter/racstrap";
import CONSTANTS from '../constants/constant';
import { CustomerdataContext } from "../../context/customer/customerdata";
import { agreementContext } from "../context/PaymentInformationContext";
import { StoreExceptionPayload } from "../interface/acceptPaymentInterface";

interface storeExceptionInterFace {
    refCode: string;
    identifierId?: string | number;
    storeExceptionCancelled: () => void;
    storeExceptionCompleted: (response: updatedPayload[] | StoreExceptionPayload[]) => void
    identifierType?: string
    isopen?: boolean;
    setStoreExceptionEnable: (response: boolean) => void;
    exceptionDescscription?: string | null
}

interface storeExceptionType {
    abbreviation: string;
    description: string;
}

interface updatedPayload {
    identifierType: string | undefined;
    storeNumber: string | null;
    exceptionTypeRefCode: string | undefined;
    exceptionText: string;
}[]
export default function StoreException(props: storeExceptionInterFace) {
//Need to modify this
    let { StoreExceptionPayload, setStoreExceptionPayload } = React.useContext(agreementContext)
    const { refCode, identifierId, storeExceptionCancelled, storeExceptionCompleted, identifierType, exceptionDescscription } = props

    const [storeExceptionPopup, setStoreExceptionPopup] = React.useState(true)
    let [exceptionText, SetexceptionText] = React.useState('')
    let [remainingLength, setremainingLength] = React.useState(255)
    let [exceptionDesc, setexceptionDesc] = React.useState('')

    useEffect(() => {
        const exceptionDescription = CONSTANTS.STORE_EXCEPTION_TYPE.filter((obj: storeExceptionType) => obj.abbreviation == refCode)
        exceptionDesc = exceptionDescription[0].description
        setexceptionDesc(exceptionDesc)
    }, [props.isopen])

    const exceptionTextEnterFn = (inputText: string) => {
        exceptionText = inputText
        SetexceptionText(inputText)

        remainingLength = 255 - exceptionText.length
        setremainingLength(remainingLength)


    }
    const setStoreExceptionCancelClick = async () => {


        if (props.isopen == true || props.isopen == false) {
            props.setStoreExceptionEnable(false)
        }
        else {
            setStoreExceptionPopup(false)

        }
        storeExceptionCancelled()
        exceptionText = ''
        SetexceptionText(exceptionText)
        exceptionDesc = ''
        setexceptionDesc(exceptionDesc)
        remainingLength = 255
        setremainingLength(remainingLength)
    }
    const setStoreExceptionCompletedClick = async () => {


        if (props.isopen == true || props.isopen == false) {
            props.setStoreExceptionEnable(false)
        }
        else {
            setStoreExceptionPopup(false)

        } const payload = [

            {
                identifierType: identifierType,
                storeNumber: window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER),
                exceptionTypeRefCode: refCode,
                exceptionText: exceptionText
            }


        ]
        const updatedPayload = [...StoreExceptionPayload, ...payload]
        StoreExceptionPayload = updatedPayload
        setStoreExceptionPayload(StoreExceptionPayload)


        storeExceptionCompleted(updatedPayload)
        exceptionText = ''
        SetexceptionText(exceptionText)
        exceptionDesc = ''
        setexceptionDesc(exceptionDesc)
        remainingLength = 255
        setremainingLength(remainingLength)

    }
    const StoreExceptionPopup = () => {
        return (
            <>
                <Typography style={{ marginTop: '10px' }} data-testid='storeExceptionId'>
                    Explanation <span style={{ color: 'red' }}> *</span>
                </Typography>
                <textarea
                    value={exceptionText}
                    data-testid='areatextId'
                    rows={8}
                    cols={73}
maxLength={255}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        exceptionTextEnterFn(e.target.value.trimStart())

                    }}
                    style={{ resize: 'none', borderRadius: '5px', marginTop: '5px', padding: '5px' }}
                >

                </textarea>
                <span style={{ marginTop: '15px', fontFamily: 'OpenSans-semibold', color: '#00000085' }}>{remainingLength}/255 characters left.</span>
                <Grid style={{ float: 'right', marginTop: '34px' }}>
                    <RACButton
                        style={{ marginRight: '10px' }}

                        color='default'
                        variant='outlined'
                        disabled={false}
                        data-testid="exception_cancelId"
                        onClick={() => setStoreExceptionCancelClick()}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        style={{ marginRight: '35px' }}
                        color='primary'
                        variant='contained'
                        data-testid='exception_saveId'
                        disabled={exceptionText?.length > 0 ? false : true}
                        onClick={() => { setStoreExceptionCompletedClick() }}
                    >
                        Save
                    </RACButton>
                </Grid>


            </>
        )
    }

    return (
        <Grid>
            <RACModalCard
                title={"Enter Explanation For " + (exceptionDescscription ? exceptionDescscription : exceptionDesc) + " Exception"}
                isOpen={props.isopen == true || props.isopen == false ? props.isopen : storeExceptionPopup}
                closeIcon={true}
                maxWidth="sm"
                onClose={() => setStoreExceptionCancelClick()}
                borderRadius={"25px !important"}
                children={StoreExceptionPopup()}
            />



        </Grid>
    )
}

/* eslint-disable */
import moment from 'moment';

export function validateForExchange(isExchangeEnabled: any,agreementStatus: any, closeDate: any, currentDueDate: any, agreementOpenDate: any, isEppAgreement?: any, agreementType?: any, exchangePeriod?: any, returnPeriod?: any) {
    // console.log(isExchangeEnabled, ' 1 ', agreementStatus,' 2 ', closeDate,' 3 ', currentDueDate, ' 4 ', agreementOpenDate, ' 5 ', isEppAgreement, 'Response');
    if(!isExchangeEnabled || isEppAgreement) {
        return false;
    }
    const today = moment().format('YYYY-MM-DD');
    let sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(7, 'days');
    if (returnPeriod) {
      sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(returnPeriod, 'days');
    }
    let sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD');
    if (exchangePeriod) {
      sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(exchangePeriod, 'months').format('YYYY-MM-DD');
    }
      if ((agreementStatus?.toString().toLowerCase() == 'active' && agreementType == 'Rental Agreement (RTO)') || ((closeDate && (moment(closeDate).format('YYYY-MM-DD') >= sevenDaysAgo.format('YYYY-MM-DD')) && agreementType == 'Rental Agreement (RTO)'))) {
        if (closeDate && (moment(closeDate).format('YYYY-MM-DD') >= sevenDaysAgo.format('YYYY-MM-DD'))) {
          return true;
        }
      if (currentDueDate && moment(currentDueDate).format('YYYY-MM-DD') >= today) {
        // const sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD');
        if (sixMonthsLater && today <= sixMonthsLater) {
          return true; // Agreement is less than 6 months old
        }
      }
    }
    return false; // Agreement does not meet the criteria
  }
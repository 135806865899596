/*eslint-disable */
/*eslint-disable  sonarjs/cognitive-complexity */
/**PS_NO_NS_48
 * Required hooks and racstrap elements are imported.
 */
import { Grid, RACSelect, RACTextbox, TextareaAutosize, Typography } from '@rentacenter/racstrap';
import { nonInventorySaleStyles } from '../../../stylesJS/nonInventorySaleStyles';
import { getNonInvSalesOptions, getTax } from '../../../api/user';
import React,{ useEffect, useState,useContext } from 'react';
import  {agreementContext}  from '../../context/PaymentInformationContext';
import CONSTANTS from '../../constants/constant';
import { DropDownArray } from '../../interface/commonInterface';
import { formatedTaxPayload, NonInventorySaleDropDownResponse, XboxMemberShipGiftCardProps } from '../../interface/nonInventorySaleInterface';
export function XboxMemberShipGiftCard(props:XboxMemberShipGiftCardProps){
  /**
   * PS_NO_NS_48
   * Required state variable are declared and destructuring of context and
   * props done here
   */
    const {nonInventorySaleType,setblurLoaderActive,xboxData, setXboxData}=props;
    const {nonInventorySaleOptions,setNonInventorySaleOptions, customerInfo}=useContext(agreementContext);
    const classes = nonInventorySaleStyles();
    const { taxPayload } = useContext(agreementContext);

    const [loadType,setloadType]= useState<boolean>(false);

   /**
    * PS_NO_NS_49 - PS_NO_NS_53
    * The useEffect is triggered on pageload it makes the API and
    * form the drop-down value and update to the context.
    */
    useEffect(()=>{
        if(!nonInventorySaleOptions.xBoxProviderValues.length)
            // if(nonInventorySaleType=="XboxGiftCard"||nonInventorySaleType=="Membership")
            {
                (async ()=>{
                    // setloadType(true);
                     const typeDropDownResp:NonInventorySaleDropDownResponse= await getNonInvSalesOptions({salesType:nonInventorySaleType,provider:CONSTANTS.EMPTY_STRING});
           if(typeDropDownResp.status==200)
            {
                // setloadType(false);
                const formAvailableSalesType:DropDownArray[] = [{ label: CONSTANTS.SELECT, value: CONSTANTS.SELECT }];
                 typeDropDownResp.data.map((el)=>{
                    formAvailableSalesType.push({
                      label: el.description,
                      value: el.carrierId,
                    });
                 })
                setNonInventorySaleOptions({...nonInventorySaleOptions,xBoxProviderValues:formAvailableSalesType});
            }

              }
        )()
    }

    },[nonInventorySaleType])


    /**
     * PS_NO_NS_54 - PS_NO_NS_57
     * The handleXboxChange() method is invoked when the dropdown is selected.
     * @param event
     * @param name
     */
    const handleXboxChange = async(event:React.ChangeEvent<HTMLSelectElement>, name:string) =>{
    const ratePlanUpdated:DropDownArray[] = [{ label: CONSTANTS.SELECT, value: CONSTANTS.SELECT }];
    switch(name){
    case CONSTANTS.PROVIDER:
        if(event.target.value!=CONSTANTS.SELECT){
          
          const choosenProviderLabel=nonInventorySaleOptions.xBoxProviderValues.find((x)=>x.value==event.target.value);
          setXboxData({...xboxData,nonInvSaleType:nonInventorySaleType,choosenProvider:event.target.value,choosenProviderLabel:choosenProviderLabel?.label??CONSTANTS.EMPTY_STRING,disableRatePlan:false});
          
            setloadType(true)
        const ratePlanOptionsResponse:NonInventorySaleDropDownResponse= await getNonInvSalesOptions({salesType:nonInventorySaleType,provider:event.target.value});
        setloadType(false);
        if(ratePlanOptionsResponse.status==200)
            {
                ratePlanOptionsResponse.data.map((x)=>{
                    ratePlanUpdated.push({
                        label:x.description,
                        value:x?.price??CONSTANTS.EMPTY_STRING
                    })
                })
            }
            
            // setdisableRatePlan(false);
            setNonInventorySaleOptions({...nonInventorySaleOptions,xBoxRatePlanValues:ratePlanUpdated})

        }
        else{
            // setdisableRatePlan(true);
            setXboxData({...xboxData,nonInvSaleType:nonInventorySaleType,choosenProvider:CONSTANTS.SELECT,choosenProviderLabel:CONSTANTS.EMPTY_STRING,disableRatePlan:true})

        }
    break;
    case CONSTANTS.PLAN:
        if(event.target.value!=CONSTANTS.SELECT){
            const finalSalesPrice = event.target.value;
            let obtainedTax = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
            setblurLoaderActive(true);
            const formatedTaxPayload : formatedTaxPayload = taxPayload;
            formatedTaxPayload.storeInputs = [{
              taxInputs: [{
                amount: Number(finalSalesPrice.replace(CONSTANTS.REPLACE_COMMA, CONSTANTS.EMPTY_STRING)).toFixed(2),
                taxInputId: xboxData.choosenProvider,
                itemCategory:CONSTANTS.CATEGORYMAPPER[nonInventorySaleType]
              }],
              storeNumber: String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
            }
            ]
            const taxResponse = await getTax(formatedTaxPayload);
            console.log('taxResponse in blur acima', taxResponse);
            setblurLoaderActive(false);
            if(taxResponse.status == 200) {
                obtainedTax = taxResponse?.data?.taxResponse[0]?.taxOutputs[0]?.taxAmount;
              }
              const choosenProviderLabel=nonInventorySaleOptions.xBoxRatePlanValues.find((x)=>x.value==event.target.value)
              setXboxData({
                ...xboxData,
                choosenRatePlan: finalSalesPrice,
                choosenRateLabel: choosenProviderLabel?.label??CONSTANTS.EMPTY_STRING,
                salesPrice: Number(event.target.value).toFixed(2),
                salesTax: String(obtainedTax),
                totalSales: (Number(finalSalesPrice) + Number(obtainedTax)).toFixed(2)
              });
        }
        else{
            setXboxData({
                ...xboxData,
                choosenRatePlan: CONSTANTS.SELECT,
                choosenRateLabel: CONSTANTS.EMPTY_STRING,
                salesPrice:CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                salesTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                totalSales: CONSTANTS.ZREO_TWO_DECIMAL_STRING
              });
        }
        break;
}


    }
    /**
     * PS_NO_NS_48
     * The return part renders the entire UI of the x-box gift card.
     */
    return(<>
                    <Grid className={classes.w100}>
                        <Typography variant="h6">
                          {nonInventorySaleType == CONSTANTS.XBOX_GIFT_CARD_TYPE
                            ? CONSTANTS.XBOX_GIFT_CARD_SCREEN
                            : CONSTANTS.XBOX_GIFT_MEMBERSHIP_SCREEN}
                        </Typography>
                        <Grid className={classes.w100}>
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACSelect
                              inputLabelClassName={`${classes.marginBottom} `}
                              inputLabel="Provider"
                              name="type"
                              defaultValue={xboxData.choosenProvider}
                              data-testid="providerId"
                              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                handleXboxChange(event, "Provider")
                              }
                              required
                            //   options={xBoxProviderValues}
                            options={nonInventorySaleOptions?.xBoxProviderValues}
                              loading={
                                nonInventorySaleOptions?.xBoxProviderValues?.length > 1 ? false : true
                              }
                            />
                          </Grid>

                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACSelect
                              inputLabelClassName={`${classes.marginBottom} `}
                              inputLabel="Rate Plan"
                              name="type"
                              defaultValue={xboxData.choosenRatePlan}
                            options={nonInventorySaleOptions.xBoxRatePlanValues}
                              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                handleXboxChange(event, "Plan")
                              }
                              isDisabled={xboxData.disableRatePlan}
                              loading={loadType}
                              data-testid="ratePlanId"
                            />
                          </Grid>

                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACTextbox
                              inputLabelClassname={classes.marginBottom}
                              type="phoneno"
                              name="phoneNumber"
                              disabled={true}
                              value={
                                customerInfo.customerDetails[0]?.firstName
                              }
                              maxlength={14}
                              inputlabel="First Name"
                            />
                          </Grid>
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACTextbox
                              inputLabelClassname={classes.marginBottom}
                              type="phoneno"
                              name="phoneNumber"
                              disabled={true}
                              value={
                                customerInfo.customerDetails[0]?.lastName
                              }
                              maxlength={14}
                              inputlabel="Last Name"
                            />
                          </Grid>
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACTextbox
                              inputLabelClassname={classes.marginBottom}
                              type="phoneno"
                              name="phoneNumber"
                              disabled={true}
                              value={customerInfo.customerDetails[0].phoneNumber}
                              maxlength={14}
                              inputlabel="ZIP Code"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className={classes.w100}>
                        <Grid className={classes.w100}>
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACTextbox
                              className={`${classes.custDigit} ${classes.cashtxtbox}`}
                              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                              inputLabelClassname={classes.marginBottom}
                              type="number"
                              name="salestax"
                              value={xboxData.salesPrice}
                              disabled={true}
                              isCurrency={true}
                              maxlength={10}
                              inputlabel="Sales Price"
                            />
                          </Grid>
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACTextbox
                              className={`${classes.custDigit} ${classes.cashtxtbox}`}
                              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                              inputLabelClassname={classes.marginBottom}
                              type="number"
                              name="salestax"
                              value={xboxData.salesTax}
                              disabled={true}
                              isCurrency={true}
                              maxlength={10}
                              inputlabel="Sales Tax"
                            />
                          </Grid>
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            <RACTextbox
                              className={`${classes.custDigit} ${classes.cashtxtbox}`}
                              dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                              inputLabelClassname={classes.marginBottom}
                              type="number"
                              name="salestax"
                              value={xboxData.totalSales}
                              disabled={true}
                              isCurrency={true}
                              maxlength={10}
                              inputlabel="Total Sales"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
    </>)

}
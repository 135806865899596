/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */


/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
import React,{ useState ,useContext } from "react";
import { useEffect } from "react";
import moment from 'moment'
import { PayHistory } from "../../stylesJS/PayHistory";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { AdjustDueDate, calculatePaymentModule } from '../../api/user';
/* eslint-disable no-debugger */
import {
  RACTableCell,
  RACTable,
  RACTableRow,
  Grid,
  RACButton,
  RACModalCard,
  RACSelect,
  Modal,
  CircularProgress,
  Typography,
} from "@rentacenter/racstrap";
import AdjustDueDatecalci from "./AdjustDueDateCalci";
import { getTax } from "../../api/user";
import calculationModulePayloadFormation from "./commonModulePayloadFormation";
import { userTraceContextData } from "../../context/UserTraceContext";
import { EDIT_SCHEDULE_AGREEMENT_TYPE } from "../../constants/constants";

export default function EditSchedule(props: any) {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;

    const [loadSpinner,setloadSpinner]=useState<boolean>(false)//used to load the page with loader untill getting the 200 res from the from the front end lambda
    const classes = takePaymentStyles();//for css
    const Payclasses = PayHistory();//for css
    const [calciRes,setcalciRes]=useState<any>([])//used t
    const [schArray,setschArray]=useState<any>()//used t
    const [rangeval,setrangeval]=useState<any>()//used t
    const [rangeDisabe,setrangeDisabe]=useState<boolean>(true)
    const [rangeValue,setrangeValue]=useState<any>("Select")
    const [selectedSchedule,setselectedSchedule]=useState<any>()
    const [currentObject,setcurrentObject]=useState<any>([])
    const [loadSchedule, setLoadSchedule] = useState<boolean>(false);
    const [editScheduleRequest, setEditScheduleRequest] = useState<any>();
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    debugger;
        const [newObject,setnewObject]=useState<any>(
          []
      )
      const [saveObject,setsaveObject]=useState<any>([])
      //it is a array of new column grid which is by default load the same values as the current grid and we can set this after getting the response from the lambda
      
  const editScheduleOption = [
    { label: "Monthly", value: "Monthly" },
    { label: "Weekly", value: "Weekly" },
    { label: "Bi-Weekly",value:"Bi-Weekly"},
    { label: "Semi-Monthly",value:"Semi-Monthly"}
  ];//drop down values
  const dropdowndatainfo :any= props.paymentSchedule
const dropdowndata:any ={schedule:dropdowndatainfo.length==0?[]:dropdowndatainfo[0].referenceDetails}
const Addsuffix = (value) => {

 

  const covertvalue = parseInt(value);

  if (covertvalue < 1000) {

    const FirstCheck = covertvalue % 10;

    const SecondCheck = covertvalue % 100;

    if (FirstCheck == 1 && SecondCheck != 11) {

      return covertvalue + 'st';

    }

    if (FirstCheck == 2 && SecondCheck != 12) {

      return covertvalue + 'nd';

    }

    if (FirstCheck == 3 && SecondCheck != 13) {

      return covertvalue + 'rd';

    }

    return covertvalue + 'th';

  } else {

    return value;

  }

};
// let Rangeval:any =[]
// let SCHArray:any=[]
let defaultOption:any;
const [selectOption,setselectOption]=useState<any>();//Drop down defalut value and set the value of dropdown by onchange 

useEffect(() => {
  console.log(
    "props.throwLoadingController",
    props.throwLoadingController,
    props.editScheduleReq
  );
  setEditScheduleRequest(props.editScheduleReq);
  if (props.throwLoadingController === true) {
    setLoadSchedule(true);
    console.log("tax data from take pay", props.throwLoadingController);
  } else {
    setLoadSchedule(false);
  }
}, [props.throwLoadingController, props.editScheduleReq]);

  useEffect(() => {
    if(dropdowndata.schedule.length>0){
      const RangeRawValueArray = dropdowndata.schedule.filter(

        (word) => word.description === 'Semi-Monthly' && word.referenceCode !== 'SEMI'
      
      );
      
      const SortRangeRawValueArray = RangeRawValueArray.sort(
      
        (a, b) => parseInt(a.displaySeq) - parseInt(b.displaySeq)
      
      );
      
      console.log('SortRangeRawValueArray', SortRangeRawValueArray);
      
     const Rangeval:any = SortRangeRawValueArray.map((ele: any) => {
      // debugger;
        const value = ele.referenceCode;
      
        const tempval = value.replace(/SEMI/g, '');
      
        const tempArr = tempval.split('-');
      
        const finalrange = Addsuffix(tempArr[0]) + ' ' + Addsuffix(tempArr[1]);
      
        return { label:finalrange,value: ele.referenceCode  };
      
      });
      const UniqSchedulevalues = dropdowndata.schedule.filter(
      
        (value, index, self) =>
      
          index === self.findIndex((t) => t.description === value.description)
      
      );
       const SCHArray:any = UniqSchedulevalues.map((ele: any) => {
      
        return {label: ele.description , value: ele.referenceCode };
      
      });
      debugger;
       defaultOption =SCHArray.filter((x)=>x.label==String(props.editScheduleReq[0].Schedule))
      setselectOption(defaultOption[0].value)
      setschArray(SCHArray)
      setrangeval([{label: "--select--" , value: "Select" },...Rangeval])
    }
   
    if((props.editScheduleReq[0].Schedule)=="Semi-Monthly"){
      setrangeDisabe(false)
      // setrangeval([{label: "--select--" , value: "Select" },...Rangeval])
      setrangeValue("Select")
      
    }
   
    
   
   
    
  
    console.log("I am getting edit schedule useEffect");

  },[]);
  useEffect(() => {
    if(schArray!=undefined){
      let newSchedule
      if(props.editScheduleReq[0].editSchedule!=""&&props.editScheduleReq[0].editSchedule!=null){
         newSchedule= [{value:props.editScheduleReq[0].editSchedule}]
      }else{
        newSchedule=[{value:props.editScheduleReq[0].agreementRateDetails.scheduleRefCode}]
        
      }
     
      const req:any = {target:{value:newSchedule[0].value}}
      changeSchedule(req,"1")

    }
    
   
  },schArray);

  // debugger;
  //ItemInfoContent to load the grid values for both current and new grids
  const itemInfoContent=(ref:string)=>{
    let mapArray:any=props.editScheduleReq //Default it should be req array
    if(ref=="Current"){
      mapArray=currentObject //While current grid loading setting mapArray to req array
    }else if(ref=="New"){
      mapArray=newObject//While new grid loading setting mapArray to req array by default and seeting this according to the onchange of the dropdown
    }
    //return the two grids at with this one return by calling the function two times
     return (mapArray).map((value:any , index:any)=>{
        return(<RACTableRow
            key={"dynamicgridrow"}
            data-testid={"dynamicgridrow"}
            className={classes.hiddenRow}
          >
             {/* {ref=="Current"? */}
             <RACTableCell
              // className={classes.textright}
            >
              {value.agreementNumber}  
            </RACTableCell>
            {/* :null} */}
              
              
              <RACTableCell
                // className={classes.textright}
              >
                {value.Schedule}  
              </RACTableCell>
              {/* <RACTableCell
                // className={classes.textright}
              >
                {value.currentDueDate}  
              </RACTableCell> */}
              <RACTableCell
                // className={classes.textright}
              >
                {value.nextDueDate}  
              </RACTableCell>
              <RACTableCell
                // className={classes.textright}
              >
               {"$"+(Number(value.actualAgreementRate)).toFixed(2)}
              </RACTableCell>
              <RACTableCell
                className={classes.textcenter}
              >
                {"$"+(Number(value.actualLdwRate)).toFixed(2)}  
              </RACTableCell>
              <RACTableCell
                // className={classes.textright}
              >
                {"$"+(Number(value.actualPolicyRate)).toFixed(2)}  
              </RACTableCell>
              <RACTableCell
                // className={classes.textright}
              >
                {"$"+(Number(value.actualTotalTax)).toFixed(2)}  
              </RACTableCell>
              <RACTableCell
                // className={classes.textright}
              >
                {"$"+(Number(value.actualTotalAmount)).toFixed(2)}  
              </RACTableCell>
          </RACTableRow>
          );

    })
  }
  const ItemInfoHead = (ref:any) => (
    <>
     {/* {ref=="Current"? */}
     <RACTableCell >Agreement#</RACTableCell>
    {/* //  :""} */}
      <RACTableCell >Schedule</RACTableCell>
      {/* <RACTableCell >Current Due Date</RACTableCell> */}
      <RACTableCell >Next Due Date</RACTableCell>
      <RACTableCell >Rate</RACTableCell>
      <RACTableCell >Opt.Services</RACTableCell>
      <RACTableCell >Policy</RACTableCell>
      <RACTableCell >Tax</RACTableCell>
      <RACTableCell >Total</RACTableCell>

    </>
  );

  const saveEditScheduleRedirect=async()=>{
    if(props.configFlags.commonModuleFlag==1){
      saveEditScheduleCalcPaymentModule()
    }else{
      saveEditSchedule()
    }
  }

  const saveEditScheduleCalcPaymentModule=async()=>{

    setLoadingSave(true);
    for (let i = 0; i < props.editScheduleReq.length; i++) {
      props.editScheduleReq[i].editSchedule=''
      props.editScheduleReq[i].isEditScheduleValue=selectedSchedule
      const agreeType = calciRes.filter(
        (x) => String(x.agreementId) === String(props.editScheduleReq[i].Agreementid)
      );
      if(agreeType[0]?.nextDueDate=="SAC"&&agreeType[0]?.nextDueDate=="PIF"&&agreeType[0]?.nextDueDate=="EPO"){
        props.editScheduleReq[i].isAdjustDueDateValue=agreeType[i].nextDueDate
      }
    }
    let commonModulePayload = await calculationModulePayloadFormation( {
      customerId:props.customerId,
      storeNumber:props.storeNumber,
      checkedAgr:props.editScheduleReq})
    let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
    commonModuleServiceRes=commonModuleServiceRes.data
    setLoadingSave(false);
    const newSchedule :any= schArray.filter(
      (x) => x.value == selectOption
    );
    if(calciRes.length>0){
      
      
    for(let i=0;i<calciRes?.length;i++){
      let nextduedate
      
      props.editScheduleReq[i].isEditScheduleValue=undefined
      const agreeType = props.editScheduleReq.filter(
        (x) => String(x.Agreementid) === String(calciRes[i].agreementId)
      );
          let commonModuleAgrRes :any =[]
        if(props.editScheduleReq[i].Agreementtype!='Club'){
          commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
            (x) =>
              String(x.agreementId) ===
              String(props.editScheduleReq[i].Agreementid)
          );
        }else{
          commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.clubPaymentDetails.filter(
            (x) =>
              String(x.clubMembershipNumber) ===
              String(props.editScheduleReq[i].AgreementNumber)
          );
        }
            if(commonModuleAgrRes[0].nextDueDate!="SAC"&&commonModuleAgrRes[0].nextDueDate!="PIF"&&commonModuleAgrRes[0].nextDueDate!="EPO"&&commonModuleAgrRes[0].nextDueDate!=null&&commonModuleAgrRes[0].nextDueDate!=''){
              nextduedate = moment(commonModuleAgrRes[0].nextDueDate).format('MM/DD/YYYY');
          }else{
            nextduedate=commonModuleAgrRes[0].isFinalPayment==undefined?commonModuleAgrRes[0].nextDueDate:["INAGMEPO","INEPO"].includes(commonModuleAgrRes[0].isFinalPayment)? "EPO": commonModuleAgrRes[0]?.isFinalPayment=="INAGMSAC"?"SAC":commonModuleAgrRes[0]?.isFinalPayment
          }
          let ldw=commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory == "LDW"||x.itemCategory == "LTLDW")
          let policyAmount =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='RACTIREPAY')
          let carryLateFee =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='CLFEEPAY')
          let lateFee = commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='LTRPAYFEE')
          let carryRentUsed = commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='CRPAY')
    

      
        props.editScheduleReq[i].Amount=Number(
          (Number(commonModuleAgrRes[0].totalAmount)+Number(commonModuleAgrRes[0].totalTax)).toFixed(2)
        );
        props.editScheduleReq[i].Schedule=newSchedule[0].label,
        props.editScheduleReq[i].Taxamount= Number(commonModuleAgrRes[0].totalTax),
        props.editScheduleReq[i].Nextduedate =  nextduedate
        props.editScheduleReq[i].policyAmount= policyAmount?.length>0?Number(policyAmount[0].amount):0
        props.editScheduleReq[i].editSchedule=selectedSchedule
        props.editScheduleReq[i].ldwTaxAmount=ldw?.length>0?Number(ldw[0].tax):0
        props.editScheduleReq[i].agrLdwAmount=ldw?.length>0?Number(ldw[0].amount):0
        props.editScheduleReq[i].policyTaxAmount=policyAmount?.length>0?Number(policyAmount[0].tax):0
        props.editScheduleReq[i].agreementRate=commonModuleAgrRes?.length>0?Number(commonModuleAgrRes[0].scheduleRate):0
        props.editScheduleReq[i].Agreementtype=agreeType[0].Agreementtype=="Club"?"Club":EDIT_SCHEDULE_AGREEMENT_TYPE.includes(commonModuleAgrRes[0].agreementType) &&commonModuleAgrRes[0].isNoteAgreement=='Y'?commonModuleAgrRes[0].agreementType:calciRes[i].agreementType
        props.editScheduleReq[i].rpayTaxAmount=commonModuleAgrRes?.length>0?Number(commonModuleAgrRes[0].totalTax):0
      

    }
    
    }
    
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"EditSchedule"
    }
    setUserTraceData([...userTraceData,traceObj])
    props.response({resArray:props.editScheduleReq,adjustEditflag:0})
    
  }
  /**
   * EditSchedule is used to send the response to the takepayment while onclick of save
   */
  const saveEditSchedule=()=>{
    
   
    
      newObject
      const newSchedule :any= schArray.filter(
        (x) => x.value == selectOption
      );
      if(calciRes.length>0){
        
        
      for(let i=0;i<calciRes?.length;i++){
        let nextduedate
        let requiredTaxData;
        let taxEngineLDWTax: any = 0;
        let taxEnginePolicyTax = 0;
        let taxEngineRPayTax = 0;
        let taxEngineLateTax = 0;
        let taxEngineCarryRentTax = 0;
        let taxEngineClubPayTax = 0;
        if(calciRes[i].nextDueDate!="SAC"&&calciRes[i].nextDueDate!="PIF"&&calciRes[i].nextDueDate!="EPO"){
           nextduedate = moment(calciRes[i].nextDueDate).format('MM/DD/YYYY');
        }else{
          nextduedate=calciRes[i].nextDueDate
        }
        const agreeType = props.editScheduleReq.filter(
          (x) => String(x.Agreementid) === String(calciRes[i].agreementId)
        );

        if (agreeType[0].taxEngineData !== null&&Number(props.optimizedPayment)==1) {
          requiredTaxData = agreeType[0].taxEngineData.filter((el) => {
            return el.taxOutputId.includes(
              `${agreeType[0].Agreementid} - ${selectedSchedule}`
            );
          });
          const FilterOnlyRpay = requiredTaxData.filter(
            (el) => el.itemCategory == "RPAY"
          );
          const FilterOnlyLDW = requiredTaxData.filter(
            (el) => el.itemCategory == "LDW"
          );
          const FilterOnlyPolicy = requiredTaxData.filter(
            (el) => el.itemCategory == "RACTIREPAY"
          );
          const FilterOnlyLate = requiredTaxData.filter(
            (el) => el.itemCategory == "LTRPAYFEE"
          );
          const FilterOnlyCarryRent = requiredTaxData.filter(
            (el) => el.itemCategory == "CRPAY"
          );
          const FilterOnlyClubPay = requiredTaxData.filter(
            (el) => el.itemCategory == "CLUBPAY"
          );
          taxEngineLDWTax = FilterOnlyLDW[0].taxAmount;
          taxEnginePolicyTax = FilterOnlyPolicy[0].taxAmount;
          if(FilterOnlyRpay.length) {
            taxEngineRPayTax = FilterOnlyRpay[0].taxAmount;
          }
          taxEngineLateTax = FilterOnlyLate[0].taxAmount;
          taxEngineCarryRentTax = FilterOnlyCarryRent[0].taxAmount;
          if(FilterOnlyClubPay.length) {
           taxEngineClubPayTax = FilterOnlyClubPay[0].taxAmount;
          }
          console.log("requiredTaxData", requiredTaxData);
        }

        let otherFeeWithTax = Number(props.editScheduleReq[i].processingFee )+
        Number( props.editScheduleReq[i].deliveryAmount )+
        Number( props.editScheduleReq[i].mattressFee) +
        Number( props.editScheduleReq[i].processingFeeTax )+
        Number( props.editScheduleReq[i].deliveryAmountTax )+
        Number( props.editScheduleReq[i].mattressFeeTax);
        let otherFeeTax = 
        Number( props.editScheduleReq[i].processingFeeTax )+
        Number( props.editScheduleReq[i].deliveryAmountTax )+
        Number( props.editScheduleReq[i].mattressFeeTax);
   
        otherFeeWithTax =Number(otherFeeWithTax)>0?Number(otherFeeWithTax):0;
        otherFeeTax=Number(otherFeeTax)>0?Number(otherFeeTax):0;

        if(agreeType[0].taxEngineData !== null&&Number(props.optimizedPayment)==1) {

        

          props.editScheduleReq[i].Amount=Number(calciRes[i].totalAmount)+Number(otherFeeWithTax)+
          Number(calciRes[i].carriedLate) +
          Number(calciRes[i].carriedLateTax) +
          Number(taxEngineLDWTax) +
          Number(taxEnginePolicyTax) +
          Number(taxEngineLateTax) + Number(taxEngineCarryRentTax) + (calciRes[i].agreementType == 'Club' ? 
            Number(taxEngineClubPayTax) : Number(taxEngineRPayTax)),
          props.editScheduleReq[i].Schedule=newSchedule[0].label,
          props.editScheduleReq[i].Taxamount= Number(taxEngineLDWTax) +Number(otherFeeTax)+
          Number(taxEnginePolicyTax) +
          Number(taxEngineLateTax) + Number(taxEngineCarryRentTax) + Number(calciRes[i].carriedLateTax) + (calciRes[i].agreementType == 'Club' ? 
          Number(taxEngineClubPayTax) : Number(taxEngineRPayTax)),
          props.editScheduleReq[i].Nextduedate =  nextduedate
          props.editScheduleReq[i].policyAmount= Number(calciRes[i].policyAmount)
          props.editScheduleReq[i].editSchedule=selectedSchedule
          props.editScheduleReq[i].ldwTaxAmount=Number(taxEngineLDWTax),
          props.editScheduleReq[i].carryRentTaxUsed = Number(taxEngineCarryRentTax).toString(2),
          props.editScheduleReq[i].agrLdwAmount=Number(calciRes[i].ldwAmount)
          props.editScheduleReq[i].policyTaxAmount=Number(taxEnginePolicyTax)
          props.editScheduleReq[i].agreementRate=Number(calciRes[i].agreementRate)
          props.editScheduleReq[i].Agreementtype=agreeType[0].Agreementtype=="Club"?"Club":calciRes[i].agreementType
          props.editScheduleReq[i].rpayTaxAmount= calciRes[i].agreementType == 'Club' ? 0 : Number(taxEngineRPayTax)
        } else {
          props.editScheduleReq[i].Amount=Number(calciRes[i].totalAmount)+Number(otherFeeWithTax)+Number(calciRes[i].totalTax)+Number(calciRes[i].carriedLate)+Number(calciRes[i].carriedLateTax),
          props.editScheduleReq[i].Schedule=newSchedule[0].label,
          props.editScheduleReq[i].Taxamount= Number(calciRes[i].totalTax) + Number(calciRes[i].carriedLateTax) +Number(otherFeeTax),
          props.editScheduleReq[i].Nextduedate =  nextduedate
          props.editScheduleReq[i].policyAmount= Number(calciRes[i].policyAmount)
          props.editScheduleReq[i].editSchedule=selectedSchedule
          props.editScheduleReq[i].ldwTaxAmount=Number(calciRes[i].ldwTaxAmount),
          props.editScheduleReq[i].agrLdwAmount=Number(calciRes[i].ldwAmount)
          props.editScheduleReq[i].policyTaxAmount=Number(calciRes[i].policyTaxAmount)
          props.editScheduleReq[i].agreementRate=Number(calciRes[i].agreementRate)
          props.editScheduleReq[i].Agreementtype=agreeType[0].Agreementtype=="Club"?"Club":calciRes[i].agreementType
          props.editScheduleReq[i].rpayTaxAmount=Number(calciRes[i].rpayTaxAmount)
        }

      }
      
      }
      
  
      props.response({resArray:props.editScheduleReq,adjustEditflag:0})

  }

  /**
   * changeSchedule :- Onchange of dropdown according to schedule will the response from the FE
   * @param e 
   */
  const changeSchedule=async(e:any,ref:any)=>{
    let initialEditScheduleRequest = editScheduleRequest;
    const value = e.target.value
        const tempval = value.split("",4);
    
        const tempArr = tempval.join('');
    if(e.target.value!="Select"){
      let resAdjustDueDate:any;
      let resGridData:any
      const resarr:any =[]
      const resGridDataarr:any=[]
      // debugger;
      // if(e.target.value!=props.editScheduleReq[0].Schedule&&e.target.value!=selectOption){
        let newSchedule:any=[]
        if(ref=="1"){
          newSchedule=[{"label":props.editScheduleReq[0].Schedule}]
        }else{
          newSchedule= schArray.filter(
            (x) => x.value == e.target.value
          );
        }
         
        debugger; 
        // const semiTrue = Addsuffix(newSchedule[0].label)
        
        if(tempArr=="SEMI"){
          setrangeDisabe(false)
          if(props.editScheduleReq.length>1&&props.editScheduleReq[0].Schedule=="Semi-Monthly"&&ref=="1"){
            // req={target:{value:"Select"}}
            setrangeValue(e.target.value)
          } else{
            setrangeValue(e.target.value)
          }
        }else{
          setrangeDisabe(true)
          setrangeValue("Select")
        }
        const adjustDueDatereq: any = []
        for (let i = 0; i < (props.editScheduleReq).length; i++) {
          let coverageEndDate=""
          let policyStatus=""
          let coverageEndDateInfo:any=[]
       if(props.ldwChangeArr.length>0){
         // props.ldwChangeArr
          coverageEndDateInfo = props.ldwChangeArr.filter(
           (x) => String(x.agreementId) === String(props.editScheduleReq[i].Agreementid)
         );
         if(coverageEndDateInfo.length>0){
           if(Number(coverageEndDateInfo[0].action)==2){
            const currentdate: Date = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
            const current_date = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate());
            coverageEndDate =  moment(current_date).format('MM/DD/YYYY');
           }else if(Number(coverageEndDateInfo[0].action)==1){
             coverageEndDate = "12/31/2099"
             if(Number(props.editScheduleReq[i].agreementRateDetails.perDayLdw)==0){
              props.editScheduleReq[i].agreementRateDetails.perDayLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)/7).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.perDayLdwTax=((Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)/7)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.weeklyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.weeklyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.weeklyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.biWeeklyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.biweeklyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.biWeeklyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.biweeklyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.monthlyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.monthlyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.monthlyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.monthlyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.semiMonthlyLdw=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.semimonthlyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.semiMonthlyLdwTax=(Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates.semimonthlyRate)*Number(coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate)).toFixed(2)
            }
           }
         }
       }
       if(coverageEndDate==""){
         coverageEndDate=String(props.editScheduleReq[i].coverageEndDate)
       }
       //policy
       if(props.policyChangeArr.length>0){
         // props.ldwChangeArr
         const policyChangeArrInfo = props.policyChangeArr.filter(
           (x) => String(x.agreementId) === String(props.editScheduleReq[i].Agreementid)
         );
         if(policyChangeArrInfo.length>0){
           if(Number(policyChangeArrInfo[0].action)==2){
             policyStatus = "I"
           }else if(Number(policyChangeArrInfo[0].action)==1){
             policyStatus = "A"
             if(Number(props.editScheduleReq[i].agreementRateDetails.perDayPolicy)==0){
              props.editScheduleReq[i].agreementRateDetails.perDayPolicy=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.weeklyRate)/7).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.perDayPolicyTax=((Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.weeklyRate)/7)*Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.weeklyPolicy=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.weeklyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.weeklyPolicyTax=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.weeklyRate)*Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.biWeeklyPolicy=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.biweeklyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.biWeeklyPolicyTax=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.biweeklyRate)*Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.monthlyPolicy=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.monthlyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.monthlyPolicyTax=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.monthlyRate)*Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.semiMonthlyPolicy=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.semimonthlyRate)).toFixed(2)
              props.editScheduleReq[i].agreementRateDetails.semiMonthlyPolicyTax=(Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates.semimonthlyRate)*Number(coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate)).toFixed(2)
            }

           }
         }
       }
       if(policyStatus==""){
         policyStatus=String(props.editScheduleReq[i].policyStatus)
       }
          const res = {
            "Agreementtype": props.editScheduleReq[i].Agreementtype,
            "agreementId": String(props.editScheduleReq[i].Agreementid),
            "agreementRateDetails":props.editScheduleReq[i].agreementRateDetails,
            "currentDueDate": String(props.editScheduleReq[i].CurrentDueDate),
            "dueDate": String(props.editScheduleReq[i].Nextduedate),
            "coverageEndDate": String(coverageEndDate),
            "taxRate": String(props.editScheduleReq[i].taxRate),
            "carriedLate": String(props.editScheduleReq[i].carriedLate),
            "carriedLateTax": String(props.editScheduleReq[i].carriedLateTax),
            "lateFee": String(props.editScheduleReq[i].lateFee),
            "lateFeeTax": String(props.editScheduleReq[i].lFeeTaxAmount),
            "policyStatus": String(policyStatus),
            "epoAmount": String(props.editScheduleReq[i].EPOAmount),
            "epoTax": String(props.editScheduleReq[i].EPOTax),
            "sacDate": String(props.editScheduleReq[i].SAC_Date),
            "carryRent":String(props.editScheduleReq[i].carryRentAmountUsed),
            "carryRentTax":String(props.editScheduleReq[i].carryRentTaxUsed),
            "agreementNumber":String(props.editScheduleReq[i].AgreementNumber),
            "ldwPercentage": String(props.editScheduleReq[i].ldwPercentage),
            "scheduleRefCode":""
          }
          adjustDueDatereq.push(res)
    
        }
          // setloadSpinner(true)//setting the load spinner to true
          console.log(e.target.value,'testing e.target.value') 
          const editScheudleRequest = {
            rateResponse: adjustDueDatereq,
            schedule:e.target.value
          }
         
          debugger;
          const adjustDueRes: any = await AdjustDueDatecalci(editScheudleRequest);
          debugger;

          if (tempArr == "SEMI" && props.throwIsOptimizedPay && props.throwTaxPayload) {
            setLoadSchedule(true);
            let taxInputsData: any = [];
            // const agreementType = props.editScheduleReq.filter(

            //   (x) => String(x.Agreementtype) === 'Club'
            
            // );
            const clubFilteredData = adjustDueRes.filter((el) => el.agreementType == 'Club');
            const nonClubFilteredData = adjustDueRes.filter((el) => el.agreementType !== 'Club');
            if(nonClubFilteredData.length) {
              nonClubFilteredData.map((el) => {
                taxInputsData.push(
                  {
                    amount: el.policyAmount,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "RACTIREPAY",
                  },
                  {
                    amount: el.agreementRate,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "RPAY",
                  },
                  {
                    amount: el.ldwAmount,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "LDW",
                  },
                  {
                    amount: el.lateFee,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "LTRPAYFEE",
                  },
                  {
                    amount: el.carryRent,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "CRPAY",
                  },
                  {
                    amount: el.carriedLate,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "CLFEEPAY",
                  }
                );
              });
            }
            if(clubFilteredData.length) {
              clubFilteredData.map((el) => {
                taxInputsData.push(
                  {
                    amount: el.policyAmount,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "RACTIREPAY",
                  },
                  {
                    amount: el.agreementRate,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "CLUBPAY",
                  },
                  {
                    amount: el.ldwAmount,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "LDW",
                  },
                  {
                    amount: el.lateFee,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "LTRPAYFEE",
                  },
                  {
                    amount: el.carryRent,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "CRPAY",
                  },
                  {
                    amount: el.carriedLate,
                    taxInputId: `${el.agreementId} - ${e.target.value}`,
                    itemCategory: "CLFEEPAY",
                  }
                );
              });
            }
            console.log("initialEditScheduleRequest", initialEditScheduleRequest);
    
            let formedTaxPayload = props.throwTaxPayload;
            console.log('formedTaxPayload', formedTaxPayload);
            console.log('sdcfewsdc', formedTaxPayload.storeInputs);
            
            formedTaxPayload.storeInputs[0].taxInputs = taxInputsData;
            const taxEngineResponse = await getTax(formedTaxPayload);
            if (taxEngineResponse.status == 200) {
              initialEditScheduleRequest.map((el) => {
                console.log("decfwsd", el.taxEngineData);
                const filteredArray = el.taxEngineData.filter(
                  (obj) => !obj.taxOutputId.includes("SEMI")
                );
                el.taxEngineData = filteredArray;
                const filteredTaxData =
                  taxEngineResponse.data.taxResponse[0].taxOutputs.filter((x) =>
                    x.taxOutputId.includes(el.Agreementid)
                  );
                el.taxEngineData.push(...filteredTaxData);
              });
            }
            console.log(
              "after val initialEditScheduleRequest",
              initialEditScheduleRequest
            );
            setLoadSchedule(false);
          }
          for(let i=0;i<(adjustDueRes).length;i++)
          {
            const agreementType = props.editScheduleReq.filter(

              (x) => String(x.Agreementid) === String(adjustDueRes[i].agreementId)
            
            );

              resAdjustDueDate ={
                Agreementid: adjustDueRes[i].agreementId,
                Agreementtype:agreementType[0].Agreementtype,
                Schedule:tempArr=="SEMI"?"Semi-Monthly":newSchedule[0].label,
                agrLdwAmount:agreementType[0].Agreementtype != 'Note Payment' ? Number(adjustDueRes[i].ldwAmount) : '0.00',
                agreementRate:(Number(adjustDueRes[i].agreementRate).toFixed(2)),
                policyAmount:Number(adjustDueRes[i].policyAmount),
                Taxamount:Number(adjustDueRes[i].totalTax) + Number(adjustDueRes[i].carriedLateTax),
                lFeeTaxAmount:Number(adjustDueRes[i].lateFeeTaxAmount),
                lateFee:Number(adjustDueRes[i].lateFee),
                Amount:(Number(adjustDueRes[i].totalTax) + Number(adjustDueRes[i].totalAmount) + Number(adjustDueRes[i].carriedLate) + Number(adjustDueRes[i].carriedLateTax)).toFixed(2),
                agreementRateTax:Number(adjustDueRes[i].agreementRateTax)
              }
            resarr.push(resAdjustDueDate)
            resGridData={
              agreementNumber:adjustDueRes[i].agreementNumber,
              Schedule:tempArr=="SEMI"?"Semi-Monthly":newSchedule[0].label,
              actualAgreementRate: adjustDueRes[i].actualEditedValue.actualAgreementRate,
              actualLdwRate:agreementType[0].Agreementtype != 'Note Payment' ? adjustDueRes[i].actualEditedValue.actualLdwRate : '0.00',
              actualPolicyRate:adjustDueRes[i].actualEditedValue.actualPolicyRate,
              actualTotalAmount: Number(adjustDueRes[i].actualEditedValue.actualTotalAmount) + Number(adjustDueRes[i].carriedLate),
              actualTotalTax: Number(adjustDueRes[i].actualEditedValue.actualTotalTax) + Number(adjustDueRes[i].carriedLateTax),
              nextDueDate:props.editScheduleReq.length>1&&props.editScheduleReq[0].Schedule=="Semi-Monthly"&&ref=="1"?agreementType[0].Nextduedate:adjustDueRes[i].nextDueDate,
              currentDueDate:adjustDueRes[i].currentDueDate
            }
            resGridDataarr.push(resGridData)
          }
          debugger;
          setcalciRes(adjustDueRes)
          if(ref=="1"){
            setcurrentObject(resGridDataarr)
            setnewObject(resGridDataarr)
          }else{
            setnewObject(resGridDataarr)
            // setsaveObject(resarr)
          }
          setselectedSchedule(e.target.value)
          setselectOption(tempArr=="SEMI"?"SEMI1-15":e.target.value) 
        }else{
          setrangeDisabe(false)
          setrangeValue(e.target.value)
        }
       
          // setloadSpinner(false)
      // }else if(e.target.value==props.editScheduleReq[0].Schedule){
      //   setnewObject(props.editScheduleReq)
      // }
      // debugger
      // setselectOption(e.target.value)
  }

 

  //children to render racstrap
  const editSchedule =()=>{
   

      return(
          <>
        <Grid>
        {loadSpinner?
        <Modal
        open={loadSpinner}
        className={classes.spinnerModal}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div>
          <div className={classes.racLoader}>
            <CircularProgress />
          </div>
        </div>
      </Modal>:null}
      <Grid>
          <Grid className={Payclasses.payHistm2}>
          <Grid item className={` ${classes.w50}`}>
              <label
                htmlFor="Edit-Schedule"
                className={`${classes.formLabel} ${classes.floatLeft} ${classes.raccollg2} ${classes.marginLeftEditSchedule}`}
              >
                New Schedule
              </label>
              <div className={`${classes.raccollg3} ${classes.floatLeft}`}>
                <RACSelect
                  data-testid="EditScheduleId"
                  loading={loadSchedule}
                  options={schArray}
                  defaultValue={selectOption}
                  onChange={(e) => changeSchedule(e,"schedule")}
                ></RACSelect>
              </div>
            </Grid>
            {!rangeDisabe?
            <Grid item className={` ${classes.w50}`}>
              <label
                htmlFor="Edit-Schedule"
                className={`${classes.formLabel} ${classes.floatLeft} ${classes.raccollg2} ${classes.marginLeftEditSchedule}`}style={{ width: '50px' }}
              >
                Range
              </label>
              <div className={`${classes.raccollg3} ${classes.floatLeft}`}>
                <RACSelect
                  data-testid="EditScheduleId"
                  options={rangeval}
                  loading={loadSchedule}
                  defaultValue={rangeValue}
                  isDisabled={rangeDisabe}
                  onChange={(e) => changeSchedule(e,"range")}
                ></RACSelect>
              </div>
            </Grid>:null}

            <Grid container spacing={4} className={Payclasses.payHistmb3}>

            <Grid item xs={12} sm={12} md={6}  className={Payclasses.payHistmb4}>
                <Grid className={Payclasses.payHistm2}>
                  <Typography variant="h5">Current</Typography>
                </Grid>
                <Grid className={`${Payclasses.payHistm2} `}>


                  <RACTable
                    renderTableHead={()=>ItemInfoHead("Current")}
                    renderTableContent={()=>itemInfoContent("Current")}
                  />

                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} md={6}  className={Payclasses.payHistmb4}>
                <Grid className={Payclasses.payHistm2}>
                  <Typography variant="h5">New</Typography>
                </Grid>
                <Grid className={`${Payclasses.payHistm2} `}>


                  <RACTable
                    renderTableHead={()=>ItemInfoHead("New")}
                    renderTableContent={()=>itemInfoContent("New")}
                  />

                </Grid>
              </Grid>
            </Grid>
            <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="outlined"
            name="clearCancel"
            data-bs-toggle="modal"
            style={{
              backgroundColor: "white",
              color: "#2C3333",
            }}
             onClick={()=>{props.isClosed(false)}}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            name="De_saveEditScheduleBtn"
            data-bs-toggle="modal"
            disabled = {loadSchedule||loadingSave}
            loading = {loadSchedule||loadingSave}
            onClick={()=>saveEditScheduleRedirect()}
          >
            Save
          </RACButton>
        </Grid>
           
          </Grid>
        

        </Grid>
        </Grid>
            
          </>
      )
  }
  return (
    <>
      <RACModalCard
        isOpen={props.isOpen}
        maxWidth="xl"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => props.isClosed(false)}
        // eslint-disable-next-line react/no-children-prop
        children={editSchedule()}
        title="Edit Schedule"
      />
    </>
  );
}

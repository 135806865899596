/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState, useLayoutEffect } from "react";
import { wizardStyles } from "../../stylesJS/wizardStyles";
//import { agreementGlobalStyles } from '../../JSstyles/agreementGlobalStyles';
import { Grid, List, ListItem, Box, Typography } from "@rentacenter/racstrap";
import { ReactComponent as CompletedIcon } from "../../assets/images/wizard-complete.svg";
import { useParams, Link } from "react-router-dom";
import { useHistory } from "react-router";

export type CoCustomerDetails = {
  coCustomerId: string;
  coCustomerName: string;
};

interface Params {
  customerId: string;
  agreementId: string;
}
export default function Wizard(props: any) {
  const wizardClassName = wizardStyles();
  const { customerId, agreementId } = useParams<Params>();
  const history = useHistory();

  const dynamicClasses: any = {
    complete: {
      anchor: wizardClassName.smartWizardListLinkActive,
      spanNum: `${wizardClassName.borderDefault} ${wizardClassName.done}`,
      spanDetail: wizardClassName.stepDone,
    },
    inprogress: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderActive,
      spanDetail: wizardClassName.stepActive,
    },
    pending: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderDefault,
      spanDetail: wizardClassName.stepNotStarted,
    },
  };

  const [itemSearchStatus, setItemSearchStatus] = useState("complete");
  const [agreementDetailStatus, setAgreementDetailStatus] =
    useState("complete");
  const [preliminaryPaymentStatus, setpreliminaryPaymentStatus] =
    useState("pending");
  const [POStatus, setPOStatus] = useState("pending");
  const [initialPaymentStatus, setInitialPaymentStatus] = useState("pending");
  const [deliveryMethodStatus, setDeliveryMethodStatus] = useState("pending");

  useLayoutEffect(() => {
    const getUrl = window.location.href;
    if (getUrl.includes("/customerorder/preliminary/")) {
      setpreliminaryPaymentStatus("inprogress");
    } else if (getUrl.includes("/customerorder/initial/")) {
      setpreliminaryPaymentStatus("complete");
      setPOStatus("complete");
      setInitialPaymentStatus("inprogress");
    }
  }, []);

  useEffect(() => {
    console.log("custdatainwizard", props.customerData);
  }, [props.customerData]);

  return (
    //Wizard starts from here
    <Grid className={wizardClassName.swThemeDefault}>
      <Grid item className={wizardClassName.swThemeDefaultContainer}>
        <List className={wizardClassName.smartWizard}>
          <ListItem
            className={wizardClassName.smartWizardList}
            onClick={() => {
              history.push({
                pathname: `/agreement/customerorder/itemsearch/${customerId}/${agreementId}?coCustomerId=N`,
              });
            }}
          >
            <a className={dynamicClasses[itemSearchStatus]["anchor"]}>
              {itemSearchStatus === "complete" ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span className={dynamicClasses[itemSearchStatus]["spanNum"]}>
                  1
                </span>
              )}

              <span className={dynamicClasses[itemSearchStatus]["spanDetail"]}>
                Item Search
              </span>
            </a>
          </ListItem>
          <ListItem
            className={wizardClassName.smartWizardList}
            onClick={() => {
              history.push({
                pathname: `/agreement/customerorder/details/${customerId}/${agreementId}?coCustomerId=N`,
              });
            }}
          >
            <a className={dynamicClasses[agreementDetailStatus]["anchor"]}>
              {agreementDetailStatus === "complete" ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={dynamicClasses[agreementDetailStatus]["spanNum"]}
                >
                  2
                </span>
              )}

              <span
                className={dynamicClasses[agreementDetailStatus]["spanDetail"]}
              >
                Agreement Details
              </span>
            </a>
          </ListItem>
          <ListItem className={wizardClassName.smartWizardList}>
            <a
              className={dynamicClasses[preliminaryPaymentStatus]["anchor"]}
              style={{ cursor: "not-allowed" }}
            >
              {preliminaryPaymentStatus === "complete" ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={
                    dynamicClasses[preliminaryPaymentStatus]["spanNum"]
                  }
                >
                  3
                </span>
              )}

              <span
                className={
                  dynamicClasses[preliminaryPaymentStatus]["spanDetail"]
                }
              >
                Preliminary Payment
              </span>
            </a>
          </ListItem>
          <ListItem
            className={wizardClassName.smartWizardList}
            onClick={() => {
              const getUrl = window.location.href;
              if (getUrl.includes("paymentinformation/customerorder")) {
                history.push({
                  pathname: `/agreement/customerorder/po/${customerId}/${agreementId}?coCustomerId=N`,
                });
              }
            }}
          >
            <a
              className={dynamicClasses[POStatus]["anchor"]}
              style={{ cursor: "not-allowed" }}
            >
              {POStatus === "complete" ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span className={dynamicClasses[POStatus]["spanNum"]}>4</span>
              )}

              <span className={dynamicClasses[POStatus]["spanDetail"]}>
                PO Status
              </span>
            </a>
          </ListItem>
          <ListItem className={wizardClassName.smartWizardList}>
            <a
              className={dynamicClasses[initialPaymentStatus]["anchor"]}
              style={{ cursor: "not-allowed" }}
            >
              {initialPaymentStatus === "complete" ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={dynamicClasses[initialPaymentStatus]["spanNum"]}
                >
                  5
                </span>
              )}

              <span
                className={dynamicClasses[initialPaymentStatus]["spanDetail"]}
              >
                Initial Payment
              </span>
            </a>
          </ListItem>
          <ListItem className={wizardClassName.smartWizardList}>
            <a
              className={dynamicClasses[deliveryMethodStatus]["anchor"]}
              style={{ cursor: "not-allowed" }}
            >
              {deliveryMethodStatus === "complete" ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={dynamicClasses[deliveryMethodStatus]["spanNum"]}
                >
                  6
                </span>
              )}

              <span
                className={dynamicClasses[deliveryMethodStatus]["spanDetail"]}
              >
                Delivery Method
              </span>
            </a>
          </ListItem>
        </List>
        <Grid
          className={`${wizardClassName.floatRight} ${wizardClassName.custompadding}`}
        >
          <div
            className={`${wizardClassName.floatLeft} ${wizardClassName.me3} ${wizardClassName.mt1}`}
          >
            <span className={wizardClassName.agrInfoPanel}>
              <Link
                className={wizardClassName.racpadLinkCustomer}
                to={`/customer/update/${customerId}/customer`}
              >
                {props.customerData !== undefined &&
                props.customerData?.paymentInfo.customerInfo.length > 0
                  ? props.customerData?.paymentInfo.customerInfo[0].firstName +
                    " " +
                    props.customerData?.paymentInfo.customerInfo[0].lastName
                  : ""}
              </Link>
            </span>
            <Typography
              variant="caption"
              className={wizardClassName.agrInfoPanel}
            >
              {customerId}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

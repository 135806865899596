/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import { Card, Grid, CardContent, Typography } from "@rentacenter/racstrap";
import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { GeneralStateContext } from "../../context/GeneralProvider";

export default function ReceiptDetailsException() {
  const classes = reversePaymentStyle();
  const { receiptDetailsException } = useContext(GeneralStateContext);
  return (
    <Grid item md={12} className={`${classes.textCenter} ${classes.bold}`}>
      <Card className={`${classes.card} ${classes.w100}`}>
        <CardContent>
          <Grid item md={12}>
            <AlertIcon />
            <Typography>
              {receiptDetailsException.serverError
                ? "Something Went Wrong"
                : receiptDetailsException.badRequest
                ? "No Records Found"
                : receiptDetailsException.notEligible
                ? "No Eligible Payment Found For This Customer To Reverse"
                : " "}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
} // This component will be rendering the exceptions that occour in the receipt.

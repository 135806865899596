/*eslint-disable react-hooks/exhaustive-deps*/
/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable no-console*/
/**
 * PS_PI_02
 * Import all the required packages, interfaces, components
 */
import React, { useContext, useEffect, useState } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  RACButton,
  RACModalCard,
  Grid,
  Typography,
  List,
  ListItem,
  CircularProgress,
} from '@rentacenter/racstrap';
import PaymentFooter from './PaymentFooter';
import PaymentTriangleComponent from './PaymentTriangle';
import { agreementContext } from '../context/PaymentInformationContext';
import {
  GetCardInfo,
  GetCustomerAddress,
  GetCustomerByIdInfo,
  GetCustomerInfoOptions,
  GetEmployeeID,
  GetRole,
  StoreswipeDevice,
  calculatePaymentModule,
  getpaymentHistory,
  getpaymentinfo,
} from '../../api/user';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import PaymentPrimaryDetails from './PaymentPrimaryDetails';
import CustomerPrimaryAddressPopup from './CustomerPrimaryAddressPopup';
import {
  dateFormat,
  dueDateFormat,
  reduceMethod,
  splitMethod,
  totalDueAmount,
  validateCashPayment,
} from '../utils/scheduleAndDateFormat';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import {
  ParamType,
  PaymentInfoReqInterface,
  PopupObject,
  AgreementDetails,
  FormedTaxEnginePayload,
  TaxInputs,
  CommitmentAgreementDetailsArray,
  AdjustmentObject,
  Customer,
  MenuConfig,
  SwipeDevicePayload,
  SessionStoreDetails,
  FreeTimeObj,
  AEFObj,
  commitmentPriorityArray,
} from '../interface/paymentInfoInterface';
import Wizard from './Wizard';
import { commitmentPriorityFormation } from '../utils/commitmentPriorityFormation';
import {
  adjustDueDateCalciPayloadFormation,
  commonModulePayloadFormation,
} from '../utils/payloadFormations';
import HandleFreeTime from './FreeTime';
import HandleAdjustDueDate from './AdjustDueDate';
import AdjustDueDatecalci from './existingFunctions/AdjustDueDateCalci';
import { ContainerContext } from '../../app/App';
import { useQueries, useQuery } from '@tanstack/react-query';
import { paymentInfoStyle } from '../stylesJS/paymentInfoStyle';
import CONSTANTS from '../constants/constant';
import { LocationParams } from '../interface/commonInterface';
import {
  CustomerAddress,
  CustomerPayHistoryRecp,
} from '../interface/apiInterface';
import {
  AgreementPaymentDetails,
  AmountDetails,
  ClubPaymentDetails,
  PaymentDetails,
} from '../interface/calculatePaymentResponse';
import {
  ClubInformation,
  DeliveryInformation,
  NumberOfPaymentsMadeInfo,
  PaymentHistoryInfo,
  PaymentInfo,
  StoreInformation,
} from '../interface/getPaymentInfoResponse';
import { CommitmentDetailsInfo } from '../interface/calculatePaymentModel';
import {
  CustomerOrderDDAPopup,
  PrimaryAddrsValidationPopup,
  RenderRetentionAlert,
} from './OnLoadPopups';
import { AgreementContextValue } from '../interface/contextInterface';
import { HandleFinalSuspense } from './HandleFinalSuspense';
import { RACExchangeAlertPopUp } from './RACExchangeReturnAlertPopup';
import { AdjustDueDateReq } from '../interface/adjustDueDateCalciInterface';
import calculationModulePayloadFormation from './existingFunctions/commonModulePayloadFormation';
import { PaymentOriginModal } from './PaymentOriginModal';
import moment from 'moment';
import AcceptEpo from './AcceptEPO';
import {
  BestTimeResponse,
  StateProvince,
} from '../interface/customerInformationInterface';
import { orderBy } from 'lodash';
/**
 * PS_PI_03 - PS_PI_188
 * Declaring the PaymentInformationParent()
 * @returns
 */
export default function PaymentInformationParent() {
  /**
   * PS_PI_04 - PS_PI_31
   * Initialization of variables
   * Retriving values from the context
   * Creating payloads for api calls
   * Making api calls using useQuery
   */
  const classes = takePaymentStyles();
  const internalStyle = paymentInfoStyle();
  const history = useHistory();

  const locationData = useLocation<LocationParams>();

  console.log('locationData', locationData);

  const {
    setPaymentSchedule,
    setCustomerInfo,
    setAgreementDetails,
    setStoreInfo,
    setOtherIncomeDetails,
    setAgreementDetailsDeepClone,
    setOtheIncomeChargeOffDetails,
    setOtheIncomeChargeOffDetailsDeepClone,
    featureFlags,
    agreementDetails,
    setCoworkerDetails,
    customerOrderPayment,
    setCustomerOrderPayment,
    setCustomerInfoDeepClone,
    modalCardEnable,
    setModalCardEnable,
    renderContext,
    setRenderContext,
    isSetToDefault,
    setPaymentHistoryResponse,
    setTaxPayload,
    agreementDetailsDeepClone,
    setCommitmentLoad,
    originPopup,
    setOriginPopup,
    setStateData,
    setBestTimeList,
  } = useContext<AgreementContextValue>(agreementContext);
  const { customerId, agreementId } = useParams<ParamType>();
  const [flag, setFlag] = useState<boolean>(false);
  const [popupPriorityString, setPopupPriorityString] = useState<string>(
    CONSTANTS.CUSTOMER_POPUPS
  );

  console.log('agreementDetailsDeepClone', agreementDetailsDeepClone);
  const containerData = useContext(ContainerContext);

  /**
   * To check the CO initial or CO prelim flow using URL
   */
  const currentPaymentModuleUrl = window.location.href;
  const storeNumber: string | null = sessionStorage.getItem(
    CONSTANTS.STORE_NUMBER
  );
  const [customerNameNav, setCustomerNameNav] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [primaryAddr, setPrimaryAddr] = useState<CustomerAddress[]>([]);
  const popupObj: PopupObject = {
    popupText: CONSTANTS.EMPTY_STRING,
    primaryAddressZipPhonePopup: false,
    multiplePrimaryAddressPopup: false,
    somethingWentWrong: false,
  };
  const [popupEnable, setPopupEnable] = useState<PopupObject>(popupObj);

  /**
   * For Retention- when user chooses Freetime or EPO
   */
  const urlParams = new URLSearchParams(window.location.search);
  const crType: string | null = urlParams.get(CONSTANTS.URLPARAMS_CRTYPE);
  const [retentionLoadPopup, setRetentionLoadPopup] = useState<boolean>(false);
  /**
   * For return agreement flow(suspense return flow or chargeoff return flow)
   */
  const type: string | null = urlParams.get(CONSTANTS.URLPARAMS_TYPE);
  const agreementIdQueryParam: string | null = urlParams.get(
    CONSTANTS.URLPARAMS_AGREEMENT_ID
  );
  /**
   * For exchange agreements
   */
  // const exchangeAgreementId = urlParams.get(
  //   CONSTANTS.URLPARAMS_EXCHANGE_AGREEMENT_ID
  // );
  /**
   * For Customer Order Initial & Prelim
   */
  const [coDueDateAdjustmentPopup, setCoDueDateAdjustmentPopup] =
    useState<boolean>(false);
  const [adjustedDuedate, setAdjustedDueDate] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  let coStatus: string = CONSTANTS.EMPTY_STRING;
  if (currentPaymentModuleUrl.includes(CONSTANTS.URL_CO_PRELIM))
    coStatus = CONSTANTS.IS_CO_PRELIM;
  else if (currentPaymentModuleUrl.includes(CONSTANTS.URL_CO_INITIAL))
    coStatus = CONSTANTS.IS_CO_INITIAL;
  const commonModuleFlag: number =
    Number(featureFlags.CommonModulePaymentFlag) ?? CONSTANTS.ZERO_NUMBER;

  const paymentInfoReq: PaymentInfoReqInterface = {
    customerID: customerId,
    commonModuleFlag: commonModuleFlag,
  };

  if (coStatus != CONSTANTS.EMPTY_STRING)
    paymentInfoReq.agreementStatus = CONSTANTS.PRELIM_PRELIM_INV_ARRAY;
  else paymentInfoReq.agreementStatus = CONSTANTS.AGREEMENT_STATUS_ACTIVE_PEND;

  const swipeDevicePayload: SwipeDevicePayload = {
    isCloudStoreSwipeDevicelist: featureFlags?.CloudStoreSwipeDevicelist
      ? CONSTANTS.YES
      : CONSTANTS.NO,
    storeNumber: storeNumber,
  };

  const secondPriority =
    coStatus == CONSTANTS.IS_CO_INITIAL
      ? CONSTANTS.CODUEDATE_POPUPS
      : crType
      ? CONSTANTS.RETENTION_POPUPS
      : CONSTANTS.SUSPENSE_POPUPS;

  /**
   * PS_PI_26
   * Makes api call for getpaymentinfo, GetCustomerByIdInfo, getpaymentHistory, GetCardInfo, StoreswipeDevice, GetCustomerAddress
   */
  const [
    getPaymentInfo,
    customerByIdInfo,
    paymentHistoryAgr,
    paymentHistoryRecp,
    paymentHistoryClub,
    cardInfo,
    swipeDeviceInfo,
    customerAddress,
  ] = useQueries({
    queries: [
      {
        queryKey: [CONSTANTS.API_KEY_GET_PAYMENT_INFO, customerId],
        queryFn: () => getpaymentinfo(paymentInfoReq),
        enabled: !agreementDetailsDeepClone.length,
      },
      {
        queryKey: [CONSTANTS.API_KEY_GET_CUSTOMER_BY_ID_INFO, customerId],
        queryFn: () => GetCustomerByIdInfo(customerId, storeNumber),
        enabled: !agreementDetailsDeepClone.length,
      },
      {
        queryKey: [
          CONSTANTS.API_KEY_GET_PAYMENT_HISTORY,
          CONSTANTS.AGR,
          customerId,
        ],
        queryFn: () =>
          getpaymentHistory(customerId, CONSTANTS.AGR, CONSTANTS.ZERO_NUMBER),
        enabled: !agreementDetailsDeepClone.length,
      },
      {
        queryKey: [
          CONSTANTS.API_KEY_GET_PAYMENT_HISTORY,
          CONSTANTS.RECP,
          customerId,
        ],
        queryFn: () =>
          getpaymentHistory(customerId, CONSTANTS.RECP, CONSTANTS.ZERO_NUMBER),
        enabled: !agreementDetailsDeepClone.length,
      },
      {
        queryKey: [
          CONSTANTS.API_KEY_GET_PAYMENT_HISTORY,
          CONSTANTS.CLUB_SMALL_CASE,
          customerId,
        ],
        queryFn: () =>
          getpaymentHistory(
            customerId,
            CONSTANTS.CLUB_SMALL_CASE,
            CONSTANTS.ZERO_NUMBER
          ),
        enabled: !agreementDetailsDeepClone.length,
      },
      {
        queryKey: [CONSTANTS.API_KEY_GET_CARD_INFO, customerId],
        queryFn: () => GetCardInfo(customerId),
        enabled: !agreementDetailsDeepClone.length,
      },
      {
        queryKey: [CONSTANTS.API_KEY_STORE_SWIPE_DEVICE, customerId],
        queryFn: () => StoreswipeDevice(swipeDevicePayload),
        enabled: !agreementDetailsDeepClone.length,
      },
      {
        queryKey: [CONSTANTS.API_KEY_GET_CUSTOMER_ADDRESS, customerId],
        queryFn: () => GetCustomerAddress(customerId, CONSTANTS.STATUS_YES),
        enabled: !agreementDetailsDeepClone.length,
      },
    ],
  });

  console.log(getPaymentInfo.isSuccess, 'getPaymentInfo.isSuccess');

  /**
   * PS_PI_31
   * Making api call for getTax using useQuery
   */
  // const { data: taxResponse, isSuccess: taxSuccess } = useQuery({
  //   queryKey: [CONSTANTS.API_KEY_GET_TAX],
  //   queryFn: () => getTax(formedTaxEnginePayload),
  //   enabled: getPaymentInfo.isSuccess,
  // });

  /**
   * PS_CI_16 - PS_CI_22
   * This makes api call to get the state and best time options
   */
  useQuery({
    queryKey: [CONSTANTS.API_KEY_GET_CUSTOMER_INFO_OPTIONS, customerId],
    queryFn: () => GetCustomerInfoOptions(storeNumber),
    onSuccess(res) {
      const stateOption = res.data.stateList.getStateResponse.map(
        (el: StateProvince) => ({
          label: el.abbreviation,
          value: el.abbreviation,
        })
      );
      setStateData(orderBy(stateOption, [CONSTANTS.LABEL], ['asc']));

      const bestTimeToCallList = res.data.callTimeList.map(
        (state: BestTimeResponse) => {
          return { label: state.description, value: state.description };
        }
      );
      setBestTimeList(bestTimeToCallList);
    },
  });

  /**
   * PS_PI_32 - PS_PI_38
   * Declaring useEffect()
   * Checking for the CO prelim and CO initial condition to set in the context
   * Invoking getCoWorkerDetails()
   */
  useEffect(() => {
    coStatus == CONSTANTS.IS_CO_INITIAL && setCoDueDateAdjustmentPopup(true);

    crType && setRetentionLoadPopup(true);

    if (isSetToDefault == 0) {
      setCustomerOrderPayment(coStatus);
      getCoWorkerDetails();
    } else {
      // setAgreementDetails(agreementDetailsDeepClone);
      setPopupPriorityString(secondPriority);
    }
  }, [isSetToDefault]);
  /**
   * PS_PI_39 - PS_PI_41
   * Declare an useEffect with dependency as getPaymentInfo.data, customerByIdInfo.data, customerAddress.data, taxSuccess
   * If success it calls setInitialContext()
   * Else it displays something went wrong popup
   */
  useEffect(() => {
    getPaymentInfo.isSuccess &&
      customerByIdInfo.isSuccess &&
      customerAddress.isSuccess &&
      setInitialContext();
    (getPaymentInfo.isError || customerByIdInfo.isError) &&
      setPopupEnable({ ...popupEnable, somethingWentWrong: true });
  }, [getPaymentInfo.data, customerByIdInfo.data, customerAddress.data]);
  console.log('popupPriorityString', popupPriorityString);
  /**
   * PS_PI_42 - PS_PI_148
   * Declaring setInitialContext()
   * Sets the context with the values received from the response
   * Checks if commonModuleRes is not 400 to set the values in context
   * Id status is 400, it displays no active primary address popup
   */
  const setInitialContext = async () => {
    const agreementDetailsArray: AgreementDetails[] = [];
    const commitmentAgreementDetailsArray: CommitmentAgreementDetailsArray[] =
      [];
    /**
     * PS_PI_44
     * Checks for the commonModuleRes status to set values in context
     */
    try {
      if (!getPaymentInfo.data.commonModuleRes?.code) {
        /**
         * PS_PI_27 - PS_PI_30
         * Payload creation for getTax api
         */
        const sessionStoreDetails: string =
          sessionStorage.getItem(CONSTANTS.STORE_DETAILS) ??
          CONSTANTS.EMPTY_STRING;
        const formattedStoreDetails: SessionStoreDetails = JSON.parse(
          atob(sessionStoreDetails)
        );
        const sessionZip: string[] = splitMethod(
          formattedStoreDetails.zip,
          CONSTANTS.HYPHEN
        );
        let formedTaxEnginePayload: FormedTaxEnginePayload =
          {} as FormedTaxEnginePayload;
        if (
          getPaymentInfo.isSuccess &&
          !getPaymentInfo.data.commonModuleRes?.code
        ) {
          let zip5: string, zip4: string;
          const resZip5 =
            getPaymentInfo?.data?.paymentInfo?.customerInfo[0].zip;
          const resZip4 =
            getPaymentInfo?.data?.paymentInfo?.customerInfo[0].plus4;
          const formattedZip5 = resZip5
            ? resZip5.replace(/\s+/g, CONSTANTS.EMPTY_STRING)
            : CONSTANTS.EMPTY_STRING;
          const formattedZip4 = resZip4
            ? resZip4.replace(/\s+/g, CONSTANTS.EMPTY_STRING)
            : CONSTANTS.EMPTY_STRING;

          if (formattedZip5.length != CONSTANTS.FIVE_NUMBER) {
            zip5 = sessionZip[0];
            zip4 = sessionZip[1];
          } else {
            zip5 = formattedZip5;
            zip4 =
              formattedZip4.length == CONSTANTS.FOUR_NUMBER
                ? formattedZip4
                : CONSTANTS.FOUR_ZERO_STRING;
          }

          formedTaxEnginePayload = {
            customerState:
              getPaymentInfo?.data?.paymentInfo?.customerInfo[0].state,
            postalCode: zip5 + CONSTANTS.HYPHEN + zip4,
            customerId: customerId,
            apiCall: CONSTANTS.EMPTY_STRING,
            dateOfBirth: dateFormat(
              getPaymentInfo.data?.paymentInfo.customerInfo[0].dob,
              CONSTANTS.YYYY_MM_DD
            ),
            taxRateOnly: CONSTANTS.STATUS_NO,
            taxExemptAsOfToday:
              getPaymentInfo.data?.paymentInfo.customerInfo[0].isTaxExempted !=
              CONSTANTS.EMPTY_STRING
                ? getPaymentInfo.data?.paymentInfo.customerInfo[0].isTaxExempted
                : CONSTANTS.STATUS_NO,
          };
          const taxInputsData: TaxInputs[] = [];
          taxInputsData.push(
            {
              amount: String(featureFlags.PaymentFee),
              taxInputId: CONSTANTS.THIRTY_TWO_STRING,
              itemCategory: CONSTANTS.CONVENFEE,
            },
            {
              amount: String(CONSTANTS.TEN_NUMBER),
              taxInputId: CONSTANTS.THIRTY_THREE_STRING,
              itemCategory: CONSTANTS.LTRPAYFEE,
            },
            {
              amount: String(CONSTANTS.TEN_NUMBER),
              taxInputId: CONSTANTS.THIRTY_FOUR_STRING,
              itemCategory: CONSTANTS.BKRPAYTAX,
            }
          );
          formedTaxEnginePayload.storeInputs = [
            {
              taxInputs: taxInputsData,
              storeNumber: storeNumber,
            },
          ];
        }
        setTaxPayload(formedTaxEnginePayload);
        const convinenceFee =
          getPaymentInfo.data.commonModuleRes?.customerFees.filter(
            (el: AmountDetails) => el.itemCategory == CONSTANTS.CONVENFEE
          );
        setPaymentHistoryResponse({
          payHistoryAgr: paymentHistoryAgr.data,
          payHistoryClub: paymentHistoryClub.data,
          payHistoryRecp: paymentHistoryRecp.data,
        });

        const prmyAddrs =
          customerAddress?.data.data?.GetCustomerAddress?.value?.addresses?.filter(
            (addr: CustomerAddress) => addr.addressType === CONSTANTS.PRIM
          );
        setPrimaryAddr(prmyAddrs);
        const multipleAddrsArray: string[] = [];
        for (let i = 0; i < prmyAddrs?.length; i++) {
          if (!multipleAddrsArray.includes(prmyAddrs[i]?.addressId))
            multipleAddrsArray.push(prmyAddrs[i]?.addressId);
        }
        if (multipleAddrsArray?.length > CONSTANTS.ONE_NUMBER) {
          setPopupEnable((prev) => ({
            ...prev,
            multiplePrimaryAddressPopup: true,
          }));
        }

        const customerDetails = getPaymentInfo.data.paymentInfo.customerInfo;
        const customerPaymentDetails = {
          check:
            paymentHistoryRecp.data?.value?.customerPaymentHistory?.filter(
              (el: CustomerPayHistoryRecp) =>
                el.tenderType.includes(CONSTANTS.CHK)
            ).length ?? CONSTANTS.HYPHEN,
          nsfCccb: CONSTANTS.HYPHEN,
          lateLast10: getPaymentInfo.data?.paymentInfo?.lastLate10GraphInfo,
          commitment: getPaymentInfo.data?.commitmentDetails?.commitments,
        };
        const customerInfoValue =
          customerByIdInfo.data?.data?.GetCustomer?.value;
        const customerVerification =
          customerByIdInfo.data?.data?.GetApproval?.value
            ?.customerApprovalDetails?.verificationStatus;

        const customerPhoneZip =
          getPaymentInfo.data.paymentInfo.customerInfo[0];

        const zip = customerPhoneZip.zip.replace(
          /\s+/g,
          CONSTANTS.EMPTY_STRING
        );

        if (zip == CONSTANTS.NULL || zip?.length != CONSTANTS.FIVE_NUMBER) {
          setPopupEnable((prev) => ({
            ...prev,
            primaryAddressZipPhonePopup: true,
            popupText: CONSTANTS.INVALID_ZIP_POPUP_TEXT,
          }));
        } else if (
          getPaymentInfo?.data?.paymentInfo?.customerInfo[0]?.phoneNumber
            ?.length != CONSTANTS.TEN_NUMBER
        ) {
          setPopupEnable((prev) => ({
            ...prev,
            primaryAddressZipPhonePopup: true,
            popupText: CONSTANTS.INVALID_PHONE_POPUP_TEXT,
          }));
        } else if (multipleAddrsArray?.length == CONSTANTS.ONE_NUMBER) {
          setPopupPriorityString(secondPriority);
        }

        const employerReferences =
          customerByIdInfo.data?.data?.GetCustomer?.value?.employerReferences;

        let webLeadUsed: string = CONSTANTS.ZERO_STRING;

        const otherIncomeTypes: string[] = [];
        getPaymentInfo.data?.commonModuleRes?.paymentDetails.forEach(
          (eachStore: PaymentDetails) => {
            if (
              !otherIncomeTypes.length &&
              eachStore.storeNumber == storeNumber
            ) {
              eachStore.otherIncome?.forEach((item: AmountDetails) =>
                otherIncomeTypes.push(item.itemCategory)
              );
            }
            console.log(otherIncomeTypes, 'otherIncomeTypes');

            eachStore?.agreementPaymentDetails?.forEach(
              (eachItem: AgreementPaymentDetails) => {
                if (
                  ((coStatus != CONSTANTS.EMPTY_STRING &&
                    CONSTANTS.PRELIM_PRELIM_INV_ARRAY.includes(
                      eachItem.agreementStatus
                    ) &&
                    agreementId == eachItem.agreementId) ||
                    (coStatus == CONSTANTS.EMPTY_STRING &&
                      !CONSTANTS.PRELIM_PRELIM_INV_ARRAY.includes(
                        eachItem.agreementStatus
                      ))) &&
                  eachItem.agreementType != CONSTANTS.RENT_TO_RENT_LONER
                ) {
                  webLeadUsed =
                    String(eachItem.agreementRateDetails?.coWebleadUsed) ??
                    CONSTANTS.ZERO_STRING;
                  const lastDetails =
                    getPaymentInfo.data?.paymentInfo?.paymentInfo?.find(
                      (each: PaymentInfo) =>
                        eachItem.agreementId == each.agreementId
                    );
                  const deliveryDetails =
                    getPaymentInfo.data?.paymentInfo?.deliveryInformation?.find(
                      (each: DeliveryInformation) =>
                        eachItem.agreementId == each.agreementId
                    );
                  const noOfPaymentsMadeDetails =
                    getPaymentInfo.data?.paymentInfo?.numberOfPaymentsMadeInfo?.find(
                      (each: NumberOfPaymentsMadeInfo) =>
                        eachItem.agreementId == each.agreementId
                    );
                  const rpayDetails = eachItem?.amountDetails?.find(
                    (el) =>
                      el.itemCategory == CONSTANTS.RPAY ||
                      el.itemCategory == CONSTANTS.LTRPAY ||
                      el.itemCategory == CONSTANTS.INITPAY ||
                      el.itemCategory == CONSTANTS.EPO ||
                      el.itemCategory == CONSTANTS.PIF ||
                      el.itemCategory == CONSTANTS.SAC ||
                      el.itemCategory == CONSTANTS.PREINITPAY ||
                      el.itemCategory == CONSTANTS.POA ||
                      el.itemCategory == CONSTANTS.INPIF
                  );

                  const lateFee = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.LTRPAYFEE,
                    CONSTANTS.AMOUNT
                  );

                  const lateFeeTaxRate =
                    eachItem?.amountDetails?.find(
                      (el) =>
                        el.itemCategory == CONSTANTS.LTRPAYFEE ||
                        el.itemCategory == CONSTANTS.LTPIFFEE
                    )?.taxRate ?? CONSTANTS.ZERO_NUMBER;

                  const latePifFee = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.LTPIFFEE,
                    CONSTANTS.AMOUNT
                  );

                  const lateFeeTax = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.LTRPAYFEE,
                    CONSTANTS.TAX
                  );

                  const latePifFeeTax = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.LTPIFFEE,
                    CONSTANTS.TAX
                  );

                  const carriedLateFee = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.CLFEEPAY,
                    CONSTANTS.AMOUNT
                  );

                  const carriedLateFeeTax = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.CLFEEPAY,
                    CONSTANTS.TAX
                  );

                  const carriedRent = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.CRPAY,
                    CONSTANTS.AMOUNT
                  );

                  const carriedRentTax = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.CRPAY,
                    CONSTANTS.TAX
                  );

                  const paymentHistory =
                    getPaymentInfo.data?.paymentInfo?.paymentHistoryInfo?.find(
                      (each: PaymentHistoryInfo) =>
                        each.agreementId == eachItem.agreementId
                    );

                  // const remainingCredits =
                  //   getPaymentInfo.data?.paymentInfo?.remainingCredits?.find(
                  //     (el: RemainingCredits) =>
                  //       el.bucket == CONSTANTS.CARRIED_LATE_FEES
                  //   );
                  const epoAmount =
                    Number(eachItem?.epoAmountIncTax ?? CONSTANTS.ZERO_NUMBER) +
                    carriedRent +
                    carriedRentTax +
                    carriedLateFee +
                    carriedLateFeeTax;
                  const epoTax =
                    Number(eachItem?.agreementRateDetails?.estimatedEpoTax) +
                    carriedRentTax +
                    carriedLateFeeTax;

                  const actualEpo = Number(
                    Number(epoAmount?.toFixed(2)) -
                      Number(epoTax?.toFixed(2)) -
                      Number(lateFee ? lateFee : 0) -
                      Number(latePifFee ? latePifFee : 0) -
                      Number(eachItem.pastDueRent ?? 0)
                  ).toFixed(2);

                  const deliveryFee = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.DELIVFEE,
                    CONSTANTS.AMOUNT
                  );

                  const deliveryFeeTax = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.DELIVFEE,
                    CONSTANTS.TAX
                  );

                  const processingFee = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.PROCESSINGFEE,
                    CONSTANTS.AMOUNT
                  );

                  const processingFeeTax = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.PROCESSINGFEE,
                    CONSTANTS.TAX
                  );

                  const mattressFee = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.MATTRESSFEE,
                    CONSTANTS.AMOUNT
                  );

                  const mattressFeeTax = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.MATTRESSFEE,
                    CONSTANTS.TAX
                  );

                  const extentionAmount = reduceMethod(
                    eachItem?.amountDetails,
                    CONSTANTS.ITEM_CATEGORY,
                    CONSTANTS.EXTAMT,
                    CONSTANTS.AMOUNT
                  );

                  const storeDetails =
                    getPaymentInfo.data?.paymentInfo?.storeInfo?.find(
                      (el: StoreInformation) =>
                        el.storeNumber == eachStore.storeNumber
                    );
                  agreementDetailsArray.push({
                    agreementId: eachItem.agreementId,
                    agreementStatus: eachItem.agreementStatus,
                    agreementNumber: eachItem.agreementNumber,
                    agreementType:
                      eachItem?.agreementType || CONSTANTS.EMPTY_STRING,
                    agreementDesc: eachItem.agreementDesc,
                    agreementSchedule: eachItem.schedule,
                    originalSchedule: eachItem.schedule,
                    currentDueDate:
                      eachItem.currentDueDate || CONSTANTS.EMPTY_STRING,
                    nextDueDate:
                      agreementIdQueryParam == eachItem.agreementId &&
                      (type == CONSTANTS.URLPARAMS_TYPE_CHARGE_OFF ||
                        type == CONSTANTS.URLPARAMS_TYPE_RETURN)
                        ? eachItem.currentDueDate || CONSTANTS.EMPTY_STRING
                        : (eachItem?.nextDueDate &&
                            dueDateFormat(eachItem.nextDueDate)) ||
                          (eachItem?.isFinalPayment &&
                            dueDateFormat(eachItem?.isFinalPayment)) ||
                          CONSTANTS.EMPTY_STRING,
                    isFinalPayment:
                      eachItem?.isFinalPayment &&
                      dueDateFormat(eachItem?.isFinalPayment),
                    daysLate: paymentHistory?.noOfDaysLate, //Added value from paymentHistoryInfo
                    totalDueAmount: totalDueAmount(
                      eachItem.totalAmount,
                      eachItem.totalTax
                    ).toFixed(2),
                    sacDate: eachItem?.sacDate,
                    epoAmount:
                      Number(epoAmount).toFixed(2) || CONSTANTS.EMPTY_STRING,
                    suspenseAmount: eachItem?.suspenseBalance
                      ? eachItem.suspenseBalance
                      : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    lateLast10: lastDetails?.lastLate10,
                    acceptEpoFlag: CONSTANTS.ZERO_NUMBER,
                    promoFreeDays:
                      lastDetails?.promoFreeDays ?? CONSTANTS.ZERO_NUMBER,
                    actualPromoFreeDays:
                      lastDetails?.promoFreeDays ?? CONSTANTS.ZERO_NUMBER,
                    promoInfo: lastDetails?.promoInformation,
                    rentPaid: eachItem?.rentPaid,
                    otherIncomeType: CONSTANTS.EMPTY_STRING,
                    lateFee:
                      lateFee != CONSTANTS.ZERO_NUMBER
                        ? lateFee?.toFixed(2)
                        : latePifFee || CONSTANTS.ZERO_NUMBER,
                    lateFeetax:
                      lateFeeTax != CONSTANTS.ZERO_NUMBER
                        ? lateFeeTax
                        : latePifFeeTax || CONSTANTS.ZERO_NUMBER,
                    lateFeeTaxRate: lateFeeTaxRate,
                    originalLateFee:
                      lateFee != CONSTANTS.ZERO_NUMBER
                        ? lateFee?.toFixed(2)
                        : latePifFee || CONSTANTS.ZERO_NUMBER,
                    carriedLateRent: carriedLateFee,
                    numberOfPaymentsMade:
                      noOfPaymentsMadeDetails?.noOfPaymentsMade,
                    deliveryFlag: deliveryDetails?.deliveryFlag,
                    selected:
                      coStatus == CONSTANTS.IS_CO_INITIAL
                        ? true
                        : (eachStore.storeNumber === storeNumber &&
                            lastDetails?.isExchanged != CONSTANTS.ONE_NUMBER) ??
                          false,
                    disabled:
                      lastDetails?.isExchanged == CONSTANTS.ONE_NUMBER
                        ? true
                        : false,
                    storeNumber: eachStore.storeNumber,
                    totalAmount: eachItem.totalAmount,
                    totalTax: eachItem.totalTax,
                    suspenseDetails: {
                      suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      suspUse:
                        (agreementIdQueryParam == eachItem.agreementId &&
                          (type == CONSTANTS.URLPARAMS_TYPE_CHARGE_OFF ||
                            type == CONSTANTS.URLPARAMS_TYPE_RETURN)) ||
                        CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
                          String(eachItem?.isFinalPayment)
                        )
                          ? Number(eachItem?.suspenseBalance).toFixed(2)
                          : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      transferSusAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    },
                    agreementRateDetails: eachItem.agreementRateDetails,
                    updatedRemainingNoteAmount:
                      eachItem?.agreementRateDetails?.remainingNoteCost ??
                      CONSTANTS.ZERO_STRING,
                    isLDW:
                      eachItem?.agreementRateDetails?.isLdwCoverageAllowed ==
                        CONSTANTS.STATUS_YES &&
                      eachItem.agreementRateDetails?.coverageStatus ==
                        CONSTANTS.STATUS_YES
                        ? true
                        : false,
                    isPolicy:
                      eachItem?.agreementRateDetails?.isPolicyCoverageAllowed ==
                        CONSTANTS.STATUS_YES &&
                      eachItem.agreementRateDetails?.coverageStatus ==
                        CONSTANTS.STATUS_YES
                        ? true
                        : false,
                    coverageStatus:
                      eachItem?.agreementRateDetails?.coverageStatus ||
                      CONSTANTS.EMPTY_STRING,
                    isCoverageActionPerformed:
                      eachItem?.agreementRateDetails?.coverageStatus,
                    ldwAmount:
                      eachItem?.amountDetails?.reduce(
                        (accum, item) =>
                          accum +
                          Number(
                            CONSTANTS.LTLDW_LDW_ARRAY.includes(
                              item.itemCategory
                            )
                              ? item.amount
                              : CONSTANTS.ZREO_TWO_DECIMAL_STRING
                          ),
                        CONSTANTS.ZERO_NUMBER
                      ) || CONSTANTS.ZERO_NUMBER,
                    ldwTax:
                      eachItem?.amountDetails?.reduce(
                        (accum, item) =>
                          accum +
                          Number(
                            CONSTANTS.LTLDW_LDW_ARRAY.includes(
                              item.itemCategory
                            )
                              ? item.tax
                              : CONSTANTS.ZREO_TWO_DECIMAL_STRING
                          ),
                        CONSTANTS.ZERO_NUMBER
                      ) || CONSTANTS.ZERO_NUMBER,
                    policyAmount:
                      eachItem?.amountDetails?.reduce(
                        (accum, item) =>
                          accum +
                          Number(
                            CONSTANTS.RACTIREPAY_ARRAY.includes(
                              item.itemCategory
                            )
                              ? item.amount
                              : CONSTANTS.ZREO_TWO_DECIMAL_STRING
                          ),
                        CONSTANTS.ZERO_NUMBER
                      ) || CONSTANTS.ZERO_NUMBER,
                    policyTax:
                      eachItem?.amountDetails?.reduce(
                        (accum, item) =>
                          accum +
                          Number(
                            CONSTANTS.RACTIREPAY_ARRAY.includes(
                              item.itemCategory
                            )
                              ? item.tax
                              : CONSTANTS.ZREO_TWO_DECIMAL_STRING
                          ),
                        CONSTANTS.ZERO_NUMBER
                      ) || CONSTANTS.ZERO_NUMBER,
                    isEpp:
                      eachItem?.agreementRateDetails?.isEppAgreement ==
                      CONSTANTS.STATUS_YES
                        ? true
                        : false,
                    switchoutDeliveryStatus:
                      eachItem?.agreementRateDetails?.switchoutDeliveryStatus ||
                      CONSTANTS.EMPTY_STRING,
                    taxRate: rpayDetails?.taxRate || CONSTANTS.EMPTY_STRING,
                    isLdwAllowed:
                      eachItem?.agreementRateDetails?.isLdwCoverageAllowed ==
                      CONSTANTS.STATUS_YES
                        ? true
                        : false,
                    isPolicyAllowed:
                      eachItem?.agreementRateDetails?.isPolicyCoverageAllowed ==
                      CONSTANTS.STATUS_YES
                        ? true
                        : false,
                    ldwPercentage:
                      eachItem?.agreementRateDetails?.ldwPercentage ||
                      CONSTANTS.EMPTY_STRING,
                    epoTax:
                      Number(epoTax).toFixed(2) ||
                      CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    policyStatus: eachItem?.agreementRateDetails?.policyStatus
                      ? eachItem.agreementRateDetails.policyStatus
                      : false,
                    carriedRent: carriedRent,
                    carriedLateAmount: CONSTANTS.ZERO_STRING,
                    carriedLateTax: CONSTANTS.ZERO_STRING,
                    carryRentTax: CONSTANTS.ZERO_STRING,
                    carryRentAmount: CONSTANTS.ZERO_STRING,
                    scheduleRate: eachItem?.scheduleRate,
                    isAutopayEnroll: lastDetails?.autopayEnrollmentDate,
                    cardLastFour: lastDetails?.cardLastFour,
                    isReturnAgreement:
                      agreementIdQueryParam == eachItem.agreementId &&
                      (type == CONSTANTS.URLPARAMS_TYPE_CHARGE_OFF ||
                        type == CONSTANTS.URLPARAMS_TYPE_RETURN)
                        ? true
                        : false,
                    isItemInService:
                      eachItem?.agreementRateDetails?.isItemInService ==
                      CONSTANTS.STATUS_YES
                        ? true
                        : false,
                    isZeroPayment: false,
                    openDate: lastDetails?.openDate,
                    actualEpo: actualEpo,
                    isExchanged:
                      lastDetails?.isExchanged == CONSTANTS.ONE_STRING
                        ? true
                        : false,
                    parentAgreementId: lastDetails?.parentAgreementId,
                    cashPrice: eachItem.cashPrice ?? CONSTANTS.ZERO_STRING,
                    totalCost: eachItem.totalCost ?? CONSTANTS.ZERO_STRING,
                    pastDueRent: eachItem?.pastDueRent
                      ? Number(eachItem.pastDueRent).toFixed(2)
                      : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    actualPastDueRent: eachItem?.pastDueRent
                      ? Number(eachItem.pastDueRent).toFixed(2)
                      : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    isAcceptEPODiscounts: {
                      epoDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      pastDueDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      noteDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                    },
                    extensionAmount: extentionAmount ?? CONSTANTS.ZERO_NUMBER,
                    deliveryFee: deliveryFee ?? CONSTANTS.ZERO_NUMBER,
                    deliveryFeeTax: deliveryFeeTax ?? CONSTANTS.ZERO_NUMBER,
                    processingFee: processingFee ?? CONSTANTS.ZERO_NUMBER,
                    processingFeeTax: processingFeeTax ?? CONSTANTS.ZERO_NUMBER,
                    mattressFee: mattressFee ?? CONSTANTS.ZERO_NUMBER,
                    mattressFeeTax: mattressFeeTax,
                    storeMerchantId: storeDetails?.merchantId ?? null,
                    coItemDetails: lastDetails?.coItemDetails,
                    isNoteAgreement: eachItem?.isNoteAgreement,
                  });
                }
              }
            );

            if (coStatus != CONSTANTS.IS_CO_INITIAL) {
              eachStore?.clubPaymentDetails?.forEach(
                (eachClubItem: ClubPaymentDetails) => {
                  const clubDetails: ClubInformation =
                    getPaymentInfo.data?.paymentInfo?.clubInformation?.find(
                      (each: ClubInformation) =>
                        eachClubItem?.customerClubId == each?.customerClubId
                    );
                  const clubDaysLate =
                    getPaymentInfo.data?.paymentInfo
                      .clubDaysLateAndPaymentSchedule[0].noOfDaysLate;
                  const originAgreementId =
                    getPaymentInfo.data?.paymentInfo?.clubInformation[0]
                      ?.originAgreementId;
                  const clubDocumentSignStatus =
                    getPaymentInfo.data?.paymentInfo?.clubInformation[0]
                      ?.clubDocumentSignStatus;

                  const storeDetails =
                    getPaymentInfo.data?.paymentInfo?.storeInfo?.find(
                      (el: StoreInformation) =>
                        el.storeNumber == eachStore.storeNumber
                    );

                  if (
                    ((eachClubItem.clubStatus === CONSTANTS.CLUB_PENDING &&
                      eachClubItem.originAgreementStatus === CONSTANTS.PRELIM &&
                      coStatus === CONSTANTS.IS_CO_PRELIM) ||
                      !(
                        eachClubItem.clubStatus === CONSTANTS.CLUB_PENDING &&
                        eachClubItem.originAgreementStatus ===
                          CONSTANTS.PRELIM &&
                        coStatus === CONSTANTS.EMPTY_STRING
                      )) &&
                    Number(eachClubItem?.customerClubId) ===
                      Number(clubDetails?.customerClubId) &&
                    clubDetails.clubFlag
                  ) {
                    agreementDetailsArray.push({
                      agreementId: eachClubItem.customerClubId,
                      agreementStatus: eachClubItem.clubStatus,
                      customerId: eachClubItem?.customerId,
                      agreementNumber: eachClubItem.clubMembershipNumber,
                      agreementType: CONSTANTS.CLUB,
                      agreementDesc: clubDetails?.primaryCustomer,
                      agreementSchedule: eachClubItem.schedule,
                      originalSchedule: eachClubItem.schedule,
                      currentDueDate:
                        eachClubItem.currentDueDate || CONSTANTS.EMPTY_STRING,
                      nextDueDate:
                        eachClubItem.nextDueDate || CONSTANTS.EMPTY_STRING,
                      daysLate: clubDaysLate,
                      totalDueAmount: totalDueAmount(
                        eachClubItem.totalAmount,
                        eachClubItem.totalTax
                      ).toFixed(2),
                      sacDate: CONSTANTS.HYPHEN, // No SAC Date key in club response
                      epoAmount: CONSTANTS.ZERO_STRING, // No EPO key in club response
                      suspenseAmount: CONSTANTS.ZERO_STRING, //No suspense key in club response
                      lateLast10: clubDetails?.clublastLate10,
                      promoFreeDays: CONSTANTS.ZERO_STRING, //No promo free key in club response
                      rentPaid: CONSTANTS.ZERO_STRING, //No rentpaid key
                      lateFee: CONSTANTS.ZERO_STRING,
                      lateFeetax: CONSTANTS.ZERO_STRING,
                      originalLateFee: CONSTANTS.ZERO_STRING,
                      lateFeeTaxRate: CONSTANTS.ZERO_NUMBER,
                      carriedLateRent: CONSTANTS.ZERO_STRING,
                      numberOfPaymentsMade:
                        eachClubItem.clubStatus == CONSTANTS.STATUS_ACTIVE
                          ? CONSTANTS.ONE_STRING
                          : CONSTANTS.ZERO_STRING,
                      deliveryFlag: false, //Need to check the key
                      selected:
                        eachStore.storeNumber === storeNumber ? true : false,
                      storeNumber: eachStore.storeNumber,
                      totalAmount: eachClubItem.totalAmount,
                      totalTax: eachClubItem.totalTax,
                      suspenseDetails: {
                        suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        suspUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        transferSusAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      },
                      agreementRateDetails: eachClubItem.clubRateDetails,
                      isLDW: false,
                      isPolicy: false,
                      coverageStatus: CONSTANTS.EMPTY_STRING,
                      isCoverageActionPerformed: CONSTANTS.EMPTY_STRING,
                      ldwAmount: CONSTANTS.ZERO_STRING,
                      ldwTax: CONSTANTS.ZERO_STRING,
                      policyAmount: CONSTANTS.ZERO_STRING,
                      policyTax: CONSTANTS.ZERO_STRING,
                      isEpp: false,
                      switchoutDeliveryStatus: CONSTANTS.EMPTY_STRING,
                      taxRate: CONSTANTS.ZERO_STRING,
                      isLdwAllowed: false,
                      isPolicyAllowed: false,
                      ldwPercentage: CONSTANTS.ZERO_STRING,
                      epoTax: CONSTANTS.ZERO_STRING,
                      policyStatus: CONSTANTS.EMPTY_STRING,
                      carriedLateAmount: CONSTANTS.ZERO_STRING,
                      carriedLateTax: CONSTANTS.ZERO_STRING,
                      carryRentTax: CONSTANTS.ZERO_STRING,
                      carryRentAmount: CONSTANTS.ZERO_STRING,
                      scheduleRate: CONSTANTS.ZERO_STRING,
                      isAutopayEnroll: clubDetails?.autopayEnrollmentDate,
                      cardLastFour: clubDetails?.cardLastFour,
                      isReturnAgreement: false,
                      otherIncomeType: CONSTANTS.EMPTY_STRING,
                      isItemInService: false,
                      isZeroPayment: false,
                      openDate: CONSTANTS.EMPTY_STRING,
                      actualEpo: CONSTANTS.ZERO_STRING,
                      isExchanged: false,
                      parentAgreementId: CONSTANTS.EMPTY_STRING,
                      cashPrice: CONSTANTS.ZERO_STRING,
                      totalCost: CONSTANTS.ZERO_STRING,
                      pastDueRent: CONSTANTS.ZERO_STRING,
                      actualPastDueRent: CONSTANTS.ZERO_STRING,
                      isAcceptEPODiscounts: {
                        epoDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        pastDueDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        noteDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      },
                      extensionAmount: CONSTANTS.ZERO_NUMBER,
                      originAgreementId: originAgreementId,
                      clubDocumentSignStatus: clubDocumentSignStatus,
                      storeMerchantId: storeDetails?.merchantId ?? null,
                    });
                  }
                }
              );
            }

            if (coStatus == CONSTANTS.EMPTY_STRING) {
              eachStore.otherIncome?.forEach(
                (eachOtherIncomeItem: AmountDetails) => {
                  if (
                    eachStore.storeNumber === storeNumber &&
                    (CONSTANTS.NSFCHK_CCCB_ARRAY.includes(
                      eachOtherIncomeItem.itemCategory
                    ) ||
                      (eachOtherIncomeItem.itemCategory ==
                        CONSTANTS.RETCHKFEE &&
                        !otherIncomeTypes.includes(CONSTANTS.NSFCHK)))
                  ) {
                    const nsf =
                      eachOtherIncomeItem.itemCategory === CONSTANTS.NSFCHK ||
                      eachOtherIncomeItem.itemCategory === CONSTANTS.RETCHKFEE
                        ? true
                        : false;
                    const remainingNSF =
                      eachOtherIncomeItem.itemCategory == CONSTANTS.RETCHKFEE
                        ? true
                        : false;
                    const agreementType = nsf
                      ? CONSTANTS.NSF_CHECK
                      : CONSTANTS.CREDIT_CARD_CHARGE_BACK;
                    const totalDueAmount = remainingNSF
                      ? Number(eachOtherIncomeItem.otherIncomeInfo?.balanceFee)
                      : Number(
                          eachOtherIncomeItem.otherIncomeInfo?.balanceAmount
                        ) +
                        Number(eachOtherIncomeItem.otherIncomeInfo?.balanceFee);

                    const description = nsf
                      ? CONSTANTS.NSF_CHECK_REPAYMENT
                      : CONSTANTS.CREDIT_CARD_REPAYMENT;
                    const retChkFee = eachStore.otherIncome?.find(
                      (item: AmountDetails) =>
                        item.itemCategory == CONSTANTS.RETCHKFEE &&
                        item.receiptTransactionId ===
                          eachOtherIncomeItem.receiptTransactionId
                    );
                    agreementDetailsArray.push({
                      agreementId:
                        eachOtherIncomeItem?.receiptTransactionId ||
                        CONSTANTS.EMPTY_STRING,
                      agreementStatus: CONSTANTS.EMPTY_STRING,
                      agreementNumber:
                        eachOtherIncomeItem?.receiptTransactionId ||
                        CONSTANTS.EMPTY_STRING,
                      agreementType: agreementType,
                      agreementDesc: description,
                      agreementSchedule: CONSTANTS.EMPTY_STRING,
                      currentDueDate: CONSTANTS.EMPTY_STRING,
                      nextDueDate: CONSTANTS.EMPTY_STRING,
                      daysLate: CONSTANTS.ZERO_STRING,
                      totalDueAmount: Number(totalDueAmount).toFixed(2),
                      sacDate: CONSTANTS.HYPHEN,
                      epoAmount: CONSTANTS.ZERO_STRING,
                      suspenseAmount: CONSTANTS.ZERO_STRING,
                      lateLast10: CONSTANTS.ZERO_STRING,
                      promoFreeDays: CONSTANTS.ZERO_STRING,
                      rentPaid: CONSTANTS.ZERO_STRING,
                      lateFee: CONSTANTS.ZERO_STRING,
                      lateFeetax: CONSTANTS.ZERO_STRING,
                      originalLateFee: CONSTANTS.ZERO_STRING,
                      lateFeeTaxRate: CONSTANTS.ZERO_NUMBER,
                      carriedLateRent: CONSTANTS.ZERO_STRING,
                      numberOfPaymentsMade: CONSTANTS.ZERO_STRING,
                      deliveryFlag: false,
                      selected: true,
                      storeNumber:
                        eachOtherIncomeItem?.otherIncomeInfo?.storeNumber ||
                        CONSTANTS.EMPTY_STRING,
                      totalAmount: CONSTANTS.ZERO_STRING,
                      totalTax: Number(
                        retChkFee?.tax ?? CONSTANTS.ZERO_NUMBER
                      ).toFixed(2),
                      suspenseDetails: {
                        suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        suspUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        transferSusAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      },
                      agreementRateDetails: {},
                      isLDW: false,
                      isPolicy: false,
                      coverageStatus: CONSTANTS.EMPTY_STRING,
                      isCoverageActionPerformed: CONSTANTS.EMPTY_STRING,
                      ldwAmount: CONSTANTS.ZERO_STRING,
                      ldwTax: CONSTANTS.ZERO_STRING,
                      policyAmount: CONSTANTS.ZERO_STRING,
                      policyTax: CONSTANTS.ZERO_STRING,
                      isEpp: false,
                      switchoutDeliveryStatus: CONSTANTS.EMPTY_STRING,
                      taxRate: CONSTANTS.ZERO_STRING,
                      isLdwAllowed: false,
                      isPolicyAllowed: false,
                      ldwPercentage: CONSTANTS.ZERO_STRING,
                      epoTax: CONSTANTS.ZERO_STRING,
                      policyStatus: CONSTANTS.EMPTY_STRING,
                      carriedLateAmount: CONSTANTS.ZERO_STRING,
                      carriedLateTax: CONSTANTS.ZERO_STRING,
                      carryRentTax: CONSTANTS.ZERO_STRING,
                      carryRentAmount: CONSTANTS.ZERO_STRING,
                      scheduleRate: CONSTANTS.EMPTY_STRING,
                      isAutopayEnroll: CONSTANTS.NULL,
                      cardLastFour:
                        eachOtherIncomeItem.otherIncomeInfo
                          ?.cardNumberLastFour ?? CONSTANTS.NULL,
                      isReturnAgreement: false,
                      otherIncomeType: agreementType,
                      isItemInService: false,
                      isZeroPayment: false,
                      openDate: CONSTANTS.EMPTY_STRING,
                      actualEpo: CONSTANTS.EMPTY_STRING,
                      isExchanged: false,
                      parentAgreementId: CONSTANTS.EMPTY_STRING,
                      cashPrice: CONSTANTS.EMPTY_STRING,
                      totalCost: CONSTANTS.EMPTY_STRING,
                      pastDueRent: CONSTANTS.EMPTY_STRING,
                      actualPastDueRent: CONSTANTS.EMPTY_STRING,
                      isAcceptEPODiscounts: {
                        epoDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        pastDueDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                        noteDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                      },
                      extensionAmount: CONSTANTS.ZERO_NUMBER,
                      checkNumber:
                        eachOtherIncomeItem.otherIncomeInfo?.checkNumber ||
                        CONSTANTS.EMPTY_STRING,
                      checkWrittenBy:
                        eachOtherIncomeItem.otherIncomeInfo?.checkWrittenBy ||
                        CONSTANTS.EMPTY_STRING,
                      returnDate: dateFormat(
                        eachOtherIncomeItem.otherIncomeInfo?.returnDate,
                        CONSTANTS.MM_DD_YYYY
                      ),
                      amount: Number(
                        eachOtherIncomeItem.otherIncomeInfo?.amount
                      ).toFixed(2),
                      balanceAmount: Number(
                        eachOtherIncomeItem.otherIncomeInfo?.balanceAmount
                      ).toFixed(2),
                      balanceFee: Number(
                        eachOtherIncomeItem.otherIncomeInfo?.balanceFee
                      ).toFixed(2),
                      nsfFee: Number(
                        eachOtherIncomeItem.otherIncomeInfo?.balanceFee
                      ).toFixed(2),
                      nsfFeeTax: Number(
                        retChkFee?.tax ?? CONSTANTS.ZERO_NUMBER
                      ).toFixed(2),
                      originalNsfFee: Number(
                        eachOtherIncomeItem.otherIncomeInfo?.nsfFee
                      ).toFixed(2),
                      originalNsfFeeTax: Number(
                        retChkFee?.tax ?? CONSTANTS.ZERO_NUMBER
                      ).toFixed(2),
                      nsfFeeTaxRate: Number(
                        retChkFee?.taxRate ?? CONSTANTS.ZERO_NUMBER
                      ).toFixed(2),
                      customerAccountId: String(
                        eachOtherIncomeItem.otherIncomeInfo?.customerAccountId
                      ),
                      accountType:
                        eachOtherIncomeItem.otherIncomeInfo?.accountType,
                      storeTotalAmount: eachStore?.storeTotalAmount,
                    });
                  }
                }
              );
            }
          }
        );
        if (coStatus == CONSTANTS.IS_CO_INITIAL) {
          const adjustDueReq = await adjustDueDateCalciPayloadFormation(
            agreementDetailsArray
          );

          const dueDateCalciPayload: AdjustDueDateReq = {
            rateResponse: adjustDueReq,
            amountAdjusted: Number(
              agreementDetailsArray[0]?.agreementRateDetails?.coPaymentAmount
            ).toFixed(2),
          };

          const adjustDueDatecalciRes = await AdjustDueDatecalci(
            dueDateCalciPayload
          );
          console.log('adjustDueDatecalciRes', adjustDueDatecalciRes);
          agreementDetailsArray.forEach((agreementItems: AgreementDetails) => {
            if (
              adjustDueDatecalciRes.length &&
              agreementItems.agreementId == adjustDueDatecalciRes[0].agreementId
            ) {
              setAdjustedDueDate(adjustDueDatecalciRes[0].nextDueDate);
              agreementItems.nextDueDate = adjustDueDatecalciRes[0].nextDueDate;
              agreementItems.totalDueAmount = totalDueAmount(
                adjustDueDatecalciRes[0].totalAmount,
                adjustDueDatecalciRes[0].totalTax
              ).toFixed(2);
              agreementItems.totalAmount = adjustDueDatecalciRes[0].totalAmount;
              agreementItems.totalTax = adjustDueDatecalciRes[0].totalTax;
            }
          });
        }
        if (agreementDetailsArray.length)
          setAgreementDetailsDeepClone(agreementDetailsArray);

        const remainingCreditsDetails =
          getPaymentInfo.data?.paymentInfo?.remainingCredits;

        if (coStatus == CONSTANTS.IS_CO_INITIAL) {
          let webLeadExist = false;

          for (const obj of remainingCreditsDetails) {
            if (
              obj.bucket == CONSTANTS.WEB_LEAD_DEPOSIT &&
              obj.storeNumber == storeNumber
            ) {
              obj.accountBalance = (
                Number(obj.accountBalance) + Number(webLeadUsed)
              ).toString();
              webLeadExist = true;
              break;
            }
          }

          if (!webLeadExist) {
            const webLeadObj = {
              customerId: customerId,
              accountBalance: webLeadUsed,
              bucket: CONSTANTS.WEB_LEAD_DEPOSIT,
              storeNumber: storeNumber,
            };
            remainingCreditsDetails.push(webLeadObj);
          }
        }

        const customerInfoObj: Customer = {
          customerId: customerId,
          customerCardDetails: cardInfo.data,
          customerDetails: customerDetails,
          customerVerifiedStatus: customerVerification,
          isEppCustomer:
            customerByIdInfo.data?.data?.GetCustomer?.value?.coworker ??
            CONSTANTS.EMPTY_STRING,
          schedule: employerReferences
            ? employerReferences[0].employerPayscheduleDesc
            : CONSTANTS.HYPHEN,
          dayPaid: employerReferences
            ? employerReferences[0].daysPaidDesc
            : CONSTANTS.HYPHEN,

          amountUsed: {
            chargeCardAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            coa: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            coaUsed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            webLeadUsed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            carryRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            convenienceFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            ipSuspenseUsed: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            cardOverPayment: false,
            tenderDetails: {
              orderId: CONSTANTS.ZERO_NUMBER,
              cash: {
                amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                type: CONSTANTS.CASH,
                orderId: CONSTANTS.ZERO_NUMBER,
              },
              card: [],
              cardPreviousValue: {},
              checkDetails: [],
              moneyOrderDetails: [],
            },
            remainingAmountDue: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            donationRemainingAmountDue: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            cardDetails: [],
          },
          remainingCredits: remainingCreditsDetails,
          carryRentEnable: false, //used to enable or disable the carry rent toggle while checking or unchecking agreement
          customerPaymentInfo: customerPaymentDetails,
          customerContactDetails: customerInfoValue?.phones,
          customerAddressDetails: customerInfoValue?.addresses,
          numberOfPaymentsMadeInfo:
            getPaymentInfo.data?.numberOfPaymentsMadeInfo,
          customerFees: getPaymentInfo.data?.commonModuleRes?.customerFees,
          customerCardInfo: cardInfo.data,
          carriedRent: CONSTANTS.ZERO_NUMBER,
          donation: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          carryRentToggleEnabled: false,
          change: {
            type: CONSTANTS.EMPTY_STRING,
            amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          },
          rsdAgreementInfo: [], //Refund security Deposit Agreements
          convinenceFee: convinenceFee,
          userEnteredDonationAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        };
        console.log('customerInfoObj', customerInfoObj);
        setCustomerInfo(customerInfoObj);

        setCustomerInfoDeepClone(JSON.parse(JSON.stringify(customerInfoObj)));
        if (
          customerPaymentDetails?.commitment &&
          customerPaymentDetails?.commitment[0]?.commitmentStatusCode ==
            CONSTANTS.OP &&
          customerPaymentDetails?.commitment[0]?.createdSource ==
            CONSTANTS.RACDB
        ) {
          customerPaymentDetails?.commitment[0]?.commitmentDetail.forEach(
            (item: CommitmentDetailsInfo) => {
              commitmentAgreementDetailsArray.push({
                agreementId: item.contractId,
                amount: item.amountCommited,
                nextDueDate: item.nextDueDate,
                commitmentType: item.commitmentTransDesc,
                commitmentTypeRefCode: item.commitmentTransRefCode,
                commitmentTypeValue: item.commitmentTransValue,
                contractTypeId: item.contractTypeId,
              });
            }
          );
        }
        console.log(
          'commitmentAgreementDetailsArray',
          commitmentAgreementDetailsArray
        );
        const finalResponse: AgreementDetails[] = [];
        const updateFinalResponse = () => {
          agreementDetails.forEach((agreementItems) => {
            if (
              (!finalResponse.some(
                (el) => el.agreementId == agreementItems.agreementId
              ) ||
                agreementItems.agreementId == CONSTANTS.ADJUST ||
                agreementItems.agreementId == CONSTANTS.ADJUST_BACK_RENT) &&
              agreementItems.agreementId != CONSTANTS.EMPTY_STRING
            )
              finalResponse.push(agreementItems);
          });

          finalResponse.length > CONSTANTS.ZERO_NUMBER &&
            setAgreementDetails(finalResponse);

          if (commitmentAgreementDetailsArray.length > 0) {
            setCommitmentLoad(true);
          }
        };

        if (Number(agreementId)) {
          agreementDetailsArray.forEach((agreementItems: AgreementDetails) => {
            if (agreementItems.agreementId == agreementId)
              finalResponse.push({
                ...agreementItems,
                selected: true,
                disabled: true,
              });
            else if (
              agreementItems.agreementType == CONSTANTS.CLUB &&
              agreementItems.agreementStatus == CONSTANTS.CLUB_PENDING
            )
              finalResponse.push({
                ...agreementItems,
                selected: true,
                disabled: false,
              });
            else if (agreementItems.isExchanged)
              finalResponse.push({
                ...agreementItems,
                selected: false,
                disabled: true,
              });
            else
              finalResponse.push({
                ...agreementItems,
                selected: false,
                disabled: false,
              });
          });

          updateFinalResponse();
          setAgreementDetailsDeepClone(
            JSON.parse(JSON.stringify(finalResponse))
          );
        } else if (commitmentAgreementDetailsArray.length > 0) {
          const commitmentPriorityResponse = await commitmentPriorityFormation(
            commitmentAgreementDetailsArray
          );
          const commitmentTakenId: (string | number)[] = [];

          commitmentPriorityResponse.forEach((item: commitmentPriorityArray) =>
            commitmentTakenId.push(item.agreementID)
          );

          console.log('commitmentPriorityResponse', commitmentPriorityResponse);
          const responseArray: AgreementDetails[] = [];
          let adjustmentTypeArray: string[] = [];
          const adjustmentObject: AdjustmentObject = {};
          let ldwTerminated = false;

          for (const agreementItems of agreementDetailsArray) {
            for (const commitmentItems of commitmentPriorityResponse) {
              if (agreementItems.agreementId == commitmentItems.agreementID) {
                adjustmentTypeArray = [];
                for (const el of commitmentItems.adjustedInfo) {
                  if (el.type != null) {
                    adjustmentTypeArray.push(el.type);
                    switch (el.type) {
                      case CONSTANTS.FTT:
                        adjustmentObject.type = (
                          el?.value as FreeTimeObj
                        )?.freeTimeType;
                        adjustmentObject.nextDueDate = (
                          el?.value as FreeTimeObj
                        )?.nextDueDate;
                        adjustmentObject.waiveLDW = (
                          el?.value as FreeTimeObj
                        )?.waiveOptService;
                        break;

                      case CONSTANTS.DDA:
                        adjustmentObject.nextDueDate = el.value as string;
                        break;

                      case CONSTANTS.ES:
                        adjustmentObject.schedule = el.value as string;
                        break;

                      case CONSTANTS.AS:
                        adjustmentObject.addSuspense = el.value as string;
                        break;

                      case CONSTANTS.US:
                        adjustmentObject.useSuspense = el.value as string;
                        break;

                      case CONSTANTS.TAS:
                        adjustmentObject.transferAddSuspense =
                          el.value as string;
                        break;

                      case CONSTANTS.TUS:
                        adjustmentObject.transferUseSuspense =
                          el.value as string;
                        break;

                      case CONSTANTS.TOS:
                        adjustmentObject.optionalServiceAvailable =
                          el.value as string;
                        adjustmentObject.optionalServiceValue =
                          CONSTANTS.STATUS_NO;
                        if (el.value == CONSTANTS.LDW) ldwTerminated = true;
                        break;

                      case CONSTANTS.AOS:
                        adjustmentObject.optionalServiceAvailable =
                          el.value as string;
                        adjustmentObject.optionalServiceValue =
                          CONSTANTS.STATUS_YES;
                        break;

                      case CONSTANTS.LFW:
                        adjustmentObject.waivedLateFee = el.value as string;
                        break;

                      case CONSTANTS.LFC:
                        adjustmentObject.carriedLateFee = el.value as string;
                        break;

                      case CONSTANTS.AEF:
                        adjustmentObject.isAcceptEPODiscounts =
                          await epoCalculation(
                            agreementDetailsArray,
                            el.value as AEFObj,
                            commitmentItems.agreementID
                          );
                        break;
                    }
                  } else if (agreementItems.agreementType == CONSTANTS.CLUB) {
                    agreementItems.isnullCommitment = true;
                  }
                }
                const payloadFormation = await commonModulePayloadFormation(
                  [agreementItems],
                  adjustmentObject,
                  adjustmentTypeArray
                );
                responseArray.push(...payloadFormation);
              }
            }
          }
          const commonPayload = {
            customerId: customerId,
            storeNumber: storeNumber,
            checkedAgr: responseArray,
          };
          const commonModulePayload = await calculationModulePayloadFormation(
            commonPayload
          );
          const commonModuleServiceRes = await calculatePaymentModule(
            commonModulePayload
          );
          const commonModuleResponse =
            commonModuleServiceRes.data.paymentDetails;
          const commonModuleResponseId: string[] = [];

          commonModuleResponse.forEach((item: PaymentDetails) => {
            item?.agreementPaymentDetails.forEach(
              (el: AgreementPaymentDetails) => {
                commonModuleResponseId.push(el.agreementId);
              }
            );
            item?.clubPaymentDetails.forEach((el: ClubPaymentDetails) => {
              commonModuleResponseId.push(el.customerClubId);
            });
          });

          for (const el of agreementDetailsArray) {
            for (const responseItem of commonModuleResponse) {
              let commonModuleAgreement: (AgreementPaymentDetails &
                ClubPaymentDetails)[] = [];
              if (el.agreementType != CONSTANTS.CLUB) {
                commonModuleAgreement =
                  responseItem.agreementPaymentDetails.filter(
                    (item: AgreementPaymentDetails) =>
                      item.agreementId == el.agreementId
                  );
              } else {
                commonModuleAgreement = responseItem.clubPaymentDetails.filter(
                  (item: ClubPaymentDetails) =>
                    item.customerClubId == el.agreementId
                );
              }
              console.log(
                'commonModuleAgreement[0] in commitment',
                commonModuleAgreement[0]
              );
              if (
                el.agreementId == commonModuleAgreement[0]?.agreementId ||
                el.agreementId == commonModuleAgreement[0]?.customerClubId
              ) {
                const adjustedTypeArray: string[] = [];
                const adjustedDetails = commitmentPriorityResponse.find(
                  (agr) => agr.agreementID == el.agreementId
                );
                console.log('adjustedDetails', adjustedDetails);

                adjustedDetails?.adjustedInfo.forEach((el) =>
                  adjustedTypeArray.push(el.type as string)
                );

                console.log('adjustedTypeArray', adjustedTypeArray);

                const lateFeeAmount = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.LTRPAYFEE,
                  CONSTANTS.AMOUNT
                );

                const latePifFee = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.LTPIFFEE,
                  CONSTANTS.AMOUNT
                );

                const lateFeeTax = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.LTRPAYFEE,
                  CONSTANTS.TAX
                );

                const latePifFeeTax = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.LTPIFFEE,
                  CONSTANTS.TAX
                );

                const waivedFee = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.CLFEEWAV,
                  CONSTANTS.AMOUNT
                );

                const carriedLateFee = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.CLFEE,
                  CONSTANTS.AMOUNT
                );

                const pastDueValue = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.LTRPAY,
                  CONSTANTS.AMOUNT
                );

                const pastDueDiscount = reduceMethod(
                  commonModuleAgreement[0]?.amountDetails,
                  CONSTANTS.ITEM_CATEGORY,
                  CONSTANTS.LTRPAYDSCOUNT[0],
                  CONSTANTS.AMOUNT
                );

                const daysDiff: number = moment(
                  adjustmentObject.nextDueDate
                ).diff(moment(commonModuleAgreement[0].currentDueDate), 'days');

                finalResponse.push({
                  ...el,
                  agreementType:
                    commonModuleAgreement[0].agreementType ?? el.agreementType,
                  agreementRateDetails:
                    commonModuleAgreement[0].agreementRateDetails ||
                    commonModuleAgreement[0].clubRateDetails,
                  nextDueDate: commonModuleAgreement[0].nextDueDate
                    ? dueDateFormat(commonModuleAgreement[0].nextDueDate)
                    : commonModuleAgreement[0].isFinalPayment
                    ? dueDateFormat(commonModuleAgreement[0].isFinalPayment)
                    : el.nextDueDate,
                  totalAmount: commonModuleAgreement[0].totalAmount,
                  totalTax: commonModuleAgreement[0].totalTax,
                  totalDueAmount: totalDueAmount(
                    commonModuleAgreement[0].totalAmount,
                    commonModuleAgreement[0].totalTax
                  ).toFixed(2),
                  isAdjustDuedateEdit:
                    adjustedTypeArray.includes(CONSTANTS.DDA) ?? undefined,
                  isFreeTimeEdit:
                    adjustedTypeArray.includes(CONSTANTS.FTT) ?? undefined,
                  freeTime: adjustedTypeArray.includes(CONSTANTS.FTT)
                    ? {
                        freeTimeType:
                          adjustmentObject.type || CONSTANTS.EMPTY_STRING,
                        freeTimeDays: String(daysDiff),
                        isOptServiceWaived:
                          (adjustmentObject.waiveLDW as boolean) || false,
                      }
                    : undefined,
                  isEditScheduleEdited:
                    adjustedTypeArray.includes(CONSTANTS.ES) ?? undefined,
                  acceptEpoFlag: adjustedTypeArray.includes(CONSTANTS.AEF)
                    ? 1
                    : 0,
                  epoAmount: adjustedTypeArray.includes(CONSTANTS.AEF)
                    ? (
                        Number(commonModuleAgreement[0].totalAmount) +
                        Number(commonModuleAgreement[0].totalTax)
                      ).toFixed(2)
                    : el.epoAmount,
                  epoTax: adjustedTypeArray.includes(CONSTANTS.AEF)
                    ? commonModuleAgreement[0].totalTax
                    : el.epoTax,
                  pastDueRent: pastDueDiscount
                    ? Number(pastDueValue - pastDueDiscount).toFixed(2)
                    : Number(pastDueValue).toFixed(2),
                  isAcceptEPODiscounts: adjustedTypeArray.includes(
                    CONSTANTS.AEF
                  )
                    ? {
                        ...(adjustmentObject.isAcceptEPODiscounts ??
                          el.isAcceptEPODiscounts),
                      }
                    : el.isAcceptEPODiscounts,
                  agreementSchedule:
                    commonModuleAgreement[0].editedSchedule ??
                    el.agreementSchedule,
                  editSchedule: adjustedTypeArray.includes(CONSTANTS.ES)
                    ? commonModuleAgreement[0].editedSchedule
                    : undefined,
                  suspenseDetails: {
                    suspAdd: adjustedTypeArray.includes(CONSTANTS.AS)
                      ? adjustmentObject?.addSuspense ??
                        CONSTANTS.ZREO_TWO_DECIMAL_STRING
                      : el.suspenseDetails.suspAdd,
                    suspUse: adjustedTypeArray.includes(CONSTANTS.US)
                      ? adjustmentObject?.useSuspense ??
                        CONSTANTS.ZREO_TWO_DECIMAL_STRING
                      : el.suspenseDetails.suspUse,
                    transferSusAdd: adjustedTypeArray.includes(CONSTANTS.TAS)
                      ? adjustmentObject?.transferAddSuspense ??
                        CONSTANTS.ZREO_TWO_DECIMAL_STRING
                      : el.suspenseDetails.transferSusAdd,
                    transferSusUse: adjustedTypeArray.includes(CONSTANTS.TUS)
                      ? adjustmentObject?.transferUseSuspense ??
                        CONSTANTS.ZREO_TWO_DECIMAL_STRING
                      : el.suspenseDetails.transferSusUse,
                  },
                  isCoverageActionPerformed:
                    adjustedTypeArray.includes(CONSTANTS.TOS) ||
                    adjustedTypeArray.includes(CONSTANTS.AOS)
                      ? adjustmentObject.optionalServiceValue
                      : el.isCoverageActionPerformed,
                  isLDWActionPerformed:
                    (adjustedTypeArray.includes(CONSTANTS.TOS) ||
                      adjustedTypeArray.includes(CONSTANTS.AOS)) &&
                    adjustmentObject.optionalServiceAvailable == CONSTANTS.LDW
                      ? adjustmentObject.optionalServiceValue
                      : undefined,
                  isPolicyActionPerformed:
                    (adjustedTypeArray.includes(CONSTANTS.TOS) ||
                      adjustedTypeArray.includes(CONSTANTS.AOS)) &&
                    adjustmentObject.optionalServiceAvailable ==
                      CONSTANTS.POLICY
                      ? adjustmentObject.optionalServiceValue
                      : undefined,
                  selected: commitmentTakenId.includes(el.agreementId) ?? false,
                  disabled:
                    ldwTerminated && el.agreementType == CONSTANTS.CLUB
                      ? true
                      : false,
                  carriedLateFee: carriedLateFee
                    ? carriedLateFee.toFixed(2)
                    : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                  waivedLateFee: waivedFee
                    ? waivedFee.toFixed(2)
                    : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                  lateFee: lateFeeAmount
                    ? lateFeeAmount
                    : latePifFee
                    ? latePifFee
                    : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                  lateFeeTax: lateFeeTax
                    ? lateFeeTax
                    : latePifFeeTax
                    ? latePifFeeTax
                    : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                  isCommitmentTaken:
                    commitmentTakenId.includes(el.agreementId) ?? undefined,
                });
              }
              if (finalResponse.length === commonModuleResponseId.length) break;
            }
            if (!commonModuleResponseId.includes(el.agreementId))
              finalResponse.push({
                ...el,
                selected: false,
                disabled:
                  ldwTerminated && el.agreementType == CONSTANTS.CLUB
                    ? true
                    : false,
              });
          }
          updateFinalResponse();
        } else if (crType) {
          agreementDetailsArray.forEach((agreemnetItems: AgreementDetails) => {
            agreemnetItems.agreementNumber ==
            locationData?.state?.[CONSTANTS.RETENTION_DATA]?.agreementNumber
              ? finalResponse.push({
                  ...agreemnetItems,
                  selected: true,
                })
              : finalResponse.push({ ...agreemnetItems, selected: false });
          });

          updateFinalResponse();
          setAgreementDetailsDeepClone(
            JSON.parse(JSON.stringify(finalResponse))
          );
        } else {
          agreementDetailsArray.forEach((agreemnetItems: AgreementDetails) => {
            agreemnetItems.isExchanged
              ? finalResponse.push({
                  ...agreemnetItems,
                  selected: false,
                  disabled: true,
                })
              : finalResponse.push({ ...agreemnetItems, disabled: false });
          });

          updateFinalResponse();
        }

        setFlag(true);

        setPaymentSchedule(getPaymentInfo.data?.paymentScheule?.references);

        setStoreInfo({
          storeDetails: getPaymentInfo.data?.paymentInfo?.storeInfo,
          swipeDevices: swipeDeviceInfo.data,
        });

        const otherIncome =
          getPaymentInfo.data?.commonModuleRes?.paymentDetails?.filter(
            (el: PaymentDetails) => el.storeNumber == storeNumber
          );

        setOtherIncomeDetails(otherIncome[0]?.otherIncome);

        const customerName =
          customerByIdInfo.data?.data?.GetCustomer?.value?.firstName +
          CONSTANTS.STRING_WITH_SINGLE_SPACE +
          customerByIdInfo.data?.data?.GetCustomer?.value?.lastName;
        customerName && setCustomerNameNav(customerName);
        /**
         * PSC_CO_NO_07 to PSC_CO_NO_09
         * The Charge-off/Skip stolen agreements are formed and updated to context variable
         */
        const chargeOffData = otherIncome[0]?.otherIncome
          .filter(
            (x: AmountDetails) =>
              x.itemCategory == CONSTANTS.RESTITPAY &&
              x.otherIncomeInfo?.remainingEpoAmount !=
                CONSTANTS.ZREO_TWO_DECIMAL_STRING
          )
          .map((val: AmountDetails) => {
            return {
              agreementId: Number(val?.agreementId),
              agreementNumber: val?.otherIncomeInfo?.agreementNumber,
              closeDate:
                val?.otherIncomeInfo?.closeDate != null &&
                val?.otherIncomeInfo?.closeDate != undefined
                  ? moment(val?.otherIncomeInfo?.closeDate).format('MM/DD/YYYY')
                  : CONSTANTS.HYPHEN,
              epoAmountOwed: Number(val?.otherIncomeInfo?.epoAmountOwed),
              epoAmountRemaining: Number(
                val?.otherIncomeInfo?.remainingEpoAmount
              ),
              epoAmountWithtax: Number(
                Number(val?.otherIncomeInfo?.remainingEpoAmount) +
                  Number(val.tax)
              ).toFixed(2),
              amount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
              reason: CONSTANTS.SELECT,
              reasonRefcode: CONSTANTS.SELECT,
              taxRate: val.taxRate,
            };
          });
        setOtheIncomeChargeOffDetails(chargeOffData);
        setOtheIncomeChargeOffDetailsDeepClone(chargeOffData);
        // setApiEnable(false);
      } else {
        if (
          getPaymentInfo.data.commonModuleRes?.code ==
            CONSTANTS.FOUR_HUNDRED_NUMBER &&
          getPaymentInfo.data.commonModuleRes?.error ==
            `customerId - ${String(customerId)} is Not Valid`
        ) {
          setPopupPriorityString(CONSTANTS.CUSTOMER_POPUPS);
          setPopupEnable({
            ...popupEnable,
            primaryAddressZipPhonePopup: true,
            popupText: CONSTANTS.NO_PRIMARY_ADDRESS_TEXT,
          });
        } else {
          setPopupPriorityString(CONSTANTS.SOMETHING_WENT_WRONG_POPUP);
          setPopupEnable({ ...popupEnable, somethingWentWrong: true });
        }
      }
    } catch (err) {
      console.log('paymentInfoParentCatch', err);
      setPopupPriorityString(CONSTANTS.SOMETHING_WENT_WRONG_POPUP);
      setPopupEnable({ ...popupEnable, somethingWentWrong: true });
    }

    setRenderContext(!renderContext);
  };
  /**
   * PS_PI_149 - PS_PI_161
   * Declaring getCoWorkerDetails()
   * Checks for the value in containerData, if true set the coWorkerDetails based on that value
   * Else, makes api call for getEmployeeId and getRole to get coWorkerDetails
   */
  const getCoWorkerDetails = async () => {
    if (containerData != undefined) {
      let enableCashPayment = false;
      const menuConfig = containerData.GetMenuConfig();
      if (
        menuConfig &&
        menuConfig.some(
          (item: MenuConfig) =>
            item.appComponentFunctionName == CONSTANTS.CASH_PAYMENT
        )
      ) {
        enableCashPayment = validateCashPayment(menuConfig);
      }
      setCoworkerDetails({
        employeeId: containerData.GetEmployeeId(),
        role: containerData.GetRole(),
        firstName: CONSTANTS.EMPTY_STRING,
        lastname: CONSTANTS.EMPTY_STRING,
        cwsUser: enableCashPayment,
      });
    } else {
      let enableCashPayment = false;
      const GetEmployeeIDResponse = await GetEmployeeID();

      const getRolePayload = {
        coworkerId: GetEmployeeIDResponse?.data?.employeeId,
      };
      const roleResponse = await GetRole(getRolePayload);

      const menuConfig: MenuConfig[] = roleResponse.data.menuConfig;

      if (
        menuConfig &&
        menuConfig.some(
          (item: MenuConfig) =>
            item.appComponentFunctionName == CONSTANTS.CASH_PAYMENT
        )
      ) {
        enableCashPayment = validateCashPayment(menuConfig);
      }
      setCoworkerDetails({
        employeeId: roleResponse?.data?.coworkerProfile?.employeeId,
        role: roleResponse?.data?.coworkerProfile?.role,
        firstName: roleResponse?.data?.coworkerProfile?.firstName,
        lastname: roleResponse?.data?.coworkerProfile?.lastName,
        cwsUser: enableCashPayment,
      });
    }
  };

  const epoCalculation = async (
    agreementDetailData: AgreementDetails[],
    value: AEFObj,
    agreementId: string | number
  ) => {
    const agreementData = agreementDetailData.find(
      (agreement: AgreementDetails) => agreement.agreementId == agreementId
    );
    const agreementAmount = Math.max(
      Number(value.epoAmount) -
        Number(agreementData?.agreementRateDetails?.remainingNoteCost),
      0
    );
    const noteAmount =
      agreementAmount == CONSTANTS.ZERO_NUMBER
        ? value.epoAmount
        : agreementData?.agreementRateDetails?.remainingNoteCost;

    let noteDiscount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    let epoDiscount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    let pastDueDiscount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    if (agreementData?.isNoteAgreement == CONSTANTS.STATUS_YES) {
      const epoWithoutNote = (
        Number(agreementData?.actualEpo) -
        Number(agreementData?.agreementRateDetails?.remainingNoteCost)
      ).toFixed(2);
      noteDiscount = Math.abs(
        Number(agreementData?.agreementRateDetails?.remainingNoteCost) -
          Number(noteAmount)
      ).toFixed(2);
      epoDiscount = Math.abs(
        Number(epoWithoutNote) - Number(agreementAmount) < CONSTANTS.ZERO_NUMBER
          ? Number(agreementData?.actualEpo) -
              Number(agreementData?.agreementRateDetails?.remainingNoteCost)
          : Number(agreementData?.actualEpo) -
              Number(agreementData?.agreementRateDetails?.remainingNoteCost) -
              Number(agreementAmount)
      ).toFixed(2);
      pastDueDiscount = Math.abs(
        Number(agreementData?.actualPastDueRent) - Number(value.pastDueRent)
      ).toFixed(2);
    } else {
      epoDiscount = Math.abs(
        Number(agreementData?.actualEpo) - Number(value.epoAmount)
      ).toFixed(2);
      pastDueDiscount = Math.abs(
        Number(agreementData?.actualPastDueRent) - Number(value.pastDueRent)
      ).toFixed(2);
    }

    return {
      epoDiscount: epoDiscount,
      pastDueDiscount: pastDueDiscount,
      noteDiscount: noteDiscount,
    };
  };
  /**
   * PS_PI_165 - PS_PI_167
   * Declaring pageLoadSomeThingWentWrong()
   * Displays something went wrong popup
   * @returns
   */
  const pageLoadSomeThingWentWrong = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography
            variant={'body2'}
            className={classes.RACPOPMsg}
            data-testid="somethingWentWrongPopup"
          >
            {`Something went wrong !. Please use ‘Report an Issue’ quick link under help, or Self Service portal to report this issue, and someone from the support team will promptly assist you with this request`}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifycontentcenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            data-testid="okBtn"
            onClick={() => {
              if (
                locationData?.state?.[CONSTANTS.REDIRECT_SOURCE] ===
                CONSTANTS.AM
              ) {
                history.push({ pathname: `/am/customer/${customerId}` });
              } else {
                history.push({
                  pathname: `/payment1/paymentsearch`,
                });
              }
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  /**
   * PS_PI_178 - PS_PI_188
   * Handles loader based success state of paymentInfo and getTax
   * Displays PaymentTriangleComponent if customerOrderPayment is empty string, else displays wizard
   * Call PaymentPrimaryDetails and PaymentFooter
   * Handles popup components ith RACModalCard
   */
  return (
    <Grid container data-testid="paymentfullscreen">
      {!(getPaymentInfo.isSuccess && flag) ? (
        <Grid className={classes.masterLoader}>
          <Grid className={classes.Loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <>
        {/**BreadCrumbs starting part */}
        <Grid item md={12}>
          <List className={`${classes.breadcrumb} ${classes.py1}`}>
            <ListItem className={classes.breadcrumbItem}>
              <a
                className={classes.breadcrumbInActive}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/dashboard`)}
              >
                Dashboard
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItem1} ${classes.breadcrumbArrow} ${classes.breadcrumbInActive}`}
            >
              <a
                className={classes.breadcrumbInActive}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push(`/payment1/paymentinformation/${customerId}/0`)
                }
              >
                Payment
              </a>
            </ListItem>
            <ListItem
              className={`${classes.breadcrumbItemActive} ${classes.breadcrumbArrow}`}
            >
              <a
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  history.push(`/customer/update/${customerId}/customer`)
                }
                data-testid="Customer-Name"
              >
                {customerNameNav
                  ? customerNameNav + ` - ` + customerId
                  : CONSTANTS.EMPTY_STRING}
              </a>
            </ListItem>
          </List>
        </Grid>
        {/**BreadCrumbs ending part */}

        {customerOrderPayment == CONSTANTS.EMPTY_STRING ? (
          <Grid className={internalStyle.displayContent}>
            <PaymentTriangleComponent />
          </Grid>
        ) : (
          <Grid className={internalStyle.displayContent}>
            <Wizard />
          </Grid>
        )}

        <Grid className={internalStyle.displayContent}>
          <PaymentPrimaryDetails />
        </Grid>

        <Grid container data-testid="paymentfullscreen">
          <Grid
            item
            className={`${classes.toolbar} ${classes.mx0} ${classes.raccollg12}`}
          >
            <PaymentFooter />
          </Grid>
        </Grid>

        {popupEnable.multiplePrimaryAddressPopup &&
          popupPriorityString == CONSTANTS.CUSTOMER_POPUPS && (
            <CustomerPrimaryAddressPopup
              primaryAddr={primaryAddr}
              onClose={() => {
                setPopupPriorityString(secondPriority);
                setPopupEnable({
                  ...popupEnable,
                  multiplePrimaryAddressPopup: false,
                });
              }}
              secondPriority={secondPriority}
              setPopupValue={setPopupPriorityString}
            />
          )}

        {retentionLoadPopup &&
          flag &&
          popupPriorityString == CONSTANTS.RETENTION_POPUPS && (
            <RACModalCard
              isOpen={retentionLoadPopup}
              maxWidth="xs"
              title={
                crType == CONSTANTS.FREETIME
                  ? CONSTANTS.RETENTION_EXTENSION
                  : CONSTANTS.EMPTY_STRING
              }
              borderRadius={'25px !important'}
            >
              <RenderRetentionAlert
                retentionText={crType}
                stateData={locationData?.state?.[CONSTANTS.RETENTION_DATA]}
                setRetentionLoadPopup={() => setRetentionLoadPopup(false)}
              />
            </RACModalCard>
          )}

        {modalCardEnable.onLoadFreeTimePopup && (
          <HandleFreeTime
            retentionData={locationData.state}
            setPopupValue={() =>
              setPopupPriorityString(CONSTANTS.SUSPENSE_POPUPS)
            }
          />
        )}

        {modalCardEnable.onLoadAcceptEPO && (
          <AcceptEpo
            isOpen={modalCardEnable.onLoadAcceptEPO}
            selectedAgreement={agreementDetails.filter(
              (el: AgreementDetails) => el.selected
            )}
            onClose={() => {
              if (locationData)
                setPopupPriorityString(CONSTANTS.SUSPENSE_POPUPS);
              setModalCardEnable({
                ...modalCardEnable,
                onLoadAcceptEPO: false,
              });
            }}
          />
        )}

        {modalCardEnable.onLoadDueDateAdjustment && (
          <HandleAdjustDueDate
            setPopupValue={() =>
              setPopupPriorityString(CONSTANTS.PAYMENT_ORIGIN_POPUP)
            }
          />
        )}
        {/**
         * Need to display multiple primary address popup first and then followed by invalid phone popup
         */}
        {popupEnable.primaryAddressZipPhonePopup &&
          !popupEnable.multiplePrimaryAddressPopup &&
          popupPriorityString == CONSTANTS.CUSTOMER_POPUPS && (
            <RACModalCard
              isOpen={popupEnable.primaryAddressZipPhonePopup}
              borderRadius="20px"
              maxWidth="xs"
            >
              <PrimaryAddrsValidationPopup text={popupEnable.popupText} />
            </RACModalCard>
          )}

        {popupEnable.somethingWentWrong &&
          popupPriorityString == CONSTANTS.SOMETHING_WENT_WRONG_POPUP && (
            <RACModalCard
              isOpen={popupEnable.somethingWentWrong}
              borderRadius="20px"
              maxWidth="xs"
              onClose={() =>
                setPopupEnable({ ...popupEnable, somethingWentWrong: true })
              }
            >
              {pageLoadSomeThingWentWrong()}
            </RACModalCard>
          )}

        {coDueDateAdjustmentPopup &&
          flag &&
          popupPriorityString == CONSTANTS.CODUEDATE_POPUPS && (
            <RACModalCard
              isOpen={coDueDateAdjustmentPopup}
              maxWidth="sm"
              borderRadius={'25px !important'}
            >
              <CustomerOrderDDAPopup
                nextDueDate={adjustedDuedate}
                setCoDueDateAdjustmentPopup={() =>
                  setCoDueDateAdjustmentPopup(false)
                }
              />
            </RACModalCard>
          )}

        {customerOrderPayment == CONSTANTS.EMPTY_STRING &&
          agreementDetails[0]?.agreementId != CONSTANTS.EMPTY_STRING && (
            <>
              <HandleFinalSuspense
                popupValue={popupPriorityString}
                setPopupValue={setPopupPriorityString}
              />

              <RACExchangeAlertPopUp
                popupValue={popupPriorityString}
                setPopupValue={setPopupPriorityString}
              />
            </>
          )}

        {originPopup &&
          popupPriorityString == CONSTANTS.PAYMENT_ORIGIN_POPUP &&
          !(
            agreementDetails.length == CONSTANTS.ZERO_NUMBER ||
            (agreementDetails.length == CONSTANTS.ONE_NUMBER &&
              agreementDetails[0].agreementId == CONSTANTS.EMPTY_STRING)
          ) &&
          !agreementDetails.some((el: AgreementDetails) =>
            CONSTANTS.CCCB_NSF_ARRAY.includes(el.agreementType)
          ) &&
          agreementDetails.every(
            (el: AgreementDetails) =>
              el.otherIncomeType === CONSTANTS.EMPTY_STRING
          ) &&
          agreementDetails.every(
            (el: AgreementDetails) =>
              Number(el.suspenseDetails.suspAdd).toFixed(2) ==
                CONSTANTS.ZREO_TWO_DECIMAL_STRING ||
              el.suspenseDetails.suspAdd == CONSTANTS.EMPTY_STRING
          ) && (
            <PaymentOriginModal
              onClose={() => {
                setPopupPriorityString(CONSTANTS.EMPTY_STRING);
                setOriginPopup(false);
              }}
            />
          )}
      </>
    </Grid>
  );
}

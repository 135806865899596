/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
  RACButton,
  Grid,
  Typography,
  RACModalCard,
} from "@rentacenter/racstrap";
import { ReactComponent as TickIcon } from './../../assets/images/TickIcon.svg';
import { customerStyles } from "../../stylesJS/customerStyles";
import { useHistory } from "react-router-dom";
import { CustomerdataContext } from "../../context/customer/customerdata";
import { postAgreementActivity } from "../../api/user";
export default function RACExchange(props: any){
    const classes = customerStyles();
    const history = useHistory();
    const {exchangeNavigationPopup, setExchangeNavigationPopup} = useContext(CustomerdataContext);
    const [suspensePopupClose, setSuspensePopupClose] = useState<any>(false);
    const [exchangePopup, setExchangePopup] = useState<any>(false);
    console.log('Props in Exchange',props);

    useEffect(()=> {
        validateAndOpenPopup();
    }, []);

    function showExchangePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                {
                    !props?.value?.isExchangeFuncEnabled ? 
                        <TickIcon/> : null
                }
                {
                    props.value.isExchangeFuncEnabled ?
                        <>
                            <Typography className={`${classes.spacerMT3} ${classes.customerRacpopup123}`}>
                                Customer is eligible for ${props?.value?.rentPaid ? parseFloat(props?.value?.rentPaid)?.toFixed(2) : 0.00} for Exchange.
                            </Typography>
                            <Typography className={`${classes.customerRacpopup123}`}>
                                Do you want to proceed with agreement creation?
                            </Typography>
                        </>
                        :
                        <Typography className={`${classes.customerRacpopup123} ${classes.spacerMT3}`}>
                            Please follow the new agreement creation workflow to complete the RAC Exchange Program.
                        </Typography>
                }
                <Grid className={`${classes.buttonsPadding} ${classes.wrapContentSA}`}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.customerFloatleft}`}
                        onClick={() => {
                            setExchangePopup(false);
                            setExchangeNavigationPopup(false);
                        }}
                    >
                        {props.value.isExchangeFuncEnabled ? 'No' : 'Cancel'}
                    </RACButton>
                    {props.value.isExchangeFuncEnabled ?
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={`${classes.customerFloatright}`}
                            onClick={() => {
                                postAgreementActivity({
                                    agreementActivities: [{
                                        agreementId: props?.value?.agreementId,
                                        activityDescription: `Agreement Exchange Selected`,
                                        agreementActivityType: "AGMXNGS",
                                    }]
                                });
                                history.push(`/agreement/rental/itemsearch/${props.value.customerId}?isExchange=1&agreementId=${props.value.agreementId}`);
                            }}
                        >
                            Create Agreement
                        </RACButton>
                        : null
                    }
                </Grid>
            </Grid>
        )
    }

    function showSuspensePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                {
                    <>
                        <Typography className={`${classes.spacerMT3} ${classes.customerRacpopup123}`}>
                            The suspense amount of ${props?.value?.suspenseAmount ? props?.value?.suspenseAmount : 0.00 } on Agreement # {props?.value?.exchangeAgreementNumber ? props?.value?.exchangeAgreementNumber : props.value.agreementId } will be
                        </Typography>
                        <Typography className={`${classes.customerRacpopup123}`}>
                            transferred to the Exchange agreement after making the initial payment.
                        </Typography>
                    </>
                }
                <Grid className={`${classes.buttonsPadding} ${classes.wrapContentSA}`}>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.customerFloatleft}`}
                        onClick={() => {
                            setSuspensePopupClose(false);
                            setExchangeNavigationPopup(true);
                            setExchangePopup(true);
                        }}
                    >
                    Ok
                    </RACButton>
                </Grid>
            </Grid>
        )
    }

    function validateAndOpenPopup(){
        if(props?.value?.suspenseAmount){
            setSuspensePopupClose(true);
        }
        else{
            setExchangePopup(true);
        }
    }

    return (
        <>
            <RACModalCard
                isOpen={exchangePopup}
                closeIcon={true}
                maxWidth={'sm'}
                borderRadius={'25px !important'}
                onClose={() => {
                    setExchangeNavigationPopup(false);
                    setExchangePopup(false);
                }}
                className={`${classes.customModal3}`}
            >
                {showExchangePopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={suspensePopupClose}
                closeIcon={true}
                maxWidth={'sm'}
                borderRadius={'25px !important'}
                onClose={() => {
                    setSuspensePopupClose(false);
                    setExchangeNavigationPopup(false);
                }}
                className={`${classes.customModal5}`}
            >
                {showSuspensePopup()}
            </RACModalCard>
        </>
    );
}
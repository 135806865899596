/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-case-declarations  */
/* eslint-disable react/no-children-prop  */


/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable sonarjs/prefer-single-boolean-return */
import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent,
    RACButton,
    RACModalCard,
    RACSelect,
    RACTextbox,
    ListItem,
    List,
    RACCheckBox,
    CircularProgress,
    RACRadio,
    RACInfoIcon,
    TextareaAutosize,
    RACTable,
    RACTableCell,
    RACTableRow,
    // LinearProgress

} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { ProductPermissions } from '../../constants/constants';
import { fontSize } from '@mui/system';


// import { useLocation } from 'react-router-dom';
// import { useParams } from 'react-router';

// import SearchFilter from './CriteriaSearch';
// import { getInventorySearch, PrintFaxSheet, getSalesInfo } from '../../api/user';
// import DynamicGridComponent from './grid';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';

// import { InventoryContext } from '../../context/InventoryInfoContext';
// import { InventoryGlobalStyles } from './Styles/InventoryGlobalStyles';
// import { searchResultStyles } from './Styles/searchResultStyles';
import { useHistory } from 'react-router';
import { GlobalStyles } from '../../stylesJS/global'
import { useLocation } from 'react-router';
import clsx from 'clsx';
import Criteriasearch from './Riscriteriasearch';
import { getInventorySearch } from '../../api/user';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RentaldataContext } from '../../context/Riscontext';

// import InfiniteScroll from 'react-infinite-scroll-component';
//import moment from 'moment';

// export interface columns 
//   columnId: string;
//   sortOrder: string;
//   columnTitle: string;
//   IsSorted: boolean;
//   IsSortable: boolean;
//   columnType?: 'link';
//   handleColumnClick?: () => void;
//   subColumns?: columns[];
//   columnColor?: string;
// }
// const sortDirection = {
//   ASC: 'asc',
//   DESC: 'desc',
// };


const useClasses = makeStyles((theme: any) => ({
    borderRed: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    mtop30: {
        marginTop: '30px',
    },
    mtop20: {
        marginTop: '20px',
    },
    w100: {
        width: '100%',
    },
    loaderStyle: {
        border: 'none',
        backgroundColor: 'transparent',
        // userFocus: 'none',
        // MozUserFocus: 'none',
        outline: 'none',
    },
    RACLoaderPage: {
        textAlign: 'center',
        marginTop: '250px',
        fontSize: '16px',
        marginLeft: '30px',
        marginRight: '30px',
        outline: 'none',
    },
    card: {
        borderRadius: '16px!important',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
        width: '100%',
    },
    title: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '28px!important',
        marginBottom: '10px!important',
    },


    title1: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '23px!important',
        marginBottom: '10px!important',
    },
    titleColor: {
        color: 'black',
        marginLeft: '10px',
        marginTop: '15px',
    },
    titleColor1: {
        color: 'black',
        marginLeft: '3px',
        marginTop: '14px',
    },
    infoTextStyle: {
        color: 'gray',
        fontSize: theme.typography.pxToRem(13),
        textAlign: 'center',
    },
    my4: {
        marginTop: theme.typography.pxToRem(24),
        marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
        marginBottom: theme.typography.pxToRem(48),
    },
    pt2: {
        paddingTop: theme.typography.pxToRem(8),
    },
    px2: {
        paddingRight: theme.typography.pxToRem(8),
        paddingLeft: theme.typography.pxToRem(8),
    },
    cardHeight: {
        height: ' 5.9375rem',
    },
    cardHeightN:{
        height: ' 20.0rem',

    },
    cardHeight1: {
        height: 'auto',
        marginTop: '26px',
    },
    headtag: {
        marginTop: '17px',
        marginLeft: '19px',
    },
    fixedFooter: {
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.typography.pxToRem(16),
        backgroundColor: '#ffffff',
        boxShadow: '0 0 3px #d9d9d9',
    },
    ml3: {
        marginLeft: theme.typography.pxToRem(16),
    },
    p2: {
        padding: theme.typography.pxToRem(8),
    },
    modalBody: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '1rem',
        width: '98%'
    },
    modalContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pointerEvents: 'auto',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        outline: '0',
    },
    me2: {
        marginRight: '0.5rem !important',
        marginLeft: '10rem',
    },
    floatStart: {
        float: 'left',
    },
    floatStart1: {
        float: 'left',
        '& input': {
            height: '24px'
        },
    },
    mb3: {
        marginBottom: theme.typography.pxToRem(16),
    },
    formLabel: {
        marginBottom: '0.2rem',
        color: '#111111',
        //eslint-disable-next-line sonarjs/no-duplicate-string
        fontFamily: 'OpenSans-semibold',
        fontSize: '14px',
        marginLeft: '10px',
    },
    textCenter: {
        textAlign: 'center',
    },
    px3: {
        paddingRight: '1rem !important',
        paddingLeft: '1rem !important',
    },
    pb4: {
        paddingBottom: '1.5rem !important',
    },
    colMd12: {
        flex: '0 0 auto',
        width: '100%',
    },
    center: {
        textAlign: 'center',
        margin: '12px',
    },
    right: {
        marginRight: '10px',
    },
    right1: {
        marginRight: '15px',
    },
    equal: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
    },
    equal1: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
        paddingBottom: '25px'
    },
    leftpadding: {
        paddingLeft: '9.5rem',
    },
    heading: {
        fontSize: '20px',
    },
    tittles: {
        marginTop: '10px',
        marginBottom: '7px',
    },
    tittle1: {
        marginTop: '10px',
        marginBottom: '7px',
        // padding: '10px !important'
        '& input': {
            padding: '10px !important'
        },
    },
    dropdown1: {
        '& div': {
            padding: '10px 9px'
        }
    },
    tittles1: {
        marginTop: '10px',
        marginBottom: '7px'
    },
    textstyle: {
        marginRight: '10px',
        marginBottom: '25px',
    },
    textstyle1: {
        marginRight: '25px',
        marginTop: '25px',
    },
    dollartext1: {
        width: '87% !important',
        '& input': {
            height: '21px'
        },
        // width: '13% !important',
        // padding: '6px 14px !important',
        // marginBottom: '25px !important',
    },
    dollartext3: {
        width: '80% !important',
        '& input': {
            height: '23px'
        }
    },
    // dollartext2:{
    //     width: '80% !important',
    //     '& input': {
    //         height: '21px'
    //     },

    // },
    marginTop: {
        marginTop: '5px',
    },
    textcolor: {
        color: 'blue',
    },
    cardborder: {
        border: '0 !important',
    },
    BreadCrumbInactive: {
        '&:after': {
            content: "''",
            width: 0,
            height: 0,
            borderTop: '5px solid transparent',
            borderLeft: '10px solid #555',
            borderBottom: '5px solid transparent',
        },
    },
    marginBottom: {
        marginBottom: '10px !important',
    },
    marginBottom1: {
        marginBottom: '10px !important',
        '& input': {
            padding: '10px'
        },
    },
    marginright: {
        marginRight: '20px',
    },
    typeMargin: {
        padding: '1px',
        '& input': {
            height: '24px'
        },
    },
    screenTop: {
        marginTop: '22px'
    },
    imgStyle: {
        paddingBottom: '10px'
    },
    outlinedBtn: {
        padding: '0.7rem 2rem',
    },
    zipCodeClass: {
        '& div': {
            '& div': {
                '& div': {
                    '& input': {
                        padding: '10px'
                    }
                }
            }
        }
    },
    marginCustName: {
        marginTop: '10px',
        color: '#2179FE'
    },
    marginCustID: {
        marginTop: '10px',
        color: 'gray'
    },
    paddingCustname: {
        padding: '10px'
    },
    customerToolbar: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        paddingLeft: theme.typography.pxToRem(16),
        paddingRight: theme.typography.pxToRem(16),
        paddingTop: theme.typography.pxToRem(16),
        paddingBottom: theme.typography.pxToRem(16),
    },
    fixedBottom: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        padding: '1rem',
    },
    floatLeft: {
        float: 'left',
    },
    floatRight: {
        float: 'right',
    },
    me1: {
        marginRight: theme.typography.pxToRem(4),
    },
    foc: {
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '1px 1px 6px 3px #80bdff',
        },
    },
    racFixedFooterMargin: {
        marginBottom: '120px',
    },
    spacerMT4: {
        marginTop: theme.typography.pxToRem(24),
    },
    RACPOPMsg: {
        fontFamily: 'OpenSans-bold',
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0,
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    formLabelInline: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        display: 'inline-block',
        paddingLeft: 0,
    },
    custDetailSection: {
        display: 'flex',
        marginLeft: '20px',
        width: '50%',
        justifyContent: 'space-around'
    },
    racInfoStyle: {
        marginLeft: '5px',
        position: 'absolute'
    },
    textArea: {
        margin: "10px 0px",
        width: 1000,
        padding: "6PX",
        borderRadius: "4px",
        resize: "none"

    },
    description: {
        margin: "10px 0px"
    },
    GridLoader: {
        textAlign: 'center',
        marginBottom: theme.typography.pxToRem(20),
        fontSize: theme.typography.pxToRem(16),
        color: "white",
        margin: '30px 0px',
    },
    fontN12: {
        fontSize: theme.typography.pxToRem(13),
        fontFamily: "OpenSans-semibold",
        paddi:theme.typography.pxToRem(2),
        color: "#6C86A5"
    },
    srmargin: {
        marginLeft: "2px"

    },
    scrollbar: {
        overflow: "auto",
        // height: "500px", 
        width: "100%"},

    title2: {
            color: '#212529',
            fontFamily: 'OpenSans-bold!important',
            fontSize: '20px!important',
            marginBottom: '10px!important'
        },
        p3: {
            paddingLeft: theme.typography.pxToRem(16),
            paddingRight:theme.typography.pxToRem(16)
          },
          tablestyle:{
            "& th": {
                fontFamily: "OpenSans-semibold",
                // float:"left",
                fontWeight:"bold",
                whiteSpace: "nowrap",
                // marginTop:"-20px",
                // padding: "0px 50px 20px 0px",
    
                
              },
              "& td": {
                // float:"left",
                // padding: "0px 60px 0px 0px",
                color: "black",
                
                // fontSize:"bold",
              },
              
        },
        row: {
            display: 'flex',
            flexWrap: 'wrap',
            // marginTop: theme.typography.pxToRem(10),
            // marginRight: theme.typography.pxToRem(14),
            // marginLeft: theme.typography.pxToRem(14),
          },
          fixTableHead: {
            overflowY: 'auto',
           maxHeight: ' 20.0rem',
           '& th': { top: 0,
          backgroundColor: '#ffffff',
          fontFamily: 'OpenSans-bold',
          zIndex: 1,
           padding: '0.7375rem 0.3055rem',
           whiteSpace: 'nowrap',
           },
           '& tbody': {
          '& tr': {
           top: 10,
           backgroundColor: '#ffffff',
            },
          '& td': {
           fontFamily: 'OpenSans-semibold',
           padding: '0.6375rem 0.3rem ',
        //    paddingLeft:'0.3rem',
           color: '#4A5174',
          },
            },
           },
}));

export default function DynamicGridComponent(props: any) {
    const classes2 = takePaymentStyles();
    const classes = useClasses();
    const classes1: any = GlobalStyles();
    const history: any = useHistory()
    // const fixedGridClassName = fixedGridStyles();
    const {updatedInventory} = useContext(RentaldataContext)
    const [gridvalues, setgridvalues] = useState<any>()
   
    // const value=[{
    //     ItemNumber:"",
    //     serialNumber:"",
    //     Model:"",
    //     Description:"",
    //     PurchaseDate:"",
    //     RemainingValue:"",
    //     SuggesteedPrice:"",

    // }]
    const Risheader = [
        { display: "" },
        { display: "Item #" },
        { display: "Serial #" },
        { display: "Model" },
        { display: "Description" },
        { display: "Condition" },
        { display: "Purchase Date" },
        { display: "Purchase Cost" },
        { display: "Remaining Value" },
        { display: "Suggested Price" }
    ];
 
    /**
     * RIS_PC_NO_17 These are the instance varible which are received as props and 
     * hasMore,loader,scrollset variables are declared.
     */
    const { arrradio, setarrradio,tabledata ,settabledata,customerInfo} = props
    const [hasMore, sethasMore] = useState<boolean>(true)
    const [loader, setloader] = useState<boolean>(false)
    const [scrollset, setScrollset] = useState<number>(0)
    // const [arrradio,setarrradio]=useState<any>([])
    // const [itemselectdata,setitemselectdata]=useState<any>()
    let condition="";


    /**RIS_PC_NO_21 This useEffect is performed to render the radio buttons
    * without checked.
    */

    useEffect(() => {
        debugger
        if(tabledata != undefined && tabledata!= ""&&customerInfo.editNo=="1")
        {
            let radioArray: any = new Array(tabledata.length)
            radioArray.fill(true)
            setarrradio(radioArray)
            props.setitemselectFlag(true)

        }
        if (tabledata != undefined && tabledata!= ""&&customerInfo.editNo!="1") {
            let radioArray: any = new Array(tabledata.length)
            radioArray.fill(false)
            setarrradio(radioArray)
        }
       


    }, [tabledata])

    const hideLengthyText = (Text: any, MaxLength: number, Mask: any) =>
     { 
        return Text?.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
     };

    /**RIS_PC_NO_19 The nextscroll function is triggered when the infinity scroll
    * is performed.
    * RIS_PC_NO_20 The nextscroll function is created and getInventorySearch() service call is made.
    */

    const nextscroll = async () => {
        

        const { Request } = props
        setScrollset(scrollset + 1)
        Request['setNumber'] = scrollset +1;
        // sethasMore(true)
        setloader(true)
        const response = await getInventorySearch(Request);
        setloader(false);
        const val = response.data.response
        let tabledata1= val.filter((x)=>!updatedInventory.includes(x.inventoryNumber))
        // setgridvalues([...gridvalues, ...val])
        settabledata([...tabledata, ...tabledata1])
        let radioarray1=new Array(tabledata1.length)
         radioarray1.fill(false)
         setarrradio([...arrradio,...radioarray1])
    
       
    
        if (tabledata1.length === 0) {
          sethasMore(false)
        }
      }
    




    /** RIS_PC_NO_18 In this function headers for the table is rendered.*/

    const renderTableHead = (): any => (
        <>
            {Risheader.map((val, index) => {
                if (val.display == "Purchase Cost" ||
                    val.display == "Remaining Value" || val.display == "Suggested Price") {
                    return (
                        <RACTableCell style={{ textAlign: "right" ,backgroundColor:"none"}}  key={index}>
                            {val.display}
                        </RACTableCell>
                    );

                } else {
                    return (
                        <RACTableCell  key={index}>
                            {val.display}
                        </RACTableCell>
                    );

                }

            })}
        </>

    )

    /**RIS_PC_NO_19 renderTableContent() function is called for rendering the 
     * table content.
     * RIS_PC_NO_21 props.risRadiobtnonclick() function is triggered when clicking the
     * radio button.
     */
    const renderTableContent = (): any => (
        // gridvalues.map((value:any,index:any)=>{
             
        // })
        // gridvalues != undefined &&
        //         gridvalues.length > 0
        //         ? gridvalues.map((value, index: any) => 
        <>
            {tabledata!= undefined &&
                tabledata.length > 0
                ? tabledata.map((value, index: any) => {
                    if (value.conditionEn == 'Based on DOR-system push') {
                        condition = 'Auto';
                      } else if (value.conditionEn == 'Term pushed up/down by Coworker') {
                        condition = 'Exception';
                      } else if (value.conditionEn == 'New - Full Term') {
                        condition = 'New';
                      } else {
                        condition = value.conditionEn;
                      }
                    return (
                        <RACTableRow key={index}  className={`${classes2.tablerowtheme}`}>
                            <RACTableCell style={{paddingBottom:"0px",paddingLeft:"0px"}}>

                                <RACRadio
                                    className={`${classes1.formcheckinput} ${classes1.checkboxStyle}`}
                                    // name={"Risradio"+index}
                                    // id={index}
                                    onClick={() => props.risRadiobtnonclick(index)}
                                    checked={arrradio[index] == false ? false : true}
                                    value=""
                                // id={value.Agreementid + "," + "id"}
                                />
                            </RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}><a style={{color:'#2179FE', textDecoration: "none", }} className={`${classes1.racpadlink} ${classes1.racpadLink}`}
                                href={`/inventory/${value.inventoryNumber}/inventoryinformation`}
                            // onClick={()=>{history.push({pathname:`/inventory/${value.inventoryNumber}/inventoryinformation`})}}
                            >
                                {value.inventoryNumber}
                            </a>
                            </RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}} className={`${classes.fontN12}`}>{value.serialNumber}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`}>{value.modelNumber}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`} title={value.inventoryDescription}>{hideLengthyText(value.inventoryDescription,20,'...')}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`}>{condition}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`}>{value.purchaseDate}</RACTableCell>
                            <RACTableCell className={`${classes.fontN12}`} style={{ textAlign: "right",paddingBottom:"0px" }}>{"$"+value.purchaseCost}</RACTableCell>
                            <RACTableCell className={`${classes.fontN12}`} style={{ textAlign: "right",paddingBottom:"0px" }}>{"$"+value.remainingValue}</RACTableCell>
                            <RACTableCell className={`${classes.fontN12}`} style={{ textAlign: "right" ,paddingBottom:"0px",paddingRight:"1%"}}>{"$"+(Number(value.cashPrice)).toFixed(2)}</RACTableCell>
                        </RACTableRow>
                    );
                })
                : null}
        </>



    )
    return (
        //     <Grid className={`${fixedGridClassName.fixTableHead} ${fixedGridClassName.fixTableHeight400}`}   >
        //   {gridvalues.length > 7 ?
        //     <InfiniteScroll
        //       dataLength={gridvalues.length} //This is important field to render the next data
        //     //   next={nextscroll}
        //     //   hasMore={hasMore}
        //       height={400}
        //       loader={<Grid className={classes.GridLoader}><CircularProgress ></CircularProgress></Grid> }
        //     >
        //       <RACTable
        //         renderTableHead={renderTableHead}
        //         renderTableContent={renderTableContent}
        //         stickyHeader
        //       />



        //     </InfiniteScroll> : (

        //       <RACTable

        //         renderTableHead={renderTableHead}
        //         renderTableContent={renderTableContent}

        //       />

        //     )}
        // </Grid>

        /**RIS_PC_NO_17 <RACTable/> contains renderTableHead and renderTableContent 
         * attributes where rederTableHead() and rendertableContent() function is called
         * 
         * RIS_PC_NO_19 If tabledata length is greater than 7 the infinity scroll is rendered else
         * the normal table is rendered.
         * 
         */
        <Grid >
            <Typography className={`${classes.title2} ${classes1.mt3} ${classes.srmargin}`}>
                Search Results
            </Typography>
            <Card style={{borderRadius:"50%"}} className={`${classes.card} ${classes.cardHeightN} ${classes1.mb4} `}>
                <CardContent className={classes1.p0}>

                 {/* <RACTable
                     className={classes. fixTableHead} 
                    // className={classes1.ractable}
                    renderTableHead={renderTableHead}
                    renderTableContent={renderTableContent}
                    stickyHeader
                    /> */}
                     {tabledata.length > 7 ?
        <InfiniteScroll
        dataLength={tabledata.length} //This is important field to render the next data
        next={nextscroll}
        hasMore={hasMore}
        height={ '20.0rem'}
        loader={loader ? <Grid className={classes.GridLoader}><CircularProgress ></CircularProgress></Grid> : null}
      >
        <RACTable
         className={classes. fixTableHead} 
          renderTableHead={renderTableHead}
          renderTableContent={renderTableContent}
          stickyHeader
        />



      </InfiniteScroll> : (

        <RACTable
        className={classes. fixTableHead} 
        renderTableHead={renderTableHead}
        renderTableContent={renderTableContent}
        stickyHeader

        />

      )}
             </CardContent>
           </Card>
        
        </Grid >

    )
}

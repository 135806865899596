/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-redundant-boolean */
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";


export default function calculationModulePayloadFormation(props: any) {
    let agreementPayloadFormation:any =[]
    let clubAdjustmentsPayload :any =[]
    let otherIncomePayment:any =[]
    console.log("props.checkedAgr",props.checkedAgr)
 for(let i=0;i<props.checkedAgr?.length;i++){
     let paygridInfo=props.checkedAgr[i]
     let adjustedDueDate = false;
     let adjustedDueDateValue=undefined;
     let editSchedule=false;
     let editScheduleValue=undefined;
     let acceptEpo= false;
     let acceptEpoValue:any= undefined;
     let acceptEpoValueDiscount=undefined;
     let freeTime = false;
     let freeTimeValue:any= undefined;
     let carryLatefee = false;
     let carryLatefeeValue:any=undefined;
     let waivelatefee = false;
     let waivelatefeeValue:any=undefined;
     
    //EditSchedule
    if(paygridInfo?.editSchedule!=''||paygridInfo.isEditScheduleValue){
        editSchedule=true;
        if(paygridInfo?.editSchedule!=''){
           editScheduleValue=paygridInfo?.editSchedule
        }else if(paygridInfo.isEditScheduleValue){
           editScheduleValue=paygridInfo.isEditScheduleValue
        }
    }
    //AdjustDueDate
    if(paygridInfo?.dueDateModified||paygridInfo.isAdjustDueDateValue){
        console.log("Inside Adjust Due Date",paygridInfo)
        if((paygridInfo?.Nextduedate=="SAC"||paygridInfo?.Nextduedate=="EPO")&&paygridInfo?.dueDateModified){
            acceptEpo=true
            acceptEpoValue="Y"
        }else{
            adjustedDueDate=true
            if(paygridInfo?.isAdjustDueDateValue=="SAC"||paygridInfo?.isAdjustDueDateValue=="EPO"){
                acceptEpo=true
                adjustedDueDate=false
                acceptEpoValue="Y"
            }else if(paygridInfo?.dueDateModified){
                adjustedDueDateValue =paygridInfo.Nextduedate
            }else if (paygridInfo.isAdjustDueDateValue){
                adjustedDueDateValue =paygridInfo.isAdjustDueDateValue
            }
        }
    }
    //Accept EPO
    if(paygridInfo?.acceptEpoFlag==1||paygridInfo.isAcceptEPOValue){
        acceptEpo=true
        acceptEpoValue='Y'
        if(paygridInfo.isAcceptEPOValue||paygridInfo.isAcceptEPODiscounts){
            acceptEpoValueDiscount=paygridInfo.isAcceptEPODiscounts
        }
    }
    //Free time
    if(paygridInfo?.FreeTime!=null||paygridInfo.isFreeTimeValue){
        freeTime=true
        if(paygridInfo?.FreeTime!=null){
            freeTimeValue ={
                type:paygridInfo?.FreeTime.freeTimeType,
                freeDays:paygridInfo?.FreeTime.freeTimeDays,
                waiveOptionalService:paygridInfo?.FreeTime.isOptServiceWaived
                ? 'Y'
                : 'N',

            }
        }else if (paygridInfo.isFreeTimeValue){
            freeTimeValue ={
                type:paygridInfo?.isFreeTimeValue.freeTimeType,
                freeDays:paygridInfo?.isFreeTimeValue.freeTimeDays,
                waiveOptionalService:paygridInfo?.isFreeTimeValue.isOptServiceWaived
                ? 'Y'
                : 'N',

            }
        }
    }
    //Carry Late Fee
    if(Number(paygridInfo?.carriedLateFee)>0||Number(paygridInfo.iscarriedLateFeeValue)>0){
        carryLatefee=true
        if(Number(paygridInfo?.carriedLateFee)>0){
            carryLatefeeValue=Number(paygridInfo.carriedLateFee) > 0
            ? Number(paygridInfo.carriedLateFee).toFixed(2)
            : undefined
        }else if(Number(paygridInfo.iscarriedLateFeeValue)>0){
            carryLatefeeValue=Number(paygridInfo.iscarriedLateFeeValue) > 0
            ? Number(paygridInfo.iscarriedLateFeeValue).toFixed(2)
            : undefined
        }
    }
    //Waive Late Fee
    if(Number(paygridInfo?.waivedLateFee)>0||paygridInfo.waivedLateFeeValue){
        waivelatefee=true
        if(Number(paygridInfo?.waivedLateFee)>0){
            waivelatefeeValue = Number(paygridInfo.waivedLateFee) > 0
            ? Number(paygridInfo.waivedLateFee).toFixed(2)
            : undefined
        }else if(paygridInfo.waivedLateFeeValue){
            waivelatefeeValue=paygridInfo.waivedLateFeeValue
        }
    }
    //toggle LDW
 

    //toggle Policy

    if(adjustedDueDate||editSchedule||acceptEpo||freeTime||carryLatefee||waivelatefee||(paygridInfo?.toggleOptionalService!=''&&paygridInfo?.toggleOptionalService!=undefined)||paygridInfo?.toggleOptionalServicePerformed!=''){
        if(paygridInfo?.Agreementtype!="Club"&&paygridInfo?.otherIncomeType==""&&
        paygridInfo?.type!="Restitution"&& paygridInfo?.type!="Back Rent" &&paygridInfo.type!="NSF Check"&& paygridInfo?.type==undefined ){
            agreementPayloadFormation.push({
                agreementId:paygridInfo?.Agreementid,
                adjustments:{
                    editedSchedule:editScheduleValue,
                    adjustedDueDate:adjustedDueDate?moment(adjustedDueDateValue).format('YYYY-MM-DD'):undefined,
                    freeTime:freeTimeValue,
                    carryLateFeeAmount:carryLatefeeValue,
                    waiveLateFeeAmount:waivelatefeeValue,
                    epoPayment:acceptEpoValue,
                    epoDiscount:acceptEpoValue=='Y'?acceptEpoValueDiscount:undefined,
                    toggleOptionalService:paygridInfo?.toggleOptionalService!=''&&paygridInfo?.toggleOptionalService!=undefined?paygridInfo?.toggleOptionalService:paygridInfo?.toggleOptionalServicePerformed!=''?paygridInfo?.toggleOptionalServicePerformed:undefined
                }
            })
        }
        else if(paygridInfo?.type=="Restitution"|| paygridInfo?.type=="Back Rent"||paygridInfo.type=="NSF Check")
        {
            otherIncomePayment.push(
                {
                    agreementId: paygridInfo?.AgreementID == undefined ? undefined : String(paygridInfo?.AgreementID),
                    amount: paygridInfo?.amount,
                    type: paygridInfo?.type == "Restitution" ? "CO" : paygridInfo?.type == "Back Rent" ? "BR" : 'NSF',
                    receiptTransactionId: paygridInfo?.receiptTransactionId == undefined ? undefined : paygridInfo?.receiptTransactionId,
                    customerAccountId: paygridInfo?.customerAccountID == undefined ? undefined : paygridInfo?.customerAccountID,
                }
            )
        }else if(paygridInfo?.Agreementtype=="Club"){
                 clubAdjustmentsPayload =[{
                    clubAmountDue:[
                        {
                            customerClubId:String(paygridInfo?.customerClubId),
                            adjustments:{
                                editedSchedule:editScheduleValue,
                                adjustedDueDate:adjustedDueDate?moment(adjustedDueDateValue).format('YYYY-MM-DD'):undefined,
                            }
                        }
                    ]
                }]
            
            
        } 
    }
    
    
 }

 let commonModulePayload ={
    customerId: props.customerId,
    paymentOrigin:'S',
    agreementAmountDue:agreementPayloadFormation?.length>0?agreementPayloadFormation:undefined,
    clubAmountDue:clubAdjustmentsPayload?.length>0?clubAdjustmentsPayload[0].clubAmountDue:undefined,
    otherIncomePayment:otherIncomePayment?.length>0?otherIncomePayment:undefined
 }
 return commonModulePayload
   
}



// export default FreeTime;

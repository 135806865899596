import { RACCOLOR, makeStyles } from '@rentacenter/racstrap';
export const ManageCustomerStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    title: {
      color: '#212529',
      fontFamily: 'OpenSans-bold',
      fontSize: '16px',
      marginBottom: '10px',
    },
    /* Margin and Padding spacing */
    m0: {
      margin: 0,
    },

    m1: {
      margin: theme.typography.pxToRem(4),
    },

    m2: {
      margin: theme.typography.pxToRem(8),
    },

    m3: {
      margin: theme.typography.pxToRem(16),
    },

    m4: {
      margin: theme.typography.pxToRem(24),
    },

    m5: {
      margin: theme.typography.pxToRem(48),
    },

    mAuto: {
      margin: 'auto',
    },

    mx0: {
      marginRight: 0,
      marginLeft: 0,
    },

    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mx2: {
      marginRight: theme.typography.pxToRem(8),
      marginLeft: theme.typography.pxToRem(8),
    },

    mx3: {
      marginRight: theme.typography.pxToRem(16),
      marginLeft: theme.typography.pxToRem(16),
    },
    mx4: {
      marginRight: theme.typography.pxToRem(24),
      marginLeft: theme.typography.pxToRem(24),
    },

    mx5: {
      marginRight: theme.typography.pxToRem(48),
      marginLeft: theme.typography.pxToRem(48),
    },

    mxAuto: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    my0: {
      marginTop: 0,
      marginBottom: 0,
    },

    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },

    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },

    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },

    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },

    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },

    myAuto: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    mt0: {
      marginTop: 0,
    },
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },

    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },
    mtAuto: {
      marginTop: 'auto',
    },
    me0: {
      marginRight: 0,
    },

    me1: {
      marginRight: theme.typography.pxToRem(4),
    },

    me3: {
      marginRight: theme.typography.pxToRem(16),
    },

    me4: {
      marginRight: theme.typography.pxToRem(24),
    },

    me5: {
      marginRight: theme.typography.pxToRem(48),
    },

    meAuto: {
      marginRight: 'auto',
    },

    mb0: {
      marginBottom: 0,
    },

    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },
    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },

    mbAuto: {
      marginBottom: 'auto',
    },

    ms0: {
      marginLeft: 0,
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },

    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },

    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },

    msAuto: {
      marginLeft: 'auto',
    },

    p0: {
      padding: 0,
    },

    p1: {
      padding: theme.typography.pxToRem(4),
    },

    p2: {
      padding: theme.typography.pxToRem(8),
    },

    p3: {
      padding: theme.typography.pxToRem(16),
    },

    p4: {
      padding: theme.typography.pxToRem(24),
    },

    p5: {
      padding: theme.typography.pxToRem(48),
    },

    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },

    px1: {
      paddingRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(4),
    },
    px3: {
      paddingRight: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(16),
    },

    px4: {
      paddingRight: theme.typography.pxToRem(24),
      paddingLeft: theme.typography.pxToRem(24),
    },

    px5: {
      paddingRight: theme.typography.pxToRem(48),
      paddingLeft: theme.typography.pxToRem(48),
    },

    py0: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },

    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },

    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },

    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },

    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },

    pt0: {
      paddingTop: 0,
    },

    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },

    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },

    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },

    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },

    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },

    pe0: {
      paddingRight: 0,
    },

    pe1: {
      paddingRight: theme.typography.pxToRem(4),
    },

    pe2: {
      paddingRight: theme.typography.pxToRem(8),
    },

    pe3: {
      paddingRight: theme.typography.pxToRem(16),
    },

    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },

    pe5: {
      paddingRight: theme.typography.pxToRem(48),
    },

    pb0: {
      paddingBottom: 0,
    },

    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },

    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },

    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },

    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },

    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },

    ps0: {
      paddingLeft: 0,
    },

    ps1: {
      paddingLeft: theme.typography.pxToRem(4),
    },
    ps3: {
      paddingLeft: theme.typography.pxToRem(16),
    },

    ps4: {
      paddingLeft: theme.typography.pxToRem(24),
    },

    ps5: {
      paddingLeft: theme.typography.pxToRem(48),
    },

    font11: {
      fontSize: theme.typography.pxToRem(11),
    },

    font12: {
      fontSize: theme.typography.pxToRem(12),
    },

    font13: {
      fontSize: theme.typography.pxToRem(13),
    },

    font14: {
      fontSize: theme.typography.pxToRem(14),
    },

    font15: {
      fontSize: theme.typography.pxToRem(15),
    },

    font17: {
      fontSize: theme.typography.pxToRem(17),
    },

    font18: {
      fontSize: theme.typography.pxToRem(18),
    },
    textRight: {
      textAlign: 'right',
    },
    textCenter: {
      textAlign: 'center',
    },
    headerTab: {
      paddingBottom: '0 !important',
    },
    racpadTab: {
      display: 'flex',
    },
    racCol12: {
      flex: '0 0 auto',
      width: '100%',
    },
    racCol6: {
      flex: '0 0 auto',
      width: '50%',
    },
    racCol10: {
      flex: '0 0 auto',
      width: '83.3333333333%',
    },
    racCol2: {
      flex: '0 0 auto',
      width: '16.666666666%',
    },
    racCol8: {
      flex: '0 0 auto',
      width: '66.6666666667%',
    },
    alertWidgetContainer: {
      flexWrap: 'unset',
      overflow: 'auto',
      display: 'flex',
    },
    alertWidgetStyle: {
      backgroundColor: '#FCE5E5',
      borderRadius: '40px',
      color: '#4B4B4B',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      padding: '4px 15px 6px 15px',
      marginTop: '1px',
      float: 'left',
      whiteSpace: 'nowrap',
    },
    alertLoader: {},
    alertLoaderSize: {},
    widthAuto: {
      width: 'auto',
    },
    custGridMargin: {
      marginBottom: '100px',
    },
    custDateField: {
      width: '100%',
      float: 'left',
      borderRadius: '6px',
      padding: '0.2875rem 0.7rem 0.2875rem 0.7rem',
      border: '1px solid #d9d9d9',
    },
    datePickerImg: {
      float: 'right',
      width: '14px',
      height: '19px',
    },
    RACCUSTOMERGridTextAlign: {
      textAlign: 'end',
    },
    navLinkItem: {
      borderBottom: 'transparent',
      fontSize: '14px',
      fontFamily: 'OpenSans-semibold',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    navLinkActive: {
      color: '#2279fd',
      borderBottom: '3px solid #2279fd',
    },
    formCheckToggle: {
      display: 'block',
      minHeight: '1.5rem',
      marginBottom: '0.125rem',
    },
    formLabelCustom: {
      float: 'left',
      width: '100%',
      fontSize: '14px',
      fontFamily: 'OpenSans-semibold',
    },
    formLabelInline: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      display: 'inline-block',
      paddingLeft: 0,
    },
    swThemeDefault: {
      width: '100%',
      position: 'relative',
    },
    swThemeDefaultContainer: {
      flex: '0 0 auto',
      width: '100%',
      background: `${RACCOLOR.WHITE}`,
    },
    smartWizard: {
      display: 'inline-block',
      listStyle: 'none',
    },
    smartWizardList: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(20),
      width: 'auto',
      display: 'inline-block',
      '&:after': {
        borderBottom: '2px solid #cecece',
        content: '""',
        position: 'absolute',
        width: '50px',
        right: '-30px',
        top: '50%',
      },
      '&:last-child': {
        '&:after': {
          border: 'none',
        },
      },
    },
    smartWizardListLinkActive: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(20),
      width: 'auto',
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    smartWizardListLinkInActive: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(20),
      width: 'auto',
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    borderActive: {
      border: '2px solid #d9d9d9',
      padding: '4px 10px',
      margin: '0 5px',
      backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderRadius: '50px',
      color: `${RACCOLOR.WHITE}`,
    },
    borderDefault: {
      border: '2px solid #d9d9d9',
      padding: '4px 10px',
      borderRadius: '50px',
      margin: '0 5px',
      color: '#999999',
    },
    navInactive: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(20),
      width: 'auto',
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      color: '#999999',
    },
    done: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: '#5ae286',
      color: 'transparent',
      borderColor: '#5ae286',
      padding: '4px',
    },
    racLoginAlert: {
      color: 'red',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
    },
    racSecFactorTextbox: {
      width: '150%',
    },
    racBtnDisabled: {
      pointerEvents: 'none',
      opacity: 0.65,
    },
    racErrorIcon: {
      width: ' 50px',
    },
    racErrorFunction: {
      fontFamily: 'OpenSans-semibold',
      marginBottom: '20px',
      marginTop: '10px',
    },
    racPinError: {
      color: 'red',
      marginTop: '5px',
      marginLeft: '0.5rem',
      fontFamily: 'OpenSans-semibold',
    },
    racPinContainer: {
      margin: '0 auto',
      width: '94%',
    },
    racPinLabel: {
      fontFamily: 'OpenSans-semibold',
      color: '#000000',
      marginLeft: '0.5rem',
      marginBottom: '3px',
    },
    racPinField: {
      height: '45px',
      width: '45px',
      marginRight: '1rem',
      marginLeft: '0.5rem',
      textAlign: 'center',
      borderRadius: '0.6rem',
      border: '1px solid #adb5bd',
      fontSize: 'x-large',
    },
    custCheckbox: {
      color: '#0d6efd',
    },
    subTitle: {
      color: '#212529',
      fontFamily: 'OpenSans-regular',
      fontWeight: 700,
      fontSize: '14px',
      marginBottom: '10px',
    },
    breadcrumb: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      marginBottom: theme.typography.pxToRem(10),
      listStyle: 'none',
    },
    breadcrumbItem: {
      width: 'auto',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
    },
    breadcrumbItemActive: {
      width: 'auto',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      color: '#3182FF',
      paddingLeft: 0,
    },
    breadcrumbArrow: {
      '&:before': {
        content: '""',
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderLeft: '7px solid #707070',
        borderBottom: '5px solid transparent',
        marginTop: 0,
        float: 'left',
        paddingRight: '13px',
        color: '#6c757d',
      },
    },
    badge: {
      display: 'inline-block',
      marginTop: '10px',
      padding: '5px 10px',
      fontSize: '0.75em',
      fontWeight: 700,
      lineHeight: 1,
      color: '#fff',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'baseline',
      borderRadius: '20px',
    },
    badgeRed: {
      backgroundColor: `${RACCOLOR.MAXIMUM_RED}`,
    },
    badgeOrange: {
      backgroundColor: `${RACCOLOR.MARIGOLD}`,
    },
    badgeGreen: {
      backgroundColor: `${RACCOLOR.VITAL_GREEN}`,
    },
    racDEDate: {
      fontSize: '13px',
      color: '#5b6d83',
      display: 'block',
      fontFamily: 'OpenSans-semibold',
    },
    custApprovalLabel: {
      textAlign: 'left',
      float: 'left',
      fontSize: '12px',
      fontFamily: 'OpenSans-semibold',
      color: '#2279fd',
      width: '100%',
    },
    custDigit: {
      width: '87%',
      '& input': {
        textAlign: 'right',
      },
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
    },
    currencyDollarField: {
      width: '13%',
      paddingTop: '7px',
      paddingBottom: '6px',
      paddingLeft: theme.typography.pxToRem(10),
      paddingRight: theme.typography.pxToRem(10),
    },
    formCheckBoxInput: {
      width: '1em',
      height: '1em',
      marginTop: '0.25em',
      verticalAlign: 'top',
      backgroundColor: '#ffffff',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      border: '1px solid rgba(0, 0, 0, 0.25)',
      appearance: 'none',
      float: 'left',
      marginLeft: '-1.5em',
      '&:checked': {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
    dateField: {
      padding: 0,
      border: 'none',
      width: 0,
    },

    gridClassToggleReverse: {
      display: 'inline-flex',
      flexDirection: 'row-reverse',
    },
    gridClassLabel: {
      marginLeft: theme.typography.pxToRem(10),
      marginRight: theme.typography.pxToRem(10),
    },
    switchLabel: {
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    textCenterSSN: {
      '& input': {
        textAlign: 'center',
      },
    },
    ssnPaddingTop: {
      paddingTop: '2px !important',
    },
    addCustomerMargin: {
      marginTop: '-3px !important',
    },
    hideField: {
      display: 'none !important',
    },
    fontColor: {
      color: '#545454 !important',
    },
    w100: {
      width: '100%',
    },
    card: {
      borderRadius: '16px!important',
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
      width: '100%',
    },
    infoTextStyle: {
      color: 'gray',
      fontSize: theme.typography.pxToRem(13),
      textAlign: 'center',
    },
    cardHeight: {
      height: theme.typography.pxToRem(271),
    },
    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = UseClasses();
  return classes;
};

/* eslint-disable */
import {
    RACCOLOR,
    makeStyles,
  } from "@rentacenter/racstrap";
  
export const useClasses = makeStyles((theme) => ({
    title: {
      color: "#212529",
      fontFamily: "OpenSans-bold",
      fontSize: "16px",
      marginBottom: "10px",
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      width: "100%",
    },
    checkboxwidth: {
      width: theme.typography.pxToRem(10),
    },
    checkboxStyle: {
      width: "unset",
      border: "none",
    },
    w100: {
      width: "100%",
    },
    floatLeft: {
      float: "left",
    },
    floatRight: {
      float: "right",
    },
    /* Margin and Padding spacing */
    m0: {
      margin: 0,
    },
  
    m1: {
      margin: theme.typography.pxToRem(4),
    },
  
    m2: {
      margin: theme.typography.pxToRem(8),
    },
  
    m3: {
      margin: theme.typography.pxToRem(16),
    },
  
    m4: {
      margin: theme.typography.pxToRem(24),
    },
  
    m5: {
      margin: theme.typography.pxToRem(48),
    },
  
    mAuto: {
      margin: "auto",
    },
  
    mx0: {
      marginRight: 0,
      marginLeft: 0,
    },
  
    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mx2: {
      marginRight: theme.typography.pxToRem(8),
      marginLeft: theme.typography.pxToRem(8),
    },
  
    mx3: {
      marginRight: theme.typography.pxToRem(16),
      marginLeft: theme.typography.pxToRem(16),
    },
    mx4: {
      marginRight: theme.typography.pxToRem(24),
      marginLeft: theme.typography.pxToRem(24),
    },
  
    mx5: {
      marginRight: theme.typography.pxToRem(48),
      marginLeft: theme.typography.pxToRem(48),
    },
  
    mxAuto: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  
    my0: {
      marginTop: 0,
      marginBottom: 0,
    },
  
    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },
  
    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },
  
    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
  
    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },
  
    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },
  
    myAuto: {
      marginTop: "auto",
      marginBottom: "auto",
    },
  
    mt0: {
      marginTop: 0,
    },
  
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
  
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
  
    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },
  
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
  
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },
  
    mtAuto: {
      marginTop: "auto",
    },
  
    me0: {
      marginRight: 0,
    },
  
    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },
  
    me3: {
      marginRight: theme.typography.pxToRem(16),
    },
  
    me4: {
      marginRight: theme.typography.pxToRem(24),
    },
  
    me5: {
      marginRight: theme.typography.pxToRem(48),
    },
  
    meAuto: {
      marginRight: "auto",
    },
  
    mb0: {
      marginBottom: 0,
    },
  
    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },
  
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
  
    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },
  
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
  
    mbAuto: {
      marginBottom: "auto",
    },
  
    ms0: {
      marginLeft: 0,
    },
  
    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },
  
    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },
  
    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },
  
    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },
  
    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },
  
    msAuto: {
      marginLeft: "auto",
    },
  
    p0: {
      padding: 0,
    },
  
    p1: {
      padding: theme.typography.pxToRem(4),
    },
  
    p2: {
      padding: theme.typography.pxToRem(8),
    },
  
    p3: {
      padding: theme.typography.pxToRem(16),
    },
  
    p4: {
      padding: theme.typography.pxToRem(24),
    },
  
    p5: {
      padding: theme.typography.pxToRem(48),
    },
  
    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  
    px1: {
      paddingRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(4),
    },
  
    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },
  
    px3: {
      paddingRight: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(16),
    },
  
    px4: {
      paddingRight: theme.typography.pxToRem(24),
      paddingLeft: theme.typography.pxToRem(24),
    },
  
    px5: {
      paddingRight: theme.typography.pxToRem(48),
      paddingLeft: theme.typography.pxToRem(48),
    },
  
    py0: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  
    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },
  
    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },
  
    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
  
    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },
  
    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },
  
    pt0: {
      paddingTop: 0,
    },
  
    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },
  
    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },
  
    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },
  
    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },
  
    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },
  
    pe0: {
      paddingRight: 0,
    },
  
    pe1: {
      paddingRight: theme.typography.pxToRem(4),
    },
  
    pe2: {
      paddingRight: theme.typography.pxToRem(8),
    },
  
    pe3: {
      paddingRight: theme.typography.pxToRem(16),
    },
  
    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },
  
    pe5: {
      paddingRight: theme.typography.pxToRem(48),
    },
  
    pb0: {
      paddingBottom: 0,
    },
  
    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },
  
    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },
  
    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },
  
    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },
  
    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },
  
    ps0: {
      paddingLeft: 0,
    },
  
    ps1: {
      paddingLeft: theme.typography.pxToRem(4),
    },
  
    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
  
    ps3: {
      paddingLeft: theme.typography.pxToRem(16),
    },
  
    ps4: {
      paddingLeft: theme.typography.pxToRem(24),
    },
  
    ps5: {
      paddingLeft: theme.typography.pxToRem(48),
    },
  
    font11: {
      fontSize: theme.typography.pxToRem(11),
    },
  
    font12: {
      fontSize: theme.typography.pxToRem(12),
    },
  
    font13: {
      fontSize: theme.typography.pxToRem(13),
    },
  
    font14: {
      fontSize: theme.typography.pxToRem(14),
    },
  
    font15: {
      fontSize: theme.typography.pxToRem(15),
    },
  
    font16: {
      fontSize: theme.typography.pxToRem(16),
    },
  
    font17: {
      fontSize: theme.typography.pxToRem(17),
    },
  
    font18: {
      fontSize: theme.typography.pxToRem(18),
    },
    customerNorecords: {
      textAlign: "center",
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
  
    mandatoryfield: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
    },
    formLabel: {
      marginBottom: "0.2rem",
      color: "#111111",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },
    raccollg5: {
      flex: "0 0 auto",
      width: "41.6666666667%",
    },
    borderrightstyle: {
      borderRight: "1px solid #D3D3D3",
    },
    raccollg6: {
      flex: "0 0 auto",
      width: "50%",
    },
    titletxtstyle: {
      fontSize: theme.typography.pxToRem(24),
    },
    bold: {
      fontFamily: "OpenSans-bold",
    },
    raccollg3: {
      flex: "0 0 auto",
      width: "25%",
    },
    labeltxtstyle: {
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
      color: "#000000",
    },
    raccollg4: {
      flex: "0 0 auto",
      width: "33.3333333333%",
    },
    labelnotxtstyle: {
      color: "#6C86A5",
    },
    raccollg7: {
      flex: "0 0 auto",
      width: "58.3333333333%",
    },
    alertcolor: {
      color: "#69a4ff",
    },
    alertwidgartcontainer: {
      flexWrap: "unset",
      overflow: "auto",
      display: "flex",
      float: "left",
      width: "420px",
    },
    raccollg2: {
      flex: "0 0 auto",
      width: "16.6666666667%",
    },
    Norecords: {
      textAlign: "center",
      paddingTop: theme.typography.pxToRem(30),
      paddingBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: "#000",
    },
    alertswidgartsstyle: {
      backgroundColor: "#FCE5E5",
      borderRadius: theme.typography.pxToRem(40),
      color: "#4B4B4B",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
      padding: "4px 15px 6px 15px",
      marginTop: "1px",
      float: "left",
      whiteSpace: "nowrap",
    },
    raccollg9: {
      flex: "0 0 auto",
      width: "75%",
    },
    racGrid: {
      "& th": {
        fontFamily: "OpenSans-semibold",
        whiteSpace: "nowrap",
      },
    },
    formcheckinput: {
      width: theme.typography.pxToRem(8),
      height: theme.typography.pxToRem(8),
      verticalAlign: "top",
      backgroundColor: "#fff",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      border: "1px solid rgba(0, 0, 0, 0.25)",
      "& checked": {
        backgroundColor: "#0d6efd",
        borderColor: "#0d6efd",
      },
    },
    rowColor: {
      backgroundColor: "#fff",
    },
    fontchange: {
      fontSize: theme.typography.pxToRem(13),
      whiteSpace: "nowrap",
    },
    racpadlink: {
      color: "#2179fe",
      fontSize: theme.typography.pxToRem(14),
      textDecoration: "none",
    },
    racpadpaymentsuccess: {
      borderLeft: "3px solid #56E0D8!important",
    },
    racpadagrlink: {
      borderLeft: "3px solid transparent",
      paddingLeft: theme.typography.pxToRem(10),
      borderRadius: theme.typography.pxToRem(2),
      padding: theme.typography.pxToRem(9),
    },
    racpadpaymentfails: {
      borderLeft: "3px solid #FD6A63",
    },
    accordiontoggle: {
      display: "block",
    },
    accordionarrowchan: {
      backgroundImage: "url(../assets/images/accordion-open-icon.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: theme.typography.pxToRem(20),
      cursor: "pointer",
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      display: "block",
    },
    accordionarrow: {
      backgroundImage: "url(../assets/images/accordion-close-icon.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: theme.typography.pxToRem(20),
      cursor: "pointer",
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      display: "block",
    },
    hiddenRow: {
      backgroundColor: "white",
    },
    backgroundcol: {
      backgroundColor: "#FAE2DF",
    },
    textcenter: {
      textAlign: "center",
    },
    textCenter: {
      textAlign: "center",
    },
    raccollg8: {
      flex: "0 0 auto",
      width: "66.6666666667%",
    },
    tabborder: {
      borderBottom: "1px solid #EFEFEF",
    },
    RACnav: {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: "0",
      marginBottom: "0",
      listStyle: "none",
    },
    racpadtab: {
      width: "auto",
    },
    semibold: {
      fontFamily: "OpenSans-semibold",
    },
    w25: {
      width: "25%",
    },
    inputgroup: {
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "stretch",
      width: "100%",
    },
    inputgrouptext: {
      display: "flex",
      alignItems: "center",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      lineHeight: "1.5",
      color: "#212529",
      textAlign: "center",
      whiteSpace: "nowrap",
      backgroundColor: "#e9ecef",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      borderTopLeftRadius: theme.typography.pxToRem(9),
      borderBottomLeftRadius: theme.typography.pxToRem(9),
    },
    formcontrol: {
      display: "block",
      fontSize: theme.typography.pxToRem(14),
      width: "100%",
      padding: "0.375rem 0.75rem",
      lineHeight: "1.5",
      color: "#212529",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      borderRadius: "0.6rem",
      border: "1px solid #C4C4C4",
    },
    textend: {
      textAlign: "right",
    },
    RAClabeltxtstyle: {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(5),
      fontFamily: "OpenSans-semibold",
      color: "#111111",
    },
    RACvaluetxtstyle: {
      color: "#B91919",
      fontSize: theme.typography.pxToRem(16),
    },
    w75: {
      width: "75%",
    },
    raccollg12: {
      flex: "0 0 auto",
      width: "100%",
    },
    borderbottom: {
      borderBottom: "1px solid #dee2e6",
    },
    formcheck: {
      display: "block",
      minHeight: "1.5rem",
      marginBottom: "0.125rem",
    },
    totalsublabelstyle: {
      fontSize: theme.typography.pxToRem(16),
    },
    totallabelstyle: {
      fontSize: theme.typography.pxToRem(18),
      color: "#fff",
    },
    editCardbtn: {
      textDecoration: "underline",
      cursor: "pointer",
      color: "#2279fd",
    },
    w50: {
      width: "50%",
    },
    updatebutton: {
      backgroundColor: "#E0EAFF",
      color: "#2468FF",
      border: "none",
    },
    deletebutton: {
      backgroundColor: "#FFD1D1",
      color: "#B50000",
      border: "none",
    },
    RACpaymenttotalcontainer: {
      backgroundColor: "#2179FE",
      borderRadius: theme.typography.pxToRem(10),
      width: "100%",
      float: "left",
      height: "100%",
    },
    totalvaluestyle: {
      fontSize: theme.typography.pxToRem(25),
    },
    colorwhite: {
      color: "#fff",
    },
    formcheckinline: {
      display: "inline-block",
      marginRight: "1rem",
    },
    changecredit: {
      color: "#fff",
    },
    containerborderbtm: {
      borderBottom: "1px solid #448BF7",
    },
    alertwidget: {
      borderRadius: "0",
    },
    labelbluetxtstyle: {
      color: "#2179FE",
      textDecoration: "none",
      fontFamily: "OpenSans-semibold",
    },
    justifycontentcenter: {
      display: "flex",
      justifyContent: "center",
    },
    raccollg10: {
      flex: "0 0 auto",
      width: "83.3333333333%",
    },
    autopayValue: {
      borderRadius: "50%",
      padding: "4px 10px",
      backgroundColor: "#EFF4FF",
      color: "#276BFF",
      fontFamily: "OpenSans-bold",
      fontSize: theme.typography.pxToRem(14),
    },
    autoPaySubTitle: {
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(17),
    },
    autoPaySubTitleAccent: {
      color: "#7E7E7E",
      fontSize: theme.typography.pxToRem(14),
      fontFamily: "OpenSans-semibold",
    },
    racTable: {
      backgroundColor: "#f7f7f7",
    },
    RACalertmsg: {
      color: "#ff1d1d",
    },
    raccollg11: {
      flex: "0 0 auto",
      width: "91.6666666667%",
    },
    tablerowtheme: {
      backgroundColor: "transparent",
    },
    clearbtn: {
      color: "#2179FE",
      fontFamily: "OpenSans-semibold",
    },
    modalfooter: {
      borderTop: "0px solid transparent",
    },
    racLoginAlert: {
      color: "red",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
    },
    racErrorIcon: {
      width: theme.typography.pxToRem(50),
    },
    racErrorFunction: {
      fontFamily: "OpenSans-semibold",
      marginBottom: theme.typography.pxToRem(20),
      marginTop: theme.typography.pxToRem(10),
    },
    racPinContainer: {
      margin: "0 auto",
      width: "94%",
    },
    racPinLabel: {
      fontFamily: "OpenSans-semibold",
      color: "#000",
      marginLeft: "0.5rem",
      marginBottom: theme.typography.pxToRem(3),
    },
    racPinError: {
      color: "red",
      marginTop: theme.typography.pxToRem(5),
      marginLeft: "0.5rem",
      fontFamily: "OpenSans-semibold",
    },
    mandatory: {
      marginBottom: theme.typography.pxToRem(8),
      "& after": {
        content: "*",
        color: "red",
      },
    },
    containerlftborder: {
      borderLeft: "1px solid #D6D6D6",
    },
    validationstyle: {
      color: "red",
      fontSize: theme.typography.pxToRem(14),
    },
    customsure: {
      color: "#CB324C",
    },
    bgpopdelete: {
      backgroundColor: "#F8F8F8",
      borderBottomLeftRadius: theme.typography.pxToRem(9),
      borderBottomRightRadius: theme.typography.pxToRem(9),
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0),
    },
    graycolortext: {
      color: "#52595e",
    },
    blackcolortext: {
      color: "#000",
    },
    btnkeepdelete: {
      color: "white",
      backgroundColor: "#CB324C",
      border: "#CB324C",
      paddingTop: theme.typography.pxToRem(11),
      paddingBottom: theme.typography.pxToRem(11),
    },
    racDeviceInfo: {
      position: "relative",
    },
    textright: {
      textAlign: "right",
    },
    customtextpay: {
      color: "#2179fe",
      fontFamily: "OpenSans-semibold",
    },
    textleft: {
      textAlign: "left",
    },
    listgroupitem: {
      backgroundColor: "#EFF4FF",
      color: "#2179FE",
      borderColor: "#EFF4FF",
      float: "left",
      width: "auto",
      borderRadius: theme.typography.pxToRem(3),
    },
    raccolauto: {
      width: "auto",
    },
    hide: {
      display: "none",
    },
    racDeviceGuide: {
      position: "absolute",
      background: "#fff",
      width: theme.typography.pxToRem(335),
      padding: theme.typography.pxToRem(15),
      right: "0",
      zIndex: 1,
      borderRadius: theme.typography.pxToRem(10),
      boxShadow: "0 0 6px #dfdfdf",
    },
    racDeviceImage: {
      "& svg": {
        width: "100%",
        height: "auto",
      },
      "& img": {
        width: "100%",
        height: "auto",
      },
    },
    toolbar: {
      position: "fixed",
      right: "0",
      bottom: "0",
      left: "0",
      zIndex: 1030,
      backgroundColor: "#fff",
      boxShadow: "0 0.5rem 1rem rgb(0 0 0 / 15%)",
      padding: "1rem",
    },
    btngroup: {
      position: "relative",
      display: "inline-flex",
      verticalAlign: "middle",
    },
    dropdownitemcustom: {
      color: "#2468FF",
      fontFamily: "OpenSans-bold",
      backgroundColor: "white",
      textDecoration: "none",
      cursor: "pointer",
    },
    dropdowntoggle: {
      "&: after": {
        display: "inline-block",
        marginLeft: "0.255em",
        verticalAlign: "0.255em",
        content: "",
        borderTop: "0.3em solid",
        borderRight: "0.3em solid transparent",
        borderBottom: "0",
        borderLeft: "0.3em solid transparent",
      },
    },
    footerbtnmenu: {
      border: "1px solid #2468FF",
      position: "absolute",
      top: "auto",
      left: "0",
      backgroundColor: "white",
      bottom: theme.typography.pxToRem(43),
      display: "none",
    },
    customerTablecellbgcolor: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
  }));
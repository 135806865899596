/**
 * Import the required packages, interface and context
 */
import { RACButton, Grid, Typography } from '@rentacenter/racstrap';
import React, { useContext } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { useHistory, useParams } from 'react-router-dom';
import { ParamType } from '../interface/commonInterface';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { CommitmentObject } from '../interface/customerPaymentInfo';
import { customerPaymentInfoStyle } from '../stylesJS/customerPaymentInfoStyle';
import CONSTANTS from '../constants/constant';
import { dateFormat } from '../utils/scheduleAndDateFormat';
import { AgreementContextValue } from '../interface/contextInterface';
/**
 * PS_CPI_02 - PS_CPI_11
 * Invoke a functional based component as CustomerPaymentInformation()
 * @returns
 */
export function CustomerPaymentInformation() {
  /**
   * PS_CPI_03 - PS_CPI_08
   * Declare variables and destruct from the context
   * Format date and time to display it in UI
   */
  const classes = customerPaymentInfoStyle();
  const { customerInfo } = useContext<AgreementContextValue>(agreementContext);
  const history = useHistory();
  const { customerId } = useParams<ParamType>();

  const commitmentObject: CommitmentObject = {
    type: CONSTANTS.HYPHEN,
    date: CONSTANTS.HYPHEN,
    time: CONSTANTS.HYPHEN,
    amount: CONSTANTS.EMPTY_STRING,
  };
  if (
    customerInfo?.customerPaymentInfo?.commitment &&
    customerInfo?.customerPaymentInfo?.commitment[0]?.commitmentStatusCode ==
      CONSTANTS.OP &&
    customerInfo?.customerPaymentInfo?.commitment[0]?.createdSource ==
      CONSTANTS.RACDB
  ) {
    const commitmentDate =
      customerInfo?.customerPaymentInfo?.commitment[0]?.commitmentDate;
    const splittedDate = commitmentDate.split(CONSTANTS.SPLIT_WITH_T);
    commitmentObject.date = dateFormat(splittedDate[0], CONSTANTS.MM_DD_YYYY);
    const formattedTime = splittedDate[1];
    commitmentObject.time = moment(formattedTime, CONSTANTS.HH_MM_SS).format(
      CONSTANTS.HH_MM_A
    );
    commitmentObject.type =
      customerInfo?.customerPaymentInfo?.commitment[0]?.commitmentTypeDesc;
    commitmentObject.amount = Number(
      customerInfo?.customerPaymentInfo?.commitment[0]?.commitmentAmount
    ).toFixed(2);
  }

  /**
   * PS_CPI_09 - PS_CPI_11
   * Within return, bind datas from the context and
   * navigate to account management page in the account management button click
   */
  return (
    <>
      <Grid item sm={6} md={4} lg={5}>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
          classes={{ root: classes.leftRightPadding12 }}
        >
          <Grid item sm={6} md={4} lg={4}>
            {/* <Grid container spacing={3}> */}
            <Grid container spacing={1} classes={{ root: classes.row }}>
              <Typography variant="h4" data-testid="PaymentInfoText">
                Payment Info
              </Typography>
              {/* </Grid> */}
            </Grid>

            <Grid container spacing={1} classes={{ root: classes.row }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                <Typography
                  display="inline"
                  variant="body1"
                  data-testid="schedule"
                >
                  Schedule:
                </Typography>
              </Grid>
              <Grid
                className={classes.alignChildrenBottom}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={7}
              >
                <Typography
                  display="inline"
                  variant="body1"
                  className={classes.valueText}
                  data-testid="scheduleValue"
                >
                  {customerInfo?.schedule || CONSTANTS.HYPHEN}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} classes={{ root: classes.row }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                <Typography
                  display="inline"
                  variant="body1"
                  data-testid="checks"
                >
                  Checks:
                </Typography>
              </Grid>
              <Grid
                className={classes.alignChildrenBottom}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={7}
              >
                <Typography
                  display="inline"
                  variant="body1"
                  className={classes.valueText}
                  data-testid="checkValue"
                >
                  {customerInfo?.customerPaymentInfo?.check}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} classes={{ root: classes.row }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                <Typography
                  display="inline"
                  variant="body1"
                  data-testid="dayPaid"
                >
                  Day Paid:{' '}
                </Typography>
              </Grid>
              <Grid
                className={classes.alignChildrenBottom}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={7}
              >
                <Typography
                  display="inline"
                  variant="body1"
                  className={classes.valueText}
                  data-testid="dayPaidValue"
                >
                  {customerInfo?.dayPaid || CONSTANTS.HYPHEN}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1} classes={{ root: classes.row }}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                <Typography
                  display="inline"
                  variant="body1"
                  data-testid="nsf/cccb"
                >
                  NSFC/CCCB:{' '}
                </Typography>
              </Grid>
              <Grid
                className={classes.alignChildrenBottom}
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={7}
              >
                <Typography
                  display="inline"
                  variant="body1"
                  className={classes.valueText}
                  data-testid="nsfCccbValue"
                >
                  {customerInfo?.customerPaymentInfo?.nsfCccb}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={6} md={4} lg={7}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h4" data-testid="lateLast10">
                  # Late Last 10
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ right: '50px', top: '60px', position: 'absolute' }}
                data-testid="barchartGrid"
              >
                <BarChart
                  width={400}
                  height={200}
                  data={customerInfo?.customerPaymentInfo?.lateLast10}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <Bar
                    dataKey="students"
                    fill="rgb(13, 110, 253)"
                    radius={20}
                    barSize={10}
                  />
                  <XAxis dataKey="name" axisLine={false} />
                  <YAxis axisLine={false} />
                </BarChart>
              </Grid>

              <Grid
                item
                sm={2}
                md={2}
                lg={12}
                container
                justifyContent="flex-end"
                style={{ float: 'right', top: '204px', position: 'relative' }}
              >
                <RACButton
                  data-testid="accountManagementBtn"
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() =>
                    history.push({ pathname: `/am/customer/${customerId}` })
                  }
                >
                  Account Management
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
          {/**
           * Need to check the commitment binding with response in context
           */}
          <Grid item lg={8} style={{ marginTop: '10%' }}>
            <Grid container spacing={1} classes={{ root: classes.row }}>
              <Typography variant="h4" data-testid="Commitment">
                Commitment
              </Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container spacing={1} classes={{ root: classes.row }}>
                  <Grid item lg={3}>
                    <Typography
                      display="inline"
                      variant="body1"
                      data-testid="type"
                    >
                      Type :
                    </Typography>
                  </Grid>
                  <Grid className={classes.alignChildrenBottom} item lg={9}>
                    <Typography
                      display="inline"
                      variant="body1"
                      className={classes.valueText}
                      data-testid="typeValue"
                    >
                      {commitmentObject.type}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1} classes={{ root: classes.row }}>
                  <Grid item lg={3}>
                    <Typography
                      display="inline"
                      variant="body1"
                      data-testid="time"
                    >
                      Time :
                    </Typography>
                  </Grid>
                  <Grid className={classes.alignChildrenBottom} item lg={9}>
                    <Typography
                      display="inline"
                      variant="body1"
                      className={classes.valueText}
                      data-testid="timeValue"
                    >
                      {commitmentObject.time}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} classes={{ root: classes.row }}>
                  <Grid item lg={4}>
                    <Typography
                      display="inline"
                      variant="body1"
                      data-testid="date"
                    >
                      Date :
                    </Typography>
                  </Grid>
                  <Grid className={classes.alignChildrenBottom} item lg={8}>
                    <Typography
                      display="inline"
                      variant="body1"
                      className={classes.valueText}
                      data-testid="dateValue"
                    >
                      {commitmentObject.date}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  classes={{ root: classes.row }}
                  style={{ display: 'inline' }}
                >
                  <Grid item lg={4} style={{ display: 'inline' }}>
                    <Typography
                      display="inline"
                      variant="body1"
                      data-testid="amount"
                    >
                      Amount :
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.alignChildrenBottom}
                    item
                    lg={8}
                    style={{ display: 'inline' }}
                  >
                    <Typography
                      display="inline"
                      variant="body1"
                      className={classes.valueText}
                      data-testid="amountValue"
                    >
                      {commitmentObject.amount
                        ? `$${commitmentObject.amount}`
                        : CONSTANTS.HYPHEN}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-undef */

/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */




/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prefer-const */





import React from 'react';
import { createContext, useState } from 'react';
/**RIS_PC_NO_28 RentadataContext useContext is created */
export const RentaldataContext = createContext<any>('');
export function RisContext(props: any) {
//   const [Risresponse, setRisresponse] = useState();
//   const [editresponse,setEditresponse]= useState<any>();
  const [slaRole,setSlaRole]=useState<any>();
  const [updatedInventory, setUpatedInventory] = useState<any>([])

  return (
    <div>
      <RentaldataContext.Provider value={{ 
        // Risresponse,
        // setRisresponse,
        // editresponse,
        // setEditresponse ,
        slaRole,
        setSlaRole,
        updatedInventory,
        setUpatedInventory
        }}>
        {props.children}
      </RentaldataContext.Provider>
    </div>
  );
}

/* eslint-disable prettier/prettier */
import React, { useState, createContext } from 'react';

export const userTraceContextData = createContext<any>([]);

export function UserTraceContext(props: any) {
const [userTraceData,setUserTraceData] = useState<any>([])


  return (
    <div>
      <userTraceContextData.Provider
        value={{
            userTraceValue: [userTraceData, setUserTraceData]
            
        }}
      >
        {props.children}
      </userTraceContextData.Provider>
    </div>
  );
}

/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable prettier/prettier*/


import moment from 'moment';
import React, { useContext } from 'react'
import { CustomerdataContext } from '../../context/customer/customerdata';
import { PaymentHistoryInterface, PaymentHistoryPrintInfoProps } from '../interface/paymentHistoryInterface';
import { CustomerAddressDetail, CustomerContactDetail } from '../interface/paymentInfoInterface';
import CONSTANTS from '../constants/constant';
import { takePaymentStyles } from '../../stylesJS/makeStyles';

export const PaymentSendEmail = (props: PaymentHistoryPrintInfoProps) => {
  const classes = takePaymentStyles();
  const { customerdata } = useContext(CustomerdataContext);

  let result: any = undefined
  let customerPrimaryAddress: string = CONSTANTS.EMPTY_STRING;
  let customerSecondaryAddress: string = CONSTANTS.EMPTY_STRING;
  let customerCity = CONSTANTS.EMPTY_STRING;

  let customerPlusFour = CONSTANTS.EMPTY_STRING;
  let customerZip = CONSTANTS.EMPTY_STRING
  let customerState = CONSTANTS.EMPTY_STRING


  result = customerdata

  if (result != undefined && result != CONSTANTS.EMPTY_STRING) {
    
    result = result.data
    if (result?.GetCustomer?.value?.addresses != undefined) {
      let customerAdressFilterArray: CustomerAddressDetail[] = result?.GetCustomer?.value?.addresses?.filter((x: CustomerAddressDetail) => 
        x.addressTypeDesc == CONSTANTS.PRIMARY_ADDRESS
      )
      if (customerAdressFilterArray.length == 0 && result?.GetCustomer?.value?.addresses.length > 0) {
        customerAdressFilterArray = [result?.GetCustomer?.value?.addresses[0]]
      } 
      customerPrimaryAddress = customerAdressFilterArray.length > 0 ? customerAdressFilterArray[0].addressLine1 : CONSTANTS.EMPTY_STRING
      customerSecondaryAddress = customerAdressFilterArray.length > 0 ? customerAdressFilterArray[0].addressLine2 : CONSTANTS.EMPTY_STRING
      customerPlusFour = customerAdressFilterArray.length > 0 ? customerAdressFilterArray[0].postalCode != null?customerAdressFilterArray[0].postalCode?.split("-")[1]:CONSTANTS.EMPTY_STRING:CONSTANTS.EMPTY_STRING
      customerZip = customerAdressFilterArray.length > 0 ? customerAdressFilterArray[0].postalCode != null?customerAdressFilterArray[0].postalCode?.split("-")[0]:CONSTANTS.EMPTY_STRING:CONSTANTS.EMPTY_STRING
      customerState = customerAdressFilterArray.length > 0 ? customerAdressFilterArray[0].state : CONSTANTS.EMPTY_STRING
      customerCity = customerAdressFilterArray.length > 0 ? customerAdressFilterArray[0].city : CONSTANTS.EMPTY_STRING
    }
    let customerPhoneDetails: CustomerContactDetail[] = result?.GetCustomer?.value?.phones?.filter((x: CustomerContactDetail) => 
      x.primary == CONSTANTS.STATUS_YES
    )
    if (customerPhoneDetails.length == 0 && result?.GetCustomer?.value?.phones.length > 0) {
      customerPhoneDetails = [result?.GetCustomer?.value?.phones[0]]
    }
  }



  const BindGrid = () => {



    return props.paygridvalues.map((value: PaymentHistoryInterface, index: number) => {

      return (

        <tr key={index}>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.datePaid}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.dayPaid}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.timePaid}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.receiptId}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right", "paddingRight": "20px" }}> {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.totalPayment}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{" "}
            {value.nextDueDate}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right", "paddingRight": "20px" }}>{" "}
            {value.daysLate}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right", "paddingRight": "20px" }}>
            {value.daysExt}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{" "}
            {value.store}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "left", "paddingRight": "20px" }}>{" "}
            {value.paymentOrigin}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{" "}
            {value.tenderType}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}> {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.netRent}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>{" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.clubAmount}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
        </tr>


      )
    })
  }

  const BindGridSummery = () => {
    return props.paygridvalues.map((value: PaymentHistoryInterface, index: number) => {

      return (

        <tr key={index}>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.datePaid}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.dayPaid}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.timePaid}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px" }}>{value.receiptId}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right", "paddingRight": "20px" }}> {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.totalPayment}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>
            {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.netRent}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>{" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.clubAmount}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>
            {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.ldw}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>
            {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.lateFee}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>
            {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.discount}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>
            {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.other}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}>
            {" "}
            {(Number(value.isReversed) == 1) ? ("(") : null}
            $
            {value.tax}
            {(Number(value.isReversed) == 1) ? (")") : null}</td>
          <td style={{ "paddingTop": "15px", "fontWeight": 600, "fontSize": "13px", "textAlign": "right" }}></td>
        </tr>


      )
    })
  }






  return (
    <div style={{ display: "none" }}>


      <div style={{ "width": "100%", "float": "left" }} id={"PaymentAgreementPrintInfoEmail"}>
        <h2>Payment History by Customer</h2>
        <table style={{ width: "100%", border: 'none' }}>
          <tbody>
            <tr style={{ border: 'none' }}>
              <th style={{ border: 'none', textAlign: "left", width: "50%", backgroundColor: 'white' }}>Store #:{String(window.sessionStorage.getItem("storeNumber"))}</th>
              <th style={{ border: 'none', textAlign: "left", width: "30%", backgroundColor: 'white' }}>Date :{moment().format('MM/DD/YYYY')}</th>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%", margin: "0px 0px 32px 0px" }}>
          <tbody>
            <tr style={{ border: 'none' }}>
              <th
                style={{
                  textAlign: "left",
                  width: "30%",
                  border: 'none',
                  backgroundColor: 'white'
                }}
              >
                Customer Information
              </th>
            </tr>
            <tr>
              <td style={{
                border: 'none',
                backgroundColor: 'white'
              }}>{props.printInfo.commoninformation?.customerName} {customerPrimaryAddress == CONSTANTS.EMPTY_STRING ? customerSecondaryAddress : customerPrimaryAddress} {customerCity}, {customerState} {customerZip}-{customerPlusFour}</td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%", margin: "0px 0px 32px 0px" }}>
          <tbody>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  width: "20%",
                  border: 'none',
                  backgroundColor: 'white'
                }}
              >
                PIF Agreements
              </th>
              <th
                style={{
                  textAlign: "left",
                  width: "20%",
                  border: 'none',
                  backgroundColor: 'white'
                }}
              >
                EPO Agreements
              </th>
              <th
                style={{
                  textAlign: "left",
                  width: "20%",
                  border: 'none',
                  backgroundColor: 'white'
                }}
              >
                Total Revenue
              </th>
              <th
                style={{
                  textAlign: "left",
                  width: "20%",
                  border: 'none',
                  backgroundColor: 'white'
                }}
              >
                NSF Checks
              </th>
              <th
                style={{
                  textAlign: "left",
                  width: "20%",
                  border: 'none',
                  backgroundColor: 'white'
                }}
              >
                Customer Since
              </th>
            </tr>
            <tr>
              <td style={{
                border: 'none',
                backgroundColor: 'white'
              }}
              >{props.printInfo.commoninformation?.pifAgreements}</td>
              <td style={{
                border: 'none',
                backgroundColor: 'white'
              }}
              >{props.printInfo.commoninformation?.epoAgreements}</td>
              <td style={{
                border: 'none',
                backgroundColor: 'white'
              }}
              >{props.printInfo.commoninformation?.totalRentalRevenue}</td>
              <td style={{
                border: 'none',
                backgroundColor: 'white'
              }}
              >{props.printInfo.commoninformation?.nsfChecks}</td>
              <td style={{
                border: 'none',
                backgroundColor: 'white'
              }}
              >{props.printInfo.commoninformation?.customerSince}</td>
            </tr>
          </tbody>
        </table>


        {/*Agreeements section ends here*/}
        {/*Payment History Summary section starts here*/}
        <div style={{ "width": "100%", "float": "left", "marginBottom": "20px" }}>
          <h4 style={{ "fontWeight": "bold", "fontSize": "13px" }}>Payment History Summary</h4>
          <table style={{ "borderCollapse": "collapse", "width": "100%" }}>
            <thead>
              <tr>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Date Paid</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Day</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Time</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Receipt #</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontWeight": "bold", "fontSize": "13px", "paddingRight": "20px" }}>Total Payment</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Next Due Date</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Free Days</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold", "paddingRight": "20px" }}>Days Late</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Store</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Payment Origin</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Tender Type</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Net Rent</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Club</th>
              </tr>
            </thead>
            <tbody>
              {BindGrid()}
            </tbody>
          </table>
        </div>
        {/*Payment History Summary section ends here*/}
        {/*Payment History Detail section starts here*/}
        <div style={{ "width": "100%", "float": "left", "marginBottom": "20px" }}>
          <h4 style={{ "fontWeight": "bold", "fontSize": "13px" }}>Payment History Detail</h4>
          <table style={{ "borderCollapse": "collapse", "width": "100%" }}>
            <thead>
              <tr>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Date Paid</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Day</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Time</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "left", "fontSize": "13px", "fontWeight": "bold" }}>Receipt #</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontWeight": "bold", "fontSize": "13px", "paddingRight": "20px" }}>Total Payment</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Net Rent</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Club</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>LDW</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Late Fee</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Discount</th>
                <th  className={`${classes.SendEmailColorPadding}`}style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Other</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Tax</th>
                <th className={`${classes.SendEmailColorPadding}`} style={{ "color": "#000", "paddingBottom": "8px", "textAlign": "right", "fontSize": "13px", "fontWeight": "bold" }}>Card Type</th>
              </tr>
            </thead>
            <tbody>
              
              {BindGridSummery()}
            </tbody>
          </table>
        </div>
        {/*Payment History Detail section ends here*/}
      </div>
    </div>
  )


}
export default PaymentSendEmail
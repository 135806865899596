/* eslint-disable  */
/* eslint-disable sonarjs/cognitive-complexity */
/**
 * PS_CO_NO_OS_1 - PS_CO_NO_OS_2
 * The required hooks and the racstrap components are imported
 */
import React, { useState, useEffect, useContext } from "react";
import { takePaymentStyles } from "../../../stylesJS/makeStyles";
import {
  RACButton,
  Grid,
  List,
  ListItem,
  makeStyles,
} from "@rentacenter/racstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { agreementContext } from "../../context/PaymentInformationContext";
import { calculatePaymentModule } from "../../../api/user";
import { OptionalService } from "./OptionalService";
import { CarryWaiveLateFeePopUp } from "./CarryWaiveLateFee";
import { useParams, useHistory } from "react-router-dom";
import calculationModulePayloadFormationUpdate from "../CommonModulePayloadFormationUpdate";
import { AgreementDetails } from "../../interface/paymentInfoInterface";
import {
  LdwStatusFlag,
  OptionalServiceFlagInterface,
} from "../../interface/optionalServiceInterface";
import CONSTANTS from "../../constants/constant";
import {
  AgreementPaymentDetails,
  AmountDetails,
  PaymentDetails,
} from "../../interface/calculatePaymentResponse";
import SecondFactor from "../../../racstrapComponents/microfrontends/TwoFactorPopup";
import { ParamType } from "../../interface/commonInterface";
import {
  agrPaymentHistory,
  clubPaymentHistory,
  getpayHis,
  recpaymentHistory,
} from "../../utils/paymentHistoryReceiptHandler";
import { ClubActivityInfo, ClubPaymentHistoryGridInfo, ClubInfo, CustomerInfoPaymentHis, PaymentHistoryGridInfo, PaymentHistoryResponse, Rootobject, constructAgreementPayloadDeciderInterface, customerPaymentHistory, uniqueAgreementIDDropDown, PaymentHistoryInterface } from "../../interface/paymentHistoryInterface";
import { EditScheduleComponent } from "../EditSchedule";
import { Theme } from "@mui/material";
import PaymentHistoryComponent from "./PaymentHistory";
import calculationModulePayloadFormation from "../existingFunctions/commonModulePayloadFormation";
import ClubPayment from "../ClubPaymentHistory";

const useClasses = makeStyles((theme: Theme) => ({
  navLink: {
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",
    position: "relative",
    fontFamily: "OpenSans-semibold",
    color: "#000",
  },
  navLinkactive: {
    color: "#2179FE",
    borderBottom: "5px solid #2468FF !important",
    fontFamily: "OpenSans-semibold",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "5px",
      width: "10px",
      background: "#2468ff",
      left: "-5px",
      bottom: "-5px",
      borderTopLeftRadius: "5px",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      height: "5px",
      width: "10px",
      background: "#2468ff",
      right: "-5px",
      bottom: "-5px",
      borderTopRightRadius: "5px",
    },
  },
  dropdowntoggle: {
    display: "inline-block",
    marginLeft: "0.255em",
    verticalAlign: "0.255em",
    content: "",
    borderTop: "0.3em solid",
    borderRight: "0.3em solid transparent",
    borderBottom: "0",
    borderLeft: "0.3em solid transparent",
  },
  dropdownitemcustom: {
    color: "#2468FF",
    fontFamily: "OpenSans-bold",
    backgroundColor: "white",
    textDecoration: "none",
    cursor: "pointer",
  },
  popUp: {
    position: "absolute",
    bottom: "100%",
    backgroundColor: "white",
    width: "200px",
    padding: "15px",
    "& li": {
      listStyle: "none",
    },
  },
  popUpHide: {
    display: "none",
  },
  customMenuContainer: {
    border: "1px solid #2468FF",
  },
  paymentBtnHover: {
    "&:hover": {
      color: "white",
      backgroundColor: "#0d6efd",
      borderColor: "#0d6efd",
    },
  },
}));
export function OtherActionComponent() {
  const history = useHistory();
  const classes = takePaymentStyles();
  const navlinkClass = useClasses();
  const { customerId } = useParams<ParamType>();
  const { agreementDetails, coWorkerDetails, paymentHistoryResponse, customerInfo, featureFlags, setOtherActionLoader, multiStoreSelected } = useContext(agreementContext);

  /**
   * PS_CO_NO_OS_3
   * The state variable declaration used for the optional service functionality
   *
   * start here
   */
  const [ldwStatusFlag, setLdwStatusFlag] = useState<LdwStatusFlag>(
    {} as LdwStatusFlag
  );
  const [optionalServiceFlag, setOptionalServiceFlag] =
    useState<OptionalServiceFlagInterface>({
      activateLDW: false,
      terminateLDW: false,
      activatePolicy: false,
      deactivatePolicy: false,
    });
  let [selectedAgreements, setSelectedAgreements] = useState<
    AgreementDetails[]
  >([]);
  const [index, setIndex] = useState<number>();
  /**Optional service ends here */

  const [editSchedulePopupFlag, setEditSchedulePopupFlag] =
    useState<boolean>(false);
  const [carryWaiveLateFeeFlag, setCarryWaiveLateFeeFlag] =
    useState<boolean>(false);
  const [carrayWaiveAuthenticationFlag, setCarryWaiveAuthenticationFlag] =
    useState<boolean>(false);

  const [loader, setLoader] = useState<boolean>(false);
  const [PopupClass, setPopupClass] = useState<string>(
    `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
  );

  /**
   * State variable declaration for PaymentHistory
   */
  const [ClubGridInfoResp, setClubGridInfoResp] = useState<ClubPaymentHistoryGridInfo[]>([]);
  const [clubActivityInfo, setclubActivityInfo] = useState<ClubActivityInfo[]>([] as ClubActivityInfo[]);
  const [uniqueAgreementID, setuniqueAgreementID] = useState<uniqueAgreementIDDropDown[]>([]);
  const [commoninformation, setcommoninformation] = useState<CustomerInfoPaymentHis>();
  const [clubinformation, setclubinformation] = useState<ClubInfo>();
  const [agreementRentPaid, setAgreementRentPaid] = useState<PaymentHistoryGridInfo[]>([]);
  const [paymentHisGrid, setpaymentHisGrid] = useState<PaymentHistoryInterface[][]>([]);
  let [payhisflag, setpayhisflag] = useState<boolean>(true);
  let [payclubflag, setpayclubflag] = useState<boolean>(true);
  const [agreementPayloadDecider, setAgreementPayloadDecider] = useState<constructAgreementPayloadDeciderInterface[]>(
    []
  );
  const [scrollLoader, setscrollLoader] = useState<boolean>(false);
  const [hasMoreReceipt, sethasMoreReceipt] = useState<boolean>(true);
  const [clubOffSet, setClubOffSet] = useState<number>(CONSTANTS.ZERO_NUMBER);
  const [receiptOffSet, setReceiptOffSet] = useState<number>(CONSTANTS.ZERO_NUMBER);
  const [PaymentHistory, setPaymentHistory] = useState<boolean>(false);
  const [ClubPaymentHistory, setClubPaymentHistory] = useState<boolean>(false);
  const [hasMoreClubRcp, sethasMoreClubRcp] = useState<boolean>(true);
  /**
   * PS_CO_NO_OS_4 to PS_CO_NO_OS_5
   * When the single agreement is selected this useEffect is triggered
   *  */

  useEffect(() => {
    const checkedAgr = agreementDetails.filter((x) => x.selected);
    selectedAgreements = checkedAgr;
    setSelectedAgreements(selectedAgreements);
    if(checkedAgr?.length == CONSTANTS.ONE_NUMBER)
      loadCoverageStatus();
  }, [agreementDetails]);

  /** This useEffect is used to handle the payment history. */
  useEffect(() => {
    const fetchPaymentHistory = async () => {

      const paymentHistoryResponselocal = await getpayHis(paymentHistoryResponse);
      variableUpdate(paymentHistoryResponselocal);
      if (paymentHistoryResponselocal.paymentHisGrid[0]?.length > CONSTANTS.ZERO_NUMBER) {
        setpayhisflag(false); // Responsible for Button Click
      } // Need once
      if (paymentHistoryResponselocal.ClubGridInfoResp?.length > CONSTANTS.ZERO_NUMBER) {
        setpayclubflag(false); // Responsible for View Club Payment History Button disable state
      } // Need once

      if (
        paymentHistoryResponselocal.paymentHisGrid[0]?.length == CONSTANTS.ZERO_NUMBER &&
        paymentHistoryResponselocal.ClubGridInfoResp?.length > CONSTANTS.ZERO_NUMBER
      ) {
        setpayhisflag(false);
        setpayclubflag(false);
      }
    }
    fetchPaymentHistory()
  }, [paymentHistoryResponse]);

  const variableUpdate = (paymentHistoryResponse: PaymentHistoryResponse) => {
    const {
      ClubGridInfoResp,
      clubActivityInfo,
      uniqueAgreementID,
      commoninformation,
      clubinformation,
      agreementRentPaid,
      paymentHisGrid,
      agreementPayloadDecider,
    } = paymentHistoryResponse;

    setclubinformation(clubinformation);
    setclubActivityInfo([...(clubActivityInfo || [])]);
    setuniqueAgreementID(uniqueAgreementID);
    setAgreementRentPaid(agreementRentPaid);
    setcommoninformation(commoninformation);
    setpaymentHisGrid(paymentHisGrid);
    setClubGridInfoResp([...(ClubGridInfoResp || [])]);
    setAgreementPayloadDecider(agreementPayloadDecider);
  }

  /**PS_CO_NO_OS_6 - PS_CO_NO_OS_20
   * The loadCoverageStatus() method is used to handle the Policy and LDW
   * buttons
   */
  const loadCoverageStatus = async () => {
    let selectedIndex: number;
    const agreementDetailsCopy: AgreementDetails[] = agreementDetails;
    const checkedAgr = agreementDetails.filter((x) => x.selected);
    const storeNumber = window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER);

    if (
      checkedAgr?.length == CONSTANTS.ONE_NUMBER &&
      checkedAgr[0].agreementType != CONSTANTS.CLUB &&
      checkedAgr[0].agreementType != CONSTANTS.PROMO &&
      checkedAgr[0].otherIncomeType == CONSTANTS.EMPTY_STRING &&
      checkedAgr[0].storeNumber == storeNumber
    ) {
      selectedIndex = agreementDetails.findIndex(
        (x) => x.agreementId == checkedAgr[0].agreementId
      );
      setIndex(selectedIndex);
      checkedAgr[0].toggleOptionalService =
        checkedAgr[0]?.agreementRateDetails?.isLdwCoverageAllowed ==
          CONSTANTS.STATUS_YES ||
          checkedAgr[0]?.agreementRateDetails?.isPolicyCoverageAllowed ==
          CONSTANTS.STATUS_YES
          ? CONSTANTS.STATUS_YES
          : CONSTANTS.EMPTY_STRING;
      checkedAgr[0].dueDateModified = checkedAgr[0]?.isAdjustDuedateEdit
      setOtherActionLoader(true);
      setLoader(true);
      const commonModulePayload = await calculationModulePayloadFormation(
        {
          customerId: customerId,
          storeNumber: storeNumber,
          checkedAgr: [checkedAgr[0]],
        }
      );
      checkedAgr[0].toggleOptionalService = CONSTANTS.EMPTY_STRING;

      let commonModuleServiceRes = await calculatePaymentModule(
        commonModulePayload
      );
      setOtherActionLoader(false);
      setLoader(false);
      if (commonModuleServiceRes.status == 200) {
        commonModuleServiceRes =
          commonModuleServiceRes?.data.paymentDetails.filter(
            (x: PaymentDetails) => x.storeNumber == storeNumber
          );
        const commonModuleAgrRes: AgreementPaymentDetails[] =
          commonModuleServiceRes[0]?.agreementPaymentDetails?.filter(
            (x: AgreementPaymentDetails) =>
              String(x.agreementId) == String(checkedAgr[0].agreementId)
          );

        const ldwAmount = commonModuleAgrRes[0]?.amountDetails?.filter(
          (x: AmountDetails) =>
            x.itemCategory == CONSTANTS.LDW || x.itemCategory == CONSTANTS.LTLDW
        );
        // [0]?.amount??CONSTANTS.ZREO_TWO_DECIMAL_STRING;
        const policyAmount = commonModuleAgrRes[0]?.amountDetails?.filter(
          (x: AmountDetails) => x.itemCategory == CONSTANTS.RACTIREPAY
        );
        // [0]?.amount??CONSTANTS.ZREO_TWO_DECIMAL_STRING;

        if (
          agreementDetailsCopy[selectedIndex].nextDueDate != CONSTANTS.EPO ||
          agreementDetailsCopy[selectedIndex].numberOfPaymentsMade !=
          CONSTANTS.ZERO_NUMBER
        ) {
          setLdwStatusFlag({
            ...ldwStatusFlag,
            isLdwCoverageAllowed:
              agreementDetailsCopy[selectedIndex]?.agreementRateDetails
                ?.isLdwCoverageAllowed ?? CONSTANTS.EMPTY_STRING,
            isPolicyCoverageAllowed:
              agreementDetailsCopy[selectedIndex]?.agreementRateDetails
                ?.isPolicyCoverageAllowed ?? CONSTANTS.EMPTY_STRING,
            coverageStatus:
              agreementDetailsCopy[selectedIndex].isCoverageActionPerformed ??
              CONSTANTS.EMPTY_STRING,
          });

          updateCoverageDetails(
            ldwAmount,
            selectedIndex,
            commonModuleAgrRes,
            policyAmount
          );
        }
      }
    }
  };

  /**
   * PS_CO_NO_OS_19
   * The updateCoverageDetails() is used to update the values for the LDW and policy
   * keys dynamicaly.
   * @param ldwAmount
   * @param selectedIndex
   * @param commonModuleAgrRes
   * @param policyAmount
   */
  const updateCoverageDetails = async (
    ldwAmount: AmountDetails[] | [],
    selectedIndex: number,
    commonModuleAgrRes: AgreementPaymentDetails[],
    policyAmount: AmountDetails[] | []
  ) => {
    if (ldwAmount?.length > 0) {
      agreementDetails[selectedIndex].ldwAmount =
        agreementDetails[selectedIndex].isZeroPayment ? CONSTANTS.ZREO_TWO_DECIMAL_STRING :
          ldwAmount[0]?.amount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      agreementDetails[selectedIndex].ldwTax =
        agreementDetails[selectedIndex].isZeroPayment ? CONSTANTS.ZREO_TWO_DECIMAL_STRING :
          ldwAmount[0]?.tax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      updateAgreementRateDetails(
        selectedIndex,
        commonModuleAgrRes,
        CONSTANTS.LDW_LOWER_CASE
      );
    } else if (policyAmount?.length > 0) {
      agreementDetails[selectedIndex].policyAmount =
        agreementDetails[selectedIndex].isZeroPayment ? CONSTANTS.ZREO_TWO_DECIMAL_STRING :
          policyAmount[0].amount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      agreementDetails[selectedIndex].policyTax =
        agreementDetails[selectedIndex].isZeroPayment ? CONSTANTS.ZREO_TWO_DECIMAL_STRING :
          policyAmount[0].tax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      updateAgreementRateDetails(
        selectedIndex,
        commonModuleAgrRes,
        CONSTANTS.POLICY_LOWER_CASE
      );
    }
  };
  /**
   * PS_CO_NO_OS_19 - PS_CO_NO_OS_20
   * The updateAgreementRateDetails() is used to update the values for the LDW and policy
   * keys dynamicaly.
   * @param selectedIndex
   * @param commonModuleAgrRes
   * @param type
   */
  const updateAgreementRateDetails = (
    selectedIndex: number,
    commonModuleAgrRes: AgreementPaymentDetails[],
    type: string
  ) => {
    const rateDetails = agreementDetails[selectedIndex]?.agreementRateDetails;
    if (rateDetails) {
      rateDetails[`perDay${type}`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`perDay${type}`]
      ).toFixed(2);
      rateDetails[`perDay${type}Tax`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`perDay${type}Tax`]
      ).toFixed(2);
      rateDetails[`weekly${type}`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`weekly${type}`]
      ).toFixed(2);
      rateDetails[`weekly${type}Tax`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`weekly${type}Tax`]
      ).toFixed(2);
      rateDetails[`biWeekly${type}`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`biWeekly${type}`]
      ).toFixed(2);
      rateDetails[`biWeekly${type}Tax`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`biWeekly${type}Tax`]
      ).toFixed(2);
      rateDetails[`semiMonthly${type}`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`semiMonthly${type}`]
      ).toFixed(2);
      rateDetails[`semiMonthly${type}Tax`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`semiMonthly${type}Tax`]
      ).toFixed(2);
      rateDetails[`monthly${type}`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`monthly${type}`]
      ).toFixed(2);
      rateDetails[`monthly${type}Tax`] = Number(
        commonModuleAgrRes[0].agreementRateDetails[`monthly${type}Tax`]
      ).toFixed(2);
    }
  };

  /**
   * PS_CO_NO_OS_26 - PS_CO_NO_OS_27
   * The optionalServiceOnclick() is invoked while clicking Activate Policy or LDW and to
   * deactivate Policy or LDW.
   * @param name
   */
  const optionalServiceOnclick = (name: string) => {
    setOptionalServiceFlag({ ...optionalServiceFlag, [name]: true });
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  /**
   * This function is used to open the Edit Schedule popup
   */
  const editScheduleOnClick = () => {
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
    setEditSchedulePopupFlag(true);
  };

  const popupButtonclick = () => {
    if (
      PopupClass ===
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    ) {
      setPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
      );
    }
    if (
      PopupClass === `${navlinkClass.customMenuContainer} ${navlinkClass.popUp}`
    ) {
      setPopupClass(
        `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
      );
    }
  };
  const setPopupClassFn = () => {
    setPopupClass(
      `${navlinkClass.customMenuContainer} ${navlinkClass.popUpHide}`
    );
  };

  const getClubWiseAllData = async () => {
    setscrollLoader(true);
    let clubPayData: Rootobject = {} as Rootobject;
    await Promise.all([clubPaymentHistory(clubOffSet + 10, customerId)]).then(
      (resp) => {
        [clubPayData] = resp;
      }
    );
    const ClubGridInfoRespLocal: ClubPaymentHistoryGridInfo[] =
      clubPayData.value.clubHistoryGridInfo;
    if (ClubGridInfoRespLocal.length) {
      setClubGridInfoResp([...ClubGridInfoResp, ...ClubGridInfoRespLocal]); // Need to check this

      setClubOffSet(clubOffSet + 10);
    } else {
      sethasMoreClubRcp(false);
    }
  };

  /**
   * This function used to fetch the receipt while scroll in payment history
   */
  const getReceiptWiseAllData = async () => {
    setscrollLoader(true);
    let selectAllReceipts: Rootobject = {} as Rootobject;
    await Promise.all([recpaymentHistory(receiptOffSet + 10, customerId)]).then(
      (resp) => {
        [selectAllReceipts] = resp;
      }
    );
    let paymentHisGridLocal: PaymentHistoryInterface[][] = [...paymentHisGrid];
    if (selectAllReceipts.value.customerPaymentHistory.length) {
      paymentHisGridLocal[0].push(
        ...selectAllReceipts.value.customerPaymentHistory
      );
      setReceiptOffSet(receiptOffSet + 10);
    } else {
      sethasMoreReceipt(false);
    }
    console.log(
      "paymentHisGridLocal in lop",
      paymentHisGridLocal,
      paymentHisGrid
    );
    setpaymentHisGrid(paymentHisGridLocal);
  };

  const getAgreementWiseAllData = async (indexObtained: string) => {
    setscrollLoader(true);
    const filterByAgreementNumber: constructAgreementPayloadDeciderInterface[] = agreementPayloadDecider.filter(
      (el: constructAgreementPayloadDeciderInterface) =>
        el.agreementNumber == paymentHisGrid[indexObtained][0].agreementNumber
    );
    console.log("filterByAgreementNumber psxkjcds", filterByAgreementNumber);

    if (filterByAgreementNumber[0].isFurtherDataThere) {
      let getpayhisstate1: Rootobject = {} as Rootobject;
      await Promise.all([
        agrPaymentHistory(filterByAgreementNumber[0].offset + 10, customerId),
      ]).then((resp) => {
        [getpayhisstate1] = resp;
      }); // Need to call with offset
      let collectedUniqueAgrData: PaymentHistoryGridInfo[] = [];
      collectedUniqueAgrData =
        getpayhisstate1.value.paymentHistoryGridInfo.filter(
          (o2) =>
            o2.agreementNumber ===
            paymentHisGrid[indexObtained][0].agreementNumber
        );
      console.log("collectedUniqueAgrData cdv", collectedUniqueAgrData);

      if (collectedUniqueAgrData.length) {
        let paymentHisGridLocal: PaymentHistoryInterface[][] = [...paymentHisGrid];
        paymentHisGridLocal[indexObtained].push(...collectedUniqueAgrData);
        setpaymentHisGrid(paymentHisGridLocal);
        const localAgrPayloadDecider: constructAgreementPayloadDeciderInterface[] = [...agreementPayloadDecider];
        localAgrPayloadDecider.map((el: constructAgreementPayloadDeciderInterface) => {
          if (
            el.agreementNumber ==
            paymentHisGrid[indexObtained][0].agreementNumber
          ) {
            el.offset = el.offset + 10;
          }
        });
        setAgreementPayloadDecider(localAgrPayloadDecider);
      } else {
        const localAgrPayloadDecider: constructAgreementPayloadDeciderInterface[] = [...agreementPayloadDecider];
        localAgrPayloadDecider.map((el: constructAgreementPayloadDeciderInterface) => {
          if (
            el.agreementNumber ==
            paymentHisGrid[indexObtained][0].agreementNumber
          ) {
            el.isFurtherDataThere = false;
          }
        });
        setAgreementPayloadDecider(localAgrPayloadDecider);
      }
    } else {
      setscrollLoader(false);
    }
  };

  const isEditScheduleEnable = () => {
    return selectedAgreements.length >= CONSTANTS.ONE_NUMBER && (selectedAgreements.every((x) => x.freeTime == undefined && x.otherIncomeType==CONSTANTS.EMPTY_STRING &&x.agreementType != CONSTANTS.PROMO && !CONSTANTS.NOT_ALLOWED_AGREEMENT_TYPE_FOR_ES.includes(x.agreementType) && x.agreementSchedule == selectedAgreements[0].agreementSchedule && x.selected && x.numberOfPaymentsMade != 0 && !x.isZeroPayment && !x.isReturnAgreement && !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(x?.nextDueDate))) && !multiStoreSelected ? false : true
  }

  const isCaryyWaiveLateFeeEnable = () => {
    return (selectedAgreements?.length == CONSTANTS.ONE_NUMBER && selectedAgreements[0]?.freeTime == undefined &&
       !selectedAgreements[0]?.isZeroPayment ) &&
      !CONSTANTS.NOT_ALLOWED_AGREEMENT_TYPE_FOR_NOTE.includes(selectedAgreements[0]?.agreementType) &&
      !multiStoreSelected &&
      (Number(selectedAgreements[0]?.lateFee) > CONSTANTS.ZERO_NUMBER ||
        Number(selectedAgreements[0]?.carriedLateFee) >
        CONSTANTS.ZERO_NUMBER ||
        Number(selectedAgreements[0]?.waivedLateFee) >
        CONSTANTS.ZERO_NUMBER) ? false : true
  }

  return (
    <Grid>
      {/**PS_CO_NO_OS_22
       * Based on the condition the optional service list items are rendered.
       */}
      <OutsideClickHandler onOutsideClick={() => setPopupClassFn()}>
        <List
          style={{ marginBottom: "5px", borderRadius: "5px" }}
          className={PopupClass}
          data-testid="UlButtonsId"
          aria-labelledby="btnGroupDrop1"
          onBlur={() => setPopupClassFn()}
        >
          {/**PS_CO_NO_OS_23
           * The based on the below condition the TerminateLDW list Item is shown
           */}
          {ldwStatusFlag.isLdwCoverageAllowed == CONSTANTS.STATUS_YES &&
            ldwStatusFlag.coverageStatus == CONSTANTS.STATUS_YES &&
            selectedAgreements.length == CONSTANTS.ONE_NUMBER &&
            !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
              selectedAgreements[0]?.nextDueDate
            ) &&
            !CONSTANTS.NOT_ALLOWED_AGREEMENT_TYPE.includes(selectedAgreements[0]?.agreementType) &&
            !multiStoreSelected &&
            Number(selectedAgreements[0]?.numberOfPaymentsMade) > CONSTANTS.ZERO_NUMBER &&
            // selectedAgreements[0]?.isNoteAgreement != CONSTANTS.STATUS_YES &&
            selectedAgreements[0]?.otherIncomeType == CONSTANTS.EMPTY_STRING ? (
            <ListItem>
              <a
                className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                data-bs-toggle="modal"
                data-testid="TerminateLDWId"
                onClick={() => optionalServiceOnclick("terminateLDW")}
                data-bs-target="#terminateldw"
              >
                Terminate LDW
              </a>
            </ListItem>
          ) : null}
          <ListItem disabled={payhisflag}>
            <a
              className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
              data-bs-toggle="modal"
              data-testid="paymenthistory"
              data-bs-target="#terminateldw"
              onClick={() => { payhisflag ? null : setPaymentHistory(true) }}
            >
              Payment History
            </a>
          </ListItem>
          {/**PS_CO_NO_OS_23
           * The based on the below condition the ActivateLDW list Item is shown
           */}
          {ldwStatusFlag.isLdwCoverageAllowed == CONSTANTS.STATUS_YES &&
            ldwStatusFlag.coverageStatus == CONSTANTS.STATUS_NO &&
            selectedAgreements.length == CONSTANTS.ONE_NUMBER &&
            !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
              selectedAgreements[0]?.nextDueDate
            ) &&
            !CONSTANTS.NOT_ALLOWED_AGREEMENT_TYPE.includes(selectedAgreements[0]?.agreementType) &&
            !multiStoreSelected &&
            Number(selectedAgreements[0]?.numberOfPaymentsMade) > CONSTANTS.ZERO_NUMBER &&
            // selectedAgreements[0]?.isNoteAgreement != CONSTANTS.STATUS_YES &&
            selectedAgreements[0]?.otherIncomeType == CONSTANTS.EMPTY_STRING ? (
            <ListItem>
              <a
                className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                style={{ background: "white", color: "" }}
                data-testid="ActiveLDWId"
                data-bs-toggle="modal"
                onClick={() => optionalServiceOnclick("activateLDW")}
                data-bs-target="#terminateldw"
              >
                Activate LDW
              </a>
            </ListItem>
          ) : null}

          {Number(featureFlags?.EditSchedule) == CONSTANTS.ONE_NUMBER ? (<ListItem
            disabled={isEditScheduleEnable()}>
            <a
              className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
              data-bs-toggle="modal"
              data-testid="TerminateLDWId"
              onClick={() => !isEditScheduleEnable() ? editScheduleOnClick() : null}
              data-bs-target="#terminateldw"
              aria-disabled={true}
            >
              Edit Schedule
            </a>
          </ListItem>) : null}
          {/**PS_CO_NO_OS_24
           * The based on the below condition the ActivatePolicy list Item is shown
           */}
          {ldwStatusFlag.isPolicyCoverageAllowed == CONSTANTS.STATUS_YES &&
            ldwStatusFlag.coverageStatus == CONSTANTS.STATUS_NO &&
            selectedAgreements.length == CONSTANTS.ONE_NUMBER &&
            !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
              selectedAgreements[0]?.nextDueDate
            ) &&
            !CONSTANTS.NOTE_AGR_NEXT_DUE_DATE_ARRAY.includes(selectedAgreements[0]?.nextDueDate) &&
            !CONSTANTS.NOT_ALLOWED_AGREEMENT_TYPE.includes(selectedAgreements[0]?.agreementType) &&
            !multiStoreSelected &&
            Number(selectedAgreements[0]?.numberOfPaymentsMade) > CONSTANTS.ZERO_NUMBER &&
            // selectedAgreements[0]?.isNoteAgreement != CONSTANTS.STATUS_YES &&
            selectedAgreements[0]?.otherIncomeType == CONSTANTS.EMPTY_STRING ? (
            <ListItem>
              <a
                className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                data-bs-toggle="modal"
                data-testid="ActivatePolicyId"
                onClick={() => optionalServiceOnclick("activatePolicy")}
                data-bs-target="#terminateldw"
              >
                Activate Policy
              </a>
            </ListItem>
          ) : null}
          {/**PS_CO_NO_OS_24
           * The based on the below condition the DeActivatePolicy list Item is shown
           */}
          {ldwStatusFlag.isPolicyCoverageAllowed == CONSTANTS.STATUS_YES &&
            ldwStatusFlag.coverageStatus == CONSTANTS.STATUS_YES &&
            selectedAgreements.length == CONSTANTS.ONE_NUMBER &&
            !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
              selectedAgreements[0]?.nextDueDate
            ) &&
            !CONSTANTS.NOTE_AGR_NEXT_DUE_DATE_ARRAY.includes(selectedAgreements[0]?.nextDueDate) &&
            !CONSTANTS.NOT_ALLOWED_AGREEMENT_TYPE.includes(selectedAgreements[0]?.agreementType) &&
            !multiStoreSelected &&
            Number(selectedAgreements[0]?.numberOfPaymentsMade) > CONSTANTS.ZERO_NUMBER &&
            // selectedAgreements[0]?.isNoteAgreement != CONSTANTS.STATUS_YES &&
            selectedAgreements[0]?.otherIncomeType == CONSTANTS.EMPTY_STRING ? (
            <ListItem>
              <a
                className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                data-testid="DeactivatePolicybtnId"
                style={{ background: "white" }}
                data-bs-toggle="modal"
                onClick={() => optionalServiceOnclick("deactivatePolicy")}
                data-bs-target="#terminateldw"
              >
                Deactivate Policy
              </a>
            </ListItem>
          ) : null}

          {Number(featureFlags?.CarryWaveLateFee) == CONSTANTS.ONE_NUMBER ? (
            <ListItem
              disabled={
                isCaryyWaiveLateFeeEnable()
              }

            >
              <a
                className={`${navlinkClass.dropdownitemcustom} ${classes.semibold}`}
                data-bs-toggle="modal"
                data-testid="TerminateLDWId"
                data-bs-target="#terminateldw"
                onClick={() => {
                  !isCaryyWaiveLateFeeEnable() ? setCarryWaiveAuthenticationFlag(true) : null;
                }}
              >
                Carry/Waive Late Fee
              </a>
            </ListItem>
          ) : null}
        </List>
      </OutsideClickHandler>
      <RACButton
        className={`${classes.ms2}`}
        style={{ height: "42.5px" }}
        color="primary"
        variant="contained"
        loading={loader}
        onClick={() => popupButtonclick()}
      >
        Other Actions
        <span className={navlinkClass.dropdowntoggle}></span>
      </RACButton>
      {/**PS_CO_NO_OS_28
       * The OptionalService component is invoked since the activate or deactivate
       * optional service is provided.
       */}
      {optionalServiceFlag.activateLDW ||
        optionalServiceFlag.terminateLDW ||
        optionalServiceFlag.activatePolicy ||
        optionalServiceFlag.deactivatePolicy ? (
        <OptionalService
          optionalServiceFlag={optionalServiceFlag}
          setOptionalServiceFlag={(res) =>
            setOptionalServiceFlag({
              ...optionalServiceFlag,
              activateLDW: res,
              activatePolicy: res,
              terminateLDW: res,
              deactivatePolicy: res,
            })
          }
          selectedAgreements={selectedAgreements}
          setLdwStatusFlag={(res) => setLdwStatusFlag(res)}
          ldwStatusFlag={ldwStatusFlag}
          index={index}
        />
      ) : null}

      {carryWaiveLateFeeFlag ? (
        <CarryWaiveLateFeePopUp
          isOpen={carryWaiveLateFeeFlag}
          close={(res) => {
            setCarryWaiveLateFeeFlag(res);
          }}
          index={index}
          customerId={customerId}
          selectedAgreements={selectedAgreements}
        />
      ) : null}

      {carrayWaiveAuthenticationFlag ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setCarryWaiveAuthenticationFlag(false);
          }}
          setTwoFactorCompleted={() => {
            setCarryWaiveLateFeeFlag(true);
            setCarryWaiveAuthenticationFlag(false);
          }}
          moduleName={CONSTANTS.SLA_MODULE_NAME_CARRYWAIVE}
          CurrentRole={coWorkerDetails.role}
        />
      ) : null}

      {PaymentHistory ? (
        <PaymentHistoryComponent
          name="test"
          agreementPayloadDecider={agreementPayloadDecider}
          hasMoreReceipt={hasMoreReceipt}
          scrollLoader={scrollLoader}
          getReceiptWiseAllData={() => getReceiptWiseAllData()}
          getAgreementWiseAllData={(el) => getAgreementWiseAllData(el)}
          ClubGridInfoResp={ClubGridInfoResp}
          clubActivityInfo={clubActivityInfo}
          clubInfo={clubinformation}
          disable={payclubflag}
          customerId={customerId}
          emailId={customerInfo.customerDetails[0].emailAddress}
          paymenthistorygrid={paymentHisGrid}
          agreementRentPaid={agreementRentPaid}
          uniqueAgreementID={uniqueAgreementID}
          commoninformation={commoninformation}
          openModal={PaymentHistory}
          closePaymentHistoryPopup={(onClose: boolean) => {
            setPaymentHistory(onClose);
          }}
          response={(res: boolean) => {
            setPaymentHistory(res);
            setClubPaymentHistory(!res);
          }}
          redirect={(response: {}) => {
            history.push(response);
          }}
        />
      ) : null}
      {ClubPaymentHistory ? (
        <ClubPayment
          getClubWiseAllData={() => getClubWiseAllData()}
          customerData={customerInfo}
          hasMoreClubRcp={hasMoreClubRcp}
          scrollLoader={scrollLoader}
          activateClubFeatureFlag={featureFlags.ActivateClub}
          openModal={ClubPaymentHistory}
          paygridvalues={agreementDetails}
          ClubGridInfoResp={ClubGridInfoResp}
          clubActivityInfo={clubActivityInfo}
          clubinfo={clubinformation}
          paymenthistorygrid={paymentHisGrid}
          uniqueAgreementID={uniqueAgreementID}
          commoninformation={commoninformation}
          closeClubPaymentHistoryPopup={(onClose: boolean) => {
            setClubPaymentHistory(onClose);
          }}
          response={(res: boolean) => {
            setClubPaymentHistory(res);
            setPaymentHistory(!res);
          }}
          redirect={(response: {}) => {
            history.push(response);
          }}
        />
      ) : null}
      {editSchedulePopupFlag ? (<EditScheduleComponent
        editSchedulePoppup={editSchedulePopupFlag}
        isClosed={(res) => { setEditSchedulePopupFlag(res) }}
      />) : null
      }
    </Grid>
  );
}

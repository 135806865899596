/* eslint-disable */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable*/

/* eslint-disable no-console */

import {
  Grid,
  makeStyles,
  Typography,
  RACTextField,
  RACButton,
  RACSelect,
  RACTextbox,
  RACModalCard,
  RACRadio,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import validateAddressFn from '../../common/validateAddressFn';
import { ReactComponent as WarningIcon } from "../../assets/images/no-records-found.svg";




// import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
// import { useStoreDetails } from '../../../context/Store/StoreProvider';
// import { AddressType } from '../../../types/types';

// import {
//   AddressNotValidatedModal,
//   AddressValidationModal,
// } from '../../Customer/CustomerInformation/AddressValidationModal';



import { format } from 'date-fns';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {  addressValidator, GetCustomerByIdInfo, GetCustomerInfoOptions, GetState, UpdateAddressInfo } from '../../api/user';
import * as api from '../../api/user';
import { ApiErrorModal } from './customer validation/ApiError';
import { AddressNotValidatedModal, AddressValidationModal } from './customer validation/AddressValidationModal';
// import { data, Rootcustomer } from '../../domain/customerdetails';
import { useParams } from 'react-router-dom';
import { CustomerdataContext } from '../../context/customer/customerdata';
import { StateDataContext } from '../../context/stateapi/stateapicontext';

// import {
//   getSelectedStore,
//   randomNumericStringGenerator,
// } from '../../../utils/utils';


const getSelectedStore = () =>
  sessionStorage.getItem('storeNumber') || '';

//  const randomNumericStringGenerator = (numberOfDigits = 8) => {
//     if (typeof numberOfDigits !== 'number') {
//       throw new TypeError(
//         `Expected number instead got: ${typeof numberOfDigits}`
//       );
//     }

//     return crypto
//       .randomBytes(4)
//       .readUInt32BE(0)
//       .toString()
//       .substr(0, numberOfDigits);
//   };


export const API_ERROR_MESSAGE = 'Unable to fetch the data';

// import { API_ERROR_MESSAGE } from '../../../constants/constants';
// import { ApiErrorModal } from '../../../common/ApiError/ApiError';

export const customerAddressTestId = 'customerAddressTestId';
export const customerAddressLine1TestId = 'customerAddressLine1TestId';
export const customerAddressLine2TestId = 'customerAddressLine2TestId';
export const customerPostalCodeTestId = 'customerPostalCodeTestId';
export const customerCityTestId = 'customerCityTestId';
export const customerStateTestId = 'customerStateTestId';
export const customerAddressFormId = 'customerAddressFormId';


enum ErrorMessage {
  INVALID_FORMAT_ERROR_MESSAGE = 'Postal code should be either in *****-**** or ***** format!',
  MAX_LENGTH_ERROR_MESSAGE = 'Max length exceeded!',
  ALL_0_ERROR_MESSAGE = `Postal code value can't be all 0's!`,
}

const useClasses = makeStyles((theme: any) => ({
  customerAddress: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(12)}`,
  },
  addressLineWrapper: { display: 'flex', flexDirection: 'row' },
  saveButton: {
    height: theme.typography.pxToRem(43),
    marginTop: theme.typography.pxToRem(16),
  },
  row: { marginBottom: theme.typography.pxToRem(24) },
  selectOptionsPaper: {
    maxHeight: theme.typography.pxToRem(200),
  },

  spacerPX2: {

    paddingLeft: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(8),

  },
  RACPOPMsg: {
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  bold: {
    fontFamily: 'OpenSans-bold',
  },
  semiBold: {
    fontFamily: 'OpenSans-semibold',
  },
  dispInlineFlex: {
    display: 'inline-flex',
  }, 
  spacerMB2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  spacerMT2: {
    marginTop: theme.typography.pxToRem(16),
  },
  formCheck: {
    minHeight: '1.5rem',
    marginBottom: '0.125rem',
    display: 'inline-block',
    marginRight: '1rem',
    paddingLeft: 0,
  },
  spacerMT4: {
    marginTop: theme.typography.pxToRem(24),
  },
  w100: {
    width: '100%',
  },
  floatLeft: {
    float: 'left',
  },
  foc: {
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '1px 1px 6px 3px #80bdff',
    },
  },
  floatRight: {
    float: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },


  
}));

enum FormFields {
  city = 'city',
  state = 'state',
  postalCode = 'postalCode',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  addressId = 'addressId',
}

// export const CustomerAddress = () => {


export default function CustomerAddress(props:any) {
  const {configDetails} = props;
  const {customerdata, setcustomerdata}=useContext(CustomerdataContext);
  const {statedata,setstatedata}=useContext(StateDataContext);
  const [canCallAddressValidation,setCanCallAddressValidation] = useState(false);
  debugger;
  const [canSuggestAddress,setCanSuggestAddress] = useState(false);
  const [cantSuggestAddress,setCantSuggestAddress] = useState(false);
console.log(configDetails, "The configDetails in customeraddress");
const [validatedAddresses,setValidatedAddress] = useState<any>({});
const [defaultSuggestedAddress, setDefaultSuggestedAddress] = useState<string>('');
useEffect(()=>{

if(configDetails && configDetails.length>0){
  configDetails.forEach((element: any) => {
    if(element.paramKeyName == 'EnableGoogleAddressValidation' && element.paramValue == '1'){
      setCanCallAddressValidation(true);
    }
  });
}
},[configDetails])



  const classes = useClasses();

  // const {
  //   customerDetails: { addresses, customerId },
  // } = useCustomerDetails();

  // const besttimemock = [
  //   { label: "Morning", value: "Morning" },
  //   { label: "Afternoon", value: "Afternoon" },
  //   { label: "Evening", value: "Evening" },
  // ];




  const [
    openCustomerAddressValidationModal,
    setOpenCustomerAddressValidationModal,
  ] = useState<boolean>(false);

  const [
    openCustomerAddressNotValidatedModal,
    setOpenCustomerAddressNotValidatedModal,
  ] = useState<boolean>(false);

  const [suggestedAddress, setSuggestedAddress] = useState<any>({});
  const [enteredAddress, setEnteredAddress] = useState<any>({});

  const [hasApiErrorUpdateCustomer, setHasApiErrorUpdateCustomer] =
    useState<boolean>(false);

  // const primaryAddress = addresses?.filter(
  //   (address) => address.addressType === AddressType.PRIMARY
  // );


  // eslint-disable-next-line react-hooks/exhaustive-deps

  // const customerAddress =
  // primaddress && primaddress.length > 0
  //     ? primaddress[0]
  //     : {
  //         city: '',
  //         state: '',
  //         postalCode: '',
  //         addressLine1: '',
  //         addressLine2: '',
  //         addressId: '',
  //       };

  const [customerAddressLine1, setCustomerAddressLine1] = useState('');
  const [customerAddressLine2, setCustomerAddressLine2] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [customerPostalCode, setCustomerPostalCode] = useState('');
  const [customerCity, setCustomerCity] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('');
  const [primaryPhonedes, setPrimaryPhonedes] = useState('');
  const [PrimaryPhoneid, setPrimaryPhoneid] = useState('');
  interface Phone {
    phoneId: string;
    phoneNumber: string;
    phoneType: string;
    phoneTypeDesc: string;
    extension: any;
    primary: string;
    callTimeType: string;
    callTimeTypeDesc: string;
    active: string;
    note: any;
  }



  const [PrimaryPhoneobj, setPrimaryPhoneobj] = useState<Phone>();


  const [instructions, setInstructions] = useState('');
  const [state, setstate] = useState('');

  const [addressDisable, setaddressDisable] = useState(false);
  const [cosntactDisable, setcosntactDisable] = useState(false);
  const [primadreess, setprimadreess] = useState([]);
  const [primadreessid, setprimadreessid] = useState([]);



  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');
  const [hasPostalCodeError, setHasPostalCodeError] = useState(false);
  const [hasStatesApiError, setHasStatesApiError] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const [pending, setPending] = useState<boolean>(false);
  const [pendingContact, setPendingContact] = useState<boolean>(false);
  const [matchCode, setmatchCode] = useState<any>('');

  interface ParamType {
    customerId: string;
    agreementId: string;
  }



  const { customerId } = useParams<ParamType>();
  const { agreementId } = useParams<ParamType>();
  const [customerparamid]: any = useState(customerId);
  const [agreementparamid]: any = useState(agreementId);





  // useEffect(() => {
  //   const { addressLine1, addressLine2, city, state, postalCode } =
  //     customerAddress;

  //   setCustomerAddressLine1(addressLine1);
  //   setCustomerAddressLine2(addressLine2);
  //   setCustomerCity(city);
  //   setStateProvince(state);
  //   setCustomerPostalCode(postalCode);
  // }, [customerAddress]);

  // const { store } = useStoreDetails();



  //console.log(primadreess[0], "primary address")
  const storeNumber = getSelectedStore();

  const [usStatesList, setUSStatesList] = useState([]);
  const [besttimemock, setbesttimemock] = useState([]);

  const [addressText, setAddressText] = useState('');

  const [agreementIdArr,setAgreementIdArr]=useState([]);



  // GetCustomerByIdInfo
  useEffect(()=>{
    if(props.agreementAddressDetails!=undefined)
    { const agreementIds:any=[]
      for(let i=0;i<props.agreementAddressDetails.length;i++)
      {
        if(props.agreementAddressDetails[i].agreementId!=null)
        {
          agreementIds.push(props.agreementAddressDetails[i].agreementId);
        }

      }
      setAgreementIdArr(agreementIds)
      
    }
    console.log("agreementIdArr",agreementIdArr)
  },[props.agreementAddressDetails])

  useEffect(()=>{
    if(props.agreementAddressDetails!=undefined)
    { const agreementIds:any=[]
      for(let i=0;i<props.agreementAddressDetails?.length;i++)
      {
        if(props.agreementAddressDetails[i]?.agreementId!=null)
        {
          agreementIds.push(props.agreementAddressDetails[i]?.agreementId);
        }

      }
      setAgreementIdArr(agreementIds)
      
    }
    console.log("agreementIdArr",agreementIdArr)
  },[props.agreementAddressDetails])

  useEffect(() => {


    customer()
    // stateApi()
    callTime()
    setFetchingData(true);


    // api
    //   .getCustomerStatesList()
    //   .then((response) => {
    //     const statesList = response.map((state: any) => {
    //       return { label: state.abbreviation, value: state.abbreviation };
    //     });
    //     const sortedStatesList: any = orderBy(statesList, ['label'], ['asc']);

    //     setUSStatesList(sortedStatesList);
    //     setHasStatesApiError(false);
    //   })
    //   .catch(() => {
    //     setHasStatesApiError(true);
    //   })
    //   .finally(() => {
    //     setFetchingData(false);
    //   });




  }, []);



  const callTime = async () => {
    
    setFetchingData(true)
    const response: any = await GetCustomerInfoOptions(storeNumber);
    console.log(response, "call response")

    const statesList = response.data.callTimeList.map((state: any) => {
      return { label: state.description, value: state.description };
    });


    // console.log(statesList)
    // const sortedStatesList: any = orderBy(statesList, ['label'], ['asc']);

    // setUSStatesList(sortedStatesList);

    setbesttimemock(statesList)
    setFetchingData(false)
  };


  

  const stateApi =() => {
    
    // if(statedata == undefined){
    //   setFetchingData(true)
    // }else{
    //   setFetchingData(false)
    // } 
      setFetchingData(true)


    
    // const response: any = await GetState();
    // console.log(response, "state response")

    // const statesList = response.data.map((state: any) => {
    //   return { label: state.stateAbbreviation, value: state.stateAbbreviation };
    // });

    // console.log(statesList)
    // const sortedStatesList: any = orderBy(statesList, ['label'], ['asc']);

    // console.log(sortedStatesList)
    // setUSStatesList(sortedStatesList);
    setFetchingData(false)

  };


  const customer = async () => {

    console.log(customerId, "customer id")
    
    const customer_id: any = customerId
    const Storenumber: any = storeNumber
    let result: any;
    if(!customerdata){
      result = await GetCustomerByIdInfo(customer_id, Storenumber);
    }
    else {
      result = customerdata;
    }
    console.log(result, "customer data result")

    setcustomerdata(result)

    // console.log(result.data.GetCustomer.value.customerId)
    // // console.log(result.Rootcustomer.GetCustomer.value.addresses.filter())
    // console.log(result.data.GetCustomer.value.globalCustomerId)
    // console.log(result.data.GetCustomer.value.globalCustomerId)
    // console.log(result.data.GetCustomer.value.phones.phoneNumber)
    // console.log(result.data.GetCustomer.value.phones.callTimeTypeDesc)

    console.log(result?.data?.GetCustomer?.value?.phones?.callTimeTypeDesc)

    const primaryphone = result?.data?.GetCustomer?.value?.phones?.filter(
      (x) => x.primary === "Y"
    );



    const primaddress = result?.data?.GetCustomer?.value?.addresses?.filter(
      (x) => x.addressType === "PRIM"
    );


    const customerAddress =
      primaddress && primaddress.length > 0
        ? primaddress[0]
        : {
          city: '',
          state: '',
          postalCode: '',
          addressLine1: '',
          addressLine2: '',
          addressId: '',
        };




 






    if(primaryphone?.length>0){
      setPrimaryPhone(primaryphone[0]?.phoneNumber)
      setPrimaryPhonedes(primaryphone[0]?.callTimeTypeDesc)
      setPrimaryPhoneid(primaryphone[0]?.phoneId)
      setPrimaryPhoneobj(primaryphone[0])
      setInstructions(primaryphone[0]?.note)
    }
    if(primaddress?.length > 0){

      setprimadreess(primaddress[0])
      setprimadreessid(primaddress[0].addressId)
      setprimadreessid(primaddress[0].addressId)
    setstate(primaddress[0]?.state)

    setCustomerAddressLine1(primaddress[0]?.addressLine1)
    setCustomerAddressLine2(primaddress[0]?.addressLine2)
    setStateProvince(primaddress[0]?.state)
    setCustomerPostalCode(primaddress[0]?.postalCode)
    setCustomerCity(primaddress[0]?.city)
    }





    console.log(primadreess)


  }

  const submitContact = () => {

    setPendingContact(true)
    setcosntactDisable(true)
    


    const getBesttype = () => {
      if (primaryPhonedes === 'Call in Evening') {
        return 'EVEN';
      } else if (primaryPhonedes === 'Call in Morning') {
        return 'MORN';
      }
      return 'AFTN';
    };


    let number = String(primaryPhone);
    number = number.replace(/[^\d]/g, "");
    const enteredContact = {
      phoneId: PrimaryPhoneobj?.phoneId||"",
      phoneNumber: number,
      phoneType: PrimaryPhoneobj?.phoneType||"CELL",
      phoneTypeDesc: PrimaryPhoneobj?.phoneTypeDesc||"Cell Phone",
      extension: PrimaryPhoneobj?.extension||null,
      primary: PrimaryPhoneobj?.primary||"Y",
      callTimeType: getBesttype(),
      callTimeTypeDesc: primaryPhonedes,
      active: PrimaryPhoneobj?.active||"Y",
      note: instructions
    };

    const payload = {
      customerId: customerId,
      phones: [
        enteredContact
      ],
    }
    api
      .UpdateNoDeReason(payload)
      .then(() => {
        setPrimaryPhone(primaryPhone);
        setPrimaryPhonedes(primaryPhonedes);
        setInstructions(instructions);
        window.location.reload()
      })
      .catch(() => {
        setHasApiErrorUpdateCustomer(true);
      })
      .finally(() => {
        setPendingContact(false)
        setcosntactDisable(false)
        setOpenCustomerAddressNotValidatedModal(false);
        setOpenCustomerAddressValidationModal(false);
      });


  }



  const handleSubmit = async() => {
    if (customerPostalCode.length == 5 || customerPostalCode.length == 10){
      setPending(true);
      setaddressDisable(true)
      // 
      console.log(primadreess)

      // const { addressId } = customerAddress;
      // const type :any = submit





      const currentTimeZoneOffset = format(new Date(), 'XXX');
      const currentTimeStamp = format(new Date(), 'yyyy-MM-dd-HH:mm:ss:SSSSSS');


      const countryAbbreviation: string = state


      // const getBesttype = () => {
      //   if (primaryPhonedes === 'Evening') {
      //     return 'EVEN';
      //   } else if (primaryPhonedes === 'Morning') {
      //     return 'MORN';
      //   }
      //   return 'AFTN';
      // };




      const getRegionID = () => {
        if (countryAbbreviation === 'US') {
          return '1';
        } else if (countryAbbreviation === 'MX') {
          return '2';
        }
        return '1';
      };

      const enteredAddress = {
        addressLine1: customerAddressLine1,
        addressLine2: customerAddressLine2,
        state: stateProvince,
        postalCode: customerPostalCode,
        city: customerCity,
      };

      setEnteredAddress(enteredAddress);
      
      const payload : any = {
        MessageID: `CMS006-${Math.floor(Math.random() * 100000000) + 1}`,
        MessageType: 'CMS006',
        MessageDTS: currentTimeStamp,
        MessageDTSTZOffset: currentTimeZoneOffset,
        RegionID: getRegionID(),
        ClientID: '8',
        ClientLocationNumber: storeNumber,
        ClientSource: '1',
        ClientOriginator: '1',
        LocationNumber: storeNumber,
        EncryptionMethod: '0',
        Addresses: [
          {
            AddressID: primadreessid,
            StreetAddress:
              customerAddressLine2?.trim().length > 0
                ? `${customerAddressLine1}, ${customerAddressLine2}`
                : customerAddressLine1,
            City: customerCity,
            StateProvince: stateProvince,
            PostalCode: customerPostalCode.replace('-', ''),
          },
        ],
        StoreConfig: {
          storeNumbers: [storeNumber],
          paramKeyNames: ['AddressDoctorEnabled'],
        },
      };




      try {
        if(canCallAddressValidation){
          const googleAddressValidationPayload : any = JSON.parse(JSON.stringify(payload))
          googleAddressValidationPayload.enableGoogleAddressValidation = true;
          const arr = [customerAddressLine1,customerCity,stateProvince,customerPostalCode ]
          if(customerAddressLine2)
          arr.unshift(customerAddressLine2)
          const validateAddressPayload ={
            postalCode: customerPostalCode,
            addressLines: arr
          }
          googleAddressValidationPayload.validateAddress = validateAddressPayload;
          interface AddressResponse {
            message?: string;
            status: boolean;
            matchCode?: string | undefined;
            suggestedAddress?: string ;
            postalAddress?: PostalAddress;
            
          }
          
          interface PostalAddress {
            addressLines: string[];
            administrativeArea: string;
            postalCode: string;
            locality: string;
          }
        
          let response: any = await Promise.all([addressValidator(payload),addressValidator(googleAddressValidationPayload)]);
          console.log(response,"The response from the getSuggested Address");
          debugger;
          if(response[0].status ==200 && response[1].status ==200 && response[0].data &&response[1].data.validateAddress){
            const result : AddressResponse = validateAddressFn(response[1].data.validateAddress, response[0].data.validateAddress,
              (response[0].data && response[0].data.storeConfig && response[0].data.storeConfig.storeProfileResponse.configDetails[0]
             .configDetails[0].paramValue == '1')? true :false, googleAddressValidationPayload)
            console.log(result);
            if(result.status){
              if(result.matchCode){
                setmatchCode(result?.matchCode);
                const errorMessage: string =
                result.matchCode == '1'
              ? `Address could not be corrected. Unlikely to match. Do you want to override?`
              : result.matchCode == '2'
                ? `Zip code found: ${payload.Addresses[0].PostalCode}. Do you Want to overrride?`
                : '';
          setAddressText(errorMessage);
          setOpenCustomerAddressNotValidatedModal(true);
          setSuggestedAddress({});
              }
              else{
              const address = result.postalAddress
              // if(address.addressLines.length >1 ){
              //   setCustomerAddressLine1(address.addressLines[1]);
              //   setCustomerAddressLine2(address.addressLines[0])
              // }else{
              //   setCustomerAddressLine1(address.addressLines[0]);
              // }
              // setCustomerCity(customerCity);
              // setStateProvince(address.administrativeArea);
              // setCustomerPostalCode(address.postalCode);
             setCanSuggestAddress(true);
            setValidatedAddress(result);
            setDefaultSuggestedAddress('googleAddress');
            setSuggestedAddress({...suggestedAddress,'StreetAddress':address?.addressLines.join(', '),
            'City':address?.locality, 'StateProvince':address?.administrativeArea,'PostalCode':address?.postalCode
          })
        }
          }
          else{
            setCantSuggestAddress(true)
            setValidatedAddress(result)
          }
          }
          else{
            throw new Error('Something went wrong')
          }
        }else{
        let response: any = await addressValidator(payload);
        // response = undefined
        const addresses = response?.data.validateAddress?.Addresses;
        console.log(addresses, "address")
        if (addresses.MatchCode === '1' || addresses.MatchCode === '2') {
          setmatchCode(addresses?.MatchCode);
          const errorMessage: string =
            addresses.MatchCode == '1'
              ? `Address could not be corrected. Unlikely to match. Do you want to override?`
              : addresses.MatchCode == '2'
                ? `Zip code found: ${addresses?.PostalCode}. Do you Want to overrride?`
                : '';
          setAddressText(errorMessage);
          setOpenCustomerAddressNotValidatedModal(true);
          setSuggestedAddress({});
        } else {
          setSuggestedAddress(addresses);
          setOpenCustomerAddressValidationModal(true);
        }

      } 
    }catch (e: any) {
        console.log(e, "The address erroee");
        setAddressText(
          'Address validation currently Unavailable'
        );
        setSuggestedAddress({});
        setOpenCustomerAddressNotValidatedModal(true);
        setaddressDisable(false)
        setCanSuggestAddress(false)
      }

      setPending(false);
      setaddressDisable(false)

    };
    }
  const handleSave = (
    isEnteredAddressSelected: string,
    userInputAddress?: Record<string, unknown>
  ) => {
    setPending(true);
    setaddressDisable(true)

    //     const enteredAddress = {
    //   addressLine1: customerAddressLine1,
    //   addressLine2: customerAddressLine2,
    //   state: stateProvince,
    //   postalCode: customerPostalCode,
    //   city: customerCity,
    // };

    // setEnteredAddress(enteredAddress);



    let { addressID, addressLine1, addressLine2, city, state, postalCode } =
      userInputAddress || enteredAddress || {};

    const { AddressID, StreetAddress, City, StateProvince, PostalCode } =
      suggestedAddress || {};
      const address = validatedAddresses.postalAddress 
      console.log(address, "address in the hanlesave ");
      if(isEnteredAddressSelected=="googleAddress"){
      if(address.addressLines.length > 1){
        setCustomerAddressLine1(address.addressLines[1]);
        setCustomerAddressLine2(address.addressLines[0]);
      }
      else{
      setCustomerAddressLine1(address.addressLines.join(', '));
    }
      setCustomerCity(customerCity);
      setStateProvince(address.administrativeArea);
      setCustomerPostalCode(address.postalCode);

}
    const isEnteredAddress = isEnteredAddressSelected.length > 0;
    if (!isEnteredAddress || isEnteredAddressSelected=="googleAddress") {
      addressID = AddressID;
      addressLine1 = StreetAddress;
      addressLine2 = '';
      city = City;
      state = StateProvince;
      postalCode = PostalCode;
    }
    setCanSuggestAddress(false)

    const payload = {
      customerId: customerId,
      addresses: [
        {
          addressType: 'PRIM',
          active:'Y',
          addressLine1,
          addressLine2,
          city,
          state,
          postalCode,
          agreementIds:agreementIdArr,
          addressId: primadreessid,
        },
      ],
    };


    api
      .UpdateAddressInfo(payload)
      // .UpdateNoDeReason(payload)
      .then(() => {
        setCustomerAddressLine1(addressLine1);
        setCustomerAddressLine2(addressLine2);
        setCustomerCity(city);
        setStateProvince(state);
        setCustomerPostalCode(postalCode);
        window.location.reload()
      })
      .catch(() => {
        setHasApiErrorUpdateCustomer(true);
      })
      .finally(() => {
        setPending(false);
        setaddressDisable(false)

        setOpenCustomerAddressNotValidatedModal(false);
        setOpenCustomerAddressValidationModal(false);
      });
  };


  const formatPhone = (input: any) => {

    const cleaned = ('' + input).replace(/\D/g, '');
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    return cleaned.replace(PhoneRegex, '($1) $2-$3');
  };




  const handleZipChange = (value: string) => {
    
    let cleaned = ('' + value).replace(/\D/g, '');
    cleaned =
      cleaned.length > 5
        ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
        : cleaned;
    console.log(cleaned, "clean record")

    setCustomerPostalCode(cleaned)

    // const regExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    // setCustomerPostalCode(value);
    // if (value?.length > 10) {
    //   setHasPostalCodeError(true);
    //   setPostalCodeErrorMessage(ErrorMessage.MAX_LENGTH_ERROR_MESSAGE);
    // } else if (value.includes('00000') || value.includes('-0000')) {
    //   setHasPostalCodeError(true);
    //   setPostalCodeErrorMessage(ErrorMessage.ALL_0_ERROR_MESSAGE);
    // } 
    // // else if (!regExp.test(value)) {
    // //   setHasPostalCodeError(true);
    // //   setPostalCodeErrorMessage(ErrorMessage.INVALID_FORMAT_ERROR_MESSAGE);
    // // } 
    // else {
    //   setHasPostalCodeError(false);
    // }


  };

  // const isDirty = () => {
  //   const { addressLine1, addressLine2, city, state, postalCode } =
  //     customerAddress;


  //   if (
  //     addressLine1 === customerAddressLine1 &&
  //     addressLine2 === customerAddressLine2 &&
  //     city === customerCity &&
  //     state === stateProvince &&
  //     postalCode === customerPostalCode
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  // const isFormValid = () => {
  //   if (
  //     customerAddressLine1.trim().length > 0 &&
  //     customerCity.trim().length > 0
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };
  function suggestAddressFn(){
    return (
        <>
          <Grid item md={12} className={classes.spacerPX2}>
          <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
            Entered Address
          </Typography>
          <div className={classes.formCheck}>
          <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'ENT' ? true : false}
                onClick={() => setDefaultSuggestedAddress('ENT')}
                value={''}
              />
          <Typography
              className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
            >
               {customerAddressLine2 ? 
                `${customerAddressLine1+', '+customerAddressLine2+', '+customerCity+', '+stateProvince+', '+customerPostalCode}`
                :
                  `${customerAddressLine1+', '+customerCity+', '+stateProvince+', '+customerPostalCode}`
              }
            </Typography>
            </div>
          <Grid
            item
            md={12}
            className={`${classes.spacerMB2} ${classes.spacerMT2}`}
          >
            <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
              Suggested Addresses
            </Typography>
            <div className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == 'googleAddress' ? true : false}
                onClick={() => setDefaultSuggestedAddress('googleAddress')}
                value={'GA'}
              />
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
              >
              {validatedAddresses.suggestedAddress}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
        >
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() => setCanSuggestAddress(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() =>{handleSave(defaultSuggestedAddress )}} 
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
        </>
    )
}
function suggestErrorInAddress(){
return (
  <>
      <Grid item md={12}>
      <Grid
            style={{
              justifyContent: "center",
              display: "flex",
              marginBottom:'2%'

            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
        <Grid item md={12} className={classes.textCenter}>
          <Typography variant={'body2'} className={classes.RACPOPMsg}>
            {validatedAddresses?.message}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => setCantSuggestAddress(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
  </>
)
}


  return (
    <div
      className={classes.customerAddress}
      data-testid={customerAddressTestId}
    >

      <Grid container>
        <Grid item sm={12} md={6} lg={6} classes={{ root: classes.row }}>
          <Typography variant="h4">Address</Typography>
          <Grid container spacing={2} classes={{ root: classes.row }}>
            <Grid item sm={12} md={12} lg={10}>
              <RACTextbox
                disabled={addressDisable}
                name={FormFields.addressLine1}
                inputlabel="Address Line 1"
                value={customerAddressLine1}
                maxlength={30}
                type="text"
                required
                OnChange={(value) => setCustomerAddressLine1(value.target.value)}
              />


              {/* <RACTextField
                disabled={addressDisable}
                name={FormFields.addressLine1}
                label="Address Line 1"
                value={customerAddressLine1}
                type="text"
                required
                onChange={(value) => setCustomerAddressLine1(value)}
              /> */}




            </Grid>
          </Grid>
          <Grid container spacing={2} classes={{ root: classes.row }}>

          <Grid item sm={12} md={12} lg={10}>
            <RACTextbox
              disabled={addressDisable}
              name={FormFields.addressLine1}
              inputlabel="Address Line 2"
              value={customerAddressLine2}
              maxlength={30}
              type="text"
              // required
              OnChange={(value) => setCustomerAddressLine2(value.target.value)}
            />
          </Grid>
          </Grid>

          <Grid item sm={12} md={12} lg={12}>

            <Grid container spacing={2} classes={{ root: classes.row }}>
              <Grid item sm={10} md={10} lg={10}>
                <Grid container spacing={2}>
                  <Grid item sm={4} md={4} lg={4}>
                    <RACTextbox
                      disabled={addressDisable}
                      name={FormFields.postalCode}
                      inputlabel="Zip"
                      maxlength={10}
                      value={customerPostalCode}
                      type="text"
                      required
                      OnChange={(value) => handleZipChange(value.target.value)}
                      errorMessage={
                        customerPostalCode != undefined &&
                          customerPostalCode != null &&
                          customerPostalCode != '' &&
                          customerPostalCode.length > 0 &&
                          customerPostalCode.length != 5 &&
                          customerPostalCode.length != 10
                          ? 'Invalid Zip'
                          : ''
                      }

                    />
                  </Grid>
                  <Grid item sm={4} md={4} lg={4}>
                    <RACTextbox
                      disabled={addressDisable}
                      name={FormFields.city}
                      inputlabel="City"
                      value={customerCity}
                      maxlength={25}
                      type="text"
                      required
                      OnChange={(city) => setCustomerCity(city.target.value)}
                    />
                  </Grid>

                  {/* <Grid item sm={4} md={4} lg={3}>
                    <RACTextField
                      name={FormFields.city}
                      label="City"
                      value={stateProvince}
                      type="text"
                      required
                    onChange={(city) => setStateProvince(city)}
                    />
                  </Grid> */}


                  <Grid item sm={4} md={4} lg={4}>
                    <RACSelect
                      classes={{ paper: classes.selectOptionsPaper }}
                      // name={state}
                      isDisabled={addressDisable}
                      inputLabel="State"
                      defaultValue={stateProvince}
                      // options={usStatesList}
                      options={statedata == undefined ? []:statedata}
                      required
                      onChange={(e) => {
                        setStateProvince(e.target.value);
                      }}
                      loading={fetchingData}
                      {...(hasStatesApiError && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                    />
                  </Grid>
                  {hasApiErrorUpdateCustomer && (
                    <ApiErrorModal
                      open
                      message="Failed to update address!"
                      onClose={() => setHasApiErrorUpdateCustomer(false)}
                    />
                  )}
                  {openCustomerAddressValidationModal && (
                    <AddressValidationModal
                      handleSave={(onsave) => handleSave(onsave)}
                      suggestedAddress={suggestedAddress}
                      enteredAddress={enteredAddress}
                      pending={pending}
                      onClose={() => setOpenCustomerAddressValidationModal(false)}
                    />
                  )}
                  {openCustomerAddressNotValidatedModal && (
                    <AddressNotValidatedModal
                      handleSave={(onsave) => handleSave(onsave)}
                      pending={pending}
                      text={addressText}
                      matchCode={matchCode}
                      onClose={() => {setOpenCustomerAddressNotValidatedModal(false);setmatchCode('')}}
                    />
                  )}







                </Grid>
              </Grid>



            </Grid>

            <Grid item sm={2} md={2} lg={3} container justifyContent="flex-end" style={{ float: "right" }}>
              <RACButton
              style={{ right: "70px" }}
                variant="contained"
                size="large"
                color="primary"
                loading={pending}
                className={classes.saveButton}
                onClick={(e) => handleSubmit()}
              >
                Save
              </RACButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6} lg={6} classes={{ root: classes.row }}>
          <Typography variant="h4">Contact</Typography>

          <Grid item sm={12} md={12} lg={12}>

            <Grid container spacing={2} classes={{ root: classes.row }}>
              <Grid item sm={10} md={10} lg={10}>
                <Grid container spacing={2}>
                  <Grid item sm={4} md={4} lg={6}>
                    <RACTextbox
                      // name={FormFields.postalCode}
                      disabled={cosntactDisable}
                      inputlabel="Primary phone"
                      value={formatPhone(primaryPhone)}
                      type="text"
                      maxlength={14}
                      required
                      OnChange={(e) => setPrimaryPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={4} md={4} lg={6}>
                    <RACSelect
                      classes={{ paper: classes.selectOptionsPaper }}
                      isDisabled={cosntactDisable}
                      inputLabel="Best Time to Call"
                      defaultValue={primaryPhonedes}
                      options={besttimemock}
                      required
                      onChange={(e) => {
                        setPrimaryPhonedes(e.target.value);
                      }}
                      loading={fetchingData}
                      {...(hasStatesApiError && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                    />
                  </Grid>

                </Grid>
              </Grid>



            </Grid>
            <Grid container spacing={2} classes={{ root: classes.row }}>

            <Grid item sm={12} md={12} lg={8} >

              <Typography variant="h6">Instructions</Typography>

              <TextareaAutosize
                disabled={cosntactDisable}
                style={{ padding: "10px 190px 90px 10px", borderRadius: "8px",resize: "none" }}
                name={FormFields.addressLine1}

                // label="Address Line 1"

                value={instructions}
                // type="text"
                // required
                // onChange={(value:any) => console.log(value,"instruction log")}
                onChange={(e) => setInstructions(e.target.value)}

              />
            </Grid>
            </Grid>


            <Grid item sm={2} md={2} lg={3} container justifyContent="flex-end" style={{ float: "right" }}>
              <RACButton
               style={{ right: "70px" }}
                variant="contained"
                size="large"
                color="primary"
                loading={pendingContact}
                className={classes.saveButton}
                onClick={() => submitContact()}
              >
                Save
              </RACButton>
            </Grid>




          </Grid>
        </Grid>

      </Grid>
      {
        canCallAddressValidation ? 
        <>
        <RACModalCard
         isOpen={canSuggestAddress}
         maxWidth="sm"
         closeIcon={true}
         borderRadius="20px"
         onClose={() => setCanSuggestAddress(false)}
         // eslint-disable-next-line react/no-children-prop
         children={suggestAddressFn()}
         title="Validation Message"
        />
        <RACModalCard
         isOpen={cantSuggestAddress}
         maxWidth="xs"
        //  closeIcon={true}
         borderRadius="20px"
        //  onClose={() => setCantSuggestAddress(false)}
         // eslint-disable-next-line react/no-children-prop
         children={suggestErrorInAddress()}
        />
        </>
        :
        null
      }

    </div>
  );
};




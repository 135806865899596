/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable use-isnan */
/* eslint-disable prettier/prettier */
/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-one-iteration-loop */
/* eslint-disable sonarjs/no-use-of-empty-return-value */
/* eslint-disable sonarjs/no-collection-size-mischeck */
// eslint-disable-next-line prettier/prettier
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import { RACModal, RACRadio, Grid } from '@rentacenter/racstrap';
import { RACButton, makeStyles } from '@rentacenter/racstrap';
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import clsx from 'clsx';
export interface ModalProps {
  onClose: (onclose: boolean) => void;
  suggestedAddress?: Record<string, unknown>;
  enteredAddress?: Record<string, unknown>;
  handleSave: (onsave: string) => void;
  pending: boolean;
  text?: string;
}
export interface AddressNotValidatedModalProps {
  onClose: (onclose: boolean) => void;
  pending: boolean;
  text?: string;
  matchCode?:any
  handleSave?:any
}
export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    marginBottom: '2rem',
  },
  dialogContent2: {
    textAlign: 'center',
    fontFamily: 'OpenSans-Bold',
  },
  spacerPX2: {

    paddingLeft: theme.typography.pxToRem(8),

    paddingRight: theme.typography.pxToRem(8),

  },
  customerTextcenter: {

    textAlign: "center",

  },
  customerRacpopup: {

    fontFamily: "OpenSans-bold",

    fontSize: theme.typography.pxToRem(16),

    letterSpacing: "0px",

  },

  customerJustifycontentcenter: {

    justifyContent: "center",

  },

  spacerMT4: {

    marginTop: theme.typography.pxToRem(24),

  },

  spacerMR2: {

    marginRight: theme.typography.pxToRem(8),

  },
  dialogRoot: {
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperWidthXs': {
      maxWidth: theme.typography.pxToRem(500),
    },
    '& .MuiTypography-h5': {
      fontFamily: 'OpenSans-semiBold',
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiTypography-h6': {
      fontFamily: 'OpenSans-semiBold',
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  suggestedAddressesHeadline: {
    fontFamily: 'OpenSans-Bold !important',
    margin: '10px 0 !important',
  },
  suggestedAddressesInput: {
    fontSize: `${theme.typography.pxToRem(14)} !important`,
  },
  buttonRoot: {
    margin: 'auto',
  },
  cancelButton: {
    margin: 'auto',
    marginLeft: theme.typography.pxToRem(16),
  },
}));
export const AddressNotValidatedModal = ({
  onClose,
  text,
  pending,
  matchCode,
  handleSave,
}: AddressNotValidatedModalProps) => {
  debugger
  const classes = useStyles();
  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent2,
        dialog: classes.dialogRoot,
      }}
      maxWidth="sm"
      title="Validation Message"
      content={
        <>
          <Grid item md={12} className={classes.spacerPX2}>
            <Grid item md={12} className={classes.customerTextcenter}>
              <Typography className={classes.customerRacpopup}>
                {text}
              </Typography>
            </Grid>
            <Grid
              container
              classes={{
                root: clsx(classes.customerJustifycontentcenter, classes.spacerMT4),
              }}
            >
              {matchCode == '1' || matchCode == '2' ? (
                <Grid item md={12} className={classes.customerTextcenter}>
                  <RACButton
                    className={`${classes.spacerMR2}`}
                    variant="outlined"
                    color="primary"
                    isRounded={false}
                    onClick={() => {
                      onClose(false);
                      // handleSave('ENT');
                    }}
                  >
                    No
                  </RACButton>
                  <RACButton
                    variant="contained"
                    color="primary"
                    isRounded={false}
                    onClick={() => {
                      onClose(false)
                      handleSave('ENT', 'MC1');
                    }}
                  >
                    Yes
                  </RACButton>
                </Grid>
              ) : (
                <Grid item md={12} className={classes.customerTextcenter}>
                  <RACButton
                    variant="contained"
                    color="primary"
                    isRounded={false}
                    onClick={() => {
                      onClose(false);
                      handleSave('ENT');
                    }}
                  >
                    Ok
                  </RACButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      }
      onClose={() => onClose(false)}
      buttons={
        <>
        </>
      }
    />
  );
};
export const AddressValidationModal = ({
  onClose,
  suggestedAddress,
  enteredAddress,
  handleSave,
  pending,
}: ModalProps) => {
  const { StreetAddress, City, StateProvince, PostalCode } =
    suggestedAddress || {};
  const suggestedAddressString = `${StreetAddress}, ${City}, ${StateProvince} - ${PostalCode}`;
  const { addressLine1, addressLine2, city, state, postalCode } =
    enteredAddress || {};
  const addressLine2value = addressLine2 ? `${addressLine2}, ` : '';
  const enteredAddressString = `${addressLine1}, ${addressLine2value}${city}, ${state} - ${postalCode}`;
  const classes = useStyles();
  const [selectedSuggestedAddress, setSelectedSuggestedAddress] = useState(
    suggestedAddressString
  );
  const [selectedEnteredAddress, setSelectedEnteredAddress] = useState('');
  const handleSuggestedAddressSelect = (value: string) => {
    setSelectedSuggestedAddress(value);
    setSelectedEnteredAddress('');
  };
  const handleEnteredAddressSelect = (value: string) => {
    setSelectedEnteredAddress(value);
    setSelectedSuggestedAddress('');
  };
  const isOverrideDisabled = pending || selectedSuggestedAddress.length > 0;
  const isContinueDisabled = pending || selectedEnteredAddress.length > 0;
  const isOverrideLoading = pending && selectedEnteredAddress.length > 0;
  const isContinueLoading = pending && selectedSuggestedAddress.length > 0;
  return (
    <RACModal
      isOpen
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="xs"
      title="Validation Message"
      content={
        <>
          <Typography display="inline" variant="body2">
            Entered address is not located
          </Typography>
          <div>
            <Typography className={clsx(classes.suggestedAddressesHeadline)}>
              Suggested Addresses
            </Typography>
            <RACRadio
              name="SuggestedAddress"
              label={suggestedAddressString}
              value={suggestedAddressString}
              checked={
                selectedSuggestedAddress.length > 0 &&
                suggestedAddressString === selectedSuggestedAddress
              }
              onChange={(event) =>
                handleSuggestedAddressSelect(event.target.value)
              }
              classes={{
                label: classes.suggestedAddressesInput,
              }}
            />
          </div>
          <div>
            <Typography className={clsx(classes.suggestedAddressesHeadline)}>
              Entered Address
            </Typography>
            <RACRadio
              name="EnteredAddress"
              label={enteredAddressString}
              value={enteredAddressString}
              checked={
                selectedEnteredAddress.length > 0 &&
                enteredAddressString === selectedEnteredAddress
              }
              onChange={(event) =>
                handleEnteredAddressSelect(event.target.value)
              }
              classes={{
                label: classes.suggestedAddressesInput,
              }}
            />
          </div>
        </>
      }
      onClose={() => onClose(false)}
      buttons={
        <>
          <RACButton
            variant="outlined"
            color="secondary"
            onClick={() => onClose(false)}
            className={classes.cancelButton}
            disabled={pending}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => handleSave('isEnteredAddress')}
            disabled={isOverrideDisabled}
            loading={isOverrideLoading}
          >
            Override
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => handleSave('')}
            disabled={isContinueDisabled}
            loading={isContinueLoading}
          >
            Continue
          </RACButton>
        </>
      }
    />
  );
};
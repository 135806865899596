/*eslint-disable */
import { makeStyles } from '@rentacenter/racstrap';

export const paymentTriangleStyle = () =>{
    const useStyles = makeStyles(() => ({
        customerInformationRoot: {
            width: 'calc(100% + 1.5rem)',
            // left: '-0.75rem',
            margin: '0 !important',
        },
        accordionSummaryRoot: {
            width: '10px',
            position: 'absolute',
            right: 30,
            top: '-45px',
        },
        accordionSummaryContent: {
            margin: '0 !important',
        },
    }));

    const classes = useStyles();
    return classes;

}
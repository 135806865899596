/* eslint-disable */
import { makeStyles } from '@rentacenter/racstrap';
export function acceptPaymentStyles(){
    const useClasses = makeStyles((theme) => ({
        racErrorIcon: {
          width: "50px",
        },
        racErrorFunction: {
          fontFamily: "OpenSans-semibold",
          marginBottom: "20px",
          marginTop: "10px",
        },
        racErrorSessionFunction: {
          fontFamily: "OpenSans-bold",
          fontSize: "16px",
          marginBottom: "8px",
          marginTop: "10px",
        },
        me2: {
          marginRight: theme.typography.pxToRem(8),
        },
        bold: {
          fontFamily: "OpenSans-bold",
        },
        textCenter: {
          textAlign: "center",
        },
        justifyContentCenter: {
          justifyContent: "center",
        },
        mt4: {
          marginTop: theme.typography.pxToRem(24),
        },
        py4: {
          paddingTop: theme.typography.pxToRem(24),
          paddingBottom: theme.typography.pxToRem(24),
        },
        textcenter: {
          textAlign: "center",
        },
        mx1: {
          marginRight: theme.typography.pxToRem(4),
          marginLeft: theme.typography.pxToRem(4),
        },
        mt3: {
          marginTop: theme.typography.pxToRem(16),
        },
        modalfooter: {
          borderTop: "0px solid transparent",
        },
        w100: {
          width: "100%",
        },
        ms1: {
          marginLeft: theme.typography.pxToRem(4),
        },
        ms2: {
          marginLeft: theme.typography.pxToRem(8),
        },
        colmd12: {
          flex: "0 0 auto",
          width: "100%",
        },
        mb2: {
          marginBottom: theme.typography.pxToRem(8),
        },
        popUpTxtStyle: {
          fontFamily: "OpenSans-regular",
          fontSize: "16px",
        },
        popUpTxtStyleSession: {
          fontFamily: "OpenSans-regular",
          fontSize: "14px",
        },
        me3: {
          marginRight: theme.typography.pxToRem(16),
        },
        dialogContent: {
          textAlign: "center",
          margin: `2rem 0`,
          height: "90%",
          overflow: "hidden",
        },
        dialogRoot: {
          height: "90% !important",
          "& .MuiPaper-rounded": {
            borderRadius: theme.typography.pxToRem(12),
          },
          "& .MuiDialog-paperScrollPaper": {
            height: "90% !important",
          },
          "& .MuiTypography-h5": {
            fontSize: theme.typography.pxToRem(20),
            fontWeight: 500,
            lineHeight: theme.typography.pxToRem(30),
          },
        },
        paymentmodal: {
          maxHeight: "95% !important",
          height: "95% !important",
        },
        textend: {
          textAlign: "end",
        },
        customModal: {
          "& div": {
            "& div": {
              maxWidth: "2000px !important",
            },
          },
        },

        mb32: {
          marginBottom: theme.typography.pxToRem(32),
        },

        receiptLoader: {
          width: "25px !important",
          height: "25px !important",
        },
        titletxtstyle: {
          fontSize: theme.typography.pxToRem(18),
        },
        racGrid: {
          "& th": {
            fontFamily: "OpenSans-semibold",
            whiteSpace: "nowrap",
            padding: "0.9375rem 0.2rem",
            color: "#000000",
            fontSize: "14px",
          },
          "& td": {
            padding: "0.375rem 0.2rem",
            fontFamily: "OpenSans-semibold",
            color: "#5A6495",
            fontSize: "14px",
          },
        },
        me1: {
          marginRight: theme.typography.pxToRem(4),
        },
        foc: {
          "&:focus": {
            borderColor: "#80bdff",
            boxShadow: "1px 1px 6px 3px #80bdff",
          },
        },
        textAreaN: {
          margin: "10px 0px",
          width: 500,
          padding: "6PX",
          borderRadius: "4px",
          resize: "none",
        },
        description: {
          margin: "10px 0px",
        },
        me2N: {
          marginRight: "0.5rem !important",
          marginLeft: "0.5rem",
        },
        floatLeft: {
          float: "left",
        },
        floatRight: {
          float: "right",
        },
      }));
      const classes=useClasses()
      return classes
}

/* eslint-disable prettier/prettier */
// import { CreditCardTypes } from './CreditCardTypes';
/* eslint-disable no-console */
const CreditCardTypes = [
  {
    id: '1',
    value: 'MasterCard',
    short: 'MC',
  },
  {
    id: '2',
    value: 'Visa',
    short: 'VI',
  },
  {
    id: '3',
    value: 'American Express',
    short: 'AX',
  },
  {
    id: '4',
    value: 'Discover',
    short: 'DI',
  },
  {
    id: '5',
    value: "Diner's Club",
    short: 'CD',
  },
  {
    id: '6',
    value: 'JCB',
    short: 'JC',
  },
  {
    id: '7',
    value: 'PayPal',
    short: 'PP',
  },
  {
    id: '8',
    value: 'PayPal Credit',
    short: 'BL',
  },
  {
    id: '9',
    value: 'eCheck',
    short: 'EC',
  },
  {
    id: '10',
    value: 'Gift Card',
    short: 'GC',
  },
  {
    id: '11',
    value: 'Unknown',
    short: '',
  },
];


export class CreditCard {
  readonly lastDigits: string | null;
  readonly cardExpiration: string | null;
  readonly cardToken: string | null;
  readonly type: string | null;

  constructor(response: any) {
    this.lastDigits = (response && response.lastFour) || null;
    this.cardToken = (response && response.paypageRegistrationId) || null;
    this.cardExpiration =
      (response && response.expMonth + response.expYear) || null;
      console.log("creditCardTypeIncome",CreditCardTypes)
    const creditCardType = CreditCardTypes?.find(
      (val) => val.short === response.type
    );
    console.log("creditCardType",creditCardType)

    this.type =
      (response && response.type && creditCardType && creditCardType.id) ||
      null;
  }
}

/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { userTraceContextData } from "../../context/UserTraceContext";
import {
  RACButton,
  RACModalCard,
  RACCheckBox,
  Grid,
  Typography,
  RACDatePicker,
  Card,
  RACSelect,
  // CircularProgress,
} from "@rentacenter/racstrap";
import { useLocation } from "react-router-dom";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import moment from "moment";
import SecondFactor from "./TwoFactorPopup";
import { calculatePaymentModule, GetEmployeeID, GetRole, getTax } from "../../api/user";
import AdjustDueDatecalci from "../../racstrapComponents/microfrontends/AdjustDueDateCalci";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
import calculationModulePayloadFormation from "./commonModulePayloadFormation";
import { cappedFreeTimeValidation } from "./customer validation/CappedFreeTimeValidation";
import { customerStyles } from "../../stylesJS/customerStyles";
import { ReactComponent as AlertIcon } from './../../assets/apiErrorIcon.svg';
export default function FreeTime(props: any) {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;

  const location = useLocation<any>();
  const classes = takePaymentStyles();
  const classesTwo = customerStyles();
  const containerData: any = useContext(
    ContainerContext
  ) as CustomPropInterface;
  const freeTimeObj = {
    currentDueDate: "",
    nextDueDate: "",
    type: "",
    waiveLDW: false,
    waivePolicy: false,
  }; // This object will be holding all the Free Time Popup Value
  const agreementTypeObj = {
    LDW: false,
    Policy: false,
  }; // This object will be controlling the enable and disable for check boc in free time popup
  const selectedAgreementTypeObject = {
    LDW: false,
    Policy: false,
  }; // This object will be holding the type of agreement that is selected
  const freeTimeType = [
    { description: "Regular", refCode: "FREAMT" },
    { description: "Service", refCode: "SERVFREETM" },
    { description: "Retention Extension", refCode: "RETEXTAMT" },
    { description: "Promo Free Time", refCode: "PROMO" },
  ]; // This array will be having all the drop down value with promo type
  const EPPFreeTimeType = [
    { description: "Retention Extension", refCode: "RETEXTAMT" },
  ];
  const freeTimeTypeWOPromo = [
    { description: "Regular", refCode: "FREAMT" },
    { description: "Service", refCode: "SERVFREETM" },
    { description: "Retention Extension", refCode: "RETEXTAMT" },
  ]; // This array will be having all the drop down value without promo type
  const [disableFreeTime, setdisableFreeTime] = useState(true); // This is used to enable and disable free time button
  const [EPPSelected, setEPPSelected] = useState(false);
  const [promoSingleAgreementMessage, setpromoSingleAgreementMessage] =
    useState(""); // This is used to display a error message for promo agreement that will not be able to opt promo free time
  const [completePromo, setcompletePromo] = useState(false); // True is stored here if all the selected agr of promo type with same promo free days else false is stored
  // const [masterLoader, setmasterLoader] = useState(false); // This is used to enable and disable loader
  const [partialPromo, setpartialPromo] = useState(false); // True is stored if atleast one of the selected agreement is of type promo
  const [needFreeTimeAuth, setneedFreeTimeAuth] = useState(false); // This will enable the SLA
  const [openFreeTime, setopenFreeTime] = useState(false); // This will be used to open Free Time popup
  const [purePromoType, setpurePromoType] = useState(false); // True is stored if all choosen agr is of promo type and has same promo free days
  const [freeTimeData, setfreeTimeData] = useState(freeTimeObj); // This object will be holding all the Free Time Popup Value
  const [selectedAgreementTypeData, setselectedAgreementTypeData] = useState(
    selectedAgreementTypeObject
  ); // This object will be holding the type of agreement that is selected
  const [maxDaysLimit, setmaxDaysLimit] = useState(30); // This is used to control the avail days in the next due date
  const [agreementTypeData, setagreementTypeData] = useState(agreementTypeObj); // This object will be controlling the enable and disable for check boc in free time popup
  const [CurrentRole, setCurrentRole] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [isInvalidDDSelected, setIsInvalidDDSelected] = useState<boolean>(false);
  const [freeTimeValidation, setFreeTimeValidation] = useState<any>();
  const [aproxFreeTimeAmount, setAproxFreeTimeAmount] = useState<any>(0);
  const [freeTimeAuthPopup, setFreeTimeAuthPopup] = useState<boolean>(false);
  const [proceedButtonLoader, setProceedButtonLoader] = useState(false);
  const [cappedFreeTimeSelected, setCappedFreeTimeSelected] = useState<boolean>(false);
  const [cappedFreeTimeStarted, setCappedFreeTimeStarted] = useState<boolean>(false);
  // const [currentFTAmount, setCurrentFTAmount] = useState<number>(0);
  // const [totallyUsedFTAmount, setTotallyUsedFTAmount] = useState<number>(0);
  let adjustDueRes: any = [];
  let bindedTypeData: any;
  const dateFormate = (input: any) => {
    return moment(input).format("MM/DD/YYYY");
  }; // This is used to change the date format

  useEffect(() => {
    if(freeTimeData.nextDueDate == '') {
      setIsInvalidDDSelected(false);
    }
    if (props?.receivedCheckedAgr[0] && props?.receivedCheckedAgr[0]?.CurrentDueDate) {
      console.log(freeTimeData?.nextDueDate, props?.receivedCheckedAgr[0]?.CurrentDueDate, 'Both Dates in useEffect');
      const date1: any = moment(props?.receivedCheckedAgr[0]?.CurrentDueDate);
      const date2: any = moment(freeTimeData?.nextDueDate);
      console.log(date1, date2, 'After Both Dates in useEffect');
      const differenceInDays = date2.diff(date1, 'days');
      let perDayRent = 0;
      props?.receivedCheckedAgr?.forEach((e: any) => {
        perDayRent += Number(e?.agreementRateDetails?.perDayRent);
      });
      console.log(differenceInDays, perDayRent, differenceInDays * perDayRent, (differenceInDays * perDayRent).toFixed(2), 'Dates Validation Here')
      setAproxFreeTimeAmount((differenceInDays * perDayRent).toFixed(2))
    }
  }, [freeTimeData.nextDueDate])

  useEffect(() => {
    if(props.openFreeTimeForRetention) {
      setopenFreeTime(true);
      setfreeTimeData({
        ...freeTimeData,
        nextDueDate: "",
        type: 'RETEXTAMT',
        waiveLDW: false,
        waivePolicy: false,
      });
      setmaxDaysLimit(Number(location?.state?.retentionData?.extentionDays))
    }
  }, [props.openFreeTimeForRetention])

  useEffect(() => {
    const GettingRole = async () => {
      const GetcurrentUser = await GetEmployeeID();
      console.log("GetcurrentUser", GetcurrentUser);
      if (GetcurrentUser.status === 200) {
        const GetcurrentUserResponse = GetcurrentUser.data;
        const EmployeeId = GetcurrentUserResponse.employeeId;
        const GetMenuPayload = {
          coworkerId: EmployeeId,
        };
        const GetMenuDetails: any = await GetRole(GetMenuPayload);
        console.log("GetMenuDetails", GetMenuDetails);
        if (GetMenuDetails.status === 200) {
          const GetMenuDetailsResponse = GetMenuDetails.data;
          const currentRole = GetMenuDetailsResponse.coworkerProfile.role;
          console.log("currentRole", currentRole);
          setCurrentRole(currentRole);
        }
      }
    };
    if (containerData !== undefined) {
      console.log("containerData", containerData);
      console.log("containerDataRole", containerData.GetRole());
      setCurrentRole(containerData.GetRole());
    } else {
      GettingRole();
    }
  }, [containerData]); // This method is used to get the current role of the co worker who have logged in
  useEffect(() => {
    console.log("sdfvfdv checkedAgr sdfc", props.receivedCheckedAgr);
    console.log("freeTimeData sdcdc", freeTimeData);
    const onlyEPPSelected = props.receivedCheckedAgr.every(
      (el) => el.agreementRateDetails?.employeePurchasePlan === "Y"
    );
    setEPPSelected(onlyEPPSelected);
    const onlyNonEPPSelected = props.receivedCheckedAgr.every(
      (el) => el.agreementRateDetails?.employeePurchasePlan === "N"
    );
    const promoSelected = props.receivedCheckedAgr.some(
      (el) => el.Agreementtype == 'Promo'
    );

    const isPIFNote = props.receivedCheckedAgr.some(
      (el) => el.Agreementtype == 'PIF Note'
    );

    const returnAgreementChecked = props.receivedCheckedAgr.some(
      (el) => el.isReturnAgrFlag == 1
    );
    const ordinaryScenario = props.receivedCheckedAgr.every(
      (el, index) =>
        el.policyAmount == 0 &&
        el.agrLdwAmount == 0 &&
        props.receivedCheckedAgr[0].CurrentDueDate ==
          props.receivedCheckedAgr[index].CurrentDueDate &&
        props.receivedCheckedAgr[0].Schedule ==
          props.receivedCheckedAgr[index].Schedule
    ); // To check an agreement of non LDW & Policy
    const policyScenario = props.receivedCheckedAgr.every(
      (el, index) =>
        el.policyAmount !== 0 &&
        el.agrLdwAmount == 0 &&
        props.receivedCheckedAgr[0].CurrentDueDate ==
          props.receivedCheckedAgr[index].CurrentDueDate &&
        props.receivedCheckedAgr[0].Schedule ==
          props.receivedCheckedAgr[index].Schedule
    ); // To check an agreement of Policy
    const LDWScenario = props.receivedCheckedAgr.every(
      (el, index) =>
        el.policyAmount == 0 &&
        el.agrLdwAmount !== 0 &&
        props.receivedCheckedAgr[0].CurrentDueDate ==
          props.receivedCheckedAgr[index].CurrentDueDate &&
        props.receivedCheckedAgr[0].Schedule ==
          props.receivedCheckedAgr[index].Schedule
    ); // To check an agreement LDW

    const sameStoreSelected = props.receivedCheckedAgr.every(
      (el, index) =>
        Number(window.sessionStorage.getItem("storeNumber")) ==
        Number(props.receivedCheckedAgr[index].store_number)
    ); // To check selected agr are same as current store
    console.log("dfdv LDWScenario 74", LDWScenario);
    console.log("dfdv policyScenario 811", policyScenario);
    console.log("sdcvfc 768 sdc sameStoreSelected", sameStoreSelected);
    const initialPayScenario = props.receivedCheckedAgr.every(
      (el) => el.number_of_paymentsmade > 0
    ); // To check selected agr doesn't fall in initial pay
    const finalPayScenario = props.receivedCheckedAgr.every(
      (el) =>
        el.Nextduedate !== "EPO" &&
        el.Nextduedate !== "SAC" &&
        el.Nextduedate !== "PIF"
    ); // To check selected agr doesn't fall in final pay
    const checkSomePromoFreeDays = props.receivedCheckedAgr.some(
      (el) => el.PromoFreeDays !== 0
    ); // To check that any selected agr is of type promo
    setpartialPromo(checkSomePromoFreeDays);
    const checkEveryPromoFreeDays = props.receivedCheckedAgr.every(
      (el) => el.PromoFreeDays !== 0
    ); // To check that all selected agr is of type promo
    setcompletePromo(checkEveryPromoFreeDays);
    const noClubSelected = props.receivedCheckedAgr.every(
      (el) => el.Agreementtype !== 'Club'
    );
    console.log('noClubSelected logger', noClubSelected);
    // if(checkPromoFreeDays){
    const checkPurePromoType = props.receivedCheckedAgr.every(
      (el, index) =>
        el.PromoFreeDays !== 0 &&
        props.receivedCheckedAgr[0].PromoFreeDays ==
          props.receivedCheckedAgr[index].PromoFreeDays
    ); // To check that all selected agr is of type promo with same promo free days
    setpurePromoType(checkPurePromoType);
    console.log("sdcfc 78 finalPayScenario", finalPayScenario);
    if (LDWScenario) {
      setselectedAgreementTypeData({ LDW: true, Policy: false });
      setagreementTypeData({ ...agreementTypeData, LDW: true });
    } else if (policyScenario) {
      setselectedAgreementTypeData({ LDW: false, Policy: true });
      setagreementTypeData({ ...agreementTypeData, Policy: true });
    } else {
      setselectedAgreementTypeData(selectedAgreementTypeObject);
      setagreementTypeData(agreementTypeObj);
    } // This is used to controll the enable and disable of check boc in the free time popup
    if (
      (ordinaryScenario || policyScenario || LDWScenario) &&
      (onlyEPPSelected || onlyNonEPPSelected) &&
      initialPayScenario &&
      props?.receivedCheckedAgr?.length > 0 &&
      sameStoreSelected &&
      finalPayScenario &&
      noClubSelected &&
      !promoSelected &&
      !returnAgreementChecked &&
      !isPIFNote
    ) {
      setdisableFreeTime(false);
    } else {
      setdisableFreeTime(true);
    } // This is used to controll the enable and disable of the free time button

    if (props.receivedCheckedAgr.length > 0) {
      const {
        isRegularFreeTimeAllowed,
        isServiceFreeTimeAllowed,
        isRegExtFreeTimeAllowed,
        isPromoFreeTimeallowed,
      } = props.configData; // Destructuring the config values from TakePayment.tsx file
      const filteredConfigs: any = [
        { paramValue: isRegularFreeTimeAllowed },
        { paramValue: isServiceFreeTimeAllowed },
        { paramValue: isRegExtFreeTimeAllowed },
        { paramValue: isPromoFreeTimeallowed },
      ]; // Assigning to a new key for mapping purpose
      const finalData = freeTimeType.filter(
        (obj: any, index: any) => filteredConfigs[index].paramValue === 1
      ); // Filtered to find the primary option that is to be choosen by default
      const promoDayLimit = props.receivedCheckedAgr.filter(
        (obj: any, index: any) =>
          props.receivedCheckedAgr[index].PromoFreeDays > 0
      ); // To filter all the agreements that has promo free days
      console.log("cvgt54redfg65", promoDayLimit);
      console.log("cvgt54redfg65sdfd", promoDayLimit.length);
      console.log("scfresdft543wafinalData", finalData);
      // if (finalData[0].refCode === "PROMO") {
      //   // If the primary option is PROMO then this condition is passed
      //   setmaxDaysLimit(
      //     promoDayLimit.length > 0 ? promoDayLimit[0].PromoFreeDays : 0
      //   ); // Max day limit is assigned
      //   setagreementTypeData(agreementTypeObj); // Check box are set to disable mode
      //   setfreeTimeData({
      //     ...freeTimeData,
      //     type: finalData[0].refCode,
      //     waiveLDW: true,
      //     waivePolicy: true,
      //   }); // The Primary option is choosen in the drop down and check boxes are checked
      // } else 
      if (!cappedFreeTimeStarted) {
        if (
          finalData[0].refCode === "FREAMT" ||
          finalData[0].refCode === "SERVFREETM"
        ) {
          if (props.openFreeTimeForRetention) {
            setfreeTimeData({
              ...freeTimeData,
              nextDueDate: "",
              type: 'RETEXTAMT',
              waiveLDW: false,
              waivePolicy: false,
            });
            setmaxDaysLimit(Number(location?.state?.retentionData?.extentionDays))
          } else {
            // If the primary option is FREAMT or SERVFREETM then this condition is passed
            setmaxDaysLimit(30); // Max day limit is assigned
            setfreeTimeData({ ...freeTimeData, type: finalData[0].refCode }); // The Primary option is choosen in the drop down
          }
        } else if (finalData[0].refCode === "RETEXTAMT") {
          if (props.openFreeTimeForRetention) {
            setfreeTimeData({
              ...freeTimeData,
              nextDueDate: "",
              type: 'RETEXTAMT',
              waiveLDW: false,
              waivePolicy: false,
            });
            setmaxDaysLimit(Number(location?.state?.retentionData?.extentionDays))
          } else {
            // If the primary option is RETEXTAMT then this condition is passed
            setmaxDaysLimit(35); // Max day limit is assigned
            setfreeTimeData({ ...freeTimeData, type: finalData[0].refCode }); // The Primary option is choosen in the drop down
          }
        }
      }
    }
  }, [props]);
  useEffect(() => {
    if (
      freeTimeData.nextDueDate == "" ||
      freeTimeData.type == "" ||
      promoSingleAgreementMessage !== ""
    ) {
      setDisableSubmit(true);
    } else  {
      setDisableSubmit(false);
    }
  }, [freeTimeData, promoSingleAgreementMessage]);
  useEffect(() => {
    console.log("dfvdc agreementTypeData 8514", agreementTypeData);
    console.log(
      "sdfvc selectedAgreementTypeData 47851",
      selectedAgreementTypeData
    );
    console.log(
      "sdcdx promoSingleAgreementMessage",
      promoSingleAgreementMessage
    );

    console.log("dfd dvmaxDaysLimit", maxDaysLimit);
    console.log("sdvfc freeTimeData sdcfc", freeTimeData);
  }, [
    agreementTypeData,
    selectedAgreementTypeData,
    maxDaysLimit,
    freeTimeData,
    promoSingleAgreementMessage,
  ]);
  useEffect(()=> {
    setFreeTimeValidation(cappedFreeTimeValidation(props))
  }, [props?.receivedCheckedAgr])

  const cancelClicked = () => {
    props.resetExtention();
    setopenFreeTime(false);
    setLoadingSave(false);
    setpromoSingleAgreementMessage("");
    if (props.receivedCheckedAgr.length > 0) {
      const {
        isRegularFreeTimeAllowed,
        isServiceFreeTimeAllowed,
        isRegExtFreeTimeAllowed,
        isPromoFreeTimeallowed,
      } = props.configData;
      const filteredConfigs: any = [
        { paramValue: isRegularFreeTimeAllowed },
        { paramValue: isServiceFreeTimeAllowed },
        { paramValue: isRegExtFreeTimeAllowed },
        { paramValue: isPromoFreeTimeallowed },
      ];
      const finalData = freeTimeType.filter(
        (obj: any, index: any) => filteredConfigs[index].paramValue === 1
      );
      const promoDayLimit = props.receivedCheckedAgr.filter(
        (obj: any, index: any) =>
          props.receivedCheckedAgr[index].PromoFreeDays > 0
      );
      console.log("cvgt54redfg65", promoDayLimit);
      console.log("cvgt54redfg65sdfd", promoDayLimit.length);
      console.log("scfresdft543wafinalData", finalData);
      // if (finalData[0].refCode === "PROMO") {
      //   setmaxDaysLimit(
      //     promoDayLimit.length > 0 ? promoDayLimit[0].PromoFreeDays : 0
      //   );
      //   setagreementTypeData(agreementTypeObj);
      //   setfreeTimeData({
      //     ...freeTimeData,
      //     type: finalData[0].refCode,
      //     waiveLDW: true,
      //     waivePolicy: true,
      //     nextDueDate: "",
      //   });
      // } else 
      if (
        finalData[0].refCode === "FREAMT" ||
        finalData[0].refCode === "SERVFREETM"
      ) {
        setmaxDaysLimit(30);
        setfreeTimeData({
          ...freeTimeData,
          type: finalData[0].refCode,
          nextDueDate: "",
          waiveLDW: false,
          waivePolicy: false,
        });
      } else if (finalData[0].refCode === "RETEXTAMT") {
        setmaxDaysLimit(35);
        setfreeTimeData({
          ...freeTimeData,
          type: finalData[0].refCode,
          nextDueDate: "",
          waiveLDW: false,
          waivePolicy: false,
        });
      } else {
        setmaxDaysLimit(0);
        setagreementTypeData(agreementTypeObj);
        setfreeTimeData({
          ...freeTimeData,
          type: "",
          nextDueDate: "",
          waiveLDW: false,
          waivePolicy: false,
        });
      }
    }
  }; // This method is invoked when Cancel or Close icon is clicked
  const acceptFreeTimeRedirect=async()=>{
    console.log(cappedFreeTimeSelected, 'CappedFreeTimeSelected');
    if (cappedFreeTimeSelected) {
      console.log('ExceededFTAmount', props.exceededAmount.exceededAmount + (freeTimeValidation.allowdedFreeAmount > freeTimeValidation.usedFreeTimeAmount ? ((freeTimeValidation.usedFreeTimeAmount + parseFloat(props.currentFTAmount.currentFTAmount)) - freeTimeValidation.allowdedFreeAmount) : parseFloat(props.currentFTAmount.currentFTAmount)))
      props.exceededAmount.setExceededAmount(props.exceededAmount.exceededAmount + (freeTimeValidation.allowdedFreeAmount > freeTimeValidation.usedFreeTimeAmount ? ((freeTimeValidation.usedFreeTimeAmount + parseFloat(props.currentFTAmount.currentFTAmount)) - freeTimeValidation.allowdedFreeAmount) : parseFloat(props.currentFTAmount.currentFTAmount)));
      setCappedFreeTimeSelected(false);
    }
    if(props.configData.commonModuleFlag==1){
      acceptFeeTimeCommonModule('Regular')
    }else{
      acceptFreeTime()
    }
  }

  const updateFreeTimeAmontForAgr = (id: any, newAmount: any) => {
    console.log(id, newAmount, 'inside the udpate function');
    let updatedItems;
    for (let i = 0; i < id.length; i++) {
      updatedItems = [
        ...props?.usedFreeTimeAmounts?.usedFreeTimeAmounts,
      ];

      const index = updatedItems.findIndex((item) => item.id == id[i]);

      if (index !== -1) {
        updatedItems[index] = { ...updatedItems[index], amount : newAmount[i] };
      } else {
        updatedItems.push({ id: id[i], amount: newAmount[i] });
      }
    }

    props?.usedFreeTimeAmounts?.setUsedFreeTimeAmouts(updatedItems);

    console.log(updatedItems, 'updatedItems');
    console.log(calculateTotalAmount(updatedItems), 'calculateTotalAmount(updatedItems)');
    return calculateTotalAmount(updatedItems);

  };

  const calculateTotalAmount = (items: any) => {
    // return items.reduce((total, item) => total + item.amount, 0);
    let totalAmount = 0;
    items?.forEach((item) => {
      totalAmount += parseFloat(item.amount)
    });
    return totalAmount;
  };

  const acceptFeeTimeCommonModule=async(purpose: any)=>{
    const updatedChkGridValue = props.receivedCheckedAgr; // Stores the choosen agr data
    console.log("sdcdc freeTimeData sdc23", freeTimeData);

    const nextduedate = moment(freeTimeData.nextDueDate).format("MM/DD/YYYY"); // Change the next due date format
    const currentduedate = moment(updatedChkGridValue[0].CurrentDueDate).format(
      "MM/DD/YYYY"
    ); // Change the current due date format
    const date1 = new Date(currentduedate);
    const date2 = new Date(nextduedate);
    const time_difference = date2.getTime() - date1.getTime();
    const days_difference = time_difference / (1000 * 3600 * 24); // To find the days difference between two dates

    console.log("sdfcv fdc34rfdsw3e currentduedate", currentduedate);
    console.log("xcvdc nextduedate2340", nextduedate);

    //const diffBetweenDates = diffDates.diff(currentduedate, "days");
    console.log("sdfedcv diffBetweenDates sdv", Number(days_difference));
    // If condition passes if any of the check box is checked in free time popup
      setLoadingSave(true);
      for (let i = 0; i < updatedChkGridValue.length; i++) {
        // const agreeId = updatedChkGridValue.filter(
        //   (x) =>
        //     String(x.agreementId) ===
        //     String(props.updatedChkGridValue[i].Agreementid)
        // );
        updatedChkGridValue[i].FreeTime=null
        updatedChkGridValue[i].isFreeTimeValue={
          freeTimeType: freeTimeData.type,
          freeTimeDays: Number(days_difference).toFixed(),
          isOptServiceWaived:
            freeTimeData.waiveLDW || freeTimeData.waivePolicy ? true : false,
        };
        
      }
      let commonModulePayload = await calculationModulePayloadFormation( {
        customerId:props.customerId,
        storeNumber:props.storeNumber,
        checkedAgr:updatedChkGridValue})
      let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
      commonModuleServiceRes=commonModuleServiceRes.data
    
      if (freeTimeData.waiveLDW || freeTimeData.waivePolicy) {
        updatedChkGridValue.map((chkObj, indexChk) => {
          updatedChkGridValue[indexChk].Amount = 0.0;
          updatedChkGridValue[indexChk].Taxamount = 0.0;
          updatedChkGridValue[indexChk].policyAmount = 0.0;
          updatedChkGridValue[indexChk].ldwTaxAmount = 0.0;
          updatedChkGridValue[indexChk].agrLdwAmount = 0.0;
          updatedChkGridValue[indexChk].policyTaxAmount = 0.0;
          updatedChkGridValue[indexChk].agreementRate = 0.0;
          updatedChkGridValue[indexChk].rpayTaxAmount = 0.0;
          updatedChkGridValue[indexChk].Latelast10 = 0.0;
          updatedChkGridValue[indexChk].lateFee = 0.0;
          updatedChkGridValue[indexChk].carryRentAmountUsed = 0.0;
          updatedChkGridValue[indexChk].lFeeTaxAmount = 0.0;
          updatedChkGridValue[indexChk].carryRentAmountUsed = 0.0;
          updatedChkGridValue[indexChk].carryRentTaxUsed = 0.0;
          updatedChkGridValue[indexChk].Nextduedate = moment(
            freeTimeData.nextDueDate
          ).format("MM/DD/YYYY");
          updatedChkGridValue[indexChk].PromoFreeDaysApplied =
            freeTimeData.type == "PROMO"
              ? Number(days_difference)
              : updatedChkGridValue[indexChk].PromoFreeDaysApplied;
          updatedChkGridValue[indexChk].FreeTime = {
            freeTimeType: freeTimeData.type,
            freeTimeDays: Number(days_difference).toFixed(),
            isOptServiceWaived:
              freeTimeData.waiveLDW || freeTimeData.waivePolicy ? true : false,
          };
        });
        if(purpose == 'Auth'){
          console.log(commonModuleServiceRes, 'commonModuleServiceRes')
          console.log(freeTimeValidation, freeTimeData, 'freeTimeValidation');
          console.log('Current Free Time Amount', commonModuleServiceRes.paymentDetails[0].agreementPaymentDetails)
          const currentFreeTimeAmountObject = commonModuleServiceRes.paymentDetails[0].agreementPaymentDetails;
          let currentFreeTimeAmount = 0;
          const agreementIds: any = [];
          const currentFTAmounts: any = [];
          currentFreeTimeAmountObject?.forEach((e : any) => {
            currentFreeTimeAmount += Number(e.freeTimeDetails.rentalFreeAmount)
            agreementIds.push(e?.agreementId)
            currentFTAmounts.push(Number(e.freeTimeDetails.rentalFreeAmount))
          });
          console.log('updated free time amount', currentFreeTimeAmount);
          props.currentFTAmount.setCurrentFTAmount(currentFreeTimeAmount);
          const agreementId = commonModuleServiceRes.paymentDetails[0].agreementPaymentDetails[0].agreementId;
          const currentlyUsedFreeTimeAmount = await updateFreeTimeAmontForAgr(agreementIds, currentFTAmounts);
          console.log(currentlyUsedFreeTimeAmount, 'currentlyUsedFreeTimeAmount');
          props.totallyUsedFTAmount.setTotallyUsedFTAmount(currentlyUsedFreeTimeAmount);
          // setCurrentFTAmount(parseFloat(currentFreeTimeAmount));
          if (freeTimeValidation.requiredValidation.includes(freeTimeData.type)) {
            console.log(freeTimeValidation.allowdedFreeAmount, freeTimeValidation.usedFreeTimeAmount, currentFreeTimeAmount, freeTimeValidation.usedFreeTimeAmount + currentFreeTimeAmount, 'Finally Completed');
            console.log('exceededFreeTimeAmount', props.exceededAmount.exceededAmount + (freeTimeValidation.allowdedFreeAmount > freeTimeValidation.usedFreeTimeAmount ? ((freeTimeValidation.usedFreeTimeAmount + currentFreeTimeAmount) - freeTimeValidation.allowdedFreeAmount) : currentFreeTimeAmount))
            if(freeTimeValidation.allowdedFreeAmount > (freeTimeValidation.usedFreeTimeAmount + currentFreeTimeAmount)){
              authFreeTime(false);
            }
            else{
              // setLoadingSave(false);
              // props.exceededAmount.setExceededAmount(props.exceededAmount.exceededAmount + ((freeTimeValidation.usedFreeTimeAmount + parseFloat(currentFreeTimeAmount)) - freeTimeValidation.allowdedFreeAmount));
              // props.exceededAmount.setExceededAmount(props.exceededAmount.exceededAmount + ((freeTimeValidation.usedFreeTimeAmount + parseFloat(currentFreeTimeAmount)) - freeTimeValidation.allowdedFreeAmount))
              // props.exceededAmount.setExceededAmount(props.exceededAmount.exceededAmount + (freeTimeValidation.allowdedFreeAmount > freeTimeValidation.usedFreeTimeAmount ? ((freeTimeValidation.usedFreeTimeAmount + parseFloat(currentFreeTimeAmount)) - freeTimeValidation.allowdedFreeAmount) : parseFloat(currentFreeTimeAmount)));
              setFreeTimeAuthPopup(true);
            }
          }
          else {
            authFreeTime(false);
          }
      }
      }
      else {

      if(purpose == 'Auth'){
        console.log(commonModuleServiceRes, 'commonModuleServiceRes')
        console.log(freeTimeValidation, freeTimeData, 'freeTimeValidation');
        console.log(
          "Current Free Time Amount",
          commonModuleServiceRes.paymentDetails[0].agreementPaymentDetails
        );
        const currentFreeTimeAmountObject =
          commonModuleServiceRes.paymentDetails[0].agreementPaymentDetails;
        let currentFreeTimeAmount = 0;
        const agreementIds: any = [];
        const currentFTAmounts: any = [];
        currentFreeTimeAmountObject?.forEach((e: any) => {
          currentFreeTimeAmount += Number(e.freeTimeDetails.rentalFreeAmount);
          agreementIds.push(e?.agreementId);
          currentFTAmounts.push(Number(e.freeTimeDetails.rentalFreeAmount));
        });
        console.log("updated free time amount", currentFreeTimeAmount);
        props.currentFTAmount.setCurrentFTAmount(currentFreeTimeAmount);
        const agreementId = commonModuleServiceRes.paymentDetails[0].agreementPaymentDetails[0].agreementId;
        const currentlyUsedFreeTimeAmount = await updateFreeTimeAmontForAgr(agreementIds, currentFTAmounts);
        console.log(currentlyUsedFreeTimeAmount, 'currentlyUsedFreeTimeAmount');
        props.totallyUsedFTAmount.setTotallyUsedFTAmount(currentlyUsedFreeTimeAmount);
        // setCurrentFTAmount(parseFloat(currentFreeTimeAmount));
        if (freeTimeValidation.requiredValidation.includes(freeTimeData.type)) {
          console.log(freeTimeValidation.allowdedFreeAmount, freeTimeValidation.usedFreeTimeAmount, currentFreeTimeAmount, freeTimeValidation.usedFreeTimeAmount + currentFreeTimeAmount, 'Finally Completed');
          console.log('exceededFreeTimeAmount', props.exceededAmount.exceededAmount + (freeTimeValidation.allowdedFreeAmount > freeTimeValidation.usedFreeTimeAmount ? ((freeTimeValidation.usedFreeTimeAmount + currentFreeTimeAmount) - freeTimeValidation.allowdedFreeAmount) : currentFreeTimeAmount))
          if(freeTimeValidation.allowdedFreeAmount > (freeTimeValidation.usedFreeTimeAmount + currentFreeTimeAmount)){
            // setLoadingSave(false);
            authFreeTime(false);
          }
          else{
            // setLoadingSave(false);
            // props.exceededAmount.setExceededAmount(props.exceededAmount.exceededAmount + ((freeTimeValidation.usedFreeTimeAmount + parseFloat(currentFreeTimeAmount)) - freeTimeValidation.allowdedFreeAmount));
            // props.exceededAmount.setExceededAmount(props.exceededAmount.exceededAmount + (freeTimeValidation.allowdedFreeAmount > freeTimeValidation.usedFreeTimeAmount ? ((freeTimeValidation.usedFreeTimeAmount + parseFloat(currentFreeTimeAmount)) - freeTimeValidation.allowdedFreeAmount) : parseFloat(currentFreeTimeAmount)));
            setFreeTimeAuthPopup(true);
          }
        }
        else {
          authFreeTime(false);
        }
      }
      else {
        //Assignment starts here
        for (let i = 0; i < updatedChkGridValue.length; i++) {




          let commonModuleAgrRes: any = []
          if (updatedChkGridValue[i].Agreementtype != 'Club') {
            commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
              (x) =>
                String(x.agreementId) ===
                String(updatedChkGridValue[i].Agreementid)
            );
          } else {
            commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.clubPaymentDetails.filter(
              (x) =>
                String(x.clubMembershipNumber) ===
                String(updatedChkGridValue[i].AgreementNumber)
            );
          }
          let nextduedate;
          if (
            commonModuleAgrRes[0].nextDueDate != "SAC" &&
            commonModuleAgrRes[0].nextDueDate != "PIF" &&
            commonModuleAgrRes[0].nextDueDate != "EPO"
          ) {
            nextduedate = moment(commonModuleAgrRes[0].nextDueDate).format("MM/DD/YYYY");
          } else if (commonModuleAgrRes[0].isFinalPayment != '' && commonModuleAgrRes[0].isFinalPayment) {
            nextduedate = commonModuleAgrRes[0].isFinalPayment;
          }
          let ldw = commonModuleAgrRes[0].amountDetails?.filter((x) => x.itemCategory == "LDW" || x.itemCategory == "LTLDW")
          let policyAmount = commonModuleAgrRes[0].amountDetails?.filter((x) => x.itemCategory == 'RACTIREPAY')
          let carryLateFee = commonModuleAgrRes[0].amountDetails?.filter((x) => x.itemCategory == 'CLFEEPAY')
          let lateFee = commonModuleAgrRes[0].amountDetails?.filter((x) => x.itemCategory == 'LTRPAYFEE')
          let carryRentUsed = commonModuleAgrRes[0].amountDetails?.filter((x) => x.itemCategory == 'CRPAY')
          updatedChkGridValue[i].Nextduedate = nextduedate;
          updatedChkGridValue[i].Amount = Number(
            (Number(commonModuleAgrRes[0].totalAmount) + Number(commonModuleAgrRes[0].totalTax)).toFixed(2)
          );
          updatedChkGridValue[i].Taxamount = Number(commonModuleAgrRes[0].totalTax)
          updatedChkGridValue[i].policyAmount = policyAmount?.length > 0 ? Number(policyAmount[0].amount) : 0
          updatedChkGridValue[i].ldwTaxAmount = ldw?.length > 0 ? Number(ldw[0].tax) : 0
          updatedChkGridValue[i].agrLdwAmount = ldw?.length > 0 ? Number(ldw[0].amount) : 0
          updatedChkGridValue[i].policyTaxAmount = policyAmount?.length > 0 ? Number(policyAmount[0].tax) : 0
          updatedChkGridValue[i].agreementRate = 0.0; // agreementRate
          updatedChkGridValue[i].rpayTaxAmount = 0.0;
          updatedChkGridValue[i].PromoFreeDaysApplied =
            freeTimeData.type == "PROMO"
              ? Number(days_difference)
              : updatedChkGridValue[i].PromoFreeDaysApplied;
          updatedChkGridValue[i].Latelast10 = 0.0;
          updatedChkGridValue[i].lateFee = 0.0;
          updatedChkGridValue[i].carryRentTaxUsed = 0.0;
          updatedChkGridValue[i].carriedLate = 0.0;
          updatedChkGridValue[i].carriedLateTax = 0.0;
          updatedChkGridValue[i].lFeeTaxAmount = 0.0;
          updatedChkGridValue[i].carryRentAmountUsed = 0.0;
          updatedChkGridValue[i].FreeTime = {
            freeTimeType: freeTimeData.type,
            freeTimeDays: Number(days_difference).toFixed(),
            isOptServiceWaived:
              freeTimeData.waiveLDW || freeTimeData.waivePolicy ? true : false,
          };
          //updatedChkGridValue[i].Agreementtype=agreeType[0].Agreementtype=="Club"?"Club":agreeId[0].agreementType
        }
      }
      //Assignment ends here
      //const finadjust: any = [];
      //let totalAmount = 0;
      //let totalTaxAmount = 0;
      //for (let index = 0; index < adjustDueRes.length; index++) {
      //totalAmount += Number(adjustDueRes[index].totalAmount);
      //totalTaxAmount += Number(adjustDueRes[index].totalTax);
      // if (
      //   adjustDueRes[index].nextDueDate != "SAC" &&
      //   adjustDueRes[index].nextDueDate != "PIF" &&
      //   adjustDueRes[0].nextDueDate != "EPO"
      // ) {
      //   const MyDate = new Date(adjustDueRes[0].nextDueDate);
      //   const nextDueDateFormate =
      //     MyDate.getFullYear() +
      //     "-" +
      //     ("0" + (MyDate.getMonth() + 1)).slice(-2) +
      //     "-" +
      //     ("0" + MyDate.getDate()).slice(-2);
      //   setnextDueDate(nextDueDateFormate);
      // } else {
      //   finadjust.push(
      //     `Adjusted AgreementNumber # ${adjustDueRes[index].agreementNumber} - will be ${adjustDueRes[index].nextDueDate} `
      //   );
      // }
      //}
      //setfinalAdjustedArray(finadjust);
      //setcalciRes(adjustDueRes);
      //settotalAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
      //setresAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
      // Calculation call ends here
    }


    console.log("dfvfd 55updatedGridValue", updatedChkGridValue);
    //setpaygridvalues(updatedGridValue);
    if (purpose != 'Auth') {
      setProceedButtonLoader(false);
      setFreeTimeAuthPopup(false);    
      setLoadingSave(false);
      setopenFreeTime(false);
      setfreeTimeData(freeTimeObj); // Used to restore default value in popup
      let traceObj = {
        iD: Number(userTraceData?.length) + 1,
        type: "FreeTime"
      }
      setUserTraceData([...userTraceData, traceObj])
      props.response({ resArray: updatedChkGridValue }); // Manipulate the grid values
      //setmasterLoader(false); // Make the loader disappear
      props.resetExtention();
    }
    //setcarryRentAmount("0.00");
    //multipleAdjust();
    //AgreementNumber
  }

  const acceptFreeTime = async () => {
    //setmasterLoader(true); // Enable the loader
    const updatedChkGridValue = props.receivedCheckedAgr; // Stores the choosen agr data
    console.log("sdcdc freeTimeData sdc23", freeTimeData);

    const nextduedate = moment(freeTimeData.nextDueDate).format("MM/DD/YYYY"); // Change the next due date format
    const currentduedate = moment(updatedChkGridValue[0].CurrentDueDate).format(
      "MM/DD/YYYY"
    ); // Change the current due date format
    const date1 = new Date(currentduedate);
    const date2 = new Date(nextduedate);
    const time_difference = date2.getTime() - date1.getTime();
    const days_difference = time_difference / (1000 * 3600 * 24); // To find the days difference between two dates

    console.log("sdfcv fdc34rfdsw3e currentduedate", currentduedate);
    console.log("xcvdc nextduedate2340", nextduedate);

    //const diffBetweenDates = diffDates.diff(currentduedate, "days");
    console.log("sdfedcv diffBetweenDates sdv", Number(days_difference));
    // If condition passes if any of the check box is checked in free time popup
    if (freeTimeData.waiveLDW || freeTimeData.waivePolicy) {
      updatedChkGridValue.map((chkObj, indexChk) => {
        updatedChkGridValue[indexChk].Amount = 0.0;
        updatedChkGridValue[indexChk].Taxamount = 0.0;
        updatedChkGridValue[indexChk].policyAmount = 0.0;
        updatedChkGridValue[indexChk].ldwTaxAmount = 0.0;
        updatedChkGridValue[indexChk].agrLdwAmount = 0.0;
        updatedChkGridValue[indexChk].policyTaxAmount = 0.0;
        updatedChkGridValue[indexChk].agreementRate = 0.0;
        updatedChkGridValue[indexChk].rpayTaxAmount = 0.0;
        updatedChkGridValue[indexChk].Latelast10 = 0.0;
        updatedChkGridValue[indexChk].lateFee = 0.0;
        updatedChkGridValue[indexChk].carryRentAmountUsed = 0.0;
        updatedChkGridValue[indexChk].lFeeTaxAmount = 0.0;
        updatedChkGridValue[indexChk].carryRentAmountUsed = 0.0;
        updatedChkGridValue[indexChk].carryRentTaxUsed = 0.0;
        updatedChkGridValue[indexChk].Nextduedate = moment(
          freeTimeData.nextDueDate
        ).format("MM/DD/YYYY");
        updatedChkGridValue[indexChk].PromoFreeDaysApplied =
          freeTimeData.type == "PROMO"
            ? Number(days_difference)
            : updatedChkGridValue[indexChk].PromoFreeDaysApplied;
        updatedChkGridValue[indexChk].FreeTime = {
          freeTimeType: freeTimeData.type,
          freeTimeDays: Number(days_difference).toFixed(),
          isOptServiceWaived:
            freeTimeData.waiveLDW || freeTimeData.waivePolicy ? true : false,
        };
      });
    } else {
      let coverageEndDateInfo: any = [];
      const adjustDueDatereq: any = [];
      for (let i = 0; i < props.receivedCheckedAgr.length; i++) {
        let coverageEndDate = "";
        let policyStatus = "";
        if (props.ldwChangeArr.length > 0) {
          // props.ldwChangeArr
          coverageEndDateInfo = props.ldwChangeArr.filter(
            (x) =>
              String(x.agreementId) ===
              String(props.receivedCheckedAgr[i].Agreementid)
          );
          if (coverageEndDateInfo.length > 0) {
            if (Number(coverageEndDateInfo[0].action) == 2) {
              coverageEndDate = moment().format("MM/DD/YYYY");
            } else if (Number(coverageEndDateInfo[0].action) == 1) {
              coverageEndDate = "12/31/2099";
              if (
                Number(
                  props.receivedCheckedAgr[i].agreementRateDetails.perDayLdw
                ) == 0
              ) {
                props.receivedCheckedAgr[i].agreementRateDetails.perDayLdw = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) / 7
                ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.perDayLdwTax =
                  (
                    (Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.weeklyRate
                    ) /
                      7) *
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                    )
                  ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.weeklyLdw =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.weeklyLdwTax =
                  (
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.weeklyRate
                    ) *
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                    )
                  ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.biWeeklyLdw =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .biweeklyRate
                  ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.biWeeklyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .biweeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.monthlyLdw =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .monthlyRate
                  ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.monthlyLdwTax =
                  (
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.monthlyRate
                    ) *
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                    )
                  ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.semiMonthlyLdw = Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .semimonthlyRate
                ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.semiMonthlyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .semimonthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
              }
            }
          }
        }
        if (coverageEndDate == "") {
          coverageEndDate = String(props.receivedCheckedAgr[i].coverageEndDate);
        }
        //policy
        if (props.policyChangeArr.length > 0) {
          // props.ldwChangeArr
          const policyChangeArrInfo = props.policyChangeArr.filter(
            (x) =>
              String(x.agreementId) ===
              String(props.receivedCheckedAgr[i].Agreementid)
          );
          if (policyChangeArrInfo.length > 0) {
            if (Number(policyChangeArrInfo[0].action) == 2) {
              policyStatus = "I";
            } else if (Number(policyChangeArrInfo[0].action) == 1) {
              policyStatus = "A";
              if (
                Number(
                  props.receivedCheckedAgr[i].agreementRateDetails.perDayPolicy
                ) == 0
              ) {
                props.receivedCheckedAgr[i].agreementRateDetails.perDayPolicy =
                  (
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.policyInfo
                        .scheduleRates.weeklyRate
                    ) / 7
                  ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.perDayPolicyTax = (
                  (Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ) /
                    7) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.weeklyPolicy =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.weeklyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.biWeeklyPolicy = Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.biweeklyRate
                ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.biWeeklyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.biweeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.receivedCheckedAgr[i].agreementRateDetails.monthlyPolicy =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.monthlyRate
                  ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.monthlyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.monthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.semiMonthlyPolicy = Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.semimonthlyRate
                ).toFixed(2);
                props.receivedCheckedAgr[
                  i
                ].agreementRateDetails.semiMonthlyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.semimonthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
              }
            }
          }
        }
        if (policyStatus == "") {
          policyStatus = String(props.receivedCheckedAgr[i].policyStatus);
        }
        let scheduleRef = "";
        if (
          props.receivedCheckedAgr[i].editSchedule != "" &&
          props.receivedCheckedAgr[i].editSchedule != null
        ) {
          scheduleRef = props.receivedCheckedAgr[i].editSchedule;
        } else {
          scheduleRef =
            props.receivedCheckedAgr[i].agreementRateDetails.scheduleRefCode;
        }
        const res = {
          agreementId: String(props.receivedCheckedAgr[i].Agreementid),
          agreementRateDetails:
            props.receivedCheckedAgr[i].agreementRateDetails,
          currentDueDate: String(props.receivedCheckedAgr[i].CurrentDueDate),
          dueDate: String(props.receivedCheckedAgr[i].Nextduedate),
          coverageEndDate: coverageEndDate,
          taxRate: String(props.receivedCheckedAgr[i].taxRate),
          carriedLate: String(props.receivedCheckedAgr[i].carriedLate),
          carriedLateTax: String(props.receivedCheckedAgr[i].carriedLateTax),
          lateFee: String(props.receivedCheckedAgr[i].lateFee),
          lateFeeTax: String(props.receivedCheckedAgr[i].lFeeTaxAmount),
          policyStatus: String(policyStatus),
          epoAmount: String(props.receivedCheckedAgr[i].EPOAmount),
          epoTax: String(props.receivedCheckedAgr[i].EPOTax),
          sacDate: String(props.receivedCheckedAgr[i].SAC_Date),
          isLdwAllowed: String(props.receivedCheckedAgr[i].isLdwAllowed),
          agreementNumber: String(props.receivedCheckedAgr[i].AgreementNumber),
          carryRent: String(props.receivedCheckedAgr[i].carryRentAmountUsed),
          carryRentTax: String(props.receivedCheckedAgr[i].carryRentTaxUsed),
          scheduleRefCode: scheduleRef,
        };
        adjustDueDatereq.push(res);
      }

      console.log("sdvffv adjustDueDatereq 852145", adjustDueDatereq);

      // Calculation call start here

      const calcireq = {
        rateResponse: adjustDueDatereq,
        dueDate: dateFormate(freeTimeData.nextDueDate),
      };
      adjustDueRes = await AdjustDueDatecalci(calcireq);
      console.log("sdfvdedfc sd875412 adjustDueRes", adjustDueRes);
      setLoadingSave(true);

      let taxInputsData: any = [];

      adjustDueRes.map((el) => {
        taxInputsData.push(
          {
            amount: el.policyAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "RACTIREPAY",
          },
          {
            amount: el.agreementRate,
            taxInputId: `${el.agreementId}`,
            itemCategory: "RPAY",
          },
          {
            amount: el.ldwAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "LDW",
          },
          {
            amount: el.lateFee,
            taxInputId: `${el.agreementId}`,
            itemCategory: "LTRPAYFEE",
          },
          {
            amount: el.carryRent,
            taxInputId: `${el.agreementId}`,
            itemCategory: "CRPAY",
          },
          {
            amount: el.carriedLate,
            taxInputId: `${el.agreementId}`,
            itemCategory: "CLFEEPAY",
          }
        );
      });

      let customerAdressFilterArray: any = props.customerInformation?.addresses?.filter(
        (x) => {
          return x.addressTypeDesc == "Primary Address";
        }
      );
      let zip5 = "";
      let zip4 = "0000";
      let postalZip=props.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip==null?null:
      props.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip?.replace(/-/g,"")
  
        let JsonsessionStoreDetails: any =
          window.sessionStorage.getItem("storeDetails");
        console.log(JsonsessionStoreDetails, "sessionStoreDetails1");
        // console.log(atob(JsonsessionStoreDetails),"sessionStoreDetails2")
        let sessionStoreDetails: any;
        try {
          sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
        } catch (e: any) {
          console.log(e, "not json formate");
        }
  
        console.log(sessionStoreDetails, "sessionStoreDetails2");
        if (postalZip?.length != 5 || postalZip == undefined || postalZip == null) {
          zip5 = sessionStoreDetails?.zip?.split("-")[0];
          zip4 = sessionStoreDetails?.zip?.split("-")[1];
          if(zip4?.length!=4){
            zip4='0000'
          }
        }else{
          zip5 = props?.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip
          if(props?.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.plus4?.length==4){
            zip4 = props?.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.plus4
          }else{
            zip4='0000'
          }
          
          
        }
        console.log("sessionStoreDetails?.zip",sessionStoreDetails?.zip)
      

      const formedTaxPayload: any = {
        customerState: props.defaultPaygridvalues.paymentInfo?.customerInfo[0]?.state,
        postalCode:  zip5+"-"+zip4,
        customerId: props?.customerInformation?.customerId,
        apiCall: "",
        dateOfBirth: moment(props.defaultPaygridvalues.paymentInfo.customerInfo[0].dob).format("YYYY-MM-DD"),
        taxRateOnly: "N",
        taxExemptAsOfToday: props.taxExemption=="" ? "N":props.taxExemption,
      };

      formedTaxPayload.storeInputs = [
        {
          taxInputs: taxInputsData,
          storeNumber: String(window.sessionStorage.getItem("storeNumber")),
        },
      ];

      const taxEngineResponse = await getTax(formedTaxPayload);
      setLoadingSave(false);

      

      //Assignment starts here
      for (let i = 0; i < updatedChkGridValue.length; i++) {
        let requiredTaxData;
        let taxEngineLDWTax: any = 0;
        let taxEnginePolicyTax = 0;
        let taxEngineRPayTax = 0;
        let taxEngineLateTax = 0;
        let taxEngineCarryRentTax = 0;
        let taxEngineCarriedLateTax = 0;
        console.log('cvbpgfdocj8r', taxEngineResponse);
        requiredTaxData = taxEngineResponse.data.taxResponse[0].taxOutputs.filter((el) => el.taxOutputId == updatedChkGridValue[i].Agreementid);

        const FilterOnlyRpay = requiredTaxData.filter(
          (el) => el.itemCategory == "RPAY"
        );
        const FilterOnlyLDW = requiredTaxData.filter(
          (el) => el.itemCategory == "LDW"
        );
        const FilterOnlyPolicy = requiredTaxData.filter(
          (el) => el.itemCategory == "RACTIREPAY"
        );
        const FilterOnlyLate = requiredTaxData.filter(
          (el) => el.itemCategory == "LTRPAYFEE"
        );
        const FilterOnlyCarryRent = requiredTaxData.filter(
          (el) => el.itemCategory == "CRPAY"
        );
        const FilterOnlyCarriedLate = requiredTaxData.filter(
          (el) => el.itemCategory == "CLFEEPAY"
        );

        taxEngineLDWTax = FilterOnlyLDW[0].taxAmount;
        taxEnginePolicyTax = FilterOnlyPolicy[0].taxAmount;
        taxEngineRPayTax = FilterOnlyRpay[0].taxAmount;
        taxEngineLateTax = FilterOnlyLate[0].taxAmount;
        taxEngineCarryRentTax = FilterOnlyCarryRent[0].taxAmount;
        taxEngineCarriedLateTax = FilterOnlyCarriedLate[0].taxAmount;

        console.log(
          "taxvaluesdjh",
          taxEngineLDWTax,
          taxEnginePolicyTax,
          taxEngineRPayTax,
          taxEngineLateTax,
          taxEngineCarryRentTax,
          taxEngineCarriedLateTax
        );


        const agreeId = adjustDueRes.filter(
          (x) =>
            String(x.agreementId) === String(updatedChkGridValue[i].Agreementid)
        );
        console.log("sdfgfv vgfdf345 agreeId", agreeId);
        // const agreeType = updatedChkGridValue.filter(
        // (x) => String(x.Agreementid) === String(agreeId[0].agreementId)
        // );
        //
        let nextduedate;
        if (
          agreeId[0].nextDueDate != "SAC" &&
          agreeId[0].nextDueDate != "PIF" &&
          agreeId[0].nextDueDate != "EPO"
        ) {
          nextduedate = moment(agreeId[0].nextDueDate).format("MM/DD/YYYY");
        } else {
          nextduedate = agreeId[0].nextDueDate;
        }
        updatedChkGridValue[i].Nextduedate = nextduedate;
        updatedChkGridValue[i].Amount =
          Number(agreeId[0].policyAmount) +
          Number(agreeId[0].ldwAmount) +
          Number(taxEngineLDWTax) +
          Number(taxEnginePolicyTax);
        updatedChkGridValue[i].Taxamount =
          Number(taxEngineLDWTax) + Number(taxEnginePolicyTax);
        updatedChkGridValue[i].policyAmount = Number(agreeId[0].policyAmount);
        updatedChkGridValue[i].ldwTaxAmount = Number(taxEngineLDWTax);
        updatedChkGridValue[i].agrLdwAmount = Number(agreeId[0].ldwAmount);
        updatedChkGridValue[i].policyTaxAmount = Number(
          taxEnginePolicyTax
        );
        updatedChkGridValue[i].agreementRate = 0.0; // agreementRate
        updatedChkGridValue[i].rpayTaxAmount = 0.0;
        updatedChkGridValue[i].PromoFreeDaysApplied =
          freeTimeData.type == "PROMO"
            ? Number(days_difference)
            : updatedChkGridValue[i].PromoFreeDaysApplied;
        updatedChkGridValue[i].Latelast10 = 0.0;
        updatedChkGridValue[i].lateFee = 0.0;
        updatedChkGridValue[i].carryRentTaxUsed = 0.0;
        updatedChkGridValue[i].lFeeTaxAmount = 0.0;
        updatedChkGridValue[i].carryRentAmountUsed = 0.0;
        updatedChkGridValue[i].FreeTime = {
          freeTimeType: freeTimeData.type,
          freeTimeDays: Number(days_difference).toFixed(),
          isOptServiceWaived:
            freeTimeData.waiveLDW || freeTimeData.waivePolicy ? true : false,
        };
        //updatedChkGridValue[i].Agreementtype=agreeType[0].Agreementtype=="Club"?"Club":agreeId[0].agreementType
      }
      //Assignment ends here
      //const finadjust: any = [];
      //let totalAmount = 0;
      //let totalTaxAmount = 0;
      //for (let index = 0; index < adjustDueRes.length; index++) {
      //totalAmount += Number(adjustDueRes[index].totalAmount);
      //totalTaxAmount += Number(adjustDueRes[index].totalTax);
      // if (
      //   adjustDueRes[index].nextDueDate != "SAC" &&
      //   adjustDueRes[index].nextDueDate != "PIF" &&
      //   adjustDueRes[0].nextDueDate != "EPO"
      // ) {
      //   const MyDate = new Date(adjustDueRes[0].nextDueDate);
      //   const nextDueDateFormate =
      //     MyDate.getFullYear() +
      //     "-" +
      //     ("0" + (MyDate.getMonth() + 1)).slice(-2) +
      //     "-" +
      //     ("0" + MyDate.getDate()).slice(-2);
      //   setnextDueDate(nextDueDateFormate);
      // } else {
      //   finadjust.push(
      //     `Adjusted AgreementNumber # ${adjustDueRes[index].agreementNumber} - will be ${adjustDueRes[index].nextDueDate} `
      //   );
      // }
      //}
      //setfinalAdjustedArray(finadjust);
      //setcalciRes(adjustDueRes);
      //settotalAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
      //setresAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
      // Calculation call ends here
    }

    console.log("dfvfd 55updatedGridValue", updatedChkGridValue);
    //setpaygridvalues(updatedGridValue);
    setopenFreeTime(false);
    setfreeTimeData(freeTimeObj); // Used to restore default value in popup
    props.response({ resArray: updatedChkGridValue }); // Manipulate the grid values
    //setmasterLoader(false); // Make the loader disappear
    props.resetExtention();
    //setcarryRentAmount("0.00");
    //multipleAdjust();
    //AgreementNumber
  }; // This method is responsible for calculation and value change in the grid

  const optionsArrayFunction = () => {
    console.log("checking call of vdjsvbhd", purePromoType);
    let bindData;
    // If the choosen agr has atleast one promo agr then it enters into  IF
    if (EPPSelected) {
      bindData = EPPFreeTimeType;
    } else if (partialPromo) {
      bindData = freeTimeType;
    } else {
      bindData = freeTimeTypeWOPromo;
    } // If none of the choosen agr has promo then else will be invoked
    const {
      isRegularFreeTimeAllowed,
      isServiceFreeTimeAllowed,
      isRegExtFreeTimeAllowed,
      isPromoFreeTimeallowed,
    } = props.configData;
    const filteredConfigs: any = [
      { paramValue: isRegularFreeTimeAllowed },
      { paramValue: isServiceFreeTimeAllowed },
      { paramValue: isRegExtFreeTimeAllowed },
      { paramValue: isPromoFreeTimeallowed },
    ];
    const finalData = bindData.filter(
      (obj: any, index: any) => filteredConfigs[index].paramValue === 1
    );
    console.log("cfdsfr3wer43ewdfrefinalData", finalData);
    bindedTypeData = finalData;
    if (finalData !== undefined) {
      return finalData.map((entry) => {
        return { label: entry.description, value: entry.refCode };
      });
    }
  }; // This method is used to bind the drop down value in free time popup
  const handleDropDown = (e: any) => {
    if (e.target.value === "PROMO") {
      if (purePromoType) {
        setagreementTypeData(agreementTypeObj);
        setfreeTimeData({
          ...freeTimeData,
          nextDueDate: "",
          waiveLDW: true,
          waivePolicy: true,
          type: e.target.value,
        }); // Store the choosen DD value
        setmaxDaysLimit(props.receivedCheckedAgr[0].PromoFreeDays); // Set the max days limit in next due date
      } else if (completePromo) {
        setpromoSingleAgreementMessage(
          "Please choose the agreements of same Promo Free Days"
        ); // Set the Promo Error message
        setagreementTypeData(agreementTypeObj);
        setfreeTimeData({
          ...freeTimeData,
          nextDueDate: "",
          waiveLDW: true,
          waivePolicy: true,
          type: e.target.value,
        }); // Store the choosen DD value
        setmaxDaysLimit(0); // Set the max days limit in next due date
      } else {
        const getAgrNumber = props.receivedCheckedAgr.filter(
          (el) => el.PromoFreeDays !== 0
        );
        console.log("sdcfdesxgetAgrNumber34", getAgrNumber);

        setfreeTimeData({
          ...freeTimeData,
          nextDueDate: "",
          waiveLDW: true,
          waivePolicy: true,
          type: e.target.value,
        }); // Store the choosen DD value
        setagreementTypeData(agreementTypeObj);
        setpromoSingleAgreementMessage(
          `Promo free time can be applied only for the agreement #${getAgrNumber[0].AgreementNumber}`
        ); // Set the Promo Error message
        setmaxDaysLimit(0); // Set the max days limit in next due date
      }
    } else if (e.target.value === "FREAMT" || e.target.value === "SERVFREETM") {
      //setagreementTypeData({ LDW: true, Policy: false });
      if (selectedAgreementTypeData.LDW === true) {
        setagreementTypeData({ LDW: true, Policy: false });
      } else if (selectedAgreementTypeData.Policy === true) {
        setagreementTypeData({ LDW: false, Policy: true });
      }
      setpromoSingleAgreementMessage(""); // Set the Promo Error message
      setmaxDaysLimit(30); // Set the max days limit in next due date
      setfreeTimeData({
        ...freeTimeData,
        nextDueDate: "",
        type: e.target.value,
        waiveLDW: false,
        waivePolicy: false,
      }); // Store the choosen DD value
      //Need to add logic for enable disable and check and uncheck check box here
    } else if (e.target.value === "RETEXTAMT") {
      //setagreementTypeData({ LDW: true, Policy: false });
      if (selectedAgreementTypeData.LDW === true) {
        setagreementTypeData({ LDW: true, Policy: false });
      } else if (selectedAgreementTypeData.Policy === true) {
        setagreementTypeData({ LDW: false, Policy: true });
      }
      setpromoSingleAgreementMessage(""); // Set the Promo Error message
      setmaxDaysLimit(35); // Set the max days limit in next due date
      setfreeTimeData({
        ...freeTimeData,
        nextDueDate: "",
        type: e.target.value,
        waiveLDW: false,
        waivePolicy: false,
      }); // Store the choosen DD value
      //Need to add logic for enable disable and check and uncheck check box here
    }
  }; // This method is invoked when drop down value changes
  const buildFreeTime = () => {
    console.log("fdd sdcd checkedAgr dcv", props.receivedCheckedAgr);
    // const MyDate = new Date(props.receivedCheckedAgr[0]?.CurrentDueDate);
    // const currentduedate =
    //   MyDate.getFullYear() +
    //   "-" +
    //   ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    //   "-" +
    //   ("0" + MyDate.getDate()).slice(-2);
    const currentduedate = moment(props.receivedCheckedAgr[0]?.CurrentDueDate).format('YYYY-MM-DD');
    // const calculatedNextDueDate = MyDate.setDate(MyDate.getDate() + 30);
    // console.log('dvfdc calculatedNextDueDate', calculatedNextDueDate);
    const calculatNextDueDate = new Date(freeTimeData.nextDueDate);
    const calculatedNextDueDate =
      calculatNextDueDate.getFullYear() +
      "-" +
      ("0" + (calculatNextDueDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + calculatNextDueDate.getDate()).slice(-2);
    console.log(" jdcbvcb csd calculatedNextDueDate", calculatedNextDueDate);

    // const currentduedate = checkedAgr[0]?.CurrentDueDate.getFullYear() + '-' + ('0' + (checkedAgr[0]?.CurrentDueDate.getMonth() + 1)).slice(-2) + '-'
    // + ('0' + (checkedAgr[0]?.CurrentDueDate.getDate())).slice(-2)
    // ;
    return (
      <Grid>
        <Grid
          className={
            props.cappedFreeTimeValues.enableCappedFreeTime
              ? `${classes.style1}`
              : `${classes.style2}`
          }
        >
          {props.receivedCheckedAgr.length === 1 ? (
            <Card
              className={`${classes.racSkyBlue} ${classes.bluecards} `}
              style={{
                padding: "10px 10px",
                backgroundColor: "#EFF4FF",
                width: "39%",
              }}
            >
              <Typography
                style={{
                  float: "left",
                  color: "#2279fd",
                  fontSize: "14px",
                  fontFamily: "opensans-bold",
                }}
              >
                Agreement # : {props.receivedCheckedAgr[0]?.AgreementNumber}
              </Typography>
            </Card>
          ) : null}
          {props.cappedFreeTimeValues.enableCappedFreeTime ? (
            <Card
              className={`${classes.racSkyBlue} ${classes.bluecards} `}
              style={{
                padding: "10px 10px",
                backgroundColor: "#EFF4FF",
                width: "57%",
                marginLeft: "4%",
              }}
            >
              <Typography
                style={{
                  float: "left",
                  color: "#2279fd",
                  fontSize: "14px",
                  fontFamily: "opensans-bold",
                }}
              >
                Store Remaining Balance :{" "}
                {freeTimeValidation.remainingAmount > 0
                  ? `$ ${freeTimeValidation.remainingAmount.toFixed(2)}`
                  : "$0.00"}
              </Typography>
            </Card>
          ) : null}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid
              md={12}
              style={{ display: "flex", flexWrap: "wrap" }}
              className={classes.margincard}
            >
              <RACDatePicker
                value={currentduedate}
                label="Current Due Date"
                name="currentDueDate"
                disabled={true}
              />
            </Grid>
            {props.cappedFreeTimeValues.enableCappedFreeTime ? (
              <Grid style={{ padding: "19px" }}></Grid>
            ) : null}
            <Grid className={classes.racSelectStyle}>
              <RACSelect
                inputLabel="Type"
                options={optionsArrayFunction()}
                isDisabled={bindedTypeData?.length === 0 ? true : false}
                defaultValue={freeTimeData.type}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleDropDown(e)
                }
                {...(bindedTypeData?.length === 0 && {
                  errorMessage: "Please choose a valid Agreement #",
                })}
              />
              <Grid
                className={`${classes.w100} ${classes.wrapword} ${classes.racLoginAlert}`}
              >
                <Typography>{promoSingleAgreementMessage}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              md={12}
              style={{ display: "flex", flexWrap: "wrap" }}
              className={classes.margincard}
            >
              <RACDatePicker
                value={freeTimeData.nextDueDate}
                required={true}
                label="Next Due Date"
                onChange={(e: any) => {
                  const minDate = moment(currentduedate)
                    .add(0, "d")
                    .format("YYYY-MM-DD");
                  const maxDate = moment(currentduedate)
                    .add(Number(maxDaysLimit) + 1, "d")
                    .format("YYYY-MM-DD");
                  console.log("e logger in chenge", e);
                  console.log("minDate logger in chenge", minDate);
                  console.log("maxDate logger in chenge", maxDate);

                  if (
                    moment(e, "YYYY-MM-DD").isAfter(minDate) &&
                    moment(e, "YYYY-MM-DD").isBefore(maxDate)
                  ) {
                    console.log("reached if of the change");
                    setIsInvalidDDSelected(false);
                  } else {
                    console.log("reached else of the change");
                    setIsInvalidDDSelected(true);
                  }
                  setfreeTimeData({
                    ...freeTimeData,
                    nextDueDate: e,
                  });
                }}
                inputProps={{
                  min: moment(currentduedate).add(1, "d").format("YYYY-MM-DD"),
                  max: moment(currentduedate)
                    .add(Number(maxDaysLimit), "d")
                    .format("YYYY-MM-DD"),
                }}
                {...(isInvalidDDSelected && {
                  errorMessage: "Please choose a valid due date",
                })}
              />
            </Grid>
            {props.cappedFreeTimeValues.enableCappedFreeTime ? (
              <Grid
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Typography
                  style={{
                    float: "left",
                    fontSize: "14px",
                    fontFamily: "opensans-bold",
                  }}
                >
                  Amount Used :{" "}
                  <span
                    className={
                      freeTimeValidation.remainingAmount >=
                        aproxFreeTimeAmount || isNaN(aproxFreeTimeAmount)
                        ? `${classesTwo.blackColor}`
                        : `${classesTwo.redColor}`
                    }
                    style={{
                      fontSize: "14px",
                      fontFamily: "opensans-bold",
                    }}
                  >
                    {!isNaN(aproxFreeTimeAmount)
                      ? `$${aproxFreeTimeAmount}`
                      : `$0.00`}
                  </span>
                </Typography>
              </Grid>
            ) : null}
            <Grid
              md={12}
              className={
                props.cappedFreeTimeValues.enableCappedFreeTime
                  ? `${classes.margincard} ${classes.flexStyle2}`
                  : `${classes.margincard} ${classes.flexStyle1}`
              }
            >
              <RACCheckBox
                size="medium"
                onChange={(e) => {
                  setfreeTimeData({
                    ...freeTimeData,
                    waiveLDW: e.target.checked,
                  });
                }}
                checked={freeTimeData.waiveLDW}
                disabled={agreementTypeData.LDW === true ? false : true}
                label="Waive LDW"
              />
              <RACCheckBox
                size="medium"
                onChange={(e) => {
                  setfreeTimeData({
                    ...freeTimeData,
                    waivePolicy: e.target.checked,
                  });
                }}
                checked={freeTimeData.waivePolicy}
                disabled={agreementTypeData.Policy === true ? false : true}
                label="Waive Policy"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="outlined"
            name="clearCancel"
            data-bs-toggle="modal"
            style={{
              backgroundColor: "white",
              color: "#2C3333",
            }}
            onClick={() => cancelClicked()}
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            //name="De_ActivateClubbtn"
            //data-bs-toggle="modal"
            onClick={() => {
              //setopenFreeTime(false);
              setCappedFreeTimeStarted(true);
              setLoadingSave(true);
              if (props.cappedFreeTimeValues.enableCappedFreeTime) {
                acceptFeeTimeCommonModule("Auth");
              } else {
                setCappedFreeTimeStarted(false);
                authFreeTime(false);
              }
            }}
            disabled={disableSubmit || loadingSave || isInvalidDDSelected}
            loading={loadingSave}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // This method will be rendering the free time popup

  const freeTimeAuthPopupContent = () => {
    return(
      <Grid className={`${classes.textCenter}`}>
        <AlertIcon/>
        <Typography className={`${classesTwo.spacerMT3} ${classesTwo.customerRacpopup1}`}>
          Allocated free time for this store has been exceeded. Do you still want to proceed ? 
        </Typography>
        <Grid className={`${classesTwo.buttonsPadding}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classesTwo.customerFloatleft}`}
            onClick={() => {
              setLoadingSave(false);
              setFreeTimeAuthPopup(false);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            color="primary"
            variant="contained"
            className={`${classesTwo.customerFloatright}`}
            onClick={() => {
              setProceedButtonLoader(true);
              setCappedFreeTimeSelected(true);
              authFreeTime(true) 
            }}
            loading={proceedButtonLoader} 
          >
            Proceed
          </RACButton>
        </Grid>
      </Grid>
    )
  }

  const authFreeTime = async(cappedFreeTimeAuthFlag: boolean) => {
    if (
      freeTimeData.type == "SERVFREETM" ||
      freeTimeData.type == "RETEXTAMT" ||
      cappedFreeTimeAuthFlag
    ) {
        setneedFreeTimeAuth(true);
    } else {
      acceptFreeTimeRedirect();
    }
  }

  return (
    <>
      {/* {masterLoader ? (
        <Grid className={`${classes.masterLoader} ${classes.Loader}`}>
          <CircularProgress />
        </Grid>
      ) : null} */}
      <RACButton
        className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
        color="primary"
        variant="contained"
        disabled={disableFreeTime || props.disableFreeTimeForTransfer}
        onClick={() => setopenFreeTime(true)}
        // {text('ContainedButton Label', 'hover over me')}
      >
        {"Free Time"}
      </RACButton>
      {needFreeTimeAuth ? (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setCappedFreeTimeStarted(false);
            setneedFreeTimeAuth(false);
            cancelClicked();
            setLoadingSave(false);
            setpromoSingleAgreementMessage("");
            setProceedButtonLoader(false);
            setFreeTimeAuthPopup(false);
          }}
          setTwoFactorCompleted={() => {
            setCappedFreeTimeStarted(false);
            setneedFreeTimeAuth(false);
            setProceedButtonLoader(false);  
            setFreeTimeAuthPopup(false);
            setCappedFreeTimeSelected(cappedFreeTimeSelected ? true : false);
            acceptFreeTimeRedirect();
          }}
          moduleName={EPPSelected ? 'RET EXT EPP' : cappedFreeTimeSelected ? 'CappedFreeTime' : 'ApplyFreeTime'}
          setApprover={cappedFreeTimeSelected ? true : false}
          CurrentRole={CurrentRole}
        ></SecondFactor>
      ) : null}
      {openFreeTime ? (
        <RACModalCard
          isOpen={openFreeTime}
          closeIcon={true}
          borderRadius={"25px !important"}
          onClose={() => cancelClicked()}
          maxWidth={"sm"}
          title={"Free Time"}
          //eslint-disable-next-line react/no-children-prop
          children={buildFreeTime()}
        />
      ) : null}
      {freeTimeAuthPopup ? (
        <RACModalCard
          isOpen={freeTimeAuthPopup}
          closeIcon={false}
          borderRadius={"25px !important"}
          onClose={() => setFreeTimeAuthPopup(false)}
          maxWidth={"xs"}
          //eslint-disable-next-line react/no-children-prop
          children={freeTimeAuthPopupContent()}
        />
      ) : null}
    </>
  );
}

// export default FreeTime;

/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-debugger */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */

import React, { useEffect, useState, useContext } from 'react';
import {
  makeStyles,
  Card,
  Grid,
  Typography,
  CardContent,
  RACButton,
  RACModalCard,
  RACSelect,
  RACTextbox,
  ListItem,
  List,
  RACCheckBox,
  CircularProgress,
  RACRadio,
  RACInfoIcon,
} from '@rentacenter/racstrap';
import { ReactComponent as Alerticon } from '../../assets/images/No-records.svg';
import { CustomPropInterface } from '../../index';
// import { ReactComponent as SuccessElmt } from '../../assets/images/success-icon.svg'
import { GlobalStyles } from '../../stylesJS/global';
import { ContainerContext } from '../../app/App';
import {
  UserStateContext,
  UserThunksContext,
} from '../../context/user/user-contexts';
// import { formStyles } from '../ReactComponents/ComponentStyles/formstyles'
// import { ReactComponent as Info } from '../../assets/images/Info-icon.svg'
import moment from 'moment';
import {
  CellActive,
  Calculate911Fee,
  StoreSearch,
  getmenuandstore,
  GetStatsStatus,
  GetCashSaleProvider,
  getTax,
} from '../../api/user';
import clsx from 'clsx';
import Modal from 'react-modal';
import el from 'date-fns/esm/locale/el/index.js';
import { number } from 'yup';
export interface dropdown {
  label: string;
  value: string;
  paydayid?: number;
}
// import v4 from 'react-v4'
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useParams } from 'react-router-dom';
import SecondFactor from './CashSalePinPopup';
// import { UserStateContext } from '../../context/user/user-contexts'
//UI
const useClasses = makeStyles((theme: any) => ({
  borderRed: {
    border: '1px solid red',
    borderRadius: '5px',
  },
  mtop30: {
    marginTop: '30px',
  },
  mtop20: {
    marginTop: '20px',
  },
  w100: {
    width: '100%',
  },
  loaderStyle: {
    border: 'none',
    backgroundColor: 'transparent',
    // userFocus: 'none',
    // MozUserFocus: 'none',
    outline: 'none',
  },
  RACLoaderPage: {
    textAlign: 'center',
    marginTop: '250px',
    fontSize: '16px',
    marginLeft: '30px',
    marginRight: '30px',
    outline: 'none',
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  title: {
    color: '#212529',
    fontFamily: 'OpenSans-bold!important',
    fontSize: '28px!important',
    marginBottom: '10px!important',
  },
  title1: {
    color: '#212529',
    fontFamily: 'OpenSans-bold!important',
    fontSize: '19px!important',
    marginBottom: '10px!important',
  },
  titleColor: {
    color: 'black',
    marginLeft: '10px',
    marginTop: '15px',
  },
  titleColor1: {
    color: 'black',
    marginLeft: '3px',
    marginTop: '14px',
  },
  infoTextStyle: {
    color: 'gray',
    fontSize: theme.typography.pxToRem(13),
    textAlign: 'center',
  },
  my4: {
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(24),
  },
  mb5: {
    marginBottom: theme.typography.pxToRem(48),
  },
  pt2: {
    paddingTop: theme.typography.pxToRem(8),
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  cardHeight: {
    height: ' 5.9375rem',
  },
  cardHeight1: {
    height: 'auto',
    marginTop: '26px',
  },
  headtag: {
    marginTop: '17px',
    marginLeft: '19px',
  },
  fixedFooter: {
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(16),
    backgroundColor: '#ffffff',
    boxShadow: '0 0 3px #d9d9d9',
  },
  ml3: {
    marginLeft: theme.typography.pxToRem(16),
  },
  p2: {
    padding: theme.typography.pxToRem(8),
  },
  modalBody: {
    position: 'relative',
    flex: '1 1 auto',
    padding: '1rem',
    width: '98%',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    pointerEvents: 'auto',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    outline: '0',
  },
  me2: {
    marginRight: '0.5rem !important',
    marginLeft: '10rem',
  },
  floatStart: {
    float: 'left',
  },
  floatStart1: {
    float: 'left',
    '& input': {
      height: '24px',
    },
  },
  mb3: {
    marginBottom: theme.typography.pxToRem(16),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    //eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: '14px',
    marginLeft: '10px',
  },
  textCenter: {
    textAlign: 'center',
  },
  px3: {
    paddingRight: '1rem !important',
    paddingLeft: '1rem !important',
  },
  pb4: {
    paddingBottom: '1.5rem !important',
  },
  colMd12: {
    flex: '0 0 auto',
    width: '100%',
  },
  center: {
    textAlign: 'center',
    margin: '12px',
  },
  right: {
    marginRight: '10px',
  },
  right1: {
    marginRight: '15px',
  },
  equal: {
    width: '55%',
    marginRight: '15px',
    marginBottom: '15px',
  },
  equal1: {
    width: '55%',
    marginRight: '15px',
    marginBottom: '15px',
    paddingBottom: '25px',
  },
  leftpadding: {
    paddingLeft: '9.5rem',
  },
  heading: {
    fontSize: '20px',
  },
  tittles: {
    marginTop: '10px',
    marginBottom: '7px',
  },
  tittle1: {
    marginTop: '10px',
    marginBottom: '7px',
    // padding: '10px !important'
    '& input': {
      padding: '10px !important',
    },
  },
  dropdown1: {
    '& div': {
      padding: '10px 9px',
    },
  },
  tittles1: {
    marginTop: '10px',
    marginBottom: '7px',
  },
  textstyle: {
    marginRight: '10px',
    marginBottom: '25px',
  },
  textstyle1: {
    marginRight: '25px',
    marginTop: '25px',
  },
  dollartext1: {
    width: '87% !important',
    '& input': {
      height: '21px',
    },
    // width: '13% !important',
    // padding: '6px 14px !important',
    // marginBottom: '25px !important',
  },
  marginTop: {
    marginTop: '5px',
  },
  textcolor: {
    color: 'blue',
  },
  cardborder: {
    border: '0 !important',
  },
  BreadCrumbInactive: {
    '&:after': {
      content: "''",
      width: 0,
      height: 0,
      borderTop: '5px solid transparent',
      borderLeft: '10px solid #555',
      borderBottom: '5px solid transparent',
    },
  },
  marginBottom: {
    marginBottom: '10px !important',
  },
  marginBottom1: {
    marginBottom: '10px !important',
    '& input': {
      padding: '10px',
    },
  },
  marginright: {
    marginRight: '20px',
  },
  typeMargin: {
    padding: '1px',
    '& input': {
      height: '24px',
    },
  },
  screenTop: {
    marginTop: '22px',
  },
  imgStyle: {
    paddingBottom: '10px',
  },
  outlinedBtn: {
    padding: '0.7rem 2rem',
  },
  zipCodeClass: {
    '& div': {
      '& div': {
        '& div': {
          '& input': {
            padding: '10px',
          },
        },
      },
    },
  },
  marginCustName: {
    marginTop: '10px',
    color: '#2179FE',
  },
  marginCustID: {
    marginTop: '10px',
    color: 'gray',
  },
  paddingCustname: {
    padding: '10px',
  },
  customerToolbar: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1030,
    backgroundColor: 'white',
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
  },
  fixedBottom: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1030,
    backgroundColor: 'white',
    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
    padding: '1rem',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  me1: {
    marginRight: theme.typography.pxToRem(4),
  },
  foc: {
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '1px 1px 6px 3px #80bdff',
    },
  },
  racFixedFooterMargin: {
    marginBottom: '120px',
  },
  spacerMT4: {
    marginTop: theme.typography.pxToRem(24),
  },
  RACPOPMsg: {
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  formLabelInline: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    display: 'inline-block',
    paddingLeft: 0,
  },
  custDetailSection: {
    display: 'flex',
    marginLeft: '20px',
    width: '50%',
    justifyContent: 'space-around',
  },
  racInfoStyle: {
    marginLeft: '5px',
    position: 'absolute',
  },
}));
const providers_checklist = [
  'Cricket Multi Line',
  'Cricket Add A Line',
  'SImple Mobile Sim RTR Activation',
  'SImple Mobile Port In RTR Activation',
  'Simple Mobile RTR',
];
const accountNum = [
  'AccNum1',
  'AccNum2',
  'AccNum3',
  'AccNum4',
  'AccNum5',
  'AccNum6',
  'AccNum7',
  'AccNum8',
  'AccNum9',
  'AccountNum',
];
const accountPass = [
  'AccountPas',
  'AccPas1',
  'AccPas2',
  'AccPas3',
  'AccPas4',
  'AccPas5',
  'AccPas6',
  'AccPas7',
  'AccPas8',
  'AccPas9',
];
export default function NonInventorySales(props?: any) {
  // const UserStateContextData = useContext(UserStateContext);
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const UserStateContextData = useContext(UserStateContext);
  const UserThunksContextData = useContext(UserThunksContext);
  /* eslint-disable no-console */
  console.log(`UserStateContextData`, UserStateContextData);
  console.log(`UserThunksContextData`, UserThunksContextData);
  const [coWorkerRole, setcoWorkerRole] = useState<any>();
  const history = useHistory();
  const classes = useClasses();
  const classes1: any = GlobalStyles();
  // const formclass = formStyles();
  // const [successpopup, setsuccesspopup] = useState(false);
  const [customerDetail, setCustomerDetail] = useState<any>();
  const [sessionId, setSessionId] = useState<any>();
  const [apiCalc, setapiCalc] = useState<any>(0);
  const [blurLoaderActive, setblurLoaderActive] = useState<any>(false);
  const [productNotFoundpopup, setproductNotFoundpopup] = useState<any>(false);
  const [productNotFoundMsg, setproductNotFoundMsg] = useState<any>('');
  const [isFeildValid, setisFeildValid] = useState<any>(true);
  const [errorBorder, setErrorBorder] = useState<any>({});
  const [isMobileNumberEnabled, setisMobileNumberEnabled] = useState(false);
  const initialTXtValues = {
    type: '',
    phoneNumber: '',
    provider: '',
    salesPrice: '',
    salesTax: '',
    fee: '',
    totalSales: '',
    product: '',
    // ratePlan: '',
    // SIMNumber: '',
    // IMEI: '',
    // firstName: '',
    // lastName: '',
    // zipCode: '',
    // salesPrice: '',
    // salesTax: '',
    // fee: '',
    // monthsNum: '',
    // totalSales: ''
  };
  const [initialObj, setInitialObj] = useState<any>(initialTXtValues);

  const [customDataObj, setCustomDataObj] = useState<any>({});
  const [customDataReqObj, setCustomDataReqObj] = useState<any>([]);
  const [hideDiv, setHideDiv] = useState(false);
  const [epayResponseSeq0, setEpayResponseSeq0] = useState<any>();
  const [epayResponseSeq1, setEpayResponseSeq1] = useState<any>();
  const [epayResponseSeq2, setEpayResponseSeq2] = useState<any>();
  const [epayResponseSeq3, setEpayResponseSeq3] = useState<any>();
  const [submitBtnEnabled, setsubmitBtnEnabled] = useState<any>(true);

  const [requireAdditionalPir, setrequireAdditionalPir] = useState<any>(true);
  const [api0, setapi0] = useState<any>(false);
  const [api1, setapi1] = useState<any>(false);
  const [api2, setapi2] = useState<any>(false);
  const [api3, setapi3] = useState<any>(false);
  const [apipayload, setapipayload] = useState<any>();
  const [saleId, setSaleId] = useState<any>();
  const [apiresponse, setapiresponse] = useState<any>([]);
  const [apiEnabled, setapiEnabled] = useState<any>(false);
  const [isContinueBtnHidden, setisContinueBtnHidden] = useState<any>(false);
  const [allseqData, setallseqData] = useState<any>([]);
  const [terminalId, setTerminalId] = useState<any>('');
  const [amountFieldEnabled, setamountFieldEnabled] = useState<any>(false);
  const [amountVal, setamountVal] = useState<any>();
  const [editItemIdFlag, seteditItemIdFlag] = useState(false);
  const [amountValue, setamountValue] = useState<any>('');
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState();
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [configData, setConfigData] = useState<any>();
  const [amountRange, setamountRange] = useState<any>();
  const [amountEnabled, setamountEnabled] = useState(false);
  const [providerList, setProviderList] = useState<any>();
  const [carrierList, setcarrierList] = useState<any>();
  const [productList, setproductList] = useState<any>();
  const [productMinValue, setproductMinValue] = useState('');
  const [productMaxValue, setproductMaxValue] = useState('');
  const [isEditData, setisEditData] = useState(false);
  const [termsAndCondition, settermsAndCondition] = useState<any>('');
  const [istermsAndConditionCheck, setistermsAndConditionCheck] =
    useState(false);
  const [carrierName, setCarrierName] = useState('');
  const [requiredPir, setrequiredPir] = useState(true);
  const [productTypeValue, setproductTypeValue] = useState('');
  const [amountValueParam, setamountValueParam] = useState<any>(0);
  const [multiplierFields, setmultiplierFields] = useState<any>({
    valueMultiplier: 0,
    discountMultiplier: 0,
    feeMultiplier: 0,
    serviceFee: 0,
    consumerDiscount: 0,
  });
  //  const [ProviderOption,setProviderOption] = useState([]);

  useEffect(() => {
    console.log('Hi from UUID', uuidv4());
    console.log('Hi from UUID2', uuidv4());
    console.log('cash sale props 3', props);
    setSessionId(uuidv4());
    setSaleId(uuidv4());
    const datee = new Date();
    const dateconcept = `${moment(datee).format('YYYY-MM-DD')}T${moment(
      datee
    ).format('hh:mm:ss A')}`;
    console.log('Hi from local time', dateconcept);
    setCustomerDetail(props?.CustomerDetail);
    const PageLoad = async () => {
      const storeNumber = window.sessionStorage.getItem('storeNumber');
      const response = await StoreSearch(storeNumber);
      console.log(
        'Hi fro useeeee',
        Number(response?.data?.response[0]?.ePayTerminalId)
      );
      setTerminalId(response?.data?.response[0]?.ePayTerminalId);
    };
    const getCoWorkerRole = async () => {
      try {
        if (containerData !== undefined) {
          /* eslint-disable no-console */
          console.log('containerData', containerData);
          /* eslint-disable no-console */
          console.log('containerDataRole', containerData.GetEmployeeId());
          const empId = containerData.GetEmployeeId();
          setcoWorkerRole(empId);
        } else {
          const coWorkerRoleObj: any = await getmenuandstore();
          console.log('containerDataRole coWorkerRoleObj', coWorkerRoleObj);

          if (
            coWorkerRoleObj?.coworkerProfile !== undefined &&
            coWorkerRoleObj?.coworkerProfile !== null &&
            coWorkerRoleObj?.coworkerProfile.employeeId !== undefined &&
            coWorkerRoleObj?.coworkerProfile.employeeId !== null
          ) {
            const roleObj = coWorkerRoleObj?.coworkerProfile?.employeeId;
            // setcoWorkerRole(roleObj);
            setcoWorkerRole(roleObj);
          }
        }
      } catch (e: any) {
        /* eslint-disable no-console */
        console.log('Error in co worker id');
      }
    };
    PageLoad();
    getCoWorkerRole();
  }, []);

  useEffect(() => {
    const PageLoad = async () => {
      const storeNumber = window.sessionStorage.getItem('storeNumber');
      // const response = await GetReference(storeNumber)
      // setProvider(response?.data?.cashSaleType)
    };
    PageLoad();
  }, []);

  useEffect(() => {
    if (amountFieldEnabled) {
      if (amountVal == '') {
        setisFeildValid(true);
      }
    }
  }, [amountFieldEnabled]);

  useEffect(() => {
    if (props && props.editItemInfo && props.editItemInfo.data) {
      console.log('Initial Loader');
    } else {
      const PageLoad = async () => {
        // const payload = {
        //     storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        //     requestParams: [{
        //         paramGroupName: 'PricePlanLimit',
        //         paramKeyName: '',
        //         paramCategoryName: ''
        //     }]
        // };
        // setblurLoaderActive(true)
        // const response = await GetStatsStatus(payload)
        // setblurLoaderActive(false)
        // setConfigData(response?.data?.storeProfileResponse?.configDetails[0]?.configDetails)
        let listResponse = await GetCashSaleProvider();

        listResponse =
          listResponse.data !== undefined ? listResponse.data : listResponse;
        setProviderList(listResponse);
        let carrierList: any = [
          {
            label: 'Select',
            value: '0',
          },
        ];

        let productList: any = [
          {
            label: 'Select',
            value: '0',
          },
        ];
        if (
          listResponse !== undefined &&
          listResponse.provider !== undefined &&
          listResponse.productType !== undefined
        ) {
          listResponse.provider.forEach((value: any, index: any) => {
            let carrier = {
              label: value,
              value: (index + 1).toString(),
            };
            carrierList.push(carrier);
          });
          listResponse.productType.forEach((value: any, index: any) => {
            let product = {
              label: value,
              value: (index + 1).toString(),
            };
            productList.push(product);
          });
          console.log('carrierList', carrierList);
          setcarrierList(carrierList);
          setproductList(productList);
        }
        setblurLoaderActive(false);
        // setamountRange(response?.data?.storeProfileResponse?.configDetails[0]?.configDetails[0]?.paramValue)
      };
      PageLoad();
    }
  }, []);

  useEffect(() => {
    if (TwoFactorCompleted === true) {
      setblurLoaderActive(false);
      setSecondFactorOpen(false);
      setHideDiv(true);
    } else if (TwoFactorCancelClick === true) {
      setblurLoaderActive(false);
      setSecondFactorOpen(false);
      setInitialObj({
        ...initialObj,
        type: '',
        phoneNumber: '',
        provider: '',
        salesPrice: '',
        salesTax: '',
        fee: '',
        totalSales: '',
        product: '',
      });
      setHideDiv(false);
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TwoFactorCancelClick, TwoFactorCompleted, EmployeeDetails]);

  useEffect(() => {
    console.log('cash sale props 1', props);
    if (props && props.editItemInfo && props.editItemInfo.data) {
      setisEditData(true);
      console.log('inside if', props);
      populateResponseStruct(props.editItemInfo, props?.editItemId);
    }
    if (props?.customerInfo) {
      setblurLoaderActive(false);
    } else {
      setblurLoaderActive(true);
    }
  }, [props]);
  useEffect(() => {
    console.log('cash sale props 2', props);
    validateFeilds('');
  }, [customDataObj, apiresponse]);
  const checkPermissionForCusRed = (customerId: any) => {
    const currentUser: any = UserStateContextData;
    // eslint-disable-next-line no-console
    console.log(`outside if agreement`, currentUser);
    if (
      !currentUser.error &&
      currentUser?.user?.permissions?.NAV_MENU.length > 0 &&
      currentUser?.user?.permissions?.NAV_MENU.includes('RPDCUST')
    ) {
      // eslint-disable-next-line no-console
      console.log(`inside agreement if condition`, customerId);
      history.push(`/customer/update/${customerId}/customer`);
    }
  };
  function populateResponseStruct(responseStruct: any, editItemId: any) {
    setHideDiv(true);

    console.log('Populate Response Struct', responseStruct);
    if (responseStruct && responseStruct.apiCalc > 0) {
      setallseqData(responseStruct?.allSeqData);
      setHideDiv(true);
      setrequireAdditionalPir(false);
      setapiEnabled(true);
      const listResponse = responseStruct.DropDownData;
      setProviderList(listResponse);

      let carrierList: any = [{ label: 'Select', value: '0' }];

      let productList: any = [{ label: 'Select', value: '0' }];

      if (
        listResponse !== undefined &&
        listResponse.provider !== undefined &&
        listResponse.productType !== undefined
      ) {
        listResponse.provider.forEach((value: any, index: any) => {
          let carrier = {
            label: value,
            value: (index + 1).toString(),
          };
          carrierList.push(carrier);
        });

        listResponse.productType.forEach((value: any, index: any) => {
          let product = {
            label: value,
            value: (index + 1).toString(),
          };
          productList.push(product);
        });

        console.log('carrierList', carrierList);
        setcarrierList(carrierList);
        setproductList(productList);
      }

      const initialTXtValues = {
        phoneNumber:
          responseStruct.phoneNumber && responseStruct.phoneNumber.length > 0
            ? responseStruct.phoneNumber
            : '',
        provider:
          responseStruct.provider && responseStruct.provider.length > 0
            ? responseStruct.provider
            : '',
        product:
          responseStruct.product && responseStruct.product.length > 0
            ? responseStruct.product
            : '',
        // salesPrice: responseStruct.salesPrice && responseStruct.salesPrice.length > 0 ? responseStruct.salesPrice : '',
        // salesTax: responseStruct?.salesTax,
        // fee: responseStruct.nineOneOneFee ? responseStruct.nineOneOneFee : '',
        // totalSales: responseStruct.totalSales && responseStruct.totalSales.length > 0 ? responseStruct.totalSales : '',
        type:
          responseStruct.CarrierType !== undefined &&
          responseStruct.CarrierType !== ''
            ? responseStruct.CarrierType
            : '1',
      };
      console.log('Inirial Object value', initialTXtValues);
      setInitialObj(initialTXtValues);
      if (responseStruct.DropDownData !== undefined) {
        setProviderList(responseStruct.DropDownData);
      }
      if (
        responseStruct.dataCashSale &&
        responseStruct.dataCashSale.length > 0
      ) {
        setapiCalc(responseStruct.apiCalc);
        let reqobj = getReqObj(responseStruct.dataCashSale);
        setCustomDataReqObj([...reqobj]);
        setapiresponse(responseStruct.dataCashSale);
        let obj: any = {};
        responseStruct.dataCashSale.forEach((element: any) => {
          obj[element.id] = element.value;
        });
        setCustomDataObj(obj);
      }
      if (editItemId > 0) {
        setsubmitBtnEnabled(false);
        const arr = apiresponse.map((disableField: any) => {
          disableField.disableInput = true;
          return disableField;
        });
        setapiresponse(arr);
        seteditItemIdFlag(true);
        setamountFieldEnabled(false);
        setrequireAdditionalPir(true);
      }
      if (responseStruct.amountFieldEnabled) {
        // setamountFieldEnabled(true)
        setamountVal(responseStruct?.amountVal);
      }
      setisContinueBtnHidden(false);
      resetState('P');
      setInitialObj(initialTXtValues);
      setisFeildValid(false);
      setamountVal('');
      setamountValue('');
      setrequiredPir(true);
      settermsAndCondition('');
      setistermsAndConditionCheck(false);
    }
  }
  const calculate911feeAmount = async (amountFieldEnabled?: boolean) => {
    if (
      termsAndCondition !== undefined &&
      termsAndCondition !== '' &&
      termsAndCondition.length > 0
    ) {
      setsubmitBtnEnabled(false);
    } else {
      setsubmitBtnEnabled(false);
      setistermsAndConditionCheck(true);
    }

    let amountVals: any = 0;
    if (amountFieldEnabled && amountVal) {
      amountVals = Number(amountVal).toFixed(2);
    } else {
      amountVals = getSalesPrice().toFixed(2);
    }
    console.log(`amountVals ${amountVals}`);
    const req: any = {
      storeConfig: {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: ['Cellphone911Fee_Amount', 'Cellphone911Fee_Percent'],
      },
      TaxRequest: {
        taxInputRequest: [
          {
            receiptItemCategory: 'WIREACT',
            amount: (
              Number(amountVals) -
              Number(multiplierFields.consumerDiscount) / 100
            ).toString(),
            taxInputId: Math.floor(Math.random() * 100),
          },
        ],
        storeNumber: window.sessionStorage.getItem('storeNumber'),
        customerZipCode5:
          props?.customerInfo?.data?.GetCustomer?.value?.addresses[0]
            ?.postalCode?.length > 5
            ? props.customerInfo?.data.GetCustomer.value.addresses[0].postalCode.split(
                '-'
              )[0]
            : props.customerInfo?.data.GetCustomer.value.addresses[0]
                .postalCode,
        customerState:
          props?.customerInfo?.data?.GetCustomer?.value?.addresses[0]?.state,
      },
    };

    let configPermissions: any = [];
    // {
    //     Cellphone911Fee_Amount:0,
    //     Cellphone911Fee_Percent:0
    // }
    console.log('props.configFlag', props.configFlag);
    console.log('props.throwConfigResponse', props.throwConfigResponse);
    const CoworkerNum = props.throwConfigResponse;
    if (
      CoworkerNum !== undefined ||
      (null &&
        CoworkerNum.storeProfileResponse !== undefined &&
        CoworkerNum.storeProfileResponse &&
        CoworkerNum.storeProfileResponse.configDetails !== undefined &&
        CoworkerNum.storeProfileResponse.configDetails.length > 0 &&
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails !==
          undefined &&
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails.length >
          0)
    ) {
      const configArray =
        CoworkerNum.storeProfileResponse.configDetails[0].configDetails;
      configPermissions = configArray.filter(
        (x) =>
          x.paramKeyName == 'Cellphone911Fee_Amount' ||
          x.paramKeyName == 'Cellphone911Fee_Percent'
      );
    }
    let customerAdressFilterArray: any =
      props?.customerInfo?.data?.GetCustomer?.value?.addresses.filter((x) => {
        return x.addressType == 'PRIM';
      });
    console.log('configArrayaa', configPermissions);
    console.log('customerAdressFilterArray in mvs', customerAdressFilterArray);
    let postalCode=""
    let zip5=""
    let zip4=""
    let postalZip=""
    if(customerAdressFilterArray.length==0)
          {
            customerAdressFilterArray=[props?.customerInformation?.addresses[0]]

          }
          postalZip=customerAdressFilterArray[0].postalCode?.split("-")[0]
    if(postalZip == undefined ||
      postalZip == null ||
      postalZip == "" ||
      postalZip?.length != 5 
      )
      {
            let JsonsessionStoreDetails: any =
                window.sessionStorage.getItem("storeDetails");
            console.log(JsonsessionStoreDetails, "sessionStoreDetails1");
            // console.log(atob(JsonsessionStoreDetails),"sessionStoreDetails2")
            let sessionStoreDetails: any;
            try {
                sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
            } catch (e: any) {
                console.log(e, "not json formate");
            }
            if (postalZip?.length != 5 || postalZip == undefined || postalZip == null) {
              zip5 = sessionStoreDetails?.zip?.split("-")[0];
              zip4 = sessionStoreDetails?.zip?.split("-")[1];
            }
            // postalCode=  sessionStoreDetails?.zip?.split("-")[0]

      }
      console.log("postalCodepostalCodepostalCode",postalCode)
    const formedTaxEnginePayload: any = {
      customerState: customerAdressFilterArray[0].state,
      postalCode: zip5 == ""? postalZip : zip5,
      customerId: props?.customerInfo?.data?.GetCustomer?.value?.customerId,
      apiCall: '',
      dateOfBirth: props?.customerInfo?.data?.GetCustomer?.value?.dateOfBirth,
      taxRateOnly: 'N',
      taxExemptAsOfToday: props.taxExemption=="" ? "N":props.taxExemption,
    };
    let taxInputsData: any = [];
    taxInputsData.push({
      amount: (
        Number(amountVals) -
        Number(multiplierFields.consumerDiscount) / 100
      ).toString(),
      taxInputId: String(Math.floor(Math.random() * 100)),
      itemCategory: 'WIREACT',
    });
    formedTaxEnginePayload.storeInputs = [
      {
        taxInputs: taxInputsData,
        storeNumber: String(window.sessionStorage.getItem('storeNumber')),
      },
    ];
    try {
      if (props.configFlag == 0) {
        setblurLoaderActive(true);
        const Res911Fee = await Calculate911Fee(req);
        setblurLoaderActive(false);
        setrequireAdditionalPir(false);
        setHideDiv(true);
        // if (Res911Fee && Res911Fee.data.TaxResponse  &&Res911Fee.data.TaxResponse && Res911Fee.data.TaxResponse.value && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0] && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0].taxAmount){
        //     setInitialObj({ ...initialObj, salesTax: Res911Fee.data.TaxResponse.value.TaxCalculatorRespone && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0] && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0].taxAmount })
        // }
        if (
          Res911Fee &&
          Res911Fee?.data?.TaxResponse &&
          Res911Fee?.data?.TaxResponse &&
          Res911Fee?.data?.TaxResponse?.value &&
          Res911Fee?.data?.TaxResponse?.value?.TaxCalculatorRespone &&
          Res911Fee?.data?.TaxResponse?.value?.TaxCalculatorRespone
            ?.taxOutPutResponse &&
          Res911Fee?.data?.TaxResponse?.value?.TaxCalculatorRespone
            ?.taxOutPutResponse[0] &&
          Res911Fee?.data?.TaxResponse?.value?.TaxCalculatorRespone
            ?.taxOutPutResponse[0]?.taxAmount >= 0 &&
          Res911Fee &&
          Res911Fee.data?.StoreConfigResponse &&
          Res911Fee.data?.StoreConfigResponse?.value &&
          Res911Fee.data?.StoreConfigResponse?.value?.storeProfileResponse &&
          Res911Fee.data?.StoreConfigResponse?.value?.storeProfileResponse
            ?.configDetails &&
          Res911Fee.data?.StoreConfigResponse?.value?.storeProfileResponse
            ?.configDetails[0] &&
          Res911Fee.data?.StoreConfigResponse?.value?.storeProfileResponse
            ?.configDetails[0]?.configDetails &&
          Res911Fee?.data?.StoreConfigResponse?.value?.storeProfileResponse
            ?.configDetails[0]?.configDetails[0] &&
          Res911Fee?.data?.StoreConfigResponse?.value?.storeProfileResponse
            ?.configDetails[0]?.configDetails[0]?.paramValue
        ) {
          let fee: any = 0;
          if (
            Res911Fee?.data.StoreConfigResponse?.value?.storeProfileResponse
              ?.configDetails[0]?.configDetails[0]?.paramKeyName ==
            'Cellphone911Fee_Amount'
          ) {
            fee += Number(
              Res911Fee.data.StoreConfigResponse?.value?.storeProfileResponse
                ?.configDetails[0]?.configDetails[0]?.paramValue
            );
          } else {
            fee +=
              Number(getSalesPrice().toFixed(2)) *
              Number(
                Res911Fee.data.StoreConfigResponse?.value?.storeProfileResponse
                  ?.configDetails[0]?.configDetails[0]?.paramValue
              );
          }
          setInitialObj({
            ...initialObj,
            salesTax:
              Res911Fee?.data?.TaxResponse?.value?.TaxCalculatorRespone?.taxOutPutResponse[0]?.taxAmount.toFixed(
                2
              ),
            fee: (Math.floor(fee * 100) / 100).toFixed(2),
          });
          // setInitialObj({ ...initialObj, fee: (Number(getpriceamount().toFixed(2)) * Number(Res911Fee.data.StoreConfigResponse.value.storeProfileResponse.configDetails[0].configDetails[0].paramValue))})
        }
      } else if (props.configFlag == 1) {
        setblurLoaderActive(true);
        const Res911Fee = await getTax(formedTaxEnginePayload);
        setblurLoaderActive(false);
        setrequireAdditionalPir(false);
        setHideDiv(true);
        // if (Res911Fee && Res911Fee.data.TaxResponse  &&Res911Fee.data.TaxResponse && Res911Fee.data.TaxResponse.value && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0] && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0].taxAmount){
        //     setInitialObj({ ...initialObj, salesTax: Res911Fee.data.TaxResponse.value.TaxCalculatorRespone && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0] && Res911Fee.data.TaxResponse.value.TaxCalculatorRespone.taxOutPutResponse[0].taxAmount })
        // }
        console.log('Res911Feemm', Res911Fee);
        if (
          Res911Fee &&
          Res911Fee?.data?.taxResponse &&
          Res911Fee?.data?.taxResponse[0] &&
          Res911Fee?.data?.taxResponse[0]?.taxOutputs &&
          Res911Fee?.data?.taxResponse[0]?.taxOutputs[0] &&
          Res911Fee?.data?.taxResponse[0]?.taxOutputs[0]?.taxAmount >= 0 &&
          configPermissions &&
          configPermissions[0] &&
          configPermissions[0]?.paramValue
        ) {
          let fee: any = 0;
          if (configPermissions[0]?.paramKeyName == 'Cellphone911Fee_Amount') {
            fee += Number(configPermissions[0]?.paramValue);
          } else {
            fee +=
              Number(getSalesPrice().toFixed(2)) *
              Number(configPermissions[0]?.paramValue);
          }
          setInitialObj({
            ...initialObj,
            salesTax: Number(
              Res911Fee?.data?.taxResponse[0]?.taxOutputs[0]?.taxAmount
            ).toFixed(2),
            fee: (Math.floor(fee * 100) / 100).toFixed(2),
          });
          // setInitialObj({ ...initialObj, fee: (Number(getpriceamount().toFixed(2)) * Number(Res911Fee.data.StoreConfigResponse.value.storeProfileResponse.configDetails[0].configDetails[0].paramValue))})
        }
        console.log('paramvaal', configPermissions[0]?.paramValue);
      }
      // setapiCalc(0)
      // console.log('Res911Fee.data', Res911Fee)
    } catch (e: any) {
      setblurLoaderActive(false);
      console.log('Res911Fee', e);
    }
  };
  const getpriceamount = () => {
    let res: any = 0;
    if (customDataObj && customDataObj.PricePlan) {
      let val: any = Number(customDataObj.PricePlan.split(',')[0]) / 100;
      res = val;
    } else if (customDataObj && customDataObj.PricePlan1) {
      let val: any = Number(customDataObj.PricePlan1.split(',')[0]) / 100;
      res = val;
    }
    return res;
  };
  const getReqObj = (obj: any) => {
    console.log('api response 21', customDataObj);

    let obj1: any = [];
    if (obj && obj.length > 0) {
      obj.forEach((element: any) => {
        // eslint-disable-next-line no-prototype-builtins
        if (
          element.type !== 'TABLE' &&
          !customDataObj.hasOwnProperty(element.id)
        ) {
          let ResponseReqObj: any = {};
          ResponseReqObj.id = element.id;
          ResponseReqObj.regex = element.regex;
          ResponseReqObj.required = element.required;
          ResponseReqObj.type = element.type;
          obj1.push(ResponseReqObj);
        }
      });
    }
    return obj1;
  };
  const filterNewSeqRes = (arr: any) => {
    console.log('an response', arr);
    let an = JSON.parse(JSON.stringify(arr));
    an.forEach((arr: any, index: any) => {
      arr.index = index;
    });

    let i: any = [];
    an.forEach((el: any, i: any) => (el.index = i));
    for (let outer = 0; outer < an.length; outer++) {
      for (let inner = 0; inner < an.length; inner++) {
        if (an[outer].id == an[inner].id && an[outer].seq > an[inner].seq) {
          [an[outer], an[inner]] = [an[inner], an[outer]];
          i.push(an[outer].index);
        }
      }
    }

    // Array.from(new Set(i)).forEach((el,i)=>an.splice(el,1))
    return an.filter((item: any) => {
      if (!i.includes(item.index)) {
        return item;
      }
    });
  };
  const buildPayload = async (pid: any, seq?: any) => {
    let amountParam = 0;

    const data = providerList.product.filter(
      (el: any) => el.productNumber == pid
    );
    if (data.length > 0 && providers_checklist.includes(data[0].productName)) {
      amountParam = amountValueParam;
    } else {
      amountParam = getAmount();
    }
    amountParam =
      amountParam *
        (Number(customDataObj.MonthsNum)
          ? Number(customDataObj.MonthsNum)
          : 1) *
        (multiplierFields.valueMultiplier == 0
          ? 1
          : multiplierFields.valueMultiplier) +
      multiplierFields.serviceFee *
        (multiplierFields.feeMultiplier == 0
          ? 1
          : multiplierFields.feeMultiplier) -
      multiplierFields.consumerDiscount *
        (multiplierFields.discountMultiplier == 0
          ? 1
          : multiplierFields.discountMultiplier) *
        (Number(customDataObj.MonthsNum) ? Number(customDataObj.MonthsNum) : 1);
    if (pid !== '0') {
      let sessionUUID: any = uuidv4();
      let saleUUID: any = uuidv4();
      const req: any = {
        requestType: 'PRODUCTINFO',
        version: '5.0',
        authorization: {
          username: 'USER_NAME',
          password: 'PASSWORD',
        },
        shopId: sessionStorage.getItem('storeNumber'),
        TerminalID: Number(terminalId),
        terminal: {
          id: terminalId,
        },
        consumerDiscount:
          multiplierFields?.consumerDiscount *
          (Number(customDataObj.MonthsNum)
            ? Number(customDataObj.MonthsNum)
            : 1),
        clientApp: 'UP API',
        clientName: 'RAC',
        cashier: String(coWorkerRole),
        sequence:
          seq !== undefined && seq !== null && seq !== '' ? seq : apiCalc,
        sessionId:
          seq !== undefined && seq !== null && seq !== ''
            ? sessionUUID
            : sessionId,
        saleId:
          seq !== undefined && seq !== null && seq !== '' ? saleUUID : saleId,
        actionId: uuidv4(),
        requestId: uuidv4(),
        txnId: uuidv4(),
        localdatetime: `${moment().format('YYYY-MM-DD')}T${moment().format(
          'HH:mm:ss'
        )}`,
        productId: pid,
        amount: Number(amountParam) || 0,
        serviceFee: 0,
        customData: {
          items: [
            {
              values: [],
            },
          ],
        },
      };
      if (
        initialObj.phoneNumber &&
        initialObj.phoneNumber?.replace(/\D/g, '').length == 10
      ) {
        req.phone = initialObj.phoneNumber?.replace(/\D/g, '');
      }
      if (seq !== undefined && seq !== null && seq !== '') {
        setSessionId(sessionUUID);
        setSaleId(saleUUID);
      }

      if (apiCalc >= 0) {
        if (seq == undefined || seq == null || seq !== 0) {
          let data: any = [];
          console.log(apiresponse);
          apiresponse.forEach((el: any) => {
            if (['ContactPhn', 'MobileNum'].includes(el.id)) {
              data.push({
                key: el.id,
                value: customDataObj[el.id].replace(/\D/g, ''),
              });
            } else if (el.type !== 'HTML_ELEMENT') {
              data.push({ key: el.id, value: customDataObj[el.id] || '' });
            }
          });
          // for (const key in apiresponse) {

          //     console.log("customDataObj, customDataObj", key, customDataObj)
          // }
          req.customData.items[0].values = data;
        }
        setapipayload(req);
      }
      console.log('multipl', multiplierFields, amountParam);
      setisFeildValid(true);
      setapiEnabled(true);
      setblurLoaderActive(true);
      const response = await CellActive(req);
      let copyResponse = false;
      setblurLoaderActive(false);
      seteditItemIdFlag(false);
      // setisContinueBtnHidden(false)
      setapiCalc(
        seq !== undefined && seq !== null && seq !== '' ? seq + 1 : apiCalc + 1
      );
      console.log('api response 21', customDataObj);
      let reqobj: any = [];
      let calcObj = { consumerDiscount: 0, serviceFee: 0 };
      if (
        response &&
        response.data &&
        response.data.customData &&
        response.data.customData.prompts
      ) {
        if (
          response.data.customData?.consumerDiscount ||
          response.data.customData?.serviceFee
        ) {
          calcObj = {
            consumerDiscount: response.data.customData?.consumerDiscount
              ? response.data.customData?.consumerDiscount
              : multiplierFields?.consumerDiscount,
            serviceFee: response.data.customData?.serviceFee
              ? response.data.customData?.serviceFee
              : multiplierFields.serviceFee,
          };
          setmultiplierFields((prev) => {
            console.log('prev val1', prev, calcObj);
            return { ...prev, ...calcObj };
          });
        }
        if (response.data.customData.prompts.length > 0) {
          const termsandCondition = response.data.customData.prompts.filter(
            (el: any) => el.id == 'CRICKETTOS'
          );
          if (termsandCondition.length > 0) {
            copyResponse = true;
            let htmlObject: any = document.createElement('div');
            htmlObject.setAttribute('id', 'terms_condition');
            htmlObject.innerHTML = termsandCondition[0].defaultValue;
            document.getElementById('hiddelEl')?.appendChild(htmlObject);
            let el: any = document.querySelectorAll('#terms_condition');
            settermsAndCondition(el);
            let ddeleteEl: HTMLElement | null =
              document.getElementById('hiddelEl');
            if (ddeleteEl) {
              ddeleteEl.style.display = 'none';
            }
            console.log('termsandCondition', termsandCondition);
            response.data.customData.prompts =
              response.data.customData.prompts.filter(
                (el: any) => el.id != 'CRICKETTOS'
              );
          }
        }
        reqobj = getReqObj(response.data.customData.prompts);
      }
      //Checking if provider is exist or not
      if (
        response &&
        response.data &&
        response.data.result &&
        response.data.result !== 0
      ) {
        setproductNotFoundpopup(true);
        setproductNotFoundMsg(response.data.resultText);
      } else if (
        (response &&
          response.data &&
          response.data.customData &&
          !response.data.customData.requireAdditionalPir &&
          response.data.customData.prompts == undefined &&
          apiCalc >= 0) ||
        (response &&
          response.data &&
          response.data.customData &&
          response.data.customData.prompts == undefined &&
          response.data.customData.requireAdditionalPir &&
          apiCalc >= 0)
      ) {
        setisFeildValid(true);
        const arr = apiresponse.map((disableField: any) => {
          disableField.disableInput = true;
          return disableField;
        });
        if (getSalesPrice() !== 0) {
          setapiresponse(arr);
          setisContinueBtnHidden(true);
          calculate911feeAmount();
          if (
            termsAndCondition !== undefined &&
            termsAndCondition !== '' &&
            termsAndCondition.length > 0
          ) {
            setsubmitBtnEnabled(false);
          } else {
            if (copyResponse) {
              setistermsAndConditionCheck(false);
            } else {
              setsubmitBtnEnabled(false);
              setistermsAndConditionCheck(true);
            }
          }
        } else {
          setapiresponse(arr);
          if (response?.data?.customData?.value != undefined) {
            debugger;
            setamountValue((prev) =>
              (
                (Number(response?.data?.customData?.value) -
                  Number(calcObj.consumerDiscount) *
                    (Number(customDataObj.MonthsNum)
                      ? Number(customDataObj.MonthsNum)
                      : 1)) /
                100
              )
                .toFixed(2)
                .toString()
            );
            setamountVal((prev) =>
              (
                (Number(response?.data?.customData?.value) -
                  Number(calcObj.consumerDiscount) *
                    (Number(customDataObj.MonthsNum)
                      ? Number(customDataObj.MonthsNum)
                      : 1)) /
                100
              )
                .toFixed(2)
                .toString()
            );
            setCustomDataObj({
              ...customDataObj,
              amount: (Number(response?.data?.customData?.value) / 100)
                .toFixed(2)
                .toString(),
            });
            setamountFieldEnabled(true);
          }

          const IsPriceplanExsistPrev = apiresponse.filter(
            (el: any) =>
              el.id == 'PricePlan' ||
              el.id == 'PricePlan1' ||
              el.id == 'PricePlan2' ||
              el.id == 'PricePlan2' ||
              el.id == 'TFPlanLst1'
          );

          const getAmountvalue = getAmount();
          if (
            response.data.customData.prompts == undefined &&
            IsPriceplanExsistPrev.length == 0
          ) {
            setamountFieldEnabled(true);
          } else if (
            getAmountvalue !== undefined &&
            getAmountvalue !== null &&
            getAmountvalue == 0 &&
            IsPriceplanExsistPrev.length == 0
          ) {
            setamountFieldEnabled(true);
          }
        }
      } else if (
        response &&
        response.data &&
        response.data.customData &&
        response.data.customData.prompts &&
        response.data.customData.requireAdditionalPir
      ) {
        // if (!response.data.customData.requireAdditionalPir && apiCalc  0)
        if (seq !== undefined && seq !== null && seq !== '') {
          let obj: any = {};
          let objSeqStore: any = [];
          objSeqStore.push(response.data.customData.prompts);
          setallseqData(objSeqStore);
          response.data.customData.prompts.forEach((element: any) => {
            if (
              (element.type == 'LIST' &&
                element.defaultValue &&
                element.defaultValue.length > 0 &&
                !element.id.includes('STATE')) ||
              (element.defaultValue &&
                element.defaultValue.length > 0 &&
                element.readOnly &&
                !['CusName', 'MsgPrtStat'].includes(element.id))
            ) {
              obj[element.id] = element.defaultValue[0];
              // eslint-disable-next-line no-prototype-builtins
              if (
                (element.valueMultiplier ||
                  element.feeMultiplier ||
                  element.discountMultiplier) &&
                !customDataObj.hasOwnProperty(element.id)
              ) {
                // debugger
                let multiplierObj = {
                  valueMultiplier: element.valueMultiplier
                    ? multiplierFields.valueMultiplier + 1
                    : multiplierFields.valueMultiplier,
                  discountMultiplier: element.discountMultiplier
                    ? multiplierFields.discountMultiplier + 1
                    : multiplierFields.discountMultiplier,
                  feeMultiplier: element.feeMultiplier
                    ? multiplierFields.feeMultiplier + 1
                    : multiplierFields.feeMultiplier,
                };
                setmultiplierFields((prev) => {
                  console.log('prev val', prev);
                  return { ...prev, ...multiplierObj };
                });
              }
            } else if (
              element.id.includes('MsgPrtStat') &&
              element.defaultValue &&
              element.defaultValue.length > 0
            ) {
              obj[element.id] = element.defaultValue[0].replace(
                'Complete Transaction',
                'Submit'
              );
              obj['innerHTMLpresent'] = true;
            } else if (['FirstName', 'FirstName1'].includes(element.id)) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.firstName;
            } else if (['LastName', 'LastName1'].includes(element.id)) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.lastName;
            } else if (['Address1'].includes(element.id)) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .addressLine1;
            } else if (['Address2'].includes(element.id)) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .addressLine2;
            } else if (['ContactPhn'].includes(element.id)) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.phones &&
                props.customerInfo?.data.GetCustomer.value.phones.length > 0 &&
                props.customerInfo?.data.GetCustomer.value.phones[0].phoneNumber?.replace(
                  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  '($1) $2-$3'
                );
            } else if (element.id.toLowerCase()?.includes('city')) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0].city;
            } else if (element.id.toLowerCase()?.includes('zip')) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .postalCode &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .postalCode.length > 5
                  ? props.customerInfo?.data.GetCustomer.value.addresses[0].postalCode.split(
                      '-'
                    )[0]
                  : props.customerInfo?.data.GetCustomer.value.addresses[0]
                      .postalCode;
            } else if (element.id.toLowerCase()?.includes('state')) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0].state;
            } else if (element.id.toLowerCase()?.includes('email')) {
              obj[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.emailAddress;
            } else if (['CusName'].includes(element.id)) {
              obj[element.id] =
                props.customerInfo?.data.GetCustomer.value.firstName +
                ' ' +
                props.customerInfo?.data.GetCustomer.value.lastName;
            } else if (['SessionID'].includes(element.id)) {
              obj[element.id] =
                (element?.defaultValue && element?.defaultValue[0]) ||
                sessionId;
            } else if (element.id.includes('PRDDISCR')) {
              obj[element.id] = element.defaultValue[0];
            } else {
              obj[element.id] =
                (element?.defaultValue && element?.defaultValue[0]) || '';
            }
          });
          setCustomDataReqObj([...reqobj]);
          console.log(
            'customDataReqObjcustomDataReqObj 1st',
            reqobj,
            customDataReqObj
          );
          console.log(customDataObj);
          let displayDependentOnValue: any = 'Portin';
          const responseArr: any = response.data.customData.prompts.map(
            (el: any) => {
              if (el.displayDependentOnValue == displayDependentOnValue) {
                el.dispField = 1;
              } else if (
                el?.hidden ||
                (el?.defaultValue &&
                  (el?.defaultValue[0] == '' || el?.defaultValue[0] == '0') &&
                  el?.readOnly)
              ) {
                el.dispField = 1;
              } else {
                el.dispField = 0;
              }
              el.seq = apiCalc;
              el.disableInput = false;
              return el;
            }
          );
          //Need to get displayDependentOnPromptId element first to know that is dependent on something
          let allResponse = responseArr.map((el: any) => {
            if (el.displayDependentOnPromptId !== '') {
              el.dispField = 1;
            }
            return el;
          });

          let lineItem = Array.from(
            new Set(
              allResponse
                .filter((lineItemEl: any) => {
                  if (lineItemEl.displayGroupName !== '') {
                    return lineItemEl.displayGroupName;
                  }
                })
                .map((lineItemElMap: any) => lineItemElMap.displayGroupName)
            )
          );
          console.log('lineItem', lineItem);
          let allLineItemsInSeq: any = [];
          let promptWOTLineItem: any = [];

          if (lineItem.length > 0) {
            lineItem.forEach((eachLineItem: any) => {
              allLineItemsInSeq.push({
                text: 'HTML_ELEMENT',
                type: 'HTML_ELEMENT',
                dispField: 0,
                length: 20,
                flags: 0,
                required: false,
                confirm: false,
                donotPersist: false,
                displayDependentOnValue: '',
                displayDependentOnPromptId: '',
                id: eachLineItem,
              });
              [...apiresponse, ...allResponse].forEach((prompt: any) => {
                if (prompt?.displayGroupName == eachLineItem) {
                  allLineItemsInSeq.push(prompt);
                }
              });
            });
            promptWOTLineItem = [...apiresponse, ...allResponse].filter(
              (prompt: any) =>
                !prompt?.displayGroupName && prompt.type !== 'HTML_ELEMENT'
            );
            const data = filterNewSeqRes([
              ...promptWOTLineItem,
              ...allLineItemsInSeq,
            ]);
            setapiresponse(data);
          } else {
            const data = filterNewSeqRes([...apiresponse, ...allResponse]);
            setapiresponse(data);
          }
          setCustomDataObj(obj);
        } else {
          let obj: any = customDataObj;
          let obj1: any = {};
          for (let key in obj) {
            obj1[key] = obj[key];
          }
          response.data.customData.prompts.forEach((element: any) => {
            if (
              (element.type == 'LIST' &&
                element.defaultValue &&
                element.defaultValue.length > 0 &&
                !element.id.includes('STATE')) ||
              (element.defaultValue &&
                element.defaultValue.length > 0 &&
                element.readOnly &&
                !['CusName', 'MsgPrtStat'].includes(element.id))
            ) {
              obj1[element.id] = element.defaultValue[0];
              // eslint-disable-next-line no-prototype-builtins
              if (
                (element.valueMultiplier ||
                  element.feeMultiplier ||
                  element.discountMultiplier) &&
                !customDataObj.hasOwnProperty(element.id)
              ) {
                let multiplierObj = {
                  valueMultiplier: element.valueMultiplier
                    ? multiplierFields.valueMultiplier + 1
                    : multiplierFields.valueMultiplier,
                  discountMultiplier: element.discountMultiplier
                    ? multiplierFields.discountMultiplier + 1
                    : multiplierFields.discountMultiplier,
                  feeMultiplier: element.feeMultiplier
                    ? multiplierFields.feeMultiplier + 1
                    : multiplierFields.feeMultiplier,
                };
                setmultiplierFields((prev) => {
                  console.log('prev val', prev);
                  return { ...prev, ...multiplierObj };
                });
              }
            } else if (
              element.id.includes('MsgPrtStat') &&
              element.defaultValue &&
              element.defaultValue.length > 0
            ) {
              obj1[element.id] = element.defaultValue[0].replace(
                'Complete Transaction',
                'Submit'
              );
              obj1['innerHTMLpresent'] = true;
            } else if (['FirstName', 'FirstName1'].includes(element.id)) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.firstName;
            } else if (['LastName', 'LastName1'].includes(element.id)) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.lastName;
            } else if (['Address1'].includes(element.id)) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .addressLine1;
            } else if (['Address2'].includes(element.id)) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .addressLine2;
            } else if (['ContactPhn'].includes(element.id)) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.phones &&
                props.customerInfo?.data.GetCustomer.value.phones.length > 0 &&
                props.customerInfo?.data.GetCustomer.value.phones[0].phoneNumber?.replace(
                  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  '($1) $2-$3'
                );
            } else if (element.id.toLowerCase()?.includes('city')) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0].city;
            } else if (element.id.toLowerCase()?.includes('zip')) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .postalCode &&
                props.customerInfo?.data.GetCustomer.value.addresses[0]
                  .postalCode.length > 5
                  ? props.customerInfo?.data.GetCustomer.value.addresses[0].postalCode.split(
                      '-'
                    )[0]
                  : props.customerInfo?.data.GetCustomer.value.addresses[0]
                      .postalCode;
            } else if (element.id.toLowerCase()?.includes('state')) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.addresses &&
                props.customerInfo?.data.GetCustomer.value.addresses.length >
                  0 &&
                props.customerInfo?.data.GetCustomer.value.addresses[0].state;
            } else if (element.id.toLowerCase()?.includes('email')) {
              obj1[element.id] =
                props &&
                props.customerInfo &&
                props.customerInfo?.data &&
                props.customerInfo?.data.GetCustomer &&
                props.customerInfo?.data.GetCustomer.value &&
                props.customerInfo?.data.GetCustomer.value.emailAddress;
            } else if (['CusName'].includes(element.id)) {
              obj1[element.id] =
                props.customerInfo?.data.GetCustomer.value.firstName +
                ' ' +
                props.customerInfo?.data.GetCustomer.value.lastName;
            } else if (['SessionID'].includes(element.id)) {
              obj1[element.id] =
                (element?.defaultValue && element?.defaultValue[0]) ||
                sessionId;
            } else if (element.id.includes('PRDDISCR')) {
              obj1[element.id] = element.defaultValue[0];
            } else {
              obj1[element.id] =
                (element?.defaultValue && element?.defaultValue[0]) || '';
            }
          });
          let displayDependentOnValue: any = 'Portin';
          const responseArr: any = response.data.customData.prompts.map(
            (el: any) => {
              if (el.displayDependentOnValue == displayDependentOnValue) {
                el.dispField = 1;
              } else if (
                el?.hidden ||
                (el?.defaultValue &&
                  (el?.defaultValue[0] == '' || el?.defaultValue[0] == '0') &&
                  el?.readOnly)
              ) {
                el.dispField = 1;
              } else {
                el.dispField = 0;
              }
              el.seq = apiCalc;
              el.disableInput = false;
              return el;
            }
          );
          const arr = apiresponse.map((disableField: any) => {
            disableField.disableInput = true;
            return disableField;
          });
          let allResponse = responseArr.map((el: any) => {
            if (el.displayDependentOnPromptId !== '') {
              el.dispField = 1;
            }
            return el;
          });

          let lineItem = Array.from(
            new Set(
              allResponse
                .filter((lineItemEl: any) => {
                  if (lineItemEl.displayGroupName !== '') {
                    return lineItemEl.displayGroupName;
                  }
                })
                .map((lineItemElMap: any) => lineItemElMap.displayGroupName)
            )
          );
          console.log('lineItem', lineItem);
          let allLineItemsInSeq: any = [];
          let promptWOTLineItem: any = [];

          if (lineItem.length > 0) {
            lineItem.forEach((eachLineItem: any) => {
              allLineItemsInSeq.push({
                text: 'HTML_ELEMENT',
                type: 'HTML_ELEMENT',
                dispField: 0,
                length: 20,
                flags: 0,
                required: false,
                confirm: false,
                donotPersist: false,
                displayDependentOnValue: '',
                displayDependentOnPromptId: '',
                id: eachLineItem,
              });
              [...apiresponse, ...allResponse].forEach((prompt: any) => {
                if (prompt?.displayGroupName == eachLineItem) {
                  allLineItemsInSeq.push(prompt);
                }
              });
            });
            promptWOTLineItem = [...apiresponse, ...allResponse].filter(
              (prompt: any) =>
                !prompt?.displayGroupName && prompt.type !== 'HTML_ELEMENT'
            );
            const data = filterNewSeqRes([
              ...promptWOTLineItem,
              ...allLineItemsInSeq,
            ]);
            setapiresponse(data);
          } else {
            const data = filterNewSeqRes([...apiresponse, ...allResponse]);
            setapiresponse(data);
          }
          console.log('allseqdata', allseqData);
          let objSeqStore: any = allseqData;
          objSeqStore.push(response.data.customData.prompts);
          setallseqData(objSeqStore);
          setCustomDataReqObj([...customDataReqObj, ...reqobj]);
          console.log(
            'customDataReqObjcustomDataReqObj 2nd',
            reqobj,
            customDataReqObj
          );
          console.log(customDataObj);
          setCustomDataObj(obj1);
        }
      } else if (
        response &&
        response.data &&
        response.data.customData &&
        response.data.customData.prompts &&
        !response.data.customData.requireAdditionalPir
      ) {
        let obj: any = customDataObj;
        let obj1: any = {};
        for (let key in obj) {
          obj1[key] = obj[key];
        }
        response.data.customData.prompts.forEach((element: any) => {
          if (
            (element.type == 'LIST' &&
              element.defaultValue &&
              element.defaultValue.length > 0 &&
              !element.id.includes('STATE')) ||
            (element.defaultValue &&
              element.defaultValue.length > 0 &&
              element.readOnly &&
              !['CusName', 'MsgPrtStat'].includes(element.id))
          ) {
            obj1[element.id] = element.defaultValue[0];
            // eslint-disable-next-line no-prototype-builtins
            if (
              (element.valueMultiplier ||
                element.feeMultiplier ||
                element.discountMultiplier) &&
              !customDataObj.hasOwnProperty(element.id)
            ) {
              let multiplierObj = {
                valueMultiplier: element.valueMultiplier
                  ? multiplierFields.valueMultiplier + 1
                  : multiplierFields.valueMultiplier,
                discountMultiplier: element.discountMultiplier
                  ? multiplierFields.discountMultiplier + 1
                  : multiplierFields.discountMultiplier,
                feeMultiplier: element.feeMultiplier
                  ? multiplierFields.feeMultiplier + 1
                  : multiplierFields.feeMultiplier,
              };
              setmultiplierFields((prev) => {
                console.log('prev val', prev);
                return { ...prev, ...multiplierObj };
              });
            }
          } else if (
            element.id.includes('MsgPrtStat') &&
            element.defaultValue &&
            element.defaultValue.length > 0
          ) {
            obj1[element.id] = element.defaultValue[0].replace(
              'Complete Transaction',
              'Submit'
            );
            obj1['innerHTMLpresent'] = true;
          } else if (['FirstName', 'FirstName1'].includes(element.id)) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.firstName;
          } else if (['LastName', 'LastName1'].includes(element.id)) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.lastName;
          } else if (['Address1'].includes(element.id)) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.addresses &&
              props.customerInfo?.data.GetCustomer.value.addresses.length > 0 &&
              props.customerInfo?.data.GetCustomer.value.addresses[0]
                .addressLine1;
          } else if (['Address2'].includes(element.id)) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.addresses &&
              props.customerInfo?.data.GetCustomer.value.addresses.length > 0 &&
              props.customerInfo?.data.GetCustomer.value.addresses[0]
                .addressLine2;
          } else if (['ContactPhn'].includes(element.id)) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.phones &&
              props.customerInfo?.data.GetCustomer.value.phones.length > 0 &&
              props.customerInfo?.data.GetCustomer.value.phones[0].phoneNumber?.replace(
                /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                '($1) $2-$3'
              );
          } else if (element.id.toLowerCase()?.includes('city')) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.addresses &&
              props.customerInfo?.data.GetCustomer.value.addresses.length > 0 &&
              props.customerInfo?.data.GetCustomer.value.addresses[0].city;
          } else if (element.id.toLowerCase()?.includes('zip')) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.addresses &&
              props.customerInfo?.data.GetCustomer.value.addresses.length > 0 &&
              props.customerInfo?.data.GetCustomer.value.addresses[0]
                .postalCode &&
              props.customerInfo?.data.GetCustomer.value.addresses[0].postalCode
                .length > 5
                ? props.customerInfo?.data.GetCustomer.value.addresses[0].postalCode.split(
                    '-'
                  )[0]
                : props.customerInfo?.data.GetCustomer.value.addresses[0]
                    .postalCode;
          } else if (element.id.toLowerCase()?.includes('state')) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.addresses &&
              props.customerInfo?.data.GetCustomer.value.addresses.length > 0 &&
              props.customerInfo?.data.GetCustomer.value.addresses[0].state;
          } else if (element.id.toLowerCase()?.includes('email')) {
            obj1[element.id] =
              props &&
              props.customerInfo &&
              props.customerInfo?.data &&
              props.customerInfo?.data.GetCustomer &&
              props.customerInfo?.data.GetCustomer.value &&
              props.customerInfo?.data.GetCustomer.value.emailAddress;
          } else if (['CusName'].includes(element.id)) {
            obj1[element.id] =
              props.customerInfo?.data.GetCustomer.value.firstName +
              ' ' +
              props.customerInfo?.data.GetCustomer.value.lastName;
          } else if (['SessionID'].includes(element.id)) {
            obj1[element.id] =
              (element?.defaultValue && element?.defaultValue[0]) || sessionId;
          } else if (element.id.includes('PRDDISCR')) {
            obj1[element.id] = element.defaultValue[0];
          } else {
            obj1[element.id] =
              (element?.defaultValue && element?.defaultValue[0]) || '';
          }
        });
        let displayDependentOnValue: any = 'Portin';
        const responseArr: any = response.data.customData.prompts.map(
          (el: any) => {
            if (el.displayDependentOnValue == displayDependentOnValue) {
              el.dispField = 1;
            } else if (
              el?.hidden ||
              (el?.defaultValue &&
                (el?.defaultValue[0] == '' || el?.defaultValue[0] == '0') &&
                el?.readOnly)
            ) {
              el.dispField = 1;
            } else {
              el.dispField = 0;
            }
            el.seq = apiCalc;
            el.disableInput = false;
            return el;
          }
        );
        const arr = apiresponse.map((disableField: any) => {
          disableField.disableInput = true;
          return disableField;
        });
        let allResponse = responseArr.map((el: any) => {
          if (el.displayDependentOnPromptId !== '') {
            el.dispField = 1;
          }
          return el;
        });

        let lineItem = Array.from(
          new Set(
            allResponse
              .filter((lineItemEl: any) => {
                if (lineItemEl.displayGroupName !== '') {
                  return lineItemEl.displayGroupName;
                }
              })
              .map((lineItemElMap: any) => lineItemElMap.displayGroupName)
          )
        );
        console.log('lineItem', lineItem);
        let allLineItemsInSeq: any = [];
        let promptWOTLineItem: any = [];

        if (lineItem.length > 0) {
          lineItem.forEach((eachLineItem: any) => {
            allLineItemsInSeq.push({
              text: 'HTML_ELEMENT',
              type: 'HTML_ELEMENT',
              dispField: 0,
              length: 20,
              flags: 0,
              required: false,
              confirm: false,
              donotPersist: false,
              displayDependentOnValue: '',
              displayDependentOnPromptId: '',
              id: eachLineItem,
            });
            [...apiresponse, ...allResponse].forEach((prompt: any) => {
              if (prompt?.displayGroupName == eachLineItem) {
                allLineItemsInSeq.push(prompt);
              }
            });
          });
          promptWOTLineItem = [...apiresponse, ...allResponse].filter(
            (prompt: any) =>
              !prompt?.displayGroupName && prompt.type !== 'HTML_ELEMENT'
          );
          const data = filterNewSeqRes([
            ...promptWOTLineItem,
            ...allLineItemsInSeq,
          ]);
          setapiresponse(data);
        } else {
          const data = filterNewSeqRes([...apiresponse, ...allResponse]);
          setapiresponse(data);
        }
        console.log('allseqdata', allseqData);
        let objSeqStore: any = allseqData;
        objSeqStore.push(response.data.customData.prompts);
        setallseqData(objSeqStore);
        setCustomDataReqObj([...customDataReqObj, ...reqobj]);
        console.log(
          'customDataReqObjcustomDataReqObj 3rd',
          reqobj,
          customDataReqObj
        );
        console.log(customDataObj);
        console.log('before getsales price call', getSalesPrice());
        if (
          getSalesPrice() !== 0 &&
          response?.data?.customData?.value === undefined
        ) {
          console.log('inside getsales price call');
          setisContinueBtnHidden(true);
          calculate911feeAmount();
          if (
            termsAndCondition !== undefined &&
            termsAndCondition !== '' &&
            termsAndCondition.length > 0
          ) {
            setsubmitBtnEnabled(false);
          } else {
            if (copyResponse) {
              setistermsAndConditionCheck(false);
            } else {
              setsubmitBtnEnabled(false);
              setistermsAndConditionCheck(true);
            }
          }
        } else {
          if (response?.data?.customData?.value != undefined) {
            console.log('inside enable amount field');
            debugger;
            setamountValue((prev) =>
              (
                (Number(response?.data?.customData?.value) -
                  Number(calcObj.consumerDiscount) *
                    (Number(customDataObj.MonthsNum)
                      ? Number(customDataObj.MonthsNum)
                      : 1)) /
                100
              )
                .toFixed(2)
                .toString()
            );
            setamountVal((prev) =>
              (
                (Number(response?.data?.customData?.value) -
                  Number(calcObj.consumerDiscount) *
                    (Number(customDataObj.MonthsNum)
                      ? Number(customDataObj.MonthsNum)
                      : 1)) /
                100
              )
                .toFixed(2)
                .toString()
            );
            setCustomDataObj({
              ...customDataObj,
              amount: (Number(response?.data?.customData?.value) / 100)
                .toFixed(2)
                .toString(),
            });
            setamountFieldEnabled(true);
          }

          const IsPriceplanExsistPrev = apiresponse.filter(
            (el: any) =>
              el.id == 'PricePlan' ||
              el.id == 'PricePlan1' ||
              el.id == 'PricePlan2' ||
              el.id == 'PricePlan2' ||
              el.id == 'TFPlanLst1'
          );

          const IsPriceplanExsistCur = response.data.customData.prompts.filter(
            (el: any) =>
              el.id == 'PricePlan' ||
              el.id == 'PricePlan1' ||
              el.id == 'PricePlan2' ||
              el.id == 'PricePlan2' ||
              el.id == 'TFPlanLst1'
          );

          // console.log("Required Pir",)
          const getAmountvalue = getAmount();
          if (
            response.data.customData.prompts == undefined &&
            IsPriceplanExsistPrev.length == 0 &&
            IsPriceplanExsistCur.length == 0
          ) {
            setamountFieldEnabled(true);
          } else if (
            getAmountvalue !== undefined &&
            getAmountvalue !== null &&
            getAmountvalue == 0 &&
            IsPriceplanExsistPrev.length == 0 &&
            IsPriceplanExsistCur.length == 0
          ) {
            setamountFieldEnabled(true);
          }
        }
        setCustomDataObj(obj1);
        // }
      }
      if (
        response &&
        response.data &&
        response.data.customData &&
        !response.data.customData.requireAdditionalPir
      ) {
        setrequiredPir(false);
      }
      setamountValueParam(response?.data?.customData?.value || 0);
    }
  };
  function ValidateEmail(inputText: any) {
    // eslint-disable-next-line no-useless-escape
    const mailformat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return mailformat.test(inputText);
  }

  const buildDropdownList = (optionsList: any, length?: any) => {
    const options: dropdown[] = [];
    if (
      optionsList != undefined &&
      optionsList != null &&
      optionsList.length > 0
    ) {
      optionsList.map((value: any, index: any) => {
        if (index == 0) {
          options.push({ label: 'Select', value: '0' });
        }
        options.push({
          label: value.displayText,
          value: value.value,
        });
      });
    } else {
      if (length != undefined) {
        for (let i = 2; i <= length; i++) {
          //      if (i == 0) {
          //     options.push({ label: 'Select', value: '0' });
          //   }
          options.push({
            label: String(i),
            value: String(i),
          });
        }
      }
    }
    return options;
  };
  const resetState = (flag?: any) => {
    setisContinueBtnHidden(false);
    setapi1(false);
    setapi2(false);
    setapi3(false);
    setapiCalc(0);
    setrequireAdditionalPir(true);
    if (flag == 'P') {
      setHideDiv(true);
    } else {
      setHideDiv(false);
    }
    setproductNotFoundpopup(false);
    setproductNotFoundMsg('');
    setCustomDataReqObj([]);
    setCustomDataObj([]);
    setsubmitBtnEnabled(true);
    setamountEnabled(false);
    setapiresponse([]);
    // if (props && props.editItemId) {
    //     props.editItemId = 0
    // }
    console.log(isFeildValid);
    setisFeildValid(true);
    setapiEnabled(false);
    setamountFieldEnabled(false);
    setamountVal('');
    setamountValue('');
    setrequiredPir(true);
    settermsAndCondition('');
    setistermsAndConditionCheck(false);
  };
  const handleOnChange = async (
    e: any,
    name?: any,
    regex?: any,
    key?: any,
    index?: any,
    required?: any,
    type?: any,
    length?: any,
    val?: any
  ) => {
    setErrorBorder({ ...errorBorder, [key]: false });
    switch (name) {
      case 'Product':
        if (e.target.value !== '0') {
          const productLabel = productList.filter(
            (el: any) => el.value == e.target.value
          );
          setproductTypeValue(
            productLabel.length > 0 ? productLabel[0].label : ''
          );
          setInitialObj({
            ...initialObj,
            product: e.target.value,
            provider: '',
          });
          setisMobileNumberEnabled(false);
        }
        break;
      case 'type':
        // setInitialObj({ ...initialObj, type: e.target.value })
        if (e.target.value !== '0') {
          const typeLabel = carrierList.filter(
            (el: any) => el.value == e.target.value
          );
          setCarrierName(typeLabel.length > 0 ? typeLabel[0].label : '');
          setTwoFactorCompleted(false);
          setTwoFactorCancelClick(false);
          setSecondFactorOpen(true);
          setHideDiv(false);
          resetState('intial');
          setInitialObj({
            ...initialObj,
            type: e.target.value,
            phoneNumber: '',
            provider: '',
            salesPrice: '',
            salesTax: '',
            fee: '',
            totalSales: '',
            product: '',
          });
        } else {
          setInitialObj({
            ...initialObj,
            type: '',
            phoneNumber: '',
            provider: '',
            salesPrice: '',
            salesTax: '',
            fee: '',
            totalSales: '',
            product: '',
          });
          setHideDiv(false);
          resetState();
        }
        break;
      case 'phoneNumber':
        setInitialObj({ ...initialObj, phoneNumber: e.target.value });
        if (
          isMobileNumberEnabled &&
          e.target.value?.replace(/\D/g, '').length < 10
        ) {
          console.log('Hi from PH.no', e.target.value);
          setInitialObj({ ...initialObj, phoneNumber: e.target.value });
          setisFeildValid(true);
        } else {
          setisFeildValid(false);
        }
        break;
      case 'provider':
        if (e.target.value !== '0') {
          console.log('Hi from Provider', e.target.value);
          resetState('P');
          setInitialObj({ ...initialObj, provider: e.target.value });
          const ProductListvalue = providerList.product.filter(
            (el: any) => el.productNumber == e.target.value
          );
          if (
            ProductListvalue.length > 0 &&
            ProductListvalue[0].mobileNumberPrompt !== undefined &&
            ProductListvalue[0].mobileNumberPrompt !== '0'
          ) {
            setisMobileNumberEnabled(false);
            setisFeildValid(false);
          } else {
            setisMobileNumberEnabled(true);
            setisFeildValid(true);
          }
          console.log('Button Enabled', e.target.value);

          // setisFeildValid(false)
          // if (configData != undefined) {
          //     configData.map((obj: any) => {
          //         if (obj.paramKeyName == keyName) {
          //             setamountRange(obj.paramValue)
          //         }
          //     })
          // }
          const dropdownValue = dropOptionsForProvider();
          console.log('Dropdown logs', dropdownValue);
          dropdownValue.map((obj: any) => {
            if (obj.value == e.target.value) {
              providerList.product.forEach((value: any, index: any) => {
                if (obj.value == value.productNumber) {
                  setproductMinValue(value.minValue);
                  setproductMaxValue(value.maxValue);
                }
              });
            }
          });
          // }
        } else if (e.target.value == '0') {
          setisFeildValid(true);
        }
        break;
      case name:
        if (validateCurrentFeild(e.target.value, key, type)) {
          setisContinueBtnHidden(false);

          console.log(apiresponse);
          console.log(customDataObj);
          console.log(customDataReqObj);

          const apirespinseEl: any = [];
          // if (allseqData && allseqData.length > 0) {
          //     allseqData.forEach((el: any, i: any) => {
          // el.forEach((subEl: any) => {
          //     apirespinseEl.push(...el)
          //     if (subEl.id == key) {
          //         if (apiCalc - 1 !== 0) {
          //             setapiCalc(i + 1)
          //             const unique = [...new Map(apirespinseEl.map((item: any) =>
          //                 [item.id, item])).values()];
          //             console.log(customDataObj);
          //             console.log(customDataReqObj);
          //             let custResObj: any = {}
          //             let custReqObj: any = []

          //             unique.forEach((el: any) => {
          //                 custResObj[el.id] = customDataObj[el.id]
          //                 customDataReqObj.forEach((elements: any) => {
          //                     if (elements.id == el.id) custReqObj.push(elements)
          //                 });
          //             })
          //             let unique1 = [...new Set(custReqObj)];
          //             let unique2 = [...new Set(unique)];
          //             let res: any;
          //             // if (key.includes('PricePlan')) {
          //             //     let splitVal: any = e.target.value.split(',')[1]
          //             //     const arr: any = unique2.filter((elPP: any) => {
          //             //         if (elPP.displayDependentOnPromptId == key) {
          //             //             if (splitVal == elPP.displayDependentOnValue) {
          //             //                 let propName: any = elPP.id;
          //             //                 elPP.dispField = 0
          //             //                 setCustomDataObj({ ...customDataObj, [propName]: elPP.defaultValue[0], [name]: e.target.value })
          //             //             } else {
          //             //                 let propName: any = elPP.id;
          //             //                 elPP.dispField = 1
          //             //                 setCustomDataObj({ ...customDataObj, [propName]: e.target.value, [name]: e.target.value })
          //             //             }
          //             //         }
          //             //         return elPP
          //             //     })
          //             //     res = arr
          //             // }
          //             if (e.target.value == 'Portin') {
          //                 const arr: any = unique2.filter((elPort: any) => {
          //                     if (elPort.displayDependentOnValue == 'Portin' && elPort?.dispField == 1 && elPort.displayDependentOnPromptId == key) {
          //                         elPort.dispField = 0
          //                     }
          //                     return elPort
          //                 })
          //                 res = arr
          //                 // setapiresponse(arr)
          //             } else if (e.target.value == 'New') {
          //                 const arr: any = unique2.filter((elNew: any) => {
          //                     // data.forEach((element: any) => {
          //                     if (elNew.displayDependentOnValue == 'Portin' && elNew.displayDependentOnPromptId == key) {
          //                         elNew.dispField = 1
          //                     }
          //                     // })
          //                     return elNew
          //                 })
          //                 res = arr
          //             }
          //             setCustomDataReqObj(unique1)
          //             setCustomDataObj(custResObj)
          //             setapiresponse(res || unique2)
          //             setInitialObj({
          //                 ...initialObj,
          //                 salesPrice: '',
          //                 salesTax: '',
          //                 fee: '',
          //                 totalSales: ''
          //             })
          //             setrequireAdditionalPir(true)
          //             setsubmitBtnEnabled(true);
          //             setisFeildValid(false)
          //             // setamountFieldEnabled(false);
          //             // setamountVal('')

          //         }
          //         if (type == 'TRI') {
          //             setCustomDataObj({ ...customDataObj, [name]: e.target.value })
          //         } else if (key == 'PricePlan' || key == 'PricePlan1') {
          //             // eslint-disable-next-line no-prototype-builtins
          //             if (customDataObj && customDataObj.hasOwnProperty('PRDDISCR') && ['PricePlan', 'PricePlan1'].includes(key) && e.target.value !== '0') {
          //                 setCustomDataObj({ ...customDataObj, PRDDISCR: e.target.value, [name]: e.target.value })
          //                 // setCustomDataObj({ ...customDataObj, [name]: validF(e.target.value, regex) })
          //                 // eslint-disable-next-line no-prototype-builtins
          //             } else if (customDataObj && customDataObj.hasOwnProperty('PRDDISCR3') && ['PricePlan', 'PricePlan1', 'PricePlan2', 'PricePlan3'].includes(key) && e.target.value !== '0') {
          //                 setCustomDataObj({ ...customDataObj, PRDDISCR3: e.target.value, [name]: e.target.value })
          //                 // setCustomDataObj({ ...customDataObj, [name]: validF(e.target.value, regex) })
          //             }
          //             else if (customDataObj && ['PricePlan', 'PricePlan1'].includes(key) && e.target.value !== '0') {
          //                 setCustomDataObj({ ...customDataObj, [name]: e.target.value })
          //                 // setCustomDataObj({ ...customDataObj, [name]: validF(e.target.value, regex) })
          //             } else if (e.target.value == '0') {
          //                 setCustomDataObj({ ...customDataObj, PRDDISCR: '', [name]: '' })
          //             }
          //             // setCustomDataObj({ ...customDataObj, [name]: validF(e.target.value, regex) })
          //             // if (e.target.value == '0') {
          //             //     setCustomDataObj({ ...customDataObj, [name]: '' })
          //             // }
          //         } else if (type == 'CB') {
          //             setCustomDataObj({ ...customDataObj, [name]: e.target.checked ? '1' : '0' })
          //             // }
          //             // } else if (type == 'PHONENUMBER') {
          //             //     const cleaned = ('' + e.target.value).replace(/\D/g, '');
          //             //     //This is raw data to pass on props
          //             //     const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          //             //     const temp = cleaned.replace(PhoneRegex, '($1) $2-$3');
          //             //     setCustomDataObj({ ...customDataObj, [name]: temp })
          //         } else {
          //             if (name.includes('IMEI')) {
          //                 setCustomDataObj({ ...customDataObj, [name]: validF(e.target.value, regex, name) })
          //             }
          //             else {
          //                 setCustomDataObj({ ...customDataObj, [name]: e.target.value })
          //             }
          //         }

          //     } else {
          if (type == 'TRI') {
            setCustomDataObj({ ...customDataObj, [name]: e.target.value });
          }
          // else
          // if (key.includes('PricePlan')) {
          //     // eslint-disable-next-line no-prototype-builtins
          //     if (customDataObj && customDataObj.hasOwnProperty('PRDDISCR') && key.includes('PricePlan') && e.target.value !== '0') {
          //         let splitVal: any = e.target.value.split(',')[1]
          //         // if (splitVal == 'CRICKETCOR') {
          //         apiresponse.forEach((el: any) => {
          //             if (el.displayDependentOnPromptId == key) {
          //                 if (splitVal == el.displayDependentOnValue) {
          //                     let propName: any = el.id;
          //                     el.dispField = 0
          //                     setCustomDataObj({ ...customDataObj, [propName]: el.defaultValue[0], [name]: e.target.value })
          //                 } else {
          //                     let propName: any = el.id;
          //                     el.dispField = 1
          //                     setCustomDataObj({ ...customDataObj, [propName]: e.target.value, [name]: e.target.value })
          //                 }
          //             }
          //         })
          //         // setapiresponse(arr)
          //         // }
          //         // else{
          //         //     const arr: any = apiresponse.filter((el: any) => {
          //         //         if (el.displayDependentOnPromptId == key) {
          //         //             let propName: any = el.id;
          //         //             el.dispField = 0
          //         //             setCustomDataObj({ ...customDataObj, [propName]: e.target.value, [name]: validF(e.target.value, regex) })
          //         //         }
          //         //         return el
          //         //     })
          //         //     setapiresponse(arr)
          //         // }
          //         // setCustomDataObj({ ...customDataObj, PRDDISCR: e.target.value, [name]: validF(e.target.value, regex) })

          //         // setCustomDataObj({ ...customDataObj, [name]: validF(e.target.value, regex) })
          //     } else if (customDataObj && ['PricePlan', 'PricePlan1'].includes(key) && e.target.value !== '0') {
          //         setCustomDataObj({ ...customDataObj, [name]: e.target.value })
          //         // setCustomDataObj({ ...customDataObj, [name]: e.target.value })
          //     } else if (e.target.value == '0') {
          //         setCustomDataObj({ ...customDataObj, PRDDISCR: '', [name]: '' })
          //     }
          //     // setCustomDataObj({ ...customDataObj, [name]: e.target.value })
          //     // if (e.target.value == '0') {
          //     //     setCustomDataObj({ ...customDataObj, [name]: '' })
          //     // }
          // }
          else if (type == 'CB') {
            const arrRes: any = apiresponse.filter((el: any) => {
              if (el.displayDependentOnPromptId == name && e.target.checked) {
                el.dispField = 0;
                return el;
              } else if (
                el.displayDependentOnPromptId == name &&
                !e.target.checked
              ) {
                el.dispField = 1;
                return el;
              }
            });
            setCalcValue(arrRes);
            setCustomDataObj({
              ...customDataObj,
              [name]: e.target.checked ? '1' : '0',
            });
          } else if (name.includes('IMEI')) {
            setCustomDataObj({
              ...customDataObj,
              [name]: validF(e.target.value, regex, name),
            });
          } else {
            const getPromtInfo: any = apiresponse.find(
              (el: any) => el.id == key
            );
            let results: any = [];
            let getdependentPropmptId: any;
            debugger;
            if (key.includes('PricePlan')) {
              let splitVal: any = e.target.value.split(',')[1];
              getdependentPropmptId = apiresponse.find(
                (el: any) =>
                  el.displayDependentOnValue == splitVal &&
                  el.displayDependentOnPromptId == key
              );
            } else {
              getdependentPropmptId = apiresponse.find(
                (el: any) =>
                  el.displayDependentOnValue == e.target.value &&
                  el.displayDependentOnPromptId == key
              );
            }
            const arrRes: any = apiresponse.filter((el: any) => {
              if (
                (el.displayDependentOnPromptId !== '' &&
                  !el.disableInput &&
                  getPromtInfo.displayGroupName == el.displayGroupName) ||
                (el.displayDependentOnPromptId !== '' &&
                  !el.disableInput &&
                  el?.displayGroupName ==
                    getdependentPropmptId?.displayGroupName)
              ) {
                return el;
              }
            });
            if (isIdDependsOnAnother(name)) {
              if (getdependentPropmptId) {
                getdependentPropmptId.dispField = 0;
                results.push(getdependentPropmptId);
                arrRes.forEach((ddid: any) => {
                  if (results.length > 0 && results[0].id !== ddid.id) {
                    const isReadOnly = apiresponse.filter(
                      (el: any) => el.id == ddid.displayDependentOnPromptId
                    );
                    if (
                      (results.length > 0 &&
                        results[0].displayDependentOnPromptId ==
                          ddid.displayDependentOnPromptId &&
                        results[0].displayDependentOnValue ==
                          ddid.displayDependentOnValue) ||
                      (isReadOnly &&
                        isReadOnly.length > 0 &&
                        isReadOnly[0].dispField == 0 &&
                        isReadOnly[0].readOnly)
                    ) {
                      ddid.dispField = 0;
                      results.push(ddid);
                    } else if (ddid.type !== 'HTML_ELEMENT') {
                      ddid.dispField = 1;
                      results.push(ddid);
                    }
                  }
                });
                setCalcValue(results);
              } else {
                const data = arrRes.map((el: any) => {
                  if (el.dispField == 0 && el.type !== 'HTML_ELEMENT') {
                    el.dispField = 1;
                  }
                  return el;
                });
                setCalcValue(data);
              }
            }
            if (getdependentPropmptId && getdependentPropmptId.type == 'BIT') {
              setCustomDataObj({
                ...customDataObj,
                [name]: e.target.value,
                [getdependentPropmptId.id]: '0',
              });
            } else {
              setCustomDataObj({ ...customDataObj, [name]: e.target.value });
            }
          }
          // }
          // })
          // })
        }
        // }
        else if (name == 'amount') {
          setamountVal(e.target.value);
          setCustomDataObj({ ...customDataObj, amount: e.target.value });
        }
        // setCustomDataReqObj({ ...customDataReqObj, [name]: false })
        console.log('setCustomDataObj', customDataObj);
        // validateFeilds(e, name, regex, key, index, required,type,length)
        break;
    }
    console.log('inside name', customDataObj);
  };
  function setCalcValue(filterData: any) {
    const map = new Map();
    apiresponse.forEach((res: any) => {
      map.set(res.id, res);
    });
    filterData.forEach((element: any) => {
      map.set(element.id, element);
    });
    const values = Array.from(map.values());
    setapiresponse(values);
  }
  function isIdDependsOnAnother(id: any) {
    let isDependent = false;
    apiresponse.forEach((el: any) => {
      if (el.displayDependentOnPromptId == id) {
        isDependent = true;
      }
    });
    return isDependent;
  }
  function validateCurrentFeild(val: any, id: any, type?: any) {
    if (type == 'CB') {
      return true;
    }
    // eslint-disable-next-line no-prototype-builtins
    return customDataObj &&
      customDataObj.hasOwnProperty(id) &&
      customDataObj[id] !== val
      ? true
      : false;
  }
  function validF(e: any, regex: any, name?: any) {
    if (name?.includes('IMEI')) {
      let value = e;
      value = value.replace(/[^0-9\b]/gi, '');
      return value;
    } else {
      return e;
    }
  }
  const filterRes: any = () => {
    const allResponse: any = [];

    if (epayResponseSeq0 && epayResponseSeq0.length > 0) {
      allResponse.push(...epayResponseSeq0);
    }
    if (epayResponseSeq1 && epayResponseSeq1.length > 0) {
      allResponse.push(...epayResponseSeq1);
    }
    if (epayResponseSeq2 && epayResponseSeq2.length > 0) {
      allResponse.push(...epayResponseSeq2);
    }
    if (epayResponseSeq3 && epayResponseSeq3.length > 0) {
      allResponse.push(...epayResponseSeq3);
    }
    return allResponse;
  };
  const clearBtn = () => {
    setInitialObj({
      ...initialObj,
      type: '',
      phoneNumber: '',
      provider: '',
      product: '',
      salesPrice: '',
      salesTax: '',
      fee: '',
      totalSales: '',
    });
    setmultiplierFields({
      valueMultiplier: 0,
      discountMultiplier: 0,
      feeMultiplier: 0,
      serviceFee: 0,
      consumerDiscount: 0,
    });
    setHideDiv(false);
    resetState();
  };

  const errorMessage = () => {
    setInitialObj({
      ...initialObj,
      type: '',
      phoneNumber: '',
      provider: '',
      product: '',
      salesPrice: '',
      salesTax: '',
      fee: '',
      totalSales: '',
    });
    setHideDiv(false);
    resetState();
  };
  const regExValidationForOnBlur = (obj: any) => {
    let regE = new RegExp(obj.regex);
    if (!regE.test(customDataObj[obj.id]?.replace(/\D/g, ''))) {
      setErrorBorder({ ...errorBorder, [obj.id]: true });
    } else {
      setErrorBorder({ ...errorBorder, [obj.id]: false });
    }
  };
  const onBlurValidation = (obj: any) => {
    if (obj?.required) {
      //Checkking if regEx is exist or nor
      if (obj?.regex) {
        regExValidationForOnBlur(obj);
      }
    }
    console.log(obj, 'objOnBlur');
  };
  function validateFeilds(
    e: any,
    name?: any,
    regex?: any,
    key?: any,
    index?: any,
    required?: any,
    type?: any,
    length?: any
  ) {
    let firstApiFields: any = customDataReqObj;
    console.log(
      'inside name customDataReqObj',
      customDataReqObj,
      epayResponseSeq0
    );
    const res: any = apiresponse;

    console.log('inside name epayResponseSeq0', res);

    // if (customDataObj && customDataObj.PricePlan) {
    //     if (customDataObj.PRDDISCR !== undefined && customDataObj.PRDDISCR !== null && customDataObj.PRDDISCR == '') {
    //         customDataObj.PRDDISCR = customDataObj.PricePlan
    //     }
    // }
    // if (customDataObj && customDataObj.PricePlan1) {
    //     if (customDataObj.PRDDISCR !== undefined && customDataObj.PRDDISCR !== null && customDataObj.PRDDISCR == '') {
    //         customDataObj.PRDDISCR = customDataObj.PricePlan1
    //     }
    // }
    let testVar = true;
    if (customDataReqObj) {
      customDataReqObj.forEach((elelment: any) => {
        // if (elelment.id.includes('PRDDISCR')){
        //     customDataObj[elelment.id] = buildPricePlanFieild(elelment.id)
        // }
        if (testVar) {
          const filterRes: any = res.filter((el: any) => el.id == elelment.id);
          if (
            filterRes &&
            filterRes.length > 0 &&
            filterRes[0].regex &&
            elelment.required
          ) {
            let regE = new RegExp(filterRes[0].regex);
            let res = regE.test(customDataObj[elelment.id]?.replace(/\D/g, ''));
            if (!res && filterRes[0]?.dispField == 0) testVar = false;
          } else if (
            elelment.required &&
            elelment.id &&
            elelment.id == 'PROMPT1' &&
            customDataObj[elelment.id]?.trim()?.length < 10 &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            elelment.required &&
            elelment.id &&
            ['EMAIL'].includes(filterRes[0]?.type) &&
            !ValidateEmail(customDataObj[elelment.id]) &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            elelment.required &&
            elelment.id &&
            customDataObj[elelment.id]?.trim()?.length == 0 &&
            ['PASSWORD', 'STRING', 'NUMERIC'].includes(filterRes[0]?.type) &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            elelment.required &&
            elelment.id &&
            customDataObj[elelment.id]?.replace(/\D/g, '').length < 10 &&
            ['PHONENUMBER'].includes(filterRes[0]?.type) &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            filterRes[0]?.type == 'LIST' &&
            elelment.required &&
            elelment.id &&
            (customDataObj[elelment.id] == '0' ||
              customDataObj[elelment.id] == '') &&
            type !== 'TABLE' &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            filterRes[0] &&
            filterRes[0]?.type == 'BIT' &&
            elelment.required &&
            elelment.id &&
            customDataObj[elelment.id] !== '1' &&
            type !== 'TABLE' &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            elelment.id?.includes('MobileNum') &&
            elelment.required &&
            elelment.id &&
            type == 'STRING' &&
            customDataObj[elelment.id]?.length < filterRes[0]?.length &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            ['MobileNum', 'MobileNum1', 'ZipCode', 'SvcZip'].includes(
              elelment.id
            ) &&
            elelment.required &&
            elelment.id &&
            customDataObj[elelment.id]?.length <
              (elelment.id.includes('MobileNum') ? 10 : 5) &&
            type !== 'TABLE' &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          } else if (
            filterRes[0] &&
            filterRes[0]?.type == 'tri-state' &&
            elelment.required &&
            elelment.id &&
            customDataObj[elelment.id] == '' &&
            type !== 'TABLE' &&
            filterRes[0]?.dispField == 0
          ) {
            testVar = false;
          }
        }
      });
      const dataObjCount: any = (arr: any) => {
        let count = 0;
        arr.forEach((el: any) => {
          if (el.type !== 'TABLE' && el.type !== 'HTML_ELEMENT') {
            count++;
          }
        });
        return count;
      };
      if (
        testVar &&
        customDataReqObj &&
        dataObjCount(customDataReqObj) == dataObjCount(res) &&
        apiEnabled
      ) {
        // if(!apiCallsCount.secondAPI && !apiCallsCountMock.secondAPIMock){
        //     setapiCallsCount({...apiCallsCount,secondAPI:true})

        // }else if(!apiCallsCount.thirdAPI && !apiCallsCountMock.thirdAPIMock){
        //     setapiCallsCount({...apiCallsCount,thirdAPI:true})
        // }

        // setfirstApiCallValid(true)
        // ;

        setisFeildValid(false);
        if (amountFieldEnabled && (amountVal == '' || amountVal == '0')) {
          setisFeildValid(true);
        }
        if (
          (amountFieldEnabled &&
            amountVal &&
            Number(amountVal?.toString()?.replace(',', '') > 0) &&
            Number(amountVal?.replace(',', '')) > Number(productMinValue) &&
            Number(amountVal?.replace(',', '')) > Number(productMaxValue)) ||
          (Number(amountVal?.toString()?.replace(',', '') > 0) &&
            Number(amountVal?.replace(',', '')) < Number(productMinValue))
        ) {
          setisFeildValid(true);
        }
        if (amountValue) setisFeildValid(false);
        else {
          if (
            customDataObj.innerHTMLpresent &&
            customDataObj.MsgPrtStat &&
            customDataObj.MsgPrtStat.includes(
              'Port is still in progress. Please try again'
            )
          ) {
            setisContinueBtnHidden(true);
          }
        }
      } else if (apiEnabled) {
        setisFeildValid(true);
      }
      console.log('inside name firstApiFields', firstApiFields);
    }
  }
  function buildPricePlanFieild(id: any) {
    let val: any = '';
    apiresponse.forEach((el: any) => {
      if (el.id == id) {
        let displayDependentOnPromptId = el.displayDependentOnPromptId;
        val = customDataObj[displayDependentOnPromptId];
      }
    });
    return val;
  }
  const getAmount = () => {
    // if (props?.editItemInfo?.amount) {
    //     return props?.editItemInfo?.amount
    // } else {
    const allPrompts = allseqData.flat(Infinity);
    let amount: any = 0;
    let pricePlanItems: any = [];

    allPrompts.forEach((element: any) => {
      if (element.id.includes('PricePlan')) pricePlanItems.push(element.id);
    });
    let uniquepricePlanItems = [...new Set(pricePlanItems)];
    if (pricePlanItems && pricePlanItems.length > 0) {
      uniquepricePlanItems.forEach((el: any) => {
        amount += Number(customDataObj[el].split(',')[0]);
      });
    } else if (amountValue != '') {
      amount += Number(amountValue) * 100;
    }
    console.log('Amount from getAmount', amount);
    debugger;
    if (Number(amount) == 0) {
      return Number(amount);
    } else {
      const amounts =
        Number(amount) *
          (Number(customDataObj.MonthsNum)
            ? Number(customDataObj.MonthsNum)
            : 1) -
        Number(multiplierFields.consumerDiscount) *
          (Number(customDataObj.MonthsNum)
            ? Number(customDataObj.MonthsNum)
            : 1);
      console.log('amounts..', amounts);
      return Number(amount);
      // return (Number(amount) * (Number(customDataObj.MonthsNum) ? Number(customDataObj.MonthsNum) : 1)) - ((Number(multiplierFields.consumerDiscount) * (Number(customDataObj.MonthsNum) ? Number(customDataObj.MonthsNum) : 1)))
    }
    // }
  };
  const getSalesPrice = () => {
    if (initialObj.salesPrice) {
      console.log('Sales price', initialObj.salesPrice);
      return Number(initialObj.salesPrice);
    }
    if (
      amountFieldEnabled &&
      Number(amountVal?.toString()?.replace(',', '') > 0) &&
      Number(amountVal?.replace(',', '')) > Number(productMinValue) &&
      Number(amountVal?.replace(',', '')) <= Number(productMaxValue)
    ) {
      // if (amountVal.includes(',')) {
      console.log(
        'Sales price amountVal',
        amountVal?.toString().replace(',', '')
      );
      return Number(amountVal?.toString().replace(',', ''));
      // }
      // return Number(amountVal)
    } else {
      return Number(getAmount()) / 100;
    }
  };
  const checkIsDispField = (key: any) => {
    const res: any = apiresponse.find((el: any) => el.id == key);
    console.log('res', res);
    return res?.dispField == 0 ? true : false;
  };
  const buildResponseStructure = () => {
    let amountParam: any = 0;
    const datas = providerList.product.filter(
      (el: any) => el.productNumber == initialObj.provider
    );
    if (
      datas.length > 0 &&
      providers_checklist.includes(datas[0].productName)
    ) {
      amountParam = amountValueParam;
    } else {
      amountParam = getAmount();
    }
    amountParam =
      amountParam *
        (Number(customDataObj.MonthsNum)
          ? Number(customDataObj.MonthsNum)
          : 1) *
        (multiplierFields.valueMultiplier == 0
          ? 1
          : multiplierFields.valueMultiplier) +
      multiplierFields.serviceFee *
        (multiplierFields.feeMultiplier == 0
          ? 1
          : multiplierFields.feeMultiplier) -
      multiplierFields.consumerDiscount *
        (multiplierFields.discountMultiplier == 0
          ? 1
          : multiplierFields.discountMultiplier) *
        (Number(customDataObj.MonthsNum) ? Number(customDataObj.MonthsNum) : 1);
    let responseStruct: any = {};
    responseStruct.amount = amountParam;
    responseStruct.provider = initialObj.provider;
    responseStruct.salesPrice = (Number(amountParam) / 100).toFixed(2);
    responseStruct.nineOneOneFee =
      initialObj.fee == '' ? '0.00' : initialObj.fee;
    responseStruct.salesTax =
      initialObj.salesTax == '' ? '0.00' : initialObj.salesTax;
    responseStruct.totalSales = (
      parseFloat(responseStruct.amount) / 100 +
      parseFloat(responseStruct.salesTax) +
      parseFloat(responseStruct.nineOneOneFee)
    ).toFixed(2);
    responseStruct.type = 'Cell phone ' + productTypeValue;
    responseStruct.removePayment = 0;
    responseStruct.editItemId = props && props.editItemId;
    responseStruct.terminalId =
      apipayload?.TerminalID || props.editItemInfo.terminalId;
    responseStruct.txnId = apipayload?.txnId || props.editItemInfo.txnId;
    responseStruct.productId =
      apipayload?.productId || props.editItemInfo.productId;
    responseStruct.requestId =
      apipayload?.requestId || props.editItemInfo.requestId;
    responseStruct.sessionId =
      apipayload?.sessionId || props.editItemInfo.sessionId;
    responseStruct.actionId =
      apipayload?.actionId || props.editItemInfo.actionId;
    responseStruct.saleId = apipayload?.saleId || props.editItemInfo.saleId;
    responseStruct.phoneNumber =
      initialObj.phoneNumber == ''
        ? ''
        : initialObj.phoneNumber?.replace(/\D/g, '');
    responseStruct.allSeqData = allseqData;
    responseStruct.apiCalc = apiCalc;
    responseStruct.product = initialObj.product;
    responseStruct.DropDownData = providerList;
    responseStruct.CarrierName = carrierName;
    responseStruct.CarrierType = initialObj.type;
    responseStruct.productVariation = initialObj.provider;
    responseStruct.cashier = String(coWorkerRole);
    responseStruct.shopId = sessionStorage.getItem('storeNumber');
    responseStruct.consumerDiscount =
      multiplierFields?.consumerDiscount *
        (Number(customDataObj.MonthsNum)
          ? Number(customDataObj.MonthsNum)
          : 1) || props.editItemInfo?.consumerDiscount;
    responseStruct.serviceFee =
      multiplierFields?.serviceFee || props.editItemInfo?.serviceFee;

    let dataCashSale: any = [];
    apiresponse.forEach((el: any) => {
      const currentElement: any = el;
      if (customDataObj && customDataObj[el.id]) {
        currentElement.value = customDataObj[el.id];
      } else {
        currentElement.value = '';
      }

      dataCashSale.push(currentElement);
    });
    let data: any = [];
    for (const key in customDataObj) {
      if (key == 'MobileNum') {
        data.push({ key: key, value: customDataObj[key]?.replace(/\D/g, '') });
      } else if (key !== 'amount') {
        data.push({ key: key, value: customDataObj[key] || '' });
      }
      console.log('customDataObj, customDataObj', key, customDataObj);
    }
    responseStruct.dataCashSale = dataCashSale;
    responseStruct.data = data;
    let text: any = '';
    const getDiscription = (val?: any) => {
      text = '';
      dataCashSale.forEach((el: any) => {
        if (el.id.includes('PricePlan')) {
          const { options } = el;
          if (options) {
            let text1: any = '';
            if (val) {
              text1 = options.filter((el1: any) => el1.value == val);
            } else {
              text1 = options.filter(
                (el1: any) => el1.value == customDataObj[el.id]
              );
            }
            text += text1[0]?.displayText + ' ';
          } else {
            text += '';
          }
        }
      });
    };
    getDiscription();

    responseStruct.description = text;
    console.log('apiresponse', apiresponse);
    let isnumlinesExist: any = false;
    for (
      let checkNumLineExist = 0;
      checkNumLineExist < apiresponse.length;
      checkNumLineExist++
    ) {
      if (apiresponse[checkNumLineExist]?.id?.toLowerCase() == 'numlines') {
        isnumlinesExist = true;
        break;
      }
    }
    let groupDetail: any = [];
    if (isnumlinesExist) {
      apiresponse.forEach((el: any) => {
        if (
          el.value.toLowerCase() == 'new' ||
          el.value.toLowerCase() == 'portin'
        ) {
          groupDetail.push({
            id: el.id,
            displayGroupName: el.displayGroupName,
          });
        }
      });
    }
    let pricePlanDetails: any = [];
    groupDetail.forEach((grpDetail: any) => {
      let obj: any = {};
      apiresponse.forEach((el: any) => {
        if (grpDetail.displayGroupName == el.displayGroupName) {
          if (
            el?.id?.toLowerCase()?.includes('mobilenum') ||
            el?.id?.toLowerCase()?.includes('simnumber')
          ) {
            obj[el.id] = el.value;
          } else if (el?.id?.toLowerCase()?.includes('priceplan')) {
            obj[el.id] = text;
          }
        }
      });
      pricePlanDetails.push(obj);
    });
    if (pricePlanDetails.length == 0) {
      let obj: any = {};
      apiresponse.forEach((el: any) => {
        if (
          el?.id?.toLowerCase()?.includes('mobilenum') ||
          el?.id?.toLowerCase()?.includes('simnumber')
        ) {
          obj[el.id] = el.value;
        } else if (el?.id?.toLowerCase()?.includes('priceplan')) {
          obj[el.id] = text;
        }
      });
      pricePlanDetails.push(obj);
    }
    console.log('pricePlanDetails', pricePlanDetails);
    responseStruct.pricePlanDetails = pricePlanDetails;
    // if (getText && getText.length > 0){
    //     const { options } = getText[0]

    // }
    if (amountFieldEnabled) {
      responseStruct.amountFieldEnabled = amountFieldEnabled;
      responseStruct.amountVal = Number(
        amountVal?.toString()?.replace(',', '')
      );
      responseStruct.amount =
        Number(amountVal?.toString()?.replace(',', '')) * 100;
      responseStruct.salesPrice = Number(
        amountVal?.toString()?.replace(',', '')
      ).toFixed(2);
      responseStruct.totalSales = (
        parseFloat(responseStruct.amount) / 100 +
        parseFloat(responseStruct.salesTax) +
        parseFloat(responseStruct.nineOneOneFee)
      ).toFixed(2);
    }
    return responseStruct;
  };
  const cancelBtn = () => {
    props.response(undefined);
  };
  const submitBtnClicked = (ref: any) => {
    console.log('multipl', multiplierFields);
    if (ref == 'RemovePayment') {
      props.response({
        phoneNumber: props.editItemInfo.phoneNumber,
        amount: props.editItemInfo.amount,
        apiCalc: props.editItemInfo.apiCalc,
        productId: props.editItemInfo.productId,
        provider: props.editItemInfo.productId,
        sessionId: props.editItemInfo.sessionId,
        actionId: props.editItemInfo.actionId,
        saleId: props.editItemInfo.saleId,
        requestId: props.editItemInfo.requestId,
        removePayment: ref == 'RemovePayment' ? 1 : 0,
        editItemId: props.editItemId,
        terminalId: props?.editItemInfo?.terminalId,
        txnId: props.editItemInfo.txnId,
        type: props.editItemInfo.type,
        salesPrice: props.editItemInfo.salesPrice,
        salesTax: props.editItemInfo.salesTax,
        nineOneOneFee: props.editItemInfo.nineOneOneFee,
        data: props.editItemInfo.data,
        dataCashSale: props.editItemInfo.dataCashSale,
        description: props.editItemInfo.description,
        allSeqData: props.editItemInfo.allSeqData,
        amountFieldEnabled: props.editItemInfo.amountFieldEnabled,
        amountVal: props.editItemInfo.amountVal,
        pricePlanDetails: props.editItemInfo.pricePlanDetails,
        CarrierType: props.editItemInfo.CarrierType,
        productVariation: props.editItemInfo.productVariation,
        cashier: props.editItemInfo.cashier,
        shopId: props.editItemInfo.shopId,
      });
    } else {
      const responseStruct = buildResponseStructure();
      console.log('responseStruct', responseStruct);
      if (Number(props.editItemId) > 0) {
        props.response({
          phoneNumber: responseStruct.phoneNumber,
          amount: responseStruct.amount,
          apiCalc: responseStruct.apiCalc,
          productId: responseStruct.productId,
          provider: responseStruct.productId,
          sessionId: responseStruct.sessionId,
          actionId: responseStruct.actionId,
          saleId: responseStruct.saleId,
          requestId: responseStruct.requestId,
          removePayment: 0,
          editItemId: props.editItemId,
          terminalId: responseStruct?.terminalId,
          txnId: responseStruct.txnId,
          type: responseStruct.type,
          salesPrice: responseStruct.salesPrice,
          salesTax: responseStruct.salesTax,
          nineOneOneFee: responseStruct.nineOneOneFee,
          data: responseStruct.data,
          dataCashSale: responseStruct.dataCashSale,
          description: responseStruct.description,
          allSeqData: responseStruct.allSeqData,
          amountFieldEnabled: responseStruct.amountFieldEnabled,
          amountVal: responseStruct.amountVal,
          product: responseStruct.product,
          DropDownData: responseStruct.DropDownData,
          carrierName: responseStruct.CarrierName,
          totalSales: responseStruct.totalSales,
          pricePlanDetails: responseStruct.pricePlanDetails,
          CarrierType: responseStruct.CarrierType,
          productVariation: responseStruct.productVariation,
          cashier: responseStruct.cashier,
          shopId: responseStruct.shopId,
          consumerDiscount: responseStruct?.consumerDiscount,
          serviceFee: responseStruct?.serviceFee,
        });
      } else {
        props.response({
          phoneNumber: responseStruct.phoneNumber,
          amount: responseStruct.amount,
          apiCalc: responseStruct.apiCalc,
          productId: responseStruct.productId,
          provider: responseStruct.productId,
          sessionId: responseStruct.sessionId,
          actionId: responseStruct.actionId,
          saleId: responseStruct.saleId,
          requestId: responseStruct.requestId,
          removePayment: 0,
          editItemId: 0,
          terminalId: responseStruct?.terminalId,
          txnId: responseStruct.txnId,
          type: responseStruct.type,
          salesPrice: responseStruct.salesPrice,
          salesTax: responseStruct.salesTax,
          nineOneOneFee: responseStruct.nineOneOneFee,
          data: responseStruct.data,
          dataCashSale: responseStruct.dataCashSale,
          description: responseStruct.description,
          allSeqData: responseStruct.allSeqData,
          amountFieldEnabled: responseStruct.amountFieldEnabled,
          amountVal: responseStruct.amountVal,
          product: responseStruct.product,
          DropDownData: responseStruct.DropDownData,
          carrierName: responseStruct.CarrierName,
          totalSales: responseStruct.totalSales,
          pricePlanDetails: responseStruct.pricePlanDetails,
          CarrierType: responseStruct.CarrierType,
          productVariation: responseStruct.productVariation,
          cashier: responseStruct.cashier,
          shopId: responseStruct.shopId,
          consumerDiscount: responseStruct?.consumerDiscount,
          serviceFee: responseStruct?.serviceFee,
        });
      }
    }
  };
  const runAPICalls = () => {
    console.log('multi add', multiplierFields);
    if (
      (amountFieldEnabled &&
        Number(amountVal?.replace(',', '')) > 0 &&
        Number(amountVal?.replace(',', '')) > Number(productMinValue) &&
        Number(amountVal?.replace(',', '')) < Number(productMaxValue)) ||
      !requiredPir
    ) {
      const arr = apiresponse.map((disableField: any) => {
        disableField.disableInput = true;
        return disableField;
      });
      calculate911feeAmount(true);
      setisContinueBtnHidden(true);
      setamountEnabled(true);
    } else {
      if (!apiEnabled) {
        buildPayload(initialObj.provider, 0);
      } else {
        buildPayload(initialObj.provider);
      }
    }

    console.log(apiEnabled);
  };
  let dropOptionsForType = carrierList;
  const termcheck = () => {
    // console.log(istermsAndConditionCheck)
    //alert(istermsAndConditionCheck)
    if (istermsAndConditionCheck == true) {
      setistermsAndConditionCheck(false);
    } else {
      setistermsAndConditionCheck(true);
    }
  };

  const dropOptionsForProvider = () => {
    console.log('productList', productList);
    console.log('Product', initialObj.product);
    console.log(providerList);
    const getProductListLable = productList?.filter(
      (el: any) => el.value == initialObj.product
    );
    const getproviderListListLable = carrierList?.filter(
      (el: any) => el.value == initialObj.type
    );
    const optionsProvider: any = [];
    if (
      providerList != undefined &&
      providerList != null &&
      providerList &&
      providerList.product != undefined &&
      providerList.product.length > 0
    ) {
      providerList.product.forEach((value: any, index: any) => {
        if (
          value.productType == getProductListLable[0]?.label &&
          value.providerName == getproviderListListLable[0]?.label
        ) {
          if (optionsProvider.length == 0) {
            optionsProvider.push({ label: 'Select', value: '0' });
          }
          optionsProvider.push({
            label: value.productName,
            value: value.productNumber,
          });
        }
      });
      if (optionsProvider.length == 0) {
        optionsProvider.push({ label: 'Select', value: '0' });
        /// setproductNotFoundpopup(true);
      }
    }
    return optionsProvider;
  };

  function productNotFoundModal() {
    return (
      <Grid item md={12} className={classes.px2}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.RACPOPMsg}>
            {productNotFoundMsg}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
        >
          <RACButton
            color="primary"
            variant="contained"
            onClick={() => errorMessage()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );

    // Reason popup for Not running DE PC-CC-29
  }
  const childnonSale = () => {
    return (
      <Grid>
        <Modal
          isOpen={blurLoaderActive}
          className={classes.loaderStyle}
          id="openpopup"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <div>
            <div className={classes.RACLoaderPage}>
              <CircularProgress />
            </div>
          </div>
        </Modal>
        <>
          <Grid className={classes.racFixedFooterMargin}>
            <RACModalCard
              isOpen={productNotFoundpopup}
              borderRadius="20px"
              maxWidth="xs"
              closeIcon={true}
              onClose={() => errorMessage()}
              // eslint-disable-next-line react/no-children-prop
              children={productNotFoundModal()}
              title=""
            />
            <Grid container className={classes.tittles}>
              <Grid className={classes1.m2}>
                <List className={`${classes1.dflex} ${classes1.p0} `}>
                  <ListItem
                    className={`${classes1.widthAuto} ${classes1.p2} ${classes1.fontSemiBold} ${classes.floatStart} ${classes.BreadCrumbInactive}`}
                  >
                    <Typography
                      className={`${classes1.pr2} ${classes1.textBlack} `}
                    >
                      Dashboard
                    </Typography>
                  </ListItem>
                  <ListItem
                    className={`${classes1.widthAuto} ${classes1.p2} ${classes.floatStart} ${classes1.fontSemiBold} ${classes.BreadCrumbInactive}`}
                  >
                    <Typography
                      className={`${classes1.pr2} ${classes1.textBlack} `}
                    >
                      Payment
                    </Typography>
                  </ListItem>
                  <ListItem
                    className={`${classes1.widthAuto} ${classes1.p2} ${classes.floatStart} ${classes1.fontSemiBold}`}
                  >
                    <Typography
                      className={`${classes1.pr2} ${classes1.textBlack}  ${classes1.textBlue} `}
                    >
                      Non-Inventory Sales
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Card
              className={`${classes.w100}  ${classes.card}  ${classes.cardHeight} ${classes.marginTop} ${classes.cardborder}`}
            >
              <CardContent>
                <Grid container>
                  <Grid item md={3}>
                    <Typography
                      variant="h6"
                      className={`${classes.title} ${classes.titleColor}`}
                    >
                      Non Inventory Sales
                    </Typography>
                  </Grid>
                  <Grid className={classes.custDetailSection}>
                    <Grid item md={4} className={classes.paddingCustname}>
                      <Typography variant="h6">Customer Name</Typography>
                      <Typography
                        variant="h6"
                        className={classes.marginCustName}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          checkPermissionForCusRed(
                            props?.customerInfo?.data?.GetCustomer?.value
                              ?.customerId
                          )
                        }
                      >
                        {`${props?.customerInfo?.data?.GetCustomer?.value?.firstName} ${props?.customerInfo?.data?.GetCustomer?.value?.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid item md={4} className={classes.paddingCustname}>
                      <Typography variant="h6">Customer ID</Typography>
                      <Typography variant="h6" className={classes.marginCustID}>
                        {
                          props?.customerInfo?.data?.GetCustomer?.value
                            ?.customerId
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              className={`${classes.w100}  ${classes.card}  ${classes.cardHeight1} s`}
            >
              <CardContent>
                <Grid container>
                  <Grid
                    item
                    md={2}
                    className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} `}
                  >
                    <RACSelect
                      inputLabelClassName={`${classes.marginBottom} `}
                      inputLabel="Carrier"
                      classes={{ nativeSelect: classes.dropdown1 }}
                      name="type"
                      loading={carrierList !== undefined ? false : true}
                      defaultValue={
                        initialObj.type === '' ? '0' : initialObj.type
                      }
                      onChange={(event: any) => handleOnChange(event, 'type')}
                      required
                      options={
                        carrierList !== undefined && carrierList.length > 0
                          ? carrierList
                          : []
                      }
                    />
                  </Grid>
                </Grid>
                {hideDiv ? (
                  <Grid>
                    <div style={{ visibility: 'hidden' }} id="hiddelEl"></div>
                    <Grid className={classes.screenTop}>
                      <Typography className={classes.heading}>
                        Activation Screen
                      </Typography>
                    </Grid>
                    <Grid>
                      <Grid container className={classes.right}>
                        <Grid
                          item
                          md={2}
                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}  `}
                        >
                          <RACSelect
                            inputLabelClassName={classes.marginBottom}
                            classes={{ nativeSelect: classes.dropdown1 }}
                            inputLabel="Product"
                            defaultValue={
                              initialObj.product !== undefined &&
                              initialObj.product !== ''
                                ? initialObj.product
                                : '0'
                            }
                            name="Product"
                            //isDisabled={apiCalc > 0 && !editItemIdFlag ? true : false}
                            // loading={providerDrop != undefined ? false : true}
                            onChange={(e: any) => handleOnChange(e, 'Product')}
                            required={true}
                            options={
                              productList !== undefined &&
                              productList.length > 0
                                ? productList
                                : []
                            }
                            isDisabled={
                              apiCalc > 0 && !editItemIdFlag ? true : false
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          md={2}
                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}  `}
                        >
                          <RACSelect
                            inputLabelClassName={classes.marginBottom}
                            classes={{ nativeSelect: classes.dropdown1 }}
                            inputLabel="Product Variation"
                            defaultValue={
                              initialObj.provider !== undefined &&
                              initialObj.provider !== ''
                                ? initialObj.provider
                                : '0'
                            }
                            name="provider"
                            // isDisabled={apiCalc > 0 && !editItemIdFlag ? true : false}
                            // loading={providerDrop != undefined ? false : true}
                            onChange={(e: any) => handleOnChange(e, 'provider')}
                            required={true}
                            options={dropOptionsForProvider()}
                            isDisabled={
                              initialObj.product == undefined ||
                              initialObj.product == '' ||
                              initialObj.product == '0' ||
                              (apiCalc > 0 && !editItemIdFlag)
                                ? true
                                : false
                            }
                          />
                        </Grid>
                        {isMobileNumberEnabled === true ? (
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} ${classes.tittle1} `}
                          >
                            <RACTextbox
                              inputLabelClassname={classes.marginBottom}
                              type="phoneno"
                              name="phoneNumber"
                              disabled={apiCalc > 0 ? true : false}
                              value={initialObj.phoneNumber}
                              OnChange={(e) => handleOnChange(e, 'phoneNumber')}
                              required={true}
                              maxlength={14}
                              inputlabel="Existing Customer Mobile"
                            />
                          </Grid>
                        ) : null}
                        {apiresponse != undefined && apiresponse.length > 0
                          ? apiresponse.map((obj: any, index: any) => {
                              if (
                                apiresponse != undefined &&
                                apiresponse.length > 0
                              ) {
                                if (obj?.dispField == 0) {
                                  return (
                                    <>
                                      {obj.type == 'NUMERIC' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} ${classes.tittle1} `}
                                        >
                                          <RACTextbox
                                            inputLabelClassname={
                                              classes.marginBottom
                                            }
                                            type="number"
                                            infoIconClassname={classes.imgStyle}
                                            disabled={obj.disableInput}
                                            value={
                                              customDataObj != undefined
                                                ? customDataObj[obj.id]
                                                : ''
                                            }
                                            OnChange={(e) =>
                                              handleOnChange(
                                                e,
                                                obj.id,
                                                obj.regex,
                                                obj.id,
                                                index,
                                                obj.required,
                                                obj.type,
                                                obj.length
                                              )
                                            }
                                            maxlength={
                                              obj.id.includes('SimNumber')
                                                ? 20
                                                : obj.length
                                            }
                                            required={obj.required}
                                            Onblur={() => onBlurValidation(obj)}
                                            className={
                                              errorBorder?.[obj.id]
                                                ? classes.borderRed
                                                : ''
                                            }
                                            inputlabel={obj.text}
                                          />
                                        </Grid>
                                      ) : obj.type == 'HTML_ELEMENT' ? (
                                        <Grid item className={classes.w100}>
                                          <h2>{obj.id}</h2>
                                        </Grid>
                                      ) : obj.type == 'PASSWORD' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} ${classes.tittle1} `}
                                        >
                                          <RACTextbox
                                            inputLabelClassname={
                                              classes.marginBottom
                                            }
                                            type="password"
                                            infoIconClassname={classes.imgStyle}
                                            disabled={obj.disableInput}
                                            value={
                                              customDataObj != undefined
                                                ? customDataObj[obj.id]
                                                : ''
                                            }
                                            OnChange={(e) =>
                                              handleOnChange(
                                                e,
                                                obj.id,
                                                obj.regex,
                                                obj.id,
                                                index,
                                                obj.required,
                                                obj.type,
                                                obj.length
                                              )
                                            }
                                            maxlength={obj.length}
                                            required={obj.required}
                                            Onblur={() => onBlurValidation(obj)}
                                            className={
                                              errorBorder?.[obj.id]
                                                ? classes.borderRed
                                                : ''
                                            }
                                            infoIcon={
                                              accountPass.includes(obj.id) ||
                                              accountNum.includes(obj.id)
                                                ? true
                                                : false
                                            }
                                            infoTitle={
                                              accountPass.includes(obj.id)
                                                ? `Current Carrier Account Password`
                                                : ''
                                            }
                                            inputlabel={obj.text}
                                          />
                                        </Grid>
                                      ) : obj.type == 'tri-state' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} ${classes.tittle1} `}
                                        >
                                          <Typography style={{ color: 'gray' }}>
                                            {obj.text}
                                          </Typography>
                                          <Grid
                                            item
                                            className={classes.formLabelInline}
                                          >
                                            <RACRadio
                                              value={'1'}
                                              disabled={obj.disableInput}
                                              onChange={(e: any) =>
                                                handleOnChange(
                                                  e,
                                                  obj.id,
                                                  '',
                                                  obj.id,
                                                  obj.type,
                                                  obj.length,
                                                  'TRI',
                                                  '',
                                                  'YES'
                                                )
                                              }
                                              checked={
                                                customDataObj[obj.id] == '1'
                                                  ? true
                                                  : false
                                              }
                                              label="Yes"
                                              name="tristateRadioCheck"
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            className={classes.formLabelInline}
                                          >
                                            <RACRadio
                                              value={'0'}
                                              disabled={obj.disableInput}
                                              checked={
                                                customDataObj[obj.id] == '0'
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e: any) =>
                                                handleOnChange(
                                                  e,
                                                  obj.id,
                                                  '',
                                                  obj.id,
                                                  obj.type,
                                                  obj.length,
                                                  'TRU',
                                                  '',
                                                  'NO'
                                                )
                                              }
                                              label="No"
                                              name="tristateRadioCheck"
                                            />
                                          </Grid>
                                        </Grid>
                                      ) : obj.type == 'PHONENUMBER' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} ${classes.tittle1} `}
                                        >
                                          <RACTextbox
                                            inputLabelClassname={
                                              classes.marginBottom
                                            }
                                            type="phoneno"
                                            infoIconClassname={classes.imgStyle}
                                            disabled={obj.disableInput}
                                            value={
                                              customDataObj != undefined
                                                ? customDataObj[obj.id]
                                                : ''
                                            }
                                            OnChange={(e) =>
                                              handleOnChange(
                                                e,
                                                obj.id,
                                                obj.regex,
                                                obj.id,
                                                index,
                                                obj.required,
                                                obj.type,
                                                obj.length
                                              )
                                            }
                                            maxlength={14}
                                            Onblur={() => onBlurValidation(obj)}
                                            className={
                                              errorBorder?.[obj.id]
                                                ? classes.borderRed
                                                : ''
                                            }
                                            required={obj.required}
                                            inputlabel={obj.text}
                                          />
                                        </Grid>
                                      ) : obj.type == 'STRING' &&
                                        obj.id !== 'CRICKETTOS' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} ${classes.tittle1} `}
                                        >
                                          {obj.id.includes('PRDDISCR') ? (
                                            <Typography>
                                              <Typography
                                                style={{ fontSize: '14px' }}
                                              >
                                                {obj.text.split(',')[0]}{' '}
                                                {obj.text.split(',').length >
                                                  1 && (
                                                  <RACInfoIcon
                                                    infoTitle={
                                                      obj.text.split(',')[1]
                                                    }
                                                    className={
                                                      classes.racInfoStyle
                                                    }
                                                  />
                                                )}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  fontSize: '14px',
                                                  color: 'grey',
                                                }}
                                              >
                                                {customDataObj[obj.id]}
                                              </Typography>
                                            </Typography>
                                          ) : obj.readOnly ? (
                                            <Typography>
                                              <Typography
                                                style={{ fontSize: '14px' }}
                                              >
                                                {obj.text}
                                              </Typography>
                                              {customDataObj.innerHTMLpresent ? (
                                                <div
                                                  style={{
                                                    fontSize: '14px',
                                                    color: 'grey',
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      customDataObj[obj.id],
                                                  }}
                                                ></div>
                                              ) : (
                                                <Typography
                                                  style={{
                                                    fontSize: '14px',
                                                    color: 'grey',
                                                  }}
                                                >
                                                  {customDataObj[obj.id]}
                                                </Typography>
                                              )}
                                            </Typography>
                                          ) : (
                                            <RACTextbox
                                              inputLabelClassname={
                                                classes.marginBottom
                                              }
                                              type={'text'}
                                              infoIconClassname={
                                                classes.imgStyle
                                              }
                                              disabled={
                                                (obj.id.includes('PRDDISCR') &&
                                                  (customDataObj.PricePlan ||
                                                    customDataObj.PricePlan1 ||
                                                    customDataObj.PricePlan2 ||
                                                    customDataObj.PricePlan3 ||
                                                    customDataObj.PricePlan4)) ||
                                                obj.disableInput ||
                                                obj.readOnly
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                obj?.text == ''
                                                  ? obj?.defaultValue[0]
                                                  : obj.id != undefined &&
                                                    obj.id != ''
                                                  ? customDataObj[obj.id]
                                                  : ''
                                              }
                                              OnChange={(e) =>
                                                handleOnChange(
                                                  e,
                                                  obj.id,
                                                  obj.regex,
                                                  obj.id,
                                                  index,
                                                  obj.required,
                                                  obj.type,
                                                  obj.length
                                                )
                                              }
                                              maxlength={
                                                obj?.id?.includes('IMEI')
                                                  ? 15
                                                  : obj.id == 'PROMPT1'
                                                  ? 10
                                                  : obj.id == 'AddressZip'
                                                  ? 5
                                                  : obj?.length
                                              }
                                              required={obj.required}
                                              inputlabel={
                                                obj.id == 'PROMPT1'
                                                  ? obj.text.split('(')[0]
                                                  : obj.id == 'PRDDISCR'
                                                  ? obj.text.split(',')[0]
                                                  : obj.text
                                              }
                                              infoIcon={
                                                accountNum.includes(obj.id)
                                                  ? true
                                                  : accountPass.includes(obj.id)
                                                  ? true
                                                  : obj.id == 'PROMPT1'
                                                  ? true
                                                  : obj.id == 'PRDDISCR' &&
                                                    obj.text.split(',').length >
                                                      1
                                                  ? true
                                                  : false
                                              }
                                              Onblur={() =>
                                                onBlurValidation(obj)
                                              }
                                              className={
                                                errorBorder?.[obj.id]
                                                  ? classes.borderRed
                                                  : ''
                                              }
                                              infoTitle={
                                                accountNum.includes(obj.id)
                                                  ? `Current Carrier Account Number`
                                                  : accountNum.includes(obj.id)
                                                  ? `Current Carrier Account Password`
                                                  : obj.id == 'PROMPT1'
                                                  ? obj.text
                                                      .split('(')[1]
                                                      .split(')')[0]
                                                  : obj.id == 'PRDDISCR' &&
                                                    obj.text.includes(',')
                                                  ? obj.text.split(',')[1]
                                                  : obj.text
                                              }
                                            />
                                          )}
                                        </Grid>
                                      ) : obj.type == 'EMAIL' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart} ${classes.tittle1} `}
                                        >
                                          <RACTextbox
                                            inputLabelClassname={
                                              classes.marginBottom
                                            }
                                            type="email"
                                            infoIconClassname={classes.imgStyle}
                                            disabled={obj.disableInput}
                                            value={
                                              customDataObj != undefined
                                                ? customDataObj[obj.id]
                                                : ''
                                            }
                                            OnChange={(e) =>
                                              handleOnChange(
                                                e,
                                                obj.id,
                                                obj.regex,
                                                obj.id,
                                                index,
                                                obj.required,
                                                obj.type,
                                                obj.length
                                              )
                                            }
                                            maxlength={obj.length}
                                            required={obj.required}
                                            Onblur={() => onBlurValidation(obj)}
                                            className={
                                              errorBorder?.[obj.id]
                                                ? classes.borderRed
                                                : ''
                                            }
                                            inputlabel={obj.text}
                                          />
                                        </Grid>
                                      ) : obj.type == 'LIST' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                                        >
                                          <RACSelect
                                            inputLabelClassName={
                                              classes.marginBottom
                                            }
                                            classes={{
                                              nativeSelect: classes.dropdown1,
                                            }}
                                            isDisabled={obj.disableInput}
                                            inputLabel={obj.text}
                                            defaultValue={
                                              customDataObj &&
                                              customDataObj[obj.id] &&
                                              customDataObj[obj.id] !=
                                                undefined &&
                                              customDataObj[obj.id] != ''
                                                ? customDataObj[obj.id]
                                                : '0'
                                            }
                                            onChange={(e: any) =>
                                              handleOnChange(
                                                e,
                                                obj.id,
                                                '',
                                                obj.id,
                                                obj.type,
                                                obj.length
                                              )
                                            }
                                            required={obj.required}
                                            options={buildDropdownList(
                                              obj.options,
                                              obj && obj.length
                                            )}
                                          />
                                        </Grid>
                                      ) : obj.type == 'BIT' ? (
                                        <Grid
                                          item
                                          md={2}
                                          className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                                        >
                                          <RACCheckBox
                                            label={obj.text}
                                            disabled={obj.disableInput}
                                            value="1"
                                            checked={
                                              customDataObj &&
                                              customDataObj[obj.id] == '1'
                                                ? true
                                                : false
                                            }
                                            onChange={(e: any) =>
                                              handleOnChange(
                                                e,
                                                obj.id,
                                                '',
                                                obj.id,
                                                obj.type,
                                                obj.length,
                                                'CB'
                                              )
                                            }
                                            required={obj.required}
                                          />
                                        </Grid>
                                      ) : null}
                                    </>
                                  );
                                }
                              }
                            })
                          : null}
                        {multiplierFields?.consumerDiscount > 0 ? (
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            {' '}
                            <RACTextbox
                              className={classes.dollartext1}
                              inputLabelClassname={classes.marginBottom}
                              type="number"
                              name="consumerDiscountFeild"
                              value={(
                                (Number(multiplierFields.consumerDiscount) *
                                  Number(
                                    customDataObj.MonthsNum
                                      ? customDataObj.MonthsNum
                                      : '1'
                                  )) /
                                100
                              ).toFixed(2)}
                              disabled={true}
                              isCurrency={true}
                              maxlength={10}
                              required={true}
                              inputlabel="Consumer discount"
                            />
                          </Grid>
                        ) : null}
                        {multiplierFields.serviceFee > 0 ? (
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            {' '}
                            <RACTextbox
                              className={classes.dollartext1}
                              inputLabelClassname={classes.marginBottom}
                              type="number"
                              name="serviceFeeFeild"
                              value={Number(
                                multiplierFields.serviceFee
                              ).toFixed(2)}
                              disabled={true}
                              isCurrency={true}
                              maxlength={10}
                              required={true}
                              inputlabel="Service fee"
                            />
                          </Grid>
                        ) : null}
                        {amountFieldEnabled ? (
                          <Grid
                            item
                            md={2}
                            className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                          >
                            {' '}
                            <RACTextbox
                              className={classes.dollartext1}
                              inputLabelClassname={classes.marginBottom}
                              type="number"
                              name="amountField"
                              value={
                                amountValue != '' ? amountValue : amountVal
                              }
                              disabled={
                                amountValue != '' || amountEnabled
                                  ? true
                                  : false
                              }
                              OnChange={(e) => handleOnChange(e, 'amount')}
                              isCurrency={true}
                              maxlength={10}
                              required={true}
                              inputlabel="Amount"
                            />
                          </Grid>
                        ) : null}
                        {!requireAdditionalPir ? (
                          <Grid className={classes.w100}>
                            <Grid
                              item
                              md={2}
                              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                            >
                              <RACTextbox
                                className={classes.dollartext1}
                                inputLabelClassname={classes.marginBottom}
                                type="number"
                                name="salesPrice"
                                value={getSalesPrice().toFixed(2)}
                                disabled
                                OnChange={(e) =>
                                  handleOnChange(e, 'salesPrice')
                                }
                                isCurrency={true}
                                maxlength={30}
                                inputlabel="Sales Price"
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                            >
                              <RACTextbox
                                className={classes.dollartext1}
                                inputLabelClassname={classes.marginBottom}
                                type="number"
                                name="salesTax"
                                disabled
                                value={
                                  initialObj.salesTax == ''
                                    ? '0.00'
                                    : initialObj.salesTax
                                }
                                // OnChange={(e) => handleOnChange(e, 'salesTax')}
                                isCurrency={true}
                                maxlength={30}
                                inputlabel="Sales Tax"
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                            >
                              <RACTextbox
                                className={classes.dollartext1}
                                inputLabelClassname={classes.marginBottom}
                                type="number"
                                name="fee"
                                disabled
                                value={
                                  initialObj.fee == '' ? '0.00' : initialObj.fee
                                }
                                // OnChange={(e) => handleOnChange(e, 'fee')}
                                isCurrency={true}
                                maxlength={30}
                                inputlabel="911 Fee"
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              className={` ${classes.tittles} ${classes.equal} ${classes.floatStart}`}
                            >
                              <RACTextbox
                                className={classes.dollartext1}
                                inputLabelClassname={classes.marginBottom}
                                type="number"
                                name="totalSales"
                                disabled
                                value={(
                                  Number(getSalesPrice().toFixed(2)) +
                                  Number(initialObj.salesTax) +
                                  Number(initialObj.fee)
                                )
                                  .toFixed(2)
                                  .toString()}
                                // OnChange={(e) => handleOnChange(e, 'totalSales')}
                                isCurrency={true}
                                maxlength={30}
                                inputlabel="Total Sales"
                              />
                            </Grid>
                            {termsAndCondition !== '' &&
                            termsAndCondition !== null &&
                            termsAndCondition.length > 0 ? (
                              <Grid
                                md={12}
                                className={` ${classes.w100} ${classes.floatLeft} ${classes.mtop20} `}
                              >
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {'Terms of Service '}{' '}
                                  <span
                                    style={{
                                      color: 'red',
                                      fontSize: '13px',
                                      marginBottom: '2px',
                                    }}
                                  >
                                    *
                                  </span>
                                </Typography>

                                <Grid className={` ${classes.floatLeft}`}>
                                  <RACCheckBox
                                    style={{ float: 'left' }}
                                    disabled={false}
                                    value={istermsAndConditionCheck}
                                    onChange={(e: any) => termcheck()}
                                    required={true}
                                  />
                                </Grid>
                                <Grid
                                  md={10}
                                  className={` ${classes.w100} ${classes.floatLeft} ${classes.marginTop} `}
                                >
                                  <Typography style={{ color: 'gray' }}>
                                    {
                                      termsAndCondition[0]?.childNodes[0]
                                        ?.childNodes[0]?.innerHTML
                                    }
                                    <Typography style={{ color: 'gray' }}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            termsAndCondition[0]?.childNodes[0]
                                              ?.childNodes[2]?.innerHTML,
                                        }}
                                      />
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : null}
                          </Grid>
                        ) : null}
                        {!isContinueBtnHidden ? (
                          <RACButton
                            variant="contained"
                            color="primary"
                            className={classes.me1}
                            disabled={isFeildValid}
                            onClick={() => runAPICalls()}
                            style={{ marginTop: '40px', height: '40px' }}
                          >
                            Continue
                          </RACButton>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </CardContent>
            </Card>
            <Grid
              item
              classes={{
                root: clsx(classes.customerToolbar, classes.fixedBottom),
              }}
              style={{ zIndex: 0 }}
            >
              <Grid item className={classes.floatLeft}>
                <RACButton
                  variant="outlined"
                  color="primary"
                  className={`${classes.me1} ${classes.foc}`}
                  onClick={() => cancelBtn()}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid className={classes.floatRight}>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.me1}
                  onClick={() => clearBtn()}
                >
                  Clear
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.me1}
                  disabled={
                    props && props.editItemId && props.editItemId > 0
                      ? false
                      : true
                  }
                  onClick={() => submitBtnClicked('RemovePayment')}
                >
                  Remove Payment
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.me1}
                  disabled={
                    submitBtnEnabled == false &&
                    istermsAndConditionCheck == true
                      ? false
                      : true
                  }
                  onClick={() => submitBtnClicked('SubmitClick')}
                >
                  Submit
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
          {/* <RACModalCard
                    isOpen={successpopup}
                    maxWidth="xs"
                    onClose={() => cancelPopup()}
                    closeIcon={true}
                    //eslint-disable-next-line react/no-children-props
                    children={successPopup()}
                    title="Success Message"
                    borderRadius={"15px"}
                /> */}
          {SecondFactorOpen ? (
            <SecondFactor
              setTwoFactorCancelClick={setTwoFactorCancelClick}
              setTwoFactorCompleted={setTwoFactorCompleted}
              setEmployeeDetails={setEmployeeDetails}
            />
          ) : null}
        </>
      </Grid>
    );
  };
  return props.isOpen ? childnonSale() : null;
}

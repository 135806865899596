/* eslint-disable prettier/prettier */
/* eslint-disable react/no-children-prop */
import React, { useRef, useContext } from "react";
import { Grid, RACButton, RACModalCard } from "@rentacenter/racstrap";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";

export default function ReverseReceipt() {
  const classes = reversePaymentStyle();
  const eventFrame = useRef<HTMLIFrameElement>(null);
  const { openReverseReceipt, reverseReceiptURL } =
    useContext(GeneralStateContext);
  const { setOpenReverseReceipt, setOpenViewReceipt } = useContext(
    GeneralStateDispatchContext
  );

  const receiptPopUp = () => {
    return (
      <Grid item className={classes.paymentmodal}>
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="600px !important"
            src={`${reverseReceiptURL}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => {
              setOpenReverseReceipt(false);
              setOpenViewReceipt(true);
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => eventFrame.current?.contentWindow?.print()}
          >
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // This method will be rendering the receipts of the reversal.
  return (
    <RACModalCard
      isOpen={openReverseReceipt}
      className={classes.customModal}
      closeIcon={true}
      onClose={() => {
        setOpenReverseReceipt(false);
        setOpenViewReceipt(true);
      }}
      borderRadius={"25px !important"}
      maxWidth="lg"
      title={"Receipt"}
      children={receiptPopUp()}
    />
  );
}

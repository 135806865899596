/* eslint-disable */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const nonInventorySaleStyles = () =>{
    const useClasses = makeStyles((theme) => ({
        borderRed: {
          border: '1px solid red',
          borderRadius: '5px',
        },
        mtop30: {
          marginTop: '30px',
        },
        mtop20: {
          marginTop: '20px',
        },
        w100: {
          width: '100%',
        },
        loaderStyle: {
          border: 'none',
          backgroundColor: 'transparent',
          // userFocus: 'none',
          // MozUserFocus: 'none',
          outline: 'none',
        },
        RACLoaderPage: {
          textAlign: 'center',
          marginTop: '250px',
          fontSize: '16px',
          marginLeft: '30px',
          marginRight: '30px',
          outline: 'none',
        },
        card: {
          borderRadius: '16px!important',
          boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
          width: '100%',
        },
        title: {
          color: '#212529',
          fontFamily: 'OpenSans-bold!important',
          fontSize: '28px!important',
          marginBottom: '10px!important',
        },
        title1: {
          color: '#212529',
          fontFamily: 'OpenSans-bold!important',
          fontSize: '19px!important',
          marginBottom: '10px!important',
        },
        titleColor: {
          color: 'black',
          marginLeft: '10px',
          marginTop: '15px',
        },
        titleColor1: {
          color: 'black',
          marginLeft: '3px',
          marginTop: '14px',
        },
        infoTextStyle: {
          color: 'gray',
          fontSize: theme.typography.pxToRem(13),
          textAlign: 'center',
        },
        my4: {
          marginTop: theme.typography.pxToRem(24),
          marginBottom: theme.typography.pxToRem(24),
        },
        mb5: {
          marginBottom: theme.typography.pxToRem(48),
        },
        pt2: {
          paddingTop: theme.typography.pxToRem(8),
        },
        px2: {
          paddingRight: theme.typography.pxToRem(8),
          paddingLeft: theme.typography.pxToRem(8),
        },
        cardHeight: {
          height: ' 5.9375rem',
        },
        cardHeight1: {
          height: 'auto',
          marginTop: '26px',
        },
        headtag: {
          marginTop: '17px',
          marginLeft: '19px',
        },
        fixedFooter: {
          bottom: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'space-between',
          padding: theme.typography.pxToRem(16),
          backgroundColor: '#ffffff',
          boxShadow: '0 0 3px #d9d9d9',
        },
        ml3: {
          marginLeft: theme.typography.pxToRem(16),
        },
        p2: {
          padding: theme.typography.pxToRem(8),
        },
        modalBody: {
          position: 'relative',
          flex: '1 1 auto',
          padding: '1rem',
          width: '98%',
        },
        modalContent: {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          pointerEvents: 'auto',
          backgroundColor: '#fff',
          backgroundClip: 'padding-box',
          outline: '0',
        },
        me2: {
          marginRight: '0.5rem !important',
          marginLeft: '10rem',
        },
        floatStart: {
          float: 'left',
        },
        floatStart1: {
          float: 'left',
          '& input': {
            height: '24px',
          },
        },
        mb3: {
          marginBottom: theme.typography.pxToRem(16),
        },
        formLabel: {
          marginBottom: '0.2rem',
          color: '#111111',
          //eslint-disable-next-line sonarjs/no-duplicate-string
          fontFamily: 'OpenSans-semibold',
          fontSize: '14px',
          marginLeft: '10px',
        },
        textCenter: {
          textAlign: 'center',
        },
        px3: {
          paddingRight: '1rem !important',
          paddingLeft: '1rem !important',
        },
        pb4: {
          paddingBottom: '1.5rem !important',
        },
        colMd12: {
          flex: '0 0 auto',
          width: '100%',
        },
        center: {
          textAlign: 'center',
          margin: '12px',
        },
        right: {
          marginRight: '10px',
        },
        right1: {
          marginRight: '15px',
        },
        equal: {
          width: '55%',
          marginRight: '15px',
          marginBottom: '15px',
        },
        equal1: {
          width: '55%',
          marginRight: '15px',
          marginBottom: '15px',
          paddingBottom: '25px',
        },
        leftpadding: {
          paddingLeft: '9.5rem',
        },
        heading: {
          fontSize: '20px',
        },
        tittles: {
          marginTop: '10px',
          marginBottom: '7px',
        },
        tittlesBreadCrums: {
            marginTop: '10px',
            marginBottom: '7px',
          },
        tittle1: {
          marginTop: '10px',
          marginBottom: '7px',
          '& input': {
            padding: '10px !important',
          },
        },
        dropdown1: {
          '& div': {
            padding: '10px 9px',
          },
        },
        tittles1: {
          marginTop: '10px',
          marginBottom: '7px',
        },
        textstyle: {
          marginRight: '10px',
          marginBottom: '25px',
        },
        textstyle1: {
          marginRight: '25px',
          marginTop: '25px',
        },
        dollartext1: {
          width: '87% !important',
          '& input': {
            height: '21px',
          },
          // width: '13% !important',
          // padding: '6px 14px !important',
          // marginBottom: '25px !important',
        },
        marginTop: {
          marginTop: '5px',
        },
        textcolor: {
          color: 'blue',
        },
        cardborder: {
          border: '0 !important',
        },
        BreadCrumbInactive: {
          '&:after': {
            content: "''",
            width: 0,
            height: 0,
            borderTop: '5px solid transparent',
            borderLeft: '10px solid #555',
            borderBottom: '5px solid transparent',
          },
        },
        marginBottom: {
          marginBottom: '10px !important',
        },
        marginBottom1: {
          marginBottom: '10px !important',
          '& input': {
            padding: '10px',
          },
        },
        marginright: {
          marginRight: '20px',
        },
        typeMargin: {
          padding: '1px',
          '& input': {
            height: '24px',
          },
        },
        screenTop: {
          marginTop: '22px',
        },
        imgStyle: {
          paddingBottom: '10px',
        },
        outlinedBtn: {
          padding: '0.7rem 2rem',
        },
        zipCodeClass: {
          '& div': {
            '& div': {
              '& div': {
                '& input': {
                  padding: '10px',
                },
              },
            },
          },
        },
        marginCustName: {
          marginTop: '10px',
          color: '#2179FE',
        },
        marginCustID: {
          marginTop: '10px',
          color: 'gray',
        },
        paddingCustname: {
          padding: '10px',
        },
        customerToolbar: {
          position: 'fixed',
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1030,
          backgroundColor: 'white',
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          paddingLeft: theme.typography.pxToRem(16),
          paddingRight: theme.typography.pxToRem(16),
          paddingTop: theme.typography.pxToRem(16),
          paddingBottom: theme.typography.pxToRem(16),
        },
        fixedBottom: {
          position: 'fixed',
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1030,
          backgroundColor: 'white',
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          padding: '1rem',
        },
        floatLeft: {
          float: 'left',
        },
        floatRight: {
          float: 'right',
        },
        me1: {
          marginRight: theme.typography.pxToRem(4),
        },
        foc: {
          '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '1px 1px 6px 3px #80bdff',
          },
        },
        racFixedFooterMargin: {
          marginBottom: '120px',
        },
        spacerMT4: {
          marginTop: theme.typography.pxToRem(24),
        },
        RACPOPMsg: {
          fontFamily: 'OpenSans-bold',
          fontSize: theme.typography.pxToRem(16),
          letterSpacing: 0,
        },
        justifyContentCenter: {
          justifyContent: 'center',
        },
        formLabelInline: {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          display: 'inline-block',
          paddingLeft: 0,
        },
        custDetailSection: {
          display: 'flex',
          marginLeft: '20px',
          width: '50%',
          justifyContent: 'space-around',
        },
        racInfoStyle: {
          marginLeft: '5px',
          position: 'absolute',
        },
        custDigit: {
          width: "83%",
          "& input": {
            textAlign: "right",
          },
          borderBottomLeftRadius: "0px",
          borderTopLeftRadius: "0px",
        },
        cashtxtbox: {
          "& input": {
            borderTopLeftRadius: "0px!important",
            borderBottomLeftRadius: "0px!important",
          },
        },
        inputgrouptext: {
          display: "flex",
          alignItems: "center",
          padding: "0.375rem 0.75rem",
          fontSize: "1rem",
          lineHeight: "1.5",
          color: "#212529",
          textAlign: "center",
          whiteSpace: "nowrap",
          backgroundColor: "#e9ecef",
          borderBottomRightRadius: "0rem",
          borderTopRightRadius: "0rem",
          borderTopLeftRadius: "0.6rem",
          borderBottomLeftRadius: "0.6rem",
        },
        cashdollor: {
          padding: "3px 12px !important",
        },
        description: {
          margin: "10px 0px",
        },
        textArea: {
          margin: "10px 0px",
          width: 1000,
          padding: "6PX",
          borderRadius: "4px",
          resize: "none",
        },
        pr2: {
          paddingRight: theme.typography.pxToRem(8),
        },
        m2: {
          margin: theme.typography.pxToRem(8),
        },
        dflex: {
          display: 'flex',
        },
        p0: {
          padding: '0 !important',
        },
        widthAuto: {
          width: 'auto',
        },
        fontSemiBold: {
          fontFamily: 'OpenSans-semibold',
        },
        textBlack: {
          color: '#000000',
        },
        textBlue: {
          color: '#2179FE',
        },
      }));

      const classes = useClasses();

      return classes
}
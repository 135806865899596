/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */


/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */


/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { CustomerdataContext } from '../../context/customer/customerdata';
// import { validateObject } from '../Shared/validateObject';
// import NumberFormat from "react-number-format";
// import moment from "moment";
// import { globalStyles } from "../componentstyles/globalstyles"
// import DynamicGridRA from '../InventoryTabs/AgreementHistory/dynamicGrids/dynamicGridRA'
// import DynamicGridLA from '../InventoryTabs/AgreementHistory/dynamicGrids/dynamicGridLA'
// import DynamicGridPA from '../InventoryTabs/AgreementHistory/dynamicGrids/dynamicGridPA'
// import DynamicGridComponent from '../InventoryTabs/IdleInventoryAudit/dynamicGrid'
export const PaymentHistoryPrintInfo = (props: any) => {
    const {customerdata, setcustomerdata}=useContext(CustomerdataContext);
    // const classes = globalStyles()
    // const [inventoryInfo, setinventoryInfo] = useState<any>({})
    // const [inventoryDetails, setinventoryDetails] = useState<any>({})
    // const [activity, setactivity] = useState<any>({
    //     activity:{},
    //     idleAudit:{},
    //     agrHistory:{}
    // })
    

    // const sortDirection = { ASC: 'asc', DESC: 'desc' }
    let result:any=undefined
    let customerPrimaryAddress:any="";
    let customerSecondaryAddress:any="";
    let customerCity="";
    let customerdob="";
    let customerPhoneNumber="";
    let customerEmailAdress="";
    let customerFname="";
    let customerLname="";
    let customerPlusFour="";
    let customerZip=""
    let customerState=""
    let besttimetocall=""
    let schedule=""
    let datepaid=""




    // useEffect(() => {
        // BindGrid()
        result=customerdata
        
    // const primaddress = result.data.GetCustomer.value.addresses.filter(
    //   (x) => x.addressType === "PRIM"
    // );
        if(result!=undefined&&result!=""){
            debugger;
            result=result.data
            if(result?.GetCustomer?.value?.addresses!=undefined){
              let customerAdressFilterArray:any=result?.GetCustomer?.value?.addresses?.filter((x)=>
                x.addressTypeDesc=="Primary Address"
              )
              if(customerAdressFilterArray.length==0&&result?.GetCustomer?.value?.addresses.length>0){
                customerAdressFilterArray=[result?.GetCustomer?.value?.addresses[0]]
              }
              customerPrimaryAddress=customerAdressFilterArray.length>0?customerAdressFilterArray[0].addressLine1:""
              customerSecondaryAddress=customerAdressFilterArray.length>0?customerAdressFilterArray[0].addressLine2:""
              customerPlusFour=customerAdressFilterArray.length>0?customerAdressFilterArray[0].postalCode != null?customerAdressFilterArray[0].postalCode?.split("-")[1]:"":""
              customerZip=customerAdressFilterArray.length>0?customerAdressFilterArray[0].postalCode!=null?customerAdressFilterArray[0].postalCode?.split("-")[0]:"":""
              customerState=customerAdressFilterArray.length>0?customerAdressFilterArray[0].state:""
              customerCity=customerAdressFilterArray.length>0?customerAdressFilterArray[0].city:""
            }
            customerdob=result?.GetCustomer?.value?.dateOfBirth
            let customerPhoneDetails:any=result?.GetCustomer?.value?.phones?.filter((x)=>
              x.primary=="Y"
            )
            if(customerPhoneDetails.length==0&&result?.GetCustomer?.value?.phones.length>0){
              customerPhoneDetails=[result?.GetCustomer?.value?.phones[0]]
            }
            // else{
            //   customerPhoneDetails=[]
            // }
            customerPhoneNumber=customerPhoneDetails.length>0?customerPhoneDetails[0]?.phoneNumber:""
            customerEmailAdress=result?.GetCustomer?.value?.emailAddress
            customerFname=result?.GetCustomer?.value?.firstName
            customerLname=result?.GetCustomer?.value?.lastName

            // const primaddress = result.GetCustomer.value.addresses.filter(
            //   (x) => x.addressType === "PRIM"
            // );

            const primaryphone = result?.GetCustomer?.value?.phones.filter(
              (x) => x.primary === "Y"
            );

            
             besttimetocall =primaryphone[0]?.callTimeTypeDesc

             schedule = customerdata==undefined?undefined:customerdata.data?.GetCustomer?.value==undefined?undefined:customerdata.data?.GetCustomer?.value?.employerReferences==undefined?undefined:customerdata.data?.GetCustomer?.value?.employerReferences[0].employerPayscheduleDesc
             datepaid = customerdata==undefined?undefined:customerdata.data?.GetCustomer?.value==undefined?undefined:customerdata.data?.GetCustomer?.value?.employerReferences==undefined?undefined:customerdata.data?.GetCustomer?.value?.employerReferences[0].daysPaidDesc

          }
        


    const BindGrid = () => {

      
    
          return props.paygridvalues.map((value: any, index: any) => {
    
            return (
    
             <tr key={index}>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.datePaid}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.dayPaid}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.timePaid}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.receiptId}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right", "paddingRight": "20px"}}> {" "}
                  {(value.isReversed == 1) ? ("(") : null}
                  $
                  {value.totalPayment}
                  {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{" "}
                  {value.nextDueDate}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right", "paddingRight": "20px"}}>{" "}
                  {value.daysExt}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right", "paddingRight": "20px"}}>
                  {value.daysLate}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{" "}
                  {value.store}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"left", "paddingRight": "20px"}}>{" "}
                  {value.paymentOrigin}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{" "}
                  {value.tenderType}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right"}}> {" "}
                  {(value.isReversed == 1) ? ("(") : null}
                  $
                  {value.netRent}
                  {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right"}}>{" "}
              {(value.isReversed == 1) ? ("(") : null}
                    $
                    {value.clubAmount}
                    {(value.isReversed == 1) ? (")") : null}</td>
            </tr>
    
    
            )
          })
    }

    const BindGridSummery=()=>{
        return props.paygridvalues.map((value: any, index: any) => {
  
            return (
    
             <tr key={index}>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.datePaid}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.dayPaid}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.timePaid}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px"}}>{value.receiptId}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right", "paddingRight": "20px"}}> {" "}
                  {(value.isReversed == 1) ? ("(") : null}
                  $
                  {value.totalPayment}
                  {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right"}}>
                    {" "}
                    {(value.isReversed == 1) ? ("(") : null}
                    $
                    {value.netRent}
                    {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px" , "textAlign":"right"}}>{" "}
              {(value.isReversed == 1) ? ("(") : null}
                      $
                      {value.clubAmount}
                      {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right"}}>
                   {" "}
                    {(value.isReversed == 1) ? ("(") : null}
                    $
                    {value.ldw}
                    {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right"}}>
              {" "}
                    {(value.isReversed == 1) ? ("(") : null}
                    $
                    {value.lateFee}
                    {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right"}}>
              {" "}
                    {(value.isReversed == 1) ? ("(") : null}
                    $
                    {value.discount}
                    {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px", "textAlign":"right"}}>
              {" "}
                    {(value.isReversed == 1) ? ("(") : null}
                    $
                    {value.other}
                    {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px" , "textAlign":"right"}}>
              {" "}
                    {(value.isReversed == 1) ? ("(") : null}
                    $
                    {value.tax}
                    {(value.isReversed == 1) ? (")") : null}</td>
              <td style={{"paddingTop":"15px", "fontWeight":"600", "fontSize": "13px" , "textAlign":"right"}}></td>
            </tr>
    
    
            )
          })
    }


      

    
    
    return (
       <div style={{display:"none"}}>
         
      
        <div style={{"width":"100%","float":"left"}} id={"PaymentAgreementPrintInfo"}>
        {/*top section starts here*/}    
        <div style={{"width":"100%","float":"left"}}>
          <div style={{"width":"50%","float":"left"}}>
            <h4 style={{"fontWeight":"bold"}}>Payment History by Customer</h4>
          </div>
          <div style={{"width":"50%","float":"left","marginTop":"25px"}}>
            <span style={{"fontWeight":"600","fontSize":"13px"}}>Date :</span>
            <span style={{"fontWeight":"600","fontSize":"13px"}}>{moment().format('MM/DD/YYYY')}</span>
          </div>
        </div>
        <div style={{"width":"100%","float":"left","marginBottom":"20px"}}>
          <span style={{"fontWeight":"600","fontSize":"13px"}}>Store #:</span>
          <span style={{"fontWeight":"600","fontSize":"13px"}}>{String(window.sessionStorage.getItem("storeNumber"))}</span>
        </div>
        {/*top section ends here*/}    
        {/*Customer information section starts here*/}   
        <div style={{"width":"100%","float":"left","marginBottom":"20px"}}>
          <div style={{"width":"45%","float":"left"}}>
            <label style={{"borderBottom":"1px solid #000","width":"85%","float":"left","paddingBottom":"8px","paddingRight":"20px","fontWeight":"bold","fontSize":"13px"}}>Customer Information</label> 
            <label style={{"width":"100%","float":"left","marginTop":"7px","marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>{props.printInfo.commoninformation.customerName}</label>
            <span style={{"width":"100%","float":"left","marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>{customerPrimaryAddress==""?customerSecondaryAddress:customerPrimaryAddress}</span>
            <span style={{"width":"100%","float":"left","marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>{customerCity}, {customerState} {customerZip}-{customerPlusFour}</span>
            <div style={{"width":"50%","float":"left"}}>
              <span style={{"marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>Phone # :</span>
              <span style={{"marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>{customerPhoneNumber}</span>
            </div>    
            <div style={{"width":"50%","float":"left","marginBottom":"10px"}}>
              <span style={{"fontWeight":"600","fontSize":"13px"}}>Best Time To Call :</span>
              <span style={{"fontWeight":"600","fontSize":"13px"}}>{besttimetocall}</span>
            </div>  
            <span style={{"width":"50%","float":"left","marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>Alt Phone # :</span>
            <span style={{"fontWeight":"600","fontSize":"13px"}}>{}</span>

            <span style={{"width":"50%","float":"left","marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>Best Time To Call :</span>
            <span style={{"fontWeight":"600","fontSize":"13px"}}>{}</span>

          </div>
          <div style={{"width":"25%","float":"left"}}>
            <label style={{"borderBottom":"1px solid #000","width":"50%","float":"left","paddingBottom":"8px","fontWeight":"bold","fontSize":"13px"}}>Payment Schedule</label>
            <label style={{"width":"100%","float":"left","marginTop":"7px","marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>{schedule || "-"}</label>
          </div>
          <div style={{"width":"30%","float":"left"}}>
            <label style={{"borderBottom":"1px solid #000","width":"70%","float":"left","paddingBottom":"8px","fontWeight":"bold","fontSize":"13px"}}>Day Paid</label>
            <label style={{"width":"100%","float":"left","marginTop":"7px","marginBottom":"10px","fontWeight":"600","fontSize":"13px"}}>{datepaid}</label>
          </div>
        </div>
        {/*Customer information section ends here*/}   
        {/*Agreeements section starts here*/}   
        <div style={{"width":"100%","float":"left","marginBottom":"20px"}}>
          <div style={{"width":"11%","float":"left","paddingRight":"10%"}}>
            <label style={{"width":"90%","float":"left","borderBottom":"1px solid #000","paddingBottom":"8px","fontWeight":"600","fontSize":"13px"}}>PIF Agreements</label>
            <label style={{"width":"90%","float":"left","paddingTop":"15px","textAlign":"right","fontWeight":"600","fontSize":"13px"}}>{props.printInfo.commoninformation.pifAgreements}</label>
          </div>
          <div style={{"width":"11%","float":"left","paddingRight":"10%"}}>
            <label style={{"width":"95%","float":"left","borderBottom":"1px solid #000","paddingBottom":"8px","fontWeight":"600","fontSize":"13px"}}>EPO Agreements</label>
            <label style={{"width":"95%","float":"left","paddingTop":"15px","textAlign":"right","fontWeight":"600","fontSize":"13px"}}>{props.printInfo.commoninformation.epoAgreements}</label>
          </div>
          <div style={{"width":"11%","float":"left","paddingRight":"10%"}}>
            <label style={{"width":"80%","float":"left","borderBottom":"1px solid #000","paddingBottom":"8px","fontWeight":"600","fontSize":"13px"}}>Total Revenue</label>
            <label style={{"width":"80%","float":"left","paddingTop":"15px","textAlign":"right","fontWeight":"600","fontSize":"13px"}}>{props.printInfo.commoninformation.totalRentalRevenue}</label>
          </div>
          <div style={{"width":"11%","float":"left","paddingRight":"8%"}}>
            <label style={{"width":"72%","float":"left","borderBottom":"1px solid #000","paddingBottom":"8px","fontWeight":"600","fontSize":"13px"}}>NSF Checks</label>
            <label style={{"width":"72%","float":"left","paddingTop":"15px","textAlign":"right","fontWeight":"600","fontSize":"13px"}}>{props.printInfo.commoninformation.nsfChecks}</label>
          </div>
          <div style={{"width":"11%","float":"left","paddingRight":"7%"}}>
            <label style={{"width":"93%","float":"left","borderBottom":"1px solid #000","paddingBottom":"8px","fontWeight":"600","fontSize":"13px"}}>Customer Since</label>
            <label style={{"width":"93%","float":"left","paddingTop":"15px","textAlign":"right","fontWeight":"600","fontSize":"13px"}}>{props.printInfo.commoninformation.customerSince}</label>
          </div>
        </div>
        {/*Agreeements section ends here*/} 
        {/*Payment History Summary section starts here*/} 
        <div style={{"width":"100%","float":"left","marginBottom":"20px"}}>
          <h4 style={{"fontWeight":"bold","fontSize":"13px"}}>Payment History Summary</h4>
          <table style={{"borderCollapse":"collapse","width":"100%"}}>
            <thead>
              <tr>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Date Paid</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Day</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Time</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Receipt #</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontWeight":"bold","fontSize":"13px","paddingRight":"20px"}}>Total Payment</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Next Due Date</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Free Days</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold","paddingRight":"20px"}}>Days Late</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Store</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Payment Origin</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Tender Type</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Net Rent</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Club</th>
              </tr>
            </thead>
            <tbody>
              {BindGrid()}
            </tbody>
          </table>
        </div>
        {/*Payment History Summary section ends here*/} 
        {/*Payment History Detail section starts here*/} 
        <div style={{"width":"100%","float":"left","marginBottom":"20px"}}>
          <h4 style={{"fontWeight":"bold","fontSize":"13px"}}>Payment History Detail</h4>
          <table style={{"borderCollapse":"collapse","width":"100%"}}>
            <thead>
              <tr>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Date Paid</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Day</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Time</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"left","fontSize":"13px","fontWeight":"bold"}}>Receipt #</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontWeight":"bold","fontSize":"13px","paddingRight":"20px"}}>Total Payment</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Net Rent</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Club</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>LDW</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Late Fee</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Discount</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Other</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Tax</th>
                <th style={{"borderBottom":"1px solid #000","color":"#000","paddingBottom":"8px","textAlign":"right","fontSize":"13px","fontWeight":"bold"}}>Card Type</th>
              </tr>
            </thead>
            <tbody>
              {BindGridSummery()}
            </tbody>
          </table>
        </div>
        {/*Payment History Detail section ends here*/} 
      </div>
      </div>
    )


}
export default PaymentHistoryPrintInfo
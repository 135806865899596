/*eslint-disable*/
/**PS_CO_NO_OS_29
 * Required hooks and the racstrap component are imported
 */
import React, { useState, useEffect, useContext } from "react";
import {
  RACButton,
  Grid,
  Typography,
  RACModalCard,
} from "@rentacenter/racstrap";
import { agreementContext } from "../../context/PaymentInformationContext";
import { takePaymentStyles } from "../../../stylesJS/makeStyles";
import { OptionalServiceProps } from "../../interface/optionalServiceInterface";
import { AgreementDetails } from "../../interface/paymentInfoInterface";
import CONSTANTS from "../../constants/constant";
import { AgreementContextValue } from "../../interface/contextInterface";

/**PS_CO_NO_OS_30
 * Declare the parent function OptionalService() which handles the activate LDW, Policy and the deactivate LDW, Policy
 */
export function OptionalService(props: OptionalServiceProps) {
  /**
   * PS_CO_NO_OS_31 - PS_CO_NO_OS_32
   * The state variable declaration is done here
   */
  const classes = takePaymentStyles();
  const {
    renderContext,
    setRenderContext,
    agreementDetails,
    setAgreementDetails,
    setCarryRentCalci,
    carryRentCalci,
  } = useContext<AgreementContextValue>(agreementContext);
  const [fee, setFee] = useState(CONSTANTS.ZREO_TWO_DECIMAL_STRING);

  /**PS_CO_NO_OS_33 - PS_CO_NO_OS_35
   * useEffec is used to calculate the LDW\Policy fee
   */
  useEffect(() => {
    if (
      props.optionalServiceFlag.activateLDW ||
      props.optionalServiceFlag.terminateLDW
    ) {
      const ldwAmountWithTax: string = Number(
        Number(props.selectedAgreements[0]?.ldwAmount) +
          Number(props.selectedAgreements[0]?.ldwTax)
      ).toFixed(2);
      setFee(ldwAmountWithTax);
    } else if (
      props.optionalServiceFlag.activatePolicy ||
      props.optionalServiceFlag.deactivatePolicy
    ) {
      const policyAmountWithTax: string = Number(
        Number(props.selectedAgreements[0]?.policyAmount) +
          Number(props.selectedAgreements[0]?.policyTax)
      ).toFixed(2);
      setFee(policyAmountWithTax);
    }
  }, []);

  /**
   * PS_CO_NO_OS_42- PS_CO_NO_OS_44
   * This mehtod is invoked when the user click the continue
   * button while activate policy/LDW
   */


  const continueActivateLDWPolicy = (type: string) => {
    let amount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    let tax = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    if (props?.index != undefined && props?.index>=0) {
      if (type == CONSTANTS.LDW) {
        amount = Number(
          Number(agreementDetails[props.index].totalDueAmount) + Number(fee)
        ).toFixed(2);
        tax = Number(
          Number(agreementDetails[props.index].totalTax) +
            Number(props.selectedAgreements[0].ldwTax)
        ).toFixed(2);
        // setAgreementDetails([{...agreementDetails[props.index],isLDWActionPerformed:'Y',isCoverageActionPerformed:'Y',totalDueAmount:amount,totalTax:tax}]);
        setAgreementDetails((prevDetails: AgreementDetails[]) =>
          prevDetails.map((detail: AgreementDetails, i: number) =>
            i === props.index
              ? {
                  ...detail,
                  totalDueAmount: amount,
                  totalAmount: Number(Number(props.selectedAgreements[0].totalAmount) + Number(props.selectedAgreements[0].ldwAmount)).toFixed(2),
                  totalTax: tax,
                  isLDWActionPerformed: CONSTANTS.STATUS_YES,
                  isLDW: true,
                  isCoverageActionPerformed: CONSTANTS.STATUS_YES,
                }
              : detail.agreementType == CONSTANTS.CLUB && detail.disabled && !detail.deactivateClubFlag && detail.storeNumber== String(window.sessionStorage.getItem('storeNumber'))
               ?{
                ...detail,
                selected: true,
                disabled: false,
               }
               :detail
          )
        );
        setCarryRentCalci(!carryRentCalci);
      } else if (type == CONSTANTS.POLICY) {
        amount = Number(
          Number(agreementDetails[props.index].totalDueAmount) + Number(fee)
        ).toFixed(2);
        tax = Number(
          Number(agreementDetails[props.index].totalTax) +
            Number(props.selectedAgreements[0].policyTax)
        ).toFixed(2);
        // setAgreementDetails([{...agreementDetails[props.index],isPolicyActionPerformed:'Y',isCoverageActionPerformed:'Y', totalDueAmount:amount, totalTax:tax}]);
        setAgreementDetails((prevDetails: AgreementDetails[]) =>
          prevDetails.map((detail: AgreementDetails, i: number) =>
            i === props.index
              ? {
                  ...detail,
                  totalDueAmount: amount,
                  totalTax: tax,
                  totalAmount: Number(Number(props.selectedAgreements[0].totalAmount) + Number(props.selectedAgreements[0].policyAmount)).toFixed(2),
                  isPolicyActionPerformed: CONSTANTS.STATUS_YES,
                  isPolicy: true,
                  isCoverageActionPerformed: CONSTANTS.STATUS_YES,
                }
              : detail
          )
        );
      }
    }
    setRenderContext(!renderContext);
    props.setOptionalServiceFlag(false);
    props.setLdwStatusFlag({
      ...props.ldwStatusFlag,
      coverageStatus: CONSTANTS.STATUS_YES,
    });
  };
/**
 * PS_CO_NO_OS_55 - PS_CO_NO_OS_58
 * While deactivate policy/LDW this method is invoked
 *
 * @param type
 */
  const ContinueTerminateLDWPolicy = (type: string) => {
    let amount = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    let tax = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    if (props?.index != undefined && props?.index>=0) {
      if (type == CONSTANTS.LDW) {
        amount = Number(
          Number(agreementDetails[props?.index].totalDueAmount) - Number(fee)
        ).toFixed(2);
        tax = Number(
          Number(agreementDetails[props?.index]?.totalTax) -
            Number(props.selectedAgreements[0]?.ldwTax)
        ).toFixed(2);
        //  setAgreementDetails([{...agreementDetails[props.index],isLDWActionPerformed:'N',isCoverageActionPerformed:'N',totalDueAmount:amount,totalTax:tax}]);
        setAgreementDetails((prevDetails: AgreementDetails[]) =>
          prevDetails.map((detail: AgreementDetails, i: number) =>
            i === props.index
              ? {
                  ...detail,
                  totalDueAmount: amount,
                  totalAmount: Number(Number(props.selectedAgreements[0]?.totalAmount) + Number(props.selectedAgreements[0]?.ldwAmount)).toFixed(2),
                  totalTax: tax,
                  isLDWActionPerformed: CONSTANTS.STATUS_NO,
                  isLDW: false,
                  isCoverageActionPerformed: CONSTANTS.STATUS_NO,
                }
                : detail.agreementType == CONSTANTS.CLUB
                ?{
                 ...detail,
                 selected: false,
                 disabled: true,
                }
                :detail
          )
        );
        setCarryRentCalci(!carryRentCalci);
      } else if (type == CONSTANTS.POLICY) {
        amount = Number(
          Number(agreementDetails[props.index]?.totalDueAmount) - Number(fee)
        ).toFixed(2);
        tax = Number(
          Number(agreementDetails[props.index]?.totalTax) -
            Number(props.selectedAgreements[0]?.policyTax)
        ).toFixed(2);
        //  setAgreementDetails([{...agreementDetails[props.index],isPolicyActionPerformed:'N',isCoverageActionPerformed:'N', totalDueAmount:amount, totalTax:tax}]);
        setAgreementDetails((prevDetails: AgreementDetails[]) =>
          prevDetails.map((detail: AgreementDetails, i: number) =>
            i === props.index
              ? {
                  ...detail,
                  totalDueAmount: amount,
                  totalAmount: Number(Number(props.selectedAgreements[0]?.totalAmount) - Number(props.selectedAgreements[0]?.policyAmount)).toFixed(2),
                  totalTax: tax,
                  isPolicyActionPerformed: CONSTANTS.STATUS_NO,
                  isPolicy: false,
                  isCoverageActionPerformed: CONSTANTS.STATUS_NO,
                }
              : detail
          )
        );
      }
    }
    setRenderContext(!renderContext);
    props.setOptionalServiceFlag(false);
    props.setLdwStatusFlag({
      ...props.ldwStatusFlag,
      coverageStatus: CONSTANTS.STATUS_NO,
    });
  };

  /**
   * PS_CO_NO_OS_37
   * The ActiveLDWPolicypopup() is used to render the entire UI of the Acitivate Policy and LDW popup
   * @returns
   */
  const ActiveLDWPolicypopup = () => {
    return (
      <>
        <Grid
          item
          id="terminateldw"
          data-testid="ActivateLdwPopupId"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="terminateldw"
          aria-hidden="true"
        >
          <Typography className={classes.ps2}>
            {props.optionalServiceFlag.activateLDW
              ? `By activating LDW, your regular payment with LDW will increase by`
              : props.optionalServiceFlag.activatePolicy
              ? `Your regular payment with policy will increase by`
              : null}
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.font16} ${classes.bold} ${classes.ms1}`}
            >
              ${Number(fee).toFixed(2)}
            </Typography>
            {props.optionalServiceFlag.activateLDW
              ? `. Do you want to activate LDW?`
              : props.optionalServiceFlag.activatePolicy
              ? `. Do you want to active Policy?`
              : null}
          </Typography>

          <Grid
            item
            md={12}
            className={`${classes.textright} ${classes.modalfooter} ${classes.mt3}`}
          >
            <RACButton
              // type='button'
              className={classes.mx1}
              variant="outlined"
              color="primary"
              data-testid="ActivateLDWPolicyCancelId"
              onClick={
                () => props.setOptionalServiceFlag(false)
              }
              data-bs-dismiss="modal"
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="contained"
              data-testid="continueActivateLDWPolicyId"
              onClick={() =>
                continueActivateLDWPolicy(
                  props.optionalServiceFlag.activateLDW ? "LDW" : "Policy"
                )
              }
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

 /**
   * PS_CO_NO_OS_51
   * The TerminateLDWPolicypopup() is used to render the entire UI of the Deactivate/Terminate Policy and LDW popup
   * @returns
   */
 const TerminateLDWPolicypopup = () => {
  return (
    <>
      <Grid
        item
        id="terminateldw"
        data-bs-backdrop="static"
        data-testid="TerminateLDWTestId"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="terminateldw"
        aria-hidden="true"
      >
        <Typography
          className={
            props.optionalServiceFlag.terminateLDW ? classes.ps2 : classes.px3
          }
        >
          {props.optionalServiceFlag.terminateLDW
            ? `By deactivating LDW, your regular payment will decrease by`
            : props.optionalServiceFlag.deactivatePolicy
            ? `Your regular payment with policy will decrease by`
            : null}
          <Typography
            variant="caption"
            className={`${
              props.optionalServiceFlag.terminateLDW
                ? classes.RAClabeltxtstyle
                : classes.labeltxtstyle
            } ${classes.bold} ${classes.ms1} ${classes.font16}`}
          >
            ${Number(fee).toFixed(2)}
          </Typography>
          {props.optionalServiceFlag.terminateLDW
            ? `. If you have club it will be cancelled and decreased by that
               payment amount also.`
            : props.optionalServiceFlag.deactivatePolicy
            ? `. Do you want to DeActive Policy?`
            : null}
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.mt3} ${classes.textright}`}
        >
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="primary"
            data-testid="TerminateCancelbtn"
            data-bs-dismiss="modal"
            onClick={() =>
              props.setOptionalServiceFlag(false)
            }
          >
            Cancel
          </RACButton>
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-testid="ActiveLDWContinueId"
            onClick={() =>
              ContinueTerminateLDWPolicy(
                props.optionalServiceFlag.terminateLDW ? "LDW" : "Policy"
              )
            }
          >
            Continue
          </RACButton>
        </Grid>
      </Grid>
    </>
  );
  // }
};

  return (
    <>
      {/**
       * PS_CO_NO_OS_36
       * This Card is used to render the Activate policy/LDW popup
       */}
      <RACModalCard
        isOpen={
          props.optionalServiceFlag.activateLDW ||
          props.optionalServiceFlag.activatePolicy
        }
        maxWidth="xs"
        title={props.optionalServiceFlag.activateLDW?"Activate LDW":props.optionalServiceFlag.activatePolicy?'Activate Policy':CONSTANTS.EMPTY_STRING}
        // eslint-disable-next-line react/no-children-prop
        children={ActiveLDWPolicypopup()}
      />
      {/**
       * PS_CO_NO_OS_50
       * This Card is used to render the Deactivate/Terminate policy/LDW popup
       */}
      <RACModalCard
        isOpen={
          props.optionalServiceFlag.terminateLDW ||
          props.optionalServiceFlag.deactivatePolicy
        }
        maxWidth="xs"
        title={props.optionalServiceFlag.terminateLDW?"Terminate LDW":props.optionalServiceFlag.deactivatePolicy?'DeActivate Policy':CONSTANTS.EMPTY_STRING}
        // eslint-disable-next-line react/no-children-prop
        children={TerminateLDWPolicypopup()}
      />
    </>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
/**
 * PS_TCD_1
 * import the necessary files
 */
import React, { useEffect, useState, useContext } from 'react';
import { agreementContext } from '../context/PaymentInformationContext';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { ClipLoader } from 'react-spinners';
import {
  loadVantivIFrame,
  getRegistrationId,
} from '../../racstrapComponents/microfrontends/vantiv-iframe.service';
import { CreditCard } from '../../racstrapComponents/microfrontends/CreditCard';
import {
  customerBillingAddressInterface,
  SaveCardRequestDTO,
  CustomerInfo,
  SaveBillinginfoForVantivCard,
  RACSelectChangeEvent,
  ProcessPaymentLogRequest,
  InputChangeEvent,
  VantivCardsInterface,
  SaveCardResponse
} from '../interface/tenderCardInterface';
import { GetCardInfo, Savecustomercard } from '../../api/user';
import { ReactComponent as SuccessIcon } from '../../../src/assets/images/success-icon.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACModalCard,
  Grid,
  Typography,
  makeStyles,
} from '@rentacenter/racstrap';
import CONSTANTS from '../constants/constant';
import * as interfaceType from '../interface/contextInterface';
import { splitMethod } from '../utils/scheduleAndDateFormat';

export function VantivCards(props: VantivCardsInterface) {
  const useClasses = makeStyles(() => ({
    navLink: {
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out',
      position: 'relative',
      fontFamily: 'OpenSans-semibold',
      color: '#000',
    },
    navLinkactive: {
      color: '#2179FE',
      borderBottom: '5px solid #2468FF !important',
      fontFamily: 'OpenSans-semibold',
      '&:before': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        left: '-5px',
        bottom: '-5px',
        borderTopLeftRadius: '5px',
      },
      '&:after': {
        content: "''",
        position: 'absolute',
        height: '5px',
        width: '10px',
        background: '#2468ff',
        right: '-5px',
        bottom: '-5px',
        borderTopRightRadius: '5px',
      },
    },
    dropdowntoggle: {
      display: 'inline-block',
      marginLeft: '0.255em',
      verticalAlign: '0.255em',
      content: '',
      borderTop: '0.3em solid',
      borderRight: '0.3em solid transparent',
      borderBottom: '0',
      borderLeft: '0.3em solid transparent',
    },
    dropdownitemcustom: {
      color: '#2468FF',
      fontFamily: 'OpenSans-bold',
      backgroundColor: 'white',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    popUp: {
      position: 'absolute',
      bottom: '100%',
      backgroundColor: 'white',
      width: '200px',
      padding: '15px',
      '& li': {
        listStyle: 'none',
      },
    },
    popUpHide: {
      display: 'none',
    },
    customMenuContainer: {
      border: '1px solid #2468FF',
    },
    paymentBtnHover: {
      '&:hover': {
        color: 'white',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
      },
    },
  }));
  const classes = takePaymentStyles();
  const navlinkClass = useClasses();

  /**
   * PS_TCD_2
   * Validation objects
   * PS_TCD_4
   */
  const customerBillingAddress: customerBillingAddressInterface = {
    name: CONSTANTS.EMPTY_STRING,
    addressLine1: CONSTANTS.EMPTY_STRING,
    addressLine2: CONSTANTS.EMPTY_STRING,
    zipcode: CONSTANTS.EMPTY_STRING,
    city: CONSTANTS.EMPTY_STRING,
    state: CONSTANTS.EMPTY_STRING,
  };

  const addNewValidationObj = {
    name: false,
    addressLine1: false,
    zipcode: false,
    city: false,
    state: false,
  };

  /**
   * PS_TCD_5
   * Invoking Context
   */
  const agreementInfo =
    useContext<interfaceType.AgreementContextValue>(agreementContext);

  /**
   * PS_TCD_6
   * State Variables Creation
   * PS_TCD_32
   */
  const [errorMessage, setErrorMessage] = useState({
    saveCard: CONSTANTS.EMPTY_STRING,
    chargeCard: CONSTANTS.EMPTY_STRING,
  });
  const [vantivframe, setVantivIframe] = useState();

  const [chargeCardAmount, setchargeCardAmount] = useState<string>(
    CONSTANTS.ZREO_TWO_DECIMAL_STRING
  );
  const [addNewBillAddressValidation, setAddNewBillAddressValidation] =
    useState(addNewValidationObj);
  const [customerAddress, setCustomerAddress] =
    useState<customerBillingAddressInterface>(customerBillingAddress);
  const [vantivError, setVantivError] = useState<string>(
    CONSTANTS.EMPTY_STRING
  );
  const [swipeSpinload, setSwipeSpinload] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(false);

  /**
   * PS_TCD_33
   * Variable Creation
   * PS_TCD_35
   */
  const customerId = agreementInfo.customerInfo.customerId;
  const firstName = agreementInfo.customerInfo.customerDetails[0].firstName;
  const lastName = agreementInfo.customerInfo.customerDetails[0].lastName;

  /**
   * PS_TCD_50
   * This function call the getCard
   * PS_TCD_49
   */
  async function getCardDetails() {
    const response = await GetCardInfo(customerId);
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    agreementContextClone.customerCardDetails = response;
    agreementInfo.setCustomerInfo(agreementContextClone);
  }

  /**
   * PS_TCD_42
   * This useEffect sets the address and entered card details
   * PS_TCD_68
   */
  useEffect(() => {
    const billingAddress =
      agreementInfo.customerInfo.customerAddressDetails.find(
        (address) => address.addressType === CONSTANTS.PRIM
      );
    if (billingAddress) {
      const sessionZip: string[] = splitMethod(
        billingAddress.postalCode,
        CONSTANTS.HYPHEN
      );
      customerAddress.name = `${firstName} ${lastName}`;
      customerAddress.addressLine1 = billingAddress.addressLine1;
      customerAddress.addressLine2 = billingAddress.addressLine2;
      customerAddress.zipcode = sessionZip[0];
      customerAddress.city = billingAddress.city;
      customerAddress.state = billingAddress.state;
    } else if (props.sessionStoreDetails) {
      const sessionZip: string[] = splitMethod(
        props.sessionStoreDetails.zip,
        CONSTANTS.HYPHEN
      );
      customerAddress.name = `${firstName} ${lastName}`;
      customerAddress.addressLine1 = props.sessionStoreDetails.addressLine1;
      customerAddress.addressLine2 = props.sessionStoreDetails.addressLine2;
      customerAddress.zipcode = sessionZip[0];
      customerAddress.city = props.sessionStoreDetails.city;
      customerAddress.state = props.sessionStoreDetails.state;
    }
    window.sessionStorage.setItem("name", `${firstName} ${lastName}`)
    window.sessionStorage.setItem("addressLine1", customerAddress.addressLine1)
    window.sessionStorage.setItem("addressLine2", customerAddress.addressLine2)
    window.sessionStorage.setItem("zipcode", customerAddress.zipcode)
    window.sessionStorage.setItem("city", customerAddress.city)
    window.sessionStorage.setItem("state", customerAddress.state)
  }, [agreementInfo.typeOfCard]);

  /**
   * PS_TCD_152
   * This useEffect triggers the vantiv frame
   */
  useEffect(() => {
    if (agreementInfo.typeOfCard == CONSTANTS.ADD_NEW_CARD) {
      callVantivIframe(1);
      window.scrollTo(0, document.body.scrollHeight);
    }
    if (agreementInfo.typeOfCard == CONSTANTS.CHARGE_CARD) {
      callVantivIframe(2);
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [agreementInfo.typeOfCard]);

  const callVantivIframe = (iframeType: number) => {
    if (iframeType == 1) {
      setVantivIframe(
        loadVantivIFrame(
          CONSTANTS.VANTIV_NUMBER,
          CONSTANTS.VANTIV_CCFRAME,
          handleVantivCallback
        )
      );
    } else if (iframeType == 2) {
      setVantivIframe(
        loadVantivIFrame(
          CONSTANTS.VANTIV_NUMBER,
          CONSTANTS.VANTIV_CHARGE_FRAME,
          handleVantivChargeCallback
        )
      );
    }
    return 1;
  };

  /**
   * PS_TCD_153
   * @param response
   * This function handles the add new card scenario
   * PS_TCD_188
   */
  const handleVantivCallback = (response: any) => {
    console.log(response, 'vantiv response');
    setDisableSave(false);
    if (response.response === CONSTANTS.VANTIV_RESPONSE_CODE_870) {
      const card = new CreditCard(response);
      const request: ProcessPaymentLogRequest = {
        paymentToken: response.paypageRegistrationId,
        cardType: card.type,
        expirationDate: card.cardExpiration,
        lastFour: card.lastDigits,
        savePaymentMethod: CONSTANTS.STATUS_YES,
      };
      vantivSubmit(request);
    } else {
      props.setSaveCardPopupShow({
        ...props.saveCardPopupShow,
        saveCardPopUp: true,
      });
      handleVantivErrorResponse(response.response);
    }
  };

  function onChangeOfBillAddress(event: RACSelectChangeEvent) {
    const keyNameId = event.target.id;
    let keyNameValue = event.target.value;
  
    const nameRegex = CONSTANTS.NAME_REGEX; // Matches any character except digits (numbers)
    const cityRegex = CONSTANTS.CITY_REGEX; // Matches only alphabets and spaces
    const zipRegex = CONSTANTS.ZIP_REGEX; // Matches only numbers
    const alphanumericRegex = CONSTANTS.ALPHA_NUMERIC_REGEX;
  
    const isNameField = keyNameId?.includes(CONSTANTS.ADDRESS_NAME);
    const isCityField = keyNameId?.includes(CONSTANTS.ADDRESS_CITY);
    const isZipField = keyNameId?.includes(CONSTANTS.ADDRESS_ZIP);
    const isAddressLineField = keyNameId?.includes(CONSTANTS.ADDRESS_ADDRESSLINE);
  
    if (isNameField) {
      if (!nameRegex.test(keyNameValue)) {
        // If name field contains numbers, don't update
        return;
      }
    } else if (isCityField) {
      if (!cityRegex.test(keyNameValue)) {
        // If city field contains numbers or special characters, don't update
        return;
      }
    } else if (isZipField) {
      if (!zipRegex.test(keyNameValue)) {
        // If zip field contains non-numeric characters, don't update
        return;
      }
    } else if (!isAddressLineField) {
      // For fields other than name, address lines, city, and zip, ensure no special characters
      // Assuming you want to allow alphanumeric and spaces for other fields
      if (!alphanumericRegex.test(keyNameValue)) {
        return;
      }
    }
  
    // Proceed with updating the customerAddress state
    if (keyNameId === undefined) {
      setCustomerAddress({
        ...customerAddress,
        [CONSTANTS.ADDRESS_STATE]: keyNameValue,
      });
      window.sessionStorage.setItem(CONSTANTS.ADDRESS_STATE, keyNameValue)
    } else {
      setCustomerAddress({
        ...customerAddress,
        [keyNameId]: keyNameValue,
      });
      window.sessionStorage.setItem(keyNameId, keyNameValue)
    }
  
    // Proceed with updating the validation state
    if (keyNameValue !== CONSTANTS.EMPTY_STRING) {
      if (keyNameId === undefined) {
        setAddNewBillAddressValidation((prevState) => ({
          ...prevState,
          [CONSTANTS.ADDRESS_STATE]: false,
        }));
      } else {
        setAddNewBillAddressValidation((prevState) => ({
          ...prevState,
          [keyNameId]: false,
        }));
      }
    }
  }

  // function onblurValidation(event: RACSelectChangeEvent) {
  //   const keyNameId = event.target.id;
  //   const keyNameValue = event.target.value;
  //   if (keyNameValue !== CONSTANTS.EMPTY_STRING) {
  //     if (keyNameId == undefined) {
  //       setAddNewBillAddressValidation((prevState) => ({
  //         ...prevState,
  //         ["state"]: false,
  //       }));
  //     } else {
  //       setAddNewBillAddressValidation((prevState) => ({
  //         ...prevState,
  //         [keyNameId]: false,
  //       }));
  //     }
  //   }
  // }

  /**
   * PS_TCD_196
   * @param response
   * This function handles the charge card
   * PS_TCD_226
   */
  const handleVantivChargeCallback = (response: any) => {
    console.log(response, 'vantiv charge response');
    setDisableSave(false);
    if (response.response === CONSTANTS.VANTIV_RESPONSE_CODE_870) {
      const card = new CreditCard(response);
      const request: ProcessPaymentLogRequest = {
        paymentToken: response.paypageRegistrationId,
        cardType: card.type,
        expirationDate: card.cardExpiration,
        lastFour: card.lastDigits,
        savePaymentMethod: CONSTANTS.STATUS_YES,
      };
      vantivChargeSubmit(request);
    } else {
      props.setSaveCardPopupShow({
        ...props.saveCardPopupShow,
        saveCardPopUp: true,
      });
      handleVantivErrorResponse(response.response);
    }
  };

  const handleVantivErrorResponse = (responseCode: string | number) => {
    const isCreditCardNumberError = CONSTANTS.CREDIT_CARD_ERROR_REPONSE.some(
      (errorCode) => errorCode === responseCode
    );
    const isCVVError = CONSTANTS.CVV_ERROR_RESPONSE.some(
      (errorCode) => errorCode === responseCode
    );
    if (isCreditCardNumberError) {
      setVantivError(
        CONSTANTS.PLEASE_CHECK_AND_REENTER_YOUR_CREDIT_CARD_NUMBER_AND_TRY_AGAIN
      );
    } else if (isCVVError) {
      setVantivError(
        CONSTANTS.PLEASE_CHECK_AND_REENTER_YOUR_CARD_VALIDATION_NUMBER_AND_TRY_AGAIN
      );
    } else if (responseCode === CONSTANTS.VANTIV_RESPONSE_CODE_884) {
      setVantivError(CONSTANTS.VANTIV_RESPONSE_CODE_884);
    } else {
      setVantivError(
        CONSTANTS.WE_ARE_EXPERIENCING_TECHINCAL_DIFFICULTIES_PLEASE_TRY_AGAIN
      );
    }
  };

  const vantivSubmit = async (request: ProcessPaymentLogRequest) => {
    const customerInfoParam = createCustomerInfo();
    const billInfo = createBillInfo();
    const newCardReq = createNewCardRequest(
      request,
      customerInfoParam,
      billInfo
    );

    showSaveCardPopup();
    setDisableSave(false);

    const savecardres = await Savecustomercard(newCardReq);
    handleSaveCardResponse(savecardres);
  };

  const createCustomerInfo = (): CustomerInfo => ({
    FirstName: firstName,
    LastName: lastName,
  });

  const createBillInfo = (): SaveBillinginfoForVantivCard => {
    const sessionZip: string[] = splitMethod(
      String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ZIPCODE)),
      CONSTANTS.HYPHEN
    );
    return {
      Address1: String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ADDRESSLINE_ONE)),
      Address2:
      String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ADDRESSLINE_TWO)) == CONSTANTS.NULL_STRING
          ? CONSTANTS.EMPTY_STRING
          : String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ADDRESSLINE_TWO)),
      City: String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_CITY)),
      StateProvince: String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_STATE)),
      PostalCode: sessionZip[0],
      Country: CONSTANTS.USA,
      BillShipSameAddressFlag: CONSTANTS.ZERO_STRING,
    };
  };

  const createNewCardRequest = (
    request: ProcessPaymentLogRequest,
    customerInfoParam: CustomerInfo,
    billInfo: SaveBillinginfoForVantivCard
  ): SaveCardRequestDTO => ({
    raftApiFlag: 1,
    storeMerchantId: props.storeIdDetails.storeMerchantId || null,
    paymentFlag: 0,
    clubFlag: 0,
    updateFlag: 0,
    cardType: String(request.cardType),
    cardToken: request.paymentToken,
    cardHolderName: String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_NAME)),
    cardExpiration: String(request.expirationDate),
    storeId: String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
    transactionAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
    cardNumberLastFour: String(request.lastFour),
    customerId: Number(customerId),
    PaymentID: String(request.paymentToken),
    Amount: 0,
    SalesTax: 0,
    CustomerInfo: customerInfoParam,
    BillToAddress: billInfo,
    agreementIds: [],
  });

  const showSaveCardPopup = () => {
    props.setSaveCardPopupShow({
      ...props.saveCardPopupShow,
      saveCardPopUp: true,
    });
  };

  const handleSaveCardResponse = async (savecardres: SaveCardResponse) => {
    if (isSaveCardSuccessful(savecardres)) {
      await handleSuccessfulSave();
    } else {
      handleFailedSave(savecardres);
    }
  };

  const isSaveCardSuccessful = (savecardres: SaveCardResponse): boolean =>
    savecardres?.status === 200 &&
    savecardres?.data !== CONSTANTS.EMPTY_STRING &&
    savecardres?.data?.customerId != undefined;

  const handleSuccessfulSave = async () => {
    await getCardDetails();
    props.setSaveCardPopupShow({
      ...props.saveCardPopupShow,
      saveCardPopUp: false,
      saveCardSuccess: true,
    });
  };

  const handleFailedSave = (savecardres: SaveCardResponse) => {
    props.setSaveCardPopupShow({
      ...props.saveCardPopupShow,
      saveCardPopUp: false,
      saveCardFail: true,
    });
    if (isDeclineCodeAvailable(savecardres)) {
      const declineCode = getDeclineCode(savecardres);
      const cardDeclineMessage = getCardDeclineMessage(declineCode);
      props.setFailedToSaveCardError(cardDeclineMessage);
    }
  };

  const isDeclineCodeAvailable = (savecardres: SaveCardResponse): boolean =>
    savecardres?.status === 400 &&
    !!savecardres?.data?.errors?.[0]?.error?.serviceResponse?.Body?.StatusInfo
      ?.StatusCodeDetail;

  const getDeclineCode = (savecardres: SaveCardResponse): number => {
    let declineCode =
      savecardres?.data?.errors?.[0]?.error?.serviceResponse?.Body?.declineCode;
    const internalStatusCode =
      savecardres?.data?.errors?.[0]?.error?.serviceResponse?.Body?.StatusInfo
        ?.statusCode;
    if (internalStatusCode === CONSTANTS.DP) {
      declineCode = 712;
    }
    return Number(declineCode);
  };

  const getCardDeclineMessage = (declineCode: number): string => {
    let cardDeclineMessage =
      CONSTANTS.CARD_SAVING_DECLINED_PLEASE_TRY_A_DIFFERENT_CARD;
    switch (Number(declineCode)) {
      case 39:
      case 110:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_INSUFFICIENT_FUND_PLEASE_TRY_A_DIFFERENT_CARD;
        break;
      case 127:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_EXCEEDS_APPROVAL_AMOUNT_LIMIT;
        break;
      case 327:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_CARDHOLDER_TRANSACTION_NOT_PERMITTED;
        break;
      case 322:
        cardDeclineMessage = CONSTANTS.CARD_SAVING_DECLINED_INVALID_TRANSACTION;
        break;
      case 330:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_INVALID_PAYMENT_TYPE;
        break;
      case 321:
        cardDeclineMessage = CONSTANTS.CARD_SAVING_DECLINED_INVALID_MERCHANT;
        break;
      case 217:
        cardDeclineMessage = CONSTANTS.CARD_SAVING_DECLINED_CARD_ALREADY_ACTIVE;
        break;
      case 822:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_CARD_TOKEN_NOT_FOUND;
        break;
      case 340:
        cardDeclineMessage = CONSTANTS.CARD_SAVING_DECLINED_INVALID_AMOUNT;
        break;
      case 11:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_TRANSACTION_NOT_PERMITTED;
        break;
      case 301:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_INVALID_ACCOUNT_NUMBER_PLEASE_TRY_WITH_DIFFERENT_CARD;
        break;
      case 326:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_EXCEEDED_NUMBER_OF_PIN_ENTRIES_PLEASE_TRY_WITH_DIFFERENT_CARD;
        break;
      case 324:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_INVALID_PIN_PLEASE_TRY_WITH_VALID_PIN_NUMBER;
        break;
      case 9:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_INVALID_CARD_NUMBER_PLEASE_TRY_WITH_DIFFERENT_CARD;
        break;
      case 4:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_EXPIRED_CARD_PLEASE_TRY_WITH_DIFFERENT_CARD;
        break;
      case 13:
        cardDeclineMessage =
          CONSTANTS.CARD_SAVING_DECLINED_PLEASE_TRY_A_DIFFERENT_CARD;
        break;
      case 26:
        cardDeclineMessage =
          CONSTANTS.ANOTHER_INSTANCE_OF_EXCEEDS_WITHDRAWAL_LIMIT_MESSAGE;
        break;
    }
    return cardDeclineMessage;
  };

  const vantivChargeSubmit = async (request: ProcessPaymentLogRequest) => {
    let cardType = CONSTANTS.UNKNOWN;
    let cardRef = CONSTANTS.UNKNOWN_REF;
    switch (request.cardType) {
      case CONSTANTS.ONE_STRING:
        cardType = CONSTANTS.MASTER_CARD;
        cardRef = "M";
        break;
      case CONSTANTS.TWO_STRING:
        cardType = CONSTANTS.VISA_CARD;
        cardRef = "V";
        break;
      case CONSTANTS.THREE_STRING:
        cardType = CONSTANTS.AMERICAN_EXPRESS_CARD;
        cardRef = "A";
        break;
      case CONSTANTS.FOUR_STRING:
        cardType = CONSTANTS.DISCOVER;
        cardRef = "D";
        break;
      case CONSTANTS.FIVE_STRING:
        cardType = CONSTANTS.DINERS_CLUB;
        cardRef = "DC";
        break;
      case CONSTANTS.SIX_STRING:
        cardType = CONSTANTS.JCB_CARD;
        cardRef = "J";
        break;
      case CONSTANTS.SEVEN_STRING:
        cardType = CONSTANTS.PAY_PAL;
        cardRef = "PP";
        break;
      case CONSTANTS.TEN_STRING:
        cardType = CONSTANTS.CARD;
        cardRef = "C";
        break;
      case CONSTANTS.ELEVEN_STRING:
        cardType = CONSTANTS.UNKNOWN;
        cardRef = "U";
        break;
    }

    const slashString = CONSTANTS.SLASH_STRING;
    const position = 2;
    const cardExpirationParse = [
      String(request.expirationDate).slice(0, position),
      slashString,
      String(request.expirationDate).slice(position),
    ].join(CONSTANTS.EMPTY_STRING);
    const sessionZip: string[] = splitMethod(
      String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ZIPCODE)),
      CONSTANTS.HYPHEN
    );
    const chargeBillInfo = {
      addressLine1: String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ADDRESSLINE_ONE)),
      addressLine2:
      String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ADDRESSLINE_TWO)) == CONSTANTS.NULL_STRING
          ? CONSTANTS.EMPTY_STRING
          : String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_ADDRESSLINE_TWO)),
      City: String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_CITY)),
      state: String(window.sessionStorage.getItem(CONSTANTS.ADDRESS_STATE)),
      zip: sessionZip[0],
    };
    const ChargeObj = {
      amount: Number(
        agreementInfo.customerInfo.amountUsed.chargeCardAmount
      ).toFixed(2),
      authNumber: CONSTANTS.EMPTY_STRING,
      cvv: undefined,
      cardLastFour: request.lastFour,
      cardType: cardRef,
      cardTransactionType: CONSTANTS.MNL,
      externalTransactionId: CONSTANTS.EMPTY_STRING,
      paymentId: String(request.paymentToken),
      expirationDate: String(cardExpirationParse),
      paymentToken: String(request.paymentToken),
      billingAddress: chargeBillInfo,
    };

    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );

    agreementContextClone.amountUsed.cardDetails.push(ChargeObj);
    const orderId = agreementContextClone.amountUsed.tenderDetails.orderId + 1;

    const sideBarCardObj = {
      type: CONSTANTS.CHARGE,
      amount: Number(
        String(agreementInfo.customerInfo.amountUsed.chargeCardAmount)
      ).toFixed(2),
      cardLastFour: request.lastFour,
      orderId: orderId,
    };
    agreementContextClone.amountUsed.tenderDetails.card.push(sideBarCardObj);
    agreementInfo.setCustomerInfo(agreementContextClone);
    agreementInfo.setTypeOfCard(CONSTANTS.EMPTY_STRING);
    agreementInfo.setRenderContext(!agreementInfo.renderContext);
  };

  const vantivSubmitForCard = () => {

    if (agreementInfo.typeOfCard == CONSTANTS.CHARGE_CARD) {
      if (
        Number(agreementInfo.customerInfo.amountUsed.chargeCardAmount) >
        Number(agreementInfo.customerInfo.amountUsed.remainingAmountDue)
      ) {
        setErrorMessage({ ...errorMessage, chargeCard: CONSTANTS.YES });
        return
      }
    }

    if (
      customerAddress.name !== CONSTANTS.EMPTY_STRING &&
      customerAddress.addressLine1 !== CONSTANTS.EMPTY_STRING &&
      customerAddress.zipcode !== CONSTANTS.EMPTY_STRING &&
      customerAddress.city !== CONSTANTS.EMPTY_STRING &&
      customerAddress.state !== CONSTANTS.ZERO_STRING
    ) {
      setDisableSave(true);
      setAddNewBillAddressValidation(addNewValidationObj);
      getRegistrationId(vantivframe, null);
    } else {
      if (customerAddress.name === CONSTANTS.EMPTY_STRING) {
        setAddNewBillAddressValidation((prevState) => ({
          ...prevState,
          name: true,
        }));
      }
      if (customerAddress.addressLine1 === CONSTANTS.EMPTY_STRING) {
        setAddNewBillAddressValidation((prevState) => ({
          ...prevState,
          addressLine1: true,
        }));
      }
      if (customerAddress.state === CONSTANTS.ZERO_STRING) {
        setAddNewBillAddressValidation((prevState) => ({
          ...prevState,
          state: true,
        }));
      }
      if (customerAddress.zipcode === CONSTANTS.EMPTY_STRING) {
        setAddNewBillAddressValidation((prevState) => ({
          ...prevState,
          zipcode: true,
        }));
      }
      if (customerAddress.city === CONSTANTS.EMPTY_STRING) {
        setAddNewBillAddressValidation((prevState) => ({
          ...prevState,
          city: true,
        }));
      }
    }
  };

  function totalChargeAmount(value: string) {
    setchargeCardAmount(value);
    const agreementContextClone = JSON.parse(
      JSON.stringify(agreementInfo.customerInfo)
    );
    const ReaminingAmt = agreementContextClone.amountUsed.remainingAmountDue;
    const parsedValue = Number(value.replace(/,/g, CONSTANTS.EMPTY_STRING));
    const totalAmount = Number(ReaminingAmt) - Number(parsedValue);
    if (totalAmount < 0) {
      agreementInfo.customerInfo.amountUsed.chargeCardAmount = String(
        CONSTANTS.ZERO_NUMBER
      );
      setErrorMessage({ ...errorMessage, chargeCard: CONSTANTS.YES });
    } else {
      const parseValue = value.replace(
        CONSTANTS.REPLACE_COMMA,
        CONSTANTS.EMPTY_STRING
      );
      agreementInfo.customerInfo.amountUsed.chargeCardAmount = parseValue;
      setErrorMessage({ ...errorMessage, chargeCard: CONSTANTS.EMPTY_STRING });
    }
  }

  function chargeCardAmt(e: RACSelectChangeEvent) {
    setchargeCardAmount(e.target.value);
  }

  /**
   * PS_TCD_194
   * This function is the popup for successfull save card
   */
  const cardSuccessfullySaved = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {props.saveCardPopupShow.saveCardSuccess ? (
          <Grid item md={12} className={classes.textcenter}>
            <SuccessIcon></SuccessIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              Card Saved Successfully
            </Typography>
          </Grid>
        ) : props.saveCardPopupShow.saveCardFail ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              {props.failedToSaveCardError == CONSTANTS.EMPTY_STRING
                ? CONSTANTS.SOMETHING_WENT_WRONG_UNABLE_TO_SAVE_CARD_PLEASE_TRY_WITH_DIFFERENT_CARD_RETRY
                : props.failedToSaveCardError}
            </Typography>
          </Grid>
        ) : (
          CONSTANTS.EMPTY_STRING
        )}

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => savedCardOk()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const savedCardOk = () => {
    props.setSaveCardPopupShow((prevState) => ({
      ...prevState,
      saveCardFail: false,
      saveCardSuccess: false,
      saveCardPopUp: false,
    }));
    agreementInfo.setTypeOfCard(CONSTANTS.EMPTY_STRING);
    props.setFailedToSaveCardError(CONSTANTS.EMPTY_STRING);
  };

  /**
   * PS_TCD_193
   * This function is the popup for successfull save card
   */
  const spinnerPopup = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader
            color="blue"
            loading={props.saveCardPopupShow.saveCardPopUp}
            size={40}
          />
          <Typography className="row justify-content-center popup-text">
            {props.saveCardPopupShow.saveCardPopUp
              ? 'Saving card'
              : 'Transaction In Progress'}
          </Typography>
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * PS_TCD_180
   * @returns
   * THis function displays the error
   */
  const cardFailedVantivError = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        {vantivError != CONSTANTS.EMPTY_STRING ? (
          <Grid item md={12} className={classes.textcenter}>
            <AlertIcon className={classes.racErrorIcon}></AlertIcon>
            <Typography
              className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
            >
              {vantivError}
            </Typography>
          </Grid>
        ) : (
          CONSTANTS.EMPTY_STRING
        )}

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => {
              props.setSaveCardPopupShow({
                ...props.saveCardPopupShow,
                saveCardPopUp: false,
              });
              setVantivError(CONSTANTS.EMPTY_STRING);
            }}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  function cancelClick() {
    agreementInfo.setTypeOfCard(CONSTANTS.EMPTY_STRING);
    setAddNewBillAddressValidation({
      name: false,
      addressLine1: false,
      zipcode: false,
      city: false,
      state: false,
    });
  }

  /**
   * HTML
   */
  return (
    <>
      {agreementInfo.typeOfCard == CONSTANTS.ADD_NEW_CARD && (
        <Grid
          style={{ float: 'left', width: '100%' }}
          id="newcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="NewCardTestId"
        >
          <Grid style={{ float: 'left', width: '100%' }}>
            <Grid style={{ float: 'left', width: '40%' }}>
              <h5
                style={{
                  marginBottom: 20,
                  marginTop: '20px',
                  fontSize: '14px',
                }}
              >
                Add Card Information
              </h5>
              <Grid style={{ float: 'left', width: '100%' }}>
                <Grid id="ccframe"></Grid>
              </Grid>
            </Grid>

            <Grid
              style={{
                float: "left",
                width: "58%",
                display: "block",
                marginLeft: "1%",
              }}
            >
              <h5 style={{ marginBottom: "20", fontSize: "14px" }}>
                Billing Address
              </h5>
              <Grid container spacing={2} style={{ width: "100%" }}>
                {[
                  { name: "name", label: "Name", validation: "name" },
                  {
                    name: "addressLine1",
                    label: "Address Line 1",
                    validation: "addressLine1",
                  },
                  { name: "addressLine2", label: "Address Line 2" },
                  { name: "zipcode", label: "ZIP Code", validation: "zipcode" },
                  { name: "city", label: "City", validation: "city" },
                ].map((field) => (
                  <Grid item xs={12} sm={6} key={field.name}>
                    <div style={{ minHeight: "63px" }}>
                      <RACTextbox
                        isCurrency={false}
                        type="text"
                        name={field.name}
                        id={field.name}
                        inputlabel={field.label}
                        required={field.validation ? true : false}
                        data-testid={`${field.name}Id`}
                        value={customerAddress[field.name]}
                        OnChange={(e) => onChangeOfBillAddress(e)}
                        {...(field.name === "zipcode" && {
                          digitFormat: "normal",
                          pattern: "\\d*",
                          maxlength: 5,
                        })}
                      />
                      {field.validation &&
                        addNewBillAddressValidation[field.validation] && (
                          <Typography
                            className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                            style={{
                              marginTop: "4px",
                              fontSize: "0.85rem",
                              color: "red",
                            }}
                          >
                            {`Please enter ${field.label}`}
                          </Typography>
                        )}
                    </div>
                  </Grid>
                ))}

                <Grid item xs={12} sm={6}>
                  <div style={{ minHeight: "63px" }}>
                    <RACSelect
                      data-testid="dropmainId"
                      options={props.states}
                      defaultValue={customerAddress.state}
                      inputLabel="State"
                      required={true}
                      name="state"
                      onChange={(e) => onChangeOfBillAddress(e)}
                    />
                    {addNewBillAddressValidation.state && (
                      <Typography
                        className={classes.mandatoryfield}
                        style={{
                          marginTop: "4px",
                          fontSize: "0.85rem",
                          color: "red",
                        }}
                      >
                        Please select state
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ float: 'left', width: '100%' }}>
            <div style={{ float: 'right' }}>
              <RACButton
                className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                variant="outlined"
                color="primary"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: 'white',
                  textTransform: 'none',
                  fontWeight: 'bolder',
                  paddingLeft: '14px',
                  paddingRight: '14px',
                  color: '#2179fe',
                }}
                onClick={() => cancelClick()}
                data-bs-toggle="modal"
              >
                Cancel
              </RACButton>

              <RACButton
                className={`${classes.paymentSmallBtn1}`}
                color="primary"
                variant="contained"
                name="De_ActivateClubbtn"
                disabled={disableSave}
                style={{
                  backgroundColor: '#2179fe',
                  textTransform: 'none',
                  fontWeight: 'bolder',
                  paddingLeft: '14px',
                  paddingRight: '14px',
                  color: 'white',
                }}
                onClick={() => vantivSubmitForCard()}
                data-bs-toggle="modal"
              >
                Save
              </RACButton>
            </div>
          </Grid>
        </Grid>
      )}
      {agreementInfo.typeOfCard == CONSTANTS.CHARGE_CARD && (
        <Grid
          style={{ float: 'left', width: '100%' }}
          id="newcard"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="carrywaive"
          aria-hidden="true"
          data-testid="NewCardTestId"
        >
          <Grid style={{ float: 'left', width: '100%' }}>
            <Grid style={{ float: 'left', width: '40%' }}>
              <h5 style={{ marginBottom: 20, marginTop: '20px' }}>
                Charge Card Information
              </h5>
              <Grid style={{ float: 'left', width: '100%' }}>
                <Grid id="chargeframe"></Grid>
              </Grid>
            </Grid>

            <Grid
              style={{
                float: "left",
                width: "58%",
                display: "block",
                marginLeft: "1%",
              }}
            >
              <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
                <Grid
                  item
                  md={12}
                  className={`${classes.formLabel} ${classes.mt2}`}
                >
                  Amount
                </Grid>
                <Grid item md={12} className={`${classes.mb3}`}>
                  <RACTextbox
                    isCurrency={true}
                    className={`${classes.custDigit}`}
                    value={chargeCardAmount}
                    name="total_amt"
                    id="chargetxtbox"
                    data-testid="amtTxtBoxId"
                    Onblur={(e: InputChangeEvent) =>
                      totalChargeAmount(e.target.value)
                    }
                    OnChange={(e: InputChangeEvent) => chargeCardAmt(e)}
                    // OnKeydown={(e) => {
                    //   if (e.key.toLowerCase() === "delete") {
                    //     setchargeCardAmt("0.00");
                    //   }
                    // }}
                    type={"number"}
                    digitFormat={"currency"}
                    maxlength={10}
                    dollarTextClassName={classes.currencyDollarField}
                  />
                  {errorMessage.chargeCard === CONSTANTS.YES ? (
                    <label className={classes.validationstyle}>
                      Amount should not be entered more than the remaining
                      amount
                    </label>
                  ) : null}
                </Grid>
              </Grid>
              <h5 style={{ marginBottom: "20", fontSize: "14px" }}>
                Billing Address
              </h5>
              <Grid container spacing={2} style={{ width: "100%" }}>
                {[
                  { name: "name", label: "Name", validation: "name" },
                  {
                    name: "addressLine1",
                    label: "Address Line 1",
                    validation: "addressLine1",
                  },
                  { name: "addressLine2", label: "Address Line 2" },
                  { name: "zipcode", label: "ZIP Code", validation: "zipcode" },
                  { name: "city", label: "City", validation: "city" },
                ].map((field) => (
                  <Grid item xs={12} sm={6} key={field.name}>
                    <div style={{ minHeight: "63px" }}>
                      <RACTextbox
                        isCurrency={false}
                        type="text"
                        name={field.name}
                        id={field.name}
                        inputlabel={field.label}
                        required={field.validation ? true : false}
                        data-testid={`${field.name}Id`}
                        value={customerAddress[field.name]}
                        OnChange={(e) => onChangeOfBillAddress(e)}
                        {...(field.name === "zipcode" && {
                          digitFormat: "normal",
                          pattern: "\\d*",
                          maxlength: 5,
                        })}
                      />
                      {field.validation &&
                        addNewBillAddressValidation[field.validation] && (
                          <Typography
                            className={`${classes.validationstyle} ${classes.mandatoryfield}`}
                            style={{
                              marginTop: "4px",
                              fontSize: "0.85rem",
                              color: "red",
                            }}
                          >
                            {`Please enter ${field.label}`}
                          </Typography>
                        )}
                    </div>
                  </Grid>
                ))}

                <Grid item xs={12} sm={6}>
                  <div style={{ minHeight: "63px" }}>
                    <RACSelect
                      data-testid="dropmainId"
                      options={props.states}
                      defaultValue={customerAddress.state}
                      inputLabel="State"
                      required={true}
                      name="state"
                      onChange={(e) => onChangeOfBillAddress(e)}
                    />
                    {addNewBillAddressValidation.state && (
                      <Typography
                        className={classes.mandatoryfield}
                        style={{
                          marginTop: "4px",
                          fontSize: "0.85rem",
                          color: "red",
                        }}
                      >
                        Please select state
                      </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ float: 'left', width: '100%' }}>
            <div style={{ float: 'right' }}>
              <RACButton
                className={`${classes.paymentSmallBtn1} ${classes.mx2} ${navlinkClass.paymentBtnHover}`}
                variant="outlined"
                color="primary"
                name="De_ActivateClubbtn"
                style={{
                  backgroundColor: 'white',
                  textTransform: 'none',
                  fontWeight: 'bolder',
                  paddingLeft: '14px',
                  paddingRight: '14px',
                  color: '#2179fe',
                }}
                onClick={() => cancelClick()}
                data-bs-toggle="modal"
              >
                Cancel
              </RACButton>

              <RACButton
                className={`${classes.paymentSmallBtn1}`}
                color="primary"
                variant="contained"
                name="De_ActivateClubbtn"
                disabled={Number(chargeCardAmount) == CONSTANTS.ZERO_NUMBER}
                style={{
                  backgroundColor: '#2179fe',
                  textTransform: 'none',
                  fontWeight: 'bolder',
                  paddingLeft: '14px',
                  paddingRight: '14px',
                  color: 'white',
                }}
                onClick={() => vantivSubmitForCard()}
                data-bs-toggle="modal"
              >
                Continue
              </RACButton>
            </div>
          </Grid>
        </Grid>
      )}
      <RACModalCard
        isOpen={
          props.saveCardPopupShow.saveCardSuccess ||
          props.saveCardPopupShow.saveCardFail
        }
        maxWidth="xs"
        onClose={() => savedCardOk()}
        closeIcon={false}
      >
        {cardSuccessfullySaved()}
      </RACModalCard>
      <RACModalCard
        isOpen={props.saveCardPopupShow.saveCardPopUp}
        maxWidth="xs"
        onClose={() =>
          props.setSaveCardPopupShow({
            ...props.saveCardPopupShow,
            saveCardPopUp: false,
          })
        }
        closeIcon={false}
      >
        {spinnerPopup()}
      </RACModalCard>

      <RACModalCard
        isOpen={swipeSpinload}
        maxWidth="xs"
        onClose={() => setSwipeSpinload(false)}
        closeIcon={false}
      >
        {spinnerPopup()}
      </RACModalCard>
      <RACModalCard
        isOpen={vantivError != CONSTANTS.EMPTY_STRING}
        maxWidth="xs"
        onClose={() => setVantivError(CONSTANTS.EMPTY_STRING)}
        closeIcon={false}
      >
        {cardFailedVantivError()}
      </RACModalCard>
    </>
  );
}

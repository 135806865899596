/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

import {
  Grid,
  makeStyles,
  RACButton,
  RACCheckBox,
  RACCOLOR,
  Typography,
} from "@rentacenter/racstrap";
// import clsx from 'clsx';
// import React from 'react';
import React, { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { ChartContext } from "../../context/paymentTriangle/chartcontext";

import CustomerAddress from "./CustomerAddress";
import { BarChart, Bar, XAxis, YAxis } from "recharts";
import { useHistory, useParams } from "react-router-dom";
import { CustomerdataContext } from "../../context/customer/customerdata";
import { HistorydataContext } from "../../context/history/historyContext";

// import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
// import { useCustomerPaymentSummary } from '../../../context/CustomerPaymentSummary/CustomerPaymentSummaryProvider';
// import { formatDateString } from '../../../utils/utils';
// import {CustomerAddress} from './CustomerAddress';

// import {
//   getPaymentScheduleValue,
//   getDaysPaidValue,
// } from '../../../constants/constants';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: "100%",
    margin: "1.25rem",
  },
  halfContainer: {
    width: "100%",
  },
  noLeftPadding: {
    paddingLeft: "0 !important",
  },
  leftRightPadding12: {
    padding: `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(12)}`,
  },
  valueText: {
    color: `${RACCOLOR.GRAY}`,
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
  },
  outlinedBox: {
    width: "fit-content",
    background: RACCOLOR.LAVENDER_MIST,
    padding: theme.typography.pxToRem(15),
    borderRadius: theme.typography.pxToRem(6),
  },
  outlinedBoxValue: {
    fontFamily: "OpenSans-bold",
  },
  noTopAndBottomPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  boxesContainer: {
    padding: `
    ${theme.typography.pxToRem(24)} 
    ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(
      24
    )} ${theme.typography.pxToRem(10)}`,
  },
  alignChildrenBottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  row: { marginBottom: theme.typography.pxToRem(14) },
  selectOptionsPaper: {
    maxHeight: theme.typography.pxToRem(200),
  },
}));

export const CustomerInformationDetails = (props:any) => {
  console.log(props.configDetails, "The porps in sfc csfd")
  interface ParamType {
    customerId: string;
    agreementId: string;
  }
  const { customerId } = useParams<ParamType>();
  const { agreementId } = useParams<ParamType>();
  const [customerparamid]: any = useState(customerId);
  const [agreementparamid]: any = useState(agreementId);
  const [commitmentTime, setCommitmentTime]: any = useState();
  const history = useHistory();

  const { chartdata, setchartdata } = useContext(ChartContext);
  const { customerdata, setcustomerdata, commitmentPayTringle } =
    useContext(CustomerdataContext);
  const { historydata, sethistorydata } = useContext(HistorydataContext);
  const [commitmentDate, setCommitmentDate]: any = useState();
  useEffect(() => {
    if (
      commitmentPayTringle !== undefined &&
      commitmentPayTringle.commitmentDate !== undefined
    ) {
      let date = new Date(commitmentPayTringle.commitmentDate);
      let res = date.toISOString().substring(0, 10);
      const commitDate = moment(res).format("MM/DD/YYYY");
      setCommitmentDate(commitDate);

      let hours = date.getHours(); // gives the value in 24 hours format
      let AmOrPm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12;
      let minutes = date.getMinutes() === 0 ? "00" : date.getMinutes();
      let finalTime = hours + ":" + String(minutes).padStart(2, '0') + " " + AmOrPm;
      setCommitmentTime(finalTime);
    }
  }, [commitmentPayTringle]);

  const schedule: any =
    customerdata == undefined
      ? undefined
      : customerdata.data?.GetCustomer?.value == undefined
      ? undefined
      : customerdata.data?.GetCustomer?.value?.employerReferences == undefined
      ? undefined
      : customerdata.data?.GetCustomer?.value?.employerReferences[0]
          .employerPayscheduleDesc;
  const datahistory: any = historydata?.length;
  const datepaid: any =
    customerdata == undefined
      ? undefined
      : customerdata.data?.GetCustomer?.value == undefined
      ? undefined
      : customerdata.data?.GetCustomer?.value?.employerReferences == undefined
      ? undefined
      : customerdata.data?.GetCustomer?.value?.employerReferences[0]
          .daysPaidDesc;

  console.log(chartdata, "chart data using context");

  let masterdatepaid: string;

  if (
    datepaid == "Monday" ||
    datepaid == "Tuesday" ||
    datepaid == "Wednesday" ||
    datepaid == "Thursday" ||
    datepaid == "Friday" ||
    datepaid == "Saturday" ||
    datepaid == "Sunday"
  ) {
    console.log("entered if");

    masterdatepaid = datepaid;
  } else {
    masterdatepaid = datepaid;
  }

  const redirectionToAccount = () => {
    const customerid = customerparamid;

    history.push({
      pathname: `/am/customer/${customerid}`,
    });
  };

  // Sample data
  const data = [
    { name: "1-16 days", students: 4 },
    { name: "7-14 days", students: 10 },
    { name: "15+ days", students: 2 },
  ];

  const classes = useStyles();

  // const {
  //   customerDetails: {
  //     pifAgreements,
  //     epoAgreements,
  //     skip,
  //     hard,
  //     employerReferences,
  //     customerSinceDate,
  //   },
  // } = useCustomerDetails();

  // const { customerPaymentSummary: customerPaymentSummaryResponse } =
  //   useCustomerPaymentSummary();

  // const createdDateString = `${formatDateString(customerSinceDate)}`;

  // const activeEmployerReferences =
  //   employerReferences &&
  //   employerReferences.filter(
  //     (employer) => employer.active?.toUpperCase() === 'Y'
  //   );

  // const primaryActiveEmployerReferences =
  //   activeEmployerReferences && activeEmployerReferences[0];

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item sm={6} md={6} lg={7}>
          <CustomerAddress 
           agreementAddressDetails={props.agreementAddressDetails}
           configDetails ={props.configDetails}
           />
          {/* <DeliverySlots /> */}
          {/* <"string"/> */}
        </Grid>
        <Grid item sm={6} md={4} lg={5}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
            classes={{ root: classes.leftRightPadding12 }}
          >
            <Grid item sm={6} md={4} lg={4}>
              {/* <Grid container spacing={3}> */}
              <Grid container spacing={1} classes={{ root: classes.row }}>
                <Typography variant="h4">Payment Info</Typography>
                {/* </Grid> */}
              </Grid>

              <Grid container spacing={1} classes={{ root: classes.row }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                  <Typography display="inline" variant="body1">
                    Schedule:
                  </Typography>
                </Grid>
                <Grid
                  className={classes.alignChildrenBottom}
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={7}
                >
                  <Typography
                    display="inline"
                    variant="body1"
                    className={classes.valueText}
                  >
                    {/* {pifAgreements} */}
                    {schedule || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} classes={{ root: classes.row }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                  <Typography display="inline" variant="body1">
                    Checks:
                  </Typography>
                </Grid>
                <Grid
                  className={classes.alignChildrenBottom}
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={7}
                >
                  <Typography
                    display="inline"
                    variant="body1"
                    className={classes.valueText}
                  >
                    {/* {epoAgreements} */}
                    {datahistory || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} classes={{ root: classes.row }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                  <Typography display="inline" variant="body1">
                    Day Paid:{" "}
                  </Typography>
                </Grid>
                <Grid
                  className={classes.alignChildrenBottom}
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={7}
                >
                  <Typography
                    display="inline"
                    variant="body1"
                    className={classes.valueText}
                  >
                    {/* {customerPaymentSummaryResponse?.paymentMetrics
                      ?.nsfAndCccbCount || ''} */}
                    {masterdatepaid || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} classes={{ root: classes.row }}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={5}>
                  <Typography display="inline" variant="body1">
                    NSFC/CCCB:{" "}
                  </Typography>
                </Grid>
                <Grid
                  className={classes.alignChildrenBottom}
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={7}
                >
                  <Typography
                    display="inline"
                    variant="body1"
                    className={classes.valueText}
                  >
                    {/* {customerPaymentSummaryResponse?.paymentMetrics
                        ?.nsfAndCccbCount || ''} */}
                    {"-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6} md={4} lg={7}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="h4"># Late Last 10</Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ right: "50px", top: "60px", position: "absolute" }}
                >
                  <BarChart
                    width={400}
                    height={200}
                    data={chartdata}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <Bar
                      dataKey="students"
                      fill="rgb(13, 110, 253)"
                      radius={20}
                      barSize={10}
                    />
                    {/* <CartesianGrid stroke="#ccc" /> */}
                    <XAxis dataKey="name" axisLine={false} />
                    <YAxis axisLine={false} />
                    {/* <CartesianGrid horizontal={false} vertical={false} /> */}
                  </BarChart>
                </Grid>

                <Grid
                  item
                  sm={2}
                  md={2}
                  lg={12}
                  container
                  justifyContent="flex-end"
                  style={{ float: "right", top: "204px", position: "relative" }}
                >
                  <RACButton
                    variant="contained"
                    size="large"
                    color="primary"
                    // loading={pending}
                    // className={classes.saveButton}
                    onClick={(e) => redirectionToAccount()}
                  >
                    Account Management
                  </RACButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={8} style={{ marginTop: "10%" }}>
              <Grid container spacing={1} classes={{ root: classes.row }}>
                <Typography variant="h4">Commitment</Typography>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Grid container spacing={1} classes={{ root: classes.row }}>
                    <Grid item lg={3}>
                      <Typography display="inline" variant="body1">
                        Type :
                      </Typography>
                    </Grid>
                    <Grid className={classes.alignChildrenBottom} item lg={9}>
                      <Typography
                        display="inline"
                        variant="body1"
                        className={classes.valueText}
                      >
                        {commitmentPayTringle !== undefined
                          ? commitmentPayTringle.commitmentTypeDesc
                          : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} classes={{ root: classes.row }}>
                    <Grid item lg={3}>
                      <Typography display="inline" variant="body1">
                        Time :
                      </Typography>
                    </Grid>
                    <Grid className={classes.alignChildrenBottom} item lg={9}>
                      <Typography
                        display="inline"
                        variant="body1"
                        className={classes.valueText}
                      >
                        {commitmentTime !== undefined ? commitmentTime : "-"}
                        {/* {commitmentPayTringle !== undefined ? commitmentPayTringle.commitmentDate : null} */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1} classes={{ root: classes.row }}>
                    <Grid item lg={4}>
                      <Typography display="inline" variant="body1">
                        Date :
                      </Typography>
                    </Grid>
                    <Grid className={classes.alignChildrenBottom} item lg={8}>
                      <Typography
                        display="inline"
                        variant="body1"
                        className={classes.valueText}
                      >
                        {commitmentDate !== undefined ? commitmentDate : "-"}
                        {/* {commitmentDate !== undefined ? commitmentDate : null} */}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} classes={{ root: classes.row }}>
                    <Grid item lg={4}>
                      <Typography display="inline" variant="body1">
                        Amount :
                      </Typography>
                    </Grid>
                    <Grid className={classes.alignChildrenBottom} item lg={8}>
                      <Typography
                        display="inline"
                        variant="body1"
                        className={classes.valueText}
                      >
                        {commitmentPayTringle !== undefined ? "$" : null}
                        {commitmentPayTringle !== undefined
                          ? Number(commitmentPayTringle.commitmentAmount).toFixed(2)
                          : "-"}
                        {/* {commitmentPayTringle !== undefined ? commitmentPayTringle.commitmentAmount : null} */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-case-declarations  */
/* eslint-disable react/no-children-prop  */


/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */



/* eslint-disable sonarjs/prefer-single-boolean-return */
import React, { useEffect, useState, useContext } from 'react';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent,
    RACButton,
    RACModalCard,
    RACSelect,
    RACTextbox,
    ListItem,
    List,
    RACCheckBox,
    CircularProgress,
    RACRadio,
    RACInfoIcon,
    TextareaAutosize,
    RACTable,
    RACTableCell,
    RACTableRow,
    // LinearProgress

} from '@rentacenter/racstrap';
import { fontSize } from '@mui/system';


// import { useLocation } from 'react-router-dom';
// import { useParams } from 'react-router';

// import SearchFilter from './CriteriaSearch';
// import { getInventorySearch, PrintFaxSheet, getSalesInfo } from '../../api/user';
// import DynamicGridComponent from './grid';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg'
import { ReactComponent as LocationIcon } from '../../assets/images/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone-icon.svg';

// import { InventoryContext } from '../../context/InventoryInfoContext';
// import { InventoryGlobalStyles } from './Styles/InventoryGlobalStyles';
// import { searchResultStyles } from './Styles/searchResultStyles';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RentaldataContext } from '../../../context/Riscontext';
import { takePaymentStyles } from '../../../stylesJS/makeStyles';
import { GlobalStyles } from '../../../stylesJS/global';
import { getInventorySearch } from '../../../api/user';
import CONSTANTS from '../../constants/constant';
import { searchResultStyle } from '../../stylesJS/searchResult';
import { DynamicGridComponentProps } from '../../interface/rentalItemInterface';
import { agreementContext } from '../../context/PaymentInformationContext';
import { AgreementContextValue } from '../../interface/contextInterface';


export default function DynamicGridComponent(props: DynamicGridComponentProps) {
    const classes2 = takePaymentStyles();
    const classes = searchResultStyle();
    const history = useHistory();
    const Risheader = [
        { display: "" },
        { display: "Item #" },
        { display: "Serial #" },
        { display: "Model" },
        { display: "Description" },
        { display: "Condition" },
        { display: "Purchase Date" },
        { display: "Purchase Cost" },
        { display: "Remaining Value" },
        { display: "Suggested Price" }
    ];

    /**
     * RIS_PC_NO_17 These are the instance varible which are received as props and
     * hasMore,loader,scrollset variables are declared.
     */
    const { agreementDetails } = useContext<AgreementContextValue>(agreementContext)
    const { arrradio, setarrradio,tabledata ,settabledata,customerInfo} = props
    const [hasMore, sethasMore] = useState<boolean>(true)
    const [loader, setloader] = useState<boolean>(false)
    const [scrollset, setScrollset] = useState<number>(0)

    let condition=CONSTANTS.EMPTY_STRING;


    /**RIS_PC_NO_21 This useEffect is performed to render the radio buttons
    * without checked.
    */

    useEffect(() => {

        if(tabledata != undefined && tabledata.length != 0 &&customerInfo.editNo=="1")
        {
            let radioArray: boolean[] = new Array(tabledata.length)
            radioArray.fill(true)
            setarrradio(radioArray)
            props.setitemselectFlag(true)

        }
        if (tabledata != undefined && tabledata.length != 0 &&customerInfo.editNo!="1") {
            let radioArray: boolean[] = new Array(tabledata.length)
            radioArray.fill(false)
            setarrradio(radioArray)
        }
    }, [tabledata])

    const hideLengthyText = (Text: string, MaxLength: number, Mask: string) =>
     {
        return Text?.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
     };

    /**RIS_PC_NO_19 The nextscroll function is triggered when the infinity scroll
    * is performed.
    * RIS_PC_NO_20 The nextscroll function is created and getInventorySearch() service call is made.
    */

    const nextscroll = async () => {


        const { Request } = props
        setScrollset(scrollset + 1)
        Request['setNumber'] = scrollset +1;
        // sethasMore(true)
        setloader(true)
        const response = await getInventorySearch(Request);
        setloader(false);
        const val = response.data.response
        let tabledata1= val.filter((item) => !agreementDetails.some((agrItem) => agrItem.agreementId === item.inventoryId))
        console.log("tabledata1",tabledata1)
        // setgridvalues([...gridvalues, ...val])
        settabledata([...tabledata, ...tabledata1])
        let radioarray1=new Array(tabledata1.length)
         radioarray1.fill(false)
         setarrradio([...arrradio,...radioarray1])



        if (tabledata1.length === 0) {
          sethasMore(false)
        }
      }





    /** RIS_PC_NO_18 In this function headers for the table is rendered.*/

    const renderTableHead = () => (
        <>
            {Risheader.map((val, index) => {
                if (val.display == "Purchase Cost" ||
                    val.display == "Remaining Value" || val.display == "Suggested Price") {
                    return (
                        <RACTableCell style={{ textAlign: "right" ,backgroundColor:"none"}}  key={index}>
                            {val.display}
                        </RACTableCell>
                    );

                } else {
                    return (
                        <RACTableCell  key={index}>
                            {val.display}
                        </RACTableCell>
                    );

                }

            })}
        </>

    )

    /**RIS_PC_NO_19 renderTableContent() function is called for rendering the
     * table content.
     * RIS_PC_NO_21 props.risRadiobtnonclick() function is triggered when clicking the
     * radio button.
     */
    const renderTableContent = () => (
        <>
            {tabledata!= undefined &&
                tabledata.length > 0
                ? tabledata.map((value, index: number) => {
                    if (value.conditionEn == 'Based on DOR-system push') {
                        condition = 'Auto';
                      } else if (value.conditionEn == 'Term pushed up/down by Coworker') {
                        condition = 'Exception';
                      } else if (value.conditionEn == 'New - Full Term') {
                        condition = 'New';
                      } else {
                        condition = value.conditionEn;
                      }
                    return (
                        <RACTableRow key={index}  className={`${classes2.tablerowtheme}`}>
                            <RACTableCell style={{paddingBottom:"0px",paddingLeft:"0px"}}>

                                <RACRadio
                                    className={`${classes2.formcheckinput} ${classes2.checkboxStyle}`}
                                    // name={"Risradio"+index}
                                    // id={index}
                                    onClick={() => props.risRadiobtnonclick(index)}
                                    checked={arrradio[index] == false ? false : true}
                                    value=""
                                // id={value.Agreementid + "," + "id"}
                                />
                            </RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}><a style={{color:'#2179FE', textDecoration: "none", }} className={`${classes2.racpadlink}`}
                                href={`/inventory/${value.inventoryNumber}/inventoryinformation`}
                            // onClick={()=>{history.push({pathname:`/inventory/${value.inventoryNumber}/inventoryinformation`})}}
                            >
                                {value.inventoryNumber}
                            </a>
                            </RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}} className={`${classes.fontN12}`}>{value.serialNumber}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`}>{value.modelNumber}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`} title={value.inventoryDescription}>{hideLengthyText(value.inventoryDescription,20,'...')}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`}>{condition}</RACTableCell>
                            <RACTableCell style={{paddingBottom:"0px"}}className={`${classes.fontN12}`}>{value.purchaseDate}</RACTableCell>
                            <RACTableCell className={`${classes.fontN12}`} style={{ textAlign: "right",paddingBottom:"0px" }}>{"$"+value.purchaseCost}</RACTableCell>
                            <RACTableCell className={`${classes.fontN12}`} style={{ textAlign: "right",paddingBottom:"0px" }}>{"$"+value.remainingValue}</RACTableCell>
                            <RACTableCell className={`${classes.fontN12}`} style={{ textAlign: "right" ,paddingBottom:"0px",paddingRight:"1%"}}>{"$"+(Number(value.cashPrice)).toFixed(2)}</RACTableCell>
                        </RACTableRow>
                    );
                })
                : null}
        </>



    )
    return (
        //     <Grid className={`${fixedGridClassName.fixTableHead} ${fixedGridClassName.fixTableHeight400}`}   >
        //   {gridvalues.length > 7 ?
        //     <InfiniteScroll
        //       dataLength={gridvalues.length} //This is important field to render the next data
        //     //   next={nextscroll}
        //     //   hasMore={hasMore}
        //       height={400}
        //       loader={<Grid className={classes.GridLoader}><CircularProgress ></CircularProgress></Grid> }
        //     >
        //       <RACTable
        //         renderTableHead={renderTableHead}
        //         renderTableContent={renderTableContent}
        //         stickyHeader
        //       />



        //     </InfiniteScroll> : (

        //       <RACTable

        //         renderTableHead={renderTableHead}
        //         renderTableContent={renderTableContent}

        //       />

        //     )}
        // </Grid>

        /**RIS_PC_NO_17 <RACTable/> contains renderTableHead and renderTableContent
         * attributes where rederTableHead() and rendertableContent() function is called
         *
         * RIS_PC_NO_19 If tabledata length is greater than 7 the infinity scroll is rendered else
         * the normal table is rendered.
         *
         */
        <Grid >
            <Typography className={`${classes.title2} ${classes2.mt3} ${classes.srmargin}`}>
                Search Results
            </Typography>
            <Card style={{borderRadius:"50%"}} className={`${classes.card} ${classes.cardHeightN} ${classes2.mb4} `}>
                <CardContent className={classes.p0}>

                 {/* <RACTable
                     className={classes. fixTableHead}
                    // className={classes1.ractable}
                    renderTableHead={renderTableHead}
                    renderTableContent={renderTableContent}
                    stickyHeader
                    /> */}
                     {tabledata.length > 7 ?
        <InfiniteScroll
        dataLength={tabledata.length} //This is important field to render the next data
        next={nextscroll}
        hasMore={hasMore}
        height={ '20.0rem'}
        loader={loader ? <Grid className={classes.GridLoader}><CircularProgress ></CircularProgress></Grid> : null}
      >
        <RACTable
         className={classes. fixTableHead}
          renderTableHead={renderTableHead}
          renderTableContent={renderTableContent}
          stickyHeader
        />



      </InfiniteScroll> : (

        <RACTable
        className={classes. fixTableHead}
        renderTableHead={renderTableHead}
        renderTableContent={renderTableContent}
        stickyHeader

        />

      )}
             </CardContent>
           </Card>

        </Grid >

    )
}

/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext } from "react";
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";
import { postReversePayment, getDocument, storeException } from "../../api/user";
import { b64toBlob, getContentType } from "../../utils/utils";
import { ReverseReasonObject } from "../../constants/constants";
import StoreException from "../microfrontends/StoreException";
import { CustomerdataContext } from "../../context/customer/customerdata";

export default function CheckReversalComponent() {
  const [StoreExceptionEnable, setStoreExceptionEnable] = React.useState(false);
  const { StoreExceptionPayload, setStoreExceptionPayload } = React.useContext(CustomerdataContext)

  const classes = reversePaymentStyle();
  let stopFlag = false;
  const {
    openCheckMOPopup,
    exceptionModal,
    postReversePayPayload,
    tenderTypesUsed,
    loadingModal,
    CCAmount,
  } = useContext(GeneralStateContext);
  const {
    setOpenCheckMOPopup,
    setLoadingModal,
    setExceptionModal,
    setCardReversal,
    setReverseReceiptURL,
    setOpenViewReceipt,
    setReverseReasonValue,
    setIsPostReversalFailed,
    setFailedReceiptFetching,
    setReversedReceiptID
  } = useContext(GeneralStateDispatchContext);

  const apiCall = async (postReversalData) => {
    const receiptResponse = await getDocument(
      postReversalData.data.reversedReceiptId,
      "RCP"
    );
    if (
      receiptResponse.status == 200 &&
      Object.keys(receiptResponse.data).length > 0 &&
      receiptResponse.data.fileList[0].fileObject !== null
    ) {
      const Receiptrecord = receiptResponse.data.fileList.filter(
        (x) => x.docType === "PRCT"
      );
      if (Receiptrecord?.length) {
        const type = getContentType(Receiptrecord[0]?.fileName);
        setReverseReceiptURL(
          URL.createObjectURL(b64toBlob(Receiptrecord[0]?.fileObject, type))
        );
      }
      console.log("5tyt", Receiptrecord);
      stopFlag = true;
      setOpenViewReceipt(true);
    }
  };
  const acceptCheckMOReversal = async () => {
    setOpenCheckMOPopup(false);
    if (tenderTypesUsed.card && Number(CCAmount.totalCardPayEligible) > 0) {
      setCardReversal(true);
    } else {
      setStoreExceptionEnable(true)
    }
  };
  const invokeGenerateReceipt = async (postReversalData) => {
    setReversedReceiptID(postReversalData.data.reversedReceiptId);
    let receiptTimeOut = true;
    setTimeout(async function () {
      receiptTimeOut = false;
    }, 25000);
    // const paymentReceiptRequest = {
    //   receiptId: Number(postReversalData.data.reversedReceiptId),
    //   resultText: "",
    //   cellPhoneActivation: [],
    // };
    setLoadingModal({ ...loadingModal, document: true });
    while (!stopFlag && receiptTimeOut) {
      await apiCall(postReversalData);
    }
    // const receiptResponse = await ReceiptGeneration(paymentReceiptRequest);
    setLoadingModal({ ...loadingModal, document: false });
    // if (receiptResponse.status === 200) {
    //   setReverseReceiptURL(
    //     URL.createObjectURL(
    //       b64toBlob(receiptResponse.data.receiptHtmlUrl, "text/html")
    //     )
    //   );
    //   setOpenViewReceipt(true);
    // } else if (receiptResponse.status === 500) {
    //   setExceptionModal({ ...exceptionModal, serverError: true });
    // } else if (receiptResponse.status === 400) {
    //   setExceptionModal({ ...exceptionModal, badRequest: true });
    // }
    if (!stopFlag) {
      setFailedReceiptFetching(true);
      // setExceptionModal({ ...exceptionModal, badRequest: true });
    }
  };
  const buildCheckReversalModal = () => {


    // This method will be fetching the current reversal receipt.

    // This method will be invoking the reverse payment API when the money order reversal is accepted.
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.bold}`}>Warning !</Typography>
          <Typography className={`${classes.bold}`}>
            Do not issue cash reversal. Checks/Money orders can't be refunded
            within 3 days of payments.
          </Typography>
          <Typography className={`${classes.bold}`}>
            Do you wish to continue?
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="outlined"
            color="secondary"
            onClick={() => {
              setOpenCheckMOPopup(false);
              setReverseReasonValue(ReverseReasonObject);
            }}
          >
            No
          </RACButton>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => acceptCheckMOReversal()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const storeExceptionCompleted = async (updatedPayload?) => {
    debugger
    setStoreExceptionEnable(false)
    const StoreExceptionPayload = updatedPayload
    setLoadingModal({ ...loadingModal, reversePay: true });
    const postReversal = await postReversePayment(postReversePayPayload);
    setLoadingModal({ ...loadingModal, reversePay: false });
    if (postReversal?.status === 200) {
      if (postReversal?.data && StoreExceptionPayload) {
        const payload = StoreExceptionPayload?.map((obj) => {
          return obj.identifierId = Number(postReversal?.data?.reversedReceiptId)
        })
        const Request = {
          storeException: StoreExceptionPayload
        }
        storeException(Request)
        setStoreExceptionPayload([])
      }
      invokeGenerateReceipt(postReversal);
    } else if (postReversal.status === 400) {
      // setExceptionModal({ ...exceptionModal, badRequest: true });
      setIsPostReversalFailed(true);
    } else if (postReversal.status === 500) {
      setExceptionModal({ ...exceptionModal, serverError: true });
    }

  }
  const storeExceptionCancelled = async () => {
    debugger
    setStoreExceptionEnable(false);
    setReverseReasonValue(ReverseReasonObject);
  }
  return (

    <>

      {StoreExceptionEnable ? (<StoreException
        refCode={'RFN'}
        identifierType={'RECEIPT'}
        storeExceptionCancelled={storeExceptionCancelled}
        storeExceptionCompleted={storeExceptionCompleted}
      />) : null}
      <RACModalCard
        isOpen={openCheckMOPopup}
        maxWidth="xs"
        borderRadius={"25px !important"}
        //eslint-disable-next-line react/no-children-prop
        children={buildCheckReversalModal()}
      />
    </>
  );
}

/* eslint-disable prefer-const */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-console */
/* eslint-disable no-debugger */

import { useState, useEffect ,useContext} from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
//import {RACModalCard} from '@rentacenter/racstrap';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACCheckBox,
  RadioColor,
  RACRadio,
  RACModalCard,
  RACToggle,
  RACChip,
  RACTable,
  RACTableRow,
  RACTableCell,
  RACBadge,
  Grid,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
  Box,
  CircularProgress,
  RACCOLOR,
  makeStyles,
  RACAlert,
  RACInfoIcon,
} from "@rentacenter/racstrap";
import { number } from 'yup';
import { userTraceContextData } from "../../context/UserTraceContext";
import calculationModulePayloadFormation from "./commonModulePayloadFormation";
import {calculatePaymentModule} from '../../api/user'
// import { RacTextbox } from '@rentacenter/racstrap/dist/components/TextboxMain/TextboxMain';

export default function PopUpBackRent(props: any) {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;
  const classes = takePaymentStyles();
  //var StateTax = props.paygridvalues;
  let [backRentDetails, setBackRentDetails] = useState({
    backRentOwed: "0.00",
    amount: "0.00",
    salesTax: "0.00",
    taxRate: "0.00",
    total: "0.00"
  })

  const [popupOtherIncome, setpopupOtherIncome] = useState(true);
  const [disableProceedBtn, setDisableProceedBtn] = useState(false);
  let [totalBackRentValue, setTotalBackRentValue] = useState<any>();
  const [acceptSpinner,setacceptSpinner] = useState(false)
  let salesTaxValue: any = Number(props.taxRate) * Number(props.backRentInfo.amount)
  console.log("backrenttax", props.taxRate)

  useEffect(() => {
    console.log('reached the obj useEffect', props.backRentInfo.total);

    // eslint-disable-next-line no-debugger
    debugger;

    // if( props.backRentInfo )
    // {
    totalBackRentValue = props.backRentInfo.total;
    setTotalBackRentValue(totalBackRentValue)
    let obj = {
      backRentOwed: props.backRentInfo.backRentOwed,
      amount: props.backRentInfo.amount,
      salesTax: props.backRentInfo.salesTax,
      taxRate: props.backRentInfo.taxRate,
      total: props.backRentInfo.total
    }
    console.log("objj", obj)
    backRentDetails = obj
    setBackRentDetails(backRentDetails)

    // }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-debugger
    debugger;
    if (props.backRentEditInfo) {
      totalBackRentValue = props.backRentEditInfo.total;
      setTotalBackRentValue(totalBackRentValue);
      let obj = {
        backRentOwed: props.backRentEditInfo.backRentOwed,
        amount: props.backRentEditInfo.amount,
        salesTax: Number(props.backRentEditInfo.salesTax).toFixed(2),
        taxRate: props.backRentEditInfo.taxRate,
        total: props.backRentEditInfo.total
      }

      backRentDetails = obj
      setBackRentDetails(backRentDetails)


    }
  }, [props.backRentEditInfo])

  const handleTotalField = (e) => {

    const salesTax = Number(e.target.value) * Number(props.backRentInfo?.taxRate)
    const removeComa = String(e.target.value).replace(/,/g, "")
    setTotalBackRentValue(removeComa)
    setBackRentDetails({ ...backRentDetails, total: removeComa })

    if (Number(e.target.value) <= (Number(props?.backRentInfo?.amount) + Number(props?.backRentInfo?.salesTax)) && Number(e.target.value) != 0) {
      setDisableProceedBtn(false)
    }
    else {
      setDisableProceedBtn(true)
    }


  }

  const handleOnblur = (e) => {
    if (e.target.value == "" || e.target.value == 0.0) {
      setTotalBackRentValue("0.00")
      setBackRentDetails({ ...backRentDetails, total: "0.00" })

    }
    else {
      const removeComa = String(e.target.value).replace(/,/g, "")
      setTotalBackRentValue(removeComa)
      setBackRentDetails({ ...backRentDetails, total: Number(removeComa).toFixed(2) })

    }
  }

  const commonModuleBackRentTrigger = async()=>{
    debugger
    if(props.configFlags?.commonModuleFlag==1){
      commonModuleProceedbtn()
    }else{
      onClickproceedbtn()

    }

  

  }

  const commonModuleProceedbtn = async() =>{
    // debugger
    setacceptSpinner(true);
    const splitTax=Number(props.backRentInfo.taxRate) + 1;
    const amountWithoutTax=(Number(backRentDetails.total)/splitTax).toFixed(2);
    const newTax=(Number(backRentDetails.total)-Number(amountWithoutTax)).toFixed(2);
    salesTaxValue=Number(newTax);
    let array:any=[{
      backRentOwed:props.backRentInfo.backRentOwed,
      amount:amountWithoutTax,
      salesTax:Number(newTax).toFixed(2),
      total:Number(backRentDetails.total).toFixed(2),
      taxRate:props.taxRate,
      type:'Back Rent'
     }]
    // commonModuleBackRentTrigger(array)

    let commonModulePayload = await calculationModulePayloadFormation( {
      customerId:props.customerId,
      storeNumber:props.storeNumber,
      checkedAgr:array})
      console.log("commonModulePayload",commonModulePayload)

      let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
      console.log("commonModuleServiceRes",commonModuleServiceRes)
      
      commonModuleServiceRes=commonModuleServiceRes?.data;
      let commonModuleAgrRes =  commonModuleServiceRes?.paymentDetails.filter((x)=>x.storeNumber==String( window.sessionStorage.getItem("storeNumber")))
      let backRent=commonModuleAgrRes[0]?.otherIncome.filter((x)=>x.itemCategory=="BKRPAY")
      let salestax:any=0
      let backRentAmount:any=0
      let totalAmount:any=0
      for(let i=0;i<backRent?.length;i++)
      {
        
        backRentAmount=Number(backRentAmount)+Number(backRent[i]?.amount)
        salestax=Number(salestax) + Number(backRent[i]?.tax)

      }
      totalAmount=(Number(backRentAmount) + Number(salestax)).toFixed(2)
      setacceptSpinner(false);
      props.response({
        backRentOwed:array[0].backRentOwed,
        amount:backRentAmount,
        salesTax:salestax,
        total:totalAmount,
        taxRate:array[0].taxRate,
        type:'Back Rent'
       })

  }

  const onClickproceedbtn = () => {
    const salesTaxSplit = Number(totalBackRentValue) * Number(props.taxRate)
    const splitTax = Number(props.backRentInfo.taxRate) + 1;
    const amountWithoutTax = (Number(backRentDetails.total) / splitTax).toFixed(2);
    const newTax = (Number(backRentDetails.total) - Number(amountWithoutTax)).toFixed(2);
    salesTaxValue = Number(newTax);
    props.response({
      backRentOwed: props.backRentInfo.backRentOwed,
      amount: amountWithoutTax,
      salesTax: Number(newTax).toFixed(2),
      total: Number(backRentDetails.total).toFixed(2),
      taxRate: props.taxRate,
      type: 'Back Rent'
    })
  }

  const cancelBtn = () => {
    let obj = {
      backRentOwed: "0.00",
      amount: "0.00",
      salesTax: "0.00",
      taxRate: "0.00",
      total: "0.00",
    }
    setBackRentDetails(obj)
    props.response(undefined)
  }

  // const onClickCancelBtn = () => {
  //   props.isClosed(false);
  // }
  console.log(totalBackRentValue, "EXIST VALUE");
  console.log("logamount", Number(Number(totalBackRentValue).toFixed(2)), (Number((Number(props?.backRentInfo?.amount) + Number(props?.backRentInfo?.salesTax)).toFixed(2))))

  const BackRentPopUP = () => {

    return (
      <>
        <Grid>
          {/* <RACModalCard
          isOpen={true}
          maxWidth="xs"
          closeIcon={false}
          borderRadius={"25px !important"}
        > */}
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Back Rent Owed</label></span></Grid>
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              <RACTextbox
                // inputlabel="Back Rent owed"
                // inputLabelClassname={classes.formLabel}
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={backRentDetails.backRentOwed}
                isCurrency={true}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Amount</label></span></Grid>
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              <RACTextbox
                // inputlabel="Amount"
                // inputLabelClassname={classes.formLabel}
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={backRentDetails.amount}
                isCurrency={true}
                disabled={true}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Sales Tax</label></span></Grid>
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              <RACTextbox
                //inputlabel="Sales Tax"
                //inputLabelClassname={classes.formLabel}
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                value={backRentDetails.salesTax}
                isCurrency={true}
                disabled={true}
                type="number"
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginBottom: '4%' }}>
            <Grid md={7} style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Total</label><span className={classes.mandatoryfield}>*</span></span></Grid>
            {/* <Grid style={{ width: "60%" , paddingBottom : "2%" , paddingTop : "2%"}}> */}
            <Grid item md={5} style={{ marginLeft: '-25px' }}>
              {/* style={{ width: "100%" , paddingBottom : "2%" , paddingTop : "2%"}} */}
              <RACTextbox
                //inputlabel="Total"
                //className={classes.mb4}
                className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                //required={true}
                //inputLabelClassname={classes.formLabel}
                id="total"
                value={backRentDetails.total}
                OnChange={(e: any) => handleTotalField(e)}
                Onblur={(e: any) => handleOnblur(e)}
                isCurrency={true}
                type="number"
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
          {/* {Number(totalBackRentValue),Number(props?.backRentInfo?.amount)+Number(props?.backRentInfo?.salesTax))} */}
          {Number(Number(totalBackRentValue).toFixed(2)) > (Number((Number(props?.backRentInfo?.amount) + Number(props?.backRentInfo?.salesTax)).toFixed(2))) ?

            <Typography
              component={"label"}

              className={classes.mandatoryfield}
            >
              Amount should not be entered more than the Back Rent Amount

            </Typography> : null}
          <Grid style={{ float: 'right' }}>

            <RACButton
              className={classes.ms2}
              //
              // style={{ 
              // backgroundColor: "white",
              // color: "#2C3333",
              // marginRight:"20px",
              //  }}
              color="primary"
              variant="outlined"
              onClick={() => {
                // props.isClosed(false)
                cancelBtn()
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              className={classes.ms2}
              style={{ marginRight: "50px" }}
              color="primary"
              variant="contained"
              disabled={disableProceedBtn}
              loading={acceptSpinner}
              onClick={() => commonModuleBackRentTrigger()}

            >
              Proceed
            </RACButton>
          </Grid>

          {/* </RACModalCard> */}

        </Grid>
      </>
    )


  }
  //style={{ fontWeight: 'bold' , fontSize: "5%" }}
  return (
    <Grid>

      <RACModalCard
        //style={{width:"80%"}}
        isOpen={props.isOpen}
        maxWidth={"xs"}
        //className={classes.BackRentPopUp}
        title="Back Rent"
        onClose={() => props.isClosed(false)}
        closeIcon={true}
        borderRadius="25px !important"

        /* eslint-disable react/no-children-prop */
        // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
        children={BackRentPopUP()}
      />
    </Grid>
  );
};



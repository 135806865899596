/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */
import { Grid, RACModalCard } from '@rentacenter/racstrap';
import { RACButton, makeStyles } from '@rentacenter/racstrap';
import { Typography } from '@material-ui/core';
import { useState } from 'react';

export interface PaymentOriginModalProps {
    onClose: (onclose: boolean) => void;
    onYes: (item: string) => void;
    convFeeWithOutTax: string;
}

export const useStyles = makeStyles((theme: any) => ({
    py1: {
        padding: '4px',
    },
    textCenter: {
        textAlign: 'center',
    },
    py4: {
        paddingTop: theme.typography.pxToRem(24),
        paddingBottom: theme.typography.pxToRem(24),
    },
    mx1: {
        marginRight: theme.typography.pxToRem(4),
        marginLeft: theme.typography.pxToRem(4),
        borderRadius: theme.typography.pxToRem(8),
        '&:hover': {
            backgroundColor: 'unset !important'
        },
    },
    mx2: {
        width: '40%'
    },
    floatRight: {
        float: 'right',
    },
    ms2: {
        marginLeft: theme.typography.pxToRem(8),
    },
    notesLabel: {
        color: '#2179FE',
        padding: '10px 10px 10px 10px',
        backgroundColor: '#459efd17',
        display: 'inline-block',
        textAlign: 'left',
        marginLeft: '10px',
        marginRight: '10px',
    }
}));

export const PaymentOriginModal = ({
    onClose,
    onYes,
    convFeeWithOutTax
}: PaymentOriginModalProps) => {
    const classes = useStyles();
    const [colorr, setColor] = useState<{ btnphne: 'primary' | 'secondary', btnstore: 'primary' | 'secondary' }>
        ({ btnphne: 'secondary', btnstore: 'secondary' });

    const [varint, setvarint] = useState<{ btnphne: 'outlined' | 'contained', btnstore: 'outlined' | 'contained' }>
        ({ btnphne: 'outlined', btnstore: 'outlined' });

    const [textColor, setTextColor] = useState({ btnphne: '#4A5174', btnstore: '#4A5174' });
    const [show, setShow] = useState<boolean>(false);
    const [phbtn, setPhbtn] = useState<boolean>(false);
    const [phNobtn, setPhNobtn] = useState<boolean>(false);
    const [loadingSaveYs, setLoadingSaveYs] = useState<boolean>(false);
    const [loadingStr, setLoadingStr] = useState<boolean>(false);
    
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    const handleCloseClick = async () => {
        await delay(2000);
        onClose(false);
      };
    const handleOvrPhClick = () => {
        setColor(prevColors => ({
            ...prevColors,
            btnphne: prevColors.btnphne === 'secondary' ? 'primary' : 'secondary'
        }));
        setvarint(prevVarint => ({
            ...prevVarint,
            btnphne: prevVarint.btnphne === 'outlined' ? 'contained' : 'outlined'
        }));
        setTextColor(prevColors => ({
            ...prevColors,
            btnphne: prevColors.btnphne === '#4A5174' ? '#fff' : '#4A5174'
        }));
    };

    const handleStreClick = () => {
        setLoadingStr(true);
        setColor(prevColors => ({
            ...prevColors,
            btnstore: prevColors.btnstore === 'secondary' ? 'primary' : 'secondary'
        }));
        setvarint(prevVarint => ({
            ...prevVarint,
            btnstore: prevVarint.btnstore === 'outlined' ? 'contained' : 'outlined'
        }));
        setTextColor(prevColors => ({
            ...prevColors,
            btnstore: prevColors.btnstore === '#4A5174' ? '#fff' : '#4A5174'
        }));
    };

    const diablebtn = () => {
        setPhbtn(phbtn === false ? true : false);
    }
    const toggleContent = () => {
        setShow(show === true ? false : true);
    }
    const disableNobtn = () => {
        setLoadingSaveYs(true);
        setPhNobtn(phNobtn === false ? true : false);
    }
    const paymentOriginPopup = () => {

        return (
            <Grid>
                <Grid className={classes.py1}>
                    <Typography style={{ fontFamily: 'OpenSans-semiBold', fontSize: "14px", paddingLeft: "26px", color: "#4A5174" }}>
                        Please select the payment origin for this transaction.
                    </Typography>
                </Grid>
                <Grid className={`${classes.py4} ${classes.textCenter}`}>
                    <RACButton
                        className={classes.mx1}
                        variant={varint.btnphne}
                        color={colorr.btnphne}
                        disabled={phbtn}
                        style={{ fontWeight: 'bold', color: textColor.btnphne }}
                        onClick={() => {
                            handleOvrPhClick();
                            toggleContent();
                            diablebtn();
                        }}
                    >
                        Over the Phone
                    </RACButton>
                    <RACButton
                        className={`${classes.mx1} ${classes.mx2}`}
                        variant={varint.btnstore}
                        color={colorr.btnstore}
                        disabled={phbtn}
                        loading={loadingStr}
                        style={{ fontWeight: 'bold', color: textColor.btnstore }}                        
                        onClick={() => {
                            handleStreClick();
                            diablebtn();
                            handleCloseClick();
                        }}
                    >
                        In Store
                    </RACButton>
                </Grid>
                {show ? (<>
                    <Typography variant="body2" className={classes.textCenter}>
                        <span className={classes.notesLabel}>
                            A convenience fee of ${Number(convFeeWithOutTax).toFixed(2)} (plus tax when applicable) will be added. Would you like to continue?
                        </span>
                    </Typography>
                    <Grid style={{ display: 'flex', float: 'right', padding: '10px' }}>
                        <RACButton
                            className={`${classes.floatRight} ${classes.ms2}`}
                            color="primary"
                            variant="contained"
                            loading={loadingSaveYs}
                            style={{ 'marginRight': '2px' }}
                            onClick={() => {
                                disableNobtn();
                                onYes("Pay By Phone");
                                handleCloseClick();
                            }}
                        >
                            Yes
                        </RACButton>
                        <RACButton
                            className={`${classes.floatRight} ${classes.ms2}`}
                            color='default'
                            variant="outlined"
                            disabled={phNobtn}
                            onClick={() => {
                                handleOvrPhClick();
                                diablebtn();
                                toggleContent();
                            }}
                        >
                            No
                        </RACButton>
                    </Grid> </>) : null}
            </Grid>
        );
    };

    return (
        <RACModalCard
            isOpen
            maxWidth="xs"
            title="Payment Origin"
            onClose={() => onClose(false)}
            closeIcon={true}
            // eslint-disable-next-line react/no-children-prop
            children={paymentOriginPopup()}
        />
    );
};

/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-console */

import React, { useContext, useEffect } from "react";
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
  Card,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACSelect,
} from "@rentacenter/racstrap";
import {
  ModalRadius,
  CardHeaderData,
  ReverseReasonObject,
  ParamType,
} from "../../constants/constants";
import { useParams } from "react-router-dom";
import { b64toBlob, getContentType } from "../../utils/utils";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";
import { CustomerdataContext } from "../../context/customer/customerdata";
import {
  ReverseCCPayment,
  postReversePayment,
  // ReceiptGeneration,
  Swipecustomercard,
  getDocument,
  storeException
} from "../../api/user";
import StoreException from "../microfrontends/StoreException";
export default function CardReversalComponent() {
  const classes = reversePaymentStyle();
  const [StoreExceptionEnable, setStoreExceptionEnable] = React.useState(false)
  const { StoreExceptionPayload, setStoreExceptionPayload } = React.useContext(CustomerdataContext)


  let stopFlag = false;
  const { customerId } = useParams<ParamType>();
  const {
    cardReversal,
    pendingReversal,
    cardTransactionFailed,
    reversePayData,
    failedCCAttempt,
    continueController,
    CCAmount,
    loadingModal,
    disableContinueButton,
    transferCOAModel,
    selectedMasterCheckBox,
    gridCardDetails,
    exceptionModal,
    reverseReasonValue,
    selectedAgreements,
    isClubReversible,
    cardReversalDetails,
    employeeId,
    amountToDisplay,
    customerInformation,
    // actionTBTOpen,
    openTerminalPopup,
    availableSwipeDevices,
    selectedTerminal,
    selectedTransactionType,
    choosenSwipeData,
    otherAmountToBeAdded,
    otherAmountToBeSub,
    otherSusPayAmt,
    isCloudReverseCCPayment,
    isCloudReverseSwipePayment,
    registerNumber,
    selectedInventories
  } = useContext(GeneralStateContext);
  const {
    setCardReversal,
    setPendingReversal,
    setCardTransactionFailed,
    setFailedCCAttempt,
    setContinueController,
    setCCAmount,
    setLoadingModal,
    setTransferCOAModel,
    setReverseReasonValue,
    setExceptionModal,
    setOpenViewReceipt,
    setReverseReceiptURL,
    setCardReversalDetails,
    setAmountToDisplay,
    setIsPostReversalFailed,
    //setActionTBTOpen,
    setOpenTerminalPopup,
    setSelectedTerminal,
    // setSelectedTransactionType,
    setChoosenSwipeData,
    setRegisterNumber,
    setFailedReceiptFetching,
    setReversedReceiptID
  } = useContext(GeneralStateDispatchContext);

  useEffect(() => {
    console.log("reversePayData logger in cc", gridCardDetails);
    if (gridCardDetails) {
      console.log("count logger gridCardDetails", gridCardDetails);

      setFailedCCAttempt(new Array(gridCardDetails.length).fill(0));
      setContinueController(new Array(gridCardDetails.length).fill(0));
    }
    setRegisterNumber(String(window.sessionStorage.getItem("registerNumber")))
  }, [gridCardDetails]); // This method will be calculating the number of failed and success attempts in the card reversal.

  useEffect(() => {
    const filteredData = availableSwipeDevices?.filter((el) => String(el.termNumber)?.slice(-3) === selectedTerminal);
    if (filteredData && filteredData.length) {
      setRegisterNumber(filteredData[0].registerNumber)
    } else if (String(window.sessionStorage.getItem("registerNumber"))) {
      setRegisterNumber(String(window.sessionStorage.getItem("registerNumber")))
    } else {
      setRegisterNumber(undefined);
    }
  }, [selectedTerminal])

  useEffect(() => {
    console.log("amountToDisplay", amountToDisplay);
    // const allowCalculate = amountToDisplay.every((el) => el === 0);
    // if (allowCalculate) {
    const calculatedAmtToDisplay: any = [];
    let amountToReverse = CCAmount.totalCardPayEligible;
    gridCardDetails.map((obj) => {
      if (
        Number(amountToReverse) -
        (Number(obj.cardAmount) - Number(obj.cardAmountReversed)) >
        0
      ) {
        calculatedAmtToDisplay.push(
          Number(obj.cardAmount) - Number(obj.cardAmountReversed)
        );
        amountToReverse =
          Number(amountToReverse) -
          (Number(obj.cardAmount) - Number(obj.cardAmountReversed));
      } else {
        calculatedAmtToDisplay.push(Number(amountToReverse));
        amountToReverse = 0;
      }
    });
    setAmountToDisplay(calculatedAmtToDisplay);
    // }
  }, [gridCardDetails]); // This method will be calculating the amount to display in the grid of card reversal.

  const invokeCCReversal = async (index, cardData) => {
    const amountToReverse = CCAmount.totalCardPayEligible;
    let refundAmt = 0;
    if (
      Number(amountToReverse) -
      (Number(cardData.cardAmount) - Number(cardData.cardAmountReversed)) >
      0
    ) {
      refundAmt =
        Number(cardData.cardAmount) - Number(cardData.cardAmountReversed);
    } else {
      refundAmt = Number(amountToReverse);
    }

    const CCData = {
      OriginalExternalTransactionID: cardData.externalTransactionId,
      ExternalTransactionID: cardData.externalTransactionId,
      CustomerID: customerId,
      OriginalAmount: cardData.cardAmount,
      RefundAmount: refundAmt.toFixed(2),
      UserID: employeeId,
      CloudReverseCCPayment: isCloudReverseCCPayment
    };
    setCardReversal(false);
    setLoadingModal({ ...loadingModal, reversePay: true });
    const postReversal = await ReverseCCPayment({
      swipeReversalData: null,
      normalReversalData: CCData,
    });
    setLoadingModal({ ...loadingModal, reversePay: false });
    console.log("postReversal", postReversal);
    if (postReversal.status === 200) {
      console.log("continueController value", continueController);

      const iniVal: any = [...[], ...continueController];
      iniVal[index] = 1;
      console.log("iniVal iniVal", iniVal);

      const updatedCardReversalDetails: any = [...[], ...cardReversalDetails];
      let externalTransactionId = null;
      let cardToken = null;
      if (isCloudReverseCCPayment == 0) {
        externalTransactionId = postReversal?.data?.ExternalTransactionID ? postReversal.data.ExternalTransactionID : null;
        // cardToken = 
      } else {
        externalTransactionId = postReversal?.data?.extTransactionId ? postReversal.data.extTransactionId : null;
        cardToken = postReversal?.data?.tokenInfo?.paymentToken ? postReversal.data.tokenInfo.paymentToken : null;
      }
      updatedCardReversalDetails.push({
        cardAuthorizationId: cardData.cardAuthorizationId.toString(),
        cardAmount: refundAmt.toFixed(2),
        externalTransactionId: externalTransactionId,
        cardToken: cardToken
      });
      console.log("updatedCardReversalDetails", updatedCardReversalDetails);

      const initialAmt2Display: any = [...[], ...amountToDisplay];
      initialAmt2Display[index] = 0;
      setAmountToDisplay(initialAmt2Display);

      setCardReversalDetails(updatedCardReversalDetails);
      setContinueController(iniVal);

      setCCAmount({
        ...CCAmount,
        totalCardPayAmtReversed: (
          Number(CCAmount.totalCardPayAmtReversed) + Number(refundAmt)
        ).toFixed(2),
        totalCardPayEligible: (
          Number(CCAmount.totalCardPayEligible) - Number(refundAmt)
        ).toFixed(2),
      });
      setCardReversal(true);
    } else if (postReversal.status === 400 || postReversal.status === 500) {
      setCardTransactionFailed(true);
      console.log("failedCCAttempt", failedCCAttempt);

      const iniVal: any = [...[], ...failedCCAttempt];
      iniVal[index] = iniVal[index] + 1;
      setFailedCCAttempt(iniVal);

      if (iniVal[index] === 3) {
        const copyContController: any = [...[], ...continueController];
        copyContController[index] = 1;
        console.log("copyContController copyContControol", iniVal);
        setContinueController(copyContController);

        const initialAmt2Display: any = [...[], ...amountToDisplay];
        initialAmt2Display[index] = 0;
        setAmountToDisplay(initialAmt2Display);

        setCardTransactionFailed(false);
        setTransferCOAModel(true);
        setCCAmount({
          ...CCAmount,
          totalCardPayEligible: (
            Number(CCAmount.totalCardPayEligible) - Number(refundAmt)
          ).toFixed(2),
          totalRefundCOA: (
            Number(CCAmount.totalRefundCOA) + Number(refundAmt)
          ).toFixed(2),
        });
      }
    }
  }; // SQ-33 This method will be initiating the reversal of the card payment.

  const invokeSwipeReversal = async (index, cardData) => {
    const amountToReverse = CCAmount.totalCardPayEligible;
    let refundAmt = 0;
    if (
      Number(amountToReverse) -
      (Number(cardData.cardAmount) - Number(cardData.cardAmountReversed)) >
      0
    ) {
      refundAmt =
        Number(cardData.cardAmount) - Number(cardData.cardAmountReversed);
    } else {
      refundAmt = Number(amountToReverse);
    }
    const currentDate = new Date().toJSON().replace("T", "-").replace("Z", "");
    const filterPostalCode = customerInformation.addresses.filter(
      (x) => x.addressTypeDesc == "Primary Address"
    );
    const PaymentInfoObj = {
      TerminalID: selectedTerminal,
      Amount: refundAmt.toFixed(2),
      PaymentDate:
        currentDate.split(".")[0] + ":000" + currentDate.split(".")[1],
      PostalCode: filterPostalCode[0].postalCode.replace("-", ""),
      PaymentType: "2",
      TransactionType: selectedTransactionType,
      RegisterNumber: registerNumber
    };

    const SwipeData = {
      PaymentInfo: PaymentInfoObj,
      storeNumber: window.sessionStorage.getItem("storeNumber"),
    };
    const SwipeCardRequestDTO = {
      PaymentInfo: SwipeData.PaymentInfo,
      storeNumber: window.sessionStorage.getItem("storeNumber"),
      clientId: "RACPAD",
      userId: String(employeeId),
      customerId: Number(customerId),
      name: customerInformation?.firstName + " " + customerInformation?.lastName,
      CloudSwipeCustomerCard: Number(isCloudReverseSwipePayment) == 1 ? 'Y' : 'N',
      swipeReversal: "Y"
    };
    setCardReversal(false);
    setLoadingModal({ ...loadingModal, reversePay: true });
    try {
      // const postReversal = await ReverseCCPayment({
      //   swipeReversalData: SwipeData,
      //   normalReversalData: null,
      // });
      const postReversal = await Swipecustomercard(SwipeCardRequestDTO);
      setLoadingModal({ ...loadingModal, reversePay: false });
      console.log("postReversal", postReversal);
      if (postReversal?.status == 200) {
        console.log("continueController value", continueController);

        const iniVal: any = [...[], ...continueController];
        iniVal[index] = 1;
        console.log("iniVal iniVal", iniVal);

        const updatedCardReversalDetails: any = [...[], ...cardReversalDetails];
        const externalTransactionId = postReversal?.data?.cardRefNumber ? postReversal.data.cardRefNumber : null;
        const cardToken = postReversal?.data?.cardToken ? postReversal.data.cardToken : null;
        updatedCardReversalDetails.push({
          cardAuthorizationId: cardData.cardAuthorizationId.toString(),
          cardAmount: refundAmt.toFixed(2),
          externalTransactionId: externalTransactionId,
          cardToken: cardToken
        });
        console.log("updatedCardReversalDetails", updatedCardReversalDetails);

        const initialAmt2Display: any = [...[], ...amountToDisplay];
        initialAmt2Display[index] = 0;
        setAmountToDisplay(initialAmt2Display);

        setCardReversalDetails(updatedCardReversalDetails);
        setContinueController(iniVal);

        setCCAmount({
          ...CCAmount,
          totalCardPayAmtReversed: (
            Number(CCAmount.totalCardPayAmtReversed) + Number(refundAmt)
          ).toFixed(2),
          totalCardPayEligible: (
            Number(CCAmount.totalCardPayEligible) - Number(refundAmt)
          ).toFixed(2),
        });
        setCardReversal(true);
      } else if (postReversal?.status != 200 || postReversal == undefined) {
        setCardTransactionFailed(true);
        console.log("failedCCAttempt", failedCCAttempt);

        const iniVal: any = [...[], ...failedCCAttempt];
        iniVal[index] = iniVal[index] + 1;
        setFailedCCAttempt(iniVal);

        if (iniVal[index] === 3) {
          const copyContController: any = [...[], ...continueController];
          copyContController[index] = 1;
          console.log("copyContController copyContControl", iniVal);
          setContinueController(copyContController);

          const initialAmt2Display: any = [...[], ...amountToDisplay];
          initialAmt2Display[index] = 0;
          setAmountToDisplay(initialAmt2Display);

          setCardTransactionFailed(false);
          setTransferCOAModel(true);
          setCCAmount({
            ...CCAmount,
            totalCardPayEligible: (
              Number(CCAmount.totalCardPayEligible) - Number(refundAmt)
            ).toFixed(2),
            totalRefundCOA: (
              Number(CCAmount.totalRefundCOA) + Number(refundAmt)
            ).toFixed(2),
          });
        }
      }
    } catch {
      setCardTransactionFailed(true);
      console.log("failedCCAttempt", failedCCAttempt);

      const iniVal: any = [...[], ...failedCCAttempt];
      iniVal[index] = iniVal[index] + 1;
      setFailedCCAttempt(iniVal);

      if (iniVal[index] === 3) {
        const copyContController: any = [...[], ...continueController];
        copyContController[index] = 1;
        console.log("copyContController copyContControl", iniVal);
        setContinueController(copyContController);

        const initialAmt2Display: any = [...[], ...amountToDisplay];
        initialAmt2Display[index] = 0;
        setAmountToDisplay(initialAmt2Display);

        setCardTransactionFailed(false);
        setTransferCOAModel(true);
        setCCAmount({
          ...CCAmount,
          totalCardPayEligible: (
            Number(CCAmount.totalCardPayEligible) - Number(refundAmt)
          ).toFixed(2),
          totalRefundCOA: (
            Number(CCAmount.totalRefundCOA) + Number(refundAmt)
          ).toFixed(2),
        });
      }
    }
  };

  const apiCall = async (postReversalData) => {
    const receiptResponse = await getDocument(
      postReversalData.data.reversedReceiptId,
      "RCP"
    );
    if (
      receiptResponse.status == 200 &&
      Object.keys(receiptResponse.data).length > 0 &&
      receiptResponse.data.fileList[0].fileObject !== null
    ) {
      const Receiptrecord = receiptResponse.data.fileList.filter(
        (x) => x.docType === "PRCT"
      );
      if (Receiptrecord.length) {
        const type = getContentType(Receiptrecord[0]?.fileName);
        setReverseReceiptURL(
          URL.createObjectURL(b64toBlob(Receiptrecord[0]?.fileObject, type))
        );
      }
      console.log("5tyt", Receiptrecord);
      stopFlag = true;
      setOpenViewReceipt(true);
    }
  };

  const invokeGenerateReceipt = async (postReversalData) => {
    setReversedReceiptID(postReversalData.data.reversedReceiptId);
    let receiptTimeOut = true;
    setTimeout(async function () {
      receiptTimeOut = false;
    }, 25000);
    // const paymentReceiptRequest = {
    //   receiptId: Number(postReversalData.data.reversedReceiptId),
    //   resultText: "",
    //   cellPhoneActivation: [],
    // };
    setLoadingModal({ ...loadingModal, document: true });
    while (!stopFlag && receiptTimeOut) {
      await apiCall(postReversalData);
    }
    // const receiptResponse = await ReceiptGeneration(paymentReceiptRequest);
    setLoadingModal({ ...loadingModal, document: false });
    // if (receiptResponse.status === 200) {
    //   setReverseReceiptURL(
    //     URL.createObjectURL(
    //       b64toBlob(receiptResponse.data.receiptHtmlUrl, "text/html")
    //     )
    //   );
    //   console.log("Data Obtained", receiptResponse);
    //   setOpenViewReceipt(true);
    // } else if (receiptResponse.status === 500) {
    //   setExceptionModal({ ...exceptionModal, serverError: true });
    // } else if (receiptResponse.status === 400) {
    //   setExceptionModal({ ...exceptionModal, badRequest: true });
    // }
    if (!stopFlag) {
      setFailedReceiptFetching(true);
      // setExceptionModal({ ...exceptionModal, badRequest: true });
    }
  }; // This method will be fetching the current reversal receipt.

  const acceptCardReversal = async () => {
    setCardReversal(false);
    setStoreExceptionEnable(true)

  }; // SQ-34 This method will be concluding the card reversal by invoking reverse payment API.

  const renderHeader = () => (
    <>
      {CardHeaderData.map((col: string, index: number) => {
        return (
          <RACTableCell
            key={index}
            className={col == "Action" ? `${classes.textCenter}` : ""}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  ); // This method will be rendering the header data of the card reversal popup.

  const renderContent = (): any => {
    return gridCardDetails.map((obj, index) => {
      return (
        <React.Fragment key={index}>
          <RACTableRow key={index}>
            <RACTableCell className={`${classes.gridLabel}`}>
              {" "}
              {obj.cardLastFour}{" "}
            </RACTableCell>
            <RACTableCell className={`${classes.gridLabel}`}>
              {" "}
              {obj.cardAuthNumber}{" "}
            </RACTableCell>
            <RACTableCell className={`${classes.gridLabel}`}>
              {" "}
              ${" "}
              {selectedMasterCheckBox.length
                ? (
                  Number(obj.cardAmount) - Number(obj.cardAmountReversed)
                ).toFixed(2)
                : amountToDisplay[index].toFixed(2)}{" "}
            </RACTableCell>
            <RACTableCell className={`${classes.textCenter}`}>
              <RACButton
                variant="contained"
                color="primary"
                className={`${classes.rcptButton}`}
                onClick={() => {
                  if (obj.cardTransactionType == "SWP") {
                    setCardReversal(false);
                    setOpenTerminalPopup(true);
                    //setActionTBTOpen(true);
                    setChoosenSwipeData({
                      swipeIndex: index,
                      swipeData: obj,
                    });
                  } else {
                    invokeCCReversal(index, obj);
                  }
                }}
                disabled={
                  failedCCAttempt[index] === 3 ||
                    continueController[index] === 1
                    ? true
                    : false
                }
              >
                Reverse
              </RACButton>
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      );
    });
  }; // This method will be rendering the content data of the card reversal popup.

  const closeCardReversal = () => {
    setCardReversal(false);
    setReverseReasonValue(ReverseReasonObject);
    setCardReversalDetails([]);
    if (continueController.some((el) => el === 1)) {
      setPendingReversal(true);
    }
  }; // This method is invoked when cancel button is clicked in the card reversal component.

  const cardReversalRenderer = () => {
    return (
      <Grid>
        <Card className={`${classes.racSkyBlue} ${classes.bluecards} `}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayLabel}>
                  Last Payment Received:
                </Typography>
              </Grid>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayLabel}>
                  Total Cash Payment Eligible for Refund:
                </Typography>
              </Grid>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayLabel}>
                  Total Card Refund Moved to COA:
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayAmtLabel}>
                  $ {(Number(CCAmount.lastPaymentReceived) + Number(otherAmountToBeAdded) - (Number(otherAmountToBeSub) + Number(otherSusPayAmt))).toFixed(2)}
                </Typography>
              </Grid>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayAmtLabel}>
                  $ {CCAmount.totalCashPayEligible}
                </Typography>
              </Grid>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayAmtLabel}>
                  $ {CCAmount.totalRefundCOA}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayLabel}>
                  Total Card Payment Eligible for Reversal:
                </Typography>
              </Grid>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayLabel}>
                  Total Card Payment Amount Reversed:
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayAmtLabel}>
                  $ {CCAmount.totalCardPayEligible}
                </Typography>
              </Grid>
              <Grid md={12} className={classes.margincard}>
                <Typography className={classes.cardPayAmtLabel}>
                  $ {CCAmount.totalCardPayAmtReversed}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Typography className={classes.cctHeading}>
          Current Card Transaction
        </Typography>
        <RACTable
          renderTableHead={renderHeader}
          renderTableContent={renderContent}
          className={classes.agrementGridMC}
        />
        <Grid
          className={`${classes.floatRight} ${classes.w100} ${classes.pt3}`}
        >
          <RACButton
            className={`${classes.floatRight} ${classes.ms2}`}
            color="primary"
            variant="contained"
            disabled={disableContinueButton}
            onClick={acceptCardReversal}
          >
            Continue
          </RACButton>
          <RACButton
            className={`${classes.floatRight} ${classes.ms2} ${classes.cardPayCancelBtn}`}
            color="primary"
            variant="outlined"
            onClick={closeCardReversal}
          >
            Cancel
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // SQ-30 This method will be rendering the card reversal popup for reversing.

  const pendingReversalRenderer = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.bold}`}>
            You have initiated a reversal transaction. All credit card reversal
            transaction must be completed.
          </Typography>
          <Typography className={`${classes.bold}`}>
            Please continue!
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setPendingReversal(false);
              setCardReversal(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // SQ-31 This method will be rendering the warning message when tring to close the reversal popup after initianing a reversal.

  const transactionFailRenderer = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.bold}`}>
            The reverse payment transaction has failed.
          </Typography>
          <Typography className={`${classes.bold}`}>
            Please try again!
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setCardTransactionFailed(false);
              setCardReversal(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // Sq-32 This method will be rendering the exception message when the transaction got failed.

  const transferCOARenderer = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.bold}`}>
            Multiple unsuccessful reversal attempts.
          </Typography>
          <Typography className={`${classes.bold}`}>
            Fund will be moved to Credit on Account.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {
              setTransferCOAModel(false);
              setCardReversal(true);
            }}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // This method will be rendering the confirmation popup while trransfering the amount to COA.

  // const actionTBTRenderer = () => {
  //   return (
  //     <Grid>
  //       <Grid className={`${classes.textCenter}`}>
  //         <AlertIcon />
  //         <Typography className={`${classes.bold}`}>
  //           Select Action To Be Taken At Device.
  //         </Typography>
  //       </Grid>
  //       <Grid className={`${classes.py4} ${classes.textCenter}`}>
  //         <RACButton
  //           className={classes.mx1}
  //           variant="contained"
  //           color="primary"
  //           onClick={() => {
  //             setActionTBTOpen(false);
  //             setOpenTerminalPopup(true);
  //             setSelectedTransactionType("2");
  //           }}
  //         >
  //           Swipe Card
  //         </RACButton>
  //         <RACButton
  //           className={classes.mx1}
  //           variant="contained"
  //           color="primary"
  //           onClick={() => {
  //             setActionTBTOpen(false);
  //             setOpenTerminalPopup(true);
  //             setSelectedTransactionType("1");
  //           }}
  //         >
  //           Card Not Present
  //         </RACButton>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  const bindTerminalList = () => {
    return availableSwipeDevices?.map((values: any, index: any) => {
      console.log("index in dd bind", index);
      if (index === 0 && values.description == "No reasons") {
        return { label: "No Records Found", value: values.referenceCode };
      } else if (index === 0) {
        return { label: "Select", value: values.referenceCode };
      } else {
        // const storeNumber = String(Number(values.storeNumber));
        const terminalId = String(values.termNumber)?.slice(-3)
        return { label: values.name, value: terminalId };
      }
    });
  };

  const renderTerminalPopup = () => {
    const amountToReverse = CCAmount.totalCardPayEligible;
    let refundAmt = 0;
    if (
      Number(amountToReverse) -
      (Number(choosenSwipeData?.swipeData.cardAmount) -
        Number(choosenSwipeData?.swipeData.cardAmountReversed)) >
      0
    ) {
      refundAmt =
        Number(choosenSwipeData?.swipeData.cardAmount) -
        Number(choosenSwipeData?.swipeData.cardAmountReversed);
    } else {
      refundAmt = Number(amountToReverse);
    }
    return (
      <Grid item>
        <Grid container justifyContent={"center"}>
          <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
            <Typography
              variant="caption"
              className={`${classes.RAClabeltxtstyle} ${classes.semibold}`}
            >
              Amount to be reversed:{" "}
            </Typography>
            <Typography
              variant="caption"
              className={`${classes.RACvaluetxtstyle} ${classes.bold}`}
            >
              ${refundAmt.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item md={12} className={`${classes.mb3} ${classes.ps2}`}>
            <Grid
              item
              md={12}
              className={`${classes.formLabel} ${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
            >
              Computer Name
            </Grid>
            <Grid
              item
              md={12}
              className={`${classes.mb3} ${classes.w100} ${classes.floatLeft}`}
            >
              <RACSelect
                options={bindTerminalList()}
                isDisabled={availableSwipeDevices?.length === 1 ? true : false}
                defaultValue={
                  selectedTerminal !== "" ? selectedTerminal : "SELECT"
                }
                onChange={(e) => {
                  setSelectedTerminal(
                    e.target.value == "SELECT" ? "" : e.target.value
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          md={12}
          className={`${classes.justifyContentRight} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenTerminalPopup(false);
              setCardReversal(true);
              //setActionTBTOpen(true);
              setSelectedTerminal("");
            }}
          >
            Cancel
          </RACButton>

          <RACButton
            color="primary"
            variant="contained"
            disabled={selectedTerminal == "" ? true : false}
            onClick={() => {
              setOpenTerminalPopup(false);
              invokeSwipeReversal(
                choosenSwipeData.swipeIndex,
                choosenSwipeData.swipeData
              );
            }}
          >
            Next
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const storeExceptionCompleted = async (updatedPayload?) => {
    debugger
    setStoreExceptionEnable(false)
    const StoreExceptionPayload = updatedPayload
    let cardAmountReversed = 0;
    if (cardReversalDetails.length) {
      cardReversalDetails.map((obj) => {
        cardAmountReversed =
          Number(cardAmountReversed) + Number(obj.cardAmount);
      });
    }
    const isConvenienceFeeUsed = reversePayData[0].otherPaymentAmount.filter((el) => el.accountType == 'CONVENFEETAX' || el.accountType == 'CONVENFEE');
    const postCardReversalData = {
      storeNumber: String(window.sessionStorage.getItem("storeNumber")),
      customerId: customerId,
      reasonType: reverseReasonValue.reverseReason,
      reasonText: reverseReasonValue.reasonInformation,
      receiptInfo: [
        {
          receiptId:
            reversePayData !== undefined ? reversePayData[0].receiptId : "",
          agreementId: selectedAgreements.length ? selectedAgreements : null,
          inventoryId: selectedInventories.length ? selectedInventories : undefined,
          isClubReversible: isClubReversible,
          reversedAmount: isConvenienceFeeUsed.length > 0 ? undefined : (
            Number(CCAmount.totalCashPayEligible) +
            Number(cardAmountReversed) +
            Number(CCAmount.totalRefundCOA)
          ).toFixed(2),
          reverseToCoaAmount: CCAmount.totalRefundCOA,
          cardReversalDetails: cardReversalDetails,
        },
      ],
    };
    console.log("postCardReversalData", postCardReversalData);

    setLoadingModal({ ...loadingModal, reversePay: true });
    const postReversal = await postReversePayment(postCardReversalData);
    setLoadingModal({ ...loadingModal, reversePay: false });
    if (postReversal.status === 200) {
      if (postReversal?.data && StoreExceptionPayload) {
        const payload = StoreExceptionPayload?.map((obj) => {
          return obj.identifierId = Number(postReversal?.data?.reversedReceiptId)
        })
        const Request = {
          storeException: StoreExceptionPayload
        }
        storeException(Request);
        setStoreExceptionPayload([])

      }
      invokeGenerateReceipt(postReversal);
    } else if (postReversal.status === 400) {
      // setExceptionModal({ ...exceptionModal, badRequest: true });
      setIsPostReversalFailed(true);
    } else if (postReversal.status === 500) {
      setExceptionModal({ ...exceptionModal, serverError: true });
    }

  }
  const storeExceptionCancelled = async () => {
    debugger
    setStoreExceptionEnable(false);
    setCardReversal(false);
    setReverseReasonValue(ReverseReasonObject);
    setCardReversalDetails([]);
    if (continueController.some((el) => el === 1)) {
      setPendingReversal(true);
    }
  }
  return (
    <>
      {StoreExceptionEnable ? (<StoreException
        refCode={'RFN'}
        identifierType={'RECEIPT'}
        storeExceptionCancelled={storeExceptionCancelled}
        storeExceptionCompleted={storeExceptionCompleted}
      />) : null}
      <RACModalCard
        isOpen={cardReversal}
        maxWidth="lg"
        borderRadius={ModalRadius}
        closeIcon={true}
        title="Reverse Payment"
        onClose={closeCardReversal}
        //eslint-disable-next-line react/no-children-prop
        children={cardReversalRenderer()}
      />
      <RACModalCard
        isOpen={pendingReversal}
        maxWidth="xs"
        borderRadius={ModalRadius}
        //eslint-disable-next-line react/no-children-prop
        children={pendingReversalRenderer()}
      />
      {/* <RACModalCard
        isOpen={actionTBTOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setActionTBTOpen(false);
          setCardReversal(true);
        }}
        borderRadius={ModalRadius}
        //eslint-disable-next-line react/no-children-prop
        children={actionTBTRenderer()}
      /> */}
      <RACModalCard
        isOpen={cardTransactionFailed}
        maxWidth="xs"
        borderRadius={ModalRadius}
        //eslint-disable-next-line react/no-children-prop
        children={transactionFailRenderer()}
      />
      <RACModalCard
        isOpen={transferCOAModel}
        maxWidth="xs"
        borderRadius={ModalRadius}
        //eslint-disable-next-line react/no-children-prop
        children={transferCOARenderer()}
      />
      <RACModalCard
        isOpen={openTerminalPopup}
        maxWidth="xs"
        title="Swipe Card"
        borderRadius={ModalRadius}
        //onClose={() => setCardrequire({ ...Cardrequire, swipcard: false })}
        // eslint-disable-next-line react/no-children-prop
        children={renderTerminalPopup()}
      />
    </>
  );
}

/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */
export const Paymentgrid=()=>
{
return(
<div>
{/*Footer starts here*/}
<div className="row fixed-bottom-spacer">
  <div className="fixed-bottom p-3 bg-white shadow">
    <div className="float-start">
      <a href="index.html" className="btn btn-secondary">Cancel</a>
    </div>
    <div className="float-end">
      <a href="/PaymentInformation" className="btn btn-primary mx-2" data-testid="btn-increment">Take Payment</a>
    </div>
  </div>
</div>
{/*Footer ends here*/}
</div>
);
}
export default Paymentgrid;

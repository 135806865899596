import { TaxResponseInterface } from '../interface/tenderTypeInterface';

const CREDIT_CARD_CHARGE_BACK = 'Credit Card Charge Back';
const NON_INVENTORY_SALE = 'Non Inventory Sale';
const XBOX_GIFT_MEMBERSHIP = 'Xbox Gift Membership';
const XBOX_GIFT_CARD = 'Xbox Gift Card';
const NOTE_PAYMENT = 'Note Payment';
const AGREEMENT_HR = 'Agreement #';
const CONSTANTS = {
  /**
   * For Array constants
   */
  AGREEMENT_STATUS_ACTIVE_PEND: ['ACTIVE', 'PEND'],
  PRELIM_PRELIM_INV_ARRAY: ['PRELIM', 'PRELIMINV'],
  LTLDW: 'LTLDW',
  LTLDW_LDW_ARRAY: ['LTLDW', 'LDW'],
  RTRPAY_ARRAY: ['RTRPAY'],
  RACTIREPAY: 'RACTIREPAY',
  RACTIREPAY_ARRAY: ['RACTIREPAY'],
  NSFCHK_CCCB_ARRAY: ['NSFCHK', 'CCCB'],
  AGREEMENT_TYPE_CLUB_NOTE_ARRAY: ['Club', 'Note Payment'],
  CCCB_NSF_AGREEMENT_ARRAY: ['Credit Card Charge Back', 'NSF Check'],
  NO_REDIRECTION_AGR_TYPE_ARRAY: [
    'Club',
    'NSF Check',
    CREDIT_CARD_CHARGE_BACK,
    'Restitution',
  ],
  NOT_ALLOWED_AGREEMENT_TYPE: [
    'EPO Rent',
    'PIF Rent',
    'PIF Note',
    NOTE_PAYMENT,
    'Club',
    'Promo',
  ],
  NOT_ALLOWED_AGREEMENT_TYPE_FOR_NOTE: [
    'EPO Rent',
    'PIF Rent',
    'Club',
    'Promo',
  ],
  NOT_ALLOWED_AGREEMENT_TYPE_FOR_ES: [
    'EPO Rent',
    'PIF Rent',
    'PIF Note',
    'Promo',
  ],
  AGREEMENT_TYPE_ARRAY: [
    'Club',
    'NSF Check',
    CREDIT_CARD_CHARGE_BACK,
    'Restitution',
    'Back Rent',
    'Promo',
    XBOX_GIFT_CARD,
    XBOX_GIFT_MEMBERSHIP,
    NON_INVENTORY_SALE,
    'Rental Item Sale',
  ],
  NON_INVENTORY_AGREEMENT_TYPE_ARRAY: [
    XBOX_GIFT_CARD,
    XBOX_GIFT_MEMBERSHIP,
    NON_INVENTORY_SALE,
  ],
  BASIC_AGREEMENT_TYPE_ARRAY: [
    'Club',
    NOTE_PAYMENT,
    'PIF Note',
    CREDIT_CARD_CHARGE_BACK,
    'NSF Check',
  ],
  CCCB_NSF_ARRAY: [CREDIT_CARD_CHARGE_BACK, 'NSF Check'],
  AGREEMENT_ARRAY: [NOTE_PAYMENT, 'Promo', 'PIF Note'],
  ZERO_PAYMENT_CHANGE_TYPE_AS_RENT_ARRAY: [
    'EPO Note',
    'PIF Note',
    'SAC',
    'PIF',
    'EPO',
    'PIF Rent',
    'EPO Rent',
    'SAC Rent',
  ],
  ZERO_AND_ONE_ARRAY: [0, 1],
  NEXT_DUE_DATE_TYPE_ARRAY: ['SAC', 'PIF', 'EPO'],
  PIF_SAC_EPO_RENT_ARRAY: ['PIF Rent', 'EPO Rent', 'SAC Rent'],
  NOTE_AGR_NEXT_DUE_DATE_ARRAY: [
    'INPIF',
    'INSAC',
    'INEPO',
    'INAGMSAC',
    'INAGMEPO',
  ],
  SERVFREETM_RETEXTAMT_ARRAY: ['SERVFREETM', 'RETEXTAMT'],
  FREAMT_SERVFREETM_ARRAY: ['FREAMT', 'SERVFREETM'],
  BACK_RENT_NSF_CHK_RESTITUTION_ARRAY: [
    'Restitution',
    'Back Rent',
    'NSF Check',
  ],
  NOTE_AGREEMENT_TYPE: [NOTE_PAYMENT, 'EPO Note', 'PIF Note'],
  EPO_NOTE: 'EPO Note',
  COMMITMENT_TYPE_ARRAY: [
    'DDA',
    'LFC',
    'LFW',
    'DC',
    'TOS',
    'AOS',
    'ES',
    'AS',
    'US',
    'TAS',
    'TUS',
  ],
  CREDIT_CARD_ERROR_REPONSE: ['871', '872', '873', '874', '876'],
  CVV_ERROR_RESPONSE: ['881', '882', 883],
  LFC_LFW_TOS_AOS_ARRAY: ['LFC', 'LFW', 'TOS', 'AOS'],
  FTT_DDA_ARRAY: ['FTT', 'DDA'],
  AS_US_TAS_TUS_ARRAY: ['AS', 'US', 'TAS', 'TUS'],
  STORE_EXCEPTION_TYPE: [
    { abbreviation: 'AGDC', description: 'Agreement declined' },
    {
      abbreviation: 'APSO',
      description: 'Agreement Not Digitally Signed By Customer',
    },
    {
      abbreviation: 'BNPRSNT',
      description:
        'Agreements are being transferred without both parties being present.',
    },
    { abbreviation: 'APP', description: 'Approval Performed By Same Person' },
    { abbreviation: 'EODOS', description: 'Auto EOD Final Drop Over or Short' },
    { abbreviation: 'BOS', description: 'Bank Over Short' },
    { abbreviation: 'CPO', description: 'Cash Price Override' },
    { abbreviation: 'COP', description: 'Cash Sale Outside BusParam' },
    { abbreviation: 'CPA', description: 'Changed Price On Agreement' },
    { abbreviation: 'CRT', description: 'Changed Rate or Term on Agreement' },
    { abbreviation: 'CB', description: 'Chargeback Activity' },
    { abbreviation: 'CLM', description: 'Claim Extensions' },
    {
      abbreviation: 'CPOA',
      description: 'Competitor Price Override On Agreement.',
    },
    { abbreviation: 'CFWV', description: 'Convenience Fee Waived' },
    { abbreviation: 'DEB', description: 'Decision Engine Bypassed' },
    { abbreviation: 'DERLIM', description: 'DE Request Limit Reached' },
    { abbreviation: 'EPD', description: 'EPO Payout Discounted' },
    { abbreviation: 'FDOS', description: 'Final Drop Over Short' },
    { abbreviation: 'FDG', description: 'Free Days Given' },
    {
      abbreviation: 'POC',
      description: 'Home Office PO Receiving or Charge Off',
    },
    { abbreviation: 'IDC', description: 'Inventory Depreciation Cap' },
    {
      abbreviation: 'INS4A',
      description: 'Item Not Scanned In 4 Consecutive Audits',
    },
    { abbreviation: 'LPT', description: 'Large Payment' },
    { abbreviation: 'LGL7', description: 'Legal agreement due within 7 days' },
    {
      abbreviation: 'LGL90',
      description: 'Legal agreement was extended beyond 90 days',
    },
    { abbreviation: 'LNR30', description: 'Loaners – 30 days on rent' },
    { abbreviation: 'LNR', description: 'Loaners with no item in service' },
    { abbreviation: 'MIA', description: 'Missing Item On Inventory Audit' },
    { abbreviation: 'NER', description: 'Negative Receipt' },
    {
      abbreviation: 'NMRB',
      description: 'No manual receipt books are assigned to store',
    },
    { abbreviation: 'NSF', description: 'NSF Check Activity' },
    {
      abbreviation: 'AEET',
      description: 'Number of Audit Exceptions Exceeds Threshold',
    },
    { abbreviation: 'OSR', description: 'Over Short Drop Reconciliation' },
    { abbreviation: 'PDO', description: 'Paidout' },
    { abbreviation: 'PFEEW', description: 'Processing Fee Waived' },
    { abbreviation: 'PROMODIS', description: 'Promo Discount Used' },
    { abbreviation: 'RAO', description: 'Rate Of Agreement Override' },
    { abbreviation: 'RFN', description: 'Refund' },
    { abbreviation: 'RFO', description: 'Reinstatement Fee Override' },
    { abbreviation: 'REX', description: 'Rental Extension/Free Time' },
    { abbreviation: 'RAR', description: 'Returned and Redelivered' },
    { abbreviation: 'RVPO', description: 'Reverse PO Receipt' },
    {
      abbreviation: 'SAME4',
      description: 'Same person doing inventory in store 4 times in a row',
    },
    { abbreviation: 'SCC', description: 'Schedule Change' },
    { abbreviation: 'SCR', description: 'Scrap Item' },
    { abbreviation: 'SDO', description: 'Security Deposit Override' },
    {
      abbreviation: 'ANC',
      description:
        'Store did not complete the required inventory audit for today',
    },
    {
      abbreviation: 'ANS',
      description:
        'Store did not started the required inventory audit for yesterday',
    },
    {
      abbreviation: 'AUD',
      description: 'Store Managers must do an audit once a week',
    },
    { abbreviation: 'SWO', description: 'Switchout' },
    { abbreviation: 'TMM', description: 'Three Or More Messages' },
    {
      abbreviation: 'TRRAC',
      description: 'Transfer Reinstatement Rights Agreement Created',
    },
    { abbreviation: 'UIR', description: 'Unreserved Inventory Reason' },
    {
      abbreviation: 'UIISN',
      description: 'Update Inventory Item Serial Number',
    },
    { abbreviation: 'WLF', description: 'Waived Late Fee' },
    { abbreviation: 'WFO', description: 'Waiver Fee Override' },
  ],
  CARD_DECLINE_MESSAGE: {
    39: 'Transaction Declined. Insufficient Fund. Please try a different card',
    127: 'Transaction Declined. Exceeds Approval Amount Limit',
    327: 'Transaction Declined. Cardholder transaction not permitted',
    322: 'Transaction Declined. Invalid Transaction',
    330: 'Transaction Declined. Invalid Payment Type',
    321: 'Transaction Declined. Invalid Merchant.',
    217: 'Transaction Declined. Card Already Active.',
    822: 'Transaction Declined. Card token not found.',
    340: 'Transaction Declined. Invalid Amount.',
    11: 'Transaction Declined. Transaction not permitted.',
    110: 'Transaction Declined. Insufficient Fund. Please try a different card',
    301: 'Transaction Declined. Invalid Account Number. Please try with different card.',
    326: 'Transaction Declined exceeded number of Pin entries. Please try with different card.',
    324: 'Transaction Declined. Invalid Pin. Please try with valid Pin Number.',
    9: 'Transaction Declined. Invalid Card Number. Please try with different card.',
    4: 'Transaction Declined. Expired Card. Please try with different card.',
    13: 'Transaction Declined. Please try a different card.',
    26: 'Transaction Declined. Exceeds withdrawal limit please try a different card',
  },
  BUTTON_STATES: {
    INACTIVE: { color: 'secondary', variant: 'outlined', textColor: '#4A5174' },
    ACTIVE: { color: 'primary', variant: 'contained', textColor: '#fff' },
  },
  AMOUNT_MISMATCH: 'Amount mismatch',
  /**
   * Number constants
   */
  ZERO_NUMBER: 0,
  ZERO_TWO_DECIMAL_NUMBER: 0.0,
  ONE_NUMBER: 1,
  TWO_NUMBER: 2,
  THREE_NUMBER: 3,
  FOUR_NUMBER: 4,
  FIVE_NUMBER: 5,
  SIX_NUMBER: 6,
  SEVEN_NUMBER: 7,
  TEN_NUMBER: 10,
  TWENTY_NUMBER: 20,
  FIFTY_NUMBER: 50,
  HUNDRED_NUMBER: 100,
  TWENTY_FIVE: 25,
  THIRTY_NUMBER: 30,
  FOUR_HUNDRED_NUMBER: 400,
  ZERO_DECIMAL_NUMBER: 0.0,
  POINT_TWO_ZERO: 0.0, //Need to check this - prettier issue
  MINUS_ONE_NUMBER: -1,
  HUNDRED_THOUSAND_DECIMAL: 100000.0,

  /**
   * Number constants Negative
   */
  ONE_NEGATIVE_NUMBER: -1,
  /**
   * String constants
   */
  NUMBER_STRING: 'number',
  EMPTY_STRING: '',
  STRING_WITH_SINGLE_SPACE: ' ',
  HASHTAG: '#',
  OPEN_BRACKET_STRING: '(',
  CLOSE_BRACKET_STRING: ')',
  SLASH_STRING: '/',
  ZERO_STRING: '0',
  ONE_STRING: '1',
  TWO_STRING: '2',
  THREE_STRING: '3',
  FOUR_STRING: '4',
  FIVE_STRING: '5',
  SIX_STRING: '6',
  SEVEN_STRING: '7',
  EIGHT_STRING: '8',
  NINE_STRING: '9',
  TEN_STRING: '10',
  ELEVEN_STRING: '11',
  ONE_WITH_DECIMAL: '1.00',
  ZREO_TWO_DECIMAL_STRING: '0.00',
  FOUR_ZERO_STRING: '0000',
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  THIRTY_TWO_STRING: '32',
  THIRTY_THREE_STRING: '33',
  THIRTY_FOUR_STRING: '34',
  ONE_LETTER_STRING: 'one',
  FIVE_LETTER_STRING: 'five',
  TEN_LETTER_STRING: 'ten',
  TWENTY_LETTER_STRING: 'twenty',
  FIFTY_LETTER_STRING: 'fifty',
  HUNDRED_LETTER_STRING: 'hundred',
  URLPARAMS_CRTYPE: 'CRTYPE', //Retention type
  URLPARAMS_SRCTYPE: 'SRC', //Retention source
  URLPARAMS_TYPE: 'type',
  URLPARAMS_AGREEMENT_ID: 'agreementId',
  URLPARAMS_EXCHANGE_AGREEMENT_ID: 'exchangeAgreementId',
  STATUS_ACTIVE: 'A', //STATUS_ACTIVE
  BILLING_ADDRESS: 'B', //BILLING_ADDRESS
  POLICY_INACTIVE: 'I', //POLICY_INACTIVE
  CLUB_PENDING: 'P', //CLUB_PENDING
  DEACTIVATE_CLUB: 'D',
  RETENTION_R: 'R', //RETENTION_R
  RETENTION_S: 'S', //RETENTION_S
  ORIGIN_STORE: 'S', // Need to check name convention
  STATUS_YES: 'Y', //STATUS_YES
  STATUS_NO: 'N', //STATUS_NO
  LANG_ENGLISH: 'E', //LANG_ENGLISH
  UNKNOWN_REF: 'U',
  MASTER_CARD_REF: 'M',
  VISA_CARD_REF: 'V',
  AMERICAN_EXPRESS_REF: 'A',
  DISCOVER_REF: 'D',
  DINERS_CLUB_REF: 'DC',
  JCB_REF: 'J',
  PAY_PAL_REF: 'PP',
  CARD_REF: 'C',
  N_SLASH_A: 'N/A',
  MX_STATE: 'MX',
  BR: 'BR',
  CO: 'CO',
  OP: 'OP',
  ES: 'ES',
  AS: 'AS',
  US: 'US',
  DC: 'DC',
  DP: 'DP',
  MNL: 'MNL',
  SWP: 'SWP',
  TAS: 'TAS',
  TUS: 'TUS',
  TOS: 'TOS',
  AOS: 'AOS',
  FTT: 'FTT',
  DDA: 'DDA',
  WOS: 'WOS',
  AEF: 'AEF',
  AEA: 'AEA',
  LFC: 'LFC',
  LFW: 'LFW',
  LDW: 'LDW',
  LDW_LOWER_CASE: 'Ldw',
  SAC: 'SAC',
  EPO: 'EPO',
  PIF: 'PIF',
  INPIF: 'INPIF',
  INSAC: 'INSAC',
  INEPO: 'INEPO',
  INAGMEPO: 'INAGMEPO',
  INAGMSAC: 'INAGMSAC',
  USA: 'USA',
  AEPDA: 'AEPDA',
  RACDB: 'RACDB',
  PASTDUERENT: 'PastDueRent',
  ENTERPRISE: 'ENTERPRISE',
  CONVENFEE: 'CONVENFEE',
  LTRPAYFEE: 'LTRPAYFEE',
  LTPIFFEE: 'LTPIFFEE',
  CLFEE: 'CLFEE',
  CLFEEWAV: 'CLFEEWAV',
  CLFEEPAY: 'CLFEEPAY',
  CRPAY: 'CRPAY',
  RETCHKFEE: 'RETCHKFEE',
  PROCESSINGFEE: 'PROCESSINGFEE',
  MATTRESSFEE: 'MATTRESSFEE',
  LTRPAY_ARRAY: ['LTRPAY'],
  LTRPAYDSCOUNT: ['LTRPAYDSCOUNT'],
  BKRPAYTAX: 'BKRPAYTAX',
  BKRPAY: 'BKRPAY',
  CHK: 'CHK',
  CPS: 'cps',
  PRIM: 'PRIM',
  PRELIM: 'PRELIM',
  PRELIMINV: 'PRELIMINV',
  RPAY: 'RPAY',
  LTRPAY: 'LTRPAY',
  INITPAY: 'INITPAY',
  PREINITPAY: 'PREINITPAY',
  POA: 'POA',
  NSFCHK: 'NSFCHK',
  URLPARAMS_TYPE_CHARGE_OFF: 'CHRGOFF',
  URLPARAMS_TYPE_RETURN: 'RETURN',
  AGREXC: 'AGREXC',
  PAYMENT_SCHEDULE: 'PAYMENT_SCHEDULE',
  RESTITPAY: 'RESTITPAY',
  FREETIME: 'FREETIME',
  ACTIVE: 'ACTIVE',
  FREAMT: 'FREAMT',
  SERVFREETM: 'SERVFREETM',
  RETEXTAMT: 'RETEXTAMT',
  EXTAMT: 'EXTAMT',
  PROMO_FT: 'PROMO',
  WK: 'WK',
  BIWK: 'BIWK',
  MON: 'MON',
  SEMI: 'SEMI',
  DELIV: 'DELIV',
  MORN: 'MORN',
  EVE: 'EVE',
  AFTN: 'AFTN',
  DAYS_D: 'd', //NEED TO CHECK WITH THIS IN ADJUST DUE DATE
  AM: 'am',
  ST: 'st',
  ND: 'nd',
  RD: 'rd',
  TH: 'th',
  AGR: 'agr',
  RECP: 'recp',
  CLUB_SMALL_CASE: 'club',
  STRING: 'string', //NEED TO CHECK WITH THIS KEY IN ADJUST DUE DATE
  IS_CO_INITIAL: 'isCOInitial',
  IS_CO_PRELIM: 'isCOPrelim',
  CASH: 'cash',
  CAPITAL_CASH: 'Cash',
  CAPITAL_CHECK: 'Check',
  MONEY_ORDER_CONCAT: 'moneyOrder',
  CASH_PAYMENT: 'Cash Payment',
  CLUB: 'Club',
  CARRIED_LATE_FEES: 'Carried Late Fees',
  NSF_CHECK: 'NSF Check',
  NSF: 'NSF',
  NSFFEE: 'NSFFEE',
  CCCB: 'CCCB',
  CELL911: 'CELL911',
  CELL_PHONE: 'Cell phone',
  ACIMA_RECOVERY_SETTLEMENT: 'Acima Recovery Settlement',
  XBOX_MEMBERSHIP_TYPE: 'Xbox Membership',
  CELL_PHONE_ACTIVATION: 'Cell Phone Activation',
  ACIMARECOVERY: 'ACIMARECOVERY',
  NONINVCASH: 'NONINVCASH',
  XBGFTCRD: 'XBGFTCRD',
  XBMEMSHIP: 'XBMEMSHIP',
  CREDIT_CARD_CHARGE_BACK: CREDIT_CARD_CHARGE_BACK,
  NSF_CHECK_REPAYMENT: 'NSF Check Repayment',
  CREDIT_CARD_REPAYMENT: 'Credit Card Repayment',
  POLICY: 'Policy',
  POLICY_LOWER_CASE: 'Policy',
  SELECT: 'select',
  INVALID_DATE: 'Invalid date',
  RETENTION_EXTENSION: 'Retention Extension',
  RAC_EXCHANGE: 'RAC Exchange',
  YES: 'Yes',
  EPO_DONE: 'Epodone',
  UNSIGNED: '/unsigned/',
  EPO_SCHEDULE: '/EPOSchedule/',
  VIEW_EPO_PDF_NAME: '_EPOHistory.pdf',
  NO: 'No',
  OTHER: 'Other',
  ACTION: 'Action',
  RESTITUTION: 'Restitution',
  RENTAL_ITEM_SALE: 'Rental Item Sale',
  PROMO: 'Promo',
  BACK_RENT: 'Back Rent',
  BACK_RENT_LOWER_CASE: 'back rent',
  SECDEP: 'SECDEP',
  DONATION: 'DONATION',
  BACK_RENT_COLLECTED: 'Back Rent Collected',
  BACK_RENT_VALIDATION_MSG:
    'Amount should not be entered more than the Back Rent Amount',
  NOTE_PAYMENT: NOTE_PAYMENT,
  NON_INVENTORY_SALE: 'Non Inventory Sale',
  XBOX_GIFT_MEMBERSHIP: 'Xbox Gift Membership',
  XBOX_GIFT_CARD: 'Xbox Gift Card',
  CURRENT_DAYS_LATE: 'Current Days Late',
  AMOUNT_DUE: 'Amount Due',
  AMOUNT_UPPER_CASE: 'Amount',
  REASON: 'Reason',
  RENTAL_AGREEMENT_RTO: 'Rental Agreement (RTO)',
  RENT: 'Rent',
  EXCHANGE_IN_PROGRESS: 'Exchange In Progress',
  EXCHANGE: 'Exchange',
  ADJUST_DUE_DATE: 'Adjust Due Date',
  ADJUST_ALL_DUE_DATE: 'Adjust All Due Date',
  UN_ENROLL_AUTOPAY: 'Un-Enroll Autopay',
  ENROLL_AUTOPAY: 'Enroll Autopay',
  CHECK_AMOUNT: 'Check Amount',
  NSF_FEE: 'NSF Fee',
  NSF_FEE_TAX: 'NSF Fee Tax',
  NSF_TOTAL: 'NSF Total',
  SECOND_HEADER_NSF_STYLE_TEXT: 'secondHeader',
  CURRENT_SUSP: 'CurrentSusp',
  NEW_SUSP: 'NewSusp',
  DIFF_SUSP: 'diffSusp',
  NEW_COA: 'newCOA',
  COA: 'coa',
  IS_OVER_SUSPENSE_KEY: 'isOverSus',
  IS_EPO_SUS_AMT_DUE_KEY: 'isEPOSusAmtDue',
  IS_EXACT_SUSPENSE_KEY: 'isExactSus',
  EPO_RENT: 'EPO Rent',
  PIF_RENT: 'PIF Rent',
  SAC_RENT: 'SAC Rent',
  BUTTON: 'button',
  REGULAR: 'Regular',
  WIREACT: 'WIREACT',
  CASHSALE: 'CASHSALE',
  DELIVFEE: 'DELIVFEE',
  PROMO_FREE_TIME: 'Promo Free Time',
  SEMI_MONTHLY: 'Semi-Monthly',
  CURRENT: 'current',
  CAPITAL_CURRENT: 'CURRENT',
  MISSING: 'Missing',
  ESTIMATED: 'ESTIMATED',
  CROSSOVER: 'CROSSOVER',
  NEW: 'New',
  SUGGESTED: 'suggested',
  STATE: 'state',
  BEST_TIME: 'bestTime',
  ENTERED: 'entered',
  INVALID_ZIP: 'Invalid Zip',
  CHARGE_BACK_AMOUNT: 'Charge Back Amount',
  CHARGE_BACK_TOTAL: 'Charge Back Total',
  DATE: 'date',
  AMOUNT: 'amount',
  WEEKLY: 'Weekly',
  BI_WEEKLY: 'Bi-Weekly',
  MONTHLY: 'Monthly',
  CALL_IN_MORNING: 'Call in Morning',
  CALL_IN_EVENING: 'Call in Evening',
  CALL_IN_AFTERNOON: 'Call in Afternoon',
  RECEIPT: 'receipt',
  RECEIPT_UPPER_CASE: 'Receipt',
  RECEIPT_STATUS: 'Receipt Status',
  BORDER_RADIUS: '25px !important',
  IN_STORE: 'In Store',
  IN_PHONE: 'Over the Phone',
  ORIGIN_PHONE: 'H',
  IN_STORE_VALUE: 'Store',
  IN_PHONE_VALUE: 'Phone',
  IN_STORE_T2P_ORIGIN: 'STORET2P',
  ORIGIN_CASH_TAB: 'Cash-tab',
  ORIGIN_CARD_TAB: 'Card-tab',
  ORIGIN_CHECK_MONEYORDER: 'CheckMoney-Order-tab',
  CHANGE: 'change',
  SUSPENSE_ADD: 'suspAdd',
  SUSPENSE_USE: 'suspUse',
  ADD: 'Add',
  USE: 'Use',
  TRANSFER: 'Transfer',
  TRANSFER_SUSPENSE_ADD: 'transferSusAdd',
  TRANSFER_SUSPENSE_USE: 'transferSusUse',
  RECIEPT: 'reciept',
  ADJUST: 'Adjust',
  ADJUST_BACK_RENT: 'Adjust Back Rent',
  MISCELLANEOUS_ITEM: 'Miscellaneous Item',
  CAPITAL_COA: 'COA',
  WEB_LEAD_DEPOSIT: 'WL Deposit',
  IP_SUSPENSE: 'IP Suspense',
  CAPITAL_SUSPENSE: 'Suspense',
  SUSPENSE: 'suspense',
  CREDIT: 'credit',
  CAPITAL_CREDIT: 'Credit',
  COA_USED: 'coaUsed',
  WEB_LEAD_USED: 'webLeadUsed',
  IP_SUSPENSE_USED: 'ipSuspenseUsed',
  REFUND: 'Refund',
  COLLECT: 'Collect',
  DAYS: 'days',
  CARD_SWIPE_ENDING: 'Card Swipe Ending',
  CARD_ENDING: 'Card Ending',
  UNKNOWN: 'Unknown',
  CARD: 'Card',
  MASTER_CARD: 'Master',
  VISA_CARD: 'Visa',
  AMERICAN_EXPRESS_CARD: 'American Express',
  JCB_CARD: 'JCB',
  DINERS_CLUB: 'Diners Club',
  PAY_PAL: 'PayPal',
  DISCOVER: 'Discover',
  CARD_REF_TYPE_PP: 'PP',
  CARD_REF_TYPE_BL: 'BL',
  CARD_REF_TYPE_EC: 'EC',
  CARD_REF_TYPE_GC: 'GC',
  CARD_REF_TYPE_VM: 'VM',
  LASTFOUR: 'lastFour',
  SWIPE: 'swipe',
  CHECK: 'check',
  MONEY_ORDER: 'money order',
  CAPITAL_MONEY_ORDER: 'Money Order',
  TYPE_OF_CHECK_STRING: 'typeOfCheck',
  PURCHASER: 'purchaser',
  ISSUER: 'issuer',
  MONEY_ORDER_NUMBER: 'moneyOrderNumber',
  COMPLETE: 'complete',
  PENDING: 'pending',
  PENDING_CAPS: 'PENDING',
  IN_PROGRESS: 'inprogress',
  RETENTION_DATA: 'retentionData',
  REDIRECT_SOURCE: 'redirectSource',
  PAYMENT_DATE: 'Payment Date',
  CONVER_NUMBER_TO_COMMA_REGEX: /\B(?=(\d{3})+(?!\d))/g,
  LABEL: 'label',
  CMS006: 'CMS006',
  RENT_TO_RENT_LONER: 'Rent to Rent - Loaner',
  CARD_ENDING_WITH: 'Card ending with ',
  SAVE: 'save',
  PAYTOK: 'PAYTOK',
  OBJECT: 'object',
  VANTIV_CCFRAME: 'ccframe',
  VANTIV_CHARGE_FRAME: 'chargeframe',
  VANTIV_NUMBER: '01190090',
  ADD_NEW_CARD: 'addNewCard',
  CHARGE_CARD: 'chargeCard',
  MANAGE_CARD: 'manageCard',
  SWIPE_CARD: 'swipeCard',
  CHARGE: 'charge',
  DEVICE_NAME: 'deviceName',
  RACPAD: 'RACPAD',
  VANTIV_RESPONSE_CODE_870: '870',
  VANTIV_RESPONSE_CODE_884: '884',
  ZIP: 'zip',
  PRIMARY_ADDRESS: 'Primary Address',
  PIF_NOTE: 'PIF Note',
  ADDRESS_NAME: 'name',
  ADDRESS_CITY: 'city',
  ADDRESS_ZIP: 'zip',
  ADDRESS_ZIPCODE: 'zipcode',
  ADDRESS_ADDRESSLINE: 'addressLine',
  ADDRESS_ADDRESSLINE_ONE: 'addressLine1',
  ADDRESS_ADDRESSLINE_TWO: 'addressLine2',
  ADDRESS_STATE: 'state',

  /**
   * regex values
   */

  REPLACE_COMMA: /,/g,
  NON_NUMERIC_CHAR: /[^\d.-]/g,
  ONLY_NUMERIC_ALLOWED: /\D/,
  ONLY_CHARS_ALLOWED: /[^A-Za-z']/gi,
  INSERT_COMMA_IN_NUMBERS: /\B(?=(\d{3})+(?!\d))/g,

  /**
   * Address details regex
   */
  NAME_REGEX: /^[^\d]*$/,
  CITY_REGEX: /^[a-zA-Z\s]*$/,
  ZIP_REGEX: /^[0-9]*$/,
  ALPHA_NUMERIC_REGEX: /^[a-zA-Z0-9\s]*$/,

  /**
   * view EPO headers
   */
  VIEW_EPO_HEADERS: [
    { display: 'No' },
    { display: 'Payment Date' },
    { display: 'Payment Amount' },
    { display: 'Amount Applied' },
    { display: 'Estimated SAC/EPO' },
    { display: 'Remaining Rent' },
  ],

  /**
   * For Autopay constants
   */
  SEND_AUTOPAY_SIGNUP_TEXT: 'Send Autopay Signup Text',
  SEND_TEXT: 'Send Text',
  AUTO_PAY_POPUP_HEADER_FIELDS: [
    { display: '' },
    { display: 'Description' },
    { display: 'Amount' },
    { display: 'Schedule' },
    { display: 'Due Date' },
  ],
  TEXT_MESSAGE_UNENROLL: 'TXT2ACU',
  MESSAGE_TEMPLATE_KEY_UNENROLL: 'TXT2ACU-MESSAGE',
  TEXT_MESSAGE_ENROLL: 'TXT2AC',
  MESSAGE_TEMPLATE_KEY_ENROLL: 'TXT2AC-MESSAGE',

  /**
   * check Money order headers
   */
  CHECK_MONEY_ORDER_HEADERS: [
    { display: 'Document Type' },
    { display: 'Document #' },
    { display: 'Document From' },
    { display: 'Amount' },
    { display: 'Action' },
  ],

  CHECK_TYPE_ARRAY: [
    { value: '0', label: 'Select' },
    { value: '1', label: 'Personal check' },
    { value: '2', label: 'Travellers check' },
    { value: '3', label: 'Payroll Check' },
    { value: '4', label: 'Government Check' },
    { value: '5', label: 'Cashiers Check' },
    { value: '6', label: 'Club Claim Check' },
  ],

  /**
   * Card Validation strings
   */
  CARD_SAVING_DECLINED_PLEASE_TRY_A_DIFFERENT_CARD:
    'Card Saving Declined. Please try a different card.',
  CARD_SAVING_DECLINED_INSUFFICIENT_FUND_PLEASE_TRY_A_DIFFERENT_CARD:
    'Card Saving Declined. Insufficient Fund. Please try a different card',
  CARD_SAVING_DECLINED_EXCEEDS_APPROVAL_AMOUNT_LIMIT:
    'Card Saving Declined. Exceeds Approval Amount Limit',
  CARD_SAVING_DECLINED_CARDHOLDER_TRANSACTION_NOT_PERMITTED:
    'Card Saving Declined. Cardholder transaction not permitted',
  CARD_SAVING_DECLINED_INVALID_TRANSACTION:
    'Card Saving Declined. Invalid Transaction',
  CARD_SAVING_DECLINED_INVALID_PAYMENT_TYPE:
    'Card Saving Declined. Invalid Payment Type',
  CARD_SAVING_DECLINED_INVALID_MERCHANT:
    'Card Saving Declined. Invalid Merchant.',
  CARD_SAVING_DECLINED_CARD_ALREADY_ACTIVE:
    'Card Saving Declined. Card Already Active.',
  CARD_SAVING_DECLINED_CARD_TOKEN_NOT_FOUND:
    'Card Saving Declined. Card token not found.',
  CARD_SAVING_DECLINED_INVALID_AMOUNT: 'Card Saving Declined. Invalid Amount.',
  CARD_SAVING_DECLINED_TRANSACTION_NOT_PERMITTED:
    'Card Saving Declined. Transaction not permitted.',
  CARD_SAVING_DECLINED_INVALID_ACCOUNT_NUMBER_PLEASE_TRY_WITH_DIFFERENT_CARD:
    'Card Saving Declined. Invalid Account Number. Please try with different card.',
  CARD_SAVING_DECLINED_EXCEEDED_NUMBER_OF_PIN_ENTRIES_PLEASE_TRY_WITH_DIFFERENT_CARD:
    'Card Saving Declined exceeded number of Pin entries. Please try with different card.',
  CARD_SAVING_DECLINED_INVALID_PIN_PLEASE_TRY_WITH_VALID_PIN_NUMBER:
    'Card Saving Declined. Invalid Pin. Please try with valid Pin Number.',
  CARD_SAVING_DECLINED_INVALID_CARD_NUMBER_PLEASE_TRY_WITH_DIFFERENT_CARD:
    'Card Saving Declined. Invalid Card Number. Please try with different card.',
  CARD_SAVING_DECLINED_EXPIRED_CARD_PLEASE_TRY_WITH_DIFFERENT_CARD:
    'Card Saving Declined. Expired Card. Please try with different card.',
  ANOTHER_INSTANCE_OF_EXCEEDS_WITHDRAWAL_LIMIT_MESSAGE:
    'Card Saving Declined. Exceeds withdrawal limit please try a different card',
  PLEASE_CHECK_AND_REENTER_YOUR_CREDIT_CARD_NUMBER_AND_TRY_AGAIN:
    'Please check and re-enter your credit card number and try again.',
  PLEASE_CHECK_AND_REENTER_YOUR_CARD_VALIDATION_NUMBER_AND_TRY_AGAIN:
    'Please check and re-enter your card validation number and try again.',
  WE_ARE_EXPERIENCING_TECHINCAL_DIFFICULTIES_PLEASE_TRY_AGAIN:
    'We are experiencing technical difficulties. Please try again.',
  SOMETHING_WENT_WRONG_UNABLE_TO_SAVE_CARD_PLEASE_TRY_WITH_DIFFERENT_CARD_RETRY:
    'Something went wrong - Unable to save card. Please try with different card/retry.',
  PAYMENT_PROCESSED_SUCCESSFULLY_CLICK_ACCEPT_PAYMENT_TO_COMPLETE_TRANSCATION:
    'Payment processed successfully, click "Accept Payment" to complete transaction.',
  /**
   * check Mo Validation strings
   */

  PLEASE_SELECT_THE_CHECK_TYPE: 'Please select the check type.',
  PLEASE_ENTER_CHECK_NUMBER: 'Please enter check number.',
  PLEASE_ENTER_WRITTEN_TO: 'Please enter written to.',
  PLEASE_ENTER_WRITTEN_BY: 'Please enter written by.',
  AMOUNT_MUST_BE_GREATER_THAN_ZERO: 'Amount must be greater than 0.',
  PERSONAL_CHECK_IS_NOT_APPLICABLE_FOR_OVER_PAYMENT:
    'Personal check is not applicable for over payment',
  TRAVELLER_CHECK_IS_NOT_APPLICABLE_FOR_OVER_PAYMENT:
    'Traveller check is not applicable for over payment',
  PLEASE_ENTER_MONEY_ORDER_NUMBER: 'Please enter money order number.',
  PLEASE_ENTER_MONEY_ORDER_ISSUER: 'Please enter money order issuer.',
  PLEASAE_ENTER_MONEY_ORDER_PURCHASER: 'Please enter money order purchaser.',

  /**
   * Wizard constants
   */
  PERLIMINARY: '/customerorder/preliminary/',
  INITIAL: '/customerorder/initial/',
  PAYMENT_URL: 'paymentinformation/customerorder',

  /**
   * For grid constants
   */
  CHARGE_OFF_AGREEMENT: 'chargeOffAgreeement',
  RENTAL_ITEM_SALE_AGREEMENT: 'rentalItemSaleAgreement',
  NSF_CHECK_AGREEMENT: 'nsfCheckAgreement',
  CCCB_AGREEMENT: 'cccbAgreement',
  PROMO_AGREEMENT: 'promoAgreement',
  BACK_RENT_AGREEMENT: 'backRentAgreement',
  CLUB_AGREEMENT: 'clubAgreement',
  NOTE_PAYMENT_AGREEMENT: 'notePaymentAgreement',
  INITIAL_PAYMENT_AGREEMENT: 'initialPaymentAgreement',
  TRANSFER_AGREEMENT: 'transferAgreement',
  ZERO_PAYMENT_AGREEMENTS: 'zeroPaymentAgreemnets',
  EPP_AGREEMENTS: 'eppAgreements',
  PIF_NOTE_AGREEMENTS: 'pifNotePaymentAgreement',
  SAC_PIF_EPO_RENT_AGREEMENTS: 'sacPifEpoRentAgreements',
  LDW_AGREEMENTS: 'ldwAgreements',
  POLICY_AGREEMENTS: 'policyAgreements',
  AUTOPAY_AGREEMENTS: 'autopayAgreements',
  RETURN_AGREEMENTS: 'returnAgreements',
  SERVICE_AGREEMENTS: 'serviceAgreements',
  OTHER_INCOME_AGREEMENTS: 'otherIncomeAgreements',
  SET_TO_DEFAULT: 'setToDefault',
  ADJUST_CHARGE_OFF: 'adjustChargeOff',
  ADJUST_RENTAL_ITEM_SALE: 'adjustRentalItemSale',
  FREE_TIME: 'freeTime',
  ADJUST_DUE_DATE_SMALL_CASE: 'adjustDueDate',
  ADJUST_NSF: 'adjustNSF',
  ADJUST_CCCB: 'adjustCCCB',
  EPO_SMALL_CASE: 'epo',
  AUTOPAY: 'autopay',
  REMOVE_AMOUNT_DUE: 'removeAmountDue',
  RETENTION: 'retention',
  RACEXCHANGE: 'racExchange',
  ACCEPT_EPO: 'acceptEpo',

  /**
   * For Retention constants
   */
  RETENTION_OFFERS_ARRAY: [
    'Product',
    'No Longer Needs',
    'EPO too High',
    'Renewal rate too high',
    'Cannot make current payment',
    'Service',
    'Moving',
    'Total RTO/Term too high',
    'SAC too High',
  ],
  /**
   * For Charge-Off
   */

  REASONMAPPING: {
    COLLAGCY: 'Collection agency',
    COURTORD: 'Court ordered',
    CHARGEOFF: 'Charge off',
    CUSTINIT: 'Customer Initiated',
  },
  CHARGE_OFF: 'Charge-off',
  PRODUCT_SERVICE_ARRAY: ['Product', 'Service'],
  REPAIR_SWITCHOUT_PRODUCT: 'Repair or switch out product',
  SKIP_STOLEN_PAYMENT: 'Skip Stolen/Payment',
  INVENTORY: 'Inventory',
  AGREEMENT: 'Agreement',
  PAYMENT: 'Payment',
  STORE_MANAGEMENT: 'StoreManagement',
  SW: 'SW',
  RR: 'RR',
  CUSTOMER_DOES_NOT_ACCEPT_ANY_OFFER: 'Customer does not accept any offer',
  NO_RETENTION_OFFER_OPTION_AVAILABLE: 'No retention offer option available',
  NO_LONGER_NEEDS: 'No Longer Needs',
  EXPLAIN_LIFETIME_REINSTATEMENT_TO_THE_CUSTOMER:
    'Explain lifetime reinstatement to the customer',
  MOVING: 'Moving',
  GIVE_CUSTOMERS_THEIR_LOCAL_RAC_STORE_INFO:
    'Give customers their local RAC store info',
  MOVE: 'MOVE',
  AGMXNGS: 'AGMXNGS',
  AGREEMENT_EXCHANGE_SELECTED: 'Agreement Exchange Selected',
  TR: 'TR',
  AGREEMENT_EPO_SELECTED: 'Agreement EPO Selected',
  AEPOS: 'AEPOS',
  SECURITY_DEPOSITE: 'securityDeposit',
  AMOUNT_TO_COLLECT: 'amountToCollect',
  REASON_LOWER_CASE: 'reason',
  CARRY_LATE_FEE: 'carryLateFee',
  WAIVED_LATE_FEE: 'waivedLateFee',
  AGREEMENT_EXTENSION_SELECTED: 'Agreement Extension Selected',
  AEXTNS: 'AEXTNS',
  AGREEMENT_RATE_ADJUSTMENT_SELECTED: 'Agreement Rate Adjustment Selected',
  ARAS: 'ARAS',
  AGREEMENT_TERM_ADJUSTMENT_SELECTED: 'Agreement Term Adjustment Selected',
  ATAS: 'ATAS',
  AGR_RETENTION_SWITCHOUT_SELECTED: 'Agr Retention Switchout Selected',
  AGMRETSWS: 'AGMRETSWS',
  AGR_RETENTION_SERVICE_SELECTED: 'Agr Retention Service Selected',
  AGMRETSS: 'AGMRETSS',
  MOVING_SELECTED: 'Moving Selected',
  MS: 'MS',
  Agr: 'AGR:',
  AGR_RETENTION_NO_OFFER_SELECTED: 'Agr Retention No Offer Selected',
  AGMRETNONE: 'AGMRETNONE',
  AGR_RETENTION_NO_OFFER_AVAILABLE: 'Agr Retention No Offer Available',
  RETNOAVAIL: 'RETNOAVAIL',
  NO_LONGER_NEEDS_SELECTED: 'No Longer Needs Selected',
  NLNS: 'NLNS',
  TOTAL_RTO_TERM_TOO_HIGH: 'Total RTO/Term too high',
  CANSCRTRT: 'CANSCRTRT',
  CANCRTRTO: 'CANCRTRTO',
  SAC_TOO_HIGH: 'SAC too High',
  CANSCRSACT: 'CANSCRSACT',
  CANCRSACTH: 'CANCRSACTH',
  EPO_TOO_HIGH: 'EPO too High',
  CANSCREPOT: 'CANSCREPOT',
  CANCREPOTH: 'CANCREPOTH',
  RENEWAL_RATE_TOO_HIGH: 'Renewal rate too high',
  CANSCRRTH: 'CANSCRRTH',
  CANCRRTH: 'CANCRRTH',
  CANNOT_MAKE_CURRENT_PAYMENT: 'Cannot make current payment',
  CANSCRCMCP: 'CANSCRCMCP',
  CANCCRCMCP: 'CANCCRCMCP',
  SERVICE: 'Service',
  CANSCRSERV: 'CANSCRSERV',
  CANCRSERV: 'CANCRSERV',
  PRODUCT: 'Product',
  CANSCRPROD: 'CANSCRPROD',
  CANCRPROD: 'CANCRPROD',
  CANSCRMOVI: 'CANSCRMOVI',
  CANCRMOVI: 'CANCRMOVI',
  CANSCRNLN: 'CANSCRNLN',
  CANSXNG: 'CANSXNG',
  CANXNG: 'CANXNG',
  CANCRNLN: 'CANCRNLN',
  AGMXNGCAN: 'AGMXNGCAN',
  TRTO_HIGH_CANCELED: 'TRTO high Canceled',
  SAC_HIGH_CANCELED: 'SAC high Canceled',
  EPO_HIGH_CANCELED: 'EPO high Canceled',
  RATE_HIGH_CANCELED: 'Rate high Canceled',
  MAKE_PAYMENT_CANCELED: 'make paymnt Canceled',
  SEL: 'Sel',
  NOT_SEL: 'Not sel',

  /**
   * For session storage
   */
  STORE_NUMBER: 'storeNumber',
  STORE_DETAILS: 'storeDetails',

  /**
   * For operator constants
   */
  HYPHEN: '-',
  HYPHEN_WITH_SPACE: ' - ',
  COMMA: ',',
  COMMA_WITH_SPACE: ', ',
  DOLLAR: '$',

  /**
   * For card star pattern
   */
  CARD_STAR_PATTERN: '**** **** ****',
  ELLIPSIS: '...',

  /**
   * For null constants
   */
  NULL: null,

  /**
   * For Date & Time format constants
   */
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM_DD_YYYY_DDD: 'MM/DD/YYYY,ddd',
  MM_DD_YYYY: 'MM/DD/YYYY',
  HH_MM_SS: 'HH:mm:ss',
  HH_MM_A: 'hh:mm A',
  XXX: 'XXX',
  YYYY_MM_DD_HH_MM_SS: 'yyyy-MM-dd-HH:mm:ss:SSSSSS',
  SPLIT_WITH_T: 'T',
  SPLIT_WITH_Z: 'Z',

  /**
   * Coverage end date
   */
  COVERAGE_END_DATE: '12/31/2099',

  /**
   * For api keys
   */
  API_KEY_GET_PAYMENT_INFO: 'paymentInfo',
  API_KEY_GET_CUSTOMER_BY_ID_INFO: 'customerInfo',
  API_KEY_GET_PAYMENT_HISTORY: 'paymentHistory',
  API_KEY_GET_CARD_INFO: 'cardInfo',
  API_KEY_STORE_SWIPE_DEVICE: 'swipeDeviceInfo',
  API_KEY_GET_CUSTOMER_ADDRESS: 'customerAddress',
  API_KEY_GET_TAX: 'getTax',
  API_KEY_GET_CUSTOMER_ALERT: 'customerAlert',
  API_KEY_GET_ALERT: 'getAlerts',
  API_KEY_GET_RETENTION_OFFERS: 'RetentionOffers',
  API_KEY_GET_CUSTOMER_INFO_OPTIONS: 'customerInfoOptions',

  /**
   * For url constants
   */
  URL_CO_INITIAL: '/customerorder/initial',
  URL_CO_PRELIM: '/customerorder/preliminary',

  /**
   * For popup test constants
   */
  INVALID_ZIP_POPUP_TEXT: 'Invalid Customer Zip Code. Please Update',
  INVALID_PHONE_POPUP_TEXT: 'Invalid Customer Phone Number. Please update',
  NO_PRIMARY_ADDRESS_TEXT:
    'Customer does not have an active primary address. Please update',
  SIMS_COMMITMENT_EXCEPTION_TEXT:
    'Commitment is taken in SIMS. Please continue the commitment payment in SIMS.',
  DIFF_STORE_CO_INITIAL_EXCEPTION_TEXT:
    ' Selected agreement for a different store. Please take a payment on the agreement in your store only',
  CUSTOMER_VERIFICATION_EXCEPTION_TEXT: 'Please verify customer information',
  DELETE_ALL_SUSPENSE: 'Are you sure to delete all the suspenses?',
  UNABLE_TO_FETCH_THE_DATA_TRY_AGAIN_LATER:
    'Unable to fetch the data. Please try again later.',

  /**
   * For tooltip constants
   */
  EXCHANGE_DELIVERY_INITIATION_TOOLTIP:
    'Please confirm delivery to initiate an exchange',
  ITEM_IN_SERVICE_CANNOT_ACCEPT_EPO_TOOLTOP:
    'Item in service cannot Accept EPO',
  AUTOPAY_VALID_AGR_TOOLTIP:
    'Please select valid agreements to Enroll-AutoPay/Un-Enroll Autopay',

  /**
   * For Validations against input fields and API error modal
   */
  AMOUNT_ENTERED_CANNOT_BE_GREATER_THAN_EPO:
    'Amount entered can not be greater than EPO',
  AMOUNT_EQUAL_TO_EPO_USE_EPO_FUNCTIONALITY:
    'Amount entered can not be equal to EPO. Please use EPO functionality to process the payment',
  AMOUNT_GREATER_THAN_AVAILABLE_SUSPENSE:
    'Amount greater than available suspense',
  AMOUNT_GREATER_THAN_EPO: 'Amount greater than epo',
  PLEASE_CHOOSE_A_VALID_DUE_DATE: 'Please choose a valid due date',
  PLEASE_CHOOSE_AGR_OF_SAME_PROMO_FREE_DAYS:
    'Please choose the agreements of same Promo Free Days',
  FAILED_TO_UPDATE_ADDRESS: 'Failed to update address!',
  ADDRESS_COULD_NOT_BE_CORRECTED_UNLIKELY_TO_MATCH_OVERRIDE:
    'Address could not be corrected. Unlikely to match. Do you want to override?',
  ADDRESS_VALIDATION_CURRENTLY_UNAVAILABLE:
    'Address validation currently Unavailable',
  SOMETHING_WENT_WRONG: 'Something went wrong',

  /**
   * For table headers
   */
  RAC_EXCHANGE_RETURN_ALERT_POPUP_HEADER: [' ', AGREEMENT_HR, 'Description'],
  SUSPENSE_POPUP_HEADER: [
    AGREEMENT_HR,
    'Schedule',
    'Payment',
    'EPO /SAC(Without Suspense)',
    'Current Suspense',
    'New Suspense',
  ],
  EDIT_SCHEDULE_POPUP_HEADER: [
    'Agreement#',
    'Schedule',
    'Next Due Date',
    'Rate',
    'Opt.Services',
    'Policy',
    'Tax',
    'Total',
  ],
  PAYMENT_GRID_HEADER: [
    { display: null },
    { display: AGREEMENT_HR },
    { display: 'Type' },
    { display: 'Description' },
    { display: 'Schedule' },
    { display: 'Current Due Date' },
    { display: 'Next Due Date' },
    { display: 'Current Days Late' },
    { display: 'Amount Due' },
  ],
  CUSTOMER_PRIMARY_ADDRESS_POPUP_HEADER: [
    'Radio',
    'Address Line 1',
    'Address Line 2',
    'Zip',
    'City',
    'State',
    'Store#',
    'Agreement Count',
  ],
  NSF_FIRST_HEADER: [
    { display: 'Check Number' },
    { display: 'Check Written By' },
    { display: 'Return Date' },
  ],
  NSF_SECOND_HEADER: [
    { display: '   ' },
    { display: 'Original Amount' },
    { display: 'Balance Due' },
    { display: 'Adjusted' },
  ],
  CCCB_FIRST_HEADER: [
    { display: 'Credit Card Number' },
    { display: 'Charge Back Date' },
    { display: '  ' },
  ],
  CCCB_SECOND_HEADER: [
    { display: '   ' },
    { display: 'Original Amount' },
    { display: 'Balance Due' },
    { display: 'Adjusted' },
  ],

  NSF_FIRST_BODY: 'firstBodyNSF',

  /**
   * For two factor module name constants
   */
  SLA_MODULE_NAME_RET_EXT_EPP: 'RET EXT EPP',
  SLA_MODULE_NAME_CAPPED_FT: 'CappedFreeTime',
  SLA_MODULE_NAME_APPLY_FT: 'ApplyFreeTime',
  SLA_MODULE_NAME_RETENTION: 'CustomerRetention',
  SLA_MODULE_NAME_RACEXCHANGE: 'RACExchange',
  SLA_MODULE_NAME_NSF: 'NSFPayments',
  SLA_MODULE_NAME_CCCB: 'CCCBPayments',
  SLA_MODULE_NAME_CARRYWAIVE: 'Adjust Late Fee',
  SLA_MODULE_NAME_ACCEPTEPO: 'Accept Epo',
  SLA_MODULE_NAME_ACCEPT_PAYMENT: 'Accept Payment',

  /**
   * Feature Flags constant
   */
  ADDRESS_DOCTOR_ENABLED: 'AddressDoctorEnabled',

  /**
   *
   * OtherIncome popup button names
   */

  CHARGEOFF_SKIP_STOLEN: 'Charge-Off Skip/Stolen Payment',
  REFUND_SECURITY_DEPOSITE: 'Refund Security Deposit',

  CATEGORYMAPPER: {
    AcimaRecovery: 'ACIMARECOVERY',
    XboxGiftCard: 'XBGFTCRD',
    Membership: 'XBMEMSHIP',
    Other: 'NONINVCASH',
    deliveryFee: 'DELIVFEE',
  },
  /**Non-Inventory Sale */
  ACIMA_RECOVERY_TYPE: 'AcimaRecovery',
  XBOX_GIFT_CARD_TYPE: 'XboxGiftCard',
  XBOX_GIFT_MEMBERSHIP_TYPE: 'Membership',
  XBOX_GIFT_CARD_SCREEN: 'XboxGiftCard Screen',
  XBOX_GIFT_MEMBERSHIP_SCREEN: 'Membership Screen',
  AGREEMENTTYPEMAPPER: {
    AcimaRecovery: NON_INVENTORY_SALE,
    XboxGiftCard: XBOX_GIFT_CARD,
    Membership: XBOX_GIFT_MEMBERSHIP,
    Other: NON_INVENTORY_SALE,
  },
  PROVIDER: 'Provider',
  PLAN: 'Plan',
  AGREEMENT_STATUS_REASON: 'AGREEMENT_STATUS_REASON',
  CHARGEOFF_REFCODES: ['CUSTINIT', 'CHARGEOFF', 'COURTORD', 'COLLAGCY'],

  /* Validate Address starts here */
  CONFIRMED_COMPONENTS: [
    'postal_code',
    'locality',
    'administrative_area_level_1',
  ],
  COMPLETE_LEVEL_CONFIRMATION: ['CONFIRMED', 'UNCONFIRMED_BUT_PLAUSIBLE'],
  /* Validate Address ends here */

  /**
   * For AdjustDueDateCalci constants
   */
  ENGLISH_US: 'en-US',
  AMERCIAN_CHICAGO: 'America/Chicago',
  NON_SEMI_MONTHLY_SCHEDULE_ARRAY: ['WK', 'BIWK', 'MON'],
  ONE_TO_FIFTEEN: '1-15',
  PAYNFSTY: 'PAYNFSTY',
  PAYNFSTY_PLUS: 'PAYNFSTY+',
  UNDEFINED_STRING: 'undefined',
  NULL_STRING: 'null',
  FROM_CVV: 'cvv',

  /**
   * Club status for ToggleClub
   */
  CLUB_IS_PENDING: 'Club is pending',
  ACTIVATE_CLUB: 'Activate club',
  DEACTIVATE_CLUB_DESCRIPTION: 'Deactivate club',

  /**
   * Accept Payment constants
   */
  ACCOUNT_DEBITE: 'D',
  ACCOUNT_CREDIT: 'C',
  ACCOUNT_TYPE_SUSP: 'SUSP',
  ACCOUNT_TYPE_CHG: 'CHG',
  ACCOUNT_TYPE_WEBLEAD: 'WEBLEAD',
  ACCOUNT_TYPE_SIPS: 'SIPS',
  ACCOUNT_TYPE_CR: 'CR',
  TENDER_TYPE_CASH: 'CASH',
  TENDER_TYPE_CHECK: 'CHK',
  TENDER_TYPE_MO: 'MO',
  PAYMENT_RECEIPT: 'Payment Receipt',
  CERTIFICATE_OF_OWNERSHIP: 'Certificate of Ownership',
  EXTENTION_RECEIPT: 'Extension Receipt Form',
  PAYMENT_FAILED: 'Failed',
  PAYMENT_PASSED: 'Passed',
  CUSTOMER_SIGNAETURE_INSTALLMENT_ARR: ['INOTE'],
  AGREEMENT_DETAILS: 'agreementDetails',
  UK: 'UK',
  REX: 'REX', //store exception typeRef code for Free time
  EPD: 'EPD', //store exception typeRef code for EPO
  RENTAL_EXTENTION: 'Rental Extension/Free Time',
  RETENTION_FREETIME: 'Agr Retention Extension',
  RETENTION_EPO: 'Agr Retention EPO',
  RETENTION_TYPE: ['AGMRETEXT', 'AGMEPORET'],
  ManualSignOptions: [
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ],

  /**Payment History Constants */
  RECEIPT_TYPE_REC: 'rec',
  RECEIPT_TYPE_COO: 'coo',
  RECEIPT_TYPE_EXT: 'ext',
  CUSTOMER: 'customer',

  /**Club Payment history */
  RECEIPT_TYPE_PRCT: 'PRCT',
  RECEIPT_TYPE_COOS: 'COOS',
  RECEIPT_TYPE_EXTRF: 'EXTRF',
  RCP: 'RCP',

  /**Txt2pay constants */
  CUSTOMER_NAME: 'Customer Name',
  PHONE_NUMBER: 'Phone Number',

  /**Accept EPO text fields name */
  EPO_TXT_BOX_AMOUNT: 'epoAmtBox',
  PAST_DUE_RENT_TXT_BOX_AMOUNT: 'pastDueRentAmtBox',

  /**
   * Popup Priority strings
   */
  CUSTOMER_POPUPS: 'customerPopup',
  CODUEDATE_POPUPS: 'coDDAPopup',
  RETENTION_POPUPS: 'retentionPopup',
  SUSPENSE_POPUPS: 'suspensePopup',
  RACEXCHANGE_POPUPS: 'racExchangePopup',
  SOMETHING_WENT_WRONG_POPUP: 'somethingWentWrong',
  PAYMENT_ORIGIN_POPUP: 'paymentOriginPopup',

  /**
   * For reduceMethod params
   */
  ITEM_CATEGORY: 'itemCategory',
  TAX: 'tax',

  AGREEMENT_DETAILS_INITIAL_ARRAY: [
    {
      agreementId: '',
      agreementStatus: '',
      agreementNumber: '',
      agreementType: '',
      agreementDesc: '',
      agreementSchedule: '',
      currentDueDate: '',
      nextDueDate: '',
      daysLate: '',
      totalDueAmount: '',
      sacDate: '',
      epoAmount: '',
      suspenseAmount: '',
      lateLast10: '',
      promoFreeDays: '',
      rentPaid: '',
      lateFee: '',
      lateFeetax: '',
      originalLateFee: '0',
      lateFeeTaxRate: 0,
      carriedLateRent: '',
      numberOfPaymentsMade: '',
      deliveryFlag: false,
      selected: false,
      storeNumber: '',
      totalAmount: '',
      totalTax: '',
      suspenseDetails: {
        suspAdd: '0.00',
        suspUse: '0.00',
        transferSusAdd: '0.00',
        transferSusUse: '0.00',
      },
      agreementRateDetails: {},
      isLDW: false,
      isPolicy: false,
      coverageStatus: '',
      isCoverageActionPerformed: '',
      ldwAmount: '',
      ldwTax: '',
      policyAmount: '',
      policyTax: '',
      isEpp: false,
      switchoutDeliveryStatus: '',
      taxRate: '',
      isLdwAllowed: false,
      isPolicyAllowed: false,
      ldwPercentage: '',
      epoTax: '',
      policyStatus: '',
      carriedLateAmount: '',
      carriedLateTax: '',
      carryRentTax: '',
      carryRentAmount: '',
      scheduleRate: '',
      isAutopayEnroll: null,
      cardLastFour: null,
      isReturnAgreement: false,
      otherIncomeType: '',
      isItemInService: false,
      isZeroPayment: false,
      openDate: '',
      actualEpo: '',
      isExchanged: false,
      cashPrice: '',
      totalCost: '',
      pastDueRent: '',
      actualPastDueRent: '',
      isAcceptEPODiscounts: {
        epoDiscount: '',
        pastDueDiscount: '',
        noteDiscount: '',
      },
      parentAgreementId: '',
      extensionAmount: 0,
    },
  ],

  CUSTOMER_INITIAL_OBJECT: {
    customerId: '',
    customerCardDetails: {
      paymentMethods: [
        {
          cardType: '',
          cardTypeRef: '',
          lastFour: '',
          customerId: '',
          paymentMethodId: '',
          defaultVal: '',
          accountType: '',
          accountTypeRef: '',
          accountDescription: '',
          cardHolderName: '',
          phoneNumber: '',
          tokenInfo: {
            paymentToken: '',
            accountNumber: '',
            routingNumber: '',
            expirationDate: '',
            authTransId: '',
          },
          billingAddress: {
            billingAddressId: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            postalCode: '',
            active: '',
            state: '',
          },
        },
      ],
    },
    customerDetails: [
      {
        firstName: '',
        lastName: '',
        dob: '',
        globalCustomerId: '',
        totalPaymentsMade: 0,
        salesLead: null,
        addressLine1: '',
        addressLine2: '',
        city: '',
        phoneNumber: '',
        emailAddress: '',
        zip: '',
        plus4: '',
        state: '',
        entPartyId: 0,
        verified: '',
        isTaxExempted: '',
        verifiedDate: null,
        coCusVerified: '',
        coCusverifiedDate: null,
      },
    ],
    customerVerifiedStatus: '',
    schedule: '',
    dayPaid: '',
    amountUsed: {
      chargeCardAmount: '0.00',
      coa: '0.00',
      coaUsed: '0.00',
      webLeadUsed: '0.00',
      carryRent: '',
      convenienceFee: '',
      ipSuspenseUsed: '',
      cardOverPayment: false,
      tenderDetails: {
        orderId: 0,
        cash: {
          amount: '',
          type: '',
          orderId: 0,
        },
        card: [],
        cardPreviousValue: {},
        checkDetails: [],
        moneyOrderDetails: [],
      },
      remainingAmountDue: '',
      donationRemainingAmountDue: '',
      cardDetails: [],
    },
    remainingCredits: [],
    carryRentEnable: false,
    customerPaymentInfo: {
      check: 0,
      nsfCccb: '',
      lateLast10: [
        {
          name: '',
          students: 0,
        },
        {
          name: '',
          students: 0,
        },
        {
          name: '',
          students: 0,
        },
      ],
      commitment: [],
    },
    customerContactDetails: [
      {
        phoneId: '',
        phoneNumber: '',
        phoneType: '',
        phoneTypeDesc: '',
        extension: null,
        primary: '',
        callTimeType: '',
        callTimeTypeDesc: '',
        active: '',
        note: null,
        isNDNCOpted: '',
        isTrnsMsgAllowed: '',
        isMktgMsgAllowed: '',
      },
    ],
    customerAddressDetails: [
      {
        addressId: '',
        addressType: '',
        addressTypeDesc: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        latitude: null,
        longitude: null,
        active: '',
        verifiedDate: null,
      },
    ],
    numberOfPaymentsMadeInfo: '',
    customerFees: [
      {
        amount: '',
        itemCategory: '',
        taxRate: '',
        tax: '',
        type: '',
        itemCategoryId: '',
        itemCategoryTax: '',
        itemCategoryTaxId: '',
        postalCode: '',
        taxGeoCode: '',
        taxGeoCodeId: 0,
        stateTaxAmount: '',
        municipalTaxAmount: '',
        zipPlus4UsedForTax: '',
      },
    ],
    customerCardInfo: {
      paymentMethods: [
        {
          cardType: '',
          cardTypeRef: '',
          lastFour: '',
          customerId: '',
          paymentMethodId: '',
          defaultVal: '',
          accountType: '',
          accountTypeRef: '',
          accountDescription: '',
          cardHolderName: '',
          phoneNumber: '',
          tokenInfo: {
            paymentToken: '',
            accountNumber: '',
            routingNumber: '',
            expirationDate: '',
            authTransId: '',
          },
          billingAddress: {
            billingAddressId: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            postalCode: '',
            active: '',
            state: '',
          },
        },
      ],
    },
    carriedRent: 0,
    donation: '',
    carryRentToggleEnabled: false,
    change: {
      type: '',
      amount: '',
    },
    rsdAgreementInfo: [],
    convinenceFee: [] as TaxResponseInterface[],
    userEnteredDonationAmount: '',
    isEppCustomer: '',
  },
};

export default CONSTANTS;

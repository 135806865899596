/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable use-isnan */
/* eslint-disable react/no-children-prop */
/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */


/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */


/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */

import React,{ useState, useEffect } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
    RACSelect,
    RACTextbox,
    RACButton,
    RACCheckBox,
    RadioColor,
    RACRadio,
    RACModalCard,
    RACToggle,
    RACChip,
    RACTable,
    RACTableRow,
    RACTableCell,
    RACBadge,
    Grid,
    Typography,
    List,
    ListItem,
    Card,
    CardContent,
    Box,
    CircularProgress,
    RACCOLOR,
    makeStyles,
    RACAlert,
    RACInfoIcon,
} from "@rentacenter/racstrap";

export default function PopUpCarryWaive(props: any) {
    let classes = takePaymentStyles();
    //obj


    let Fees = {
        totalFeesOwed: "0.00",
        amountToCollect: "0.00",
        carryLateFee: "0.00",
        waivedLateFee: "0.00",
    }
    let [lateFees, setLateFees] = useState<any>(Fees);

    const [disableContinueBtn, setDisableContinueBtn] = useState(false);

    // const agrdetails = Number(props?.checkedAgr[0]?.lateFee);
    //     console.log(agrdetails,"------------AGRDETAILS");
    //     lateFees.totalFeesOwed = agrdetails;
    //     //setLateFees(lateFees);
    //     lateFees.amountToCollect = agrdetails;
    //     setLateFees(lateFees);

    // let newVar:<any>lateFees.totalFeesOwed-lateFees.carryLateFee;
    // setLateFees({...lateFees,amountToCollect:newVar});

    // useEffect(() => {
    //     //debugger;
    //    
    //     let totalfee = 0;
    //     for (let i = 0; i < props.checkedAgr.length; i++) {
    //         if (props.checkedAgr[i].lateFee > 0) {
    //             totalfee = totalfee + props.checkedAgr[i].lateFee;
    //             lateFees.totalFeesOwed = totalfee;
    //             setLateFees(lateFees);
    //         }
    //     }
    //     // lateFees.totalFeesOwed = props.checkedAgr[0].lateFee;
    //     // setLateFees(lateFees);

    //     //setLateFees({...lateFees, totalFeesOwed:props.checkedAgr[0].lateFee})

    //     // lateFees.carryLateFee = "0.00";
    //     // setLateFees(lateFees);
    //     /*let amtclct = lateFees.totalFeesOwed;
    //     lateFees.amountToCollect = amtclct;
    //     setLateFees(lateFees);*/
    //     //setLateFees({... lateFees,amountToCollect:props.checkedAgr[0].lateFee, totalFeesOwed:props.checkedAgr[0].lateFee})


    //     //setLateFees({...lateFees, totalFeesOwed:props.checkedAgr[0].lateFee});
    //     // setLateFees({...lateFees, amountToCollect:props.checkedAgr[0].lateFee})

    // })



    useEffect(() => {
        //debugger;
        // const [checkedAgrDetails, setCheckedAgrDetails] = useState(props.checkedAgr);
        //console.log(props?.checkedAgr, '------------------checked agr details 007');
        // if(Number(props?.checkedAgr[0]?.lateFee) != undefined){
        const agrdetails = Number(props?.checkedAgr[0]?.lateFee);
        const agrdetails1 = Number(props?.checkedAgr[0]?.constLateFee);
        //const updwaive = Number(agrdetails)-Number(agrdetails1);
        // console.log(agrdetails,"------------AGRDETAILS");
        // lateFees.totalFeesOwed = agrdetails;
        // lateFees.amountToCollect = agrdetails;
        // setLateFees(lateFees);

        if (agrdetails1 > 0) {
            lateFees.totalFeesOwed = Number(agrdetails1).toFixed(2);
            lateFees.amountToCollect = Number(agrdetails).toFixed(2);
            lateFees.waivedLateFee = (Number(agrdetails1)-Number(agrdetails)).toFixed(2);
            setLateFees(lateFees);
        }
       


    },[props])

    /* useEffect(() => {
         debugger
         if(Number(lateFees.amountToCollect)>Number(lateFees.totalFeesOwed))
                 {
                     setDisableContinueBtn(true);
                 }
                 else {
                     setDisableContinueBtn(false);
                 }
     },[lateFees])*/

    // useEffect(() => {

    //     console.log(props.checkedAgr, 'checked agr details1');
    //     let totalfee = props?.checkedAgr[0]?.lateFee.toFixed(2);
    //     console.log(totalfee,"-----------TOTALFEE");
    //     lateFees.totalFeesOwed = totalfee;
    //     setLateFees(lateFees);
    //     lateFees.amountToCollect = totalfee;
    //     setLateFees(lateFees);
    //     // lateFees.waivedLateFees = "0.00";
    //     // setLateFees(lateFees);
    //     //setLateFees({...lateFees, "totalFeesOwed":Number(totalfee)});
    //     // console.log(lateFees.totalFeesOwed,"-------------TOTALFEESOWED");
    //     // console.log(lateFees.amountToCollect,"-------------------AMOUNTTOCOLLECT");
    //     //setLateFees({...lateFees,totalFeesOwed:Number(totalfee), amountToCollect:Number(totalfee)});
    //     })

    console.log(props.checkedAgr, 'checked agr details2');

    // useEffect(() => {

    //     Number((lateFees).toFixed(2));
    //     setLateFees(lateFees);
    //     console.log(lateFees,"---------------BEFORE");
    // },[lateFees])    
    // useEffect(() => {
    //     console.log(props.checkedAgr, 'checked agr details1');
    //     setLateFees({...lateFees,amountToCollect:props.checkedAgr[0].lateFee, totalFeesOwed:props.checkedAgr[0].lateFee});
    // },[lateFees])

    // useEffect(() => {
    //     debugger;
    //     if(Number(lateFees.amountToCollect) || Number(lateFees.waivedLateFee)>Number(lateFees.totalFeesOwed)){
    //       setDisableContinueBtn(true);
    //     }
    //     else if(Number(lateFees.amountToCollect) || Number(lateFees.waivedLateFee)<=Number(lateFees.totalFeesOwed)){
    //         setDisableContinueBtn(false); 
    //     }
    //     else{
    //         setDisableContinueBtn(false);
    //     }

    // },[lateFees])
    // useEffect(() => {
    //     setLateFees({... lateFees,"amountToCollect" : lateFees.totalFeesOwed })
    // },[])
    // useEffect(() => {
    //     lateFees.totalFeesOwed = props.carryWaive.totalFeesOwed;
    //     lateFees.carryLateFee = "4.00";
    //     lateFees.waivedLateFee = "0.00";
    //     setLateFees(lateFees);
    // }, [])

    // useEffect(() => {
    //     // let amtclct = lateFees.totalFeesOwed - lateFees.carryLateFee;
    //     // lateFees.amountToCollect = amtclct;
    //     // setLateFees(lateFees);

    // }, [])



    // var fee1 = Number(lateFees.totalFeesOwed)-Number(lateFees.carryLateFee);
    // lateFees.amountToCollect = fee1;
    // setLateFees(lateFees);

    // lateFees.amountToCollect = lateFees.totalFeesOwed-lateFees.carryLateFee;
    // setLateFees(lateFees);
    // const[totalFeesOwed, setTotalFeesOwed] = useState<any>(15.55);
    // const[setVar, setSetVar] = useState<any>();
    // const[waiveFee,setWaiveFee] = useState<any>();
    // const[carryFee,setCarryFee] = useState<any>();

    // useEffect (()=>{
    //    // setTotalFeesOwed(15.55);
    //     // let carryamt = 4.00;
    //     // let fee1 = lateFees;
    //     // fee1[0].carryLateFee =Number(carryamt);
    //     // fee1[0].waivedLatefee = 0.00;
    //     // setLateFees(fee1);
    //     //  let subtraction = Number(totalFeesOwed)-Number(lateFees[0].carryLateFee);
    //     // lateFees[0].amountToCollect = subtraction;
    //     // setLateFees(lateFees);
    //     let amtclct = Number(totalFeesOwed)-Number(lateFees[0].carryLateFee);
    //     setSetVar(amtclct);
    //     setCarryFee(4.00);
    //     setWaiveFee(0.00);
    //     //amtclt(carryamt);
    // },[])
    // let fee2 = lateFees;
    // fee2[0].amountToCollect = totalFeesOwed - fee2[0].carryLateFee;
    // setLateFees(fee2);  
    // useEffect (()=>{

    // //    var carryamt = 4.00;
    //    let fee2 = totalFeesOwed;
    //    //let amtclct = Number(fee2) - Number(carryamt);
    //    let fee1 = lateFees;
    //    fee1[0].amountToCollect = fee2-carryamt;
    //    fee1[0].carryLateFee = carryamt;
    //    setLateFees(fee1);
    // },[])

    /*function amtclt(val:any){
       let fee2 = lateFees;
       fee2[0].amountToCollect = totalFeesOwed-val;
       setLateFees(fee2);
    }*/

    // useEffect (()=>{
    //     amtclt();
    // },[])

    // let fee2 = totalFeesOwed;
    // let amtclct = Number(fee2) - Number(carryamt);

    // useEffect(()=>{
    //    setTotalFeesOwed(15.55);
    //    let carryamt = 4.00;
    //    let amtclct = totalFeesOwed-carryamt;
    //    setTotalFeesOwed({...totalFeesOwed[0].amountToCollect : amtclct}); 
    // },[])
    const onClickCancel = () => {
        setDisableContinueBtn(false)
        props.isClosed(false)
        // lateFees.waivedLateFee = "0.00";
        // setLateFees(lateFees);
    }
    const onClickContinue = () => {
        //debugger;
        // lateFees.waivedLateFee="0.00";
        // setLateFees(lateFees);
        let prevlftx = (Number(props?.checkedAgr[0]?.lateFee) + Number(props?.checkedAgr[0]?.lFeeTaxAmount)).toFixed(2);
        let Amount1 = Number(Number(props?.checkedAgr[0]?.Amount) - Number(prevlftx)).toFixed(2);
        let prevtax = Number(props?.checkedAgr[0].Taxamount) - Number(props?.checkedAgr[0].lFeeTaxAmount);


        let lateFee = Number(lateFees.amountToCollect) ;
        let newtax = Number(Number(lateFee) * Number(props?.checkedAgr[0]?.lateFeeTaxRate)).toFixed(2);

        let finlfwtx = Number((Number(lateFee) + Number(newtax)).toFixed(2));
        let fintaxamt = Number((Number(prevtax) + Number(newtax)).toFixed(2));

        let Amount = Number(Amount1) + Number(finlfwtx);
        let initialepo = (Number(props?.checkedAgr[0]?.EPOAmount) - Number(prevlftx)).toFixed(2);
        let finalepo = (Number(initialepo) + Number(finlfwtx)).toFixed(2);
        let prevepotax = Number(props?.checkedAgr[0]?.EPOTax) - Number(props?.checkedAgr[0].lFeeTaxAmount);
        let finepotax = (Number(prevepotax) + Number(newtax)).toFixed(2);

        //let Amount = Number(props?.checkedAgr[0]?.Amount)-Number(lateFees.waivedLateFee);
        //let constLateFee = Number(lateFees.totalFeesOwed);
        //setLateFees({...lateFees, waivedLateFees:"0.00"})
        // let feesplit = Number(lateFees.carryLateFee) + Number(lateFees.waivedLateFee);
        // props.response({
        //     feesplit: feesplit
        // })

        // const updatedlatefee = props.checkedAgr;

        // const finallatefee = Number(props?.checkedAgr[0]?.lateFee)-Number(lateFees.waivedLateFee);
        // const finalamt = Number(props?.checkedAgr[0]?.Amount)-Number(lateFees.waivedLateFee);
        // updatedlatefee.Amount = Number(finalamt);
        // updatedlatefee.Taxamount = 0.0;
        // updatedlatefee.policyAmount = 0.0;
        // updatedlatefee.ldwTaxAmount = 0.0;
        // updatedlatefee.agrLdwAmount = 0.0;
        // updatedlatefee.policyTaxAmount = 0.0;
        // updatedlatefee.agreementRate = 0.0;
        // updatedlatefee.rpayTaxAmount = 0.0;
        // updatedlatefee.Latelast10 = 0.0;
        // updatedlatefee.lateFee = Number(finallatefee);
        // updatedlatefee.lFeeTaxAmount = 0.0;

        // console.log(updatedlatefee,'------------LATE FEE');
        // lateFees.waivedLateFee = "0.00";
        setLateFees(lateFees);
        props.checkedAgr[0].lateFee = Number(lateFee).toFixed(2)
        props.checkedAgr[0].Amount = Number(Amount)
        props.checkedAgr[0].Taxamount = Number(fintaxamt)
        props.checkedAgr[0].lFeeTaxAmount = Number(newtax).toFixed(2)
        props.checkedAgr[0].EPOAmount = Number(Number(finalepo).toFixed(2));
        props.checkedAgr[0].EPOTax = Number(Number(finepotax).toFixed(2));
        props.checkedAgr[0].waivedLateFee =Number(lateFees.totalFeesOwed)- Number(lateFees.amountToCollect)
        props.response({resArray:props.checkedAgr});
        //setLateFees({...lateFees, waivedLateFees:"0.00"})
    };

    const handleInputField = (e: any) => {
        //console.log('rgbffgg');
        // setSetVar(e.target.value);
        //debugger;
        switch (e.target.name) {

            case "amountToCollect":
                // setSetVar(e.target.value);

                // var remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);
                // //var latefee = lateFees;
                // if (remainingvalue >= lateFees.carryLateFee) {
                //     //alert('sdf');
                //     let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
                //     //setLateFees({...lateFees[0], [e.target.name]: Number(remvalue)});
                //     lateFees.waivedLateFee = remvalue;
                //     //console.log('HHHH', latefee);
                //     // setLateFees(latefee);
                // }
                // else if(e.target.value>lateFees.totalFeesOwed){
                //     lateFees.carryLateFee = "0.00";
                //     lateFees.waivedLateFee = "0.00";
                // }
                // else  {
                //     //alert('dfghg');
                //     //setLateFees([{...lateFees[0], [e.target.name]: Number(remainingvalue)}]);
                //     lateFees.carryLateFee = remainingvalue;
                //     lateFees.waivedLateFee = "0.00";

                // }
                // setLateFees(lateFees);

                // lateFees.amountToCollect = Number(e.target.value);
                // setLateFees(lateFees);
                // let amtclct = e.target.value;
                // lateFees.amountToCollect = Number((amtclct).toFixed(2));
                // setLateFees(lateFees);

                let amtclct = (e.target.value).split(",");
                let value = "";
                for (let i = 0; i < amtclct.length; i++) {
                    value = value + amtclct[i]
                }
                if (Number(value) > Number(lateFees.totalFeesOwed)||Number(lateFees.waivedLateFee)>Number(lateFees.totalFeesOwed)) {
                    setDisableContinueBtn(true);
                }
                else {
                    setDisableContinueBtn(false);
                }
                setLateFees({ ...lateFees, "amountToCollect": value });
                // if(Number(lateFees.amountToCollect) > Number(lateFees.totalFeesOwed))
                // {
                //     setDisableContinueBtn(true);
                // }
                // else {
                //     setDisableContinueBtn(false);
                // }


                console.log('HIII', lateFees);
                break;

            case "waivedLateFee":
                //debugger;
                //setWaiveFee(e.target.value);
                //alert('dfghg');
                // var remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);
                // //var latefee = lateFees;
                // if (remainingvalue >= lateFees.carryLateFee) {

                //     let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
                //     lateFees.amountToCollect = remvalue;
                // }
                // else if (e.target.value > lateFees.totalFeesOwed) {
                //     lateFees.amountToCollect = "0.00";
                //     lateFees.carryLateFee = "0.00";
                // }
                // else {
                //     // alert('df');
                //     lateFees.carryLateFee = remainingvalue;
                //     lateFees.amountToCollect = "0.00";

                // }
                // setLateFees(lateFees);

                amtclct = (e.target.value).split(",");
                value = "";
                for (let i = 0; i < amtclct.length; i++) {
                    value = value + amtclct[i]
                }
                if (Number(value) > Number(lateFees.totalFeesOwed)||Number(lateFees.amountToCollect)>Number(lateFees.totalFeesOwed)) {
                    setDisableContinueBtn(true);
                }
                else {
                    setDisableContinueBtn(false);
                }
                setLateFees({ ...lateFees, "waivedLateFee": value });
                // if (Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed)) {
                //     setDisableContinueBtn(true);
                // }
                // else {
                //     setDisableContinueBtn(false);
                // }
                console.log('GII', lateFees);
                //  setWaiveFee(lateFees[0].)
                break;

            case "carryLateFee":
                // var remainingvalue2 = lateFees.totalFeesOwed - Number(e.target.value);
                // var remvalue2 = Number(remainingvalue2) - Number(lateFees.waivedLateFee);

                // // setLateFees({...lateFees, "amountToCollect": remvalue2});
                // lateFees.amountToCollect = Number(remvalue2);
                // setLateFees(lateFees);
                setLateFees({ ...lateFees, "carryLateFee": e.target.value });
                if(e.target.value>Number(lateFees.totalFeesOwed))
                {
                    setDisableContinueBtn(true);
                }
                else {
                    setDisableContinueBtn(false);
                }
                break;




        }
    };

    const handleOtherField = (e: any) => {
        //debugger;
        switch (e.target.name) {
            case "amountToCollect":
                // Number((lateFees.amountToCollect).toFixed(2));
                // setLateFees(lateFees);
                let remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);
                //var latefee = lateFees;
                if (remainingvalue >= lateFees.carryLateFee) {
                    //alert('sdf');
                    let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
                    //setLateFees({...lateFees[0], [e.target.name]: Number(remvalue)});
                    lateFees.waivedLateFee = remvalue.toFixed(2);
                    //console.log('HHHH', latefee);
                    // setLateFees(latefee);
                    //setDisableContinueBtn(false);
                }
                else if (e.target.value > lateFees.totalFeesOwed) {
                    lateFees.carryLateFee = "0.00";
                    lateFees.waivedLateFee = "0.00";
                    //setDisableContinueBtn(true);
                }
                else {
                    //alert('dfghg');
                    //setLateFees([{...lateFees[0], [e.target.name]: Number(remainingvalue)}]);
                    lateFees.carryLateFee = remainingvalue.toFixed(2);
                    lateFees.waivedLateFee = "0.00";
                    //setDisableContinueBtn(false);

                }
                if (Number(e.target.value) > Number(lateFees.totalFeesOwed)||Number(lateFees.waivedLateFee)>Number(lateFees.totalFeesOwed)) {
                    setDisableContinueBtn(true);
                }
                else {
                    setDisableContinueBtn(false);
                }
                // setLateFees(lateFees);
                setLateFees({ ...lateFees, "amountToCollect": String(Number(e.target.value).toFixed(2)) });

                console.log('HIII', lateFees);
                break;

            case "waivedLateFee":
                //debugger;
                //setWaiveFee(e.target.value);
                //alert('dfghg');
                 remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);
                // var remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
                // lateFees.amountToCollect = remvalue;
                // setLateFees(lateFees);
                //var latefee = lateFees;
                if (remainingvalue >= lateFees.carryLateFee) {

                    let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
                    lateFees.amountToCollect = remvalue.toFixed(2);
                    //setDisableContinueBtn(false);
                }
                else if (e.target.value > lateFees.totalFeesOwed) {
                    lateFees.amountToCollect = "0.00";
                    lateFees.carryLateFee = "0.00";
                    //setDisableContinueBtn(true);
                }
                else {
                    // alert('df');
                    lateFees.carryLateFee = remainingvalue.toFixed(2);
                    lateFees.amountToCollect = "0.00";
                    //setDisableContinueBtn(false);
                }
                if (Number(e.target.value) > Number(lateFees.totalFeesOwed)||Number(lateFees.amountToCollect)>Number(lateFees.totalFeesOwed)) {
                    setDisableContinueBtn(true);
                }
                else {
                    setDisableContinueBtn(false);
                }
                // setLateFees(lateFees);
                setLateFees({ ...lateFees, "waivedLateFee": String(Number(e.target.value).toFixed(2)) });

                //  
                console.log('GII', lateFees);
                //  setWaiveFee(lateFees[0].)
                break;

            case "carryLateFee":
                //debugger;
                 remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);
                let remvalue2 = Number(remainingvalue) - Number(lateFees.waivedLateFee);

                // setLateFees({...lateFees, "amountToCollect": remvalue2});
                lateFees.amountToCollect = Number(remvalue2);
                setLateFees(lateFees);
                setDisableContinueBtn(false);
                if (e.target.value > lateFees.totalFeesOwed) {
                    lateFees.amountToCollect = "0.00";
                    lateFees.waivedLateFee = "0.00";
                    //setDisableContinueBtn(true);
                }
                //setLateFees({ ...lateFees, "carryLateFee": e.target.value });
                break;

        }
    }


    //changes in calculation of fields regards in the SIIMS

    // const handleInputField = (e: any) => {
    //     //console.log('rgbffgg');
    //     // setSetVar(e.target.value);
    //     //debugger;
    //     switch (e.target.name) {

    //         case "amountToCollect":


    //             var remainingvalue = lateFees.totalFeesOwed - (Number(e.target.value)+Number(lateFees.waivedLateFee));

    //             if (remainingvalue >= 0.00
    //                 ) {
    //                 //alert('sdf');
    //                 let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
    //                 //setLateFees({...lateFees[0], [e.target.name]: Number(remvalue)});
    //                 lateFees.waivedLateFee = remvalue;
    //                 //console.log('HHHH', latefee);
    //                 // setLateFees(latefee);
    //             }
    //             else if(e.target.value>lateFees.totalFeesOwed){
    //                 lateFees.carryLateFee = "0.00";
    //                 lateFees.waivedLateFee = "0.00";
    //             }
    //             else  {
    //                 //alert('dfghg');
    //                 //setLateFees([{...lateFees[0], [e.target.name]: Number(remainingvalue)}]);
    //                 lateFees.carryLateFee = remainingvalue;
    //                 lateFees.waivedLateFee = "0.00";

    //             }
    //             setLateFees(lateFees);
    //             setLateFees({ ...lateFees, "amountToCollect": e.target.value });

    //             console.log('HIII', lateFees);
    //             break;

    //         case "waivedLateFee":
    //             //debugger;
    //             //setWaiveFee(e.target.value);
    //             //alert('dfghg');
    //             var remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);
    //             //var latefee = lateFees;
    //             if (remainingvalue >= lateFees.carryLateFee) {

    //                 let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
    //                 lateFees.amountToCollect = remvalue;
    //             }
    //             else if(e.target.value>lateFees.totalFeesOwed){
    //                 lateFees.amountToCollect = "0.00";
    //                 lateFees.carryLateFee = "0.00";
    //             }
    //             else {
    //                 // alert('df');
    //                 lateFees.carryLateFee = remainingvalue;
    //                 lateFees.amountToCollect = "0.00";

    //             }
    //             setLateFees(lateFees);
    //             setLateFees({ ...lateFees, "waivedLateFee": e.target.value });

    //             //  
    //             console.log('GII', lateFees);
    //             //  setWaiveFee(lateFees[0].)
    //             break;

    //         case "carryLateFee":
    //             var remainingvalue2 = lateFees.totalFeesOwed - Number(e.target.value);
    //             var remvalue2 = Number(remainingvalue2) - Number(lateFees.waivedLateFee);

    //             // setLateFees({...lateFees, "amountToCollect": remvalue2});
    //             lateFees.amountToCollect = Number(remvalue2);
    //             setLateFees(lateFees);
    //             setLateFees({ ...lateFees, "carryLateFee": e.target.value });
    //             break;




    //     }
    // }

    const carryWaivePopUp = () => {
        return (
            <>
                <Grid container spacing={3} className={classes.px3}>

                    <Grid item md={6} className={classes.mb2} >
                        {/* <Grid  style={{fontSize:'small', fontWeight:'bold' , marginTop:'2%' , marginBottom:'2%',marginLeft:'5%' }} ><span><label className='classes.formLabel'>Total Fees Owed</label></span></Grid> */}
                        <RACTextbox
                            inputlabel="Total Fees Owed"
                            inputLabelClassname={classes.waiveLabel}
                            //className={classes.formcontrol3}
                            //className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                            //  dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                            className={classes.waiveText}
                            value={lateFees.totalFeesOwed}
                            isCurrency={true}
                            disabled={true}
                            required={true}
                        />
                    </Grid>
                    <Grid item md={6} className={classes.mb2} style={{ marginRight: '-25px' }} >
                        {/* <Grid  style={{fontSize:'small', fontWeight:'bold' ,width: "46%", marginTop:'2%' , marginBottom:'2%',marginLeft:'5%' }} ><span><label className='classes.formLabel'>Amount To Collect</label></span></Grid> */}
                        <RACTextbox
                            inputlabel="Amount To Collect"
                            inputLabelClassname={classes.waiveLabel}
                            // inputLabelClassname={classes.formLabel}
                            // className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                            // dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                            className={classes.waiveText}
                            value={lateFees.amountToCollect}
                            isCurrency={true}
                            OnChange={(e: any) => handleInputField(e)}
                            Onblur={(e: any) => handleOtherField(e)}
                            name="amountToCollect"
                            type={"number"}
                            maxlength={5}
                        />
                        {Number(lateFees.amountToCollect) > Number(lateFees.totalFeesOwed) ? (
                            <label className={classes.mandatoryfield}>
                                Amount shouldn't greater than total fees owed.
                            </label>
                        ) : null}
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.px3}>
                    <Grid item md={6} className={classes.mb2} >
                        {/* <Grid style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Carry Late Fee Amount (max $4.00)</label></span></Grid> */}
                        {/* <RACTextbox
                            inputlabel="Carry Late Fee Amount (max $4.00)"
                            inputLabelClassname={classes.formLabel}
                            className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                            dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}

                            value={lateFees.carryLateFee}
                            isCurrency={true}
                            OnChange={(e: any) => handleInputField(e)}
                            Onblur={(e: any) => handleOtherField(e)}
                            name="carryLateFee"
                            type="number"
                            disabled={true}
                        />
                        {Number(lateFees.carryLateFee) > Number(4.00) ? (
                            <label className={classes.mandatoryfield}>
                                User shouldn't be allowed to enter more than $4.00 in Carry Late Fee Amount field
                            </label>
                        ) : null} */}
                        <RACTextbox
                            inputlabel="Waived Late Fee Amount"
                            inputLabelClassname={classes.waiveLabel}
                            // inputLabelClassname={classes.formLabel}
                            // className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                            // dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                            className={classes.waiveText}
                            value={lateFees.waivedLateFee}
                            isCurrency={true}
                            OnChange={(e: any) => handleInputField(e)}
                            Onblur={(e: any) => handleOtherField(e)}
                            maxlength={5}
                            name="waivedLateFee"
                            type={"number"}
                        />
                        {Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed) ? (
                            <label className={classes.mandatoryfield}>
                                Amount shouldn't greater than total fees owed.
                            </label>

                        ) : null}
                    </Grid>

                    <Grid item md={6} className={classes.mb2}>
                        {/* <Grid style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Waived Late Fee Amount</label></span></Grid> */}
                        {/* <RACTextbox
                            inputlabel="Waived Late Fee Amount"
                            inputLabelClassname={classes.formLabel}
                            className={`${classes.custDigit1} ${classes.cashtxtbox}`}
                            dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                            value={lateFees.waivedLateFee}
                            isCurrency={true}
                            OnChange={(e: any) => handleInputField(e)}
                            Onblur={(e: any) => handleOtherField(e)}
                             
                            name="waivedLateFee"
                            type="number"
                        />
                        {Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed) ? (
                            <label className={classes.mandatoryfield}>
                                User shouldn't be allowed to enter more than maximum of total late fee amount in Waive Late Fee field
                            </label>
                        ) : null} */}
                    </Grid>
                </Grid>
                <Grid style={{ float: 'right' }}>
                    <RACButton
                        //className={classes.ms2}
                        style={{
                            marginRight: "20px",
                            // backgroundColor: "white",
                            // color: "#2C3333",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                        }}
                        color="inherit"
                        variant="outlined"
                        onClick={() => onClickCancel()}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        //className={classes.ms2}
                        style={{ marginRight: "15px" }}
                        color="primary"
                        variant="contained"
                        disabled={disableContinueBtn}
                        onClick={() => onClickContinue()}

                    >
                        Continue
                    </RACButton>
                </Grid>


            </>
        )
    }

    return (
        <Grid>
            <RACModalCard
                isOpen={props.isOpen}
                maxWidth="xs"
                title="Waive Late Fee"
                onClose={() => onClickCancel()}
                closeIcon={true}
                borderRadius="20px"
                children={carryWaivePopUp()}
            />
        </Grid>
    );
};
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-constant-condition */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable sonarjs/no-identical-expressions */
/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-irregular-whitespace */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable react/no-children-prop */

import { useEffect, useState } from "react"
import React  from 'react'
import { useHistory ,useParams} from "react-router-dom";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import SecondFactor from "./TwoFactorPopup";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import moment from "moment";
//import {getpaymentinfo,CoworkerRequired} from "../api/user";
//import{Takepayment}from "../racstrapComponents/microfrontends/Takepayment";
import{
    RACSelect,
    RACTextbox,
    RACButton,
    RACModalCard,
    Grid,
    Typography,
    RACTable,
    RACTableCell,
    RACTableRow,
    
 } from "@rentacenter/racstrap";
 export default function NsfCheckPay(props:any){
  //debugger;
    const[nsfTotal,setNsfTotal]=useState<number>(0.00);
    const[nsfAuthentication,setNsfAuthentication]=useState<any>();
   const[nsfContinue,setNsfContinue]=useState<boolean>(false)
   const [nsfFeeTax, setNsfFeeTax]=useState<any>(props.nsfFeetax)
    const[type,setType]=useState<boolean>(false)
    let Amount=props.nsfFee;
    const[nsfFee,setNsfFee]=useState<any>(Amount);
    const[nsf,setNsf]=useState<any>({
      nsfFee:"0.00",
        nsfTax:"0.00"
   })
    
    console.log(nsf,"nsfState")
    console.log(nsfFee,"nsfTax");
    console.log(props.nsfTax,"props.nsfTax");
    const[total,setTotal]=useState<number>(0.00);
    //let nsfFeeTotal=(Number(props.nsfDetails[0].nsfAmount)+Number(nsfFee));
    const ModalRadius = "25px !important";

    {/**
   *PS_CO_24
   *To bind the total amount and to show the error message
   *and set the Continue button to disable.
   */} 
   useEffect(()=>{
    let obj:any={
      nsfFee:Number(props.nsfFee).toFixed(2),

        nsfTax:Number(props.nsfTax)

   }
    setNsf(obj)
    console.log("nsfobj",obj)

   },[])

    useEffect(()=>{
        let amountVal=nsf
        let amount=Number(nsf.nsfFee);
        //console.log(amount,"nsf amt");
        debugger;
        let nsfFeeTotal=(Number(props.nsfAmt)+Number(nsf.nsfFee==undefined?props?.nsfFee:nsf.nsfFee)+Number(nsfFeeTax==''?props?.balanceDueFeeTax:nsfFeeTax));
        setTotal(nsfFeeTotal);
        let totalFee=(Number(props.nsfAmt)+Number((props?.nsfData?.nsfFee))+Number(props?.balanceDueFeeTax));
        setNsfTotal(totalFee);
        setNsfFeeTax(Number(nsfFeeTax==''?props.balanceDueFeeTax:nsfFeeTax))
        // setNsfFee
        
    },[nsf])
    // useEffect(()=>{
    //     setNsf(
    //         {
    //             nsfFee:props.nsfTax,
    //             nsfTax:0.00   
    //         }
    //     )
    // },[props.nsfTax])
  //   useEffect(()=>{
  //     if(props.nsfPopUp==true){
  //         setNsfAuthentication(true);
  //         checkDetails();
  //     }
  // },[props.cccb])
    // to set the values
    useEffect(()=>{
        
        if(Number(nsf.nsfFee)>Number(props.originalNsfFee)){
          setNsfContinue(true);
          setType(true);
        }
        else{
            setNsfContinue(false);
            setType(false);
        }
        if(nsf.nsfFee=="" ||nsf.nsfFee=="0.00"){
          setNsfContinue(true);
        }
    },[nsf])
    
    const classes=takePaymentStyles();
    // check details
    const autohead=[
        {display:"Check Number"},
        {display:"Check Written By"},
        {display:"Return Date"}
    ];
    //Check values
    const checkContent=[
      {display:props?.checkNumber},
      {display:props?.checkWrittenBy},
      {display:moment(props?.nsfData?.returnDate).format("MM/DD/YYYY")}
    ];
    //to bind the header
    //const[amtDetail,setAmtDetail]=useState();
    const heading=[
        {display:"   "},
        {display:"Original Amount"},
        {display:"Balance Due"},
        {display:"Adjusted"}
    ]
     {/**
   *PS_CO_23
   *To set the value entered in the textBox to the stateVariable
   */}
    function handleInputTextChange(e:any){
        //let a=(e.target.value).tofixed(2);
       // console.log(a,"nsf a");
       let tax=(Number(e.target.value)*Number(props.nsfFeeTaxRate)).toFixed(2)
        setNsf({...nsf,[e.target.name]: e.target.value});
        setNsfFeeTax(tax)
        console.log("taxx",tax)
        amountBlur(e); 
   }

   function textboxblur(e:any){
    console.log(e.target.value,"nsf valueee");
    if(e.target.value==""){
      let inputval="0.00";
      setNsf(
        {
            nsfFee:inputval,
            nsfTax:0.00   
        }
     )
   }else{
    let inputval=Number(e.target.value).toFixed(2);
    setNsf(
      {
          nsfFee:inputval,
          nsfTax:0.00   
      }
   )

   }
  }
   //to change the value,
    {/**
   *PS_CO_23
   *This code is to remove the comma(seperator) from the entered value
   */}
   const amountBlur  = (e: any) => {
   // debugger;
    console.log(e.target.value,"nsf valueee");
    let InputTxt = e.target.value === "" ? "0.00" : e.target.value;
    console.log(InputTxt,"nsf InputTxt")
    let FloatInputTxt:any;
    FloatInputTxt = InputTxt;
    //console.log(FloatInputTxt," nsf FloatInputTxt")
    if (InputTxt !== "" && parseFloat(InputTxt) !== 0.0) {
      let InputTxtArr = InputTxt.replace(",","");
      let tax=(Number(InputTxtArr) * Number(props.nsfFeeTaxRate)).toFixed(2)
      console.log("taxxxx",tax)
   
      setNsf(

        {
            nsfFee:InputTxtArr,
            nsfTax:0.00   
        }  
    )
    setNsfFeeTax(tax)
      }
   }
    //to render the header
    const renderCheckValue=()=>(
       <>

       {
        checkContent.map((val,index)=>{
            return(

                <RACTableCell className={`${classes.racTable} ${classes.title1} `}
                style={{backgroundColor:"white"}}
                //style={{marginLeft:"100px",backgroundColor:"white",marginTop:"50px",fontSize:"12"}}
                
                 key={index} >
                    {val.display}
            </RACTableCell>
            );
        })
       }
       </> 
    );
    const renderCheck=()=>(
        <>
        {
         autohead.map((val,index)=>{
             return(
                 
                 <RACTableCell className={`${classes.racTable} ${classes.title1}`}
                 style={{backgroundColor:"White"}}
                 //style={{marginLeft:"100px",backgroundColor:"white",marginTop:"50px",fontSize:"12"}}
                  key={index} >
                     {val.display}
             </RACTableCell>
             );
         })
        }
        </> 
    )
    //to render the  value
    const renderHead=()=>(
     <>
       {
        heading.map((val,index)=>{
            return(
                
                <RACTableCell className={`${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.rowColor}`} 
              style={{paddingLeft:"2px"}}
                //style={{marginLeft:"50px",backgroundColor:"white"}}
                key={index} >
                    {val.display}
            </RACTableCell>
            );
        })
       }
     </>
    )
    
    const renderAmount=()=>{
        return(
          <>
          
          <RACTableRow
                    className={` ${classes.rowColor} ${classes.font12}`}
                  >
                    <RACTableCell className={` ${classes.paymentStore}  `} 
                    style={{fontFamily: "OpenSans-semibold"}}>
                       Check Amount
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                    style={{fontFamily: "OpenSans-semibold"}}
                    >
                    <span>$</span>{Number(props?.nsfData?.amount).toFixed(2)}
                     
                   
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                    style={{fontFamily: "OpenSans-semibold"}}
                    >
                    <span>$</span>{(props.nsfAmt).toFixed(2)}
                    
                    </RACTableCell>
                    <RACTableCell
                     className={`${classes.textend} ${classes.font12} ${classes.paymentStore} `}
                     style={{fontFamily: "OpenSans-semibold"}} >
                    <span>$</span>{(props.nsfAmt).toFixed(2)}
                    </RACTableCell>
                    
         </RACTableRow>
                  <RACTableRow
                    className={` ${classes.rowColor} ${classes.font12}`}
                  >
                    <RACTableCell className={` ${classes.paymentStore}`} 
                    style={{fontFamily: "OpenSans-semibold"}}
                    >
                       NSF Fee
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                    style={{fontFamily: "OpenSans-semibold"}}>
                     <span>$</span>{props.originalAmountnsfFee}
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                    style={{fontFamily: "OpenSans-semibold"}}>
                    <span>$</span>{props.balanceDuensfFee}
                    </RACTableCell>
                    <RACTableCell
                    style={{width:"23%"}}
                    >
                        <RACTextbox 
                          inputlabel=""
                          className={`${classes.nsfCurrency} `}
                          type={"number"}
                          digitFormat={"currency"}
                          id="nsfFee"
                         name="nsfFee"
                        value={nsf.nsfFee}
                        OnChange={(e)=>handleInputTextChange(e)}
                       Onblur={(e)=>textboxblur(e)}
                       maxlength={6}
                       dollarTextClassName={classes.currency}
                       
                       isCurrency={true}
                       />
                    </RACTableCell> 
                  </RACTableRow>
                 
                  {type==true?( 
                <RACTableRow
                //error message

                
                  className={` ${classes.rowColor} ${classes.font12}`}
                >  
               <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`} 
                  style={{fontFamily: "OpenSans-semibold",float:"left"}}>
                  {}
                  </RACTableCell>
                  <RACTableCell  >
                   {}   
                  </RACTableCell>
                  <RACTableCell >
                  {}   
                  </RACTableCell>
                  <RACTableCell
                   >
                   {type===true?(
                      <label className={classes.mandatoryfield}
                      style={{float:"left",fontSize:"11px"}}>   
                         Amount entered should not be greater than ${Amount}.
                    </label> 
                  ):null}   
                  </RACTableCell>        
                 </RACTableRow>):null}

    
                
                  <RACTableRow
                    className={` ${classes.rowColor} ${classes.font12}`}
                  >
                     <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`} 
                    style={{fontFamily: "OpenSans-semibold",float:"left"}}>
                       NSF Fee Tax
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                   style={{fontFamily: "OpenSans-semibold"}} >
                    <span>$</span>{(Number(props.originalAmountnsfFee)*Number(props.nsfFeeTaxRate)).toFixed(2)}
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                    style={{fontFamily: "OpenSans-semibold"}}>
                    <span>$</span>{(Number(props.balanceDueFeeTax)).toFixed(2)}
                    </RACTableCell>
                    
                    <RACTableCell
                     className={`${classes.textend} ${classes.font12} ${classes.paymentStore} `}
                     style={{fontFamily: "OpenSans-semibold"}}>
                    <span>$</span>{Number(nsfFeeTax).toFixed(2)}
                    </RACTableCell>
                    
         </RACTableRow>
         <RACTableRow
                    className={` ${classes.rowColor} ${classes.font12}`}
                  >
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`} 
                    style={{fontFamily: "OpenSans-semibold",float:"left"}}>
                       NSF Total
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                    style={{fontFamily: "OpenSans-semibold"}} >
                    <span>$</span>{(Number(props?.originalAmountnsfFee)+Number(props?.nsfData?.amount)+Number(props.balanceDueFeeTax)).toFixed(2)}
                    </RACTableCell>
                    <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                    style={{fontFamily: "OpenSans-semibold"}}>
                    <span>$</span>{nsfTotal.toFixed(2)}
                    </RACTableCell>
                    <RACTableCell
                     className={`${classes.textend} ${classes.font12} ${classes.paymentStore} `}
                     style={{fontFamily: "OpenSans-semibold"}}>
                    <span>$</span>{total.toFixed(2)}
                    </RACTableCell>
                  

                    
         </RACTableRow>
      
       
    </> 
        )  
    }
    {/**
   *PS_CO_24
   *This code is to set the values to the initial values, when the set to default is clicked
   */}
    const setDefault=()=>{
        setNsf(
            {
                nsfFee:Number(props.balanceDuensfFee),
                nsfTax:Number(props.balanceDueFeeTax)   
            }
        )
        setNsfFeeTax(props.balanceDueFeeTax)
        setType(false)
    }

   const cancelClick=()=>{
    props.isClosed(false);
    setNsf(
        {
            nsfFee:props.nsfFee,
            nsfTax:0.00   
        }
    )
    setType(false)
    setNsfFeeTax(props.balanceDueFeeTax)
   }
   {/*
   *PS_CO_26
   *In this code when the continue button is clicked it send the
   *type, amount , nsf fee,tax , total and agreement id to the takePayment and set the nsf Fee 
   */} 
   
    const ContinueClck=()=>{
        
       /* props.response({
            type:"NSF Check",
            Total:total,
            Amount:Number(nsf.nsfFee),
            Agreementid:Number(props.checkNumber),
           // salesTax :Number(nsf.nsfFee)
        })
        setNsfFee(props.nsfTax);*/
        props.response({
          type:"NSF Check",
          Total:total,
          Amount:nsf.nsfFee,
          Agreementid:Number(props.nsfData.receiptTransactionId),
          salesTax :Number(nsfFeeTax)
      })
      setNsfFee(nsf.nsfFee);
  }
    
   // console.log("nsf",props.agrmt);
   {/*
   *PS_CO_22
   *To bind the data from takePayment as props 
   *and set it to the respective fields
   */}
    function popUpnsf(){
     return(
        <>
        <Grid 
        >
         <RACTable
           renderTableHead={renderCheck}
           renderTableContent={renderCheckValue}
         />
        </Grid>
        <Grid>
       
         <RACTable 
           renderTableHead={renderHead}
           renderTableContent={()=>renderAmount()}
           />
        </Grid>
        <Grid item md={12}
        style={{marginTop:"30px"}} >          
               
                  <RACButton
                     
                     variant="contained"
                     color="primary"
                     className={`${classes.spacerMR2} ${classes.setDefault}`}
                     style={{float:"right",padding:"8px",paddingLeft:"15px",paddingRight:"15px"}}
                     disabled={nsfContinue}
                    onClick={()=> ContinueClck()}
                   
                  >
                  Continue
                  </RACButton>

                  <RACButton
                    className={`${classes.spacerMR2} ${classes.setDefault}`}
                    color="primary"
                    variant="contained"
                    // disabled={}
                    style={{float:"right",marginLeft:"15px",marginRight:"10px"}}
                    onClick={()=>setDefault()}
                  // {text('ContainedButton Label', 'hover over me')}
                  >
                    Set To Default
                  </RACButton>
                  <RACButton
                    type="button"
                    color="primary"
                    variant="outlined"
                     style={{float:"right"}}
                     className={`${classes.spacerMR2} ${classes.setDefault1}`}
                     onClick={()=> cancelClick()}
                   
                  >
                  Cancel
                  </RACButton>


                  </Grid>
                    
        </>
     )
    }
    return(
        <>
        <Grid >
         <RACModalCard
        isOpen={props.isOpen}
        maxWidth="sm"
        title ="Adjust NSF Check Payment"
        TitleVariantClassName={classes.popUpFont}
        borderRadius={ModalRadius}
        onClose={()=> cancelClick()}
        closeIcon={true}
        children={popUpnsf()}
        />
        </Grid>
        </>
        
    )
 }
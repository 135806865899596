import React from 'react';
import { createContext, useState } from 'react';
export const StateDataContext = createContext<any>('');
export function StateContext(props: any) {
  const [statedata, setstatedata] = useState();
  return (
    <div>
      <StateDataContext.Provider value={{ statedata, setstatedata }}>
        {props.children}
      </StateDataContext.Provider>
    </div>
  );
}

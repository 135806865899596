/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-undef */

/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */




/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prefer-const */





import React from 'react';
import { createContext, useState } from 'react';
export const CustomerdataContext = createContext<any>('');
export function CustomerContext(props: any) {
  const [customerdata, setcustomerdata] = useState();
  const [commitmentPayTringle, setCommitmentPayTringle] = useState();
  const [paymentFunctionFlag, setpaymentFunctionFlag] = useState({
    payFunc: "1",
    isCashSale: "1",
    convFeeWithOutTax: "0",
    reversePayment: 0
  });
  const [isExchangeEnabled, setExchangeEnabled] = useState<boolean>(false);
  const [exchangeNavigationPopup, setExchangeNavigationPopup] = useState<boolean>(false);
  const [exchangePeriod, setExchangePeriod] = useState<any>('');
  const [returnPeriod, setReturnPeriod] = useState<any>('');
  let [StoreExceptionPayload, setStoreExceptionPayload] = useState<any>([]);
  const [approverId, setApproverId] = useState<any>('');
  return (
    <div>
      <CustomerdataContext.Provider value={{ customerdata, setcustomerdata, paymentFunctionFlag, setpaymentFunctionFlag, commitmentPayTringle, setCommitmentPayTringle, isExchangeEnabled, setExchangeEnabled, exchangeNavigationPopup, setExchangeNavigationPopup, exchangePeriod, setExchangePeriod, returnPeriod, setReturnPeriod, StoreExceptionPayload, setStoreExceptionPayload, approverId, setApproverId }}>
        {props.children}
      </CustomerdataContext.Provider>
    </div>
  );
}

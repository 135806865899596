/*eslint-disable react-hooks/rules-of-hooks*/
/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable sonarjs/no-collapsible-if*/
/**
 * PS_AC_1
 * imports
 */
import { calculateTotalDueAmount } from './calculateTotalDueAmount';
import { AgreementDetails, Customer } from '../interface/paymentInfoInterface';
import {
  totalAmountInterface,
  tenderDetailsCalculation,
} from '../interface/utilsInterface/amountCaculationInterface';
import * as interfaceType from '../interface/contextInterface';
import CONSTANTS from '../constants/constant';

/**
 * PS_AC_3
 * This function handles the amount calculations
 * @param agreementInfo
 * @returns an object with coa, webLead, carry rent, ipsuspense, tenderAmount, remaining amount and total amount.
 * PS_AC_43
 */
export const calculateRemainingAmountDue = (
  agreementInfo: interfaceType.AgreementContextValue,
  cardValidationCheck?: boolean
) => {
  const totalAmountOfAgreements: string[] = [];
  const onlySelectedAgreements = agreementInfo.agreementDetails.filter(
    (agreement: AgreementDetails) => agreement.selected
  );

  for (let i = 0; i <= onlySelectedAgreements.length - 1; i++) {
    const amount: string | number = calculateTotalDueAmount(
      onlySelectedAgreements[i],
      agreementInfo.customerOrderPayment
    );

    totalAmountOfAgreements.push(amount.toString());
  }

  const filteredArray = totalAmountOfAgreements.filter(
    (item: string) =>
      !item.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
      !item.includes(CONSTANTS.CLOSE_BRACKET_STRING)
  );
  const amountNeedtoChange = totalAmountOfAgreements.filter(
    (item: string) =>
      item.includes(CONSTANTS.OPEN_BRACKET_STRING) &&
      item.includes(CONSTANTS.CLOSE_BRACKET_STRING)
  );
  const extractedNumbers = amountNeedtoChange.map((str: string) =>
    parseFloat(str.replace(CONSTANTS.NON_NUMERIC_CHAR, CONSTANTS.EMPTY_STRING))
  );
  const totAmountForChange = extractedNumbers.reduce(
    (acc: number, amount) => acc + Number(amount),
    CONSTANTS.ZERO_NUMBER
  );
  const totAmount = filteredArray.reduce(
    (acc: number, amount: string) => acc + Number(amount),
    CONSTANTS.ZERO_NUMBER
  );

  const customerReturnAmount = totAmount - totAmountForChange;

  const tranferCoaAmount = agreementInfo.customerInfo.finalTransferCOA
    ? Number(Number(agreementInfo.customerInfo.finalTransferCOA).toFixed(2))
    : CONSTANTS.ZERO_NUMBER;

  const totalAmount = customerReturnAmount - tranferCoaAmount;

  const { surrenderAmount, depositAmount } = calculateRefundSecurityDeposit(
    agreementInfo.customerInfo
  );

  const totalRsdRefundAmount = totalAmount - surrenderAmount + depositAmount;

  const amountWithDonation =
    totalRsdRefundAmount + Number(agreementInfo.customerInfo.donation);
  const usedCredits = useAvailableCredits(agreementInfo, amountWithDonation);

  const amountWithoutCoa =
    usedCredits.coa > CONSTANTS.ZERO_NUMBER
      ? totalRsdRefundAmount - usedCredits.coa
      : totalRsdRefundAmount;
  const amountWithoutweb =
    usedCredits.webLeadUsed > CONSTANTS.ZERO_NUMBER
      ? amountWithoutCoa - usedCredits.webLeadUsed
      : amountWithoutCoa;
  const amountWithoutIp =
    usedCredits.ipSuspense > CONSTANTS.ZERO_NUMBER
      ? amountWithoutweb - usedCredits.ipSuspense
      : amountWithoutweb;

  const amountWithoutDonation =
    amountWithoutIp - Number(agreementInfo.carryRent);
  const finalTotalAmount =
    amountWithoutDonation + Number(agreementInfo.customerInfo.donation);
  usedCredits.totalAmount = Number(finalTotalAmount.toFixed(2));

  let totalSuspense: number = CONSTANTS.ZERO_NUMBER;

  if (agreementInfo.customerOrderPayment === CONSTANTS.IS_CO_INITIAL) {
    if (usedCredits.remainingAmount > usedCredits.coPaymentAmount) {
      usedCredits.remainingAmount -= usedCredits.coPaymentAmount;
      usedCredits.totalAmount -= usedCredits.coPaymentAmount;
    } else if (usedCredits.remainingAmount < usedCredits.coPaymentAmount) {
      totalSuspense = usedCredits.coPaymentAmount - usedCredits.remainingAmount;
      usedCredits.remainingAmount = CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
      usedCredits.totalAmount = CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
    } else if (usedCredits.remainingAmount == usedCredits.coPaymentAmount) {
      usedCredits.remainingAmount = CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
      usedCredits.totalAmount = CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
    }
  }
  let totAmountforTender = CONSTANTS.ZERO_NUMBER;

  if (!cardValidationCheck) {
    const totalTenderAmount = tenderDetails(agreementInfo);

    totAmountforTender = totalTenderAmount.reduce(
      (acc: number, tenderAmount) => acc + Number(tenderAmount.amount),
      CONSTANTS.ZERO_NUMBER
    );
  } else {
    const totalTenderAmount = tenderDetails(agreementInfo, '', true);

    totAmountforTender = totalTenderAmount.reduce(
      (acc: number, tenderAmount) => acc + Number(tenderAmount.amount),
      CONSTANTS.ZERO_NUMBER
    );
  }

  usedCredits.remainingAmount -= totAmountforTender;
  usedCredits.totalAmount -= totAmountforTender;

  usedCredits.suspenseAdd =
    totalSuspense <= CONSTANTS.ZERO_NUMBER
      ? CONSTANTS.ZERO_NUMBER
      : totalSuspense;
  usedCredits.remainingAmount =
    usedCredits.remainingAmount <= CONSTANTS.ZERO_NUMBER
      ? CONSTANTS.ZERO_NUMBER
      : usedCredits.remainingAmount;
  return usedCredits;
};

/**
 * PS_AC_6
 * This function handles the amount calculation based on available credits
 * @param agreementInfo
 * @param totalDueAmount
 * @returns
 * PS_AC_36
 */
export const useAvailableCredits = (
  agreementInfo: interfaceType.AgreementContextValue,
  totalDueAmount: number
) => {
  let remainingAmount: number = totalDueAmount;
  let webLead: number = CONSTANTS.ZERO_NUMBER;
  let coa: number = CONSTANTS.ZERO_NUMBER;
  let ipSuspense: number = CONSTANTS.ZERO_NUMBER;
  let webLeadUsed: number = CONSTANTS.ZERO_NUMBER;
  let coaUsed: number = CONSTANTS.ZERO_NUMBER;
  const carryRent: number = CONSTANTS.ZERO_NUMBER;
  let ipSuspenseUsed: number = CONSTANTS.ZERO_NUMBER;
  const totalAmount: number = CONSTANTS.ZERO_NUMBER;
  const suspenseAdd: number = CONSTANTS.ZERO_NUMBER;

  agreementInfo?.customerInfo?.remainingCredits?.map((values) => {
    if (values.storeNumber == sessionStorage.getItem(CONSTANTS.STORE_NUMBER)) {
      if (values.bucket == CONSTANTS.WEB_LEAD_DEPOSIT) {
        webLead += Number(values.accountBalance);
      } else if (values.bucket == CONSTANTS.CAPITAL_COA) {
        coa += Number(values.accountBalance);
      } else if (values.bucket == CONSTANTS.IP_SUSPENSE) {
        ipSuspense += Number(values.accountBalance);
      }
    }
  });

  remainingAmount =
    Number(remainingAmount) +
    Number(agreementInfo.customerInfo.amountUsed.convenienceFee);
  if (!agreementInfo.multiStoreSelected) {
    if (
      remainingAmount > CONSTANTS.ZERO_NUMBER &&
      webLead > CONSTANTS.ZERO_NUMBER
    ) {
      remainingAmount = remainingAmount - webLead;
      if (Number(remainingAmount) >= Number(CONSTANTS.ZERO_NUMBER)) {
        webLeadUsed += Number(webLead);
      } else {
        webLeadUsed += Number(webLead) + Number(remainingAmount);
      }
    }
  }

  if (
    agreementInfo.customerOrderPayment == CONSTANTS.EMPTY_STRING &&
    !agreementInfo.multiStoreSelected
  ) {
    if (
      remainingAmount > CONSTANTS.ZERO_NUMBER &&
      ipSuspense > CONSTANTS.ZERO_NUMBER
    ) {
      remainingAmount = remainingAmount - ipSuspense;
      if (Number(remainingAmount) >= Number(CONSTANTS.ZERO_NUMBER)) {
        ipSuspenseUsed += Number(ipSuspense);
      } else {
        ipSuspenseUsed += Number(ipSuspense) + Number(remainingAmount);
      }
    }
  }

  if (
    agreementInfo.customerOrderPayment == CONSTANTS.EMPTY_STRING &&
    !agreementInfo.multiStoreSelected
  ) {
    remainingAmount = remainingAmount - coa;
    if (Number(remainingAmount) >= Number(CONSTANTS.ZERO_NUMBER)) {
      coaUsed += Number(coa);
    } else {
      coaUsed += Number(coa) + Number(remainingAmount);
    }
  }

  const coAmount = Number(
    agreementInfo.agreementDetails[0].agreementRateDetails.coPaymentAmount
  )
    ? Number(
        agreementInfo.agreementDetails[0].agreementRateDetails.coPaymentAmount
      )
    : CONSTANTS.ZERO_NUMBER;

  const coPaymentAmount = coAmount - webLead;

  if (
    remainingAmount > CONSTANTS.ZERO_NUMBER &&
    parseFloat(agreementInfo.carryRent) > CONSTANTS.ZERO_NUMBER
  ) {
    remainingAmount = remainingAmount - parseFloat(agreementInfo.carryRent);
  }

  if (agreementInfo.customerOrderPayment !== CONSTANTS.EMPTY_STRING) {
    coaUsed = CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
    coa = CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
    ipSuspenseUsed = CONSTANTS.ZERO_TWO_DECIMAL_NUMBER;
  }

  if (agreementInfo.multiStoreSelected) {
    coa = CONSTANTS.ZERO_NUMBER;
  }

  return {
    remainingAmount:
      remainingAmount > CONSTANTS.ZERO_NUMBER
        ? remainingAmount
        : CONSTANTS.ZERO_NUMBER,
    webLeadUsed: webLeadUsed,
    coaUsed: Number(
      (coaUsed <= CONSTANTS.ZERO_NUMBER
        ? CONSTANTS.ZERO_TWO_DECIMAL_NUMBER
        : coaUsed
      ).toFixed(2)
    ),
    coa: coa,
    carryRent: carryRent,
    ipSuspense: ipSuspenseUsed,
    totalAmount: totalAmount,
    coPaymentAmount:
      coPaymentAmount > CONSTANTS.ZERO_NUMBER
        ? coPaymentAmount
        : CONSTANTS.ZERO_NUMBER,
    suspenseAdd: suspenseAdd,
  };
};

/**
 * PS_AC_45
 * @params agreementInfo
 * @returns an object containing change and remainingAmount.
 * PS_AC_52
 */
export const calculateChangeAmount = (
  agreementInfo: interfaceType.AgreementContextValue
) => {
  const totalAmount: totalAmountInterface =
    calculateRemainingAmountDue(agreementInfo);
  let change: number;
  if (Number(totalAmount.totalAmount) < CONSTANTS.ZERO_NUMBER) {
    if (agreementInfo.paymentOrigin == CONSTANTS.IN_PHONE_VALUE) {
      change = Math.abs(
        Math.abs(totalAmount.totalAmount) -
          Number(agreementInfo.customerInfo.amountUsed.convenienceFee)
      );
    } else {
      change = Math.abs(totalAmount.totalAmount);
    }
  } else {
    change = CONSTANTS.ZERO_NUMBER;
  }

  return {
    remainingAmountDue: totalAmount.remainingAmount.toFixed(2),
    change: change.toFixed(2),
  };
};

/**
 * PS_AC_53
 * This function modify the tender details array's into single array of objects
 * @param agreementInfo
 * @returns the object of all tender detials
 * PS_AC_60
 */
export const tenderDetails = (
  agreementInfo: interfaceType.AgreementContextValue,
  onlyCheckMo?: string,
  onlyForCard?: boolean
) => {
  const TenderDetails: tenderDetailsCalculation[] = [];
  const TenderDetailsForCheck: tenderDetailsCalculation[] = [];
  const TenderDetailsForCard: tenderDetailsCalculation[] = [];

  if (
    parseFloat(
      agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.amount
    ) > CONSTANTS.ZERO_NUMBER
  ) {
    TenderDetails.push({
      tenderType: CONSTANTS.CAPITAL_CASH,
      amount:
        agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.amount,
      orderId:
        agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.orderId,
    });
    TenderDetailsForCard.push({
      tenderType: CONSTANTS.CAPITAL_CASH,
      amount:
        agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.amount,
      orderId:
        agreementInfo?.customerInfo?.amountUsed?.tenderDetails.cash.orderId,
    });
  }

  agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.card.map((val) => {
    if (
      val.type == CONSTANTS.SWIPE &&
      Number(val.amount) > CONSTANTS.ZERO_NUMBER
    ) {
      TenderDetails.push({
        tenderType: `${CONSTANTS.CARD_SWIPE_ENDING} - ${val.cardLastFour}`,
        amount: val.amount,
        orderId: val.orderId,
      });
    } else if (Number(val.amount) > CONSTANTS.ZERO_NUMBER) {
      TenderDetails.push({
        tenderType: `${CONSTANTS.CARD_ENDING} - ${val.cardLastFour}`,
        amount: val.amount,
        orderId: val.orderId,
      });
    }
  });

  agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.card.map((val) => {
    if (
      (val.type == CONSTANTS.SWIPE || val.type == CONSTANTS.CHARGE) &&
      Number(val.amount) > CONSTANTS.ZERO_NUMBER
    ) {
      TenderDetailsForCard.push({
        tenderType: `${CONSTANTS.CARD_SWIPE_ENDING} - ${val.cardLastFour}`,
        amount: val.amount,
        orderId: val.orderId,
      });
    }
  });

  agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.checkDetails.map(
    (val) => {
      TenderDetails.push({
        tenderType: CONSTANTS.CHECK,
        amount: val.amount,
        number: val.checkNumber,
        orderId: val.orderId,
      });
      TenderDetailsForCheck.push({
        tenderType: CONSTANTS.CHECK,
        amount: val.amount,
        orderId: val.orderId,
        typeOfCheck: val.typeOfCheck,
      });
      TenderDetailsForCard.push({
        tenderType: CONSTANTS.CHECK,
        amount: val.amount,
        orderId: val.orderId,
      });
    }
  );

  agreementInfo?.customerInfo?.amountUsed?.tenderDetails?.moneyOrderDetails.map(
    (val) => {
      TenderDetails.push({
        tenderType: CONSTANTS.MONEY_ORDER,
        amount: val.amount,
        number: val.moneyOrderNumber,
        orderId: val.orderId,
      });
      TenderDetailsForCheck.push({
        tenderType: CONSTANTS.MONEY_ORDER,
        amount: val.amount,
        orderId: val.orderId,
      });
      TenderDetailsForCard.push({
        tenderType: CONSTANTS.MONEY_ORDER,
        amount: val.amount,
        orderId: val.orderId,
      });
    }
  );

  TenderDetails.sort((a, b) => a.orderId - b.orderId);
  return onlyCheckMo == CONSTANTS.CHECK
    ? TenderDetailsForCheck
    : onlyForCard
    ? TenderDetailsForCard
    : TenderDetails;
};

export function resetTenderTypes(
  agreementInfo: interfaceType.AgreementContextValue
) {
  const selectedAgreements = agreementInfo.agreementDetails.filter(
    (agreement) => agreement.selected
  );

  const totalTenderDetails = tenderDetails(agreementInfo);

  const totalAmountOnTender = totalTenderDetails.reduce(
    (acc: number, tenderAmount) => acc + Number(tenderAmount.amount),
    CONSTANTS.ZERO_NUMBER
  );

  const remainingAmountDue =
    Number(agreementInfo.customerInfo.amountUsed.remainingAmountDue) +
    totalAmountOnTender;

  const changeAmount = calculateChangeAmount(agreementInfo);

  const finalAmount =
    Number(Number(remainingAmountDue).toFixed(2)) -
    Number(Number(changeAmount.change).toFixed(2));

  let reRender = false;

  if (
    Number(finalAmount.toFixed(2)) > totalAmountOnTender ||
    selectedAgreements.length == 0 ||
    agreementInfo.carryRent !== CONSTANTS.ZREO_TWO_DECIMAL_STRING
  ) {
    reRender = true;
  }

  return reRender;
}

export function calculateRefundSecurityDeposit(customerInfo: Customer) {
  const amountToRefundArray = customerInfo?.rsdAgreementInfo?.map((item) =>
    parseFloat(item.depositAmount).toFixed(2)
  );
  const amountToCollectArray = customerInfo.rsdAgreementInfo?.map((item) =>
    parseFloat(item.amountToCollect).toFixed(2)
  );

  const totalRsdAmount = amountToRefundArray.reduce(
    (acc: number, curr: string) => acc + parseFloat(curr),
    CONSTANTS.ZERO_NUMBER
  );

  const totalAmountToCollect = amountToCollectArray.reduce(
    (acc: number, curr: string) => acc + parseFloat(curr),
    CONSTANTS.ZERO_NUMBER
  );

  let surrenderAmount = CONSTANTS.ZERO_NUMBER;
  let depositAmount = CONSTANTS.ZERO_NUMBER;
  if (totalAmountToCollect <= totalRsdAmount) {
    surrenderAmount = totalRsdAmount - totalAmountToCollect;
  } else if (totalAmountToCollect > totalRsdAmount) {
    depositAmount = totalAmountToCollect - totalRsdAmount;
  }

  return { surrenderAmount, depositAmount };
}

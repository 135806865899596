/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */
/* eslint-disable no-case-declarations  */
/* eslint-disable react/no-children-prop  */


/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */



/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable sonarjs/prefer-single-boolean-return */

import { RACTable, TableCell, TableRow } from '@rentacenter/racstrap'
import {useEffect,useState} from 'react'
import React from 'react';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent,
    RACButton,
    RACModalCard,
    RACSelect,
    RACTextbox,
    ListItem,
    List,
    RACCheckBox,
    CircularProgress,
    RACRadio,
    RACInfoIcon,
    TextareaAutosize,
    RACTableCell,
    RACTableRow,
    // LinearProgress

} from '@rentacenter/racstrap';
import { ReactComponent as SearchImageDefault } from '../../assets/images/search-btn.svg';
import { getSearchParams } from '../../api/user';
const useClasses = makeStyles((theme: any) => ({
    borderRed: {
        border: '1px solid red',
        borderRadius: '5px'
    },
    mtop30:{
        marginTop:'30px',
    },
    mtop20:{
        marginTop:'20px',
    },
    w100: {
        width: '100%',
    },
    loaderStyle: {
        border: 'none',
        backgroundColor: 'transparent',
        // userFocus: 'none',
        // MozUserFocus: 'none',
        outline: 'none',
    },
    RACLoaderPage: {
        textAlign: 'center',
        marginTop: '250px',
        fontSize: '16px',
        marginLeft: '30px',
        marginRight: '30px',
        outline: 'none',
    },
    card: {
        borderRadius: '16px!important',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
        width: '100%',
    },
    title: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '25px!important',
        marginBottom: '10px!important',
    },

   

    title1: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '20px!important',
        marginBottom: '10px!important',
    },
    titleColor: {
        color: 'black',
        marginLeft: '10px',
        marginTop: '15px',
    },
    titleColor1: {
        color: 'black',
        marginLeft: '3px',
        marginTop: '14px',
    },
    infoTextStyle: {
        color: 'gray',
        fontSize: theme.typography.pxToRem(13),
        textAlign: 'center',
    },
    my4: {
        marginTop: theme.typography.pxToRem(24),
        marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
        marginBottom: theme.typography.pxToRem(48),
    },
    pt2: {
        paddingTop: theme.typography.pxToRem(8),
    },
    px2: {
        paddingRight: theme.typography.pxToRem(8),
        paddingLeft: theme.typography.pxToRem(8),
    },
    cardHeight: {
        height: ' 5.9375rem',
    },
    cardHeight1: {
        height: 'auto',
        marginTop: '26px',
    },
    headtag: {
        marginTop: '17px',
        marginLeft: '19px',
    },
    fixedFooter: {
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.typography.pxToRem(16),
        backgroundColor: '#ffffff',
        boxShadow: '0 0 3px #d9d9d9',
    },
    ml3: {
        marginLeft: theme.typography.pxToRem(16),
    },
    p2: {
        padding: theme.typography.pxToRem(8),
    },
    modalBody: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '1rem',
        width: '98%'
    },
    modalContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        pointerEvents: 'auto',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        outline: '0',
    },
    me2: {
        marginRight: '0.5rem !important',
        marginLeft: '10rem',
    },
    floatStart: {
        float: 'left',
    },
    floatStart1: {
        float: 'left',
        '& input': {
            height: '24px'
        },
    },
    mb3: {
        marginBottom: theme.typography.pxToRem(16),
    },
    formLabel: {
        marginBottom: '0.5rem',
        // color: '#111111',
        color:"black",
        //eslint-disable-next-line sonarjs/no-duplicate-string
        fontFamily: 'OpenSans-bold',
        fontSize: '14px',
        marginLeft: '0px',
    },
    formLabelN: {
        marginBottom: '0.5rem',
        // color: '#111111',
        // paddingBottom:"20px",
        color:"black",
        //eslint-disable-next-line sonarjs/no-duplicate-string
        fontFamily: 'OpenSans-bold',
        fontSize: '14px',
        marginLeft: '0px',
    },
    textCenter: {
        textAlign: 'center',
    },
    px3: {
        paddingRight: '1rem !important',
        paddingLeft: '1rem !important',
    },
    pb4: {
        paddingBottom: '1.5rem !important',
    },
    colMd12: {
        flex: '0 0 auto',
        width: '100%',
    },
    center: {
        textAlign: 'center',
        margin: '12px',
    },
    right: {
        marginRight: '10px',
    },
    right1: {
        marginRight: '15px',
    },
    equal: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
    },
    equal1: {
        width: '55%',
        marginRight: '15px',
        marginBottom: '15px',
        paddingBottom: '25px'
    },
    leftpadding: {
        paddingLeft: '9.5rem',
    },
    heading: {
        fontSize: '20px',
    },
    tittles: {
        marginTop: '10px',
        marginBottom: '7px',
    },
    tittle1: {
        marginTop: '10px',
        marginBottom: '7px',
        // padding: '10px !important'
        '& input': {
            padding: '10px !important'
        },
    },
    dropdown1: {
        '& div': {
            // float:"left",
            padding: '10px 9px',
            // color:"black",
            width:'200px !important',
            borderRadius:"8px"
            // marginLeft:"0px"
        }
    },
    tittles1: {
        marginTop: '10px',
        marginBottom: '7px'
    },
    textstyle: {
        marginRight: '10px',
        marginBottom: '25px',
    },
    textstyle1: {
        marginRight: '25px',
        marginTop: '25px',
    },
    dollartext1: {
        width: '87% !important',
        '& input': {
            height: '21px'
        },
        // width: '13% !important',
        // padding: '6px 14px !important',
        // marginBottom: '25px !important',
    },
    dollartext3:{
        width:'40% !important   ',
        
        '& input':{
            height:'25px'
            
        }
    },
    dollartextN:{
        width:'110% !important   ',
        
        // marginRight:'10%!important',
        
        '& input':{
            height:'17px',
            borderRadius:"5px !important",
            
        }
    },
    // dollartext2:{
    //     width: '80% !important',
    //     '& input': {
    //         height: '21px'
    //     },

    // },
    marginTop: {
        marginTop: '5px',
    },
    textcolor: {
        color: 'blue',
    },
    cardborder: {
        border: '0 !important',
    },
    BreadCrumbInactive: {
        '&:after': {
            content: "''",
            width: 0,
            height: 0,
            borderTop: '5px solid transparent',
            borderLeft: '10px solid #555',
            borderBottom: '5px solid transparent',
        },
    },
    marginBottom: {
        marginBottom: '10px !important',
    },
    marginBottom1: {
        marginBottom: '10px !important',
        '& input': {
            padding: '10px'
        },
    },
    marginright: {
        marginRight: '20px',
    },
    typeMargin: {
        padding: '1px',
        '& input': {
            height: '24px'
        },
    },
    screenTop: {
        marginTop: '22px'
    },
    imgStyle: {
        paddingBottom: '10px'
    },
    outlinedBtn: {
        padding: '0.7rem 2rem',
    },
    zipCodeClass: {
        '& div': {
            '& div': {
                '& div': {
                    '& input': {
                        padding: '10px'
                    }
                }
            }
        }
    },
    marginCustName: {
        marginTop: '10px',
        color: '#2179FE'
    },
    marginCustID: {
        marginTop: '10px',
        color: 'gray'
    },
    paddingCustname: {
        padding: '10px'
    },
    customerToolbar: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        paddingLeft: theme.typography.pxToRem(16),
        paddingRight: theme.typography.pxToRem(16),
        paddingTop: theme.typography.pxToRem(16),
        paddingBottom: theme.typography.pxToRem(16),
    },
    fixedBottom: {
        position: 'fixed',
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1030,
        backgroundColor: 'white',
        boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
        padding: '1rem',
    },
    floatLeft: {
        float: 'left',
    },
    floatRight: {
        float: 'right',
        marginTop:'30px'
    },
    me1: {
        marginRight: theme.typography.pxToRem(4),
    },
    foc: {
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '1px 1px 6px 3px #80bdff',
        },
    },
    racFixedFooterMargin: {
        marginBottom: '120px',
    },
    spacerMT4: {
        marginTop: theme.typography.pxToRem(24),
    },
    RACPOPMsg: {
        fontFamily: 'OpenSans-bold',
        fontSize: theme.typography.pxToRem(16),
        letterSpacing: 0,
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    formLabelInline: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        display: 'inline-block',
        paddingLeft: 0,
    },
    custDetailSection: {
        display: 'flex',
        marginLeft: '20px',
        width: '50%',
        justifyContent: 'space-around'
    },
    racInfoStyle: {
        marginLeft: '5px',
        position: 'absolute'
    },
    textArea:{
        margin: "10px 0px",
        width:1000 ,
        padding:"6PX", 
        borderRadius: "4px",
        resize: "none" 

    },
    description:{
        margin:"0px 0px 20px 0px"
    },
    description1:{
        margin:"0px 0px 50px 0px"
    },
     modalcard:{
        height:"50%"

    },
    tablestyle:{
        "& th": {
            fontFamily: "OpenSans-semibold",
            // float:"left",
            fontWeight:"bold",
            whiteSpace: "nowrap",
            // marginTop:"-20px",
            // padding: "0px 50px 20px 0px",

            
          },
          "& td": {
            float:"left",
            // padding: "0px 60px 0px 0px",
            color: "black",
            
            // fontSize:"bold",
          }
    },
    tableStyle:{
        "& thead": {backgroundColor:"white",},
"& tbody": {
"& tr":{backgroundColor:"white ",
"& td":{fontWeight:"bold"
},
},
},

        
    },
    grid1:{
        marginTop:"10px",
        marginRight:"5%"
    },
    parag:{
        padding:"-10px"

    },
    btnmargin:{
        marginLeft:"5px"
    },
    textright:{
        paddingLeft:"50px"
    },
    textright1:{
        paddingLeft:"150px"
    },
    textright2:{
        paddingLeft:"110px"
    },
    textBoxcell:{
        // paddingLeft:"40px"
        // float:"right"
    },
    header2:{
        fontWeight:"bolder",
        fontSize:"10%"
    },
    mt2: {
        marginTop: theme.typography.pxToRem(8),
      },
      selectBoxFont:{
        '& div':{
            width:'90% !important   ',
        
            height:"25px",
          fontFamily: 'OpenSans-semibold',            
      }
      }
}));
import { customerStyles } from '../../stylesJS/customerStyles';
import { GlobalStyles } from '../../stylesJS/global'

export default function Criteriasearch (props:any)
{ 
     const classes1=useClasses();
     const classes = customerStyles();
     const classes2: any = GlobalStyles();
    
     
   /* RIS_PC_NO_05 searchbtnFlag is declared for enabling and disabling the search button
      RIS_PC_NO_06  Creating iniTxValues object and assigning it to the state variable TxtValues
      and creating a state variable conditionLoader */


    //  const iniTxtValues={"storeNumbers": "",
    // "itemNumber":"",
    // "serialNumber": "",
    // "bracket":"",
    // "brand":"",
    // "modelNumber":"",
    // "poNumber": null,
    // "statusId": 1,
    // "subStatusId": [2],
    // "conditionId":'',
    // "department": "",
    // "subDepartment": "",
    // "setNumber": 0,
    // "isActivePriceTag": true

    //  }

    //  const [TxtValues,setTxtValues]=useState(iniTxtValues);
    const {TxtValues,setTxtValues}:any=props
     const [searchbtnFlag,setsearchbtnFlag]=useState(true);
     const [dropdownArr,setdropdownArr]=useState<any>([{label:'',value:''}])
     const condition=[{label:"Auto",value:"ATO"},
                      {label:"Exception",value:"EXCP"},
                      {label:"New",value:"NEW"},
                     ]
    const [conditionLoader,setconditionLoader]=useState(true)
    
    /*RIS_PC_NO_07 Inside the the loaddata() is called  */

     useEffect(()=>{
        loaddata();
     },[])

     /**RIS_PC_NO_11 The useEffect is performed when the TxtValues is updated and it checks 
      * whether the value is entered in the search criteria based on that the search button will be 
      * disabled or enabled.
      * 
      */

     useEffect(()=>{
        if(TxtValues.itemNumber!==""||TxtValues.modelNumber!==""||TxtValues.serialNumber!==""||
           TxtValues.conditionId!=="")
        {
            setsearchbtnFlag(false)
        }else{
            setsearchbtnFlag(true)
        }
     },[TxtValues])
     /**
      * RIS_PC_NO_07 The loaddata() function is performed and inside it getsearchParam() service call is made
      * RIS_PC_NO_08 The service call is performed and the data is fetched
      * RIS_PC_NO_09 By using the response received the dropdown array is updated.
      * 
      */
     const loaddata=async()=>{
        debugger
        setconditionLoader(true)
        const response=await getSearchParams();
        setconditionLoader(false)
        let condition=response.conditionList;
        let condArr=[{label:"Select",value:''}]
        condition.map((val)=>{
            if(val.referenceCode=="ATO")
            {let data={label:"Auto",value:val.id.toString()}
          
            condArr.push(data)

            }else if(val.referenceCode=="EXCP")
            {
                let data={label:"Exception",value:val.id.toString()}
            
            condArr.push(data)
            }else if(val.referenceCode=="NEW")
            {
                let data={label:"New",value:val.id.toString()}
                
                condArr.push(data)

            }
            
        })
        setdropdownArr(condArr);
     }
     
     /**
      * RIS_PC_NO_10 When the values entered in the text fields the searchcriteriahandlechange function is 
      * performed.
      *  
      */

     const searchcriteriahandlechange=(val:any,name:any)=>{
        switch(name)
        {
            case "itemNumber":
                setTxtValues({...TxtValues,"itemNumber":val.target.value})
                break;
            case "modelNumber":
                setTxtValues({...TxtValues,"modelNumber":val.target.value})
                break;
            case "serialNumber":
                setTxtValues({...TxtValues,"serialNumber":val.target.value})
                break;
            case "condition":
                setTxtValues({...TxtValues,"conditionId":val.target.value})
            
        }

     }
     const EnterOnClick = (e: any) => {
        if (
          e.key == 'Enter' &&
          (
            TxtValues.modelNumber.length > 0 ||
            TxtValues.itemNumber.length > 0 ||          
            TxtValues.serialNumber.length > 0 ||
            TxtValues.conditionId !== "" 
           
           )
        ) {
            props.searchclick(TxtValues)
        }
      };
     /**
      * RIS_PC_NO_12 when co-worker clicks the search button the searchbtnOnclick 
      * function is performed.
      */
    
     const searchbtnOnclick=()=>{
        debugger;
    // (document.getElementById('ItemNo') as HTMLInputElement).blur();  
    // (document.getElementById('ModelNo') as HTMLInputElement).blur();  
    // (document.getElementById('serialNo') as HTMLInputElement).blur();
    props.searchclick(TxtValues)
     }

    //RIS_PC_NO_05 Rendering the Search criteria, Item # text box ,Model text box , Serial # text box
    //  and condition dropdown
    return(
        
        <React.Fragment>
      <Grid container className={classes2.mt2} style={{marginTop:"0px"}}>
        <Grid item md={12}>
        <Typography
             variant="h1"
             style={{marginLeft:"2px"}}
             className={`${classes1.title1} ${classes2.mt3}`}>
                                    Search Criteria
                                </Typography>

          <Card className={classes1.card}>
            <CardContent>
              <Grid container spacing={2}>
                {/* <Grid item md={2}></Grid> */}
         {/* <RACTable
        className={classes1.tableStyle} 
        // renderTableHead = {tableheader}
        renderTableContent= {tableContent}
        /> */}
         <Grid item md={2} style={{marginLeft:"1%"}}>
                  <RACTextbox
                    inputlabel="Item #"
                    inputLabelClassname={`${classes1.formLabel }`}
                    className={`${classes1.dollartextN}`}
                    type="number"
                    id="IemNo"
                    name="itemNumber"
                    isCurrency={false}
                    maxlength={50}
                    value={TxtValues.itemNumber}
                    OnChange={(e) => searchcriteriahandlechange(e,"itemNumber")}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2} style={{marginLeft:"2%"}}>
                  <RACTextbox
                    inputlabel="Model"
                    inputLabelClassname={classes1.formLabel}
                    className={`${classes1.dollartextN}`}
                    type="text"
                    id="ModelNo"
                    name="modelNumber"
                    isCurrency={false}
                    maxlength={50}
                    value={TxtValues.modelNumber}
                    OnChange={(e) => searchcriteriahandlechange(e,"modelNumber")}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2} style={{marginLeft:"2%"}}>
                  <RACTextbox
                    inputlabel="Serial #"
                    inputLabelClassname={classes1.formLabel}
                    className={`${classes1.dollartextN}`}
                    type="text"
                    id="serialNo"
                    name="serialNumber"
                    isCurrency={false}
                    maxlength={50}
                    value={TxtValues.serialNumber}
                    OnChange={(e) => searchcriteriahandlechange(e,"serialNumber")}
                    OnKeydown={(e) => EnterOnClick(e)}
                  />
                </Grid>
                <Grid item md={2}style={{marginLeft:"2%"}}>
                  <RACSelect
                    // classes={{nativeSelect:classes1.dropdown1}}
                    inputLabel="Condition"
                    inputLabelClassName={classes1.formLabelN}
                    name="condition"
                    loading={conditionLoader}
                    defaultValue={ TxtValues.conditionId }                  
                    onChange={(e:any) => searchcriteriahandlechange(e,"condition")}
                    options={dropdownArr}
                
                    
                   
                    // TxtValues.conditionId !=''?TxtValues.conditionId:
                    //   TxtValues.conditionId !== null
                    //     ? TxtValues.conditionId
                    //     : '0'
                 
                    // {...(dropdownArr.length == 1 &&
                    //   !conditionLoader && {
                    //   errorMessage: 'Unable to fetch Data',
                    // })}
                  />
                </Grid>
              
                <Grid item md={2} style={{marginLeft:"-1%", marginTop:"-12px"}}>
                    <RACButton
                      disabled={searchbtnFlag}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{margin:"38px 0px 0px 40px"}}
                      className={`${classes.spacerMT2N} ${classes.spacerP1N}`}
                      onClick={() =>searchbtnOnclick()}
                    >
                      <SearchImageDefault
                        className={classes.racGlobalSearchBtn}
                      ></SearchImageDefault>
                      Search
                    </RACButton>
                  </Grid>
        </Grid>  
        </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>   

    )

}
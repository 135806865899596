/*eslint-disable */
import { makeStyles } from '@rentacenter/racstrap';

export const alertPopupStyle = () =>{
    const useClasses = makeStyles((theme) => ({
        navLink: {
            transition:
                "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out",
            position: "relative",
            fontFamily: "OpenSans-semibold",
            color: "#000",
        },
        navLinkactive: {
            color: "#2179FE",
            borderBottom: "5px solid #2468FF !important",
            fontFamily: "OpenSans-semibold",
            "&:before": {
                content: "''",
                position: "absolute",
                height: "5px",
                width: "10px",
                background: "#2468ff",
                left: "-5px",
                bottom: "-5px",
                borderTopLeftRadius: "5px",
            },
            "&:after": {
                content: "''",
                position: "absolute",
                height: "5px",
                width: "10px",
                background: "#2468ff",
                right: "-5px",
                bottom: "-5px",
                borderTopRightRadius: "5px",
            },
        },
        dropdowntoggle: {
            display: "inline-block",
            marginLeft: "0.255em",
            verticalAlign: "0.255em",
            content: "",
            borderTop: "0.3em solid",
            borderRight: "0.3em solid transparent",
            borderBottom: "0",
            borderLeft: "0.3em solid transparent",
        },
        dropdownitemcustom: {
            color: "#2468FF",
            fontFamily: "OpenSans-bold",
            backgroundColor: "white",
            textDecoration: "none",
            cursor: "pointer",
        },
        popUp: {
            position: "absolute",
            bottom: "100%",
            backgroundColor: "white",
            width: "200px",
            padding: "15px",
            "& li": {
                listStyle: "none",
            },
        },
        popUpHide: {
            display: "none",
        },
        customMenuContainer: {
            border: "1px solid #2468FF",
        },
        paymentBtnHover: {
            "&:hover": {
                color: "white",
                backgroundColor: "#0d6efd",
                borderColor: "#0d6efd",
            },
        },
    }));
    const classes = useClasses();
    return classes;
}
/* eslint-disable sonarjs/no-duplicate-string */
/*eslint-disable react-hooks/rules-of-hooks*/
import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const epoScheduleStyles = () => {
  const useClasses = makeStyles((theme) => ({
    colorPallet: {
      width: '13px',
      height: '13px',
      borderRadius: '2px',
      float: 'left',
      marginTop: '3px',
    },
    cpBlack: {
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
    },
    cpRed: {
      backgroundColor: `${RACCOLOR.MAXIMUM_RED}`,
    },
    cpPurple: {
      backgroundColor: `${RACCOLOR.DARK_MAGENTA}`,
    },
    cpBlue: {
      backgroundColor: `${RACCOLOR.NORMAL_BLUE}`,
    },
    cpGreen: {
      backgroundColor: `${RACCOLOR.VITAL_GREEN}`,
    },
    tdBlackTxt: {
      color: `${RACCOLOR.WASHED_BLACK}`,
    },
    tdPurpleTxt: {
      color: `${RACCOLOR.DARK_MAGENTA}`,
    },
    tdRedTxt: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
    },
    tdBlueTxt: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
    },
    tdGreenTxt: {
      color: `${RACCOLOR.VITAL_GREEN}`,
    },
    borderBlack: {
      borderLeft: `3px Solid ${RACCOLOR.WASHED_BLACK} !important`,
      // borderColor: `${RACCOLOR.WASHED_BLACK}`,
    },
    borderPurple: {
      // borderColor: `${RACCOLOR.DARK_MAGENTA}`,
      borderLeft: `3px Solid ${RACCOLOR.DARK_MAGENTA} !important`,
    },
    borderRed: {
      // borderColor: `${RACCOLOR.MAXIMUM_RED}`,
      borderLeft: `3px Solid ${RACCOLOR.MAXIMUM_RED} !important`,
    },
    borderBlue: {
      // borderColor: `${RACCOLOR.NORMAL_BLUE}`,
      borderLeft: `3px Solid #2279fd !important`,
    },
    borderGreen: {
      // borderColor: `${RACCOLOR.VITAL_GREEN}`,
      borderLeft: `3px Solid ${RACCOLOR.VITAL_GREEN} !important`,
    },
    racpadAgrLink: {
      // borderLeft: '3px solid transparent',
      paddingLeft: '10px',
      borderRadius: '2px',
    },
    rowColorBlack: {
      backgroundColor: 'lightgray',
    },

    rowColorWhite: {
      backgroundColor: `${RACCOLOR.WHITE}`,
    },
    epoLabel: {
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      color: `${RACCOLOR.WASHED_BLACK}`,
      marginLeft: '5px',
    },

    popupText: {
      fontFamily: 'OpenSans-semibold',
      fontSize: '17px',
    },
    tooltipcss: {
      marginLeft: '4px',

      marginBottom: '-2px',

      top: '0',

      positon: 'absolute',
    },
    rowCss: {
      marginLeft: '31px',
    },
    hide: {
      display: 'none',
    },
  }));

  return useClasses();
};

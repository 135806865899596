/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";
export default function TransferException() {
  const classes = reversePaymentStyle();
  const { openTransferException } = useContext(GeneralStateContext);
  const { setOpenTransferException, setAccordianIndex } = useContext(GeneralStateDispatchContext);
  const buildTransferException = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <Typography className={`${classes.bold}`}>
            This customer has pending agreement transfer.
          </Typography>
          <Typography className={`${classes.bold}`}>
            Please recall the transfer and try again.
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => {setOpenTransferException(false); setAccordianIndex([0]);}}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  return (
    <RACModalCard
      isOpen={openTransferException}
      maxWidth="xs"
      borderRadius={"25px !important"}
      //eslint-disable-next-line react/no-children-prop
      children={buildTransferException()}
    />
  );
}

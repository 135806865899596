/* eslint-disable */

export function cappedFreeTimeValidation(props: any) {
    let freeTimeConfigs = props.cappedFreeTimeValues;
    let freeTimeValues = props.cappedFreeTimeResponseValues;
    let currentAgreementAmount = 0;
    if(props?.usedFreeTimeAmounts?.usedFreeTimeAmounts?.length && props?.receivedCheckedAgr?.length){
        let agreementId = props?.receivedCheckedAgr?.[0].Agreementid;
        const updatedItems: any = [...props?.usedFreeTimeAmounts?.usedFreeTimeAmounts];
        // const index = updatedItems ? updatedItems?.findIndex(item => item.id == agreementId) : -1;
        // if (index !== -1) {
        //     currentAgreementAmount = updatedItems[index].amount;
        // }
        updatedItems?.forEach((item) => {
            item?.id != agreementId ? currentAgreementAmount += parseFloat(item.amount) : null
        });
    }
    let usedFreeTimeAmount = 0;
    let requiredValidation: any = [];

    if (freeTimeConfigs?.cappedFreeTimeRegularFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeRegular || 0;
        requiredValidation.push('FREAMT');
    }

    if (freeTimeConfigs?.cappedFreeTimeServiceFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeService || 0;
        requiredValidation.push('SERVFREETM');
    }

    if (freeTimeConfigs?.cappedFreeTimeRetentionFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeRetention || 0;
        requiredValidation.push('RETEXTAMT');
    }

    if (freeTimeConfigs?.cappedFreeTimeInitPaymentFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimeInitPayment || 0;
        requiredValidation.push('INITPAY');
    }

    if (freeTimeConfigs?.cappedFreeTimePromoFlag) {
        usedFreeTimeAmount += freeTimeValues?.cappedFreeTimePromo || 0;
        requiredValidation.push('PROMO');
    }

    return {
        usedFreeTimeAmount: usedFreeTimeAmount,
        requiredValidation: requiredValidation,
        allowdedFreeAmount: freeTimeValues?.cappedFreeTimeSMRRAmount - currentAgreementAmount,
        remainingAmount : freeTimeValues?.cappedFreeTimeSMRRAmount - currentAgreementAmount - usedFreeTimeAmount
    }
}
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
// import accordianOpenIcon from '../assets/images/Accordion-down.svg';
// import accordianCloseIcon from '../assets/images/Accordion-close.svg';
import completedIcon from "../assets/images/wizard-complete.svg";
export const wizardStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    swThemeDefault: {
      width: "100%",
      position: "relative",
      paddingBottom: "2%",
    },
    swThemeDefaultContainer: {
      flex: "0 0 auto",
      width: "100%",
      background: `${RACCOLOR.WHITE}`,
    },
    smartWizard: {
      display: "inline-block",
      listStyle: "none",
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    smartWizardList: {
      textAlign: "center",
      position: "relative",
      marginRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
      width: "auto",
      display: "inline-block",
      "&:after": {
        borderBottom: "2px solid #cecece",
        content: '""',
        position: "absolute",
        width: "28px",
        right: "-11px",
        top: "50%",
      },
      "&:last-child": {
        "&:after": {
          border: "none",
        },
      },
    },
    smartWizardListLinkActive: {
      textAlign: "center",
      position: "relative",
      marginRight: theme.typography.pxToRem(14),
      width: "auto",
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      cursor: "pointer",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(12),
      textDecoration: "none",
    },
    smartWizardListLinkInActive: {
      textAlign: "center",
      position: "relative",
      marginRight: theme.typography.pxToRem(14),
      width: "auto",
      cursor: "pointer",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(12),
      textDecoration: "none",
      color: "#9a9a9a",
    },
    borderActive: {
      border: "2px solid #d9d9d9",
      padding: "4px 10px",
      margin: "0 5px",
      backgroundColor: `#2179FE`,
      borderColor: `#2179FE`,
      borderRadius: "50px",
      color: `${RACCOLOR.WHITE}`,
    },
    borderDone: {
      border: "2px solid #d9d9d9",
      padding: "4px 4px",
      margin: "0 5px",
      backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderRadius: "50px",
      color: `${RACCOLOR.WHITE}`,
    },
    borderDefault: {
      border: "2px solid #d9d9d9",
      padding: "4px 10px",
      borderRadius: "50px",
      margin: "0 5px",
      color: "#8E8E8E",
    },
    navInactive: {
      textAlign: "center",
      position: "relative",
      marginRight: theme.typography.pxToRem(20),
      width: "auto",
      cursor: "pointer",
      fontFamily: "OpenSans-semibold",
      fontSize: theme.typography.pxToRem(14),
      color: "#999999",
    },
    done: {
      backgroundImage: `url(${completedIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "#5ae286",
      color: "transparent",
      borderColor: "#5ae286",
    },
    stepDone: {
      color: "#000000",
      fontFamily: "OpenSans-bold",
    },
    stepActive: {
      color: "#2179FE",
      fontFamily: "OpenSans-bold",
    },
    stepNotStarted: {
      color: "#8E8E8E",
    },
    swThemeSpaceBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    paddingtop: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    custompadding: {
      padding: "11px 0px 1px 0px",
    },
    margintopwidget: {
      marginTop: "7px",
    },
    paddingTop3: {
      paddingTop: "3px",
    },
    customerName: {
      color: `#2179FE`,
      fontSize: theme.typography.pxToRem(14),
      textDecoration: "none",
      cursor: "pointer",
      fontFamily: "OpenSans-bold",
    },
    coCustomerLabel: {
      display: "block",
      fontSize: "14px",
      color: "#5b6d83",
      fontFamily: "OpenSans-semibold",
      lineHeight: "unset",
    },
    approvalAmt: {
      color: `#2179FE`,
      fontSize: theme.typography.pxToRem(13),
      textDecoration: "none",
      cursor: "pointer",
      fontFamily: "OpenSans-semibold",
    },
    expDate: {
      display: "block",
      padding: "0.1rem 1rem",
      textDecoration: "none",
      fontSize: "14px",
      marginRight: "5px",
      fontFamily: "OpenSans-semibold",
      color: `#5B6D83`,
    },
    racProfileContent: {
      transform: "none",

      top: "100%",

      left: 0,

      border: "none",

      boxShadow: "0px 2px 3px 0px #e0e0e0",

      position: "absolute",

      zIndex: 1000,

      display: "none",

      padding: "0.5rem 0",

      margin: 0,

      color: "#212529",

      textAlign: "left",

      background: `${RACCOLOR.WHITE}`,
    },
    racGlobalSearchShow: {
      display: "block",
    },
    racProfileMenu: {
      right: 0,
      left: "auto",
    },
    menuList: {
      width: "400px",
      padding: "18px",
      borderRadius: "10px",
    },
    menuItem: {
      "&:hover": {
        background: "none !important",
      },
    },
    closeIcon: {
      width: "15px",
      float: "right",
      outline: "none",
      cursor: "pointer",
    },
    whiteSpace: {
      whiteSpace: "initial",
      color: "#8E8E8E",
    },
    captionFont: {
      color: " #8E8E8E",
    },
    floatRight: {
      float: "right",
    },
    floatLeft: {
      float: "left",
    },
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
    me3: {
      marginRight: theme.typography.pxToRem(16),
    },
    agrInfoPanel: {
      display: "block",
      fontSize: "13px",
      color: "#5b6d83",
      fontFamily: "OpenSans-semibold",
      lineHeight: "unset",
    },
    racpadLinkCustomer: {
      fontFamily: "OpenSans-bold",
      fontSize: "14px",
      color: "#2179FE",
      textDecoration: "none",
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = UseClasses();
  return classes;
};

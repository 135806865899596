/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */
/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable use-isnan */
/* eslint-disable react/no-children-prop */
/* eslint-disable prettier/prettier */
/*eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */

import { useState, useEffect ,useContext} from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import {
  RACSelect,
  RACTextbox,
  RACButton,
  RACCheckBox,
  RadioColor,
  RACRadio,
  RACModalCard,
  RACToggle,
  RACChip,
  RACTable,
  RACTableRow,
  RACTableCell,
  RACBadge,
  Grid,
  Typography,
  List,
  ListItem,
  Card,
  CardContent,
  Box,
  CircularProgress,
  RACCOLOR,
  makeStyles,
  RACAlert,
  RACInfoIcon,
} from '@rentacenter/racstrap';
import { number } from 'yup';
import calculationModulePayloadFormation from './commonModulePayloadFormation';
import { calculatePaymentModule } from '../../api/user';
import { userTraceContextData } from "../../context/UserTraceContext";
import { FINAL_PAYMENT_AGREEMENT } from '../../constants/constants';
export default function PopUpCarryWaiveLate(props: any) {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;
  let classes = takePaymentStyles();

  let amtclct: any;
  let value: any;

  let Fees = {
    totalFeesOwed: '0.00',
    amountToCollect: '0.00',
    carryLateFee: '0.00',
    waivedLateFee: '0.00',
  };
  let [lateFees, setLateFees] = useState<any>(Fees); //(Pseudo no : PC_CW_09) This state handles as the main object to bind & store value in tht field

  const [disableContinueBtn, setDisableContinueBtn] = useState(false); //(Pseudo no : PC_CW_09) This state will handle with enable/disable of continue button
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  useEffect(() => {
    //(Pseudo no : PC_CW_10) useEffect to bind the values in the appropriate fields
    //debugger;

    const agrdetails = Number(props?.checkedAgr[0]?.lateFee);
    const agrdetails1 = Number(props?.checkedAgr[0]?.constLateFee);
    const waivelate = Number(props?.checkedAgr[0]?.waivedLateFee);
    // const carrylate = Number(props?.checkedAgr[0]?.carryLateFee);
    const carrylate = Number(props?.checkedAgr[0]?.carriedLateFee);

    if (agrdetails1 > 0) {
      lateFees.totalFeesOwed = Number(agrdetails1).toFixed(2);
      lateFees.amountToCollect = Number(agrdetails).toFixed(2);
      lateFees.waivedLateFee = Number(waivelate).toFixed(2);

      lateFees.carryLateFee = Number(carrylate).toFixed(2);

      setLateFees(lateFees);
    }
  }, [props]);

  console.log(props.checkedAgr, 'checked agr details2');

  const onClickCancel = () => {
    //(Pseudo no : PC_CW_23) This func will invoke when user is clicking on the cancel btn
    props.isClosed(false);
    setDisableContinueBtn(false);
  };

  const onClickContinueRedirection=async()=>{
    if(props.configFlags.commonModuleFlag==1){
      onClickContinueCommonModule()
    }else{
      onClickContinue()
    }
  }

  const onClickContinueCommonModule=async()=>{
    setLoadingSave(true);
    for (let i = 0; i < props.checkedAgr.length; i++) {

      props.checkedAgr[0].carriedLateFee=0
      props.checkedAgr[0].waivedLateFee=0
      props.checkedAgr[0].isZeroPayment=false
      props.checkedAgr[0].iscarriedLateFeeValue= Number(lateFees.carryLateFee).toFixed(2)
      props.checkedAgr[0].waivedLateFeeValue=Number(lateFees.waivedLateFee).toFixed(2)

    }
    let commonModulePayload = await calculationModulePayloadFormation( {
      customerId:props.customerId,
      storeNumber:props.storeNumber,
      checkedAgr:props.checkedAgr})
      props.checkedAgr[0].iscarriedLateFeeValue= undefined
      props.checkedAgr[0].waivedLateFeeValue=undefined
    let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
    commonModuleServiceRes=commonModuleServiceRes.data
    setLoadingSave(false);
    let prevlftx = (
      Number(props?.checkedAgr[0]?.lateFee) +
      Number(props?.checkedAgr[0]?.lFeeTaxAmount)
    ).toFixed(2);
    let Amount1 = Number(
      Number(props?.checkedAgr[0]?.Amount) - Number(prevlftx)
    ).toFixed(2);
    let prevtax =
      Number(props?.checkedAgr[0].Taxamount) -
      Number(props?.checkedAgr[0].lFeeTaxAmount);

    let lateFeeAmount = Number(lateFees.amountToCollect);

    let newtax = Number(
      Number(lateFeeAmount) * Number(props?.checkedAgr[0]?.taxRate)
    ).toFixed(2);

    let finlfwtx = Number((Number(lateFeeAmount) + Number(newtax)).toFixed(2));
    let fintaxamt = Number((Number(prevtax) + Number(newtax)).toFixed(2));

    let Amount = Number(Amount1) + Number(finlfwtx);

    let initialepo = (
      Number(props?.checkedAgr[0]?.EPOAmount) - Number(prevlftx)
    ).toFixed(2);
    let finalepo = (Number(initialepo) + Number(finlfwtx)).toFixed(2);

    let prevepotax =
      Number(props?.checkedAgr[0]?.EPOTax) -
      Number(props?.checkedAgr[0].lFeeTaxAmount);
    let finepotax = (Number(prevepotax) + Number(newtax)).toFixed(2);
    let commonModuleAgrRes :any =[]
     if(props.checkedAgr[0].Agreementtype!='Club'){
      commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
        (x) =>
          String(x.agreementId) ===
          String(props.checkedAgr[0].Agreementid)
      );
     }else{
      commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.clubPaymentDetails.filter(
        (x) =>
          String(x.clubMembershipNumber) ===
          String(props.checkedAgr[0].AgreementNumber)
      );
     }
      

    let ldw=commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory == "LDW"||x.itemCategory == "LTLDW")
      let policyAmount =commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory=='RACTIREPAY')
      let carryLateFee =commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory=='CLFEEPAY')
      let lateFee = commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory=='LTRPAYFEE')
      let carryRentUsed = commonModuleAgrRes[0]?.amountDetails?.filter((x)=>x.itemCategory=='CRPAY')

    let waivedLateFees = Number(lateFees.waivedLateFee);
    let carryLateFees = Number(lateFees.carryLateFee);
    props.checkedAgr[0].iscarriedLateFeeValue= undefined
      props.checkedAgr[0].waivedLateFeeValue=undefined

    setLateFees(lateFees);
    props.checkedAgr[0].lateFee =lateFee?.length>0?Number(lateFee[0].amount):0
    props.checkedAgr[0].Amount =  Number(
      (Number(commonModuleAgrRes[0].totalAmount)+Number(commonModuleAgrRes[0].totalTax)).toFixed(2)
    );
    props.checkedAgr[0].Taxamount = Number(commonModuleAgrRes[0].totalTax)
    props.checkedAgr[0].lFeeTaxAmount =  lateFee?.length>0?Number(lateFee[0].tax):0
    props.checkedAgr[0].waivedLateFee = Number(waivedLateFees).toFixed(2);
    props.checkedAgr[0].carryLateFee = Number(carryLateFees).toFixed(2);
    props.checkedAgr[0].carriedLateFee = Number(carryLateFees).toFixed(2);
    props.checkedAgr[0].EPOAmount = Number(Number(finalepo).toFixed(2));
    props.checkedAgr[0].EPOTax = Number(Number(finepotax).toFixed(2));

    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"CarryWaveLateFee"
    }
    setUserTraceData([...userTraceData,traceObj])
    props.response({ resArray: props.checkedAgr });
  }
  const onClickContinue = () => {
    //(Pseudo no : PC_CW_19) This func will invoke when user clicking on continue btn & calculation will occurs
    //debugger;

    let prevlftx = (
      Number(props?.checkedAgr[0]?.lateFee) +
      Number(props?.checkedAgr[0]?.lFeeTaxAmount)
    ).toFixed(2);
    let Amount1 = Number(
      Number(props?.checkedAgr[0]?.Amount) - Number(prevlftx)
    ).toFixed(2);
    let prevtax =
      Number(props?.checkedAgr[0].Taxamount) -
      Number(props?.checkedAgr[0].lFeeTaxAmount);

    let lateFee = Number(lateFees.amountToCollect);

    let newtax = Number(
      Number(lateFee) * Number(props?.checkedAgr[0]?.lateFeeTaxRate)
    ).toFixed(2);

    let finlfwtx = Number((Number(lateFee) + Number(newtax)).toFixed(2));
    let fintaxamt = Number((Number(prevtax) + Number(newtax)).toFixed(2));

    let Amount = Number(Amount1) + Number(finlfwtx);
    let initialCarryLateFeeTax =Number( props.checkedAgr[0].carriedLateFee)* Number(props?.checkedAgr[0]?.lateFeeTaxRate)

    let initialepo = (
      Number(props?.checkedAgr[0]?.EPOAmount) - Number(prevlftx) -Number( props.checkedAgr[0].carriedLateFee)-Number(initialCarryLateFeeTax)
    ).toFixed(2);
    let finalepo = (Number(initialepo) + Number(finlfwtx)).toFixed(2);
    let carryLatefeeTax=Number(lateFees.carryLateFee)*Number(props?.checkedAgr[0]?.lateFeeTaxRate)

    let prevepotax =
      Number(props?.checkedAgr[0]?.EPOTax) -
      Number(props?.checkedAgr[0].lFeeTaxAmount)-
      Number(initialCarryLateFeeTax);
    let finepotax = (Number(prevepotax) + Number(newtax)+Number(carryLatefeeTax)).toFixed(2);
    finalepo=(Number(finalepo)+ Number(lateFees.carryLateFee)+Number(carryLatefeeTax)).toFixed(2)
    let waivedLateFees = Number(lateFees.waivedLateFee);
    let carryLateFees = Number(lateFees.carryLateFee);

    setLateFees(lateFees);
    props.checkedAgr[0].lateFee = Number(lateFee).toFixed(2);
    props.checkedAgr[0].Amount = Number(Amount);
    props.checkedAgr[0].Taxamount = Number(fintaxamt);
    props.checkedAgr[0].lFeeTaxAmount = Number(newtax).toFixed(2);
    props.checkedAgr[0].waivedLateFee = Number(waivedLateFees).toFixed(2);
    props.checkedAgr[0].carryLateFee = Number(carryLateFees).toFixed(2);
    props.checkedAgr[0].carriedLateFee = Number(carryLateFees).toFixed(2);
    props.checkedAgr[0].EPOAmount = Number(Number(finalepo).toFixed(2));
    props.checkedAgr[0].EPOTax = Number(Number(finepotax).toFixed(2));
    props.response({ resArray: props.checkedAgr });
  };

  const handleInputField = (e: any) => {
    //(Pseudo no : PC_CW_12 - PC_CW_15) This func will invoke when user is entering values in any of the fiels in onChange() func will be called

    //debugger;
    switch (e.target.name) {
      case 'amountToCollect':
        amtclct = e.target.value;
        console.log(amtclct, '................................AMOUNTCOLLECT');

        value = amtclct.replace(',', '');
        console.log(value, '........................................VALUE');
        if (
          (Number(value) > Number(lateFees.totalFeesOwed) &&
            Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed)) ||
          (Number(value) > Number(lateFees.totalFeesOwed) &&
            Number(lateFees.carryLateFee) > Number(lateFees.totalFeesOwed)) ||
          Number(value) > Number(lateFees.totalFeesOwed)
        ) {
          setDisableContinueBtn(true);
        } else {
          setDisableContinueBtn(false);
        }
        setLateFees({ ...lateFees, amountToCollect: value });

        console.log('HIII', lateFees);
        break;

      case 'waivedLateFee':
        //debugger;

        amtclct = e.target.value;

        value = amtclct.replace(',', '');
        if (
          (Number(value) > Number(lateFees.totalFeesOwed) &&
            Number(lateFees.amountToCollect) >
              Number(lateFees.totalFeesOwed)) ||
          (Number(value) > Number(lateFees.totalFeesOwed) &&
            Number(lateFees.carryLateFee) > Number(lateFees.totalFeesOwed)) ||
          Number(value) > Number(lateFees.totalFeesOwed)
        ) {
          setDisableContinueBtn(true);
        } else {
          setDisableContinueBtn(false);
        }
        setLateFees({ ...lateFees, waivedLateFee: value });

        console.log('GII', lateFees);

        break;

      case 'carryLateFee':
        value = e.target.value;
        console.log(value, '*******CARRY FEE');
        console.log(lateFees.totalFeesOwed, '******************CARRY FEE1');
        if (
          (Number(value) > Number('4.00') &&
            Number(lateFees.amountToCollect) >
              Number(lateFees.totalFeesOwed)) ||
          (Number(value) > Number('4.00') &&
            Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed)) ||
          Number(value) > Number('4.00')
        ) {
          setDisableContinueBtn(true);
        } else {
          setDisableContinueBtn(false);
        }

        setLateFees({ ...lateFees, carryLateFee: value });
        break;
    }
  };

  console.log(lateFees, '****************Final LateFee');

  const handleOtherField = (e: any) => {
    //(Pseudo no : PC_CW_16 - PC_CW_18) This func will invoke when the user entered the value
    //in the field , in onBlur() func will be invoke
    debugger;
    let remainingvalue: any;

    switch (e.target.name) {
      case 'amountToCollect':
        // Number((lateFees.amountToCollect).toFixed(2));
        // setLateFees(lateFees);
        console.log(e.target.value, '..................AMOUNT TO Collect');
        let value1 = Number(e.target.value);
        if (value1 > lateFees.totalFeesOwed) {
          setLateFees({
            ...lateFees,
            carryLateFee: '0.00',
            waivedLateFee: '0.00',
            amountToCollect: String(Number(value1).toFixed(2)),
          });
          //setDisableContinueBtn(true);
          break;
        } else if (Number(lateFees.carryLateFee) > 4.0) {
          //|| Number(lateFees.carryLateFee) > (4.00)
          if (e.target.value == '' && Number(lateFees.carryLateFee) > 4.0) {
            //let value5 = Number(lateFees.totalFeesOwed) - Number(value1)
            setLateFees({
              ...lateFees,
              carryLateFee: '0.00',
              waivedLateFee: Number(lateFees.totalFeesOwed).toFixed(2),
              amountToCollect: '0.00',
            });
            //break;
          } else if (Number(lateFees.carryLateFee) > 4.0) {
            let value2 = Number(lateFees.totalFeesOwed) - Number(value1);
            setLateFees({
              ...lateFees,
              carryLateFee: '0.00',
              waivedLateFee: Number(value2).toFixed(2),
              amountToCollect: Number(value1).toFixed(2),
            });
            //break;
          }

          break;
        }
        remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);

        if (remainingvalue >= lateFees.carryLateFee) {
          let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);

          lateFees.waivedLateFee = remvalue.toFixed(2);
          setLateFees(lateFees);
        } else {
          lateFees.carryLateFee = remainingvalue.toFixed(2);
          lateFees.waivedLateFee = '0.00';
          setLateFees(lateFees);
        }

        if (
          Number(e.target.value) > Number(lateFees.totalFeesOwed) ||
          Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed)
          //|| Number(lateFees.carryLateFee) > Number(lateFees.totalFeesOwed)
        ) {
          setDisableContinueBtn(true);
        } else {
          setDisableContinueBtn(false);
        }
        setLateFees({
          ...lateFees,
          amountToCollect: String(Number(e.target.value).toFixed(2)),
        });

        console.log('HIII', lateFees);
        break;

      case 'waivedLateFee':
        //debugger;

        let value3 = Number(e.target.value);
        if (value3 > lateFees.totalFeesOwed) {
          setLateFees({
            ...lateFees,
            carryLateFee: '0.00',
            amountToCollect: '0.00',
            waivedLateFee: String(Number(value3).toFixed(2)),
          });
          break;
        } else if (Number(lateFees.carryLateFee) > 4.0) {
          if (e.target.value == '' && Number(lateFees.carryLateFee) > 4.0) {
            //let value5 = Number(lateFees.totalFeesOwed) - Number(value1)
            setLateFees({
              ...lateFees,
              carryLateFee: '0.00',
              amountToCollect: Number(lateFees.totalFeesOwed).toFixed(2),
              waivedLateFee: '0.00',
            });
            //break;
          } else if (Number(lateFees.carryLateFee) > 4.0) {
            let value4 = Number(lateFees.totalFeesOwed) - Number(value3);
            setLateFees({
              ...lateFees,
              carryLateFee: '0.00',
              amountToCollect: Number(value4).toFixed(2),
              waivedLateFee: Number(value3).toFixed(2),
            });
            //break;
          }

          break;
        }
        remainingvalue = lateFees.totalFeesOwed - Number(e.target.value);

        if (remainingvalue >= lateFees.carryLateFee) {
          let remvalue = Number(remainingvalue) - Number(lateFees.carryLateFee);
          lateFees.amountToCollect = remvalue.toFixed(2);
          setLateFees(lateFees);
        } else {
          lateFees.carryLateFee = remainingvalue.toFixed(2);
          lateFees.amountToCollect = '0.00';
          setLateFees(lateFees);
        }

        if (
          Number(e.target.value) > Number(lateFees.totalFeesOwed) ||
          Number(lateFees.amountToCollect) > Number(lateFees.totalFeesOwed)
          //|| Number(lateFees.carryLateFee) > Number(lateFees.totalFeesOwed)
        ) {
          setDisableContinueBtn(true);
        } else {
          setDisableContinueBtn(false);
        }
        setLateFees({
          ...lateFees,
          waivedLateFee: String(Number(e.target.value).toFixed(2)),
        });
        //setLateFees(lateFees);

        console.log('GII', lateFees);

        break;

      case 'carryLateFee':
        if (
          (Number(e.target.value) > Number('4.00') &&
            Number(lateFees.amountToCollect) >
              Number(lateFees.totalFeesOwed)) ||
          (Number(e.target.value) > Number('4.00') &&
            Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed)) ||
          Number(e.target.value) > Number('4.00')
        ) {
          setDisableContinueBtn(true);
        } else {
          setDisableContinueBtn(false);
        }
        lateFees.carryLateFee = String(Number(e.target.value).toFixed(2));
        setLateFees(lateFees);

        let value = Number(e.target.value);
        if (
          value > 4.0 ||
          Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed) ||
          Number(lateFees.amountToCollect) > Number(lateFees.totalFeesOwed)
        ) {
          if (
            (Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed) &&
              Number(lateFees.amountToCollect) >
                Number(lateFees.totalFeesOwed)) ||
            //  || (Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed) ||   Number(lateFees.amountToCollect) > Number(lateFees.totalFeesOwed))
            (value <= 4.0 &&
              Number(lateFees.amountToCollect) >
                Number(lateFees.totalFeesOwed)) ||
            (value <= 4.0 &&
              Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed))
          ) {
            let finalvalue: Number =
              Number(lateFees.totalFeesOwed) - Number(value);
            if (
              finalvalue > 0.0 ||
              Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed)
            ) {
              setLateFees({
                ...lateFees,
                amountToCollect: Number(finalvalue).toFixed(2),
                waivedLateFee: '0.00',
              });
              break;
            } else if (finalvalue <= 0.0) {
              setLateFees({
                ...lateFees,
                amountToCollect: '0.00',
                waivedLateFee: '0.00',
              });
              break;
            }
          }

          setLateFees({
            ...lateFees,
            amountToCollect: '0.00',
            waivedLateFee: '0.00',
          });

          break;
        }

        remainingvalue = lateFees.totalFeesOwed - Number(value);
        let remvalue = Number(lateFees.waivedLateFee) - Number(remainingvalue);

        if (remvalue <= 0.0) {
          let remainingvalue3 = Math.abs(remvalue);
          let remainingvalue5 =
            Number(lateFees.totalFeesOwed) -
            (Number(remainingvalue3) + Number(value));
          setLateFees({
            ...lateFees,
            amountToCollect: String(Number(remainingvalue3).toFixed(2)),
            waivedLateFee: String(Number(remainingvalue5).toFixed(2)),
          });
        } else if (remvalue > 0.0) {
          let remainingvalue4 =
            Number(lateFees.totalFeesOwed) -
            (Number(remainingvalue) + Number(value));
          setLateFees({
            ...lateFees,
            amountToCollect: String(Number(remainingvalue).toFixed(2)),
            waivedLateFee: String(Number(remainingvalue4).toFixed(2)),
          });
        }
        break;
    }
  };

  const carryWaivePopUp = () => {
    //(Pseudo no : PC_CW_08)  This func will be called in the return part
    return (
      <>
        <Grid container spacing={3} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            <RACTextbox
              inputlabel="Total Fees Owed"
              inputLabelClassname={classes.waiveLabel}
              className={classes.waiveText}
              value={lateFees.totalFeesOwed}
              isCurrency={true}
              disabled={true}
              required={true}
            />
          </Grid>
          <Grid
            item
            md={6}
            className={classes.mb2}
            style={{ marginRight: '-25px' }}
          >
            <RACTextbox
              inputlabel="Amount To Collect"
              inputLabelClassname={classes.waiveLabel}
              className={classes.waiveText}
              value={lateFees.amountToCollect}
              isCurrency={true}
              OnChange={(e: any) => handleInputField(e)}
              Onblur={(e: any) => handleOtherField(e)}
              name="amountToCollect"
              type={'number'}
              maxlength={5}
            />
            {Number(lateFees.amountToCollect) >
            Number(lateFees.totalFeesOwed) ? (
              <label className={classes.mandatoryfield}>
                Amount shouldn't greater than total fees owed.
              </label>
            ) : null}
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.px3}>
          <Grid item md={6} className={classes.mb2}>
            {/* <Grid style={{ fontSize: 'small', fontWeight: 'bold', marginTop: '2%', marginBottom: '2%', marginLeft: '5%' }} ><span><label className='classes.formLabel'>Carry Late Fee Amount (max $4.00)</label></span></Grid> */}
            <RACTextbox
              inputlabel="Carry Late Fee Amount (max $4.00)"
              inputLabelClassname={classes.waiveLabel}
              className={classes.waiveText}
              value={lateFees.carryLateFee}
              isCurrency={true}
              OnChange={(e: any) => handleInputField(e)}
              Onblur={(e: any) => handleOtherField(e)}
              name="carryLateFee"
              type="number"
              maxlength={5}
              disabled = { FINAL_PAYMENT_AGREEMENT.includes(props?.checkedAgr[0]?.Nextduedate) }
            />
            {Number(lateFees.carryLateFee) > Number(4.0) ? (
              <label className={classes.mandatoryfield}>
                Amount shouldn't greater than $4.00
              </label>
            ) : null}
          </Grid>
          <Grid
            item
            md={6}
            className={classes.mb2}
            style={{ marginRight: '-25px' }}
          >
            <RACTextbox
              inputlabel="Waived Late Fee Amount"
              inputLabelClassname={classes.waiveLabel1}
              className={classes.waiveText}
              value={lateFees.waivedLateFee}
              isCurrency={true}
              OnChange={(e: any) => handleInputField(e)}
              Onblur={(e: any) => handleOtherField(e)}
              maxlength={5}
              name="waivedLateFee"
              type={'number'}
            />
            {Number(lateFees.waivedLateFee) > Number(lateFees.totalFeesOwed) ? (
              <label className={classes.mandatoryfield}>
                Amount shouldn't greater than total fees owed.
              </label>
            ) : null}
          </Grid>

          {/* <Grid item md={6} className={classes.mb2}>
                    
                    </Grid> */}
        </Grid>
        <Grid style={{ float: 'right' }}>
          <RACButton
            style={{
              marginRight: '20px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
            color="inherit"
            variant="outlined"
            onClick={() => onClickCancel()}
          >
            Cancel
          </RACButton>
          <RACButton
            style={{ marginRight: '15px' }}
            color="primary"
            variant="contained"
            disabled={disableContinueBtn||loadingSave}
            loading={loadingSave}
            onClick={() => onClickContinueRedirection()}
          >
            Continue
          </RACButton>
        </Grid>
      </>
    );
  };

  return (
    // Return part will bind funtionality in the popup
    <Grid>
      <RACModalCard
        isOpen={props.isOpen}
        maxWidth="xs"
        title="Carry Waive/Late Fees"
        onClose={() => onClickCancel()}
        closeIcon={true}
        borderRadius="20px"
        children={carryWaivePopUp()}
      />
    </Grid>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * PS_TCK_1
 */
import React, { useEffect, useState, useContext } from 'react';
import { AddCheck } from './AddCheck';
import { AddMoneyOrder } from './AddMoneyOrder';
import { agreementContext } from '../context/PaymentInformationContext';
import {
  RACButton,
  RACTable,
  RACTableRow,
  RACTableCell,
  Grid,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { checkMoGridStyle } from '../stylesJS/checkMoGridStyle';
import { ReactComponent as InfoiconGrey } from '../../assets/images/Info-icon-grey.svg';
import {
  checkMoneyDataInterface,
  checkDataInterface,
  checkAndMoDisable,
} from '../interface/tenderCheckMoneyGridInterface';
import CONSTANTS from '../constants/constant';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { resetTenderTypes } from '../utils/amountCalculations';

interface propsInterface {
  setCurrentTab: (flag: string) => void;
  setRerenderReaminingAmount: (flag: boolean) => void;
  rerenderReaminingAmount: boolean;
}
/**
 *
 * @returns PS_TCK_2
 */
export const TenderCheckMoneyGrid = (props: propsInterface) => {
  /**
   * PS_TCK_3
   */
  const classes = takePaymentStyles();
  const navlinkClass = checkMoGridStyle();
  const agreementInfo = useContext(agreementContext);
  const [checkMoneyOrderDetails, setCheckMoneyOrderDetails] = useState<
    checkMoneyDataInterface[]
  >([]);
  const [openCheckForm, setOpenCheckForm] = useState<boolean>(false);
  const [openMoneyOrderForm, setOpenMoneyOrderForm] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(
    CONSTANTS.MINUS_ONE_NUMBER
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [resetTender, setResetTender] = useState<boolean>(false);
  const [disableAddCheckAndMo, setDisableAddCheckAndMo] =
    useState<checkAndMoDisable>({
      nonClubAgreements: [],
      nonInventorySaleAgreement: [],
      selectedAgreements: [],
      transferAgreementStatus: false,
      otherIncomeAgreements: false,
      suspenseAdded: false,
    });
  const checkMoneyOrderHeaderFields = CONSTANTS.CHECK_MONEY_ORDER_HEADERS;

  /**
   * PS_TCK_4
   */

  useEffect(() => {
    agreementInfo.setTypeOfCard(CONSTANTS.EMPTY_STRING);
  }, []);

  useEffect(() => {
    if (!openCheckForm) {
      formatGridData();
      getDisableCombinations();
    }
  }, [
    openCheckForm,
    deleteAction,
    openMoneyOrderForm,
    agreementInfo.isSetToDefault,
  ]);

  useEffect(() => {
    setResetTender(true);
    if (resetTenderTypes(agreementInfo) && resetTender) {
      agreementInfo.customerInfo.amountUsed.tenderDetails.cash.amount =
        CONSTANTS.ZREO_TWO_DECIMAL_STRING;
      agreementInfo.customerInfo.amountUsed.tenderDetails.card = [];
      agreementInfo.customerInfo.amountUsed.cardDetails = [];
      agreementInfo.customerInfo.amountUsed.tenderDetails.checkDetails = [];
      agreementInfo.customerInfo.amountUsed.tenderDetails.moneyOrderDetails =
        [];
      agreementInfo.setTypeOfCard(CONSTANTS.EMPTY_STRING);
      props.setCurrentTab(CONSTANTS.ORIGIN_CASH_TAB);
    }
  }, [agreementInfo.agreementDetails]);

  /**
   * PS_TCK_5
   */
  const formatGridData = () => {
    const formattedData: checkMoneyDataInterface[] = [];
    agreementInfo.customerInfo.amountUsed.tenderDetails.checkDetails.map(
      (check: checkDataInterface, index: number) => {
        const obj = {
          id: index,
          docType: CONSTANTS.CAPITAL_CHECK,
          name: check.checkName,
          docNumber: check.checkNumber,
          docFrom: check.writtenBy,
          amount: check.amount,
          orderId: check.orderId,
        };
        formattedData.push(obj);
      }
    );

    agreementInfo.customerInfo.amountUsed.tenderDetails.moneyOrderDetails.map(
      (moneyOrder, index: number) => {
        const obj = {
          id: index,
          docType: CONSTANTS.MONEY_ORDER_CONCAT,
          name: CONSTANTS.EMPTY_STRING,
          docNumber: moneyOrder.moneyOrderNumber,
          docFrom: moneyOrder.issuer,
          amount: moneyOrder.amount,
          orderId: moneyOrder.orderId,
        };
        formattedData.push(obj);
      }
    );

    formattedData.sort((a, b) => a.orderId - b.orderId);
    setCheckMoneyOrderDetails(formattedData);
  };

  /**
   * PS_TCK_13, PS_TCK_14
   * @param docType
   * @param index
   */
  const update = (docType: string, index: number) => {
    docType == CONSTANTS.CAPITAL_CHECK
      ? setOpenCheckForm(true)
      : setOpenMoneyOrderForm(true);
    setEditMode(true);
    setCurrentIndex(index);
  };

  /**
   * PS_TCK_11, PS_TCK_12
   * @param docType
   * @param index
   */
  const deleteItem = (docType: string, index: number) => {
    if (docType === CONSTANTS.CAPITAL_CHECK) {
      const currentCheckData = JSON.parse(
        JSON.stringify(agreementInfo.customerInfo)
      );
      currentCheckData.amountUsed.tenderDetails.checkDetails.splice(
        index,
        CONSTANTS.ONE_NUMBER
      );
      agreementInfo.setCustomerInfo(currentCheckData);
      props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
    } else {
      const currentMoneyOrderData = JSON.parse(
        JSON.stringify(agreementInfo.customerInfo)
      );
      currentMoneyOrderData.amountUsed.tenderDetails.moneyOrderDetails.splice(
        index,
        CONSTANTS.ONE_NUMBER
      );
      agreementInfo.setCustomerInfo(currentMoneyOrderData);
      props.setRerenderReaminingAmount(!props.rerenderReaminingAmount);
    }
    setDeleteAction(!deleteAction);
  };

  /**
   *
   * @returns PS_TCK_7
   */
  const bindCheckHeader = () => (
    <>
      {checkMoneyOrderHeaderFields.map((val, index) => {
        return val.display === CONSTANTS.CHECK_MONEY_ORDER_HEADERS[0].display ||
          val.display === CONSTANTS.CHECK_MONEY_ORDER_HEADERS[1].display ||
          val.display === CONSTANTS.CHECK_MONEY_ORDER_HEADERS[2].display ? (
          <RACTableCell className={classes.textleft} key={index}>
            {val.display}
          </RACTableCell>
        ) : val.display === CONSTANTS.CHECK_MONEY_ORDER_HEADERS[3].display ? (
          <RACTableCell className={classes.textright} key={index}>
            {val.display}
          </RACTableCell>
        ) : val.display === CONSTANTS.CHECK_MONEY_ORDER_HEADERS[4].display ? (
          <RACTableCell className={classes.textcenter} key={index}>
            {val.display}
          </RACTableCell>
        ) : null;
      })}
    </>
  );

  /**
   * PS_TCK_9
   * @returns
   */
  const bindCheckBody = () => {
    return (
      <>
        {checkMoneyOrderDetails.map(
          (value: checkMoneyDataInterface, index: number) => {
            return (
              <RACTableRow
                key={'dynamicgridrow' + index}
                data-testid={'dynamicgridrow' + index}
                className={classes.hiddenRow}
              >
                {value.docType === CONSTANTS.CAPITAL_CHECK ? (
                  <RACTableCell
                    className={classes.textleft}
                    title={value.name}
                    data-testid={'tooltip' + index}
                    onMouseOver={() => {
                      value.name;
                    }}
                  >
                    {CONSTANTS.CAPITAL_CHECK}
                    <InfoiconGrey className={classes.infoIcon} />
                  </RACTableCell>
                ) : (
                  <RACTableCell className={classes.textleft}>
                    {CONSTANTS.CAPITAL_MONEY_ORDER}
                  </RACTableCell>
                )}
                <RACTableCell className={classes.textleft}>
                  {value.docNumber}
                </RACTableCell>
                <RACTableCell className={classes.textleft}>
                  {value.docFrom}
                </RACTableCell>
                {value.amount.split('.').length < 2 ? (
                  <RACTableCell className={classes.textright}>
                    <span>$</span>
                    {value.amount}
                    <span>{CONSTANTS.POINT_TWO_ZERO}</span>
                  </RACTableCell>
                ) : (
                  <RACTableCell className={classes.textright}>
                    <span>$</span>
                    {value.amount}
                  </RACTableCell>
                )}
                <RACTableCell className={classes.textcenter}>
                  <RACButton
                    className={`${classes.paymentSmallBtn} ${classes.mx2} ${classes.updatebutton}`}
                    onClick={() => update(value.docType, value.id)}
                    data-testid={index + 'update'}
                  >
                    Update
                  </RACButton>
                  <RACButton
                    className={`${classes.paymentSmallBtn} ${classes.deletebutton}`}
                    onClick={() => deleteItem(value.docType, value.id)}
                    data-testid={'deletebuttongrid' + index}
                  >
                    Delete
                  </RACButton>
                </RACTableCell>
              </RACTableRow>
            );
          }
        )}
      </>
    );
  };

  /**
   * PS_TCK_22
   */
  const closeAddCheckPopup = () => {
    setEditMode(false);
    setOpenCheckForm(false);
    setCurrentIndex(CONSTANTS.MINUS_ONE_NUMBER);
  };

  /**
   * PS_TCK_23
   */
  const closeAddMoneyOrderPopup = () => {
    setEditMode(false);
    setOpenMoneyOrderForm(false);
    setCurrentIndex(CONSTANTS.MINUS_ONE_NUMBER);
  };

  /**
   * PS_TCK_6
   */
  const getDisableCombinations = () => {
    const storeNumber = sessionStorage.getItem('storeNumber');
    const sameStoreAgreements = agreementInfo?.agreementDetails?.filter(
      (agreement) => agreement.storeNumber == storeNumber
    );
    const disableConditions = {
      nonClubAgreements: sameStoreAgreements.filter(
        (agrmeentData: AgreementDetails) =>
          agrmeentData.agreementType != CONSTANTS.CLUB
      ),
      nonInventorySaleAgreement: sameStoreAgreements.filter(
        (agrmeentData: AgreementDetails) =>
          agrmeentData.agreementId == CONSTANTS.ADJUST
      ),
      selectedAgreements: sameStoreAgreements.filter(
        (agrmeentData: AgreementDetails) => agrmeentData.selected
      ),
      transferAgreementStatus: !!agreementInfo.storeInfo?.storeDetails?.some(
        (storeValue) =>
          storeValue.storeNumber ==
            sessionStorage.getItem(CONSTANTS.STORE_NUMBER) &&
          storeValue.transferAgreementStatus == 1
      ),
      otherIncomeAgreements: !!sameStoreAgreements.some(
        (agrmeentData: AgreementDetails) =>
          agrmeentData.selected &&
          CONSTANTS.CCCB_NSF_AGREEMENT_ARRAY.includes(
            agrmeentData.otherIncomeType
          )
      ),
      suspenseAdded: !!sameStoreAgreements.some(
        ({ suspenseDetails: susp, selected }: AgreementDetails) =>
          selected && parseFloat(susp?.suspAdd) > CONSTANTS.ZERO_NUMBER
      ),
    };

    setDisableAddCheckAndMo(disableConditions);
  };

  /**
   * PS_TCK_16
   * @returns
   */
  const disableAddCheck = (): boolean => {
    let value = false;
    const storeNumber = sessionStorage.getItem('storeNumber');
    const sameStoreSelectedAgreements = agreementInfo?.agreementDetails?.filter(
      (agreement) => agreement.storeNumber == storeNumber && agreement.selected
    );
    const nonInventorySaleAgreement = sameStoreSelectedAgreements.every(
      (agrmeentData: AgreementDetails) =>
        agrmeentData.agreementId == CONSTANTS.ADJUST
    );
    const finalAgreements = sameStoreSelectedAgreements.every(
      (agrmeentData: AgreementDetails) =>
        agrmeentData.otherIncomeType == CONSTANTS.EMPTY_STRING &&
        !CONSTANTS.AGREEMENT_TYPE_CLUB_NOTE_ARRAY.includes(
          agrmeentData.agreementType
        ) &&
        CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(agrmeentData.nextDueDate)
    );
    if (
      (disableAddCheckAndMo.nonClubAgreements.length == CONSTANTS.ONE_NUMBER &&
        CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
          disableAddCheckAndMo.nonClubAgreements[0].nextDueDate
        )) ||
      nonInventorySaleAgreement ||
      finalAgreements ||
      disableAddCheckAndMo.transferAgreementStatus ||
      disableAddCheckAndMo.suspenseAdded ||
      parseFloat(agreementInfo.customerInfo.donation) > CONSTANTS.ZERO_NUMBER
    ) {
      value = true;
    }
    return value;
  };

  function disableCheckOnNSFCCCB() {
    const otherIncomeAgreements = agreementInfo.agreementDetails.some(
      (agrmeentData: AgreementDetails) =>
        agrmeentData.selected &&
        CONSTANTS.CCCB_NSF_AGREEMENT_ARRAY.includes(agrmeentData.agreementType)
    );

    return otherIncomeAgreements ? true : false;
  }

  /**
   * PS_TCK_17
   * @returns
   */
  const disableAddMo = (): boolean => {
    let value = false;
    const storeNumber = sessionStorage.getItem('storeNumber');
    const sameStoreSelectedAgreements = agreementInfo?.agreementDetails?.filter(
      (agreement) => agreement.storeNumber == storeNumber && agreement.selected
    );
    const nonInventorySaleAgreement = sameStoreSelectedAgreements.every(
      (agrmeentData: AgreementDetails) =>
        agrmeentData.agreementId == CONSTANTS.ADJUST
    );
    if (
      nonInventorySaleAgreement ||
      disableAddCheckAndMo.transferAgreementStatus
    ) {
      value = true;
    }
    return value;
  };

  /**
   * PS_TCK_15
   */
  return (
    <>
      <Grid
        item
        id="CheckMoney-Order"
        role="tabpanel"
        aria-labelledby="CheckMoney-Order-tab"
        data-testid="checkdivshow"
      >
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.mt2}`}
        >
          <Grid
            item
            className={`${classes.raccollg12} ${classes.mt4} ${classes.mb3} ${classes.floatLeft}`}
          >
            <Grid item className={classes.floatRight}>
              <RACButton
                variant="outlined"
                color="primary"
                data-testid="addCheck"
                className={`${classes.paymentSmallBtn} ${classes.mx2} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                onClick={() => {
                  setOpenCheckForm(true);
                }}
                disabled={disableAddCheck() || disableCheckOnNSFCCCB()}
              >
                Add Check
              </RACButton>
              <RACButton
                variant="outlined"
                color="primary"
                data-testid="addMoney"
                disabled={disableAddMo()}
                // disabled={isPendingAgrTransfer || addMoneyOrderDisable}
                className={`${classes.paymentSmallBtn} ${navlinkClass.paymentBtnHover} ${classes.outlinecustombtn}`}
                onClick={() => {
                  setOpenMoneyOrderForm(true);
                }}
              >
                Add Money Order
              </RACButton>
            </Grid>
          </Grid>
          <Grid className={`${classes.raccollg12} ${classes.floatLeft}`}>
            <RACTable
              className={classes.racGrid}
              renderTableHead={bindCheckHeader}
              renderTableContent={() => bindCheckBody()}
            />
          </Grid>
        </Grid>
      </Grid>

      <AddCheck
        openCheckForm={openCheckForm}
        editMode={editMode}
        id={currentIndex}
        closeAddCheckPopup={closeAddCheckPopup}
        render={props}
      />
      <AddMoneyOrder
        openMoneyOrderForm={openMoneyOrderForm}
        editMode={editMode}
        id={currentIndex}
        closeAddMoneyOrderPopup={closeAddMoneyOrderPopup}
        render={props}
      />
    </>
  );
};

/*eslint-disable*/
import React, { useState, useLayoutEffect, useContext } from "react";
import { wizardStyles } from "../../stylesJS/wizardStyles";
import { Grid, List, ListItem, Box, Typography } from "@rentacenter/racstrap";
import { ReactComponent as CompletedIcon } from "../../assets/images/wizard-complete.svg";
import { useParams, Link } from "react-router-dom";
import { useHistory } from "react-router";
import CONSTANTS from "../constants/constant";
import { agreementContext } from "../context/PaymentInformationContext";

export type CoCustomerDetails = {
  coCustomerId: string;
  coCustomerName: string;
};

interface Params {
  customerId: string;
  agreementId: string;
}
export default function Wizard() {
  const wizardClassName = wizardStyles();
  const { customerId, agreementId } = useParams<Params>();
  const history = useHistory();

  const dynamicClasses = {
    complete: {
      anchor: wizardClassName.smartWizardListLinkActive,
      spanNum: `${wizardClassName.borderDefault} ${wizardClassName.done}`,
      spanDetail: wizardClassName.stepDone,
    },
    inprogress: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderActive,
      spanDetail: wizardClassName.stepActive,
    },
    pending: {
      anchor: wizardClassName.smartWizardListLinkInActive,
      spanNum: wizardClassName.borderDefault,
      spanDetail: wizardClassName.stepNotStarted,
    },
  };
  const primaryInfoData = useContext(agreementContext);

  const itemSearchStatus = CONSTANTS.COMPLETE;
  const agreementDetailStatus = CONSTANTS.COMPLETE;
  const [preliminaryPaymentStatus, setpreliminaryPaymentStatus] = useState<string>(
    CONSTANTS.PENDING
  );
  const [POStatus, setPOStatus] = useState<string>(CONSTANTS.PENDING);
  const [initialPaymentStatus, setInitialPaymentStatus] = useState<string>(
    CONSTANTS.PENDING
  );
  const deliveryMethodStatus = CONSTANTS.PENDING
  const styleCursor = { cursor: "not-allowed" }

  const firstName =
    primaryInfoData.customerInfo.customerDetails[CONSTANTS.ZERO_NUMBER]
      .firstName;
  const lastName =
    primaryInfoData.customerInfo.customerDetails[CONSTANTS.ZERO_NUMBER]
      .lastName;

  useLayoutEffect(() => {
    const getUrl = window.location.href;
    if (getUrl.includes(CONSTANTS.PERLIMINARY)) {
      setpreliminaryPaymentStatus(CONSTANTS.IN_PROGRESS);
    } else if (getUrl.includes(CONSTANTS.INITIAL)) {
      setpreliminaryPaymentStatus(CONSTANTS.COMPLETE);
      setPOStatus(CONSTANTS.COMPLETE);
      setInitialPaymentStatus(CONSTANTS.IN_PROGRESS);
    }
  }, []);

  return (
    //Wizard starts from here
    <Grid className={wizardClassName.swThemeDefault}>
      <Grid item className={wizardClassName.swThemeDefaultContainer}>
        <List className={wizardClassName.smartWizard}>
          <ListItem
            className={wizardClassName.smartWizardList}
            onClick={() => {
              history.push({
                pathname: `/agreement/customerorder/itemsearch/${customerId}/${agreementId}?coCustomerId=N`,
              });
            }}
          >
            <a className={dynamicClasses[itemSearchStatus]["anchor"]}>
              {itemSearchStatus === CONSTANTS.COMPLETE ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span className={dynamicClasses[itemSearchStatus]["spanNum"]}>
                  1
                </span>
              )}

              <span className={dynamicClasses[itemSearchStatus]["spanDetail"]}>
                Item Search
              </span>
            </a>
          </ListItem>
          <ListItem
            className={wizardClassName.smartWizardList}
            onClick={() => {
              history.push({
                pathname: `/agreement/customerorder/details/${customerId}/${agreementId}?coCustomerId=N`,
              });
            }}
          >
            <a className={dynamicClasses[agreementDetailStatus]["anchor"]}>
              {agreementDetailStatus === CONSTANTS.COMPLETE ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={dynamicClasses[agreementDetailStatus]["spanNum"]}
                >
                  2
                </span>
              )}

              <span
                className={dynamicClasses[agreementDetailStatus]["spanDetail"]}
              >
                Agreement Details
              </span>
            </a>
          </ListItem>
          <ListItem className={wizardClassName.smartWizardList}>
            <a
              className={dynamicClasses[preliminaryPaymentStatus]["anchor"]}
              style={styleCursor}
            >
              {preliminaryPaymentStatus === CONSTANTS.COMPLETE ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={
                    dynamicClasses[preliminaryPaymentStatus]["spanNum"]
                  }
                >
                  3
                </span>
              )}

              <span
                className={
                  dynamicClasses[preliminaryPaymentStatus]["spanDetail"]
                }
              >
                Preliminary Payment
              </span>
            </a>
          </ListItem>
          <ListItem
            className={wizardClassName.smartWizardList}
            onClick={() => {
              const getUrl = window.location.href;
              if (getUrl.includes(CONSTANTS.PAYMENT_URL)) {
                history.push({
                  pathname: `/agreement/customerorder/po/${customerId}/${agreementId}?coCustomerId=N`,
                });
              }
            }}
          >
            <a
              className={dynamicClasses[POStatus]["anchor"]}
              style={styleCursor}
            >
              {POStatus === CONSTANTS.COMPLETE ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span className={dynamicClasses[POStatus]["spanNum"]}>4</span>
              )}

              <span className={dynamicClasses[POStatus]["spanDetail"]}>
                PO Status
              </span>
            </a>
          </ListItem>
          <ListItem className={wizardClassName.smartWizardList}>
            <a
              className={dynamicClasses[initialPaymentStatus]["anchor"]}
              style={styleCursor}
            >
              {initialPaymentStatus === CONSTANTS.COMPLETE ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={dynamicClasses[initialPaymentStatus]["spanNum"]}
                >
                  5
                </span>
              )}

              <span
                className={dynamicClasses[initialPaymentStatus]["spanDetail"]}
              >
                Initial Payment
              </span>
            </a>
          </ListItem>
          <ListItem className={wizardClassName.smartWizardList}>
            <a
              className={dynamicClasses[deliveryMethodStatus]["anchor"]}
              style={styleCursor}
            >
              {deliveryMethodStatus === CONSTANTS.COMPLETE ? (
                <Box
                  component="span"
                  className={`${wizardClassName.done} ${wizardClassName.borderDone}`}
                >
                  <CompletedIcon className={wizardClassName.paddingTop3} />
                </Box>
              ) : (
                <span
                  className={dynamicClasses[deliveryMethodStatus]["spanNum"]}
                >
                  6
                </span>
              )}

              <span
                className={dynamicClasses[deliveryMethodStatus]["spanDetail"]}
              >
                Delivery Method
              </span>
            </a>
          </ListItem>
        </List>
        <Grid
          className={`${wizardClassName.floatRight} ${wizardClassName.custompadding}`}
        >
          <div
            className={`${wizardClassName.floatLeft} ${wizardClassName.me3} ${wizardClassName.mt1}`}
          >
            <span className={wizardClassName.agrInfoPanel}>
              <Link
                className={wizardClassName.racpadLinkCustomer}
                to={`/customer/update/${customerId}/customer`}
              >
                {firstName + CONSTANTS.STRING_WITH_SINGLE_SPACE + lastName}
              </Link>
            </span>
            <Typography
              variant="caption"
              className={wizardClassName.agrInfoPanel}
            >
              {customerId}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

/* eslint-disable prettier/prettier */
import { VantivConfig } from '../../config/VantivConfig';
// import { appConfig } from '../config/app-config';

declare let LitlePayframeClient: any;
let paypageId = 'uwrjGM3KiuKcqyAs';
export const config: VantivConfig = {
  paypageId: 'uwrjGM3KiuKcqyAs',
  merchantId: '',
  style: 'racpadprimaryiframev2',
  reportGroup: 'SIMS',
  div: '',
  timeout: 15000,
  height: '250',
  showCvv: true,
  numYears: '12',
  placeholderText: {
    cvv: 'CVV',
    accountNumber: 'Credit Card Number',
  },
  tabIndex: {
    cvv: 3,
    accountNumber: 2,
    expMonth: 3,
    expYear: 4,
  },
  enhancedUxFeatures: {
    inlineFieldValidations: true,
  },
  callback: 0,
};

export const getConfig = (
  storeMerchantId: string | null,
  containerId: string,
  callback: any
) => {
  const localConfig = config;

  localConfig.merchantId = storeMerchantId;
  localConfig.div = containerId;
  localConfig.callback = callback;
  localConfig.paypageId = paypageId;

  return localConfig;
};

export const loadVantivIFrame = (
  storeMerchantId: string | null,
  containerId: string,
  callback: any
) => {
  // Need to remove this logic and keep the payPageId in environment file
  if(window.location.href.includes("dev") || window.location.href.includes("qa") || window.location.href.includes("uat") || window.location.href.includes("perf") || window.location.href.includes("training")){
    paypageId = 'uwrjGM3KiuKcqyAs';
  }
  else{
    paypageId = 'Fjm8Xzn5DhtCg9Sy';
  }
  
  return new LitlePayframeClient(
    getConfig(storeMerchantId, containerId, callback)
  );
};

export const getRegistrationId = (
  vantivIFrame: any,
  uniqueId: string | null
) => {
  vantivIFrame.getPaypageRegistrationId({
    id: config.merchantId,
    orderId: uniqueId ? uniqueId.substring(1, 20) : 'null',
  });
};

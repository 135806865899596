import CONSTANTS from '../constants/constant';
import { PaymentMethod } from '../interface/paymentInfoInterface';

export const cardTypeValdation = (cardDetails: PaymentMethod[]) => {
  return (
    cardDetails?.filter(
      (el) =>
        el.cardTypeRef !== 'PP' &&
        el.cardTypeRef !== 'BL' &&
        el.cardTypeRef !== 'EC' &&
        el.cardTypeRef !== 'GC' &&
        el.cardTypeRef !== 'VM' &&
        el.cardTypeRef !== null &&
        el.cardTypeRef !== CONSTANTS.EMPTY_STRING
    ) ?? []
  );
};

export const formatPhone = (input: string) => {
  const cleaned = ('' + input).replace(/\D/g, '');
  const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return cleaned.replace(PhoneRegex, '($1) $2-$3');
};

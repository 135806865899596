/* eslint-disable react/no-children-prop */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-duplicated-branches */
import React, { useState, useEffect, useRef, useContext } from "react";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router";
import { ReactComponent as SuccessIcon } from "../../assets/images/success-icon.svg";
import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
import {
  AcceptPaymentApi,
  AgreementCustomer,
  GenerateAgreementDoc,
  SalesLead,
  Receiptpdfgen,
  GetEmployeeID,
  GetRole,
  MakePayment,
  LDwServiceCall,
  ActiveClub,
  PolicyServiceCall,
  PlaceOrderCO
} from "../../api/user";
import { MakePaymentDTO, CustomerInfo } from "../../domain/makepaymentpayload";
//import { parseInt } from 'lodash';
import SecondFactor from "./TwoFactorPopup";
import { salesLeadRequest } from "../microfrontends/paymentgrid";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
import {
  RACButton,
  RACModalCard,
  Grid,
  Typography,
  RACCOLOR,
  makeStyles,
  RACModal,
  TextareaAutosize
} from "@rentacenter/racstrap";
import { AcceptPaymentRequest } from "../../racstrapComponents/microfrontends/paymentgrid";
import { height } from "@mui/system";
import { Redirect, useLocation } from "react-router-dom";
import { AppRoute } from "../../config/route-config";
import { DeliveryContext } from "../../context/DeliveryContext";
import CustomerSignature from "./CustomerSignature";
import { useParams } from "react-router-dom";
import { takePaymentStyles } from '../../stylesJS/makeStyles';

const useClasses = makeStyles((theme: any) => ({
  racErrorIcon: {
    width: "50px",
  },
  racErrorFunction: {
    fontFamily: "OpenSans-semibold",
    marginBottom: "20px",
    marginTop: "10px",
  },
  me2: {
    marginRight: theme.typography.pxToRem(8),
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  textcenter: {
    textAlign: "center",
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  modalfooter: {
    borderTop: "0px solid transparent",
  },
  w100: {
    width: "100%",
  },
  ms1: {
    marginLeft: theme.typography.pxToRem(4),
  },
  ms2: {
    marginLeft: theme.typography.pxToRem(8),
  },
  colmd12: {
    flex: "0 0 auto",
    width: "100%",
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  popUpTxtStyle: {
    fontFamily: "OpenSans-regular",
    fontSize: "16px",
  },
  me3: {
    marginRight: theme.typography.pxToRem(16),
  },
  dialogContent: {
    textAlign: "center",
    margin: `2rem 0`,
    height: "90%",
    overflow: "hidden",
  },
  dialogRoot: {
    height: "90% !important",
    "& .MuiPaper-rounded": {
      borderRadius: theme.typography.pxToRem(12),
    },
    "& .MuiDialog-paperScrollPaper": {
      height: "90% !important",
    },
    "& .MuiTypography-h5": {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
  },
  titletxtstyle: {
    fontSize: theme.typography.pxToRem(18),
  },
  bold: {
    fontFamily: "OpenSans-bold",
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center",
  },
  mt4: {
    marginTop: theme.typography.pxToRem(24),
  },
  paymentmodal: {
    maxHeight: "95% !important",
    height: "95% !important",
  },
  textend: {
    textAlign: "end",
  },
  customModal: {
    "& div": {
      "& div": {
        maxWidth: "2000px !important",
      },
    },
  },
  me1: {
    marginRight: theme.typography.pxToRem(4),
  },
  foc: {
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '1px 1px 6px 3px #80bdff',
    },
  },
  textAreaN: {
    margin: "10px 0px",
    width: 500,
    padding: "6PX",
    borderRadius: "4px",
    resize: "none"

  },
  description: {
    margin: "10px 0px"
  },
  me2N: {
    marginRight: '0.5rem !important',
    marginLeft: '0.5rem',
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
}));
let extensionArrayUrl: string[] = [];
let cofArrayUrl: string[] = [];
interface ParamType {
  customerId: string;
  agreementId: string;
}
export function Payment(props: any) {
  const { customerId } = useParams<ParamType>();
  const {
    clubsStatus,
    customerOrder,
    // searchResult
  } = useContext(DeliveryContext);
  const [clubBtnStatus, setclubBtnStatus] = clubsStatus;
  const [custOrder, setCustOrder] = customerOrder;
  const classes = useClasses();
  const classes2 = takePaymentStyles();
  const history = useHistory();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [tran, settran] = useState(false);
  const [recpt, setrecpt] = useState(false);
  const [load, setload] = useState(false);
  const [Tray1, setTray1] = useState(false);
  const [Ownership, setOwnership] = useState(false);
  const [cofUrlState, setcofUrlState]: any = useState();
  const [Recptload, setRecptload] = useState(false);
  const [receipturl, setreceipturl]: any = useState(undefined);
  const [cofPopup, setcofPopup]: any = useState(false);
  const [extPopup, setextPopup]: any = useState(false);
  const [viewReceiptPopup, setviewReceiptPopup]: any = useState(false);
  const [viewExtPopup, setviewExtPopup]: any = useState(false);
  const [viewCOFPopup, setviewCOFPopup]: any = useState(false);
  const [cofIndex, setcofIndex]: any = useState(0);
  const [extIndex, setextIndex]: any = useState(0);
  const [MakepaymentResp, setMakepaymentResp]: any = useState();
  const [txtChange, settxtChange] = useState(false);
  const [epayFail, setepayFail] = useState(false);
  const [cardPaymentFailedMessage, setCardPaymentFailedMessage] = useState('');

  const totalDueamt = props.Totaldue;
  const getcardResponse = props.getResponse;
  const radioFlags = props.WidgetRadioBtn;
  const empId = props.coworkerId;
  const cardResponse = props.getCardstate;
  let AcPayment = props.AcceptPay;
  let ClubAcPayment = props.ClubPay;
  let ReceiptCatogory = props.Reciept;
  console.log(
    AcPayment,
    ClubAcPayment,
    ReceiptCatogory,
    "receipt array details"
  );
  const Agreement = props.AgreementIdProp;
  const customer = props.CustomerIdProp;
  const Changes = props.Paymentchange;
  const PaymentOrigin = props.PayOrigin;
  const getPaymentInfoResp = props.Taxfield;
  const acceptdisable = props.disabled;
  const cardAuthNo = props.cardAuthNumber;
  const lastFour = props.lastFour;
  const swipeCardAmt = props.swipeCardAmt;
  let finalagr = props.finalagr;
  const swipeFlag = props.swipeFlag;
  let actLDWArr = props.actLDWArr;
  let actPolicyArr = props.actPolicyArr;
  const cardDetail = props.cardDetail;
  const checkDetail = props.checkDetail;
  const taxGeoCode = props.taxGeoCode;
  const taxZip = props.taxZip;
  const origin = props.origin;
  const convTax = props.convTax;
  const profileAccResp = props.profileAccResp;
  const cardToken = props.cardToken;
  const chargeCardArray = props.chargeCard;
  const carryAmount = props.carryRent;
  const tenderTypeArray = props.tenderTypeArray;
  // const carryTaxRate=
  //let paymentMade = props.paymentMade;
  const billingAddressCharge = props.billingAddressCharge;
  const avaliableCredits = props.avaliableCredits;
  console.log(props, "props in accept payment");

  //Below state variables are declared to handle authentication popup flow
  const [TwoFactorCancelClick, setTwoFactorCancelClick] = useState(false);
  const [TwoFactorCompleted, setTwoFactorCompleted] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState();
  const [SecondFactorOpen, setSecondFactorOpen] = useState(false);
  const [CurrentRole, setCurrentRole] = useState("");
  const [AgreementIndex, setAgreementIndex]: any = useState();
  const [TranFail, setTranFail] = useState(false);
  const [TranFailtxtchange, setTranFailtxtchange] = useState(false);
  const [ReceiptURL, setReceiptURL] = useState<any>();
  const [ExtensionURL, setExtensionURL] = useState<any>();
  const [COFUrl, setCOFUrl] = useState<any>([]);
  const eventFrame = useRef<HTMLIFrameElement>(null);
  const [openCommitmentConfirmation, setopenCommitmentConfirmation] = useState(false);

  const [redirectToAccountManagement, setRedirectToAccountManagement] =
    useState(false);
  const [customerSignaturePopup, setcustomerSignaturePopup] =
    useState<any>(false);
  const [customerSignaturePopupInote, setcustomerSignaturePopupInote] =
    useState<any>(false);
  const [clubdocumentgenerate, setclubdocumentgenerate] = useState<any>(false);
  const [clubSignFlow, setclubSignFlow] = useState<any>(false);
  const [ReceiptURLArray, setReceiptURLArray] = useState<any>([]);
  const [receipturlCO, setreceipturlCO]: any = useState();
  const [recptCO, setrecptCO] = useState(false);
  const [selectedIndex, setSelectedIndex]: any = useState();
  const [viewReceiptPopupCO, setviewReceiptPopupCO]: any = useState(false);
   /** RIS_PC_NO_34,RIS_PC_NO_35,RISPC_NO_36 Rental Item Sale state variables*/
   const [largepaymentAlertpopupFlag,setlargepaymentAlertpopupFlag]=useState(false)
   const [reasonpopupFlag,setReasonpopupFlag]=useState(false)
   const [risFlag,setRisFlag]=useState<boolean>(false)
   const [reason,setReason]=useState<any>({reason:""})
   const [customerPresentpopupFlag,setcustomerPresentpopupFlag]=useState(false)
   const [changepopupFlag,setChangepopupFlag]=useState(false)
  const location = useLocation<any>();
  const ManualSignOptions = [
    {
      value: "",
      label: "Select",
    },
    {
      value: "3",
      label: "Technology issue - Digital Signing",
    },
    {
      value: "2",
      label: "Technology issue - Customer",
    },
    {
      value: "1",
      label: "Customer requires Paper Signature",
    },
  ];
  const [pifAgreementID, setPifAgreementID] = useState<any>("");
  useEffect(() => {
    const GettingRole = async () => {
      if (containerData != undefined) {
        const GetMenuDetails: any = containerData.GetRole();
        console.log("GetMenuDetails", GetMenuDetails);
        // const GetMenuDetailsResponse = GetMenuDetails.data;
        const currentRole = GetMenuDetails;
        console.log("currentRole", currentRole);
        setCurrentRole(currentRole);
      }
      // if (GetMenuDetails.status === 200) {

      // }
      // const GetcurrentUser = await GetEmployeeID();
      // console.log('GetcurrentUser', GetcurrentUser);
      // if (GetcurrentUser.status === 200) {
      //   const GetcurrentUserResponse = GetcurrentUser.data;
      //   const EmployeeId = GetcurrentUserResponse.employeeId;
      //   const GetMenuPayload = {
      //     coworkerId: EmployeeId,
      //   };

      // }
    };
    GettingRole();
  }, [containerData]);

  /**RIS_PC_NO_45 Inside the useEffect the condition check is done whether TwoFactorCompleted is true 
  * and risFlag is true.
  */
  useEffect(() => {
    /* eslint-disable no-debugger */
    if (TwoFactorCompleted === true) {
      setload(true);
      setSecondFactorOpen(false);
      extensionArrayUrl = [];
      cofArrayUrl = [];
      MakepaymentApiCall();
    }
    else if (TwoFactorCancelClick === true) {
      setload(false);
      setSecondFactorOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TwoFactorCancelClick, TwoFactorCompleted, EmployeeDetails])
  let AcceptMiscArray: any = [];
  let receiptCatArray: any = [];
  const MakepaymentApiCall = async () => {
    props.setpaymentMade(true);
    let cardlastFour: any = lastFour === "" ? undefined : lastFour;
    /* eslint-disable no-console */
    console.log("getcardResponse response----->", getcardResponse);
    if (actLDWArr.length > 0) {
      for (let i = 0; i < actLDWArr.length; i++) {
        // for(let i=0;i<actLDWArr.length;i++){

        // }
        let lDWRequest = {
          agreementId: actLDWArr[i].agreementId,
          action: actLDWArr[i].action,
        };
        const ldwAPIResult = await LDwServiceCall(lDWRequest);
        if (ldwAPIResult.status === 200) {
          AcPayment.map((obj) => {
            if (
              obj.agreementId == actLDWArr[i].agreementId &&
              actLDWArr[i].action == 1
            ) {
              obj.ldwActivate = 1;
            } else if (
              obj.agreementId == actLDWArr[i].agreementId &&
              actLDWArr[i].action == 2
            ) {
              obj.ldwActivate = 0;
            }
          });
        }
        if (actLDWArr[i].action == 2) {
          actLDWArr[i].action = 1;
        } else {
          actLDWArr[i].action = 2;
        }
      }
    }
    if (actPolicyArr.length > 0) {
      for (let i = 0; i < actPolicyArr.length; i++) {
        let PolicyRequest = {
          agreementId: actPolicyArr[i].agreementId,
          action: actPolicyArr[i].action,
        };
        await PolicyServiceCall(PolicyRequest);
        if (actPolicyArr[i].action == 2) {
          actPolicyArr[i].action = 1;
        } else {
          actPolicyArr[i].action = 2;
        }
      }
    }
    let documentDetail: any = [];
    for (let i = 0; i < checkDetail.length; i++) {
      let documentObj = {
        documentAmount: Number(checkDetail[i].Amount_rac),
        documentType: checkDetail[i].Type_info == "Check" ? "CHK" : "MO",
        documentNumber: checkDetail[i].check_no,
        documentIssuer: checkDetail[i].Written_by,
        documentPayee: checkDetail[i].Written_too,
      };
      documentDetail.push(documentObj);
    }
    const customerInfoParam: CustomerInfo = {
      FirstName: getPaymentInfoResp.paymentInfo.customerInfo[0].firstName,
      LastName: getPaymentInfoResp.paymentInfo.customerInfo[0].lastName,
    };
    let cardInfoParam: any = [];
    let BillAddressInfoParam: any;
    let chargeCardFlag = 0;
    let cardDetals: any = [];
    // if (
    if (
      getcardResponse.CardInfo !== null &&
      getcardResponse.CardInfo !== undefined
    ) {
      // cardlastFour = getcardResponse.CardInfo.CardLastFour;
      cardInfoParam = getcardResponse.CardInfo.filter(
        (e) => Number(e.Amount) > 0 && e.cvvEncrypt == "***"
      );
      if (chargeCardArray.length > 0) {
        cardInfoParam = [...cardInfoParam, ...chargeCardArray];
      }
      // let cardDetail = {
      //   cardAmount: Number(DecimalVal),
      //   cardAuthNumber: "",
      //   cardLastFour: lastFour,
      //   cardType: cardType,
      //   cardToken: cardToken,
      // };
      for (let i = 0; i < cardInfoParam.length; i++) {
        // const cardtoken:any = cardToken.length==0?"":cardToken.filter((e) => String(cardInfoParam[i].lastFour)==String(e.lastFour));
        cardDetals.push({
          cardAmount: Number(cardInfoParam[i].Amount),
          cardAuthNumber: "",
          cardLastFour: cardInfoParam[i].lastFour,
          cardType: cardInfoParam[i].cardType,
          cardToken: cardInfoParam[i].cardToken,
          cardTransactionType:
            cardInfoParam[i].chargeCard == false ? "PAYTOK" : "MNL",
        });
        cardInfoParam[i].billingAddress.addressLine2 =
          cardInfoParam[i].billingAddress.addressLine2 != null
            ? cardInfoParam[i].billingAddress.addressLine2
            : "";
      }
      for (let i = 0; i < cardDetail.length; i++) {
        cardDetals.push({
          cardAmount: Number(cardDetail[i].cardAmount),
          cardAuthNumber: cardDetail[i].cardAuthNumber,
          cardLastFour: cardDetail[i].cardLastFour,
          cardType: cardDetail[i].cardType,
          cardToken: cardDetail[i].cardToken,
          cardSequence: cardDetail[i].cardSequence,
          cardTransactionType: "SWP",
        });
        //cardDetail[i].billingAddress.addressLine2=cardDetail[i].billingAddress.addressLine2!=null?cardDetail[i].billingAddress.addressLine2:""
      }
      let mescellineasArrayReceipt: any = [];
      //  debugger;
      for (let i = 0; i < tenderTypeArray.length; i++) {
        // props.paygridvalues.filter((e) => String(cardInfoParam[i].lastFour)==String(e.lastFour));
        const misItemArray = props.paygridvalues.filter(
          (x: any) =>
            Number(
              tenderTypeArray[i].agreement_id.split("Miscellaneous Item ")[1]
            ) == Number(x.miscellaneousItemsNum)
        );
        // if(Number((tenderTypeArray[i].agreement_id).split("Miscellaneous Item ")[0].includes("Miscellaneous Item ")))
        if (misItemArray.length > 0) {
          let ReceiptArray: any = {
            customerId: null,
            schedule: "",
            amountDue: 0,
            ldwFeeAmount: 0,
            clubPayment: 0,
            clubPaymentTax: 0,
            creditOnAccountAdd: 0,
            creditOnAccountSub: Number(misItemArray[0].COA),
            integratedPaymentSuspense: 0,
            initialPayment: 0,
            netLateFee: 0,
            carriedLateFee: 0,
            wlDeposit: 0,
            htSuspense: 0,
            suspenseAdd: 0,
            suspenseSub: 0, //Number( payGridObj[0].suspense),
            racTirePay: 0,
            agreementId: null,
            change: 0,
            totalTax: 0,
            sac: 0,
            sacTax: 0,
            ldwFeeAmountTax: 0,
            rentalPaymentTax: 0,
            lateRentalPaymentfeeTax: 0,
            lateRentalPaymentfee: 0,
            racTirePayTax: 0,
            deliveryFee: 0,
            deliveryFeeTax: 0,
            processingFee: 0,
            processingFeeTax: 0,
            //otherFee: Number( payGridObj[0].mattressFee),
            //otherFeeTax: Number( payGridObj[0].mattressFeeTax),
            pif: 0,
            pifTax: 0,
            extensionAmount: 0,
            convenienceFee: 0,
            convenienceFeeTax: 0,
            mattressFee: 0,
            nineOneOneFee: Number(misItemArray[0].nineOneOneFee),
            cellPhoneActivation: Number(misItemArray[0].cellPhoneActivation),
            cellPhoneActivationTax: Number(
              misItemArray[0].cellPhoneActivationTax
            ),
          };
          mescellineasArrayReceipt.push(ReceiptArray);
          const miscArray = {
            terminalId: misItemArray[0].miscellaneousItemInfo.terminalId,

            sessionId: misItemArray[0].miscellaneousItemInfo.sessionId,

            actionId: misItemArray[0].miscellaneousItemInfo.actionId,

            saleId: misItemArray[0].miscellaneousItemInfo.saleId,

            requestId: misItemArray[0].miscellaneousItemInfo.requestId,

            sequenceNumber: misItemArray[0].miscellaneousItemInfo.apiCalc,

            txnId: misItemArray[0].miscellaneousItemInfo.txnId,

            productId: misItemArray[0].miscellaneousItemInfo.productId,

            amount: misItemArray[0].miscellaneousItemInfo.amount,

            shopId: misItemArray[0].miscellaneousItemInfo.shopId,

            cashier: misItemArray[0].miscellaneousItemInfo.cashier,

            consumerDiscount:
              misItemArray[0].miscellaneousItemInfo.consumerDiscount ==
              undefined
                ? "0.00"
                : misItemArray[0].miscellaneousItemInfo.consumerDiscount,

            serviceFee:
              misItemArray[0].miscellaneousItemInfo.serviceFee == undefined
                ? "0.00"
                : misItemArray[0].miscellaneousItemInfo.serviceFee,

            productName: String(misItemArray[0].miscellaneousItemInfo.type),

            saleAmount: String(
              misItemArray[0].miscellaneousItemInfo.salesPrice
            ),

            mobileNumber: String(
              misItemArray[0].miscellaneousItemInfo.phoneNumber
            ),

            carrier: String(misItemArray[0].miscellaneousItemInfo.carrierName),

            pricePlan: String(
              misItemArray[0].miscellaneousItemInfo.description
            ),

            totalSale: String(misItemArray[0].miscellaneousItemInfo.totalSales),

            simNumber: String(misItemArray[0].miscellaneousItemInfo.simNumber),

            customData: misItemArray[0].miscellaneousItemInfo.data,
          };
          AcceptMiscArray.push(miscArray);
        }
      }
      receiptCatArray = [...ReceiptCatogory, ...mescellineasArrayReceipt];

      // if (getcardResponse.CardInfo.BillingAddress != undefined) {
      //   BillAddressInfoParam = {
      //     Address1: getcardResponse.CardInfo.BillingAddress.addressLine1,
      //     Address2: getcardResponse.CardInfo.BillingAddress.addressLine2 !== null ? getcardResponse.CardInfo.BillingAddress.addressLine2 : '',
      //     City: getcardResponse.CardInfo.BillingAddress.city,
      //     StateProvince: getcardResponse.CardInfo.BillingAddress.state,
      //     PostalCode: getcardResponse.CardInfo.BillingAddress.zip,
      //     Country: 'USA',
      //     BillShipSameAddressFlag: "0",
      //   };
      // }
    }
    if (billingAddressCharge != undefined) {
      BillAddressInfoParam = billingAddressCharge;
      chargeCardFlag = 1;
    }
    let amount =
      cardInfoParam.length == 0
        ? 0.0
        : cardInfoParam
            .map((bill) => bill.Amount)
            .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2));
    let cardFlag = "0";
    if (amount > 0) {
      cardFlag = "1";
    }

    if (swipeFlag) {
      cardlastFour = lastFour;
      amount = Number(swipeCardAmt);
    }
    let transactionDate = new Date();
    for (let i = 0; i < cardDetail.length; i++) {
      if (cardDetail[i].cardAmount == 0) {
        cardDetail.splice(i, 1);
      }
    }

    for (let i = 0; i < ReceiptCatogory.length; i++) {
      ReceiptCatogory[i].schedule = ReceiptCatogory[i].schedule.split("|")[0];
    }
    let finalConfirm = 0;
    for (let i = 0; i < props.ClubPayFlag?.length; i++) {
      if (
        props.ClubPayFlag[i].Nextduedate == "PIF" ||
        props.ClubPayFlag[i].Nextduedate == "SAC" ||
        props.ClubPayFlag[i].Nextduedate == "EPO"
      ) {
        finalConfirm = 1;
        finalagr = Number(props.ClubPayFlag[i].Agreementid);
      }
    }
    if (finalConfirm != 1) {
      finalagr = undefined;
    }
    let webleadAmount: any = [];
    let creditOnAccount: any = [];
    let IPSuspense: any = [];

    if (props.availableCredsremaining.length > 0) {
      webleadAmount = props.availableCredsremaining.filter(
        (x) => x.balanceType === "WEBLEAD"
      );
      creditOnAccount = props.availableCredsremaining.filter(
        (x) => x.balanceType === "COA"
      );
      IPSuspense = props.availableCredsremaining.filter(
        (x) => x.balanceType === "IP Suspence"
      );
    }
    let suspenseUsed = 0;
    if (receiptCatArray?.length > 0) {
      for (let i = 0; i < receiptCatArray?.length; i++) {
        suspenseUsed = suspenseUsed + Number(receiptCatArray[i].suspenseSub);
      }
    }
    let transferSuspense: any = [];
    for (let i = 0; i < props?.paygridvalues?.length; i++) {
      if (
        Number(props?.paygridvalues[i].SuspenseAmount) !=
        Number(props?.paygridvalues[i].transferOldSuspense)
      ) {
        transferSuspense.push({
          currentBalance: Number(
            props?.paygridvalues[i].transferOldSuspense
          ).toFixed(2),
          newSuspenseBalance: Number(
            props?.paygridvalues[i].SuspenseAmount
          ).toFixed(2),
          agreementId: String(props?.paygridvalues[i].Agreementid),
          isTransferToCoa: props?.paygridvalues[i].isCOATransfered,
        });
      }
    }
    let totalDue: any = totalDueamt;
    const finalPaymentSuspenseuse = props.ClubPayFlag?.filter(
      (e) => Number(e.acceptEpoFlag) == 1 && Number(e.suspenseSub) > 0
    );
    for (let i = 0; i < finalPaymentSuspenseuse?.length; i++) {
      totalDue =
        Number(totalDue) + Number(finalPaymentSuspenseuse[i].suspenseSub);
    }
    let processOrderData: any = undefined;
    if (
      props.isCoPrelim &&
      (props.provideEAFlag == null || (props.provideEAFlag).toLowerCase() == "no")
    ) {
      processOrderData = {
        eaFlag: 'No',
        agreementNumber: props.provideAgrNumberPO,
      };
    } else if (props.isCoPrelim && (props.provideEAFlag).toLowerCase() == "yes") {
      const updatedPlaceOrderAddressData = props.providePOAddressData;
      updatedPlaceOrderAddressData.items = props.providePlaceOrderItemData;
      processOrderData = {
        eaFlag: 'Yes',
        agreementNumber: props.provideAgrNumberPO,
        shippingAddress: [updatedPlaceOrderAddressData],
      };
    }
    const addSuspenseAmount = props?.totalsuspensemaster?.filter(function (e) {
      return e.sustype == "Add" && Number(e.newsuspense) > 0;
    });
    const useSuspenseAmount =  props?.totalsuspensemaster?.filter(function (e) {
      return e.sustype == "Used" && Number(e.newsuspense) > 0;
    });
    let addSusTotalAmount =
      addSuspenseAmount?.length == 0
        ? 0.0
        : addSuspenseAmount?.map((bill) => bill.newsuspense)?.reduce((acc, bill) => (Number(bill) + Number(acc))?.toFixed(2));
    let usedSusTotalAmount =
      useSuspenseAmount?.length == 0
        ? 0.0
        : useSuspenseAmount?.map((bill) => bill.newsuspense)?.reduce((acc, bill) => (Number(bill) + Number(acc))?.toFixed(2));

    let totalAmountCollected = (Number(totalDue)+Number(addSusTotalAmount)-Number(usedSusTotalAmount))<0?0:Number((Number(totalDue)+Number(addSusTotalAmount)-Number(usedSusTotalAmount)).toFixed(2))
    const MakePaymentParam: MakePaymentDTO = {
      raftApiFlag: Number(props.configFlags.raftApiFlag),
      transferSuspense: transferSuspense,
      storeMerchantId: props.storeMerchantId || null,
      miscellaneousArray: AcceptMiscArray,
      recieptCategoryReqDetails: receiptCatArray,
      storeNumber: String(window.sessionStorage.getItem("storeNumber")),
      customerId: String(customer),
      storeCoWorkerId: String(empId),
      paymentOriginName: PaymentOrigin,
      totalAmountCollected: Number(Number(totalAmountCollected).toFixed(2)),
      totalTaxCollected: getPaymentInfoResp.totalTax,
      paymentId: String(
        getcardResponse.CardInfo !== null &&
          getcardResponse.CardInfo !== undefined &&
          swipeFlag !== true
          ? getcardResponse.CardInfo.customerPaymentMethodId
          : 0
      ),
      carryAmount: Number(carryAmount),
      taxRate: Number(props.paygridvalues[0].taxRate),
      cardAmount: Number(amount == null ? 0 : amount),
      cashAmount:
        props.throwPaymentAtOrder !== undefined
          ? Number((Number(props.throwPaymentAtOrder) +
            Number(getcardResponse.CashAmount)).toFixed(2))
          : getcardResponse.CashAmount,
      moneyOrderAmount: getcardResponse.MOAmount,
      checkAmount: getcardResponse.ChequeAmount,
      phoneNumber:
        getPaymentInfoResp.paymentInfo.customerInfo[0].phoneNumber == null ||
        undefined
          ? ""
          : getPaymentInfoResp.paymentInfo.customerInfo[0].phoneNumber,
      transactionDate: String(transactionDate),
      customerZip:
        getPaymentInfoResp.paymentInfo.customerInfo[0].zip +
        "-" +
        getPaymentInfoResp.paymentInfo.customerInfo[0].plus4,
      customerState: getPaymentInfoResp.paymentInfo.customerInfo[0].state,
      customerDOB: "02/22/1950",
      chargeCardFlag: chargeCardFlag,
      //paymentAmount: Number(Number(totalDueamt).toFixed(2)),
      cardDetails: cardDetals,
      documentDetails: documentDetail,
      paymentAmount: Number(
        getcardResponse.CashAmount +
          getcardResponse.MOAmount +
          getcardResponse.ChequeAmount +
          Number(amount == null ? 0 : amount) +
          getcardResponse.AvailableCredits +
          Number(
            props.throwPaymentAtOrder !== undefined
              ? props.throwPaymentAtOrder
              : 0
          )
      ),
      cardFlag: cardFlag,
      emailAddress:
        getPaymentInfoResp.paymentInfo.customerInfo[0].emailAddress == null ||
        undefined
          ? ""
          : getPaymentInfoResp.paymentInfo.customerInfo[0].emailAddress,
      originFee: "0.00",
      totalAmountDue: Number(Number(totalDue).toFixed(2)),
      wlDeposit:
        webleadAmount.length > 0
          ? Number(webleadAmount[0].accountBalance)
          : 0.0,
      itSuspence:
        IPSuspense.length > 0 ? Number(IPSuspense[0].accountBalance) : 0.0,
      suspense: Number(props.Suspense),
      changeFlag: radioFlags,
      taxGeoCodeId: taxGeoCode,
      zipPlus4UsedForTax: taxZip,
      customerInfo: customerInfoParam,
      cardInfo: cardInfoParam,
      // billToAddresInfo: BillAddressInfoParam,
      creditOnAccount:
        creditOnAccount.length > 0
          ? Number(creditOnAccount[0].accountBalance)
          : 0.0,
      // change: Number( getcardResponse.CashAmount+
      //   getcardResponse.MOAmount+
      //   getcardResponse.ChequeAmount +
      //   0.00) - Number(Number(totalDueamt).toFixed(2)),
      change:
        radioFlags == "0"
          ? Number(Number(fixedChange(Changes)).toFixed(2))
          : 0.0,
      acceptPayment: AcPayment,
      clubActivate: props.clubActivate,
      acceptClubPayment: ClubAcPayment,
      cardAuthNumber: cardAuthNo,
      cardLastFour: cardlastFour,
      placeOrderDetails: processOrderData,
      // cardToken: (cardFlag == '1' && getcardResponse.CardInfo.BillingAddress != undefined ? getcardResponse.CardInfo.BillingAddress.cardToken : undefined)
    };
    MakePaymentParam?.acceptPayment?.forEach((item, index) => {
      const itemPaygrid = props.paygridvalues.filter(
        (e) => String(item.agreementId) == String(e.Agreementid)
      );
      item.lateFeeWaivedAmount =
        Number(itemPaygrid[0].waivedLateFee) == 0
          ? undefined
          : Number(itemPaygrid[0].waivedLateFee).toFixed(2);
    }),
      MakePaymentParam.recieptCategoryReqDetails.forEach((item, index) => {
        let susAmt = 0;
        let coaAmt = 0;
        let changeNum = Number(Number(Changes).toFixed(2));
        const installmentNoteAgrFilter = props.paygridvalues.filter(
          (e) =>
            Number(e.agreementNoteDetails.isNoteAgreement) == 1 &&
            String(item.agreementId) == String(e.Agreementid)
        );
        const itemPaygrid = props.paygridvalues.filter(
          (e) => String(item.agreementId) == String(e.Agreementid)
        );
        item.suspenseSub = itemPaygrid[0]?.suspenseSub;
        if (installmentNoteAgrFilter?.length > 0) {
          if (installmentNoteAgrFilter[0].Agreementtype == "Note Payment") {
            item.installmentNote = {
              type: "Note",

              amount: Number(item.amountDue).toFixed(2),

              taxAmount: "0.00",
            };
            item.amountDue = 0.0;
            item.rentalPaymentTax = 0.0;
          }
          if (
            (installmentNoteAgrFilter[0].Nextduedate == "SAC" ||
              installmentNoteAgrFilter[0].Nextduedate == "EPO" ||
              installmentNoteAgrFilter[0].Nextduedate == "PIF") &&
            installmentNoteAgrFilter[0].acceptEpoFlag == 0
          ) {
            // item.amountDue=0.00
            // item.rentalPaymentTax=0.00

            if (installmentNoteAgrFilter[0].Nextduedate == "SAC") {
              item.sac = Number(
                (
                  Number(item.sac) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),
              };
            } else if (installmentNoteAgrFilter[0].Nextduedate == "EPO") {
              item.epo = Number(
                (
                  Number(item.epo) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  )
                ).toFixed(2)
              );
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),
              };
            } else if (installmentNoteAgrFilter[0].Nextduedate == "PIF") {
              item.pif =
                installmentNoteAgrFilter[0].Agreementtype == "PIF NOTE"
                  ? 0.0
                  : Number(
                      (
                        Number(item.pif) -
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .NotAmountFinal
                        )
                      ).toFixed(2)
                    );
              if (installmentNoteAgrFilter[0].Agreementtype == "PIF NOTE") {
                item.amountDue = 0.0;
                item.rentalPaymentTax = 0.0;
              }
              if (
                Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .NotAmountFinal
                ) > 0 && installmentNoteAgrFilter[0].Agreementtype == "PIF NOTE"
              ) {
                item.installmentNote = {
                  type:
                    installmentNoteAgrFilter[0].Agreementtype == "PIF NOTE"
                      ? "PIF Note"
                      : installmentNoteAgrFilter[0].Nextduedate,

                  amount: Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ).toFixed(2),

                  taxAmount: "0.00",
                };
              }
              
              if (
                Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .NotAmountFinal
                ) > 0 && installmentNoteAgrFilter[0].Agreementtype == "PIF Rent"  &&
                installmentNoteAgrFilter[0].Nextduedate=="PIF"
              ) {
                item.installmentNote = {
                  type:"Note",
                  amount: Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .NotAmountFinal
                  ).toFixed(2),

                  taxAmount: "0.00",
                };
              }
            }
          } else if (
            (installmentNoteAgrFilter[0].Nextduedate == "SAC" ||
              installmentNoteAgrFilter[0].Nextduedate == "EPO" ||
              installmentNoteAgrFilter[0].Nextduedate == "PIF") &&
            installmentNoteAgrFilter[0].acceptEpoFlag == 1
          ) {
            if (
              installmentNoteAgrFilter[0].Nextduedate == "SAC" &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) != 0
            ) {
              // let epoAmount=Number(((Number(item.sac)-Number(item.pastDueAmount)+Number(item.pastDueDiscount==undefined?0:item.pastDueDiscount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))<0?0.00:(Number(item.sac)-Number(item.pastDueAmount)- Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))).toFixed(2))
              // item.sac=Number((Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingAgreementCost)+Number(item.pastDueAmount)-Number(item.pastDueDiscount)).toFixed(2))
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                0
                  ? 0.0
                  : Number(item.epoDiscount) -
                    Number(
                      installmentNoteAgrFilter[0].agreementNoteDetails
                        .remainingNoteCostAmount
                    )
                ).toFixed(2)
              );
              item.sac = Number(
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingAgreementCost
                  ) -
                  Number(item.epoDiscount) -
                  Number(item.pastDueDiscount)
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == "0.00"
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .remainingNoteCostAmount
                        ) -
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .editedNoteAmount
                        )
                      ).toFixed(2)
                    : "0.00"
                );
              }
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),

                discount: Number(noteDiscount).toFixed(2),
              };
            } else if (
              installmentNoteAgrFilter[0].Nextduedate == "EPO" &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) != 0
            ) {
              // let epoAmount=Number(((Number(item.epo)-Number(item.pastDueAmount)+Number(item.pastDueDiscount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))<0?0.00:(Number(item.epo)-Number(item.pastDueAmount)- Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount))).toFixed(2))
              // item.epo=Number((Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingAgreementCost)+Number(item.pastDueAmount)-Number(item.pastDueDiscount)).toFixed(2))
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                0
                  ? 0.0
                  : Number(item.epoDiscount) -
                    Number(
                      installmentNoteAgrFilter[0].agreementNoteDetails
                        .remainingNoteCostAmount
                    )
                ).toFixed(2)
              );
              item.epo = Number(
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingAgreementCost
                  ) -
                  Number(item.epoDiscount) -
                  Number(item.pastDueDiscount)
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == "0.00"
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .remainingNoteCostAmount
                        ) -
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .editedNoteAmount
                        )
                      ).toFixed(2)
                    : "0.00"
                );
              }
              item.installmentNote = {
                type:
                  installmentNoteAgrFilter[0].Agreementtype == "Epo Note"
                    ? "EPO Note"
                    : "EPO",

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCost
                ).toFixed(2),

                taxAmount: (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .noteAmountDueIncTax
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCost
                  )
                ).toFixed(2),

                discount: Number(noteDiscount).toFixed(2),
              };
            }
            if (
              installmentNoteAgrFilter[0].Nextduedate == "SAC" &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) == 0
            ) {
              item.sac = Number(
                (Number(item.sac) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                0
                  ? 0.0
                  : Number(item.sac) -
                    Number(
                      installmentNoteAgrFilter[0].agreementNoteDetails
                        .remainingNoteCostAmount
                    )
                ).toFixed(2)
              );
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                0
                  ? 0.0
                  : Number(item.epoDiscount) -
                    Number(
                      installmentNoteAgrFilter[0].agreementNoteDetails
                        .remainingNoteCostAmount
                    )
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == "0.00"
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .remainingNoteCostAmount
                        ) -
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .editedNoteAmount
                        )
                      ).toFixed(2)
                    : "0.00"
                );
              }
              item.installmentNote = {
                type: installmentNoteAgrFilter[0].Nextduedate,

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCostAmount
                ).toFixed(2),

                taxAmount: "0.00",

                discount: Number(noteDiscount).toFixed(2),
                //Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)>0?(Number(installmentNoteAgrFilter[0].agreementNoteDetails.remainingNoteCostAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)).toFixed(2):"0.00",
              };
              item.amountDue = 0.0;
              item.rentalPaymentTax = 0.0;
            } else if (
              installmentNoteAgrFilter[0].Nextduedate == "EPO" &&
              Number(
                installmentNoteAgrFilter[0].agreementNoteDetails
                  .remainingAgreementCost
              ) == 0
            ) {
              item.epo = Number(
                (Number(item.epo) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                0
                  ? 0.0
                  : Number(item.epo) -
                    Number(
                      installmentNoteAgrFilter[0].agreementNoteDetails
                        .remainingNoteCostAmount
                    )
                ).toFixed(2)
              );
              // let epodiscount = (Number(installmentNoteAgrFilter[0].agreementNoteDetails.editedNoteAmount)-Number(installmentNoteAgrFilter[0].agreementNoteDetails.noteAmountDueIncTax))<=0?0:
              item.epoDiscount = Number(
                (Number(item.epoDiscount) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) <
                0
                  ? 0.0
                  : Number(item.epoDiscount) -
                    Number(
                      installmentNoteAgrFilter[0].agreementNoteDetails
                        .remainingNoteCostAmount
                    )
                ).toFixed(2)
              );
              let noteDiscount = 0;
              if (
                (
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .remainingNoteCostAmount
                  ) -
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  )
                ).toFixed(2) == "0.00"
              ) {
                noteDiscount = Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .editedNoteAmount
                );
              } else {
                noteDiscount = Number(
                  Number(
                    installmentNoteAgrFilter[0].agreementNoteDetails
                      .editedNoteAmount
                  ) > 0
                    ? (
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .remainingNoteCostAmount
                        ) -
                        Number(
                          installmentNoteAgrFilter[0].agreementNoteDetails
                            .editedNoteAmount
                        )
                      ).toFixed(2)
                    : "0.00"
                );
              }
              item.installmentNote = {
                type:
                  installmentNoteAgrFilter[0].Agreementtype == "Epo Note"
                    ? "EPO Note"
                    : "EPO",

                amount: Number(
                  installmentNoteAgrFilter[0].agreementNoteDetails
                    .remainingNoteCostAmount
                ).toFixed(2),

                taxAmount: "0.00",

                discount: Number(noteDiscount).toFixed(2),
              };
              item.amountDue = 0.0;
              item.rentalPaymentTax = 0.0;
            }
          }
        }

        if (
          radioFlags == "1" &&
          Number(Changes) > 0 &&
          Number(Changes) >=
            Number(
              Number(totalDueamt) -
                Number(avaliableCredits) -
                Number(carryAmount)
            )
        ) {
          susAmt = radioFlags == "1" && changeNum >= 0 ? changeNum : 0;
        }

        if (
          radioFlags == "1" &&
          Number(Changes) > 0 &&
          props.suspensehide == false &&
          props.CreditRadio == true
        ) {
          coaAmt = radioFlags == "1" && changeNum >= 0 ? changeNum : 0;
        }
        item.change = Number((0).toFixed(2));
        //Web lead
        if (webleadAmount.length > 0) {
          item.wlDeposit = -1 * Number(webleadAmount[0].accountBalance);
        } else {
          item.wlDeposit = 0;
        }

        // COA
        if (creditOnAccount.length > 0) {
          item.creditOnAccountSub = Number(creditOnAccount[0].accountBalance);
        } else {
          item.creditOnAccountSub = 0;
        }

        //IP Suspense
        if (IPSuspense.length > 0) {
          item.integratedPaymentSuspense =
            -1 * Number(IPSuspense[0].accountBalance);
        } else {
          item.integratedPaymentSuspense = 0;
        }

        item.creditOnAccountAdd = coaAmt;
        // if(radioFlags == "1" && changeNum >= 0 &&Number(item.suspenseAdd)==0){
        //   item.suspenseAdd = susAmt;
        // }
        // item.suspenseAdd = susAmt;
        if (PaymentOrigin == "Pay By Phone") {
          item.convenienceFee = Number(props.ConvFeeWithOutTax);
          item.convenienceFeeTax = convTax;
        }
      });
    let acceptPaymentApi;
    let acceptApiCall = await MakePayment(MakePaymentParam);
    let placeOrderResponse;
    setMakepaymentResp(acceptApiCall);
    props.setAcceptpaymentbuttonenable(true);
    props.setAcceptpaymentbuttonenable(true);
    if (acceptApiCall.status === 200) {
      if(MakePaymentParam.placeOrderDetails){
        const payload={
          placeOrderDetails:MakePaymentParam.placeOrderDetails
        }
        placeOrderResponse = await PlaceOrderCO(payload);
        if(placeOrderResponse?.status==200){
          acceptPaymentApi = await AcceptPaymentApiCall(acceptApiCall);
        }else{
          setTranFail(true);
          setTranFailtxtchange(true);
          setload(false);
        }
        
      }else{
        acceptPaymentApi = await AcceptPaymentApiCall(acceptApiCall);
      }
     
      // if(acceptPaymentApi.status===400){
      //   if(acceptPaymentApi.errors[0].error=="Failed to do the cellphone activation"){
      //     setepayFail("Failed to do the cellphone activation")
      //   }
      // }
    } else if (acceptApiCall.status === 403 && acceptApiCall.status !== 200) {
      setTranFail(true);
      setTranFailtxtchange(true);
      setload(false);
      // if (actLDWArr.length > 0) {
      //   for (let i = 0; i < actLDWArr.length; i++) {
      //     await LDwServiceCall(actLDWArr[i]);
      //     if (actLDWArr[i].action == 1) {
      //       ActClubApiCall(actLDWArr[i].agreementId, actLDWArr[i].action);
      //     }
      //   }
      // }
    } else {
      setTranFail(true);
      setTranFailtxtchange(false);
      setload(false);
      if(acceptApiCall.status === 400 && acceptApiCall?.data?.errors[0].error?.serviceResponse?.Body?.StatusInfo?.StatusCodeDetail) {
        setCardPaymentFailedMessage(acceptApiCall?.data?.errors[0].error?.statusCodeDetail+
        acceptApiCall.data.errors[0].error.serviceResponse.Body.StatusInfo.StatusCodeDetail=="INSUFFICIENT FUNDS"?" - "+"Insufficient funds in this card, please retry with different card.":"Please try with different card / Retry");
      }
      // if (actLDWArr.length > 0) {
      //   for (let i = 0; i < actLDWArr.length; i++) {
      //     await LDwServiceCall(actLDWArr[i]);
      //     if (actLDWArr[i].action == 1) {
      //       ActClubApiCall(actLDWArr[i].agreementId, actLDWArr[i].action);
      //     }
      //   }
      // }
    }
    /* eslint-disable no-console */
    console.log("AcceptPayment response----->", acceptApiCall);
  };
  const getCustDetail = () => {
    let arr: any = [];
    if (props?.customerData?.data?.GetCustomer?.value) {
      let obj: any = {
        customerId: props?.customerData?.data?.GetCustomer?.value?.customerId,
        customerName:
          props?.customerData?.data?.GetCustomer?.value.firstName +
          " " +
          props?.customerData?.data?.GetCustomer?.value.lastName,
        phoneNumber:
          props?.customerData?.data?.GetCustomer?.value?.phones[0]?.phoneNumber,
        emailId: props?.customerData?.data?.GetCustomer?.value?.emailAddress,
      };
      arr.push(obj);
    }
    return arr;
    // setcustomerData(arr)
  };
  const fixedChange = (value: string) => {
    if (value == undefined || value == null || parseFloat(value) < 0.0) {
      return "0.00";
    } else {
      return String(parseFloat(value).toFixed(2));
    }
  };
  const AgreementCustApiCall = async () => {
    console.log(
      getPaymentInfoResp.paymentInfo.customerInfo[0].salesLead,
      "sales lead accept payment"
    );
    if (getPaymentInfoResp.paymentInfo.customerInfo[0].salesLead !== null) {
      // let schedule = AgreementIdList[i].schedule.split("|");
      // let agrNumber = "";
      // if (schedule.length == 2) {
      //   agrNumber = schedule[1];
      // }
      let agreementObj = getPaymentInfoResp.paymentInfo.paymentInfo.filter(
        (e) => (e.agreementId = Agreement)
      );
      let salesLeadReq: salesLeadRequest = {
        agreementNumber: String(agreementObj[0].agreementNumber),
        agreementStatus: "",
        partyId: "",
        leadId: String(
          getPaymentInfoResp.paymentInfo.customerInfo[0].salesLead
        ),
        leadStatus: "Converted",
        loginName: String(empId),
      };
      SalesLead(salesLeadReq);
    }
    // let AgreementIdList = ReceiptCatogory.filter(x => x.initialPayment === 1);
    // for (let i = 0; i < AgreementIdList.length; i++) {
    //   //let agrId = AgreementIdList[i].agreementId;
    //   //let AgreementcustCall = await AgreementCustomer(agrId);

    // }

    //MakepaymentApiCall();
  };
  const b64toBlob = (
    b64Data: any,
    contentType = "application/pdf",
    sliceSize = 512
  ) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const ReceiptpdfgenApiCall = async (payload, acceptpaymentRes) => {
    /* eslint-disable no-console */
    console.log("entered into the receipt api call", payload.receiptId);
    let receiptResponse = await Receiptpdfgen(payload);
    console.log("receiptResponse", receiptResponse);
    setload(false);
    if (receiptResponse.status === 200) {
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        if (
          Number(acceptpaymentRes.data?.cellPhoneActivation[i]?.result) > 0 &&
          acceptpaymentRes.data?.cellPhoneActivation[i]?.resultText
            ?.split("+")[0]
            ?.includes("Failed to do the cellphone activation ")
        ) {
          setepayFail(true);
        }
      }
      settran(true);
      if (Agreement > 0) {
        AgreementCustApiCall();
      }
    } else if (
      receiptResponse.status !== 200 &&
      receiptResponse.status !== 403
    ) {
      setTranFail(true);
      setTranFailtxtchange(false);
    } else {
      setTranFail(true);
      setTranFailtxtchange(true);
    }

    /* eslint-disable no-console */

    setreceipturl(receiptResponse);
    setReceiptURL(
      URL.createObjectURL(
        b64toBlob(receiptResponse.data.receiptHtmlUrl, "text/html")
      )
    );
    if (
      receiptResponse.data.htmlUrl.length > 0 &&
      receiptResponse.data.htmlUrl != undefined
    ) {
      setcofPopup(true);
      for (let i = 0; i < receiptResponse.data.htmlUrl.length; i++) {
        let url = URL.createObjectURL(
          b64toBlob(receiptResponse.data.htmlUrl[i], "text/html")
        );
        cofArrayUrl.push(url);
      }
      setCOFUrl(cofArrayUrl);
    }
    if (
      receiptResponse.data.extensionHtmlUrl != undefined &&
      receiptResponse.data.extensionHtmlUrl.length > 0
    ) {
      setextPopup(true);
      for (let i = 0; i < receiptResponse.data.extensionHtmlUrl.length; i++) {
        let url = URL.createObjectURL(
          b64toBlob(receiptResponse.data.extensionHtmlUrl[i], "text/html")
        );
        extensionArrayUrl.push(url);
      }
      console.log(extensionArrayUrl, "array for ext receipt");
      setExtensionURL(extensionArrayUrl);
    }
    // window.open(receiptResponse.receiptHtmlUrl, '_self');
  };
  const ReceiptpdfgenApiCallCO = async (
    payload,
    acceptpaymentRes,
    epayFail,
    cellPhoneArray,
    resultTextBase64
  ) => {
    /* eslint-disable no-console */
    console.log("entered into the receipt api call", payload);
    const res = await Promise.all(
      payload.map((el: any) =>
        Receiptpdfgen({
          receiptId: Number(el),
          agreementId: finalagr == null ? undefined : finalagr,
          resultText: epayFail == 0 ? resultTextBase64 : "",
          cellPhoneActivation: cellPhoneArray,
          termsArray: acceptpaymentRes.data.termsArray,
        })
      )
    );
    console.log("res of the awaited pdf response", res);
    // let receiptResponse = await Receiptpdfgen(payload);
    // console.log('receiptResponse', receiptResponse);
    setload(false);
    if (res !== undefined) {
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        if (
          Number(acceptpaymentRes.data?.cellPhoneActivation[i]?.result) > 0 &&
          acceptpaymentRes.data?.cellPhoneActivation[i]?.resultText
            ?.split("+")[0]
            ?.includes("Failed to do the cellphone activation ")
        ) {
          setepayFail(true);
        }
      }
      settran(true);
      if (Agreement > 0) {
        AgreementCustApiCall();
      }
    } else if (res == undefined) {
      setTranFail(true);
      setTranFailtxtchange(false);
    } else {
      setTranFail(true);
      setTranFailtxtchange(true);
    }
    let collectedURL: any = [];
    if (res !== undefined) {
      res.map((obj: any, index) => {
        const blobURLGenerated: any = URL.createObjectURL(
          b64toBlob(obj.data.receiptHtmlUrl, "text/html")
        );
        collectedURL.push(blobURLGenerated);
      });
    }
    setReceiptURLArray(collectedURL);
    setreceipturlCO(res);
    /* eslint-disable no-console */

    // setreceipturl(receiptResponse);
    // setReceiptURL(
    //   URL.createObjectURL(
    //     b64toBlob(receiptResponse.data.receiptHtmlUrl, 'text/html')
    //   )
    // );
    // if (receiptResponse.data.htmlUrl.length > 0 && receiptResponse.data.htmlUrl != undefined) {
    //   setcofPopup(true);
    //   for (let i = 0; i < receiptResponse.data.htmlUrl.length; i++) {
    //     let url = URL.createObjectURL(
    //       b64toBlob(receiptResponse.data.htmlUrl[i], 'text/html')
    //     );
    //     cofArrayUrl.push(url);
    //   }
    //   setCOFUrl(cofArrayUrl)

    // }
    // if (receiptResponse.data.extensionHtmlUrl != undefined && receiptResponse.data.extensionHtmlUrl.length > 0) {
    //   setextPopup(true);
    //   for (let i = 0; i < receiptResponse.data.extensionHtmlUrl.length; i++) {
    //     let url = URL.createObjectURL(
    //       b64toBlob(receiptResponse.data.extensionHtmlUrl[i], 'text/html')
    //     );
    //     extensionArrayUrl.push(url);
    //   }
    //   console.log(extensionArrayUrl, "array for ext receipt")
    //   setExtensionURL(extensionArrayUrl);

    // }
    // window.open(receiptResponse.receiptHtmlUrl, '_self');
  };
  const ActClubApiCall = async (agreementId: number, action: number) => {
    let payObj = getPaymentInfoResp.paymentInfo.paymentInfo.filter(
      (e) => (e.agreementId = agreementId)
    );
    let paymentScheduleId;
    if (payObj[0].paymentSchedule === "Bi-Weekly") {
      paymentScheduleId = 1;
    } else if (payObj[0].paymentSchedule === "Weekly") {
      paymentScheduleId = 2;
    } else if (payObj[0].paymentSchedule === "Monthly") {
      paymentScheduleId = 3;
    } else if (payObj[0].paymentSchedule === "Semi-Monthly") {
      paymentScheduleId = 4;
    }
    let req = {
      paymentScheduleId: paymentScheduleId,
      customerId: String(customer),
      isCreate: action,
      storeNumber: String(window.sessionStorage.getItem("storeNumber")),
    };
    await ActiveClub(req);
  };
  const AcceptPaymentApiCall = async (acceptApiCall) => {
    let processOrderData: any = undefined;
    if (
      props.isCoPrelim &&
      (props.provideEAFlag == null || props.provideEAFlag == "No")
    ) {
      processOrderData = {
        eaFlag: 'No',
        agreementNumber: props.provideAgrNumberPO,
      };
    } else if (props.isCoPrelim && props.provideEAFlag == "Yes") {
      const updatedPlaceOrderAddressData = props.providePOAddressData;
      updatedPlaceOrderAddressData.items = props.providePlaceOrderItemData;
      processOrderData = {
        eaFlag: props.provideEAFlag,
        agreementNumber: props.provideAgrNumberPO,
        shippingAddress: [updatedPlaceOrderAddressData],
      };
    }
    const acceptPaymentReq: AcceptPaymentRequest = {
      raftApiFlag: Number(props.configFlags.raftApiFlag),
      createReceiptReq: acceptApiCall.data.createReceiptReq,
      email: acceptApiCall.data.email,
      phoneNumber: acceptApiCall.data.phoneNumber,
      transactionId: acceptApiCall.data.transactionId,
      miscellaneousArray: acceptApiCall.data.miscellaneousArray,
      placeOrderDetails: processOrderData,
    };

    let acceptpaymentRes: any = await AcceptPaymentApi(acceptPaymentReq);
    if (acceptpaymentRes.status === 200) {
      AcPayment = [];
      ReceiptCatogory = [];
      ClubAcPayment = undefined;
      settxtChange(true);
      if (props.activateClubFeatureFlag == 1) {
        let payload: any = {
          paymentSchedule: null,
          customerId: Number(customer),
          isCreate: 0,
          agreementId: null,
          clubStatusRefCode: "C",
        };
        const clubFlag = props.ClubPayFlag.filter(
          (x: any) => x.Agreementtype == "Club"
        );
        if (
          clubBtnStatus == "P" &&
          clubFlag.length == 0 &&
          props.clubCheckDisabled == true
        ) {
          await ActiveClub(payload);
        }
      }
      window.sessionStorage.setItem("LDWaction", String(""));
      const resultTextBase64 = btoa(acceptpaymentRes.data.resultText);
      let cellPhoneArray: any = [];
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        let cashsale = {
          carrier: acceptpaymentRes.data?.cellPhoneActivation[i].carrier,
          mobileNumber:
            acceptpaymentRes.data?.cellPhoneActivation[i].mobileNumber,
          paymentCharge:
            acceptpaymentRes.data?.cellPhoneActivation[i].paymentCharge,
          pricePlan: acceptpaymentRes.data?.cellPhoneActivation[i].pricePlan,
          productId: acceptpaymentRes.data?.cellPhoneActivation[i].productId,
          productName:
            acceptpaymentRes.data?.cellPhoneActivation[i].productName,
          saleAmount: acceptpaymentRes.data?.cellPhoneActivation[i].saleAmount,
          saleDate: acceptpaymentRes.data?.cellPhoneActivation[i].saleDate,
          simNumber: acceptpaymentRes.data?.cellPhoneActivation[i].simNumber,
          terminalId: acceptpaymentRes.data?.cellPhoneActivation[i].terminalId,
          totalSale: acceptpaymentRes.data?.cellPhoneActivation[i].totalSale,
          transactionId:
            acceptpaymentRes.data?.cellPhoneActivation[i].transactionId,
          serviceFee: acceptpaymentRes.data?.cellPhoneActivation[i].serviceFee,
          consumerDiscount:
            acceptpaymentRes.data?.cellPhoneActivation[i].consumerDiscount,
        };
        cellPhoneArray.push(cashsale);
        // acceptpaymentRes.data?.cellPhoneActivation[i]?.result=undefine
      }
      let epayFail = 0;
      for (
        let i = 0;
        i < acceptpaymentRes.data?.cellPhoneActivation?.length;
        i++
      ) {
        if (
          Number(acceptpaymentRes.data?.cellPhoneActivation[i]?.result) > 0 &&
          acceptpaymentRes.data?.cellPhoneActivation[i]?.resultText
            ?.split("+")[0]
            ?.includes("Failed to do the cellphone activation ")
        ) {
          epayFail = 1;
        }
      }

      let pdfReq = {};

      if (
        acceptpaymentRes.data.receiptId !== undefined &&
        acceptpaymentRes.data.coReceiptId == undefined
      ) {
        pdfReq = {
          receiptId: Number(acceptpaymentRes.data.receiptId),
          agreementId: finalagr == null ? undefined : finalagr,
          resultText: epayFail == 0 ? resultTextBase64 : "",
          cellPhoneActivation: cellPhoneArray,
          termsArray: acceptpaymentRes.data.termsArray,
        };
        await ReceiptpdfgenApiCall(pdfReq, acceptpaymentRes);
      } else if (acceptpaymentRes.data.coReceiptId !== undefined) {
        await ReceiptpdfgenApiCallCO(
          acceptpaymentRes.data.coReceiptId,
          acceptpaymentRes,
          epayFail,
          cellPhoneArray,
          resultTextBase64
        );
      }

      //await ReceiptpdfgenApiCall(pdfReq,acceptpaymentRes);
    } else {
      setload(false);
      // if (actLDWArr.length > 0) {
      //   for (let i = 0; i < actLDWArr.length; i++) {
      //     await LDwServiceCall(actLDWArr[i]);
      //     if (actLDWArr[i].action == 1) {
      //       ActClubApiCall(actLDWArr[i].agreementId, actLDWArr[i].action);
      //     }
      //   }
      // }
      console.log("epayrescheck", acceptpaymentRes);
      if (
        acceptpaymentRes?.data?.errors[0]?.error
          ?.split("+")[0]
          ?.includes("Failed to do the cellphone activation ")
      ) {
        setepayFail(true);
        setTranFail(true);
      } else {
        setTranFail(true);
      }
    }
  };

  const commitmentConfirmation = () =>{
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <Typography className={`${classes.bold} ${classes.titletxtstyle}`}>
            Do you want to take recommitment ?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.mt4}`}
        >
          <RACButton
            className={`${classes.me2}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={ReceiptOk}
          >
            No
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              history.push({
                pathname: `/am/customer/${customerId}`,
                search: `?type=RECOMMIT`,
              });
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  }

  const OwnershipTray = () => {
    const getUrl = window.location.href;
    if (cofUrlState !== undefined && cofUrlState.cofurl !== "") {
      setTray1(true);
      settran(false);
    }
    if (
      receipturlCO !== undefined &&
      receipturlCO[0].status === 200 &&
      (getUrl.includes("/payment1/customerorder/preliminary/") ||
        getUrl.includes("/payment1/customerorder/initial/"))
    ) {
      setrecptCO(true);
    } else if (receipturl.status === 200) {
      console.log("entered into the redirection condition");
      setrecpt(true);
    }
    setepayFail(false);
    settran(false);
  };

/**RIS_PC_NO_44 showPopup() function is triggered when Accept payment button is clicked and inside the 
   * function condition check is done 
   */
const showPopup = () => {
  // debugger;
  // if (props?.checkedAgr?.length == 1 && props?.checkedAgr[0]?.Agreementtype == "Rental Item Sale"
  //   && props?.checkedAgr[0]?.Amount > 1000
  //   // props.Paymentchange>props.checkedAgr[0].Amount
  // ) {
  //   setlargepaymentAlertpopupFlag(true)
  // } else {
    setTwoFactorCompleted(false);
    setTwoFactorCancelClick(false);
    setSecondFactorOpen(true);
  // }
  
};

  const ReceiptOk = () => {
    if (props.activateClubFeatureFlag == 1) {
      const clubInformation = props.paygridvalues.filter(
        (x: any) => x.Agreementtype == "Club"
      );
      const installmentInfo = props.ClubPayFlag.filter(
        (x: any) =>
          x.Agreementtype == "PIF Rent" &&
          x.agreementNoteDetails.isNoteAgreement == 1
      );
      const clubFlag = props.ClubPayFlag.filter(
        (x: any) => x.Agreementtype == "Club"
      );
      if (
        clubInformation[0] == undefined
          ? false
          : (clubInformation[0].clubDocumentSignStatus == null ||
              clubInformation[0].clubDocumentSignStatus == "UK") &&
            clubInformation[0].originAgreementId == null &&
            clubFlag.length > 0 && clubInformation[0]?.number_of_paymentsmade==0
      ) {
        customerDocGen();
        setcustomerSignaturePopup(true);
        setrecpt(false);
      } else if (installmentInfo?.length > 0) {
        installmentAgrSignFlow(installmentInfo);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        let returnAGRParamValue:any;
        let CPSAGRParamValue:any;
        const returnType = urlParams.get("type")
        if(returnType=="RETURN"||returnType=="CHRGOFF"){
         returnAGRParamValue = urlParams.get("agreementId");
         CPSAGRParamValue = urlParams.get("cps");
        }
        if (Number(returnAGRParamValue) > 0) {
          history.push({
            pathname: `/agreement/info/details/${customer}/${returnAGRParamValue}`,
            search: `?type=${returnType}`,
          });
        } else {
          redirectionFunc();
        }
      }
    } else {
      redirectionFunc();
    }
  };
  const customerDocGenInstallmet = async (id: any) => {
    const generateDocPayload = {
      identifier: Number(id),
      identifierType: "AGR",
      signingUrlRequired: false,
      signers: [
        {
          customerId: String(customer),
          customerName: String(
            props?.customerData?.data?.GetCustomer?.value.firstName +
              " " +
              props?.customerData?.data?.GetCustomer?.value.lastName
          ),
          phoneNumber: String(
            props?.customerData?.data?.GetCustomer?.value?.phones[0]
              ?.phoneNumber
          ),
          emailId: String(
            props?.customerData?.data?.GetCustomer?.value?.emailAddress
          ),
          priority: 1,
        },
      ],
      signatureType: 1,
      isRemote: false,
      manualSignatureReasonId: null,
      documentType: ["INOTE"],
      returnURL: window.location.origin,
    };
    await GenerateAgreementDoc(generateDocPayload);
    setclubSignFlow(true);
  };
  const installmentAgrSignFlow = (installmentInfo) => {
    for (let i = 0; i < installmentInfo?.length; i++) {
      customerDocGenInstallmet(Number(installmentInfo[i].Agreementid));
      setPifAgreementID(Number(installmentInfo[i].Agreementid));
      setcustomerSignaturePopupInote(true);
      setrecpt(false);
    }
  };
  const redirectionFunc = () => {
    const getUrl = window.location.href;
    if (Agreement > 0 && getUrl.includes("/customerorder/initial/")) {
      history.push({
        pathname: `/agreement/info/details/${customer}/${Agreement}`,
      });
    } else if (
      Agreement > 0 &&
      getUrl.includes("/customerorder/preliminary/")
    ) {
      history.push({
        pathname: `/agreement/customerorder/postatus/${customer}/${Agreement}`,
      });
    } else if (Agreement > 0) {
      history.push({
        pathname: `/agreement/rental/delivery/${customer}/${Agreement}`,
      });
    } else if (location?.state?.redirectSource === "am") {
      setRedirectToAccountManagement(true);
    } else {
      window.location.reload();
    }
  };
  // useEffect(() => {
  //   customerDocGen()
  // },[clubdocumentgenerate==true])

  const customerDocGen = async () => {
    const generateDocPayload = {
      identifier: Number(customer),
      identifierType: "CUST",
      signingUrlRequired: false,
      signers: [
        {
          customerId: String(customer),
          customerName: String(
            props?.customerData?.data?.GetCustomer?.value.firstName +
              " " +
              props?.customerData?.data?.GetCustomer?.value.lastName
          ),
          phoneNumber: String(
            props?.customerData?.data?.GetCustomer?.value?.phones[0]
              ?.phoneNumber
          ),
          emailId: String(
            props?.customerData?.data?.GetCustomer?.value?.emailAddress
          ),
          priority: 1,
        },
      ],
      signatureType: 1,
      isRemote: false,
      manualSignatureReasonId: null,
      documentType: ["CLUB"],
      returnURL: window.location.origin,
    };
    await GenerateAgreementDoc(generateDocPayload);
    setclubSignFlow(true);
  };

  const receiptId = () => {
    window.open(receipturl.data.receiptHtmlUrl);
  };

  const cof = (index: number) => {
    window.open(receipturl.data.htmlUrl[index]);
  };

  const extensionUrl = (index: number) => {
    window.open(receipturl.data.extensionHtmlUrl[index]);
  };

  const receipt = () => {
    setOwnership(false);
    setrecpt(true);
    //settran(false);
  };

  const OwnerShipPopup = () => {
    setOwnership(true);
    setTray1(false);
  };

  const close = () => {
    settran(false);
    setOwnership(false);
    setTray1(false);
    setrecpt(false);
    setrecptCO(false);
  };

  const print = () => {
    eventFrame.current?.contentWindow?.print();
  };

  const ownershipurl = () => {
    window.open(
      "https://generatepdfinracpad.s3.us-east-2.amazonaws.com/Mano%20RanjithCertificateOfOwnership.pdf"
    );
  };



  const spinnerpopup = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <ClipLoader color="blue" loading={load} size={40} />
          {txtChange === false ? (
            <Typography className="row justify-content-center popup-text">
              Transaction In Progress
            </Typography>
          ) : (
            <Typography className="row justify-content-center popup-text">
              Receipt is generating
            </Typography>
          )}
        </Grid>
        <Grid item md={12} className={classes.textcenter}>
          <Typography variant="h6" className={classes.mt3}>
            Please Wait...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const receiptPopUp = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${ReceiptURL}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewReceiptPopup(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const receiptPopUpCO = () => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${ReceiptURLArray[selectedIndex]}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewReceiptPopupCO(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const transactioncompletedPopup = () => {
    return (
      <Grid
        item
        data-testid="transcomppart"
        id="transatraction"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
      >
        <Grid item md={12} className={classes.textcenter}>
          <SuccessIcon></SuccessIcon>
          <Typography
            className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
          >
            Transaction completed successfully
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            color="primary"
            variant="contained"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            onClick={() => OwnershipTray()}
            data-bs-target="#delivery-receipt"
            data-testid="ownerShipId"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const viewReceipt = () => {
    setviewReceiptPopup(true);
  };
  const viewReceiptCO = (index: any) => {
    setviewReceiptPopupCO(true);
    setSelectedIndex(index);
  };
  const openExtReceipt = (index: number) => {
    setextIndex(index);
    setviewExtPopup(true);
  };
  const openCOFReceipt = (index: number) => {
    setcofIndex(index);
    setviewCOFPopup(true);
  };
  const viewCOF = (index: number) => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${COFUrl[index]}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewCOFPopup(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const viewExtReceipt = (index: number) => {
    return (
      <Grid
        item
        data-testid="spinpart"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="transaction"
        aria-hidden="true"
        className={classes.paymentmodal}
      >
        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.paymentmodal}`}
        >
          <iframe
            ref={eventFrame}
            frameBorder={0}
            width="100%"
            height="750px"
            src={`${ExtensionURL[index]}#toolbar=0`}
          ></iframe>
        </Grid>
        <Grid item md={12} className={classes.textend}>
          <RACButton
            variant="outlined"
            color="secondary"
            className={classes.me2}
            onClick={() => setviewExtPopup(false)}
          >
            Cancel
          </RACButton>
          <RACButton variant="contained" color="primary" onClick={print}>
            Print
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const cofReceipt = () => {
    return receipturl.data.htmlUrl.map((value: any, index: any) => {
      return (
        <>
          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Certificate of Ownership :
            <a
              onClick={() => openCOFReceipt(index)}
              style={{
                textDecoration: "underline",
                color: "#2179FE",
                cursor: "pointer",
              }}
              className={`${classes.mt3}${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
        </>
      );
    });
  };
  const extensionReceipt = () => {
    return receipturl.data.extensionHtmlUrl.map((value: any, index: any) => {
      return (
        <>
          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Extension Receipt Form:{" "}
            <a
              onClick={() => openExtReceipt(index)}
              style={{
                textDecoration: "underline",
                color: "#2179FE",
                cursor: "pointer",
              }}
              className={`${classes.mt3}${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
        </>
      );
    });
  };

  const Receiptpopup = () => {
    return (
      <Grid
        item
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
        data-testid="receiptIdBtn"
      >
        <Grid item md={12} className={`${classes.py4} ${classes.textcenter}`}>
          {/* apply racstrap here */}

          <Typography className={`${classes.colmd12} ${classes.mb2}`}>
            Payment Receipt :
            <a
              onClick={() => viewReceipt()}
              style={{
                textDecoration: "underline",
                color: "#2179FE",
                cursor: "pointer",
              }}
              className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
            >
              View
            </a>
          </Typography>
          {cofPopup ? (
            <>
              <Typography
                className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
              >
                Please load the Certificate of Ownership into Tray1 with face up
                and the top of the form facing front.
              </Typography>
              {cofReceipt()}
            </>
          ) : null}
          {extPopup ? extensionReceipt() : null}
          <Grid className={`${classes.w100} ${classes.textcenter}`}>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="outlined"
              onClick={() => {
                if(props.throwtakeCommitmentData) {
                  setrecpt(false);
                  setopenCommitmentConfirmation(true);
                } else {
                  ReceiptOk();
                }
              }}
              data-testid="ReceiptCancel"
              style={{ marginTop: "20px" }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const ReceiptpopupCO = () => {
    return (
      <Grid
        item
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
        data-testid="receiptIdBtn"
      >
        <Grid item md={12} className={`${classes.py4} ${classes.textcenter}`}>
          {/* apply racstrap here */}
          {ReceiptURLArray?.map((obj, index) => {
            return (
              <Typography
                key={index}
                className={`${classes.colmd12} ${classes.mb2}`}
              >
                Payment Receipt :
                <a
                  onClick={() => viewReceiptCO(index)}
                  style={{
                    textDecoration: "underline",
                    color: "#2179FE",
                    cursor: "pointer",
                  }}
                  className={`${classes.me3} ${classes.ms2} ${classes.popUpTxtStyle} ${classes.ms1}`}
                >
                  View
                </a>
              </Typography>
            );
          })}
          {cofPopup ? (
            <>
              <Typography
                className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
              >
                Please load the Certificate of Ownership into Tray1 with face up
                and the top of the form facing front.
              </Typography>
              {cofReceipt()}
            </>
          ) : null}
          {extPopup ? extensionReceipt() : null}
          <Grid className={`${classes.w100} ${classes.textcenter}`}>
            <RACButton
              className={classes.mx1}
              color="primary"
              variant="outlined"
              onClick={() => ReceiptOk()}
              data-testid="ReceiptCancel"
              style={{ marginTop: "20px" }}
            >
              OK
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const Tray1Popup = () => {
    return (
      <Grid
        item
        data-testid="ownershidPopupTestId"
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
      >
        <Typography
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.w100} ${classes.mt3}`}
        >
          please load the certificate of ownership into Tray1 with face up and
          the top of the form facing front
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.modalfooter} ${classes.textcenter} ${classes.mt3}`}
        >
          <RACButton
            className={classes.mx1}
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-testid="certificateId"
            onClick={() => OwnerShipPopup()}
            data-bs-target="#delivery-receipt"
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  const okClickUnavailable = () => {
    setTranFail(false);
    setepayFail(false);
    window.location.reload();
  };

  const unAvailable = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {epayFail == false ? (
            <Typography className={classes.racErrorFunction}>
              Transaction has been failed
            </Typography>
          ) : (
            <Typography className={classes.racErrorFunction}>
              Failed to do the cellphone activation please reach out IT service
              desk
            </Typography>
          )}
          {cardPaymentFailedMessage !== '' ? (
            <Typography className={classes.racErrorFunction}>
            {cardPaymentFailedMessage}
          </Typography>
          ) : null}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={okClickUnavailable}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const epayunAvailable = () => {
    return (
      <div>
        <Grid className={classes.textcenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          {epayFail == true ? (
            <Typography className={classes.racErrorFunction}>
              Failed to do the cellphone activation please reach out IT service
              desk
            </Typography>
          ) : null}
        </Grid>
        <Grid className={classes.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2}
            onClick={() => OwnershipTray()}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  {/**Rental Item Sale starts here */ }
  /**RIS_PC_NO_46 The changepopup() is rendered when changepopupFlag is updated as true */
  const changepopup = () => {
    return (
      <div>
        <Grid className={classes2.textcenter}>
          {/* <AlertIcon ></AlertIcon> */}
          <Typography className={classes.racErrorFunction}>
            The transaction was saved successfully. Change owed to customer{props.Paymentchange}
          </Typography>
        </Grid>
        <Grid className={classes2.textcenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            className={classes.me2N}
            onClick={() => { btnOk() }}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  }

  /**RIS_PC_NO_46 btnOk() function is triggered when co-worker clicks the ok button
   * in changepopup()
   */
  const btnOk = () => {
    setload(true);
    // setSecondFactorOpen(false);
    setChangepopupFlag(false)
    extensionArrayUrl = [];
    cofArrayUrl = [];
    MakepaymentApiCall();

  }
  /**RIS_PC_NO-46 The customerPresentpopup() is rendered when customerPresentpopupFlag is updated as true */
  const customerPresentpopup = () => {
    return (
      <div>
        <Grid className={classes2.textcenter}>
          {/* <AlertIcon ></AlertIcon> */}
          <Typography variant='h4' className={classes.racErrorFunction}>
            Is customer present?
          </Typography>
        </Grid>
        <Grid container style={{ marginLeft: "10px", marginTop: "40px" }}>
          <Grid item md={4} className={classes.floatLeft}>
            <RACButton
              style={{ marginLeft: "125px", padding: "5px 20px" }}
              variant="outlined"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => {
                setcustomerPresentpopupFlag(false);
                setChangepopupFlag(true)
              }}
            >
              Yes
            </RACButton>
          </Grid>
          <Grid item md={4} className={classes.floatRight}>
            <RACButton
              style={{ marginLeft: "70px", padding: "5px 20px" }}
              variant="contained"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => { btnNo() }}
            >
              No
            </RACButton>
          </Grid>

        </Grid>

      </div>
    )

  }
  /**RIS_PC_NO_45 The largepaymentAlertpopup() is rendered when largepayment AlertpopupFlag is updated as true */
  const largepaymentAlertpopup = () => {
    return (
      <div>
        <Grid className={classes2.textcenter}>
          {/* <AlertIcon ></AlertIcon> */}
          {/* <RACInfoIcon></RACInfoIcon> */}
          <Typography className={classes.racErrorFunction}>
            Do you wish to continue with Large Payment
          </Typography>
        </Grid>
        <Grid container style={{ marginLeft: "10px", marginTop: "40px" }}>
          <Grid item md={4} className={classes.floatLeft}>
            <RACButton
              style={{ marginLeft: "120px", padding: "10px" }}
              variant="outlined"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => yesBtn()}
            >
              Yes
            </RACButton>
          </Grid>
          <Grid item md={4} className={classes.floatRight}>


            <RACButton
              style={{ marginLeft: "70px", padding: "10px" }}
              variant="contained"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => { setlargepaymentAlertpopupFlag(false) }}
            >
              No
            </RACButton>
          </Grid>

        </Grid>

      </div>
    )

  }
  /**RIS_PC_NO_45 yesBtn() function is triggered when co-worker clicks the yes button 
   * in largepaymentAlertpopup */
  const yesBtn = () => {
    setlargepaymentAlertpopupFlag(false)
    setSecondFactorOpen(true)
    setRisFlag(true)
  }
  /**RIS_PC_NO_46 btnNo() function is triggered when co-worker clicks the no button 
   * in customerPresentpopup */
  const btnNo = () => {
    setcustomerPresentpopupFlag(false)
    setload(true);
    // setChangepopupFlag(false)
    extensionArrayUrl = [];
    cofArrayUrl = [];
    MakepaymentApiCall();

  }
  /**RIS_PC_NO_46 The reasonpopup() is rendered when reasonpopupFlag updated as true */
  const reasonpopup = () => {
    console.log("rrr", reason)
    return (
      <div>
        <Grid
          // className={classes.textcenter}
          style={{
            marginLeft: "5%",
            // marginBottom:"1%"
          }}
        >
          {/* <AlertIcon ></AlertIcon> */}
          <Typography variant='h5'
            style={{
              // marginLeft:"-21%",
              marginBottom: "1%"
            }}
            className={`${classes.racErrorFunction}${classes.description}`}>
            Enter explanation for Large Payment exception.
          </Typography>
          <TextareaAutosize
            name='reason'
            minRows={5}
            maxLength={500}
            className={`${classes.textAreaN} `}
            value={reason.reason}
            onChange={(e: any) => setReason({ ...reason, reason: e.target.value })}
          />
        </Grid>
        <Grid container style={{ marginLeft: "0px" }}>


          <Grid className={classes.floatRight}>
            <RACButton
              style={{ marginLeft: "330px", padding: "6px 5%" }}
              variant="contained"
              color="primary"
              // disabled={continuebtnPopupFlag}
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => { reasonpopupContinueBtn() }}
            >
              Submit
            </RACButton>
          </Grid>
          <Grid item className={classes.floatRight}>
            <RACButton
              style={{ marginLeft: "40px", padding: "6% 20%" }}
              variant="outlined"
              color="primary"
              className={`${classes.me1} ${classes.foc}`}
              onClick={() => setReasonpopupFlag(false)}
            >
              Cancel
            </RACButton>
          </Grid>

        </Grid>

      </div>

    )


  }
  /**RIS_PC_NO_46 Triggered when click the submit button in reasonpoup() */
  const reasonpopupContinueBtn = () => {
    setReasonpopupFlag(false)
    setcustomerPresentpopupFlag(true)
    // MakepaymentApiCall()


  }

  {/**Rental Item Sale Ends here */ }

  const OwnershipModal = () => {
    return (
      <Grid
        item
        data-testid="printOwnerId"
        id="delivery-receipt"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="autopayconsent"
        aria-hidden="true"
      >
        <Typography className={classes.mt3}>
          Certificate of Ownership
          <a
            href="#"
            style={{ marginLeft: "5px" }}
            onClick={() => ownershipurl()}
            data-testid="ViewClickId"
          >
            view
          </a>
        </Typography>

        <Grid
          item
          md={12}
          className={`${classes.textcenter} ${classes.mt3} ${classes.modalfooter}`}
        >
          <RACButton
            color="primary"
            variant="contained"
            className={classes.mx1}
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-testid="continueId"
            onClick={() => receipt()}
            data-bs-target="#delivery-receipt"
          >
            continue
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  if (redirectToAccountManagement) {
    return (
      <Redirect
        to={{
          pathname: AppRoute.AccountManagement.replace(
            ":customerId",
            location?.state?.accountManagementCustomerId || ""
          ),
          state: {
            ...(location?.state || {}),
            redirectSource: "payment",
          },
        }}
      />
    );
  }
  return (
    <>
      <RACButton
        color="primary"
        variant="contained"
        className={classes.ms2}
        name="De_ActivateClubbtn"
        onClick={() => showPopup()}
        data-bs-toggle="modal"
        data-bs-target="#transaction"
        data-testid="acceptBtn"
        disabled={acceptdisable}
      >
        {" "}
        Accept Payment
      </RACButton>

      {SecondFactorOpen === true ? (
        <SecondFactor
          setTwoFactorCancelClick={setTwoFactorCancelClick}
          setTwoFactorCompleted={setTwoFactorCompleted}
          setEmployeeDetails={setEmployeeDetails}
          storeAccessResponse={profileAccResp}
          CurrentRole={CurrentRole}
        />
      ) : null}
      {customerSignaturePopup ? (
        <CustomerSignature
          customerSignUsingField="club"
          customerSignUsingModule="payment"
          identifier={parseInt(customer)}
          customersArr={getCustDetail()}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={["CLUB"]}
          customerSignatureCompletedFn={() => redirectionFunc()}
          customerSignatureCanceledFn={() => redirectionFunc()}
          documentGenerateCompleted={clubSignFlow}
        />
      ) : null}
      {customerSignaturePopupInote ? (
        <CustomerSignature
          customerSignUsingField="agreementDetails"
          customerSignUsingModule="payment"
          identifier={pifAgreementID}
          customersArr={getCustDetail()}
          manualSignOptions={ManualSignOptions}
          documentTypeArr={["INOTE"]}
          customerSignatureCompletedFn={() => redirectionFunc()}
          customerSignatureCanceledFn={() => redirectionFunc()}
          documentGenerateCompleted={clubSignFlow}
        />
      ) : null}
      {/*spinner part*/}

      <RACModalCard
        isOpen={load}
        maxWidth="xs"
        onClose={() => setload(false)}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={spinnerpopup()}
      />
      {/* spinner part end here */}

      <RACModalCard
        isOpen={openCommitmentConfirmation}
        maxWidth="sm"
        borderRadius={'25px !important'}
        children={commitmentConfirmation()}
      />

      {/* Transaction completed Modal Starts here */}

      <RACModalCard
        isOpen={tran && epayFail == false}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={transactioncompletedPopup()}
      />
      {/*Receipt*/}

      {/* Printing Modal Starts here */}
      <RACModalCard
        isOpen={recpt}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={Receiptpopup()}
      />
      <RACModalCard
        isOpen={recptCO}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={false}
        // eslint-disable-next-line react/no-children-prop
        children={ReceiptpopupCO()}
      />
      {/* Printing ends here */}
      {/**Rental Item Sale Starts here */}
      <RACModalCard
        isOpen={largepaymentAlertpopupFlag}
        maxWidth="xs"
        onClose={() => setlargepaymentAlertpopupFlag(false)}
        closeIcon={false}
        borderRadius={"20px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={largepaymentAlertpopup()}
      />

      <RACModalCard
        isOpen={reasonpopupFlag}
        maxWidth="sm"
        onClose={() => setReasonpopupFlag(false)}
        closeIcon={false}
        borderRadius={"20px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={reasonpopup()}
      />

      <RACModalCard
        isOpen={customerPresentpopupFlag}
        maxWidth="xs"
        onClose={() => setcustomerPresentpopupFlag(false)}
        closeIcon={false}
        borderRadius={"20px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={customerPresentpopup()}
      />
      <RACModalCard
        isOpen={changepopupFlag}
        maxWidth="xs"
        // onClose={() => setalertpopupFlag(false)}
        // closeIcon={false}
        borderRadius={"20px !important"}
        // eslint-disable-next-line react/no-children-prop
        children={changepopup()}
      />
      {/**Rental Item Sale ends here */}

      {/* Tray1 Modal Starts here */}
      <RACModalCard
        isOpen={Tray1}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={Tray1Popup()}
      />
      {/* Tray1 ends here */}

      {/* Certificate of ownership 2nd popup starts here */}
      <RACModalCard
        isOpen={Ownership}
        maxWidth="xs"
        onClose={() => close()}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={OwnershipModal()}
      />
      {/* Certificate of ownership 2nd popup end here */}

      <RACModalCard
        isOpen={TranFail}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={unAvailable()}
      />
      <RACModalCard
        isOpen={epayFail}
        maxWidth="xs"
        // eslint-disable-next-line react/no-children-prop
        children={epayunAvailable()}
      />
      {/* <RACModal
      isOpen={viewReceiptPopup}
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="lg"
      maxHeight="lg"
      title={'Receipt'}
      content={
        <iframe
              ref={eventFrame}
              frameBorder={0}
              width="100%"
              height="100%"
              src={`${ReceiptURL}#toolbar=0`}
        ></iframe>
      }
      onClose={() => setviewReceiptPopup(false)}
      buttons={
        <>
          <RACButton variant="outlined" color="secondary" onClick={() => setviewReceiptPopup(false)}>
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={print}
          >
            Print
          </RACButton>
        </>
        
      }
    /> */}
      <RACModalCard
        isOpen={viewReceiptPopup}
        className={classes.customModal}
        maxWidth="lg"
        title={"Receipt"}
        children={receiptPopUp()}
        closeIcon={true}
        onClose={() => setviewReceiptPopup(false)}
      />

      <RACModalCard
        isOpen={viewReceiptPopupCO}
        className={classes.customModal}
        maxWidth="lg"
        title={"Receipt"}
        children={receiptPopUpCO()}
        closeIcon={true}
        onClose={() => setviewReceiptPopupCO(false)}
      />

      {receipturl != undefined && cofArrayUrl.length > 0 ? (
        <>
          <RACModalCard
            isOpen={viewCOFPopup}
            className={classes.customModal}
            maxWidth="lg"
            title={"Certificate of Ownership"}
            children={viewCOF(cofIndex)}
            closeIcon={true}
            onClose={() => setviewCOFPopup(false)}
          />
        </>
      ) : null}
      {receipturl != undefined && extensionArrayUrl.length > 0 ? (
        <>
          <RACModalCard
            isOpen={viewExtPopup}
            className={classes.customModal}
            maxWidth="lg"
            title={"Extension Receipt"}
            children={viewExtReceipt(extIndex)}
            closeIcon={true}
            onClose={() => setviewExtPopup(false)}
          />
        </>
      ) : null}
    </>
  );
}

export default Payment;

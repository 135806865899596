/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_FT_02
 * Import the required packages, interface and context
 */
import React, { useContext, useEffect, useState } from 'react';
import {
  RACButton,
  RACModalCard,
  RACCheckBox,
  Grid,
  Typography,
  RACDatePicker,
  Card,
  RACSelect,
  // CircularProgress,
} from '@rentacenter/racstrap';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { customerStyles } from '../../stylesJS/customerStyles';
import { agreementContext } from '../context/PaymentInformationContext';
import moment from 'moment';
import { ParamType } from '../interface/commonInterface';
import { useParams } from 'react-router-dom';
import calculationModulePayloadFormation from './existingFunctions/commonModulePayloadFormation';
import { calculatePaymentModule } from '../../api/user';
import { commonModulePayloadFormation } from '../utils/payloadFormations';
import SecondFactor from '../../racstrapComponents/microfrontends/TwoFactorPopup';
import { ReactComponent as AlertIcon } from './../../assets/apiErrorIcon.svg';
import {
  FreeTimeOptions,
  FreeTimeData,
  OptionsArray,
  FreeTimeProps,
} from '../interface/freeTimeInterface';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import CONSTANTS from '../constants/constant';
import { dateFormat, reduceMethod } from '../utils/scheduleAndDateFormat';
import { AgreementContextValue } from '../interface/contextInterface';
import {
  AgreementPaymentDetails,
  AmountDetails,
  ClubPaymentDetails,
  PaymentDetails,
} from '../interface/calculatePaymentResponse';
/**
 * PS_FT_03 - PS_FT_72
 * Invoke HandleFreeTime() with props
 * @param props
 * @returns
 */
export default function HandleFreeTime(props?: FreeTimeProps) {
  /**
   * PS_FT_04 - PS_FT_09
   * Declare variables and destruct from context
   */
  const classes = takePaymentStyles();
  const classesTwo = customerStyles();
  const { customerId } = useParams<ParamType>();
  const storeNumber = sessionStorage.getItem(CONSTANTS.STORE_NUMBER);
  const retentionData = props?.retentionData?.retentionData;

  const {
    agreementDetails,
    setAgreementDetails,
    customerInfo,
    setCustomerInfo,
    featureFlags,
    coWorkerDetails,
    renderContext,
    setRenderContext,
    cappedFreeTimeDetails,
    setCappedFreeTimeDetails,
    modalCardEnable,
    setModalCardEnable,
  } = useContext<AgreementContextValue>(agreementContext);

  const selectedAgreements = agreementDetails.filter(
    (el: AgreementDetails) => el.selected
  );
  let perDayRent = 0;
  let totalAmountUsed = 0;

  selectedAgreements.forEach((el: AgreementDetails) => {
    perDayRent +=
      Number(el.agreementRateDetails.perDayRent) ?? CONSTANTS.ZERO_NUMBER;
    totalAmountUsed += el.freeTimeAmountUsed ?? CONSTANTS.ZERO_NUMBER;
  });

  const [exceedPopup, setExceedPopup] = useState<boolean>(false);

  const freeTimeOptions: FreeTimeOptions[] = [
    {
      label: CONSTANTS.REGULAR,
      value: CONSTANTS.FREAMT,
      active: Number(featureFlags?.RegularFreeTime) > 0 ? true : false,
    },
    {
      label: CONSTANTS.SERVICE,
      value: CONSTANTS.SERVFREETM,
      active: Number(featureFlags.ServiceFreeTime) > 0 ? true : false,
    },
    {
      label: CONSTANTS.RETENTION_EXTENSION,
      value: CONSTANTS.RETEXTAMT,
      active:
        Number(featureFlags.RetentionExtensionFreeTime) > 0 ? true : false,
    },
    {
      label: CONSTANTS.PROMO_FREE_TIME,
      value: CONSTANTS.PROMO_FT,
      active: Number(featureFlags.PromoFreeTime) > 0 ? true : false,
    },
  ].filter((el) => el.active);

  const [freeTimeType, setFreeTimeType] = useState<OptionsArray[]>([]);
  const [needAuth, setNeedAuth] = useState<boolean>(false);
  const retentionExtension = freeTimeOptions.filter(
    (el) => el.value == CONSTANTS.RETEXTAMT
  );
  const freeTimeInitialObject = {
    type: retentionData
      ? retentionExtension[0]?.value
      : freeTimeOptions[0]?.value,
    nextDueDate: CONSTANTS.EMPTY_STRING,
    waiveLDW: false,
    waivePolicy: false,
    noOfDays: retentionData
      ? Number(retentionData?.extentionDays)
      : CONSTANTS.THIRTY_NUMBER,
    error: CONSTANTS.EMPTY_STRING,
    typeError: CONSTANTS.EMPTY_STRING,
    checkDisable: false,
    storeRemainingAmount: Math.min(
      cappedFreeTimeDetails.originalStoreRemainingBalance,
      cappedFreeTimeDetails.storeRemainingAmount + totalAmountUsed
    ),
    storeAmountActual: Math.min(
      cappedFreeTimeDetails.originalStoreRemainingBalance,
      cappedFreeTimeDetails.storeRemainingAmount + totalAmountUsed
    ),
    amountUsed: CONSTANTS.ZERO_NUMBER,
  };
  const [freeTimeData, setFreeTimeData] = useState<FreeTimeData>(
    freeTimeInitialObject
  );

  const [flag, setFlag] = useState<boolean>(false);
  const [saveLoader, setSaveLoader] = useState<boolean>(false);
  /**
   * Within useEffect(), call the optionsArrayFunction()
   */
  useEffect(() => {
    optionsArrayFunction();
  }, []);
  /**
   * PS_FT_10 - PS_FT_13
   * This function sets the drop down values based on the agreement
   */
  const optionsArrayFunction = () => {
    let options: OptionsArray[];
    if (selectedAgreements.every((el) => el.isEpp)) {
      const arr = freeTimeOptions.filter(
        (el) => el.value === CONSTANTS.RETEXTAMT
      );
      options = arr.map((el: FreeTimeOptions) => {
        delete el?.active;
        return el as OptionsArray;
      });
    } else if (selectedAgreements.some((el) => el.promoFreeDays != 0)) {
      options = freeTimeOptions.map((el: FreeTimeOptions) => {
        delete el?.active;
        return el as OptionsArray;
      });
    } else {
      const arr = freeTimeOptions.filter(
        (el) => el.value != CONSTANTS.PROMO_FT
      );
      options = arr.map((el: FreeTimeOptions) => {
        delete el?.active;
        return el as OptionsArray;
      });
    }
    setFreeTimeType(options);
    setFreeTimeData({
      ...freeTimeData,
      type:
        retentionData && !selectedAgreements.every((el) => el.isEpp)
          ? retentionExtension[0]?.value
          : options[0].value,
    });
    setFlag(!flag);
  };
  /**
   * PS_FT_14 - PS_FT_27
   * This function triggers when value changes in the drop down
   * It sets the value in the state variable
   * @param event
   */
  const handleDropDown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === CONSTANTS.PROMO_FT) {
      if (selectedAgreements.every((el) => el.promoFreeDays != 0)) {
        if (
          selectedAgreements.every(
            (el) => el.promoFreeDays === selectedAgreements[0].promoFreeDays
          )
        )
          setFreeTimeData({
            ...freeTimeData,
            type: event.target.value,
            nextDueDate: CONSTANTS.EMPTY_STRING,
            waiveLDW: true,
            waivePolicy: true,
            noOfDays: Number(selectedAgreements[0].promoFreeDays),
            checkDisable: true,
            amountUsed: CONSTANTS.ZERO_NUMBER,
            typeError: CONSTANTS.EMPTY_STRING,
          });
        else
          setFreeTimeData({
            ...freeTimeData,
            type: event.target.value,
            nextDueDate: CONSTANTS.EMPTY_STRING,
            waiveLDW: true,
            waivePolicy: true,
            checkDisable: true,
            noOfDays: Number(selectedAgreements[0].promoFreeDays),
            amountUsed: CONSTANTS.ZERO_NUMBER,
            typeError: CONSTANTS.PLEASE_CHOOSE_AGR_OF_SAME_PROMO_FREE_DAYS,
          });
      } else if (selectedAgreements.some((el) => el.promoFreeDays != 0)) {
        const agreementId: string[] = [];
        selectedAgreements.forEach(
          (el) => el.promoFreeDays != 0 && agreementId.push(el.agreementNumber)
        );
        setFreeTimeData({
          ...freeTimeData,
          type: event.target.value,
          waiveLDW: true,
          waivePolicy: true,
          checkDisable: true,
          typeError: `Promo free time can be applied only for the agreement ${agreementId}`,
        });
      }
    } else if (CONSTANTS.FREAMT_SERVFREETM_ARRAY.includes(event.target.value))
      setFreeTimeData({
        ...freeTimeData,
        nextDueDate: CONSTANTS.EMPTY_STRING,
        type: event.target.value,
        noOfDays: 30,
        waiveLDW: false,
        waivePolicy: false,
        checkDisable: false,
        amountUsed: CONSTANTS.ZERO_NUMBER,
        typeError: CONSTANTS.EMPTY_STRING,
      });
    else if (event.target.value === CONSTANTS.RETEXTAMT)
      setFreeTimeData({
        ...freeTimeData,
        nextDueDate: CONSTANTS.EMPTY_STRING,
        type: event.target.value,
        noOfDays: 35,
        waiveLDW: false,
        waivePolicy: false,
        checkDisable: false,
        amountUsed: CONSTANTS.ZERO_NUMBER,
        typeError: CONSTANTS.EMPTY_STRING,
      });
  };
  /**
   * PS_FT_28 - PS_FT_40
   * This function triggers in the date change
   * @param event
   */
  const onDateChange = async (event: string) => {
    const inputDate = dateFormat(event, CONSTANTS.YYYY_MM_DD);
    const minDate = moment(selectedAgreements[0].currentDueDate)
      .add(1, 'd')
      .format(CONSTANTS.YYYY_MM_DD);
    const maxDate = moment(selectedAgreements[0].currentDueDate)
      .add(freeTimeData.noOfDays, 'd')
      .format(CONSTANTS.YYYY_MM_DD);

    if (inputDate < minDate || inputDate > maxDate)
      setFreeTimeData({
        ...freeTimeData,
        amountUsed: CONSTANTS.ZERO_NUMBER,
        storeRemainingAmount: freeTimeData.storeAmountActual,
        error: CONSTANTS.PLEASE_CHOOSE_A_VALID_DUE_DATE,
      });
    else {
      const updatedNextDueDate = moment(event);
      const currentDueDate = moment(selectedAgreements[0].currentDueDate);
      const daysDiff = updatedNextDueDate.diff(currentDueDate, 'days');

      const totalAmount = (daysDiff * perDayRent).toFixed(2);

      setFreeTimeData({
        ...freeTimeData,
        nextDueDate: inputDate,
        error: CONSTANTS.EMPTY_STRING,
        amountUsed: Number(totalAmount)
          ? Number(totalAmount)
          : CONSTANTS.ZERO_TWO_DECIMAL_NUMBER,
        storeRemainingAmount: Math.max(
          0,
          freeTimeData.storeAmountActual - Number(totalAmount)
        ),
      });
    }
  };
  /**
   * PS_FT_41 - PS_FT_43
   * This function triggers in the save button click
   * It checks for the need of two factor authentication
   */
  const handleSave = () => {
    setSaveLoader(true);
    if (
      freeTimeData.amountUsed > freeTimeData.storeRemainingAmount &&
      featureFlags.CappedFreeTimeEnabled
    )
      setExceedPopup(true);
    else if (CONSTANTS.SERVFREETM_RETEXTAMT_ARRAY.includes(freeTimeData.type))
      setNeedAuth(true);
    else acceptFreeTime();
  };
  /**
   * PS_FT_44 - PS_FT_56
   * This function sets the data to the context
   */
  const acceptFreeTime = async () => {
    const daysDiff: number = moment(freeTimeData.nextDueDate).diff(
      moment(selectedAgreements[0].currentDueDate),
      'days'
    );
    const finalResponse: AgreementDetails[] = [];

    if (freeTimeData.waiveLDW || freeTimeData.waivePolicy) {
      waivedOptional(finalResponse, daysDiff);
    } else {
      await acceptWithoutWaivedOptional(finalResponse, daysDiff);
    }

    setCappedFreeTimeDetails({
      ...cappedFreeTimeDetails,
      storeRemainingAmount: freeTimeData.storeRemainingAmount,
    });

    setAgreementDetails(finalResponse);
    const customerDetail = JSON.parse(JSON.stringify(customerInfo));
    customerDetail.carryRentEnable = false;
    setCustomerInfo(customerDetail);
    setSaveLoader(false);
    setModalCardEnable({
      ...modalCardEnable,
      freeTimePopup: false,
      onLoadFreeTimePopup: false,
    });
    if (props?.setPopupValue) props?.setPopupValue(CONSTANTS.SUSPENSE_POPUPS);
    setRenderContext(!renderContext);
  };

  const waivedOptional = (
    finalResponse: AgreementDetails[],
    daysDiff: number
  ) => {
    agreementDetails.forEach((eachAgreement: AgreementDetails) => {
      if (eachAgreement.selected) {
        finalResponse.push({
          ...eachAgreement,
          freeTimeAmountUsed:
            Number(eachAgreement.agreementRateDetails.perDayRent) * daysDiff,
          totalDueAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          totalAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          lateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          lateFeetax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          totalTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          carriedLateRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          carriedRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          ldwAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          ldwTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          policyAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          policyTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          nextDueDate: dateFormat(
            freeTimeData.nextDueDate,
            CONSTANTS.YYYY_MM_DD
          ),
          promoFreeDays: calculatePromoFreeDays(eachAgreement, daysDiff),
          freeTime: {
            freeTimeType: freeTimeData.type,
            freeTimeDays: String(daysDiff),
            isOptServiceWaived:
              freeTimeData.waiveLDW || freeTimeData.waivePolicy || false,
          },
          isFreeTimeEdit: true,
          isAdjustDuedateEdit: false,
        });
      } else finalResponse.push(eachAgreement);
    });
    setAgreementDetails(finalResponse);
  };

  const acceptWithoutWaivedOptional = async (
    finalResponse: AgreementDetails[],
    daysDiff: number
  ) => {
    const commonModuleResponse: PaymentDetails =
      await getCommonModuleResponse();

    agreementDetails.forEach((eachAgreement: AgreementDetails) => {
      if (eachAgreement.selected) {
        const commonModuleAgreement: AgreementPaymentDetails &
          ClubPaymentDetails =
          findCommonModuleAgreement(eachAgreement, commonModuleResponse) ??
          ({} as AgreementPaymentDetails & ClubPaymentDetails);
        const updatedAgreement: AgreementDetails = createUpdatedAgreement(
          eachAgreement,
          commonModuleAgreement,
          daysDiff
        );
        finalResponse.push(updatedAgreement);
      } else {
        finalResponse.push({ ...eachAgreement });
      }
    });
  };

  const getCommonModuleResponse = async () => {
    const payload = await commonModulePayloadFormation(
      selectedAgreements,
      freeTimeData,
      [CONSTANTS.FTT]
    );
    const commonPayload = {
      customerId: customerId,
      storeNumber: storeNumber,
      checkedAgr: payload,
    };

    const commonModulePayload = await calculationModulePayloadFormation(
      commonPayload
    );
    const commonModuleServiceRes = await calculatePaymentModule(
      commonModulePayload
    );
    return commonModuleServiceRes?.data?.paymentDetails[0];
  };

  const findCommonModuleAgreement = (
    eachAgreement: AgreementDetails,
    commonModuleResponse: PaymentDetails
  ) => {
    if (eachAgreement.agreementType !== CONSTANTS.CLUB) {
      return commonModuleResponse?.agreementPaymentDetails?.find(
        (item: AgreementPaymentDetails) =>
          item.agreementId === eachAgreement.agreementId
      ) as AgreementPaymentDetails & ClubPaymentDetails;
    } else {
      return commonModuleResponse?.clubPaymentDetails?.find(
        (item: ClubPaymentDetails) =>
          item.clubMembershipNumber === eachAgreement.agreementNumber
      ) as AgreementPaymentDetails & ClubPaymentDetails;
    }
  };

  const createUpdatedAgreement = (
    eachAgreement: AgreementDetails,
    commonModuleAgreement: AgreementPaymentDetails & ClubPaymentDetails,
    daysDiff: number
  ) => {
    const lateFee = reduceMethod(
      commonModuleAgreement?.amountDetails,
      CONSTANTS.ITEM_CATEGORY,
      CONSTANTS.LTRPAYFEE,
      CONSTANTS.AMOUNT
    );

    const latePifFee = reduceMethod(
      commonModuleAgreement?.amountDetails,
      CONSTANTS.ITEM_CATEGORY,
      CONSTANTS.LTPIFFEE,
      CONSTANTS.AMOUNT
    );

    const lateFeeTax = reduceMethod(
      commonModuleAgreement?.amountDetails,
      CONSTANTS.ITEM_CATEGORY,
      CONSTANTS.LTRPAYFEE,
      CONSTANTS.TAX
    );

    const latePifFeeTax = reduceMethod(
      commonModuleAgreement?.amountDetails,
      CONSTANTS.ITEM_CATEGORY,
      CONSTANTS.LTPIFFEE,
      CONSTANTS.TAX
    );

    const carriedLateFee = reduceMethod(
      commonModuleAgreement?.amountDetails,
      CONSTANTS.ITEM_CATEGORY,
      CONSTANTS.CLFEEPAY,
      CONSTANTS.AMOUNT
    );

    const carriedRent = reduceMethod(
      commonModuleAgreement?.amountDetails,
      CONSTANTS.ITEM_CATEGORY,
      CONSTANTS.CRPAY,
      CONSTANTS.AMOUNT
    );

    return {
      ...eachAgreement,
      freeTimeAmountUsed:
        Number(eachAgreement.agreementRateDetails.perDayRent) * daysDiff,
      totalAmount: commonModuleAgreement.totalAmount,
      totalTax: commonModuleAgreement.totalTax,
      lateFee:
        lateFee != CONSTANTS.ZERO_NUMBER
          ? lateFee?.toFixed(2)
          : latePifFee || CONSTANTS.ZERO_NUMBER,
      lateFeetax:
        lateFeeTax != CONSTANTS.ZERO_NUMBER
          ? lateFeeTax
          : latePifFeeTax || CONSTANTS.ZERO_NUMBER,
      carriedLateRent: carriedLateFee,
      carriedRent: carriedRent,
      ldwAmount:
        calculateAmount(commonModuleAgreement, CONSTANTS.LTLDW_LDW_ARRAY) ||
        eachAgreement.ldwAmount,
      ldwTax:
        calculateTax(commonModuleAgreement, CONSTANTS.LTLDW_LDW_ARRAY) ||
        eachAgreement.ldwTax,
      policyAmount:
        calculateAmount(commonModuleAgreement, CONSTANTS.RTRPAY_ARRAY) ||
        eachAgreement.policyAmount,
      policyTax:
        calculateTax(commonModuleAgreement, CONSTANTS.RACTIREPAY_ARRAY) ||
        eachAgreement.policyTax,
      nextDueDate: freeTimeData.nextDueDate,
      promoFreeDays: calculatePromoFreeDays(eachAgreement, daysDiff),
      freeTime: {
        freeTimeType: freeTimeData.type,
        freeTimeDays: String(daysDiff),
        isOptServiceWaived:
          freeTimeData.waiveLDW || freeTimeData.waivePolicy || false,
      },
      isFreeTimeEdit: true,
      isAdjustDuedateEdit: false,
      totalDueAmount: calculateTotalDueAmount(commonModuleAgreement),
    };
  };

  const calculateAmount = (
    agreement: AgreementPaymentDetails & ClubPaymentDetails,
    categoryArray: string[]
  ) =>
    agreement.amountDetails?.reduce(
      (acc: number, el: AmountDetails) =>
        acc + (categoryArray.includes(el.itemCategory) ? Number(el.amount) : 0),
      0
    );

  const calculateTax = (
    agreement: AgreementPaymentDetails & ClubPaymentDetails,
    categoryArray: string[]
  ) =>
    agreement.amountDetails?.reduce(
      (acc: number, el: AmountDetails) =>
        acc + (categoryArray.includes(el.itemCategory) ? Number(el.tax) : 0),
      0
    );

  const calculatePromoFreeDays = (
    agreement: AgreementDetails,
    daysDiff: number
  ) =>
    freeTimeData.type === CONSTANTS.PROMO_FT
      ? Number(agreement.actualPromoFreeDays) - daysDiff
      : agreement.actualPromoFreeDays ?? agreement.promoFreeDays;

  const calculateTotalDueAmount = (
    agreement: AgreementPaymentDetails & ClubPaymentDetails
  ) => Number(agreement.totalAmount) + Number(agreement.totalTax);

  /**
   * PS_FT_59 - PS_FT_61
   * This function holds the HTML for the capped free time popup
   * @returns
   */
  const cappedFreeTimePopup = () => {
    return (
      <Grid className={`${classes.textCenter}`}>
        <AlertIcon />
        <Typography
          className={`${classesTwo.spacerMT3} ${classesTwo.customerRacpopup1}`}
          data-testid="CappedFreeTimePopup"
        >
          Allocated free time for this store has been exceeded. Do you still
          want to proceed ?
        </Typography>
        <Grid className={`${classesTwo.buttonsPadding}`}>
          <RACButton
            variant="outlined"
            color="primary"
            className={`${classesTwo.customerFloatleft}`}
            onClick={() => {
              setExceedPopup(false);
              setSaveLoader(false);
            }}
            data-testid="cappedCancelBtn"
          >
            Cancel
          </RACButton>
          <RACButton
            color="primary"
            variant="contained"
            className={`${classesTwo.customerFloatright}`}
            onClick={() => {
              setNeedAuth(true);
            }}
            loading={needAuth}
            data-testid="proceedBtn"
          >
            Proceed
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  /**
   * PS_FT_62 - PS_FT_72
   * This function holds the HTML for the free time popup
   * @returns
   */
  const freeTimePopup = () => {
    return (
      <Grid>
        <Grid className={`${classes.displayFlex}`}>
          {selectedAgreements.length == 1 && (
            <Card
              className={`${classes.racSkyBlue} ${classes.bluecards} `}
              style={{
                padding: '10px 10px',
                backgroundColor: '#EFF4FF',
                width: '39%',
              }}
            >
              <Typography
                className={`${classes.fontSize14} ${classes.fontFamilyOpenSansBold}`}
                style={{
                  float: 'left',
                  color: '#2279fd',
                }}
              >
                Agreement # : {selectedAgreements[0].agreementNumber}
              </Typography>
            </Card>
          )}

          {featureFlags?.CappedFreeTimeEnabled && (
            <Card
              className={`${classes.racSkyBlue} ${classes.bluecards} `}
              style={{
                padding: '10px 10px',
                backgroundColor: '#EFF4FF',
                width: '57%',
                marginLeft: '4%',
              }}
            >
              <Typography
                className={`${classes.fontSize14} ${classes.fontFamilyOpenSansBold}`}
                style={{
                  float: 'left',
                  color: '#2279fd',
                }}
                data-testid="StoreRemainingBalance"
              >
                Store Remaining Balance : $
                {Number(freeTimeData?.storeRemainingAmount).toFixed(2)}
              </Typography>
            </Card>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <RACDatePicker
                data-testid="currentDueDate"
                value={dateFormat(
                  selectedAgreements[0].currentDueDate,
                  CONSTANTS.YYYY_MM_DD
                )}
                label="Current Due Date"
                name="currentDueDate"
                disabled={true}
              />
            </Grid>
            <Grid style={{ padding: '19px' }}></Grid>
            <Grid className={classes.racSelectStyle}>
              <RACSelect
                data-testid="freeTimeType"
                name="select"
                inputLabel="Type"
                options={freeTimeType}
                isDisabled={freeTimeOptions?.length === 0 ? true : false}
                defaultValue={freeTimeData.type}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  handleDropDown(e)
                }
              />
              <Grid
                className={`${classes.w100} ${classes.wrapword} ${classes.racLoginAlert}`}
              >
                <Typography>{freeTimeData.typeError}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid
              md={12}
              style={{ display: 'flex', flexWrap: 'wrap' }}
              className={classes.margincard}
            >
              <RACDatePicker
                data-testid="nextDueDate"
                value={freeTimeData.nextDueDate}
                required={true}
                label="Next Due Date"
                inputProps={{
                  min: moment(selectedAgreements[0].currentDueDate)
                    .add(1, 'd')
                    .format(CONSTANTS.YYYY_MM_DD),
                  max: moment(selectedAgreements[0].currentDueDate)
                    .add(freeTimeData.noOfDays, 'd')
                    .format(CONSTANTS.YYYY_MM_DD),
                }}
                errorMessage={freeTimeData.error}
                onChange={(e: string) => onDateChange(e)}
              />
            </Grid>

            {featureFlags?.CappedFreeTimeEnabled && (
              <Grid
                style={{
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                <Typography
                  className={`${classes.fontSize14} ${classes.fontFamilyOpenSansBold}`}
                  style={{
                    float: 'left',
                  }}
                  data-testid="AmountUsed"
                >
                  Amount Used :{' '}
                  <span
                    className={`${
                      freeTimeData.storeRemainingAmount >=
                        freeTimeData.amountUsed ||
                      isNaN(freeTimeData.amountUsed)
                        ? classesTwo.blackColor
                        : classesTwo.redColor
                    }
                        ${classes.fontSize14} ${
                      classes.fontFamilyOpenSansBold
                    }`}
                  >
                    $
                    {freeTimeData.amountUsed
                      ? freeTimeData.amountUsed.toFixed(2)
                      : CONSTANTS.ZREO_TWO_DECIMAL_STRING}
                  </span>
                </Typography>
              </Grid>
            )}

            <Grid md={12} className={`${classes.margincard}`}>
              <RACCheckBox
                data-testid="waiveLDW"
                size="medium"
                checked={freeTimeData.waiveLDW}
                disabled={
                  !selectedAgreements.every((el) => el.isLDW) ||
                  freeTimeData.checkDisable
                }
                onChange={() =>
                  setFreeTimeData({
                    ...freeTimeData,
                    waiveLDW: !freeTimeData.waiveLDW,
                  })
                }
                label="Waive LDW"
              />
              <RACCheckBox
                size="medium"
                checked={freeTimeData.waivePolicy}
                disabled={
                  !selectedAgreements.every((el) => el.isPolicy) ||
                  freeTimeData.checkDisable
                }
                onChange={() =>
                  setFreeTimeData({
                    ...freeTimeData,
                    waivePolicy: !freeTimeData.waivePolicy,
                  })
                }
                label="Waive Policy"
                data-testid="waivePolicy"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          <RACButton
            data-testid="cancelBtn"
            className={classes.ms2}
            color="primary"
            variant="outlined"
            name="clearCancel"
            data-bs-toggle="modal"
            style={{
              backgroundColor: 'white',
              color: '#2C3333',
            }}
            onClick={() => {
              setFreeTimeData(freeTimeInitialObject);
              if (props?.setPopupValue)
                props?.setPopupValue(CONSTANTS.SUSPENSE_POPUPS);
              setModalCardEnable({
                ...modalCardEnable,
                freeTimePopup: false,
                onLoadFreeTimePopup: false,
              });
            }}
          >
            Cancel
          </RACButton>
          <RACButton
            data-testid="saveBtn"
            className={classes.ms2}
            color="primary"
            variant="contained"
            onClick={() => {
              handleSave();
            }}
            disabled={
              !freeTimeData.nextDueDate ||
              saveLoader ||
              !!freeTimeData.error ||
              !!freeTimeData.typeError
            }
            loading={saveLoader}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  /**
   * Within the return part,
   * It holds the modal card for the freetime,
   * capped freeTime and secondFactor authentication
   */
  return (
    <>
      <RACModalCard
        isOpen={
          modalCardEnable.freeTimePopup || modalCardEnable.onLoadFreeTimePopup
        }
        closeIcon={true}
        borderRadius={'25px !important'}
        onClose={() => {
          if (props?.setPopupValue)
            props?.setPopupValue(CONSTANTS.SUSPENSE_POPUPS);
          setModalCardEnable({
            ...modalCardEnable,
            freeTimePopup: false,
            onLoadFreeTimePopup: false,
          });
        }}
        maxWidth={'sm'}
        title={'Free Time'}
      >
        {freeTimePopup()}
      </RACModalCard>

      {needAuth && (
        <SecondFactor
          setTwoFactorCancelClick={() => {
            setFreeTimeData(freeTimeInitialObject);
            if (props?.setPopupValue)
              props?.setPopupValue(CONSTANTS.SUSPENSE_POPUPS);
            setModalCardEnable({
              ...modalCardEnable,
              freeTimePopup: false,
              onLoadFreeTimePopup: false,
            });
            setExceedPopup(false);
            setNeedAuth(false);
          }}
          setTwoFactorCompleted={() => {
            acceptFreeTime();
            setExceedPopup(false);
            setNeedAuth(false);
          }}
          moduleName={
            selectedAgreements.every((el) => el.isEpp)
              ? CONSTANTS.SLA_MODULE_NAME_RET_EXT_EPP
              : exceedPopup
              ? CONSTANTS.SLA_MODULE_NAME_CAPPED_FT
              : CONSTANTS.SLA_MODULE_NAME_APPLY_FT
          }
          CurrentRole={coWorkerDetails.role}
        ></SecondFactor>
      )}

      {exceedPopup && (
        <RACModalCard
          isOpen={exceedPopup}
          closeIcon={false}
          borderRadius={'25px !important'}
          onClose={() => setExceedPopup(false)}
          maxWidth={'xs'}
          data-testid="CappedFreeTimePopup"
        >
          {cappedFreeTimePopup()}
        </RACModalCard>
      )}
    </>
  );
}

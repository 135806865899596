/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import { reversePaymentStyle } from "../../stylesJS/reversePaymentStyle";
import { ReactComponent as AlertIcon } from "../../assets/images/no-records-found.svg";
import { GeneralStateContext } from "../../context/GeneralProvider";
import { GeneralStateDispatchContext } from "../../context/GeneralProvider";
export default function BadRequest() {
  const classes = reversePaymentStyle();
  const { exceptionModal } = useContext(GeneralStateContext);
  const { setExceptionModal } = useContext(GeneralStateDispatchContext);
  const buildBadRequestException = () => {
    return (
      <Grid>
        <Grid className={`${classes.textCenter}`}>
          <AlertIcon />
          <Typography className={`${classes.bold}`}>
            No Records Found
          </Typography>
        </Grid>
        <Grid className={`${classes.py4} ${classes.textCenter}`}>
          <RACButton
            className={classes.mx1}
            variant="contained"
            color="primary"
            onClick={() => setExceptionModal({...exceptionModal, badRequest: false})}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    );
  }; // This method will be rendering the bad request popup.
  return (
    <RACModalCard
      isOpen={exceptionModal.badRequest}
      maxWidth="xs"
      borderRadius={"25px !important"}
      //eslint-disable-next-line react/no-children-prop
      children={buildBadRequestException()}
    />
  );
}

/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-use-of-empty-return-value */

import{useEffect,useState}from 'react'
import React from 'react';
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import SecondFactor from './TwoFactorPopup';
import{
    
    RACButton,
    RACModalCard,
    Grid,
    RACTable,
    RACTableCell,
    RACTableRow,
    
 } from "@rentacenter/racstrap";
 import moment from "moment";
 export default function CreditChargeBack(props:any){
    const ModalRadius = "25px !important";
    const classes=takePaymentStyles();
    {/**PS_CCCB_NO_18 stateVariables used in Adjust CCCB */}
    const[cccbValue,setCccbValue]=useState<any>({
        creditCardNumber:'',
        chargeDate:'',
        chargeAmount:0,
        cccbAmount:0,
        type:''
     })//To store the values from the checked Agreement
     const[cccbPopUp,setCccbPopUp]=useState<any>();//used to Open the Adjust CCCB PopUp
     const[cccbAuthentication,setCccbAuthentication]=useState<any>();
    useEffect(()=>{
            // setCccbPopUp(true);
            // checkDetails();
            for(let i=0;i<props.receivedCheckedAgr.length;i++){
                debugger;
                if(props.receivedCheckedAgr[i].Agreementtype=="Credit Card Charge Back"){
                    const cardNo=props.receivedCheckedAgr[i].cccbData.cardLastFour
                    //console.log(cardNo,"...crdNo");
                    const creditDate=props.receivedCheckedAgr[i].cccbData.returnDate
                    //console.log(creditDate,"....cccbDate");
                    const cardType=props.receivedCheckedAgr[i].Agreementtype=="Credit Card Charge Back"? props.receivedCheckedAgr[i].Agreementtype:null
                    const cccbCardAmount=props.receivedCheckedAgr[i].Agreementtype=="Credit Card Charge Back"?props.receivedCheckedAgr[i].cccbData.balanceAmount:null
                    //console.log(cccbCardAmount,"....cccbAmount");
                    const cccbAmountVal=props.receivedCheckedAgr[i].Amount;
                   // console.log(cccbAmountVal,"...cccb Amount")
                    setCccbValue({
                        creditCardNumber:Number(cardNo),
                        chargeDate:(creditDate),
                        chargeAmount:Number(cccbCardAmount),
                        cccbAmount:Number(cccbAmountVal),
                        type:cardType
                       })
                   setCccbPopUp(true);
                }
              }
       
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    debugger;
    {/**PS_Cccb_No_19  To get the values from the paygridValues by checking
     the agreement type as Credit Card Charge Back */}
    // const checkDetails=()=>{
    // debugger;
    //   for(let i=0;i<props.receivedCheckedAgr.length;i++){
    //     debugger;
    //     if(props.receivedCheckedAgr[i].Agreementtype=="Credit Card Charge Back"){
    //         const cardNo=props.receivedCheckedAgr[i].cccbData.cardLastFour
    //         //console.log(cardNo,"...crdNo");
    //         const creditDate=props.receivedCheckedAgr[i].cccbData.returnDate
    //         //console.log(creditDate,"....cccbDate");
    //         const cardType=props.receivedCheckedAgr[i].Agreementtype=="Credit Card Charge Back"? props.receivedCheckedAgr[i].Agreementtype:null
    //         const cccbCardAmount=props.receivedCheckedAgr[i].Agreementtype=="Credit Card Charge Back"?props.receivedCheckedAgr[i].cccbData.balanceAmount:null
    //         //console.log(cccbCardAmount,"....cccbAmount");
    //         const cccbAmountVal=props.receivedCheckedAgr[i].Amount;
    //        // console.log(cccbAmountVal,"...cccb Amount")
    //         setCccbValue({
    //             creditCardNumber:Number(cardNo),
    //             chargeDate:(creditDate),
    //             chargeAmount:Number(cccbCardAmount),
    //             cccbAmount:Number(cccbAmountVal),
    //             type:cardType
    //            })
    //        setCccbPopUp(true);
    //     }
    //   }
    // }
    const cccbSecondHeader=[
        {display:"   "},
        {display:"Original Amount"},
        {display:"Balance Due"},
        {display:"Adjusted"}
    ];
    const cccbHeaderValue=[
        {display:"Credit Card Number"},
        {display:"Charge Back Date"},
        {display:"  "}
    ];
    const cccbHeadVal=[
        {display:"**** **** ****"+props?.receivedCheckedAgr[0]?.cccbData?.cardLastFour},
        {display:moment(String(props?.receivedCheckedAgr[0]?.cccbData?.returnDate)).format("MM/DD/YYYY")},
        {dispaly:"  "}
    ];
    
    
    const cccbHeader=()=>(
        <>
        {
         cccbHeaderValue.map((val,index)=>{
            return(
                <RACTableCell className={`${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.rowColor}`} 
                  style={{paddingLeft:"2px"}}
                    key={index} >
                        {val.display}
                </RACTableCell>  
            );
    
         })  
        }
        </>
    )
    const cccbHeaderContent=()=>(
        <>
          {
           cccbHeadVal.map((val,index)=>{
               return(
                   
                   <RACTableCell className={`${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.rowColor}`} 
                 style={{paddingLeft:"2px"}}
                   key={index} >
                       {val.display}
               </RACTableCell>
               );
           })
          }
        </>
       )
       const cccbTableValues=()=>(
        <>
        {
         cccbSecondHeader.map((val,index)=>{
            return(
                <RACTableCell className={`${classes.paymentStore} ${classes.textend} ${classes.font12} ${classes.rowColor}`} 
                  style={{paddingLeft:"2px"}}
                    key={index} >
                        {val.display}
                </RACTableCell>  
            );
    
         })  
        }
        </>
    )
    //Continue button
{/**PS_Cccb_No_23 When the Continue is clicked the new values get updated 
and the popUp close */}
const ContinueClk=()=>{
    debugger;
    const checkedAgr=props.receivedCheckedAgr;
    const cccbResponse=checkedAgr.filter((e)=>e.Agreementtype == "Credit Card Charge Back")
    // if(cccbResponse?.length>0){
    //   cccbResponse[0].Agreementtype=cccbValue.type;
    //   cccbResponse[0].Amount=Number(cccbValue.cccbAmount);
    //   cccbResponse[0].chargeBackAmount=Number(cccbValue.cccbAmount);
    //   cccbResponse[0].actualAmountDue=Number(cccbValue.cccbAmount);
    //   cccbResponse[0].agreementRate =Number(cccbValue.cccbAmount);
    // }
    setCccbPopUp(false);
    setCccbAuthentication(false);
    setCccbValue({...cccbValue,cccbAmount:cccbValue.cccbAmount})
    props.response({resArray: cccbResponse})

    }
   {/**Ps_Cccb_No_1.20 
* When the Cancel button or close icon is clicked it would close the popUp and by using
  the stateVariable cccbValue it set the values from the checked agreement */}
    const cancelBtn=()=>{
       //debugger;
        setCccbPopUp(false);
        props.setCccb(false);
        setCccbAuthentication(false);
        setCccbValue({
            creditCardNumber:cccbValue.creditCardNumber,
            chargeAmount:cccbValue.chargeAmount,
            chargeDate:cccbValue.chargeDate,
            cccbAmount:cccbValue.cccbAmount,
            type:cccbValue.type 
        })
       }
    function cccbModalCard(){
        return(
        <>
        <Grid style={{width:"65%"}}>
        <Grid 
        >
         <RACTable
           renderTableHead={cccbHeader}
           renderTableContent={cccbHeaderContent}
         />
        </Grid>
        </Grid>
        <Grid 
        >
         <RACTable
           renderTableHead={cccbTableValues}
           renderTableContent={()=>cccbRender()}
         />
        </Grid>
       
        <Grid item md={12}
        style={{marginTop:"30px"}} >          
               
                  <RACButton
                     
                     variant="contained"
                     color="primary"
                     className={`${classes.spacerMR2} ${classes.setDefault}`}
                     style={{float:"right"}}
                     //disabled={nsfContinue}
                    onClick={()=> ContinueClk()}
                   
                >
                  Continue
                  </RACButton>
                  {/* <RACButton
                    className={`${classes.spacerMR2} ${classes.setDefault}`}
                    color="primary"
                    variant="contained"
                    // disabled={}
                    style={{float:"right",marginLeft:"15px",marginRight:"10px"}}
                    onClick={()=>{setCccbValue({...cccbValue,cccbAmount:cccbValue.chargeAmount})}}
                  // {text('ContainedButton Label', 'hover over me')}
                  >
                    Set To Default
                  </RACButton> */}
                  {/* <RACButton
                    className={`${classes.spacerMR2} ${classes.setDefault}`}
                    color="primary"
                    variant="contained"
                    // disabled={}
                    style={{float:"right",marginLeft:"15px",marginRight:"10px"}}
                    onClick={()=>{setCccbValue({...cccbValue,cccbAmount:"0.00"})}}
                  // {text('ContainedButton Label', 'hover over me')}
                  >
                    Set To Zero
                  </RACButton> */}
                  <RACButton
                        type="button"
                        color="primary"
                        variant="outlined"
                         style={{float:"right"}}
                         className={`${classes.spacerMR2} ${classes.setDefault1}`}
                         onClick={()=>cancelBtn()}
                      >
                      Cancel
                      </RACButton>
    
                  </Grid>         
        </> 
        )
    }
    const cccbRender=()=>{
        return(
            <>
            
            <RACTableRow
                      className={` ${classes.rowColor} ${classes.font12}`}
                    >
                      <RACTableCell className={` ${classes.paymentStore}  `} 
                      style={{fontFamily: "OpenSans-bold"}}>
                         Charge Back Amount
                      </RACTableCell>
                      <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                      style={{fontFamily: "OpenSans-semibold"}}
                      >
                      <span>$</span>{Number(props.receivedCheckedAgr[0].cccbData.amount).toFixed(2)}
                      </RACTableCell>
                      <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                      style={{fontFamily: "OpenSans-semibold"}}
                      >
                      <span>$</span>{Number(props.receivedCheckedAgr[0].cccbData.balanceAmount).toFixed(2)}
                      </RACTableCell>
                      <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                      style={{fontFamily: "OpenSans-semibold"}}
                      >
                      <span>$</span>{Number(props.receivedCheckedAgr[0].cccbData.balanceAmount).toFixed(2)}
                      </RACTableCell>
                     
                      
           </RACTableRow>
           <RACTableRow
                      className={` ${classes.rowColor} ${classes.font12}`}
                    >
                      <RACTableCell className={` ${classes.paymentStore}  `} 
                      style={{fontFamily: "OpenSans-bold"}}>
                         Charge Back Total
                      </RACTableCell>
                      <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                      style={{fontFamily: "OpenSans-semibold"}}
                      >
                      <span>$</span>{Number(props.receivedCheckedAgr[0].cccbData.amount).toFixed(2)}
                      </RACTableCell>
                      <RACTableCell className={` ${classes.paymentStore} ${classes.textend} ${classes.font12}`}
                      style={{fontFamily: "OpenSans-semibold"}}
                      >
                      <span>$</span>{Number(props.receivedCheckedAgr[0].cccbData.balanceAmount).toFixed(2)}
                    
                      </RACTableCell>
                      <RACTableCell
                       className={`${classes.textend} ${classes.font12} ${classes.paymentStore} `}
                       style={{fontFamily: "OpenSans-semibold"}} >
                      <span>$</span>{Number(props.receivedCheckedAgr[0].cccbData.balanceAmount).toFixed(2)}
                      </RACTableCell>
                      
           </RACTableRow>
           </>
        )
    }
    return(
        <>
        <Grid >
         <RACModalCard
        isOpen={props.isOpen}
        maxWidth="sm"
        title ="Adjust Credit Card Charge Back Repayment"
        borderRadius={ModalRadius}
        onClose={()=> {cancelBtn()}}
        closeIcon={true}
        /* eslint-disable react/no-children-prop */
        children={cccbModalCard()}
        />
    </Grid>
    </>
    )
 }
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, ListItem, List } from "@rentacenter/racstrap";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import "react-loading-skeleton/dist/skeleton.css";
export default function BreadCrumbs() {
  const classes = takePaymentStyles();
  const history = useHistory();

  return (
    <Grid item md={12}>
      <List className={`${classes.breadcrumb} ${classes.py1}`}>
        <ListItem className={classes.breadcrumbItem}>
          <a
            className={classes.breadcrumbInActive}
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/dashboard`)}
          >
            Dashboard
          </a>
        </ListItem>
        <ListItem
          className={`${classes.breadcrumbItem1} ${classes.breadcrumbArrow} ${classes.breadcrumbInActive}`}
        >
          <a
            className={classes.breadcrumbInActive}
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/payment1/paymentsearch`)
            }
          >
            Payment
          </a>
        </ListItem>
        <ListItem
          className={`${classes.breadcrumbItemActive} ${classes.breadcrumbArrow}`}
        >
          <a style={{ cursor: "pointer" }}>Reverse Payment</a>
        </ListItem>
      </List>
    </Grid>
  );
} // PC-06 This methos will be rendering the breadcrumbs in the reverse payment page.

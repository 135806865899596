import React from 'react';
import { RACModal, Typography, RACButton, Grid } from '@rentacenter/racstrap';
import { ReactComponent as ApiErrorIcon } from '../../../assets/images/apiErrorIcon.svg';
import CONSTANTS from '../../constants/constant';
import { ApiErrorModalStyles } from '../../interface/apiErrorModalInterface';

export function ApiErrorModal({ open, message, onClose }) {
  const classes = ApiErrorModalStyles();
  const apiErrorModalContent = (message: string) => {
    return (
      <>
        <Grid className={`${classes.textAlignCenter}`}>
          <Grid className={`${classes.mr1} ${classes.pad1}`}>
            <ApiErrorIcon />
          </Grid>
          <Typography
            variant="body2"
            className={`${classes.apiErrorMessage} ${classes.mr1}`}
          >
            {message || CONSTANTS.UNABLE_TO_FETCH_THE_DATA_TRY_AGAIN_LATER}
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <RACModal
      isOpen={open}
      titleVariant="h4"
      classes={{
        dialogContent: classes.textAlignLeft,
        dialog: classes.dialogRoot,
        dialogActions: classes.dialogActions,
      }}
      maxWidth="xs"
      title=""
      content={apiErrorModalContent(message)}
      onClose={onClose}
      buttons={
        <>
          <RACButton variant="contained" color="primary" onClick={onClose}>
            OK
          </RACButton>
        </>
      }
    />
  );
}

/* eslint-disable  */
enum CONTENT_TYPE {
  pdf = 'application/pdf',
  html = 'text/html',
  jpg = 'image/jpg',
  jpeg = 'image/jpeg',
  jpe = 'image/jpe',
  gif = 'image/gif',
  png = 'image/png',
  bmp = 'image/bmp',
}
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { CustomerdataContext } from '../../context/customer/customerdata';
import CONSTANTS from '../constants/constant';
import { ClubActivityInfo, ClubPaymentHistoryGridInfo } from '../interface/paymentHistoryInterface';
import { Customer, CustomerAddressDetail, CustomerContactDetail } from '../interface/paymentInfoInterface';

export const ClubHistoryPrintInfo = (props) => {

  const { customerdata, setcustomerdata } = useContext(CustomerdataContext);




  let customerPrimaryAddress: string = CONSTANTS.EMPTY_STRING;
  let customerSecondaryAddress: string = CONSTANTS.EMPTY_STRING;
  let customerCity = CONSTANTS.EMPTY_STRING;
  let customerdob = CONSTANTS.EMPTY_STRING;
  let customerPhoneNumber = CONSTANTS.EMPTY_STRING;
  let customerEmailAdress = CONSTANTS.EMPTY_STRING;
  let customerFname = CONSTANTS.EMPTY_STRING;
  let customerLname = CONSTANTS.EMPTY_STRING;
  let customerPlusFour = CONSTANTS.EMPTY_STRING;
  let customerZip = CONSTANTS.EMPTY_STRING
  let customerState = CONSTANTS.EMPTY_STRING


  let result: Customer = customerdata
  if (result != undefined) {

    if (result.customerAddressDetails != undefined) {
      let customerAdressFilterArray: CustomerAddressDetail[] = result.customerAddressDetails?.filter((x: CustomerAddressDetail) =>
        x.addressTypeDesc == "Primary Address"
      )
      if (customerAdressFilterArray.length == CONSTANTS.ZERO_NUMBER && result.customerAddressDetails.length > CONSTANTS.ZERO_NUMBER) {
        customerAdressFilterArray = [result.customerAddressDetails[0]]
      }
      customerPrimaryAddress = customerAdressFilterArray.length > CONSTANTS.ZERO_NUMBER ? customerAdressFilterArray[0].addressLine1 : CONSTANTS.EMPTY_STRING
      customerSecondaryAddress = customerAdressFilterArray.length > CONSTANTS.ZERO_NUMBER ? customerAdressFilterArray[0].addressLine2 : CONSTANTS.EMPTY_STRING
      customerPlusFour = customerAdressFilterArray.length > CONSTANTS.ZERO_NUMBER ? customerAdressFilterArray[0].postalCode != null ? customerAdressFilterArray[0].postalCode?.split(CONSTANTS.HYPHEN)[1] : CONSTANTS.EMPTY_STRING : CONSTANTS.EMPTY_STRING
      customerZip = customerAdressFilterArray.length > CONSTANTS.ZERO_NUMBER ? customerAdressFilterArray[0].postalCode != null ? customerAdressFilterArray[0].postalCode?.split(CONSTANTS.HYPHEN)[0] : CONSTANTS.EMPTY_STRING : CONSTANTS.EMPTY_STRING
      customerState = customerAdressFilterArray.length > CONSTANTS.ZERO_NUMBER ? customerAdressFilterArray[0].state : CONSTANTS.EMPTY_STRING
      customerCity = customerAdressFilterArray.length > CONSTANTS.ZERO_NUMBER ? customerAdressFilterArray[0].city : CONSTANTS.EMPTY_STRING
    }
    customerdob = result.customerDetails[0].dob
    let customerPhoneDetails: CustomerContactDetail[] = result.customerContactDetails?.filter((x: CustomerContactDetail) =>
      x.primary == "Y"
    )
    if (customerPhoneDetails.length == CONSTANTS.ZERO_NUMBER && result.customerContactDetails.length > CONSTANTS.ZERO_NUMBER) {
      customerPhoneDetails = [result.customerContactDetails[0]]
    }
    customerPhoneNumber = customerPhoneDetails.length > CONSTANTS.ZERO_NUMBER ? customerPhoneDetails[0]?.phoneNumber : CONSTANTS.EMPTY_STRING
    customerEmailAdress = result.customerDetails[0].emailAddress
    customerFname = result.customerDetails[0].firstName
    customerLname = result.customerDetails[0].lastName

  }


  const ClubGridbind = () => {

    return props.ClubGridInfoResp.map((value: ClubPaymentHistoryGridInfo, index: number) => {

      return (

        <tr key={index}>
          <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.datePaid}
          </td>
          <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.dayPaid}{" "}
          </td>
          <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.timePaid}
          </td>
          <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.receiptId}
          </td>
          <td
            style={{
              paddingTop: 10,
              fontSize: 13,
              fontWeight: 600,
              paddingRight: 10
            }}
          >
            {value.oldDueDate}
          </td>


          <td
            style={{
              paddingTop: 10,
              fontSize: 13,
              fontWeight: 600,
              textAlign: "right",
              paddingRight: 20
            }}
          >
            ${Number(value.totalPayment).toFixed(2) ? Number(value.totalPayment).toFixed(2) : value.totalPayment}
          </td>
          <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.nextDueDate}
          </td>

          <td
            style={{
              paddingTop: 10,
              fontSize: 13,
              fontWeight: 600,
              textAlign: "right"
            }}
          >
            {value.daysLate}
          </td>
          <td
            style={{
              paddingTop: 10,
              fontSize: 13,
              fontWeight: 600,
              textAlign: "right",
              paddingRight: 20
            }}
          >
            {value.store}
          </td>



          <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.paymentOrigin}
          </td>
          <td style={{ paddingTop: 10, fontSize: 13, fontWeight: 600 }}>
            {value.tenderType}
          </td>


          <td
            style={{
              paddingTop: 10,
              fontSize: 13,
              fontWeight: 600,
              textAlign: "right"
            }}
          >
            ${Number(value.tax).toFixed(2) ? Number(value.tax).toFixed(2) : value.tax}
          </td>
        </tr>

      )
    })
  }

  const ClubActivitybind = () => {
    return props.clubActivityInfo.map((value: ClubActivityInfo, index: number) => {

      return (

        <tr key={index}>
          <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
            {value.activityDate}
          </td>
          <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
            {value.activityType}
          </td>
          <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
            {value.status}
          </td>
          <td style={{ paddingTop: 12, fontWeight: 600, fontSize: 13 }}>
            {value.coWorker}
          </td>
        </tr>


      )
    })
  }






  return (


    <div style={{ display: "none" }}>
      <div style={{ width: "100%", float: "left" }} id={"ClubAgreementPrintInfo"}>
        {/*top section starts here*/}
        <div style={{ width: "100%", float: "left" }}>
          <div style={{ width: "50%", float: "left" }}>
            <h4 style={{ fontSize: 17, fontWeight: "bold" }}>
              Club Payment Receipt
            </h4>
          </div>
          <div style={{ width: "50%", float: "left", marginTop: 25 }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Date :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{moment().format('MM/DD/YYYY')}</span>
          </div>
        </div>
        <div style={{ width: "100%", float: "left", marginBottom: 20 }}>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Store # :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{String(window.sessionStorage.getItem("storeNumber"))}</span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Membership # :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{props.clubinfo.membershipId || "NA"}</span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Customer :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{props.commoninformation.customerName} </span>
          </div>
          <div style={{ width: "25%", float: "left" }}>
            <span style={{ fontWeight: 600, fontSize: 13 }}>Co-Customer :</span>
            <span style={{ fontWeight: 600, fontSize: 13 }}>{props.commoninformation.coCustomerName || CONSTANTS.HYPHEN} </span>

          </div>
        </div>
        {/*top section ends here*/}
        {/*Club Information section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: 20 }}>
          <h4
            style={{
              borderBottom: "1px solid #000",
              paddingBottom: 8,
              width: "100%",
              fontSize: 13,
              fontWeight: "bold",
              marginBottom: 20
            }}
          >
            Club Information
          </h4>
          <div style={{ width: "10%", float: "left", paddingRight: "10%" }}>
            <label
              style={{
                width: "75%",
                float: "left",
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Weekly Rate
            </label>
            <label
              style={{
                width: "75%",
                float: "left",
                paddingTop: 13,
                textAlign: "right",
                fontWeight: 600,
                fontSize: 13
              }}
            >
              ${Number(props.clubinfo.scheduleRate).toFixed(2) ? Number(props.clubinfo.scheduleRate).toFixed(2) : props.clubinfo.scheduleRate}
            </label>
          </div>
          <div style={{ width: "12%", float: "left", paddingRight: "15%" }}>
            <label
              style={{
                width: "90%",
                float: "left",
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Club Paid To Date
            </label>
            <label
              style={{
                width: "90%",
                float: "left",
                paddingTop: 13,
                textAlign: "right",
                fontWeight: 600,
                fontSize: 13
              }}
            >
              ${Number(props.clubinfo.clubPaidTillDate).toFixed(2) ? Number(props.clubinfo.clubPaidTillDate).toFixed(2) : props.clubinfo.clubPaidTillDate}
            </label>
          </div>
          <div style={{ width: "11%", float: "left", paddingRight: "14%" }}>
            <label
              style={{
                width: "58%",
                float: "left",
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Open Date
            </label>
            <label
              style={{
                width: "56%",
                float: "left",
                paddingTop: 13,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              {props.clubinfo.enrollmentDate || "NA"}
            </label>
          </div>
          <div style={{ width: "10%", float: "left", paddingRight: "18%" }}>
            <label
              style={{
                borderBottom: "1px solid #000",
                paddingBottom: 8,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              Close Date
            </label>
            <label
              style={{
                width: "56%",
                float: "left",
                paddingTop: 13,
                fontWeight: 600,
                fontSize: 13
              }}
            >
              {props.clubinfo.terminationDate}
            </label>
          </div>
        </div>
        {/*Club Information section ends here*/}
        {/*Club activity section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: 50 }}>
          <h4
            style={{
              borderBottom: "1px solid #000",
              paddingBottom: 10,
              width: "100%",
              fontSize: 15,
              fontWeight: "bold",
              marginBottom: 10
            }}
          >
            Club Activity
          </h4>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Activity Date
                  </span>
                </th>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Activity Type
                  </span>
                </th>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Status
                  </span>
                </th>
                <th style={{ textAlign: "left", fontSize: 13 }}>
                  <span
                    style={{
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      paddingBottom: 6
                    }}
                  >
                    Coworker
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {ClubActivitybind()}
            </tbody>
          </table>
        </div>
        {/*Club activity section ends here*/}
        {/*Payment History Summary section starts here*/}
        <div style={{ width: "100%", float: "left", marginBottom: 20 }}>
          <h4 style={{ fontSize: 13, fontWeight: "bold" }}>Club Payment History</h4>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Date Paid
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Day
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Time
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Receipt #
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13,
                    paddingRight: 10
                  }}
                >
                  Old Due Date{" "}
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13,
                    paddingRight: 20
                  }}
                >
                  Total Payment
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Next Due Date
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13
                  }}
                >
                  Days Late
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13,
                    paddingRight: 20
                  }}
                >
                  Store
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Payment Origin
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "left",
                    fontSize: 13
                  }}
                >
                  Tender Type
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #000",
                    color: "#000",
                    paddingBottom: 8,
                    textAlign: "right",
                    fontSize: 13
                  }}
                >
                  Tax
                </th>
              </tr>
            </thead>
            <tbody>
              {ClubGridbind()}
            </tbody>
          </table>
        </div>
        {/*Payment History Summary section ends here*/}
      </div>
    </div>
  )


}
export default ClubHistoryPrintInfo
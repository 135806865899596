/* eslint-disable prettier/prettier */
import React, { useState, createContext } from 'react';

export const DeliveryContext = createContext<any>('');

export function DeliveryContextData(props: any) {
  const [instruction, setinstruction] = useState('');
  const [clubBtnStatus, setclubBtnStatus] = useState('');
  const [custOrder, setCustOrder] = useState('');


  const [searchVal, setsearchVal] = useState<any>({
    firstName: '',
    lastName: '',
    dob: '',
    taxId: '',
    phones: [
      {
        phoneNumber: ''
      }
    ],
    cancelClick: true,
  });


  return (
    <div>
      <DeliveryContext.Provider
        value={{
          InstructionContext: [instruction, setinstruction],
          paymentSearchValue: [searchVal, setsearchVal],
          clubsStatus: [clubBtnStatus, setclubBtnStatus],
          customerOrder: [custOrder, setCustOrder],
        }}
      >
        {props.children}
      </DeliveryContext.Provider>
    </div>
  );
}

import { makeStyles } from '@rentacenter/racstrap';
import { Theme } from '@material-ui/core/styles/createTheme';

export const ApiErrorModalStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    textAlign: 'left',
    height: theme.typography.pxToRem(400),
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      padding: '1rem',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: theme.typography.pxToRem(100),
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(500),
      maxHeight: theme.typography.pxToRem(363),
    },
    '& .MuiTypography-h4': {
      fontSize: '20px !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.typography.pxToRem(10),
    },
    '& .MuiInputBase-multiline': {
      margin: '8px 0px 0px 0px !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  dialogActions: {
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    justifyContent: 'center',
  },
  apiErrorMessage: {
    fontSize: '1rem !important',
  },
  mr1: {
    margin: '1%',
  },
  pad1: {
    paddingBottom: '1%',
  },
}));

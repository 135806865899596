/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable use-isnan */
// eslint-disable prettier/prettier
// eslint-disable-next-line prettier/prettier

import { makeStyles } from '@rentacenter/racstrap';
export const checkMoGridStyle = () => {

    const UseClasses = makeStyles(() => ({
        
        paymentBtnHover: {
            "&:hover": {
                color: "white",
                backgroundColor: "#0d6efd",
                borderColor: "#0d6efd",
            },
        },
    }));

    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const classes = UseClasses();
    return classes;
};
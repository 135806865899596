/**
 * PaymentPrimaryDetails_02
 * Import the required packages, interface and context files
 */
import React, { useContext } from 'react';
import { PaymentGrid } from './PaymentGrid';
import { PaymentException } from './PaymentExceptionComponent';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { Grid } from '@rentacenter/racstrap';
import { PaymentSideBar } from './PaymentSideBar';
import { AvailableCredits } from './AvailableCredits';
import { CarryRent } from './CarryRent';
import { agreementContext } from '../context/PaymentInformationContext';
import TenderType from './TenderType';
import CONSTANTS from '../constants/constant';

/**
 * PaymentPrimaryDetails_03
 * Invoke a functional based component as PaymentPrimaryDetails()
 * @returns
 */
export default function PaymentPrimaryDetails() {
  const classes = takePaymentStyles();
  /**
   * PaymentPrimaryDetails_04
   * Destruct customerOrderPayment from the context
   */
  const { customerOrderPayment } = useContext(agreementContext);

  /**
   * To restrict the carry rent in commitment flow
   */
  const urlParams = new URLSearchParams(window.location.search);
  const isCommitment = urlParams.get('origin');

  /**
   * PaymentPrimaryDetails_05 - PaymentPrimaryDetails_08
   * Within return component, call final suspense component, racExchange return pending popup
   * payment exceptioncomponent, tender type, available credits, payment side bar
   * carry rent will be rendered only for normal rental flow payments
   * payment grid will not be rendered in customer order prelim flow
   */
  return (
    <>
      <Grid container data-testid="paymentfullscreen">
        <Grid className={`${classes.w100} ${classes.bgWhite} ${classes.mr1m0}`}>
          <PaymentException />
        </Grid>

        <Grid className={classes.raccollg9}>
          {customerOrderPayment != CONSTANTS.IS_CO_PRELIM && <PaymentGrid />}
          <TenderType />

          <Grid
            item
            className={`${classes.raccollg4} ${classes.floatLeft} ${classes.mb3} ${classes.px1}  ${classes.responsivelayout}`}
          >
            <AvailableCredits />
            {customerOrderPayment == CONSTANTS.EMPTY_STRING &&
              !isCommitment && <CarryRent />}
          </Grid>
        </Grid>
        <PaymentSideBar />
      </Grid>
    </>
  );
}
